import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';


class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
  }


  componentDidMount = async () => {
   
    let getSession_Administrator_Email = await sessionStorage.getItem('administrator_email')
    // if (getSession_Administrator_Email !== null) {
    //   await this.setState({
    //     administrator_email: getSession_Administrator_Email,
    //   })
    // }

    //alert(getSession_Administrator_Email);

    let getSession_Administrator_ID = await sessionStorage.getItem('administrator_id')
    if (getSession_Administrator_ID == null) {
      this.setState({ redirect: true })
    }
   


    // //alert(getSession_Administrator_ID);


    // let getSession_Administrator_Name = await sessionStorage.getItem('administrator_name')
    // if (getSession_Administrator_Name !== null) {
    //   await this.setState({
    //     administrator_name: getSession_Administrator_Name,
    //   })
    // }
    

  }

  logout = () => {
    console.log("in logout")
    this.setState({ redirect: true })
    sessionStorage.removeItem('administrator_id')
    sessionStorage.removeItem('administrator_name')
    sessionStorage.removeItem('administrator_email_id')
  }


  render() {
    if (this.state.redirect) {
      return (<Redirect to={'/'} />)
    }
    return (
      <div className="header-nav">
        <div className="container">
          <nav className="navbar navbar-expand-lg">
            {/* <a className="navbar-brand" href="https://cetas-healthcare.com/">
                            <img
                                src={require('../Assets/Images/cetas-logo.png')} 
                            />  
                        </a> */}
            <a style={{ display: "table-cell" }} className="navbar-brand"
              href="https://cetas-healthcare.com/" target="_blank" rel="noopener">
              <img
                src={require('../Assets/Images/cetas-logo.png')}
              />
            </a>

            <button className="navbar-toggler" type="button" data-toggle="collapse"
              data-target="#navbarNavAltMarkup"
              aria-controls="navbarNavAltMarkup" aria-expanded="false"
              aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
              <div className="navbar-nav" style={{ marginLeft: '80%' }}>
                {/* <a className="nav-item nav-link" href="#">Home 
                                    <span className="sr-only">(current)</span>
                                </a>
                                <a className="nav-item nav-link active" href="#">Projects</a>
                                <a className="nav-item nav-link" href="#">Team Members</a> */}
                {/* <a className="nav-item nav-link" >
                                    <img
                                        src={require('../Assets/Images/setting.png')} 
                                    /> 
                                    Search
                                </a> */}
              </div>
            </div>

            <a className="nav-item nav-link username-box" >
              <img
                src={require('../Assets/Images/user.svg')}
              />
              Welcome,  {this.props.ClientName}
            </a>


            <a className="nav-item nav-link username-box  float-right"
              onClick={this.logout.bind(this)}>
              <img
                src={require('../Assets/Images/logoutByVibha.svg')}
              />
              Logout
            </a>

          </nav>
        </div>
      </div>
    );
  }
}

export default Header;