import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import { Chart } from "react-google-charts";
import { Bar, Line } from 'react-chartjs-2';
import { ButtonDropdown, ButtonGroup, Card, CardBody, CardColumns, CardHeader, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
import filterFactory, { textFilter, customFilter, selectFilter, Comparator, FILTER_TYPES } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import LoadingBar from "react-top-loading-bar";
import Header from './Header';
import ApexChart from './ApexChart';

class AdministratorKolProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kol_id: '',
      administrator_name: '',
      // ProfileBySession: this.props.location.state.ProfileBySession,

      RedirectFlag: false,

      //Flag to show form of selected drawer item
      PersonalInformationFlag: true,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      SummaryFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,

      // to store data fetch from api
      PersonalInformation: {},
      WorkDetails: [],
      Biography: {},
      ProfessionalMemberships: [],
      Education: [],
      Summary: [],
      AdvisoryBoard: [],
      AwardsHonours: [],
      BooksMonographs: [],
      Commitees: [],
      ClinicalTrialDetails: [],
      EventsDetails: [],
      Grants: [],
      Guidelines: [],
      HospitalAffiliations: [],
      Interactions: [],
      PressItem: [],
      Presentation: [],
      PublicationDetails: [],
      SunshinePayment: [],

      profile_photo: '',
      LanguageSpoken: [],
      BiographyResume: '',
      profile_photo_for_edit: '',
      BiographyResume_edit: '',
      BiographyResumeFileName: '',
      FinalResumeName: '',

      CountryListData: [],
      StateData: [],
      CityData: [],
      EmployeeListData: [],

      // KolRatingScore data
      event_kol_rating: [],
      publication_kol_rating: [],
      ad_board_kol_rating: [],
      guideline_kol_rating: [],
      clinicaltrial_kol_rating: [],

      //piechart data
      event_piechart: [],
      ad_board_piechart: [],
      publication_piechart: [],
      guideline_piechart: [],
      clinicaltrial_piechart: [],

      home_piechart_flag: true,

      // all columns 
      columnsWorkDetails: [
        {
          dataField: 'current_place_of_work',
          text: 'Current Place of Work',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            // return { textAlign: 'center', width: '10%' };
            return { width: '10%' };
          },
        },
        {
          dataField: 'designation',
          text: 'Designation',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'department',
          text: 'Department',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'from_year',
          text: 'From Year',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
              return { width: '10%' };
          },
        },
        {
            dataField: 'to_year',
            text: 'To Year',
            // align: 'center',
            headerStyle: (colum, colIndex) => {
                return { width: '10%' };
            },
        },
      ],
      columnsProfessionalMemberships: [
        {
          dataField: 'organization',
          text: 'Organization',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'organization_type',
          text: 'Organization Type',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'location',
          text: 'Location',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'position',
          text: 'Position',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '8%' };
          },
        },
        {
          dataField: 'tenure',
          text: 'Tenure',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '8%' };
          },
        },
        {
          dataField: 'organization_website',
          text: 'Organization Website',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'source_link',
          text: 'Source Link',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
      ],
      columnsEducation: [
        {
          dataField: 'institute_name',
          text: 'Alma  Mater',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'institute_type',
          text: 'Alma Mater Type',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'degrees',
          text: 'Qualification',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'speciality_type',
          text: 'Speciality',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'years_attended_from',
          text: 'Years Attended From',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'years_attended_to',
          text: 'Years Attended To',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'source_link',
          text: 'Institution Link',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
      ],
      columnsAdvisoryBord: [
        {
          dataField: 'year',
          text: 'From Year',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        // new
        {
          dataField: 'to_year',
          text: 'To Year',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
              return { width: '10%' };
          },
        },
        // {
        //   dataField: 'speciality_type',
        //   text: 'Speciality Type',
        //   // align: 'center',
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '10%' };
        //   },
        // },
        // {
        //   dataField: 'sub_specialty_id',
        //   text: 'Sub Speciality Type',
        //   // align: 'center',
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '15%' };
        //   },
        // },
        // {
        //   dataField: 'procedure_type_id',
        //   text: 'Procedure Type',
        //   // align: 'center',
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '15%' };
        //   },
        // },
        {
          dataField: 'company',
          text: 'Company',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'type_of_company',
          text: 'Type of Company',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'advisory_board',
          text: 'Advisory Board',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'topic',
          text: 'Topic',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'role_id',
          text: 'Role',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        // {
        //     dataField: 'manual_weightage',
        //     text: 'Manual Weightage',
        //     // align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //     return { width: '15%' };
        //     },
        // },
      ],
      columnsAwardsHonours: [
        {
          dataField: 'year',
          text: 'Year',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '5%' };
          },
        },
        // new
        {
          dataField: 'to_year',
          text: 'To Year',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
              return { width: '5%' };
          },
        },
        {
          dataField: 'awards_honours',
          text: 'Awards Honours',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '5%' };
          },
        },
        {
          dataField: 'types',
          text: 'Types of Awards & Honours',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '5%' };
          },
        },
      ],
      columnsBooksMonographs: [
        {
          dataField: 'year',
          text: 'Year',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'title',
          text: 'Title',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'author',
          text: 'Author',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'publication',
          text: 'Publication',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'edition',
          text: 'Edition',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '8%' };
          },
        },
        {
          dataField: 'pages',
          text: 'Pages',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '8%' };
          },
        },
        {
          dataField: 'editors',
          text: 'Editors',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '20%' };
          },
        },
      ],
      columnsCommitees: [
        {
          dataField: 'year',
          text: 'Year',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        // new
        {
          dataField: 'body_org_name',
          text: 'Body/Org Name',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
              return { width: '10%' };
          },
        },
        {
          dataField: 'committee',
          text: 'Committee',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'role_id',
          text: 'Role',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
      ],
      columnsClinicalTrialDetails: [
        {
          dataField: 'study_details',
          text: 'Study Details',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        // {
        //   dataField: 'speciality_type',
        //   text: 'Speciality Type',
        //   // align: 'center',
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '10%' };
        //   },
        // },
        // {
        //   dataField: 'sub_speciality',
        //   text: 'Sub Speciality Type',
        //   // align: 'center',
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '10%' };
        //   },
        // },
        // {
        //   dataField: 'procedure_type',
        //   text: 'Procedure Type',
        //   // align: 'center',
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '10%' };
        //   },
        // },
        {
          dataField: 'start_date',
          text: 'Start Date',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '8%' };
          },
        },
        {
          dataField: 'end_date',
          text: 'End Date',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '8%' };
          },
        },
        {
          dataField: 'type',
          text: 'Type',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '8%' };
          },
        },
        {
          dataField: 'phase',
          text: 'Phase',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '8%' };
          },
        },
        {
          dataField: 'role_id',
          text: 'Role',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '8%' };
          },
        },
        {
          dataField: 'no_of_study_locations',
          text: 'No of Study Locations',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'conditions',
          text: 'Condition',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'conditions',
          text: 'Condition',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'treatment_intervention',
          text: 'Treatment Intervention',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'primary_outcomes',
          text: 'Primary Outcomes',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'secondary_outcomes',
          text: 'Secondary Outcomes',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'sponsors',
          text: 'Sponsors',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'collaborators',
          text: 'Collaborators',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        // {
        //     dataField: 'manual_weightage',
        //     text: 'Manual Weightage',
        //     // align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //     return { width: '10%' };
        //     },
        // },
        {
          dataField: 'source_link',
          text: 'Source Link',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
      ],
      columnsEventsDetails: [
        {
          dataField: 'events',
          text: 'Event Name',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'event_type',
          text: 'Event Type',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        // {
        //   dataField: 'speciality_type',
        //   text: 'Speciality Type',
        //   // align: 'center',
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '10%' };
        //   },
        // },
        // {
        //   dataField: 'sub_speciality',
        //   text: 'Sub Speciality Type',
        //   // align: 'center',
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '10%' };
        //   },
        // },
        // {
        //   dataField: 'procedure_type',
        //   text: 'Procedure Type',
        //   // align: 'center',
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '10%' };
        //   },
        // },
        {
          dataField: 'event_desc',
          text: 'Event Description',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'event_topic',
          text: 'Event Topic',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'conditions',
          text: 'Conditions',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'intervention',
          text: 'Iterventions',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'date',
          text: 'Date',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '8%' };
          },
        },
        {
          dataField: 'session_type',
          text: 'Session Type',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'role_id',
          text: 'Role',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '8%' };
          },
        },
        {
          dataField: 'event_sponsor',
          text: 'Event Sponsor',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'total_event_attendees',
          text: 'Total Event Attendees',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'country_name',
          text: 'Country',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'source_link',
          text: 'Source Link',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
      ],
      columnsGrants: [
        {
          dataField: 'company',
          text: 'Company',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'title',
          text: 'Title',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'year',
          text: 'Year',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'amount',
          text: 'Amount',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'co_investigator',
          text: 'Co-Investigator',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
      ],
      columnsGuidelines: [
        {
          dataField: 'year',
          text: 'Co-Year',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        // {
        //   dataField: 'speciality_type',
        //   text: 'Speciality Type',
        //   // align: 'center',
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '10%' };
        //   },
        // },
        // {
        //   dataField: 'sub_speciality',
        //   text: 'Sub Speciality Type',
        //   // align: 'center',
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '15%' };
        //   },
        // },
        // {
        //   dataField: 'procedure_type',
        //   text: 'Procedure Type',
        //   // align: 'center',
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '15%' };
        //   },
        // },
        {
          dataField: 'company',
          text: 'Company',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'title',
          text: 'Title',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'organization',
          text: 'Organization',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'type_of_organization',
          text: 'Type of Organization',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        // {
        //     dataField: 'manual_weightage',
        //     text: 'Manual Weightage',
        //     // align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //     return {width: '8%' };
        //     },
        // },
      ],
      columnsHospitalAffiliations: [
        {
          dataField: 'hospital',
          text: 'Hospital',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        // new
        {
          dataField: 'role',
          text: 'Role',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
              return { width: '10%' };
          },
        },
        // new
        {
            dataField: 'from_year',
            text: 'From Year',
            // align: 'center',
            headerStyle: (colum, colIndex) => {
                return { width: '10%' };
            },
        },
        // new
        {
            dataField: 'to_year',
            text: 'To Year',
            // align: 'center',
            headerStyle: (colum, colIndex) => {
                return { width: '10%' };
            },
        },
        {
          dataField: 'city_name',
          text: 'City',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'state_name',
          text: 'State',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'country_name',
          text: 'Country',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
      ],
      columnsInteractions: [
        {
          dataField: 'date',
          text: 'Date',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'opinion_leader',
          text: 'Opinion Leader',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'emp_name',
          text: 'Entered By',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'product',
          text: 'Product',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'type',
          text: 'Type',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'mode',
          text: 'Mode',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'time',
          text: 'Time',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'activity_or_tool_used',
          text: 'Activity or Tool Used',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '13%' };
          },
        },
        {
          dataField: 'comments',
          text: 'Comments',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '13%' };
          },
        },
      ],
      columnsPressItem: [
        {
          dataField: 'title',
          text: 'Title',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        // new
        {
          dataField: 'quote',
          text: 'Quote',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
              return { width: '10%' };
          },
        },
        {
          dataField: 'date',
          text: 'Date',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'source_link',
          text: 'Press Item Link',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
      ],
      columnsPresentation: [
        {
          dataField: 'title',
          text: 'Title',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        // new
        {
          dataField: 'event_name',
          text: 'Event Name',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
              return { width: '10%' };
          },
        },
        {
          dataField: 'type',
          text: 'Type',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'sponsor',
          text: 'Sponsor',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'location',
          text: 'Location',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'date',
          text: 'Date',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
      ],
      columnsPublicationDetails: [
        {
          dataField: 'journal',
          text: 'Journal',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        // {
        //   dataField: 'speciality_type',
        //   text: 'Speciality Type',
        //   // align: 'center',
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '10%' };
        //   },
        // },
        // {
        //   dataField: 'sub_speciality',
        //   text: 'Sub Speciality Type',
        //   // align: 'center',
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '10%' };
        //   },
        // },
        // {
        //   dataField: 'procedure_type',
        //   text: 'Procedure Type',
        //   // align: 'center',
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '10%' };
        //   },
        // },
        {
          dataField: 'type_of_journal',
          text: 'Type of Journal',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'publication_title',
          text: 'Publication Title',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'date',
          text: 'Date',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '8%' };
          },
        },
        {
          dataField: 'position',
          text: 'Position',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'co_author',
          text: 'Co-Author',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'key_words',
          text: 'Key Words',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        // {
        //     dataField: 'manual_weightage',
        //     text: 'Manual Weightage',
        //     // align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //     return { width: '8%' };
        //     },
        // },
        {
          dataField: 'source_link',
          text: 'Source Link',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
      ],
      columnsSunshinePayment: [
        {
          dataField: 'date',
          text: 'Date',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'type',
          text: 'Type',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'devices',
          text: 'Devices',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'location',
          text: 'Location',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'amount',
          text: 'Amount',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'payment_type',
          text: 'Payment Type',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'OL_address',
          text: 'Address',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        // {
        //   dataField: 'speciality_type',
        //   text: 'Speciality Type',
        //   // align: 'center',
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '15%' };
        //   },
        // },
        {
          dataField: 'NPI',
          text: 'NPI',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
      ],
    };
  }

  //Sub Navbar functions of summary piechart
  HomePiechartFun = async () => {
    await this.setState({
      home_piechart_flag: true,
      event_piechart_flag: false,
      publication_piechart_flag: false,
      ad_board_piechart_flag: false,
      guideline_piechart_flag: false,
      clinicaltrial_piechart_flag: false,
    });
  }
  EventPiechartFun = async () => {
    await this.setState({
      home_piechart_flag: false,
      event_piechart_flag: true,
      publication_piechart_flag: false,
      ad_board_piechart_flag: false,
      guideline_piechart_flag: false,
      clinicaltrial_piechart_flag: false,
    });
  }
  PublicationPiechartFun = async () => {
    await this.setState({
      home_piechart_flag: false,
      event_piechart_flag: false,
      publication_piechart_flag: true,
      ad_board_piechart_flag: false,
      guideline_piechart_flag: false,
      clinicaltrial_piechart_flag: false,
    });
  }
  AdboardPiechartFun = async () => {
    await this.setState({
      home_piechart_flag: false,
      event_piechart_flag: false,
      publication_piechart_flag: false,
      ad_board_piechart_flag: true,
      guideline_piechart_flag: false,
      clinicaltrial_piechart_flag: false,
    });
  }
  GuidelinePiechartFun = async () => {
    await this.setState({
      home_piechart_flag: false,
      event_piechart_flag: false,
      publication_piechart_flag: false,
      ad_board_piechart_flag: false,
      guideline_piechart_flag: true,
      clinicaltrial_piechart_flag: false,
    });
  }
  ClinicaltrialPiechartFun = async () => {
    await this.setState({
      home_piechart_flag: false,
      event_piechart_flag: false,
      publication_piechart_flag: false,
      ad_board_piechart_flag: false,
      guideline_piechart_flag: false,
      clinicaltrial_piechart_flag: true,
    });
  }

  // Function for drawer side selection
  PersonalInformationFun = async () => {
    await this.setState({
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      SummaryFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      PersonalInformationFlag: true,
    })
  }

  BiographyFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      SummaryFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      BiographyFlag: true,
    })
  }

  ProfessionalMembershipFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      EducationFlag: false,
      SummaryFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      ProfessionalMembershipFlag: true,
    })
  }

  EducationFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      SummaryFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      EducationFlag: true,
    })
  }

  SummaryFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      SummaryFlag: true,
    })
  }

  AdvisoryBoardFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      SummaryFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      AdvisoryBoardFlag: true,
    })
  }

  AwardsAndHonoursFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      SummaryFlag: false,
      AdvisoryBoardFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      AwardsAndHonoursFlag: true,
    })
  }

  BooksAndMonographFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      SummaryFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      BooksAndMonographFlag: true,
    })
  }

  CommiteesFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      SummaryFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      CommiteesFlag: true,
    })
  }

  ClinicalTrialDetailsFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      SummaryFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      ClinicalTrialDetailsFlag: true,
    })
  }

  EventsDetailsFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      SummaryFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      EventsDetailsFlag: true,
    })
  }

  GrantsFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      SummaryFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      GrantsFlag: true,
    })
  }

  GuidelinesFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      SummaryFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      GuidelinesFlag: true,
    })
  }

  HospitalAffiliationsFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      SummaryFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      HospitalAffiliationsFlag: true,
    })
  }

  InteractionsFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      SummaryFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      InteractionsFlag: true,
    })
  }

  PressItemFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      SummaryFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      PressItemFlag: true,
    })
  }

  PresentationsFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      SummaryFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      PresentationsFlag: true,
    })
  }

  PublicationDetailsFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      SummaryFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      PublicationDetailsFlag: true,
    })
  }

  SunshinePaymentFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      SummaryFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      ProcedurePotentialFlag: false,
      SunshinePaymentFlag: true,
    })
  }

  ProcedurePotentialFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      SummaryFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: true,
    })
  }

  componentDidMount() {
    let getSession_Kol_Id = sessionStorage.getItem('kol_id')
    if (getSession_Kol_Id !== null) {
      this.setState({
        kol_id: JSON.parse(getSession_Kol_Id),
      })
    }

    let getSession_Administrator_Name = sessionStorage.getItem('administrator_name')
    if (getSession_Administrator_Name !== null) {
      this.setState({
        administrator_name: getSession_Administrator_Name,
      })
    }

    console.log("kol_id", this.state.kol_id);
    console.log("getSession_Kol_Id", getSession_Kol_Id);

    this.setState({
      ShowBar: true,
    })
    const options = {
      method: 'POST',
    }
    const url = global.websiteUrl+'/service/KOL_Dashboard/get_FetchKOLInfo';
    let formData = new FormData();
    formData.append('kol_id', JSON.parse(getSession_Kol_Id));
    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }

    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var lgdata = response.data;
        console.log("lgdata=====", JSON.stringify(lgdata));
        var KOL_Personal_Info = response.data.KOL_Personal_Info;
        console.log("KOL_Personal_Info==========", JSON.stringify(KOL_Personal_Info));
        var KOL_Work_Details = response.data.KOL_Work_Details;
        console.log("KOL_Work_Details==========", JSON.stringify(KOL_Work_Details));
        this.setState({
          PersonalInformation: KOL_Personal_Info,
          WorkDetails: response.data.KOL_Work_Details,
          Biography: response.data.KOL_Biography,
          ProfessionalMemberships: response.data.KOL_Professional_Memberships,
          Education: response.data.KOL_Education,
          AdvisoryBoard: response.data.KOL_Advisory_Board,
          AwardsHonours: response.data.KOL_Awards_Honours,
          BooksMonographs: response.data.KOL_Books_Monographs,
          Commitees: response.data.KOL_Commitees,
          ClinicalTrialDetails: response.data.KOL_Clinical_Trial_Details,
          EventsDetails: response.data.KOL_Events_Details,
          Grants: response.data.KOL_Grants,
          Guidelines: response.data.KOL_Guidelines,
          HospitalAffiliations: response.data.KOL_Hospital_Affiliations,
          Interactions: response.data.KOL_Interactions,
          PressItem: response.data.KOL_Press_Item,
          Presentation: response.data.KOL_Presentations,
          PublicationDetails: response.data.KOL_Publication_Details,
          SunshinePayment: response.data.KOL_Sunshine_Payment,

          profile_photo_for_edit: response.data.KOL_Profile_Photo,
          BiographyResume_edit: response.data.KOL_Resume,
          BiographyResumeFileName: response.data.KOL_Resume,

          profile_photo: response.data.KOL_Profile_Photo,
          // BiographyResume: response.data.KOL_Resume, 

        })

        this.setState({
          ShowBar: false,
          // ContractFileName: ContractFileNameapi
        })
        console.log("PersonalInformation", this.state.PersonalInformation);
        console.log("profile_photo_for_edit", this.state.profile_photo_for_edit);
        console.log("Education", this.state.Education);
        console.log("WorkDetails===============", this.state.WorkDetails);

        // to convert country id to string 
        if (KOL_Personal_Info.country_id) {
          if (KOL_Personal_Info.country_id !== '0') {
            const options = {
              method: 'POST',
            }
            fetch(global.websiteUrl+'/service/Location/get_CountryList', options)
              .then((Response) => Response.json())
              .then((findresponse) => {
                console.log(findresponse)
                var testDataCountryList = findresponse.Country_Details;
                console.log("testDataCountryList", JSON.stringify(testDataCountryList));


                testDataCountryList.forEach(element => {
                  if (element.id == this.state.PersonalInformation.country_id) {
                    let { PersonalInformation } = this.state;
                    PersonalInformation.country_id = element.country_name;
                    this.setState({ PersonalInformation });
                  }
                })
              })
          }
        }

        if (KOL_Personal_Info.country_id) {
          if (KOL_Personal_Info.country_id !== '0') {
            const url = global.websiteUrl+'/service/Location/get_StateList';
            let formData = new FormData();
            formData.append('country_id', this.state.PersonalInformation.country_id);

            const config = {
              //   headers: { 'content-type': 'multipart/form-data' }
            }
            axios.post(url, formData, config)
              .then(response => {
                console.log(response);
                var testStateData = response.data.Country_Details;
                console.log("testStateData", JSON.stringify(testStateData));
                this.setState({
                  StateData: response.data.Country_Details,
                })
                console.log("StateData======", this.state.StateData)

                testStateData.forEach(element => {
                  if (element.id == this.state.PersonalInformation.state_id) {
                    let { PersonalInformation } = this.state;
                    PersonalInformation.state_id = element.state_name;
                    this.setState({ PersonalInformation });
                  }
                })
              })
              .catch(error => {
                console.log(error);
              });
          }
        }

        if (KOL_Personal_Info.country_id) {
          if (KOL_Personal_Info.country_id !== '0') {
            const url = global.websiteUrl+'/service/Location/get_CityList';
            let formData = new FormData();
            formData.append('country_id', this.state.PersonalInformation.country_id);

            const config = {
              //   headers: { 'content-type': 'multipart/form-data' }
            }
            axios.post(url, formData, config)
              .then(response => {
                console.log(response);
                var testCityData = response.data.Country_Details;
                console.log("testCityData", JSON.stringify(testCityData));
                this.setState({
                  CityData: response.data.Country_Details,
                })
                console.log("CityData======", this.state.CityData)
              })
              .catch(error => {
                console.log(error);
              });


          }
        }

        if (KOL_Personal_Info.specialty_id) {
          fetch(global.websiteUrl+'/service/Speciality/get_SpecialityList', options)
            .then((Response) => Response.json())
            .then((findresponse) => {
              console.log(findresponse)
              var testDataSpeciality = findresponse.data;
              console.log("testDataSpeciality", JSON.stringify(testDataSpeciality));

              testDataSpeciality.forEach(element => {
                if (element.id == this.state.PersonalInformation.specialty_id) {
                  let { PersonalInformation } = this.state;
                  PersonalInformation.specialty_id = element.speciality_type;
                  this.setState({ PersonalInformation });
                }
              })
            })
        }

        // if(KOL_Personal_Info.specialty_id){
        //     console.log("in Procedure list");
        //     console.log("speciality_type_id",this.state.PersonalInformation.specialty_id);           
        //     const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
        //     let formData = new FormData();
        //     formData.append('speciality_type_id',this.state.PersonalInformation.specialty_id);
        //     formData.append('search_type',"Procedure");

        //     const config = {     
        //     //   headers: { 'content-type': 'multipart/form-data' }
        //     }
        //     axios.post(url, formData, config)
        //         .then(response => {
        //             console.log(response);
        //             var proceduredata= response.data;
        //             console.log("proceduredata",JSON.stringify(proceduredata));
        //             this.setState({
        //             ProcedureData : response.data.data,
        //             showProcedureSubSpecialityDropdown: false,
        //             showProcedureSubSpecialityDropdownAdvBoard: false,
        //             }) 
        //             console.log("ProcedureData",this.state.ProcedureData)
        //             this.setState({
        //             showProcedureSubSpecialityDropdown: true,
        //             showProcedureSubSpecialityDropdownAdvBoard: true,
        //             }) 

        //             var proceduredataFromJSON2 = [];
        //             var proceduredata= response.data.data;
        //             var proceduredataFromJSON = this.state.PersonalInformation.procedure_type_id.split(',');
        //             if(proceduredata){
        //                 proceduredata.forEach(element => {
        //                    proceduredataFromJSON.forEach(elementProcedure => {
        //                         if( element.id == elementProcedure ){
        //                             console.log("same option is here Team Members",element);
        //                             var joined = proceduredataFromJSON2.concat(element.procedure_type);
        //                             proceduredataFromJSON2 = joined;
        //                             console.log("proceduredataFromJSON2",proceduredataFromJSON2);
        //                         }
        //                     })       
        //                 })
        //             }
        //             let { PersonalInformation } = this.state;
        //             PersonalInformation.procedure_type_id = proceduredataFromJSON2.toString();
        //             this.setState({ PersonalInformation });

        //         })
        //         .catch(error => {
        //             console.log(error);
        //         }); 
        // }

        // if(KOL_Personal_Info.specialty_id){
        //     console.log("in sub speciality list");
        //     const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
        //     let formData = new FormData();
        //     formData.append('speciality_type_id',this.state.PersonalInformation.specialty_id);
        //     formData.append('search_type',"Sub Speciality");

        //     const config = {     
        //     //   headers: { 'content-type': 'multipart/form-data' }
        //     }
        //     axios.post(url, formData, config)
        //         .then(response => {
        //             console.log(response);
        //             var SubSpecialitydata= response.data;
        //             console.log("SubSpecialitydata",JSON.stringify(SubSpecialitydata));
        //             this.setState({
        //             SubSpecialitydata : response.data.data,
        //             showProcedureSubSpecialityDropdown: false,
        //             }) 
        //             console.log("SubSpecialitydata",this.state.SubSpecialitydata)
        //             this.setState({
        //             showProcedureSubSpecialityDropdown: true,
        //             }) 

        //             var subSpecialitydataFromJSON2 = [];
        //             var subSpecialitydata= response.data.data;
        //             var subSpecialitydataFromJSON = this.state.PersonalInformation.sub_specialty_id.split(',');
        //             if(subSpecialitydata){
        //                 subSpecialitydata.forEach(element => {
        //                    subSpecialitydataFromJSON.forEach(elementSubSpeciality => {
        //                         if( element.id == elementSubSpeciality ){
        //                             console.log("same option is here Team Members",element);
        //                             var joined = subSpecialitydataFromJSON2.concat(element.sub_speciality_type);
        //                             subSpecialitydataFromJSON2 = joined;
        //                             console.log("subSpecialitydataFromJSON2",subSpecialitydataFromJSON2);
        //                         }
        //                     })       
        //                 })
        //             }
        //             let { PersonalInformation } = this.state;
        //             PersonalInformation.sub_specialty_id = subSpecialitydataFromJSON2.toString();
        //             this.setState({ PersonalInformation });
        //             // this.setState({
        //             //     TeamMembers: TeamMembers3.toString(),
        //             // })

        //         })
        //         .catch(error => {
        //             console.log(error);
        //         }); 
        // }
      })

    const url2 = global.websiteUrl+'/service/KOL/report_by_kol_id';
    let formData2 = new FormData();
    formData2.append('kol_unique_id', JSON.parse(getSession_Kol_Id));
    formData2.append('for_filter', false);
    formData2.append('filtered_kol_id ', []);
    axios.post(url2, formData2, config)
      .then(response => {
        console.log('response of report api', response.data);
        // activity index chart and kol score rating data
        this.state.event_kol_rating = response.data.KolRatingScore.kol_event_details;
        this.state.publication_kol_rating = response.data.KolRatingScore.kol_publication_details;
        this.state.ad_board_kol_rating = response.data.KolRatingScore.kol_advisory_board;
        this.state.guideline_kol_rating = response.data.KolRatingScore.kol_guidlines;
        this.state.clinicaltrial_kol_rating = response.data.KolRatingScore.kol_clinical_trial_details;

        // piechart data
        this.state.event_piechart = response.data.PieChart.kol_event_details;
        this.state.publication_piechart = response.data.PieChart.kol_publication_details;
        this.state.ad_board_piechart = response.data.PieChart.kol_advisory_board;
        this.state.guideline_piechart = response.data.PieChart.kol_guidlines;
        this.state.clinicaltrial_piechart = response.data.PieChart.kol_clinical_trial_details;

        console.log('this.state.event_piechart', this.state.event_piechart);
        console.log('this.state.publication_piechart', this.state.publication_piechart);
        console.log('this.state.ad_board_piechart', this.state.ad_board_piechart);
        console.log('this.state.guideline_piechart', this.state.guideline_piechart);
        console.log('this.state.clinicaltrial_piechart', this.state.clinicaltrial_piechart);


        // kol peer group standing data
        this.state.event_peer_group_standing = response.data.Kol_peer_group_standing.kol_event_details;
        this.state.publication_peer_group_standing = response.data.Kol_peer_group_standing.kol_publication_details;
        this.state.ad_board_peer_group_standing = response.data.Kol_peer_group_standing.kol_adboard_details;
        this.state.guideline_peer_group_standing = response.data.Kol_peer_group_standing.kol_guideline_details;
        this.state.clinicaltrial_peer_group_standing = response.data.Kol_peer_group_standing.kol_clinical_trial_details;

        // engagement summary data (apex chart)
        this.state.engagementSummary = response.data.EngagementSummary;

        // engagement coverage overall data
        this.state.engagementCoverage_Global = response.data.EngagementCoverageOverall.Global;
        this.state.engagementCoverage_Regional = response.data.EngagementCoverageOverall.Regional;
        this.state.engagementCoverage_National = response.data.EngagementCoverageOverall.National;
        this.state.engagementCoverage_Local = response.data.EngagementCoverageOverall.Local;

        console.log('this.state.engagementCoverage_Global', this.state.engagementCoverage_Global);
        console.log('this.state.engagementCoverage_Regional', this.state.engagementCoverage_Regional);
        console.log('this.state.engagementCoverage_National', this.state.engagementCoverage_National);
        console.log('this.state.engagementCoverage_Local', this.state.engagementCoverage_Local);

        // Kol peer group standing geographic scope of speaking engagement
        this.state.EventDetailsGeographicScope_Global = response.data.EventDetailsGeographicScope.Global;
        this.state.EventDetailsGeographicScope_Regional = response.data.EventDetailsGeographicScope.Regional;
        this.state.EventDetailsGeographicScope_National = response.data.EventDetailsGeographicScope.National;
        this.state.EventDetailsGeographicScope_Local = response.data.EventDetailsGeographicScope.Local;

        // Kol peer group standing role prominence of speaking engagement
        this.state.EventDetailsRoleProminence_KeynoteSpeaker = response.data.EventDetailsRoleProminence.KeynoteSpeaker;
        this.state.EventDetailsRoleProminence_Regular = response.data.EventDetailsRoleProminence.Regular;
        this.state.EventDetailsRoleProminence_Chairperson = response.data.EventDetailsRoleProminence.Chairperson;

        // Kol peer group standing geographic scope of Publication
        this.state.PublicationDetailsGeographicScope_Global = response.data.PublicationDetailsGeographicScope.Global;
        this.state.PublicationDetailsGeographicScope_Regional = response.data.PublicationDetailsGeographicScope.Regional;
        this.state.PublicationDetailsGeographicScope_Local = response.data.PublicationDetailsGeographicScope.Local;

        // Kol peer group standing role prominence of Publication
        this.state.PublicationDetailsRoleProminence_First_author = response.data.PublicationDetailsRoleProminence.First_author;
        this.state.PublicationDetailsRoleProminence_Last_author = response.data.PublicationDetailsRoleProminence.Last_author;
        this.state.PublicationDetailsRoleProminence_Second_author = response.data.PublicationDetailsRoleProminence.Second_author;

        //kol peer group standing geographic scope of guideline
        this.state.GuidelineDetailsGeographicScope_Global = response.data.GuidelineDetailsGeographicScope.Global;
        this.state.GuidelineDetailsGeographicScope_Regional = response.data.GuidelineDetailsGeographicScope.Regional;
        this.state.GuidelineDetailsGeographicScope_National = response.data.GuidelineDetailsGeographicScope.National;
        this.state.GuidelineDetailsGeographicScope_Local = response.data.GuidelineDetailsGeographicScope.Local;

        // kol peer group standing geographic scope advisory board
        this.state.AdvisoryBoardGeographicScope_Global = response.data.AdvisoryBoardGeographicScope.Global;
        this.state.AdvisoryBoardGeographicScope_Regional = response.data.AdvisoryBoardGeographicScope.Regional;
        this.state.AdvisoryBoardGeographicScope_Local = response.data.AdvisoryBoardGeographicScope.Local;

        // Kol peer group standing role prominence of advisory board
        this.state.AdvisoryBoardRoleProminence_Board_Member = response.data.AdvisoryBoardRoleProminence.Board_Member;
        this.state.AdvisoryBoardRoleProminence_Commitee_Member = response.data.AdvisoryBoardRoleProminence.Commitee_Member;

        // kol peer group standing geographic scope of clinical trial
        this.state.ClinicalTrialsGeographicScope_Global = response.data.ClinicalTrialsGeographicScope.Global;
        this.state.ClinicalTrialsGeographicScope_Regional = response.data.ClinicalTrialsGeographicScope.Regional;
        this.state.ClinicalTrialsGeographicScope_National = response.data.ClinicalTrialsGeographicScope.National;
        this.state.ClinicalTrialsGeographicScope_Local = response.data.ClinicalTrialsGeographicScope.Local;

        //kol peer group standing clinical trails of clinical trials
        this.state.ClinicalTrialsRoleProminence_Interventional = response.data.ClinicalTrialsRoleProminence.Interventional;
        this.state.ClinicalTrialsRoleProminence_observational = response.data.ClinicalTrialsRoleProminence.observational;

        //for testing purpose only
        // this.setState({
        //   event_piechart: {
        //     Regular: 1,
        //     KeynoteSpeaker: 2,
        //     Chairperson: 3,
        //     Global: 4,
        //     Regional: 2,
        //     National: 3,
        //     Local: 1,
        //   },
        //   publication_piechart: {
        //     FirstAuthor: 1,
        //     SecondAuthor: 2,
        //     LastAuthor: 3,
        //     Global: 1,
        //     Regional: 2,
        //     Local: 3,
        //   },
        //   ad_board_piechart: {
        //     AdvisoryCommittee: 1,
        //     BoardMember: 2,
        //     Global: 1,
        //     Local: 2,
        //     Regional: 3,
        //   },
        //   clinicaltrial_piechart: {
        //     Global: 1,
        //     Interventional: 4,
        //     Local: 2,
        //     National: 3,
        //     Observational: 5,
        //     Regional: 6,
        //   },
        //   guideline_piechart: {
        //     Global: 5,
        //     Local: 2,
        //     National: 3,
        //     Regional: 1,
        //   }
        // }); //for tesing purpose only
      })
      .catch(error => {
        console.log(error);
      });

      const url3 = global.websiteUrl+'/service/KOL/get_score';
      let formData3 = new FormData();
      // formData3.append('kol_unique_id', JSON.parse(getSession_Kol_Id));
      axios.post(url3, formData3, config)
        .then(response => {
          console.log('response of get_score api', response.data);

      })
      .catch(error => {
        console.log(error);
      });
  }

  // rediect funcs
  BackToDashboard() {
    this.setState({ RedirectFlag: true })
  }

  render() {
    console.log("this.state.event_peer_group_standing",this.state.event_peer_group_standing)
    if (this.state.RedirectFlag) {
      return (<Redirect to={{
        pathname: '/AdministratorViewKolList',
      }} />)
    }

    let dataProfessionalMembership = this.state.ProfessionalMemberships.map((dataProfessionalMembership) => {
      return (

        <tr key={dataProfessionalMembership.organization}>
          <td>{dataProfessionalMembership.organization}</td>
          <td>{dataProfessionalMembership.organization_type}</td>
          <td>{dataProfessionalMembership.source_link}</td>
          <td>{dataProfessionalMembership.location}</td>
          <td>{dataProfessionalMembership.position}</td>
          <td>{dataProfessionalMembership.tenure}</td>
          <td>{dataProfessionalMembership.organization_website}</td>
        </tr>

      )
    });
    return (
      <body>
        <div>
          <div>
            <div>
              <Header ClientName={this.state.administrator_name} />
              <div className="container">
                <div className="content-wrapper">
                  <div className="screen-headingbox">
                    <div className="back-section">
                      <a onClick={this.BackToDashboard.bind(this)}>
                        <img src={require('../Assets/Images/back-arrow.svg')} />
                        Back
                      </a>
                    </div>
                    <h2>Profile Details </h2>
                  </div>

                  <div className="row">
                    <div className="col-md-3 col-sm-12 col-xs-12 profile-box">
                      <div className="sidebar sticky">
                        <ul className="cetas-leftnav">
                          <li>
                            <a onClick={this.PersonalInformationFun}>
                              {
                                (this.state.PersonalInformationFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Personal Information</text>
                                  </div>

                                ) : (
                                  <div>
                                    <text>Personal Information</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.BiographyFun}>
                              {
                                (this.state.BiographyFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Biography</text>
                                  </div>

                                ) : (
                                  <div><text>Biography</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li className="submenu-link">
                            <a onClick={this.ProfessionalMembershipFun}>
                              {
                                (this.state.ProfessionalMembershipFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Professional Membership</text>
                                  </div>
                                ) : (
                                  <div>
                                    <text>Professional Membership</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.EducationFun}>
                              {
                                (this.state.EducationFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Education</text>
                                  </div>
                                ) : (
                                  <div>
                                    <text>Education</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          {
                            (this.state.PersonalInformation.kol_type == "KOL Community" )?
                            (      
                              <li>
                                <a onClick={this.SummaryFun}>
                                  {
                                    (this.state.SummaryFlag) ? (
                                      <div>
                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                          Summary</text>
                                      </div>
                                    ) : (
                                      <div>
                                        <text>Summary</text>
                                      </div>
                                    )
                                  }
                                </a>
                              </li>
                            ):
                            (<div></div>)
                          }    
                          <li>
                            <a onClick={this.AdvisoryBoardFun}>
                              {
                                (this.state.AdvisoryBoardFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Advisory Board</text>
                                  </div>
                                ) : (
                                  <div>
                                    <text>Advisory Board</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.AwardsAndHonoursFun}>
                              {
                                (this.state.AwardsAndHonoursFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Awards & Honours</text>
                                  </div>
                                ) : (
                                  <div>
                                    <text>Awards & Honours</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.BooksAndMonographFun}>
                              {
                                (this.state.BooksAndMonographFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Books & Monographs</text>
                                  </div>
                                ) : (
                                  <div>
                                    <text>Books & Monographs</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.CommiteesFun}>
                              {
                                (this.state.CommiteesFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Commitees</text>
                                  </div>
                                ) : (
                                  <div>
                                    <text>Commitees</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.ClinicalTrialDetailsFun}>
                              {
                                (this.state.ClinicalTrialDetailsFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Clinical Trials</text>
                                  </div>
                                ) : (
                                  <div>
                                    <text>Clinical Trials</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.EventsDetailsFun}>
                              {
                                (this.state.EventsDetailsFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Events</text>
                                  </div>
                                ) : (
                                  <div>
                                    <text>Events</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.GrantsFun}>
                              {
                                (this.state.GrantsFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Grants</text>
                                  </div>
                                ) : (
                                  <div>
                                    <text>Grants</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.GuidelinesFun}>
                              {
                                (this.state.GuidelinesFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Guidelines</text>
                                  </div>
                                ) : (
                                  <div>
                                    <text>Guidelines</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.HospitalAffiliationsFun}>
                              {
                                (this.state.HospitalAffiliationsFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Hospital Affiliations</text>
                                  </div>
                                ) : (
                                  <div>
                                    <text>Hospital Affiliations</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.InteractionsFun}>
                              {
                                (this.state.InteractionsFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Interactions</text>
                                  </div>
                                ) : (
                                  <div>
                                    <text>Interactions</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.PressItemFun}>
                              {
                                (this.state.PressItemFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Press Item</text>
                                  </div>
                                ) : (
                                  <div>
                                    <text>Press Item</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.PresentationsFun}>
                              {
                                (this.state.PresentationsFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Presentations</text>
                                  </div>
                                ) : (
                                  <div>
                                    <text>Presentations</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.PublicationDetailsFun}>
                              {
                                (this.state.PublicationDetailsFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Publications</text>
                                  </div>
                                ) : (
                                  <div>
                                    <text>Publications</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.SunshinePaymentFun}>
                              {
                                (this.state.SunshinePaymentFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Sunshine Payment</text>
                                  </div>
                                ) : (
                                  <div>
                                    <text>Sunshine Payment</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          {/* <li>
                                <a onClick={this.ProcedurePotentialFun}>
                                    {
                                        (this.state.ProcedurePotentialFlag) ? (
                                            <div>
                                            <text style={{color:'#A90007', fontWeight:'bold'}}>
                                                Procedure Potential</text>
                                            </div>
                                        )  : (
                                            <div>
                                            <text>Procedure Potential</text>
                                            </div>
                                        )
                                    }
                                </a>
                            </li> */}
                        </ul>
                        <div style={{ marginTop: '10%' }}></div>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="scroll-box">
                        <div className="doctor-details sticky">
                          <div className="profile-section">
                            <div className="img-box">
                              {/* <img src="img/doctor.jpg" className="pro-image rounded-circle" /> */}
                              <img
                                src={this.state.profile_photo_for_edit} />
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="pro-description">
                                  <p className="float-left">
                                    <span className="bold">{this.state.PersonalInformation.salutation} 
                                      {this.state.PersonalInformation.doctor_full_name} &nbsp;
                                      {this.state.PersonalInformation.suffix}
                                    </span><br />
                                    <span className="bold">Classification = Rising Star</span>
                                    {/* Director - Cardiovascular clinical health research center */}
                                  </p>
                                </div>
                                {
                                  ( this.state.PersonalInformation.kol_type == "KOL Community"  )?
                                  (
                                    <div className="pro-description float-right" style={{ marginTop: '0px !important' }}>
                                      <p className="overall-rating float-left" style={{ textAlign: 'center', marginTop: '-30px !important', padding: '10px !important' }}>
                                        <span className="bold" >Overall</span><br />
                                        <span className="bold" >Rating</span><br />
                                        <span className="bold" >{this.state.PersonalInformation.aggregate_score}</span>
                                      </p>
                                    </div>
                                  ):
                                  (<div></div>)
                                }
                                
                              </div>
                            </div>
                          </div>
                        </div>
                        <a id="overall-details"></a>
                        {
                          (this.state.PersonalInformationFlag == true) ? (
                            <section className="mt-50 " >
                              <h3 className="mt-30">Overall Details</h3>
                              <div className="scrollable-box">
                                <div className="pro-description">
                                  <p>
                                    <span className="bold">Degree: </span><br />
                                    {this.state.PersonalInformation.qualification}
                                  </p>
                                </div>
                                <div className="pro-description">
                                  <p>
                                    <span className="bold">Experience: </span><br />
                                    {this.state.PersonalInformation.experience}
                                  </p>
                                </div>
                                <div className="pro-description">
                                  <p>
                                    <span className="bold">Alma  Mater: </span><br />
                                    {this.state.PersonalInformation.medical_schools}
                                  </p>
                                </div>
                                <div className="pro-description">
                                  <p>
                                    <span className="bold">Medical Registration No: </span><br />
                                    {this.state.PersonalInformation.medical_registration_no}
                                  </p>
                                </div>
                                <div className="pro-description">
                                  <p>
                                    <span className="bold">NPI No: </span><br />
                                    {this.state.PersonalInformation.NPI_no}
                                  </p>
                                </div>
                                <div className="pro-description">
                                  <p>
                                    <span className="bold">Speciality Type</span><br />
                                    {this.state.PersonalInformation.specialty_id}
                                  </p>
                                </div>
                                <div className="pro-description">
                                  <p>
                                    <span className="bold">Sub Speciality Type</span><br />
                                    {this.state.PersonalInformation.sub_specialty_id}
                                  </p>
                                </div>
                                <div className="pro-description">
                                  <p>
                                    <span className="bold">Procedure Type</span><br />
                                    {this.state.PersonalInformation.procedure_type_id}
                                  </p>
                                </div>
                                <div className="pro-description">
                                  <p>
                                    <span className="bold">Contact Details: </span><br />
                                    <img src={require('../Assets/Images/phone.svg')} /> &nbsp; &nbsp;
                                    {this.state.PersonalInformation.doctor_contact_no} <br />
                                    <img src={require('../Assets/Images/fax.svg')} /> &nbsp; &nbsp;
                                    {this.state.PersonalInformation.fax} <br />
                                    <img src={require('../Assets/Images/message.svg')} /> &nbsp; &nbsp;
                                    {this.state.PersonalInformation.doctor_email}
                                  </p>
                                </div>
                                <div className="pro-description">
                                  <p>
                                    <span className="bold">LinkedIn Profile</span><br />
                                    {/* {this.state.PersonalInformation.linked_in_profile} */}
                                    <a href={this.state.PersonalInformation.linked_in_profile} target="_blank">
                                      <text>{this.state.PersonalInformation.linked_in_profile}</text>
                                    </a>
                                  </p>
                                </div>
                                <div className="pro-description">
                                  <p>
                                    <span className="bold">Twitter Handle</span><br />
                                    <a href={this.state.PersonalInformation.twitter_handle} target="_blank">
                                      <text>{this.state.PersonalInformation.twitter_handle}</text>
                                    </a>
                                  </p>
                                </div>
                                <div className="pro-description">
                                  <p>
                                    <span className="bold">Assistance Contact No</span> <br />
                                    <img src={require('../Assets/Images/phone.svg')} />
                                    {this.state.PersonalInformation.assistance_contact_no}
                                  </p>
                                </div>
                                <div className="pro-description">
                                  <p>
                                    <span className="bold">Assistance Email</span> <br />
                                    {this.state.PersonalInformation.assistance_email}
                                  </p>
                                </div>
                                <div className="pro-description">
                                  <p>
                                    <span className="bold">Website</span> <br />
                                    <a href={this.state.PersonalInformation.website} target="_blank">
                                      <text>{this.state.PersonalInformation.website}</text>
                                    </a>
                                  </p>
                                </div>
                                <div className="pro-description">
                                  <p>
                                    <span className="bold">Primary Work Address</span> <br />
                                    {this.state.PersonalInformation.address_1}
                                  </p>
                                </div>
                                <div className="pro-description">
                                  <p>
                                    <span className="bold">Primary Work Address 2</span> <br />
                                    {this.state.PersonalInformation.address_2}
                                  </p>
                                </div>
                                <div className="pro-description">
                                  <p>
                                    <span className="bold">Country</span> <br />
                                    {this.state.PersonalInformation.country_id}
                                  </p>
                                </div>
                                <div className="pro-description">
                                  <p>
                                    <span className="bold">State</span> <br />
                                    {this.state.PersonalInformation.state_id}
                                  </p>
                                </div>
                                <div className="pro-description">
                                  <p>
                                    <span className="bold">Procedure Potential</span> <br />
                                    {this.state.PersonalInformation.procedure_potential}
                                  </p>
                                </div>
                                <div className="pro-description">
                                  <p>
                                    <span className="bold">Zip Code</span> <br />
                                    {this.state.PersonalInformation.zip_code}
                                  </p>
                                </div>
                                <div className="pro-description">
                                  <p>
                                    <span className="bold">Contract</span> <br />
                                    {this.state.PersonalInformation.contract}
                                    <br />
                                    <a href={this.state.PersonalInformation.contract_file} target="_blank">
                                      <text>{this.state.PersonalInformation.contract_file}</text>
                                    </a>
                                  </p>

                                </div>
                              </div>

                              {/* Work Details */}
                              {/* {
                            (this.state.EducationFlag == true ) ? ( */}
                              <div className="emplist-box" style={{ marginTop: '5%', marginBottom: '5%' }}>
                                <div className="employee-actions">
                                  <h3 className="mt-30">Work Details</h3>
                                  <div className="table-infobox">
                                    <p className="list-count" style={{ color: 'white' }}>
                                      {this.state.WorkDetails.length}
                                    </p>
                                  </div>
                                </div>
                                <div style={{ width: '100%' }}>
                                  <BootstrapTable
                                    keyField='id'
                                    noDataIndication="Table is Empty"
                                    data={this.state.WorkDetails}
                                    columns={this.state.columnsWorkDetails}
                                    filter={filterFactory()}
                                    striped
                                    hover
                                    condensed
                                  />
                                </div>
                              </div>
                              {/* ) : (
                                <div>
                                </div>
                            )
                        } */}
                              <div style={{ marginTop: '10%' }}></div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        <a id="advisory-board"></a>
                        {
                          (this.state.BiographyFlag == true) ? (
                            <section className="mt-50 " >
                              <h3 className="mt-30">Biography Details</h3>
                              <div className="scrollable-box">
                                <div className="pro-description">
                                  <p>
                                    <span className="bold">Citizenship: </span><br />
                                    {this.state.Biography.citizenship}
                                  </p>
                                </div>
                                <div className="pro-description">
                                  <p>
                                    <span className="bold">Language Spoken: </span><br />
                                    {this.state.Biography.language_spoken}
                                  </p>
                                </div>
                                <div className="pro-description">
                                  <p>
                                    <span className="bold">Linkedin Followers: </span><br />
                                    {this.state.Biography.linkedin_followers}
                                  </p>
                                </div>
                                <div className="pro-description">
                                  <p>
                                    <span className="bold">Twitter Followers: </span><br />
                                    {this.state.Biography.twitter_followers}
                                  </p>
                                </div>
                                <div className="pro-description">
                                  <p>
                                    <span className="bold">Biography: </span><br />
                                    {this.state.Biography.biography}
                                  </p>
                                </div>
                                <div className="pro-description">
                                  <p>
                                    <span className="bold">Areas Of Interest: </span><br />
                                    {this.state.Biography.areas_of_interest}
                                  </p>
                                </div>

                                {/* <div className="pro-description">
                                            <p>
                                                <span className="bold">Licensed Regions: </span><br/> 
                                                {this.state.Biography.licensed_regions}
                                            </p>
                                        </div>
                                         */}
                                {/* <div className="pro-description">
                                            <p>
                                                <span className="bold">Work Phone: </span><br/> 
                                                <img src={require('../Assets/Images/phone.svg')} />
                                                {this.state.Biography.work_phone}
                                            </p>
                                        </div> */}
                                {/* <div className="pro-description">
                                            <p>
                                                <span className="bold">Work Email: </span><br/> 
                                                {this.state.Biography.work_email}
                                            </p>
                                        </div> */}
                                <div className="pro-description">
                                  <p>
                                    <span className="bold">Resume: </span><br />
                                    <a
                                      href={this.state.Biography.resume} target="_blank">
                                      <text>{this.state.Biography.resume}</text>
                                    </a>
                                  </p>
                                </div>
                              </div>
                              <div style={{ marginTop: '10%' }}></div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* Professional membership */}
                        {
                          (this.state.ProfessionalMembershipFlag == true) ? (
                            <section className="mt-50 " >
                              <h3 className="mt-30">Professional Membership Details</h3>
                              <div className="scrollable-box">
                                {/* <div className="emplist-box-boot" style={{width:'200%'}}>  */}
                                <div style={{ width: '100%', overflowX: 'scroll' }}>
                                  <div style={{ width: '200%' }}>
                                    <BootstrapTable
                                      // responsive  
                                      // scrollX   
                                      // wrapperClasses="table-responsive"
                                      // wrapperClasses ="tablebootstrap" 
                                      // style={{width:'200%'}}   
                                      noDataIndication="Table is Empty"
                                      keyField='id'
                                      data={this.state.ProfessionalMemberships}
                                      columns={this.state.columnsProfessionalMemberships}
                                      filter={filterFactory()}
                                      striped
                                      hover
                                      condensed
                                    />
                                  </div>
                                </div>
                                {/* <table id="datatable" className="table table-striped table-bordered" 
                                        style={{overflow:'auto'}} scrollX 
                                    cellspacing="0" width="1000%">
                                        <thead>
                                                <tr>
                                                    <th className="WidthForTable">organization</th>
                                                    <th>organization_type</th>
                                                    <th>Asource_link</th>
                                                    <th>location</th>
                                                    <th>position</th>
                                                    <th>tenure</th>
                                                    <th>organization_website</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {dataProfessionalMembership}
                                            </tbody>
                                    </table> */}
                              </div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* Education */}
                        {
                          (this.state.EducationFlag == true) ? (
                            <section className="mt-50 " >
                              <h3 className="mt-30">Education Details</h3>
                              <div className="scrollable-box">
                                {/* <div className="emplist-box-boot" style={{width:'200%'}}>  */}
                                <div style={{ width: '100%', overflowX: 'scroll' }}>
                                  <div style={{ width: '210%' }}>
                                    <BootstrapTable
                                      noDataIndication="Table is Empty"
                                      keyField='id'
                                      data={this.state.Education}
                                      columns={this.state.columnsEducation}
                                      filter={filterFactory()}
                                      striped
                                      hover
                                      condensed
                                    />
                                  </div>
                                </div>
                              </div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* Summary */}
                        {
                          (this.state.SummaryFlag == true) ? (
                            <section className="mt-50 " >
                              {/* <div class="container"> */}
                              <div className="graph-tabs">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                  <li className="nav-item" role="presentation">
                                    <a onClick={this.HomePiechartFun}>
                                      {(this.state.home_piechart_flag == true) ? (
                                        <button className="nav-link" id="event-tab" data-bs-toggle="tab" data-bs-target="#event-tab" type="button"
                                          role="tab" aria-controls="event" style={{ color: '#A90007', fontWeight: 'bold' }}>
                                          Home
                                        </button>
                                      ) : (
                                        <button className="nav-link" id="event-tab" data-bs-toggle="tab" data-bs-target="#event-tab" type="button"
                                          role="tab" aria-controls="event">
                                          Home
                                        </button>
                                      )}
                                    </a>
                                  </li>
                                  <li className="nav-item" role="presentation">
                                    <a onClick={this.EventPiechartFun}>
                                      {(this.state.event_piechart_flag == true) ? (
                                        <button className="nav-link" id="event-tab" data-bs-toggle="tab" data-bs-target="#event-tab" type="button"
                                          role="tab" aria-controls="event" style={{ color: '#A90007', fontWeight: 'bold' }}>
                                          Events
                                        </button>
                                      ) : (
                                        <button className="nav-link" id="event-tab" data-bs-toggle="tab" data-bs-target="#event-tab" type="button"
                                          role="tab" aria-controls="event">
                                          Events
                                        </button>
                                      )}

                                    </a>
                                  </li>
                                  <li className="nav-item" role="presentation">
                                    <a onClick={this.PublicationPiechartFun}>
                                      {(this.state.publication_piechart_flag == true) ? (
                                        <button className="nav-link" id="publication-tab" data-bs-toggle="tab" data-bs-target="#publication-tab" type="button"
                                          role="tab" aria-controls="publication" style={{ color: '#A90007', fontWeight: 'bold' }}>
                                          Publications
                                        </button>
                                      ) : (
                                        <button className="nav-link" id="publication-tab" data-bs-toggle="tab" data-bs-target="#publication-tab" type="button"
                                          role="tab" aria-controls="publication">
                                          Publications
                                        </button>
                                      )}
                                    </a>
                                  </li>
                                  <li className="nav-item" role="presentation">
                                    <a onClick={this.ClinicaltrialPiechartFun}>
                                      {(this.state.clinicaltrial_piechart_flag == true) ? (
                                        <button className="nav-link" id="clinical-trial-tab" data-bs-toggle="tab" data-bs-target="#clinical-trial-tab" type="button"
                                          role="tab" aria-controls="clinical-trial" style={{ color: '#A90007', fontWeight: 'bold' }}>
                                          Clinical Trials
                                        </button>
                                      ) : (
                                        <button className="nav-link" id="clinical-trial-tab" data-bs-toggle="tab" data-bs-target="#clinical-trial-tab" type="button"
                                          role="tab" aria-controls="clinical-trial">
                                          Clinical Trials
                                        </button>
                                      )}
                                    </a>
                                  </li>
                                  <li className="nav-item" role="presentation">
                                    <a onClick={this.AdboardPiechartFun}>
                                      {(this.state.ad_board_piechart_flag == true) ? (
                                        <button className="nav-link" id="advisory-board-tab" data-bs-toggle="tab" data-bs-target="#advisory-board-tab" type="button"
                                          role="tab" aria-controls="advisory-board" style={{ color: '#A90007', fontWeight: 'bold' }}>
                                          Advisory Boards
                                        </button>
                                      ) : (
                                        <button className="nav-link" id="advisory-board-tab" data-bs-toggle="tab" data-bs-target="#advisory-board-tab" type="button"
                                          role="tab" aria-controls="advisory-board">
                                          Advisory Boards
                                        </button>
                                      )}
                                    </a>
                                  </li>
                                  <li className="nav-item" role="presentation">
                                    <a onClick={this.GuidelinePiechartFun}>
                                      {(this.state.guideline_piechart_flag == true) ? (
                                        <button className="nav-link" id="guideline-tab" data-bs-toggle="tab" data-bs-target="#guideline-tab" type="button"
                                          role="tab" aria-controls="guideline" style={{ color: '#A90007', fontWeight: 'bold' }}>
                                          Guidelines
                                        </button>
                                      ) : (
                                        <button className="nav-link" id="guideline-tab" data-bs-toggle="tab" data-bs-target="#guideline-tab" type="button"
                                          role="tab" aria-controls="guideline">
                                          Guidelines
                                        </button>
                                      )}
                                    </a>
                                  </li>
                                </ul>

                                {/* <div className="scrollable-box">
                                  <div style={{ display: 'flex', maxWidth: 900 }}> */}
                                {/* Graphs Home */}
                                {(this.state.home_piechart_flag == true) ? (
                                  <div>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <Chart
                                          width={'600px'}
                                          height={'500px'}
                                          chartType="BarChart"
                                          loader={<div className="scrollable-box">Loading Chart</div>}
                                          data={[
                                            ['Points', 'KOLs Score', 'Average Score'],
                                            ['Clinical Guideline', parseFloat(this.state.guideline_kol_rating.kol_guideline_score), parseFloat(this.state.guideline_kol_rating.avg_guideline_score)],
                                            ['Investigator', parseFloat(this.state.clinicaltrial_kol_rating.kol_clinical_trial_score), parseFloat(this.state.clinicaltrial_kol_rating.avg_clinical_trial_score)],
                                            ['Publications', parseFloat(this.state.publication_kol_rating.kol_publication_score), parseFloat(this.state.publication_kol_rating.avg_publication_score)],
                                            ['Ad Boards', parseFloat(this.state.ad_board_kol_rating.advisory_board_score), parseFloat(this.state.ad_board_kol_rating.avg_advisory_board_score)],
                                            ['Speakership', parseFloat(this.state.event_kol_rating.kol_event_score), parseFloat(this.state.event_kol_rating.avg_event_score)],
                                          ]}
                                          options={{
                                            title: 'Activity Index',
                                            chartArea: { width: '50%' },
                                            hAxis: {
                                              // title: 'Score',
                                              minValue: 0,
                                              ticks: [0, 1, 2, 3, 4, 5]
                                            },
                                            vAxis: {
                                              // title: '',
                                            },
                                          }}
                                          // For tests
                                          rootProps={{ 'data-testid': '1' }}
                                        />
                                      </div>
                                    </div>

                                    <hr></hr>

                                    <div className="row">
                                      <div className="col-md-6">
                                        <Chart
                                          width={'400px'}
                                          height={'300px'}
                                          chartType="BarChart"
                                          loader={<div>Loading Chart</div>}
                                          data={[
                                            ['-', 'KOL Rating Score', { role: 'annotation' }],
                                            ['Clinical Guideline', parseFloat(this.state.guideline_kol_rating.kol_guideline_score), this.state.guideline_kol_rating.kol_guideline_score],
                                            ['Investigator', parseFloat(this.state.clinicaltrial_kol_rating.kol_clinical_trial_score), this.state.clinicaltrial_kol_rating.kol_clinical_trial_score],
                                            ['Publications', parseFloat(this.state.publication_kol_rating.kol_publication_score), this.state.publication_kol_rating.kol_publication_score],
                                            ['Ad Boards', parseFloat(this.state.ad_board_kol_rating.advisory_board_score), this.state.ad_board_kol_rating.advisory_board_score],
                                            ['Speakership', parseFloat(this.state.event_kol_rating.kol_event_score), this.state.event_kol_rating.kol_event_score],
                                          ]}
                                          options={{
                                            title: 'Rating Score',
                                            chartArea: { width: '50%' },
                                            hAxis: {
                                              title: 'KOL Rating Score',
                                              minValue: 0,
                                              ticks: [0, 1, 2, 3, 4, 5],
                                            },
                                            vAxis: {
                                              // title: 'Progress Fields',
                                            },
                                          }}
                                          // For tests
                                          rootProps={{ 'data-testid': '3' }}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        {/* <Chart
                                          width={'400px'}
                                          height={'300px'}
                                          chartType="BarChart"
                                          loader={<div>Loading Chart</div>}
                                          data={[
                                            ['Points', 'KOLs Score', 'Average Score'],
                                            // ['City', '2010 Population', '2000 Population'],
                                            ['Clinical Guideline', parseFloat(this.state.guideline_peer_group_standing.percentile), 100 - parseFloat(this.state.guideline_peer_group_standing.percentile)],
                                            ['Investigator', parseFloat(this.state.clinicaltrial_peer_group_standing.percentile), 100 - parseFloat(this.state.clinicaltrial_peer_group_standing.percentile)],
                                            ['Publications', parseFloat(this.state.publication_peer_group_standing.percentile), 100 - parseFloat(this.state.publication_peer_group_standing.percentile)],
                                            ['Ad Boards', parseFloat(this.state.ad_board_peer_group_standing.percentile), 100 - parseFloat(this.state.ad_board_peer_group_standing.percentile)],
                                            ['Speakership', parseFloat(this.state.event_peer_group_standing.percentile), 100 - parseFloat(this.state.event_peer_group_standing.percentile)],
                                          ]}
                                          options={{
                                            title: 'KOL Peer Group Standing',
                                            chartArea: { width: '50%' },
                                            isStacked: 'percent',
                                            'tooltip': {
                                              trigger: 'none'
                                            },
                                            hAxis: {
                                              title: '0% = bottom 10% || 100% = top 10%',
                                              minValue: 0,
                                              ticks: ['0', '1'],
                                            },
                                            vAxis: {
                                              // title: 'City',
                                            },
                                          }}
                                          // For tests
                                          rootProps={{ 'data-testid': '3' }}
                                        /> */}

                                          <ApexChart Data={this.state.engagementSummary} />

                                      </div>
                                    </div>

                                    <hr></hr>

                                    <div className="row">
                                      <div className="col-md-6">
                                        <Chart
                                          width={'400px'}
                                          height={'300px'}
                                          chartType="BarChart"
                                          loader={<div>Loading Chart</div>}
                                          data={[
                                            ['-', '', ''],
                                            // ['City', '2010 Population', '2000 Population'],
                                            ['Global', parseFloat(this.state.engagementCoverage_Global.percentile), 100 - parseFloat(this.state.engagementCoverage_Global.percentile)],
                                            ['Regional', parseFloat(this.state.engagementCoverage_Regional.percentile), 100 - parseFloat(this.state.engagementCoverage_Regional.percentile)],
                                            ['National', parseFloat(this.state.engagementCoverage_National.percentile), 100 - parseFloat(this.state.engagementCoverage_National.percentile)],
                                            ['Local', parseFloat(this.state.engagementCoverage_Local.percentile), 100 - parseFloat(this.state.engagementCoverage_Local.percentile)],
                                          ]}
                                          options={{
                                            title: 'Engagement Coverage Overall',
                                            chartArea: { width: '50%' },
                                            isStacked: 'percent',
                                            'tooltip': {
                                              trigger: 'none'
                                            },
                                            hAxis: {
                                              title: '0% = bottom 10% || 100% = top 10%',
                                              minValue: 0,
                                              ticks: ['0', '1'],
                                            },
                                            vAxis: {
                                              // title: 'City',
                                            },
                                          }}
                                          // For tests
                                          rootProps={{ 'data-testid': '3' }}
                                        />
                                      </div>

                                    </div> 

                                  </div>
                                ) : (
                                  <div></div>
                                )
                                }

                                {/* Graphs Event */}
                                <div className="tab-content" id="myTabContent">
                                  <div className="tab-pane fade show active" id="event-tab" role="tabpanel" aria-labelledby="event-tab">
                                    {
                                      (this.state.event_piechart_flag == true) ? (
                                        <div>
                                          <div className="row">
                                            <div className="col-md-6">
                                              <Chart
                                                width={'500px'}
                                                height={'300px'}
                                                chartType="PieChart"
                                                loader={<div>Loading Event Chart</div>}
                                                data={[
                                                  ['Speaking Engagement', 'Geographic Scope'],
                                                  ['Global', parseInt(this.state.event_piechart.Global)],
                                                  ['Regional', parseInt(this.state.event_piechart.Regional)],
                                                  ['Local', parseInt(this.state.event_piechart.Local)],
                                                  ['National', parseInt(this.state.event_piechart.National)],
                                                ]}
                                                options={{
                                                  title: 'Geographic Scope : Speaking Engagements',
                                                }}
                                                rootProps={{ 'data-testid': '1' }}
                                              />
                                            </div>
                                            <div className="col-md-6">
                                              {/* <Chart
                                                width={'400px'}
                                                height={'300px'}
                                                chartType="BarChart"
                                                loader={<div>Loading Chart</div>}
                                                data={[
                                                  ['Points', '', ''],
                                                  // ['City', '2010 Population', '2000 Population'],
                                                  ['Global', parseFloat(this.state.EventDetailsGeographicScope_Global.percentile), 100 - parseFloat(this.state.EventDetailsGeographicScope_Global.percentile)],
                                                  ['Regional', parseFloat(this.state.EventDetailsGeographicScope_Regional.percentile), 100 - parseFloat(this.state.EventDetailsGeographicScope_Regional.percentile)],
                                                  ['National', parseFloat(this.state.EventDetailsGeographicScope_National.percentile), 100 - parseFloat(this.state.EventDetailsGeographicScope_National.percentile)],
                                                  ['Local', parseFloat(this.state.EventDetailsGeographicScope_Local.percentile), 100 - parseFloat(this.state.EventDetailsGeographicScope_Local.percentile)],

                                                ]}
                                                options={{

                                                  title: 'KOL Peer Group Standing : Geographic Scope',
                                                  chartArea: { width: '50%' },
                                                  isStacked: 'percent',
                                                  'tooltip': {
                                                    trigger: 'none'
                                                  },
                                                  hAxis: {
                                                    title: '0% = bottom 10% || 100% = top 10%',
                                                    minValue: 0,
                                                    ticks: ['0', '1'],
                                                  },
                                                  vAxis: {
                                                    // title: 'City',
                                                  },
                                                }}
                                                // For tests
                                                rootProps={{ 'data-testid': '3' }}
                                              /> */}
                                              <Chart
                                                width={'500px'}
                                                height={'300px'}
                                                chartType="PieChart"
                                                loader={<div>Loading Chart</div>}
                                                data={[
                                                  ['Speaking Engagement', 'Role Prominence'],
                                                  ['Keynote Speaker', parseInt(this.state.event_piechart.KeynoteSpeaker)],
                                                  ['Chairperson', parseInt(this.state.event_piechart.Chairperson)],
                                                  ['Regular', parseInt(this.state.event_piechart.Regular)],
                                                ]}
                                                options={{
                                                  title: 'Role Prominence : Speaking Engagements',
                                                }}
                                                rootProps={{ 'data-testid': '1' }}
                                              />
                                            </div>

                                          </div>
                                          <hr></hr>
                                          <div className="row">
                                            <div className="col-md-6">
                                            <Chart
                                                // width={'500px'}
                                                // height={'400px'}
                                                width="100%"
                                                height="400px"
                                                chartType="Scatter"
                                                loader={<div>Loading Chart</div>}
                                                data={[
                                                  // ["Bottom Percentile","KOL Percentile", "Top Percentile"],
                                                  // [this.state.event_peer_group_standing.bottom_percentile, this.state.event_peer_group_standing.percentile, this.state.event_peer_group_standing.top_percentile],

                                                  // ["Percentile","-","-"],
                                                  // ["Bottom","0",this.state.event_peer_group_standing.bottom_percentile],
                                                  // ["Percentile","0",this.state.event_peer_group_standing.percentile],
                                                  // ["Top","0",this.state.event_peer_group_standing.top_percentile],

                                                  // ["-","Bottom Percentile","Percentile"],
                                                  // ["Bottom Percentile",this.state.event_peer_group_standing.bottom_percentile,this.state.event_peer_group_standing.bottom_percentile],
                                                  // ["KOL Percentile",this.state.event_peer_group_standing.bottom_percentile,this.state.event_peer_group_standing.percentile],
                                                  // ["Top Percentile",this.state.event_peer_group_standing.bottom_percentile,this.state.event_peer_group_standing.top_percentile],

                                                  // ["-","Percentile"],
                                                  // ["Bottom",this.state.event_peer_group_standing.bottom_percentile],
                                                  // ["KOL Percentile",this.state.event_peer_group_standing.percentile],
                                                  // ["Top",this.state.event_peer_group_standing.top_percentile],

                                                  // ["-","Percentile","-"],
                                                  // ["Bottom",this.state.event_peer_group_standing.bottom_percentile,"0"],
                                                  // ["KOL Percentile",this.state.event_peer_group_standing.percentile,"0"],
                                                  // ["Top",this.state.event_peer_group_standing.top_percentile,"0"],

                                                  ["-","Percentile"],
                                                  ["Bottom",this.state.event_peer_group_standing.bottom_percentile],
                                                  ["KOL Percentile",this.state.event_peer_group_standing.percentile],
                                                  ["Top",this.state.event_peer_group_standing.top_percentile],
                                                ]}
                                                
                                                options={{
                                                  chart: {
                                                    title: "KOL Peer Group Standing",
                                                    subtitle: "Top, Bottom and self Percentile",
                                                  },
                                                  vAxis: { title: "Percentile" },
                                                }}
                                              />
                                              <div style={{fontSize:'12px'}}>
                                                Bottom 10 Percentile: {this.state.event_peer_group_standing.bottom_percentile}<br/>
                                                My Percentile: {this.state.event_peer_group_standing.percentile}<br/>
                                                Top 10 Percentile: {this.state.event_peer_group_standing.top_percentile}
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              {/* <Chart
                                                width={'400px'}
                                                height={'300px'}
                                                chartType="BarChart"
                                                loader={<div>Loading Chart</div>}
                                                data={[
                                                  ['Points', '', ''],
                                                  // ['City', '2010 Population', '2000 Population'],
                                                  ['Keynote Speaker ', parseFloat(this.state.EventDetailsRoleProminence_KeynoteSpeaker.percentile), 100 - parseFloat(this.state.EventDetailsRoleProminence_KeynoteSpeaker.percentile)],
                                                  ['Chairperson', parseFloat(this.state.EventDetailsRoleProminence_Chairperson.percentile), 100 - parseFloat(this.state.EventDetailsRoleProminence_Chairperson.percentile)],
                                                  ['Regular', parseFloat(this.state.EventDetailsRoleProminence_Regular.percentile), 100 - parseFloat(this.state.EventDetailsRoleProminence_Regular.percentile)]
                                                ]}
                                                options={{

                                                  title: 'KOL Peer Group Standing : Role Prominence',
                                                  chartArea: { width: '50%' },
                                                  isStacked: 'percent',
                                                  'tooltip': {
                                                    trigger: 'none'
                                                  },
                                                  hAxis: {
                                                    title: '0% = bottom 10% || 100% = top 10%',
                                                    minValue: 0,
                                                    ticks: ['0', '1'],
                                                  },
                                                  vAxis: {
                                                    // title: 'City',
                                                  },
                                                }}
                                                // For tests
                                                rootProps={{ 'data-testid': '3' }}
                                              /> */}
                                            </div>
                                          </div>

                                          <div className="row" style={{marginTop:'3%'}}>
                                            <div className="col-md-6">
                                              
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div></div>)
                                    }

                                    {/* Graphs Publication */}
                                    {
                                      (this.state.publication_piechart_flag == true) ? (
                                        <div>
                                          <div className="row">
                                            <div className="col-md-6">
                                              <Chart
                                                width={'500px'}
                                                height={'300px'}
                                                chartType="PieChart"
                                                loader={<div>Loading Chart</div>}
                                                data={[
                                                  ['Publication', 'Geographic Scope'],
                                                  ['Global', parseInt(this.state.publication_piechart.Global)],
                                                  ['Regional', parseInt(this.state.publication_piechart.Regional)],
                                                  ['Local', parseInt(this.state.publication_piechart.Local)],
                                                ]}
                                                options={{
                                                  title: 'Geographic Scope : Publication',
                                                }}
                                                rootProps={{ 'data-testid': '1' }}
                                              />
                                            </div>
                                            <div className="col-md-6">
                                              {/* <Chart
                                                width={'400px'}
                                                height={'300px'}
                                                chartType="BarChart"
                                                loader={<div>Loading Chart</div>}
                                                data={[
                                                  ['Points', '', ''],
                                                  // ['City', '2010 Population', '2000 Population'],
                                                  ['Global', parseFloat(this.state.PublicationDetailsGeographicScope_Global.percentile), 100 - parseFloat(this.state.PublicationDetailsGeographicScope_Global.percentile)],
                                                  ['Regional', parseFloat(this.state.PublicationDetailsGeographicScope_Regional.percentile), 100 - parseFloat(this.state.PublicationDetailsGeographicScope_Regional.percentile)],
                                                  ['Local', parseFloat(this.state.PublicationDetailsGeographicScope_Local.percentile), 100 - parseFloat(this.state.PublicationDetailsGeographicScope_Local.percentile)],

                                                ]}
                                                options={{

                                                  title: 'KOL Peer Group Standing : Geographic Scope',
                                                  chartArea: { width: '50%' },
                                                  isStacked: 'percent',
                                                  'tooltip': {
                                                    trigger: 'none'
                                                  },
                                                  hAxis: {
                                                    title: '0% = bottom 10% || 100% = top 10%',
                                                    minValue: 0,
                                                    ticks: ['0', '1'],
                                                  },
                                                  vAxis: {
                                                    // title: 'City',
                                                  },
                                                }}
                                                // For tests
                                                rootProps={{ 'data-testid': '3' }}
                                              /> */}

                                              <Chart
                                                width={'500px'}
                                                height={'300px'}
                                                chartType="PieChart"
                                                loader={<div>Loading Chart</div>}
                                                data={[
                                                  ['Publication', 'Role Prominence'],
                                                  ['1st Author', parseInt(this.state.publication_piechart.FirstAuthor)],
                                                  ['2nd Author', parseInt(this.state.publication_piechart.SecondAuthor)],
                                                  ['Last Author', parseInt(this.state.publication_piechart.LastAuthor)],
                                                ]}
                                                options={{
                                                  title: 'Role Prominence : Publication',
                                                }}
                                                rootProps={{ 'data-testid': '1' }}
                                              />
                                            </div>
                                          </div>

                                          <div className="row">
                                            <div className="col-md-6">
                                              <Chart
                                                width="100%"
                                                height="400px"
                                                chartType="Scatter"
                                                loader={<div>Loading Chart</div>}
                                                data={[
                                                  ["-","Percentile"],
                                                  ["Bottom",this.state.publication_peer_group_standing.bottom_percentile],
                                                  ["KOL Percentile",this.state.publication_peer_group_standing.percentile],
                                                  ["Top",this.state.publication_peer_group_standing.top_percentile],
                                                ]}
                                                options={{
                                                  chart: {
                                                    title: "KOL Peer Group Standing",
                                                    subtitle: "Top, Bottom and self Percentile",
                                                  },
                                                  vAxis: { title: "Percentile" },
                                                }}
                                              />
                                              <div style={{fontSize:'12px'}}>
                                                Bottom 10 Percentile: {this.state.publication_peer_group_standing.bottom_percentile}<br/>
                                                My Percentile: {this.state.publication_peer_group_standing.percentile}<br/>
                                                Top 10 Percentile: {this.state.publication_peer_group_standing.top_percentile}
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              {/* <Chart
                                                width={'400px'}
                                                height={'300px'}
                                                chartType="BarChart"
                                                loader={<div>Loading Chart</div>}
                                                data={[
                                                  ['Points', '', ''],
                                                  // ['City', '2010 Population', '2000 Population'],
                                                  ['First Author', parseFloat(this.state.PublicationDetailsRoleProminence_First_author.percentile), 100 - parseFloat(this.state.PublicationDetailsRoleProminence_First_author.percentile)],
                                                  ['Second Author', parseFloat(this.state.PublicationDetailsRoleProminence_Second_author.percentile), 100 - parseFloat(this.state.PublicationDetailsRoleProminence_Second_author.percentile)],
                                                  ['Last Author', parseFloat(this.state.PublicationDetailsRoleProminence_Last_author.percentile), 100 - parseFloat(this.state.PublicationDetailsRoleProminence_Last_author.percentile)],
                                                ]}
                                                options={{
                                                  title: 'KOL Peer Group Standing : Role Prominence',
                                                  chartArea: { width: '50%' },
                                                  isStacked: 'percent',
                                                  'tooltip': {
                                                    trigger: 'none'
                                                  },
                                                  hAxis: {
                                                    title: '0% = bottom 10% || 100% = top 10%',
                                                    minValue: 0,
                                                    ticks: ['0', '1'],
                                                  },
                                                  vAxis: {
                                                    // title: 'City',
                                                  },
                                                }}
                                                // For tests
                                                rootProps={{ 'data-testid': '3' }}
                                              /> */}
                                            </div>
                                          </div>

                                        </div>
                                      ) : (
                                        <div></div>
                                      )
                                    }

                                    {/* Graphs Clinical Trial */}
                                    {
                                      (this.state.clinicaltrial_piechart_flag == true) ? (
                                        <div>
                                          <div className="row">
                                            <div className="col-md-6">
                                              <Chart
                                                width={'500px'}
                                                height={'300px'}
                                                chartType="PieChart"
                                                loader={<div>Loading Chart</div>}
                                                data={[
                                                  ['Clinical Trials', 'Geographic Scope'],
                                                  ['Global', parseInt(this.state.clinicaltrial_piechart.Global)],
                                                  ['National', parseInt(this.state.clinicaltrial_piechart.National)],
                                                  ['Regional', parseInt(this.state.clinicaltrial_piechart.Regional)],
                                                  ['Local', parseInt(this.state.clinicaltrial_piechart.Local)],
                                                ]}
                                                options={{
                                                  title: 'Geographic Scope : Publication',
                                                }}
                                                rootProps={{ 'data-testid': '1' }}
                                              />
                                            </div>
                                            <div className="col-md-6">
                                              {/* <Chart
                                                width={'400px'}
                                                height={'300px'}
                                                chartType="BarChart"
                                                loader={<div>Loading Chart</div>}
                                                data={[
                                                  ['Points', '', ''],
                                                  // ['City', '2010 Population', '2000 Population'],
                                                  ['Global', parseFloat(this.state.ClinicalTrialsGeographicScope_Global.percentile), 100 - parseFloat(this.state.ClinicalTrialsGeographicScope_Global.percentile)],
                                                  ['Regional', parseFloat(this.state.ClinicalTrialsGeographicScope_Regional.percentile), 100 - parseFloat(this.state.ClinicalTrialsGeographicScope_Regional.percentile)],
                                                  ['National', parseFloat(this.state.ClinicalTrialsGeographicScope_National.percentile), 100 - parseFloat(this.state.ClinicalTrialsGeographicScope_National.percentile)],
                                                  ['Local', parseFloat(this.state.ClinicalTrialsGeographicScope_Local.percentile), 100 - parseFloat(this.state.ClinicalTrialsGeographicScope_Local.percentile)]
                                                ]}
                                                options={{
                                                  title: 'KOL Peer Group Standing : Geographic Scope',
                                                  chartArea: { width: '50%' },
                                                  isStacked: 'percent',
                                                  'tooltip': {
                                                    trigger: 'none'
                                                  },
                                                  hAxis: {
                                                    title: '0% = bottom 10% || 100% = top 10%',
                                                    minValue: 0,
                                                    ticks: ['0', '1'],
                                                  },
                                                  vAxis: {
                                                    // title: 'City',
                                                  },
                                                }}
                                                // For tests
                                                rootProps={{ 'data-testid': '3' }}
                                              /> */}

                                              <Chart
                                                width={'500px'}
                                                height={'300px'}
                                                chartType="PieChart"
                                                loader={<div>Loading Chart</div>}
                                                data={[
                                                  ['Clinical Trials', 'Types'],
                                                  ['Interventional', parseInt(this.state.clinicaltrial_piechart.Interventional)],
                                                  ['Observational', parseInt(this.state.clinicaltrial_piechart.Observational)],
                                                ]}
                                                options={{
                                                  title: 'Types of Clinical Trials',
                                                }}
                                                rootProps={{ 'data-testid': '1' }}
                                              />
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-md-6">
                                              <Chart
                                                width="100%"
                                                height="400px"
                                                chartType="Scatter"
                                                loader={<div>Loading Chart</div>}
                                                data={[
                                                  ["-","Percentile"],
                                                  ["Bottom",this.state.clinicaltrial_peer_group_standing.bottom_percentile],
                                                  ["KOL Percentile",this.state.clinicaltrial_peer_group_standing.percentile],
                                                  ["Top",this.state.clinicaltrial_peer_group_standing.top_percentile],
                                                ]}
                                                
                                                options={{
                                                  chart: {
                                                    title: "KOL Peer Group Standing",
                                                    subtitle: "Top, Bottom and self Percentile",
                                                  },
                                                  vAxis: { title: "Percentile" },
                                                }}
                                              />
                                              <div style={{fontSize:'12px'}}>
                                                Bottom 10 Percentile: {this.state.clinicaltrial_peer_group_standing.bottom_percentile}<br/>
                                                My Percentile: {this.state.clinicaltrial_peer_group_standing.percentile}<br/>
                                                Top 10 Percentile: {this.state.clinicaltrial_peer_group_standing.top_percentile}
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              {/* <Chart
                                                width={'400px'}
                                                height={'300px'}
                                                chartType="BarChart"
                                                loader={<div>Loading Chart</div>}
                                                data={[
                                                  ['Points', '', ''],
                                                  // ['City', '2010 Population', '2000 Population'],
                                                  ['Interventional', parseFloat(this.state.ClinicalTrialsRoleProminence_Interventional.percentile), 100 - parseFloat(this.state.ClinicalTrialsRoleProminence_Interventional.percentile)],
                                                  ['Observational', parseFloat(this.state.ClinicalTrialsRoleProminence_observational.percentile), 100 - parseFloat(this.state.ClinicalTrialsRoleProminence_observational.percentile)]
                                                ]}
                                                options={{
                                                  title: 'KOL Peer Group Standing : Clinical Trials',
                                                  chartArea: { width: '50%' },
                                                  isStacked: 'percent',
                                                  'tooltip': {
                                                    trigger: 'none'
                                                  },
                                                  hAxis: {
                                                    title: '0% = bottom 10% || 100% = top 10%',
                                                    minValue: 0,
                                                    ticks: ['0', '1'],
                                                  },
                                                  vAxis: {
                                                    // title: 'City',
                                                  },
                                                }}
                                                // For tests
                                                rootProps={{ 'data-testid': '3' }}
                                              /> */}
                                            </div>
                                          </div>

                                        </div>
                                      ) : (
                                        <div></div>
                                      )
                                    }

                                    {/* Graphs Advisory Board */}
                                    {
                                      (this.state.ad_board_piechart_flag == true) ? (
                                        <div>
                                          <div className="row">
                                            <div className="col-md-6">
                                              <Chart
                                                width={'500px'}
                                                height={'300px'}
                                                chartType="PieChart"
                                                loader={<div>Loading Chart</div>}
                                                data={[
                                                  ['Ad Boards Engagement', 'Geographic Scope'],
                                                  ['Global', parseInt(this.state.ad_board_piechart.Global)],
                                                  ['Regional', parseInt(this.state.ad_board_piechart.Regional)],
                                                  ['Local', parseInt(this.state.ad_board_piechart.Local)],
                                                ]}
                                                options={{
                                                  title: 'Geographic Scope : Ad Boards Engagement',
                                                }}
                                                rootProps={{ 'data-testid': '1' }}
                                              />
                                            </div>
                                            <div className="col-md-6">
                                              {/* <Chart
                                                width={'400px'}
                                                height={'300px'}
                                                chartType="BarChart"
                                                loader={<div>Loading Chart</div>}
                                                data={[
                                                  ['Points', '', ''],
                                                  // ['City', '2010 Population', '2000 Population'],
                                                  ['Global', parseFloat(this.state.AdvisoryBoardGeographicScope_Global.percentile), 100 - parseFloat(this.state.AdvisoryBoardGeographicScope_Global.percentile)],
                                                  ['Regional', parseFloat(this.state.AdvisoryBoardGeographicScope_Regional.percentile), 100 - parseFloat(this.state.AdvisoryBoardGeographicScope_Regional.percentile)],
                                                  ['Local', parseFloat(this.state.AdvisoryBoardGeographicScope_Local.percentile), 100 - parseFloat(this.state.AdvisoryBoardGeographicScope_Local.percentile)],
                                                ]}
                                                options={{
                                                  title: 'KOL Peer Group Standing : Geographic Scope',
                                                  chartArea: { width: '50%' },
                                                  isStacked: 'percent',
                                                  'tooltip': {
                                                    trigger: 'none'
                                                  },
                                                  hAxis: {
                                                    title: '0% = bottom 10% || 100% = top 10%',
                                                    minValue: 0,
                                                    ticks: ['0', '1'],
                                                  },
                                                  vAxis: {
                                                    // title: 'City',
                                                  },
                                                }}
                                                // For tests
                                                rootProps={{ 'data-testid': '3' }}
                                              /> */}
                                              <Chart
                                                width={'500px'}
                                                height={'300px'}
                                                chartType="PieChart"
                                                loader={<div>Loading Chart</div>}
                                                data={[
                                                  ['Ad Boards Prominence', 'Types'],
                                                  ['Board Member', parseInt(this.state.ad_board_piechart.BoardMember)],
                                                  ['Advisory Committee', parseInt(this.state.ad_board_piechart.AdvisoryCommittee)],
                                                ]}
                                                options={{
                                                  title: 'Ad Boards Prominence'
                                                }}
                                                rootProps={{ 'data-testid': '1' }}
                                              />
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-md-6">
                                              <Chart
                                                width="100%"
                                                height="400px"
                                                chartType="Scatter"
                                                loader={<div>Loading Chart</div>}
                                                data={[
                                                  ["-","Percentile"],
                                                  ["Bottom",this.state.ad_board_peer_group_standing.bottom_percentile],
                                                  ["KOL Percentile",this.state.ad_board_peer_group_standing.percentile],
                                                  ["Top",this.state.ad_board_peer_group_standing.top_percentile],
                                                ]}
                                                
                                                options={{
                                                  chart: {
                                                    title: "KOL Peer Group Standing",
                                                    subtitle: "Top, Bottom and self Percentile",
                                                  },
                                                  vAxis: { title: "Percentile" },
                                                }}
                                              />
                                              <div style={{fontSize:'12px'}}>
                                                Bottom 10 Percentile: {this.state.ad_board_peer_group_standing.bottom_percentile}<br/>
                                                My Percentile: {this.state.ad_board_peer_group_standing.percentile}<br/>
                                                Top 10 Percentile: {this.state.ad_board_peer_group_standing.top_percentile}
                                              </div>
                                            </div>
                                            <div className="col-md-6">
                                              {/* <Chart
                                                width={'400px'}
                                                height={'300px'}
                                                chartType="BarChart"
                                                loader={<div>Loading Chart</div>}
                                                data={[
                                                  ['Points', '', ''],
                                                  // ['City', '2010 Population', '2000 Population'],
                                                  ['Commitee Member', parseFloat(this.state.AdvisoryBoardRoleProminence_Commitee_Member.percentile), 100 - parseFloat(this.state.AdvisoryBoardRoleProminence_Commitee_Member.percentile)],
                                                  ['Board Member', parseFloat(this.state.AdvisoryBoardRoleProminence_Board_Member.percentile), 100 - parseFloat(this.state.AdvisoryBoardRoleProminence_Board_Member.percentile)]
                                                ]}
                                                options={{
                                                  title: 'KOL Peer Group Standing : Role Prominence',
                                                  chartArea: { width: '50%' },
                                                  isStacked: 'percent',
                                                  'tooltip': {
                                                    trigger: 'none'
                                                  },
                                                  hAxis: {
                                                    title: '0% = bottom 10% || 100% = top 10%',
                                                    minValue: 0,
                                                    ticks: ['0', '1'],
                                                  },
                                                  vAxis: {
                                                    // title: 'City',
                                                  },
                                                }}
                                                // For tests
                                                rootProps={{ 'data-testid': '3' }}
                                              /> */}
                                            </div>
                                          </div>

                                        </div>
                                      ) : (
                                        <div></div>
                                      )
                                    }

                                    {/* Graphs Guidelines */}
                                    {
                                      (this.state.guideline_piechart_flag == true) ? (
                                        <div>
                                          <div className="row">
                                            <div className="col-md-6">
                                              <Chart
                                                width={'500px'}
                                                height={'300px'}
                                                chartType="PieChart"
                                                loader={<div>Loading Chart</div>}
                                                data={[
                                                  ['Guideline', 'Geographic Scope'],
                                                  ['Global', parseInt(this.state.guideline_piechart.Global)],
                                                  ['Regional', parseInt(this.state.guideline_piechart.Regional)],
                                                  ['National', parseInt(this.state.guideline_piechart.National)],
                                                  ['Local', parseInt(this.state.guideline_piechart.Local)],
                                                ]}
                                                options={{
                                                  title: 'Geographic Scope : Guideline',
                                                }}
                                                rootProps={{ 'data-testid': '1' }}
                                              />
                                            </div>
                                            <div className="col-md-6">
                                              {/* <Chart
                                                width={'400px'}
                                                height={'300px'}
                                                chartType="BarChart"
                                                loader={<div>Loading Chart</div>}
                                                data={[
                                                  ['Points', '', ''],
                                                  // ['City', '2010 Population', '2000 Population'],
                                                  ['Global', parseFloat(this.state.GuidelineDetailsGeographicScope_Global.percentile), 100 - parseFloat(this.state.GuidelineDetailsGeographicScope_Global.percentile)],
                                                  ['Regional', parseFloat(this.state.GuidelineDetailsGeographicScope_Regional.percentile), 100 - parseFloat(this.state.GuidelineDetailsGeographicScope_Regional.percentile)],
                                                  ['National', parseFloat(this.state.GuidelineDetailsGeographicScope_National.percentile), 100 - parseFloat(this.state.GuidelineDetailsGeographicScope_National.percentile)],
                                                  ['Local', parseFloat(this.state.GuidelineDetailsGeographicScope_Local.percentile), 100 - parseFloat(this.state.GuidelineDetailsGeographicScope_Local.percentile)],
                                                ]}
                                                options={{
                                                  title: 'KOL Peer Group Standing : Role Prominence',
                                                  chartArea: { width: '50%' },
                                                  isStacked: 'percent',
                                                  'tooltip': {
                                                    trigger: 'none'
                                                  },
                                                  hAxis: {
                                                    title: '0% = bottom 10% || 100% = top 10%',
                                                    minValue: 0,
                                                    ticks: ['0', '1'],
                                                  },
                                                  vAxis: {
                                                    // title: 'City',
                                                  },
                                                }}
                                                // For tests
                                                rootProps={{ 'data-testid': '3' }}
                                              /> */}

                                              <Chart
                                                width="100%"
                                                height="400px"
                                                chartType="Scatter"
                                                loader={<div>Loading Chart</div>}
                                                data={[
                                                  ["-","Percentile"],
                                                  ["Bottom",this.state.guideline_peer_group_standing.bottom_percentile],
                                                  ["KOL Percentile",this.state.guideline_peer_group_standing.percentile],
                                                  ["Top",this.state.guideline_peer_group_standing.top_percentile],
                                                ]}
                                                options={{
                                                  chart: {
                                                    title: "KOL Peer Group Standing",
                                                    subtitle: "Top, Bottom and self Percentile",
                                                  },
                                                  vAxis: { title: "Percentile" },
                                                }}
                                              />
                                              <div style={{fontSize:'12px'}}>
                                                Bottom 10 Percentile: {this.state.guideline_peer_group_standing.bottom_percentile}<br/>
                                                My Percentile: {this.state.guideline_peer_group_standing.percentile}<br/>
                                                Top 10 Percentile: {this.state.guideline_peer_group_standing.top_percentile}
                                              </div>
                                            </div>
                                          </div>

                                        </div>
                                      ) : (
                                        <div></div>
                                      )
                                    }
                                  </div>
                                </div>
                              </div>

                              <div style={{ marginTop: '10%' }}></div>
                            </section>
                          ) : (
                            <div></div>
                          )
                        }
                        {/* advisory board */}
                        {
                          (this.state.AdvisoryBoardFlag == true) ? (
                            <section className="mt-50 " >
                              <h3 className="mt-30">Advisory Board Details</h3>
                              <div className="scrollable-box">
                                {/* <div className="emplist-box-boot" style={{width:'200%'}}>  */}
                                <div style={{ width: '100%', overflowX: 'scroll' }}>
                                  <div style={{ width: '270%' }}>
                                    <BootstrapTable
                                      noDataIndication="Table is Empty"
                                      keyField='id'
                                      data={this.state.AdvisoryBoard}
                                      columns={this.state.columnsAdvisoryBord}
                                      striped
                                      hover
                                      condensed
                                    />
                                  </div>
                                </div>
                              </div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* Awards and honours */}
                        {
                          (this.state.AwardsAndHonoursFlag == true) ? (
                            <section className="mt-50 " >
                              <h3 className="mt-30">Awards and Honours Details</h3>
                              <div className="scrollable-box">
                                <div style={{ width: '100%', overflowX: 'scroll' }}>
                                  <BootstrapTable
                                    noDataIndication="Table is Empty"
                                    keyField='id'
                                    data={this.state.AwardsHonours}
                                    columns={this.state.columnsAwardsHonours}
                                    striped
                                    hover
                                    condensed
                                  />
                                </div>
                              </div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* Books and Monographs */}
                        {
                          (this.state.BooksAndMonographFlag == true) ? (
                            <section className="mt-50 " >
                              <h3 className="mt-30">Books and Monographs</h3>
                              <div className="scrollable-box">
                                {/* <div className="emplist-box-boot" style={{width:'200%'}}>  */}
                                <div style={{ width: '100%', overflowX: 'scroll' }}>
                                  <div style={{ width: '250%' }}>
                                    <BootstrapTable
                                      noDataIndication="Table is Empty"
                                      keyField='id'
                                      data={this.state.BooksMonographs}
                                      columns={this.state.columnsBooksMonographs}
                                      striped
                                      hover
                                      condensed
                                    />
                                  </div>
                                </div>
                              </div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* Commitees */}
                        {
                          (this.state.CommiteesFlag == true) ? (
                            <section className="mt-50 " >
                              <h3 className="mt-30">Commitee Details</h3>
                              <div className="scrollable-box">
                                <div style={{ width: '100%', overflowX: 'scroll' }}>
                                  <BootstrapTable
                                    noDataIndication="Table is Empty"
                                    keyField='id'
                                    data={this.state.Commitees}
                                    columns={this.state.columnsCommitees}
                                    striped
                                    hover
                                    condensed
                                  />
                                </div>
                              </div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* Clinical Trial Details */}
                        {
                          (this.state.ClinicalTrialDetailsFlag == true) ? (
                            <section className="mt-50 " >
                              <h3 className="mt-30">Clinical Trial Details</h3>
                              <div className="scrollable-box">
                                {/* <div className="emplist-box-boot" style={{width:'200%'}}>  */}
                                <div style={{ width: '100%', overflowX: 'scroll' }}>
                                  <div style={{ width: '400%' }}>
                                    <BootstrapTable
                                      noDataIndication="Table is Empty"
                                      keyField='id'
                                      data={this.state.ClinicalTrialDetails}
                                      columns={this.state.columnsClinicalTrialDetails}
                                      striped
                                      hover
                                      condensed
                                    />
                                  </div>
                                </div>
                              </div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* Event Details */}
                        {
                          (this.state.EventsDetailsFlag == true) ? (
                            <section className="mt-50 " >
                              <h3 className="mt-30">Event Details</h3>
                              <div className="scrollable-box">
                                <div style={{ width: '100%', overflowX: 'scroll' }}>
                                  <div style={{ width: '300%' }}>
                                    <BootstrapTable
                                      noDataIndication="Table is Empty"
                                      keyField='id'
                                      data={this.state.EventsDetails}
                                      columns={this.state.columnsEventsDetails}
                                      striped
                                      hover
                                      condensed
                                    />
                                  </div>
                                </div>
                              </div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* grants */}
                        {
                          (this.state.GrantsFlag == true) ? (
                            <section className="mt-50 " >
                              <h3 className="mt-30">Grants Details</h3>
                              <div className="scrollable-box">
                                {/* <div className="emplist-box-boot" style={{width:'200%'}}>  */}
                                <div style={{ width: '100%', overflowX: 'scroll' }}>
                                  <BootstrapTable
                                    noDataIndication="Table is Empty"
                                    keyField='id'
                                    data={this.state.Grants}
                                    columns={this.state.columnsGrants}
                                    striped
                                    hover
                                    condensed
                                  />
                                </div>
                              </div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* Guidelines */}
                        {
                          (this.state.GuidelinesFlag == true) ? (
                            <section className="mt-50 " >
                              <h3 className="mt-30">Guidelines Details</h3>
                              <div className="scrollable-box">
                                <div style={{ width: '100%', overflowX: 'scroll' }}>
                                  <div style={{ width: '250%' }}>
                                    <BootstrapTable
                                      noDataIndication="Table is Empty"
                                      keyField='id'
                                      data={this.state.Guidelines}
                                      columns={this.state.columnsGuidelines}
                                      striped
                                      hover
                                      condensed
                                    />
                                  </div>
                                </div>
                              </div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* Hospital Affiliations */}
                        {
                          (this.state.HospitalAffiliationsFlag == true) ? (
                            <section className="mt-50 " >
                              <h3 className="mt-30">Hospital Affiliation Details</h3>
                              <div className="scrollable-box">
                                <div style={{ width: '100%', overflowX: 'scroll' }}>
                                  <BootstrapTable
                                    noDataIndication="Table is Empty"
                                    keyField='id'
                                    data={this.state.HospitalAffiliations}
                                    columns={this.state.columnsHospitalAffiliations}
                                    striped
                                    hover
                                    condensed
                                  />
                                </div>
                              </div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* Interactions */}
                        {
                          (this.state.InteractionsFlag == true) ? (
                            <section className="mt-50 " >
                              <h3 className="mt-30">Interactions Details</h3>
                              <div className="scrollable-box">
                                <div style={{ width: '100%', overflowX: 'scroll' }}>
                                  <div style={{ width: '250%' }}>
                                    <BootstrapTable
                                      noDataIndication="Table is Empty"
                                      keyField='id'
                                      data={this.state.Interactions}
                                      columns={this.state.columnsInteractions}
                                      striped
                                      hover
                                      condensed
                                    />
                                  </div>
                                </div>
                              </div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* Press Items */}
                        {
                          (this.state.PressItemFlag == true) ? (
                            <section className="mt-50 " >
                              <h3 className="mt-30">Press Items Details</h3>
                              <div className="scrollable-box">
                                <div style={{ width: '100%', overflowX: 'scroll' }}>
                                  <div style={{ width: '150%' }}>
                                    <BootstrapTable
                                      noDataIndication="Table is Empty"
                                      keyField='id'
                                      data={this.state.PressItem}
                                      columns={this.state.columnsPressItem}
                                      striped
                                      hover
                                      condensed
                                    />
                                  </div>
                                </div>
                              </div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* Presentation */}
                        {
                          (this.state.PresentationsFlag == true) ? (
                            <section className="mt-50 " >
                              <h3 className="mt-30">Presentation Details</h3>
                              <div className="scrollable-box">
                                <div style={{ width: '100%', overflowX: 'scroll' }}>
                                  <BootstrapTable
                                    noDataIndication="Table is Empty"
                                    keyField='id'
                                    data={this.state.Presentation}
                                    columns={this.state.columnsPresentation}
                                    striped
                                    hover
                                    condensed
                                  />
                                </div>
                              </div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* Publication Details */}
                        {
                          (this.state.PublicationDetailsFlag == true) ? (
                            <section className="mt-50 " >
                              <h3 className="mt-30">Publication Details</h3>
                              <div className="scrollable-box">
                                <div style={{ width: '100%', overflowX: 'scroll' }}>
                                  <div style={{ width: '300%' }}>
                                    <BootstrapTable
                                      noDataIndication="Table is Empty"
                                      keyField='id'
                                      data={this.state.PublicationDetails}
                                      columns={this.state.columnsPublicationDetails}
                                      striped
                                      hover
                                      condensed
                                    />
                                  </div>
                                </div>
                              </div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* Sunshine Payment */}
                        {
                          (this.state.SunshinePaymentFlag == true) ? (
                            <section className="mt-50 " >
                              <h3 className="mt-30">Sunshine Payment Details</h3>
                              <div className="scrollable-box">
                                <div style={{ width: '100%', overflowX: 'scroll' }}>
                                  <div style={{ width: '250%' }}>
                                    <BootstrapTable
                                      noDataIndication="Table is Empty"
                                      keyField='id'
                                      data={this.state.SunshinePayment}
                                      columns={this.state.columnsSunshinePayment}
                                      striped
                                      hover
                                      condensed
                                    />
                                  </div>
                                </div>
                              </div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <footer className="footer-fixed">
            <div className="cetas-footer">
              <div className="container">
                <p>All rights reserved. www.cetashealthcare.com</p>
              </div>
            </div>
          </footer>
        </div>
      </body >
    );

  }
}

export default AdministratorKolProfile;