import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Input, Container, Row, Col, Modal } from 'reactstrap';
import filterFactory, { textFilter, customFilter, selectFilter, Comparator, FILTER_TYPES } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { Button, Spinner } from 'reactstrap';
import Axios from 'axios';
import axios from 'axios';
import { RingLoader, FadeLoader, DotLoader, PropagateLoader } from 'react-spinners';

import { Redirect, Link } from 'react-router-dom';
import LoadingBar from "react-top-loading-bar";
import Swal from 'sweetalert2';
import ResercherHeader from './ResercherHeader';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
const { ExportCSVButton } = CSVExport;
const headerSortingStyle = { backgroundColor: 'grey' };
const { SearchBar } = Search;
const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total ml-2">
        Showing {from} to {to} of {size} Results
    </span>
);

// class Researcher_View_KolList extends Component {
class DuplicateViewKolList extends Component {
    constructor(props) {

        super(props)

        this.state = {

            system_id: '',
            delete_id: '',
            token:'',
            SpecialityData:[],
            // To show Add new Kol button
            showAddnewKol: false,
            // for redirecting
            redirectFormManagement: false,
            RedirectEditKOL: false,
            RedirectIdentifireForm: false,
            RedirectcheckduplicateForm:false,
            RedirectEdiIdentifireForm: false,

            DuplicateDivDisplay:true,

            // KOL IDS for new kol
            emp_unique_id: '',
            SrNO: '',

            kol_unique_id: '',
            kol_status: '',

            // first_name:'',
            // last_name:'',
            // specility:'',

            firstnameErr:'',
            lastnameErr:'',
            specilityErr:'',

            //Kol ID
            // RID: this.props.location.state.RID,
            // resercher_name: this.props.location.state.resercher_name,
            // project_id: this.props.location.state.project_id,
            // Project_Name: this.props.location.state.Project_Name,
            RID: '',
            resercher_name: '',
            project_id: '',
            Project_Name: '',

            RedirectFlag: false,

            //To show Spinner
            ShowBar: false,

            // State for saving data
            projectList: [],
            Project_Count: [],
            Rejected_Count: [],
            Approved_Count: [],

            onPriceFilter: null,
            onProductilters: null,
            CountryListData:[],
            // State for displaying data
            status: '',


            checkDuplicate: {
                first_name:'',
                last_name: '',
                specialty_id: '',
                    
              },

            // Column state for Bootstrap Table 
            columns: [
                
                {
                    dataField: "profile_photo",
                    text: "Image",
                    formatter: this.PfrofileImageFormater,
                    // headerStyle: (colum, colIndex) => {
                    //   return {width: '8%',borderColor:'#02365F',textAlign:'center', fontSize:'13px', borderTop:'solid', borderTopColor:'#fff'};
                    // },
                  },
                {                    
                    dataField: 'name',
                    text: 'KOL Name',
                    sort: true,
                    formatter: this.link,
                    filter: textFilter(),

                    filterRenderer: (onFilter, column) => {
                        this.onTextFilter = onFilter;
                        return null;
                    },
                    style: (cell, row, rowIndex, colIndex) => {
                        return {
                            color: '#41A2FF'
                        };
                    },
                },
                
                {
                    dataField: 'speciality_type',
                    text: 'Speciality',
                    sort: true,
                    formatter: (cell, row) => {
                        return(
                        <div style={{marginTop:'50px'}} >
                            {row.speciality_type}
              
                        </div>
                        )
              
                       },
                },
                {
                    dataField: 'current_place_of_work',
                    text: 'Current Organization',
                    formatter: (cell, row) => {
                        return(
                        <div style={{marginTop:'50px'}} >
                            {row.current_place_of_work}
              
                        </div>
                        )
              
                       },
                    
                },
                {
                    dataField: 'country',
                    text: 'Country',
                    formatter: (cell, row) => {
                        return(
                        <div style={{marginTop:'50px'}} >
                            {row.country}
              
                        </div>
                        )
              
                       },
                },

                {
                    dataField: "kol_status",
                    text: "Status",
                    sort: true,
                    filter: textFilter(),
                    formatter:this.kolstatuscolour,

                    filterRenderer: (onFilter, column) => {
                        this.onStatusFilters = onFilter;
                        return null;
                    },

                    // style: (cell, row, rowIndex, colIndex) => {
                    //     if (row.kol_status == "Submited") {
                    //         return {
                    //             color: '#1FCE39'
                    //         };
                    //     } else if (row.kol_status == "Approved") {
                    //         return {
                    //             color: '#1FCE39'
                    //         };
                    //     } else if (row.kol_status == "Rejected") {
                    //         return {
                    //             color: '#e51c1c'
                    //         };
                    //     } else if (row.kol_status == "Work In Progress") {
                    //         return {
                    //             color: '#FF7F41'
                    //         };
                    //     }
                    //     else if (row.kol_status == "Prelim") {
                    //         return {
                    //             color: '#8e67e0'
                    //         };
                    //     }
                    //     else if (row.kol_status == "Prelim Approved") {
                    //         return {
                    //             color: '#c585f2'
                    //         };
                    //     }
                    //     else if (row.kol_status == "Prelim Rejected") {
                    //         return {
                    //             color: '#0c9c86'
                    //         };
                    //     }
                    // },
                }
            ]
            // End of state
        }
        this.BackToDashboard = this.BackToDashboard.bind(this);
        // End of constructor
    }


    PfrofileImageFormater= (cell, row) => {
        var imgsource ='';
       // alert(row.profile_photo);
            if( row.profile_photo !== null)
            {
              imgsource= row.profile_photo;
            }
            else
            {
              imgsource="require('../Assets/Images/default-user.jpg')";
            // imgsource="{require('../Assets/Images/cetas-logo.png')}";
            }
    
    
    
        return (
          <div>        
            <td>    
            {
              ( row.profile_photo !== null ) ?   
              (
                <img src={row.profile_photo} class="kLogo" />
             
              ):
              (
                <img src={require('../Assets/Images/default-user.jpg')} class="kLogo" />
    
              )
    
            }
    
             
             </td>
            
          </div>
        )
      }

    kolstatuscolour = (cell, row) => {
        var id = row.project_id;
        var project_name = row.p_name;
        var project_status = row.project_status;
        var col='';
      
        if (row.kol_status == "Submited") {
                        col = '#1FCE39'
                  
                } else if (row.kol_status == "Approved") {
                    col= '#1FCE39'
                    
                } else if (row.kol_status == "Rejected") {
                        col= '#e51c1c'
                   
                } else if (row.kol_status == "Work In Progress") {
                  
                    col= '#FF7F41'
                  
                }
                else if (row.kol_status == "Prelim") {
                    col= '#8e67e0'
                    
                }
                else if (row.kol_status == "Prelim Approved") {
                        col= '#1FCE39'
                   
                }
                else if (row.kol_status == "Prelim Rejected") {
                   
                        col= '#e51c1c'
                   
                }
      
      
        return (
          <div style={{color:col ,marginTop:'50px'}}>
           {row.kol_status}
          </div>
        )
      }


    // STARNGE  
    onTextChange = (event) => {
        console.log(event.target.value)
        const { value } = event.target;
        this.onTextFilter(value);
    };

    //STRANGE
    onFilter = (event) => {
        console.log(event.target.value)
        const { value } = event.target;
        this.onStatusFilters(value);
    };

    //Function for toggling state which is for Back to Dashboard
    BackToDashboard() {
        this.setState({ RedirectFlag: true })
    }

    
    
    // Method to fetch data from API when component Mounted
    componentDidMount = async () => {
    

        this.setState({
            ShowBar: true,
        })

        const options = {
            method: 'POST',
        }
        //  Speciality type data
        fetch(global.websiteUrl+'/service/Speciality/get_SpecialityList', options)
            .then((Response) => Response.json())
            .then((findresponse) => {
                console.log(findresponse)
                var testDataSpeciality = findresponse.data;
                console.log("testDataSpeciality", JSON.stringify(testDataSpeciality));
                this.setState({
                    SpecialityData: findresponse.data,
                })
            })

            this.setState({
                ShowBar: false,
            })
    
        let getSession_token = await sessionStorage.getItem('token')
        if (getSession_token !== null) {
            await this.setState({
            token: getSession_token,
            })
        }
        
        let getSession_Researcher_Id = await sessionStorage.getItem('id')
        if (getSession_Researcher_Id !== null) {
            await this.setState({
                RID: getSession_Researcher_Id,
            })
        }
        let getSession_Researcher_Name = await sessionStorage.getItem('name')
        if (getSession_Researcher_Name !== null) {
            await this.setState({
                resercher_name: getSession_Researcher_Name,
            })
        }
        let getSession_Project_Id = await sessionStorage.getItem('project_id')
        if (getSession_Project_Id !== null) {
            await this.setState({
                project_id: getSession_Project_Id,
            })
        }
        let getSession_Project_Name = await sessionStorage.getItem('Project_Name')
        if (getSession_Project_Name !== null) {
            await this.setState({
                Project_Name: getSession_Project_Name,
            })
        }

        console.log("getSession_Researcher_Id ===== ", getSession_Researcher_Id)
        console.log("getSession_Researcher_Name ===== ", getSession_Researcher_Name)
        console.log("getSession_Project_Id ===== ", getSession_Project_Id)
        console.log("getSession_Project_Name ===== ", getSession_Project_Name)

       

        // this.setState({
        //     ShowBar: true,
        // })

        // // Project List Fetched
        // const url = global.websiteUrl+'/service/KOL/get_Project_KOL';
        // let formData = new FormData();
        // formData.append('project_id', this.state.project_id);
        // formData.append('user_type', 'Researcher');

        // // formData.append('project_id','CHCPI0002');
        // // formData.append('oper', 'del');

        // const config = {
        //     //   headers: { 'content-type': 'multipart/form-data' }
        // }

        // Axios.post(url, formData, config)
        //     .then(response => {
        //         console.log(response);
        //         var lgdata = response.data.KOL_Personal_Info;
        //         console.log("Fetched data", JSON.stringify(lgdata));
        //         var lgstatus = response.data.status;
        //         console.log("Fetched status", JSON.stringify(lgstatus));
        //         console.log("Message from fetched api", response.data.message);
        //         var kolIds = response.data.kol_system_id;
        //         console.log("kolIds", JSON.stringify(kolIds));

        //         if (lgdata) {
        //             this.setState({
        //                 status: response.data.message,
        //                 projectList: response.data.KOL_Personal_Info
        //             })
        //         } else {
        //             this.setState({
        //                 status: response.data.message,
        //                 projectList: []
        //             })
        //         }


        //         kolIds.map(async (data) => {
        //             var testkolid = data.emp_unique_id;
        //             console.log("testkolid", testkolid);
        //             var testsrno = data.SI_NO;
        //             console.log("testsrno", testsrno);
        //             this.setState({
        //                 emp_unique_id: testkolid,
        //                 SrNO: testsrno,
        //             })
        //             sessionStorage.setItem('emp_unique_id', testkolid)
        //             sessionStorage.setItem('SrNO', testsrno)
        //         })

        //         this.setState({
        //             ShowBar: false,
        //             showAddnewKol: true
        //         })

        //     });
        // console.log("PROJECT ID FROM DASHBOARD", this.state.project_id)
        // console.log("PROJECT Namwe FROM DASHBOARD", this.state.Project_Name)
        // console.log("VKBackRID FROM DASHBOARD", this.state.RID)
    }



    
    //Funtion to apply links to project names
    link = (cell, row) => {
        // console.log(cell)
        // console.log(row)
        var kol_unique_id = row.kol_unique_id;
        var kol_status = row.kol_status;
        return (
            <div style={{marginTop:'50px'}}>
                <a style={{
           color: '#069',
          textDecoration: 'underline', cursor: 'pointer'
        }}
 onClick={this.GoToEditKOL.bind(this, kol_unique_id, kol_status)}> {row.name} </a>
            </div>
        )
    }

    GoToEditKOL(kol_unique_id, kol_status) {
        console.log('Yep Here');
        console.log('Id Here', kol_unique_id);
        if (kol_status == "Prelim") {
            Swal.fire({
                icon: 'error',
                text: 'Prelim Profile is under review',
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
            })
        }
        else if (kol_status == "Approved") {
            Swal.fire({
                icon: 'error',
                text: 'Profile is Approved',
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
            })
        }
        else if (kol_status == "Prelim Rejected") {           
           
            this.setState({                

                RedirectEdiIdentifireForm: true,

                // kol_unique_id: kol_unique_id, 
                // kol_status: kol_status 
            })
        }
        else {
            this.setState({
                RedirectEditKOL: true,
                // kol_unique_id: kol_unique_id, 
                // kol_status: kol_status 
            })
        }
        sessionStorage.setItem('kol_unique_id', kol_unique_id)
        sessionStorage.setItem('kol_status', kol_status)
    }

    validate=()=>
    {
            let firstnameErr='';
            let lastnameErr='';
            let specilityErr='';

            if(!this.state.checkDuplicate.first_name)
            {
                 firstnameErr='Please Enter first name' ;
            }
            if(!this.state.checkDuplicate.last_name)
            {
                 lastnameErr='Please Enter last name' ;
            }

            if(!this.state.checkDuplicate.specialty_id)
            {
                specilityErr='Select Specility' ;
            }

            if(firstnameErr || lastnameErr || specilityErr)
            {
                this.setState({
                    firstnameErr,lastnameErr,specilityErr
                })
                return false;
            }

            return true;
    }

    DuplicateCheckFunc() {
        
        
        this.setState({
            ShowBar: true,
        })
        
       const isvalid=this.validate();
       if(isvalid)
       {

       

        const url = global.websiteUrl+'/service/KOL/check_KOL_duplicate';
          
        //const url ='http://localhost/Cetas/service/KOL/check_KOL_duplicate';
        let formData = new FormData();
        formData.append('KOl_Info', JSON.stringify(this.state.checkDuplicate));
  
        const config = {
          headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(url, formData, config)
          .then(response => {
            console.log(response);
            var status = response.data.status;
            console.log("status", status);

            var duplicatedatalist=response.data.KOL_Personal_Info;
            if(duplicatedatalist)
            {
               
                this.setState({projectList:duplicatedatalist,})

            }else
            {
                this.setState({projectList:[],})
            }

            this.setState({
                ShowBar: false,                    
           })

          //  alert(response.data.message);
            if (response.data.status == true) {

              Swal.fire({
                icon: 'error',
                text: response.data.message,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })

              this.setState({DuplicateDivDisplay:true,})

              this.setState({
                ShowBar: false,                    
                })
           
              //this.setState({ RedirectResercherKolList: true })
            }
            else {
              Swal.fire({
                icon: 'success',
                text: response.data.message,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
              this.setState({
                ShowBar: false,                    
                })

                this.setState({RedirectIdentifireForm:true})
           
            }
          })
          .catch(error => {
            console.log(error);
          });
        
      }
      else {
        Swal.fire({
          // info
          icon: 'error',
          text: 'Please Enter All Mandatory Fields',
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
        })

        this.setState({
            ShowBar: false,                    
       })
       
      }

    const options = {
        method: 'POST',
    }
    // Country list(market) 
    fetch(global.websiteUrl+'/service/Location/get_CountryList', options)
      .then((Response) => Response.json())
      .then((findresponse) => {
        console.log(findresponse)
        var testDataCountryList = findresponse.Country_Details;
        console.log("testDataCountryList", JSON.stringify(testDataCountryList));
        this.setState({
          CountryListData: findresponse.Country_Details,
          ShowBar: false,
          progressBar: false,
        })

        // console.log("Client_Unique_id", this.state.Client_Unique_id);
      })


       }
      

    render() {

        let speciality_type = this.state.SpecialityData;
        let OptionsSpeciality = speciality_type.map((speciality_type) =>
            <option key={speciality_type.id} value={speciality_type.id}>
                {speciality_type.speciality_type}</option>
        );


        if (this.state.redirectFormManagement) {
            // return (<Redirect to={'/KolProfileForm'}/>)
            //  emp_unique_id : '',
            // SrNO: '', 
            return (<Redirect to={{
                pathname: '/KolProfileForm',
                // state:{ 
                //     SrNO: this.state.SrNO , 
                //     emp_unique_id: this.state.emp_unique_id , 
                //     project_id: this.state.project_id,
                //     RID: this.state.RID ,
                //     resercher_name: this.state.resercher_name,
                //     Project_Name:  this.state.Project_Name,
                //     } 
            }}
            />)
        }

        if (this.state.RedirectEditKOL) {
            // return (<Redirect to={'/KolProfileForm'}/>)
            //  emp_unique_id : '',
            // SrNO: '', 
            return (<Redirect to={{
                pathname: '/EditKolProfileForm',
                // state:{ 
                //     emp_unique_id: this.state.emp_unique_id, 
                //     kol_unique_id: this.state.kol_unique_id,
                //     SrNO: this.state.SrNO,
                //     project_id: this.state.project_id,
                //     kol_status: this.state.kol_status,
                //     RID: this.state.RID ,
                //     resercher_name: this.state.resercher_name,
                //     Project_Name:  this.state.Project_Name,
                // } 
            }} />)
        }

        if (this.state.RedirectFlag) {
            return (<Redirect to={{
                pathname: '/ResercherDashboard',
                state: {
                    RID: this.state.RID,
                    resercher_name: this.state.resercher_name,
                }
            }} />)
        }

        if (this.state.RedirectIdentifireForm) {
            return (<Redirect to={{
                pathname: '/IdentifierForm',
            }} />)
        }

        if (this.state.RedirectcheckduplicateForm) {
            return (<Redirect to={{
                pathname: '/DuplicateViewKolList',
            }} />)
        }

        
        if (this.state.RedirectEdiIdentifireForm) {
            return (<Redirect to={{
                pathname: '/EditIdentifireForm',
            }} />)
        }

        const pageButtonRenderer = ({
            page,
            active,
            disable,
            title,
            onPageChange
        }) => {
            const handleClick = (e) => {
                e.preventDefault();
                onPageChange(page);
            };
            return (
                <Button outline color="danger" className="mr-2" onClick={handleClick} > {page} </Button>
            );
        };

        const options = {
            pageButtonRenderer,
            paginationSize: 4,
            pageStartIndex: 1,
            // alwaysShowAllBtns: true, // Always show next and previous button
            // withFirstAndLast: false, // Hide the going to First and Last page button
            // hideSizePerPage: true, // Hide the sizePerPage dropdown always
            // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: customTotal,
            disablePageTitle: true,
            sizePerPageList: [{
                text: '50', value: 50
            }, {
                text: '100', value: 100
            }, {
                text: '200', value: 200
            }, {
                text: 'All', value: this.state.projectList.length
            }]  // A numeric array is also available. the purpose of above example is custom the text

        };



        return (
            <div style={{marginTop:'15vh' }}>

            <div>
          {this.state.ShowBar ? <Modal isOpen={true} className='propagateLoader' centered>
            <PropagateLoader color={'#A90007'} size='40px' loading='true' />
          </Modal> : null}
        </div>
        {this.state.ShowProcedure ? <Modal isOpen={true} className='propagateLoader' centered>
          <PropagateLoader color={'#A90007'} size='40px' loading='true' />
        </Modal> : null}
                {/* {this.state.ShowBar ?
                    <div style={{ position: 'fixed', top: '0' }}>
                        <LoadingBar
                            progress='100'
                            // onLoaderFinished={() => setProgress(0)}
                            //  progress={progress}
                            height={3} color="#A90007" transitionTime={4000}
                            loaderSpeed={4000} />
                    </div>
                    : null
                } */}
                <div>
                    <div>
                        <div>
                            <ResercherHeader ResercherName={this.state.resercher_name} />
                            <div class="container">
                                <div class="content-wrapper">
                                    <div class="screen-headingbox">
                                        <div className="back-section"  >
                                            <a onClick={this.BackToDashboard.bind(this)} >
                                                <img src={require('../Assets/Images/back-arrow.svg')} className="backbutton" />
                                                Back
                                            </a>
                                        </div>
                                            <h2>Check Duplicate</h2>
                                        {/* <h2>Check Duplicate <button style={{ visibility: this.state.showAddnewKol ? "visible" : "hidden" }}
                                            class="cetas-button float-right"
                                            onClick={this.formManagementNav.bind(this)}> Check Duplicate</button>
                                        </h2> */}
                                    </div>
                                    {/* <h3 class="mt-30 mb-30"></h3> */}
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-xs-12 emplist-box">
                                            <div className="row">
                                                <div className="col-md-12">

                                                    {/* start for check duplicate */}
                                            
                                                    <div className="row" style={{marginBottom:'20px'}}>
      
                                                        <div className="col-md-3">
                                                    {/* <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                                    First Name <span class="mandatory">*</span></label> */}
                                                                <input type="text" class="form-control"
                                                                    //autocomplete="off"
                                                                    style={{ height: '45px' }}
                                                                    id="input4" placeholder="Enter First Name"
                                                                    name="first_name"
                                                                     value={this.state.checkDuplicate.first_name}
                                                                    onChange={(e) => { 

                                                                        this.setState({firstnameErr:''})

                                                                        var re = /^[a-z\- ]+$/i;
                                                                        if (re.test(e.target.value)) {
                                                                            //this.setState({first_name:e.target.value,})
                                                                            let { checkDuplicate } = this.state;
                                                                            checkDuplicate.first_name = e.target.value;
                                                                            this.setState({ checkDuplicate });
                                                                        }
                                                                        if (e.target.value.length == '0') {
                                                                            
                                                                            this.onKeyPressRestrictOnlyChar(e)
                                                                            let { checkDuplicate } = this.state;
                                                                            checkDuplicate.first_name = '';
                                                                            this.setState({ checkDuplicate });
                                                                        }
                                                                    }}
                                                                />
                                                                <div className="loginErrCreateProject">{this.state.firstnameErr}</div>
                                                               
                                                                </div>
                                                                
                                                                <div className="col-md-3">
                                                    {/* <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                                    First Name <span class="mandatory">*</span></label> */}
                                                                <input type="text" class="form-control"
                                                                   // autocomplete="off"
                                                                   style={{ height: '45px' }}
                                                                    id="input5" placeholder="Enter Last Name"
                                                                    name="last_name"
                                                                     value={this.state.checkDuplicate.last_name}
                                                                    onChange={(e) => {
                                                                        this.setState({lastnameErr:''})
                                                                       
                                                                        var re = /^[a-z\- ]+$/i;
                                                                        if (re.test(e.target.value)) {
                                                                            //this.setState({last_name:e.target.value,})
                                                                            let { checkDuplicate } = this.state;
                                                                            checkDuplicate.last_name = e.target.value;
                                                                            this.setState({ checkDuplicate });
                                                                        }
                                                                        if (e.target.value.length == '0') {
                                                                           // this.setState({last_name:'',})
                                                                           
                                                                            this.onKeyPressRestrictOnlyChar(e)
                                                                            let { checkDuplicate } = this.state;
                                                                            checkDuplicate.last_name = '';
                                                                            this.setState({ checkDuplicate });
                                                                        }
                                                                    }}
                                                                />
                                                                <div className="loginErrCreateProject">{this.state.lastnameErr}</div>
                                                          
                                                                </div>
                                                                <div className="col-md-3">
                                                                    {/* <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                                    Speciality </label> */}
                                                                    <Input type="select" id="roleSelect" className="form-control"
                                                                        style={{ height: '45px' }}
                                                                        name="specialty_id"
                                                                         value={this.state.checkDuplicate.specialty_id}
                                                                        onChange={async (e) => {
                                                                            this.setState({specilityErr:''})
                                                                       
                                                                          //  this.state.specility=e.target.value;
                                                                            let { checkDuplicate } = this.state;
                                                                            checkDuplicate.specialty_id = e.target.value;
                                                                            //PersonalInformation.procedure_type_id = '';
                                                                            //PersonalInformation.sub_specialty_id = '';
                                                                            await this.setState({ checkDuplicate });
                                                                           
                                                                            
                                                                        }}
                                                                    >
                                                                        <option value="" >--Select Speciality--</option>
                                                                        {OptionsSpeciality}
                                                                    </Input>
                                                                    
                                                                     <div style={{
                                                                        color: "red", fontSize: "12px", marginTop: "1%",
                                                                        marginBottom: "5px",
                                                                    }}>
                                                                       {this.state.specilityErr}
                                                           
                                                                        </div> 
                                                                        
                                                                </div>


                                                                <div className="col-md-3">

                                                                <button class="cetas-button float-left" onClick={this.DuplicateCheckFunc.bind(this)}> Check Duplicate</button>
                                                                                                        
                                                                </div>

                                                    </div>
                                                   {/* End Duplicate Check */}

                                                   {
                                                    (this.state.DuplicateDivDisplay)?
                                                    (

                                                    <div >
                                                        {/* <h3>Total KOL Profiles for {this.state.Project_Name}</h3><br/> */}
                                                        <div className="employee-actions">
                                                            <div className="table-infobox">
                                                                <p className="list-count" style={{ color:'black' , fontWeight:'bold' }}>{this.state.projectList.length} Duplicate KOL's Listed</p>
                                                                <div className="filter-box float-right mb-30">
                                                                    <div className="input-group">
                                                                        {/* <div className="input-group-prepend">
                                                                                    <div className="input-group-text"><img src={require("../Assets/Images/search.svg")} /></div>
                                                                                </div>
                                                                                <input type="text" className="form-control" id="inlineFormInputGroupUsername" placeholder="Search by Name, project"
                                                                                    onChange={(e) => { this.onTextChange(e) }}
                                                                                /> */}
                                                                    </div>
                                                                    {/* <div className="input-group"> */}
                                                                        {/* <div className="input-group-prepend">
                                                                                    <div className="input-group-text"><img src={require("../Assets/Images/search.svg")} /></div>
                                                                                </div>
                                                                                <select className="form-control no-border-top no-border-left no-border-right" id="exampleFormControlSelect1"
                                                                                    onChange={(e) => { this.onTextChange(e) }}
                                                                                >
                                                                                    <option value="">Sort by alphabates</option>
                                                                                    <option>A</option>
                                                                                    <option>B</option>
                                                                                    <option>C</option>
                                                                                    <option>D</option>
                                                                                </select> */}
                                                                        {/* <div className="input-group-prepend">
                                                                            <div className="input-group-text"><img src={require("../Assets/Images/search.svg")} /></div>
                                                                        </div>
                                                                        <input type="text" className="form-control" id="inlineFormInputGroupUsername" placeholder="Search by KOL Name"
                                                                            onChange={(e) => { this.onTextChange(e) }}
                                                                        />
                                                                    </div> */}


                                                                </div>
                                                            </div>


                                                            <div className="sort-roll">
                                                                <select className="form-control" id="exampleFormControlSelect1"
                                                                    onChange={(e) => { this.onFilter(e) }}
                                                                >
                                                                    <option value="">Select Status</option>
                                                                    <option>Submited</option>
                                                                    <option>Work In Progress</option>
                                                                    <option>Approved</option>
                                                                    <option>Rejected</option>
                                                                    <option>Prelim</option>
                                                                    <option>Prelim Approved</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <ToolkitProvider
                                                            keyField="emp_id"
                                                            data={this.state.projectList}
                                                            columns={this.state.columns}
                                                            search={this.customMatchFunc}
                                                            //exportCSV
                                                            exportCSV={{
                                                                fileName: 'cetas.csv',
                                                                noAutoBOM: false,
                                                            }}
                                                        >
                                                            {
                                                                props => (
                                                                    <div>
                                                                        {/* <ExportCSVButton { ...props.csvProps }>Export CSV!!</ExportCSVButton> */}
                                                                        {/* <hr /> */}
                                                                        <BootstrapTable
                                                                            {...props.baseProps}
                                                                            // selectRow={ selectRow }
                                                                            noDataIndication="Table is Empty"
                                                                            striped
                                                                            hover
                                                                            condensed
                                                                            loading={true}
                                                                            pagination={paginationFactory(options)}
                                                                            filter={filterFactory()}
                                                                            rowStyle={ { backgroundColor: 'White' } }

                                                                        // cellEdit={ cellEditFactory({
                                                                        //     mode: 'click',
                                                                        //     blurToSave: true

                                                                        // }) }
                                                                        />
                                                                    </div>
                                                                )
                                                            }
                                                        </ToolkitProvider>

                                                    </div>
                                                    ):(<div></div>)
                                                    }




                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DuplicateViewKolList;