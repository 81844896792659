import React, { Component } from 'react';

//datatable npm
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
// showing progress bar npm 
import { PropagateLoader } from 'react-spinners';
import LoadingBar from "react-top-loading-bar";
import axios from 'axios';
import { Redirect, Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import Axios from 'axios';
import { Multiselect } from 'react-widgets';
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Button, Alert } from 'reactstrap';


// session storage item:
// visitor_email: is the email of login employee
// 'kolInfo' : to set search result of all kol to session
// 'visitor_id' : is the id of login employee
// 'visitor_name' : is name of login employee
//  'kol_id' : is selected kol to get its personal info


const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total ml-2">
    Showing {from} to {to} of {size} Results
  </span>
);

const selectRow = {
  mode: 'checkbox',
  // clickToSelect: true
};

const { ExportCSVButton } = CSVExport;

class VisitorDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // visitor_id: this.props.location.state.visitor_id,
      // visitor_name: this.props.location.state.visitor_name,
      visitor_id: '',
      visitor_name: '',
      visitor_email: '',

      redirectProileInfo: false,
      RedirectLogin: false,

      // state for progress bar
      ShowBar: false,
      progressBar: false,
      isSearch: false,
      RedirectEmployeeKolList: false,

      // to get data from dropdown
      SpecialityData: [],
      ProcedureData: [],
      SubSpecialityData: [],
      CountryListData: [],
      Profile: [],
      ProfileBySession: [],
      Market: '',

      // to set form values
      Speciality: '',
      SubSpecialityType: '',
      ProcedureType: '',
      NameForSearch: '',

      // Column state for Bootstrap Table 
      columns: [
        {
          dataField: 'kol_unique_id',
          text: 'ID',
          sort: true,
          csvExport: false
        },
        {
          dataField: 'doctor_full_name',
          text: 'KOL Name',
          formatter: this.linkToDetails,
          // sort: true,
          filter: textFilter(),
          filterRenderer: (onFilter, column) => {
            this.onProjectNameFilter = onFilter;
            return null;
          },
        },
        {
          dataField: "rank",
          text: "Ranking",
          hidden: true,
        },
        {
          dataField: "speciality_type",
          text: "Speciality Type",
          hidden: true,
        },
        {
          dataField: "sub_speciality_type",
          text: "Sub Speciality",
          hidden: true,
        },
        {
          dataField: "procedure_type",
          text: "Procedure Type",
          hidden: true,
        },
        {
          dataField: "doctor_email",
          text: "Email",
          hidden: true,
        },
        {
          dataField: "doctor_contact_no",
          text: "Mobile No.",
          hidden: true,
        },
        {
          dataField: 'qualification',
          text: 'Degree',
          // hidden: true                              to show data in export file
          csvExport: false
        },
        {
          dataField: "speciality_type",
          text: "Speciality",
          csvExport: false
          // align: 'center',
          // headerAlign: 'center'
        },
        {
          dataField: "state_name",
          text: "State",
          csvExport: false
        },
        {
          dataField: "country_name",
          text: "Country",
        },
        {
          dataField: "procedure_potential",
          text: "Procedure Potential",
          csvExport: false
        },
        // {
        //   dataField: "",
        //   text: "Download",
        //   formatter: this.linkFollow,
        //   csvExport: false,
        // },
      ],
    };
    // Functions will be Bind here
    this.togglesearch = this.togglesearch.bind(this);
  }

  componentDidMount = async () => {

    let getSession_visitor_email = await sessionStorage.getItem('visitor_email')
    if (getSession_visitor_email !== null) {
      await this.setState({
        visitor_email: getSession_visitor_email,
      })
    }

    let getSession_visitor_id = await sessionStorage.getItem('visitor_id')
    if (getSession_visitor_id !== null) {
      await this.setState({
        visitor_id: getSession_visitor_id,
      })
    }
    else
    {
      this.setState({redirect: true})
      
    }

    let getSession_visitor_name = await sessionStorage.getItem('visitor_name')
    if (getSession_visitor_name !== null) {
      await this.setState({
        visitor_name: getSession_visitor_name,
      })
    }

    console.log("getSession_visitor_email ====", getSession_visitor_email)
    console.log("getSession_visitor_id ====", getSession_visitor_id)
    console.log("getSession_visitor_name ====", getSession_visitor_name)

    this.setState({
      ShowBar: true,
      progressBar: true,
    })

    const options = {
      method: 'POST',
    }

    //  Speciality type data
    fetch(global.websiteUrl+'/service/Speciality/get_SpecialityList', options)
      .then((Response) => Response.json())
      .then((findresponse) => {
        console.log(findresponse)
        var testDataSpeciality = findresponse.data;
        console.log("testDataSpeciality", JSON.stringify(testDataSpeciality));
        this.setState({
          SpecialityData: findresponse.data,
          ShowBar: false,
          progressBar: false,
        })
        console.log("Length of SpecialityData ", this.state.SpecialityData.length);

      })

    // Country list(market) 
    fetch(global.websiteUrl+'/service/Location/get_CountryList', options)
      .then((Response) => Response.json())
      .then((findresponse) => {
        console.log(findresponse)
        var testDataCountryList = findresponse.Country_Details;
        console.log("testDataCountryList", JSON.stringify(testDataCountryList));
        this.setState({
          CountryListData: findresponse.Country_Details,
          ShowBar: false,
          progressBar: false,
        })
        // console.log("Client_Unique_id", this.state.Client_Unique_id);
      })

    // To assign employee list to session if it already set
    let getSessionDataOfProfileInfo = [];
    let getSessionDataOfProfileInfoCheck = sessionStorage.getItem('kolInfo')
    if (getSessionDataOfProfileInfoCheck == null) {
      getSessionDataOfProfileInfo = [];
      // this.setState({
      //   ProfileBySession: getSessionDataOfProfileInfo
      // })
    }
    else {
      let getSessionDataOfProfileInfoParse = sessionStorage.getItem('kolInfo');
      getSessionDataOfProfileInfo = JSON.parse(getSessionDataOfProfileInfoParse);
      // this.setState({
      //   ProfileBySession: getSessionDataOfProfileInfo
      // })
    }
    console.log("getSessionDataOfProfileInfo", getSessionDataOfProfileInfo)
  }

  getSubSpecialityList() {
    console.log("in Procedure list");
    console.log("speciality_type_id", this.state.Speciality);

    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
    let formData = new FormData();
    formData.append('speciality_type_id', this.state.Speciality);
    formData.append('search_type', "Sub Speciality");

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var SubSpecialityData = response.data;
        console.log("SubSpecialityData", JSON.stringify(SubSpecialityData));
        console.log(SubSpecialityData.status)
        // if(this.state.SubSpecialityData.status == false){
        //     this.setState({
        //         SubSpecialityData : [],
        //     })
        // } else {
        //     this.setState({
        //         SubSpecialityData : response.data.data,
        //     }) 
        // } 
        if (response.data.status == true) {
          this.setState({
            SubSpecialityData: response.data.data,
          })
        }
        else if (response.data.status == false) {
          this.setState({
            SubSpecialityData: [],
          })
        }
        console.log("SubSpecialityData====", this.state.SubSpecialityData)
        console.log("Length of SubSpecialityData ", this.state.SubSpecialityData.length);
      })
      .catch(error => {
        console.log(error);
      });
  }

  getProcedureList() {
    console.log("in Procedure list");
    console.log("speciality_type_id", this.state.Speciality);

    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
    let formData = new FormData();
    formData.append('speciality_type_id', this.state.Speciality);
    formData.append('search_type', "Procedure");

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var proceduredata = response.data;
        console.log("proceduredata", JSON.stringify(proceduredata));
        // this.setState({
        //     ShowBar: false,
        //     progressBar: false,
        //     ProcedureData : response.data.data,
        // }) 
        if (response.data.status == true) {
          this.setState({
            ShowBar: false,
            progressBar: false,
            ProcedureData: response.data.data,
          })
        }
        else if (response.data.status == false) {
          this.setState({
            ShowBar: false,
            progressBar: false,
            ProcedureData: [],
          })
        }
        // console.log("ProcedureData",this.state.ProcedureData)
      })
      .catch(error => {
        console.log(error);
      });
  }

  search = async () => {
    this.setState({
      ShowBar: true,
      progressBar: true,
    })
    console.log('in Search')
    console.log("Name for search", this.state.NameForSearch)
    console.log("Speciality ID", this.state.Speciality)
    console.log("Sub Speciality ID", this.state.SubSpecialityType)
    console.log("Procedure ID", this.state.ProcedureType)
    console.log("Country selected ID", this.state.Market)

    try {

      const url = global.websiteUrl+'/service/KOL/get_Category_Wise_KOL_List';
      let formData = new FormData();
      formData.append('kol_name', this.state.NameForSearch);
      formData.append('speciality', this.state.Speciality);
      formData.append('subspeciality', this.state.SubSpecialityType);
      formData.append('procedure', this.state.ProcedureType);
      formData.append('country', this.state.Market);

      const config = {
        // headers: { 'content-type': 'multipart/form-data' }
      }

      Axios.post(url, formData, config)
        .then(response => {
          console.log(response.data);
          console.log(response.data.message);
          if (response.data.status == false) {
            Swal.fire({
              icon: 'error',
              text: 'No List Present of Selected Category',
              // text: 'Wrong Username or Password!',
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })

            this.setState({
              Profile: [],
              ShowBar: false,
              progressBar: false,
            })
          }
          else if (response.data.status == true) {
            console.log(response.data.KOL_Personal_Info);
            console.log(response.data.message);
            this.Profile = response.data.KOL_Personal_Info;
            console.log(this.Profile.length)
            // console.log(JSON.stringify(Profile));
            this.setState({
              Profile: response.data.KOL_Personal_Info,
              ShowBar: false,
              progressBar: false,
            })
            // sessionStorage.setItem('kolInfo', data) 
            if (response.data.KOL_Personal_Info) {
              sessionStorage.setItem('kolInfo', JSON.stringify(response.data.KOL_Personal_Info))
            }
            else {
              sessionStorage.setItem('kolInfo', [])
            }
            let getSessionDataOfProfileInfo = [];
            let getSessionDataOfProfileInfoCheck = sessionStorage.getItem('kolInfo')
            if (getSessionDataOfProfileInfoCheck == null) {
              getSessionDataOfProfileInfo = [];
              this.setState({
                ProfileBySession: getSessionDataOfProfileInfo
              })
            }
            else {
              let getSessionDataOfProfileInfoParse = sessionStorage.getItem('kolInfo');
              getSessionDataOfProfileInfo = JSON.parse(getSessionDataOfProfileInfoParse);
              this.setState({
                ProfileBySession: getSessionDataOfProfileInfo
              })
            }
            console.log("getSessionDataOfProfileInfo", getSessionDataOfProfileInfo)
          }
        })
    }
    catch (e) {
      console.log(e);
    }
    this.setState({
      isSearch: false,
    })
  }

  // Funtion for displaying 'Edit' & 'delete' buttons inside Bootstrap Grid
  linkFollow = (cell, row, rowIndex, formatExtraData) => {
    console.log("in dowload resume linkFollow");
    return (
      <div>
        {console.log("in dowload bio linkFollow row.bio_pdf", row.bio_pdf)}
        {
          (row.bio_pdf !== null) ? (
            <a
              href={row.bio_pdf} target="_blank">
              <button className="cetas-button cetas-button-small mr-2">
                Bio
              </button>
            </a>
          ) : (
            <div></div>
          )
        }
        {console.log("in dowload profile linkFollow row.profile_pdf", row.profile_pdf)}
        {
          (row.profile_pdf !== null) ? (
            <a
              href={row.profile_pdf} target="_blank">
              <button className="cetas-button cetas-button-small mr-2">
                Profile
              </button>
            </a>
          ) : (
            <div></div>
          )
        }
        {console.log("in dowload resume linkFollow row.profile_pdf", row.resume)}
        {
          (row.resume !== null) ? (
            <a
              href={row.resume} target="_blank">
              <button className="cetas-button cetas-button-small mr-2">
                CV
              </button>
            </a>
          ) : (
            <div></div>
          )
        }
      </div>
    );
  };

  DownloadCV(linkResume) {
    console.log("in dowload resume last");
    return (
      <a href={linkResume} target="_blank">
        {/* <text>{itemsProcedurePotential}</text> */}
      </a>
    )
  }

  togglesearch() {
    this.setState({
      isSearch: !this.state.isSearch,
    })
  }

  onProjectNameChange = (event) => {
    const { value } = event.target;
    this.onProjectNameFilter(value);
  };

  // nanu f
  BackToDashboard() {
    this.setState({ RedirectFlag: true })
  }
  profileInfoNav(kol_id) {
    sessionStorage.setItem('kol_id', JSON.stringify(kol_id))
    this.setState({
      redirectProileInfo: true,
    })
  }

  //Funtion to apply links to project names
  linkToDetails = (cell, row) => {
    var kol_id = row.kol_unique_id;
    return (
      <div>
        <a style={{
          fontFamily: 'arial, sans-serif', color: '#069',
          textDecoration: 'underline', cursor: 'pointer'
        }}
          onClick={this.profileInfoNav.bind(this, kol_id)}> {row.doctor_full_name} </a>
      </div>
    )
  }
  // nanu t

  logout = () => {
    console.log("in logout")
    this.setState({ RedirectLogin: true })
    sessionStorage.removeItem('visitor_email')
    sessionStorage.removeItem('kolInfo')
    sessionStorage.removeItem('visitor_id')
    sessionStorage.removeItem('visitor_name')
  }


  render() {
    if (this.state.redirectProileInfo) {
      return (<Redirect to={{
        pathname: '/VisitorKolProfile',
      }} />)
    }
    if (this.state.RedirectLogin) {
      return (<Redirect to={'/'} />)
    }

    // Speciality Type options 
    let speciality_type = this.state.SpecialityData;
    let OptionsSpeciality = speciality_type.map((speciality_type) =>
      <option key={speciality_type.id} value={speciality_type.id}>
        {speciality_type.speciality_type}</option>
    );

    // Procedure List
    let procedure_list = this.state.ProcedureData;
    let OptionProcedure = procedure_list.map((procedure_list) =>
      <option key={procedure_list.id} value={procedure_list.id}>
        {procedure_list.procedure_type}</option>
    );

    // Sub Speciality List
    let subSpeciality_list = this.state.SubSpecialityData;
    let OptionSubSpeciality = subSpeciality_list.map((subSpeciality_list) =>
      <option key={subSpeciality_list.id} value={subSpeciality_list.id}>
        {subSpeciality_list.sub_speciality_type}</option>
    );

    // For rendering pageination buttons
    const pageButtonRenderer = ({
      page,
      active,
      disable,
      title,
      onPageChange
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        onPageChange(page);
      };
      return (
        <li class="page-item" onClick={handleClick} ><a class="page-link">{page}</a></li>
      );
    };


    // options for passing values to bootstrap table 2
    const options = {
      pageButtonRenderer,
      paginationSize: 5,
      pageStartIndex: 1,
      // alwaysShowAllBtns: true, // Always show next and previous button
      // withFirstAndLast: false, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      // firstPageText: 'First',
      prePageText: 'Previous',
      nextPageText: 'Next',
      // lastPageText: 'Last',
      // nextPageTitle: 'First page',
      // prePageTitle: 'Pre page',
      // firstPageTitle: 'Next page',
      // lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '50', value: 50
      }, {
        text: '100', value: 100
      }, {
        text: '200', value: 200
      }, {
        text: 'All', value: this.state.ProfileBySession.length
      }] // A numeric array is also available. the purpose of above example is custom the text

    };
    const MyExportCSV = (props) => {
      const handleClick = () => {
        props.onExport();
      };
      return (
        <div>
          <button class="cetas-button" onClick={handleClick}>Export</button>
        </div>
      );
    };

    return (
      <div>
        {this.state.ShowBar ? <Modal isOpen={true} centered>
          <PropagateLoader color={'#A90007'} size='30px' loading='true' />
        </Modal> : null}
        {this.state.progressBar ? <div style={{ position: 'fixed', top: '0' }}>
          <LoadingBar
            progress='100'
            height={3} color="#A90007" transitionTime={4000}
            loaderSpeed={3000} />
        </div> : null}
        <div>
          <div>
            <Modal id="search" isOpen={this.state.isSearch} toggle={this.togglesearch.bind()} style={{ padding: '15px' }}>
              <ModalHeader toggle={this.togglesearch.bind(this)} style={{ border: 'none' }} cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}>
                <h3 style={{ marginTop: '40px', marginLeft: '5%' }}>Search KOL</h3>
              </ModalHeader>
              <ModalBody style={{ padding: '20px 80px 10px 80px' }}>
                <FormGroup>
                  <Label for="exampleFormControlTextarea1">Name</Label>
                  <input type="text" className="form-control" id="exampleInputPassword1"
                    placeholder="Enter Name"
                    value={this.state.NameForSearch}
                    onChange={(e) => {
                      let { NameForSearch } = this.state;
                      NameForSearch = e.target.value;
                      this.setState({ NameForSearch });
                    }}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="exampleFormControlTextarea1">Speciality</Label>
                  <Input type="select" id="roleSelect" className="form-control"
                    style={{ height: '50px' }}
                    value={this.state.Speciality}
                    onChange={async (e) => {
                      let { Speciality } = this.state;
                      Speciality = e.target.value;
                      await this.setState({ Speciality });
                      { this.getSubSpecialityList() }
                      { this.getProcedureList() }
                    }}>
                    <option value="0" >--Select Speciality--</option>
                    {OptionsSpeciality}
                  </Input>
                </FormGroup>
                <FormGroup>
                  {
                    (!this.state.Speciality) ? (
                      // <label for="exampleFormControlTextarea1">Procedure Type</label>
                      <div></div>
                    ) : (
                      <div>
                        <label for="exampleFormControlTextarea1">Sub Speciality Type</label>
                        <Input type="select" id="roleSelect" className="form-control"
                          style={{ height: '50px' }}
                          value={this.state.SubSpecialityType}
                          onChange={(e) => {
                            let { SubSpecialityType } = this.state;
                            SubSpecialityType = e.target.value;
                            this.setState({ SubSpecialityType });
                          }}>
                          <option value="0" >--Select Sub Speciality Type--</option>
                          {OptionSubSpeciality}
                        </Input>
                      </div>
                    )
                  }
                </FormGroup>
                <FormGroup>
                  {
                    (!this.state.Speciality) ? (
                      // <label for="exampleFormControlTextarea1">Procedure Type</label>
                      <div></div>
                    ) : (
                      <div>
                        <label for="exampleFormControlTextarea1">Procedure Type</label>
                        <Input type="select" id="roleSelect" className="form-control"
                          style={{ height: '50px' }}
                          value={this.state.ProcedureType}
                          onChange={(e) => {
                            let { ProcedureType } = this.state;
                            ProcedureType = e.target.value;
                            this.setState({ ProcedureType });
                          }}>
                          <option value="0" >--Select Procedure Type--</option>
                          {OptionProcedure}
                        </Input>
                      </div>
                    )
                  }
                </FormGroup>
                <FormGroup>
                  <div>
                    <label for="exampleFormControlTextarea1">
                      Countries <span class="mandatory">*</span></label>
                    <Multiselect
                      data={this.state.CountryListData}
                      placeholder='--Select Countries--'
                      valueField='id'
                      textField='country_name'
                      onSelect={(valueField) => console.log(valueField)}
                      onChange={(event) => {
                        console.log(event);
                        var joined = [];
                        console.log("length : " + event.length);
                        if (event.length === 0) {
                          this.setState({ Market: [] });
                        }
                        event.map(async (data) => {
                          console.log("data in eve map : " + JSON.stringify(data));
                          joined = joined.concat(data.id);
                          console.log("data join : " + joined);
                          await this.setState({
                            Market: joined
                          });
                          console.log("check this", this.state.Market)
                        })
                      }}
                    />
                  </div>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <button color="danger" class="cetas-button cetas-button-small mr-2" onClick={this.search.bind(this)}> Search </button>
                <button class="cetas-secondry-button cetas-button-small" onClick={this.togglesearch.bind(this)}>Cancel</button>
              </ModalFooter>
            </Modal>
            <div>
              <div className="header-nav">
                <div className="container">
                  <nav className="navbar navbar-expand-lg">
                    <a style={{ display: "table-cell" }} className="navbar-brand"
                      href="https://cetas-healthcare.com/" target="_blank">
                      <img
                        src={require('../Assets/Images/cetas-logo.png')}
                      />
                    </a>

                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                      data-target="#navbarNavAltMarkup"
                      aria-controls="navbarNavAltMarkup" aria-expanded="false"
                      aria-label="Toggle navigation">
                      <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                      <div className="navbar-nav" style={{ marginLeft: '80%' }}>
                        {/* <a class="nav-item nav-link active" href="#">Home <span class="sr-only">(current)</span></a> */}
                        {/* <a class="nav-item nav-link active" href="#">Projects</a> */}
                        {/* <li class="nav-item  dropdown multi-option">
                                                <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    KOLs
                                                </a>
                                                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                                    <ul><a class="dropdown-item" href="#">KOL Ranking</a></ul>
                                                    <ul><a class="dropdown-item" href="#">KOL QC</a></ul>
                                                </div>
                                            </li> */}
                        <a class="nav-item nav-link" data-toggle="modal" data-target="#search"

                          onClick={this.togglesearch.bind()}>
                          <img src={require('../Assets/Images/setting.svg')} />
                          Search
                        </a>
                      </div>
                    </div>

                    <a className="nav-item nav-link username-box" >
                      <img
                        src={require('../Assets/Images/user.svg')}
                      />
                      Welcome, {this.state.visitor_name}
                    </a>

                    <a className="nav-item nav-link username-box  float-right"
                      onClick={this.logout.bind(this)}>
                      <img
                        src={require('../Assets/Images/logoutByVibha.svg')}
                      />
                      Logout
                    </a>
                  </nav>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="content-wrapper">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12 emplist-box">
                  <div className="row">
                    <div className="col-md-12">
                      <h3><b>Opinion Leaders</b></h3>

                      <div className="employee-actions">
                        <div className="table-infobox">
                          <p className="list-count" style={{ color: 'white' }}>{this.state.ProfileBySession.length} employees listed</p>
                          <div class="filter-box float-right mb-30">
                            {/* <div class="input-group float-right">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text"><img src={require("../Assets/Images/search.svg")} /></div>
                                            </div>
                                            <input type="text" className="form-control" 
                                                    id="inlineFormInputGroupUsername" 
                                                    placeholder="Search by KOL Name"
                                                    onChange={ (e) => { this.onProjectNameChange(e) }} 
                                            />
                                        </div> */}

                            {/* <div class="input-group">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text"><img src={require("../Assets/Images/search.svg")} /></div>
                                            </div>
                                            <select class="form-control no-border-top no-border-left no-border-right" id="exampleFormControlSelect1">
                                            <option>Sort by alphabates</option>
                                            <option>A</option>
                                            <option>B</option>
                                            <option>C</option>
                                            <option>D</option>
                                            </select>
                                        </div> */}
                          </div>
                        </div>
                      </div>
                      <ToolkitProvider
                        keyField="kol_unique_id"
                        data={this.state.ProfileBySession}
                        columns={this.state.columns}
                        search={this.customMatchFunc}
                        //exportCSV
                        exportCSV={{
                          fileName: 'cetas.csv',
                          noAutoBOM: false,
                          onlyExportSelection: true,
                          exportAll: true
                        }}
                      >
                        {
                          props => (
                            <div>
                              <BootstrapTable
                                {...props.baseProps}
                                // keyField='kol_unique_id'  
                                // data = { this.state.ProfileBySession }
                                // columns={ this.state.columns } 
                                noDataIndication="Table is Empty...Please Serach KOL as your requirement..From header Search option"
                                filter={filterFactory()}
                                selectRow={selectRow}
                                pagination={paginationFactory(options)}
                                striped
                                hover
                                condensed
                                tabIndexCell
                              />
                              <hr></hr>
                              {/* <ExportCSVButton { ...props.csvProps }>Export</ExportCSVButton> */}
                              <MyExportCSV {...props.csvProps} />
                            </div>
                          )
                        }
                      </ToolkitProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default VisitorDashboard;