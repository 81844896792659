import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button } from 'reactstrap';
import filterFactory, { textFilter, customFilter, selectFilter, Comparator, FILTER_TYPES } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import LoadingBar from "react-top-loading-bar";
import Swal from 'sweetalert2';
import { RingLoader, FadeLoader, DotLoader, PropagateLoader } from 'react-spinners';

import AdminHeader from './AdminHeader';


class Rating extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // project_id: this.props.location.state.project_id ,
            // p_name: this.props.location.state.p_name ,
            redirectDashbard: false,

            // Progress
            ShowProcedure: false,
            ShowBar: false,

            // flag for side menu      
            SpeakerScoreFlag: true,
            AdvisoryBoardScoreFlag: false,
            PublicationScoreFlag: false,
            InvestigatorScoreFlag: false,
            GuidelineScoreFlag: false,

            // to get values from input
            EventTypeErr: '',
            eventRoleErr: '',
            companyNameErr: '',
            advisoryRoleErr: '',
            publicationNameErr: '',
            publicationRoleErr: '',
            noOfLocationErr: '',
            typeOfTrialErr: '',
            typeOfOrganizationErr: '',

            // speaker
            // event_weightage: {
            //     id: '',
            //     event_conference_weightage: '',
            //     event_role_weightage: '',
            // },
            event_weightage: {},
            // adv board
            // advisory_board_weightage: {
            //     id: '',
            //     advisory_board_company_weightage: '',
            //     advisory_board_role_weightage: '',
            // },
            advisory_board_weightage: {},
            // publication
            // publication_weightage: {
            //     id: '',
            //     publication_journal_weightage: '',
            //     publication_role_weightage: '',
            // },
            publication_weightage: {},
            // investigator
            // clinical_trial_weightage: {
            //     id: '',
            //     clinical_trial_location_weightage: '',
            //     clinical_trial_study_type_weightage: '',
            // },
            clinical_trial_weightage: {},
            // guidelines
            // guideline_weightage: {
            //     id: '',
            //     guideline_company_weightage: '',
            // },
            guideline_weightage: {},
        };
    }

    componentDidMount() {
        this.setState({
            ShowBar: true,
        })

        const options = {
            method: 'POST',
        }
        fetch(global.websiteUrl+'/service/User/get_WeightagesList', options)
            .then((Response) => Response.json())
            .then((findresponse) => {
                console.log(findresponse)
                var weightageData = findresponse.data;
                console.log("weightageData", JSON.stringify(weightageData));
                this.setState({
                    event_weightage: findresponse.event_weightage,
                    advisory_board_weightage: findresponse.advisory_board_weightage,
                    clinical_trial_weightage: findresponse.clinical_trial_weightage,
                    guideline_weightage: findresponse.guideline_weightage,
                    publication_weightage: findresponse.publication_weightage,
                })
                this.setState({
                    ShowBar: false,
                })
            })
    }

    SpeakerScoreFunc = async () => {
        await this.setState({
            SpeakerScoreFlag: true,
            AdvisoryBoardScoreFlag: false,
            PublicationScoreFlag: false,
            InvestigatorScoreFlag: false,
            GuidelineScoreFlag: false,
        })
    }

    AdvisoryBoardScoreFunc = async () => {
        await this.setState({
            SpeakerScoreFlag: false,
            AdvisoryBoardScoreFlag: true,
            PublicationScoreFlag: false,
            InvestigatorScoreFlag: false,
            GuidelineScoreFlag: false,
        })
    }

    PublicationScoreFunc = async () => {
        await this.setState({
            SpeakerScoreFlag: false,
            AdvisoryBoardScoreFlag: false,
            PublicationScoreFlag: true,
            InvestigatorScoreFlag: false,
            GuidelineScoreFlag: false,
        })
    }

    InvestigatorScoreFunc = async () => {
        await this.setState({
            SpeakerScoreFlag: false,
            AdvisoryBoardScoreFlag: false,
            PublicationScoreFlag: false,
            InvestigatorScoreFlag: true,
            GuidelineScoreFlag: false,
        })
    }

    GuidelineScoreFunc = async () => {
        await this.setState({
            SpeakerScoreFlag: false,
            AdvisoryBoardScoreFlag: false,
            PublicationScoreFlag: false,
            InvestigatorScoreFlag: false,
            GuidelineScoreFlag: true,
        })
    }

    validatesaveasdraft = () => {
        let EventTypeErr = '';
        let eventRoleErr = '';
        let companyNameErr = '';
        let advisoryRoleErr = '';
        let publicationNameErr = '';
        let publicationRoleErr = '';
        let noOfLocationErr = '';
        let typeOfTrialErr = '';
        let typeOfOrganizationErr = '';

        if (this.state.SpeakerScoreFlag == true) {
            if (!this.state.event_weightage.event_conference_weightage) {
                EventTypeErr = 'Please Enter Event Type Percentage';
            }
            if (!this.state.event_weightage.event_role_weightage) {
                eventRoleErr = 'Please Enter Event Role Percentage';
            }
        }

        if (this.state.AdvisoryBoardScoreFlag == true) {
            if (!this.state.advisory_board_weightage.advisory_board_company_weightage) {
                companyNameErr = 'Please Enter Company Name Percentage';
            }
            if (!this.state.advisory_board_weightage.advisory_board_role_weightage) {
                advisoryRoleErr = 'Please Enter Role Percentage';
            }
        }

        if (this.state.PublicationScoreFlag == true) {
            if (!this.state.publication_weightage.publication_journal_weightage) {
                publicationNameErr = 'Please Enter Journal Name Percentage';
            }
            if (!this.state.publication_weightage.publication_role_weightage) {
                publicationRoleErr = 'Please Enter Role Percentage';
            }
        }

        if (this.state.InvestigatorScoreFlag == true) {
            if (!this.state.clinical_trial_weightage.clinical_trial_location_weightage) {
                noOfLocationErr = 'Please Enter No of Location Percentage';
            }
            if (!this.state.clinical_trial_weightage.clinical_trial_study_type_weightage) {
                typeOfTrialErr = 'Please Enter Type of Trial Percentage';
            }
        }

        if (this.state.GuidelineScoreFlag == true) {
            if (!this.state.guideline_weightage.guideline_company_weightage) {
                typeOfOrganizationErr = 'Please Enter type of Organization Percentage';
            }
        }

        if (EventTypeErr || eventRoleErr || companyNameErr || advisoryRoleErr || publicationNameErr || publicationRoleErr ||
            noOfLocationErr || typeOfTrialErr || typeOfOrganizationErr) {
            this.setState({
                EventTypeErr, eventRoleErr, companyNameErr, advisoryRoleErr, publicationNameErr, publicationRoleErr,
                noOfLocationErr, typeOfTrialErr, typeOfOrganizationErr
            });
            return false;
        }
        return true;

    }

    Save() {
        console.log('event_weightage', this.state.event_weightage);
        console.log('advisory_board_weightage', this.state.advisory_board_weightage);
        console.log('publication_weightage', this.state.publication_weightage);
        console.log('clinical_trial_weightage', this.state.clinical_trial_weightage);
        console.log('guideline_weightage', this.state.guideline_weightage);

        const isValid = this.validatesaveasdraft();
        if (isValid) {
            this.setState({
                ShowProcedure: true,
            })

            const url = global.websiteUrl+'/service/User/update_weightage';
            let formData = new FormData();
            formData.append('event_weightage', JSON.stringify(this.state.event_weightage));
            formData.append('advisory_board_weightage', JSON.stringify(this.state.advisory_board_weightage));
            formData.append('publication_weightage', JSON.stringify(this.state.publication_weightage));
            formData.append('clinical_trial_weightage', JSON.stringify(this.state.clinical_trial_weightage));
            formData.append('guideline_weightage', JSON.stringify(this.state.guideline_weightage));

            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }

            axios.post(url, formData, config)
                .then(response => {
                    console.log(response);
                    var data = response.data;
                    console.log("data", data);
                    var status = response.data.status;
                    console.log("status", status);
                    this.setState({
                        ShowProcedure: false,
                    })
                    if (response.data.status == true) {
                        Swal.fire({
                            icon: 'success',
                            text: response.data.message,
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'OK'
                        })
                        this.setState({ redirectDashbard: true })
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            text: response.data.message,
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'OK'
                        })
                    }
                })
                .catch(error => {
                    console.log(error);
                });
            this.setState({
                EventTypeErr: '',
                eventRoleErr: '',
                companyNameErr: '',
                advisoryRoleErr: '',
                publicationNameErr: '',
                publicationRoleErr: '',
                noOfLocationErr: '',
                typeOfTrialErr: '',
                typeOfOrganizationErr: '',
            })

        }
    }


    BackToDashboard() {
        this.setState({
            redirectDashbard: true,
        })
    }


    render() {
        if (this.state.redirectDashbard == true) {
            return (<Redirect to={{
                pathname: '/AdminDashboard',
            }} />)
        }


        return (
            <body>
                <div style={{marginTop:'15vh'}}>
                    <div>
                        <div>
                            {this.state.ShowBar ? <div style={{ position: 'fixed', top: '0' }}>
                                <LoadingBar
                                    progress='100'
                                    // onLoaderFinished={() => setProgress(0)}
                                    //  progress={progress}
                                    height={3} color="#A90007" transitionTime={4000}
                                    loaderSpeed={4000} />
                            </div>
                                : null}
                        </div>
                        {this.state.ShowProcedure ? <Modal isOpen={true} className='propagateLoader' centered>
                            <PropagateLoader color={'#A90007'} size='40px' loading='true' />
                        </Modal> : null}
                        <div>
                            <AdminHeader />
                            <div class="container">
                                <div class="content-wrapper">
                                    <div class="screen-headingbox">
                                        <div className="back-section">
                                            <a onClick={this.BackToDashboard.bind(this)}>
                                                <img src={require('../Assets/Images/back-arrow.svg')} />
                                                Back
                                            </a>
                                        </div>
                                        <h2>Profile Details </h2>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 col-xs-12 profile-box">
                                            <div class="sidebar sticky">
                                                <ul class="cetas-leftnav">
                                                    <li>
                                                        <a onClick={this.SpeakerScoreFunc}>
                                                            {
                                                                (this.state.SpeakerScoreFlag) ? (
                                                                    <div>
                                                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                                            Speaker Score</text>
                                                                        {
                                                                            (this.state.EventTypeErr || this.state.eventRoleErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )

                                                                        }
                                                                    </div>

                                                                ) : (
                                                                    <div>
                                                                        <text>Speaker Score</text>
                                                                        {
                                                                            (this.state.EventTypeErr || this.state.eventRoleErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )

                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a onClick={this.AdvisoryBoardScoreFunc}>
                                                            {
                                                                (this.state.AdvisoryBoardScoreFlag) ? (
                                                                    <div>
                                                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                                            Advisory Board Score</text>
                                                                        {
                                                                            (this.state.companyNameErr || this.state.advisoryRoleErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )

                                                                        }

                                                                    </div>

                                                                ) : (
                                                                    <div>
                                                                        <text>Advisory Board Score</text>
                                                                        {
                                                                            (this.state.companyNameErr || this.state.advisoryRoleErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )

                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a onClick={this.PublicationScoreFunc}>
                                                            {
                                                                (this.state.PublicationScoreFlag) ? (
                                                                    <div>
                                                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                                            Publication Score</text>
                                                                        {
                                                                            (this.state.publicationNameErr || this.state.publicationRoleErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )

                                                                        }
                                                                    </div>

                                                                ) : (
                                                                    <div>
                                                                        <text>Publication Score</text>
                                                                        {
                                                                            (this.state.publicationNameErr || this.state.publicationRoleErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )

                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a onClick={this.InvestigatorScoreFunc}>
                                                            {
                                                                (this.state.InvestigatorScoreFlag) ? (
                                                                    <div>
                                                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                                            Investigator Score</text>
                                                                        {
                                                                            (this.state.noOfLocationErr || this.state.typeOfTrialErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )

                                                                        }
                                                                    </div>

                                                                ) : (
                                                                    <div>
                                                                        <text>Investigator Score</text>
                                                                        {
                                                                            (this.state.noOfLocationErr || this.state.typeOfTrialErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )

                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a onClick={this.GuidelineScoreFunc}>
                                                            {
                                                                (this.state.GuidelineScoreFlag) ? (
                                                                    <div>
                                                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                                            Guideline Score</text>
                                                                        {
                                                                            (this.state.typeOfOrganizationErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )

                                                                        }
                                                                    </div>

                                                                ) : (
                                                                    <div>
                                                                        <text>Guideline Score</text>
                                                                        {
                                                                            (this.state.typeOfOrganizationErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )

                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div style={{ marginTop: '10%' }}></div>
                                            </div>
                                        </div>
                                        <div class="col-md-5">
                                            {/* Speaker Score */}
                                            {
                                                (this.state.SpeakerScoreFlag == true) ? (
                                                    <div>
                                                        <h3 class="mt-30">Speaker Score</h3>

                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                            Events/Conference Type <span class="mandatory">*</span></label>
                                                        <input type="text" class="form-control"
                                                            id="input7" placeholder="Enter Value"
                                                            name="event_conference_weightage"
                                                            value={this.state.event_weightage.event_conference_weightage}
                                                            onChange={(e) => {
                                                                let { event_weightage } = this.state;
                                                                event_weightage.event_conference_weightage = e.target.value;
                                                                this.setState({ event_weightage });
                                                            }}
                                                        />
                                                        <div className="loginErrCreateProject">{this.state.EventTypeErr}</div>

                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                            Role <span class="mandatory">*</span></label>
                                                        <input type="text" class="form-control"
                                                            id="input3" placeholder="Add Value"
                                                            name="event_role_weightage"
                                                            value={this.state.event_weightage.event_role_weightage}
                                                            onChange={(e) => {
                                                                let { event_weightage } = this.state;
                                                                event_weightage.event_role_weightage = e.target.value;
                                                                this.setState({ event_weightage });
                                                            }}
                                                        />
                                                        <div className="loginErrCreateProject">{this.state.eventRoleErr}</div>
                                                        <div style={{ marginTop: '20%' }}></div>
                                                    </div>
                                                ) : (
                                                    <div>
                                                    </div>
                                                )
                                            }
                                            {/* Advisory Board Scrore */}
                                            {
                                                (this.state.AdvisoryBoardScoreFlag == true) ? (
                                                    <div>
                                                        <h3 class="mt-30">Advisory Board Score</h3>

                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                            Company/Org Name  <span class="mandatory">*</span></label>
                                                        <input type="text" class="form-control"
                                                            id="input3" placeholder="Add Value"
                                                            name="advisory_board_company_weightage"
                                                            value={this.state.advisory_board_weightage.advisory_board_company_weightage}
                                                            onChange={(e) => {
                                                                let { advisory_board_weightage } = this.state;
                                                                advisory_board_weightage.advisory_board_company_weightage = e.target.value;
                                                                this.setState({ advisory_board_weightage });
                                                            }}
                                                        />
                                                        <div className="loginErrCreateProject">{this.state.companyNameErr}</div>

                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                            Role <span class="mandatory">*</span></label>
                                                        <input type="text" class="form-control"
                                                            id="input3" placeholder="Add Value"
                                                            name="advisory_board_role_weightage"
                                                            value={this.state.advisory_board_weightage.advisory_board_role_weightage}
                                                            onChange={(e) => {
                                                                let { advisory_board_weightage } = this.state;
                                                                advisory_board_weightage.advisory_board_role_weightage = e.target.value;
                                                                this.setState({ advisory_board_weightage });
                                                            }}
                                                        />
                                                        <div className="loginErrCreateProject">{this.state.advisoryRoleErr}</div>
                                                        <div style={{ marginTop: '20%' }}></div>
                                                    </div>
                                                ) : (
                                                    <div>
                                                    </div>
                                                )
                                            }
                                            {/* Publiaction Score */}
                                            {
                                                (this.state.PublicationScoreFlag == true) ? (
                                                    <div>
                                                        <h3 class="mt-30">Publication Score</h3>

                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                            Publication/Journal Name  <span class="mandatory">*</span></label>
                                                        <input type="text" class="form-control"
                                                            id="input3" placeholder="Add Value"
                                                            name="publication_journal_weightage"
                                                            value={this.state.publication_weightage.publication_journal_weightage}
                                                            onChange={(e) => {
                                                                let { publication_weightage } = this.state;
                                                                publication_weightage.publication_journal_weightage = e.target.value;
                                                                this.setState({ publication_weightage });
                                                            }}
                                                        />
                                                        <div className="loginErrCreateProject">{this.state.publicationNameErr}</div>

                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                            Role <span class="mandatory">*</span></label>
                                                        <input type="text" class="form-control"
                                                            id="input3" placeholder="Add Value"
                                                            name="publication_role_weightage"
                                                            value={this.state.publication_weightage.publication_role_weightage}
                                                            onChange={(e) => {
                                                                let { publication_weightage } = this.state;
                                                                publication_weightage.publication_role_weightage = e.target.value;
                                                                this.setState({ publication_weightage });
                                                            }}
                                                        />
                                                        <div className="loginErrCreateProject">{this.state.publicationRoleErr}</div>
                                                        <div style={{ marginTop: '20%' }}></div>
                                                    </div>
                                                ) : (
                                                    <div>
                                                    </div>
                                                )
                                            }
                                            {/* Invistigator Scope */}
                                            {
                                                (this.state.InvestigatorScoreFlag == true) ? (
                                                    <div>
                                                        <h3 class="mt-30">Investigator Score</h3>

                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                            No of Location  <span class="mandatory">*</span></label>
                                                        <input type="text" class="form-control"
                                                            id="input3" placeholder="Add Value"
                                                            name="clinical_trial_location_weightage"
                                                            value={this.state.clinical_trial_weightage.clinical_trial_location_weightage}
                                                            onChange={(e) => {
                                                                let { clinical_trial_weightage } = this.state;
                                                                clinical_trial_weightage.clinical_trial_location_weightage = e.target.value;
                                                                this.setState({ clinical_trial_weightage });
                                                            }}
                                                        />
                                                        <div className="loginErrCreateProject">{this.state.noOfLocationErr}</div>

                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                            Type of Trial <span class="mandatory">*</span></label>
                                                        <input type="text" class="form-control"
                                                            id="input3" placeholder="Add Value"
                                                            name="clinical_trial_study_type_weightage"
                                                            value={this.state.clinical_trial_weightage.clinical_trial_study_type_weightage}
                                                            onChange={(e) => {
                                                                let { clinical_trial_weightage } = this.state;
                                                                clinical_trial_weightage.clinical_trial_study_type_weightage = e.target.value;
                                                                this.setState({ clinical_trial_weightage });
                                                            }}
                                                        />
                                                        <div className="loginErrCreateProject">{this.state.typeOfTrialErr}</div>
                                                        <div style={{ marginTop: '20%' }}></div>
                                                    </div>
                                                ) : (
                                                    <div>
                                                    </div>
                                                )
                                            }
                                            {/* Guidelines Score */}
                                            {
                                                (this.state.GuidelineScoreFlag == true) ? (
                                                    <div>
                                                        <h3 class="mt-30">Guideline Score</h3>

                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                            Type of Organization  <span class="mandatory">*</span></label>
                                                        <input type="text" class="form-control"
                                                            id="input3" placeholder="Add Value"
                                                            name="guideline_company_weightage"
                                                            value={this.state.guideline_weightage.guideline_company_weightage}
                                                            onChange={(e) => {
                                                                let { guideline_weightage } = this.state;
                                                                guideline_weightage.guideline_company_weightage = e.target.value;
                                                                this.setState({ guideline_weightage });
                                                            }}
                                                        />
                                                        <div className="loginErrCreateProject">{this.state.typeOfOrganizationErr}</div>
                                                        <div style={{ marginTop: '20%' }}></div>
                                                    </div>
                                                ) : (
                                                    <div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="action-btn-container">
                                <div class="container">
                                    <div class="col-md-7 offset-md-3">
                                        <button class="cetas-secondry-button" onClick={this.BackToDashboard.bind(this)}>Cancel</button>
                                        <button class="cetas-button" data-toggle="modal" data-target="#edit-item"
                                            onClick={this.Save.bind(this)}>Save</button>

                                    </div>
                                </div>
                            </div>
                            <footer class="footer-fixed">
                                <div class="cetas-footer">
                                    <div class="container">
                                        <p>All rights reserved. www.cetashealthcare.com</p>
                                    </div>

                                </div>
                            </footer>
                        </div>
                    </div>
                </div>
            </body>
        );
    }
}

export default Rating;