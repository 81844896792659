import React, { Component } from 'react';
import ManagerHeader from './ManagerHeader'; // importing file for header bar

//datatable npm
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';

//reactstrap npm
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Button, Alert } from 'reactstrap';

import Axios from 'axios';
import { Redirect, Link } from 'react-router-dom';

import Swal from 'sweetalert2'; //sweetAlerts

// showing progress bar npm 
import { PropagateLoader } from 'react-spinners';
import LoadingBar from "react-top-loading-bar";

// table functionalities
const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;
const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total ml-2">
    Showing {from} to {to} of {size} Results
  </span>
);

var today = new Date();
var curr_date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()+ " " + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();

class ReviewSignup extends Component {
  constructor(props) {

    super(props)

    this.state = {

      // state for progress bar
      ShowBar: false,
      progressBar: false,

      system_id: '',
      delete_id: '',

      // State for saving data
      SignupList: [],

      onPriceFilter: null,
      onProductilters: null,

      // State for displaying data
      status: '',
      // States for toggle Edit form
      isEdit: false,
      // Stae for saving data from Edit Form
      editData: {
        id: '',
        emp_name: '',
        email_id: '',
        role_type: '',
        username: '',
        password: '',
      },

      Signup_data:{
        id: '1',
        Status: '77',

      },
      // State for toggle Create Employee Form
      isAdd: false,
      // Stae for saving data from AddNewEmployee Form
      addData: {
        id: '',
        emp_name: '',
        role_type: '',
        email_id: '',
        username: '',
        password: '',
        emp_unique_id: ''
      },

      //Error states
      nameError: '',
      roleError: '',
      // usernameError: '',
      emailError: '',
      passwordError: '',
      manager_name: '',
      //Edit Form Error States
      editnameError: '',
      editroleError: '',
      // editusernameError: "",
      editemailError: "",
      editpasswordError: '',

      // Column state for Bootstrap Table 
      columns: [
        {
          dataField: 'id',
          text: 'ID',
          sort: true,
          hidden:true,
        },
        {
          dataField: 'company_nm',
          text: ' Company Name',
          sort: true,
          filter: textFilter(),

          filterRenderer: (onFilter, column) => {
            this.onPriceFilter = onFilter;
            return null;
          },

          // formatter: this.EmployeeDetails
        },
        {
          dataField: 'client_name',
          text: ' Client Name',
          sort: true,
          filter: textFilter(),

          filterRenderer: (onFilter, column) => {
            this.onPriceFilter = onFilter;
            return null;
          },

          // formatter: this.EmployeeDetails
        },
        
        // {
        //   dataField: "mobile_no",
        //   text: "Mobile No",
        //   // formatter: this.Nested_kol_profile_Data,
        //   align: 'center',
        //   headerAlign: 'center'
        // },
        // {
        //   dataField: "quotation_id",
        //   text: "Quotation ID",
        //   // formatter: this.Nested_kol_profile_Data,
        //   align: 'center',
        //   headerAlign: 'center'
        // },
        {
          dataField: 'email_id',
          text: 'Email',
          filter: textFilter(),

          filterRenderer: (onFilter, column) => {
            this.onProductilters = onFilter;
            return null;
          },
        },
        {
          dataField: "reg_date",
          text: "Date",
          // formatter: this.Nested_kol_profile_Data,
          align: 'center',
          headerAlign: 'center'
        },
        {
          dataField: "followup_date",
          text: "Follow Up Date",
          // formatter: this.Nested_kol_profile_Data,
          align: 'center',
          headerAlign: 'center'
        },
        {
          dataField: "status",
          text: "Status",
          formatter:this.kolstatuscolour,

          // formatter: this.Nested_kol_profile_Data,
          align: 'center',
          headerAlign: 'center'
        },
        {
          dataField: "password",
          text: "Password",
          //formatter:this.kolstatuscolour,
         hidden:true,
          // formatter: this.Nested_kol_profile_Data,
          align: 'center',
          headerAlign: 'center'
        },
        {
          dataField: "Actions",
          // text: "Actions",
          formatter: this.linkFollow,
         // sort: true
        }
      ]

      // End of state
    }

    // Functions will be Bind here
    this.toggleaddModal = this.toggleaddModal.bind(this);
    // this.AddEmployee = this.AddEmployee.bind(this);
    // this.EditEmployee = this.EditEmployee.bind(this);
    // this.refreshCategory = this.refreshCategory.bind(this)
    // this.handlesubmitAdd = this.handlesubmitAdd.bind(this)

  }

  ////////////////////////////////////////react bootstrap Column functions//////////////////////////////////////////////////////////////////  
  // Anchor link foe employee names
  // EmployeeDetails(cell, row) {
  //   if (row.emp_name) {
  //     return (
  //       <div>
  //         <a href={row.emp_name}>{row.emp_name}</a>
  //       </div>
  //     )
  //   }
  // }
  // //Function for displaying nested KOL Profiles
  // Nested_kol_profile_Data(cell, row) {
  //   if (row.project_details) {
  //     return (
  //       <div>
  //         {row.project_details.map((data) => {

  //           return (
  //             <div>
  //               <div>{data.required_kol_profile}</div>
  //             </div>
  //           )
  //         })}
  //       </div>
  //     )
  //   }
  // }


  // // Nested Data Function
  // NestedData(cell, row) {
  //   if (row.project_details) {
  //     return (
  //       <div>
  //         {row.project_details.map((data, index) => {
  //           return (
  //             <div>
  //               <div> <span>{index + 1}.</span> {data.p_name}</div>
  //             </div>
  //           )
  //         })}
  //       </div>
  //     )
  //   }
  // }

Approved_client(row,status) {

// if(row.status =="Approved")
// {

    this.setState({
            ShowProcedure: true,
            ShowBar:true
        })

       var cl_status="";
       if(status=="Approve")
       {
        cl_status="Approved";
       }
       else
       {
        cl_status="Rejected";
       }

      
        //var id=row.id;
       
         var client_info={"client_name":row.client_name,"company_nm":row.company_nm,"email_id":row.email_id,"mobile_no":row.mobile_no,"signup_method":row.signup_method,"username":"","password":row.password,"client_id":"","login_type":"client","signup_method":row.signup_method,"status":"Active"};
        
         var Signup_data={"id":row.id,"client_name":row.client_name,"client_id":"","status":cl_status,"followup_date":curr_date,"email_id":row.email_id};

        // var quotation_={"quotation_id":row.quotation_id};

         const url = global.websiteUrl+'/service/KOL_landing/approved_signup_data';
         let formData = new FormData();
        
// //         alert(cl_status);
         if(cl_status=="Approved")
         {
             formData.append('client_info',JSON.stringify(client_info));
             //formData.append('quotationid',JSON.stringify(row.quotation_id));
            formData.append('quotationid',row.quotation_id);
  
         }

            formData.append('Signup_data',JSON.stringify(Signup_data));
            
            const config = {
                // headers: { 'content-type': 'multipart/form-data' }
            }

            Axios.post(url, formData, config)
                .then(response => {
                    console.log(response.data); 
                    
                  //  alert(response.data.status);


                  this.refreshSignupList();
       
                    this.setState({
                        ShowProcedure: false,
                        ShowBar:false
                    })

              
                    if (response.data.status == true) {
                        Swal.fire({
                            icon: 'success',
                            text: response.data.message,
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'OK'
                        })
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            text: response.data.message,
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'OK'
                        })
                    }
                })
                .catch(error => {
                    console.log(error);
                });

          
    //          }       
    
}


kolstatuscolour = (cell, row) => {
  var id = row.status;
  var col='';

    if (row.status == "Approved") {
          
          col= '#1FCE39'
    }
    else if (row.status == "Rejected") {
      
              col= '#e51c1c'
          
    }
    if (row.status == "Pending") {
          col= '#41A2FF'

    }
  

  return (
    <div style={{color:col}}>
     {row.status}
    </div>
  )
}





  // Funtion for displaying 'Edit' & 'delete' buttons inside Bootstrap Grid
  linkFollow = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
   
        <button className="cetas-button cetas-button-small mr-2"  disabled={row.status ==="Approved"}
        
        onClick={this.Approved_client.bind(this,row,'Approve')}>
          Approve
        </button>

        <button className="cetas-button cetas-button-small mr-2" disabled={row.status ==="Approved"}
          onClick={this.Approved_client.bind(this,row,'Reject')}>
          Reject
        </button>
       
      </div>
    );
  };

  // STARNGE  
  onPriceChange = (event) => {
    console.log(event.target.value)
    const { value } = event.target;
    this.onPriceFilter(value);
  };

  //STRANGE
  onProductilter = (event) => {
    const { value } = event.target;
    this.onProductilters(value);
  };

  //////////////////////////////////////// END of react bootstrap Column functions//////////////////////////////////////////////////////////////////      

  ////////////////////////////////////////Component Did Mount Method//////////////////////////////////////////////////////////////////      
  // Method to fetch data from API when component Mounted
  componentDidMount= async () => {

    let getSession_Manager_Name = await sessionStorage.getItem('name')
        if (getSession_Manager_Name !== null) {
            await this.setState({
                manager_name: getSession_Manager_Name,
            })
        }
    this.setState({ progressBar: true })

    const headers = new Headers()

    const options = {
      method: 'POST',
    }
    fetch(global.websiteUrl+'/service/KOL_landing/get_SignupList', options)
      
    //fetch(global.websiteUrl+'/service/Employee/get_EmployeeList', options)
      .then((Response) => Response.json())
      .then((findresponse) => {
        console.log(findresponse)
        var testData = findresponse.Signup_Details;
        console.log("testData", JSON.stringify(testData));

        this.setState({
          SignupList: findresponse.Signup_Details,
          progressBar: false
        })

        if (findresponse.status == false) {
          this.setState({
            SignupList: [],
          })
        }
        
      })
  }

  //////////////////////////////////////// END of Component Did Mount Method//////////////////////////////////////////////////////////////////  

  //////////////////////////////////////// CRUD OPERATIONS //////////////////////////////////////////////////////////////////////////
  // // Function to post data using API
  // async AddEmployee() {

  //   this.setState({ ShowBar: true })

  //   console.log('in Add Categtory')
  //   console.log(this.state.addData.role_type)
  //   console.log(this.state.addData.emp_name)
  //   console.log(this.state.addData.email_id)

  //   try {

  //     const url = global.websiteUrl+'/service/Employee/employee_curd';
  //     let formData = new FormData();
  //     formData.append('oper', 'add');
  //     formData.append('emp_name', this.state.addData.emp_name);
  //     formData.append('role_type', this.state.addData.role_type);
  //     formData.append('email_id', this.state.addData.email_id);
  //     formData.append('username', this.state.addData.username);
  //     formData.append('password', this.state.addData.password);
  //     formData.append('emp_id', this.state.system_id);

  //     const config = {
  //       // headers: { 'content-type': 'multipart/form-data' }
  //     }

  //     Axios.post(url, formData, config)
  //       .then(response => {
  //         console.log(response);
  //         var lgdata = response.Employee_Details;
  //         console.log("lgdata", JSON.stringify(lgdata));
  //         var lgstatus = response.data.status;
  //         console.log("addlgstatus", JSON.stringify(lgstatus));

  //         var message = response.data.message
  //         console.log("message", message)

  //         this.setState({ ShowBar: true })
  //         if (lgstatus == true) {
  //           // this.setState({ ShowBar: false })
  //           Swal.fire({
  //             icon: 'success',
  //             text: message,
  //             confirmButtonColor: '#d33',
  //             confirmButtonText: 'OK'

  //           })
  //         }
  //         else {
  //           Swal.fire({
  //             icon: 'error',
  //             text: message,
  //             // text: 'Wrong Username or Password!',
  //             confirmButtonColor: '#d33',
  //             confirmButtonText: 'OK'
  //           })
  //         }
  //         this.refreshCategory();
  //       });

  //     this.setState({
  //       nameError: null,
  //       roleError: null,
  //       // usernameError: null,
  //       emailError: null,
  //       passwordError: null,
  //     })

  //   } catch (e) {
  //     console.log(e);
  //   }

  // }

  // Whenever employee gets added, deleted or edited function will refresh emplyee list
  refreshSignupList() {

    this.setState({ ShowBar: true })
    try {

      console.log('I am in referesh category');
      const headers = new Headers()

      const options = {
        method: 'POST',
      }
      fetch(global.websiteUrl+'/service/KOL_landing/get_SignupList', options)
        
      //fetch(global.websiteUrl+'/service/Employee/get_EmployeeList', options)
        .then((Response) => Response.json())
        .then((findresponse) => {
          console.log(findresponse)
          var testData = findresponse.Signup_Details;
          console.log("testData", JSON.stringify(testData));

          this.setState({
            SignupList: findresponse.Signup_Details,
            progressBar: false,
            ShowBar:false
          })
  
          // if (findresponse.status == false) {
          //   this.setState({
          //     SignupList: [],
          //   })
          // }
          
        })



    } catch (error) {
      console.log(error)
    }

  }

  // // Function to post Edited data to API
  // EditEmployee() {

  //   this.setState({ ShowBar: true })
  //   try {

  //     const url = global.websiteUrl+'/service/Employee/employee_curd';
  //     let formData = new FormData();
  //     formData.append('emp_id', this.state.editData.id);
  //     formData.append('role_type', this.state.editData.role_type);
  //     formData.append('email_id', this.state.editData.email_id);
  //     formData.append('emp_name', this.state.editData.emp_name);
  //     formData.append('username', this.state.editData.username);
  //     formData.append('password', this.state.editData.password);
  //     formData.append('oper', 'edit');

  //     const config = {
  //       //   headers: { 'content-type': 'multipart/form-data' }
  //     }

  //     Axios.post(url, formData, config)
  //       .then(response => {
  //         var lgstatus = response.data.status;
  //         console.log(response);
  //         console.log("edit message", response.message);
  //         console.log("edit status", JSON.stringify(lgstatus));
  //         // alert(response.data.message)
  //         var message = response.data.message
  //         console.log("message", message)

  //         this.setState({ ShowBar: true })
  //         if (lgstatus == true) {
  //           // this.setState({redirect: true})
  //           Swal.fire({
  //             icon: 'success',
  //             // title: '<strong> message <u> message </u></strong>',
  //             text: message,
  //             // text: message,
  //             confirmButtonColor: '#d33',
  //             confirmButtonText: 'OK'
  //           })
  //         }
  //         else {
  //           Swal.fire({
  //             icon: 'error',
  //             text: message,
  //             // text: 'Wrong Username or Password!',
  //             confirmButtonColor: '#d33',
  //             confirmButtonText: 'OK'
  //           })
  //         }
  //         this.setState({
  //           status: response.data.message
  //         })
  //         this.refreshCategory();
  //       });
  //   } catch (e) {
  //     console.log(e);
  //   }

  // }

  // delete(id) {

  //   this.setState({ ShowBar: true })
  //   console.log(id)
  //   try {
  //     console.log('ID will be deleted', id);
  //     const url = global.websiteUrl+'/service/Employee/employee_curd';
  //     let formData = new FormData();
  //     formData.append('emp_id', id);
  //     formData.append('oper', 'del');

  //     const config = {
  //       //   headers: { 'content-type': 'multipart/form-data' }
  //     }

  //     Axios.post(url, formData, config)
  //       .then(response => {
  //         console.log(response);
  //         var lgdata = response.Employee_Details;
  //         console.log("delete data", JSON.stringify(lgdata));
  //         var lgstatus = response.data.status;
  //         console.log("delete status", JSON.stringify(lgstatus));

  //         var message = response.data.message
  //         console.log("message", message)

  //         this.setState({ ShowBar: true })
  //         if (lgstatus == true) {

  //           Swal.fire({
  //             icon: 'success',
  //             text: message,
  //             // text: 'Wrong Username or Password!',
  //             confirmButtonColor: '#d33',
  //             confirmButtonText: 'OK'
  //           })
  //         }
  //         else {
  //           Swal.fire({
  //             icon: 'error',
  //             text: 'message',
  //             // text: 'Wrong Username or Password!',
  //             confirmButtonColor: '#d33',
  //             confirmButtonText: 'OK'
  //           })
  //         }
  //         this.refreshCategory();
  //       });

  //   } catch (e) {
  //     console.log(e);
  //   }
  // }


  //////////////////////////////////////// END OF CRUD OPERATIONS //////////////////////////////////////////////////////////////////  

  //////////////////////////////////////// Functions for toggling forms ///////////////////////////////////////////////////////////////////////
  // Function for toggling Add Form
  toggleaddModal() {
    this.setState({
      isAdd: !this.state.isAdd,
      nameError: null,
      roleError: null,
      // usernameError: null,
      emailError: null,
      passwordError: null,
      addData: {
        ...this.state.addData,
        // id: '', 
        emp_name: null,
        role_type: null,
        email_id: null,
        username: null,
        password: null,
      }
    });
  }

  // Function to toggle Edit Form and To store data of edit form into states(editData)
  edit(id, emp_name, role_type, email_id, username, password) {
    console.log(email_id);
    console.log(id);
    this.setState({
      editData: { id, emp_name, role_type, email_id, username, password },
      isEdit: !this.state.isEdit,
      editnameError: null,
      editroleError: null,
      // editusernameError: null,
      editemailError: null,
      editpasswordError: null,
    });
  }

  //////////////////////////////////////// Functions for toggling forms ///////////////////////////////////////////////////////////////////////  

  //////////////////////////////////////// VALIDATIONS ///////////////////////////////////////////////////////////////////////
  // handleValidationAdd() {

  //   let nameError = "";
  //   // let usernameError = "";
  //   let emailError = "";
  //   let passwordError = '';
  //   let roleError = '';
  //   let formIsValid = true;

  //   let Employee_Name = this.state.addData.emp_name;
  //   let Employee_userName = this.state.addData.username;
  //   let Employee_email = this.state.addData.email_id;
  //   let Employee_password = this.state.addData.password;
  //   let Employee_role = this.state.addData.role_type;

  //   console.log(Employee_Name)
  //   console.log(Employee_userName)
  //   console.log(Employee_email)
  //   console.log(Employee_password)
  //   console.log(Employee_role)

  //   //Name
  //   if (!Employee_Name) {
  //     formIsValid = false;
  //     nameError = "Name field needed";

  //     if (nameError) {
  //       console.log(nameError)
  //       this.setState({ nameError })
  //     }
  //   } else {
  //     console.log(nameError)
  //     this.setState({ nameError: null })
  //   }


  //   //Email
  //   if (!Employee_email) {
  //     formIsValid = false;
  //     emailError = 'email cannot be empty';

  //     if (emailError) {
  //       this.setState({ emailError })
  //     }

  //     console.log('Email Error', emailError)
  //   } else if (typeof Employee_email !== "undefined") {

  //     let lastAtPos = Employee_email.lastIndexOf('@');
  //     let lastDotPos = Employee_email.lastIndexOf('.');

  //     if (!(lastAtPos < lastDotPos && lastAtPos > 0 && Employee_email.indexOf('@@') == -1 && lastDotPos > 2 && (Employee_email.length - lastDotPos) > 2)) {
  //       formIsValid = false;
  //       emailError = "Email is not valid";
  //       this.setState({ emailError })
  //     } else {
  //       this.setState({ emailError: null })
  //     }
  //   } else {
  //     this.setState({ emailError: null })
  //   }

  //   // if (Employee_userName == null) {
  //   //   formIsValid = false;
  //   //   usernameError = "username can't be empty";

  //   //   if (usernameError) {
  //   //     this.setState({ usernameError })
  //   //   }

  //   // } else if (!Employee_userName.match(/^[a-zA-Z]|@!#&+$/)) {
  //   //   formIsValid = false;
  //   //   usernameError = "username should Only be in letters or special characters";

  //   //   if (usernameError) {
  //   //     this.setState({ usernameError })
  //   //   }
  //   // } else {
  //   //   this.setState({ usernameError: null })
  //   // }

  //   //Password
  //   if (!Employee_password) {
  //     formIsValid = false;
  //     passwordError = "password cannot be empty";

  //     if (passwordError) {
  //       this.setState({ passwordError })
  //     }

  //   } else if (Employee_password.length < 8) {
  //     formIsValid = false;
  //     passwordError = "Password should be 8 characters long";

  //     if (passwordError) {
  //       this.setState({ passwordError })
  //     }

  //   } else {
  //     this.setState({ passwordError: null })
  //   }

  //   if (!Employee_role) {
  //     formIsValid = false;
  //     roleError = "Please specify which role do you belog to...";

  //     if (roleError) {
  //       this.setState({ roleError })
  //     }
  //   } else {
  //     this.setState({ roleError: null })
  //   }

  //   console.log(formIsValid)
  //   // this.setState({errors: errors});
  //   return formIsValid;
  // }

  // handlesubmitAdd(e) {
  //   e.preventDefault();

  //   if (this.handleValidationAdd()) {
  //     this.AddEmployee();
  //   } else {
  //     // alert("Form has error");
  //     console.log('Form Has Errors')
  //   }
  // }

  // handleValidationEdit() {

  //   let editnameError = "";
  //   let editroleError = "";
  //   // let editusernameError = "";
  //   let editemailError = "";
  //   let editpasswordError = '';
  //   let formIsValid = true;

  //   console.log(this.state.editData.emp_name);
  //   console.log(this.state.editData.role_type);
  //   console.log(this.state.editData.email_id);
  //   console.log(this.state.editData.password);
  //   //Name
  //   if (!this.state.editData.emp_name) {
  //     formIsValid = false;
  //     editnameError = "Name field needed";

  //     if (editnameError) {
  //       this.setState({ editnameError })
  //     }
  //   } else {
  //     this.setState({ editnameError: null })
  //   }

  //   //User Name
  //   // if (!this.state.editData.username) {
  //   //   formIsValid = false;
  //   //   editusernameError = "username Field cannot be empty";

  //   //   if (editusernameError) {
  //   //     this.setState({ editusernameError })
  //   //   }
  //   // } else if (!this.state.editData.username.match(/^[a-zA-Z]|@!#&+$/)) {
  //   //   formIsValid = false;
  //   //   editusernameError = "Only letters";

  //   //   if (editusernameError) {
  //   //     this.setState({ editusernameError })
  //   //   }
  //   // } else {
  //   //   this.setState({ editusernameError: null })
  //   // }

  //   //Email
  //   if (!this.state.editData.email_id) {
  //     formIsValid = false
  //     editemailError = 'email cannot be empty or Invalid Email'

  //     if (editemailError) {
  //       this.setState({ editemailError })
  //     }
  //     console.log('i am here2');
  //   } else if (typeof this.state.editData.email_id !== "undefined") {
  //     let lastAtPos = this.state.editData.email_id.lastIndexOf('@');
  //     let lastDotPos = this.state.editData.email_id.lastIndexOf('.');

  //     if (!(lastAtPos < lastDotPos && lastAtPos > 0)) {
  //       formIsValid = false;
  //       editemailError = "Email is not valid";
  //       if (editemailError) {
  //         this.setState({ editemailError })
  //       }
  //     } else {
  //       this.setState({ editemailError: null })
  //     }
  //   } else {

  //     this.setState({ editemailError: null })
  //   }

  //   //Role Type
  //   if (!this.state.editData.role_type) {
  //     formIsValid = false
  //     editroleError = "Please specify which role do you belog to..."

  //     if (editroleError) {
  //       this.setState({ editroleError })
  //     }
  //   } else {
  //     this.setState({ editroleError: null })
  //   }

  //   //Password
  //   if (!this.state.editData.password) {
  //     formIsValid = false
  //     editpasswordError = "password cannot be empty"

  //     if (editpasswordError) {
  //       this.setState({ editpasswordError })
  //     }

  //   } else if (this.state.editData.password.length < 8) {
  //     formIsValid = false
  //     editpasswordError = "Password should be 8 characters long"

  //     if (editpasswordError) {
  //       this.setState({ editpasswordError })
  //     }
  //   } else {
  //     this.setState({ editpasswordError: null })
  //   }

  //   console.log(formIsValid)
  //   return formIsValid;
  // }

  // handlesubmitEdit(e) {
  //   e.preventDefault();

  //   if (this.handleValidationEdit()) {
  //     this.setState({
  //       // editusernameError: null,
  //       editemailError: null,
  //       editpasswordError: null,
  //       editnameError: null,
  //       editroleError: null
  //     })
  //     this.EditEmployee();
  //   } else {
  //     // alert("Form has error");
  //     console.log('Form Has Errors')
  //   }
  // }

  //////////////////////////////////////// VALIDATIONS ///////////////////////////////////////////////////////////////////////

  render() {

    // for rendering projects and kol Profiles
    const expandRow = {
      renderer: row => {
        if (row.project_details) {
          return (
            <table style={{ width: '100%', height: '100%' }}>
              <thead >
                <th className="projectnameEmployee">Project Name</th>
                <th >required Kol's</th>
              </thead>
              {row.project_details.map((data) => {
                return (
                  <tbody>
                    <tr>
                      <td>{data.p_name}</td>
                      <td>{data.required_kol_profile}</td>
                    </tr>
                  </tbody>
                )
              })}
            </table>
          )
        }
      }
    };

    //Function for displaying nested KOL Profiles
    // Nested_kol_profile_Data(cell, row) {
    //   if (row.project_details) {
    //     return (
    //       <div>
    //         {row.project_details.map((data) => {

    //           return (
    //             <div>
    //               <div>{data.required_kol_profile}</div>
    //             </div>
    //           )
    //         })}
    //       </div>
    //     )
    //   }
    // }

    // For rendering pageination buttons
    const pageButtonRenderer = ({
      page,
      active,
      disable,
      title,
      onPageChange
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        onPageChange(page);
      };
      return (
        <li class="page-item" onClick={handleClick} ><a class="page-link" href="#">{page}</a></li>
      );
    };

    // options for passing values to bootstrap table 2
    const options = {
      pageButtonRenderer,
      paginationSize: 4,
      pageStartIndex: 1,
      // alwaysShowAllBtns: true, // Always show next and previous button
      // withFirstAndLast: false, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      // firstPageText: 'First',
      prePageText: 'Previous',
      nextPageText: 'Next',
      // lastPageText: 'Last',
      // nextPageTitle: 'First page',
      // prePageTitle: 'Pre page',
      // firstPageTitle: 'Next page',
      // lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '50', value: 50
      }, {
        text: '100', value: 100
      }, {
        text: '200', value: 200
      }, {
        text: 'All', value: this.state.SignupList.length
      }]  // A numeric array is also available. the purpose of above example is custom the text

    };

    return (
      <div style={{marginTop:'12vh'}}>
        {this.state.ShowBar ? <Modal isOpen={true} centered>
          <PropagateLoader color={'#A90007'} size='30px' loading='true' />
        </Modal> : null}
        {this.state.progressBar ? <div style={{ position: 'fixed', top: '0' }}>
          <LoadingBar
            progress='100'
            height={3} color="#A90007" transitionTime={4000}
            loaderSpeed={3000} />
        </div>
          : null}
        <div>
          <div >
       
                   {/* {/ page /} */}
            <ManagerHeader ManagerName={this.state.manager_name}/>
            <div className="container">
              <div className="content-wrapper">
                <div className="screen-headingbox">
                  <div className="back-section" style={{marginTop:'20px'}}>
                    <a>
                      <Link to="/ManagerDashboard">
                        <img
                          src={require('../Assets/Images/back-arrow.svg')}
                        />
                        Back
                      </Link>
                    </a>
                  </div>
                  <h2>Signup List</h2>
                        
                  {/* <h2>Signup Management<button className="cetas-button float-right" data-toggle="modal" data-target="#new-empl-add" onClick={this.toggleaddModal.bind()}>+ Add New Employee</button></h2> */}
                </div>
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12 emplist-box">
                    <div className="row">
                      <div className="col-md-12">
                        {/* <h3>Client Signup List</h3> */}
                        <div className="employee-actions">
                          {/* <div className="table-infobox">
                            <p className="list-count" style={{ color: 'white' }}>{this.state.SignupList.length} client listed</p>
                            <div className="filter-box float-right mb-30">
                              <div className="input-group">
                              </div>
                              <div className="input-group">
                             
                                <div className="input-group-prepend">
                                  <div className="input-group-text"><img src={require("../Assets/Images/search.svg")} /></div>
                                </div>
                                <input type="text" className="form-control" id="inlineFormInputGroupUsername" placeholder="Search by Employee Name"
                                  onChange={(e) => { this.onPriceChange(e) }} />
                              </div>
                            </div>
                          </div> */}
                          {/* <div className="sort-roll">
                            <select className="form-control" id="exampleFormControlSelect1"
                              onChange={(e) => { this.onProductilter(e) }}
                            >
                              <option value="">--Select Role--</option>
                              <option value="researcher">Researcher</option>
                              <option value="admin">Admin</option>
                              <option value="project manager">Project Manager</option>
                              <option value="reporting manager">Reporting Manager</option>
                            </select>
                          </div> */}
                        </div>
                        <ToolkitProvider
                          keyField="emp_id"
                          data={this.state.SignupList}
                          columns={this.state.columns}
                          search={this.customMatchFunc}
                          //exportCSV
                          exportCSV={{
                            fileName: 'cetas.csv',
                            noAutoBOM: false,
                          }}
                        >
                          {
                            props => (
                              <div>
                                {/* <ExportCSVButton { ...props.csvProps }>Export CSV!!</ExportCSVButton> */}
                                {/* <hr /> */}
                                <BootstrapTable
                                  {...props.baseProps}
                                  // selectRow={ selectRow }
                                  noDataIndication="Table is Empty"
                                  expandRow={expandRow}
                                  striped
                                  hover
                                  condensed
                                  loading={true}
                                  pagination={paginationFactory(options)}
                                  filter={filterFactory()}
                                />
                              </div>
                            )
                          }
                        </ToolkitProvider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReviewSignup;