import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import axios from 'axios';
import BootstrapTable, { CHECKBOX_STATUS_CHECKED } from 'react-bootstrap-table-next';
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, Spinner } from 'reactstrap';
import filterFactory, { textFilter, customFilter, selectFilter, Comparator, FILTER_TYPES } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Axios from 'axios';
import Swal from 'sweetalert2';
import LoadingBar from "react-top-loading-bar";
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import { RingLoader, FadeLoader, DotLoader, PropagateLoader } from 'react-spinners';
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import '../styles/react-bootstrap-table.css';

import ManagerHeader from './ManagerHeader';

const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total ml-2">
        Showing {from} to {to} of {size} Results
    </span>
);

class AssignKol extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // passed
            // manager_id: this.props.location.state.manager_id,
            // manager_name: this.props.location.state.manager_name,
            // project_id: this.props.location.state.project_id,
            manager_id: '',
            manager_name: '',
            project_id: '',

            // progess
            ShowBar: false,
            ShowProcedure: false,

            // redirect
            RedirectManagerKolList: false,
            RedirectpreliminarykolNav:false,

            // set datat from api
            KOLDetailsForAssign: [],

            // to get checked row ids
            selected: [],
            SelectedKOLs: '',

            columnsAssignKOL: [
                {
                    dataField: 'doctor_full_name',
                    text: 'Kol Name',
                    style: {
                       // backgroundColor: '#D6EAF8',
                        width: '20%'
                    },
                    filter: textFilter({
                        style: {
                           // backgroundColor: '#D6EAF8',
                            height: '5%'
                        },
                        // className: 'test-classname',
                        placeholder: 'Search by kol Name',
                    }),
                    align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center' };
                    },
                },
                {
                    dataField: "speciality_type",
                    text: "Speciality Type",
                    style: {
                        //backgroundColor: '#D6EAF8',
                        width: '20%'
                    },
                    sort: true,
                    filter: textFilter({
                        style: {
                            height: '5%'
                        },
                        placeholder: 'Search by Speciality Type',
                    }),
                    align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center' };
                    },
                },
                // {
                //     dataField: 'subspeciality',
                //     text: 'Sub Speciality',
                //     filter: textFilter({
                //         style: {
                //             height: '5%'
                //         },
                //         placeholder: 'Enter Sub Speciality',
                //     }),
                //     align: 'center',
                //     headerStyle: (colum, colIndex) => {
                //         return { textAlign: 'center' };
                //     },
                // },
                {
                    dataField: 'proceduretype',
                    text: 'Procedure',
                    style: {
                        //backgroundColor: '#D6EAF8',
                        width: '20%'
                    },
                    filter: textFilter({
                        style: {
                            height: '5%'
                            
                        },
                        
                        placeholder: 'Search by Procedure Type',
                    }),
                    align: 'center',
                    
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center' };
                    },
                },
                {
                    dataField: 'country_name',
                    text: 'Country',
                    style: {
                        //backgroundColor: '#D6EAF8',
                        width: '20%'
                    },
                    filter: textFilter({
                        style: {
                            height: '5%'
                        },
                        placeholder: 'Search by Country',
                    }),
                    align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center' };
                    },
                },
               
                {
                    dataField: 'profile_type',
                    text: 'Profile Type',
                    style: {
                     // backgroundColor: '#D6EAF8',
                      width: '20%',
                    
                    },
                    filter: selectFilter({
                      options: [
                        { label: 'Deep Profiles', value: 'Deep Profile' },
                        { label: 'Basic Profiles', value: 'Basic Profile' },
                        // Add more options as needed
                      ],
                      style: {
                        height: '5%',
                        // position: 'relative', // Add this to ensure proper positioning
                        // top: '-5px', // Adjust this value as needed to align the dropdown
                   
                        
                    },
                      placeholder: 'All Profiles',
                    }),
                    align: 'center',
                    headerStyle: (colum, colIndex) => {
                      return { textAlign: 'center',   position: 'relative', // Add this to ensure proper positioning
                      top: '-17px', // Adjust this value as needed to align the dropdown
                  };
                    },
                        style: (cell, row, rowIndex, colIndex) => {
                                if (row.profile_type == "Basic Profile") {
                                    return {
                                        color: '#FF7F41'
                                      
                                    };
                                } else if (row.profile_type == "Deep Profile") {
                                    return {
                                        color: '#1FCE39',
                                        
                                    };
                                } 
                            }
                  },
                // {
                //     dataField: 'researcher_name',
                //     text: 'Researcher Name',
                //     filter: textFilter({
                //         style: {
                //             height: '5%'
                //         },
                //         placeholder: 'Enter Researcher Name',
                //     }),
                //     align: 'center',
                //     headerStyle: (colum, colIndex) => {
                //         return { textAlign: 'center' };
                //     },
                // },
                // {
                //     dataField: 'kol_status',
                //     text: 'Status',
                //     filter: textFilter({
                //         style: {
                //             height: '5%'
                //         },
                //         placeholder: 'Enter Status',
                //     }),
                //     align: 'center',
                //     headerStyle: (colum, colIndex) => {
                //         return { textAlign: 'center' };
                //     },
                //     style: (cell, row, rowIndex, colIndex) => {
                //         if (row.kol_status == "ongoing") {
                //             return {
                //                 color: '#FF7F41'
                //             };
                //         } else if (row.kol_status == "approved") {
                //             return {
                //                 color: '#1FCE39'
                //             };
                //         } else if (row.kol_status == "new") {
                //             return {
                //                 color: '#41A2FF'
                //             };

                //         } else if (row.kol_status == "rejected") {
                //             return {
                //                 color: '#e51c1c'
                //             };
                //         }
                //     }
                // },
                // {
                //     dataField: 'qc_updated_date',
                //     text: 'Updated Date',
                //     sort: true,
                //     formatter: this.link,
                //     filter: textFilter({
                //         style: {
                //             height: '5%'
                //         },
                //         placeholder: 'Enter Updated Date',
                //     }),
                //     align: 'center',
                //     headerStyle: (colum, colIndex) => {
                //         return { textAlign: 'center' };
                //     },
                // },
            ]
        };
    }

    componentDidMount = async () => {

        let getSession_Manager_ID = await sessionStorage.getItem('id')
        if (getSession_Manager_ID !== null) {
            await this.setState({
                manager_id: getSession_Manager_ID,
            })
        }

        let getSession_Manager_Name = await sessionStorage.getItem('name')
        if (getSession_Manager_Name !== null) {
            await this.setState({
                manager_name: getSession_Manager_Name,
            })
        }

        let getSession_Project_Id = await sessionStorage.getItem('project_id')
        if (getSession_Project_Id !== null) {
            await this.setState({
                project_id: getSession_Project_Id,
            })
        }
        console.log("getSession_Manager_ID ====", getSession_Manager_ID)
        console.log("getSession_Manager_Name ====", getSession_Manager_Name)
        console.log("getSession_Project_Id ====", getSession_Project_Id)

        this.setState({
            ShowBar: true,
        })
        const url = global.websiteUrl+'/service/KOL/get_ProjectWise_Assign_KOL_List';
        let formData = new FormData();
        // formData.append('project_id', this.state.project_id);
        formData.append('project_id', getSession_Project_Id);
        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var KOLDetails = response.data.KOL_Personal_Info;
                console.log("KOLDetails====", KOLDetails);
                if (KOLDetails) {
                    this.setState({
                        KOLDetailsForAssign: response.data.KOL_Personal_Info,
                    })
                }
                else {
                    this.setState({
                        KOLDetailsForAssign: [],
                    })
                }
                this.setState({
                    ShowBar: false,
                })
            })
    }

    handleOnSelect = (row, isSelect) => {
        
        if(row.researcher_name !== null)
        {
            if (isSelect) {
                this.setState(() => ({
                    selected: [...this.state.selected, row.kol_unique_id]
                }));
                this.convertKolIdsToString();
                // var KOL_List= this.state.selected.toString();
                //  this.setState({
                //     SelectedKOLs: KOL_List,
                // })

            } else {
                this.setState(() => ({
                    selected: this.state.selected.filter(x => x !== row.kol_unique_id)
                }));
                this.convertKolIdsToString();
                // var KOL_List= this.state.selected.toString();
                //  this.setState({
                //     SelectedKOLs: KOL_List,
                // })
            }
            console.log("selected", this.state.selected);

            }
            else
            {
                Swal.fire({
                    icon: 'error',
                    text: 'Assign Researcher First',
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK'
                })
             
                this.setState({ RedirectpreliminarykolNav: true })
                   
            }

    }

    convertKolIdsToString() {
        console.log("in convertKolIdsToString")
        if (this.state.selected.length !== '0') {
            var KOL_List = this.state.selected.toString();
            this.setState({
                SelectedKOLs: KOL_List,
            })
        }
        console.log("selected in convertKolIdsToString ", this.state.selected);
    }

    handleOnSelectAll = async (isSelect, rows) => {
        const ids = rows.map(r => r.kol_unique_id);
        if (isSelect) {
            await this.setState(() => ({
                selected: ids
            }));
        } else {
            await this.setState(() => ({
                selected: []
            }));
        }
    }

    AssignKOLSubmit() {

        console.log('selected kol', this.state.selected);
        console.log('SelectedKOLs koL STRING', this.state.SelectedKOLs);
        console.log('project', this.state.project_id);

        // if(this.state.SelectedKOLs !== ''){
        if (this.state.selected.length > '0' ) {
            this.setState({
                ShowProcedure: true,
            })

            const url = global.websiteUrl+'/service/KOL/assign_KOL_to_Project';
            let formData = new FormData();
            formData.append('project_id', this.state.project_id);
            // formData.append('KOL_List', this.state.SelectedKOLs);
            formData.append('KOL_List', this.state.selected.toString());
            const config = {
                //   headers: { 'content-type': 'multipart/form-data' }
            }

            axios.post(url, formData, config)
                .then(response => {
                    console.log(response);
                    var KOLaddDetails = response.data;
                    console.log("KOLDetails====", KOLaddDetails);

                    this.setState({
                        ShowProcedure: false,
                        assignKolModal: false,
                        // RedirectManagerKolList: true,
                    })
                    if (response.data.status == true) {
                        Swal.fire({
                            icon: 'success',
                            text: response.data.message,
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'OK'
                        })
                        this.setState({ RedirectManagerKolList: true })
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            text: response.data.message,
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'OK'
                        })
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
        else {
            Swal.fire({
                icon: 'error',
                text: "Please Select KOL",
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
            })
        }



    }

    BackToManagerKolList = () => {
        this.setState({ RedirectManagerKolList: true })
    }

    preliminarykolNav()
    {
        this.setState({ RedirectpreliminarykolNav: true })

    }

    render() {

        if (this.state.RedirectManagerKolList) {
            return (<Redirect to={{
                pathname: '/ManagerKolList',
                state: {
                    manager_name: this.state.manager_name,
                    manager_id: this.state.manager_id,
                    project_id: this.state.project_id,
                }
            }} />)
        }

        if (this.state.RedirectpreliminarykolNav) {
            return (<Redirect to={{
                pathname: '/UploadPreliminarykol',
               
            }} />)
        }

        

        const selectRow = {

             mode: 'checkbox',
             clickToSelect: true,
             onSelect: this.handleOnSelect,
             onSelectAll: this.handleOnSelectAll
            
            
                
            
            };



        const pageButtonRenderer = ({
            page,
            active,
            disable,
            title,
            onPageChange
        }) => {
            const handleClick = (e) => {
                e.preventDefault();
                onPageChange(page);
            };
            return (
                <Button outline color="danger" className="mr-2" onClick={handleClick} > {page} </Button>
            );
        };

        const optionsAssignKol = {
            pageButtonRenderer,
            paginationSize: 4,
            pageStartIndex: 1,
            // alwaysShowAllBtns: true, // Always show next and previous button
            // withFirstAndLast: false, // Hide the going to First and Last page button
            // hideSizePerPage: true, // Hide the sizePerPage dropdown always
            // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: customTotal,
            disablePageTitle: true,
            sizePerPageList: [{
                text: '50', value: 50
            }, {
                text: '100', value: 100
            }, {
                text: '200', value: 200
            }, {
                text: 'All', value: this.state.KOLDetailsForAssign.length
            }] // A numeric array is also available. the purpose of above example is custom the text
        };

        return (
            <body>
                {/* <div> */}
                <div style={{ overflowY: 'auto' ,marginTop:'10vh'}} >
                    {this.state.ShowBar ?
                        <div style={{ position: 'fixed', top: '0' }}>
                            <LoadingBar
                                progress='100'
                                // onLoaderFinished={() => setProgress(0)}
                                //  progress={progress}
                                height={3} color="#A90007" transitionTime={4000}
                                loaderSpeed={4000} />
                        </div>
                        : null
                    }

                    {this.state.ShowProcedure ? <Modal isOpen={true} className='propagateLoader' centered>
                        <PropagateLoader color={'#A90007'} size='40px' loading='true' />
                    </Modal> : null}
                    <div>
                        <div>
                            <ManagerHeader ManagerName={this.state.manager_name} />
                            <div class="container">
                                <div class="content-wrapper">
                                    <div class="screen-headingbox">
                                        <div class="back-section">
                                            <a onClick={this.BackToManagerKolList.bind(this)}>
                                                <img
                                                    src={require('../Assets/Images/back-arrow.svg')}
                                                />
                                                Back
                                            </a>
                                        </div>
                                        <h2>KOL List
                                            {/* <button className="cetas-button float-right" 
                        onClick={this.AssignKOL.bind(this)}>Assign KOL</button> */}
                                        </h2>

                                    </div>
                                    <div>
                                        <div className="emplist-box" style={{ marginBottom: '5%', marginTop: '-2%' }}>
                                            <div style={{ width: '100%', overflowX: 'scroll' }}>
                                                <div style={{ width: '100%' }}>
                                                    <BootstrapTable
                                                        // {...props.baseProps}
                                                        keyField="kol_unique_id"
                                                        data={this.state.KOLDetailsForAssign}
                                                        noDataIndication="Data Not Available"
                                                        columns={this.state.columnsAssignKOL}
                                                        selectRow={selectRow}
                                                        striped
                                                        hover
                                                        condensed
                                                        // bordered={ true }
                                                        pagination={paginationFactory(optionsAssignKol)}
                                                        filter={filterFactory()}

                                                        rowStyle={{backgroundColor:'white'}}
                                                    />
                                                </div>
                                            </div>
                                            <div style={{ marginTop: '20%' }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="action-btn-container">
                        <div class="container">
                            <div class="col-md-7 offset-md-3">
                                <button class="cetas-secondry-button" onClick={this.BackToManagerKolList}>Cancel</button>
                                <button class="cetas-button" data-toggle="modal" data-target="#edit-item"
                                    onClick={this.AssignKOLSubmit.bind(this)}>Update</button>

                            </div>
                        </div>
                    </div>
                    <footer class="footer-fixed">
                        <div class="cetas-footer">
                            <div class="container">
                                <p>All rights reserved. www.cetashealthcare.com</p>
                            </div>

                        </div>
                    </footer>
                </div>
            </body>
        );
    }
}

export default AssignKol;