import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, Spinner } from 'reactstrap';
import filterFactory, { textFilter, customFilter, selectFilter, Comparator, FILTER_TYPES } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Axios from 'axios';
import Swal from 'sweetalert2';
import LoadingBar from "react-top-loading-bar";
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import { RingLoader, FadeLoader, DotLoader, PropagateLoader } from 'react-spinners';
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import '../styles/react-bootstrap-table.css';
import { Multiselect } from 'react-widgets';
import ManagerHeader from './ManagerHeader';

const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total ml-2">
        Showing {from} to {to} of {size} Results
    </span>
);



class ClientProjectList extends Component {
    constructor(props) {
        super(props);
        var today = new Date(),
            date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        this.state = {
            currentDate: date,
            ShowProcedure: false,

            // manager_id: this.props.location.state.manager_id,
            // manager_name: this.props.location.state.manager_name,
            // project_id: this.props.location.state.project_id,
            // p_name: this.props.location.state.p_name,
            manager_id: '',
            manager_name: '',
            project_id: '',
            p_name: '',
            client_name:'',
            assignKolFlag: false,

            assignKolModal: false,
            selected: [],
            SelectedKOLs: [],

            // redirect
            RedirectManagerComment: false,
            redirectAssignKol: false,
            RedirectClientProjectList: false,

            // modal for create creadentials
            EditIncentiveModal: false,
            // for create creadentials modal variables
            kolIdForCredential: '',

            CreateCredentials: {
                login_id: '',
                password: '',
            },

            from_kolid:[],
            to_kolid:'',
            MergekolModal:false,
            projectList: [],
            // error for create creadentials
            PasswordErr: '',


            //To show Spinner
            ShowBar: true,

            // redirect
            RedirectManagerDashoard: false,
            redirectManagerKolList: false,

            // data from api
            KOLList: [],
            selected_kol_id: [],
            KOLDetailsForAssign: [],

            // for incentive modal
            kol_incentive_info: {
                kol_id: '',
                project_id: '',
                engagement_status: '',
                incentive_status: '',
                incentive_amt: '',
                currency: '',
            },

            // Column state for Bootstrap Table 
            columns: [
                {

                    dataField: "project_id",
                     text: "Project id",
                //     sort: true,
                        hidden:true,
                },
                    {
                    dataField: 'p_name',
                    text: 'Project Name',
                    sort: true,
                    formatter: this.linkProjectComment,
                    filter: textFilter(),

                    filterRenderer: (onFilter, column) => {
                        this.onTextFilter = onFilter;
                        return null;
                    },
                    style: (cell, row, rowIndex, colIndex) => {
                        return {
                            color: '#41A2FF'
                        };
                    },
                },

                

                // {
                //     dataField: 'team_members',
                //     text: 'Project Team Members',
                //     align: 'center',
                //     headerStyle: (colum, colIndex) => {
                //         return { textAlign: 'center'};
                //     },
                //     // sort: true,
                //     // formatter: this.linkProjectComment,
                //     // filter: textFilter(),

                //     // filterRenderer: (onFilter, column) => {
                //     //     this.onTextFilter = onFilter;
                //     //     return null;
                //     // },
                //     // style: (cell, row, rowIndex, colIndex) => {
                //     //     return {
                //     //         color: '#41A2FF'
                //     //     };                      
                //     // },
                // },
                {
                    dataField: 'speciality_type',
                    text: 'Speciality',
                    align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center' };
                    },
                },
                
                {
                    dataField: 'start_date',
                    text: 'Start Date',
                    align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center' };
                    },
                },
                
                {
                    dataField: 'noofkols',
                    text: 'No Of Kols',
                    align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center' };
                    },
                },
                
                {
                    dataField: 'country',
                    text: 'Country',
                    align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center' };
                    },
                },
                
                {
                    dataField: "project_status",
                    text: "Status",
                    sort: true,
                    filter: textFilter(),
                    formatter: this.projectstatuscolour,
                    filterRenderer: (onFilter, column) => {
                        this.onStatusFilters = onFilter;
                        return null;
                    },
                    align: 'center',
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center' };
                    },

                 },
                
            ]
           


        }
        
    }


    linkProjectComment = (cell, row, rowIndex, formatExtraData) => {
        console.log(cell)
        console.log("linkProjectComment",row)
        var project_id = row.project_id;
        var p_name = row.p_name;
        var project_status = row.project_status;
        return (
            <div>
                <a style={{
           color: '#069',
          textDecoration: 'underline', cursor: 'pointer'
        }} onClick={this.GoToManagerKolList.bind(this, project_id, p_name,project_status)}> {row.p_name} </a>
            </div>
        )
    }

    GoToManagerKolList(project_id, p_name, project_status) {
        console.log("in manager kol list redirect")
        sessionStorage.setItem('project_id', project_id)
        sessionStorage.setItem('p_name', p_name)
        if(project_status == "Upcoming"){
            Swal.fire({
                icon: 'error',
                text: "Project is in upcoming state",
                // text: 'Wrong Username or Password!',
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
            });     
        }
        else{
            this.setState({
                redirectManagerKolList: true,
                // project_id: project_id, 
                // p_name: p_name,
            })
        }
    }

    // rowEvents = {
    // onClick: (e, row, rowIndex) => {
    //     console.log('clicked on row with index:',rowIndex);
    //         console.log(row)
    //         var project_id = row.project_id;
    //         var p_name = row.p_name;

    //         this.setState({ 
    //             redirectManagerKolList: true,
    //             project_id: project_id, 
    //             p_name: p_name,
    //         })
    // },
    // };



    projectstatuscolour = (cell, row) => {
        var id = row.project_id;
        var project_name = row.p_name;
        var project_status = row.project_status;
        var col='';
      
        if (row.project_status == "work in progress") {
                col= '#FF7F41'
            
                } else if (row.project_status == "Ongoing") {
                        col= '#1FCE39'
            
                } else if (row.project_status == "Upcoming") {
                    
                        col= '#41A2FF'
                    

                } else if (row.project_status == "Completed") {
                    
                        col= '#FF7F41'
                    
                }
                return (
                    <div style={{color:col}}>
                     {row.project_status}
                    </div>
                  )
            }

    kolstatuscolour = (cell, row) => {
        var id = row.project_id;
        var project_name = row.p_name;
        var project_status = row.project_status;
        var col='';
      
        if (row.kol_status == "Prelim") {
           
            col= '#41A2FF'
        
        } else if (row.kol_status == "Approved") {
            
            col= '#1FCE39'
            
        } else if (row.kol_status == "Submited") {
            
            col= '#41A2FF'
        

        } else if (row.kol_status == "Rejected") {
        
                col= '#e51c1c'
        
        } else if (row.kol_status == "Work In Progress") {
            
                col= '#FF7F41'
            
        } else if (row.kol_status == "Prelim Approved") {
            
                col= '#1FCE39'
            
        } else if (row.kol_status == "Prelim Rejected") {
            
                col= '#e51c1c'
            
        }
        else if (row.kol_status == "Prelim Pending") {
               col= '#41A2FF'
        
        } else if (row.kol_status == "Pending") {
            col= '#41A2FF'
     
     }
        
      
        return (
          <div style={{color:col}}>
           {row.kol_status}
          </div>
        )
      }

    componentDidMount = async () => {
        let getSession_manager_name = await sessionStorage.getItem('name')
        if (getSession_manager_name !== null) {
            await this.setState({
                manager_name: getSession_manager_name,
            })
        }

        let getSession_Manager_ID = await sessionStorage.getItem('id')
        if (getSession_Manager_ID !== null) {
          await this.setState({
            manager_id: getSession_Manager_ID,
          })
        }

        let getSession_client_Id = await sessionStorage.getItem('client_id')
        if (getSession_client_Id !== null) {
            await this.setState({
                client_id: getSession_client_Id,
            })
        }



       // alert(getSession_client_Id);
       
        let getSession_Client_name = await sessionStorage.getItem('name')
        if (getSession_Client_name !== null) {
            await this.setState({
                client_name: getSession_Client_name,
            })
        }

        console.log("getSession_client_Id ====", getSession_client_Id)
        // console.log("getSession_Manager_Name ====", getSession_Manager_Name)
        // console.log("getSession_client_Id ====", getSession_client_Id)
        // console.log("getSession_Project_Name ====", getSession_Project_Name)

        this.setState({
            ShowBar: true,
           
            progressBar: false,
        })


        // KOL List Fetch
        const url = global.websiteUrl+'/service/Manager/get_client_based_project_list';
        let formData = new FormData();
        // formData.append('project_id', this.state.project_id);
        formData.append('client_id', getSession_client_Id);
        //formData.append('user_type', 'Manager');
        this.setState({
            ShowBar: true,           
            progressBar: false,
        })
        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }

        Axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var lgdata = response.data.project_list;
                console.log("Fetched data", JSON.stringify(lgdata));
                // var lgstatus = response.data.status;
                // console.log("Fetched status", JSON.stringify(lgstatus));
                // console.log("Message from fetched api", response.data.message);
                // var kolIds = response.data.kol_system_id;
                // console.log("kolIds", JSON.stringify(kolIds));

                if (lgdata) {
                    this.setState({
                        projectList: response.data.project_list
                    })
                    this.setState({
                        ShowBar: false,
                        // showAddnewKol : true
                    })

                    // this.state.KOLList.map(async (data, i) => {
                    //     console.log("data.due_date+++++=====================", data.due_date)
                    //     console.log("data.currentDate+++++=====================", this.state.currentDate)
                    //     if (data.kol_status == 'Submited') {
                    //         if (data.due_date < this.state.currentDate) {
                    //             console.log("ok this is between date")
                    //             // data.kol_status = 'Pending';
                    //             // this.setState({
                    //             //     KOLList
                    //             // })
                    //             let KOLList = [...this.state.KOLList];
                    //             KOLList[i] = { ...KOLList[i], kol_status: 'Pending' };
                    //             this.setState({ KOLList });
                    //         }
                    //         else if (data.due_date > this.state.currentDate) {
                    //             console.log("ok noooooooooooooooo")
                    //             let KOLList = [...this.state.KOLList];
                    //             KOLList[i] = { ...KOLList[i], kol_status: 'Overdue' };
                    //             this.setState({ KOLList });
                    //         }
                    //     }
                    //     if (data.kol_status == 'Prelim') {
                    //         if (data.due_date < this.state.currentDate) {
                    //             console.log("ok this is between date")
                    //             // data.kol_status = 'Pending';
                    //             // this.setState({
                    //             //     KOLList
                    //             // })
                    //             let KOLList = [...this.state.KOLList];
                    //             KOLList[i] = { ...KOLList[i], kol_status: 'Prelim Pending' };
                    //             this.setState({ KOLList });
                    //         }
                    //         else if (data.due_date > this.state.currentDate) {
                    //             console.log("ok noooooooooooooooo")
                    //             let KOLList = [...this.state.KOLList];
                    //             KOLList[i] = { ...KOLList[i], kol_status: 'Prelim Overdue' };
                    //             this.setState({ KOLList });
                    //         }
                    //     }
                    // })

                } else {
                    this.setState({
                        projectList: []
                    })
                }


            });

            

    // const options = {
    //     method: 'POST',
    //     }

    // // kol List
    // fetch(global.websiteUrl+'/service/Manager/get_client_list', options)
    //     .then((Response) => Response.json())
    //     .then((findresponse) => {
    //     console.log(findresponse)
    //     var TestkolList = findresponse.Client_list;
       
    // // console.log("TestkolList", JSON.stringify(TestkolList));
    //     this.setState({
    //         kolListData: findresponse.Client_list,
    //     })
   
    // })

   
    }

    onTextChange = (event) => {
        console.log(event.target.value)
        const { value } = event.target;
        this.onTextFilter(value);
    };

    link = (cell, row) => {
        console.log(cell)
        console.log(row)
        var client_id = row.client_id;
        return (
            <div>
                <a style={{
           color: '#069',
          textDecoration: 'underline', cursor: 'pointer'
        }} onClick={this.GoToClientProjectList.bind(this, client_id)}> {row.name} </a>
            </div>
        )
    }

    GoToClientProjectList(client_id) {
        console.log('Yep Here');
        console.log('Id Here', client_id);
            this.setState({
                RedirectClientProjectList: true,
            })
            sessionStorage.setItem('client_id', client_id)
       
    }

    linkFollow = (cell, row, rowIndex, formatExtraData) => {
        var kolIdForCredential = row.kol_unique_id;
        return (
            <div>
                <button className="cetas-button cetas-button-small mr-2"
                    onClick={this.toggleEditIncentiveModal.bind(this, kolIdForCredential)}>
                    Edit
                </button>
            </div>
        );
    }

    linkmergekol = (cell, row, rowIndex, formatExtraData) => {
        var to_kolid = row.kol_unique_id;
        return (
            <div>
                <button className="cetas-button cetas-button-small mr-2"
                    onClick={this.togglekolmergeModal.bind(this, to_kolid)}>
                    Merge
                </button>
            </div>
        );
    }

    

    toggleEditIncentiveModal(kolIdForCredential) {
        let { kol_incentive_info } = this.state;
        kol_incentive_info.kol_id = kolIdForCredential;
        this.setState({
            kol_incentive_info,
            kolIdForCredential: kolIdForCredential,
            EditIncentiveModal: !this.state.EditIncentiveModal,
            PasswordErr: '',
        });
    }

    togglekolmergeModal(to_kolid) {
        // let { kol_incentive_info } = this.state;
        // kol_incentive_info.kol_id = kolIdForCredential;
        this.setState({           
            to_kolid: to_kolid,
            MergekolModal: !this.state.MergekolModal,
            
        });
    }

    togglekolmergeModal_hide() {
         this.setState({           
            MergekolModal: !this.state.MergekolModal,
            
        });
    }
    

    EditIncentiveFunc = async () => {
        console.log("kolIdForCredential", this.state.kolIdForCredential)
        console.log("project_id", this.state.project_id)
        console.log("kol_incentive_info:", this.state.kol_incentive_info)
        
        
        this.setState({
            ShowProcedure: true,
        })
        const url = global.websiteUrl+'/service/KOL_Dashboard/insert_kol_incentive';
        let formData = new FormData();
        formData.append('kol_incentive_info', JSON.stringify(this.state.kol_incentive_info));

        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }

        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var testStateData = response.data;
                console.log("testStateData", JSON.stringify(testStateData));
                if (response.data.status == true) {
                    Swal.fire({
                        icon: 'success',
                        text: response.data.message,
                        confirmButtonColor: '#d33',
                        confirmButtonText: 'OK'
                    })
                    this.setState({
                        ShowProcedure: false,
                    })
                    this.refreshProjectList();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: response.data.message,
                        confirmButtonColor: '#d33',
                        confirmButtonText: 'OK'
                    })
                    this.setState({
                        ShowProcedure: false,
                    })
                }
            })
            .catch(error => {
                console.log(error);
            });
        this.setState({
            EditIncentiveModal: false,
        })
    }

    

    mergekolfun = async () => {

        
        this.setState({
            ShowProcedure: true,
        })
        const url = global.websiteUrl+'/service/Deep_Profiles/merge_kol';
        let formData1 = new FormData();
      //  formData.append('kol_incentive_info', JSON.stringify(this.state.kol_incentive_info));
      formData1.append('from_kolid',this.state.from_kolid);
      formData1.append('to_kolid',this.state.to_kolid);
      formData1.append('manager_id', this.state.manager_id);
     
        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }

        axios.post(url, formData1, config)
            .then(response => {
                console.log(response);
                var testStateData = response.data;
                console.log("testStateData", JSON.stringify(testStateData));
                if (response.data.status == true) {
                    Swal.fire({
                        icon: 'success',
                        text: response.data.message,
                        confirmButtonColor: '#d33',
                        confirmButtonText: 'OK',
                       
                    })
                    this.setState({
                        ShowProcedure: false,

                    })
                    setTimeout(function(){window.location.reload(true);},5000);
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: response.data.message,
                        confirmButtonColor: '#d33',
                        confirmButtonText: 'OK'
                    })
                    this.setState({
                        ShowProcedure: false,
                    })
                }
            })
            .catch(error => {
                console.log(error);
            });
        this.setState({
            MergekolModal: false,
        })
    }


    validateCreateCredentials() {
        let PasswordErr = '';

        if (!this.state.CreateCredentials.password) {
            PasswordErr = 'Please Enter Password';
        }

        if (PasswordErr) {
            this.setState({ PasswordErr });
            return false;
        }
        return true;
    }

    CreateCrendialsFun() {
        console.log("CreateCredentials====", this.state.CreateCredentials)
        console.log("kolIdForCredential====", this.state.kolIdForCredential)
        const isValid = this.validateCreateCredentials();
        if (isValid) {
            this.setState({
                ShowProcedure: true,
            })
            const url = global.websiteUrl+'/service/KOL_Dashboard/Create_KOL_Credentials';
            let formData = new FormData();
            formData.append('kol_id', this.state.kolIdForCredential);
            formData.append('password', this.state.CreateCredentials.password);

            const config = {
                //   headers: { 'content-type': 'multipart/form-data' }
            }
            axios.post(url, formData, config)
                .then(response => {
                    console.log(response);
                    var lgdata = response.data;
                    console.log("lgdata", JSON.stringify(lgdata));
                    var lgstatus = response.data.status;
                    console.log("lgstatus", JSON.stringify(lgstatus));
                    console.log("response message2", response.data.message)
                    this.setState({
                        ShowProcedure: false,
                    })
                    if (lgstatus == true) {
                        Swal.fire({
                            icon: 'success',
                            text: response.data.message,
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'OK'
                        })
                    }
                    else if (lgstatus == false) {
                        // alert("Wrong Username Or Password")
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Wrong Username or Password!',
                            // showCancelButton: true,
                            confirmButtonColor: '#d33',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'OK'
                        })
                    }
                })
                .catch(error => {
                    console.log(error);
                });

            this.setState({
                EditIncentiveModal: false,
                // kolIdForCredential: '', 
                CreateCredentials: {
                    login_id: '',
                    password: '',
                }
            });
        }
    }



    BackToManagerDashboard() {
        this.setState({ RedirectManagerDashoard: true })
    }

    AssignKOL = async () => {
        await this.setState({
            // assignKolFlag: true,
            redirectAssignKol: true,
            assignKolModal: !this.state.assignKolModal,
        })
    }

    AssignKOLSubmit() {
        this.setState({
            ShowProcedure: true,
        })
        console.log('selected kol', this.state.selected);
        console.log('SelectedKOLs koL STRING', this.state.SelectedKOLs);
        console.log('project', this.state.project_id);

        const url = global.websiteUrl+'/service/KOL/assign_KOL_to_Project';
        let formData = new FormData();
        formData.append('project_id', this.state.project_id);
        formData.append('KOL_List', this.state.SelectedKOLs);
        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }

        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var KOLaddDetails = response.data;
                console.log("KOLDetails====", KOLaddDetails);

                this.setState({
                    ShowProcedure: false,
                    assignKolModal: false,
                })
                this.refreshProjectList();
            })

    }

    refreshProjectList() {
        const url = global.websiteUrl+'/service/KOL/get_Project_KOL';
        let formData = new FormData();
        formData.append('project_id', this.state.project_id);
        formData.append('user_type', 'Manager');

        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }

        Axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var lgdata = response.data.KOL_Personal_Info;
                console.log("Fetched data", JSON.stringify(lgdata));
                var lgstatus = response.data.status;
                console.log("Fetched status", JSON.stringify(lgstatus));
                console.log("Message from fetched api", response.data.message);
                var kolIds = response.data.kol_system_id;
                console.log("kolIds", JSON.stringify(kolIds));

                if (lgdata) {
                    this.setState({
                        KOLList: response.data.KOL_Personal_Info
                    })
                    // this.setState({
                    //     ShowBar: false,
                    //     showAddnewKol : true
                    // })

                    this.state.KOLList.map(async (data, i) => {
                        if (data.kol_status == 'Submited') {
                            if (data.due_date > this.state.currentDate) {
                                console.log("ok this is between date")
                                // data.kol_status = 'Pending';
                                // this.setState({
                                //     KOLList
                                // })
                                let KOLList = [...this.state.KOLList];
                                KOLList[i] = { ...KOLList[i], kol_status: 'Pending' };
                                this.setState({ KOLList });
                            }
                            else {
                                console.log("ok noooooooooooooooo")
                                let KOLList = [...this.state.KOLList];
                                KOLList[i] = { ...KOLList[i], kol_status: 'Overdue' };
                                this.setState({ KOLList });
                            }
                        }
                        if (data.kol_status == 'Prelim') {
                            if (data.due_date > this.state.currentDate) {
                                console.log("ok this is between date")
                                // data.kol_status = 'Pending';
                                // this.setState({
                                //     KOLList
                                // })
                                let KOLList = [...this.state.KOLList];
                                KOLList[i] = { ...KOLList[i], kol_status: 'Prelim Pending' };
                                this.setState({ KOLList });
                            }
                            else {
                                console.log("ok noooooooooooooooo")
                                let KOLList = [...this.state.KOLList];
                                KOLList[i] = { ...KOLList[i], kol_status: 'Prelim Overdue' };
                                this.setState({ KOLList });
                            }
                        }
                    })

                } else {
                    this.setState({
                        KOLList: []
                    })
                }
            });
    }

    handleOnSelect = async (row, isSelect) => {
        if (isSelect) {
            await this.setState(() => ({
                selected: [...this.state.selected, row.kol_unique_id]
            }));

            var KOL_List = this.state.selected.toString();
            await this.setState({
                SelectedKOLs: KOL_List,
            })

        } else {
            await this.setState(() => ({
                selected: this.state.selected.filter(x => x !== row.kol_unique_id)
            }));
            var KOL_List = this.state.selected.toString();
            await this.setState({
                SelectedKOLs: KOL_List,
            })
        }
        console.log("selected", this.state.selected);
    }

    handleOnSelectAll = async (isSelect, rows) => {
        const ids = rows.map(r => r.kol_unique_id);
        if (isSelect) {
            await this.setState(() => ({
                selected: ids
            }));
        } else {
            await this.setState(() => ({
                selected: []
            }));
        }
    }

    render() {

        const selectRow = {
            mode: 'checkbox',
            // bgColor: 'pink',
            // width: '1%',
            //  headerStyle: (colum, colIndex) => {
            //         return { width: '2%' };
            //         },
            // headerColumnStyle: {
            //     whiteSpace: 'nowrap',
            //     width: '1%',
            //     backgroundColor: 'blue'
            // },
            // selectColumnPosition: 'left',
            clickToSelect: true,
            onSelect: this.handleOnSelect,
            onSelectAll: this.handleOnSelectAll
        };

        if (this.state.RedirectManagerDashoard) {
            return (<Redirect to={{
                pathname: '/ManagerDashboard',
                state: {
                    manager_name: this.state.manager_name,
                    manager_id: this.state.manager_id,
                }
            }} />)
        }

        if (this.state.RedirectManagerComment) {
            return (<Redirect to={{
                pathname: '/ManagerQC',
                // state:{ 
                //     kol_unique_id: this.state.kol_unique_id,
                //     manager_name: this.state.manager_name,
                //     manager_id: this.state.manager_id,
                //     project_id: this.state.project_id,
                //     p_name: this.state.p_name,
                //     } 
            }} />)
        }

        if (this.state.redirectAssignKol) {
            return (<Redirect to={{
                pathname: '/AssignKol',
                // state: {
                //     manager_name: this.state.manager_name,
                //     manager_id: this.state.manager_id,  
                //     project_id: this.state.project_id,
                // }
            }} />)
        }

        if (this.state.RedirectClientProjectList) {
            return (<Redirect to={{
                pathname: '/ManagerQCIdentifire',
            }} />)
        }

        if (this.state.redirectManagerKolList) {
            return (<Redirect to={{
                pathname: '/ManagerKolList',
                // state:{ 
                //     project_id: this.state.project_id, 
                //     p_name: this.state.p_name,
                //     manager_name: this.state.manager_name,
                //     manager_id: this.state.manager_id, 
                // } 
            }} />)
        }

    //    let Optionskol="";
    //    if(this.state.kolListData.length>0)
    //    {
      
    //     let kol_list = this.state.kolListData;      
    //      Optionskol = kol_list.map((kol_list) =>
    //       <option key={kol_list.kol_unique_id} value={kol_list.kol_unique_id}>
    //         {kol_list.kol_unique_id+"-"+kol_list.name}</option>
    //     );
    //    }

    
        const pageButtonRenderer = ({
            page,
            active,
            disable,
            title,
            onPageChange
        }) => {
            const handleClick = (e) => {
                e.preventDefault();
                onPageChange(page);
            };
            return (
                <Button outline color="danger" className="mr-2" onClick={handleClick} > {page} </Button>
            );
        };

        const options = {
            pageButtonRenderer,
            paginationSize: 4,
            pageStartIndex: 1,
            // alwaysShowAllBtns: true, // Always show next and previous button
            // withFirstAndLast: false, // Hide the going to First and Last page button
            // hideSizePerPage: true, // Hide the sizePerPage dropdown always
            // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: customTotal,
            disablePageTitle: true,
            sizePerPageList: [{
                text: '50', value: 50
            }, {
                text: '100', value: 100
            }, {
                text: '200', value: 200
            }, {
                text: 'All', value: this.state.KOLList.length
            }]  // A numeric array is also available. the purpose of above example is custom the text

        };

        const optionsAssignKol = {
            pageButtonRenderer,
            paginationSize: 4,
            pageStartIndex: 1,
            // alwaysShowAllBtns: true, // Always show next and previous button
            // withFirstAndLast: false, // Hide the going to First and Last page button
            // hideSizePerPage: true, // Hide the sizePerPage dropdown always
            // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: customTotal,
            disablePageTitle: true,
            sizePerPageList: [{
                text: '5', value: 5
            }, {
                text: '10', value: 10
            }, {
                text: 'All', value: this.state.KOLDetailsForAssign.length
            }] // A numeric array is also available. the purpose of above example is custom the text

        };

        return (
            <body>
                <div style={{marginTop:'15vh' }}>
                    {this.state.ShowBar ?
                        <div style={{ position: 'fixed', top: '0' }}>
                            <LoadingBar
                                progress='100'
                                // onLoaderFinished={() => setProgress(0)}
                                //  progress={progress}
                                height={3} color="#A90007" transitionTime={4000}
                                loaderSpeed={4000} />
                        </div>
                        : null
                    }

                    {this.state.ShowProcedure ? <Modal isOpen={true} className='propagateLoader' centered>
                        <PropagateLoader color={'#A90007'} size='40px' loading='true' />
                    </Modal> : null}
                    <div>
                        <div>
                            <ManagerHeader ManagerName={this.state.manager_name} />
                            <div class="container">
                                <div class="content-wrapper">
                                    <div class="screen-headingbox">
                                        <div class="back-section">
                                            <a onClick={this.BackToManagerDashboard.bind(this)}>
                                                <img
                                                    src={require('../Assets/Images/back-arrow.svg')}
                                                />
                                                Back
                                            </a>
                                        </div>
                                        <h2>Project List
                                            {/* <button className="cetas-button float-right"
                                                onClick={this.AssignKOL.bind(this)}>MAP KOL TO Client</button> */}
                                        </h2>

                                        {/* <div>
                    <p>
                    { this.state.currentDate }
                    </p>
                </div> */}

                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-xs-12 emplist-box">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    {/* <h3>Client Name {this.state.client_name}</h3> */}
                                                    <div className="employee-actions">
                                                        <div className="table-infobox">
                                                            
                                                        {/* <p className="list-count" style={{ color: 'black' , fontWeight:'bold'}}>{this.state.KOLList.length} KLO's Listed</p> */}
                                                            <p className="list-count" style={{ height:'30px', color: 'black' , fontWeight:'bold'}}>{this.state.projectList.length} Project's Listed </p>
                                                            <div className="filter-box float-right mb-30">
                                                                <div className="input-group">
                                                                    {/* <div className="input-group-prepend">
                                                <div className="input-group-text"><img src={require("../Assets/Images/search.svg")} /></div>
                                                </div>
                                                <input type="text" className="form-control" id="inlineFormInputGroupUsername" placeholder="Search by Name, project"
                                                onChange={(e) => { this.onTextChange(e) }}
                                                /> */}
                                                                </div>
                                                                <div className="input-group" style={{ marginLeft: '20%', marginTop:'0px' }}>
                                                                    {/* <div className="input-group-prepend">
                                            <div className="input-group-text"><img src={require("../Assets/Images/search.svg")} /></div>
                                            </div>
                                            <select className="form-control no-border-top no-border-left no-border-right" id="exampleFormControlSelect1"
                                            onChange={(e) => { this.onTextChange(e) }}
                                            >
                                            <option value="">Sort by alphabates</option>
                                            <option>A</option>
                                            <option>B</option>
                                            <option>C</option>
                                            <option>D</option>
                                            </select> */}
                                                                    <div className="input-group-prepend">
                                                                        <div className="input-group-text"><img src={require("../Assets/Images/search.svg")} /></div>
                                                                    </div>
                                                                    <input type="text" className="form-control"
                                                                        id="inlineFormInputGroupUsername" placeholder="Search by Project Name"
                                                                        onChange={(e) => { this.onTextChange(e) }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="sort-roll">
                                                            {/* <select className="form-control" id="exampleFormControlSelect1"
                                    onChange={(e) => { this.onFilter(e) }}
                                    >
                                    <option value="">Select Status</option>
                                    <option>Submited</option>
                                    <option>Work In Progress</option>
                                    <option>Approved</option>
                                    <option>Rejected</option>
                                    </select> */}
                                                        </div>
                                                    </div>
                                                    <ToolkitProvider
                                                        keyField="project_id"
                                                        data={this.state.projectList}
                                                        columns={this.state.columns}
                                                    // search={this.customMatchFunc}
                                                    //exportCSV
                                                    >
                                                        {
                                                            props => (
                                                                <div>
                                                                    {/* <ExportCSVButton { ...props.csvProps }>Export CSV!!</ExportCSVButton> */}
                                                                    {/* <hr /> */}
                                                                    <BootstrapTable
                                                                        {...props.baseProps}
                                                                        noDataIndication="Table is Empty"
                                                                        bordered={true}
                                                                        //  selectRow={ selectRow }
                                                                        striped
                                                                        hover
                                                                        condensed
                                                                        // loading={true}
                                                                        pagination={paginationFactory(options)}
                                                                        filter={filterFactory()}
                                                                        rowStyle={ { backgroundColor: 'White' } }
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    </ToolkitProvider>
                                                    <div style={{ marginTop: '50%' }}></div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <Modal isOpen={this.state.assignKolModal} toggle={this.AssignKOL.bind(this)} size="xl">
                                        <ModalHeader toggle={this.AssignKOL.bind(this)}
                                            cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
                                            style={{ borderBottom: '0px' }}>
                                            <h3 style={{ justifyContent: 'center', marginTop: '10px' }} id="login-tit">Assign KOL</h3>
                                        </ModalHeader>
                                        <ModalBody>
                                            <FormGroup>
                                                {/* <Label for="AddIntOnco">Add Item in Speciality</Label> */}
                                                <div className="emplist-box" style={{ marginBottom: '5%', marginTop: '-2%' }}>
                                                    {/* <h3 class="mt-30 mb-30">Assign KOL</h3> */}
                                                    <div style={{ width: '150%' }}>
                                                        {/* <ToolkitProvider
                             keyField="kol_unique_id"
                             data={this.state.KOLDetailsForAssign}
                             columns={this.state.columnsAssignKOL}
                         >
                         {
                         props => (
                            <div>
                              <hr /> */}
                                                        <BootstrapTable
                                                            // {...props.baseProps}
                                                            keyField="kol_unique_id"
                                                            data={this.state.KOLDetailsForAssign}
                                                            noDataIndication="Data Not Available"
                                                            columns={this.state.columnsAssignKOL}
                                                            selectRow={selectRow}
                                                            striped
                                                            hover
                                                            condensed
                                                            // bordered={ true }
                                                            pagination={paginationFactory(optionsAssignKol)}
                                                            filter={filterFactory()}
                                                            rowStyle={{backgroundColor:'white'}}
                                                            
                                                        />
                                                        {/* </div>
                         )
                         }
                         </ToolkitProvider> */}

                                                    </div>
                                                </div>
                                            </FormGroup>
                                        </ModalBody>
                                        <ModalFooter>
                                            <button class="cetas-button cetas-button-small" style={{ height: '40px' }}
                                                onClick={this.AssignKOLSubmit.bind(this)}>Assign KOL</button>
                                            <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                                                onClick={this.AssignKOL.bind(this)}>Cancel</button>
                                        </ModalFooter>
                                    </Modal>

                                    {/* Incentive modal */}
                                    <Modal isOpen={this.state.EditIncentiveModal} toggle={this.toggleEditIncentiveModal.bind(this)} centered>
                                        <ModalHeader toggle={this.toggleEditIncentiveModal.bind(this)}
                                            cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
                                            style={{ borderBottom: '0px' }}>
                                            <h3 style={{ justifyContent: 'center', marginTop: '30px' }} id="login-tit">Edit Incentive Info</h3>
                                        </ModalHeader>
                                        <ModalBody style={{ padding: '0px 80px 0px 80px' }}>

                                            <FormGroup>
                                                <Label for="engStatusIN" >
                                                    Engagement Status </Label>
                                                <Input type="select" id="engStatusIN"
                                                    style={{ height: '50px', width: '100%' }}
                                                    name="engagement_status"
                                                    value={this.state.kol_incentive_info.engagement_status}
                                                    onChange={async (e) => {
                                                        let { kol_incentive_info } = this.state;
                                                        kol_incentive_info.engagement_status = e.target.value;
                                                        await this.setState({ kol_incentive_info });
                                                    }}
                                                >
                                                    <option value="" >--Select Engagement Status--</option>
                                                    <option value="Ongoing" >Ongoing</option>
                                                    <option value="Upcoming" >Upcoming</option>
                                                    <option value="Completed" >Completed</option>
                                                </Input>
                                                {/* <div className="centerErrLogin">{this.state.kolProfileUsernameErr}</div>  */}
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="engStatusIN" >
                                                    Incentive Status </Label>
                                                <Input type="select" id="engStatusIN"
                                                    style={{ height: '50px', width: '100%' }}
                                                    name="incentive_status"
                                                    value={this.state.kol_incentive_info.incentive_status}
                                                    onChange={async (e) => {
                                                        let { kol_incentive_info } = this.state;
                                                        kol_incentive_info.incentive_status = e.target.value;
                                                        await this.setState({ kol_incentive_info });
                                                    }}
                                                >
                                                    <option value="" >--Select Incentive Status--</option>
                                                    <option value="Incentivised" >Incentivised</option>
                                                    <option value="In Process" >In Process</option>
                                                </Input>
                                                {/* <div className="centerErrLogin">{this.state.kolProfileUsernameErr}</div>  */}
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="engStatusIN" >
                                                    Currency </Label>
                                                <Input type="select" id="engStatusIN"
                                                    style={{ height: '50px', width: '100%' }}
                                                    name="currency"
                                                    value={this.state.kol_incentive_info.currency}
                                                    onChange={async (e) => {
                                                        let { kol_incentive_info } = this.state;
                                                        kol_incentive_info.currency = e.target.value;
                                                        await this.setState({ kol_incentive_info });
                                                    }}
                                                >
                                                    <option value="" >--Select Currency--</option>
                                                    <option value="AFN - Afghan Afghani" >AFN - Afghan Afghani</option>
                                                    <option value="AMD - Armenian Dram" >AMD - Armenian Dram</option>
                                                    <option value="AZN - Azerbaijani Manat" >AZN - Azerbaijani Manat</option>
                                                    <option value="BDT - Bangladeshi Taka" >BDT - Bangladeshi Taka</option>
                                                    <option value="KHR - Cambodian Riel" >KHR - Cambodian Riel</option>
                                                    <option value="CNY - Chinese Yuan" >CNY - Chinese Yuan</option>
                                                    <option value="GEL - Georgian Lari" >GEL - Georgian Lari</option>
                                                    <option value="HKD - Hong Kong Dollar" >HKD - Hong Kong Dollar</option>
                                                    <option value="IDR - Indonesian Rupiah" >IDR - Indonesian Rupiah</option>
                                                    <option value="JPY - Japanese Yen" >JPY - Japanese Yen</option>
                                                    <option value="KZT - Kazakhstani Tenge" >KZT - Kazakhstani Tenge</option>
                                                    <option value="LAK - Lao Kip" >LAK - Lao Kip</option>
                                                    <option value="MYR - Malaysian Ringgit" >MYR - Malaysian Ringgit</option>
                                                    <option value="KRW - South Korean Won" >KRW - South Korean Won</option>
                                                    <option value="LKR - Sri Lankan Rupee" >LKR - Sri Lankan Rupee</option>
                                                    <option value="MVR - Maldivian Rufiyaa" >MVR - Maldivian Rufiyaa</option>
                                                    <option value="PKR - Pakistani Rupee" >PKR - Pakistani Rupee</option>
                                                    <option value="PHP - Philippine Peso" >PHP - Philippine Peso</option>
                                                    <option value="THB - Thai Baht" >THB - Thai Baht</option>
                                                    <option value="TWD - Taiwanese Dollar" >TWD - Taiwanese Dollar</option>
                                                    <option value="VND - Vietnamese Dong" >VND - Vietnamese Dong</option>
                                                    <option value="BHD - Bahraini Dinar" >BHD - Bahraini Dinar</option>
                                                    <option value="AED - Emirati Dirham" >AED - Emirati Dirham</option>
                                                    <option value="SAR - Saudi Arabian Riyal" >SAR - Saudi Arabian Riyal</option>
                                                    <option value="OMR - Omani Rial" >OMR - Omani Rial</option>
                                                    <option value="ILS - Israeli Shekel" >ILS - Israeli Shekel</option>
                                                    <option value="IQD - Iraqi Dinar" >IQD - Iraqi Dinar</option>
                                                    <option value="IRR - Iranian Rial" >IRR - Iranian Rial</option>
                                                    <option value="JOD - Jordanian Dinar" >JOD - Jordanian Dinar</option>
                                                    <option value="KWD - Kuwaiti Dinar" >KWD - Kuwaiti Dinar</option>
                                                    <option value="LBP - Lebanese Pound" >LBP - Lebanese Pound</option>
                                                    <option value="QAR - Qatari Riyal" >QAR - Qatari Riyal</option>
                                                    <option value="LYD - Libyan Dinar" >LYD - Libyan Dinar</option>
                                                    <option value="BAM - Bosnian Convertible Marka" >BAM - Bosnian Convertible Marka</option>
                                                    <option value="EUR - Euro" >EUR - Euro</option>
                                                    <option value="BGN - Bulgarian Lev" >BGN - Bulgarian Lev</option>
                                                    <option value="CZK - Czech Koruna" >CZK - Czech Koruna</option>
                                                    <option value="HRK - Croatian Kuna" >HRK - Croatian Kuna</option>
                                                    <option value="DKK - Danish Krone" >DKK - Danish Krone</option>
                                                    <option value="HUF - Hungarian Forint" >HUF - Hungarian Forint</option>
                                                    <option value="ISK - Icelandic Krona" >ISK - Icelandic Krona</option>
                                                    <option value="MDL - Moldovan Leu" >MDL - Moldovan Leu</option>
                                                    <option value="MKD - Macedonian Denar" >MKD - Macedonian Denar</option>
                                                    <option value="NOK - Norwegian Krone" >NOK - Norwegian Krone</option>
                                                    <option value="PLN - Polish Zloty" >PLN - Polish Zloty</option>
                                                    <option value="RON - Romanian Leu" >RON - Romanian Leu</option>
                                                    <option value="RSD - Serbian Denar" >RSD - Serbian Denar</option>
                                                    <option value="RUB - Russian Ruble" >RUB - Russian Ruble</option>
                                                    <option value="NOK - Norwegian Krone" >NOK - Norwegian Krone</option>
                                                    <option value="SEK - Swedish Krona" >SEK - Swedish Krona</option>
                                                    <option value="CHF - Swiss Franc" >CHF - Swiss Franc</option>
                                                    <option value="TRY - Turkish Lira" >TRY - Turkish Lira</option>
                                                    <option value="UAH - Ukranian Hryvnia" >UAH - Ukranian Hryvnia</option>
                                                    <option value="GBP - British Pound" >GBP - British Pound</option>
                                                    <option value="ARS - Argentine Peso" >ARS - Argentine Peso</option>
                                                    <option value="BOB - Bolivian Boliviano" >BOB - Bolivian Boliviano</option>
                                                    <option value="BRL - Brazilian Real" >BRL - Brazilian Real</option>
                                                    <option value="CLP - Chilean Peso" >CLP - Chilean Peso</option>
                                                    <option value="COP - Colombian Peso" >COP - Colombian Peso</option>
                                                    <option value="PEN - Peruvian Sol" >PEN - Peruvian Sol</option>
                                                    <option value="PYG - Paraguayan Guaraní" >PYG - Paraguayan Guaraní</option>
                                                    <option value="UYU - Uruguayan Peso" >UYU - Uruguayan Peso</option>
                                                    <option value="VEF - Venezuelan Bolívar" >VEF - Venezuelan Bolívar</option>
                                                    <option value="USD - United States Dollar" >USD - United States Dollar</option>
                                                    <option value="CAD - Canadian Dollar" >CAD - Canadian Dollar</option>
                                                    <option value="AWG - Aruban/Dutch Guilder" >AWG - Aruban/Dutch Guilder</option>
                                                    <option value="BBD - Bajan Dollar" >BBD - Bajan Dollar</option>
                                                    <option value="BMD - Bermudian Dollar" >BMD - Bermudian Dollar</option>
                                                    <option value="BSD - Bahamian Dollar" >BSD - Bahamian Dollar</option>
                                                    <option value="DOP - Dominican Peso" >DOP - Dominican Peso</option>
                                                    <option value="JMD - Jamaican Dollar" >JMD - Jamaican Dollar</option>
                                                    <option value="MXN - Mexican Peso" >MXN - Mexican Peso</option>
                                                    <option value="ZAR - South African Rand" >ZAR - South African Rand</option>
                                                    <option value="EGP - Egyptian Pound" >EGP - Egyptian Pound</option>
                                                    <option value="GHS - Ghanian Cedi" >GHS - Ghanian Cedi</option>
                                                    <option value="GMD - Gambian Dalasi" >GMD - Gambian Dalasi</option>
                                                    <option value="KES - Kenyan Shilling" >KES - Kenyan Shilling</option>
                                                    <option value="MAD - Moroccan Dirham" >MAD - Moroccan Dirham</option>
                                                    <option value="MUR - Mauritian Rupee" >MUR - Mauritian Rupee</option>
                                                    <option value="NAD - Namibian Dollar" >NAD - Namibian Dollar</option>
                                                    <option value="NGN - Nigerian Naira" >NGN - Nigerian Naira</option>
                                                    <option value="SCR - Seychellois Rupee" >SCR - Seychellois Rupee</option>
                                                    <option value="TND - Tunisian Dinar" >TND - Tunisian Dinar</option>
                                                    <option value="UGX - Ugandan Shilling" >UGX - Ugandan Shilling</option>
                                                    <option value="XAF - CFA Franc" >XAF - CFA Franc</option>
                                                    <option value="AUD - Australian Dollar" >AUD - Australian Dollar</option>
                                                    <option value="FJD - Fijian Dollar" >FJD - Fijian Dollar</option>
                                                    <option value="NZD - New Zealand Dollar" >NZD - New Zealand Dollar</option>
                                                    <option value="XPF - CPF Franc" >XPF - CPF Franc</option>

                                                </Input>
                                                {/* <div className="centerErrLogin">{this.state.kolProfileUsernameErr}</div>  */}
                                            </FormGroup>

                                            <FormGroup>
                                                <Label for="engStatusIN" >
                                                    Incentive Amount </Label>
                                                <Input type="number" id="engStatusIN"
                                                    className="form-control"
                                                    // style={{height: '50px', width:'100%'}}
                                                    placeholder="Enter Incentive Amount"
                                                    name="incentive_amt"
                                                    value={this.state.kol_incentive_info.incentive_amt}
                                                    onChange={async (e) => {
                                                        let { kol_incentive_info } = this.state;
                                                        kol_incentive_info.incentive_amt = e.target.value;
                                                        await this.setState({ kol_incentive_info });
                                                    }}
                                                />
                                                {/* <div className="centerErrLogin">{this.state.kolProfileUsernameErr}</div>  */}
                                            </FormGroup>

                                        </ModalBody>
                                        <ModalFooter style={{ borderTop: '0px' }}>
                                            <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                                                onClick={this.toggleEditIncentiveModal.bind(this)}>Cancel</button>
                                            <button type="submit" class="cetas-button" style={{ marginRight: '20%', }}
                                                onClick={this.EditIncentiveFunc.bind(this)}>
                                                Edit
                                            </button>
                                        </ModalFooter>
                                    </Modal>


                                    {/* Merge kol modal */}
                                    <Modal isOpen={this.state.MergekolModal} toggle={this.togglekolmergeModal_hide.bind(this)} centered>
                                        <ModalHeader toggle={this.togglekolmergeModal_hide.bind(this)}
                                            cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
                                            style={{ borderBottom: '0px' }}>
                                            <h3 style={{ justifyContent: 'center', marginTop: '30px' }} id="login-tit">Merge KOL</h3>
                                        </ModalHeader>
                                        <ModalBody style={{ padding: '0px 80px 0px 80px' }}>

                                            <FormGroup>
                                            <h5 for="engStatusIN" style={{color:'blue'}} >
                                                   Your kol will be merge to this: {this.state.to_kolid}  </h5>

                                                <Label for="engStatusIN" >
                                                    Select kol for merge </Label>
{/*                                                 <Input type="select" id="engStatusIN"  isSearchable={true}
                                                    style={{ height: '50px', width: '100%' }}
                                                    name="engagement_status"
                                                   // value={this.state.kol_incentive_info.engagement_status}
                                                    onChange={async (e) => {
                                                      await this.setState({to_kolid:e.target.value})
                                                    }}
                                                >
                                                    <option value="" >--Select kol--</option>
                                                    {Optionskol}
                                                </Input> */}

                                    <Multiselect style={{height:'40px'}}
                                        data={this.state.kolListData}
                                        placeholder="--Select Kol--"
                                        valueField='client_id'
                                        textField='client_id'
                                        // defaultValue={this.state.ProcedureType}
                                         onSelect={(valueField) => console.log(valueField)}
                                        //  onChange={(e) => {
                                        //      this.setState({to_kolid:e.target.value})
                                        //   }}

                                    //     onChange={(event) => {
                                    //       console.log(event);
                                    //       var joined = [];
                                    //       console.log("length : " + event.length);
                                    //       if (event.length === 0) {
                                    //         this.setState({ from_kolid: [] });
                                    //       }
                                    //       event.map(async (data) => {
                                    //         console.log("data in eve map : " + JSON.stringify(data));
                                    //         joined = joined.concat(data.client_id);
                                    //         console.log("data join : " + joined);
                                    //         await this.setState({
                                    //             from_kolid: joined
                                    //         });
                                    //         console.log("check this ", this.state.from_kolid)

                                    //         var output = this.state.from_kolid.map(function (item) {
                                    //           return item;
                                    //         });

                                    // //         let { PersonalInformation, checkDuplicate } = this.state;
                                    // //         PersonalInformation.procedure_type_id = output.join(",");
                                    // //         checkDuplicate.procedure_type_id = output.join(",");
                                    // //         this.setState({ PersonalInformation, checkDuplicate });

                                    // //         console.log("check this", this.state.PersonalInformation.procedure_type_id)
                                    // //         console.log(output.join(","))
                                    //        })
                                    //  }
                                    // }
                                      />

                                      {/* <div style={{
                                        color: "red", fontSize: "12px", marginTop: "1%",
                                        marginBottom: "5px",
                                      }}>{this.state.proceduretypeErr}</div>
                                          */}
                                                    
                                                {/* <div className="centerErrLogin">{this.state.kolProfileUsernameErr}</div>  */}
                                            </FormGroup>

                                            
                                        </ModalBody>
                                        <ModalFooter style={{ borderTop: '0px' }}>
                                            <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                                                onClick={this.togglekolmergeModal_hide.bind(this)}>Cancel</button>
                                            <button type="submit" class="cetas-button" style={{ marginRight: '20%', }}
                                                onClick={this.mergekolfun.bind(this)}>
                                                Next
                                            </button>
                                        </ModalFooter>
                                    </Modal>

                                </div>
                            </div>
                        </div>
                    </div>
                    <footer className="footer-fixed">
                        <div className="cetas-footer">
                            <div className="container">
                                <p>All rights reserved. www.cetashealthcare.com</p>
                            </div>
                        </div>
                    </footer>
                </div>
            </body>
        );
    }
}

export default ClientProjectList;