import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import {
    FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, Spinner,
    Popover, PopoverHeader, PopoverBody
} from 'reactstrap';
import Axios from 'axios';
import Swal from 'sweetalert2';
import { Multiselect } from 'react-widgets';
import PhoneInput from 'react-phone-input-2';
import LoadingBar from "react-top-loading-bar";
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import { RingLoader, FadeLoader, DotLoader, PropagateLoader } from 'react-spinners';
import { Pie, Doughnut } from 'react-chartjs-2';
import AddToCalendar from 'react-add-to-calendar';
import 'react-add-to-calendar/dist/react-add-to-calendar.css';
import moment from 'moment';

import KolProfileHeader from './KolProfileHeader';

const messagesEndRef = React.createRef()

// session variables
// kol_uniqu_id: unique id of loged in kol
// research_total_survey: total survey count of loged in kol
//  kol_name : name of loged in kol
// kol_login_time_password : kols login time password

class KolProfileDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {

            // id of the login kol
            kolIdForCredential: '',
            researcher_total_survey: '',
            kolNameLogedIn: '',
            kolLoginTimePassword: '',

            // 
            redirect: false,
            redirectFAQs: false,

            // state for progress bar
            progressBar: false,
            progressBar: false,

            // change password modal variables
            CraeteCrendialsModal: false,
            CreateCredentials: {
                login_id: '',
                oldPassword: '',
                newPassword: '',
                confirmPassword: '',
            },
            // unsubscribe modal
            UnsubscibeModal: false,
            // error for create creadentials
            oldPasswordErr: '',
            newPasswordErr: '',
            confirmPasswordErr: '',

            // to navigate to profile edit
            editProfileFlag: false,

            // store data from api
            UpcomingResercherInfotrial: ['a', 'b', 'c'],
            UpcomingResercherInfo: [],
            OngoingResercherInfo: [],
            PastResercherInfo: [],

            SpecialityData: [],
            ProcedureData: [],
            SubSpecialitydata: [],

            ProcedureType: [],
            SubSpecialityType: [],

            CountryListData: [],
            StateData: [],

            showProcedureSubSpecialityDropdown: true,

            // schedule propover
            MySchedulePropover: false,
            MySProfilePropover: false,

            // 
            PersonalInformation: {},
            Biography: {},
            ProfilePhoto: '',
            profile_photo_for_edit: '',
            file: '',
            imagePreviewUrl: '',

            NameFromMap: '',
            stateNameFromMap: '',
            specialityTypeFromMap: '',

            TotalHonarariumEarned: '',

            // for pie chart
            labels: ['Under 18', 'Age 18-54', 'Age 55+'],
            datasets: [{
                data: [2000, 4000, 2800],
                backgroundColor: ['#FF7F41', '#5E62C7', '#1FCE39']
            }],

            kolInteractionDetailsData: [],
            eventToPass: [],
            event: {
                title: 'Sample Event',
                description: 'This is the sample event provided as an example only',
                location: 'Portland, OR',
                startTime: '2016-09-16T20:15:00-04:00',
                endTime: '2016-09-16T21:45:00-04:00'
            },
            lengthOfKol: '',
        };
    }

    componentDidMount = async () => {
        let getSession_Resercher_Total_Survey = await sessionStorage.getItem('research_total_survey')
        if (getSession_Resercher_Total_Survey !== null) {
            await this.setState({
                researcher_total_survey: getSession_Resercher_Total_Survey,
            })
        }
        let getSession_Kol_Unique_Id = await sessionStorage.getItem('kol_uniqu_id')
        if (getSession_Kol_Unique_Id !== null) {
            await this.setState({
                kolIdForCredential: getSession_Kol_Unique_Id,
            })
        }
        let getSession_Kol_Name = await sessionStorage.getItem('kol_name')
        if (getSession_Kol_Name !== null) {
            await this.setState({
                kolNameLogedIn: getSession_Kol_Name,
            })
        }
        let getSession_Kol_Login_Time_Pass = await sessionStorage.getItem('kol_login_time_password')
        if (getSession_Kol_Login_Time_Pass !== null) {
            await this.setState({
                kolLoginTimePassword: getSession_Kol_Login_Time_Pass,
            })
        }
        console.log('researcher_total_survey====', getSession_Resercher_Total_Survey)
        console.log('kolIdForCredential====', getSession_Kol_Unique_Id)
        console.log('kolNameLogedIn====', getSession_Kol_Name)
        console.log("getSession_Kol_Login_Time_Pass========", getSession_Kol_Login_Time_Pass)

        this.setState({
            progressBar: true,
        })

        // to get kol common info
        const options = {
            method: 'POST',
        }

        // fetch(global.websiteUrl+'/service/KOL_Dashboard/get_KOL_Common_Info', options)
        //     .then((Response) => Response.json())
        //     .then((findresponse)=>
        //     {
        //         console.log(findresponse)
        //         var upcoming_research= findresponse.project_count;
        //         console.log("upcoming_research=====",JSON.stringify(upcoming_research));  

        //         var ongoinging_research= findresponse.ongoing_research;
        //         console.log("ongoinging_research=====",JSON.stringify(ongoinging_research)); 

        //         var past_research= findresponse.past_research;
        //         console.log("past_research=====",JSON.stringify(past_research));   

        //         if( upcoming_research )     
        //         this.setState({
        //             UpcomingResercherInfo: findresponse.upcoming_research
        //         })
        //         if( ongoinging_research )     
        //         this.setState({
        //             OngoingResercherInfo: findresponse.ongoing_research
        //             // progressBar: false,
        //         })
        //         if( past_research )     
        //         this.setState({
        //             PastResercherInfo: findresponse.past_research,
        //             progressBar: false,
        //         })           
        //     })
        //  Speciality type data
        // fetch(global.websiteUrl+'/service/Speciality/get_SpecialityList', options)
        // .then((Response) => Response.json())
        // .then((findresponse)=>
        // {
        //     console.log(findresponse)
        //     var testDataSpeciality= findresponse.data;
        //     console.log("testDataSpeciality",JSON.stringify(testDataSpeciality));
        //     this.setState({
        //         SpecialityData:findresponse.data,
        //     })        
        // })

        // Country List
        // fetch(global.websiteUrl+'/service/Location/get_CountryList', options)
        // .then((Response) => Response.json())
        // .then((findresponse)=>
        // {
        //     console.log(findresponse)
        //     var TestCountryList= findresponse.Country_Details;
        //     // var TestKolIddata= findresponse.kol_system_id;
        //     console.log("TestCountryList",JSON.stringify(TestCountryList));
        //     // console.log("TestKolIddata",JSON.stringify(TestKolIddata));
        //     this.setState({
        //         CountryListData: findresponse.Country_Details,
        //     })        
        // })

        if (getSession_Kol_Unique_Id) {
            const url = global.websiteUrl+'/service/KOL_Dashboard/get_KOL_Common_Info';
            let formData = new FormData();
            formData.append('kol_id', getSession_Kol_Unique_Id);
            const config = {
                //   headers: { 'content-type': 'multipart/form-data' }
            }
            axios.post(url, formData, config)
                .then(response => {
                    console.log(response);
                    var project_count = response.data.project_count;
                    console.log("project_count=====", JSON.stringify(project_count));
                    console.log("Total Honararium Earned", response.data.incentive_amt);
                    this.setState({
                        TotalHonarariumEarned: response.data.incentive_amt,
                    })
                    // // project_count.map(async(data)=>{
                    // //     console.log("data.role_type",data.completed_projects)
                    //     if( project_count.completed_projects )
                    //     {
                    //         this.setState({
                    //             PastResercherInfo: project_count.completed_projects,
                    //         }) 
                    //         console.log("past_research=====",this.state.PastResercherInfo);  
                    //     }
                    //     else if( project_count.ongoing_projects ){
                    //         this.setState({
                    //             OngoingResercherInfo: project_count.ongoing_projects,
                    //         })
                    //         console.log("ongoing_research=====",this.state.OngoingResercherInfo);
                    //     }
                    //     else if( project_count.upcoming_projects ){
                    //         this.setState({
                    //             UpcomingResercherInfo: project_count.upcoming_projects,
                    //             progressBar: false,
                    //         })
                    //         console.log("upcoming_research=====",this.state.UpcomingResercherInfo); 
                    //     }
                    // // /})

                    // project_count.map(async(data)=>{
                    //     console.log("data.role_type",data.completed)
                    //     if( data.completed )
                    //     {
                    //         this.setState({
                    //             PastResercherInfo: data.completed,
                    //         }) 
                    //         console.log("past_research=====",this.state.PastResercherInfo);  
                    //     }
                    //     else if( data.ongoing ){
                    //         this.setState({
                    //             OngoingResercherInfo: data.ongoing,
                    //         })
                    //         console.log("ongoing_research=====",this.state.OngoingResercherInfo);
                    //     }
                    //     else if( data.upcoming ){
                    //         this.setState({
                    //             UpcomingResercherInfo: data.upcoming,
                    //             progressBar: false,
                    //         })
                    //         console.log("upcoming_research=====",this.state.UpcomingResercherInfo); 
                    //     }
                    // })
                    if (project_count.upcoming.length >= '1') {
                        this.setState({
                            UpcomingResercherInfo: project_count.upcoming,
                        })
                    }

                    if (project_count.completed.length >= '1') {
                        this.setState({
                            PastResercherInfo: project_count.completed,
                        })
                    }

                    if (project_count.ongoing.length >= '1') {
                        this.setState({
                            OngoingResercherInfo: project_count.ongoing,
                        })
                    }


                    // project_count.map(async(data)=>{
                    //     console.log("data.role_type",data.completed)
                    //     if( data.completed )
                    //     {
                    //         this.setState({
                    //             PastResercherInfo: data.completed,
                    //         }) 
                    //         console.log("past_research=====",this.state.PastResercherInfo);  
                    //     }
                    //     if( data.ongoing ){
                    //         this.setState({
                    //             OngoingResercherInfo: data.ongoing  ,
                    //         })
                    //         console.log("ongoing_research=====",this.state.OngoingResercherInfo);
                    //     }
                    //     if( data.upcoming ){
                    //         this.setState({
                    //             UpcomingResercherInfo: data.upcoming,
                    //             progressBar: false,
                    //         })
                    //         console.log("upcoming_research=====",this.state.UpcomingResercherInfo); 
                    //     }
                    // })
                })
                .catch(error => {
                    console.log(error);
                });
        }

        const url = global.websiteUrl+'/service/KOL_Dashboard/get_KOL_Interaction';
        let formData = new FormData();
        formData.append('kol_id', getSession_Kol_Unique_Id);
        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var lgdata = response.data.kol_info;
                console.log("lgdata Interaction data===========", JSON.stringify(lgdata));
                var lgstatus = response.data.status;
                console.log("lgstatus", JSON.stringify(lgstatus));
                var KOL_Personal_Info = response.data.kol_personal;
                console.log("response message2", response.data.message)
                this.setState({
                    ShowBar: false,
                })
                if (response.data.status == true) {
                    this.setState({
                        kolInteractionDetailsData: response.data.kol_info,
                        PersonalInformation: response.data.kol_personal,
                        Biography: response.data.kol_bio,
                        ProfilePhoto: response.data.kol_profile,
                        profile_photo_for_edit: response.data.kol_profile,
                        imagePreviewUrl: response.data.kol_profile,
                        lengthOfKol: response.data.kol_info.length,
                    })
                    console.log("response kolInteractionDetailsData.....=-=-=-=-=-=", this.state.kolInteractionDetailsData);
                    console.log("length of kolInteractionDetailsData", this.state.lengthOfKol)
                    // if( this.state.kolInteractionDetailsData.length >=1 ){
                    //     this.state.kolInteractionDetailsData.map((el, i) => {

                    //     })
                    // }
                }
                else if (response.data.status == false) {
                    this.setState({
                        kolInteractionDetailsData: [],
                        PersonalInformation: response.data.kol_personal,
                        Biography: response.data.kol_bio,
                        ProfilePhoto: response.data.kol_profile,
                        profile_photo_for_edit: response.data.kol_profile,
                        imagePreviewUrl: response.data.kol_profile,
                    })
                    console.log("response kolInteractionDetailsData.....=-=-=-=-=-=", this.state.kolInteractionDetailsData)
                }

                if (KOL_Personal_Info.specialty_id) {

                    const config = {
                        //   headers: { 'content-type': 'multipart/form-data' }
                    }
                    fetch(global.websiteUrl+'/service/Speciality/get_SpecialityList', options)
                        .then((Response) => Response.json())
                        .then((findresponse) => {
                            console.log(findresponse)
                            var testDataSpeciality = findresponse.data;
                            console.log("testDataSpeciality", JSON.stringify(testDataSpeciality));
                            this.setState({
                                SpecialityData: findresponse.data,
                            })
                            this.state.SpecialityData.map((data) => {
                                // console.log("data.id is state",data.id)
                                // console.log("KOL_Personal_Info.specialty_id",KOL_Personal_Info.specialty_id)
                                if (data.id == KOL_Personal_Info.specialty_id) {
                                    this.setState({
                                        specialityTypeFromMap: data.speciality_type,
                                        editProfileFlag: false,
                                    })
                                    console.log("specialityTypeFromMapspecialityTypeFromMap====", this.state.specialityTypeFromMap)
                                }
                            })
                        })

                    console.log("in Procedure list");
                    console.log("speciality_type_id", this.state.PersonalInformation.specialty_id);
                    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
                    let formData = new FormData();
                    formData.append('speciality_type_id', this.state.PersonalInformation.specialty_id);
                    formData.append('search_type', "Procedure");

                    axios.post(url, formData, config)
                        .then(response => {
                            console.log(response);
                            var proceduredata = response.data;
                            console.log("proceduredata", JSON.stringify(proceduredata));
                            this.setState({
                                ProcedureData: response.data.data,
                                showProcedureSubSpecialityDropdown: false,
                            })
                            console.log("ProcedureData", this.state.ProcedureData)
                            this.setState({
                                showProcedureSubSpecialityDropdown: true,
                            })
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }

                if (KOL_Personal_Info.specialty_id) {
                    console.log("in sub speciality list");
                    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
                    let formData = new FormData();
                    formData.append('speciality_type_id', this.state.PersonalInformation.specialty_id);
                    formData.append('search_type', "Sub Speciality");

                    const config = {
                        //   headers: { 'content-type': 'multipart/form-data' }
                    }
                    axios.post(url, formData, config)
                        .then(response => {
                            console.log(response);
                            var SubSpecialitydata = response.data;
                            console.log("SubSpecialitydata", JSON.stringify(SubSpecialitydata));
                            this.setState({
                                SubSpecialitydata: response.data.data,
                                showProcedureSubSpecialityDropdown: false,
                            })
                            console.log("SubSpecialitydata", this.state.SubSpecialitydata)
                            this.setState({
                                showProcedureSubSpecialityDropdown: true,
                            })
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }

                if (KOL_Personal_Info.country_id) {
                    this.setState({
                        editProfileFlag: true,
                    })
                    // to get country name from id
                    console.log("in country condition+++++++++++++++++++++++")
                    if (KOL_Personal_Info.country_id !== '0') {
                        console.log("in country condition+++++++++++++++++++++++ KOL_Personal_Info.country_id !== '0'")
                        const options = {
                            method: 'POST',
                        }
                        fetch(global.websiteUrl+'/service/Location/get_CountryList', options)
                            .then((Response) => Response.json())
                            .then((findresponse) => {
                                console.log(findresponse)
                                var TestCountryList = findresponse.Country_Details;
                                // var TestKolIddata= findresponse.kol_system_id;
                                console.log("TestCountryList", JSON.stringify(TestCountryList));
                                // console.log("TestKolIddata",JSON.stringify(TestKolIddata));
                                this.setState({
                                    CountryListData: findresponse.Country_Details,
                                })

                                this.state.CountryListData.map((data) => {
                                    // console.log("data.id is",data.id)
                                    // console.log("KOL_Personal_Info.country_id",KOL_Personal_Info.country_id)
                                    if (data.id == KOL_Personal_Info.country_id) {
                                        this.setState({
                                            countryNameFromMap: data.country_name,
                                            editProfileFlag: false,
                                        })
                                        console.log("countryNameFromMapcountryNameFromMap====", this.state.countryNameFromMap)
                                    }
                                })
                            })

                        // to fetch state list from country id
                        const url = global.websiteUrl+'/service/Location/get_StateList';
                        let formData = new FormData();
                        formData.append('country_id', this.state.PersonalInformation.country_id);

                        const config = {
                            //   headers: { 'content-type': 'multipart/form-data' }
                        }
                        axios.post(url, formData, config)
                            .then(response => {
                                console.log(response);
                                var testStateData = response.data.Country_Details;
                                console.log("testStateData", JSON.stringify(testStateData));
                                this.setState({
                                    StateData: response.data.Country_Details,
                                })
                                console.log("StateData======", this.state.StateData)

                                this.state.StateData.map((data) => {
                                    // console.log("data.id is state",data.id)
                                    // console.log("KOL_Personal_Info.state_id",KOL_Personal_Info.state_id)
                                    if (data.id == KOL_Personal_Info.state_id) {
                                        this.setState({
                                            stateNameFromMap: data.state_name,
                                            editProfileFlag: false,
                                        })
                                        console.log("stateNameFromMapstateNameFromMap====", this.state.stateNameFromMap)
                                    }
                                })
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });


        // // to convert procedure id strings into array
        //         var procedureDefault = KOL_Personal_Info.procedure_type_id
        //         console.log("procedureDefault", procedureDefault);
        //         var arrayProcedureDefault = procedureDefault.split(',');
        //         console.log("arrayProcedureDefault", arrayProcedureDefault);

        //         arrayProcedureDefault.map(async(data)=>{
        //             if( data !== "" ){
        //                 var joined = this.state.ProcedureType.concat(data);
        //                 await this.setState({
        //                     ProcedureType: joined
        //                 });
        //                 console.log("ProcedureTypeDefaultValues",this.state.ProcedureTypeDefaultValues)
        //             }
        //         })

        //         // to convert sub speciality id strings into array
        //         var subSpecialityDefault = KOL_Personal_Info.sub_specialty_id
        //         console.log("subSpecialityDefault", subSpecialityDefault);
        //         var arraysubSpecialityDefault = subSpecialityDefault.split(',');
        //         console.log("arraysubSpecialityDefault", arraysubSpecialityDefault);

        //         arraysubSpecialityDefault.map(async(data)=>{
        //             if( data !== "" ){
        //                 var joined = this.state.SubSpecialityType.concat(data);
        //                 await this.setState({
        //                     SubSpecialityType: joined
        //                 });
        //                 console.log("SubSpecialityTypeDefaultValues",this.state.SubSpecialityTypeDefaultValues)
        //             }
        //         })


    }

    // change password functions
    toggleCraeteCrendialsModal(kolIdForCredential) {
        console.log("in cred modal fun");
        this.setState({
            CraeteCrendialsModal: !this.state.CraeteCrendialsModal,
            oldPasswordErr: '',
            newPasswordErr: '',
            confirmPasswordErr: '',
            MySProfilePropover: false,
        });
    }

    validateCreateCredentials() {
        let oldPasswordErr = '';
        let newPasswordErr = '';
        let confirmPasswordErr = '';

        if (!this.state.CreateCredentials.oldPassword) {
            oldPasswordErr = 'Please Enter Old Password';
        }
        else if (this.state.CreateCredentials.oldPassword !== this.state.kolLoginTimePassword) {
            oldPasswordErr = 'Old Password Is Wrong'
        }

        if (!this.state.CreateCredentials.newPassword) {
            newPasswordErr = 'Please Enter New Password'
        }
        if (!this.state.CreateCredentials.confirmPassword) {
            confirmPasswordErr = 'Please Enter New Password'
        }
        else if (this.state.CreateCredentials.newPassword !== this.state.CreateCredentials.confirmPassword) {
            confirmPasswordErr = 'New and Confirm Passwords are Mismatched'
        }

        if (oldPasswordErr || newPasswordErr || confirmPasswordErr) {
            this.setState({ oldPasswordErr, newPasswordErr, confirmPasswordErr });
            return false;
        }
        return true;
    }

    CreateCrendialsFun = async () => {
        console.log("CreateCredentials====", this.state.CreateCredentials)
        console.log("kolIdForCredential====", this.state.kolIdForCredential)
        const isValid = this.validateCreateCredentials();
        if (isValid) {
            this.setState({
                ShowProcedure: true,
            })
            const url = global.websiteUrl+'/service/KOL_Dashboard/Create_KOL_Credentials';
            let formData = new FormData();
            formData.append('kol_id', this.state.kolIdForCredential);
            formData.append('password', this.state.CreateCredentials.newPassword);

            let editedpass = this.state.CreateCredentials.newPassword;
            await this.setState({
                kolLoginTimePassword: editedpass,
            })
            console.log("kolLoginTimePassword after api ====", this.state.kolLoginTimePassword)
            await sessionStorage.setItem('kol_login_time_password', this.state.CreateCredentials.newPassword)

            const config = {
                //   headers: { 'content-type': 'multipart/form-data' }
            }
            axios.post(url, formData, config)
                .then(response => {
                    console.log(response);
                    var lgdata = response.data;
                    console.log("lgdata", JSON.stringify(lgdata));
                    var lgstatus = response.data.status;
                    console.log("lgstatus", JSON.stringify(lgstatus));
                    console.log("response message2", response.data.message)
                    this.setState({
                        ShowProcedure: false,
                    })
                    if (lgstatus == true) {
                        Swal.fire({
                            icon: 'success',
                            text: response.data.message,
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'OK'
                        })
                    }
                    else if (lgstatus == false) {
                        // alert("Wrong Username Or Password")
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Wrong Username or Password!',
                            // showCancelButton: true,
                            confirmButtonColor: '#d33',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'OK'
                        })
                    }
                })
                .catch(error => {
                    console.log(error);
                });

            this.setState({
                CraeteCrendialsModal: false,
                CreateCredentials: {
                    login_id: '',
                    login_id: '',
                    oldPassword: '',
                    newPassword: '',
                    confirmPassword: '',
                },
                oldPasswordErr: '',
                newPasswordErr: '',
                confirmPasswordErr: '',
            });
        }
    }

    editPfofileFunc = async () => {
        await this.setState({
            editProfileFlag: !this.state.editProfileFlag,
            MySProfilePropover: false,
        })
        // this.messagesEnd.scrollIntoView({ behavior: "smooth" });
        // {this.scrollToBottom()}
        // this.scrollToBottom();
    }

    // componentDidUpdate() {
    //   this.scrollToBottom();
    // }

    // scrollToBottom = () => {
    //   this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    // }

    toggleMySchedulePropover = async () => {
        console.log('clicked on propover')
        this.setState({
            MySchedulePropover: !this.state.MySchedulePropover
        })
    }

    toggleMySProfilePropover = async () => {
        console.log('clicked on propover')
        this.setState({
            MySProfilePropover: !this.state.MySProfilePropover
        })
    }

    logout = () => {
        console.log("in logout")
        this.setState({ redirect: true })
        sessionStorage.removeItem('kol_uniqu_id')
        sessionStorage.removeItem('research_total_survey')
        sessionStorage.removeItem('kol_name')
    }

    FAQsFunc = () => {
        this.setState({ redirectFAQs: true })
    }

    // To get procedure 
    getProcedureList = () => {
        console.log("in Procedure list");
        console.log("speciality_type_id", this.state.PersonalInformation.specialty_id);


        const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
        let formData = new FormData();
        formData.append('speciality_type_id', this.state.PersonalInformation.specialty_id);
        formData.append('search_type', "Procedure");

        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var proceduredata = response.data;
                console.log("proceduredata", JSON.stringify(proceduredata));
                // this.setState({
                //    ProcedureData : response.data.data,
                // }) 
                if (response.data.status == true) {
                    this.setState({
                        ProcedureData: response.data.data,
                    })
                }
                else if (response.data.status == false) {
                    this.setState({
                        ProcedureData: [],
                    })
                }
                console.log("ProcedureData", this.state.ProcedureData)
            })
            .catch(error => {
                console.log(error);
            });
    }

    // To get sub speciality list 
    getSubSpecialityList = () => {
        console.log("in sub speciality list");
        const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
        let formData = new FormData();
        formData.append('speciality_type_id', this.state.PersonalInformation.specialty_id);
        formData.append('search_type', "Sub Speciality");

        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var SubSpecialitydata = response.data;
                console.log("SubSpecialitydata", JSON.stringify(SubSpecialitydata));
                // this.setState({
                //    SubSpecialitydata : response.data.data,
                // }) 
                if (response.data.status == true) {
                    this.setState({
                        SubSpecialitydata: response.data.data,
                    })
                }
                else if (response.data.status == false) {
                    this.setState({
                        SubSpecialitydata: [],
                    })
                }
                console.log("SubSpecialitydata", this.state.SubSpecialitydata)
            })
            .catch(error => {
                console.log(error);
            });
    }

    // To get state list
    getStateList = () => {
        console.log("in state list");
        console.log("country id", this.state.PersonalInformation.country_id)

        const url = global.websiteUrl+'/service/Location/get_StateList';
        let formData = new FormData();
        formData.append('country_id', this.state.PersonalInformation.country_id);

        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var testStateData = response.data.Country_Details;
                console.log("testStateData", JSON.stringify(testStateData));
                // this.setState({
                //    StateData : response.data.Country_Details,
                // }) 
                if (response.data.status == true) {
                    this.setState({
                        StateData: response.data.Country_Details,
                    })
                }
                else if (response.data.status == false) {
                    this.setState({
                        StateData: [],
                    })
                }
                console.log("StateData======", this.state.StateData)
            })
            .catch(error => {
                console.log(error);
            });
    }

    ProcedureDropdown = async () => {
        console.log("n procedure dropdown")
        await this.setState({ showProcedureSubSpecialityDropdown: true })
    }

    saveEditedKolInfo = async () => {
        this.setState({
            ShowProcedure: true,
        })

        console.log("personal info", this.state.PersonalInformation)
        console.log('Biography', this.state.Biography)
        console.log("ProfilePhoto", this.state.ProfilePhoto)

        const url = global.websiteUrl+'/service/KOL_Dashboard/update_kol_info';
        let formData = new FormData();
        formData.append('kol_id', this.state.kolIdForCredential);
        formData.append('kol_personal_info', JSON.stringify(this.state.PersonalInformation));
        formData.append('kol_biography', JSON.stringify(this.state.Biography));
        formData.append('profile_photo', this.state.ProfilePhoto);

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var data = response.data;
                console.log("data", data);
                var status = response.data.status;
                console.log("status", status);
                this.setState({
                    ShowProcedure: false,
                })
                if (response.data.status == true) {
                    Swal.fire({
                        icon: 'success',
                        text: response.data.message,
                        confirmButtonColor: '#d33',
                        confirmButtonText: 'OK'
                    })
                    this.setState({ editProfileFlag: false })
                    this.refreshkolPersonalInfo();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: response.data.message,
                        confirmButtonColor: '#d33',
                        confirmButtonText: 'OK'
                    })
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    refreshkolPersonalInfo() {
        this.setState({
            editProfileFlag: true,
        })
        const url = global.websiteUrl+'/service/KOL_Dashboard/get_KOL_Interaction';
        let formData = new FormData();
        formData.append('kol_id', this.state.kolIdForCredential);
        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var lgdata = response.data.kol_info;
                console.log("lgdata Interaction data===========", JSON.stringify(lgdata));
                var lgstatus = response.data.status;
                console.log("lgstatus", JSON.stringify(lgstatus));
                var KOL_Personal_Info = response.data.kol_personal;
                console.log("response in refresh KOL_Personal_Info.....=-=-=-=-=-=", KOL_Personal_Info)
                console.log("response message2", response.data.message)
                this.setState({
                    ShowBar: false,
                })
                if (response.data.status == true) {
                    this.setState({
                        kolInteractionDetailsData: response.data.kol_info,
                        PersonalInformation: response.data.kol_personal,
                        Biography: response.data.kol_bio,
                        ProfilePhoto: response.data.kol_profile,
                        profile_photo_for_edit: response.data.kol_profile,
                        imagePreviewUrl: response.data.kol_profile,
                        editProfileFlag: false,
                    })
                    console.log("response kolInteractionDetailsData.....=-=-=-=-=-=", this.state.kolInteractionDetailsData)
                    console.log("response in refresh kol_personal.....=-=-=-=-=-=", this.state.kol_personal)
                }
                else if (response.data.status == false) {
                    this.setState({
                        kolInteractionDetailsData: [],
                        PersonalInformation: response.data.kol_personal,
                        Biography: response.data.kol_bio,
                        profile_photo_for_edit: response.data.kol_profile,
                        imagePreviewUrl: response.data.kol_profile,
                        editProfileFlag: false,
                    })
                    console.log("response kolInteractionDetailsData.....=-=-=-=-=-=", this.state.kolInteractionDetailsData)
                }

                if (KOL_Personal_Info.specialty_id) {
                    const options = {
                        method: 'POST',
                    }
                    const config = {
                        //   headers: { 'content-type': 'multipart/form-data' }
                    }
                    fetch(global.websiteUrl+'/service/Speciality/get_SpecialityList', options)
                        .then((Response) => Response.json())
                        .then((findresponse) => {
                            console.log(findresponse)
                            var testDataSpeciality = findresponse.data;
                            console.log("testDataSpeciality", JSON.stringify(testDataSpeciality));
                            this.setState({
                                SpecialityData: findresponse.data,
                            })
                            this.state.SpecialityData.map((data) => {
                                // console.log("data.id is state",data.id)
                                // console.log("KOL_Personal_Info.specialty_id",KOL_Personal_Info.specialty_id)
                                if (data.id == KOL_Personal_Info.specialty_id) {
                                    this.setState({
                                        specialityTypeFromMap: data.speciality_type,
                                        editProfileFlag: false,
                                    })
                                    console.log("specialityTypeFromMapspecialityTypeFromMap====", this.state.specialityTypeFromMap)
                                }
                            })
                        })
                }

                if (KOL_Personal_Info.specialty_id) {
                    console.log("in Procedure list");
                    console.log("speciality_type_id", this.state.PersonalInformation.specialty_id);
                    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
                    let formData = new FormData();
                    formData.append('speciality_type_id', this.state.PersonalInformation.specialty_id);
                    formData.append('search_type', "Procedure");
                    const config = {
                        //   headers: { 'content-type': 'multipart/form-data' }
                    }
                    axios.post(url, formData, config)
                        .then(response => {
                            console.log(response);
                            var proceduredata = response.data;
                            console.log("proceduredata", JSON.stringify(proceduredata));
                            this.setState({
                                ProcedureData: response.data.data,
                                showProcedureSubSpecialityDropdown: false,
                            })
                            console.log("ProcedureData", this.state.ProcedureData)
                            this.setState({
                                showProcedureSubSpecialityDropdown: true,
                            })
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }

                if (KOL_Personal_Info.specialty_id) {
                    console.log("in sub speciality list");
                    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
                    let formData = new FormData();
                    formData.append('speciality_type_id', this.state.PersonalInformation.specialty_id);
                    formData.append('search_type', "Sub Speciality");

                    const config = {
                        //   headers: { 'content-type': 'multipart/form-data' }
                    }
                    axios.post(url, formData, config)
                        .then(response => {
                            console.log(response);
                            var SubSpecialitydata = response.data;
                            console.log("SubSpecialitydata", JSON.stringify(SubSpecialitydata));
                            this.setState({
                                SubSpecialitydata: response.data.data,
                                showProcedureSubSpecialityDropdown: false,
                            })
                            console.log("SubSpecialitydata", this.state.SubSpecialitydata)
                            this.setState({
                                showProcedureSubSpecialityDropdown: true,
                            })
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }
                if (KOL_Personal_Info.country_id) {
                    if (KOL_Personal_Info.country_id !== '0') {
                        const url = global.websiteUrl+'/service/Location/get_StateList';
                        let formData = new FormData();
                        formData.append('country_id', this.state.PersonalInformation.country_id);

                        const config = {
                            //   headers: { 'content-type': 'multipart/form-data' }
                        }
                        axios.post(url, formData, config)
                            .then(response => {
                                console.log(response);
                                var testStateData = response.data.Country_Details;
                                console.log("testStateData", JSON.stringify(testStateData));
                                this.setState({
                                    StateData: response.data.Country_Details,
                                })
                                console.log("StateData======", this.state.StateData)
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    toggleUnsubscibeModal = async () => {
        this.setState({
            UnsubscibeModal: !this.state.UnsubscibeModal,
        });
    }

    UnsubscibeFunc = async () => {
        console.log("in unsubscribe func")
        this.setState({
            ShowProcedure: true,
        })
        const url = global.websiteUrl+'/service/KOL_Dashboard/Update_KOL_terms_conditions';
        let formData = new FormData();
        formData.append('kol_id', this.state.kolIdForCredential);
        formData.append('flag_type', "unsubscribe");
        formData.append('unsubscribe_status', "deactive");

        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var proceduredata = response.data;
                if (response.data.status == true) {
                    Swal.fire({
                        icon: 'success',
                        text: response.data.message,
                        confirmButtonColor: '#d33',
                        confirmButtonText: 'OK'
                    })
                    this.setState({
                        redirect: true,
                        ShowProcedure: false,
                    })
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: response.data.message,
                        confirmButtonColor: '#d33',
                        confirmButtonText: 'OK'
                    })
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    getPhoto = event => {
        event.preventDefault();
        console.log("photo event", event.target.files[0])

        if (event.target.files[0]) {
            let reader = new FileReader();
            let file = event.target.files[0];
            // this.setState({file: e.target.files[0],})
            console.log("file my", this.state.file);
            reader.onloadend = () => {
                // let { PersonalInformation } = this.state;
                // PersonalInformation.profile_photo = file.name ;                               

                this.setState({
                    ProfilePhoto: file,
                    // PersonalInformation,
                    imagePreviewUrl: reader.result,
                    profile_photo_for_edit: '',
                });
            }
            reader.readAsDataURL(file);
        }
    }

    render() {
        if (this.state.redirect) {
            return (<Redirect to={'/'} />)
        }

        if (this.state.redirectFAQs) {
            return (<Redirect to={'/FAQs'} />)
        }

        let speciality_type = this.state.SpecialityData;
        let OptionsSpeciality = speciality_type.map((speciality_type) =>
            <option key={speciality_type.id} value={speciality_type.id}>
                {speciality_type.speciality_type}</option>
        );

        let Country_list = this.state.CountryListData;
        let OptionsCountry = Country_list.map((Country_list) =>
            <option key={Country_list.id} value={Country_list.id}>
                {Country_list.country_name}</option>
        );

        let state_list = this.state.StateData;
        let OptionsState = state_list.map((state_list) =>
            <option key={state_list.id} value={state_list.id}>
                {state_list.state_name}</option>
        );

        let { imagePreviewUrl } = this.state;
        let imagePreview = null;

        if (this.state.profile_photo_for_edit !== 'NULL' && this.state.profile_photo_for_edit) {
            // console.log("in profile photo for edit")
            // console.log("image is",this.state.profile_photo_for_edit)
            imagePreview = (<div> <img className="pro-image rounded-circle" src={this.state.profile_photo_for_edit} /> </div>)
        }
        else
            if (imagePreviewUrl) {
                // console.log("in profile photo original")
                imagePreview = (<img className="pro-image rounded-circle" src={imagePreviewUrl} />);
            } else {
                // console.log("in profile photo not selected")
                imagePreview = (<div className="photo-box"></div>);
            }

        return (
            <body>
                <div>
                    {this.state.ShowBar ? <Modal isOpen={true} centered>
                        <PropagateLoader color={'#A90007'} size='30px' loading='true' />
                    </Modal> : null}
                    {this.state.progressBar ? <div style={{ position: 'fixed', top: '0' }}>
                        <LoadingBar
                            progress='100'
                            height={3} color="#A90007" transitionTime={4000}
                            loaderSpeed={3000} />
                    </div> : null}

                    {this.state.ShowProcedure ? <Modal isOpen={true} className='propagateLoader' centered>
                        <PropagateLoader color={'#A90007'} size='40px' loading='true' />
                    </Modal> : null}
                    <div>
                        <div>
                            {/* <KolProfileHeader KolName={this.state.kolNameLogedIn}/> */}
                            <div class="header-nav">
                                <div class="container">
                                    <nav class="navbar navbar-expand-lg">
                                        <a className="navbar-brand" href="https://cetas-healthcare.com/">
                                            <img
                                                src={require('../Assets/Images/cetas-logo.png')}
                                            />
                                        </a>
                                        <button class="navbar-toggler" type="button" data-toggle="collapse"
                                            data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup"
                                            aria-expanded="false" aria-label="Toggle navigation">
                                            <span class="navbar-toggler-icon"></span>
                                        </button>
                                        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                                            <div class="navbar-nav">
                                                {/* <a class="nav-item nav-link active" href="#">Home <span
                                    class="sr-only">(current)</span></a>
                                <a class="nav-item nav-link" href="#">Projects</a>
                                <li class="nav-item  dropdown multi-option">
                                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        KOLs
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <a class="dropdown-item" href="#">KOL Ranking</a>
                                        <a class="dropdown-item" href="#">KOL QC</a>
                                    </div>
                                </li>
                                <a class="nav-item nav-link" href="#">
                                    <img src={require('../Assets/Images/setting.svg')} /> Search
                                </a> */}

                                            </div>
                                        </div>
                                        <div class="sub-menu">
                                            {/* <a href="#">unsubscribe</a> */}
                                            {/* <a 
                                onClick={this.FAQsFunc.bind(this)}
                            >
                                FAQs
                            </a>
                            <a >Contact Us</a> */}
                                        </div>
                                        <a class="nav-item nav-link username-box" id="popoverClickForMyProfile"
                                            onClick={this.toggleMySProfilePropover.bind(this)}
                                        >
                                            <img src={require('../Assets/Images/settings-white-24dp.svg')} /> My Profile </a>
                                        <div>
                                            <Popover placement="bottom" trigger="legacy" target="popoverClickForMyProfile"
                                                isOpen={this.state.MySProfilePropover}
                                                toggle={this.toggleMySProfilePropover.bind(this)}
                                            >
                                                <PopoverHeader>Settings</PopoverHeader>
                                                {/* <PopoverHeader>--Select--</PopoverHeader> */}
                                                <PopoverBody>
                                                    <text style={{ cursor: 'pointer' }} onClick={this.editPfofileFunc.bind(this)} >
                                                        Edit Profile</text>
                                                    <hr />
                                                    {/* <a>
                                    
                                    </a> */}
                                                    <text style={{ cursor: 'pointer' }} onClick={this.toggleCraeteCrendialsModal.bind(this)} >
                                                        Change Password
                                                    </text>
                                                </PopoverBody>
                                            </Popover>
                                        </div>


                                        <a className="nav-item nav-link username-box  float-right"
                                            onClick={this.logout.bind(this)}>
                                            <img
                                                src={require('../Assets/Images/logoutByVibha.svg')}
                                            />
                                            Logout
                                        </a>
                                    </nav>
                                </div>
                            </div>
                            <div class="container">
                                <div class="content-wrapper">
                                    <div class="screen-headingbox">
                                        <h2>Profile Overview</h2>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12 col-sm-12 col-xs-12">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class="tileforkoldash">
                                                        <div class="tile-heading-kolprofile">
                                                            <h3>Upcoming Research Studies</h3>
                                                            <ul class="ResearchList-box">
                                                                {/* this.state.ProfessionalMemberships.map((el, i) => { */}
                                                                {this.state.UpcomingResercherInfo.map((data) => {
                                                                    // if( i <= 2 ){
                                                                    return (
                                                                        <div >
                                                                            {console.log("i of upcoming resercher", data)}
                                                                            <li
                                                                                value={data}
                                                                                style={{ cursor: "pointer" }}
                                                                                key={data}
                                                                            >
                                                                                {data}
                                                                            </li>
                                                                        </div>
                                                                    )
                                                                    // }
                                                                })}

                                                            </ul>

                                                        </div>
                                                        {/* <text>{this.state.UpcomingResercherInfo}</text> */}
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="tileforkoldash">
                                                        <div class="tile-heading-kolprofile">
                                                            <h3>Ongoing Research Studies</h3>
                                                            <ul class="ResearchList-box">
                                                                {this.state.OngoingResercherInfo.map((item, i) => {
                                                                    if (i <= 2) {
                                                                        return (
                                                                            <div key={i}>
                                                                                <li
                                                                                    value={item}
                                                                                    style={{ cursor: "pointer" }}
                                                                                    key={item}
                                                                                >
                                                                                    {item}
                                                                                </li>
                                                                            </div>
                                                                        )
                                                                    }
                                                                })}

                                                            </ul>

                                                        </div>
                                                        {/* <text>{this.state.OngoingResercherInfo}</text> */}
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="tileforkoldash">
                                                        <div class="tile-heading-kolprofile">
                                                            <h3>Past Research Studies</h3>
                                                            <ul class="ResearchList-box">
                                                                {this.state.PastResercherInfo.map((item, i) => {
                                                                    if (i <= 2) {
                                                                        return (
                                                                            <div key={i}>
                                                                                <li
                                                                                    value={item}
                                                                                    style={{ cursor: "pointer" }}
                                                                                    key={item}
                                                                                >
                                                                                    {item}
                                                                                </li>
                                                                            </div>
                                                                        )
                                                                    }
                                                                })}

                                                            </ul>

                                                        </div>
                                                        {/* <text>{this.state.PastResercherInfo}</text> */}
                                                    </div>
                                                </div>
                                                {/* <div class="col-md-12">
                                <div class="tile">
                                    <div class="tile-heading-kolprofile">
                                        <h3>My recent engagements</h3>
                                        <ul class="ResearchList-box">
                                            <li>Research of Obstetrics & Gynecology <span class="date float-right">
                                                <img
                                                    src={require('../Assets/Images/clock.svg')} 
                                                />   Date: 31-10-2020</span>
                                            </li>
                                            <li>Research of Forensic Medicine & Toxicology <span class="date float-right"><img src="img/clock.svg" /> Date: 31-10-2020</span></li>
                                            <li>Engagements medical research <span class="date float-right"><img src="img/clock.svg" /> Date: 31-10-2020</span></li>
                                            <li>Research of Forensic Medicine & Toxicology <span class="date float-right"><img src="img/clock.svg" /> Date: 31-10-2020</span></li>
                                            <li>Engagements medical research <span class="date float-right"><img src="img/clock.svg" /> Date: 31-10-2020</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div> */}
                                                <div class="col-md-4">
                                                    <div class="tile imp-boxes">
                                                        <div class="tile-heading-kolprofile">
                                                            <h3>My Schedule</h3>
                                                            <a href="#" class="red">
                                                                <h2 class="bold">20</h2>
                                                            </a>
                                                            <span class="month">
                                                                Nov
                                                            </span>
                                                        </div>
                                                        <div class="box-image" id="PopoverClick">
                                                            <img style={{ cursor: 'pointer' }}
                                                                onClick={this.toggleMySchedulePropover.bind(this)}
                                                                src={require('../Assets/Images/calendar.svg')}
                                                            />
                                                        </div>
                                                        {/* propover */}
                                                        <div>
                                                            <Popover placement="right" trigger="legacy" target="PopoverClick"
                                                                isOpen={this.state.MySchedulePropover}
                                                                toggle={this.toggleMySchedulePropover.bind(this)}>
                                                                <PopoverHeader>My Schedule</PopoverHeader>
                                                                <PopoverBody>
                                                                    {/* <div className="row"> */}
                                                                    {/* Date: 04-December-2020
                                                    Time: 12PM  */}
                                                                    {/* <div style={{marginBottom:'5%',marginTop:'5%', marginLeft:'15%'}}> 
                                                        <AddToCalendar 
                                                            event={this.state.kolInteractionDetailsData}
                                                            buttonLabel="Add to My Calender"
                                                        /> */}
                                                                    <div>
                                                                        {
                                                                            this.state.kolInteractionDetailsData.map((el, i) => {
                                                                                // let timeforevent=  el.date +'T'+ el.time + '-' + el.timezone;
                                                                                // let timeforevent=  el.date +'T'+ el.time ;
                                                                                // let timeforevent=  el.date +'T'+ el.time + '-04:00';
                                                                                // let timeforevent=  el.date +'T'+ el.time + 'GMT' + el.timezone;

                                                                                let timeforevent = el.date + 'T' + el.time + el.timezone;
                                                                                // let timeforevent=  el.date +'T'+ el.time + '+09:30';
                                                                                // let timeforevent=  el.date +'T'+ el.time + moment().tz('Australia/Adelaide');
                                                                                console.log("time for event is", timeforevent)
                                                                                let event = {
                                                                                    title: el.event_name,
                                                                                    description: el.engagement_type,
                                                                                    location: '',
                                                                                    // date: el.date,
                                                                                    startTime: timeforevent,
                                                                                    // endTime: '2016-09-16T21:45:00-04:00'    
                                                                                    endTime: timeforevent,
                                                                                    // timezone: '	Indian/Kerguelen',  
                                                                                };
                                                                                return (
                                                                                    <div key={i}>
                                                                                        <div style={{ marginBottom: '5%', marginTop: '5%', marginLeft: '15%' }}>
                                                                                            <text>Date: {el.date}</text>
                                                                                            <text>Time: {el.time}{el.timezone}</text>

                                                                                            <AddToCalendar
                                                                                                event={event}
                                                                                                buttonLabel="Add to My Calender"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                    {/* </div> */}
                                                                    {/* </div> */}


                                                                </PopoverBody>
                                                            </Popover>
                                                        </div>
                                                        <hr />
                                                        <small><b>{this.state.lengthOfKol} meetings</b> schedule in current week</small>
                                                    </div>
                                                </div>
                                                {/* <div class="col-md-4">
                                <div class="tile imp-boxes">
                                    <div class="tile-heading-kolprofile">
                                        <h3>Account info</h3>
                                        <h5><b>username:</b> drsalimroy98</h5>
                                        <button class="cetas-button-small" data-toggle="modal" data-target="#reset-password"
                                            onClick={this.toggleCraeteCrendialsModal.bind(this )}>
                                            Reset password
                                        </button>
                                    </div>
                                    <div class="box-image">
                                        <img
                                            src={require('../Assets/Images/doctor-gray.svg')} 
                                        />
                                    </div>
                                </div>
                            </div> */}
                                                <div class="col-md-4">
                                                    <div class="tile imp-boxes">
                                                        <div class="tile-heading-kolprofile">
                                                            <h3>Total Honararium Earned</h3>
                                                            <div class="servey-box">
                                                                {/* <h5>Total Surveys</h5>
                                            <a href="#" class="red">
                                                <h2 class="bold">20</h2>
                                            </a> */}
                                                            </div>
                                                            <div class="servey-box">
                                                                {/* <h5>Completed Surveys</h5> */}
                                                                <a href="#" class="red">
                                                                    <h2 class="bold">{this.state.TotalHonarariumEarned}</h2>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div class="box-image">
                                                            {/* <img
                                            src={require('../Assets/Images/graph.svg')} 
                                        /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div class="col-md-12">
                                <div class="tile imp-boxes">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <Doughnut
                                                data={{
                                                    labels: this.state.labels,
                                                    datasets: this.state.datasets
                                                }}
                                                height= '120%'
                                                options={{
                                                    cutoutPercentage: 25,
                                                    title:{
                                                        display:true,
                                                        text:'Research summary dashboard',
                                                        fontSize:20,
                                                        fontColor: 'black'
                                                    },
                                                    legend:{
                                                        display:true,
                                                        position:'left',
                                                    }
                                                }}
                                            />
                                        </div> 
                                        <div  class="col-md-1" style={{ borderLeft: '1px solid grey', height: '200px'}}></div>
                                        <div class="col-md-5">
                                            <Doughnut
                                                data={{
                                                    labels: this.state.labels,
                                                    datasets: this.state.datasets
                                                }}
                                                
                                                height= '145%'
                                                options={{
                                                    title:{
                                                        display:true,
                                                        text:'Incentive summary dashboard(USD)',
                                                        fontSize:20,
                                                        fontColor: 'black'
                                                    },
                                                    cutoutPercentage: 25,
                                                    legend:{
                                                        display:true,
                                                        position:'left'
                                                    }
                                                }}
                                            />
                                        </div>   
                                    </div>
                                    <hr />
                                    <div class="sub-menu">
                                        <button class="cetas-button-small bold" data-toggle="modal" 
                                            data-target="#client-login" style={{marginTop:'6%'}}>Redeem Now
                                        </button>
                                        <a href="#">unsubscribe</a>
                                        <a href="#">FAQs</a>
                                        <a href="#">Contact Us</a>
                                    </div>
                                </div>
                            </div> */}

                                                {
                                                    (this.state.editProfileFlag == false) ? (
                                                        <div class="col-md-12"
                                                            ref={(el) => { this.messagesEnd = el; }}
                                                        >
                                                            <div class="tile imp-boxes">
                                                                <div class="kol-info">
                                                                    <div class="profile-section">
                                                                        <div class="img-box">
                                                                            {/* <img src={this.state.ProfilePhoto} 
                                                            class="pro-image rounded-circle"
                                                        /> */}
                                                                            {imagePreview}
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="col-md-12">
                                                                                <div class="pro-description">
                                                                                    <p class="float-left">
                                                                                        <span class="bold">{this.state.PersonalInformation.salutation} {this.state.PersonalInformation.doctor_full_name}</span>
                                                                                        <br />
                                                                                        {/* M.D (Optha) */}
                                                                                        <br />
                                                                                        {/* Opthymologist - Dinanath Mangeshkar hospital */}
                                                                                    </p>
                                                                                </div>
                                                                                <div class="pro-description float-right">
                                                                                    <p class="float-left">
                                                                                        <span style={{ marginLeft: '-10%', }}>
                                                                                            <img style={{ marginLeft: '-15%', marginTop: '1%' }}
                                                                                                src={require('../Assets/Images/clock.svg')}
                                                                                            />
                                                                                            Last Updated: <b>{this.state.PersonalInformation.kol_updated_date}</b>
                                                                                        </span>
                                                                                        {/* <button class="cetas-button-small" style={{marginLeft:'5%'}}
                                                                        onClick={this.editPfofileFunc.bind(this)}
                                                                    >
                                                                        <img
                                                                                src={require('../Assets/Images/pencil.svg')} 
                                                                        />  
                                                                        Edit profile
                                                                    </button> */}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="additional-info">
                                                                            {/* <br/><br/> */}
                                                                            <h3>
                                                                                Biography
                                                                            </h3>
                                                                            <p>{this.state.Biography.biography}</p>
                                                                            <h3>
                                                                                Education and Experiance
                                                                            </h3>
                                                                            <p>{this.state.PersonalInformation.qualification}, {this.state.PersonalInformation.experience}</p>
                                                                            <hr />
                                                                            <div class="row">
                                                                                <div class="col-md-4">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">Alma Mater</label>
                                                                                            <info>{this.state.PersonalInformation.medical_schools}</info>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-4">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">License No.</label>
                                                                                            <info>{this.state.PersonalInformation.medical_registration_no}</info>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-4">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">Speciality</label>
                                                                                            {/* <info>{this.state.PersonalInformation.specialty_id}</info> */}
                                                                                            <info>{this.state.specialityTypeFromMap}</info>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-4">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">Sub Speciality</label>
                                                                                            <info>{this.state.PersonalInformation.sub_specialty_id}</info>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-4">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">Procedure Type</label>
                                                                                            <info>{this.state.PersonalInformation.procedure_type_id}</info>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-4">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">LinkedIn Profile</label>
                                                                                            <info>{this.state.PersonalInformation.linked_in_profile}</info>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-4">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">Twitter Handle</label>
                                                                                            <info>{this.state.PersonalInformation.twitter_handle}</info>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-4">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">Zip Code</label>
                                                                                            <info>{this.state.PersonalInformation.zip_code}</info>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <hr />
                                                                            <h3>
                                                                                Contact info
                                                                            </h3>
                                                                            <div class="row">
                                                                                <div class="col-md-4">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">Address</label>
                                                                                            <info>{this.state.PersonalInformation.address_1},{this.state.PersonalInformation.address_2}</info>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-4">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">Phone number</label>
                                                                                            <info>{this.state.PersonalInformation.doctor_contact_no}</info>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-4">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">official Email id</label>
                                                                                            <info>{this.state.PersonalInformation.doctor_email}</info>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-4">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">Fax</label>
                                                                                            <info>{this.state.PersonalInformation.fax}</info>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-4">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">Assistant Contact No</label>
                                                                                            <info>{this.state.PersonalInformation.assistance_contact_no}</info>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-4">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">Assistant Email ID</label>
                                                                                            <info>{this.state.PersonalInformation.assistance_email}</info>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-4">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">Website</label>
                                                                                            <info>{this.state.PersonalInformation.website}</info>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-4">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">Country</label>
                                                                                            <info>{this.state.countryNameFromMap}</info>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-4">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">State</label>
                                                                                            <info>{this.state.stateNameFromMap}</info>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                            <hr />
                                                                            <a style={{ color: '#007bff' }} className="float-right"
                                                                                onClick={this.toggleUnsubscibeModal.bind(this)}
                                                                            >
                                                                                Unsubscribe
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div class="col-md-12">
                                                            <div class="tile imp-boxes">
                                                                <div class="kol-info">
                                                                    <div class="profile-section">
                                                                        {/* <div class="img-box">
                                                        <img src={require('../Assets/Images/clock.svg')} class="pro-image rounded-circle" />
                                                    </div> */}
                                                                        <div class="col-md-4">
                                                                            {imagePreview}
                                                                            {
                                                                                <div>
                                                                                    <input type='file'
                                                                                        id="myInput"
                                                                                        accept="image/*"
                                                                                        onChange={this.getPhoto} hidden />
                                                                                    <label htmlFor="myInput"
                                                                                        style={{ color: 'blue', fontSize: "15px", }}>
                                                                                        Upload Photo
                                                                                    </label>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="col-md-12">
                                                                                <div class="pro-description">
                                                                                    <p class="float-left">
                                                                                        {/* <div class="form-group">
                                                                        <label for="exampleFormControlTextarea1">Name</label>
                                                                        <input type="text" class="form-control" value="Dr. Frank Roy" />
                                                                    </div>

                                                                    <div class="form-group">
                                                                        <label for="exampleFormControlTextarea1">basic education</label>
                                                                        <input type="text" class="form-control" value="M.D (Optha)" />
                                                                    </div>

                                                                    <div class="form-group">
                                                                        <label for="exampleFormControlTextarea1">speciality</label>
                                                                        <input type="text" class="form-control" value="Opthymologist - Dinanath Mangeshkar hospital" />
                                                                    </div> */}

                                                                                        {/* <br/><br/> */}
                                                                                        {/* <br/><br/> */}
                                                                                    </p>
                                                                                </div>
                                                                                <div class="pro-description float-right">
                                                                                    <p class="float-left">
                                                                                        <button class="cetas-secondry-button" data-toggle="modal" data-target="#delete-item"
                                                                                            onClick={this.editPfofileFunc.bind(this)}
                                                                                        >
                                                                                            Cancel
                                                                                        </button>
                                                                                        &nbsp;
                                                                                        <button class="cetas-button" data-toggle="modal" data-target="#edit-item"
                                                                                            onClick={this.saveEditedKolInfo.bind(this)}
                                                                                        >
                                                                                            Save
                                                                                        </button>

                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="additional-info">
                                                                            <h3>
                                                                                Personal Information
                                                                            </h3>
                                                                            <div class="row">
                                                                                <div class="col-md-6">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">
                                                                                                Full Name <span class="mandatory">*</span></label>
                                                                                            <input type="text" class="form-control"
                                                                                                autoFocus
                                                                                                id="input3" placeholder="Enter Full Name"
                                                                                                name="doctor_full_name"
                                                                                                value={this.state.PersonalInformation.doctor_full_name}
                                                                                                onChange={(e) => {
                                                                                                    var re = /^[a-z\- ]+$/i;
                                                                                                    console.log('return : ' + re.test(e.target.value))
                                                                                                    if (re.test(e.target.value)) {
                                                                                                        // this.onKeyPressRestrictOnlyChar(e)
                                                                                                        let { PersonalInformation } = this.state;
                                                                                                        PersonalInformation.doctor_full_name = e.target.value;
                                                                                                        this.setState({ PersonalInformation });
                                                                                                    }
                                                                                                    // console.log("e.length",e.target.value.length)
                                                                                                    if (e.target.value.length == '0') {
                                                                                                        // this.onKeyPressRestrictOnlyChar(e)
                                                                                                        let { PersonalInformation } = this.state;
                                                                                                        PersonalInformation.doctor_full_name = '';
                                                                                                        this.setState({ PersonalInformation });
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-6">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">
                                                                                                First Name <span class="mandatory">*</span></label>
                                                                                            <input type="text" class="form-control"
                                                                                                autocomplete="off"
                                                                                                id="input5" placeholder="Enter First Name"
                                                                                                name="first_name"
                                                                                                value={this.state.PersonalInformation.first_name}
                                                                                                onChange={(e) => {
                                                                                                    var re = /^[a-z\- ]+$/i;
                                                                                                    if (re.test(e.target.value)) {
                                                                                                        let { PersonalInformation } = this.state;
                                                                                                        PersonalInformation.first_name = e.target.value;
                                                                                                        this.setState({ PersonalInformation });
                                                                                                    }
                                                                                                    if (e.target.value.length == '0') {
                                                                                                        // this.onKeyPressRestrictOnlyChar(e)
                                                                                                        let { PersonalInformation } = this.state;
                                                                                                        PersonalInformation.first_name = '';
                                                                                                        this.setState({ PersonalInformation });
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-6">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">
                                                                                                Middle Name </label>
                                                                                            <input type="text" class="form-control"
                                                                                                autocomplete="off"
                                                                                                id="input5" placeholder="Enter Middle Name"
                                                                                                name="middle_name"
                                                                                                value={this.state.PersonalInformation.middle_name}
                                                                                                onChange={(e) => {
                                                                                                    var re = /^[a-z\- ]+$/i;
                                                                                                    if (re.test(e.target.value)) {
                                                                                                        let { PersonalInformation } = this.state;
                                                                                                        PersonalInformation.middle_name = e.target.value;
                                                                                                        this.setState({ PersonalInformation });
                                                                                                    }
                                                                                                    if (e.target.value.length == '0') {
                                                                                                        // this.onKeyPressRestrictOnlyChar(e)
                                                                                                        let { PersonalInformation } = this.state;
                                                                                                        PersonalInformation.middle_name = '';
                                                                                                        this.setState({ PersonalInformation });
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-6">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1" >
                                                                                                Last Name <span class="mandatory">*</span></label>
                                                                                            <input type="text" class="form-control"
                                                                                                autocomplete="off"
                                                                                                id="input5" placeholder="Enter Last Name"
                                                                                                name="last_name"
                                                                                                value={this.state.PersonalInformation.last_name}
                                                                                                onChange={(e) => {
                                                                                                    var re = /^[a-z\- ]+$/i;
                                                                                                    if (re.test(e.target.value)) {
                                                                                                        let { PersonalInformation } = this.state;
                                                                                                        PersonalInformation.last_name = e.target.value;
                                                                                                        this.setState({ PersonalInformation });
                                                                                                    }
                                                                                                    if (e.target.value.length == '0') {
                                                                                                        // this.onKeyPressRestrictOnlyChar(e)
                                                                                                        let { PersonalInformation } = this.state;
                                                                                                        PersonalInformation.last_name = '';
                                                                                                        this.setState({ PersonalInformation });
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-6">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">
                                                                                                Clinical Experience <span class="mandatory">*</span></label>
                                                                                            <Input type="select" id="roleSelect" className="form-control"
                                                                                                style={{ height: '50px' }}
                                                                                                name="experience"
                                                                                                value={this.state.PersonalInformation.experience}
                                                                                                onChange={(e) => {
                                                                                                    let { PersonalInformation } = this.state;
                                                                                                    PersonalInformation.experience = e.target.value;
                                                                                                    this.setState({ PersonalInformation });
                                                                                                }}
                                                                                            >
                                                                                                <option value="" >--Select Experience--</option>
                                                                                                <option value="1 year" >1 year</option>
                                                                                                <option value="2 years" >2 years</option>
                                                                                                <option value="3 years" >3 years</option>
                                                                                                <option value="4 years" >4 years</option>
                                                                                                <option value="5 years" >5 years</option>
                                                                                                <option value="6 years" >6 years</option>
                                                                                                <option value="7 years" >7 years</option>
                                                                                                <option value="8 years" >8 years</option>
                                                                                                <option value="9 years" >9 years</option>
                                                                                                <option value="10 years" >10 years</option>
                                                                                                <option value="11 years" >11 years</option>
                                                                                                <option value="12 years" >12 years</option>
                                                                                                <option value="13 years" >13 years</option>
                                                                                                <option value="14 years" >14 years</option>
                                                                                                <option value="15 years" >15 years</option>
                                                                                                <option value="16 years" >16 years</option>
                                                                                                <option value="17 years" >17 years</option>
                                                                                                <option value="18 years" >18 years</option>
                                                                                                <option value="19 years" >19 years</option>
                                                                                                <option value="20 years" >20 years</option>
                                                                                                <option value="21 years" >21 years</option>
                                                                                                <option value="22 years" >22 years</option>
                                                                                                <option value="23 years" >23 years</option>
                                                                                                <option value="24 years" >24 years</option>
                                                                                                <option value="25+ years" >25+ years</option>
                                                                                                <option value="N/A" >N/A</option>
                                                                                            </Input>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-6">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1" >
                                                                                                Qualification  <span class="mandatory">*</span></label>
                                                                                            <input type="text" class="form-control disabled"
                                                                                                autocomplete="off"
                                                                                                id="input6" placeholder="Enter Qualification "
                                                                                                name="qualification"
                                                                                                value={this.state.PersonalInformation.qualification}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-6">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1" >
                                                                                                {/* Medical Schools  */}
                                                                                                Alma Mater
                                                                                            </label>
                                                                                            <input type="text" class="form-control disabled"
                                                                                                autocomplete="off"
                                                                                                id="input7" placeholder="Enter Alma Mater"
                                                                                                name="medical_schools"
                                                                                                value={this.state.PersonalInformation.medical_schools}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-6">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">
                                                                                                License No. </label>
                                                                                            <input type="text" class="form-control"
                                                                                                id="input7" placeholder="Enter License No. "
                                                                                                name="medical_registration_no"
                                                                                                value={this.state.PersonalInformation.medical_registration_no}
                                                                                                onChange={(e) => {
                                                                                                    var re = /^[a-z0-9]+$/i;
                                                                                                    if (re.test(e.target.value)) {
                                                                                                        let { PersonalInformation } = this.state;
                                                                                                        PersonalInformation.medical_registration_no = e.target.value;
                                                                                                        this.setState({ PersonalInformation });
                                                                                                    }
                                                                                                    if (e.target.value.length == '0') {
                                                                                                        let { PersonalInformation } = this.state;
                                                                                                        PersonalInformation.medical_registration_no = '';
                                                                                                        this.setState({ PersonalInformation });
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-6">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">
                                                                                                Speciality <span class="mandatory">*</span></label>
                                                                                            <Input type="select" id="roleSelect" className="form-control"
                                                                                                style={{ height: '50px' }}
                                                                                                name="specialty_id"
                                                                                                value={this.state.PersonalInformation.specialty_id}
                                                                                                onChange={async (e) => {
                                                                                                    let { PersonalInformation } = this.state;
                                                                                                    PersonalInformation.specialty_id = e.target.value;
                                                                                                    PersonalInformation.procedure_type_id = '';
                                                                                                    PersonalInformation.sub_specialty_id = '';
                                                                                                    await this.setState({ PersonalInformation });

                                                                                                    await this.setState({
                                                                                                        ProcedureType: [],
                                                                                                        SubSpecialityType: [],
                                                                                                        showProcedureSubSpecialityDropdown: false,
                                                                                                    })
                                                                                                    { this.getProcedureList() }
                                                                                                    { this.getSubSpecialityList() }
                                                                                                    console.log('ProcedureType', this.state.ProcedureType)
                                                                                                    console.log('PersonalInformation.procedure_type_id', this.state.PersonalInformation)
                                                                                                    { this.ProcedureDropdown() }
                                                                                                }}
                                                                                            >
                                                                                                <option value="" >--Select Speciality--</option>
                                                                                                {OptionsSpeciality}
                                                                                            </Input>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-6">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            {/* Sub Speciality Dropdown starts here */}
                                                                                            {
                                                                                                // ( !this.state.PersonalInformation.specialty_id ) ? (
                                                                                                (this.state.showProcedureSubSpecialityDropdown == false) ? (
                                                                                                    <div></div>
                                                                                                ) : (
                                                                                                    <div>
                                                                                                        <label for="exampleFormControlTextarea1" >
                                                                                                            Sub Speciality <span class="mandatory">*</span></label>
                                                                                                        <Multiselect
                                                                                                            data={this.state.SubSpecialitydata}
                                                                                                            placeholder="--Select Sub Speciality--"
                                                                                                            valueField='sub_speciality_type'
                                                                                                            textField='sub_speciality_type'
                                                                                                            defaultValue={this.state.PersonalInformation.sub_specialty_id}
                                                                                                            onSelect={(valueField) => console.log(valueField)}
                                                                                                            onChange={(event) => {
                                                                                                                console.log(event);
                                                                                                                var joined = [];
                                                                                                                console.log("length : " + event.length);
                                                                                                                if (event.length === 0) {
                                                                                                                    this.setState({ SubSpecialityType: [] });
                                                                                                                }
                                                                                                                event.map(async (data) => {
                                                                                                                    console.log("data in eve map : " + JSON.stringify(data));
                                                                                                                    joined = joined.concat(data.sub_speciality_type);
                                                                                                                    console.log("data join : " + joined);
                                                                                                                    await this.setState({
                                                                                                                        SubSpecialityType: joined
                                                                                                                    });
                                                                                                                    console.log("check this", this.state.SubSpecialityType)

                                                                                                                    var output = this.state.SubSpecialityType.map(function (item) {
                                                                                                                        return item;
                                                                                                                    });

                                                                                                                    let { PersonalInformation } = this.state;
                                                                                                                    PersonalInformation.sub_specialty_id = output.join(",");
                                                                                                                    this.setState({ PersonalInformation });

                                                                                                                    console.log("check this", this.state.PersonalInformation.sub_specialty_id)
                                                                                                                    console.log(output.join(","))
                                                                                                                })
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-6">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            {/* Procedure Dropdown starts here */}
                                                                                            {
                                                                                                // ( !this.state.PersonalInformation.specialty_id ) ? (
                                                                                                (this.state.showProcedureSubSpecialityDropdown == false) ? (
                                                                                                    <div></div>
                                                                                                ) : (
                                                                                                    <div>
                                                                                                        <label for="exampleFormControlTextarea1"
                                                                                                        >
                                                                                                            Procedure Type <span class="mandatory">*</span></label>
                                                                                                        <Multiselect
                                                                                                            data={this.state.ProcedureData}
                                                                                                            placeholder="--Select Procedure Type--"
                                                                                                            valueField='procedure_type'
                                                                                                            textField='procedure_type'
                                                                                                            defaultValue={this.state.PersonalInformation.procedure_type_id}
                                                                                                            onSelect={(valueField) => console.log(valueField)}

                                                                                                            onChange={(event) => {
                                                                                                                console.log(event);
                                                                                                                var joined = [];
                                                                                                                console.log("length : " + event.length);
                                                                                                                if (event.length === 0) {
                                                                                                                    this.setState({ ProcedureType: [] });
                                                                                                                }
                                                                                                                event.map(async (data) => {
                                                                                                                    console.log("data in eve map : " + JSON.stringify(data));
                                                                                                                    joined = joined.concat(data.procedure_type);
                                                                                                                    console.log("data join : " + joined);
                                                                                                                    await this.setState({
                                                                                                                        ProcedureType: joined
                                                                                                                    });
                                                                                                                    console.log("check this ", this.state.ProcedureType)

                                                                                                                    var output = this.state.ProcedureType.map(function (item) {
                                                                                                                        return item;
                                                                                                                    });

                                                                                                                    let { PersonalInformation } = this.state;
                                                                                                                    PersonalInformation.procedure_type_id = output.join(",");
                                                                                                                    this.setState({ PersonalInformation, });

                                                                                                                    console.log("check this", this.state.PersonalInformation.procedure_type_id)
                                                                                                                    console.log(output.join(","))
                                                                                                                })
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-6">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">
                                                                                                Contact No. <span class="mandatory">*</span></label>
                                                                                            <PhoneInput
                                                                                                inputStyle={{
                                                                                                    width: "100%",
                                                                                                    height: "50px",
                                                                                                    fontSize: "15px",
                                                                                                }}
                                                                                                width="50%"
                                                                                                country={'in'}
                                                                                                value={this.state.PersonalInformation.doctor_contact_no}
                                                                                                onChange={(DoctorContactNo) => {
                                                                                                    let { PersonalInformation } = this.state;
                                                                                                    PersonalInformation.doctor_contact_no = DoctorContactNo;
                                                                                                    this.setState({ PersonalInformation });
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-6">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">
                                                                                                Email ID <span class="mandatory">*</span></label>
                                                                                            <input type="text" class="form-control"
                                                                                                id="input7" placeholder="Enter Email "
                                                                                                name="doctor_email"
                                                                                                value={this.state.PersonalInformation.doctor_email}
                                                                                                onChange={(e) => {
                                                                                                    let { PersonalInformation } = this.state;
                                                                                                    PersonalInformation.doctor_email = e.target.value;
                                                                                                    this.setState({ PersonalInformation });
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-6">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">
                                                                                                Fax </label>
                                                                                            <input type="text" class="form-control"
                                                                                                id="input7" placeholder="Enter Fax"
                                                                                                value={this.state.PersonalInformation.fax}
                                                                                                onChange={(e) => {
                                                                                                    let { PersonalInformation } = this.state;
                                                                                                    PersonalInformation.fax = e.target.value;
                                                                                                    this.setState({ PersonalInformation });
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-6">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">
                                                                                                LinkedIn Profile </label>
                                                                                            <input type="text" class="form-control"
                                                                                                id="input7" placeholder="Enter LinkedIn Profile "
                                                                                                name="linked_in_profile"
                                                                                                value={this.state.PersonalInformation.linked_in_profile}
                                                                                                onChange={(e) => {
                                                                                                    let { PersonalInformation } = this.state;
                                                                                                    PersonalInformation.linked_in_profile = e.target.value;
                                                                                                    this.setState({ PersonalInformation });
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-6">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">
                                                                                                Twitter Handle </label>
                                                                                            <input type="text" class="form-control"
                                                                                                id="input7" placeholder="Enter Twitter Profile "
                                                                                                name="twitter_handle"
                                                                                                value={this.state.PersonalInformation.twitter_handle}
                                                                                                onChange={(e) => {
                                                                                                    let { PersonalInformation } = this.state;
                                                                                                    PersonalInformation.twitter_handle = e.target.value;
                                                                                                    this.setState({ PersonalInformation });
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-6">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">
                                                                                                Assistant/Secretary Contact No. </label>
                                                                                            <PhoneInput
                                                                                                inputStyle={{
                                                                                                    width: "100%",
                                                                                                    height: "50px",
                                                                                                    fontSize: "15px",
                                                                                                }}
                                                                                                width="50%"
                                                                                                country={'in'}
                                                                                                value={this.state.PersonalInformation.assistance_contact_no}
                                                                                                onChange={(AssistanceContactNo) => {
                                                                                                    let { PersonalInformation } = this.state;
                                                                                                    PersonalInformation.assistance_contact_no = AssistanceContactNo;
                                                                                                    this.setState({ PersonalInformation });
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-6">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">
                                                                                                Assistant Email ID </label>
                                                                                            <input type="text" class="form-control"
                                                                                                id="input7" placeholder="Enter Email "
                                                                                                name="assistance_email"
                                                                                                value={this.state.PersonalInformation.assistance_email}
                                                                                                onChange={(e) => {
                                                                                                    let { PersonalInformation } = this.state;
                                                                                                    PersonalInformation.assistance_email = e.target.value;
                                                                                                    this.setState({ PersonalInformation });
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-6">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">
                                                                                                Website </label>
                                                                                            <input type="text" class="form-control"
                                                                                                id="input7" placeholder="Enter Website "
                                                                                                name="website"
                                                                                                value={this.state.PersonalInformation.website}
                                                                                                onChange={(e) => {
                                                                                                    let { PersonalInformation } = this.state;
                                                                                                    PersonalInformation.website = e.target.value;
                                                                                                    this.setState({ PersonalInformation });
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-6">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">
                                                                                                Country <span class="mandatory">*</span></label>
                                                                                            <Input type="select" id="roleSelect" className="form-control"
                                                                                                style={{ height: '50px' }}
                                                                                                name="country_id"
                                                                                                value={this.state.PersonalInformation.country_id}
                                                                                                onChange={async (e) => {
                                                                                                    let { PersonalInformation } = this.state;
                                                                                                    PersonalInformation.country_id = e.target.value;
                                                                                                    PersonalInformation.NPI_no = '';
                                                                                                    await this.setState({ PersonalInformation });

                                                                                                    { this.getStateList() }
                                                                                                    // {this.getCityList()}
                                                                                                }}
                                                                                            >
                                                                                                <option value="" >--Select Country--</option>
                                                                                                {OptionsCountry}
                                                                                            </Input>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-6">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1" >
                                                                                                State <span class="mandatory">*</span></label>
                                                                                            <Input type="select" id="roleSelect" className="form-control"
                                                                                                style={{ height: '50px' }}
                                                                                                value={this.state.PersonalInformation.state_id}
                                                                                                onChange={async (e) => {
                                                                                                    let { PersonalInformation } = this.state;
                                                                                                    PersonalInformation.state_id = e.target.value;
                                                                                                    await this.setState({ PersonalInformation });
                                                                                                }}
                                                                                            >
                                                                                                <option value="" >--Select State--</option>
                                                                                                {OptionsState}
                                                                                            </Input>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-6">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">
                                                                                                Primary Work Address <span class="mandatory">*</span></label>
                                                                                            <input type="text" class="form-control"
                                                                                                id="input7" placeholder="Enter First Address "
                                                                                                name="address_1"
                                                                                                value={this.state.PersonalInformation.address_1}
                                                                                                onChange={(e) => {
                                                                                                    let { PersonalInformation } = this.state;
                                                                                                    PersonalInformation.address_1 = e.target.value;
                                                                                                    this.setState({ PersonalInformation });
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-6">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">
                                                                                                Primary Work Address 2 </label>
                                                                                            <input type="text" class="form-control"
                                                                                                id="input7" placeholder="Enter Second Address "
                                                                                                name="address_2"
                                                                                                value={this.state.PersonalInformation.address_2}
                                                                                                onChange={(e) => {
                                                                                                    let { PersonalInformation } = this.state;
                                                                                                    PersonalInformation.address_2 = e.target.value;
                                                                                                    this.setState({ PersonalInformation });
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-6">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">
                                                                                                Zip Code <span class="mandatory">*</span></label>
                                                                                            <input type="text" class="form-control"
                                                                                                id="input7" placeholder="Enter Zip Code "
                                                                                                name="zip_code"
                                                                                                value={this.state.PersonalInformation.zip_code}
                                                                                                onChange={(e) => {
                                                                                                    let { PersonalInformation } = this.state;
                                                                                                    PersonalInformation.zip_code = e.target.value;
                                                                                                    this.setState({ PersonalInformation });
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-md-6">
                                                                                    <div class="doc-info">
                                                                                        <div class="form-group">
                                                                                            <label for="exampleFormControlTextarea1">
                                                                                                Biography  <span class="mandatory">*</span></label>
                                                                                            <input type="text" class="form-control"
                                                                                                id="input7" placeholder="Enter Biography "
                                                                                                value={this.state.Biography.biography}
                                                                                                onChange={(e) => {
                                                                                                    let { Biography } = this.state;
                                                                                                    Biography.biography = e.target.value;
                                                                                                    this.setState({ Biography });
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                            </div>

                                                                            <hr />
                                                                            {/* <a style={{color:'#007bff'}} className="float-right"
                                                            onClick={this.toggleUnsubscibeModal.bind(this)}
                                                        >
                                                            Unsubscribe
                                                        </a> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <form>
                                            <li></li>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Reset password modal stars here --> */}
                    {/* create crentials modal */}
                    <Modal isOpen={this.state.CraeteCrendialsModal} toggle={this.toggleCraeteCrendialsModal.bind(this)} centered>
                        <ModalHeader toggle={this.toggleCraeteCrendialsModal.bind(this)}
                            cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
                            style={{ borderBottom: '0px' }}>
                            <h3 style={{ justifyContent: 'center', marginTop: '30px' }} id="login-tit">Change Password</h3>
                        </ModalHeader>

                        <ModalBody>

                            {/* <FormGroup>
            <div className="center">
              <Input id="username" placeholder="Team Id" 
                    style={{width: '80%', height: '50px',}}
                    value={this.state.kolIdForCredential} 
              />
            </div>
            <div className="centerErrLogin">{this.state.kolProfileUsernameErr}</div> 
            </FormGroup> */}

                            <FormGroup>
                                <div className="center" >
                                    <Input id="oldPassword" placeholder="Enter Old Password"
                                        style={{ width: '80%', height: '50px', }}
                                        type="text"
                                        value={this.state.CreateCredentials.oldPassword} onChange={(e) => {
                                            let { CreateCredentials } = this.state;
                                            CreateCredentials.oldPassword = e.target.value;
                                            this.setState({ CreateCredentials });
                                        }}
                                    />
                                </div>
                            </FormGroup>
                            <div className="centerErrKolChangePass">{this.state.oldPasswordErr}</div>

                            <FormGroup>
                                <div className="center" style={{ marginTop: '7%' }}>
                                    <Input id="newPassword" placeholder="Enter New Password"
                                        style={{ width: '80%', height: '50px', }}
                                        type="text"
                                        value={this.state.CreateCredentials.newPassword} onChange={(e) => {
                                            let { CreateCredentials } = this.state;
                                            CreateCredentials.newPassword = e.target.value;
                                            this.setState({ CreateCredentials });
                                        }}
                                    />
                                </div>
                            </FormGroup>
                            <div className="centerErrKolChangePass">{this.state.newPasswordErr}</div>

                            <FormGroup>
                                <div className="center" style={{ marginTop: '7%' }}>
                                    <Input id="confirmPassword" placeholder="Enter Confirm Password"
                                        style={{ width: '80%', height: '50px', }}
                                        type="text"
                                        value={this.state.CreateCredentials.confirmPassword} onChange={(e) => {
                                            let { CreateCredentials } = this.state;
                                            CreateCredentials.confirmPassword = e.target.value;
                                            this.setState({ CreateCredentials });
                                        }}
                                    />
                                </div>
                            </FormGroup>
                            <div className="centerErrKolChangePass">{this.state.confirmPasswordErr}</div>

                        </ModalBody>
                        <ModalFooter style={{ borderTop: '0px' }}>
                            <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                                onClick={this.toggleCraeteCrendialsModal.bind(this)}>Cancel</button>
                            <button type="submit" class="cetas-button" style={{ marginRight: '20%', }}
                                onClick={this.CreateCrendialsFun.bind(this)}>
                                Create
                            </button>
                        </ModalFooter>
                    </Modal>
                    {/* <!-- Reset password ends here --> */}

                    {/* unsubscribe */}
                    <Modal isOpen={this.state.UnsubscibeModal} toggle={this.toggleUnsubscibeModal.bind(this)} centered>
                        <ModalHeader toggle={this.toggleUnsubscibeModal.bind(this)}
                            cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
                            style={{ borderBottom: '0px' }}>
                            <h3 style={{ justifyContent: 'center', marginTop: '10px' }} id="login-tit">Unsubscribe</h3>
                        </ModalHeader>
                        <ModalBody style={{ padding: '0px 45px 0px 45px' }} >
                            <p style={{ textAlign: 'center' }}>
                                We find it truly unfortunate to part with you. <br></br>We hope to be associated with you again.<br></br>
                                Please provide us your valuable suggestions to improvise.
                            </p>
                        </ModalBody>
                        <ModalFooter>
                            <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                                onClick={this.toggleUnsubscibeModal.bind(this)}>Cancel</button>
                            <button class="cetas-button" data-toggle="modal" data-target="#edit-item"
                                onClick={this.UnsubscibeFunc}>Unsubscribe</button>
                        </ModalFooter>
                    </Modal>

                </div>
            </body>
        );
    }
}

export default KolProfileDashboard;