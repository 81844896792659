import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'

//datatable npm
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

// showing progress bar npm 
import { PropagateLoader } from 'react-spinners';
import LoadingBar from "react-top-loading-bar";

import axios from 'axios';
import Swal from 'sweetalert2';
import { Multiselect } from 'react-widgets';
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Popover, PopoverBody } from 'reactstrap';

// session storage item:
// 'client_id': is the login id of client
// 'administrator_name' : is the name of the client
// 'email_id' : is the email id of client
// project_id : is selected project from 1st page

const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total ml-2">
    Showing {from} to {to} of {size} Results
  </span>
);

class AdministratorViewProject extends Component {
  constructor(props) {
    super(props)

    this.state = {
      searchInCetas: false,
      redirect: false,
      administrator_id: '',
      administrator_name: '',
      administrator_email: '',

      redirectAdministratorViewKolList: false,
      redirectAdministratorSearchInCetasDb: false,
      RedirectLogin: false,

      // state for progress bar
      ShowBar: false,
      progressBar: false,
      isSearch: false,
      isSearch1: false,
      Profile: [],
      ProfileBySession: [],

      // to get data from dropdown
      SpecialityData: [],
      ProcedureData: [],
      SubSpecialityData: [],
      CountryListData: [],

      // Column state for Bootstrap Table 
      columns: [
        {
          dataField: 'project_id',
          text: 'Project ID',
          sort: true,
        },
        {
          dataField: 'project_id',
          text: 'Project ID',
          sort: true,
          hidden: true
        },
        {
          dataField: 'p_name',
          text: 'Project Name',
          formatter: this.linkToDetails,
          // sort: true,
          filter: textFilter(),
          filterRenderer: (onFilter, column) => {
            this.onProjectNameFilter = onFilter;
            return null;
          },
        },
        {
          dataField: 'speciality_type',
          text: 'Speciality Type',
        },
        {
          dataField: "company_nm",
          text: "Company Name",
          // align: 'center',
          // headerAlign: 'center'
        },
        {
          dataField: "project_status",
          text: "Project Status",
        },
        // {
        //   dataField: "country",
        //   text: "Country",
        // },
        // {
        //   dataField: "noofkols",
        //   text: "No.of KOL",
        // },
      ],

    }    // End of states
    // Functions will be Bind here
    this.togglesearch = this.togglesearch.bind(this);
    this.togglesearch1 = this.togglesearch1.bind(this);
  }   // End of constructor

  logout = () => {
    console.log("in logout")
    this.setState({ redirect: true })
    sessionStorage.removeItem('administrator_id')
    sessionStorage.removeItem('administrator_name')
    sessionStorage.removeItem('administrator_id')
  }

  togglesearch() {
    this.setState({
      isSearch: !this.state.isSearch,
      Speciality: '',
      SubSpecialityType: '',
      ProcedureType: '',
      Market: [],
      searchPopover: false,
      isSearch1: false,
    })
  }

  togglesearch1() {
    this.setState({
      isSearch1: !this.state.isSearch1,
      Speciality: '',
      SubSpecialityType: '',
      ProcedureType: '',
      Market: [],
      searchPopover: false,
      isSearch: false,
    })
  }

  componentDidMount = async () => {
    console.log("In Administrator view projects")
    let getSession_Administrator_Email = await sessionStorage.getItem('administrator_email')
    if (getSession_Administrator_Email !== null) {
      await this.setState({
        administrator_email: getSession_Administrator_Email,
      })
    }

    let getSession_Administrator_ID = await sessionStorage.getItem('administrator_id')
    if (getSession_Administrator_ID !== null) {
      await this.setState({
        administrator_id: getSession_Administrator_ID,
      })
    }
    else
    {
      this.setState({ redirect: true });
    
    }

    let getSession_Administrator_Name = await sessionStorage.getItem('administrator_name')
    if (getSession_Administrator_Name !== null) {
      await this.setState({
        administrator_name: getSession_Administrator_Name,
      })
    }
    else
    {
      this.setState({ redirect: true });    
    }

    console.log("getSession_Administrator_Email ====", getSession_Administrator_Email)
    console.log("getSession_Administrator_ID ====", getSession_Administrator_ID)
    console.log("getSession_Administrator_Name ====", getSession_Administrator_Name)

    this.setState({
      ShowBar: true,
      progressBar: true,
    })

    const options = {
      method: 'POST',
    }

    //  Speciality type data
    fetch(global.websiteUrl+'/service/Speciality/get_SpecialityList', options)
      .then((Response) => Response.json())
      .then((findresponse) => {
        console.log(findresponse)
        var testDataSpeciality = findresponse.data;
        console.log("testDataSpeciality", JSON.stringify(testDataSpeciality));
        this.setState({
          SpecialityData: findresponse.data,
          ShowBar: false,
          progressBar: false,
        })
        console.log("Length of SpecialityData ", this.state.SpecialityData.length);

      })

    // Country list(market) 
    fetch(global.websiteUrl+'/service/Location/get_CountryList', options)
      .then((Response) => Response.json())
      .then((findresponse) => {
        console.log(findresponse)
        var testDataCountryList = findresponse.Country_Details;
        console.log("testDataCountryList", JSON.stringify(testDataCountryList));
        this.setState({
          CountryListData: findresponse.Country_Details,
          ShowBar: false,
          progressBar: false,
        })
        // console.log("Client_Unique_id", this.state.Client_Unique_id);
      })

    const url = global.websiteUrl+'/service/Client/get_ClientProjectList';
    let formData = new FormData();
    formData.append('client_id', getSession_Administrator_ID);
    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log('response of API', response);
        this.Profile = response.data.Client_Project_Details;
        // console.log(this.Profile.length)
        console.log("Profile", JSON.stringify(response.data.Client_Project_Details));
        // console.log("Profile iddddddddddddddddddddd", this.Profile);
        if (response.data.status == true) {
          console.log('true');
          this.setState({
            Profile: response.data.Client_Project_Details,
            ShowBar: false,
            progressBar: false,
          })

          if (response.data.Client_Project_Details) {
            sessionStorage.setItem('ProjectInfo', JSON.stringify(response.data.Client_Project_Details))
          }
          else {
            sessionStorage.setItem('ProjectInfo', [])
          }
          let getSessionDataOfProjectProfile = [];
          let getSessionDataOfProjectProfileCheck = sessionStorage.getItem('ProjectInfo')
          if (getSessionDataOfProjectProfileCheck == null) {
            getSessionDataOfProjectProfile = [];
            this.setState({
              ProfileBySession: getSessionDataOfProjectProfile
            })
          }
          else {
            let getSessionDataOfProjectProfileParse = sessionStorage.getItem('ProjectInfo');
            getSessionDataOfProjectProfile = JSON.parse(getSessionDataOfProjectProfileParse);
            this.setState({
              ProfileBySession: getSessionDataOfProjectProfile
            })
          }
          console.log("getSessionDataOfProjectProfile", this.state.ProfileBySession);
        }
        else if (response.data.status == false) {
          console.log('false');
          // Swal.fire({
          //   icon: 'error',
          //   text: 'No List Present',
          //   // text: 'Wrong Username or Password!',
          //   confirmButtonColor: '#d33',
          //   confirmButtonText: 'OK'
          // })

          this.setState({
            Profile: [],
            ShowBar: false,
            progressBar: false,
          })
        }
        this.setState({
          ShowBar: false,
          progressBar: false,
        });
      })
      .catch(error => {
        console.log(error);
        // alert("Error Occurs");
        this.setState({
          ShowBar: false,
          progressBar: false,
        });
      });
  }

  getSubSpecialityList() {
    console.log("in Procedure list");
    console.log("speciality_type_id", this.state.Speciality);

    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
    let formData = new FormData();
    formData.append('speciality_type_id', this.state.Speciality);
    formData.append('search_type', "Sub Speciality");

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var SubSpecialityData = response.data;
        console.log("SubSpecialityData", JSON.stringify(SubSpecialityData));
        console.log(SubSpecialityData.status)
        // if(this.state.SubSpecialityData.status == false){
        //     this.setState({
        //         SubSpecialityData : [],
        //     })
        // } else {
        //     this.setState({
        //         SubSpecialityData : response.data.data,
        //     }) 
        // } 
        if (response.data.status == true) {
          this.setState({
            SubSpecialityData: response.data.data,
          })
        }
        else if (response.data.status == false) {
          this.setState({
            SubSpecialityData: [],
          })
        }
        console.log("SubSpecialityData====", this.state.SubSpecialityData)
        console.log("Length of SubSpecialityData ", this.state.SubSpecialityData.length);
      })
      .catch(error => {
        console.log(error);
      });
  }

  getProcedureList() {
    console.log("in Procedure list");
    console.log("speciality_type_id", this.state.Speciality);

    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
    let formData = new FormData();
    formData.append('speciality_type_id', this.state.Speciality);
    formData.append('search_type', "Procedure");

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var proceduredata = response.data;
        console.log("proceduredata", JSON.stringify(proceduredata));
        if (response.data.status == true) {
          this.setState({
            ShowBar: false,
            progressBar: false,
            ProcedureData: response.data.data,
          })
        }
        else if (response.data.status == false) {
          this.setState({
            ShowBar: false,
            progressBar: false,
            ProcedureData: [],
          })
        }
        // console.log("ProcedureData",this.state.ProcedureData)
      })
      .catch(error => {
        console.log(error);
      });
  }

  search = async () => {
    this.setState({
      ShowBar: true,
      progressBar: true,
    })
    console.log('in Search')
    // console.log("Name for search", this.state.NameForSearch)
    console.log("Speciality ID", this.state.Speciality)
    console.log("Sub Speciality ID", this.state.SubSpecialityType)
    console.log("Procedure ID", this.state.ProcedureType)
    console.log("Country selected ID", this.state.Market)

    try {

      const url = global.websiteUrl+'/service/KOL/get_Category_Wise_KOL_List';
      let formData = new FormData();
      // formData.append('kol_name', this.state.NameForSearch);
      formData.append('speciality', this.state.Speciality);
      formData.append('subspeciality', this.state.SubSpecialityType);
      formData.append('procedure', this.state.ProcedureType);
      formData.append('country', this.state.Market);
      console.log("formData in administrator",formData);
      const config = {
        // headers: { 'content-type': 'multipart/form-data' }
      }

      axios.post(url, formData, config)
        .then(response => {
          console.log(response.data);
          console.log(response.data.message);
          if (response.data.status == false) {
            Swal.fire({
              icon: 'error',
              text: 'No List Present of Selected Category',
              // text: 'Wrong Username or Password!',
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })

            this.setState({
              Profile1: [],
              ShowBar: false,
              progressBar: false,
            })
          }
          else if (response.data.status == true) {
            console.log(response.data.KOL_Personal_Info);
            console.log(response.data.message);
            this.Profile1 = response.data.KOL_Personal_Info;
            console.log(this.Profile1.length)
            console.log(JSON.stringify(this.Profile1));
            this.setState({
              Profile1: response.data.KOL_Personal_Info,
              ShowBar: false,
              progressBar: false,
            })

            // sessionStorage.setItem('kolInfo', data) 
            var kol_ids_for_filter=[];
            if (response.data.KOL_Personal_Info) {
              response.data.KOL_Personal_Info.map((item, index) =>{
                console.log("item of map kol pers info", item.kol_unique_id)
                kol_ids_for_filter = kol_ids_for_filter.concat(item.kol_unique_id);
                console.log("kol_ids_for_filter on client search",kol_ids_for_filter)
              })
              sessionStorage.setItem('AllKolIdsOfSearchResults', JSON.stringify(kol_ids_for_filter));
              sessionStorage.setItem('kolInfo', JSON.stringify(response.data.KOL_Personal_Info));
              sessionStorage.setItem('SearchInCetas', 'true');
              this.setState({
                redirectAdministratorSearchInCetasDb: true,
              });
            }
            else {
              sessionStorage.setItem('kolInfo', [])
            }

            // let getSessionDataOfProfileInfo = [];
            // let getSessionDataOfProfileInfoCheck = sessionStorage.getItem('kolInfo')
            // if (getSessionDataOfProfileInfoCheck == null) {
            //   getSessionDataOfProfileInfo = [];
            //   this.setState({
            //     ProfileBySession: getSessionDataOfProfileInfo
            //   })
            // }
            // else {
            //   let getSessionDataOfProfileInfoParse = sessionStorage.getItem('kolInfo');
            //   getSessionDataOfProfileInfo = JSON.parse(getSessionDataOfProfileInfoParse);
            //   this.setState({
            //     ProfileBySession: getSessionDataOfProfileInfo
            //   })
            // }
            // console.log("getSessionDataOfProfileInfo", getSessionDataOfProfileInfo)
          }
        })
    }
    catch (e) {
      console.log(e);
      this.setState({
        ShowBar: false,
        progressBar: false,
      })
    }
    this.setState({
      isSearch: false,
    })
  }

  search1 = async () => {
    this.setState({
      ShowBar: true,
      progressBar: true,
    })

    try {
      const url = global.websiteUrl+'/service/client/client_profile_search';
      let formData = new FormData();
      formData.append('clientid', this.state.administrator_id);
      formData.append('doctor_name', this.state.NameForSearch); //doctor_name kol_name
      formData.append('specility', this.state.Speciality); //specility speciality
      formData.append('subspeciality', this.state.SubSpecialityType); //subspeciality subspeciality
      formData.append('procedure', this.state.ProcedureType); //procedure
      formData.append('country', this.state.Market); //country 

      const config = {
        // headers: { 'content-type': 'multipart/form-data' }
      }

      console.log('client id', this.state.administrator_id);
      console.log("Name for search", this.state.NameForSearch);
      console.log("Speciality ID", this.state.Speciality);
      console.log("Sub Speciality ID", this.state.SubSpecialityType);
      console.log("Procedure ID", this.state.ProcedureType);
      console.log("Country selected ID", this.state.Market);

      axios.post(url, formData, config)
        .then(response => {
          console.log('im innnnnnnnnnnnnnnnnnnn');
          console.log(response.data);
          console.log(response.data.message);
          console.log(response.data.status);
          console.log(response.data.statusCode);
          if (response.data.status == 'false') {
            Swal.fire({
              icon: 'error',
              text: response.data.message,
              // text: 'Wrong Username or Password!',
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })

            this.setState({
              Profile: [],
              ShowBar: false,
              progressBar: false,
            })
          }
          else if (response.data.status == 'true') {
            console.log('in true status');
            console.log(response.data.message);
            console.log(response.data.KOL_Personal_Info);
            console.log(response.data.message);
            this.Profile = response.data.KOL_Personal_Info;
            console.log(this.Profile.length)
            // console.log(JSON.stringify(Profile));
            this.setState({
              Profile: response.data.KOL_Personal_Info,
              ShowBar: false,
              progressBar: false,
            })
            // sessionStorage.setItem('kolInfo', data) 
            if (response.data.KOL_Personal_Info) {
              sessionStorage.setItem('kolInfo', JSON.stringify(response.data.KOL_Personal_Info));
              sessionStorage.setItem('SearchInCetas', 'false');
              this.setState({
                redirectAdministratorSearchInCetasDb: true,
              });
            }
            else {
              sessionStorage.setItem('kolInfo', [])
            }
            // let getSessionDataOfProfileInfo = [];
            // let getSessionDataOfProfileInfoCheck = sessionStorage.getItem('kolInfo')
            // if (getSessionDataOfProfileInfoCheck == null) {
            //   getSessionDataOfProfileInfo = [];
            //   this.setState({
            //     ProfileBySession: getSessionDataOfProfileInfo
            //   })
            // }
            // else {
            //   let getSessionDataOfProfileInfoParse = sessionStorage.getItem('kolInfo');
            //   getSessionDataOfProfileInfo = JSON.parse(getSessionDataOfProfileInfoParse);
            //   this.setState({
            //     ProfileBySession: getSessionDataOfProfileInfo
            //   })
            // }
            // console.log("getSessionDataOfProfileInfo", getSessionDataOfProfileInfo)
          }
        })
    }
    catch (e) {
      console.log(e);
    }
    this.setState({
      isSearch1: false,
    })
  }

  BackToDashboard() {
    this.setState({ RedirectFlag: true })
  }

  profileInfoNav(project_id) {
    sessionStorage.setItem('project_id', project_id);
    console.log('Navigation of clientKolList', project_id);
    this.setState({
      redirectAdministratorViewKolList: true,
    })
  }

  //Funtion to apply links to project names
  linkToDetails = (cell, row) => {
    var project_id = row.project_id;
    return (
      <div>
        <a style={{
          fontFamily: 'arial, sans-serif', color: '#069',
          textDecoration: 'underline', cursor: 'pointer'
        }}
          onClick={this.profileInfoNav.bind(this, project_id)}> {row.p_name} </a>
      </div>
    )
  }

  //Project Name Filter Function
  onProjectNameChange = (event) => {
    const { value } = event.target;
    this.onProjectNameFilter(value);
  };

  togglesearchPopover = async () => {
    console.log('clicked on propover')
    this.setState({
      searchPopover: !this.state.searchPopover
    })
  }

  render() {
    if (this.state.redirectAdministratorViewKolList) {
      return (<Redirect to={{
        pathname: '/AdministratorViewKolList',
      }} />)
    }
    if (this.state.redirect) {
      return (<Redirect to={'/'} />)
    }
    if (this.state.redirectAdministratorSearchInCetasDb) {
      return (<Redirect to={{
        pathname: '/AdministratorSearchInCetasDb',
      }} />)
    }

    // Speciality Type options 
    let speciality_type = this.state.SpecialityData;
    let OptionsSpeciality = speciality_type.map((speciality_type) =>
      <option key={speciality_type.id} value={speciality_type.id}>
        {speciality_type.speciality_type}</option>
    );

    // Procedure List
    let procedure_list = this.state.ProcedureData;
    let OptionProcedure = procedure_list.map((procedure_list) =>
      <option key={procedure_list.id} value={procedure_list.procedure_type}>
        {procedure_list.procedure_type}</option>
    );

    // Sub Speciality List
    let subSpeciality_list = this.state.SubSpecialityData;
    let OptionSubSpeciality = subSpeciality_list.map((subSpeciality_list) =>
      <option key={subSpeciality_list.id} value={subSpeciality_list.sub_speciality_type}>
        {subSpeciality_list.sub_speciality_type}</option>
    );

    // For rendering pageination buttons
    const pageButtonRenderer = ({
      page,
      active,
      disable,
      title,
      onPageChange
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        onPageChange(page);
      };
      return (
        <li className="page-item" onClick={handleClick} ><a className="page-link" href="#">{page}</a></li>
      );
    };

    // options for passing values to bootstrap table 2
    const options = {
      pageButtonRenderer,
      paginationSize: 5,
      pageStartIndex: 1,
      // alwaysShowAllBtns: true, // Always show next and previous button
      // withFirstAndLast: false, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      // firstPageText: 'First',
      prePageText: 'Previous',
      nextPageText: 'Next',
      // lastPageText: 'Last',
      // nextPageTitle: 'First page',
      // prePageTitle: 'Pre page',
      // firstPageTitle: 'Next page',
      // lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '50', value: 50
      }, {
        text: '100', value: 100
      }, {
        text: '200', value: 200
      }, {
        text: 'All', value: this.state.Profile.length
      }]// A numeric array is also available. the purpose of above example is custom the text

    };

    return (
      <div>
        {this.state.ShowBar ? <Modal isOpen={true} centered>
          <PropagateLoader color={'#A90007'} size='30px' loading='true' />
        </Modal> : null}
        {this.state.progressBar ? <div style={{ position: 'fixed', top: '0' }}>
          <LoadingBar
            progress='100'
            height={3} color="#A90007" transitionTime={4000}
            loaderSpeed={3000} />
        </div> : null}
        <div>
          <Modal id="search" isOpen={this.state.isSearch} toggle={this.togglesearch.bind()} style={{ padding: '15px' }}>
            <ModalHeader toggle={this.togglesearch.bind(this)} style={{ border: 'none' }} cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}>
              <h3 style={{ marginTop: '40px', marginLeft: '5%' }}>Search KOL</h3>
            </ModalHeader>
            <ModalBody style={{ padding: '20px 80px 10px 80px' }}>
              {/* <FormGroup>
                <Label for="exampleFormControlTextarea1">Name</Label>
                <input type="text" className="form-control" id="exampleInputPassword1"
                  placeholder="Enter Name"
                  value={this.state.NameForSearch}
                  onChange={(e) => {
                    let { NameForSearch } = this.state;
                    NameForSearch = e.target.value;
                    this.setState({ NameForSearch });
                  }}
                />
              </FormGroup> */}

              <FormGroup>
                <Label for="exampleFormControlTextarea1">Speciality</Label>
                <Input type="select" id="roleSelect" className="form-control"
                  style={{ height: '50px' }}
                  value={this.state.Speciality}
                  onChange={async (e) => {
                    let { Speciality } = this.state;
                    Speciality = e.target.value;
                    await this.setState({ Speciality });
                    { this.getSubSpecialityList() }
                    { this.getProcedureList() }
                  }}>
                  <option value="0" >--Select Speciality--</option>
                  {OptionsSpeciality}
                </Input>
              </FormGroup>
              <FormGroup>
                {
                  (!this.state.Speciality) ? (
                    // <label for="exampleFormControlTextarea1">Procedure Type</label>
                    <div></div>
                  ) : (
                    <div>
                      <label for="exampleFormControlTextarea1">Sub Speciality Type</label>
                      <Input type="select" id="roleSelect" className="form-control"
                        style={{ height: '50px' }}
                        value={this.state.SubSpecialityType}
                        onChange={(e) => {
                          let { SubSpecialityType } = this.state;
                          SubSpecialityType = e.target.value;
                          this.setState({ SubSpecialityType });
                        }}>
                        <option value="0" >--Select Sub Speciality Type--</option>
                        {OptionSubSpeciality}
                      </Input>
                    </div>
                  )
                }
              </FormGroup>
              <FormGroup>
                {
                  (!this.state.Speciality) ? (
                    // <label for="exampleFormControlTextarea1">Procedure Type</label>
                    <div></div>
                  ) : (
                    <div>
                      <label for="exampleFormControlTextarea1">Procedure Type</label>
                      <Input type="select" id="roleSelect" className="form-control"
                        style={{ height: '50px' }}
                        value={this.state.ProcedureType}
                        onChange={(e) => {
                          let { ProcedureType } = this.state;
                          ProcedureType = e.target.value;
                          this.setState({ ProcedureType });
                        }}>
                        <option value="0" >--Select Procedure Type--</option>
                        {OptionProcedure}
                      </Input>
                    </div>
                  )
                }
              </FormGroup>
              <FormGroup>
                <div>
                  <label for="exampleFormControlTextarea1">
                    Countries <span className="mandatory">*</span></label>
                  <Multiselect
                    data={this.state.CountryListData}
                    placeholder='--Select Countries--'
                    valueField='id'
                    textField='country_name'
                    onSelect={(valueField) => console.log(valueField)}
                    onChange={(event) => {
                      console.log(event);
                      var joined = [];
                      console.log("length : " + event.length);
                      if (event.length === 0) {
                        this.setState({ Market: [] });
                      }
                      event.map(async (data) => {
                        console.log("data in eve map : " + JSON.stringify(data));
                        joined = joined.concat(data.id);
                        console.log("data join : " + joined);
                        await this.setState({
                          Market: joined
                        });
                        console.log("check this", this.state.Market)
                      })
                    }}
                  />
                </div>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <button color="danger" className="cetas-button cetas-button-small mr-2" onClick={this.search.bind(this)}> Search </button>
              <button className="cetas-secondry-button cetas-button-small" onClick={this.togglesearch.bind(this)}>Cancel</button>
            </ModalFooter>
          </Modal>


          <Modal id="search1" isOpen={this.state.isSearch1} toggle={this.togglesearch1.bind()} style={{ padding: '15px' }}>
            <ModalHeader toggle={this.togglesearch1.bind(this)} style={{ border: 'none' }} cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}>
              <h3 style={{ marginTop: '40px', marginLeft: '5%' }}>Search1 KOL</h3>
            </ModalHeader>
            <ModalBody style={{ padding: '20px 80px 10px 80px' }}>
              <FormGroup>
                <Label for="exampleFormControlTextarea1">Name</Label>
                <input type="text" className="form-control" id="exampleInputPassword1"
                  placeholder="Enter Name"
                  value={this.state.NameForSearch}
                  onChange={(e) => {
                    let { NameForSearch } = this.state;
                    NameForSearch = e.target.value;
                    this.setState({ NameForSearch });
                  }}
                />
              </FormGroup>

              <FormGroup>
                <Label for="exampleFormControlTextarea1">Speciality</Label>
                <Input type="select" id="roleSelect" className="form-control"
                  style={{ height: '50px' }}
                  value={this.state.Speciality}
                  onChange={async (e) => {
                    let { Speciality } = this.state;
                    Speciality = e.target.value;
                    await this.setState({ Speciality });
                    { this.getSubSpecialityList() }
                    { this.getProcedureList() }
                  }}>
                  <option value="0" >--Select Speciality--</option>
                  {OptionsSpeciality}
                </Input>
              </FormGroup>
              <FormGroup>
                {
                  (!this.state.Speciality) ? (
                    // <label for="exampleFormControlTextarea1">Procedure Type</label>
                    <div></div>
                  ) : (
                    <div>
                      <label for="exampleFormControlTextarea1">Sub Speciality Type</label>
                      <Input type="select" id="roleSelect" className="form-control"
                        style={{ height: '50px' }}
                        value={this.state.SubSpecialityType}
                        onChange={(e) => {
                          let { SubSpecialityType } = this.state;
                          SubSpecialityType = e.target.value;
                          this.setState({ SubSpecialityType });
                        }}>
                        <option value="0" >--Select Sub Speciality Type--</option>
                        {OptionSubSpeciality}
                      </Input>
                    </div>
                  )
                }
              </FormGroup>
              <FormGroup>
                {
                  (!this.state.Speciality) ? (
                    // <label for="exampleFormControlTextarea1">Procedure Type</label>
                    <div></div>
                  ) : (
                    <div>
                      <label for="exampleFormControlTextarea1">Procedure Type</label>
                      <Input type="select" id="roleSelect" className="form-control"
                        style={{ height: '50px' }}
                        value={this.state.ProcedureType}
                        onChange={(e) => {
                          let { ProcedureType } = this.state;
                          ProcedureType = e.target.value;
                          this.setState({ ProcedureType });
                        }}>
                        <option value="0" >--Select Procedure Type--</option>
                        {OptionProcedure}
                      </Input>
                    </div>
                  )
                }
              </FormGroup>
              <FormGroup>
                <div>
                  <label for="exampleFormControlTextarea1">
                    Countries <span className="mandatory">*</span></label>
                  <Multiselect
                    data={this.state.CountryListData}
                    placeholder='--Select Countries--'
                    valueField='id'
                    textField='country_name'
                    onSelect={(valueField) => console.log('valueField of country', valueField)}
                    onChange={(event) => {
                      console.log(event);
                      var joined = [];
                      console.log("length : " + event.length);
                      if (event.length === 0) {
                        this.setState({ Market: [] });
                      }
                      event.map(async (data) => {
                        console.log("data in eve map : " + JSON.stringify(data));
                        joined = joined.concat(JSON.parse(data.id)); //sheetal
                        console.log("data join : " + joined);
                        await this.setState({
                          Market: joined,
                        });
                        console.log("check this", this.state.market);
                      })
                    }}
                  />
                </div>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <button color="danger" className="cetas-button cetas-button-small mr-2" onClick={this.search1.bind(this)}> Search </button>
              <button className="cetas-secondry-button cetas-button-small" onClick={this.togglesearch1.bind(this)}>Cancel</button>
            </ModalFooter>
          </Modal>

          <div>
            {/* <ClientHeader ClientName={this.state.administrator_name}/> */}
            <div className="header-nav">
              <div className="container">
                <nav className="navbar navbar-expand-lg">
                  <a style={{ display: "table-cell" }} className="navbar-brand"
                    href="https://cetas-healthcare.com/" target="_blank" rel="noopener">
                    <img
                      src={require('../Assets/Images/cetas-logo.png')}
                    />
                  </a>

                  <button className="navbar-toggler" type="button" data-toggle="collapse"
                    data-target="#navbarNavAltMarkup"
                    aria-controls="navbarNavAltMarkup" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                  </button>

                  <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                    {/* <div className="navbar-nav">
                      <a className="nav-item nav-link" data-toggle="modal" data-target="#search"
                        onClick={this.togglesearch1.bind()}>
                        <img src={require('../Assets/Images/setting.svg')} />
                        &nbsp;Search 1
                      </a>
                    </div>
                    <div className="navbar-nav">
                      <a className="nav-item nav-link" data-toggle="modal" data-target="#search"
                        onClick={this.togglesearch.bind()}>
                        <img src={require('../Assets/Images/setting.svg')} />
                        &nbsp;Search
                      </a>
                    </div> */}
                    <div style={{ marginLeft: '78%' }}>
                      <a className="nav-item nav-link" data-toggle="modal" data-target="#search" id="popoverClickForMyProfile"
                        onClick={this.togglesearchPopover.bind()}>
                        <img src={require('../Assets/Images/setting.svg')} />
                        &nbsp;Search
                      </a>
                      <Popover placement="bottom" trigger="legacy" target="popoverClickForMyProfile"
                        isOpen={this.state.searchPopover}
                        toggle={this.togglesearchPopover.bind(this)}
                      >
                        <PopoverBody>
                          <text style={{ cursor: 'pointer' }} onClick={this.togglesearch.bind(this)} >
                            Search in Cetas Data
                          </text><hr />
                          {/* <text style={{ cursor: 'pointer' }} onClick={this.togglesearch1.bind(this)} >
                            Search in Project
                          </text> */}
                        </PopoverBody>
                      </Popover>
                    </div>
                  </div>
                  <a className="nav-item nav-link username-box" >
                    <img
                      src={require('../Assets/Images/user.svg')}
                    />
                    Welcome, {this.state.administrator_name}
                  </a>


                  <a className="nav-item nav-link username-box  float-right"
                    onClick={this.logout.bind(this)}>
                    <img
                      src={require('../Assets/Images/logoutByVibha.svg')}
                    />
                    Logout
                  </a>

                </nav>
              </div>
            </div>

          </div>

          <div className="container">
            <div className="content-wrapper">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12 emplist-box">
                  <div className="row">
                    <div className="col-md-12">
                      <h3><b>My Projects</b></h3>
                      <div className="employee-actions">
                        <div className="table-infobox">
                          <p className="list-count" style={{ color: 'white' }}>{this.state.Profile.length} Employees Listed</p>
                          <div className="filter-box float-right mb-30" >
                            <div className="input-group" style={{ marginLeft: '55%' }}>
                              <div className="input-group-prepend">
                                <div className="input-group-text"><img src={require("../Assets/Images/search.svg")} /></div>
                              </div>
                              <input type="text" className="form-control"
                                id="inlineFormInputGroupUsername"
                                placeholder="Search by Project Name"
                                onChange={(e) => { this.onProjectNameChange(e) }} />
                            </div>
                          </div>
                        </div>
                      </div>

                      <ToolkitProvider
                        keyField="project_id"
                        data={this.state.Profile}
                        columns={this.state.columns}
                        search={this.customMatchFunc}
                      >
                        {
                          props => (
                            <div>
                              <BootstrapTable
                                keyField='project_id'
                                data={this.state.Profile} columns={this.state.columns}
                                filter={filterFactory()}
                                // selectRow={ selectRow } 
                                pagination={paginationFactory(options)}
                                striped
                                hover
                                condensed
                                tabIndexCell
                              />
                              <hr></hr>
                              {/* <ExportCSVButton  className="cetas-button" { ...props.csvProps }>Export</ExportCSVButton> */}
                            </div>
                          )
                        }
                      </ToolkitProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default AdministratorViewProject;