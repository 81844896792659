import React, { Component, useRef, useState } from "react";
import { Redirect, Link, NavLink } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Button,
  Col,Container, Row, Nav, NavItem,
  Form,
  Popover,
  PopoverHeader,
  PopoverBody,
  Alert,
  FormFeedback,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import LoadingBar from "react-top-loading-bar";
import PhoneInput from "react-phone-input-2";
import { Multiselect } from "react-widgets";
//import Spinner from '../src/Assets/Spinner'
import {
  RingLoader,
  FadeLoader,
  DotLoader,
  PropagateLoader,
} from "react-spinners";

export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement("script");
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}

class Blogs extends Component {
  // typeAheadref = useRef < Typeahead > (null);
  constructor(props) {
    super(props);
    this.state = {
      subClinicalKolEnabled: true,
      subSpecilityVisible: false,
      ShowBar: false,
      alert: null,
      redirect: false,
      redirectLogin: false,
      redirectSignup: false,
      redirecthome: false,
      redirectPClientViewKolList: false,
      redirectAdministrator: false,
      profiletypeErr: false,
      redirectVisitor: false,
      redirectResercher: false,
      redirectManager: false,
      redirectEmployee: false,
      redirectClientDashboard: false,
      redirectPClientDashboard: false,

      redirectAdministratorDashboard: false,
      redirectVisitorDashboard: false,
      redirectKolProfile: false,
      redirectTermsandPrivacy: false,

      redirectServices: false,

      kdidentificationhidediv: false,
      kdprofilinghidediv: false,
      kdinteracthidediv: false,

      GetInTouchModal: true,

      ClientLoginModal: false,
      AdministratorLoginModal: false,
      VisitorLoginModal: false,
      KolProfileloginModal: false,
      WrongTeamAlertModal: false,
      ContactUsModal: false,
      NewGurusModal: false,
      NewClientsModal: false,
      TermsAndConditionModal: false,
      TermsAndConditionModalForTeam: false,

      //resercherd id for redirectig to researcher dashoard
      RID: "",
      resercher_name: "",
      kols: [
        { id: "clinical", label: "Clinical" },
        { id: "non-clinical", label: "Non-Clinical" },
      ],
      kolSubType: [
        { value: "Payers", label: "Payers" },
        { value: "HTA Experts", label: "HTA Experts" },
        {
          value: "Hospital Procurement Experts",
          label: "Hospital Procurement Experts",
        },
        { value: "Advocacy Group Experts", label: "Advocacy Group Experts" },
        {
          value: "MedTech Industry Experts",
          label: "MedTech Industry Experts",
        },
      ],
      GetInTouchData: {
        client_name: "",
        email_id: "",
        company_nm: "",
        mobile_no: "",
        designation: "",
        message: "",
        status: "New",
        // loginType: 'pre_client',
      },
      Procedure: "",
      procedureSelected: false,
      conditionSelected: false,
      // Manager info
      manager_id: "",
      manager_name: "",

      // Employee info
      employee_id: "",
      employee_name: "",

      speciality_id: "",
      speciality_name: "",

      // For validations
      PhoneErr: "",
      DesignationErr: "",
      MessageErr: "",
      UsernameErr: "",
      UseremailErr: "",
      CompanyNameErr: "",

      PasswordErr: "",
      SelectRoleErr: "",
      wrngUsPsErr: "",

      clientUsernameErr: "",
      clientPasswordErr: "",

      administratorUsernameErr: "",
      administratorPasswordErr: "",

      visitorUsernameErr: "",
      visitorPasswordErr: "",

      kolProfileUsernameErr: "",
      kolProfilePasswordErr: "",
      // /kolProfileSelectRoleErr: '',

      //For getting values of input
      loginData: {
        client_id: "",
        password: "",
        role: "",
        // loginType: 'team',
      },

      ClientLoginData: {
        login_id: "",
        password: "",
        login_type: "client",
      },
      teamLoginIdForTerms: "",
      //Administrator
      AdministratorLoginData: {
        login_id: "",
        password: "",
        login_type: "administrator",
      },
      //visitor
      VisitorLoginData: {
        login_id: "",
        password: "",
        login_type: "visitor",
      },

      KolProfileLoginData: {
        username: "",
        password: "",
        // kol_login_role: '',
      },
      kolLoginIdForTerms: "",

      contact_us: {
        first_name: "",
        last_name: "",
        organization: "",
        email_id: "",
        phone_no: "",
        country: "",
        Read_qpharma: "",
        representative_contact_me: "",
        qpharmas_products: "",
        technical_support_issue: "",
        need_of_assistance: "",
        receive_qpharma_news: "",
        describe_issue_question: "",
      },
      cuFirstNameErr: "",
      cuLastNameErr: "",
      cuOrganizaErr: "",
      cuEmailErr: "",
      cuPhoneErr: "",
      cuCountryErr: "",

      new_guru: {
        first_name: "",
        last_name: "",
        mobile_no: "",
        email_id: "",
        speciality: "",
        sub_speciality: "",
        procedure_type: "",
        country: "",
        state: "",
        current_place_of_work: "",
        department: "",
        linkedin_link: "",
        additional_info: "",
      },
      ngFirstNameErr: "",
      ngLastNameErr: "",
      ngEmailErr: "",
      cuPhoneErr: "",
      ngCountryErr: "",
      ngStateErr: "",

      new_client: {
        first_name: "",
        last_name: "",
        email_id: "",
        mobile_no: "",
        msg: "",
      },
      ncFirstNameErr: "",
      ncLastNameErr: "",
      ncEmailErr: "",
      // ncPhoneErr: '',

      service1: false,
      service2: false,
      service3: false,
      service4: false,
      service5: false,
      service6: false,

      // to get data from dropdown
      SpecialityData: [],
      ProcedureData: [],
      SubSpecialityData: [],
      CountryListData: [],
      StateData: [],

      // to set form values
      Speciality: "",
      SubSpecialityType: [],
      ProcedureType: [],

      Market: [],
      Market2: [],

      PersonalInformation: {},

      toggleForgotPasswordModal: false,
      ForgotPasswordData: {
        email_id: "",
        role: "",
      },
      selectedKol: "",
      searchModalOpen: false,
      kolFormSubmitted: false,
      countryList: [
        { value: "USA", label: "USA" },
        { value: "Canada", label: "Canada" },
        { value: "UK", label: "UK" },
        { value: "Germany", label: "Germany" },
        { value: "France", label: "France" },
        { value: "Italy", label: "Italy" },
        { value: "Spain", label: "Spain" },
      ],
      clinicalKolForm: {
        speciality: "",
        subSpeciality: "",
        procedure: "",
        country: "",
      },
      nonClinicalKolForm: {
        kolSubType: "",
        country: "",
        fullName: "",
        workEmail: "",
        phone: "",
        companyName: "",
        jobTitle: "",
        message: "",
      },
      nonClinicalContactFormSubmitted: false,
      showNonClinicalContactForm: false,
    };
  }

  componentDidMount = async () => {
    console.log("in componentDidMount");

    const options = {
      method: "POST",
    };
    // Speciality type data

    fetch(
      global.websiteUrl +
      "/service/KOL_landing/Get_Speciality_List",
      options
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        console.log(findresponse);
        var testDataSpeciality = findresponse.data;
        // console.log("testDataSpeciality", JSON.stringify(testDataSpeciality));
        if (findresponse.data != "undefined") {
          this.setState({
            SpecialityData: findresponse.data.map((e) => ({
              label: e.speciality_type,
              value: e.id,
            })),
          });
        }
      });
    // Country list(market)
    fetch(global.websiteUrl + "/service/Location/get_CountryList", options)
      .then((Response) => Response.json())
      .then((findresponse) => {
        console.log(findresponse);
        // var testDataCountryList = findresponse.Country_Details;
        // console.log("testDataCountryList", JSON.stringify(testDataCountryList));
        this.setState({
          countryList: findresponse.Country_Details.map(c => ({ "value": c.id, "label": c.country_name })),
          Client_Unique_id: findresponse.client_system_id,
        });
      });
  };

  setSubSpecility = (id) => {
    const formdata = new FormData();
    formdata.append("speciality_type_id", id);
    const options = {
      method: "POST",
      body: formdata,
    };
    // Speciality type data
    const url = "/service/KOL_landing/Get_Sub_Speciality_List";
    fetch(global.websiteUrl + url, options)
      .then((Response) => Response.json())
      .then((findresponse) => {
        let subSpecilityList = findresponse.data.map(e => ({
          label: e.sub_speciality_type,
          value: e.id,
        }))
        if (findresponse.data != "undefined") {
          this.setState({
            SubSpecialityType: subSpecilityList,
            subSpecilityVisible: subSpecilityList[0].label !== "NA" ? true : false
          });
        }
      });
  };

  toggleGetInTouchModal() {
    //console.log("loginclicked")

    this.setState({
      GetInTouchModal: !this.state.GetInTouchModal,
    });
  }

  //login = toggle validate and login
  toggleLoginModal() {
    this.setState({
      redirectLogin: true,
    });
  }

  toggleSignUpModal() {
    sessionStorage.removeItem("quotation_id", "");
    this.setState({
      redirectSignup: true,
    });
  }

  toggleWithoutidModal() {
    setTimeout(function () {
      window.location.reload();
    }, 3000);
  }

  closeSearchModal() {
    this.setState({ searchModalOpen: false, kolFormSubmitted: false });
  }

  closeNonClinicalContactModal() {
    this.setState({ showNonClinicalContactForm: false });
  }

  openSearchModal() {
    if (this.state.selectedKol != "") {
      console.log(this.state.selectedKol);
      this.setState({ searchModalOpen: true });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops",
        text: "Please select KOL",
        confirmButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
  }

  makespaceondivkdidentification() {
    this.setState({
      hidedivkdprofiling: false,
      hidedivkdinteract: false,
      hidedivkdidentification: true,
    });
  }

  makespaceondivkdprofiling() {
    this.setState({
      hidedivkdidentification: false,
      hidedivkdinteract: false,
      hidedivkdprofiling: true,
    });
  }

  makespaceondivkdinteract() {
    this.setState({
      hidedivkdidentification: false,
      hidedivkdprofiling: false,
      hidedivkdinteract: true,
    });
  }

  makespaceondivhome() {
    this.setState({
      hidedivkdidentification: false,
      hidedivkdprofiling: false,
      hidedivkdinteract: false,
    });
  }

  validate = () => {
    let UsernameErr = "";
    let UseremailErr = "";
    let CompanyNameErr = "";
    let PhoneErr = "";
    let DesignationErr = "";
    let MessageErr = "";

    if (!this.state.GetInTouchData.client_name) {
      UsernameErr = "Please Enter Full Name";
    }
    if (!this.state.GetInTouchData.email_id) {
      UseremailErr = "Please Enter Email id";
    }

    if (!this.state.GetInTouchData.company_nm) {
      CompanyNameErr = "Please Enter Company Name";
    }

    if (!this.state.GetInTouchData.mobile_no) {
      PhoneErr = "Please Enter phone";
    }

    if (!this.state.GetInTouchData.designation) {
      DesignationErr = "Please Enter designation";
    }

    if (!this.state.GetInTouchData.message) {
      MessageErr = "Please Enter message";
    }

    if (
      UsernameErr ||
      UseremailErr ||
      CompanyNameErr ||
      PhoneErr ||
      DesignationErr ||
      MessageErr
    ) {
      this.setState({
        UsernameErr,
        PhoneErr,
        UseremailErr,
        CompanyNameErr,
        DesignationErr,
        MessageErr,
      });
      return false;
    }
    return true;
  };

  GetInTouchfun() {
    const isValid = this.validate();

    if (isValid) {
      this.setState({ ShowBar: true });

      const url =
        global.websiteUrl + "/service/KOL_landing/check_duplicate_get_in_touch";
      let formData = new FormData();
      formData.append("email_id", this.state.GetInTouchData.email_id);

      const config = {
        //   headers: { 'content-type': 'multipart/form-data' }
      };
      axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
          var lgstatus = response.data.status;
          console.log("lgstatus", JSON.stringify(lgstatus));

          this.setState({ ShowBar: false });

          //  alert(lgstatus);

          if (lgstatus == false) {
            this.SaveGetInTouchDatafun();
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops",
              text: "Emails Already exits pls change new email",
              confirmButtonColor: "#d33",
              confirmButtonText: "OK",
            });

            this.setState({
              UseremailErr: "Change New Email",
              confirmpasswordErr: "",
              UsernameErr: "",
              CompanyNameErr: "",
              PasswordErr: "",
              termconditionErr: "",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  SaveGetInTouchDatafun() {
    this.setState({
      ShowBar: true,
    });

    const url =
      global.websiteUrl + "/service/KOL_landing/save_Gate_In_Touch_data";
    let formData = new FormData();

    formData.append(
      "Gate_In_Touch_Info",
      JSON.stringify(this.state.GetInTouchData)
    );

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    };

    axios
      .post(url, formData, config)
      .then((response) => {
        console.log(response);
        var lgdata = response.data;
        console.log("lgdata", JSON.stringify(lgdata));
        var lgstatus = response.data.status;
        console.log("lgstatus", JSON.stringify(lgstatus));
        //      console.log("response message2", response.data.message)

        this.setState({
          ShowBar: false,
        });

        if (response.data.status == true) {
          Swal.fire({
            icon: "secondary",
            //text: response.data.message,
            text: "secondary! Your message has been secondaryfully delivered to us. Our team will get in touch with one working day.",
            confirmButtonColor: "#d33",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            icon: "error",
            text: "Failed",
            confirmButtonColor: "#d33",
            confirmButtonText: "OK",
          });
        }

        setTimeout(function () {
          window.location.reload();
        }, 4000);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  submitKOLform() {
    this.setState({ kolFormSubmitted: true });
    if (this.state.selectedKol === this.state.kols[0].label) {
      if (this.state.clinicalKolForm.speciality) {
        sessionStorage.setItem("client_email", "");
        sessionStorage.setItem("client_id", "Guest");
        sessionStorage.setItem("client_name", "");
        sessionStorage.setItem(
          "subspeciality",
          this.state.clinicalKolForm.subSpeciality?.[0]?.label
        );
        sessionStorage.setItem(
          "procedure",
          this.state.clinicalKolForm.procedure?.[0]?.label
        );
        sessionStorage.setItem(
          "speciality_id",
          this.state.clinicalKolForm?.speciality?.[0]?.value
        );
        sessionStorage.setItem(
          "speciality",
          this.state.clinicalKolForm?.speciality?.[0]?.label
        );
        this.setState({ redirectPClientViewKolList: true });
      } else {
        Swal.fire({
          icon: "error",
          text: "Please Select Speciality",
          confirmButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    } else {
      if (this.state.nonClinicalKolForm.kolSubType) {
        console.log(this.state.nonClinicalKolForm.kolSubType)
        this.setState({
          showNonClinicalContactForm: true,
          searchModalOpen: false,
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "Please Select KOL Subtype",
          confirmButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    }
  }

  handleClinicalFormField(id, value) {
    console.log({
      id,
      value,
    });
    let { clinicalKolForm } = this.state;
    // this.setState({
    //   ProcedureType: []
    // })
    if (id != '')
      clinicalKolForm[id] = value;

    clinicalKolForm['procedureType'] = this.state.Procedure
    clinicalKolForm['procedure'] = ""

    // const spId = this.state.SpecialityData.filter(sp => sp.speciality_type === value)[0]
    if (id == "speciality" && value?.[0]?.value !== undefined) {
      this.state.subClinicalKolEnabled = false
      this.setSubSpecility(value?.[0]?.value);
      this.state.profiletypeErr = false;

      // this.refs.typeahead.getInstance().clear()
      this.setState({
        ProcedureType: [],
        Procedure: false,
        procedureSelected: false,
        conditionSelected: false
      })
    } else if (id == "procedure" && value?.[0]?.value !== undefined) {
      this.state.subClinicalKolEnabled = false
    } else {
      this.state.subClinicalKolEnabled = true
    }
    this.setState({ clinicalKolForm: clinicalKolForm });
    console.log(this.state.clinicalKolForm);
  }

  handleNonClinicalFormField(id, value) {
    this.state.subClinicalKolEnabled = false
    let { nonClinicalKolForm } = this.state;
    nonClinicalKolForm[id] = value;
    this.setState({ nonClinicalKolForm: nonClinicalKolForm });
  }

  validateField(type, id) {
    console.log('Validate field called with - ', { type, id })
    const form = this.state[type];
    if (this.state.kolFormSubmitted) {
      if (form[id]) {
        console.log('returning false')
        return false;
      } else {
        console.log('returning true')
        return true;
      }
    } else {
      console.log('returning false')
      return false;
    }
  }

  validateContactFormField(id) {
    const form = this.state.nonClinicalKolForm;
    // console.log(this.state.nonClinicalContactFormSubmitted, "check");
    if (this.state.nonClinicalContactFormSubmitted) {
      if (form[id]) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  onProcedureChanged = (e) => {
    console.log(e.target.value);
    let { clinicalKolForm } = this.state
    console.log(clinicalKolForm)
    let speciality_id = clinicalKolForm.speciality
    if (!speciality_id) {
      this.state.profiletypeErr = 'Please select Speciality from dropdown!'
    }
    const value = e.target.value;
    this.setState({
      ProcedureType: [],
    });
    // console.log(this.refs.typeahead.clear())
    if (this.refs.typeahead) { this.refs.typeahead.clear() }
    value === "Procedure" ? this.setState({ procedureSelected: true, conditionSelected: false }) : this.setState({ conditionSelected: true, procedureSelected: false })
    const formdata = new FormData();
    if (speciality_id?.[0]) {
      formdata.append("speciality_type_id", speciality_id[0].value);
      this.setState({ Procedure: value });
      this.state.profiletypeErr = false

    }
    const options = {
      method: "POST",
      body: formdata,
    };
    let url =
      value === "Procedure"
        ? "/service/KOL_landing/Get_Procedure_List"
        : "/service/KOL_landing/Get_Condition_List";
    fetch(global.websiteUrl + url, options)
      .then((Response) => Response.json())
      .then((findresponse) => {
        console.log(findresponse);
        var procedureList = findresponse.data;
        if (procedureList != "undefined") {
          this.setState({
            ProcedureType: procedureList.map((item) => ({
              label:
                value === "Procedure"
                  ? item.procedure_type
                  : item.condition_type,
              value: item.id,
            })),
          });
        }
      });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/home"} />;
    }

    if (this.state.redirecthome) {
      return <Redirect to={"/home"} />;
    }

    if (this.state.redirectLogin) {
      return <Redirect to={"/user login"} />;
    }

    if (this.state.redirectSignup) {
      return <Redirect to={"/create new account"} />;
    }
    if (this.state.redirectPClientViewKolList) {
      return <Redirect to={"/PClientViewKolList"} />;
    }

    if (this.state.redirectAdmin) {
      return <Redirect to={"/AdminDashboard"} />;
    }

    if (this.state.redirectResercher) {
      return (
        <Redirect
          to={{
            pathname: "/ResercherDashboard",
            state: {
              RID: this.state.RID,
              resercher_name: this.state.resercher_name,
            },
          }}
        />
      );
    }

    if (this.state.redirectManager) {
      return (
        <Redirect
          to={{
            pathname: "/ManagerDashboard",
            // state:{manager_id: this.state.manager_id , manager_name: this.state.manager_name}
          }}
        />
      );
    }

    if (this.state.redirectEmployee) {
      return (
        <Redirect
          to={{
            pathname: "/EmployeeDashboard",
            //  state:{employee_id: this.state.employee_id , employee_name: this.state.employee_name}
          }}
        />
      );
    }

    if (this.state.redirectClientDashboard) {
      return (
        <Redirect
          to={{
            pathname: "/ClientViewProject",
          }}
        />
      );
    }

    if (this.state.redirectAdministratorDashboard) {
      return (
        <Redirect
          to={{
            pathname: "/AdministratorViewProject",
          }}
        />
      );
    }

    if (this.state.redirectVisitorDashboard) {
      return (
        <Redirect
          to={{
            pathname: "/VisitorDashboard",
          }}
        />
      );
    }

    if (this.state.redirectKolProfile) {
      return (
        <Redirect
          to={{
            pathname: "/KolProfileDashboard",
          }}
        />
      );
    }

    if (this.state.redirectTermsandPrivacy) {
      return (
        <Redirect
          to={{
            pathname: "/TermsPrivacyPolicy",
          }}
        />
      );
    }
    if (this.state.redirectVisitorDashboard) {
      return (
          <Redirect
              to={{
                  pathname: "/VisitorDashboard",
              }}
          />
      );
  }
  if (this.state.redirectKolProfile) {
      return (
          <Redirect
              to={{
                  pathname: "/KolProfileDashboard",
              }}
          />
      );
  }
    if (this.state.redirectabout) {
        return (
          <Redirect
            to={{
              pathname: "/about",
            }}
          />
        );
      }
      if (this.state.redirectpricing) {
        return (
          <Redirect
            to={{
              pathname: "/pricing",
            }}
          />
        );
      }
      if (this.state.redirectContact) {
        return (
          <Redirect
            to={{
              pathname: "/contact",
            }}
          />
        );
      }
      if (this.state.redirectBlogs) {
        return (
          <Redirect
            to={{
              pathname: "/blogs",
            }}
          />
        );
      }
      if (this.state.redirectCaseStudy) {
        return (
          <Redirect
            to={{
              pathname: "/casestudy",
            }}
          />
        );
      }
      if (this.state.redirectKOLCompliant) {
        return (
          <Redirect
            to={{
              pathname: "/compliant",
            }}
          />
        );
      }
    let speciality_type = this.state.SpecialityData;
    let OptionsSpeciality = speciality_type.map((speciality_type) => (
      <option key={speciality_type.id} value={speciality_type.id}>
        {speciality_type.speciality_type}
      </option>
    ));

    return (
      <body id="landing-body">
        <div>
          {this.state.ShowBar ? (
            <Modal isOpen={true} className="propagateLoader" centered>
              <PropagateLoader color={"#A90007"} size="40px" loading="true" />
            </Modal>
          ) : null}
        </div>
        {this.state.ShowProcedure ? (
          <Modal isOpen={true} className="propagateLoader" centered>
            <PropagateLoader color={"#A90007"} size="40px" loading="true" />
          </Modal>
        ) : null}

        {AddLibrary("./js/app.js")}

        <nav
          className="navbar navbar-expand-lg navbar-landing sticky-top" id="navbar"
        >
          <div className="content container-fluid">
            <a
              className="navbar-brand"
              href="https://www.cetas.medtech-gurus.com"
            >
              <img
                  src={require('../src/Assets/Images/Medtech_Gurus_logo.png')} 
                ></img>
            </a>
            <div className="menu-bar">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="bx bx-menu"></i>
              </button>
              <div
                className="navbar-collapse collapse"
                id="navbarNav">
                <ul className="navbar-nav me-md-auto ms-auto mt-2 mt-lg-0">
                <li className="nav-item">
                    <a
                      href='/home' onClick={this.redirecthome}
                      className="nav-link"
                    >
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href='/about us' onClick={this.redirectabout}
                    >
                      About Us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href='/kol identification' onClick={this.redirectKOLidentification}
                    >
                      KOL Identification
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href='/kol engagement' onClick={this.redirectKOLEngagement}
                    >
                      KOL Engagement
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href='/pricing' onClick={this.redirectpricing}
                    >
                      Pricing
                    </a>
                  </li>                  
                  <li className="nav-item">
                  <a
                      className="nav-link"
                      href='/blogs' onClick={this.redirectBlogs}
                    >
                      Blogs
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href='/contact us' onClick={this.redirectContact}
                    >
                      Contact Us
                    </a>
                  </li>
                  <li className="nav-signup align-items-center d-flex d-lg-none">
                    <a
                      href="javascript:void(0);"
                      style={{ color: "#A90007", fontWeight: "bolder" }}
                      data-bs-toggle="modal"
                      onClick={this.toggleLoginModal.bind(this)}
                    >
                      Log In
                    </a>
                    <a
                      href="javascript:void(0);"
                      style={{ color: "#A90007", fontWeight: "bolder" }}
                      data-bs-toggle="modal"
                      onClick={this.toggleSignUpModal.bind(this)}
                    >
                      / Sign Up
                    </a>
                  </li>
                </ul>

              </div>
            </div>
            <div className="Navheaderlink justify-content-between d-none d-lg-flex">
              <ul className="navbar-nav  align-items-center gap-20">
                <li className="nav-social">
                  <a
                    href="https://www.linkedin.com/company/cetas-healthcare/"
                    target="_blank"
                  >
                    <i className="bx bxl-linkedin"></i>
                  </a>
                </li>
                <li className="nav-item-email">
                  <a
                    className="link-h email-link"
                    onClick={this.makespaceondivhome.bind(this)}
                    href="mailto:kolmanagement@cetas-healthcare.com"
                    style={{ fontWeight: "normal" }}
                  >
                    <img src="./static/media/email.f2b82b84.svg" />
                    kolmanagement@cetas-healthcare.com
                  </a>
                </li>
              </ul>

              <div className="nav-signup align-items-center">
                <a
                  href="javascript:void(0);"
                  style={{ color: "#A90007", fontWeight: "bolder" }}
                  data-bs-toggle="modal"
                  onClick={this.toggleLoginModal.bind(this)}
                >
                  Log In
                </a>
                <a
                  href="javascript:void(0);"
                  style={{ color: "#A90007", fontWeight: "bolder" }}
                  data-bs-toggle="modal"
                  onClick={this.toggleSignUpModal.bind(this)}
                >
                  / Sign Up
                </a>
              </div>
            </div>
          </div>
        </nav>
        <div id="page-banner" className="bg-service">
          <div className="bg-overlay"></div>
          <div className="container-fluid banner-section">
            <h1 className="pb-1 text-white">Resources</h1>
            <ol class="breadcrumb m-0 p-0 bg-transparent">
              <li class="breadcrumb-item"><a href="/home">Home</a></li>
              <li class="breadcrumb-item active">Resources</li>
            </ol>
          </div>
        </div>
        <section class="section">
          <div class="container">
            <div class="justify-content-center row">
              <div class="col-lg-12 mb-4">
                <h3 class="mb-0 fw-bold lh-base fs-4">Our Latest News</h3>
              </div>
            </div>
            <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="card">
                            <div class="card-body p-0 m-0">
                                    <img src={require("../src/Assets/Images/MedTech-Industry.jpg")} alt="" class="img-fluid rounded" />
                                </div>
                                <div class="card-body">
                                    <ul class="list-inline d-flex align-items-center fs-12 text-muted">
                                        <li class="list-inline-item d-flex align-items-center">
                                            <i class="bx bx-calendar align-bottom me-1"></i> 23 April, 2024
                                        </li>
                                        <li class="list-inline-item d-flex align-items-center">
                                            <i class="bx bx-purchase-tag align-bottom me-1"></i> Blog
                                        </li>
                                    </ul>
                                    <a href="/blogdetails">
                                        <h5 class="fs-18">The Critical Role of KOLs in Medical Device Innovation</h5>
                                    </a>
                                    <p class="fs-14">Engaging with Key Opinion Leaders (KOLs) is crucial for a medical devices company for various reasons, including:.</p>
                                    <div class="fs-14">
                                        <a href="/blogdetails" class="link-secondary">Read More <i class="ri-arrow-right-line align-bottom ms-1"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
          </div>
        </section>
        
        <div id="search" className="text-center">
          <p className="service_insights m-0">
            Your One-Stop-Solution for Key Opinion Leaders identification and
            engagement
          </p>
        </div>

        <footer class="footer bg-grey">
            <div class="container">
                <div class="row">
                    <div class="col-sm-6 col-md-4 mt-4 col-lg-2 text-align">
                        <a className="text-center" href="https://www.cetas.medtech-gurus.com">
                        <img src={require('../src/Assets/Images/Medtech_Gurus_logo.png')} width="100" ></img>
                        </a>
                        <div class="social">
                            <ul class="list-inline my-4">
                                <li class="list-inline-item"><a href="#" class="text-secbrand"><i class='fs-36 bx bxl-linkedin-square' ></i></a></li>
                            </ul>
                        </div>
                    </div>                      
                    <div class="col-sm-6 col-md-4 col-lg-3 text-align">
                        <div class="information">
                            <ul class="list-unstyled footer-link mt-4">
                                <li class="mb-1"><a href="/about us" class="text-decoration-none">About</a></li>
                                <li class="mb-1"><a href="/services" class="text-decoration-none">Services</a></li>
                                <li class="mb-1"><a href="/pricing" class="text-decoration-none">Pricing</a></li>
                                <li class=""><a href="/contact us" class="text-decoration-none">Contact</a></li>
                            </ul>
                        </div>
                    </div>                   
                    <div class="col-sm-6 col-md-4 col-lg-3 text-align">
                        <div class="information">
                            <ul class="list-unstyled footer-link mt-4">
                                <li class="mb-1"><a href="/blogs" class="text-decoration-none">Blogs</a></li>
                                <li class="mb-1"><a href="/casestudy" class="text-decoration-none">Case Study</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-3 text-align">
                        <div class="resources">
                            <ul class="list-unstyled footer-link mt-4">
                                <li class="mb-1"><a href="https://www.cetas.medtech-gurus.comprivacy_policy/" class="text-decoration-none">Privacy Policy</a></li>
                                <li class="mb-1"><a href="https://www.cetas.medtech-gurus.comterms-of-use/" class="text-decoration-none">Terms & Conditions</a></li>
                                <li class="mb-1"><a href="https://www.cetas.medtech-gurus.comcookie-notice/" class="text-decoration-none">Security</a></li>
                            </ul>
                        </div>
                    </div>  
                </div>
            </div>
            <div class="text-center bg-black p-2">
                <p class="mb-0 fs-12 text-white">{global.CopyRightText}</p>
            </div>
        </footer>
        <Modal
          isOpen={this.state.searchModalOpen}
          toggle={() => this.closeSearchModal()}
          centered
          fullscreen
        >
          <ModalHeader toggle={() => this.closeSearchModal()}>
            <h3>{this.state.selectedKol} KOL</h3>
          </ModalHeader>
          <ModalBody style={{ padding: "0px 30px" }}>
            <div style={{ margin: "20px 0px" }}>
              {this.state.selectedKol === this.state.kols[0].label ? (
                <Form>
                  <FormGroup>
                    <Label>Select Country</Label>
                    <Typeahead
                      id="clinical_country"
                      multiple
                      onChange={(e) =>
                        this.handleClinicalFormField("country", e)
                      }
                      options={this.state.countryList}
                      placeholder="Choose a Country..."
                      selected={this.state.clinicalKolForm.country}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      Select Speciality <span class="mandatory">*</span>
                    </Label>
                    <Typeahead
                      id="speciality"
                      invalid={this.validateField(
                        "clinicalKolForm",
                        "speciality"
                      )}
                      onChange={(e) =>
                        this.handleClinicalFormField("speciality", e)
                      }
                      options={this.state.SpecialityData}
                      placeholder="Choose a Speciality..."
                      selected={this.state.clinicalKolForm.speciality}
                    />
                    {this.validateField("clinicalKolForm", "speciality") && (
                      <FormFeedback>Please Select Speciality</FormFeedback>
                    )}
                  </FormGroup>
                  <FormGroup style={this.state.subSpecilityVisible ? {} : { "display": "none" }}>
                    <Label>Select Sub-Speciality</Label>
                    <Typeahead
                      id="subSpeciality"
                      multiple
                      onChange={(e) =>
                        this.handleClinicalFormField("subSpeciality", e)
                      }
                      options={this.state.SubSpecialityType}
                      placeholder="Choose a Sub-speciality..."
                      selected={this.state.clinicalKolForm.subSpeciality}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Please specify the procedure/condition (if any)</Label>
                    <div
                      onChange={this.onProcedureChanged}
                      style={{
                        height: "30px",
                        width: "100%",
                        // color: "#949393",
                        backgroundColor: "#fff",
                        borderColor: "#ccc",
                      }}
                    >
                      <input
                        type="radio"
                        value="Procedure"
                        name="Procedure"
                        checked={this.state.procedureSelected}
                      />
                      <span className="Radio_button">Procedure </span>
                      <input
                        type="radio"
                        value="Condition"
                        name="Procedure"
                        checked={this.state.conditionSelected}
                        style={{ marginLeft: "5%" }}
                      />{" "}
                      Condition
                    </div>

                    <div className="Quotblanck">
                      {this.state.profiletypeErr ? this.state.profiletypeErr : null}
                    </div>
                  </FormGroup>
                  {this.state.Procedure && (
                    <FormGroup>
                      <Label>Select {this.state.Procedure}</Label>
                      <Typeahead
                        ref='typeahead'
                        id="procedure"
                        onChange={(e) =>
                          this.handleClinicalFormField("procedure", e)
                        }
                        options={this.state.ProcedureType}
                        placeholder={`Choose a ${this.state.Procedure}...`}
                        selected={this.state.clinicalKolForm.procedure}
                      />
                    </FormGroup>
                  )}


                </Form>
              ) : (
                <Form>
                  <FormGroup>
                    <Label>
                      Select KOL Subtype <span class="mandatory">*</span>
                    </Label>
                    <Typeahead
                      id="kolSubType"
                      onChange={(e) =>
                        this.handleNonClinicalFormField("kolSubType", e)
                      }
                      options={this.state.kolSubType}
                      placeholder="Choose a KOL Subtype..."
                      selected={this.state.nonClinicalKolForm.kolSubType}
                    />

                    {this.validateField("nonClinicalKolForm", "kolSubType") && (
                      <FormFeedback>Please Select Sub-KOL Type</FormFeedback>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label>Select Country</Label>
                    <Typeahead
                      id="nonclinical_country"
                      multiple
                      onChange={(e) =>
                        this.handleNonClinicalFormField("country", e)
                      }
                      options={this.state.countryList}
                      placeholder="Choose a Country..."
                      selected={this.state.nonClinicalKolForm.country}
                    />
                  </FormGroup>
                </Form>
              )}
            </div>
            <br />
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => this.submitKOLform()}
              className="btn btn-brand ms-lg-3"
              disabled={this.state.subClinicalKolEnabled}
            >
              Submit
            </Button>{" "}
            <Button color="secondary" onClick={() => this.closeSearchModal()}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.showNonClinicalContactForm}
          toggle={() => this.closeNonClinicalContactModal()}
          size="lg"
          style={{ maxWidth: "700px", width: "100%" }}
          centered
          fullscreen
        >
          <ModalHeader toggle={() => this.closeNonClinicalContactModal()}>
            <h1>Get in touch with us</h1>
            <p>
              Please fill in the form below and we will get in touch within 1
              working day
            </p>
          </ModalHeader>
          <ModalBody style={{ padding: "0px 30px" }}>
            <form className="p-lg-5 col-12 row g-3">
              <div className="col-lg-6">
                <label for="" className="form-label">
                  KOL Subtype:<span class="mandatory">*</span>
                </label>
                <Input
                  type="select"
                  id="SpecilitySelect"
                  value={this.state.nonClinicalKolForm.kolSubType[0]?.label}
                  onChange={(e) =>
                    this.handleNonClinicalFormField(
                      "kolSubType",
                      e.target.value
                    )
                  }
                  style={{ backgroundColor: "white" }}
                >
                  <option>Select KOL Subtype</option>
                  {this.state.kolSubType.map((kol) => (
                    <option key={kol.id} value={kol.label}>
                      {kol.label}
                    </option>
                  ))}
                </Input>
                {this.validateContactFormField("kolSubType") && (
                  <FormFeedback>Please Select Sub-KOL Type</FormFeedback>
                )}
                <br />
              </div>
              <div className="col-lg-6">
                <label for="" className="form-label">
                  Country:
                </label>
                <Input
                  type="select"
                  id="SpecilitySelect"
                  multi
                  style={{ backgroundColor: "white" }}
                  value={this.state.nonClinicalKolForm.country[0]?.label}
                  onChange={(e) =>
                    this.handleNonClinicalFormField("country", e.target.value)
                  }
                >
                  <option value="">Select Country</option>
                  {this.state.countryList.map((country) => {
                    // console.log(country)
                    return (<option key={country.value} value={country.label}>
                      {country.label}
                    </option>)
                  })}
                </Input>
              </div>
              <div className="col-lg-6">
                <label for="" className="form-label">
                  Full Name:<span class="mandatory">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  id=""
                  aria-describedby=""
                  value={this.state.nonClinicalKolForm.fullName}
                  onChange={(e) => {
                    this.handleNonClinicalFormField("fullName", e.target.value);
                  }}
                ></input>
                {this.validateContactFormField("fullName") && (
                  <div className="error">Please Enter Full Name</div>
                )}
              </div>

              <div className="col-lg-6">
                <label for="" className="form-label">
                  Your work email:<span class="mandatory">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  id=""
                  aria-describedby=""
                  value={this.state.nonClinicalKolForm.workEmail}
                  onChange={(e) => {
                    this.handleNonClinicalFormField(
                      "workEmail",
                      e.target.value
                    );
                  }}
                ></input>
                {this.validateContactFormField("workEmail") && (
                  <div className="error">Please Enter Work Email</div>
                )}
              </div>
              <div className="col-lg-6">
                <label for="" className="form-label">
                  Your work phone:<span class="mandatory">*</span>
                </label>

                <PhoneInput
                  inputStyle={{
                    width: "100%",
                    height: "50px",
                    fontSize: "15px",
                  }}
                  width="50%"
                  country={"in"}
                  value={this.state.nonClinicalKolForm.phone}
                  onChange={(mobile_no) => {
                    this.handleNonClinicalFormField("phone", mobile_no);
                  }}
                />

                {this.validateContactFormField("phone") && (
                  <div
                    style={{ marginTop: "10px !important" }}
                    className="error"
                  >
                    Please Enter Mobile Number
                  </div>
                )}
              </div>
              <div className="col-lg-6">
                <label for="" className="form-label">
                  Your company name:<span class="mandatory">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  id=""
                  aria-describedby=""
                  value={this.state.nonClinicalKolForm.companyName}
                  onChange={(e) => {
                    this.handleNonClinicalFormField(
                      "companyName",
                      e.target.value
                    );
                  }}
                ></input>
                {this.validateContactFormField("companyName") && (
                  <div className="error">Please Enter Work Email</div>
                )}
              </div>
              <div className="col-lg-12">
                <label for="" className="form-label">
                  Your job title:<span class="mandatory">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  id=""
                  aria-describedby=""
                  value={this.state.nonClinicalKolForm.jobTitle}
                  onChange={(e) => {
                    this.handleNonClinicalFormField("jobTitle", e.target.value);
                  }}
                ></input>
                {this.validateContactFormField("jobTitle") && (
                  <div className="error">Please Enter Job Title</div>
                )}
              </div>
              <div className="col-lg-12">
                <label for="exampleInputEmail1" className="form-label">
                  Message:<span class="mandatory">*</span>
                </label>
                <textarea
                  name=""
                  placeholder=""
                  className="form-control"
                  id=""
                  rows="2"
                  value={this.state.nonClinicalKolForm.message}
                  onChange={(e) => {
                    this.handleNonClinicalFormField("message", e.target.value);
                  }}
                ></textarea>

                {this.validateContactFormField("message") && (
                  <div className="error">Please Enter Message</div>
                )}
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() =>
                this.setState({
                  nonClinicalContactFormSubmitted: true,
                  showNonClinicalContactForm: false,
                })
              }
              className="btn btn-brand ms-lg-3"
            >
              Submit
            </Button>{" "}
            <Button
              color="secondary"
              onClick={() => this.closeNonClinicalContactModal()}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </body>
    );
  }
}

export default Blogs;
