import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Input, Container, Row, Col, Modal } from 'reactstrap';
import axios from 'axios';
import { Multiselect } from 'react-widgets';
import Swal from 'sweetalert2';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import LoadingBar from "react-top-loading-bar";
import { RingLoader, FadeLoader, DotLoader, PropagateLoader } from 'react-spinners';
import MultipleValueTextInput from 'react-multivalue-text-input';
import YearPicker from "react-year-picker";

import ResercherHeader from './ResercherHeader';


class IdentifierForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Progress
            ShowProcedure: false,
            ShowBar: false,
            token:'',
            // 
            resercher_name: '',
            kol_unique_id: '',
            emp_unique_id: '',
            SrNO: '',
            kol_status: '',
            project_id: '',
            ImageErr:'',
            ResumeErr:'',
            // 
            SpecialityData: [],
            CountryListData: [],
            CityData: [],
            StateData: [],

            // to show buttons
            ShowDuplicateCheckButton: true,
            ShowKOLIdentifierButton: false,

            // redirect
            RedirectResercherKolList: false,

            //Flag to show form of selected drawer item
            PersonalInformationFlag: true,

            // 
            PersonalInformation: {},
            WorkDetails: [],
            KOl_Identifier: {},
            Biography: {},
            ProfessionalMemberships: [],
            Education: [],
            AdvisoryBoard: [],
            AwardsHonours: [],
            BooksMonographs: [],
            Commitees: [],
            ClinicalTrialDetails: [],
            EventsDetails: [],
            Grants: [],
            Guidelines: [],
            HospitalAffiliations: [],
            Interactions: [],
            PressItem: [],
            Presentation: [],
            PublicationDetails: [],
            SunshinePayment: [],
            profile_photo: '',
            contract_file: '',
            ContractFileName: '',

            BiographyResume: '',

            profile_photo_for_edit: '',
            BiographyResume_edit: '',
            profile_photo: '',


            // for image
            file: '',
            imagePreviewUrl: '',

            checkDuplicate: {
                kol_type: '',
                doctor_full_name: '',
                specialty_id: '',
                sub_specialty_id: '',
                procedure_type_id: '',
                country_id: '',
            },

            // variables for error
            // personal info
            koltypeErr: '',
            salutationErr: '',
            doctorfullnameErr: '',
            firstnameErr: '',
            lastnameErr: '',
            SuffixErr: '',
            expertiseErr: '',
            // qualificationErr: '',
            experienceErr: '',
            // medicalschoolErr: '',
            placeofworkErr: '',
            designationErr: '',
            departmentErr: '',
            specialityErr: '',
            proceduretypeErr: '',
            subspecialitytypeErr: '',
            doctorcontactnoErr: '',
            doctoremailErr: '',
            doctoremail2Err: '',
            countryErr: '',
            stateErr: '',
            address1Err: '',
            zipcodeErr: '',
            // lastupdateddateErr: '',

            eventConferencTypeErr: '',
            eventRoleErr: '',
            advBoardCompanyTypeErr: '',
            advBoardRoleErr: '',
            publicationJournalTypeErr: '',
            publicationRoleErr: '',
            ClinicalNoOfTrialLocationErr: '',
            clinicalTrialTypeErr: '',
            guidelinesOrgTypeErr: '',

            // Variables for add source
            SalutationAddSource: false,
            DFullNameAddSource: false,
            FirstNameAddSource: false,
            MiddleNameAddSource: false,
            LastNameAddSource: false,
            suffixAddSource: false,
            expertiseAddSource: false,
            QualificationAddSource: false,
            ExperienceAddSource: false,
            MedicalSchoolsAddSource: false,
            MedicalRegistrationNoAddSource: false,
            NPINoAddSource: false,
            PlaceOfWorkAddSource: false,
            DesignationAddSource: false,
            BrandAddSource: false,
            DepartmentAddSource: false,
            SpecialityAddSource: false,
            ProcedureTypeAddSource: false,
            SubSpecialityTypeAddSource: false,
            DoctorContactNoAddSource: false,
            DoctorEmailAddSource: false,
            DoctorEmail2AddSource: false,
            FaxAddSource: false,
            LinkedInProfileAddSource: false,
            TwitterHandleAddSource: false,
            AssistanceContactNoAddSource: false,
            AssistanceEmailAddSource: false,
            WebsiteAddSource: false,
            CountryAddSource: false,
            CityAddSource: false,
            
            StateAddSource: false,
            Address1AddSource: false,
            Address2AddSource: false,

            // for adding new source multiple
            items: [],
            value: "",

            // for adding new source multiple peronal info
            valuePersonalInfoAddSource: '',

            itemsSalutation: [],
            itemsDoctorFullName: [],
            itemsFirstName: [],
            itemsMiddleName: [],
            itemsLastName: [],
            
            itemsexpertise: [],
            itemssuffix: [],
            itemsQualification: [],
            itemsExperience: [],
            itemsMedicalSchool: [],
            itemsMedicalRegisterNo: [],
            itemsNpiNo: [],
            itemsPlaceWork: [],
            itemsDesignation: [],
            itemsBrand: [],
            itemsDepartment: [],
            itemSpeciality: [],
            itemSpeciality2: [],
            itemsProcedure: [],
            itemsSubSpeciality: [],
            itemsDoctorContact: [],
            itemsDoctorEmail: [],
            itemsDoctorEmail2: [],
            itemsFax: [],
            itemsLinkedIn: [],
            itemsTwitter: [],
            itemsAssistanceContact: [],
            itemsAssistanceEmail: [],
            itemsWebsite: [],
            itemsCountry: [],
            itemsState: [],
            itemsCity: [],
            itemsAddress1: [],
            itemsAddress2: [],
            itemsProcedurePotential: [],

            // set procedre and sub speciality 
            ProcedureType: [],
            SubSpecialityType: [],
            // to recall the procedure and subspeciality multiple dropdowns
            showProcedureSubSpecialityDropdown: false,

        };
    }

    componentDidMount = async () => {

        let getSession_token = await sessionStorage.getItem('token')
        if (getSession_token !== null) {
        await this.setState({
            token: getSession_token,
        })
        }

        let getSession_Emp_Unique_Id = await sessionStorage.getItem('emp_unique_id')
        if (getSession_Emp_Unique_Id !== null) {
            await this.setState({
                emp_unique_id: getSession_Emp_Unique_Id,
            })
        }
        let getSession_Sr_No = await sessionStorage.getItem('SrNO')
        if (getSession_Sr_No !== null) {
            await this.setState({
                SrNO: getSession_Sr_No,
            })
        }
        let getSession_Researcher_Name = await sessionStorage.getItem('name')
        if (getSession_Researcher_Name !== null) {
            await this.setState({
                resercher_name: getSession_Researcher_Name,
            })
        }
        let getSession_Project_Id = await sessionStorage.getItem('project_id')
        if (getSession_Project_Id !== null) {
            await this.setState({
                project_id: getSession_Project_Id,
            })
        }
        let getSession_Kol_Unique_Id = await sessionStorage.getItem('kol_unique_id')
        if (getSession_Kol_Unique_Id !== null) {
            await this.setState({
                kol_unique_id: getSession_Kol_Unique_Id,
            })
        }
        let getSession_Kol_Status = await sessionStorage.getItem('kol_status')
        if (getSession_Kol_Status !== null) {
            await this.setState({
                kol_status: getSession_Kol_Status,
            })
        }
        console.log("getSession_Researcher_Name ===== ", getSession_Researcher_Name)
        console.log("getSession_Emp_Unique_Id ===== ", getSession_Emp_Unique_Id)
        console.log("getSession_Sr_No ===== ", getSession_Sr_No)
        console.log("getSession_Kol_Status ===== ", getSession_Kol_Status)
        console.log("getSession_Kol_Unique_Id ===== ", getSession_Kol_Unique_Id)

        const options = {
            method: 'POST',
        }
        //  Speciality type data
        fetch(global.websiteUrl+'/service/Speciality/get_SpecialityList', options)
            .then((Response) => Response.json())
            .then((findresponse) => {
                console.log(findresponse)
                var testDataSpeciality = findresponse.data;
                console.log("testDataSpeciality", JSON.stringify(testDataSpeciality));
                this.setState({
                    SpecialityData: findresponse.data,
                })
            })

        // Country List
        fetch(global.websiteUrl+'/service/Location/get_CountryList', options)
            .then((Response) => Response.json())
            .then((findresponse) => {
                console.log(findresponse)
                var TestCountryList = findresponse.Country_Details;
                // var TestKolIddata= findresponse.kol_system_id;
                console.log("TestCountryList", JSON.stringify(TestCountryList));
                // console.log("TestKolIddata",JSON.stringify(TestKolIddata));
                this.setState({
                    CountryListData: findresponse.Country_Details,
                })
            })


        const url = global.websiteUrl+'/service/KOL/get_KOLList';
        let formData = new FormData();
        // formData.append('kol_id','CHCUI0026');
        // formData.append('kol_id',this.props.location.state.kol_unique_id);
        formData.append('kol_id', getSession_Kol_Unique_Id);
        formData.append('project_id', getSession_Project_Id);
        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }

        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var lgdata = response.data;
                console.log("lgdata=====", JSON.stringify(lgdata));
                var KOL_Personal_Info = response.data.KOL_Personal_Info;
                console.log("KOL_Personal_Info==========", JSON.stringify(KOL_Personal_Info));
                var KOL_Work_Details = response.data.KOL_Work_Details;
                console.log("KOL_Work_Details==========", JSON.stringify(KOL_Work_Details));
                this.setState({
                    PersonalInformation: KOL_Personal_Info,
                    WorkDetails: response.data.KOL_Work_Details,
                    KOl_Identifier: response.data.KOL_Identifiers,
                    Biography: response.data.KOL_Biography,
                    ProfessionalMemberships: response.data.KOL_Professional_Memberships,
                    Education: response.data.KOL_Education,
                    AdvisoryBoard: response.data.KOL_Advisory_Board,
                    AwardsHonours: response.data.KOL_Awards_Honours,
                    BooksMonographs: response.data.KOL_Books_Monographs,
                    Commitees: response.data.KOL_Commitees,
                    ClinicalTrialDetails: response.data.KOL_Clinical_Trial_Details,
                    EventsDetails: response.data.KOL_Events_Details,
                    Grants: response.data.KOL_Grants,
                    Guidelines: response.data.KOL_Guidelines,
                    HospitalAffiliations: response.data.KOL_Hospital_Affiliations,
                    Interactions: response.data.KOL_Interactions,
                    PressItem: response.data.KOL_Press_Item,
                    Presentation: response.data.KOL_Presentations,
                    PublicationDetails: response.data.KOL_Publication_Details,
                    SunshinePayment: response.data.KOL_Sunshine_Payment,

                    profile_photo_for_edit: response.data.KOL_Profile_Photo,
                    BiographyResume_edit: response.data.KOL_Resume,
                    BiographyResumeFileName: response.data.KOL_Resume,

                    profile_photo: response.data.KOL_Profile_Photo,
                    // BiographyResume: response.data.KOL_Resume, 

                })

                var ContractFileNameapi = this.state.PersonalInformation.contract_file

                this.setState({
                    ShowBar: false,
                    ContractFileName: ContractFileNameapi
                })
                console.log("PersonalInformation", this.state.PersonalInformation.doctor_full_name);
                console.log("Education", this.state.Education);
                console.log("WorkDetails===============", this.state.WorkDetails);
                console.log("BiographyResume========", this.state.BiographyResume);
                console.log("BiographyResumeFileName========", this.state.BiographyResumeFileName);
                console.log("ContractFileName-------========", this.state.ContractFileName);

                /// for duplicate check
               // alert(this.state.PersonalInformation.doctor_email);

               let { PersonalInformation ,checkDuplicate} = this.state;
               PersonalInformation.doctor_email = this.state.PersonalInformation.doctor_email;
               checkDuplicate.doctor_email = this.state.PersonalInformation.doctor_email;
               this.setState({ PersonalInformation,checkDuplicate });



                // Contract File Name last only
                if (this.state.ContractFileName !== null) {
                    console.log("in Contract name ")
                    var contractname = this.state.ContractFileName
                    console.log('contractname::::::::::::::', contractname);
                    var finalcontractname = contractname.substr(contractname.lastIndexOf('/') + 1);
                    this.setState({
                        FinalContractName: finalcontractname,
                    })
                    console.log("FinalContractName", this.state.FinalContractName)
                }

                // biography file name last only
                if (this.state.BiographyResumeFileName !== null) {
                    console.log("in resume name ")
                    var resumename = this.state.BiographyResumeFileName
                    console.log('resumename::::::::::::::', resumename);
                    var finalResumeName = resumename.substr(resumename.lastIndexOf('/') + 1);
                    this.setState({
                        FinalResumeName: finalResumeName,
                    })
                    console.log("finalResumeName", finalResumeName)
                }

                if (this.state.PersonalInformation.salutation_links != '') {
                    this.setState({
                        itemsSalutation: this.state.PersonalInformation.salutation_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.doctor_full_name_links != '') {
                    this.setState({
                        itemsDoctorFullName: this.state.PersonalInformation.doctor_full_name_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.first_name_links != '') {
                    this.setState({
                        itemsFirstName: this.state.PersonalInformation.first_name_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.middle_name_links != '') {
                    this.setState({
                        itemsMiddleName: this.state.PersonalInformation.middle_name_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.last_name_links != '') {
                    this.setState({
                        itemsLastName: this.state.PersonalInformation.last_name_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.suffix_links != '') {
                    this.setState({
                        itemssuffix: this.state.PersonalInformation.suffix_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.expertise_links != '') {
                    this.setState({
                        itemsexpertise: this.state.PersonalInformation.expertise_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.qualification_links != '') {
                    this.setState({
                        itemsQualification: this.state.PersonalInformation.qualification_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.experience_links != '') {
                    this.setState({
                        itemsExperience: this.state.PersonalInformation.experience_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.medical_schools_links != '') {
                    this.setState({
                        itemsMedicalSchool: this.state.PersonalInformation.medical_schools_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.medical_registration_no_links != '') {
                    this.setState({
                        itemsMedicalRegisterNo: this.state.PersonalInformation.medical_registration_no_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.NPI_no_links != '') {
                    this.setState({
                        itemsNpiNo: this.state.PersonalInformation.NPI_no_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.brand_links != '') {
                    this.setState({
                        itemsBrand: this.state.PersonalInformation.brand_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.specialty_links != '') {
                    this.setState({
                        itemSpeciality: this.state.PersonalInformation.specialty_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.specialty2_links != '') {
                    this.setState({
                        itemSpeciality2: this.state.PersonalInformation.specialty2_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.procedure_type_links != '') {
                    this.setState({
                        itemsProcedure: this.state.PersonalInformation.procedure_type_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.sub_specialty_links != '') {
                    this.setState({
                        itemsSubSpeciality: this.state.PersonalInformation.sub_specialty_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.doctor_contact_links != '') {
                    this.setState({
                        itemsDoctorContact: this.state.PersonalInformation.doctor_contact_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.doctor_email_links != '') {
                    this.setState({
                        itemsDoctorEmail: this.state.PersonalInformation.doctor_email_links.split(','),
                    })
                }
                if (this.state.PersonalInformation.doctor_email2_links != '') {
                    this.setState({
                        itemsDoctorEmail2: this.state.PersonalInformation.doctor_email2_links.split(','),
                    })
                }
                if (this.state.PersonalInformation.fax_links != '') {
                    this.setState({
                        itemsFax: this.state.PersonalInformation.fax_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.linked_in_profile_links != '') {
                    this.setState({
                        itemsLinkedIn: this.state.PersonalInformation.linked_in_profile_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.twitter_handle_links != '') {
                    this.setState({
                        itemsTwitter: this.state.PersonalInformation.twitter_handle_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.assistance_contact_no_links != '') {
                    this.setState({
                        itemsAssistanceContact: this.state.PersonalInformation.assistance_contact_no_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.assistance_email_links != '') {
                    this.setState({
                        itemsAssistanceEmail: this.state.PersonalInformation.assistance_email_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.website_links != '') {
                    this.setState({
                        itemsWebsite: this.state.PersonalInformation.website_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.country_links != '') {
                    this.setState({
                        itemsCountry: this.state.PersonalInformation.country_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.state_links != '') {
                    this.setState({
                        itemsState: this.state.PersonalInformation.state_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.city_links != '') {
                    this.setState({
                        itemsCity: this.state.PersonalInformation.city_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.address_links != '') {
                    this.setState({
                        itemsAddress1: this.state.PersonalInformation.address_links.split(','),
                    })
                }
                if (this.state.PersonalInformation.address_2links != '') {
                    this.setState({
                        itemsAddress2: this.state.PersonalInformation.address_2links.split(','),
                    })
                }
                if (this.state.PersonalInformation.procedure_potential_links != '') {
                    this.setState({
                        itemsProcedurePotential: this.state.PersonalInformation.procedure_potential_links.split(','),
                    })
                }

                if (KOL_Personal_Info.country_id) {
                    if (KOL_Personal_Info.country_id !== '0') {
                        const url = global.websiteUrl+'/service/Location/get_StateList';
                        let formData = new FormData();
                        formData.append('country_id', this.state.PersonalInformation.country_id);

                        const config = {
                            //   headers: { 'content-type': 'multipart/form-data' }
                        }
                        axios.post(url, formData, config)
                            .then(response => {
                                console.log(response);
                                var testStateData = response.data.Country_Details;
                                console.log("testStateData", JSON.stringify(testStateData));
                                this.setState({
                                    StateData: response.data.Country_Details,
                                })
                                console.log("StateData======", this.state.StateData)
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }
                }

                if (KOL_Personal_Info.country_id) {
                    if (KOL_Personal_Info.country_id !== '0') {
                        const url = global.websiteUrl+'/service/Location/get_CityList';
                        let formData = new FormData();
                        formData.append('country_id', this.state.PersonalInformation.country_id);

                        const config = {
                            //   headers: { 'content-type': 'multipart/form-data' }
                        }
                        axios.post(url, formData, config)
                            .then(response => {
                                console.log(response);
                                var testCityData = response.data.Country_Details;
                                console.log("testCityData", JSON.stringify(testCityData));
                                this.setState({
                                    CityData: response.data.Country_Details,
                                })
                                console.log("CityData======", this.state.CityData)
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }
                }

                if (KOL_Personal_Info.specialty_id) {
                    console.log("in Procedure list");
                    console.log("speciality_type_id", this.state.PersonalInformation.specialty_id);
                    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
                    let formData = new FormData();
                    formData.append('speciality_type_id', this.state.PersonalInformation.specialty_id);
                    formData.append('search_type', "Procedure");

                    const config = {
                        //   headers: { 'content-type': 'multipart/form-data' }
                    }
                    axios.post(url, formData, config)
                        .then(response => {
                            console.log(response);
                            var proceduredata = response.data;
                            console.log("proceduredata", JSON.stringify(proceduredata));
                            this.setState({
                                ProcedureData: response.data.data,
                                showProcedureSubSpecialityDropdown: false,
                                showProcedureSubSpecialityDropdownAdvBoard: false,
                                showProcedureSubSpecialityDropdownClinicalTrial: false,
                                showProcedureSubSpecialityDropdownEventDetails: false,
                                showProcedureSubSpecialityDropdownGuidelines: false,
                                showProcedureSubSpecialityDropdownPublicationDetails: false,
                            })
                            console.log("ProcedureData", this.state.ProcedureData)
                            this.setState({
                                showProcedureSubSpecialityDropdown: true,
                                showProcedureSubSpecialityDropdownAdvBoard: true,
                                showProcedureSubSpecialityDropdownClinicalTrial: true,
                                showProcedureSubSpecialityDropdownEventDetails: true,
                                showProcedureSubSpecialityDropdownGuidelines: true,
                                showProcedureSubSpecialityDropdownPublicationDetails: true,
                            })
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }

                if (KOL_Personal_Info.specialty_id) {
                    console.log("in sub speciality list");
                    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
                    let formData = new FormData();
                    formData.append('speciality_type_id', this.state.PersonalInformation.specialty_id);
                    formData.append('search_type', "Sub Speciality");

                    const config = {
                        //   headers: { 'content-type': 'multipart/form-data' }
                    }
                    axios.post(url, formData, config)
                        .then(response => {
                            console.log(response);
                            var SubSpecialitydata = response.data;
                            console.log("SubSpecialitydata", JSON.stringify(SubSpecialitydata));
                            this.setState({
                                SubSpecialitydata: response.data.data,
                                showProcedureSubSpecialityDropdown: false,
                            })
                            console.log("SubSpecialitydata", this.state.SubSpecialitydata)
                            this.setState({
                                showProcedureSubSpecialityDropdown: true,
                            })
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }

                // to convert procedure id strings into array
                var procedureDefault = KOL_Personal_Info.procedure_type_id
                console.log("procedureDefault", procedureDefault);
                var arrayProcedureDefault = procedureDefault.split(',');
                console.log("arrayProcedureDefault", arrayProcedureDefault);

                arrayProcedureDefault.map(async (data) => {
                    if (data !== "") {
                        var joined = this.state.ProcedureType.concat(data);
                        await this.setState({
                            ProcedureType: joined
                        });
                        console.log("ProcedureTypeDefaultValues", this.state.ProcedureTypeDefaultValues)
                    }
                })

                // to convert sub speciality id strings into array
                var subSpecialityDefault = KOL_Personal_Info.sub_specialty_id
                console.log("subSpecialityDefault", subSpecialityDefault);
                var arraysubSpecialityDefault = subSpecialityDefault.split(',');
                console.log("arraysubSpecialityDefault", arraysubSpecialityDefault);

                arraysubSpecialityDefault.map(async (data) => {
                    if (data !== "") {
                        var joined = this.state.SubSpecialityType.concat(data);
                        await this.setState({
                            SubSpecialityType: joined
                        });
                        console.log("SubSpecialityTypeDefaultValues", this.state.SubSpecialityTypeDefaultValues)
                    }
                })


            })

    }

    // Functions for add source variable toggle
    SalutaionAddSourceFun = async () => {
        await this.setState({
            SalutationAddSource: !this.state.SalutationAddSource,
        })
    }
    DFullNameAddSourceFun = async () => {
        await this.setState({
            DFullNameAddSource: !this.state.DFullNameAddSource,
        })
    }
    FirstNameAddSourceFun = async () => {
        await this.setState({
            FirstNameAddSource: !this.state.FirstNameAddSource,
        })
    }
    MiddleNameAddSourceFun = async () => {
        await this.setState({
            MiddleNameAddSource: !this.state.MiddleNameAddSource,
        })
    }
    LastNameAddSourceFun = async () => {
        await this.setState({
            LastNameAddSource: !this.state.LastNameAddSource,
        })
    }

    suffixAddSourceFun = async () => {
        await this.setState({
            suffixAddSource: !this.state.suffixAddSource,
        })
    }

    expertiseAddSourceFun = async () => {
        await this.setState({
            expertiseAddSource: !this.state.expertiseAddSource,
        })
    }

    QualificationAddSourceFun = async () => {
        await this.setState({
            QualificationAddSource: !this.state.QualificationAddSource,
        })
    }
    ExperienceAddSourceFun = async () => {
        await this.setState({
            ExperienceAddSource: !this.state.ExperienceAddSource,
        })
    }
    MedicalSchoolsAddSourceFun = async () => {
        await this.setState({
            MedicalSchoolsAddSource: !this.state.MedicalSchoolsAddSource,
        })
    }
    MedicalRegistrationNoAddSourceFun = async () => {
        await this.setState({
            MedicalRegistrationNoAddSource: !this.state.MedicalRegistrationNoAddSource,
        })
    }
    NPINoAddSourceFun = async () => {
        await this.setState({
            NPINoAddSource: !this.state.NPINoAddSource,
        })
    }
    SpecialityAddSourceFun = async () => {
        await this.setState({
            SpecialityAddSource: !this.state.SpecialityAddSource,
        })
    }
    Speciality2AddSourceFun = async () => {
        await this.setState({
            Speciality2AddSource: !this.state.Speciality2AddSource,
        })
    }

    SubSpecialityTypeAddSourceFun = async () => {
        await this.setState({
            SubSpecialityTypeAddSource: !this.state.SubSpecialityTypeAddSource,
        })
    }
    ProcedureTypeAddSourceFun = async () => {
        await this.setState({
            ProcedureTypeAddSource: !this.state.ProcedureTypeAddSource,
        })
    }
    DoctorContactNoAddSourceFunc = async () => {
        await this.setState({
            DoctorContactNoAddSource: !this.state.DoctorContactNoAddSource,
        })
    }
    DoctorEmailAddSourceFun = async () => {
        await this.setState({
            DoctorEmailAddSource: !this.state.DoctorEmailAddSource,
        })
    }

    DoctorEmail2AddSourceFun = async () => {
        await this.setState({
            DoctorEmail2AddSource: !this.state.DoctorEmail2AddSource,
        })
    }

    FaxAddSourceFun = async () => {
        await this.setState({
            FaxAddSource: !this.state.FaxAddSource,
        })
    }
    LinkedInProfileAddSourceFun = async () => {
        await this.setState({
            LinkedInProfileAddSource: !this.state.LinkedInProfileAddSource,
        })
    }
    TwitterHandleAddSourceFun = async () => {
        await this.setState({
            TwitterHandleAddSource: !this.state.TwitterHandleAddSource,
        })
    }
    AssistanceContactNoAddSourceFunc = async () => {
        await this.setState({
            AssistanceContactNoAddSource: !this.state.AssistanceContactNoAddSource,
        })
    }
    AssistanceEmailAddSourceFun = async () => {
        await this.setState({
            AssistanceEmailAddSource: !this.state.AssistanceEmailAddSource,
        })
    }
    WebsiteAddSourceFun = async () => {
        await this.setState({
            WebsiteAddSource: !this.state.WebsiteAddSource,
        })
    }
    CountryAddSourceFun = async () => {
        await this.setState({
            CountryAddSource: !this.state.CountryAddSource,
        })
    }
    StateAddSourceFun = async () => {
        await this.setState({
            StateAddSource: !this.state.StateAddSource,
        })
    }

    CityAddSourceFun = async () => {
        await this.setState({
            CityAddSource: !this.state.CityAddSource,
        })
    }

    
    Address1AddSourceFun = async () => {
        await this.setState({
            Address1AddSource: !this.state.Address1AddSource,
        })
    }


    Address2AddSourceFun = async () => {
        await this.setState({
            Address2AddSource: !this.state.Address2AddSource,
        })
    }


    PlaceOfWorkAddSourceFun = async () => {
        await this.setState({
            PlaceOfWorkAddSource: !this.state.PlaceOfWorkAddSource,
        })
    }
    DesignationAddSourceFun = async () => {
        await this.setState({
            DesignationAddSource: !this.state.DesignationAddSource,
        })
    }
    DepartmentAddSourceFun = async () => {
        await this.setState({
            DepartmentAddSource: !this.state.DepartmentAddSource,
        })
    }


    // Personal Information Add Source Funs starts here
    handleChangePersonalInfoAddSource = (evt) => {
        this.setState({
            valuePersonalInfoAddSource: evt.target.value,
            error: null
        });
    };

    handleKeyDownSalutation = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsSalutation: [...this.state.itemsSalutation, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsSalutation.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.salutation_links = output.join(",");
                await this.setState({ PersonalInformation });

                console.log("check this", this.state.PersonalInformation)
                console.log(output.join(","))
            }
        }
    };
    handleDeleteSalutation = async (item) => {
        await this.setState({
            itemsSalutation: this.state.itemsSalutation.filter((i) => i !== item)
        });
        var output = this.state.itemsSalutation.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.salutation_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownDoctorFullName = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsDoctorFullName: [...this.state.itemsDoctorFullName, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsDoctorFullName.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.doctor_full_name_links = output.join(",");
                await this.setState({ PersonalInformation });

                console.log("check this", this.state.PersonalInformation)
                console.log(output.join(","))
            }
        }
    };
    handleDeleteDoctorFullName = async (item) => {
        await this.setState({
            itemsDoctorFullName: this.state.itemsDoctorFullName.filter((i) => i !== item)
        });
        var output = this.state.itemsDoctorFullName.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.doctor_full_name_links = output.join(",");
        await this.setState({ PersonalInformation });

        console.log("check this", this.state.PersonalInformation.doctor_full_name_links)
        console.log(output.join(","))
        console.log("items", this.state.items)
    };

    handleKeyDownFirstName = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsFirstName: [...this.state.itemsFirstName, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsFirstName.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.first_name_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteFirstName = async (item) => {
        await this.setState({
            itemsFirstName: this.state.itemsFirstName.filter((i) => i !== item)
        });
        var output = this.state.itemsFirstName.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.first_name_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownMiddleName = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsMiddleName: [...this.state.itemsMiddleName, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsMiddleName.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.middle_name_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteMiddleName = async (item) => {
        await this.setState({
            itemsMiddleName: this.state.itemsMiddleName.filter((i) => i !== item)
        });
        var output = this.state.itemsMiddleName.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.middle_name_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownLastName = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsLastName: [...this.state.itemsLastName, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsLastName.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.last_name_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteLastName = async (item) => {
        await this.setState({
            itemsLastName: this.state.itemsLastName.filter((i) => i !== item)
        });
        var output = this.state.itemsLastName.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.last_name_links = output.join(",");
        await this.setState({ PersonalInformation });
    };




    
    handleKeyDownsuffix = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemssuffix: [...this.state.itemssuffix, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemssuffix.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.suffix_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeletesuffix = async (item) => {
        await this.setState({
            itemssuffix: this.state.itemssuffix.filter((i) => i !== item)
        });
        var output = this.state.itemssuffix.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.suffix_links = output.join(",");
        await this.setState({ PersonalInformation });
    };


    handleKeyDownexpertise = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsexpertise: [...this.state.itemsexpertise, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsexpertise.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.expertise_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteexpertise = async (item) => {
        await this.setState({
            itemsexpertise: this.state.itemsexpertise.filter((i) => i !== item)
        });
        var output = this.state.itemsexpertise.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.expertise_links = output.join(",");
        await this.setState({ PersonalInformation });
    };




    handleKeyDownQualification = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsQualification: [...this.state.itemsQualification, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsQualification.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.qualification_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteQualification = async (item) => {
        await this.setState({
            itemsQualification: this.state.itemsQualification.filter((i) => i !== item)
        });
        var output = this.state.itemsQualification.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.qualification_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownExperience = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsExperience: [...this.state.itemsExperience, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsExperience.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.experience_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteExperience = async (item) => {
        await this.setState({
            itemsExperience: this.state.itemsExperience.filter((i) => i !== item)
        });
        var output = this.state.itemsExperience.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.experience_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownMedicalSchool = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsMedicalSchool: [...this.state.itemsMedicalSchool, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsMedicalSchool.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.medical_schools_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteMedicalSchool = async (item) => {
        await this.setState({
            itemsMedicalSchool: this.state.itemsMedicalSchool.filter((i) => i !== item)
        });
        var output = this.state.itemsMedicalSchool.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.medical_schools_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownMedicalMedicalRegister = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsMedicalRegisterNo: [...this.state.itemsMedicalRegisterNo, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsMedicalRegisterNo.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.medical_registration_no_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteMedicalRegister = async (item) => {
        await this.setState({
            itemsMedicalRegisterNo: this.state.itemsMedicalRegisterNo.filter((i) => i !== item)
        });
        var output = this.state.itemsMedicalRegisterNo.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.medical_registration_no_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownNpiNo = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsNpiNo: [...this.state.itemsNpiNo, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsNpiNo.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.NPI_no_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteNpiNo = async (item) => {
        await this.setState({
            itemsNpiNo: this.state.itemsNpiNo.filter((i) => i !== item)
        });
        var output = this.state.itemsNpiNo.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.NPI_no_links = output.join(",");
        await this.setState({ PersonalInformation });
    };
    handleKeyDownSpeciality = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemSpeciality: [...this.state.itemSpeciality, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemSpeciality.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.specialty_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };

    handleKeyDownSpeciality2 = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemSpeciality2: [...this.state.itemSpeciality2, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemSpeciality2.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.specialty2_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };

    handleDeleteSpeciality = async (item) => {
        await this.setState({
            itemSpeciality: this.state.itemSpeciality.filter((i) => i !== item)
        });
        var output = this.state.itemSpeciality.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.specialty_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleDeleteSpeciality2 = async (item) => {
        await this.setState({
            itemSpeciality2: this.state.itemSpeciality2.filter((i) => i !== item)
        });
        var output = this.state.itemSpeciality2.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.specialty2_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownSubSpeciality = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsSubSpeciality: [...this.state.itemsSubSpeciality, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsSubSpeciality.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.sub_specialty_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteSubspeciality = async (item) => {
        await this.setState({
            itemsSubSpeciality: this.state.itemsSubSpeciality.filter((i) => i !== item)
        });
        var output = this.state.itemsSubSpeciality.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.sub_specialty_links = output.join(",");
        await this.setState({ PersonalInformation });
    };
    handleKeyDownProcedure = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsProcedure: [...this.state.itemsProcedure, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsProcedure.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.procedure_type_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteProcedure = async (item) => {
        await this.setState({
            itemsProcedure: this.state.itemsProcedure.filter((i) => i !== item)
        });
        var output = this.state.itemsProcedure.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.procedure_type_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownDoctorsContact = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsDoctorContact: [...this.state.itemsDoctorContact, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsDoctorContact.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.doctor_contact_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteDoctorsContact = async (item) => {
        await this.setState({
            itemsDoctorContact: this.state.itemsDoctorContact.filter((i) => i !== item)
        });
        var output = this.state.itemsDoctorContact.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.doctor_contact_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownDoctorsEmail = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsDoctorEmail: [...this.state.itemsDoctorEmail, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsDoctorEmail.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.doctor_email_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };

    handleKeyDownDoctorsEmail2 = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsDoctorEmail2: [...this.state.itemsDoctorEmail2, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsDoctorEmail2.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.doctor_email2_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };

    handleDeleteDoctorsEmail = async (item) => {
        await this.setState({
            itemsDoctorEmail: this.state.itemsDoctorEmail.filter((i) => i !== item)
        });
        var output = this.state.itemsDoctorEmail.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.doctor_email_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleDeleteDoctorsEmail2 = async (item) => {
        await this.setState({
            itemsDoctorEmail2: this.state.itemsDoctorEmail2.filter((i) => i !== item)
        });
        var output = this.state.itemsDoctorEmail2.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.doctor_email2_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownFax = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsFax: [...this.state.itemsFax, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsFax.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.fax_links = output.join(",");
                await this.setState({ PersonalInformation });

                console.log("check this", this.state.PersonalInformation)
                console.log(output.join(","))
            }
        }
    };
    handleDeleteFax = async (item) => {
        await this.setState({
            itemsFax: this.state.itemsFax.filter((i) => i !== item)
        });
        var output = this.state.itemsFax.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.fax_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownLinkedIn = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsLinkedIn: [...this.state.itemsLinkedIn, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsLinkedIn.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.linked_in_profile_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteLinkedIn = async (item) => {
        await this.setState({
            itemsLinkedIn: this.state.itemsLinkedIn.filter((i) => i !== item)
        });
        var output = this.state.itemsLinkedIn.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.linked_in_profile_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownTwitter = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsTwitter: [...this.state.itemsTwitter, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsTwitter.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.twitter_handle_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteTwitter = async (item) => {
        await this.setState({
            itemsTwitter: this.state.itemsTwitter.filter((i) => i !== item)
        });
        var output = this.state.itemsTwitter.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.twitter_handle_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownAssisContact = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsAssistanceContact: [...this.state.itemsAssistanceContact, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsAssistanceContact.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.assistance_contact_no_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteAssisContact = async (item) => {
        await this.setState({
            itemsAssistanceContact: this.state.itemsAssistanceContact.filter((i) => i !== item)
        });
        var output = this.state.itemsAssistanceContact.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.assistance_contact_no_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownAssisEmail = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsAssistanceEmail: [...this.state.itemsAssistanceEmail, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsAssistanceEmail.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.assistance_email_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteAssisEmail = async (item) => {
        await this.setState({
            itemsAssistanceEmail: this.state.itemsAssistanceEmail.filter((i) => i !== item)
        });
        var output = this.state.itemsAssistanceEmail.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.assistance_email_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownWebsite = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsWebsite: [...this.state.itemsWebsite, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsWebsite.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.website_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteWebsite = async (item) => {
        await this.setState({
            itemsWebsite: this.state.itemsWebsite.filter((i) => i !== item)
        });
        var output = this.state.itemsWebsite.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.website_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownCountry = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsCountry: [...this.state.itemsCountry, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsCountry.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.country_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteCountry = async (item) => {
        await this.setState({
            itemsCountry: this.state.itemsCountry.filter((i) => i !== item)
        });
        var output = this.state.itemsCountry.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.country_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownState = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsState: [...this.state.itemsState, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsState.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.state_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };


    handleKeyDownCity = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsCity: [...this.state.itemsCity, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsCity.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.city_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };


    handleDeleteCity = async (item) => {
        await this.setState({
            itemsCity: this.state.itemsCity.filter((i) => i !== item)
        });
        var output = this.state.itemsCity.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.city_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleDeleteState = async (item) => {
        await this.setState({
            itemsState: this.state.itemsState.filter((i) => i !== item)
        });
        var output = this.state.itemsState.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.state_links = output.join(",");
        await this.setState({ PersonalInformation });
    };


    

    handleKeyDownAddress1 = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsAddress1: [...this.state.itemsAddress1, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsAddress1.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.address_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteAddress1 = async (item) => {
        await this.setState({
            itemsAddress1: this.state.itemsAddress1.filter((i) => i !== item)
        });
        var output = this.state.itemsAddress1.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.address_links = output.join(",");
        await this.setState({ PersonalInformation });
    };



    handleKeyDownAddress2 = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsAddress2: [...this.state.itemsAddress2, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsAddress2.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.address_2links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteAddress2 = async (item) => {
        await this.setState({
            itemsAddress2: this.state.itemsAddress2.filter((i) => i !== item)
        });
        var output = this.state.itemsAddress2.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.address_2links = output.join(",");
        await this.setState({ PersonalInformation });
    };


    //  to set values of Work Details array
    handleChangeWorkDetails(i, e) {
        console.log(i);
        console.log(e.target);
        const { name, value } = e.target;
        let WorkDetails = [...this.state.WorkDetails];
        WorkDetails[i] = { ...WorkDetails[i], [name]: value };
        this.setState({ WorkDetails });
    }

    removeClickWorkDetails(i) {
        let WorkDetails = [...this.state.WorkDetails];
        WorkDetails.splice(i, 1);
        this.setState({ WorkDetails });
    }

    addClickWorkDetails = async () => {
        await this.setState(prevState => ({
            WorkDetails: [...prevState.WorkDetails, {
                // kol_id: this.props.location.state.emp_unique_id,
                kol_id: sessionStorage.getItem('emp_unique_id'),
                current_place_of_work: '',
                current_place_of_work_links: '',
                current_place_of_work_comment: '',
                current_place_of_work_status: '',
                designation: '',
                designation_links: '',
                designation_comment: '',
                designation_status: '',
                department: '',
                department_links: '',
                department_comment: '',
                department_status: '',
            }]
        }))

        this.setState({ items: [] })
    }

    handleChangeWorkingDetailsAddSource = (evt) => {
        // console.log("hdl : "+evt.target.value)
        this.setState({
            value: evt.target.value,
            error: null
        });
    };

    handleKeyDownWorkingDetails = async (evt, index, name) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();
            console.log('name', name)
            console.log('index', index)

            var value = this.state.value.trim();
            if (value !== '') {
                console.log("ite input : " + JSON.stringify(this.state.WorkDetails[index].current_place_of_work_links))
                // organization_type_links
                //    if (value && this.isValid(value)) {
                if (name == 'current_place_of_work_links') {
                    await this.setState({
                        items: [...this.state.WorkDetails[index].current_place_of_work_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'designation_links') {
                    await this.setState({
                        items: [...this.state.WorkDetails[index].designation_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'department_links') {
                    await this.setState({
                        items: [...this.state.WorkDetails[index].department_links.split(","), this.state.value],
                        value: ""
                    });
                }

                console.log("key d : " + JSON.stringify(this.state.items.toString()))

                let list = [...this.state.WorkDetails];
                list[index] = { ...list[index], [name]: this.state.items.toString() };
                //  console.log("list : "+JSON.stringify(list));
                this.setState({ WorkDetails: list });
            }
        }
    }

    handleDeleteWorkingDetails = async (array, item, index, name) => {
        console.log('in del : ' + JSON.stringify(array));
        await this.setState({
            items: array.flat().filter((i) => i !== item)
        });
        let list = [...this.state.WorkDetails];
        list[index] = { ...list[index], [name]: this.state.items.toString() };
        console.log("list : " + JSON.stringify(list));
        this.setState({ WorkDetails: list });
    }




    // To get procedure 
    getProcedureList = () => {
        console.log("in Procedure list");
        console.log("speciality_type_id", this.state.PersonalInformation.specialty_id);


        const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
        let formData = new FormData();
        formData.append('speciality_type_id', this.state.PersonalInformation.specialty_id);
        formData.append('search_type', "Procedure");

        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var proceduredata = response.data;
                console.log("proceduredata", JSON.stringify(proceduredata));
                // this.setState({
                //    ProcedureData : response.data.data,
                // }) 
                if (response.data.status == true) {
                    this.setState({
                        ProcedureData: response.data.data,
                    })
                }
                else if (response.data.status == false) {
                    this.setState({
                        ProcedureData: [],
                    })
                }
                console.log("ProcedureData", this.state.ProcedureData)
            })
            .catch(error => {
                console.log(error);
            });
    }

    // To get sub speciality list 
    getSubSpecialityList = () => {
        console.log("in sub speciality list");
        const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
        let formData = new FormData();
        formData.append('speciality_type_id', this.state.PersonalInformation.specialty_id);
        formData.append('search_type', "Sub Speciality");

        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var SubSpecialitydata = response.data;
                console.log("SubSpecialitydata", JSON.stringify(SubSpecialitydata));
                // this.setState({
                //    SubSpecialitydata : response.data.data,
                // }) 
                if (response.data.status == true) {
                    this.setState({
                        SubSpecialitydata: response.data.data,
                    })
                }
                else if (response.data.status == false) {
                    this.setState({
                        SubSpecialitydata: [],
                    })
                }
                console.log("SubSpecialitydata", this.state.SubSpecialitydata)
            })
            .catch(error => {
                console.log(error);
            });
    }

    ProcedureDropdown = async () => {
        console.log("n procedure dropdown")
        await this.setState({ showProcedureSubSpecialityDropdown: true })
    }

    getPhoto = event => {
        const filesFormats=["image/jpeg","image/png","image/jpg"];
        const isRightFormat = filesFormats.includes(event.target.files[0].type);
        //alert(event.target.files[0].type);
        console.log(isRightFormat);
        if(isRightFormat)
        {
        
        event.preventDefault();
        
        let reader = new FileReader();
        let file = event.target.files[0];
        // this.setState({file: e.target.files[0],})
        console.log("file my", this.state.profile_photo);
        reader.onloadend = () => {
            this.setState({
                profile_photo: file,
                imagePreviewUrl: reader.result,
                profile_photo_for_edit: '',
                ImageErr:'',
            });
        }
        reader.readAsDataURL(file);

    }
    else
    {
      this.setState({

      ImageErr:'only JPEG , PNG , JPG Allowed',
      });
      //alert('only JPEG , PNG , JPG Allowed');
    }

    }

    // Personal Info Upload Contract
    onUploadContract = async (event) => {
        console.log('Contract file event', event)
        var contract_file2 = event.target.files[0];
        console.log("contract_file2", contract_file2);
        // let { PersonalInformation } = this.state;
        // PersonalInformation.contract_file = event.target.files[0];
        await this.setState({
            // PersonalInformation,
            contract_file: event.target.files[0],
            ContractFileName: event.target.files[0].name
        });
        // console.log("contract file ------",this.state.PersonalInformation.contract_file)
    }

    // To get state list
    getStateList = () => {
        console.log("in state list");
        console.log("country id", this.state.PersonalInformation.country_id)

        const url = global.websiteUrl+'/service/Location/get_StateList';
        let formData = new FormData();
        formData.append('country_id', this.state.PersonalInformation.country_id);

        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var testStateData = response.data.Country_Details;
                console.log("testStateData", JSON.stringify(testStateData));
                // this.setState({
                //    StateData : response.data.Country_Details,
                // }) 
                if (response.data.status == true) {
                    this.setState({
                        StateData: response.data.Country_Details,
                    })
                }
                else if (response.data.status == false) {
                    this.setState({
                        StateData: [],
                    })
                }
                console.log("StateData======", this.state.StateData)
            })
            .catch(error => {
                console.log(error);
            });
    }

    // To get City list
    getCityList = () => {
        console.log("in city list");
        console.log("country id", this.state.PersonalInformation.country_id)

        const url = global.websiteUrl+'/service/Location/get_CityList';
        let formData = new FormData();
        formData.append('country_id', this.state.PersonalInformation.country_id);

        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var testCityData = response.data.Country_Details;
                console.log("testCityData", JSON.stringify(testCityData));
                // this.setState({
                //    CityData : response.data.Country_Details,
                // }) 
                if (response.data.status == true) {
                    this.setState({
                        CityData: response.data.Country_Details,
                    })
                }
                else if (response.data.status == false) {
                    this.setState({
                        CityData: [],
                    })
                }
                console.log("CityData======", this.state.CityData)
            })
            .catch(error => {
                console.log(error);
            });
    }


    // after selection salutation
    afterSelectSalutation = () => {
        console.log("in function yeeeeeeeeeeee")
        console.log("personalinformation.koltype", this.state.PersonalInformation.kol_type)
        console.log("personalinformation.salutation", this.state.PersonalInformation.salutation)

        if (this.state.PersonalInformation.kol_type) {
            if (this.state.PersonalInformation.kol_type == 'Payers Community' ||
                this.state.PersonalInformation.kol_type == 'HTA Experts' ||
                this.state.PersonalInformation.kol_type == 'Hospital Procurement' ||
                this.state.PersonalInformation.kol_type == 'Advocacy Group'
                // this.state.PersonalInformation.kol_type == 'Clinical Commission Group'
            ) {
                if (this.state.PersonalInformation.salutation == 'Mr.' ||
                    this.state.PersonalInformation.salutation == 'Ms.' ||
                    this.state.PersonalInformation.salutation == 'Mrs.' ||
                    this.state.PersonalInformation.salutation == 'Prof.' ||
                    this.state.PersonalInformation.salutation == 'Other') {
                    let { PersonalInformation } = this.state;
                    PersonalInformation.medical_schools = '';
                    PersonalInformation.medical_registration_no = '';
                    PersonalInformation.NPI_no = '';
                    this.setState({ PersonalInformation });
                }
            }
        }
        console.log("personal information after selecting salutation", this.state.PersonalInformation)
    }

    BackToResercherKolList() {
        this.setState({ RedirectResercherKolList: true })
    }

    validateDuplicate = () => {
        let koltypeErr = '';
        let doctorfullnameErr = '';
        let specialityErr = '';
        let proceduretypeErr = '';
        let subspecialitytypeErr = '';
        let countryErr = '';

        if (!this.state.checkDuplicate.kol_type) {
            koltypeErr = 'Please Select Kol Type';
        }
        if (!this.state.checkDuplicate.doctor_full_name) {
            doctorfullnameErr = 'Please Enter Full Name';
        }
        if (!this.state.checkDuplicate.specialty_id) {
            specialityErr = 'Please Select Speciality';
        }
        if (!this.state.checkDuplicate.sub_specialty_id || this.state.SubSpecialityType.length <= 0) {
            subspecialitytypeErr = 'Please Select Sub Speciality';
        }
        if (!this.state.checkDuplicate.procedure_type_id || this.state.ProcedureType.length <= 0) {
            proceduretypeErr = 'Please Select Procedure Type';
        }
        if (!this.state.checkDuplicate.country_id) {
            countryErr = 'Please Select Country';
        }

        if (koltypeErr || doctorfullnameErr || specialityErr || subspecialitytypeErr || proceduretypeErr || countryErr) {
            this.setState({ koltypeErr, doctorfullnameErr, specialityErr, subspecialitytypeErr, proceduretypeErr, countryErr });
            return false;
        }
        return true;
    }

    DuplicateCheckFunc = () => {
        console.log("checkDuplicate:====", this.state.checkDuplicate)
        //const isValid = this.validateDuplicate();
        const isValid =true;
        if (isValid) {
          this.setState({
            ShowProcedure: true,
          })
    
          //alert(this.state);
    
          const url = global.websiteUrl+'/service/KOL/check_KOL_duplicate';
          
          //const url ='http://localhost/Cetas/service/KOL/check_KOL_duplicate';
          let formData = new FormData();
          formData.append('KOl_Info', JSON.stringify(this.state.checkDuplicate));
    
          const config = {
            headers: { 'content-type': 'multipart/form-data' }
          }
          axios.post(url, formData, config)
            .then(response => {
              console.log(response);
              var status = response.data.status;
              console.log("status", status);
              this.setState({
                ShowProcedure: false,
              })
            //  alert(response.data.message);
              if (response.data.status == true) {
                Swal.fire({
                  icon: 'error',
                  text: response.data.message,
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
                //this.setState({ RedirectResercherKolList: true })
              }
              else {
                Swal.fire({
                  icon: 'success',
                 // text: response.data.message,
                 text:'No Duplicate found',
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
                this.setState({
                  ShowDuplicateCheckButton: false,
                  ShowKOLIdentifierButton: true,
                })
              }
            })
            .catch(error => {
              console.log(error);
            });
          this.setState({
            koltypeErr: '',
            doctorfullnameErr: '',
            specialityErr: '',
            proceduretypeErr: '',
            subspecialitytypeErr: '',
            countryErr: '',
          })
        }
        else {
          Swal.fire({
            // info
            icon: 'error',
            text: 'Please Enter All Mandatory Fields',
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }
      }

    // DuplicateCheckFunc = () => {
    //     console.log("checkDuplicate:====", this.state.checkDuplicate)
    //    // const isValid = this.validateDuplicate();
    //    const isValid=true;
    //     if (isValid) {
    //         this.setState({
    //             ShowProcedure: true,
    //         })
    //         const url = global.websiteUrl+'/service/KOL/check_KOL_duplicate';
    //         let formData = new FormData();
    //         formData.append('KOl_Info', JSON.stringify(this.state.checkDuplicate));

    //         const config = {
    //             headers: { 'content-type': 'multipart/form-data' }
    //         }
    //         axios.post(url, formData, config)
    //             .then(response => {
    //                 console.log(response);
    //                 var status = response.data.status;
    //                 console.log("status", status);
    //                 this.setState({
    //                     ShowProcedure: false,
    //                 })

    //                 alert(response.data.status);
    //                 if (response.data.status == true) {
    //                     Swal.fire({
    //                         icon: 'error',
    //                         text: response.data.message,
    //                         confirmButtonColor: '#d33',
    //                         confirmButtonText: 'OK'
    //                     })
    //                     this.setState({ RedirectResercherKolList: true })
    //                 }
    //                 else {
    //                     Swal.fire({
    //                         icon: 'success',
    //                         text: response.data.message,
    //                         confirmButtonColor: '#d33',
    //                         confirmButtonText: 'OK'
    //                     })
    //                     this.setState({
    //                         ShowDuplicateCheckButton: false,
    //                         ShowKOLIdentifierButton: true,
    //                     })
    //                 }
    //             })
    //             .catch(error => {
    //                 console.log(error);
    //             });
    //         this.setState({
    //             koltypeErr: '',
    //             doctorfullnameErr: '',
    //             specialityErr: '',
    //             proceduretypeErr: '',
    //             subspecialitytypeErr: '',
    //             countryErr: '',
    //         })
    //     }
    //     else {
    //         Swal.fire({
    //             // info
    //             icon: 'error',
    //             text: 'Please Enter All Mandatory Fields',
    //             confirmButtonColor: '#d33',
    //             confirmButtonText: 'OK'
    //         })
    //     }
    // }

    validateKOLIdentifier = () => {
        let koltypeErr = '';
        let salutationErr = '';
        let doctorfullnameErr = '';
        let firstnameErr = '';
        let lastnameErr = '';
        let SuffixErr = '';
        let expertiseErr = '';
        let experienceErr = '';
        // let medicalschoolErr = '';
        let specialityErr = '';
        let proceduretypeErr = '';
        let subspecialitytypeErr = '';
        let doctorcontactnoErr = '';
        let doctoremailErr = '';
        let doctoremail2Err = '';
        let countryErr = '';
        let stateErr = '';
        let address1Err = '';
        let zipcodeErr = '';

        let placeofworkErr = '';
        let designationErr = '';
        let departmentErr = '';

        let eventConferencTypeErr = '';
        let eventRoleErr = '';
        let advBoardCompanyTypeErr = '';
        let advBoardRoleErr = '';
        let publicationJournalTypeErr = '';
        let publicationRoleErr = '';
        let ClinicalNoOfTrialLocationErr = '';
        let clinicalTrialTypeErr = '';
        let guidelinesOrgTypeErr = '';

        if (!this.state.PersonalInformation.kol_type) {
            koltypeErr = 'Please Select KOL Type';
        }
        if (!this.state.PersonalInformation.salutation) {
            salutationErr = 'Please Select Salutation';
        }
        if (!this.state.PersonalInformation.doctor_full_name) {
            doctorfullnameErr = 'Please Enter Full Name';
        }
        if (!this.state.PersonalInformation.first_name) {
            firstnameErr = 'Please Enter First Name';
        }
        if (!this.state.PersonalInformation.last_name) {
            lastnameErr = 'Please Enter Last Name';
        }
        if(!this.state.PersonalInformation.suffix)  {
            SuffixErr = 'Please Enter suffix';
         }

        if (!this.state.PersonalInformation.expertise) {
            expertiseErr = 'Please Select Expertise';
        }

        if(this.state.PersonalInformation.kol_type == "KOL Community"){
            if ( !this.state.PersonalInformation.experience) {
              experienceErr = 'Please Select Experience';
            }
        }
        // if(!this.state.PersonalInformation.medical_schools)  {
        //     medicalschoolErr = 'Please Enter Medical School';
        // }
        if (!this.state.PersonalInformation.specialty_id) {
            specialityErr = 'Please Select Speciality';
        }
        if (!this.state.PersonalInformation.procedure_type_id || this.state.ProcedureType.length <= 0) {
            proceduretypeErr = 'Please Select Procedure Type';
        }
        if (!this.state.PersonalInformation.sub_specialty_id || this.state.SubSpecialityType.length <= 0) {
            subspecialitytypeErr = 'Please Select Sub Speciality Type';
        }
        if (!this.state.PersonalInformation.doctor_contact_no) {
            doctorcontactnoErr = 'Please Enter Contact No';
        }
        if (!this.state.PersonalInformation.doctor_email) {
            doctoremailErr = 'Please Enter Email';
        }
        else if (typeof this.state.PersonalInformation.doctor_email !== "undefined") {
            let lastAtPos = this.state.PersonalInformation.doctor_email.lastIndexOf('@');
            let lastDotPos = this.state.PersonalInformation.doctor_email.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0)) {
                //   formIsValid = false;
                doctoremailErr = "Email is not valid";
                if (doctoremailErr) {
                    this.setState({ doctoremailErr })
                }
            } else {
                this.setState({ doctoremailErr: '' })
            }
        }
        else {
            this.setState({ doctoremailErr: '' })
        }

        // if (!this.state.PersonalInformation.doctor_email2) {
        //     doctoremail2Err = 'Please Enter Email';
        // }
        // else 
        if (this.state.PersonalInformation.doctor_email2) {
        if (typeof this.state.PersonalInformation.doctor_email2 !== "undefined") {
            let lastAtPos = this.state.PersonalInformation.doctor_email2.lastIndexOf('@');
            let lastDotPos = this.state.PersonalInformation.doctor_email2.lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0)) {
                //   formIsValid = false;
                doctoremail2Err = "Email is not valid";
                if (doctoremail2Err) {
                    this.setState({ doctoremail2Err })
                }
            } else {
                this.setState({ doctoremail2Err: '' })
            }
        }
        else {
            this.setState({ doctoremail2Err: '' })
        }
    }





        if (!this.state.PersonalInformation.country_id) {
            countryErr = 'Please Select Country';
        }
        if (!this.state.PersonalInformation.state_id) {
            stateErr = 'Please Select State';
        }
        if (!this.state.PersonalInformation.address_1) {
            address1Err = 'Please Enter Address';
        }
        if (!this.state.PersonalInformation.zip_code) {
            zipcodeErr = 'Please Enter Zip Code';
        }

        // Work Details
        {
            this.state.WorkDetails.map((el, i) => {
                if (!el.current_place_of_work) {
                    placeofworkErr = 'Please Enter Current Place of Work';
                }
                if (!el.designation) {
                    designationErr = 'Please Enter Designation';
                }
                // if (!el.department) {
                //     departmentErr = 'Please Enter Department';
                // }
            })
        }

        if (this.state.KOl_Identifier.event_identifier == true) {
            if (this.state.KOl_Identifier.international_event !== true && this.state.KOl_Identifier.national_event !== true) {
                eventConferencTypeErr = 'Please Select At Least One';
            }
            if (this.state.KOl_Identifier.keynote_speaker_event !== true && this.state.KOl_Identifier.regular_event !== true) {
                eventRoleErr = 'Please Select At Least One';
            }
        }

        if (this.state.KOl_Identifier.advisory_identifier == true) {
            if (this.state.KOl_Identifier.international_advisory_board !== true && this.state.KOl_Identifier.national_advisory_board !== true) {
                advBoardCompanyTypeErr = 'Please Select At Least One';
            }
            if (this.state.KOl_Identifier.board_member_advisory_board !== true && this.state.KOl_Identifier.advisory_committee_advisory_board !== true) {
                advBoardRoleErr = 'Please Select At Least One';
            }
        }

        if (this.state.KOl_Identifier.publication_identifier == true) {
            if (this.state.KOl_Identifier.international_publication !== true && this.state.KOl_Identifier.national_publication !== true) {
                publicationJournalTypeErr = 'Please Select At Least One';
            }
            if (this.state.KOl_Identifier.second_author_publication !== true && this.state.KOl_Identifier.last_author_publication !== true
                && this.state.KOl_Identifier.first_author_publication !== true) {
                publicationRoleErr = 'Please Select At Least One';
            }
        }

        if (this.state.KOl_Identifier.clinical_trial_identifier == true) {
            if (this.state.KOl_Identifier.one_trial_location !== true && this.state.KOl_Identifier.ten_trial_location !== true &&
                this.state.KOl_Identifier.fifty_trial_location !== true && this.state.KOl_Identifier.tenthousand_trial_location !== true) {
                ClinicalNoOfTrialLocationErr = 'Please Select At Least One';
            }
            if (this.state.KOl_Identifier.interventional_trial_location !== true && this.state.KOl_Identifier.observational_trial_location !== true) {
                clinicalTrialTypeErr = 'Please Select At Least One';
            }
        }

        if (this.state.KOl_Identifier.guidelines_identifier == true) {
            if (this.state.KOl_Identifier.international_guideline !== true && this.state.KOl_Identifier.national_guideline !== true &&
                this.state.KOl_Identifier.local_guideline !== true) {
                guidelinesOrgTypeErr = 'Please Select At Least One';
            }
        }

        if (koltypeErr || salutationErr || doctorfullnameErr || firstnameErr || lastnameErr || SuffixErr || expertiseErr ||
            experienceErr || specialityErr || proceduretypeErr || subspecialitytypeErr ||
            doctorcontactnoErr || doctoremailErr || doctoremail2Err || countryErr || stateErr || address1Err || zipcodeErr || placeofworkErr ||
            designationErr || departmentErr || eventConferencTypeErr || eventRoleErr || advBoardCompanyTypeErr ||
            advBoardRoleErr || publicationJournalTypeErr || publicationRoleErr || ClinicalNoOfTrialLocationErr ||
            clinicalTrialTypeErr || guidelinesOrgTypeErr) {
            this.setState({
                koltypeErr, salutationErr, doctorfullnameErr, firstnameErr, lastnameErr, SuffixErr, expertiseErr,
                experienceErr, specialityErr, proceduretypeErr, subspecialitytypeErr,
                doctorcontactnoErr, doctoremailErr, doctoremail2Err, countryErr, stateErr, address1Err, zipcodeErr, placeofworkErr,
                designationErr, departmentErr, eventConferencTypeErr, eventRoleErr, advBoardCompanyTypeErr,
                advBoardRoleErr, publicationJournalTypeErr, publicationRoleErr, ClinicalNoOfTrialLocationErr,
                clinicalTrialTypeErr, guidelinesOrgTypeErr
            });
            return false;
        }
        return true;
    }

    KOLIdentifiereFunc = () => {
        console.log("PersonalInformation===", this.state.PersonalInformation)
        console.log("KOl_Identifier===", this.state.KOl_Identifier)
        const isValid = this.validateKOLIdentifier();
        if (isValid) {
            this.setState({
                ShowProcedure: true,
            })
            const url = global.websiteUrl+'/service/KOL/KOL';
            let formData = new FormData();
            formData.append('PersonalInformation', JSON.stringify(this.state.PersonalInformation));
            formData.append('KOl_Identifier', JSON.stringify(this.state.KOl_Identifier));
            formData.append('contract_file', this.state.contract_file);
            formData.append('WorkDetails', JSON.stringify(this.state.WorkDetails));
            formData.append('profile_photo', this.state.profile_photo);
            formData.append('Biography', JSON.stringify(this.state.Biography));
            formData.append('resume', this.state.BiographyResume);
            formData.append('ProfessionalMemberships', JSON.stringify(this.state.ProfessionalMemberships));
            formData.append('Education', JSON.stringify(this.state.Education));
            formData.append('AdvisoryBoard', JSON.stringify(this.state.AdvisoryBoard));
            formData.append('AwardsHonours', JSON.stringify(this.state.AwardsHonours));
            formData.append('BooksMonographs', JSON.stringify(this.state.BooksMonographs));
            formData.append('Commitees', JSON.stringify(this.state.Commitees));
            formData.append('ClinicalTrialDetails', JSON.stringify(this.state.ClinicalTrialDetails));
            formData.append('EventsDetails', JSON.stringify(this.state.EventsDetails));
            formData.append('Grants', JSON.stringify(this.state.Grants));
            formData.append('Guidelines', JSON.stringify(this.state.Guidelines));
            formData.append('HospitalAffiliations', JSON.stringify(this.state.HospitalAffiliations));
            formData.append('Interactions', JSON.stringify(this.state.Interactions));
            formData.append('PressItem', JSON.stringify(this.state.PressItem));
            formData.append('Presentations', JSON.stringify(this.state.Presentation));
            formData.append('PublicationDetails', JSON.stringify(this.state.PublicationDetails));
            formData.append('SunshinePayment', JSON.stringify(this.state.SunshinePayment));
            formData.append('kol_status', "Prelim");
            formData.append('project_id', this.state.project_id);

            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            axios.post(url, formData, config)
                .then(response => {
                    console.log(response);
                    var data = response.data;
                    console.log("data", data);
                    var status = response.data.status;
                    console.log("status", status);
                    this.setState({
                        ShowProcedure: false,
                    })
                    //if (response.data.status == true) {
                    if (response.status === 200) {
                        Swal.fire({
                            icon: 'success',
                            text: response.data.message,
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'OK'
                        })
                        this.setState({ RedirectResercherKolList: true })
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            text: response.data.message,
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'OK'
                        })
                    }
                })
                .catch(error => {
                    console.log(error);
                });
            this.setState({
                koltypeErr: '',
                salutationErr: '',
                doctorfullnameErr: '',
                firstnameErr: '',
                lastnameErr: '',

                SuffixErr: '',
                experienceErr: '',
                // medicalschoolErr: '',
                placeofworkErr: '',
                designationErr: '',
                departmentErr: '',
                specialityErr: '',
                proceduretypeErr: '',
                subspecialitytypeErr: '',
                doctorcontactnoErr: '',
                doctoremailErr: '',
                doctoremail2Err: '',
                countryErr: '',
                stateErr: '',
                address1Err: '',
                zipcodeErr: '',

                eventConferencTypeErr: '',
                eventRoleErr: '',
                advBoardCompanyTypeErr: '',
                advBoardRoleErr: '',
                publicationJournalTypeErr: '',
                publicationRoleErr: '',
                ClinicalNoOfTrialLocationErr: '',
                clinicalTrialTypeErr: '',
                guidelinesOrgTypeErr: '',
            });
        }
        else {
            Swal.fire({
                // info
                icon: 'error',
                text: 'Please Enter All Mandatory Fields',
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
            })
        }

    }

    render() {
        if (this.state.RedirectResercherKolList) {
            return (<Redirect to={{
                pathname: '/ResercheViewKolList',
            }} />)
        }

        let { imagePreviewUrl } = this.state;
        let imagePreview = null;

        if (this.state.profile_photo_for_edit !== 'NULL' && this.state.profile_photo_for_edit) {
            console.log("in profile photo for edit")
            console.log("image is", this.state.profile_photo_for_edit)
            imagePreview = (<div> <img style={{ height: '150px', width: '100px' }} src={this.state.profile_photo_for_edit} /> </div>)
        }
        else
            if (imagePreviewUrl) {
                console.log("in profile photo original")
                imagePreview = (<img style={{ height: '150px', width: '100px' }} src={imagePreviewUrl} />);
            } else {
                console.log("in profile photo not selected")
                imagePreview = (<div className="photo-box"></div>);
            }

        let speciality_type = this.state.SpecialityData;
        let OptionsSpeciality = speciality_type.map((speciality_type) =>
            <option key={speciality_type.id} value={speciality_type.id}>
                {speciality_type.speciality_type}</option>
        );

        let Country_list = this.state.CountryListData;
        let OptionsCountry = Country_list.map((Country_list) =>
            <option key={Country_list.id} value={Country_list.id}>
                {Country_list.country_name}</option>
        );

        let state_list = this.state.StateData;
        let OptionsState = state_list.map((state_list) =>
            <option key={state_list.id} value={state_list.id}>
                {state_list.state_name}</option>
        );

        return (
            <body>
                <div style={{marginTop:'15vh' }}>
                    <div>
                        {this.state.ShowBar ?
                            <div style={{ position: 'fixed', top: '0' }}>
                                <LoadingBar
                                    progress='100'
                                    // onLoaderFinished={() => setProgress(0)}
                                    //  progress={progress}
                                    height={3} color="#A90007" transitionTime={4000}
                                    loaderSpeed={4000} />
                            </div>
                            : null}
                    </div>

                    {this.state.ShowProcedure ? <Modal isOpen={true} className='propagateLoader' centered>
                        <PropagateLoader color={'#A90007'} size='40px' loading='true' />
                    </Modal> : null}
                    <div>
                        <div>
                            <ResercherHeader ResercherName={this.state.resercher_name} />
                            <div class="container">
                                <div class="content-wrapper">
                                    <div class="screen-headingbox">
                                        <div class="back-section">
                                            <a onClick={this.BackToResercherKolList.bind(this)}>
                                                <img src={require('../Assets/Images/back-arrow.svg')} />Back</a>
                                        </div>
                                        <h2>Profile Details </h2>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 col-xs-12 profile-box">
                                            <div class="sidebar sticky">
                                                <ul class="cetas-leftnav">
                                                    <li>
                                                        <a>
                                                            <text style={{ color: '#A90007', fontWeight: 'bold' }}>Personal Information</text>
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a>
                                                            <text>Biography</text>
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a>
                                                            <text>Professional Membership</text>
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a>
                                                            <text>Education</text>
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a>
                                                            <text>Advisory Board</text>
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a>
                                                            <text>Awards & Honours</text>
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a>
                                                            <text>Books & Monographs</text>
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a>
                                                            <text>Committees</text>
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a>
                                                            <text>Clinical Trial Details</text>
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a>
                                                            <text>Events Details</text>
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a>
                                                            <text>Grants</text>
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a>
                                                            <text>Guidelines</text>
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a>
                                                            <text>Hospital Affiliations</text>
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a>
                                                            <text>Interactions</text>
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a>
                                                            <text>Press Item</text>
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a>
                                                            <text>Presentations</text>
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a>
                                                            <text>Publication Details</text>
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a>
                                                            <text>Sunshine Payment</text>
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a>
                                                            <text>Contract</text>
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a>
                                                            <text>Procedure Potential</text>
                                                        </a>
                                                    </li>
                                                    <li></li>
                                                </ul>
                                                <div style={{ marginTop: '50%' }}></div>
                                            </div>
                                        </div>
                                        <div class="col-md-5">
                                            <div class="scroll-box" style={{ marginTop:'-100px' }}>
                                                <a id="overall-details"></a>
                                                <section style={{ marginBottom: '30%' }}>
                                                    <h3 class="mt-30">Personal Information </h3>
                                                    <span class="mandatory">Note: All * fields are mandatory </span>
                                                    <div class="scrollable-box">
                                                        <form>
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <div class="form-group">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Select KOL Type <span class="mandatory">*</span></label>
                                                                        <Input type="select" id="roleSelect" className="form-control"
                                                                            style={{ height: '50px' }}
                                                                            name="KOLType"
                                                                            autofocus="true"
                                                                            value={this.state.PersonalInformation.kol_type}
                                                                            onChange={async (e) => {
                                                                                let { PersonalInformation, checkDuplicate } = this.state;
                                                                                PersonalInformation.kol_type = e.target.value;
                                                                                checkDuplicate.kol_type = e.target.value;
                                                                                await this.setState({ PersonalInformation, checkDuplicate, KOLType: e.target.value });
                                                                                console.log("KolType", this.state.KOLType)

                                                                                // let { checkDuplicate } = this.state;
                                                                                // checkDuplicate.kol_type = e.target.value;
                                                                                // await this.setState({ checkDuplicate}); 

                                                                                // {this.afterSelectingKOL()}
                                                                                // below afterSelectSalutation call is just if user 1st selects the saalutation and then selects KOL
                                                                                { this.afterSelectSalutation() }
                                                                            }}
                                                                        >
                                                                           <option value="" >--Select KOL--</option>
                                                                            <option value="KOL Community" >HCP</option>
                                                                            <option value="Payers Community" >Payers</option>
                                                                            <option value="HTA Experts" >HTA Experts</option>
                                                                            <option value="Hospital Procurement" >Hospital Procurement Experts</option>
                                                                            <option value="Advocacy Group" >Advocacy Experts</option>
                                                                            <option value="MedTech Experts" >MedTech Experts</option>

                                                                        </Input>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style={{
                                                                color: "red", fontSize: "12px",
                                                                marginBottom: "5px",
                                                            }}>
                                                                {this.state.koltypeErr}</div>
                                                            <hr />
                                                            <div class="row">
                                                                <div class="col-md-4">
                                                                    {imagePreview}
                                                                    {/* <div class="photo-box"></div> */}
                                                                    <div>
                                                                        <input type='file'
                                                                            id="myInput"
                                                                            accept="image/*"
                                                                            onChange={this.getPhoto} hidden />
                                                                        <label htmlFor="myInput"
                                                                            style={{ color: 'blue', fontSize: "15px", }}>
                                                                            Upload Photo
                                                                        </label>
                                                                    </div>
                                                                    <div className="loginErrCreateProject">{this.state.ImageErr}</div>
 
                                                                </div>

                                                                <div class="col-md-8">
                                                                    <div class="form-group bottom-seperator pb-30">
                                                                        <label for="exampleFormControlTextarea1">SI. No.</label>
                                                                        {/* <input class="form-control" type="text" 
                                                        placeholder={this.state.SrNO} 
                                                        readonly="" /> */}
                                                                        <text className="form-control">{this.state.PersonalInformation.id}</text>
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Unique ID</label>
                                                                        {/* <input class="form-control" type="text" 
                                                                placeholder="UTFI8782" readonly="" /> */}
                                                                        <text className="form-control">{this.state.kol_unique_id}</text>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div class="form-group">

                                                                <label for="exampleFormControlTextarea1">
                                                                    Salutation <span class="mandatory">*</span></label>
                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                    style={{ height: '50px' }}
                                                                    name="salutation"
                                                                    value={this.state.PersonalInformation.salutation}
                                                                    onChange={async (e) => {
                                                                        let { PersonalInformation } = this.state;
                                                                        PersonalInformation.salutation = e.target.value;
                                                                        await this.setState({ PersonalInformation });
                                                                        { this.afterSelectSalutation() }
                                                                    }}
                                                                >
                                                                    <option value="" >--Select Salutation--</option>
                                                                    <option value="Mr." >Mr.</option>
                                                                    <option value="Ms." >Ms.</option>
                                                                    <option value="Mrs." >Mrs.</option>
                                                                    <option value="Dr." >Dr.</option>
                                                                    <option value="Prof." >Prof.</option>
                                                                    <option value="Other" >Other</option>
                                                                </Input>
                                                                <div style={{
                                                                    color: "red", fontSize: "12px", marginTop: "1%",
                                                                    marginBottom: "5px",
                                                                }}>
                                                                    {this.state.salutationErr}
                                                                </div>
                                                                {
                                                                    (this.state.kol_status == 'Prelim Rejected' &&
                                                                        this.state.PersonalInformation.salutation_comment !== "") ? (
                                                                        <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                            {this.state.PersonalInformation.salutation_comment}</a>
                                                                    ) :
                                                                        (<div></div>)
                                                                }
                                                                <a class="float-right" style={{ color: '#007bff', marginTop: '0.5%' }}
                                                                    onClick={this.SalutaionAddSourceFun}>+ Add source</a>
                                                                {
                                                                    (!this.state.SalutationAddSource) ? (
                                                                        <div></div>
                                                                    ) : (
                                                                        <div style={{ marginTop: '8%' }}>
                                                                            {this.state.itemsSalutation.map((itemsSalutation) => (
                                                                                <div className="tag-item" key={itemsSalutation}>
                                                                                    {itemsSalutation}
                                                                                    <button
                                                                                        type="button"
                                                                                        className="button"
                                                                                        onClick={() => this.handleDeleteSalutation(itemsSalutation)}
                                                                                    >
                                                                                        &times;
                                                                                    </button>
                                                                                </div>
                                                                            ))}
                                                                            <input
                                                                                class="form-control-addsource-Personal"
                                                                                value={this.state.valuePersonalInfoAddSource}
                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                onKeyDown={this.handleKeyDownSalutation}
                                                                                onChange={this.handleChangePersonalInfoAddSource}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }
                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                    Full Name <span class="mandatory">*</span></label>
                                                                <input type="text" class="form-control"
                                                                    // onKeyPress={event => this.onKeyPressRestrictOnlyChar(event)}
                                                                    // onKeyPress={event => (event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122)}
                                                                    id="input3" placeholder="Enter Full Name"
                                                                    name="doctor_full_name"
                                                                    value={this.state.PersonalInformation.doctor_full_name}

                                                                    onChange={(e) => {
                                                                        var re = /^[a-z\- ]+$/i;
                                                                        console.log('return : ' + re.test(e.target.value))
                                                                        if (re.test(e.target.value)) {
                                                                            // this.onKeyPressRestrictOnlyChar(e)
                                                                            let { PersonalInformation, checkDuplicate } = this.state;
                                                                            PersonalInformation.doctor_full_name = e.target.value;
                                                                            checkDuplicate.doctor_full_name = e.target.value;
                                                                            this.setState({ PersonalInformation, checkDuplicate });
                                                                        }
                                                                        // console.log("e.length",e.target.value.length)
                                                                        if (e.target.value.length == '0') {
                                                                            // this.onKeyPressRestrictOnlyChar(e)
                                                                            let { PersonalInformation, checkDuplicate } = this.state;
                                                                            PersonalInformation.doctor_full_name = '';
                                                                            checkDuplicate.doctor_full_name = '';
                                                                            this.setState({ PersonalInformation, checkDuplicate });
                                                                        }
                                                                    }}
                                                                />
                                                                <div className="loginErrCreateProject">{this.state.doctorfullnameErr}</div>
                                                                {
                                                                    (this.state.kol_status == 'Prelim Rejected' &&
                                                                        this.state.PersonalInformation.doctor_full_name_comment !== "") ? (
                                                                        <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                            {this.state.PersonalInformation.doctor_full_name_comment}</a>
                                                                    ) :
                                                                        (<div></div>)
                                                                }
                                                                <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                    onClick={this.DFullNameAddSourceFun}>+ Add source</a>
                                                                {
                                                                    (!this.state.DFullNameAddSource) ? (
                                                                        <div></div>
                                                                    ) : (
                                                                        <div style={{ marginTop: '8%' }}>
                                                                            {this.state.itemsDoctorFullName.map((itemsDoctorFullName) => (
                                                                                <div className="tag-item" key={itemsDoctorFullName}>
                                                                                    {itemsDoctorFullName}
                                                                                    <button
                                                                                        type="button"
                                                                                        className="button"
                                                                                        onClick={() => this.handleDeleteDoctorFullName(itemsDoctorFullName)}
                                                                                    >
                                                                                        &times;
                                                                                    </button>
                                                                                </div>
                                                                            ))}
                                                                            <input
                                                                                class="form-control-addsource-Personal"
                                                                                value={this.state.valuePersonalInfoAddSource}
                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                onKeyDown={this.handleKeyDownDoctorFullName}
                                                                                onChange={this.handleChangePersonalInfoAddSource}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }

                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                                    First Name <span class="mandatory">*</span></label>
                                                                <input type="text" class="form-control"
                                                                    autocomplete="off"
                                                                    id="input5" placeholder="Enter First Name"
                                                                    name="first_name"
                                                                    value={this.state.PersonalInformation.first_name}
                                                                    onChange={(e) => {
                                                                        var re = /^[a-z\- ]+$/i;
                                                                        if (re.test(e.target.value)) {
                                                                            let { PersonalInformation } = this.state;
                                                                            PersonalInformation.first_name = e.target.value;
                                                                            this.setState({ PersonalInformation });
                                                                        }
                                                                        if (e.target.value.length == '0') {
                                                                            // this.onKeyPressRestrictOnlyChar(e)
                                                                            let { PersonalInformation } = this.state;
                                                                            PersonalInformation.first_name = '';
                                                                            this.setState({ PersonalInformation });
                                                                        }
                                                                    }}
                                                                />
                                                                <div className="loginErrCreateProject">{this.state.firstnameErr}</div>
                                                                {
                                                                    (this.state.kol_status == 'Prelim Rejected' &&
                                                                        this.state.PersonalInformation.first_name_comment !== "") ? (
                                                                        <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                            {this.state.PersonalInformation.first_name_comment}</a>
                                                                    ) :
                                                                        (<div></div>)
                                                                }
                                                                <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                    onClick={this.FirstNameAddSourceFun}>+ Add source</a>
                                                                {
                                                                    (!this.state.FirstNameAddSource) ? (
                                                                        <div></div>
                                                                    ) : (
                                                                        <div style={{ marginTop: '8%' }}>
                                                                            {this.state.itemsFirstName.map((itemsFirstName) => (
                                                                                <div className="tag-item" key={itemsFirstName}>
                                                                                    {itemsFirstName}
                                                                                    <button
                                                                                        type="button"
                                                                                        className="button"
                                                                                        onClick={() => this.handleDeleteFirstName(itemsFirstName)}
                                                                                    >
                                                                                        &times;
                                                                                    </button>
                                                                                </div>
                                                                            ))}
                                                                            <input
                                                                                class="form-control-addsource-Personal"
                                                                                value={this.state.valuePersonalInfoAddSource}
                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                onKeyDown={this.handleKeyDownFirstName}
                                                                                onChange={this.handleChangePersonalInfoAddSource}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }

                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                                    Middle Name </label>
                                                                <input type="text" class="form-control"
                                                                    autocomplete="off"
                                                                    id="input5" placeholder="Enter Middle Name"
                                                                    name="middle_name"
                                                                    value={this.state.PersonalInformation.middle_name}
                                                                    onChange={(e) => {
                                                                        var re = /^[a-z\- ]+$/i;
                                                                        if (re.test(e.target.value)) {
                                                                            let { PersonalInformation } = this.state;
                                                                            PersonalInformation.middle_name = e.target.value;
                                                                            this.setState({ PersonalInformation });
                                                                        }
                                                                        if (e.target.value.length == '0') {
                                                                            // this.onKeyPressRestrictOnlyChar(e)
                                                                            let { PersonalInformation } = this.state;
                                                                            PersonalInformation.middle_name = '';
                                                                            this.setState({ PersonalInformation });
                                                                        }
                                                                    }}
                                                                />
                                                                {
                                                                    (this.state.kol_status == 'Prelim Rejected' &&
                                                                        this.state.PersonalInformation.middle_name_comment !== "") ? (
                                                                        <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                            {this.state.PersonalInformation.middle_name_comment}</a>
                                                                    ) :
                                                                        (<div></div>)
                                                                }
                                                                <a class="float-right" style={{ marginTop: '-3%', color: '#007bff' }}
                                                                    onClick={this.MiddleNameAddSourceFun}>+ Add source</a>
                                                                {
                                                                    (!this.state.MiddleNameAddSource) ? (
                                                                        <div></div>
                                                                    ) : (
                                                                        <div style={{ marginTop: '8%' }}>
                                                                            {this.state.itemsMiddleName.map((itemsMiddleName) => (
                                                                                <div className="tag-item" key={itemsMiddleName}>
                                                                                    {itemsMiddleName}
                                                                                    <button
                                                                                        type="button"
                                                                                        className="button"
                                                                                        onClick={() => this.handleDeleteMiddleName(itemsMiddleName)}
                                                                                    >
                                                                                        &times;
                                                                                    </button>
                                                                                </div>
                                                                            ))}
                                                                            <input
                                                                                class="form-control-addsource-Personal"
                                                                                value={this.state.valuePersonalInfoAddSource}
                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                onKeyDown={this.handleKeyDownMiddleName}
                                                                                onChange={this.handleChangePersonalInfoAddSource}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }

                                                                <label for="exampleFormControlTextarea1" >
                                                                    Last Name <span class="mandatory">*</span></label>
                                                                <input type="text" class="form-control"
                                                                    autocomplete="off"
                                                                    id="input5" placeholder="Enter Last Name"
                                                                    name="last_name"
                                                                    value={this.state.PersonalInformation.last_name}
                                                                    onChange={(e) => {
                                                                        var re = /^[a-z\- ]+$/i;
                                                                        if (re.test(e.target.value)) {
                                                                            let { PersonalInformation } = this.state;
                                                                            PersonalInformation.last_name = e.target.value;
                                                                            this.setState({ PersonalInformation });
                                                                        }
                                                                        if (e.target.value.length == '0') {
                                                                            // this.onKeyPressRestrictOnlyChar(e)
                                                                            let { PersonalInformation } = this.state;
                                                                            PersonalInformation.last_name = '';
                                                                            this.setState({ PersonalInformation });
                                                                        }
                                                                    }}
                                                                />
                                                                <div className="loginErrCreateProject">{this.state.lastnameErr}</div>
                                                                {
                                                                    (this.state.kol_status == 'Prelim Rejected' &&
                                                                        this.state.PersonalInformation.last_name_comment !== "") ? (
                                                                        <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                            {this.state.PersonalInformation.last_name_comment}</a>
                                                                    ) :
                                                                        (<div></div>)
                                                                }
                                                                <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                    onClick={this.LastNameAddSourceFun}>+ Add source</a>
                                                                {
                                                                    (!this.state.LastNameAddSource) ? (
                                                                        <div></div>
                                                                    ) : (
                                                                        <div style={{ marginTop: '8%' }}>
                                                                            {this.state.itemsLastName.map((itemsLastName) => (
                                                                                <div className="tag-item" key={itemsLastName}>
                                                                                    {itemsLastName}
                                                                                    <button
                                                                                        type="button"
                                                                                        className="button"
                                                                                        onClick={() => this.handleDeleteLastName(itemsLastName)}
                                                                                    >
                                                                                        &times;
                                                                                    </button>
                                                                                </div>
                                                                            ))}
                                                                            <input
                                                                                class="form-control-addsource-Personal"
                                                                                value={this.state.valuePersonalInfoAddSource}
                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                onKeyDown={this.handleKeyDownLastName}
                                                                                onChange={this.handleChangePersonalInfoAddSource}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }







                                                    <label for="exampleFormControlTextarea1" >
                                                    Suffix <span class="mandatory">*</span></label>
                                                                <input type="text" class="form-control"
                                                                    autocomplete="off"
                                                                    id="input5" placeholder="Enter Suffix"
                                                                    name="suffix"
                                                                    value={this.state.PersonalInformation.suffix}
                                                                    onChange={(e) => {
                                                                        var re = /^[a-z\- ]+$/i;
                                                                        if (re.test(e.target.value)) {
                                                                            let { PersonalInformation } = this.state;
                                                                            PersonalInformation.suffix = e.target.value;
                                                                            this.setState({ PersonalInformation });
                                                                        }
                                                                        if (e.target.value.length == '0') {
                                                                            // this.onKeyPressRestrictOnlyChar(e)
                                                                            let { PersonalInformation } = this.state;
                                                                            PersonalInformation.suffix = '';
                                                                            this.setState({ PersonalInformation });
                                                                        }
                                                                    }}
                                                                />
                                                                <div className="loginErrCreateProject">{this.state.SuffixErr}</div>
                                                                {
                                                                    (this.state.kol_status == 'Prelim Rejected' &&
                                                                        this.state.PersonalInformation.suffix_comments !== "") ? (
                                                                        <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                            {this.state.PersonalInformation.suffix_comments}</a>
                                                                    ) :
                                                                        (<div></div>)
                                                                }
                                                                <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                    onClick={this.suffixAddSourceFun}>+ Add source</a>
                                                                {
                                                                    (!this.state.suffixAddSource) ? (
                                                                        <div></div>
                                                                    ) : (
                                                                        <div style={{ marginTop: '8%' }}>
                                                                            {this.state.itemssuffix.map((itemssuffix) => (
                                                                                <div className="tag-item" key={itemssuffix}>
                                                                                    {itemssuffix}
                                                                                    <button
                                                                                        type="button"
                                                                                        className="button"
                                                                                        onClick={() => this.handleDeletesuffix(itemssuffix)}
                                                                                    >
                                                                                        &times;
                                                                                    </button>
                                                                                </div>
                                                                            ))}
                                                                            <input
                                                                                class="form-control-addsource-Personal"
                                                                                value={this.state.valuePersonalInfoAddSource}
                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                onKeyDown={this.handleKeyDownsuffix}
                                                                                onChange={this.handleChangePersonalInfoAddSource}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }












                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                                    Clinical Experience <span class="mandatory">*</span></label>
                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                    style={{ height: '50px' }}
                                                                    name="experience"
                                                                    value={this.state.PersonalInformation.experience}
                                                                    onChange={(e) => {
                                                                        let { PersonalInformation } = this.state;
                                                                        PersonalInformation.experience = e.target.value;
                                                                        this.setState({ PersonalInformation });
                                                                    }}
                                                                >
                                                                    <option value="" >--Select Experience--</option>
                                                                    <option value="1 year" >1 year</option>
                                                                    <option value="2 years" >2 years</option>
                                                                    <option value="3 years" >3 years</option>
                                                                    <option value="4 years" >4 years</option>
                                                                    <option value="5 years" >5 years</option>
                                                                    <option value="6 years" >6 years</option>
                                                                    <option value="7 years" >7 years</option>
                                                                    <option value="8 years" >8 years</option>
                                                                    <option value="9 years" >9 years</option>
                                                                    <option value="10 years" >10 years</option>
                                                                    <option value="11 years" >11 years</option>
                                                                    <option value="12 years" >12 years</option>
                                                                    <option value="13 years" >13 years</option>
                                                                    <option value="14 years" >14 years</option>
                                                                    <option value="15 years" >15 years</option>
                                                                    <option value="16 years" >16 years</option>
                                                                    <option value="17 years" >17 years</option>
                                                                    <option value="18 years" >18 years</option>
                                                                    <option value="19 years" >19 years</option>
                                                                    <option value="20 years" >20 years</option>
                                                                    <option value="21 years" >21 years</option>
                                                                    <option value="22 years" >22 years</option>
                                                                    <option value="23 years" >23 years</option>
                                                                    <option value="24 years" >24 years</option>
                                                                    <option value="25+ years" >25+ years</option>
                                                                    <option value="N/A" >N/A</option>
                                                                </Input>
                                                                <div style={{
                                                                    color: "red", fontSize: "12px", marginTop: "1%",
                                                                    marginBottom: "5px",
                                                                }}>
                                                                    {this.state.experienceErr}</div>
                                                                {
                                                                    (this.state.kol_status == 'Prelim Rejected' &&
                                                                        this.state.PersonalInformation.experience_comment !== "") ? (
                                                                        <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                            {this.state.PersonalInformation.experience_comment}</a>
                                                                    ) :
                                                                        (<div></div>)
                                                                }
                                                                <a class="float-right" style={{ marginTop: '0.5%', color: '#007bff' }}
                                                                    onClick={this.ExperienceAddSourceFun}>+ Add source</a>
                                                                {
                                                                    (!this.state.ExperienceAddSource) ? (
                                                                        <div></div>
                                                                    ) : (
                                                                        <div style={{ marginTop: '8%' }}>
                                                                            {this.state.itemsExperience.map((itemsExperience) => (
                                                                                <div className="tag-item" key={itemsExperience}>
                                                                                    {itemsExperience}
                                                                                    <button
                                                                                        type="button"
                                                                                        className="button"
                                                                                        onClick={() => this.handleDeleteExperience(itemsExperience)}
                                                                                    >
                                                                                        &times;
                                                                                    </button>
                                                                                </div>
                                                                            ))}
                                                                            <input
                                                                                class="form-control-addsource-Personal"
                                                                                value={this.state.valuePersonalInfoAddSource}
                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                onKeyDown={this.handleKeyDownExperience}
                                                                                onChange={this.handleChangePersonalInfoAddSource}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }

                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                                    Qualification  <span class="mandatory">*</span></label>
                                                                <input type="text" class="form-control disabled"
                                                                    autocomplete="off"
                                                                    id="input6" placeholder="Enter Qualification "
                                                                    name="qualification"
                                                                    value={this.state.PersonalInformation.qualification}
                                                                />
                                                                {/* <div className="loginErrCreateProject">{this.state.qualificationErr}</div> */}
                                                                {
                                                                    (this.state.kol_status == 'Prelim Rejected' &&
                                                                        this.state.PersonalInformation.qualification_comment !== "") ? (
                                                                        <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                            {this.state.PersonalInformation.qualification_comment}</a>
                                                                    ) :
                                                                        (<div></div>)
                                                                }
                                                                <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                    onClick={this.QualificationAddSourceFun}>+ Add source</a>
                                                                {
                                                                    (!this.state.QualificationAddSource) ? (
                                                                        <div></div>
                                                                    ) : (
                                                                        <div style={{ marginTop: '8%' }}>
                                                                            {this.state.itemsQualification.map((itemsQualification) => (
                                                                                <div className="tag-item" key={itemsQualification}>
                                                                                    {itemsQualification}
                                                                                    <button
                                                                                        type="button"
                                                                                        className="button"
                                                                                        onClick={() => this.handleDeleteQualification(itemsQualification)}
                                                                                    >
                                                                                        &times;
                                                                                    </button>
                                                                                </div>
                                                                            ))}
                                                                            <input
                                                                                class="form-control-addsource-Personal"
                                                                                value={this.state.valuePersonalInfoAddSource}
                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                onKeyDown={this.handleKeyDownQualification}
                                                                                onChange={this.handleChangePersonalInfoAddSource}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }



                                                                {/* {this.forMedicalInformation()} */}
                                                                {
                                                                    (this.state.PersonalInformation.kol_type == 'HTA Experts' ||
                                                                        this.state.PersonalInformation.kol_type == 'Payers Community' ||
                                                                        this.state.PersonalInformation.kol_type == 'Hospital Procurement' ||
                                                                        this.state.PersonalInformation.kol_type == 'Advocacy Group'
                                                                        // this.state.PersonalInformation.kol_type == 'Clinical Commission Group'
                                                                    ) ? (
                                                                        (this.state.PersonalInformation.salutation == 'Dr.') ? (
                                                                            <div>
                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                    {/* Medical Schools  */}
                                                                                    Alma Mater
                                                                                </label>
                                                                                <input type="text" class="form-control disabled"
                                                                                    autocomplete="off"
                                                                                    id="input7" placeholder="Enter Alma Mater"
                                                                                    name="medical_schools"
                                                                                    value={this.state.PersonalInformation.medical_schools}
                                                                                />
                                                                                {/* <div className="loginErrCreateProject">{this.state.medicalschoolErr}</div> */}
                                                                                {
                                                                                    (this.state.kol_status == 'Prelim Rejected' &&
                                                                                        this.state.PersonalInformation.medical_schools_comment !== "") ? (
                                                                                        <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                                            {this.state.PersonalInformation.medical_schools_comment}</a>
                                                                                    ) :
                                                                                        (<div></div>)
                                                                                }
                                                                                <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                                    onClick={this.MedicalSchoolsAddSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.MedicalSchoolsAddSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div style={{ marginTop: '8%' }}>
                                                                                            {this.state.itemsMedicalSchool.map((itemsMedicalSchool) => (
                                                                                                <div className="tag-item" key={itemsMedicalSchool}>
                                                                                                    {itemsMedicalSchool}
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="button"
                                                                                                        onClick={() => this.handleDeleteMedicalSchool(itemsMedicalSchool)}
                                                                                                    >
                                                                                                        &times;
                                                                                                    </button>
                                                                                                </div>
                                                                                            ))}
                                                                                            <input
                                                                                                class="form-control-addsource-Personal"
                                                                                                value={this.state.valuePersonalInfoAddSource}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={this.handleKeyDownMedicalSchool}
                                                                                                onChange={this.handleChangePersonalInfoAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                    License No. </label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter License No. "
                                                                                    name="medical_registration_no"
                                                                                    value={this.state.PersonalInformation.medical_registration_no}
                                                                                    onChange={(e) => {
                                                                                       // var re = /^[a-z0-9]+$/i;
                                                                                       var re =/^[a-z0-9\s]*$/;
                                                                                        if (re.test(e.target.value)) {
                                                                                            let { PersonalInformation } = this.state;
                                                                                            PersonalInformation.medical_registration_no = e.target.value;
                                                                                            this.setState({ PersonalInformation });
                                                                                        }
                                                                                        if (e.target.value.length == '0') {
                                                                                            let { PersonalInformation } = this.state;
                                                                                            PersonalInformation.medical_registration_no = '';
                                                                                            this.setState({ PersonalInformation });
                                                                                        }
                                                                                    }}
                                                                                />
                                                                                {
                                                                                    (this.state.kol_status == 'Prelim Rejected' &&
                                                                                        this.state.PersonalInformation.medical_registration_no_comment !== "") ? (
                                                                                        <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                                            {this.state.PersonalInformation.medical_registration_no_comment}</a>
                                                                                    ) :
                                                                                        (<div></div>)
                                                                                }
                                                                                <a class="float-right" style={{ marginTop: '-3%', color: '#007bff' }}
                                                                                    onClick={this.MedicalRegistrationNoAddSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.MedicalRegistrationNoAddSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div style={{ marginTop: '8%' }}>
                                                                                            {this.state.itemsMedicalRegisterNo.map((itemsMedicalRegisterNo) => (
                                                                                                <div className="tag-item" key={itemsMedicalRegisterNo}>
                                                                                                    {itemsMedicalRegisterNo}
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="button"
                                                                                                        onClick={() => this.handleDeleteMedicalRegister(itemsMedicalRegisterNo)}
                                                                                                    >
                                                                                                        &times;
                                                                                                    </button>
                                                                                                </div>
                                                                                            ))}
                                                                                            <input
                                                                                                class="form-control-addsource-Personal"
                                                                                                value={this.state.valuePersonalInfoAddSource}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={this.handleKeyDownMedicalMedicalRegister}
                                                                                                onChange={this.handleChangePersonalInfoAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                {
                                                                                    (this.state.PersonalInformation.country_id == '1') ? (
                                                                                        <div>
                                                                                            <label for="exampleFormControlTextarea1" style={{ marginTop: '1%' }}>
                                                                                                NPI No. </label>
                                                                                            <input type="text" maxlength="10" class="form-control"
                                                                                                id="input7" placeholder="Enter NPI No. "
                                                                                                name="NPI_no"
                                                                                                value={this.state.PersonalInformation.NPI_no}
                                                                                                onChange={(e) => {
                                                                                                    var re = /^[a-z0-9]+$/i;
                                                                                                    if (re.test(e.target.value)) {
                                                                                                        let { PersonalInformation } = this.state;
                                                                                                        PersonalInformation.NPI_no = e.target.value;
                                                                                                        this.setState({ PersonalInformation });
                                                                                                    }
                                                                                                    if (e.target.value.length == '0') {
                                                                                                        // this.onKeyPressRestrictOnlyChar(e)
                                                                                                        let { PersonalInformation } = this.state;
                                                                                                        PersonalInformation.NPI_no = '';
                                                                                                        this.setState({ PersonalInformation });
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                            {
                                                                                                (this.state.kol_status == 'Prelim Rejected' &&
                                                                                                    this.state.PersonalInformation.NPI_no_comment !== "") ? (
                                                                                                    <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                                                        {this.state.PersonalInformation.NPI_no_comment}</a>
                                                                                                ) :
                                                                                                    (<div></div>)
                                                                                            }
                                                                                            <a class="float-right" style={{ marginTop: '-3%', color: '#007bff' }}
                                                                                                onClick={this.NPINoAddSourceFun}>+ Add source</a>
                                                                                            {
                                                                                                (!this.state.NPINoAddSource) ? (
                                                                                                    <div></div>
                                                                                                ) : (
                                                                                                    <div style={{ marginTop: '8%' }}>
                                                                                                        {this.state.itemsNpiNo.map((itemsNpiNo) => (
                                                                                                            <div className="tag-item" key={itemsNpiNo}>
                                                                                                                {itemsNpiNo}
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="button"
                                                                                                                    onClick={() => this.handleDeleteNpiNo(itemsNpiNo)}
                                                                                                                >
                                                                                                                    &times;
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        ))}
                                                                                                        <input
                                                                                                            class="form-control-addsource-Personal"
                                                                                                            value={this.state.valuePersonalInfoAddSource}
                                                                                                            placeholder="Type or paste source link and press `Enter`"
                                                                                                            onKeyDown={this.handleKeyDownNpiNo}
                                                                                                            onChange={this.handleChangePersonalInfoAddSource}
                                                                                                        />
                                                                                                    </div>
                                                                                                )
                                                                                            }

                                                                                        </div>
                                                                                    ) : (
                                                                                        <div></div>
                                                                                    )
                                                                                }

                                                                            </div>
                                                                        ) :
                                                                            (
                                                                                <div></div>
                                                                            )

                                                                    ) :
                                                                        (
                                                                            <div>
                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                    {/* Medical Schools  */}
                                                                                    Alma Mater
                                                                                    <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control disabled"
                                                                                    autocomplete="off"
                                                                                    id="input7" placeholder="Enter Alma Mater"
                                                                                    name="medical_schools"
                                                                                    value={this.state.PersonalInformation.medical_schools}
                                                                                // onChange={(e) => {
                                                                                //     let { PersonalInformation } = this.state;
                                                                                //     PersonalInformation.medical_schools = e.target.value;
                                                                                //     this.setState({ PersonalInformation });
                                                                                // }}
                                                                                />
                                                                                {/* <div className="loginErrCreateProject">{this.state.medicalschoolErr}</div> */}
                                                                                {
                                                                                    (this.state.kol_status == 'Prelim Rejected' &&
                                                                                        this.state.PersonalInformation.medical_schools_comment !== "") ? (
                                                                                        <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                                            {this.state.PersonalInformation.medical_schools_comment}</a>
                                                                                    ) :
                                                                                        (<div></div>)
                                                                                }
                                                                                <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                                    onClick={this.MedicalSchoolsAddSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.MedicalSchoolsAddSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div style={{ marginTop: '8%' }}>
                                                                                            {this.state.itemsMedicalSchool.map((itemsMedicalSchool) => (
                                                                                                <div className="tag-item" key={itemsMedicalSchool}>
                                                                                                    {itemsMedicalSchool}
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="button"
                                                                                                        onClick={() => this.handleDeleteMedicalSchool(itemsMedicalSchool)}
                                                                                                    >
                                                                                                        &times;
                                                                                                    </button>
                                                                                                </div>
                                                                                            ))}
                                                                                            <input
                                                                                                class="form-control-addsource-Personal"
                                                                                                value={this.state.valuePersonalInfoAddSource}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={this.handleKeyDownMedicalSchool}
                                                                                                onChange={this.handleChangePersonalInfoAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                    License No. </label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter License No. "
                                                                                    name="medical_registration_no"
                                                                                    value={this.state.PersonalInformation.medical_registration_no}
                                                                                    onChange={(e) => {
                                                                                        //var re = /^[a-z0-9]+$/i;
                                                                                        var re =/^[a-z0-9\s]*$/;
                                                                                        if (re.test(e.target.value)) {
                                                                                            let { PersonalInformation } = this.state;
                                                                                            PersonalInformation.medical_registration_no = e.target.value;
                                                                                            this.setState({ PersonalInformation });
                                                                                        }
                                                                                        if (e.target.value.length == '0') {
                                                                                            let { PersonalInformation } = this.state;
                                                                                            PersonalInformation.medical_registration_no = '';
                                                                                            this.setState({ PersonalInformation });
                                                                                        }
                                                                                    }}
                                                                                />
                                                                                {
                                                                                    (this.state.kol_status == 'Prelim Rejected' &&
                                                                                        this.state.PersonalInformation.medical_registration_no_comment !== "") ? (
                                                                                        <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                                            {this.state.PersonalInformation.medical_registration_no_comment}</a>
                                                                                    ) :
                                                                                        (<div></div>)
                                                                                }
                                                                                <a class="float-right" style={{ marginTop: '-3%', color: '#007bff' }}
                                                                                    onClick={this.MedicalRegistrationNoAddSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.MedicalRegistrationNoAddSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div style={{ marginTop: '8%' }}>
                                                                                            {this.state.itemsMedicalRegisterNo.map((itemsMedicalRegisterNo) => (
                                                                                                <div className="tag-item" key={itemsMedicalRegisterNo}>
                                                                                                    {itemsMedicalRegisterNo}
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="button"
                                                                                                        onClick={() => this.handleDeleteMedicalRegister(itemsMedicalRegisterNo)}
                                                                                                    >
                                                                                                        &times;
                                                                                                    </button>
                                                                                                </div>
                                                                                            ))}
                                                                                            <input
                                                                                                class="form-control-addsource-Personal"
                                                                                                value={this.state.valuePersonalInfoAddSource}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={this.handleKeyDownMedicalMedicalRegister}
                                                                                                onChange={this.handleChangePersonalInfoAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                {
                                                                                    (this.state.PersonalInformation.country_id == '1') ? (
                                                                                        <div>
                                                                                            <label for="exampleFormControlTextarea1" style={{ marginTop: '1%' }}>
                                                                                                NPI No. </label>
                                                                                            <input type="text" maxlength="10" class="form-control"
                                                                                                id="input7" placeholder="Enter NPI No. "
                                                                                                name="NPI_no"
                                                                                                value={this.state.PersonalInformation.NPI_no}
                                                                                                onChange={(e) => {
                                                                                                    var re = /^[a-z0-9]+$/i;
                                                                                                    if (re.test(e.target.value)) {
                                                                                                        let { PersonalInformation } = this.state;
                                                                                                        PersonalInformation.NPI_no = e.target.value;
                                                                                                        this.setState({ PersonalInformation });
                                                                                                    }
                                                                                                    if (e.target.value.length == '0') {
                                                                                                        // this.onKeyPressRestrictOnlyChar(e)
                                                                                                        let { PersonalInformation } = this.state;
                                                                                                        PersonalInformation.NPI_no = '';
                                                                                                        this.setState({ PersonalInformation });
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                            {
                                                                                                (this.state.kol_status == 'Prelim Rejected' &&
                                                                                                    this.state.PersonalInformation.NPI_no_comment !== "") ? (
                                                                                                    <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                                                        {this.state.PersonalInformation.NPI_no_comment}</a>
                                                                                                ) :
                                                                                                    (<div></div>)
                                                                                            }
                                                                                            <a class="float-right" style={{ marginTop: '-3%', color: '#007bff' }}
                                                                                                onClick={this.NPINoAddSourceFun}>+ Add source</a>
                                                                                            {
                                                                                                (!this.state.NPINoAddSource) ? (
                                                                                                    <div></div>
                                                                                                ) : (
                                                                                                    <div style={{ marginTop: '8%' }}>
                                                                                                        {this.state.itemsNpiNo.map((itemsNpiNo) => (
                                                                                                            <div className="tag-item" key={itemsNpiNo}>
                                                                                                                {itemsNpiNo}
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="button"
                                                                                                                    onClick={() => this.handleDeleteNpiNo(itemsNpiNo)}
                                                                                                                >
                                                                                                                    &times;
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        ))}
                                                                                                        <input
                                                                                                            class="form-control-addsource-Personal"
                                                                                                            value={this.state.valuePersonalInfoAddSource}
                                                                                                            placeholder="Type or paste source link and press `Enter`"
                                                                                                            onKeyDown={this.handleKeyDownNpiNo}
                                                                                                            onChange={this.handleChangePersonalInfoAddSource}
                                                                                                        />
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div></div>
                                                                                    )
                                                                                }

                                                                            </div>
                                                                        )
                                                                }

                                                                <div>
                                                                    <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                                        Speciality <span class="mandatory">*</span></label>
                                                                    <Input type="select" id="roleSelect" className="form-control disabled"
                                                                        style={{ height: '50px' }}
                                                                        name="specialty_id"
                                                                        value={this.state.PersonalInformation.specialty_id}
                                                                        onChange={async (e) => {
                                                                            let { PersonalInformation, checkDuplicate } = this.state;
                                                                            this.state.specialty_id = e.target.value;
                                                                            checkDuplicate.specialty_id = e.target.value;
                                                                            PersonalInformation.procedure_type_id = '';
                                                                            PersonalInformation.sub_specialty_id = '';
                                                                            await this.setState({ PersonalInformation, checkDuplicate });

                                                                            await this.setState({
                                                                                ProcedureType: [],
                                                                                SubSpecialityType: [],
                                                                                showProcedureSubSpecialityDropdown: false,
                                                                            })
                                                                            { this.getProcedureList() }
                                                                            { this.getSubSpecialityList() }
                                                                            console.log('ProcedureType', this.state.ProcedureType)
                                                                            console.log('PersonalInformation.procedure_type_id', this.state.PersonalInformation)
                                                                            { this.ProcedureDropdown() }
                                                                        }}
                                                                    >
                                                                        <option value="" >--Select Speciality--</option>
                                                                        {OptionsSpeciality}
                                                                    </Input>
                                                                    <div style={{
                                                                        color: "red", fontSize: "12px", marginTop: "1%",
                                                                        marginBottom: "5px",
                                                                    }}>
                                                                        {this.state.specialityErr}</div>
                                                                    {
                                                                        (this.state.kol_status == 'Prelim Rejected' &&
                                                                            this.state.PersonalInformation.specialty_comment !== "") ? (
                                                                            <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                                {this.state.PersonalInformation.specialty_comment}</a>
                                                                        ) :
                                                                            (<div></div>)
                                                                    }
                                                                    <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                        onClick={this.SpecialityAddSourceFun}>+ Add source</a>
                                                                    {
                                                                        (!this.state.SpecialityAddSource) ? (
                                                                            <div></div>
                                                                        ) : (
                                                                            <div style={{ marginTop: '8%' }}>
                                                                                {this.state.itemSpeciality.map((itemSpeciality) => (
                                                                                    <div className="tag-item" key={itemSpeciality}>
                                                                                        {itemSpeciality}
                                                                                        <button
                                                                                            type="button"
                                                                                            className="button"
                                                                                            onClick={() => this.handleDeleteSpeciality(itemSpeciality)}
                                                                                        >
                                                                                            &times;
                                                                                        </button>
                                                                                    </div>
                                                                                ))}
                                                                                <input
                                                                                    class="form-control-addsource-Personal"
                                                                                    value={this.state.valuePersonalInfoAddSource}
                                                                                    placeholder="Type or paste source link and press `Enter`"
                                                                                    onKeyDown={this.handleKeyDownSpeciality}
                                                                                    onChange={this.handleChangePersonalInfoAddSource}
                                                                                />
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>


                                                                <div>
                                                                    <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                                    Secondary Speciality </label>
                                                                    <Input type="select" id="roleSelect" className="form-control"
                                                                        style={{ height: '50px' }}
                                                                        name="specialty2_id"
                                                                        value={this.state.PersonalInformation.specialty2_id}
                                                                        onChange={async (e) => {
                                                                            // let { PersonalInformation } = this.state;
                                                                            // this.state.specialty2_id = e.target.value;
                                                                            // //checkDuplicate.specialty_id = e.target.value;
                                                                            // //PersonalInformation.procedure_type_id = '';
                                                                            // //PersonalInformation.sub_specialty_id = '';
                                                                            // await this.setState({ PersonalInformation});

                                                                            let { PersonalInformation } = this.state;
                                                                            PersonalInformation.specialty2_id = e.target.value;
                                                                            //PersonalInformation.procedure_type_id = '';
                                                                            //PersonalInformation.sub_specialty_id = '';
                                                                            await this.setState({ PersonalInformation });
                                                                           
                                                                            
                                                                        }}
                                                                    >
                                                                        <option value="" >--Select Secondary Speciality--</option>
                                                                        {OptionsSpeciality}
                                                                    </Input>
                                                                    <div style={{
                                                                        color: "red", fontSize: "12px", marginTop: "1%",
                                                                        marginBottom: "5px",
                                                                    }}>
                                                                        
                                                                        </div>
                                                                    {
                                                                        (this.state.kol_status == 'Prelim Rejected' &&
                                                                            this.state.PersonalInformation.specialty2_comment !== "") ? (
                                                                            <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                                {this.state.PersonalInformation.specialty2_comment}</a>
                                                                        ) :
                                                                            (<div></div>)
                                                                    }
                                                                    <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                        onClick={this.Speciality2AddSourceFun}>+ Add source</a>
                                                                    {
                                                                        (!this.state.Speciality2AddSource) ? (
                                                                            <div></div>
                                                                        ) : (
                                                                            <div style={{ marginTop: '8%' }}>
                                                                                {this.state.itemSpeciality2.map((itemSpeciality2) => (
                                                                                    <div className="tag-item" key={itemSpeciality2}>
                                                                                        {itemSpeciality2}
                                                                                        <button
                                                                                            type="button"
                                                                                            className="button"
                                                                                            onClick={() => this.handleDeleteSpeciality2(itemSpeciality2)}
                                                                                        >
                                                                                            &times;
                                                                                        </button>
                                                                                    </div>
                                                                                ))}
                                                                                <input
                                                                                    class="form-control-addsource-Personal"
                                                                                    value={this.state.valuePersonalInfoAddSource}
                                                                                    placeholder="Type or paste source link and press `Enter`"
                                                                                    onKeyDown={this.handleKeyDownSpeciality2}
                                                                                    onChange={this.handleChangePersonalInfoAddSource}
                                                                                />
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>




                                                                {/* Sub Speciality Dropdown starts here */}
                                                                {
                                                                    // ( !this.state.PersonalInformation.specialty_id ) ? (
                                                                    (this.state.showProcedureSubSpecialityDropdown == false) ? (
                                                                        <div></div>
                                                                    ) : (
                                                                        <div>
                                                                            <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                Sub Speciality Type <span class="mandatory">*</span></label>
                                                                            <Multiselect
                                                                                data={this.state.SubSpecialitydata}
                                                                                placeholder="--Select Sub Speciality--"
                                                                                valueField='sub_speciality_type'
                                                                                textField='sub_speciality_type'
                                                                                defaultValue={this.state.SubSpecialityType}
                                                                                onSelect={(valueField) => console.log(valueField)}
                                                                                onChange={(event) => {
                                                                                    console.log(event);
                                                                                    var joined = [];
                                                                                    console.log("length : " + event.length);
                                                                                    if (event.length === 0) {
                                                                                        this.setState({ SubSpecialityType: [] });
                                                                                    }
                                                                                    event.map(async (data) => {
                                                                                        console.log("data in eve map : " + JSON.stringify(data));
                                                                                        joined = joined.concat(data.sub_speciality_type);
                                                                                        console.log("data join : " + joined);
                                                                                        await this.setState({
                                                                                            SubSpecialityType: joined
                                                                                        });
                                                                                        console.log("check this", this.state.SubSpecialityType)

                                                                                        var output = this.state.SubSpecialityType.map(function (item) {
                                                                                            return item;
                                                                                        });

                                                                                        let { PersonalInformation, checkDuplicate } = this.state;
                                                                                        PersonalInformation.sub_specialty_id = output.join(",");
                                                                                        checkDuplicate.sub_specialty_id = output.join(",");
                                                                                        this.setState({ PersonalInformation, checkDuplicate });

                                                                                        console.log("check this", this.state.PersonalInformation.sub_specialty_id)
                                                                                        console.log(output.join(","))
                                                                                    })
                                                                                }}
                                                                            />
                                                                            <div style={{
                                                                                color: "red", fontSize: "12px", marginTop: "1%",
                                                                                marginBottom: "5px",
                                                                            }}>{this.state.subspecialitytypeErr}</div>
                                                                            {
                                                                                (this.state.kol_status == 'Prelim Rejected' &&
                                                                                    this.state.PersonalInformation.sub_specialty_comment !== "") ? (
                                                                                    <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                                        {this.state.PersonalInformation.sub_specialty_comment}</a>
                                                                                ) :
                                                                                    (<div></div>)
                                                                            }
                                                                            <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                                onClick={this.SubSpecialityTypeAddSourceFun}>+ Add source</a>
                                                                            {
                                                                                (!this.state.SubSpecialityTypeAddSource) ? (
                                                                                    <div></div>
                                                                                ) : (
                                                                                    <div style={{ marginTop: '8%' }}>
                                                                                        {this.state.itemsSubSpeciality.map((itemsSubSpeciality) => (
                                                                                            <div className="tag-item" key={itemsSubSpeciality}>
                                                                                                {itemsSubSpeciality}
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="button"
                                                                                                    onClick={() => this.handleDeleteSubspeciality(itemsSubSpeciality)}
                                                                                                >
                                                                                                    &times;
                                                                                                </button>
                                                                                            </div>
                                                                                        ))}
                                                                                        <input
                                                                                            class="form-control-addsource-Personal"
                                                                                            value={this.state.valuePersonalInfoAddSource}
                                                                                            placeholder="Type or paste source link and press `Enter`"
                                                                                            onKeyDown={this.handleKeyDownSubSpeciality}
                                                                                            onChange={this.handleChangePersonalInfoAddSource}
                                                                                        />
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    )
                                                                }

                                                                {/* Procedure Dropdown starts here */}
                                                                {
                                                                    // ( !this.state.PersonalInformation.specialty_id ) ? (
                                                                    (this.state.showProcedureSubSpecialityDropdown == false) ? (
                                                                        <div></div>
                                                                    ) : (
                                                                        <div>
                                                                            <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                Procedure Type <span class="mandatory">*</span></label>
                                                                            <Multiselect
                                                                                data={this.state.ProcedureData}
                                                                                placeholder="--Select Procedure Type--"
                                                                                valueField='procedure_type'
                                                                                textField='procedure_type'
                                                                                defaultValue={this.state.ProcedureType}
                                                                                onSelect={(valueField) => console.log(valueField)}

                                                                                onChange={(event) => {
                                                                                    console.log(event);
                                                                                    var joined = [];
                                                                                    console.log("length : " + event.length);
                                                                                    if (event.length === 0) {
                                                                                        this.setState({ ProcedureType: [] });
                                                                                    }
                                                                                    event.map(async (data) => {
                                                                                        console.log("data in eve map : " + JSON.stringify(data));
                                                                                        joined = joined.concat(data.procedure_type);
                                                                                        console.log("data join : " + joined);
                                                                                        await this.setState({
                                                                                            ProcedureType: joined
                                                                                        });
                                                                                        console.log("check this ", this.state.ProcedureType)

                                                                                        var output = this.state.ProcedureType.map(function (item) {
                                                                                            return item;
                                                                                        });

                                                                                        let { PersonalInformation, checkDuplicate } = this.state;
                                                                                        PersonalInformation.procedure_type_id = output.join(",");
                                                                                        checkDuplicate.procedure_type_id = output.join(",");
                                                                                        this.setState({ PersonalInformation, checkDuplicate });

                                                                                        console.log("check this", this.state.PersonalInformation.procedure_type_id)
                                                                                        console.log(output.join(","))
                                                                                    })
                                                                                }}
                                                                            />

                                                                            <div style={{
                                                                                color: "red", fontSize: "12px", marginTop: "1%",
                                                                                marginBottom: "5px",
                                                                            }}>{this.state.proceduretypeErr}</div>
                                                                            {
                                                                                (this.state.kol_status == 'Prelim Rejected' &&
                                                                                    this.state.PersonalInformation.procedure_type_comment !== "") ? (
                                                                                    <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                                        {this.state.PersonalInformation.procedure_type_comment}</a>
                                                                                ) :
                                                                                    (<div></div>)
                                                                            }
                                                                            <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                                onClick={this.ProcedureTypeAddSourceFun}>+ Add source</a>
                                                                            {
                                                                                (!this.state.ProcedureTypeAddSource) ? (
                                                                                    <div></div>
                                                                                ) : (
                                                                                    <div style={{ marginTop: '8%' }}>
                                                                                        {this.state.itemsProcedure.map((itemsProcedure) => (
                                                                                            <div className="tag-item" key={itemsProcedure}>
                                                                                                {itemsProcedure}
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="button"
                                                                                                    onClick={() => this.handleDeleteProcedure(itemsProcedure)}
                                                                                                >
                                                                                                    &times;
                                                                                                </button>
                                                                                            </div>
                                                                                        ))}
                                                                                        <input
                                                                                            class="form-control-addsource-Personal"
                                                                                            value={this.state.valuePersonalInfoAddSource}
                                                                                            placeholder="Type or paste source link and press `Enter`"
                                                                                            onKeyDown={this.handleKeyDownProcedure}
                                                                                            onChange={this.handleChangePersonalInfoAddSource}
                                                                                        />
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    )
                                                                }



<label for="exampleFormControlTextarea1" >
Expertise <span class="mandatory">*</span></label>
                                                                <input type="text" class="form-control"
                                                                    autocomplete="off"
                                                                    id="input5" placeholder="Enter expertise"
                                                                    name="expertise"
                                                                    value={this.state.PersonalInformation.expertise}
                                                                    onChange={(e) => {
                                                                        var re = /^[a-z\- ]+$/i;
                                                                        if (re.test(e.target.value)) {
                                                                            let { PersonalInformation } = this.state;
                                                                            PersonalInformation.expertise = e.target.value;
                                                                            this.setState({ PersonalInformation });
                                                                        }
                                                                        if (e.target.value.length == '0') {
                                                                            // this.onKeyPressRestrictOnlyChar(e)
                                                                            let { PersonalInformation } = this.state;
                                                                            PersonalInformation.expertise = '';
                                                                            this.setState({ PersonalInformation });
                                                                        }
                                                                    }}
                                                                />
                                                                <div className="loginErrCreateProject">{this.state.expertiseErr}</div>
                                                                {
                                                                    (this.state.kol_status == 'Prelim Rejected' &&
                                                                        this.state.PersonalInformation.expertise_comments !== "") ? (
                                                                        <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                            {this.state.PersonalInformation.expertise_comments}</a>
                                                                    ) :
                                                                        (<div></div>)
                                                                }
                                                                <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                    onClick={this.expertiseAddSourceFun}>+ Add source</a>
                                                                {
                                                                    (!this.state.expertiseAddSource) ? (
                                                                        <div></div>
                                                                    ) : (
                                                                        <div style={{ marginTop: '8%' }}>
                                                                            {this.state.itemsexpertise.map((itemsexpertise) => (
                                                                                <div className="tag-item" key={itemsexpertise}>
                                                                                    {itemsexpertise}
                                                                                    <button
                                                                                        type="button"
                                                                                        className="button"
                                                                                        onClick={() => this.handleDeleteexpertise(itemsexpertise)}
                                                                                    >
                                                                                        &times;
                                                                                    </button>
                                                                                </div>
                                                                            ))}
                                                                            <input
                                                                                class="form-control-addsource-Personal"
                                                                                value={this.state.valuePersonalInfoAddSource}
                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                onKeyDown={this.handleKeyDownexpertise}
                                                                                onChange={this.handleChangePersonalInfoAddSource}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }






                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                    Contact No. <span class="mandatory">*</span></label>
                                                                <PhoneInput
                                                                    inputStyle={{
                                                                        width: "100%",
                                                                        height: "50px",
                                                                        fontSize: "15px",
                                                                    }}
                                                                    width="50%"
                                                                    country={'in'}
                                                                    value={this.state.PersonalInformation.doctor_contact_no}
                                                                    onChange={(DoctorContactNo) => {
                                                                        let { PersonalInformation } = this.state;
                                                                        PersonalInformation.doctor_contact_no = DoctorContactNo;
                                                                        this.setState({ PersonalInformation });
                                                                    }}
                                                                />
                                                                <div style={{
                                                                    color: "red", fontSize: "12px", marginTop: "1%",
                                                                    marginBottom: "5px",
                                                                }}>{this.state.doctorcontactnoErr}</div>
                                                                {
                                                                    (this.state.kol_status == 'Prelim Rejected' &&
                                                                        this.state.PersonalInformation.doctor_contact_comment !== "") ? (
                                                                        <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                            {this.state.PersonalInformation.doctor_contact_comment}</a>
                                                                    ) :
                                                                        (<div></div>)
                                                                }
                                                                <a class="float-right" style={{ marginTop: '-1', color: '#007bff' }}
                                                                    onClick={this.DoctorContactNoAddSourceFunc}>+ Add source</a>
                                                                {
                                                                    (!this.state.DoctorContactNoAddSource) ? (
                                                                        <div></div>
                                                                    ) : (
                                                                        <div style={{ marginTop: '8%' }}>
                                                                            {this.state.itemsDoctorContact.map((itemsDoctorContact) => (
                                                                                <div className="tag-item" key={itemsDoctorContact}>
                                                                                    {itemsDoctorContact}
                                                                                    <button
                                                                                        type="button"
                                                                                        className="button"
                                                                                        onClick={() => this.handleDeleteDoctorsContact(itemsDoctorContact)}
                                                                                    >
                                                                                        &times;
                                                                                    </button>
                                                                                </div>
                                                                            ))}
                                                                            <input
                                                                                class="form-control-addsource-Personal"
                                                                                value={this.state.valuePersonalInfoAddSource}
                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                onKeyDown={this.handleKeyDownDoctorsContact}
                                                                                onChange={this.handleChangePersonalInfoAddSource}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }

                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '5%' }}>
                                                                    Email ID <span class="mandatory">*</span></label>
                                                                <input type="text" class="form-control"
                                                                    id="input7" placeholder="Enter Email "
                                                                    name="doctor_email"
                                                                    value={this.state.PersonalInformation.doctor_email}
                                                                    onChange={(e) => {
                                                                        // let { PersonalInformation } = this.state;
                                                                        // PersonalInformation.doctor_email = e.target.value;                                                                                        
                                                                        // this.setState({ PersonalInformation });
                                                                        let { PersonalInformation ,checkDuplicate} = this.state;
                                                                        PersonalInformation.doctor_email = e.target.value;
                                                                        checkDuplicate.doctor_email = e.target.value;
                                                                        this.setState({ PersonalInformation,checkDuplicate });
                                                                        
                                                                    }}
                                                                    
                                                                   
                                                                    />
                                                                <div className="loginErrCreateProject">{this.state.doctoremailErr}</div>
                                                                {
                                                                    (this.state.kol_status == 'Prelim Rejected' &&
                                                                        this.state.PersonalInformation.doctor_email_comment !== "") ? (
                                                                        <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                            {this.state.PersonalInformation.doctor_email_comment}</a>
                                                                    ) :
                                                                        (<div></div>)
                                                                }
                                                                <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                    onClick={this.DoctorEmailAddSourceFun}>+ Add source</a>
                                                                {
                                                                    (!this.state.DoctorEmailAddSource) ? (
                                                                        <div></div>
                                                                    ) : (
                                                                        <div style={{ marginTop: '8%' }}>
                                                                            {this.state.itemsDoctorEmail.map((itemsDoctorEmail) => (
                                                                                <div className="tag-item" key={itemsDoctorEmail}>
                                                                                    {itemsDoctorEmail}
                                                                                    <button
                                                                                        type="button"
                                                                                        className="button"
                                                                                        onClick={() => this.handleDeleteDoctorsEmail(itemsDoctorEmail)}
                                                                                    >
                                                                                        &times;
                                                                                    </button>
                                                                                </div>
                                                                            ))}
                                                                            <input
                                                                                class="form-control-addsource-Personal"
                                                                                value={this.state.valuePersonalInfoAddSource}
                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                onKeyDown={this.handleKeyDownDoctorsEmail}
                                                                                onChange={this.handleChangePersonalInfoAddSource}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }


                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '5%' }}>
                                                                Secondary Email ID  </label>
                                                                <input type="text" class="form-control"
                                                                    id="input7" placeholder="Enter Secondary Email ID  "
                                                                    name="doctor_email2"
                                                                    value={this.state.PersonalInformation.doctor_email2}
                                                                    onChange={(e) => {
                                                                        let { PersonalInformation } = this.state;
                                                                        PersonalInformation.doctor_email2 = e.target.value;
                                                                       // checkDuplicate.doctor_email2 = e.target.value;
                                                                        this.setState({ PersonalInformation });

                                                                        // let { PersonalInformation, checkDuplicate } = this.state;
                                                                        // PersonalInformation.country_id = e.target.value;
                                                                        // checkDuplicate.country_id = e.target.value;
                                                                        // PersonalInformation.NPI_no = '';
                                                                        // await this.setState({ PersonalInformation, checkDuplicate });

                                                                    }}
                                                                />
                                                                <div className="loginErrCreateProject">{this.state.doctoremail2Err}</div>
                                                                {
                                                                    (this.state.kol_status == 'Prelim Rejected' &&
                                                                        this.state.PersonalInformation.doctor_email2_comment !== "") ? (
                                                                        <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                            {this.state.PersonalInformation.doctor_email2_comment}</a>
                                                                    ) :
                                                                        (<div></div>)
                                                                }
                                                                <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                    onClick={this.DoctorEmail2AddSourceFun}>+ Add source</a>
                                                                {
                                                                    (!this.state.DoctorEmail2AddSource) ? (
                                                                        <div></div>
                                                                    ) : (
                                                                        <div style={{ marginTop: '8%' }}>
                                                                            {this.state.itemsDoctorEmail2.map((itemsDoctorEmail2) => (
                                                                                <div className="tag-item" key={itemsDoctorEmail2}>
                                                                                    {itemsDoctorEmail2}
                                                                                    <button
                                                                                        type="button"
                                                                                        className="button"
                                                                                        onClick={() => this.handleDeleteDoctorsEmail2(itemsDoctorEmail2)}
                                                                                    >
                                                                                        &times;
                                                                                    </button>
                                                                                </div>
                                                                            ))}
                                                                            <input
                                                                                class="form-control-addsource-Personal"
                                                                                value={this.state.valuePersonalInfoAddSource}
                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                onKeyDown={this.handleKeyDownDoctorsEmail2}
                                                                                onChange={this.handleChangePersonalInfoAddSource}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }






                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                    Fax </label>

                                                                    <PhoneInput
                                                                        inputStyle={{
                                                                        width: "100%",
                                                                        height: "50px",
                                                                        fontSize: "15px",
                                                                        }}
                                                                        width="50%"
                                                                        country={'in'}
                                                                        value={this.state.PersonalInformation.fax}
                                                                        onChange={(FaxNo) => {
                                                                        let { PersonalInformation } = this.state;
                                                                        PersonalInformation.fax = FaxNo;
                                                                        this.setState({ PersonalInformation });
                                                                        }}
                                                                    />
                                                                {/* <input type="text" class="form-control"
                                                                    id="input7" placeholder="Enter Fax"
                                                                    value={this.state.PersonalInformation.fax}
                                                                    onChange={(e) => {
                                                                        let { PersonalInformation } = this.state;
                                                                        PersonalInformation.fax = e.target.value;
                                                                        this.setState({ PersonalInformation });
                                                                    }}
                                                                /> */}
                                                                {
                                                                    (this.state.kol_status == 'Prelim Rejected' &&
                                                                        this.state.PersonalInformation.fax_comment !== "") ? (
                                                                        <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                            {this.state.PersonalInformation.fax_comment}</a>
                                                                    ) :
                                                                        (<div></div>)
                                                                }
                                                                <a class="float-right" style={{ marginTop: '-0%', color: '#007bff' }}
                                                                    onClick={this.FaxAddSourceFun}>+ Add source</a>
                                                                {
                                                                    (!this.state.FaxAddSource) ? (
                                                                        <div></div>
                                                                    ) : (
                                                                        <div style={{ marginTop: '8%' }}>
                                                                            {this.state.itemsFax.map((itemsFax) => (
                                                                                <div className="tag-item" key={itemsFax}>
                                                                                    {itemsFax}
                                                                                    <button
                                                                                        type="button"
                                                                                        className="button"
                                                                                        onClick={() => this.handleDeleteFax(itemsFax)}
                                                                                    >
                                                                                        &times;
                                                                                    </button>
                                                                                </div>
                                                                            ))}
                                                                            <input
                                                                                class="form-control-addsource-Personal"
                                                                                value={this.state.valuePersonalInfoAddSource}
                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                onKeyDown={this.handleKeyDownFax}
                                                                                onChange={this.handleChangePersonalInfoAddSource}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }

                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                    LinkedIn Profile </label>
                                                                <input type="text" class="form-control"
                                                                    id="input7" placeholder="Enter LinkedIn Profile "
                                                                    name="linked_in_profile"
                                                                    value={this.state.PersonalInformation.linked_in_profile}
                                                                    onChange={(e) => {
                                                                        let { PersonalInformation } = this.state;
                                                                        PersonalInformation.linked_in_profile = e.target.value;
                                                                        this.setState({ PersonalInformation });
                                                                    }}
                                                                />
                                                                {
                                                                    (this.state.kol_status == 'Prelim Rejected' &&
                                                                        this.state.PersonalInformation.linked_in_profile_comment !== "") ? (
                                                                        <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                            {this.state.PersonalInformation.linked_in_profile_comment}</a>
                                                                    ) :
                                                                        (<div></div>)
                                                                }
                                                                <a class="float-right" style={{ marginTop: '-3%', color: '#007bff' }}
                                                                    onClick={this.LinkedInProfileAddSourceFun}>+ Add source</a>
                                                                {
                                                                    (!this.state.LinkedInProfileAddSource) ? (
                                                                        <div></div>
                                                                    ) : (
                                                                        <div style={{ marginTop: '8%' }}>
                                                                            {this.state.itemsLinkedIn.map((itemsLinkedIn) => (
                                                                                <div className="tag-item" key={itemsLinkedIn}>
                                                                                    {itemsLinkedIn}
                                                                                    <button
                                                                                        type="button"
                                                                                        className="button"
                                                                                        onClick={() => this.handleDeleteLinkedIn(itemsLinkedIn)}
                                                                                    >
                                                                                        &times;
                                                                                    </button>
                                                                                </div>
                                                                            ))}
                                                                            <input
                                                                                class="form-control-addsource-Personal"
                                                                                value={this.state.valuePersonalInfoAddSource}
                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                onKeyDown={this.handleKeyDownLinkedIn}
                                                                                onChange={this.handleChangePersonalInfoAddSource}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }

                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '1%' }}>
                                                                    Twitter Handle </label>
                                                                <input type="text" class="form-control"
                                                                    id="input7" placeholder="Enter Twitter Profile "
                                                                    name="twitter_handle"
                                                                    value={this.state.PersonalInformation.twitter_handle}
                                                                    onChange={(e) => {
                                                                        let { PersonalInformation } = this.state;
                                                                        PersonalInformation.twitter_handle = e.target.value;
                                                                        this.setState({ PersonalInformation });
                                                                    }}
                                                                />
                                                                {
                                                                    (this.state.kol_status == 'Prelim Rejected' &&
                                                                        this.state.PersonalInformation.twitter_handle_comment !== "") ? (
                                                                        <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                            {this.state.PersonalInformation.twitter_handle_comment}</a>
                                                                    ) :
                                                                        (<div></div>)
                                                                }
                                                                <a class="float-right" style={{ marginTop: '-3%', color: '#007bff' }}
                                                                    onClick={this.TwitterHandleAddSourceFun}>+ Add source</a>
                                                                {
                                                                    (!this.state.TwitterHandleAddSource) ? (
                                                                        <div></div>
                                                                    ) : (
                                                                        <div style={{ marginTop: '8%' }}>
                                                                            {this.state.itemsTwitter.map((itemsTwitter) => (
                                                                                <div className="tag-item" key={itemsTwitter}>
                                                                                    {itemsTwitter}
                                                                                    <button
                                                                                        type="button"
                                                                                        className="button"
                                                                                        onClick={() => this.handleDeleteTwitter(itemsTwitter)}
                                                                                    >
                                                                                        &times;
                                                                                    </button>
                                                                                </div>
                                                                            ))}
                                                                            <input
                                                                                class="form-control-addsource-Personal"
                                                                                value={this.state.valuePersonalInfoAddSource}
                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                onKeyDown={this.handleKeyDownTwitter}
                                                                                onChange={this.handleChangePersonalInfoAddSource}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }

                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                    Assistant/Secretary Contact No. </label>
                                                                <PhoneInput
                                                                    inputStyle={{
                                                                        width: "100%",
                                                                        height: "50px",
                                                                        fontSize: "15px",
                                                                    }}
                                                                    width="50%"
                                                                    country={'in'}
                                                                    value={this.state.PersonalInformation.assistance_contact_no}
                                                                    onChange={(AssistanceContactNo) => {
                                                                        let { PersonalInformation } = this.state;
                                                                        PersonalInformation.assistance_contact_no = AssistanceContactNo;
                                                                        this.setState({ PersonalInformation });
                                                                    }}
                                                                />
                                                                {
                                                                    (this.state.kol_status == 'Prelim Rejected' &&
                                                                        this.state.PersonalInformation.assistance_contact_no_comment !== "") ? (
                                                                        <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                            {this.state.PersonalInformation.assistance_contact_no_comment}</a>
                                                                    ) :
                                                                        (<div></div>)
                                                                }
                                                                <a class="float-right" style={{ marginTop: '0.5%', color: '#007bff' }}
                                                                    onClick={this.AssistanceContactNoAddSourceFunc}>+ Add source</a>
                                                                {
                                                                    (!this.state.AssistanceContactNoAddSource) ? (
                                                                        <div></div>
                                                                    ) : (
                                                                        <div style={{ marginTop: '8%' }}>
                                                                            {this.state.itemsAssistanceContact.map((itemsAssistanceContact) => (
                                                                                <div className="tag-item" key={itemsAssistanceContact}>
                                                                                    {itemsAssistanceContact}
                                                                                    <button
                                                                                        type="button"
                                                                                        className="button"
                                                                                        onClick={() => this.handleDeleteAssisContact(itemsAssistanceContact)}
                                                                                    >
                                                                                        &times;
                                                                                    </button>
                                                                                </div>
                                                                            ))}
                                                                            <input
                                                                                class="form-control-addsource-Personal"
                                                                                value={this.state.valuePersonalInfoAddSource}
                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                onKeyDown={this.handleKeyDownAssisContact}
                                                                                onChange={this.handleChangePersonalInfoAddSource}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }

                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '5%' }}>
                                                                    Assistant Email ID </label>
                                                                <input type="text" class="form-control"
                                                                    id="input7" placeholder="Enter Email "
                                                                    name="assistance_email"
                                                                    value={this.state.PersonalInformation.assistance_email}
                                                                    onChange={(e) => {
                                                                        let { PersonalInformation } = this.state;
                                                                        PersonalInformation.assistance_email = e.target.value;
                                                                        this.setState({ PersonalInformation });
                                                                    }}
                                                                />
                                                                {
                                                                    (this.state.kol_status == 'Prelim Rejected' &&
                                                                        this.state.PersonalInformation.assistance_email_comment !== "") ? (
                                                                        <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                            {this.state.PersonalInformation.assistance_email_comment}</a>
                                                                    ) :
                                                                        (<div></div>)
                                                                }
                                                                <a class="float-right" style={{ marginTop: '-3%', color: '#007bff' }}
                                                                    onClick={this.AssistanceEmailAddSourceFun}>+ Add source</a>
                                                                {
                                                                    (!this.state.AssistanceEmailAddSource) ? (
                                                                        <div></div>
                                                                    ) : (
                                                                        <div style={{ marginTop: '8%' }}>
                                                                            {this.state.itemsAssistanceEmail.map((itemsAssistanceEmail) => (
                                                                                <div className="tag-item" key={itemsAssistanceEmail}>
                                                                                    {itemsAssistanceEmail}
                                                                                    <button
                                                                                        type="button"
                                                                                        className="button"
                                                                                        onClick={() => this.handleDeleteAssisEmail(itemsAssistanceEmail)}
                                                                                    >
                                                                                        &times;
                                                                                    </button>
                                                                                </div>
                                                                            ))}
                                                                            <input
                                                                                class="form-control-addsource-Personal"
                                                                                value={this.state.valuePersonalInfoAddSource}
                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                onKeyDown={this.handleKeyDownAssisEmail}
                                                                                onChange={this.handleChangePersonalInfoAddSource}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }

                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '1%' }}>
                                                                    Website </label>
                                                                <input type="text" class="form-control"
                                                                    id="input7" placeholder="Enter Website "
                                                                    name="website"
                                                                    value={this.state.PersonalInformation.website}
                                                                    onChange={(e) => {
                                                                        let { PersonalInformation } = this.state;
                                                                        PersonalInformation.website = e.target.value;
                                                                        this.setState({ PersonalInformation });
                                                                    }}
                                                                />
                                                                {
                                                                    (this.state.kol_status == 'Prelim Rejected' &&
                                                                        this.state.PersonalInformation.website_comment !== "") ? (
                                                                        <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                            {this.state.PersonalInformation.website_comment}</a>
                                                                    ) :
                                                                        (<div></div>)
                                                                }
                                                                <a class="float-right" style={{ marginTop: '-3%', color: '#007bff' }}
                                                                    onClick={this.WebsiteAddSourceFun}>+ Add source</a>
                                                                {
                                                                    (!this.state.WebsiteAddSource) ? (
                                                                        <div></div>
                                                                    ) : (
                                                                        <div style={{ marginTop: '8%' }}>
                                                                            {this.state.itemsWebsite.map((itemsWebsite) => (
                                                                                <div className="tag-item" key={itemsWebsite}>
                                                                                    {itemsWebsite}
                                                                                    <button
                                                                                        type="button"
                                                                                        className="button"
                                                                                        onClick={() => this.handleDeleteWebsite(itemsWebsite)}
                                                                                    >
                                                                                        &times;
                                                                                    </button>
                                                                                </div>
                                                                            ))}
                                                                            <input
                                                                                class="form-control-addsource-Personal"
                                                                                value={this.state.valuePersonalInfoAddSource}
                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                onKeyDown={this.handleKeyDownWebsite}
                                                                                onChange={this.handleChangePersonalInfoAddSource}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }

                                                                {/* <label for="contractfile" style={{marginTop:'3%'}}>
                                                Contract File </label>
                                            <div class="form-control" style={{height:'25%'}}>
                                                <input type='file' 
                                                    id="contractfile"
                                                    // accept="image/*"
                                                    onChange={this.onUploadContract} hidden />
                                                <label htmlFor="contractfile" 
                                                        style={{color:'blue', fontSize: "15px", marginTop:'1.5%'}}>
                                                        Upload Contract File
                                                </label>
                                                {
                                                    ( this.state.ContractFileName )?(
                                                        <div  class="float-right" style={{marginTop:'1.5%'}}>
                                                            <text>{this.state.ContractFileName}</text>
                                                        </div>
                                                    ):(
                                                        <div class="float-right" style={{marginTop:'1.5%'}}>
                                                        No file Choosen 
                                                        </div>
                                                    )
                                                }    
                                            </div> */}

                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '1%' }}>
                                                                    Country <span class="mandatory">*</span></label>
                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                    style={{ height: '50px' }}
                                                                    name="country_id"
                                                                    value={this.state.PersonalInformation.country_id}
                                                                    onChange={async (e) => {
                                                                        let { PersonalInformation, checkDuplicate } = this.state;
                                                                        PersonalInformation.country_id = e.target.value;
                                                                        checkDuplicate.country_id = e.target.value;
                                                                        PersonalInformation.NPI_no = '';
                                                                        await this.setState({ PersonalInformation, checkDuplicate });

                                                                        { this.getStateList() }
                                                                        { this.getCityList() }
                                                                    }}
                                                                >
                                                                    <option value="" >--Select Country--</option>
                                                                    {OptionsCountry}
                                                                </Input>
                                                                <div style={{
                                                                    color: "red", fontSize: "12px", marginTop: "1%",
                                                                    marginBottom: "5px",
                                                                }}>{this.state.countryErr}</div>
                                                                {
                                                                    (this.state.kol_status == 'Prelim Rejected' &&
                                                                        this.state.PersonalInformation.country_comment !== "") ? (
                                                                        <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                            {this.state.PersonalInformation.country_comment}</a>
                                                                    ) :
                                                                        (<div></div>)
                                                                }
                                                                <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                    onClick={this.CountryAddSourceFun}>+ Add source</a>
                                                                {
                                                                    (!this.state.CountryAddSource) ? (
                                                                        <div></div>
                                                                    ) : (
                                                                        <div style={{ marginTop: '8%' }}>
                                                                            {this.state.itemsCountry.map((itemsCountry) => (
                                                                                <div className="tag-item" key={itemsCountry}>
                                                                                    {itemsCountry}
                                                                                    <button
                                                                                        type="button"
                                                                                        className="button"
                                                                                        onClick={() => this.handleDeleteCountry(itemsCountry)}
                                                                                    >
                                                                                        &times;
                                                                                    </button>
                                                                                </div>
                                                                            ))}
                                                                            <input
                                                                                class="form-control-addsource-Personal"
                                                                                value={this.state.valuePersonalInfoAddSource}
                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                onKeyDown={this.handleKeyDownCountry}
                                                                                onChange={this.handleChangePersonalInfoAddSource}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }

                                                                {/* State Dropdown starts here */}
                                                                {
                                                                    (!this.state.PersonalInformation.country_id) ? (
                                                                        // <label for="exampleFormControlTextarea1">Procedure Type</label>
                                                                        <div></div>
                                                                    ) : (
                                                                        <div>
                                                                            <label for="exampleFormControlTextarea1"
                                                                                style={{ marginTop: '5%' }}>
                                                                                State <span class="mandatory">*</span></label>
                                                                            <Input type="select" id="roleSelect" className="form-control"
                                                                                style={{ height: '50px' }}
                                                                                value={this.state.PersonalInformation.state_id}
                                                                                onChange={async (e) => {
                                                                                    let { PersonalInformation } = this.state;
                                                                                    PersonalInformation.state_id = e.target.value;
                                                                                    await this.setState({ PersonalInformation });
                                                                                }}
                                                                            >
                                                                                <option value="" >--Select State--</option>
                                                                                {OptionsState}
                                                                            </Input>
                                                                            <div style={{
                                                                                color: "red", fontSize: "12px", marginTop: "1%",
                                                                                marginBottom: "5px",
                                                                            }}>{this.state.stateErr}</div>
                                                                            {
                                                                                (this.state.kol_status == 'Prelim Rejected' &&
                                                                                    this.state.PersonalInformation.state_comment !== "") ? (
                                                                                    <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                                        {this.state.PersonalInformation.state_comment}</a>
                                                                                ) :
                                                                                    (<div></div>)
                                                                            }
                                                                            <a class="float-right" style={{ marginTop: '0.5%', color: '#007bff' }}
                                                                                onClick={this.StateAddSourceFun}>+ Add source</a>
                                                                            {
                                                                                (!this.state.StateAddSource) ? (
                                                                                    <div></div>
                                                                                ) : (
                                                                                    <div style={{ marginTop: '8%' }}>
                                                                                        {this.state.itemsState.map((itemsState) => (
                                                                                            <div className="tag-item" key={itemsState}>
                                                                                                {itemsState}
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="button"
                                                                                                    onClick={() => this.handleDeleteState(itemsState)}
                                                                                                >
                                                                                                    &times;
                                                                                                </button>
                                                                                            </div>
                                                                                        ))}
                                                                                        <input
                                                                                            class="form-control-addsource-Personal"
                                                                                            value={this.state.valuePersonalInfoAddSource}
                                                                                            placeholder="Type or paste source link and press `Enter`"
                                                                                            onKeyDown={this.handleKeyDownState}
                                                                                            onChange={this.handleChangePersonalInfoAddSource}
                                                                                        />
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    )
                                                                }



<label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                      City </label>
                                    <input type="text" class="form-control"
                                      id="input7" placeholder="Enter City"
                                      name="city"
                                      value={this.state.PersonalInformation.city}
                                      onChange={(e) => {
                                        let { PersonalInformation } = this.state;
                                        PersonalInformation.city = e.target.value;
                                        this.setState({ PersonalInformation });
                                      }}
                                    />
                                    {
                                      (this.state.kol_status == 'Rejected' &&
                                        this.state.kol_status == 'Prelim Approved' ||
                                        this.state.PersonalInformation.city_comments !== '') ? (
                                        <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                          {this.state.PersonalInformation.city_comments}</a>
                                      ) :
                                        (<div></div>)
                                    }
                                    {/* <div className="loginErrCreateProject">{this.state.address1Err}</div> */}
                                    <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                      onClick={this.CityAddSourceFun}>+ Add source</a>
                                    {
                                      (!this.state.CityAddSource) ? (
                                        <div></div>
                                      ) : (
                                        <div style={{ marginTop: '8%' }}>
                                          {this.state.itemsCity.map((itemsCity) => (
                                            <div className="tag-item" key={itemsCity}>
                                              {itemsCity}
                                              <button
                                                type="button"
                                                className="button"
                                                onClick={() => this.handleDeleteCity(itemsCity)}
                                              >
                                                &times;
                                              </button>
                                            </div>
                                          ))}
                                          <input
                                            class="form-control-addsource-Personal"
                                            value={this.state.valuePersonalInfoAddSource}
                                            placeholder="Type or paste source link and press `Enter`"
                                            onKeyDown={this.handleKeyDownCity}
                                            onChange={this.handleChangePersonalInfoAddSource}
                                          />
                                        </div>
                                      )
                                    }










                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                    Primary Work Address <span class="mandatory">*</span></label>
                                                                <input type="text" class="form-control"
                                                                    id="input7" placeholder="Enter First Address "
                                                                    name="address_1"
                                                                    value={this.state.PersonalInformation.address_1}
                                                                    onChange={(e) => {
                                                                        let { PersonalInformation } = this.state;
                                                                        PersonalInformation.address_1 = e.target.value;
                                                                        this.setState({ PersonalInformation });
                                                                    }}
                                                                />
                                                                <div className="loginErrCreateProject">{this.state.address1Err}</div>
                                                                {
                                                                    (this.state.kol_status == 'Prelim Rejected' &&
                                                                        this.state.PersonalInformation.address_comment !== "") ? (
                                                                        <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                            {this.state.PersonalInformation.address_comment}</a>
                                                                    ) :
                                                                        (<div></div>)
                                                                }
                                                                <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                    onClick={this.Address1AddSourceFun}>+ Add source</a>
                                                                {
                                                                    (!this.state.Address1AddSource) ? (
                                                                        <div></div>
                                                                    ) : (
                                                                        <div style={{ marginTop: '8%' }}>
                                                                            {this.state.itemsAddress1.map((itemsAddress1) => (
                                                                                <div className="tag-item" key={itemsAddress1}>
                                                                                    {itemsAddress1}
                                                                                    <button
                                                                                        type="button"
                                                                                        className="button"
                                                                                        onClick={() => this.handleDeleteAddress1(itemsAddress1)}
                                                                                    >
                                                                                        &times;
                                                                                    </button>
                                                                                </div>
                                                                            ))}
                                                                            <input
                                                                                class="form-control-addsource-Personal"
                                                                                value={this.state.valuePersonalInfoAddSource}
                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                onKeyDown={this.handleKeyDownAddress1}
                                                                                onChange={this.handleChangePersonalInfoAddSource}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }

                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                    Primary Work Address 2 </label>
                                                                <input type="text" class="form-control"
                                                                    id="input7" placeholder="Enter Second Address "
                                                                    name="address_2"
                                                                    value={this.state.PersonalInformation.address_2}
                                                                    onChange={(e) => {
                                                                        let { PersonalInformation } = this.state;
                                                                        PersonalInformation.address_2 = e.target.value;
                                                                        this.setState({ PersonalInformation });
                                                                    }}
                                                                />


<div className="loginErrCreateProject">{this.state.address2Err}</div>
                                                                {
                                                                    (this.state.kol_status == 'Prelim Rejected' &&
                                                                        this.state.PersonalInformation.address_2comment !== "") ? (
                                                                        <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                            {this.state.PersonalInformation.address_2comment}</a>
                                                                    ) :
                                                                        (<div></div>)
                                                                }
                                                                <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                    onClick={this.Address2AddSourceFun}>+ Add source</a>
                                                                {
                                                                    (!this.state.Address2AddSource) ? (
                                                                        <div></div>
                                                                    ) : (
                                                                        <div style={{ marginTop: '8%' }}>
                                                                            {this.state.itemsAddress2.map((itemsAddress2) => (
                                                                                <div className="tag-item" key={itemsAddress2}>
                                                                                    {itemsAddress2}
                                                                                    <button
                                                                                        type="button"
                                                                                        className="button"
                                                                                        onClick={() => this.handleDeleteAddress2(itemsAddress2)}
                                                                                    >
                                                                                        &times;
                                                                                    </button>
                                                                                </div>
                                                                            ))}
                                                                            <input
                                                                                class="form-control-addsource-Personal"
                                                                                value={this.state.valuePersonalInfoAddSource}
                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                onKeyDown={this.handleKeyDownAddress2}
                                                                                onChange={this.handleChangePersonalInfoAddSource}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }




                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '1%' }}>
                                                                    Zip Code <span class="mandatory">*</span></label>
                                                                <input type="text" class="form-control"
                                                                    id="input7" placeholder="Enter Zip Code "
                                                                    name="zip_code"
                                                                    value={this.state.PersonalInformation.zip_code}
                                                                    onChange={(e) => {
                                                                        let { PersonalInformation } = this.state;
                                                                        PersonalInformation.zip_code = e.target.value;
                                                                        this.setState({ PersonalInformation });
                                                                    }}
                                                                />
                                                                <div className="loginErrCreateProject">{this.state.zipcodeErr}</div>
                                                                {
                                                                    (this.state.kol_status == 'Prelim Rejected' &&
                                                                        this.state.PersonalInformation.zip_code_comment !== "") ? (
                                                                        <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                            {this.state.PersonalInformation.zip_code_comment}</a>
                                                                    ) :
                                                                        (<div></div>)
                                                                }

                                                                {/* Work details */}
                                                                {
                                                                    this.state.WorkDetails.map((el, i) => {

                                                                        var arrayCurruntPlaceOfWork = [];
                                                                        var arrayDesignation = [];
                                                                        var arrayDepartment = [];

                                                                        if (el.current_place_of_work_links !== '') {
                                                                            arrayCurruntPlaceOfWork = el.current_place_of_work_links.split(',')
                                                                            arrayCurruntPlaceOfWork = arrayCurruntPlaceOfWork.splice(1)
                                                                        }
                                                                        if (el.designation_links !== '') {
                                                                            arrayDesignation = el.designation_links.split(',')
                                                                            arrayDesignation = arrayDesignation.splice(1)
                                                                        }
                                                                        if (el.department_links !== '') {
                                                                            arrayDepartment = el.department_links.split(',')
                                                                            arrayDepartment = arrayDepartment.splice(1)
                                                                        }

                                                                        return (
                                                                            <div key={i}>
                                                                                {
                                                                                    (i == 0) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            <h3 class="mt-30">Work Details {i + 1}</h3>
                                                                                            {this.state.WorkDetails.length !== 1 &&
                                                                                                <a class="float-right" style={{ marginTop: '-8%', color: '#007bff' }}
                                                                                                    onClick={this.removeClickWorkDetails.bind(this, i)}>x</a>
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                                                    Current Place of Work <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Current Place of Work"
                                                                                    name="current_place_of_work" value={el.current_place_of_work || ''}
                                                                                    onChange={this.handleChangeWorkDetails.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject">
                                                                                    {
                                                                                        (el.current_place_of_work != '') ? (<div></div>) : (this.state.placeofworkErr)
                                                                                    }
                                                                                </div>
                                                                                {
                                                                                    (this.state.kol_status == 'Prelim Rejected' &&
                                                                                        el.current_place_of_work_comment !== '') ? (
                                                                                        <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                                            {el.current_place_of_work_comment}</a>
                                                                                    ) :
                                                                                        (<div></div>)
                                                                                }
                                                                                <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                                    onClick={this.PlaceOfWorkAddSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.PlaceOfWorkAddSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div style={{ marginTop: '8%' }}>
                                                                                            {
                                                                                                arrayCurruntPlaceOfWork.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteWorkingDetails(el.current_place_of_work_links.split(','), item, i, "current_place_of_work_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownWorkingDetails(e, i, "current_place_of_work_links")}
                                                                                                onChange={this.handleChangeWorkingDetailsAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                    Designation <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Designation"
                                                                                    name="designation" value={el.designation || ''}
                                                                                    onChange={this.handleChangeWorkDetails.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject">
                                                                                    {
                                                                                        (el.designation != '') ? (<div></div>) : (this.state.designationErr)
                                                                                    }
                                                                                </div>
                                                                                {
                                                                                    (this.state.kol_status == 'Prelim Rejected' &&
                                                                                        el.designation_comment !== '') ? (
                                                                                        <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                                            {el.designation_comment}</a>
                                                                                    ) :
                                                                                        (<div></div>)
                                                                                }
                                                                                <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                                    onClick={this.DesignationAddSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.DesignationAddSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div style={{ marginTop: '8%' }}>
                                                                                            {
                                                                                                arrayDesignation.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteWorkingDetails(el.designation_links.split(','), item, i, "designation_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownWorkingDetails(e, i, "designation_links")}
                                                                                                onChange={this.handleChangeWorkingDetailsAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Department  {/*<span class="mandatory">*</span>*/}</label> 
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Department"
                                                                                    name="department" value={el.department || ''}
                                                                                    onChange={this.handleChangeWorkDetails.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject">
                                                                                    {
                                                                                        (el.department != '') ? (<div></div>) : (this.state.departmentErr)
                                                                                    }
                                                                                </div>
                                                                                {
                                                                                    (this.state.kol_status == 'Prelim Rejected' &&
                                                                                        el.department_comment !== '') ? (
                                                                                        <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                                            {el.department_comment}</a>
                                                                                    ) :
                                                                                        (<div></div>)
                                                                                }
                                                                                <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                                    onClick={this.DepartmentAddSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.DepartmentAddSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div style={{ marginTop: '8%' }}>
                                                                                            {
                                                                                                arrayDepartment.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteWorkingDetails(el.department_links.split(','), item, i, "department_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownWorkingDetails(e, i, "department_links")}
                                                                                                onChange={this.handleChangeWorkingDetailsAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                                <hr />
                                                                                {/* Add More and remove */}

                                                                                {this.state.WorkDetails.length - 1 === i &&
                                                                                    <a style={{ marginTop: '5%', color: '#007bff' }}
                                                                                        onClick={this.addClickWorkDetails.bind(this)}>+ Add More Work Details</a>
                                                                                }
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                                <div style={{ marginTop: '10%' }}></div>
                                                                <hr />

                                                                {/* <h2> KOL Identifiers </h2>
                                            
                                            <br/> */}
                                                                {/* <h4 style={{marginTop:'2%'}}>Event Identifire</h4> */}
                                                                {/* <li>Event Identifire</li> */}
                                                                {/* <label style={{fontSize:'23px',fontWeight:'1%'}} class="mt-30">Event Identifier</label> */}
                                                                {
                                                                    (this.state.PersonalInformation.kol_type == 'KOL Community' || this.state.PersonalInformation.kol_type == '') ? (
                                                                        <div>
                                                                            {/* <h3 class="mt-30">Event participation</h3> */}
                                                                            <div className="row">
                                                                                <h3 class="mt-30" style={{ marginLeft: '3%' }}>Event participation</h3>
                                                                                <input type='checkbox'
                                                                                    style={{ marginLeft: '35%', marginTop: '9%' }}
                                                                                    id="event_identifier"
                                                                                    name="event_identifier"
                                                                                    checked={this.state.KOl_Identifier.event_identifier}
                                                                                    onChange={(e) => {
                                                                                        let { KOl_Identifier } = this.state;
                                                                                        KOl_Identifier.event_identifier = e.target.checked;
                                                                                        this.setState({ KOl_Identifier });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            {/* <br/> */}
                                                                            <label for="exampleFormControlTextarea1" >
                                                                                Event/Conference Type </label>
                                                                            {/* <div class="row"> */}
                                                                            <div class="form-control">
                                                                                <input type='checkbox'
                                                                                    style={{ marginLeft: '5%' }}
                                                                                    id="international_event"
                                                                                    name="international_event"
                                                                                    checked={this.state.KOl_Identifier.international_event}
                                                                                    onChange={(e) => {
                                                                                        let { KOl_Identifier } = this.state;
                                                                                        KOl_Identifier.international_event = e.target.checked;
                                                                                        this.setState({ KOl_Identifier });
                                                                                    }}
                                                                                />
                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                    International
                                                                                </label>

                                                                                <input type='checkbox'
                                                                                    style={{ marginLeft: '22%' }}
                                                                                    id="national_event"
                                                                                    name="national_event"
                                                                                    checked={this.state.KOl_Identifier.national_event}
                                                                                    onChange={(e) => {
                                                                                        let { KOl_Identifier } = this.state;
                                                                                        KOl_Identifier.national_event = e.target.checked;
                                                                                        this.setState({ KOl_Identifier });
                                                                                    }}
                                                                                />
                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                    National
                                                                                </label>
                                                                            </div>
                                                                            <div className="loginErrCreateProject" style={{ marginTop: '2%' }}>{this.state.eventConferencTypeErr}</div>

                                                                            <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                Role
                                                                            </label>
                                                                            {/* <div class="row"> */}
                                                                            <div class="form-control">
                                                                                <input type='checkbox'
                                                                                    style={{ marginLeft: '5%' }}
                                                                                    id="keynote_speaker_event"
                                                                                    name="keynote_speaker_event"
                                                                                    checked={this.state.KOl_Identifier.keynote_speaker_event}
                                                                                    onChange={(e) => {
                                                                                        let { KOl_Identifier } = this.state;
                                                                                        KOl_Identifier.keynote_speaker_event = e.target.checked;
                                                                                        this.setState({ KOl_Identifier });
                                                                                    }}
                                                                                />
                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                    Keynote Speaker
                                                                                </label>

                                                                                <input type='checkbox'
                                                                                    style={{ marginLeft: '16%' }}
                                                                                    id="regular_event"
                                                                                    name="regular_event"
                                                                                    checked={this.state.KOl_Identifier.regular_event}
                                                                                    onChange={(e) => {
                                                                                        let { KOl_Identifier } = this.state;
                                                                                        KOl_Identifier.regular_event = e.target.checked;
                                                                                        this.setState({ KOl_Identifier });
                                                                                    }}
                                                                                />
                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                    Regular
                                                                                </label>

                                                                            </div>
                                                                            <div className="loginErrCreateProject" style={{ marginTop: '2%' }}>{this.state.eventRoleErr}</div>
                                                                            {
                                                                                (this.state.kol_status == 'Prelim Rejected' &&
                                                                                    this.state.KOl_Identifier.event_comment !== "") ? (
                                                                                    <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                                        {this.state.KOl_Identifier.event_comment}</a>
                                                                                ) :
                                                                                    (<div></div>)
                                                                            }

                                                                            <hr style={{ marginTop: '5%' }} />
                                                                            {/* <label style={{fontSize:'23px',fontWeight:'1%'}}>Advisory Board Identifier</label> */}
                                                                            {/* <h3 class="mt-30">Advisory Board membership</h3> */}
                                                                            <div className="row">
                                                                                <h3 class="mt-30" style={{ marginLeft: '3%' }}>Advisory Board membership</h3>
                                                                                <input type='checkbox'
                                                                                    style={{ marginLeft: '5%', marginTop: '9%' }}
                                                                                    id="advisory_identifier"
                                                                                    name="advisory_identifier"
                                                                                    checked={this.state.KOl_Identifier.advisory_identifier}
                                                                                    onChange={(e) => {
                                                                                        let { KOl_Identifier } = this.state;
                                                                                        KOl_Identifier.advisory_identifier = e.target.checked;
                                                                                        this.setState({ KOl_Identifier });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            {/* <br/> */}
                                                                            <label for="exampleFormControlTextarea1" >
                                                                                Org/Company Type </label>
                                                                            {/* <div class="row"> */}
                                                                            <div class="form-control">
                                                                                <input type='checkbox'
                                                                                    style={{ marginLeft: '5%' }}
                                                                                    id="international_advisory_board"
                                                                                    name="international_advisory_board"
                                                                                    checked={this.state.KOl_Identifier.international_advisory_board}
                                                                                    onChange={(e) => {
                                                                                        let { KOl_Identifier } = this.state;
                                                                                        KOl_Identifier.international_advisory_board = e.target.checked;
                                                                                        this.setState({ KOl_Identifier });
                                                                                    }}
                                                                                />
                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                    International (MNC)
                                                                                </label>

                                                                                <input type='checkbox'
                                                                                    style={{ marginLeft: '12%' }}
                                                                                    id="national_advisory_board"
                                                                                    name="national_advisory_board"
                                                                                    checked={this.state.KOl_Identifier.national_advisory_board}
                                                                                    onChange={(e) => {
                                                                                        let { KOl_Identifier } = this.state;
                                                                                        KOl_Identifier.national_advisory_board = e.target.checked;
                                                                                        this.setState({ KOl_Identifier });
                                                                                    }}
                                                                                />
                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                    National
                                                                                </label>
                                                                            </div>
                                                                            <div className="loginErrCreateProject" style={{ marginTop: '2%' }}>{this.state.advBoardCompanyTypeErr}</div>

                                                                            <label for="exampleFormControlTextarea1" >
                                                                                Role </label>
                                                                            {/* <div class="row"> */}
                                                                            <div class="form-control">
                                                                                <input type='checkbox'
                                                                                    style={{ marginLeft: '5%' }}
                                                                                    id="board_member_advisory_board"
                                                                                    name="board_member_advisory_board"
                                                                                    checked={this.state.KOl_Identifier.board_member_advisory_board}
                                                                                    onChange={(e) => {
                                                                                        let { KOl_Identifier } = this.state;
                                                                                        KOl_Identifier.board_member_advisory_board = e.target.checked;
                                                                                        this.setState({ KOl_Identifier });
                                                                                    }}
                                                                                />
                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                    Board Member
                                                                                </label>

                                                                                <input type='checkbox'
                                                                                    style={{ marginLeft: '19%' }}
                                                                                    id="advisory_committee_advisory_board"
                                                                                    name="advisory_committee_advisory_board"
                                                                                    checked={this.state.KOl_Identifier.advisory_committee_advisory_board}
                                                                                    onChange={(e) => {
                                                                                        let { KOl_Identifier } = this.state;
                                                                                        KOl_Identifier.advisory_committee_advisory_board = e.target.checked;
                                                                                        this.setState({ KOl_Identifier });
                                                                                    }}
                                                                                />
                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                    Advisory Committee
                                                                                </label>
                                                                            </div>
                                                                            <div className="loginErrCreateProject" style={{ marginTop: '2%' }}>{this.state.advBoardRoleErr}</div>
                                                                            {
                                                                                (this.state.kol_status == 'Prelim Rejected' &&
                                                                                    this.state.KOl_Identifier.advisory_board_comment !== "") ? (
                                                                                    <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                                        {this.state.KOl_Identifier.advisory_board_comment}</a>
                                                                                ) :
                                                                                    (<div></div>)
                                                                            }

                                                                            <hr style={{ marginTop: '5%' }} />
                                                                            {/* <label style={{fontSize:'23px',fontWeight:'1%'}}>Publication Identifier</label> */}
                                                                            <h3 class="mt-30">Publication</h3>
                                                                            <input type='checkbox'
                                                                                style={{ marginLeft: '57%', marginTop: '9%' }}
                                                                                id="publication_identifier"
                                                                                name="publication_identifier"
                                                                                checked={this.state.KOl_Identifier.publication_identifier}
                                                                                onChange={(e) => {
                                                                                    let { KOl_Identifier } = this.state;
                                                                                    KOl_Identifier.publication_identifier = e.target.checked;
                                                                                    this.setState({ KOl_Identifier });
                                                                                }}
                                                                            />
                                                                            {/* <br/> */}
                                                                            <label for="exampleFormControlTextarea1" >
                                                                                Publication/Journal Type </label>
                                                                            {/* <div class="row"> */}
                                                                            <div class="form-control">
                                                                                <input type='checkbox'
                                                                                    style={{ marginLeft: '5%' }}
                                                                                    id="international_publication"
                                                                                    name="international_publication"
                                                                                    checked={this.state.KOl_Identifier.international_publication}
                                                                                    onChange={(e) => {
                                                                                        let { KOl_Identifier } = this.state;
                                                                                        KOl_Identifier.international_publication = e.target.checked;
                                                                                        this.setState({ KOl_Identifier });
                                                                                    }}
                                                                                />
                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                    International (MNC)
                                                                                </label>

                                                                                <input type='checkbox'
                                                                                    style={{ marginLeft: '12%' }}
                                                                                    id="national_publication"
                                                                                    name="national_publication"
                                                                                    checked={this.state.KOl_Identifier.national_publication}
                                                                                    onChange={(e) => {
                                                                                        let { KOl_Identifier } = this.state;
                                                                                        KOl_Identifier.national_publication = e.target.checked;
                                                                                        this.setState({ KOl_Identifier });
                                                                                    }}
                                                                                />
                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                    National
                                                                                </label>
                                                                            </div>
                                                                            <div className="loginErrCreateProject" style={{ marginTop: '2%' }}>{this.state.publicationJournalTypeErr}</div>
                                                                            <label for="exampleFormControlTextarea1" >
                                                                                Role </label>
                                                                            {/* <div class="row"> */}
                                                                            <div class="form-control">
                                                                                <input type='checkbox'
                                                                                    style={{ marginLeft: '5%' }}
                                                                                    id="first_author_publication"
                                                                                    name="first_author_publication"
                                                                                    checked={this.state.KOl_Identifier.first_author_publication}
                                                                                    onChange={(e) => {
                                                                                        let { KOl_Identifier } = this.state;
                                                                                        KOl_Identifier.first_author_publication = e.target.checked;
                                                                                        this.setState({ KOl_Identifier });
                                                                                    }}
                                                                                />
                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                    First Author
                                                                                </label>

                                                                                <input type='checkbox'
                                                                                    style={{ marginLeft: '19%' }}
                                                                                    id="second_author_publication"
                                                                                    name="second_author_publication"
                                                                                    checked={this.state.KOl_Identifier.second_author_publication}
                                                                                    onChange={(e) => {
                                                                                        let { KOl_Identifier } = this.state;
                                                                                        KOl_Identifier.second_author_publication = e.target.checked;
                                                                                        this.setState({ KOl_Identifier });
                                                                                    }}
                                                                                />
                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                    Second Author
                                                                                </label>
                                                                            </div>
                                                                            <div class="form-control" style={{ marginTop: '2%' }}>
                                                                                <input type='checkbox'
                                                                                    style={{ marginLeft: '5%' }}
                                                                                    id="last_author_publication"
                                                                                    name="last_author_publication"
                                                                                    checked={this.state.KOl_Identifier.last_author_publication}
                                                                                    onChange={(e) => {
                                                                                        let { KOl_Identifier } = this.state;
                                                                                        KOl_Identifier.last_author_publication = e.target.checked;
                                                                                        this.setState({ KOl_Identifier });
                                                                                    }}
                                                                                />
                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                    Last Author
                                                                                </label>
                                                                            </div>
                                                                            <div className="loginErrCreateProject" style={{ marginTop: '2%' }}>{this.state.publicationRoleErr}</div>
                                                                            {
                                                                                (this.state.kol_status == 'Prelim Rejected' &&
                                                                                    this.state.KOl_Identifier.publication_comment !== "") ? (
                                                                                    <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                                        {this.state.KOl_Identifier.publication_comment}</a>
                                                                                ) :
                                                                                    (<div></div>)
                                                                            }

                                                                            <hr style={{ marginTop: '5%' }} />
                                                                            {/* <label style={{fontSize:'23px',fontWeight:'1%'}}>Clinical Trial Identifier</label> */}
                                                                            {/* <h3 class="mt-30"> Clinical Trial Participation</h3> */}
                                                                            <div className="row">
                                                                                <h3 class="mt-30" style={{ marginLeft: '3%' }}>Clinical Trial Participation</h3>
                                                                                <input type='checkbox'
                                                                                    style={{ marginLeft: '14%', marginTop: '9%' }}
                                                                                    id="clinical_trial_identifier"
                                                                                    name="clinical_trial_identifier"
                                                                                    checked={this.state.KOl_Identifier.clinical_trial_identifier}
                                                                                    onChange={(e) => {
                                                                                        let { KOl_Identifier } = this.state;
                                                                                        KOl_Identifier.clinical_trial_identifier = e.target.checked;
                                                                                        this.setState({ KOl_Identifier });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            {/* <br/> */}
                                                                            <label for="exampleFormControlTextarea1" >
                                                                                No. of Trial Location </label>
                                                                            {/* <div class="row"> */}
                                                                            <div class="form-control">
                                                                                <input type='checkbox'
                                                                                    style={{ marginLeft: '5%' }}
                                                                                    id="one_trial_location"
                                                                                    name="one_trial_location"
                                                                                    checked={this.state.KOl_Identifier.one_trial_location}
                                                                                    onChange={(e) => {
                                                                                        let { KOl_Identifier } = this.state;
                                                                                        KOl_Identifier.one_trial_location = e.target.checked;
                                                                                        this.setState({ KOl_Identifier });
                                                                                    }}
                                                                                />
                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                    1
                                                                                </label>

                                                                                <input type='checkbox'
                                                                                    style={{ marginLeft: '40%' }}
                                                                                    id="ten_trial_location"
                                                                                    name="ten_trial_location"
                                                                                    checked={this.state.KOl_Identifier.ten_trial_location}
                                                                                    onChange={(e) => {
                                                                                        let { KOl_Identifier } = this.state;
                                                                                        KOl_Identifier.ten_trial_location = e.target.checked;
                                                                                        this.setState({ KOl_Identifier });
                                                                                    }}
                                                                                />
                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                    2-10
                                                                                </label>
                                                                            </div>
                                                                            {/* <div class="row"> */}
                                                                            <div class="form-control" style={{ marginTop: '2%' }}>
                                                                                <input type='checkbox'
                                                                                    style={{ marginLeft: '5%' }}
                                                                                    id="fifty_trial_location"
                                                                                    name="fifty_trial_location"
                                                                                    checked={this.state.KOl_Identifier.fifty_trial_location}
                                                                                    onChange={(e) => {
                                                                                        let { KOl_Identifier } = this.state;
                                                                                        KOl_Identifier.fifty_trial_location = e.target.checked;
                                                                                        this.setState({ KOl_Identifier });
                                                                                    }}
                                                                                />
                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                    11-50
                                                                                </label>

                                                                                <input type='checkbox'
                                                                                    style={{ marginLeft: '33%' }}
                                                                                    id="tenthousand_trial_location"
                                                                                    name="tenthousand_trial_location"
                                                                                    checked={this.state.KOl_Identifier.tenthousand_trial_location}
                                                                                    onChange={(e) => {
                                                                                        let { KOl_Identifier } = this.state;
                                                                                        KOl_Identifier.tenthousand_trial_location = e.target.checked;
                                                                                        this.setState({ KOl_Identifier });
                                                                                    }}
                                                                                />
                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                    51+
                                                                                </label>
                                                                            </div>
                                                                            <div className="loginErrCreateProject" style={{ marginTop: '2%' }}>{this.state.ClinicalNoOfTrialLocationErr}</div>
                                                                            <label for="exampleFormControlTextarea1" >
                                                                                Trial Type </label>
                                                                            {/* <div class="row"> */}
                                                                            <div class="form-control">
                                                                                <input type='checkbox'
                                                                                    style={{ marginLeft: '5%' }}
                                                                                    id="interventional_trial_location"
                                                                                    name="interventional_trial_location"
                                                                                    checked={this.state.KOl_Identifier.interventional_trial_location}
                                                                                    onChange={(e) => {
                                                                                        let { KOl_Identifier } = this.state;
                                                                                        KOl_Identifier.interventional_trial_location = e.target.checked;
                                                                                        this.setState({ KOl_Identifier });
                                                                                    }}
                                                                                />
                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                    Interventional
                                                                                </label>

                                                                                <input type='checkbox'
                                                                                    style={{ marginLeft: '21%' }}
                                                                                    id="observational_trial_location"
                                                                                    name="observational_trial_location"
                                                                                    checked={this.state.KOl_Identifier.observational_trial_location}
                                                                                    onChange={(e) => {
                                                                                        let { KOl_Identifier } = this.state;
                                                                                        KOl_Identifier.observational_trial_location = e.target.checked;
                                                                                        this.setState({ KOl_Identifier });
                                                                                    }}
                                                                                />
                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                    Observational
                                                                                </label>
                                                                            </div>
                                                                            <div className="loginErrCreateProject" style={{ marginTop: '2%' }}>{this.state.clinicalTrialTypeErr}</div>
                                                                            {
                                                                                (this.state.kol_status == 'Prelim Rejected' &&
                                                                                    this.state.KOl_Identifier.trial_location_comment !== "") ? (
                                                                                    <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                                        {this.state.KOl_Identifier.trial_location_comment}</a>
                                                                                ) :
                                                                                    (<div></div>)
                                                                            }

                                                                            <hr style={{ marginTop: '5%' }} />
                                                                            {/* <label style={{fontSize:'23px',fontWeight:'1%'}}>Guideline Identifier</label> */}
                                                                            {/* <h3 class="mt-30">Guidelines</h3> */}
                                                                            <div className="row">
                                                                                <h3 class="mt-30" style={{ marginLeft: '3%' }}>Guidelines</h3>
                                                                                <input type='checkbox'
                                                                                    style={{ marginLeft: '58%', marginTop: '9%' }}
                                                                                    id="guidelines_identifier"
                                                                                    name="guidelines_identifier"
                                                                                    checked={this.state.KOl_Identifier.guidelines_identifier}
                                                                                    onChange={(e) => {
                                                                                        let { KOl_Identifier } = this.state;
                                                                                        KOl_Identifier.guidelines_identifier = e.target.checked;
                                                                                        this.setState({ KOl_Identifier });
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            {/* <br/> */}
                                                                            <label for="exampleFormControlTextarea1" >
                                                                                Organization Type </label>
                                                                            {/* <div class="row"> */}
                                                                            <div class="form-control">
                                                                                <input type='checkbox'
                                                                                    style={{ marginLeft: '5%' }}
                                                                                    id="international_guideline"
                                                                                    name="international_guideline"
                                                                                    checked={this.state.KOl_Identifier.international_guideline}
                                                                                    onChange={(e) => {
                                                                                        let { KOl_Identifier } = this.state;
                                                                                        KOl_Identifier.international_guideline = e.target.checked;
                                                                                        this.setState({ KOl_Identifier });
                                                                                    }}
                                                                                />
                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                    International
                                                                                </label>

                                                                                <input type='checkbox'
                                                                                    style={{ marginLeft: '23%' }}
                                                                                    id="national_guideline"
                                                                                    name="national_guideline"
                                                                                    checked={this.state.KOl_Identifier.national_guideline}
                                                                                    onChange={(e) => {
                                                                                        let { KOl_Identifier } = this.state;
                                                                                        KOl_Identifier.national_guideline = e.target.checked;
                                                                                        this.setState({ KOl_Identifier });
                                                                                    }}
                                                                                />
                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                    National
                                                                                </label>
                                                                            </div>
                                                                            {/* <div class="row"> */}
                                                                            <div class="form-control" style={{ marginTop: '2%' }}>
                                                                                <input type='checkbox'
                                                                                    style={{ marginLeft: '5%' }}
                                                                                    id="local_guideline"
                                                                                    name="local_guideline"
                                                                                    checked={this.state.KOl_Identifier.local_guideline}
                                                                                    onChange={(e) => {
                                                                                        let { KOl_Identifier } = this.state;
                                                                                        KOl_Identifier.local_guideline = e.target.checked;
                                                                                        this.setState({ KOl_Identifier });
                                                                                    }}
                                                                                />
                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                    Local
                                                                                </label>
                                                                            </div>
                                                                            <div className="loginErrCreateProject" style={{ marginTop: '2%' }}>{this.state.guidelinesOrgTypeErr}</div>
                                                                            {
                                                                                (this.state.kol_status == 'Prelim Rejected' &&
                                                                                    this.state.KOl_Identifier.guideline_comment !== "") ? (
                                                                                    <a class="mandatory" style={{ color: '#A90007' }}>QC Comment : &nbsp;
                                                                                        {this.state.KOl_Identifier.guideline_comment}</a>
                                                                                ) :
                                                                                    (<div></div>)
                                                                            }
                                                                        </div>
                                                                    ) : (
                                                                        <div></div>
                                                                    )
                                                                }

</div>

                                                        <label for="exampleFormControlTextarea1">
                                                                Source Link</label>
                                                            <input type="text" class="form-control-addsource-Personal"
                                                                id="input3" placeholder="Enter Source Link"
                                                                name="source_link"
                                                                value={this.state.PersonalInformation.source_link}
                                                                onChange={(e) => {
                                                                    // let { kol_comment } = this.state;
                                                                    // kol_comment = e.target.value;
                                                                    // this.setState({ kol_comment });

                                                                    // let { PersonalInformation } = this.state;
                                                                    // PersonalInformation.kol_comment = e.target.value;
                                                                    // this.setState({ PersonalInformation });
                                                                }}
                                                            />


                                                            <label for="exampleFormControlTextarea1">
                                                                Overall Comment</label>
                                                            <input type="text" class="form-control-addsource-Personal"
                                                                id="input3" placeholder="Enter Overall Comment"
                                                                name="kol_comment"
                                                                value={this.state.PersonalInformation.kol_comment}
                                                                onChange={(e) => {
                                                                    // let { kol_comment } = this.state;
                                                                    // kol_comment = e.target.value;
                                                                    // this.setState({ kol_comment });

                                                                    // let { PersonalInformation } = this.state;
                                                                    // PersonalInformation.kol_comment = e.target.value;
                                                                    // this.setState({ PersonalInformation });
                                                                }}
                                                            />

                                                        </form>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="action-btn-container">
                                <div class="container">
                                    <div class="col-md-7 offset-md-3">
                                        {
                    ( this.state.ShowDuplicateCheckButton == true )?(
                        <button class="cetas-button" onClick={this.DuplicateCheckFunc}>Duplicate Check</button>
                    ):(
                        <div></div>
                    )
                }
                                        {/* {
                    ( this.state.ShowKOLIdentifierButton == true )?( */}
                                        <button class="cetas-button" data-toggle="modal" data-target="#edit-item"
                                            onClick={this.KOLIdentifiereFunc}>KOL Identifier</button>
                                        {/* ):(
                        <div></div>
                    )    */}
                                        {/* } */}
                                    </div>
                                </div>
                            </div>
                            <footer class="footer-fixed">
                                <div class="cetas-footer">
                                    <div class="container">
                                        <p>All rights reserved. www.cetashealthcare.com</p>
                                    </div>

                                </div>
                            </footer>
                        </div>
                    </div>
                </div>
            </body>
        );
    }
}

export default IdentifierForm;