import React, { Component } from 'react';

//datatable npm
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';

//reactstrap npm
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Button, Alert } from 'reactstrap';

import Axios from 'axios';
import { Redirect, Link } from 'react-router-dom';

import Swal from 'sweetalert2'; //sweetAlerts

// showing progress bar npm 
import { PropagateLoader } from 'react-spinners';
import LoadingBar from "react-top-loading-bar";
import ManagerHeader from './ManagerHeader';

// table functionalities
const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;
const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total ml-2">
    Showing {from} to {to} of {size} Results
  </span>
);

var today = new Date();
var curr_date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()+ " " + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();

class ReviewQuote extends Component {
  constructor(props) {

    super(props)

    var today = new Date(),
    date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

    this.state = {

      
      // state for progress bar
      ShowBar: false,
      progressBar: false,
      PaymentStatusErr:'',
      commentsErr:'',
      pvalueErr:'',
      redirect:false,
      button_disable:false,
      system_id: '',
      delete_id: '',
      manager_name: '',
      // State for saving data
      QuotaionData: [],
      SpecialityData:[],

      comment_box:false,
      payment_value_box:false,


      onPriceFilter: null,
      onProductilters: null,

      // State for displaying data
      status: '',
      // States for toggle Edit form
      isEdit: false,

      redirectCreateProject:false,
      

      paymentinfo:{
        
        id:'',
        client_id:'',
        payment_status:'',        
        paymentvalue:0,
        payment_date:today,
        comment:'',
        
        
      },
      // Stae for saving data from Edit Form
      editData: {
        id: '',
        emp_name: '',
        email_id: '',
        role_type: '',
        username: '',
        password: '',
      },

      Signup_data:{
        id: '1',
        Status: '77',

      },
      // State for toggle Create Employee Form
      isAdd: false,
      // Stae for saving data from AddNewEmployee Form
      addData: {
        id: '',
        emp_name: '',
        role_type: '',
        email_id: '',
        username: '',
        password: '',
        emp_unique_id: ''
      },

      //Error states
      nameError: '',
      roleError: '',
      // usernameError: '',
      emailError: '',
      passwordError: '',

      //Edit Form Error States
      editnameError: '',
      editroleError: '',
      // editusernameError: "",
      editemailError: "",
      editpasswordError: '',

      PaymentStatusModal:false,

      // Column state for Bootstrap Table 
      columns: [
        {
          dataField: 'id',
          text: 'Quotation ID',
          sort: true,
         // hidden:true,
        },
        {
          dataField: 'client_id',
          text: 'Client ID',
          sort: true,
         // hidden:true,
        },
        // {
        //   dataField: 'companyname',
        //   text: 'Company Name',
        //   sort: true,
        //  // hidden:true,
        // },
        // {
        //   dataField: 'phonenumber',
        //   text: 'Phone Number',
        //   sort: true,
        //  // hidden:true,
        // },
        // {
        //   dataField: 'email_id',
        //   text: 'Email',
        //   sort: true,
        //  // hidden:true,
        // },      
        
        {
          dataField: 'client_name',
          text: ' Client Name',
          sort: true,
          filter: textFilter(),

          filterRenderer: (onFilter, column) => {
            this.onPriceFilter = onFilter;
            return null;
          },

          headerStyle: (colum, colIndex) => {
            return { textAlign: 'left',width:'10%'};
        },
          // formatter: this.EmployeeDetails
        },
        {
          dataField: 'speciality_type',
          text: 'Specialty',
          sort: true,
          filter: textFilter(),

          filterRenderer: (onFilter, column) => {
            this.onPriceFilter = onFilter;
            return null;
          },
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { textAlign: 'center',width:'10%'};
        },
        },
        {
          dataField: "procedure_type",
          text: "Procedure Type",
          // formatter: this.Nested_kol_profile_Data,
          align: 'center',
         // headerAlign: 'center'
          headerStyle: (colum, colIndex) => {
            return { textAlign: 'center',width:'12%'};
        },
        },
        { 
          dataField: "countrieslist",
          text: "Country",
          // formatter: this.Nested_kol_profile_Data,
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { textAlign: 'center',width:'8%'};
        },
        },
        // {
        //   dataField: "Profilingtype",
        //   text: "Profiling Type",
        //   // formatter: this.Nested_kol_profile_Data,
        //   align: 'center',
        //   headerAlign: 'center'
        // },
        {
          dataField: "no_of_kol",
          text: "No Of KOL",
          // formatter: this.Nested_kol_profile_Data,
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { textAlign: 'center',width:'10%'};
        },
        },
        {
          dataField: "date",
          text: "Date",
          // formatter: this.Nested_kol_profile_Data,
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { textAlign: 'center',width:'10%'};
        },
        },
   {
          dataField: "payment_status",
          text: "Payment Status",
          formatter: this.paymentstatuscolour,
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { textAlign: 'center',width:'12%'};
        },
        },
   {
          dataField: "status",
          text: "Client Creation",
          formatter: this.Quotestatuscolour,
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { textAlign: 'center',width:'10%'};
        },
        },

        
        {
          dataField: "Actions",
          // text: "Actions",
          formatter: this.linkFollow,
         // sort: true
        }
      ]

      // End of state
    }

    // Functions will be Bind here
    this.toggleaddModal = this.toggleaddModal.bind(this);
   
  }

  ////////////////////////////////////////react bootstrap Column functions//////////////////////////////////////////////////////////////////  
  // Anchor link foe employee names
  // EmployeeDetails(cell, row) {
  //   if (row.emp_name) {
  //     return (
  //       <div>
  //         <a href={row.emp_name}>{row.emp_name}</a>
  //       </div>
  //     )
  //   }
  // }
  // //Function for displaying nested KOL Profiles
  // Nested_kol_profile_Data(cell, row) {
  //   if (row.project_details) {
  //     return (
  //       <div>
  //         {row.project_details.map((data) => {

  //           return (
  //             <div>
  //               <div>{data.required_kol_profile}</div>
  //             </div>
  //           )
  //         })}
  //       </div>
  //     )
  //   }
  // }


  // // Nested Data Function
  // NestedData(cell, row) {
  //   if (row.project_details) {
  //     return (
  //       <div>
  //         {row.project_details.map((data, index) => {
  //           return (
  //             <div>
  //               <div> <span>{index + 1}.</span> {data.p_name}</div>
  //             </div>
  //           )
  //         })}
  //       </div>
  //     )
  //   }
  // }

  togglePaymentStatusModal(row)
  {
    
    if(row.payment_status != "Success")
    {
      let { paymentinfo } = this.state;
      paymentinfo.id = row.id;
      paymentinfo.client_id = row.client_id;
      this.setState({ paymentinfo });
      

      this.setState({PaymentStatusModal:!this.state.PaymentStatusModal})
    }
    else
    {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Payment Status already updated!',
        // showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    }


  }

  togglePaymentStatusModalCancle()
  {
    this.setState({PaymentStatusModal:!this.state.PaymentStatusModal})
  }

  toggleCreateNewProject(row)
  {
    //alert(row.specialty_id);
    if(row.payment_status == "Success")
    {
      this.setState({
        ShowProcedure: true,
        ShowBar:true
        })
 
    var client_info={"client_name":row.client_name,"company_nm":row.companyname,"email_id":row.email_id,"mobile_no":row.phonenumber,"signup_method":'Quotation',"username":"","password":row.password,"client_id":row.client_id,"login_type":"client","status":"Active"};
 
    
    // var quotation_={"quotation_id":row.quotation_id};

     const url = global.websiteUrl+'/service/KOL_landing/approved_Quotation_data';
     let formData = new FormData();
     formData.append('client_info',JSON.stringify(client_info));
         //formData.append('quotationid',JSON.stringify(row.quotation_id));
     formData.append('quotationid',row.id);

        const config = {
            // headers: { 'content-type': 'multipart/form-data' }
        }

        Axios.post(url, formData, config)
            .then(response => {
                console.log(response.data); 
   
                this.setState({
                    ShowProcedure: false,
                    ShowBar:false
                })

                if (response.data.status == true) {
                  this.refreshList();
 
                    Swal.fire({
                        icon: 'success',
                        text: response.data.message,
                        confirmButtonColor: '#d33',
                        confirmButtonText: 'OK'
                    })
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: response.data.message,
                        confirmButtonColor: '#d33',
                        confirmButtonText: 'OK'
                    })
                }
            })
            .catch(error => {
                console.log(error);
            });

         

      // sessionStorage.setItem("quote_client_id",row.client_id);
      // sessionStorage.setItem("quote_client_name",row.company_name);
      // sessionStorage.setItem("quote_speciality_id",row.specialty_id);
      // sessionStorage.setItem("quote_procedure_type_id",row.procedure_type_id);
      // sessionStorage.setItem("quote_country_id",row.country_id);
      // sessionStorage.setItem("quote_no_of_kol",row.no_of_kol);



      // this.setState({redirectCreateProject:true})
    }
    else
    {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Payment Status not updated!',
        // showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    }
  }








// Approved_client(row,status) {

// if(row.status !="Approved")
// {

//     this.setState({
//             ShowProcedure: true,
//             ShowBar:true
//         })

//        var cl_status="";
//        if(status=="Approve")
//        {
//         cl_status="Approved";
//        }
//        else
//        {
//         cl_status="Rejected";
//        }

      
//         //var id=row.id;
       
//          var client_info={"client_name":row.client_name,"company_nm":row.company_nm,"email_id":row.email_id,"mobile_no":row.mobile_no,"signup_method":row.signup_method,"username":"","password":"","client_id":"","login_type":"pre_client","signup_method":row.signup_method,"status":"Active"};
        
//          var Signup_data={"id":row.id,"client_name":row.client_name,"status":cl_status,"followup_date":curr_date,"email_id":row.email_id};

//          const url = global.websiteUrl+'/service/KOL_landing/approved_signup_data';
//          let formData = new FormData();
        
//          if(cl_status=="Approved")
//          {
//              formData.append('client_info',JSON.stringify(client_info));
//          }

//             formData.append('Signup_data',JSON.stringify(Signup_data));
            
//             const config = {
//                 // headers: { 'content-type': 'multipart/form-data' }
//             }

//             Axios.post(url, formData, config)
//                 .then(response => {
//                     console.log(response.data); 
            
//               this.refreshSignupList();
       
//                     this.setState({
//                         ShowProcedure: false,
//                         ShowBar:false
//                     })

//                     //alert(response.data.status);

//                     if (response.data.status == true) {
//                         Swal.fire({
//                             icon: 'success',
//                             text: response.data.message,
//                             confirmButtonColor: '#d33',
//                             confirmButtonText: 'OK'
//                         })
//                     }
//                     else {
//                         Swal.fire({
//                             icon: 'error',
//                             text: response.data.message,
//                             confirmButtonColor: '#d33',
//                             confirmButtonText: 'OK'
//                         })
//                     }
//                 })
//                 .catch(error => {
//                     console.log(error);
//                 });

          
//               }       
    
// }


Quotestatuscolour = (cell, row) => {
  var id = row.status;
  var col='';

    if (row.status == "Pending") {
          
          col= '#41A2FF'
    }
    else
    {
           col= '#1FCE39'
          
    }
    

  return (
    <div style={{color:col,fontWeight:'bold'}}>
     {row.status}
    </div>
  )
}


paymentstatuscolour = (cell, row) => {
  var id = row.payment_status;
  var col='';

    if (row.payment_status == "Success") {
          
          col= '#1FCE39'
    }
    else if (row.payment_status == "Failed") {
      
              col= '#e51c1c'
          
    }
    if (row.payment_status == "Pending") {
          col= '#41A2FF'

    }
  

  return (
    <div style={{color:col,fontWeight:'bold'}}>
     {row.payment_status}
    </div>
  )
}




  // Funtion for displaying 'Edit' & 'delete' buttons inside Bootstrap Grid
  linkFollow = (cell, row, rowIndex, formatExtraData) => {
    // if(row.status == "Success")
    // {
    //   alert(row.status); 
      
    // }else
    // {
    //   this.setState({button_disable:false,})
    // }

         
    return (
      <div>
 {/* onClick={this.edit.bind(this, row.id, row.emp_name, row.role_type, row.email_id, row.username, row.password)}> */}
         
       

        <button className="cetas-button cetas-button-small mr-2" disabled={(row.status=="Success")?'true':''}
          onClick={this.togglePaymentStatusModal.bind(this,row)}>
          Update Payment
        </button>

         <button className="cetas-button cetas-button-small mr-2" style={{marginTop:'5px'}} disabled={(row.status=="Complete")?'true':''}
          onClick={this.toggleCreateNewProject.bind(this,row)}>
              { (row.client_id =='Guest')?'Create Client':'Assign kol' }  
        </button>

        
      </div>
    );
  };

  // STARNGE  
  onPriceChange = (event) => {
    console.log(event.target.value)
    const { value } = event.target;
    this.onPriceFilter(value);
  };

  //STRANGE
  onProductilter = (event) => {
    const { value } = event.target;
    this.onProductilters(value);
  };

  //////////////////////////////////////// END of react bootstrap Column functions//////////////////////////////////////////////////////////////////      

  ////////////////////////////////////////Component Did Mount Method//////////////////////////////////////////////////////////////////      
  // Method to fetch data from API when component Mounted
  componentDidMount= async () => {

    let getSession_Manager_Name = await sessionStorage.getItem('name')
        if (getSession_Manager_Name !== null) {
            await this.setState({
                manager_name: getSession_Manager_Name,
            })
        } 
        
    this.setState({ ShowBar: false,
      progressBar: false })

    const headers = new Headers()

    const options = {
      method: 'POST',
    }
    fetch(global.websiteUrl+'/service/KOL_landing/get_QuotaionData', options)
      
    //fetch(global.websiteUrl+'/service/Employee/get_EmployeeList', options)
      .then((Response) => Response.json())
      .then((findresponse) => {
        console.log(findresponse)
        var testData = findresponse.QuotaionData;
        console.log("testData", JSON.stringify(testData));

        this.setState({
          QuotaionData: findresponse.QuotaionData,
          progressBar: false
        })

        if (findresponse.status == false) {
          this.setState({
            QuotaionData: [],
          })
        }
        
      })


       //  Speciality type data
    fetch(global.websiteUrl+'/service/Speciality/get_SpecialityList', options)
    .then((Response) => Response.json())
    .then((findresponse) => {
      console.log(findresponse)
      var testDataSpeciality = findresponse.data;
      console.log("testDataSpeciality", JSON.stringify(testDataSpeciality));
      this.setState({
        SpecialityData: findresponse.data,
        ShowBar: false,
        progressBar: false,
      })
      
      console.log("Length of SpecialityData ", this.state.SpecialityData.length);
     // alert(this.state.SpecialityData.length)
    })
  
  }

  validate = () => {
    let PaymentStatusErr = '';
    let pvalueErr='';
    let commentsErr='';
     
    if (!this.state.paymentinfo.payment_status)
   {
      PaymentStatusErr = 'Please Select Payment Status';
    }
  //  if (!this.state.paymentinfo.paymentvalue)
  //  {
  //     pvalueErr = 'Please fill Payment Value';
  //   }

  //   if (!this.state.paymentinfo.comment)
  //  {
  //     commentsErr = 'Please write comment';
  //   }

    // if (PaymentStatusErr || pvalueErr ) {
    //   this.setState({ PaymentStatusErr,pvalueErr });
    //   return false;
    // }

    if (PaymentStatusErr) {
      this.setState({ PaymentStatusErr});
      return false;
    }

    return true;
  }



  UpdatePaymentfun(row)
  {
        
  const isValid = this.validate();
    //Alert(isValid);
  if (isValid) {
      
  this.setState({
      ShowProcedure: true,
      ShowBar:true
  })

 
   const url = global.websiteUrl+'/service/Project/Update_Client_Payment_Status';
   let formData = new FormData();  
    formData.append('pay_data',JSON.stringify(this.state.paymentinfo));
      
      const config = {
          // headers: { 'content-type': 'multipart/form-data' }
      }

      Axios.post(url, formData, config)
          .then(response => {
              console.log(response.data); 
              this.setState({
                  ShowProcedure: false,
                  ShowBar:false
              })

//            alert(response.data.status);

              if (response.data.status == true) {
                  Swal.fire({
                      icon: 'success',
                      text:'Update Successfull',
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK'
                  })
              }
              else {
                  Swal.fire({
                      icon: 'error',
                      text: response.data.message,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK'
                  })
              }

              this.setState({PaymentStatusModal:false,
                 // Redirect:true
              
              })
        
              this.refreshList();
 
              //window.location.reload(true);
          })
          .catch(error => {
              console.log(error);
          });

    
        }


  }



  


  // Whenever employee gets added, deleted or edited function will refresh emplyee list
  refreshList() {

    this.setState({ ShowBar: true })
    try {

      console.log('I am in referesh category');
      const headers = new Headers()

      const options = {
        method: 'POST',
      }

      fetch(global.websiteUrl+'/service/KOL_landing/get_QuotaionData', options)
    //fetch(global.websiteUrl+'/service/KOL_landing/get_QuotaionData', options)
        
      //fetch(global.websiteUrl+'/service/Employee/get_EmployeeList', options)
        .then((Response) => Response.json())
        .then((findresponse) => {
          console.log(findresponse)
          var testData = findresponse.QuotaionData;
          console.log("testData", JSON.stringify(testData));

          this.setState({
            QuotaionData: findresponse.QuotaionData,
            progressBar: false,
            ShowBar:false
          })
  
          
          
        })



    } catch (error) {
      console.log(error)
    }

  }

 
  
  //////////////////////////////////////// Functions for toggling forms ///////////////////////////////////////////////////////////////////////
  // Function for toggling Add Form
  toggleaddModal() {
    this.setState({
      isAdd: !this.state.isAdd,
      nameError: null,
      roleError: null,
      // usernameError: null,
      emailError: null,
      passwordError: null,
      addData: {
        ...this.state.addData,
        // id: '', 
        emp_name: null,
        role_type: null,
        email_id: null,
        username: null,
        password: null,
      }
    });
  }

  // Function to toggle Edit Form and To store data of edit form into states(editData)
  edit(id, emp_name, role_type, email_id, username, password) {
    console.log(email_id);
    console.log(id);
    this.setState({
      editData: { id, emp_name, role_type, email_id, username, password },
      isEdit: !this.state.isEdit,
      editnameError: null,
      editroleError: null,
      // editusernameError: null,
      editemailError: null,
      editpasswordError: null,
    });
  }


  render() {

    if (this.state.redirect) {
      return (<Redirect to={'/'}/>)
}

    if (this.state.redirectCreateProject) {
      return (<Redirect to={{
          pathname: '/CreateProject',
          
      }} />)
  }

    // for rendering projects and kol Profiles
    const expandRow = {
      renderer: row => {
        if (row.project_details) {
          return (
            <table style={{ width: '100%', height: '100%' }}>
              <thead >
                <th className="projectnameEmployee">Project Name</th>
                <th >required Kol's</th>
              </thead>
              {row.project_details.map((data) => {
                return (
                  <tbody>
                    <tr>
                      <td>{data.p_name}</td>
                      <td>{data.required_kol_profile}</td>
                    </tr>
                  </tbody>
                )
              })}
            </table>
          )
        }
      }
    };

    
    // For rendering pageination buttons
    const pageButtonRenderer = ({
      page,
      active,
      disable,
      title,
      onPageChange
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        onPageChange(page);
      };
      return (
        <li class="page-item" onClick={handleClick} ><a class="page-link" href="#">{page}</a></li>
      );
    };

    // options for passing values to bootstrap table 2
    const options = {
      pageButtonRenderer,
      paginationSize: 4,
      pageStartIndex: 1,
      prePageText: 'Previous',
      nextPageText: 'Next',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '50', value: 50
      }, {
        text: '100', value: 100
      }, {
        text: '200', value: 200
      }, {
        text: 'All', value: this.state.QuotaionData.length
      }]  // A numeric array is also available. the purpose of above example is custom the text

    };

    return (
      <div style={{marginTop:'12vh'}}>
        {this.state.ShowBar ? <Modal isOpen={true} centered>
          <PropagateLoader color={'#A90007'} size='30px' loading='true' />
        </Modal> : null}
        {this.state.progressBar ? <div style={{ position: 'fixed', top: '0' }}>
          <LoadingBar
            progress='100'
            height={3} color="#A90007" transitionTime={4000}
            loaderSpeed={3000} />
        </div>
          : null}
        <div>
      <div >
            {/* {/ page /} */}
            <ManagerHeader  ManagerName={this.state.manager_name}/>
            <div className="container">
              <div className="content-wrapper">
                <div className="screen-headingbox">
                  <div className="back-section" style={{marginTop:'20px'}}>
                    <a>
                      <Link to="/ManagerDashboard">
                        <img
                          src={require('../Assets/Images/back-arrow.svg')}
                        />
                        Back
                      </Link>
                    </a>
                  </div>
                  <h2>Quotation list</h2>
                        
                  {/* <h2>Signup Management<button className="cetas-button float-right" data-toggle="modal" data-target="#new-empl-add" onClick={this.toggleaddModal.bind()}>+ Add New Employee</button></h2> */}
                </div>
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12 emplist-box">
                    <div className="row">
                      <div className="col-md-12">
                        {/* <h3>Client Signup List</h3> */}
                        <div className="employee-actions">
                         
                        </div>
                        <ToolkitProvider
                          keyField="client_id"
                          data={this.state.QuotaionData}
                          columns={this.state.columns}
                          search={this.customMatchFunc}
                          //exportCSV
                          exportCSV={{
                            fileName: 'cetas.csv',
                            noAutoBOM: false,
                          }}
                        >
                          {
                            props => (
                              <div>
                                {/* <ExportCSVButton { ...props.csvProps }>Export CSV!!</ExportCSVButton> */}
                                {/* <hr /> */}
                                <BootstrapTable
                                  {...props.baseProps}
                                  // selectRow={ selectRow }
                                  noDataIndication="Table is Empty"
                                  expandRow={expandRow}
                                  striped
                                  hover
                                  condensed
                                  loading={true}
                                  pagination={paginationFactory(options)}
                                  filter={filterFactory()}
                                />
                              </div>
                            )
                          }
                        </ToolkitProvider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


    <Modal isOpen={this.state.PaymentStatusModal} toggle={this.togglePaymentStatusModal.bind(this)} centered>
    <ModalHeader toggle={this.togglePaymentStatusModalCancle.bind(this)}
        cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
        style={{ borderBottom: '0px' }}>
        <h3 style={{ justifyContent: 'center', marginTop: '30px' }} id="login-tit">Payment Status Update</h3>
    </ModalHeader>
    <ModalBody style={{ padding: '0px 80px 0px 80px' }}>

            <FormGroup>
         
            <Input type="select" id="engStatusIN" name="payment"  style={{ height: '50px' }}
               // value={this.state.kol_researcher_info.incentive_status}
                onChange={async (e) => {
                    let { paymentinfo } = this.state;
                    paymentinfo.payment_status = e.target.value;  
                    //alert(e.event.value);
                    if (e.target.value === 'Success') {

                      this.setState({comment_box: false, payment_value_box: true });
                    } else {
                      this.setState({ payment_value_box: false, comment_box: true });
                    }              
                    await this.setState({ paymentinfo });
                   
                }}
                >
                <option value="" >--Select Payment Status--</option>              
                <option value="Success" >Success</option>
                <option value="Pending" >Pending</option>
                <option value="Failed" >Failed</option>
              
            </Input>
            <div className="loginErrCreateProject">{this.state.PaymentStatusErr}</div>
                
            {/* <div className="centerErrLogin" style={{ width:'100%',marginTop:'10px',textAlign:'left',fontWeight:'bold' }}>{this.state.PaymentStatusErr}</div>  */}
       


                            {/* <label for="exampleFormControlTextarea1">Project Scope</label>
                                <span class="mandatory">*</span> */}
                                 {
                                  (!this.state.payment_value_box) ? (
                                    <div></div>
                                  ) : ( <div>
                            <label for="exampleFormControlTextarea1" style={{ marginTop:'20px'}}>
                              Payment value (in $)
                               </label>
                              <input type="text" className="form-control" id="exampleInputPassword1"
                                                                  placeholder="Enter payment value" 
                             
                                            onChange={(e) => {
                                                let { paymentinfo } = this.state;
                                                paymentinfo.paymentvalue = e.target.value;
                                                this.setState({ paymentinfo });
                                            }}
                            />
                           
                      
                          <div className="loginErrCreateProject">{this.state.pvalueErr}</div>

                          </div> )} 
                          {/* </FormGroup>
        <FormGroup> */}
          
          {
                                  (!this.state.comment_box) ? (
                                    <div></div>
                                  ) : ( <div>
            <label for="exampleFormControlTextarea1" style={{ marginTop:'20px'}}>
                              Comments </label>

                              <textarea className="form-control" id="exampleFormControlTextarea1"
                                      placeholder="Enter comments" rows="3"
                                      // value={this.state.ProjectScope}
                                      onChange={(e) => {
                                          let { paymentinfo } = this.state;
                                          paymentinfo.comment = e.target.value;
                                          this.setState({ paymentinfo });
                                      }}
                                  >
                              </textarea>
                              <div className="loginErrCreateProject">{this.state.commentsErr}</div>
                              </div> )}   
{/* 
                              <input type="text" className="form-control" id="exampleInputPassword1"
                                                                  placeholder="Enter payment value" 
                             
                                            onChange={(e) => {
                                                let { paymentinfo } = this.state;
                                                paymentinfo.paymentvalue = e.target.value;
                                                this.setState({ paymentinfo });
                                            }}
                            /> */}
                           
                      
                        
       
        </FormGroup>


    </ModalBody>
    <ModalFooter style={{ borderTop: '0px' }}>
        <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
            onClick={this.togglePaymentStatusModalCancle.bind(this)}>Cancel</button>
        <button type="submit" class="cetas-button" style={{ marginRight: '20%', }}
            onClick={this.UpdatePaymentfun.bind(this)}>
            Update
        </button>

    </ModalFooter>
    </Modal>


          </div>
        </div>
      </div>
    );


    

  }
}

export default ReviewQuote;