import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button } from 'reactstrap';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {
    textFilter, customFilter, selectFilter, Comparator,
    FILTER_TYPES
} from 'react-bootstrap-table2-filter';
import Swal from 'sweetalert2';
import LoadingBar from "react-top-loading-bar";
import { RingLoader, FadeLoader, DotLoader, PropagateLoader } from 'react-spinners';

import { Chart } from "react-google-charts";

//datatable npm
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';

import AdminHeader from './AdminHeader';

const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total ml-2">
      Showing {from} to {to} of {size} Results
    </span>
  );

class ParameterConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ShowProcedure: false,
            ShowBar: false,
            ParameterList: [],
            EditEventModal: false,
            EditPublicationModal: false,
            EditClinicalModal: false,
            EditGuidelineModal: false,

            // 
            event_piechart_flag: true,
            publication_piechart_flag: false,
            ad_board_piechart_flag: false,
            guideline_piechart_flag: false,
            clinicaltrial_piechart_flag: false,

            // error variable
            event_score_chairperson_Err: '',
            event_score_keynote_speaker_Err: '',
            event_score_e_score_div_by_Err: '',
            event_score_global_Err: '',
            event_score_local_Err: '',
            event_score_manual_weigtage_mul_by_Err: '',
            event_score_r_score_div_by_Err: '',
            event_score_national_Err: '',
            event_score_regional_Err: '',
            event_score_regular_Err: '',
           // event_score_modarator_Err: '',
            event_score_session_chair_Err: '',
            event_in_lifetime_Err: '',
            event_in_per_year_Err: '',


            publication_score_first_author_Err: '',
            publication_score_second_author_Err: '',
            publication_score_last_author_Err: '',
            publication_in_lifetime_Err: '',
            publication_in_per_year_Err: '',
            publication_score_e_score_div_by_Err: '',
            publication_score_global_Err: '',
            publication_score_national_Err: '',
            publication_score_local_Err: '',
            publication_score_manual_weigtage_mul_by_Err: '',
            publication_score_r_score_div_by_Err: '',
            publication_score_regional_Err: '',

            clinical_score_study_chair_Err: '',
            clinical_score_study_director_Err: '',
            clinical_score_investigator_Err: '',
            clinical_score_principle_investigator_Err: '',
            clinical_in_lifetime_Err: '',
        clinical_in_per_year_Err: '',
        
            clinical_score_e_score_div_by_Err: '',
            clinical_score_global_Err: '',
            clinical_score_local_Err: '',
            clinical_score_interventional_Err: '',
            clinical_score_manual_weigtage_mul_by_Err: '',
            clinical_score_national_Err: '',
            clinical_score_observational_Err: '',
            clinical_score_regional_Err: '',
            clinical_score_study_locations_1_Err: '',
            clinical_clinical_score_study_locations_10_Err: '',
            clinical_clinical_score_study_locations_50_Err: '',
            clinical_clinical_score_study_locations_10000_Err: '',
            clinical_clinical_score_c_score_div_by_Err: '',
            clinical_clinical_score_type_div_by_Err: '',

           // board_score_advisory_committee_Err: '',
           board_score_member_Err: '',
            board_score_chair_Err: '',
            board_score_co_chair_Err: '',
            board_in_lifetime_Err: '',
            board_in_per_year_Err: '',

            board_score_e_score_div_by_Err: '',
            board_score_global_Err: '',
            board_score_national_Err: '',
            board_score_local_Err: '',
            board_score_manual_weigtage_mul_by_Err: '',
            board_score_r_score_div_by_Err: '',
            board_score_regional_Err: '',

            guideline_score_o_score_div_by_Err: '',
            guideline_score_authore_Err: '',
            Guideline_score_committee_chair_Err: '',
            guideline_score_committee_member_Err: '',
            Guideline_in_lifetime_Err: '',
            Guideline_in_per_year_Err: '',
            guideline_score_global_Err: '',
            guideline_score_local_Err: '',
            guideline_score_manual_weigtage_mul_by_Err: '',
            guideline_score_regional_Err: '',
            guideline_score_national_Err: '',

            data : [
                ["Hours Studied", "Final"],
                [0,67],
            ],

            data2: [
                ["Label", "Value"],
                ["Memory", '20'],
                ["CPU", '30'],
                ["Network", '50'],
            ],
        };
    }


    componentDidMount() {
        this.setState({
            ShowBar: true,
        })
        
        const options = {
            method: 'POST',
        }

        fetch(global.websiteUrl+'/service/KOL/get_score', options)
        .then((Response) => Response.json())
        .then((findresponse) => {
            console.log(findresponse)
            var testData = findresponse.data;
            console.log("testDataget_score=====", JSON.stringify(testData));
            this.setState({
                ParameterList: findresponse.data,
            })
            this.setState({
                ShowBar: false,
            })
        })

    }

     //Sub Navbar functions of summary piechart
  HomePiechartFun = async () => {
    await this.setState({
      home_piechart_flag: true,
      event_piechart_flag: false,
      publication_piechart_flag: false,
      ad_board_piechart_flag: false,
      guideline_piechart_flag: false,
      clinicaltrial_piechart_flag: false,
    });
  }
  EventPiechartFun = async () => {
    await this.setState({
      home_piechart_flag: false,
      event_piechart_flag: true,
      publication_piechart_flag: false,
      ad_board_piechart_flag: false,
      guideline_piechart_flag: false,
      clinicaltrial_piechart_flag: false,
    });
  }
  PublicationPiechartFun = async () => {
    await this.setState({
      home_piechart_flag: false,
      event_piechart_flag: false,
      publication_piechart_flag: true,
      ad_board_piechart_flag: false,
      guideline_piechart_flag: false,
      clinicaltrial_piechart_flag: false,
    });
  }
  AdboardPiechartFun = async () => {
    await this.setState({
      home_piechart_flag: false,
      event_piechart_flag: false,
      publication_piechart_flag: false,
      ad_board_piechart_flag: true,
      guideline_piechart_flag: false,
      clinicaltrial_piechart_flag: false,
    });
  }
  GuidelinePiechartFun = async () => {
    await this.setState({
      home_piechart_flag: false,
      event_piechart_flag: false,
      publication_piechart_flag: false,
      ad_board_piechart_flag: false,
      guideline_piechart_flag: true,
      clinicaltrial_piechart_flag: false,
    });
  }
  ClinicaltrialPiechartFun = async () => {
    await this.setState({
      home_piechart_flag: false,
      event_piechart_flag: false,
      publication_piechart_flag: false,
      ad_board_piechart_flag: false,
      guideline_piechart_flag: false,
      clinicaltrial_piechart_flag: true,
    });
  }

  toggleEditEventModal = async () => {
      console.log("toggleEditEventModal")
      this.setState({
        EditEventModal: !this.state.EditEventModal,
      })
  }

  CloseEditEventModal = async () => {
    console.log("CloseEditEventModal")
    this.setState({
        EditEventModal: false,
        event_score_chairperson_Err:'', 
        event_score_keynote_speaker_Err: '',
        event_score_e_score_div_by_Err: '',
        event_score_global_Err: '',
        event_score_local_Err: '',
        event_score_manual_weigtage_mul_by_Err: '',
        event_score_r_score_div_by_Err: '',
        event_score_national_Err: '',
        event_score_regional_Err: '',
        event_score_regular_Err: '',
       // event_score_modarator_Err: '',
        event_score_session_chair_Err: '',
        event_in_lifetime_Err: '',
        event_in_per_year_Err: '',

    })
    const options = {
        method: 'POST',
    }
    this.setState({ ShowBar: true })

    fetch(global.websiteUrl+'/service/KOL/get_score', options)
    .then((Response) => Response.json())
    .then((findresponse) => {
        console.log(findresponse)
        var testData = findresponse.data;
        console.log("testDataget_score=====", JSON.stringify(testData));
        this.setState({
            ParameterList: findresponse.data,
        })
        this.setState({
            ShowBar: false,
        })
        
    })
}

validateEventScore() {
    let event_score_chairperson_Err = '';
    let event_score_keynote_speaker_Err = '';
    let event_score_e_score_div_by_Err = '';
    let event_score_global_Err = '';
    let event_score_local_Err = '';
    let event_score_manual_weigtage_mul_by_Err = '';
    let event_score_r_score_div_by_Err = '';
    let event_score_national_Err = '';
    let event_score_regional_Err = '';
    let event_score_regular_Err = '';
    //let event_score_modarator_Err= '';
    let event_score_session_chair_Err= '';
    let event_in_lifetime_Err= '';
    let event_in_per_year_Err= '';

    if (!this.state.ParameterList.event_score_chairperson) {
        event_score_chairperson_Err = 'Please Select Event Score Chairperson';
    }
    if (!this.state.ParameterList.event_score_keynote_speaker) {
        event_score_keynote_speaker_Err = 'Please Select Event Score Keynote Speaker';
    }
    if (!this.state.ParameterList.event_score_e_score_div_by) {
        event_score_e_score_div_by_Err = 'Please Select Event Score e Divided By';
    }
    if (!this.state.ParameterList.event_score_global) {
        event_score_global_Err = 'Please Select Event Score Global';
    }
    if (!this.state.ParameterList.event_score_local) {
        event_score_local_Err = 'Please Select Event Score Local';
    }
    if (!this.state.ParameterList.event_score_manual_weigtage_mul_by) {
        event_score_manual_weigtage_mul_by_Err = 'Please Select Event Score Manual Weigtage Multiply By';
    }
    if (!this.state.ParameterList.event_score_r_score_div_by) {
        event_score_r_score_div_by_Err = 'Please Select Event Score r Divided By';
    }
    if (!this.state.ParameterList.event_score_national) {
        event_score_national_Err = 'Please Select Event Score National';
    }
    if (!this.state.ParameterList.event_score_regional) {
        event_score_regional_Err = 'Please Select Event Score Regional';
    }
    if (!this.state.ParameterList.event_score_regular) {
        event_score_regular_Err = 'Please Select Event Score Regular';
    }
    // if (!this.state.ParameterList.event_score_moderator ) {
    //     event_score_modarator_Err = 'Please Select Event Score Moderator';
    // }
    if (!this.state.ParameterList.event_score_session_chair ) {
        event_score_session_chair_Err = 'Please Select Event Score Session Chair';
    }

    if (!this.state.ParameterList.event_in_lifetime ) {
        event_in_lifetime_Err = 'Please Select Event in lifetime';
    }

    if (!this.state.ParameterList.event_in_per_year ) {
        event_in_per_year_Err = 'Please Select Event in Year';
    }
  
 

    if (event_score_chairperson_Err || event_score_keynote_speaker_Err || event_score_e_score_div_by_Err ||
        event_score_global_Err || event_score_local_Err || event_score_manual_weigtage_mul_by_Err ||
        event_score_r_score_div_by_Err || event_score_national_Err || event_score_regional_Err || 
        event_score_regular_Err  || event_score_session_chair_Err || event_in_lifetime_Err || event_in_per_year_Err) {
        this.setState({ event_score_chairperson_Err, event_score_keynote_speaker_Err, event_score_e_score_div_by_Err,
            event_score_global_Err, event_score_local_Err, event_score_manual_weigtage_mul_by_Err,
            event_score_r_score_div_by_Err, event_score_national_Err, event_score_regional_Err,
            event_score_regular_Err, event_score_session_chair_Err ,event_in_lifetime_Err ,event_in_per_year_Err});
        return false;
    }
    return true;
}

EditEventModalSubmit = async () => {
    const isValid = this.validateEventScore();
    if (isValid) {
    console.log("EditEventModalSubmit");
    this.setState({
        EditEventModal: false,
        ShowBar: true,
        event_score_chairperson_Err:'', 
        event_score_keynote_speaker_Err: '',
        event_score_regular_Err: '',
        //event_score_modarator_Err: '',
        event_score_session_chair_Err: '',

        event_score_e_score_div_by_Err: '',
        event_score_global_Err: '',
        event_score_local_Err: '',
        event_score_manual_weigtage_mul_by_Err: '',
        event_score_r_score_div_by_Err: '',
        event_score_national_Err: '',
        event_score_regional_Err: '',
        
        event_in_lifetime_Err: '',
        event_in_per_year_Err: '',

    })

    const url = global.websiteUrl+'/service/KOL/update_score';
    let formData = new FormData();
    formData.append('event_score_keynote_speaker', this.state.ParameterList.event_score_keynote_speaker);
    formData.append('event_score_regular', this.state.ParameterList.event_score_regular);
    formData.append('event_score_chairperson', this.state.ParameterList.event_score_chairperson);
    formData.append('event_score_session_chair', this.state.ParameterList.event_score_session_chair);
    
    formData.append('event_in_lifetime', this.state.ParameterList.event_in_lifetime);
    formData.append('event_in_per_year', this.state.ParameterList.event_in_per_year);
 
//    // formData.append('event_score_moderator ', this.state.ParameterList.event_score_moderator );
   
    formData.append('event_score_global', this.state.ParameterList.event_score_global);
    formData.append('event_score_regional', this.state.ParameterList.event_score_regional);
    formData.append('event_score_national', this.state.ParameterList.event_score_national);
    formData.append('event_score_local', this.state.ParameterList.event_score_local);
   
    formData.append('event_score_e_score_div_by', this.state.ParameterList.event_score_e_score_div_by);
    formData.append('event_score_r_score_div_by', this.state.ParameterList.event_score_r_score_div_by);
    formData.append('event_score_manual_weigtage_mul_by', this.state.ParameterList.event_score_manual_weigtage_mul_by);
    
   

    const config = {
    //   headers: { 'content-type': 'multipart/form-data' }
    }
  
    axios.post(url, formData, config)
        .then(response => {
            this.setState({ ShowBar: false })
            console.log(response);
            var lgdata = response.data;
            console.log("lgdata=====", JSON.stringify(lgdata));
            var lgstatus = response.data.status;
            var message = response.data.message
            console.log("message", message)
            if (lgstatus == true) {
                Swal.fire({
                  icon: 'success',
                  text: message,
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
    
                })
              }
            else {
                Swal.fire({
                  icon: 'error',
                  text: message,
                  // text: 'Wrong Username or Password!',
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
                const options = {
                    method: 'POST',
                }
                fetch(global.websiteUrl+'/service/KOL/get_score', options)
                .then((Response) => Response.json())
                .then((findresponse) => {
                    console.log(findresponse)
                    var testData = findresponse.data;
                    console.log("testDataget_score=====", JSON.stringify(testData));
                    this.setState({
                        ParameterList: findresponse.data,

                    })
                    this.setState({
                        ShowBar: false,
                    })
                    
                })
            }
        })
    }
}

toggleEditPublicationModal = async () => {
    console.log("toggleEditPublicationModal")
    this.setState({
        EditPublicationModal: !this.state.EditPublicationModal,
        publication_score_first_author_Err: '',
        publication_score_second_author_Err: '',
        publication_score_last_author_Err: '',
        publication_in_lifetime_Err: '',
        publication_in_per_year_Err: '',
        publication_score_e_score_div_by_Err: '',
        publication_score_global_Err: '',
        publication_score_national_Err: '',
        
        publication_score_local_Err: '',
        publication_score_manual_weigtage_mul_by_Err: '',
        publication_score_r_score_div_by_Err: '',
        publication_score_regional_Err: '',
    })
}

ClosePublicationEventModal = async () => {
    console.log("ClosePublicationEventModal")
    this.setState({
        EditPublicationModal: false,
        publication_score_first_author_Err: '',
        publication_score_second_author_Err: '',
        publication_score_last_author_Err: '',
        publication_in_lifetime_Err: '',
        publication_in_per_year_Err: '',
        publication_score_e_score_div_by_Err: '',
        publication_score_global_Err: '',
        publication_score_national_Err: '',
        publication_score_local_Err: '',
        publication_score_manual_weigtage_mul_by_Err: '',
        publication_score_r_score_div_by_Err: '',
        publication_score_regional_Err: '',
    })
    const options = {
        method: 'POST',
    }
    this.setState({ ShowBar: true })

    fetch(global.websiteUrl+'/service/KOL/get_score', options)
    .then((Response) => Response.json())
    .then((findresponse) => {
        console.log(findresponse)
        var testData = findresponse.data;
        console.log("testDataget_score=====", JSON.stringify(testData));
        this.setState({
            ParameterList: findresponse.data,

        })
        this.setState({
            ShowBar: false,
        })
        
    })
}

validatePublicationScore() {
    let publication_score_first_author_Err = '';
    let publication_score_second_author_Err = '';
    let publication_score_last_author_Err = '';
    let publication_in_lifetime_Err= '';
   let publication_in_per_year_Err= '';
    let publication_score_e_score_div_by_Err = '';
    let publication_score_global_Err = '';
    let publication_score_national_Err = '';
    let publication_score_local_Err = '';
    let publication_score_manual_weigtage_mul_by_Err = '';
    let publication_score_r_score_div_by_Err = '';
    let publication_score_regional_Err = '';

    if (!this.state.ParameterList.publication_score_first_author) {
        publication_score_first_author_Err = 'Please Enter Publication Score First Author';
    }
    if (!this.state.ParameterList.publication_score_second_author) {
        publication_score_second_author_Err = 'Please Enter Publication Score Second Author';
    }
    if (!this.state.ParameterList.publication_score_last_author) {
        publication_score_last_author_Err = 'Please Enter Publication Score Last Author';
    }

    if (!this.state.ParameterList.publication_in_lifetime) {
        publication_in_lifetime_Err = 'Please Enter Publication Total in Lifetime';
    }
    
    if (!this.state.ParameterList.publication_in_per_year) {
        publication_in_per_year_Err = 'Please Enter Publication Total in Lifetime';
    }

    if (!this.state.ParameterList.publication_score_e_score_div_by) {
        publication_score_e_score_div_by_Err = 'Please Enter Publication Score e Divided by';
    }
    if (!this.state.ParameterList.publication_score_global) {
        publication_score_global_Err = 'Please Enter Publication Score Global';
    }
    if (!this.state.ParameterList.publication_score_national) {
        publication_score_national_Err = 'Please Enter Publication Score National';
    }
    if (!this.state.ParameterList.publication_score_local) {
        publication_score_local_Err = 'Please Enter Publication Score Local';
    }
    if (!this.state.ParameterList.publication_score_manual_weigtage_mul_by) {
        publication_score_manual_weigtage_mul_by_Err = 'Please Enter Publication Score Manual Weigtage Multiply by';
    }
    if (!this.state.ParameterList.publication_score_r_score_div_by) {
        publication_score_r_score_div_by_Err = 'Please Enter Publication Score r Divided by';
    }
    if (!this.state.ParameterList.publication_score_regional) {
        publication_score_regional_Err = 'Please Enter Publication Score Regional';
    }
  

    if (publication_score_first_author_Err ||  publication_score_second_author_Err || publication_score_last_author_Err || publication_in_lifetime_Err || publication_in_per_year_Err ||
        publication_score_e_score_div_by_Err || publication_score_global_Err || publication_score_national_Err || publication_score_local_Err || 
        publication_score_manual_weigtage_mul_by_Err || publication_score_r_score_div_by_Err || publication_score_regional_Err ) {
        this.setState({ publication_score_first_author_Err, publication_score_second_author_Err, publication_score_last_author_Err, publication_in_lifetime_Err,publication_in_per_year_Err,
            publication_score_e_score_div_by_Err, publication_score_global_Err,publication_score_national_Err, publication_score_local_Err,
            publication_score_manual_weigtage_mul_by_Err, publication_score_r_score_div_by_Err, publication_score_regional_Err });
        return false;
    }
    return true;
}

EditPublicationModalSubmit = async () => {
    const isValid = this.validatePublicationScore();
    if (isValid) {
    console.log("EditPublicationModalSubmit");
    this.setState({
        EditPublicationModal: false,
        ShowBar: true,
        EditPublicationModal: false,
        publication_score_first_author_Err: '',
        publication_score_second_author_Err: '',
        publication_score_last_author_Err: '',
        publication_in_lifetime_Err: '',
        publication_in_per_year_Err: '',
        publication_score_e_score_div_by_Err: '',
        publication_score_global_Err: '',
        publication_score_national_Err: '',
        publication_score_local_Err: '',
        publication_score_manual_weigtage_mul_by_Err: '',
        publication_score_r_score_div_by_Err: '',
        publication_score_regional_Err: '',
    })

    const url = global.websiteUrl+'/service/KOL/update_score';
    let formData = new FormData();
    formData.append('publication_score_first_author', this.state.ParameterList.publication_score_first_author);
    formData.append('publication_score_second_author', this.state.ParameterList.publication_score_second_author);
    formData.append('publication_score_last_author', this.state.ParameterList.publication_score_last_author);
    formData.append('publication_score_e_score_div_by', this.state.ParameterList.publication_score_e_score_div_by);
    formData.append('publication_score_global', this.state.ParameterList.publication_score_global);
    formData.append('publication_score_national', this.state.ParameterList.publication_score_national);
    formData.append('publication_score_local', this.state.ParameterList.publication_score_local);
    formData.append('publication_score_manual_weigtage_mul_by', this.state.ParameterList.publication_score_manual_weigtage_mul_by);
    formData.append('publication_score_r_score_div_by', this.state.ParameterList.publication_score_r_score_div_by);
   // formData.append('event_score_regional', this.state.ParameterList.event_score_regional);
    formData.append('publication_score_regional', this.state.ParameterList.publication_score_regional);
    
    formData.append('publication_in_lifetime', this.state.ParameterList.publication_in_lifetime);
    formData.append('publication_in_per_year', this.state.ParameterList.publication_in_per_year);
   
   
    const config = {
    //   headers: { 'content-type': 'multipart/form-data' }
    }
  
    axios.post(url, formData, config)
        .then(response => {
            this.setState({ ShowBar: false })
            console.log(response);
            var lgdata = response.data;
            console.log("lgdata=====", JSON.stringify(lgdata));
            var lgstatus = response.data.status;
            var message = response.data.message
            console.log("message", message)
            if (lgstatus == true) {
                Swal.fire({
                  icon: 'success',
                  text: message,
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
    
                })
              }
            else {
                Swal.fire({
                  icon: 'error',
                  text: message,
                  // text: 'Wrong Username or Password!',
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
                const options = {
                    method: 'POST',
                }
                fetch(global.websiteUrl+'/service/KOL/get_score', options)
                .then((Response) => Response.json())
                .then((findresponse) => {
                    console.log(findresponse)
                    var testData = findresponse.data;
                    console.log("testDataget_score=====", JSON.stringify(testData));
                    this.setState({
                        ParameterList: findresponse.data,

                    })
                    this.setState({
                        ShowBar: false,
                    })
                    
                })
            }
        })
    }
}

toggleEditClinicalModal = async() => {
    console.log("toggleEditClinicalModal")
    this.setState({
        EditClinicalModal: !this.state.EditClinicalModal,
        // publication_score_first_author_Err: '',
        // publication_score_second_author_Err: '',
        // publication_score_last_author_Err: '',
        // publication_score_e_score_div_by_Err: '',
        // publication_score_global_Err: '',
        // publication_score_local_Err: '',
        // publication_score_manual_weigtage_mul_by_Err: '',
        // publication_score_r_score_div_by_Err: '',
        // publication_score_regional_Err: '',
    })
}

CloseClinicalEventModal = async() => {
    console.log("toggleEditClinicalModal")
    this.setState({
        EditClinicalModal: false,
        clinical_score_e_score_div_by_Err: '',
        clinical_score_study_chair_Err: '',
        clinical_score_study_director_Err: '',
        clinical_score_investigator_Err: '',
        clinical_score_principle_investigator_Err: '',
        clinical_in_lifetime_Err: '',
        clinical_in_per_year_Err: '',
        
        clinical_score_global_Err: '',
        clinical_score_local_Err: '',
        clinical_score_interventional_Err: '',
        clinical_score_manual_weigtage_mul_by_Err: '',
        clinical_score_national_Err: '',
        clinical_score_observational_Err: '',
        clinical_score_regional_Err: '',
        clinical_score_study_locations_1_Err: '',
        clinical_clinical_score_study_locations_10_Err: '',
        clinical_clinical_score_study_locations_50_Err: '',
        clinical_clinical_score_study_locations_10000_Err: '',
        clinical_clinical_score_c_score_div_by_Err: '',
        clinical_clinical_score_type_div_by_Err: '',
    })
    const options = {
        method: 'POST',
    }
    this.setState({ ShowBar: true })

    fetch(global.websiteUrl+'/service/KOL/get_score', options)
    .then((Response) => Response.json())
    .then((findresponse) => {
        console.log(findresponse)
        var testData = findresponse.data;
        console.log("testDataget_score=====", JSON.stringify(testData));
        this.setState({
            ParameterList: findresponse.data,

        })
        this.setState({
            ShowBar: false,
        })
        
    })
}

validateClinicalScore() {
    let clinical_score_e_score_div_by_Err = '';
    let clinical_score_study_chair_Err = '';
    let   clinical_score_study_director_Err = '';
    let clinical_score_investigator_Err = '';
    let clinical_score_principle_investigator_Err = '';
    let clinical_in_lifetime_Err= '';
     let   clinical_in_per_year_Err= '';
        
    let clinical_score_global_Err = '';
    let clinical_score_local_Err = '';
    let clinical_score_interventional_Err = '';
    let clinical_score_manual_weigtage_mul_by_Err = '';
    let clinical_score_national_Err = '';
    let clinical_score_observational_Err = '';
    let clinical_score_regional_Err = '';
    let clinical_score_study_locations_1_Err = '';
    let clinical_clinical_score_study_locations_10_Err = '';
    let clinical_clinical_score_study_locations_50_Err = '';
    let clinical_clinical_score_study_locations_10000_Err = '';
    let clinical_clinical_score_c_score_div_by_Err = '';
    let clinical_clinical_score_type_div_by_Err = '';

    if (!this.state.ParameterList.clinical_score_e_score_div_by) {
        clinical_score_e_score_div_by_Err = 'Please Enter Clinical Score e Divided by';
    }
    if (!this.state.ParameterList.clinical_score_study_chair) {
        clinical_score_study_chair_Err = 'Please Enter Clinical Score Study Chair';
    }
    if (!this.state.ParameterList.clinical_score_study_director) {
        clinical_score_study_director_Err = 'Please Enter Clinical Score Study Director';
    }
    if (!this.state.ParameterList.clinical_score_investigator) {
        clinical_score_investigator_Err = 'Please Enter Clinical Score Investigator';
    }
    if (!this.state.ParameterList.clinical_score_principle_investigator) {
        clinical_score_principle_investigator_Err = 'Please Enter Clinical Score Investigator';
    }

    if (!this.state.ParameterList.clinical_in_lifetime) {
        clinical_in_lifetime_Err = 'Please Enter Clinical Total in Lifetime';
    }
    if (!this.state.ParameterList.clinical_in_per_year) {
        clinical_in_per_year_Err = 'Please Enter Clinical Total in Year';
    }
      

    if (!this.state.ParameterList.clinical_score_global) {
        clinical_score_global_Err = 'Please Enter Clinical Score Global';
    }
    if (!this.state.ParameterList.clinical_score_local) {
        clinical_score_local_Err = 'Please Enter Clinical Score Local';
    }
    if (!this.state.ParameterList.clinical_score_interventional) {
        clinical_score_interventional_Err = 'Please Enter Clinical Score International';
    }
    if (!this.state.ParameterList.clinical_score_manual_weigtage_mul_by) {
        clinical_score_manual_weigtage_mul_by_Err = 'Please Enter Clinical Score Manual Weitage Multiply by';
    }
    if (!this.state.ParameterList.clinical_score_national) {
        clinical_score_national_Err = 'Please Enter Clinical Score National';
    }
    if (!this.state.ParameterList.clinical_score_observational) {
        clinical_score_observational_Err = 'Please Enter Clinical Score Observational';
    }
    if (!this.state.ParameterList.clinical_score_regional) {
        clinical_score_regional_Err = 'Please Enter Clinical Score Regional';
    }
    if (!this.state.ParameterList.clinical_score_study_locations_1) {
        clinical_score_study_locations_1_Err = 'Please Enter Clinical Score Study Location 1';
    }
    if (!this.state.ParameterList.clinical_score_study_locations_10) {
        clinical_clinical_score_study_locations_10_Err = 'Please Enter Clinical Score Study Location 10';
    }
    if (!this.state.ParameterList.clinical_score_study_locations_50) {
        clinical_clinical_score_study_locations_50_Err = 'Please Enter Clinical Score Study Location 50';
    }
    if (!this.state.ParameterList.clinical_score_study_locations_10000) {
        clinical_clinical_score_study_locations_10000_Err = 'Please Enter Clinical Score Study Location 10000';
    }
    if (!this.state.ParameterList.clinical_score_c_score_div_by) {
        clinical_clinical_score_c_score_div_by_Err = 'Please Enter Clinical Score c Divided by';
    }
    if (!this.state.ParameterList.clinical_score_type_div_by) {
        clinical_clinical_score_type_div_by_Err = 'Please Enter Clinical Score Type Divided by';
    }


    if (clinical_score_e_score_div_by_Err || clinical_score_global_Err || clinical_score_study_chair_Err ||
        clinical_score_study_director_Err || clinical_score_investigator_Err || 
        clinical_score_principle_investigator_Err || clinical_in_lifetime_Err ||clinical_in_per_year_Err ||
         clinical_score_local_Err ||
        clinical_score_interventional_Err || clinical_score_manual_weigtage_mul_by_Err || clinical_score_national_Err  ||
        clinical_score_observational_Err || clinical_score_regional_Err || clinical_score_study_locations_1_Err ||
        clinical_clinical_score_study_locations_10_Err || clinical_clinical_score_study_locations_50_Err ||
        clinical_clinical_score_study_locations_10000_Err || clinical_clinical_score_c_score_div_by_Err||
        clinical_clinical_score_type_div_by_Err) {
        this.setState({ clinical_score_e_score_div_by_Err, clinical_score_global_Err, clinical_score_study_chair_Err, 
            clinical_score_study_director_Err, clinical_score_investigator_Err, 
            clinical_score_principle_investigator_Err, clinical_score_local_Err,clinical_in_lifetime_Err,clinical_in_per_year_Err,
            
            clinical_score_interventional_Err, clinical_score_manual_weigtage_mul_by_Err, clinical_score_national_Err,
            clinical_score_observational_Err, clinical_score_regional_Err, clinical_score_study_locations_1_Err,
            clinical_clinical_score_study_locations_10_Err, clinical_clinical_score_study_locations_50_Err,
            clinical_clinical_score_study_locations_10000_Err, clinical_clinical_score_c_score_div_by_Err,
            clinical_clinical_score_type_div_by_Err});
        return false;
    }
    return true;
}

EditClinicalModalSubmit = async () => {
    const isValid = this.validateClinicalScore();
    if (isValid) {
    console.log("EditClinicalModalSubmit");
    this.setState({
        EditClinicalModal: false,
        ShowBar: true,
        clinical_score_e_score_div_by_Err: '',
        clinical_score_study_chair_Err: '',
        clinical_score_study_director_Err: '',
        clinical_score_investigator_Err: '',
        clinical_score_principle_investigator_Err: '',
        clinical_in_lifetime_Err: '',
        clinical_in_per_year_Err: '',
        clinical_score_global_Err: '',
        clinical_score_local_Err: '',
        clinical_score_interventional_Err: '',
        clinical_score_manual_weigtage_mul_by_Err: '',
        clinical_score_national_Err: '',
        clinical_score_observational_Err: '',
        clinical_score_regional_Err: '',
        clinical_score_study_locations_1_Err: '',
        clinical_clinical_score_study_locations_10_Err: '',
        clinical_clinical_score_study_locations_50_Err: '',
        clinical_clinical_score_study_locations_10000_Err: '',
        clinical_clinical_score_c_score_div_by_Err: '',
        clinical_clinical_score_type_div_by_Err: '',
    })

    const url = global.websiteUrl+'/service/KOL/update_score';
    let formData = new FormData();
    formData.append('clinical_score_e_score_div_by', this.state.ParameterList.clinical_score_e_score_div_by);
    formData.append('clinical_score_study_chair', this.state.ParameterList.clinical_score_study_chair);
    formData.append('clinical_score_study_director', this.state.ParameterList.clinical_score_study_director);
    formData.append('clinical_score_investigator', this.state.ParameterList.clinical_score_investigator);
    formData.append('clinical_score_principle_investigator', this.state.ParameterList.clinical_score_principle_investigator);
    formData.append('clinical_score_global', this.state.ParameterList.clinical_score_global);
    formData.append('clinical_score_local', this.state.ParameterList.clinical_score_local);
    formData.append('clinical_score_interventional', this.state.ParameterList.clinical_score_interventional);
    formData.append('clinical_score_manual_weigtage_mul_by', this.state.ParameterList.clinical_score_manual_weigtage_mul_by);
    formData.append('clinical_score_national', this.state.ParameterList.clinical_score_national);
    formData.append('clinical_score_observational', this.state.ParameterList.clinical_score_observational);
    formData.append('clinical_score_regional', this.state.ParameterList.clinical_score_regional);
    formData.append('clinical_score_study_locations_1', this.state.ParameterList.clinical_score_study_locations_1);
    formData.append('clinical_score_study_locations_10', this.state.ParameterList.clinical_score_study_locations_10);
    formData.append('clinical_score_study_locations_50', this.state.ParameterList.clinical_score_study_locations_50);
    formData.append('clinical_score_study_locations_10000', this.state.ParameterList.clinical_score_study_locations_10000);
    formData.append('clinical_score_c_score_div_by', this.state.ParameterList.clinical_score_c_score_div_by);
    formData.append('clinical_score_type_div_by', this.state.ParameterList.clinical_score_type_div_by);
    formData.append('clinical_in_lifetime', this.state.ParameterList.clinical_in_lifetime);
    formData.append('clinical_in_per_year', this.state.ParameterList.clinical_in_per_year);

    const config = {
    //   headers: { 'content-type': 'multipart/form-data' }
    }
  
    axios.post(url, formData, config)
        .then(response => {
            this.setState({ ShowBar: false })
            console.log(response);
            var lgdata = response.data;
            console.log("lgdata=====", JSON.stringify(lgdata));
            var lgstatus = response.data.status;
            var message = response.data.message
            console.log("message", message)
            if (lgstatus == true) {
                Swal.fire({
                  icon: 'success',
                  text: message,
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
    
                })
              }
            else {
                Swal.fire({
                  icon: 'error',
                  text: message,
                  // text: 'Wrong Username or Password!',
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
                const options = {
                    method: 'POST',
                }
                fetch(global.websiteUrl+'/service/KOL/get_score', options)
                .then((Response) => Response.json())
                .then((findresponse) => {
                    console.log(findresponse)
                    var testData = findresponse.data;
                    console.log("testDataget_score=====", JSON.stringify(testData));
                    this.setState({
                        ParameterList: findresponse.data,

                    })
                    this.setState({
                        ShowBar: false,
                    })
                    
                })
            }
        })
    }
}

toggleEditAdvisoryModal = async() => {
    console.log("toggleEditAdvisoryModal")
    this.setState({
        EditAdvisoryModal: !this.state.EditAdvisoryModal,
       // board_score_advisory_committee_Err: '',
       board_score_member_Err: '',
        board_score_chair_Err: '',
        board_score_co_chair_Err: '',
        board_in_lifetime_Err: '',
        board_in_per_year_Err: '',

        board_score_e_score_div_by_Err: '',
        board_score_global_Err: '',
        board_score_national_Err: '',
        board_score_local_Err: '',
        board_score_manual_weigtage_mul_by_Err: '',
        board_score_r_score_div_by_Err: '',
        board_score_regional_Err: '',
    })
}

CloseEditAdvisoryModal = async() => {
    console.log("toggleEditAdvisoryModal")
    this.setState({
        EditAdvisoryModal: false,
       // board_score_advisory_committee_Err: '',
       board_score_member_Err: '',
        board_score_chair_Err: '',
        board_score_co_chair_Err: '',
        board_in_lifetime_Err: '',
        board_in_per_year_Err: '',

        board_score_e_score_div_by_Err: '',
        board_score_global_Err: '',
        board_score_national_Err: '',
        board_score_local_Err: '',
        board_score_manual_weigtage_mul_by_Err: '',
        board_score_r_score_div_by_Err: '',
        board_score_regional_Err: '',
    })
    const options = {
        method: 'POST',
    }
    this.setState({ ShowBar: true })

    fetch(global.websiteUrl+'/service/KOL/get_score', options)
    .then((Response) => Response.json())
    .then((findresponse) => {
        console.log(findresponse)
        var testData = findresponse.data;
        console.log("testDataget_score=====", JSON.stringify(testData));
        this.setState({
            ParameterList: findresponse.data,

        })
        this.setState({
            ShowBar: false,
        })
        
    })
}

validateAdvisoryScore() {
    //let board_score_advisory_committee_Err = '';
    let board_score_member_Err = '';
    let board_score_chair_Err = '';
    let board_score_co_chair_Err = '';
    let board_score_e_score_div_by_Err = '';
    let board_score_global_Err = '';
    let board_score_national_Err = '';
    let board_score_local_Err = '';
    let board_score_manual_weigtage_mul_by_Err = '';
    let board_score_r_score_div_by_Err = '';
    let board_score_regional_Err = '';
    let board_in_lifetime_Err = '';
    let board_in_per_year_Err = '';


    // if (!this.state.ParameterList.board_score_advisory_committee) {
    //     board_score_advisory_committee_Err = 'Please Enter Advisory Board Score Commitee';
    // }
    if (!this.state.ParameterList.board_score_member) {
        board_score_member_Err = 'Please Enter Advisory Board Score Member';
    }
    if (!this.state.ParameterList.board_score_chair) {
        board_score_chair_Err = 'Please Enter Advisory Board Score Chair';
    }
    if (!this.state.ParameterList.board_score_co_chair) {
        board_score_co_chair_Err = 'Please Enter Advisory Board Score Co-Chair';
    }

    if (!this.state.ParameterList.board_in_lifetime) {
        board_in_lifetime_Err = 'Please Enter Advisory Board in Lifetime';
    }
    if (!this.state.ParameterList.board_in_per_year) {
        board_in_per_year_Err = 'Please Enter Advisory Board Year';
    }
  
    if (!this.state.ParameterList.board_score_e_score_div_by) {
        board_score_e_score_div_by_Err = 'Please Enter Advisory Board Score e Divided by';
    }
    if (!this.state.ParameterList.board_score_global) {
        board_score_global_Err = 'Please Enter Advisory Board Score Global';
    }

    if (!this.state.ParameterList.board_score_national) {
        board_score_national_Err = 'Please Enter Advisory Board Score National';
    }
    if (!this.state.ParameterList.board_score_local) {
        board_score_local_Err = 'Please Enter Advisory Board Score Local';
    }
    if (!this.state.ParameterList.board_score_manual_weigtage_mul_by) {
        board_score_manual_weigtage_mul_by_Err = 'Please Enter Advisory Board Score Manual Weigtage Multiply by';
    }
    if (!this.state.ParameterList.board_score_r_score_div_by) {
        board_score_r_score_div_by_Err = 'Please Enter Advisory Board Score r Divided by';
    }
    if (!this.state.ParameterList.board_score_regional) {
        board_score_regional_Err = 'Please Enter Advisory Board Score Regional';
    }

    

    if ( board_score_member_Err || board_score_chair_Err ||
        board_score_co_chair_Err || board_score_e_score_div_by_Err || board_in_lifetime_Err ||board_in_per_year_Err ||
        board_score_global_Err ||board_score_national_Err || board_score_local_Err || board_score_manual_weigtage_mul_by_Err || board_score_r_score_div_by_Err ||
        board_score_regional_Err ) {
        this.setState({  board_score_member_Err,
            board_score_chair_Err, board_score_co_chair_Err, board_score_e_score_div_by_Err, board_in_lifetime_Err,board_in_per_year_Err,    
            board_score_global_Err,board_score_national_Err, board_score_local_Err, board_score_manual_weigtage_mul_by_Err, board_score_r_score_div_by_Err,
            board_score_regional_Err });
        return false;
    }
    return true;
}

EditAdvisoryModalSubmit = async () => {
    const isValid = this.validateAdvisoryScore();
    if (isValid) {
    console.log("EditClinicalModalSubmit");
    this.setState({
        EditAdvisoryModal: false,
        ShowBar: true,
       // board_score_advisory_committee_Err: '',
        board_score_member_Err: '',
        board_score_chair_Err: '',
        board_score_co_chair_Err: '',
        board_in_lifetime_Err: '',
        board_in_per_year_Err: '',

        board_score_e_score_div_by_Err: '',
        board_score_global_Err: '',
        board_score_national_Err: '',
        board_score_local_Err: '',
        board_score_manual_weigtage_mul_by_Err: '',
        board_score_r_score_div_by_Err: '',
        board_score_regional_Err: '',
    })

    const url = global.websiteUrl+'/service/KOL/update_score';
    let formData = new FormData();
   // formData.append('board_score_advisory_committee', this.state.ParameterList.board_score_advisory_committee);
    formData.append('board_score_member', this.state.ParameterList.board_score_member);
    formData.append('board_score_chair', this.state.ParameterList.board_score_chair);
    formData.append('board_score_co_chair', this.state.ParameterList.board_score_co_chair);
    formData.append('board_score_e_score_div_by', this.state.ParameterList.board_score_e_score_div_by);
    formData.append('board_score_global', this.state.ParameterList.board_score_global);
    formData.append('board_score_national', this.state.ParameterList.board_score_national);
    formData.append('board_score_local', this.state.ParameterList.board_score_local);
    formData.append('board_score_manual_weigtage_mul_by', this.state.ParameterList.board_score_manual_weigtage_mul_by);
    formData.append('board_score_r_score_div_by', this.state.ParameterList.board_score_r_score_div_by);
    formData.append('board_score_regional', this.state.ParameterList.board_score_regional);
    formData.append('board_in_lifetime', this.state.ParameterList.board_in_lifetime);
    formData.append('board_in_per_year', this.state.ParameterList.board_in_per_year);

    const config = {
    //   headers: { 'content-type': 'multipart/form-data' }
    }
  
    axios.post(url, formData, config)
        .then(response => {
            this.setState({ ShowBar: false })
            console.log(response);
            var lgdata = response.data;
            console.log("lgdata=====", JSON.stringify(lgdata));
            var lgstatus = response.data.status;
            var message = response.data.message
            console.log("message", message)
            if (lgstatus == true) {
                Swal.fire({
                  icon: 'success',
                  text: message,
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
    
                })
              }
            else {
                Swal.fire({
                  icon: 'error',
                  text: message,
                  // text: 'Wrong Username or Password!',
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
                const options = {
                    method: 'POST',
                }
                fetch(global.websiteUrl+'/service/KOL/get_score', options)
                .then((Response) => Response.json())
                .then((findresponse) => {
                    console.log(findresponse)
                    var testData = findresponse.data;
                    console.log("testDataget_score=====", JSON.stringify(testData));
                    this.setState({
                        ParameterList: findresponse.data,

                    })
                    this.setState({
                        ShowBar: false,
                    })
                    
                })
            }
        })
    }
}

toggleEditGuidelineModal = async() => {
    console.log("toggleEditGuidelineModal")
    this.setState({
        EditGuidelineModal: !this.state.EditGuidelineModal,
        guideline_score_o_score_div_by_Err: '',
        guideline_score_authore_Err: '',
        Guideline_score_committee_chair_Err: '',
        guideline_score_committee_member_Err: '',
        Guideline_in_per_year_Err: '',
        Guideline_in_lifetime_Err: '',        
        guideline_score_global_Err: '',
        guideline_score_local_Err: '',
        guideline_score_manual_weigtage_mul_by_Err: '',
        guideline_score_regional_Err: '',
        guideline_score_national_Err: '',
    })
}

CloseEditGuidelineModal = async() => {
    console.log("EditGuidelineModal")
    this.setState({
        EditGuidelineModal: false,
        guideline_score_o_score_div_by_Err: '',
        guideline_score_global_Err: '',
        guideline_score_authore_Err: '',
        Guideline_score_committee_chair_Err: '',
        guideline_score_committee_member_Err: '',
        Guideline_in_lifetime_Err: '',
        Guideline_in_per_year_Err: '',
        
        guideline_score_local_Err: '',
        guideline_score_manual_weigtage_mul_by_Err: '',
        guideline_score_regional_Err: '',
        guideline_score_national_Err: '',
    })
    const options = {
        method: 'POST',
    }
    this.setState({ ShowBar: true })

    fetch(global.websiteUrl+'/service/KOL/get_score', options)
    .then((Response) => Response.json())
    .then((findresponse) => {
        console.log(findresponse)
        var testData = findresponse.data;
        console.log("testDataget_score=====", JSON.stringify(testData));
        this.setState({
            ParameterList: findresponse.data,

        })
        this.setState({
            ShowBar: false,
        })
        
    })
}

validateGuidelineScore(){
    // console.log("in Edit validateGuidelineScore submit")
    let guideline_score_o_score_div_by_Err = '';
    let guideline_score_authore_Err = '';
    let Guideline_score_committee_chair_Err = '';
    let guideline_score_committee_member_Err = '';
    let Guideline_in_lifetime_Err = '';
    let Guideline_in_per_year_Err = '';
    
    let guideline_score_global_Err = '';
    let guideline_score_local_Err = '';
    let guideline_score_manual_weigtage_mul_by_Err = '';
    let guideline_score_regional_Err = '';
    let guideline_score_national_Err = '';

    if (!this.state.ParameterList.guideline_score_o_score_div_by) {
        guideline_score_o_score_div_by_Err = 'Please Enter Guideline Score Divided by';
    }
    if (!this.state.ParameterList.guideline_score_global) {
        guideline_score_global_Err = 'Please Enter Guideline Score Global';
    }
    if (!this.state.ParameterList.guideline_score_authore) {
        // console.log("this.state.ParameterList.guideline_score_authore")
        guideline_score_authore_Err = 'Please Enter Guideline Score Author';
    }
    if (!this.state.ParameterList.Guideline_score_committee_chair) {
        Guideline_score_committee_chair_Err = 'Please Enter Guideline Score Committee Chair';
    }
    if (!this.state.ParameterList.guideline_score_committee_member) {
        guideline_score_committee_member_Err = 'Please Enter Guideline Score Committee Member';
    }

    if (!this.state.ParameterList.Guideline_in_lifetime) {
        Guideline_in_lifetime_Err = 'Please Enter Total Guideline in Lifetime';
    }
    
    if (!this.state.ParameterList.Guideline_in_per_year) {
        Guideline_in_per_year_Err = 'Please Enter Total Guideline in Year';
    }
    

    if (!this.state.ParameterList.guideline_score_local) {
        guideline_score_local_Err = 'Please Enter Guideline Score Local';
    }
    if (!this.state.ParameterList.guideline_score_manual_weigtage_mul_by) {
        guideline_score_manual_weigtage_mul_by_Err = 'Please Enter Guideline Score Manual Weitage Multiply by';
    }
    if (!this.state.ParameterList.guideline_score_regional) {
        guideline_score_regional_Err = 'Please Enter Guideline Score Regional';
    }
    if (!this.state.ParameterList.guideline_score_national) {
        guideline_score_national_Err = 'Please Enter Guideline Score National';
    }


    if (guideline_score_o_score_div_by_Err|| guideline_score_global_Err || guideline_score_authore_Err ||
        Guideline_score_committee_chair_Err || guideline_score_committee_member_Err || Guideline_in_lifetime_Err || Guideline_in_per_year_Err || guideline_score_local_Err ||
        guideline_score_manual_weigtage_mul_by_Err || guideline_score_regional_Err || guideline_score_national_Err ) {
        this.setState({ guideline_score_o_score_div_by_Err,guideline_score_global_Err, guideline_score_authore_Err,
            Guideline_score_committee_chair_Err, guideline_score_committee_member_Err, Guideline_in_lifetime_Err,Guideline_in_per_year_Err, guideline_score_local_Err,
            guideline_score_manual_weigtage_mul_by_Err, guideline_score_regional_Err, guideline_score_national_Err });
        return false;
    }
    return true;
}

EditGuidelineModalSubmit = async () => {
    console.log("in Edit Guideline submit")
    const isValid = this.validateGuidelineScore();
    if (isValid) {
    console.log("EditClinicalModalSubmit");

    const url = global.websiteUrl+'/service/KOL/update_score';
    let formData = new FormData();
    formData.append('guideline_score_o_score_div_by', this.state.ParameterList.guideline_score_o_score_div_by);
    formData.append('guideline_score_authore', this.state.ParameterList.guideline_score_authore);
    formData.append('Guideline_score_committee_chair', this.state.ParameterList.Guideline_score_committee_chair);
    formData.append('guideline_score_committee_member', this.state.ParameterList.guideline_score_committee_member);
    formData.append('guideline_score_global', this.state.ParameterList.guideline_score_global);
    formData.append('guideline_score_local', this.state.ParameterList.guideline_score_local);
    formData.append('guideline_score_manual_weigtage_mul_by', this.state.ParameterList.guideline_score_manual_weigtage_mul_by);
    formData.append('guideline_score_regional', this.state.ParameterList.guideline_score_regional);
    formData.append('guideline_score_national', this.state.ParameterList.guideline_score_national);
    formData.append('Guideline_in_lifetime', this.state.ParameterList.Guideline_in_lifetime);
    formData.append('Guideline_in_per_year', this.state.ParameterList.Guideline_in_per_year);

    const config = {
    //   headers: { 'content-type': 'multipart/form-data' }
    }
  
    axios.post(url, formData, config)
        .then(response => {
            this.setState({
                EditGuidelineModal: false,
                ShowBar: true,
                guideline_score_o_score_div_by_Err: '',
                guideline_score_global_Err: '',
                guideline_score_authore_Err: '',
                Guideline_score_committee_chair_Err: '',
                guideline_score_committee_member_Err: '',
                Guideline_in_lifetime_Err: '',
                Guideline_in_per_year_Err: '',
                guideline_score_local_Err: '',
                guideline_score_manual_weigtage_mul_by_Err: '',
                guideline_score_regional_Err: '',
                guideline_score_national_Err: '',
            })
            this.setState({ ShowBar: false })
            console.log(response);
            var lgdata = response.data;
            console.log("lgdata=====", JSON.stringify(lgdata));
            var lgstatus = response.data.status;
            var message = response.data.message
            console.log("message", message)
            if (lgstatus == true) {
                Swal.fire({
                  icon: 'success',
                  text: message,
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
    
                })
              }
            else {
                Swal.fire({
                  icon: 'error',
                  text: message,
                  // text: 'Wrong Username or Password!',
                  confirmButtonColor: '#d33',
                  confirmButtonText: 'OK'
                })
                const options = {
                    method: 'POST',
                }
                fetch(global.websiteUrl+'/service/KOL/get_score', options)
                .then((Response) => Response.json())
                .then((findresponse) => {
                    console.log(findresponse)
                    var testData = findresponse.data;
                    console.log("testDataget_score=====", JSON.stringify(testData));
                    this.setState({
                        ParameterList: findresponse.data,

                    })
                    this.setState({
                        ShowBar: false,
                    })
                    
                })
            }
        })
    }
}

    render() {
        const options = {
            // Material design options
            chart: {
              title: "Students' Final Grades",
              subtitle: "based on hours studied",
            },
            axes: {
                x: 0,
                y: null ,
              
            },
          }

          const options2 = {
            width: 400,
            height: 120,
            redFrom: 90,
            redTo: 100,
            yellowFrom: 75,
            yellowTo: 90,
            minorTicks: 5,
          };
        console.log("ParameterList in return",this.state.ParameterList)
        return (
            <body>
                <div style={{marginTop:'15vh'}}>
                    {this.state.ShowBar ?   
                        <div style={{ position: 'fixed', top: '0' }}>
                            <LoadingBar
                                progress='100'
                                // onLoaderFinished={() => setProgress(0)}
                                //  progress={progress}
                                height={3} color="#A90007" transitionTime={4000}
                                loaderSpeed={4000} />
                        </div>
                    : null}

                    {this.state.ShowProcedure ? <Modal isOpen={true} className='propagateLoader' centered>
                        <PropagateLoader color={'#A90007'} size='40' loading='true' />
                    </Modal> : null}

                    <div >
                        <AdminHeader />
                        <div class="container">
                            <div class="content-wrapper">
                                <div class="screen-headingbox">
                                    <div className="back-section">
                                        <a>
                                            <Link to="/AdminDashboard">
                                                <img
                                                    src={require('../Assets/Images/back-arrow.svg')}
                                                />
                                                Back
                                            </Link>
                                        </a>
                                    </div>
                                    <h2>Parameter Configuration</h2>
                                </div>

                                {/* <Chart
                                    chartType="Gauge"
                                    width="100%"
                                    height="400px"
                                    // data={this.state.data2}
                                    // options={options2}
                                />

                                <Chart
                                    chartType="Scatter"
                                    width="80%"
                                    height="400px"
                                    data={this.state.data}
                                    options={options}
                                /> */}
                            

                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-xs-12 emplist-box">
                                        <div className="row">
                                        <div className="col-md-12">
                                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <a onClick={this.EventPiechartFun}>
                                                        {(this.state.event_piechart_flag == true) ? (
                                                            <button className="nav-link" id="event-tab" data-bs-toggle="tab" data-bs-target="#event-tab" type="button"
                                                            role="tab" aria-controls="event" style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                            Events
                                                            </button>
                                                        ) : (
                                                            <button className="nav-link" id="event-tab" data-bs-toggle="tab" data-bs-target="#event-tab" type="button"
                                                            role="tab" aria-controls="event">
                                                            Events
                                                            </button>
                                                        )}

                                                        </a>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <a onClick={this.PublicationPiechartFun}>
                                                        {(this.state.publication_piechart_flag == true) ? (
                                                            <button className="nav-link" id="publication-tab" data-bs-toggle="tab" data-bs-target="#publication-tab" type="button"
                                                            role="tab" aria-controls="publication" style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                            Publications
                                                            </button>
                                                        ) : (
                                                            <button className="nav-link" id="publication-tab" data-bs-toggle="tab" data-bs-target="#publication-tab" type="button"
                                                            role="tab" aria-controls="publication">
                                                            Publications
                                                            </button>
                                                        )}
                                                        </a>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <a onClick={this.ClinicaltrialPiechartFun}>
                                                        {(this.state.clinicaltrial_piechart_flag == true) ? (
                                                            <button className="nav-link" id="clinical-trial-tab" data-bs-toggle="tab" data-bs-target="#clinical-trial-tab" type="button"
                                                            role="tab" aria-controls="clinical-trial" style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                            Clinical Trials
                                                            </button>
                                                        ) : (
                                                            <button className="nav-link" id="clinical-trial-tab" data-bs-toggle="tab" data-bs-target="#clinical-trial-tab" type="button"
                                                            role="tab" aria-controls="clinical-trial">
                                                            Clinical Trials
                                                            </button>
                                                        )}
                                                        </a>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <a onClick={this.AdboardPiechartFun}>
                                                        {(this.state.ad_board_piechart_flag == true) ? (
                                                            <button className="nav-link" id="advisory-board-tab" data-bs-toggle="tab" data-bs-target="#advisory-board-tab" type="button"
                                                            role="tab" aria-controls="advisory-board" style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                            Advisory Boards
                                                            </button>
                                                        ) : (
                                                            <button className="nav-link" id="advisory-board-tab" data-bs-toggle="tab" data-bs-target="#advisory-board-tab" type="button"
                                                            role="tab" aria-controls="advisory-board">
                                                            Advisory Boards
                                                            </button>
                                                        )}
                                                        </a>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <a onClick={this.GuidelinePiechartFun}>
                                                        {(this.state.guideline_piechart_flag == true) ? (
                                                            <button className="nav-link" id="guideline-tab" data-bs-toggle="tab" data-bs-target="#guideline-tab" type="button"
                                                            role="tab" aria-controls="guideline" style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                            Guidelines
                                                            </button>
                                                        ) : (
                                                            <button className="nav-link" id="guideline-tab" data-bs-toggle="tab" data-bs-target="#guideline-tab" type="button"
                                                            role="tab" aria-controls="guideline">
                                                            Guidelines
                                                            </button>
                                                        )}
                                                        </a>
                                                    </li>
                                                </ul>
                                                {
                                                    (this.state.event_piechart_flag == true) ? (
                                                        <div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                            <div className="screen-headingbox">
                                                                <h3>Event Score Parameter List
                                                                    <button className="cetas-button float-right"
                                                                        onClick={this.toggleEditEventModal.bind(this)}
                                                                    >
                                                                       Edit 
                                                                    </button>
                                                                </h3>
                                                            </div>
                                                            <div style={{marginTop:'3%', marginLeft:'2%'}}> 
                                                                <h4>Event Chairperson/Program Director: &nbsp;&nbsp;{this.state.ParameterList.event_score_chairperson}</h4>
                                                                <h4>Event Score Keynote Speaker: &nbsp;&nbsp;{this.state.ParameterList.event_score_keynote_speaker}</h4>
                                                                <h4>Event Score Moderator/ Session Chair: &nbsp;&nbsp;{this.state.ParameterList.event_score_session_chair}</h4>
                                                                <h4>Event Score Regular Speaker/Panelist/Participant: &nbsp;&nbsp;{this.state.ParameterList.event_score_regular}</h4>                                                               
                                                                <hr/>
                                                                <h4>Event Total in Lifetime: &nbsp;&nbsp;{this.state.ParameterList.event_in_lifetime}</h4>                                                                
                                                                <h4>Event Total in Year: &nbsp;&nbsp;{this.state.ParameterList.event_in_per_year}</h4>
                                                                <hr/>
                                                                <h4>Event Score Global: &nbsp;&nbsp;{this.state.ParameterList.event_score_global}</h4>
                                                                <h4>Event Score Local: &nbsp;&nbsp;{this.state.ParameterList.event_score_local}</h4>
                                                                <h4>Event Score National: &nbsp;&nbsp;{this.state.ParameterList.event_score_national}</h4>
                                                                <h4>Event Score Regional: &nbsp;&nbsp;{this.state.ParameterList.event_score_regional}</h4>
                                                                <hr/>
                                                                <h4>Event Score r Score Divided By: &nbsp;&nbsp;{this.state.ParameterList.event_score_r_score_div_by}</h4>                                                               
                                                                <h4>Event Score Manual Weightage Multiply By: &nbsp;&nbsp;{this.state.ParameterList.event_score_manual_weigtage_mul_by}</h4>
                                                                <h4>Event Score e Score Divided By: &nbsp;&nbsp;{this.state.ParameterList.event_score_e_score_div_by}</h4>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    ):(
                                                        <div></div>
                                                    )
                                                } 
                                                {
                                                    (this.state.publication_piechart_flag == true) ? (
                                                        <div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                            {/* <h3 style={{marginTop:'2%',marginLeft:'1%'}}>Publication Parameter List</h3> */}
                                                            <div className="screen-headingbox">
                                                                <h3>Publication  Parameter List
                                                                    <button className="cetas-button float-right"
                                                                        onClick={this.toggleEditPublicationModal.bind(this)}
                                                                    >
                                                                       Edit 
                                                                    </button>
                                                                </h3>
                                                            </div>
                                                            <div style={{marginTop:'3%', marginLeft:'2%'}}> 
                                                                <h4>Publication Score First Author: &nbsp;&nbsp;{this.state.ParameterList.publication_score_first_author}</h4>
                                                                <h4>Publication Score Second Author: &nbsp;&nbsp;{this.state.ParameterList.publication_score_second_author}</h4>
                                                                <h4>Publication Score Last Author: &nbsp;&nbsp;{this.state.ParameterList.publication_score_last_author}</h4>
                                                                <hr/>
                                                                <h4>Publication Total in Lifetime: &nbsp;&nbsp;{this.state.ParameterList.publication_in_lifetime}</h4>                                                                
                                                                <h4>Publication Total in Year: &nbsp;&nbsp;{this.state.ParameterList.publication_in_per_year}</h4>
                                                                
                                                                <hr/>
                                                                
                                                                <h4>Publication Score Global: &nbsp;&nbsp;{this.state.ParameterList.publication_score_global}</h4>
                                                                <h4>Publication Score National: &nbsp;&nbsp;{this.state.ParameterList.publication_score_national}</h4>
                                                                <h4>Publication Score Local: &nbsp;&nbsp;{this.state.ParameterList.publication_score_local}</h4>
                                                                <h4>Publication Score Regional: &nbsp;&nbsp;{this.state.ParameterList.publication_score_regional}</h4>
                                                                <hr/>
                                                                <h4>Publication Score Manual Weightage Multiply By: &nbsp;&nbsp;{this.state.ParameterList.publication_score_manual_weigtage_mul_by}</h4>
                                                                <h4>Publication r Score Divided By: &nbsp;&nbsp;{this.state.ParameterList.publication_score_r_score_div_by}</h4>
                                                                <h4>Publication e Score Divided By: &nbsp;&nbsp;{this.state.ParameterList.publication_score_e_score_div_by}</h4>
                                                                
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    ):(
                                                        <div></div>
                                                    )
                                                }
                                                {
                                                    (this.state.clinicaltrial_piechart_flag == true) ? (
                                                        <div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                            {/* <h3 style={{marginTop:'2%',marginLeft:'1%'}}>Clinical Trial Parameter List</h3> */}
                                                            <div className="screen-headingbox">
                                                                <h3>Clinical Trial Parameter List
                                                                    <button className="cetas-button float-right"
                                                                        onClick={this.toggleEditClinicalModal.bind(this)}
                                                                    >
                                                                       Edit 
                                                                    </button>
                                                                </h3>
                                                            </div>
                                                            <div style={{marginTop:'3%', marginLeft:'2%'}}> 
                                                                
                                                                <h4>Clinical Score Study Chair: &nbsp;&nbsp;{this.state.ParameterList.clinical_score_study_chair}</h4>
                                                                <h4>Clinical Score Study Director: &nbsp;&nbsp;{this.state.ParameterList.clinical_score_study_director}</h4>
                                                                <h4>Clinical Score Investigator: &nbsp;&nbsp;{this.state.ParameterList.clinical_score_investigator}</h4>
                                                                <h4>Clinical Score Principle Investigator: &nbsp;&nbsp;{this.state.ParameterList.clinical_score_principle_investigator}</h4>
                                                               
                                                                <hr/>
                                                                <h4>Clinical Total in Lifetime: &nbsp;&nbsp;{this.state.ParameterList.clinical_in_lifetime}</h4>                                                                
                                                                <h4>Clinical Total in Year: &nbsp;&nbsp;{this.state.ParameterList.clinical_in_per_year}</h4>
                                                                
                                                                <hr/>
                                                                <h4>Clinical Score Global: &nbsp;&nbsp;{this.state.ParameterList.clinical_score_global}</h4>
                                                                <h4>Clinical Score Local: &nbsp;&nbsp;{this.state.ParameterList.clinical_score_local}</h4>
                                                                <h4>Clinical Score Interventional: &nbsp;&nbsp;{this.state.ParameterList.clinical_score_interventional}</h4>                                                             
                                                                <h4>Clinical Score National: &nbsp;&nbsp;{this.state.ParameterList.clinical_score_national}</h4>
                                                                <h4>Clinical Score Observational: &nbsp;&nbsp;{this.state.ParameterList.clinical_score_observational}</h4>
                                                                <h4>Clinical Score Regional: &nbsp;&nbsp;{this.state.ParameterList.clinical_score_regional}</h4>
                                                                <hr/>
                                                                <h4>Clinical Score Locations_1: &nbsp;&nbsp;{this.state.ParameterList.clinical_score_study_locations_1}</h4>
                                                                <h4>Clinical Score Locations_1-10: &nbsp;&nbsp;{this.state.ParameterList.clinical_score_study_locations_10}</h4>
                                                                <h4>Clinical Score Locations_11-50: &nbsp;&nbsp;{this.state.ParameterList.clinical_score_study_locations_50}</h4>
                                                                <h4>Clinical Score Locations_MoreThan_50: &nbsp;&nbsp;{this.state.ParameterList.clinical_score_study_locations_10000}</h4>
                                                                <hr/>
                                                                <h4>Clinical Score e Divided By: &nbsp;&nbsp;{this.state.ParameterList.clinical_score_e_score_div_by}</h4>
                                                                <h4>Clinical Score C Divided By: &nbsp;&nbsp;{this.state.ParameterList.clinical_score_c_score_div_by}</h4>
                                                                <h4>Clinical Score Type Divided By: &nbsp;&nbsp;{this.state.ParameterList.clinical_score_type_div_by}</h4>
                                                                <h4>Clinical Score Manual Weightage Multiply By: &nbsp;&nbsp;{this.state.ParameterList.clinical_score_manual_weigtage_mul_by}</h4>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    ):(
                                                        <div></div>
                                                    )
                                                } 
                                                {
                                                    (this.state.ad_board_piechart_flag == true) ? (
                                                        <div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                            {/* <h3 style={{marginTop:'2%',marginLeft:'1%'}}>Advisory Board Parameter List</h3> */}
                                                            <div className="screen-headingbox">
                                                                <h3>Advisory Board Parameter List
                                                                    <button className="cetas-button float-right"
                                                                        onClick={this.toggleEditAdvisoryModal.bind(this)}
                                                                    >
                                                                       Edit 
                                                                    </button>
                                                                </h3>
                                                            </div>
                                                            <div style={{marginTop:'3%', marginLeft:'2%'}}> 
                                                                {/* <h4>Advisory Board Score Advisory Committee: &nbsp;&nbsp;{this.state.ParameterList.board_score_advisory_committee}</h4> */}
                                                                <h4>Advisory Board Score Member: &nbsp;&nbsp;{this.state.ParameterList.board_score_member}</h4>
                                                                <h4>Advisory Board Score Chair: &nbsp;&nbsp;{this.state.ParameterList.board_score_chair}</h4>
                                                                <h4>Advisory Board Score Co-Chair: &nbsp;&nbsp;{this.state.ParameterList.board_score_co_chair}</h4>
                                                                {/* <h4>Advisory Board Score Member: &nbsp;&nbsp;{this.state.ParameterList.board_score_member}</h4> */}
                                                                <hr/>

                                                                <h4>Advisory Board Total in Lifetime: &nbsp;&nbsp;{this.state.ParameterList.board_in_lifetime}</h4>                                                                
                                                                <h4>Advisory Board Total in Year: &nbsp;&nbsp;{this.state.ParameterList.board_in_per_year}</h4>
                                                                
                                                                <hr/>

                                                                <h4>Advisory Board Score Global: &nbsp;&nbsp;{this.state.ParameterList.board_score_global}</h4>
                                                                <h4>Advisory Board Score National: &nbsp;&nbsp;{this.state.ParameterList.board_score_national}</h4>
                                                                
                                                                <h4>Advisory Board Score Local: &nbsp;&nbsp;{this.state.ParameterList.board_score_local}</h4>
                                                                <h4>Advisory Board Score Regional: &nbsp;&nbsp;{this.state.ParameterList.board_score_regional}</h4>
                                                                <hr/>
                                                                <h4>Advisory Board Score e Score Divided By: &nbsp;&nbsp;{this.state.ParameterList.board_score_e_score_div_by}</h4>
                                                                <h4>Advisory Board Score Manual Weightage Multiply By: &nbsp;&nbsp;{this.state.ParameterList.board_score_manual_weigtage_mul_by}</h4>
                                                                <h4>Advisory Board Score r Score Divided By: &nbsp;&nbsp;{this.state.ParameterList.board_score_r_score_div_by}</h4>
                                                               
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    ):(
                                                        <div></div>
                                                    )
                                                }    
                                                {
                                                    (this.state.guideline_piechart_flag == true) ? (
                                                        <div>
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                            {/* <h3 style={{marginTop:'2%',marginLeft:'1%'}}>Guideline Board Parameter List</h3> */}
                                                            <div className="screen-headingbox">
                                                                <h3>Guideline Board Parameter List
                                                                    <button className="cetas-button float-right"
                                                                        onClick={this.toggleEditGuidelineModal.bind(this)}
                                                                    >
                                                                       Edit 
                                                                    </button>
                                                                </h3>
                                                            </div>
                                                            <div style={{marginTop:'3%', marginLeft:'2%'}}> 
                                                                <h4>Guideline Score Author: &nbsp;&nbsp;{this.state.ParameterList.guideline_score_authore}</h4>
                                                                <h4>Guideline Score Committee Chair: &nbsp;&nbsp;{this.state.ParameterList.Guideline_score_committee_chair}</h4>
                                                                <h4>Guideline Score Committee Member: &nbsp;&nbsp;{this.state.ParameterList.guideline_score_committee_member}</h4>
                                                                <hr/>
                                                                <h4>Guideline Total in Lifetime: &nbsp;&nbsp;{this.state.ParameterList.Guideline_in_lifetime}</h4>                                                                
                                                                <h4>Guideline Total in Year: &nbsp;&nbsp;{this.state.ParameterList.Guideline_in_per_year}</h4>
                                                                
                                                                <hr/>
                                                                <h4>Guideline Score Global: &nbsp;&nbsp;{this.state.ParameterList.guideline_score_global}</h4>
                                                                <h4>Guideline Score Local: &nbsp;&nbsp;{this.state.ParameterList.guideline_score_local}</h4>
                                                               
                                                                <h4>Guideline Score Regional: &nbsp;&nbsp;{this.state.ParameterList.guideline_score_regional}</h4>
                                                                <h4>Guideline Score National: &nbsp;&nbsp;{this.state.ParameterList.guideline_score_national}</h4>
                                                                <hr/>
                                                                <h4>Guideline Score o Divided By: &nbsp;&nbsp;{this.state.ParameterList.guideline_score_o_score_div_by}</h4>
                                                                <h4>Guideline Score Manual Weightage Multiply By: &nbsp;&nbsp;{this.state.ParameterList.guideline_score_manual_weigtage_mul_by}</h4>
                                                               </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    ):(
                                                        <div></div>
                                                    )
                                                }                                                                                 
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* modal to edit Event */}
                    <Modal isOpen={this.state.EditEventModal} toggle={this.toggleEditEventModal.bind(this)}
                        centered >
                        <ModalHeader toggle={this.CloseEditEventModal.bind(this)}
                            cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
                            style={{ borderBottom: '0px' }}>
                            <h3 style={{ justifyContent: 'center', marginTop: '30px' }} id="login-tit">
                               Edit Event Score
                            </h3>
                        </ModalHeader>
                            <ModalBody>
                                <div>
                                <FormGroup style={{marginLeft:'4%'}}>
                                    <Label for="category">Event Chairperson/Program Director<span class="mandatory">*</span></Label>
                                    <Input id="event_score_chairperson" placeholder="Event Chairperson/Program Director"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.event_score_chairperson}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.event_score_chairperson = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.event_score_chairperson_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Event Score Keynote Speaker<span class="mandatory">*</span></Label>
                                    <Input id="event_score_keynote_speaker" placeholder="Event Score Keynote Speaker"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.event_score_keynote_speaker}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.event_score_keynote_speaker = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.event_score_keynote_speaker_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Event Score Regular Speaker/Panelist/Participant<span class="mandatory">*</span></Label>
                                    <Input id="event_score_regular" placeholder="Event Score Regular Speaker/Panelist/Participant"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.event_score_regular}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.event_score_regular = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.event_score_regular_Err}</div>
                                </FormGroup>

                                {/* <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Event Score Moderator<span class="mandatory">*</span></Label>
                                    <Input id="event_score_moderator" placeholder="Event Score Moderator"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.event_score_moderator}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.event_score_moderator = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.event_score_modarator_Err}</div>
                                </FormGroup> */}

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Event Score Session Chair<span class="mandatory">*</span></Label>
                                    <Input id="event_score_session_chair" placeholder="Event Score Session Chair"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.event_score_session_chair}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.event_score_session_chair = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.event_score_session_chair_Err}</div>
                                </FormGroup>
                               


                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Event Total in Lifetime<span class="mandatory">*</span></Label>
                                    <Input id="event_in_lifetime" placeholder="Event in lifetime"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.event_in_lifetime}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.event_in_lifetime= e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.event_in_lifetime_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Event Total in Year<span class="mandatory">*</span></Label>
                                    <Input id="event_in_per_year" placeholder="Event in Year"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.event_in_per_year}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.event_in_per_year = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.event_in_per_year_Err}</div>
                                </FormGroup>




                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Event Score Global<span class="mandatory">*</span></Label>
                                    <Input id="event_score_global" placeholder="Event Score Global"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.event_score_global}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.event_score_global = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.event_score_global_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Event Score Local<span class="mandatory">*</span></Label>
                                    <Input id="event_score_local" placeholder="Event Score Local"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.event_score_local}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.event_score_local = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.event_score_local_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Event Score National<span class="mandatory">*</span></Label>
                                    <Input id="event_score_national" placeholder="Event Score National"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.event_score_national}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.event_score_national = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.event_score_national_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Event Score Regional<span class="mandatory">*</span></Label>
                                    <Input id="event_score_regional" placeholder="Event Score Regional"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.event_score_regional}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.event_score_regional = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.event_score_regional_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Event Score Manual Weightage Multiply By<span class="mandatory">*</span></Label>
                                    <Input id="event_score_manual_weigtage_mul_by" placeholder="Event Score Manual Weightage Multiply By"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.event_score_manual_weigtage_mul_by}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.event_score_manual_weigtage_mul_by = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.event_score_manual_weigtage_mul_by_Err}</div>
                                </FormGroup>
                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Event Score r Divided By<span class="mandatory">*</span></Label>
                                    <Input id="event_score_r_score_div_by" placeholder="Event Score r Divided By"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.event_score_r_score_div_by}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.event_score_r_score_div_by = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.event_score_r_score_div_by_Err}</div>
                                </FormGroup>
                                
                                
                                
                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Event Score e Score Divided By<span class="mandatory">*</span></Label>
                                    <Input id="event_score_e_score_div_by" placeholder="Event Score e Score Divided By"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.event_score_e_score_div_by}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.event_score_e_score_div_by = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.event_score_e_score_div_by_Err}</div>
                                </FormGroup>
                                <div style={{ marginLeft: '53%', marginTop: '10%' }}>
                                    <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                                        onClick={this.CloseEditEventModal.bind(this)}>Cancel</button>
                                    &nbsp;&nbsp;
                                    <button type="submit" class="cetas-button" style={{ marginRight: '20%', }}
                                        onClick={this.EditEventModalSubmit.bind(this)}>
                                        Edit
                                    </button>
                                </div>
                                </div>
                        </ModalBody>
                    </Modal>
                    {/* modal to edit Publication */}
                    <Modal isOpen={this.state.EditPublicationModal} toggle={this.toggleEditPublicationModal.bind(this)}
                        centered >
                        <ModalHeader toggle={this.ClosePublicationEventModal.bind(this)}
                            cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
                            style={{ borderBottom: '0px' }}>
                            <h3 style={{ justifyContent: 'center', marginTop: '30px' }} id="login-tit">
                               Edit Publication Score
                            </h3>
                        </ModalHeader>
                        <ModalBody>
                            <div>
                                <FormGroup style={{marginLeft:'4%'}}>
                                    <Label for="category">Publication Score First Author<span class="mandatory">*</span></Label>
                                    <Input id="publication_score_first_author" placeholder="Publication Score First Author"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.publication_score_first_author}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.publication_score_first_author = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.publication_score_first_author_Err}</div>
                                </FormGroup>
                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Publication Score Second Author<span class="mandatory">*</span></Label>
                                    <Input id="publication_score_second_author" placeholder="Publication Score Second Author"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.publication_score_second_author}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.publication_score_second_author = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.publication_score_second_author_Err}</div>
                                </FormGroup>
                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Publication Score Last Author<span class="mandatory">*</span></Label>
                                    <Input id="publication_score_last_author" placeholder="Publication Score Last Author"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.publication_score_last_author}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.publication_score_last_author = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.publication_score_last_author_Err}</div>
                                </FormGroup>



                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Publication Total in Lifetime<span class="mandatory">*</span></Label>
                                    <Input id="publication_in_lifetime" placeholder="Publication Total in Lifetime"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.publication_in_lifetime}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.publication_in_lifetime = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.publication_in_lifetime_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Publication Total in Year<span class="mandatory">*</span></Label>
                                    <Input id="publication_in_per_year" placeholder="Publication Total in Year"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.publication_in_per_year}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.publication_in_per_year = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.publication_in_per_year_Err}</div>
                                </FormGroup>
                                




                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Publication Score e Diveided by<span class="mandatory">*</span></Label>
                                    <Input id="publication_score_e_score_div_by" placeholder="Publication Score e Diveided by"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.publication_score_e_score_div_by}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.publication_score_e_score_div_by = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.publication_score_e_score_div_by_Err}</div>
                                </FormGroup>
                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Publication Score Global<span class="mandatory">*</span></Label>
                                    <Input id="publication_score_global" placeholder="Publication Score Global"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.publication_score_global}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.publication_score_global = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.publication_score_global_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Publication Score National<span class="mandatory">*</span></Label>
                                    <Input id="publication_score_national" placeholder="Publication Score National"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.publication_score_national}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.publication_score_national = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.publication_score_national_Err}</div>
                                </FormGroup>


                                
                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Publication Score Local<span class="mandatory">*</span></Label>
                                    <Input id="publication_score_local" placeholder="Publication Score Local"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.publication_score_local}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.publication_score_local = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.publication_score_local_Err}</div>
                                </FormGroup>
                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Publication Score Manual Weigtage Multiply by<span class="mandatory">*</span></Label>
                                    <Input id="publication_score_manual_weigtage_mul_by" placeholder="Publication Score Manual Weigtage Multiply"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.publication_score_manual_weigtage_mul_by}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.publication_score_manual_weigtage_mul_by = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.publication_score_manual_weigtage_mul_by_Err}</div>
                                </FormGroup>
                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Publication Score r Divided by<span class="mandatory">*</span></Label>
                                    <Input id="publication_score_r_score_div_by" placeholder="Publication Score r Divided by"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.publication_score_r_score_div_by}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.publication_score_r_score_div_by = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.publication_score_r_score_div_by_Err}</div>
                                </FormGroup>
                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Publication Score Regional<span class="mandatory">*</span></Label>
                                    <Input id="publication_score_regional" placeholder="Publication Score Regional"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.publication_score_regional}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.publication_score_regional = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.publication_score_regional_Err}</div>
                                </FormGroup>
                                <div style={{ marginLeft: '53%', marginTop: '10%' }}>
                                    <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                                        onClick={this.ClosePublicationEventModal.bind(this)}>Cancel</button>
                                    &nbsp;&nbsp;
                                    <button type="submit" class="cetas-button" style={{ marginRight: '20%', }}
                                        onClick={this.EditPublicationModalSubmit.bind(this)}>
                                        Edit
                                    </button>
                                </div>
                            </div>
                            </ModalBody>
                    </Modal>  
                    {/* modal to edit Clinical Trial */}
                    <Modal isOpen={this.state.EditClinicalModal} toggle={this.toggleEditClinicalModal.bind(this)}
                        centered >
                        <ModalHeader toggle={this.CloseClinicalEventModal.bind(this)}
                            cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
                            style={{ borderBottom: '0px' }}>
                            <h3 style={{ justifyContent: 'center', marginTop: '30px' }} id="login-tit">
                               Edit Clinical Trial Score
                            </h3>
                        </ModalHeader>
                        <ModalBody>
                            <div>

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Clinical Score Study Chair<span class="mandatory">*</span></Label>
                                    <Input id="clinical_score_study_chair" placeholder="Clinical Score Study Chair"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.clinical_score_study_chair}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.clinical_score_study_chair = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.clinical_score_study_chair_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Clinical Score Study Director<span class="mandatory">*</span></Label>
                                    <Input id="clinical_score_study_director" placeholder="Clinical Score Study Director"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.clinical_score_study_director}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.clinical_score_study_director = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.clinical_score_study_director_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Clinical Score Investigator<span class="mandatory">*</span></Label>
                                    <Input id="clinical_score_investigator" placeholder="Clinical Score Investigator"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.clinical_score_investigator}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.clinical_score_investigator = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.clinical_score_investigator_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Clinical Score Principle Investigator<span class="mandatory">*</span></Label>
                                    <Input id="clinical_score_principle_investigator" placeholder="Clinical Score Principle Investigator"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.clinical_score_principle_investigator}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.clinical_score_principle_investigator = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.clinical_score_principle_investigator_Err}</div>
                                </FormGroup>
                                


                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Clinical Total in Lifetime<span class="mandatory">*</span></Label>
                                    <Input id="clinical_in_lifetime" placeholder="Clinical Total in Lifetime"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.clinical_in_lifetime}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.clinical_in_lifetime = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.clinical_in_lifetime_Err}</div>
                                </FormGroup>
                               
                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Clinical Total in Year<span class="mandatory">*</span></Label>
                                    <Input id="clinical_in_per_year" placeholder="Clinical Total in Year"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.clinical_in_per_year}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.clinical_in_per_year = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.clinical_in_per_year_Err}</div>
                                </FormGroup>
                               




                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Clinical Score Global<span class="mandatory">*</span></Label>
                                    <Input id="clinical_score_global" placeholder="Clinical Score Global"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.clinical_score_global}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.clinical_score_global = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.clinical_score_global_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Clinical Score Local<span class="mandatory">*</span></Label>
                                    <Input id="clinical_score_local" placeholder="Clinical Score Local"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.clinical_score_local}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.clinical_score_local = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.clinical_score_local_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Clinical Score Interventional<span class="mandatory">*</span></Label>
                                    <Input id="clinical_score_interventional" placeholder="Clinical Score Interventional"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.clinical_score_interventional}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.clinical_score_interventional = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.clinical_score_interventional_Err}</div>
                                </FormGroup>
                                
                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Clinical Score National<span class="mandatory">*</span></Label>
                                    <Input id="clinical_score_national" placeholder="Clinical Score National"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.clinical_score_national}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.clinical_score_national = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.clinical_score_national_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Clinical Score Observational<span class="mandatory">*</span></Label>
                                    <Input id="clinical_score_observational" placeholder="Clinical Score Observational"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.clinical_score_observational}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.clinical_score_observational = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.clinical_score_observational_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Clinical Score Regional<span class="mandatory">*</span></Label>
                                    <Input id="clinical_score_regional" placeholder="Clinical Score Regional"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.clinical_score_regional}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.clinical_score_regional = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.clinical_score_regional_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Clinical Score Location 1<span class="mandatory">*</span></Label>
                                    <Input id="clinical_score_study_locations_1" placeholder="Clinical Score Location 1"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.clinical_score_study_locations_1}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.clinical_score_study_locations_1 = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.clinical_score_study_locations_1_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Clinical Score Location 1 - 10<span class="mandatory">*</span></Label>
                                    <Input id="clinical_score_study_locations_10" placeholder="Clinical Score Location 10"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.clinical_score_study_locations_10}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.clinical_score_study_locations_10 = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.clinical_clinical_score_study_locations_10_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Clinical Score Location 11 - 50<span class="mandatory">*</span></Label>
                                    <Input id="clinical_score_study_locations_50" placeholder="Clinical Score Location 50"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.clinical_score_study_locations_50}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.clinical_score_study_locations_50 = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.clinical_clinical_score_study_locations_50_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Clinical Score Location More Than 50<span class="mandatory">*</span></Label>
                                    <Input id="clinical_score_study_locations_10000" placeholder="Clinical Score Location 10000"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.clinical_score_study_locations_10000}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.clinical_score_study_locations_10000 = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.clinical_clinical_score_study_locations_10000_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%'}}>
                                    <Label for="category">Clinical Score e Divided by<span class="mandatory">*</span></Label>
                                    <Input id="clinical_score_e_score_div_by" placeholder="Clinical Score e Divided by"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.clinical_score_e_score_div_by}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.clinical_score_e_score_div_by = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.clinical_score_e_score_div_by_Err}</div>
                                </FormGroup>
                                
                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Clinical Score Manual Weigtage Multiply by<span class="mandatory">*</span></Label>
                                    <Input id="clinical_score_manual_weigtage_mul_by" placeholder="Clinical Score Manual Weigtage Multiply by"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.clinical_score_manual_weigtage_mul_by}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.clinical_score_manual_weigtage_mul_by = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.clinical_score_manual_weigtage_mul_by_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Clinical Score c Divided by<span class="mandatory">*</span></Label>
                                    <Input id="clinical_score_c_score_div_by" placeholder="Clinical Score c Divided by"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.clinical_score_c_score_div_by}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.clinical_score_c_score_div_by = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.clinical_clinical_score_c_score_div_by_Err}</div>
                                </FormGroup>
                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Clinical Score Type Divided by<span class="mandatory">*</span></Label>
                                    <Input id="clinical_score_type_div_by" placeholder="Clinical Score Type Divided by"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.clinical_score_type_div_by}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.clinical_score_type_div_by = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.clinical_clinical_score_type_div_by_Err}</div>
                                </FormGroup>
                                        
                                <div style={{ marginLeft: '53%', marginTop: '10%' }}>
                                    <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                                        onClick={this.CloseClinicalEventModal.bind(this)}>Cancel</button>
                                    &nbsp;&nbsp;
                                    <button type="submit" class="cetas-button" style={{ marginRight: '20%', }}
                                        onClick={this.EditClinicalModalSubmit.bind(this)}>
                                        Edit
                                    </button>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                    {/* modal to edit Advisory board */}
                    <Modal isOpen={this.state.EditAdvisoryModal} toggle={this.toggleEditAdvisoryModal.bind(this)}
                        centered >
                        <ModalHeader toggle={this.CloseEditAdvisoryModal.bind(this)}
                            cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
                            style={{ borderBottom: '0px' }}>
                            <h3 style={{ justifyContent: 'center', marginTop: '30px' }} id="login-tit">
                               Edit Advisory Board Score
                            </h3>
                        </ModalHeader>
                        <ModalBody>
                            <div>
                                {/* <FormGroup style={{marginLeft:'4%'}}>
                                    <Label for="category">Advisory Board Score Commity<span class="mandatory">*</span></Label>
                                    <Input id="board_score_advisory_committee" placeholder="Advisory Board Score Commity"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.board_score_advisory_committee}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.board_score_advisory_committee = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.board_score_advisory_committee_Err}</div>
                                </FormGroup> */}

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Advisory Board Score Member<span class="mandatory">*</span></Label>
                                    <Input id="board_score_member" placeholder="Advisory Board Score Member"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.board_score_member}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.board_score_member = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.board_score_member_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Advisory Board Score Chair<span class="mandatory">*</span></Label>
                                    <Input id="board_score_chair" placeholder="Advisory Board Score Chair"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.board_score_chair}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.board_score_chair = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.board_score_chair_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Advisory Board Score Co-Chair<span class="mandatory">*</span></Label>
                                    <Input id="board_score_co_chair" placeholder="Advisory Board Score Co-Chair"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.board_score_co_chair}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.board_score_co_chair = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.board_score_co_chair_Err}</div>
                                </FormGroup>




                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Advisory Board Total in Lifetime<span class="mandatory">*</span></Label>
                                    <Input id="board_in_lifetime" placeholder="Advisory Board Total in Lifetime"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.board_in_lifetime}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.board_in_lifetime = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.board_in_lifetime_Err}</div>
                                </FormGroup>

                                
                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Advisory Board Total in Year<span class="mandatory">*</span></Label>
                                    <Input id="board_in_per_year" placeholder="Advisory Board Total in Year"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.board_in_per_year}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.board_in_per_year = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.board_in_per_year_Err}</div>
                                </FormGroup>











                                
                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Advisory Board Score Global<span class="mandatory">*</span></Label>
                                    <Input id="board_score_global" placeholder="Advisory Board Score Global"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.board_score_global}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.board_score_global = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.board_score_global_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Advisory Board Score National<span class="mandatory">*</span></Label>
                                    <Input id="board_score_national" placeholder="Advisory Board Score Global"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.board_score_national}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.board_score_national = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.board_score_national_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Advisory Board Score Local<span class="mandatory">*</span></Label>
                                    <Input id="board_score_local" placeholder="Advisory Board Score Local"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.board_score_local}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.board_score_local = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.board_score_local_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Advisory Board Score Regional<span class="mandatory">*</span></Label>
                                    <Input id="board_score_regional" placeholder="Advisory Board Score Regional"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.board_score_regional}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.board_score_regional = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.board_score_regional_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Advisory Board Score Manual Weigtage Multiply by<span class="mandatory">*</span></Label>
                                    <Input id="board_score_manual_weigtage_mul_by" placeholder="Advisory Board Score Manual Weigtage Multiply by"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.board_score_manual_weigtage_mul_by}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.board_score_manual_weigtage_mul_by = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.board_score_manual_weigtage_mul_by_Err}</div>
                                </FormGroup>
                                
                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Advisory Board Score r Divided by<span class="mandatory">*</span></Label>
                                    <Input id="board_score_r_score_div_by" placeholder="Advisory Board Score r Divided by"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.board_score_r_score_div_by}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.board_score_r_score_div_by = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.board_score_r_score_div_by_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Advisory Board Score e Divided by<span class="mandatory">*</span></Label>
                                    <Input id="board_score_e_score_div_by" placeholder="Advisory Board Score e Divided by"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.board_score_e_score_div_by}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.board_score_e_score_div_by = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.board_score_e_score_div_by_Err}</div>
                                </FormGroup>

                                <div style={{ marginLeft: '53%', marginTop: '10%' }}>
                                    <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                                        onClick={this.CloseEditAdvisoryModal.bind(this)}>Cancel</button>
                                    &nbsp;&nbsp;
                                    <button type="submit" class="cetas-button" style={{ marginRight: '20%', }}
                                        onClick={this.EditAdvisoryModalSubmit.bind(this)}>
                                        Edit
                                    </button>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                    {/* modal to edit Advisory board */}
                    <Modal isOpen={this.state.EditGuidelineModal} toggle={this.toggleEditGuidelineModal.bind(this)}
                        centered >
                        <ModalHeader toggle={this.CloseEditGuidelineModal.bind(this)}
                            cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
                            style={{ borderBottom: '0px' }}>
                            <h3 style={{ justifyContent: 'center', marginTop: '30px' }} id="login-tit">
                               Edit Guideline Score
                            </h3>
                        </ModalHeader>
                        <ModalBody>
                            <div>
                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Guideline Score Author<span class="mandatory">*</span></Label>
                                    <Input id="guideline_score_authore" placeholder="Guideline Score Author"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.guideline_score_authore}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.guideline_score_authore = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.guideline_score_authore_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Guideline Score Committee Chair<span class="mandatory">*</span></Label>
                                    <Input id="Guideline_score_committee_chair" placeholder="Guideline Score  Committee Chair"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.Guideline_score_committee_chair}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.Guideline_score_committee_chair = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.Guideline_score_committee_chair_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Guideline Score Committee Member<span class="mandatory">*</span></Label>
                                    <Input id="guideline_score_committee_member" placeholder="Guideline Score  Committee Member"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.guideline_score_committee_member}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.guideline_score_committee_member = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.guideline_score_committee_member_Err}</div>
                                </FormGroup>



                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Guideline Total in Lifetime<span class="mandatory">*</span></Label>
                                    <Input id="Guideline_in_lifetime" placeholder="Guideline Total in Lifetime"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.Guideline_in_lifetime}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.Guideline_in_lifetime = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.Guideline_in_lifetime_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Guideline Total in Year<span class="mandatory">*</span></Label>
                                    <Input id="Guideline_in_per_year" placeholder="Guideline Total in Year"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.Guideline_in_per_year}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.Guideline_in_per_year = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.Guideline_in_per_year_Err}</div>
                                </FormGroup>






                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Guideline Score Global<span class="mandatory">*</span></Label>
                                    <Input id="guideline_score_global" placeholder="Guideline Score Global"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.guideline_score_global}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.guideline_score_global = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.guideline_score_global_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Guideline Score Local<span class="mandatory">*</span></Label>
                                    <Input id="guideline_score_local" placeholder="Guideline Score Local"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.guideline_score_local}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.guideline_score_local = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.guideline_score_local_Err}</div>
                                </FormGroup>
                                

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Guideline Score Regional<span class="mandatory">*</span></Label>
                                    <Input id="guideline_score_regional" placeholder="Guideline Score Regional"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.guideline_score_regional}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.guideline_score_regional = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.guideline_score_regional_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Guideline Score National<span class="mandatory">*</span></Label>
                                    <Input id="guideline_score_national" placeholder="Guideline Score National"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.guideline_score_national}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.guideline_score_national = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.guideline_score_national_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%'}}>
                                    <Label for="category">Guideline Score o Divided by<span class="mandatory">*</span></Label>
                                    <Input id="guideline_score_o_score_div_by" placeholder="uideline Score o Divided by"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.guideline_score_o_score_div_by}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.guideline_score_o_score_div_by = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.guideline_score_o_score_div_by_Err}</div>
                                </FormGroup>

                                <FormGroup style={{marginLeft:'4%', marginTop:'5%'}}>
                                    <Label for="category">Guideline Score Manual Weitage Multiply by<span class="mandatory">*</span></Label>
                                    <Input id="guideline_score_manual_weigtage_mul_by" placeholder="Guideline Score Manual Weitage Multiply by"
                                        style={{ width: '95%', height: '50px', }}
                                        type="number"
                                        value={this.state.ParameterList.guideline_score_manual_weigtage_mul_by}
                                        onChange={(e) => {
                                            let { ParameterList } = this.state;
                                            ParameterList.guideline_score_manual_weigtage_mul_by = e.target.value;
                                            this.setState({ ParameterList });
                                        }}
                                    />
                                    <div className="centerErrLoginPass"style={{marginLeft:'1%', marginTop:'-1%'}}>{this.state.guideline_score_manual_weigtage_mul_by_Err}</div>
                                </FormGroup>

                                <div style={{ marginLeft: '53%', marginTop: '10%' }}>
                                    <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                                        onClick={this.CloseEditGuidelineModal.bind(this)}>Cancel</button>
                                    &nbsp;&nbsp;
                                    <button type="submit" class="cetas-button" style={{ marginRight: '20%', }}
                                        onClick={this.EditGuidelineModalSubmit.bind(this)}>
                                        Edit
                                    </button>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
            </body>
        )
    }
}
export default ParameterConfiguration;