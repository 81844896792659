import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, Col, FormFeedback, Form, Popover, PopoverHeader, PopoverBody, Alert } from 'reactstrap';

import { Typeahead } from "react-bootstrap-typeahead";
import PhoneInput from "react-phone-input-2";
import Swal from 'sweetalert2';
import axios from "axios";
//import '../styles/ClientHeader.css';
//import './Client.css';

var jwt=require('jsonwebtoken');
var jwtkey= require('../config');

class ClientHeader extends Component {
  constructor(props) {
    console.log(props)
    super(props);
    this.state = {
      procedureSelected: false,
      conditionSelected: false,
      subSpecilityVisible: false,
      redirect: false,
      client_id: "",
      client_name: "",
      client_email: "",
      kol_id: "",
      project_id: "",
      SpecialityData:[],
      clinicalKolForm: {
        speciality: "",
        subSpeciality: "",
        procedure: "",
        condition: "",
        country: "",
        procedureType: ""
      },
      nonClinicalKolForm: {
        kolSubType: "",
        country: "",
        fullName: "",
        workEmail: "",
        phone: "",
        companyName: "",
        jobTitle: "",
        message: "",
      },
      subClinicalKolEnabled: true,
      nonClinicalContactFormSubmitted: false,
      showNonClinicalContactForm: false,
      kols: [
        { id: "clinical", label: "Clinical" },
        { id: "non-clinical", label: "Non-Clinical" },
      ],
      kolSubType: [
        { value: "Payers", label: "Payers" },
        { value: "HTA Experts", label: "HTA Experts" },
        {
          value: "Hospital Procurement Experts",
          label: "Hospital Procurement Experts",
        },
        { value: "Advocacy Group Experts", label: "Advocacy Group Experts" },
        {
          value: "MedTech Industry Experts",
          label: "MedTech Industry Experts",
        },
      ],
      countryList: [
        { value: "USA", label: "USA" },
        { value: "Canada", label: "Canada" },
        { value: "UK", label: "UK" },
        { value: "Germany", label: "Germany" },
        { value: "France", label: "France" },
        { value: "Italy", label: "Italy" },
        { value: "Spain", label: "Spain" },
      ],
    };
  }

  componentDidMount = async () => {

    let getSession_wtoken = await sessionStorage.getItem('wtoken')  
     //console.log('tok1',getSession_wtoken);
 
     jwt.verify(getSession_wtoken,jwtkey.jwt.key, async (err, response) => {   
      ///console.log('tok2',response); 
     if(!err && (response.login_type =="client" || response.login_type =="client_demo"))
         {
            console.log('errclient===',response);

            let getSession_Client_token = await sessionStorage.getItem('token')
            if (getSession_Client_token !== null) {
              await this.setState({
                client_token: getSession_Client_token,
              })
            }
            else {
              this.setState({ redirect: true });
            }

            let getSession_Client_Email = await sessionStorage.getItem("email");
            if (getSession_Client_Email !== null) {
              this.setState({
                client_email: getSession_Client_Email,
              });
            }

            let getSession_Client_ID = await sessionStorage.getItem("id");
            if (getSession_Client_ID !== null) {
              this.setState({
                client_id: getSession_Client_ID,
              });
            }
             else {
              this.setState({ redirect: true });
            }

            let getSession_Client_Name = sessionStorage.getItem("name");
            let email_id = sessionStorage.getItem("email");
            console.log(getSession_Client_Name, "getSession_Client_Name");
            if (getSession_Client_Name !== null) {
              this.setState({
                client_name: getSession_Client_Name,
                client_email: email_id,
              });
            } else {
              this.setState({ redirect: true });
            }

            //alert(this.state.client_name);

            let getSession_Kol_Id = sessionStorage.getItem("kol_id");
            // console.log({ getSession_Kol_Id })
            console.log({ getSession_Kol_IdJSON: typeof getSession_Kol_Id })

            if (getSession_Kol_Id !== null) {
              this.setState({
                kol_id: JSON.parse(getSession_Kol_Id),
              });
            }

            let getSession_Project_Id = await sessionStorage.getItem("project_id");
            if (getSession_Project_Id !== null) {
              await this.setState({
                project_id: getSession_Project_Id,
              });
            }

            // console.log("getSession_Client_Email ====", getSession_Client_Email);
            // console.log("getSession_Client_ID ====", getSession_Client_ID);
            // console.log("getSession_Client_Name ====", getSession_Client_Name);

            // console.log("getSession_kol_id ====", this.state.kol_id);
            // console.log("getSession_Project_Id ===== ", getSession_Project_Id);
 
            
                const url =
                global.websiteUrl +
                "/service/KOL_landing/Get_Speciality_List";
              let formData = new FormData();
              formData.append('token_id',getSession_Client_token);
              const config = {
                //   headers: { 'content-type': 'multipart/form-data' }
              };
              axios
            .post(url, formData, config)
            .then((response) => {
              console.log(response);
              const SpecialityData = response.data;
              console.log("SpecialityData", JSON.stringify(SpecialityData));
              if (SpecialityData.status === true) {
                this.setState({
                  SpecialityData: response.data.data.map((e) => ({
                    label: e.speciality_type,
                    value: e.id,
                  }))

                });
                
              }
            })
            .catch((error) => {
              console.error('There was an error!', error);
            });


                const url1 =
                global.websiteUrl +
                "/service/Location/get_CountryList";
              // let formData1 = new FormData();
              // formData1.append('token_id',getSession_Client_token);
              // const config1 = {
              //   //   headers: { 'content-type': 'multipart/form-data' }
              // };
              axios
            .post(url1, formData, config)
            .then((findresponse) => {
            // console.log("country=",findresponse);
              const CountryData = findresponse.data;
              if (CountryData.status === true) {
                this.setState({
                  countryList: findresponse.data.Country_Details.map(c => ({ "value": c.id, "label": c.country_name })),
                  Client_Unique_id: findresponse.data.client_system_id,

                });
              // console.log("CountryData", this.state.countryList);
                
              }
            })
            .catch((error) => {
              console.error('There was an error!', error);
            });


          }
          else
          {                        
              Swal.fire({
              icon: 'error',
              title: jwtkey.jwt.session_expired_msg_title,
              text:jwtkey.jwt.wrong_url_msg,
              // showCancelButton: true,
              confirmButtonColor: '#d33',
              cancelButtonColor: '#d33',
              confirmButtonText: 'OK'
              })
              sessionStorage.removeItem('token');
              sessionStorage.removeItem('wtoken');
              sessionStorage.removeItem('id');
              sessionStorage.removeItem('name');
              sessionStorage.removeItem('email');
              sessionStorage.removeItem('login_type');   
              sessionStorage.removeItem("speciality_id");           
              this.setState({redirect: true})
          }
         
       })
 

    


  };


  validate = () => {
    let UsernameErr = "";
    let UseremailErr = "";
    let CompanyNameErr = "";
    let PhoneErr = "";
    let DesignationErr = "";
    let MessageErr = "";

    if (!this.state.GetInTouchData.client_name) {
      UsernameErr = "Please Enter Full Name";
    }
    if (!this.state.GetInTouchData.email_id) {
      UseremailErr = "Please Enter Email id";
    }

    if (!this.state.GetInTouchData.company_nm) {
      CompanyNameErr = "Please Enter Company Name";
    }

    if (!this.state.GetInTouchData.mobile_no) {
      PhoneErr = "Please Enter phone";
    }

    if (!this.state.GetInTouchData.designation) {
      DesignationErr = "Please Enter designation";
    }

    if (!this.state.GetInTouchData.message) {
      MessageErr = "Please Enter message";
    }

    if (
      UsernameErr ||
      UseremailErr ||
      CompanyNameErr ||
      PhoneErr ||
      DesignationErr ||
      MessageErr
    ) {
      this.setState({
        UsernameErr,
        PhoneErr,
        UseremailErr,
        CompanyNameErr,
        DesignationErr,
        MessageErr,
      });
      return false;
    }
    return true;
  };
  validateField(type, id) {
    console.log('Validate field called with - ', { type, id })
    const form = this.state[type];
    if (this.state.kolFormSubmitted) {
      console.log('kolFormSubmitted', ' ', form[id])
      if (form[id]) {
        console.log('returning false')
        return false;
      } else {
        console.log('returning true')
        return true;
      }
    } else {
      console.log('returning false')
      return false;
    }
  }
  validateContactFormField(id) {
    const form = this.state.nonClinicalKolForm;
    // console.log(this.state.nonClinicalContactFormSubmitted, "check");
    if (this.state.nonClinicalContactFormSubmitted) {
      if (form[id]) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
  toggleSearchModal() {
    if (this.state.speciality_name != "") {
      if (this.state.client_name == "") {
        sessionStorage.setItem('speciality_id', this.state.speciality_name)
      }
      else {
        sessionStorage.setItem('speciality_id', this.state.speciality_name)
      }
      if (window.location.href.includes('PClientViewKolList')) {
        window.location.reload(true)
      }
      else if (window.location.href.includes('PClientKolProfile')) {
        this.setState({
          redirectBackToKolList: true,
        })
      }
    }
    else {
      Swal.fire({
        icon: 'error',
        title: 'Oops',
        text: 'Select Speciality',
        confirmButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    }
  }
  handleClinicalFormField(id, value) {
    console.log({
      id,
      value,
      procedure: this.state.Procedure
    });

    let { clinicalKolForm } = this.state;
    // this.setState({
    //   ProcedureType: []
    // })

    if (id != '') {
      console.log('value setting in procedure')
      console.log(value)
      clinicalKolForm[id] = value;
      clinicalKolForm['procedureType'] = this.state.Procedure
      // clinicalKolForm['procedure'] = value
    }
    console.log(clinicalKolForm)
    // const spId = this.state.SpecialityData.filter(sp => sp.speciality_type === value)[0]
    if (id == "speciality" && value?.[0]?.value !== undefined) {
      this.state.subClinicalKolEnabled = false
      this.setSubSpecility(value?.[0]?.value);
      this.state.profiletypeErr = false;
      this.setState({
        ProcedureType: [],
        Procedure: false,
        procedureSelected: false,
        conditionSelected: false
      })
    } else if (id == "subSpeciality" && value?.[0]?.value !== undefined) {
      this.state.subClinicalKolEnabled = false
    }
    else if (id == "procedure" && value?.[0]?.value !== undefined) {
      this.state.subClinicalKolEnabled = false
    } else {
      this.state.subClinicalKolEnabled = true
    }
    this.setState({ clinicalKolForm: clinicalKolForm });
    console.log(this.state.clinicalKolForm);
  }
  handleNonClinicalFormField(id, value) {
    let { nonClinicalKolForm } = this.state;
    nonClinicalKolForm[id] = value;
    this.setState({ nonClinicalKolForm: nonClinicalKolForm });
  }
  setSubSpecility = (id) => {
    const formdata = new FormData();
    formdata.append("speciality_type_id", id);
    formdata.append('token_id',this.state.client_token);

    const options = {
      method: "POST",
      body: formdata,
    };
    // Speciality type data
    const url = "/service/KOL_landing/Get_Sub_Speciality_List";
    fetch(global.websiteUrl + url, options)
      .then((Response) => Response.json())
      .then((findresponse) => {
        let subSpecilityList = findresponse.data.map(e => ({
          label: e.sub_speciality_type,
          value: e.id,
        }))
        if (findresponse.data != "undefined") {

          this.setState({
            SubSpecialityType: subSpecilityList,
            subSpecilityVisible: subSpecilityList[0].label !== "NA" ? true : false
          });
        }
      });
  };
  onProcedureChanged = (e) => {
    console.log(e.target.value);
    let { clinicalKolForm } = this.state
    console.log(clinicalKolForm)
    let speciality_id = clinicalKolForm.speciality
    if (!speciality_id) {
      this.state.profiletypeErr = 'Please select Speciality!'
    }
    const value = e.target.value;
    this.setState({
      ProcedureType: [],
    });
    if (this.refs.typeahead) { this.refs.typeahead.clear() }
    value === "Procedure" ? this.setState({ procedureSelected: true, conditionSelected: false }) : this.setState({ procedureSelected: false, conditionSelected: true })
    const formdata = new FormData();
    if (speciality_id?.[0]) {
      formdata.append("speciality_type_id", speciality_id[0].value);
      formdata.append('token_id',this.state.client_token);

      console.log({ value })
      this.setState({ Procedure: value });
      this.state.profiletypeErr = false
      this.state.subClinicalKolEnabled = true
    }
    const options = {
      method: "POST",
      body: formdata,
    };
    let url =
      value === "Procedure"
        ? "/service/KOL_landing/Get_Procedure_List"
        : "/service/KOL_landing/Get_Condition_List";

    fetch(global.websiteUrl + url, options)
      .then((Response) => Response.json())
      .then((findresponse) => {
        console.log(findresponse);
        var procedureList = findresponse.data;
        if (procedureList != "undefined") {
          this.setState({
            ProcedureType: procedureList.map((item) => ({
              label:
                value === "Procedure"
                  ? item.procedure_type
                  : item.condition_type,
              value: item.id,
            })),
          });
        }
      });
  };
  submitKOLform() {
    this.setState({ kolFormSubmitted: true });
    console.log(this.state.selectedKol)
    console.log(this.state.kols[0].label)
    console.log(this.state.nonClinicalKolForm.kolSubType)
    if (this.state.selectedKol === this.state.kols[0].label) {
      if (this.state.clinicalKolForm.speciality) {
         sessionStorage.setItem(
          "subspeciality",
          this.state.clinicalKolForm.subSpeciality?.[0]?.label
        );
        sessionStorage.setItem(
          "procedure",
          this.state.clinicalKolForm.procedure?.[0]?.label
        );
        sessionStorage.setItem(
          "speciality_id",
          this.state.clinicalKolForm?.speciality?.[0]?.value
        );
        sessionStorage.setItem(
          "speciality",
          this.state.clinicalKolForm?.speciality?.[0]?.label
        );
        this.props.onSubmitHandler(this.state.selectedKol, this.state.clinicalKolForm, () => {
          this.setState({
            searchModalOpen: false,
          })
        })
        // this.setState({ redirectPClientViewKolList: true });
      } else {
        Swal.fire({
          icon: "error",
          text: "Please Select Speciality",
          confirmButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    } else {
      if (this.state.nonClinicalKolForm.kolSubType) {
        this.setState({
          showNonClinicalContactForm: true,
          searchModalOpen: false,
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "Please Select KOL Subtype",
          confirmButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    }
  }
  logout = () => {
    console.log("in logout");

    sessionStorage.removeItem('email');
    sessionStorage.removeItem('id');
    sessionStorage.removeItem('name');
    sessionStorage.removeItem('wtoken');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('login_type');
    sessionStorage.removeItem('project_id');
    sessionStorage.removeItem('kol_id');
    localStorage.removeItem('SearchedProfileData');
    sessionStorage.removeItem('token');              
    sessionStorage.removeItem('wtoken');
    sessionStorage.removeItem('id');
    sessionStorage.removeItem('name');
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('login_type');  

    sessionStorage.removeItem("kol_id");
    sessionStorage.removeItem("getSession_Project_Id");
    sessionStorage.removeItem("speciality_id");
    
    this.setState({ redirect: true });
  };
  openSearchModal() {
    if (this.state.selectedKol != "") {
      console.log(this.state.selectedKol);
      this.setState({ searchModalOpen: true });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops",
        text: "Please select KOL",
        confirmButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
  }
  closeSearchModal() {
    this.setState({ searchModalOpen: false, kolFormSubmitted: false });
  }
  render() {
    if (this.state.redirect) {
      return <Redirect to={"/"} />;
    }
    return (
      <div class="Client-header">
        <div className="header-nav">
          <div className="container">
            <nav class="navbar navbar-light navbar-expand-lg fixed-top">
              <div class="container-fluid">
                <a
                  style={{ display: "table-cell" }}
                  className="navbar-brand"
                  href="/"
                  target="_blank"
                  rel="noopener"
                >
                  <img
                    src={require("../Assets/Images/Medtech_Gurus_logo.png")}
                    width="100"
                  />
                </a>

                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNavAltMarkup"
                  aria-controls="navbarNavAltMarkup"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div
                  className="collapse navbar-collapse"
                  id="navbarNavAltMarkup"
                >
                  <div className="navbar-nav">
                    {/* <a className="nav-item nav-link" href="#">Home 
                                    <span className="sr-only">(current)</span>
                                </a>
                                <a className="nav-item nav-link active" href="#">Projects</a>
                                <a className="nav-item nav-link" href="#">Team Members</a> */}
                    {/* <a className="nav-item nav-link" >
                                    <img
                                        src={require('../Assets/Images/setting.png')} 
                                    /> 
                                    Search
                                </a> */}
                  </div>
                </div>
                <div
                  class="collapse navbar-collapse justify-content-end"
                  id="navbarNavAltMarkup"
                >
                  {/* {sessionStorage.getItem('login_type') == "client_demo" && this.props.onSubmitHandler !== undefined ? (<div class="preclient"> */}
                  {this.props.onSubmitHandler !== undefined ? (<div class="preclient">
                 
                    <div className="searchbox">
                      <Input
                        type="select"
                        id="SpecilitySelect"
                        style={{ backgroundColor: "white" }}
                        value={this.state.selectedKol}
                        onChange={(e) => {
                          console.log(e.target.value, "target");
                          this.setState({ selectedKol: e.target.value });
                        }}
                      >
                        <option value="">Search KOLs</option>
                        {this.state.kols.map((kol) => (
                          <option key={kol.id} value={kol.label}>
                            {kol.label}
                          </option>
                        ))}
                      </Input>
                    </div>
                    <a
                      href="#" data-bs-toggle="modal"
                      onClick={this.openSearchModal.bind(this)}
                      style={{
                        background: "#0C4272"
                        , color: "#fff"
                        , paddingTop: "8px"
                        , paddingBottom: "8px"
                        , paddingLeft: "10px"
                        , paddingRight: "10px"
                        , borderRadius: "5px"
                        , minWidth: "110px"
                        , fontWeight: "400"
                        , fontSize: "14px"
                        , marginLeft: "3px"
                        , height: "auto"
                      }}
                    >
                      <i class="fa fa-search" style={{ fontWeight: 'lighter' }}></i> &nbsp;&nbsp; SEARCH
                    </a>
                  </div>) : (<div />)}
                  <Dropdown>
                    <Dropdown.Toggle
                      className="client_dropdown"
                      id="dropdown-basic"
                    >
                      <span class="btn-label">
                        <i
                          class="fa fa-user"
                          style={{ marginRight: "10px" }}
                        ></i>
                        <span>{this.state.client_name}</span>
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{ width: "300px !important" }}>
                      <Dropdown.ItemText>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div className="client_profile"></div>

                          <div style={{ marginLeft: 15 }}>
                            <div style={{ fontSize: 16, fontWeight: 500 }}>
                              {" "}
                              {this.state.client_name}
                            </div>
                            <div style={{ fontSize: 12, fontWeight: 400 }}>
                              {this.state.client_email}
                            </div>
                            <a style={{ color: "#212529", fontWeight: 400 }} href="#">
                              <div style={{ fontSize: 12, marginBottom: 5 }}>
                                View Account
                                <i
                                  style={{ marginLeft: 10 }}
                                  class="fa fa-external-link"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </a>
                          </div>
                        </div>
                      </Dropdown.ItemText>
                      {/* <Dropdown.ItemText className="align-center">
                      
                      </Dropdown.ItemText>
                      <Dropdown.ItemText className="align-center">
         
                      </Dropdown.ItemText> */}
                      <Dropdown.Divider />
                      <Dropdown.Item
                        className="client_dropdown_item"
                        onClick={this.logout.bind(this)}
                        as="button"
                      >
                        <i class="fa fa-power-off"></i>
                        <span style={{ marginLeft: 10 }}>Logout</span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <Modal
          isOpen={this.state.searchModalOpen}
          toggle={() => this.closeSearchModal()}
          centered
          fullscreen
        >
          <ModalHeader toggle={() => this.closeSearchModal()}>
            <h3>{this.state.selectedKol} KOL</h3>
          </ModalHeader>
          <ModalBody style={{ padding: "0px 30px" }}>
            <div style={{ margin: "20px 0px" }}>
              {this.state.selectedKol === this.state.kols[0].label ? (
                <Form>
                  <FormGroup>
                    <Label>Select Country</Label>
                    <Typeahead
                      id="clinical_country"
                      multiple
                      onChange={(e) =>
                        this.handleClinicalFormField("country", e)
                      }
                      options={this.state.countryList}
                      placeholder="Choose a Country..."
                      selected={this.state.clinicalKolForm.country}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      Select Speciality <span class="mandatory">*</span>
                    </Label>
                    <Typeahead
                      id="speciality"
                      invalid={this.validateField(
                        "clinicalKolForm",
                        "speciality"
                      )}
                      onChange={(e) =>
                        this.handleClinicalFormField("speciality", e)
                      }
                      options={this.state.SpecialityData}
                      placeholder="Choose a Speciality..."
                      selected={this.state.clinicalKolForm.speciality}
                    />
                    {this.validateField("clinicalKolForm", "speciality") && (
                      <FormFeedback>Please Select Speciality</FormFeedback>
                    )}
                  </FormGroup>
                  <FormGroup style={this.state.subSpecilityVisible ? {} : { "display": "none" }}>
                    <Label>Select Sub-Speciality</Label>
                    <Typeahead
                      id="subSpeciality"
                      multiple
                      onChange={(e) =>
                        this.handleClinicalFormField("subSpeciality", e)
                      }
                      options={this.state.SubSpecialityType}
                      placeholder="Choose a Sub-speciality..."
                      selected={this.state.clinicalKolForm.subSpeciality}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Please specify the procedure/condition (if any)</Label>
                    <div
                      onChange={this.onProcedureChanged}
                      style={{
                        height: "30px",
                        width: "100%",
                        // color: "#949393",
                        backgroundColor: "#fff",
                        borderColor: "#ccc",
                      }}
                    >
                      <input
                        type="radio"
                        value="Procedure"
                        name="Procedure"
                        checked={this.state.procedureSelected}
                      />
                      <span className="Radio_button">Procedure </span>
                      <input
                        type="radio"
                        value="Condition"
                        name="Procedure"
                        checked={this.state.conditionSelected}
                        style={{ marginLeft: "5%" }}
                      />{" "}
                      Condition
                    </div>

                    <div className="Quotblanck">
                      {this.state.profiletypeErr ? this.state.profiletypeErr : null}
                    </div>
                  </FormGroup>
                  {this.state.Procedure && (
                    <FormGroup>
                      <Label>Select {this.state.Procedure}</Label>
                      <Typeahead
                        ref='typeahead'
                        id="procedure"
                        onChange={(e) =>
                          this.handleClinicalFormField("procedure", e)
                        }
                        options={this.state.ProcedureType}
                        placeholder={`Choose a ${this.state.Procedure}...`}
                        selected={this.state.clinicalKolForm.procedure}
                      />
                    </FormGroup>
                  )}
                </Form>
              ) : (
                <Form>
                  <FormGroup>
                    <Label>
                      Select KOL Subtype <span class="mandatory">*</span>
                    </Label>
                    <Typeahead
                      id="kolSubType"
                      onChange={(e) =>
                        this.handleNonClinicalFormField("kolSubType", e)
                      }
                      options={this.state.kolSubType}
                      placeholder="Choose a KOL Subtype..."
                      selected={this.state.nonClinicalKolForm.kolSubType}
                    />

                    {this.validateField("nonClinicalKolForm", "kolSubType") && (
                      <FormFeedback>Please Select Sub-KOL Type</FormFeedback>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label>Select Country</Label>
                    <Typeahead
                      id="nonclinical_country"
                      multiple
                      onChange={(e) =>
                        this.handleNonClinicalFormField("country", e)
                      }
                      options={this.state.countryList}
                      placeholder="Choose a Country..."
                      selected={this.state.nonClinicalKolForm.country}
                    />
                  </FormGroup>
                </Form>
              )}
            </div>
            <br />
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => this.submitKOLform()}
              className="btn btn-brand ms-lg-3"
              disabled={this.state.subClinicalKolEnabled}
            >
              Submit
            </Button>{" "}
            <Button color="secondary" onClick={() => this.closeSearchModal()}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.showNonClinicalContactForm}
          toggle={() => this.closeNonClinicalContactModal()}
          size="lg"
          style={{ maxWidth: "700px", width: "100%" }}
          centered
          fullscreen
        >
          <ModalHeader toggle={() => this.closeNonClinicalContactModal()}>
            <h1>Get in touch with us</h1>
            <p>
              Please fill in the form below and we will get in touch within 1
              working day
            </p>
          </ModalHeader>
          <ModalBody style={{ padding: "0px 30px" }}>
            <form className="p-lg-5 col-12 row g-3">
              <div className="col-lg-6">
                <label for="" className="form-label">
                  KOL Subtype:<span class="mandatory">*</span>
                </label>
                <Input
                  type="select"
                  id="SpecilitySelect"
                  value={this.state.nonClinicalKolForm.kolSubType[0]?.label}
                  onChange={(e) =>
                    this.handleNonClinicalFormField(
                      "kolSubType",
                      e.target.value
                    )
                  }
                  style={{ backgroundColor: "white" }}
                >
                  <option>Select KOL Subtype</option>
                  {this.state.kolSubType.map((kol) => (
                    <option key={kol.id} value={kol.label}>
                      {kol.label}
                    </option>
                  ))}
                </Input>
                {this.validateContactFormField("kolSubType") && (
                  <FormFeedback>Please Select Sub-KOL Type</FormFeedback>
                )}
                <br />
              </div>
              <div className="col-lg-6">
                <label for="" className="form-label">
                  Country:
                </label>
                <Input
                  type="select"
                  id="SpecilitySelect"
                  multi
                  style={{ backgroundColor: "white" }}
                  value={this.state.nonClinicalKolForm.country[0]?.label}
                  onChange={(e) =>
                    this.handleNonClinicalFormField("country", e.target.value)
                  }
                >
                  <option value="">Select Country</option>
                  {this.state.countryList.map((country) => (
                    <option key={country.value} value={country.label}>
                      {country.label}
                    </option>
                  ))}
                </Input>
              </div>
              <div className="col-lg-6">
                <label for="" className="form-label">
                  Full Name:<span class="mandatory">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  id=""
                  aria-describedby=""
                  value={this.state.nonClinicalKolForm.fullName}
                  onChange={(e) => {
                    this.handleNonClinicalFormField("fullName", e.target.value);
                  }}
                ></input>
                {this.validateContactFormField("fullName") && (
                  <div className="error">Please Enter Full Name</div>
                )}
              </div>

              <div className="col-lg-6">
                <label for="" className="form-label">
                  Your work email:<span class="mandatory">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  id=""
                  aria-describedby=""
                  value={this.state.nonClinicalKolForm.workEmail}
                  onChange={(e) => {
                    this.handleNonClinicalFormField(
                      "workEmail",
                      e.target.value
                    );
                  }}
                ></input>
                {this.validateContactFormField("workEmail") && (
                  <div className="error">Please Enter Work Email</div>
                )}
              </div>
              <div className="col-lg-6">
                <label for="" className="form-label">
                  Your work phone:<span class="mandatory">*</span>
                </label>

                <PhoneInput
                  inputStyle={{
                    width: "100%",
                    height: "50px",
                    fontSize: "15px",
                  }}
                  width="50%"
                  country={"in"}
                  value={this.state.nonClinicalKolForm.phone}
                  onChange={(mobile_no) => {
                    this.handleNonClinicalFormField("phone", mobile_no);
                  }}
                />

                {this.validateContactFormField("phone") && (
                  <div
                    style={{ marginTop: "10px !important" }}
                    className="error"
                  >
                    Please Enter Mobile Number
                  </div>
                )}
              </div>
              <div className="col-lg-6">
                <label for="" className="form-label">
                  Your company name:<span class="mandatory">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  id=""
                  aria-describedby=""
                  value={this.state.nonClinicalKolForm.companyName}
                  onChange={(e) => {
                    this.handleNonClinicalFormField(
                      "companyName",
                      e.target.value
                    );
                  }}
                ></input>
                {this.validateContactFormField("companyName") && (
                  <div className="error">Please Enter Work Email</div>
                )}
              </div>
              <div className="col-lg-12">
                <label for="" className="form-label">
                  Your job title:<span class="mandatory">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  id=""
                  aria-describedby=""
                  value={this.state.nonClinicalKolForm.jobTitle}
                  onChange={(e) => {
                    this.handleNonClinicalFormField("jobTitle", e.target.value);
                  }}
                ></input>
                {this.validateContactFormField("jobTitle") && (
                  <div className="error">Please Enter Job Title</div>
                )}
              </div>
              <div className="col-lg-12">
                <label for="exampleInputEmail1" className="form-label">
                  Message:<span class="mandatory">*</span>
                </label>
                <textarea
                  name=""
                  placeholder=""
                  className="form-control"
                  id=""
                  rows="2"
                  value={this.state.nonClinicalKolForm.message}
                  onChange={(e) => {
                    this.handleNonClinicalFormField("message", e.target.value);
                  }}
                ></textarea>

                {this.validateContactFormField("message") && (
                  <div className="error">Please Enter Message</div>
                )}
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() =>
                this.setState({
                  nonClinicalContactFormSubmitted: true,
                  showNonClinicalContactForm: false,
                })
              }
              className="btn btn-brand ms-lg-3"
            >
              Submit
            </Button>{" "}
            <Button
              color="secondary"
              onClick={() => this.closeNonClinicalContactModal()}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div >
    );
  }
}

export default ClientHeader;
