import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button } from 'reactstrap';
import filterFactory, { textFilter, customFilter, selectFilter, Comparator, FILTER_TYPES } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import LoadingBar from "react-top-loading-bar";
import Swal from 'sweetalert2';
import { RingLoader, FadeLoader, DotLoader, PropagateLoader } from 'react-spinners';

import AdminHeader from './AdminHeader';

class ManageKolProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectDashbard: false,

            ShowBar: false,

            // to save data from api
            KolNamesData: [],

            // to navigate drawer
            SpecificInfoFlag: true,
            CommanInfoFlag: false,

            // specific info
            kol_name: '',
            total_survey: '',
            completed_survey: '',
            incentivised_survey: '',
            accured_points: '',
            reedeemed_points: '',
            total_surveyErr: '',
            completed_surveyErr: '',
            incentivised_surveyErr: '',
            accured_pointsErr: '',
            reedeemed_pointsErr: '',
            completed_surveyErr2: '',
            incentivised_surveyErr2: '',
            reedeemed_pointsErr2: '',

            // common info 
            upcoming_research: [{
                upcoming_research_name: '',
                research_date: '',
            }],
            ongoing_research: [{
                ongoing_research_name: '',
            }],
            past_research: [{
                past_research_name: '',
            }],
        };
    }

    componentDidMount = async () => {
        //  kol name id list
        const options = {
            method: 'POST',
        }
        fetch(global.websiteUrl+'/service/KOL_Dashboard/get_KOLList', options)
            .then((Response) => Response.json())
            .then((findresponse) => {
                console.log(findresponse)
                //var testKolNames = findresponse.kol_info;
                //console.log("testKolNames====", JSON.stringify(testKolNames));
                //this.setState({
                  //  KolNamesData: findresponse.kol_info,
                //})

                if (findresponse.statusCode === 400) {
                    // Swal.fire({
                      //   icon: 'error',
                        // text: findresponse.message,
                         //confirmButtonColor: '#d33',
                         //cancelButtonColor: '#d33',
                         //confirmButtonText: 'OK'
                    // })
    
                 } else {
                    var testKolNames = findresponse.kol_info;
                    console.log("testKolNames====", JSON.stringify(testKolNames));
                    this.setState({
                    KolNamesData: findresponse.kol_info,
                    })
                 }


            })
    }

    // to set drawer variables
    SpecificInfoFunc = async () => {
        await this.setState({
            CommanInfoFlag: false,
            SpecificInfoFlag: true,
        })
    }
    CommanInfoFunc = async () => {
        await this.setState({
            SpecificInfoFlag: false,
            CommanInfoFlag: true,
        })
    }
    // funcs to redirect
    BackToDashboard() {
        this.setState({
            redirectDashbard: true,
        })
    }

    // funs for Upcoming Researcher
    handleChangeUpcomingResearcher(i, e) {
        console.log(i);
        console.log(e.target);
        const { name, value } = e.target;
        let upcoming_research = [...this.state.upcoming_research];
        upcoming_research[i] = { ...upcoming_research[i], [name]: value };
        this.setState({ upcoming_research });
    }
    addClickUpcomingResearcher() {
        this.setState(prevState => ({
            upcoming_research: [...prevState.upcoming_research, {
                upcoming_research_name: '',
                research_date: '',
            }]
        }))
    }
    removeClickUpcomingResearcher(i) {
        let upcoming_research = [...this.state.upcoming_research];
        upcoming_research.splice(i, 1);
        this.setState({ upcoming_research });
    }

    // funs for Ongoing Researcher
    handleChangeOngoingResearcher(i, e) {
        console.log(i);
        console.log(e.target);
        const { name, value } = e.target;
        let ongoing_research = [...this.state.ongoing_research];
        ongoing_research[i] = { ...ongoing_research[i], [name]: value };
        this.setState({ ongoing_research });
    }
    addClickOngoingResearcher() {
        this.setState(prevState => ({
            ongoing_research: [...prevState.ongoing_research, {
                ongoing_research_name: '',
            }]
        }))
    }
    removeClickOngoingResearcher(i) {
        let ongoing_research = [...this.state.ongoing_research];
        ongoing_research.splice(i, 1);
        this.setState({ ongoing_research });
    }

    // func for Past Researcher
    handleChangePastResearcher(i, e) {
        console.log(i);
        console.log(e.target);
        const { name, value } = e.target;
        let past_research = [...this.state.past_research];
        past_research[i] = { ...past_research[i], [name]: value };
        this.setState({ past_research });
    }
    addClickPastResearcher() {
        this.setState(prevState => ({
            past_research: [...prevState.past_research, {
                past_research_name: '',
            }]
        }))
    }
    removeClickPastResearcher(i) {
        let past_research = [...this.state.past_research];
        past_research.splice(i, 1);
        this.setState({ past_research });
    }

    // to save information
    saveCommonInfo() {
        console.log("upcoming_research==", this.state.upcoming_research);
        console.log("ongoing_research==", this.state.ongoing_research);
        console.log("past_research==", this.state.past_research);

        this.setState({
            ShowBar: true,
        })
        const url = global.websiteUrl+'/service/KOL_Dashboard/create_KOL_Common_Info';
        let formData = new FormData();
        formData.append('kol_id', this.state.kol_name);
        formData.append('upcoming_research', JSON.stringify(this.state.upcoming_research));
        formData.append('ongoing_research', JSON.stringify(this.state.ongoing_research));
        formData.append('past_research', JSON.stringify(this.state.past_research));

        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }

        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var lgdata = response.data;
                console.log("lgdata", JSON.stringify(lgdata));
                var lgstatus = response.data.status;
                console.log("lgstatus", JSON.stringify(lgstatus));
                console.log("response message2", response.data.message)
                this.setState({
                    ShowBar: false,
                })
                if (lgstatus == true) {
                    // alert("Data Saved")
                    Swal.fire({
                        icon: 'success',
                        text: response.data.message,
                        confirmButtonColor: '#d33',
                        confirmButtonText: 'OK'
                    })
                    this.setState({ redirectDashbard: true })
                }
                else if (lgstatus == false) {
                    // alert("Wrong Username Or Password")
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Wrong Username or Password!',
                        // showCancelButton: true,
                        confirmButtonColor: '#d33',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'OK'
                    })
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    validateSpecificInfo = () => {

        let total_surveyErr = '';
        let completed_surveyErr = '';
        let incentivised_surveyErr = '';
        let accured_pointsErr = '';
        let reedeemed_pointsErr = '';
        let completed_surveyErr2 = '';
        let incentivised_surveyErr2 = '';
        let reedeemed_pointsErr2 = '';

        if (!this.state.total_survey) {
            total_surveyErr = 'Please Enter Total Survey';
        }
        if (!this.state.completed_survey) {
            completed_surveyErr = 'Please Enter Completed Survey';
        }
        if (!this.state.incentivised_survey) {
            incentivised_surveyErr = 'Please Enter Incentivised Survey';
        }
        if (!this.state.accured_points) {
            accured_pointsErr = 'Please Enter Accured Points';
        }
        if (!this.state.reedeemed_points) {
            reedeemed_pointsErr = 'Please Enter Reedeemed Points';
        }

        let TS = parseInt(this.state.total_survey);
        let CS = parseInt(this.state.completed_survey);
        let IS = parseInt(this.state.incentivised_survey);
        let AP = parseInt(this.state.accured_points);
        let RP = parseInt(this.state.reedeemed_points);

        console.log(TS, CS, IS, RP, AP);

        if (TS < CS) {
            completed_surveyErr2 = 'Completed Surveys Should Be Less Than Total Surveys';
            console.log('completed survey is less =', CS);
        }

        if (TS < IS) {
            incentivised_surveyErr2 = 'Incentivised Surveys Should Be Less Than Total Surveys';
            console.log('incentivised survey is less', IS);
        }

        if (AP < RP) {
            reedeemed_pointsErr2 = 'Reedemed Points Should Be Less Than Accured Points';
            console.log('reedemed points are less', RP);
        }

        if (total_surveyErr || completed_surveyErr || incentivised_surveyErr || accured_pointsErr || reedeemed_pointsErr || completed_surveyErr2 || incentivised_surveyErr2 || reedeemed_pointsErr2) {
            this.setState({ total_surveyErr, completed_surveyErr, incentivised_surveyErr, accured_pointsErr, reedeemed_pointsErr, completed_surveyErr2, incentivised_surveyErr2, reedeemed_pointsErr2 });
            console.log('false');
            return false;
        }
        console.log("true");
        return true;
    }

    saveSpecificInfo = () => {
        console.log("this.state.total_survey: ====", this.state.total_survey);
        console.log("this.state.completed_survey: ====", this.state.completed_survey);
        console.log("this.state.incentivised_survey: ====", this.state.incentivised_survey);
        console.log("this.state.accured_points: ====", this.state.accured_points);
        console.log("this.state.reedeemed_points: ====", this.state.reedeemed_points);
        console.log("this.state.kol_name: ====", this.state.kol_name);

        const isValid = this.validateSpecificInfo();
        console.log("isValid", this.isValid);
        if (isValid) {
            this.setState({
                ShowBar: true,
            })
            const url = global.websiteUrl+'/service/KOL_Dashboard/create_KOL_Common_Info';
            let formData = new FormData();
            formData.append('kol_id', this.state.kol_name);
            formData.append('research_total_survey', this.state.total_survey);
            formData.append('research_completed_survey', this.state.completed_survey);
            formData.append('research_incentivised_survey', this.state.incentivised_survey);
            formData.append('incentive_accured_points', this.state.accured_points);
            formData.append('incentive_reedeemed_points', this.state.reedeemed_points);

            const config = {
                //   headers: { 'content-type': 'multipart/form-data' }
            }

            axios.post(url, formData, config)
                .then(response => {
                    console.log(response);
                    var lgdata = response.data;
                    console.log("lgdata", JSON.stringify(lgdata));
                    var lgstatus = response.data.status;
                    console.log("lgstatus", JSON.stringify(lgstatus));
                    console.log("response message2", response.data.message)
                    this.setState({
                        ShowBar: false,
                    })
                    if (lgstatus == true) {
                        // alert("Data Saved")
                        Swal.fire({
                            icon: 'success',
                            text: response.data.message,
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'OK'
                        })
                        this.setState({ redirectDashbard: true })
                    }
                    else if (lgstatus == false) {
                        // alert("Wrong Username Or Password")
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Wrong Username or Password!',
                            // showCancelButton: true,
                            confirmButtonColor: '#d33',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'OK'
                        })
                    }
                })
                .catch(error => {
                    console.log(error);
                });
            this.setState({
                total_surveyErr: '',
                completed_surveyErr: '',
                incentivised_surveyErr: '',
                accured_pointsErr: '',
                reedeemed_pointsErr: '',
                completed_surveyErr2: '',
                incentivised_surveyErr2: '',
                reedeemed_pointsErr2: '',
            });
        }
    }

    render() {
        if (this.state.redirectDashbard == true) {
            return (<Redirect to={{
                pathname: '/AdminDashboard',
            }} />)
        }

        let kol_names = this.state.KolNamesData;
        let optionKolNames = kol_names.map((kol_names) =>
            <option key={kol_names.kol_unique_id} value={kol_names.kol_unique_id}>
                {kol_names.name}</option>
        );

        return (
            <body>
                <div style={{marginTop:'15vh'}}>
                    <div>
                        {this.state.ShowBar ? <Modal isOpen={true} className='propagateLoader' centered>
                            <PropagateLoader color={'#A90007'} size='40px' loading='true' />
                        </Modal> : null}
                    </div>
                    <div>
                        <div>
                            <AdminHeader />
                            <div class="container">
                                <div class="content-wrapper">
                                    <div class="screen-headingbox">
                                        <div class="back-section">
                                            <a onClick={this.BackToDashboard.bind(this)}>
                                                <img src={require('../Assets/Images/back-arrow.svg')} />
                                                Back
                                            </a>
                                        </div>
                                        <h2>KOL Profile Management </h2>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 col-xs-12 profile-box">
                                            <div class="sidebar sticky">
                                                <ul class="cetas-leftnav">
                                                    <li class="active">
                                                        <a onClick={this.SpecificInfoFunc}>
                                                            {
                                                                (this.state.SpecificInfoFlag) ? (
                                                                    <div>
                                                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                                            KOL specific info</text>
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <text>KOL specific info</text>
                                                                    </div>
                                                                )
                                                            }
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a onClick={this.CommanInfoFunc}>
                                                            {
                                                                (this.state.CommanInfoFlag) ? (
                                                                    <div>
                                                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                                            Common Info</text>
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <text>Common Info</text>
                                                                    </div>
                                                                )
                                                            }
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-md-5">
                                            <div class="scroll-box">
                                                <a id="overall-details"></a>
                                                {/* Common Info Form */}
                                                {
                                                    (this.state.CommanInfoFlag == true) ? (
                                                        <section>
                                                            <div class="scrollable-box">
                                                                <form>

                                                                    {
                                                                        this.state.upcoming_research.map((el, i) => (
                                                                            <div key={i}>
                                                                                {
                                                                                    (i == 0) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {this.state.upcoming_research.length !== 1 &&
                                                                                                <a class="float-right" style={{ marginTop: '-4%', color: '#007bff' }}
                                                                                                    onClick={this.removeClickUpcomingResearcher.bind(this, i)}>x</a>
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <div class="form-group">
                                                                                    <label for="exampleFormControlTextarea1">
                                                                                        Upcoming Research Studies
                                                                                    </label>
                                                                                    <input type="text" class="form-control"
                                                                                        autofocus="true"
                                                                                        id="upcoming_research_name" placeholder="Enter Upcoming Research Studies"
                                                                                        name="upcoming_research_name" value={el.upcoming_research_name || ''}
                                                                                        onChange={this.handleChangeUpcomingResearcher.bind(this, i)}
                                                                                    />


                                                                                    <div class="form-group">
                                                                                        <label for="exampleFormControlTextarea1">
                                                                                            Select Date
                                                                                        </label>
                                                                                        <input type="date" class="form-control" id="exampleInputPassword1"
                                                                                            placeholder="Enter research study date"
                                                                                            name="research_date" value={el.research_date || ''}
                                                                                            onChange={this.handleChangeUpcomingResearcher.bind(this, i)}
                                                                                        />
                                                                                    </div>
                                                                                    <a style={{ marginTop: '5%', color: '#007bff' }}
                                                                                        onClick={this.addClickUpcomingResearcher.bind(this)}>
                                                                                        + Add More
                                                                                    </a>
                                                                                </div>

                                                                            </div>
                                                                        ))
                                                                    }
                                                                    <hr />
                                                                    <br /><br /><br />

                                                                    {
                                                                        this.state.ongoing_research.map((el, i) => (
                                                                            <div key={i}>
                                                                                {
                                                                                    (i == 0) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {this.state.ongoing_research.length !== 1 &&
                                                                                                <a class="float-right" style={{ marginTop: '-4%', color: '#007bff' }}
                                                                                                    onClick={this.removeClickOngoingResearcher.bind(this, i)}>x</a>
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <div class="form-group">
                                                                                    <label for="exampleFormControlTextarea1">
                                                                                        Ongoing Research Studies
                                                                                    </label>
                                                                                    <input type="text" class="form-control"
                                                                                        autofocus="true"
                                                                                        id="ongoing_research_name" placeholder="Enter Ongoing Research Study"
                                                                                        name="ongoing_research_name" value={el.ongoing_research_name || ''}
                                                                                        onChange={this.handleChangeOngoingResearcher.bind(this, i)}
                                                                                    />

                                                                                    <a style={{ marginTop: '5%', color: '#007bff' }}
                                                                                        onClick={this.addClickOngoingResearcher.bind(this)}>
                                                                                        + Add More
                                                                                    </a>
                                                                                </div>

                                                                            </div>
                                                                        ))
                                                                    }
                                                                    <hr />
                                                                    <br /><br /><br />

                                                                    {
                                                                        this.state.past_research.map((el, i) => (
                                                                            <div key={i}>
                                                                                {
                                                                                    (i == 0) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {this.state.past_research.length !== 1 &&
                                                                                                <a class="float-right" style={{ marginTop: '-4%', color: '#007bff' }}
                                                                                                    onClick={this.removeClickPastResearcher.bind(this, i)}>x</a>
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <div class="form-group">
                                                                                    <label for="exampleFormControlTextarea1">
                                                                                        Past Research Studies
                                                                                    </label>
                                                                                    <input type="text" class="form-control"
                                                                                        autofocus="true"
                                                                                        id="past_research_name" placeholder="Enter Past Research Study"
                                                                                        name="past_research_name" value={el.past_research_name || ''}
                                                                                        onChange={this.handleChangePastResearcher.bind(this, i)}
                                                                                    />

                                                                                    <a style={{ marginTop: '5%', color: '#007bff' }}
                                                                                        onClick={this.addClickPastResearcher.bind(this)}>
                                                                                        + Add More
                                                                                    </a>
                                                                                </div>

                                                                            </div>
                                                                        ))
                                                                    }
                                                                </form>
                                                                {/* <div class="action-btn-container">
                                        <div class="container">
                                            <div class="col-md-7 offset-md-3"> */}
                                                                <button class="cetas-secondry-button">Cancel</button>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                <button class="cetas-button" data-toggle="modal" data-target="#edit-item"
                                                                    onClick={this.saveCommonInfo.bind(this)}>
                                                                    Save</button>
                                                                {/* </div>
                                        </div>
                                    </div> */}
                                                                <div style={{ marginTop: '20%' }}></div>
                                                            </div>
                                                        </section>
                                                    ) : (
                                                        <div></div>
                                                    )
                                                }
                                                {/* Specific Info form */}
                                                {
                                                    (this.state.SpecificInfoFlag == true) ? (
                                                        <section>
                                                            <div class="scrollable-box">
                                                                <form>
                                                                    <div class="form-group">
                                                                        <label for="exampleFormControlTextarea1">KOL Name</label>
                                                                        <Input type="select" className="form-control"
                                                                            style={{ height: '50px' }}
                                                                            name="select" id="categorySelect"
                                                                            value={this.state.kol_name}
                                                                            onChange={(e) => {
                                                                                this.setState({ kol_name: e.target.value });
                                                                            }}
                                                                        >
                                                                            <option value="0" >--Select KOL Name--</option>
                                                                            {optionKolNames}
                                                                        </Input>
                                                                    </div>

                                                                    {/* <h4>Engagement summary dashboard</h4>
                                        <div class="form-group">
                                            <label for="exampleFormControlTextarea1">Recent engagements <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Enter recent engagement"/>
                                        </div>

                                        <div class="form-group">
                                            <label for="exampleFormControlTextarea1">select Date</label>
                                            <input type="date" class="form-control" id="exampleInputPassword1" placeholder="Enter engagement date"/>
                                        </div>
                                        
                                        {/* <a href="#" class="float-right">+ Add more engagements</a> */}
                                                                    {/* <a style={{marginTop:'5%', color:'#007bff'}}
                                           >+ Add More Engagements</a>
                                        <hr/>
                                        <br/><br/><br/> */}

                                                                    <br />
                                                                    <h4>Research Summary Dashboard</h4>
                                                                    <div class="form-group">
                                                                        <label for="exampleFormControlTextarea1">Total Survey
                                                                            <span class="mandatory">*</span>
                                                                        </label>
                                                                        <input type="text" class="form-control"
                                                                            id="input7" placeholder="Enter Total Survey"
                                                                            name="total_survey"
                                                                            value={this.state.total_survey}
                                                                            onChange={(e) => {
                                                                                this.setState({ total_survey: e.target.value });
                                                                            }}
                                                                        />
                                                                        <div className="loginErrCreateProject">{this.state.total_surveyErr}</div>
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Completed Survey <span class="mandatory">*</span>
                                                                        </label>
                                                                        <input type="text" class="form-control"
                                                                            id="input7" placeholder="Enter Completed Survey"
                                                                            name="completed_survey"
                                                                            value={this.state.completed_survey}
                                                                            onChange={(e) => {
                                                                                this.setState({ completed_survey: e.target.value });
                                                                            }}
                                                                        />
                                                                        <div className="loginErrCreateProject">{this.state.completed_surveyErr}</div>
                                                                        <div className="loginErrCreateProject">{this.state.completed_surveyErr2}</div>
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Incentivised Survey<span class="mandatory">*</span>
                                                                        </label>
                                                                        <input type="text" class="form-control"
                                                                            id="input7" placeholder="Enter Incentivised Survey"
                                                                            name="incentivised_survey"
                                                                            value={this.state.incentivised_survey}
                                                                            onChange={(e) => {
                                                                                this.setState({ incentivised_survey: e.target.value });
                                                                            }}
                                                                        />
                                                                        <div className="loginErrCreateProject">{this.state.incentivised_surveyErr}</div>
                                                                        <div className="loginErrCreateProject">{this.state.incentivised_surveyErr2}</div>
                                                                    </div>
                                                                    <hr />
                                                                    <br /><br />

                                                                    <h4>Incentive Summary Dashboard</h4>
                                                                    <div class="form-group">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Accured Points<span class="mandatory">*</span>
                                                                        </label>
                                                                        <input type="text" class="form-control"
                                                                            id="input7" placeholder="Enter Accured Points"
                                                                            name="accured_points"
                                                                            value={this.state.accured_points}
                                                                            onChange={(e) => {
                                                                                this.setState({ accured_points: e.target.value });
                                                                            }}
                                                                        />
                                                                        <div className="loginErrCreateProject">{this.state.accured_pointsErr}</div>
                                                                    </div>

                                                                    <div class="form-group">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Reedeemed Points <span class="mandatory">*</span>
                                                                        </label>
                                                                        <input type="text" class="form-control"
                                                                            id="input7" placeholder="Enter Reedeemed Points"
                                                                            name="reedeemed_points"
                                                                            value={this.state.reedeemed_points}
                                                                            onChange={(e) => {
                                                                                this.setState({ reedeemed_points: e.target.value });
                                                                            }}
                                                                        />
                                                                        <div className="loginErrCreateProject">{this.state.reedeemed_pointsErr}</div>
                                                                        <div className="loginErrCreateProject">{this.state.reedeemed_pointsErr2}</div>
                                                                    </div>
                                                                    <hr />
                                                                </form>

                                                                <button class="cetas-secondry-button">Cancel</button>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                <button class="cetas-button" data-toggle="modal" data-target="#edit-item"
                                                                    onClick={this.saveSpecificInfo.bind(this)}>
                                                                    Save
                                                                </button>
                                                                <div style={{ marginTop: '20%' }}></div>
                                                            </div>
                                                        </section>
                                                    ) : (
                                                        <div></div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="action-btn-container">
        <div class="container">
            <div class="col-md-7 offset-md-3">
                <button class="cetas-secondry-button">Cancel</button>
                <button class="cetas-button" data-toggle="modal" data-target="#edit-item" 
                 onClick={this.saveInfo.bind(this)}>
                Save</button>
            </div>
        </div>
    </div> */}
                            <footer class="footer-fixed">
                                <div class="cetas-footer">
                                    <div class="container">
                                        <p>All rights reserved. www.cetashealthcare.com</p>
                                    </div>
                                </div>
                            </footer>
                        </div>
                    </div>
                </div>
            </body>
        );
    }
}

export default ManageKolProfile;