import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Input, Container, Row, Col, Modal } from 'reactstrap';
import axios from 'axios';
import { Multiselect } from 'react-widgets';
import Swal from 'sweetalert2';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import LoadingBar from "react-top-loading-bar";
import { RingLoader, FadeLoader, DotLoader, PropagateLoader } from 'react-spinners';
import MultipleValueTextInput from 'react-multivalue-text-input';
import YearPicker from "react-year-picker";

import ResercherHeader from './ResercherHeader';

class KolProfileForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token:'',

            // for image
            file: '',
            imagePreviewUrl: '',

            // Progress
            ShowProcedure: false,
            ShowBar: false,

            contract_file: '',
            ImageErr:'',
            ResumeErr:'',


            // Redirect
            RedirectResercherKolList: false,

            //Flag to show form of selected drawer item
            PersonalInformationFlag: true,
            BiographyFlag: false,
            ProfessionalMembershipFlag: false,
            EducationFlag: false,
            AdvisoryBoardFlag: false,
            AwardsAndHonoursFlag: false,
            BooksAndMonographFlag: false,
            CommiteesFlag: false,
            ClinicalTrialDetailsFlag: false,
            EventsDetailsFlag: false,
            GrantsFlag: false,
            GuidelinesFlag: false,
            HospitalAffiliationsFlag: false,
            InteractionsFlag: false,
            PressItemFlag: false,
            PresentationsFlag: false,
            PublicationDetailsFlag: false,
            SunshinePaymentFlag: false,
            ProcedurePotentialFlag: false,

            Language: [
                { id: 'Mandarin Chinese', language: 'Mandarin Chinese' },
                { id: 'Spanish', language: 'Spanish' },
                { id: 'English', language: 'English' },
                { id: 'Hindi (Sanskritised Hindustani)[9]', language: 'Hindi (Sanskritised Hindustani)[9]' },
                { id: 'Bengali', language: 'Bengali' },
                { id: 'Portuguese', language: 'Portuguese' },
                { id: 'Russian', language: 'Russian' },
                { id: 'Japanese', language: 'Japanese' },
                { id: 'Western Punjabi[10]', language: 'Western Punjabi[10]' },
                { id: 'Marathi', language: 'Marathi' },
                { id: 'Telugu', language: 'Telugu' },
                { id: 'Wu Chinese', language: 'Wu Chinese' },
                { id: 'Turkish', language: 'Turkish' },
                { id: 'Korean', language: 'Korean' },
                { id: 'French', language: 'French' },
                { id: 'German', language: 'German' },
                { id: 'Vietnamese', language: 'Vietnamese' },
                { id: 'Tamil', language: 'Tamil' },
                { id: 'Yue Chinese', language: 'Yue Chinese' },
                { id: 'Urdu (Persianised Hindustani)[9]', language: 'Urdu (Persianised Hindustani)[9]' },
                { id: 'Javanese', language: 'Javanese' },
                { id: 'Italian', language: 'Italian' },
                { id: 'Egyptian Arabic', language: 'Egyptian Arabic' },
                { id: 'Gujarati', language: 'Gujarati' },
                { id: 'Iranian Persian', language: 'Iranian Persian' },
                { id: 'Bhojpuri', language: 'Bhojpuri' },
                { id: 'Min Nan Chinese', language: 'Min Nan Chinese' },
                { id: 'Hakka Chinese', language: 'Hakka Chinese' },
                { id: 'Jin Chinese', language: 'Jin Chinese' },
                { id: 'Hausa', language: 'Hausa' },
                { id: 'Kannada', language: 'Kannada' },
                { id: 'Indonesian (Indonesian Malay)', language: 'Indonesian (Indonesian Malay)' },
                { id: 'Polish', language: 'Polish' },
                { id: 'Yoruba', language: 'Yoruba' },
                { id: 'Xiang Chinese', language: 'Xiang Chinese' },
                { id: 'Malayalam', language: 'Malayalam' },
                { id: 'Odia', language: 'Odia' },
                { id: 'Maithili', language: 'Maithili' },
                { id: 'Burmese', language: 'Burmese' },
                { id: 'Eastern Punjabi[10]', language: 'Eastern Punjabi[10]' },
                { id: 'Sunda', language: 'Sunda' },
                { id: 'Sudanese Arabic', language: 'Sudanese Arabic' },
                { id: 'Algerian Arabic', language: 'Algerian Arabic' },
                { id: 'Moroccan Arabic', language: 'Moroccan Arabic' },
                { id: 'Ukrainian', language: 'Ukrainian' },
                { id: 'Igbo', language: 'Igbo' },
                { id: 'Northern Uzbek', language: 'Northern Uzbek' },
                { id: 'Sindhi', language: 'Sindhi' },
                { id: 'North Levantine Arabic', language: 'North Levantine Arabic' },
                { id: 'Romanian', language: 'Romanian' },
                { id: 'Tagalog', language: 'Tagalog' },
                { id: 'Dutch', language: 'Dutch' },
                { id: 'Saʽidi Arabic', language: 'Saʽidi Arabic' },
                { id: 'Gan Chinese', language: 'Gan Chinese' },
                { id: 'Amharic', language: 'Amharic' },
                { id: 'Northern Pashto', language: 'Northern Pashto' },
                { id: 'Magahi', language: 'Magahi' },
                { id: 'Thai', language: 'Thai' },
                { id: 'Saraiki', language: 'Saraiki' },
                { id: 'Khmer', language: 'Khmer' },
                { id: 'Chhattisgarhi', language: 'Chhattisgarhi' },
                { id: 'Somali', language: 'Somali' },
                { id: 'Malay (Malaysian Malay)', language: 'Malay (Malaysian Malay)' },
                { id: 'Cebuano', language: 'Cebuano' },
                { id: 'Nepali', language: 'Nepali' },
                { id: 'Mesopotamian Arabic', language: 'Mesopotamian Arabic' },
                { id: 'Assamese', language: 'Assamese' },
                { id: 'Sinhalese', language: 'Sinhalese' },
                { id: 'Northern Kurdish', language: 'Northern Kurdish' },
                { id: 'Hejazi Arabic', language: 'Hejazi Arabic' },
                { id: 'Nigerian Fulfulde', language: 'Nigerian Fulfulde' },
                { id: 'Bavarian', language: 'Bavarian' },
                { id: 'South Azerbaijani', language: 'South Azerbaijani' },
                { id: 'Greek', language: 'Greek' },
                { id: 'Chittagonian', language: 'Chittagonian' },
                { id: 'Kazakh', language: 'Kazakh' },
                { id: 'Deccan', language: 'Deccan' },
                { id: 'Hungarian', language: 'Hungarian' },
                { id: 'Kinyarwanda', language: 'Kinyarwanda' },
                { id: 'Zulu', language: 'Zulu' },
                { id: 'South Levantine Arabic', language: 'South Levantine Arabic' },
                { id: 'Tunisian Arabic', language: 'Tunisian Arabic' },
                { id: 'Sanaani Spoken Arabic', language: 'Sanaani Spoken Arabic' },
                { id: 'Min Bei Chinese', language: 'Min Bei Chinese' },
                { id: 'Southern Pashto', language: 'Southern Pashto' },
                { id: 'Czech', language: 'Czech' },
                { id: 'Taʽizzi-Adeni Arabic', language: 'Taʽizzi-Adeni Arabic' },
                { id: 'Min Dong Chinese', language: 'Min Dong Chinese' },
                { id: 'Sylheti', language: 'Sylheti' },
            ],

            CitizenshipdDrop: [
                { id: 'Afghans', citizenship: 'Afghans' },
                { id: 'Albanians', citizenship: 'Albanians' },
                { id: 'Algerians', citizenship: 'Algerians' },
                { id: 'Americans', citizenship: 'Americans' },
                { id: 'Andorrans', citizenship: 'Andorrans' },
                { id: 'Angolans', citizenship: 'Angolans' },
                { id: 'Antiguans and Barbudans', citizenship: 'Antiguans and Barbudans' },
                { id: 'Argentines', citizenship: 'Argentines' },
                { id: 'Armenians', citizenship: 'Armenians' },
                { id: 'Arubans', citizenship: 'Arubans' },
                { id: 'Australians', citizenship: 'Australians' },
                { id: 'Austrians', citizenship: 'Austrians' },
                { id: 'Azerbaijanis', citizenship: 'Azerbaijanis' },
                { id: 'Bahamians', citizenship: 'Bahamians' },
                { id: 'Bahrainis', citizenship: 'Bahrainis' },
                { id: 'Bangladeshis', citizenship: 'Bangladeshis' },
                { id: 'Barbadians', citizenship: 'Barbadians' },
                { id: 'Basques', citizenship: 'Basques' },
                { id: 'Belarusians', citizenship: 'Belarusians' },
                { id: 'Belgians', citizenship: 'Belgians' },
                { id: 'Belizeans', citizenship: 'Belizeans' },
                { id: 'Beninese', citizenship: 'Beninese' },
                { id: 'Bermudians', citizenship: 'Bermudians' },
                { id: 'Bhutanese', citizenship: 'Bhutanese' },
                { id: 'Bolivians', citizenship: 'Bolivians' },
                { id: 'Bosniaks', citizenship: 'Bosniaks' },
                { id: 'Bosnians and Herzegovinians', citizenship: 'Bosnians and Herzegovinians' },
                { id: 'Botswana', citizenship: 'Botswana' },
                { id: 'Brazilians', citizenship: 'Brazilians' },
                { id: 'Bretons', citizenship: 'Bretons' },
                { id: 'British', citizenship: 'British' },
                { id: 'British Virgin Islanders', citizenship: 'British Virgin Islanders' },
                { id: 'Bruneians', citizenship: 'Bruneians' },
                { id: 'Bulgarians', citizenship: 'Bulgarians' },
                { id: 'Macedonian Bulgarians', citizenship: 'Macedonian Bulgarians' },
                { id: 'Burkinabés', citizenship: 'Burkinabés' },
                { id: 'Burmese', citizenship: 'Burmese' },
                { id: 'Burundians', citizenship: 'Burundians' },
                { id: 'Cambodians', citizenship: 'Cambodians' },
                { id: 'Cameroonians', citizenship: 'Cameroonians' },
                { id: 'Canadians', citizenship: 'Canadians' },
                // { id: 'Catalans', citizenship: 'Catalans' },
                { id: 'Cape Verdeans', citizenship: 'Cape Verdeans' },
                { id: 'Chadians', citizenship: 'Chadians' },
                { id: 'Chileans', citizenship: 'Chileans' },
                { id: 'Chinese', citizenship: 'Chinese' },
                { id: 'Colombians', citizenship: 'Colombians' },
                { id: 'Comorians', citizenship: 'Comorians' },
                { id: 'Congolese (DRC)', citizenship: 'Congolese (DRC)' },
                { id: 'Congolese (RotC)', citizenship: 'Congolese (RotC)' },
                { id: 'Costa Ricans', citizenship: 'Costa Ricans' },
                { id: 'Croats', citizenship: 'Croats' },
                { id: 'Cubans', citizenship: 'Cubans' },
                { id: 'Cypriots', citizenship: 'Cypriots' },
                { id: 'Czechs', citizenship: 'Czechs' },
                { id: 'Danes', citizenship: 'Danes' },
                { id: 'Greenlanders', citizenship: 'Greenlanders' },
                { id: 'Djiboutians', citizenship: 'Djiboutians' },
                { id: 'Dominicans (Commonwealth)', citizenship: 'Dominicans (Commonwealth)' },
                { id: 'Dominicans (Republic)', citizenship: 'Dominicans (Republic)' },
                { id: 'Dutch', citizenship: 'Dutch' },
                { id: 'East Timorese', citizenship: 'East Timorese' },
                { id: 'Ecuadorians', citizenship: 'Ecuadorians' },
                { id: 'Egyptians', citizenship: 'Egyptians' },
                { id: 'Emiratis', citizenship: 'Emiratis' },
                { id: 'English', citizenship: 'English' },
                { id: 'Equatoguineans', citizenship: 'Equatoguineans' },
                { id: 'Eritreans', citizenship: 'Eritreans' },
                { id: 'Estonians', citizenship: 'Estonians' },
                { id: 'Ethiopians', citizenship: 'Ethiopians' },
                { id: 'Falkland Islanders', citizenship: 'Falkland Islanders' },
                { id: 'Faroese', citizenship: 'Faroese' },
                { id: 'Fijians', citizenship: 'Fijians' },
                { id: 'Finns', citizenship: 'Finns' },
                { id: 'Finnish Swedish', citizenship: 'Finnish Swedish' },
                { id: 'Filipinos', citizenship: 'Filipinos' },
                { id: 'French citizens', citizenship: 'French citizens' },
                { id: 'Gabonese', citizenship: 'Gabonese' },
                { id: 'Gambians', citizenship: 'Gambians' },
                { id: 'Georgians', citizenship: 'Georgians' },
                { id: 'Germans', citizenship: 'Germans' },
                { id: 'Baltic Germans', citizenship: 'Baltic Germans' },
                { id: 'Ghanaians', citizenship: 'Ghanaians' },
                { id: 'Gibraltarians', citizenship: 'Gibraltarians' },
                { id: 'Greeks', citizenship: 'Greeks' },
                { id: 'Greek Macedonians', citizenship: 'Greek Macedonians' },
                { id: 'Grenadians', citizenship: 'Grenadians' },
                { id: 'Guatemalans', citizenship: 'Guatemalans' },
                { id: 'Guianese (French)', citizenship: 'Guianese (French)' },
                { id: 'Guineans', citizenship: 'Guineans' },
                { id: 'Guinea-Bissau nationals', citizenship: 'Guinea-Bissau nationals' },
                { id: 'Guyanese', citizenship: 'Guyanese' },
                { id: 'Haitians', citizenship: 'Haitians' },
                { id: 'Hondurans', citizenship: 'Hondurans' },
                { id: 'Hong Kongers', citizenship: 'Hong Kongers' },
                { id: 'Hungarians', citizenship: 'Hungarians' },
                { id: 'Icelanders', citizenship: 'Icelanders' },
                { id: 'I-Kiribati', citizenship: 'I-Kiribati' },
                { id: 'Indians', citizenship: 'Indians' },
                { id: 'Indonesians', citizenship: 'Indonesians' },
                { id: 'Iranians', citizenship: 'Iranians' },
                { id: 'Iraqis', citizenship: 'Iraqis' },
                { id: 'Irish', citizenship: 'Irish' },
                { id: 'Israelis', citizenship: 'Israelis' },
                { id: 'Italians', citizenship: 'Italians' },
                { id: 'Ivoirians', citizenship: 'Ivoirians' },
                { id: 'Jamaicans', citizenship: 'Jamaicans' },
                { id: 'Japanese', citizenship: 'Japanese' },
                { id: 'Jordanians', citizenship: 'Jordanians' },
                { id: 'Kazakhs', citizenship: 'Kazakhs' },
                { id: 'Kenyans', citizenship: 'Kenyans' },
                { id: 'Koreans', citizenship: 'Koreans' },
                { id: 'Kosovars', citizenship: 'Kosovars' },
                { id: 'Kuwaitis', citizenship: 'Kuwaitis' },
                { id: 'Kyrgyzs', citizenship: 'Kyrgyzs' },
                { id: 'Lao', citizenship: 'Lao' },
                { id: 'Latvians', citizenship: 'Latvians' },
                { id: 'Lebanese', citizenship: 'Lebanese' },
                { id: 'Liberians', citizenship: 'Liberians' },
                { id: 'Libyans', citizenship: 'Libyans' },
                { id: 'Liechtensteiners', citizenship: 'Liechtensteiners' },
                { id: 'Lithuanians', citizenship: 'Lithuanians' },
                { id: 'Luxembourgers', citizenship: 'Luxembourgers' },
                { id: 'Macao', citizenship: 'Macao' },
                // { id: 'Macedonians', citizenship: 'Macedonians' },
                { id: 'Malagasy', citizenship: 'Malagasy' },
                { id: 'Malawians', citizenship: 'Malawians' },
                { id: 'Malaysians', citizenship: 'Malaysians' },
                { id: 'Maldivians', citizenship: 'Maldivians' },
                { id: 'Malians', citizenship: 'Malians' },
                { id: 'Maltese', citizenship: 'Maltese' },
                { id: 'Manx', citizenship: 'Manx' },
                { id: 'Marshallese', citizenship: 'Marshallese' },
                { id: 'Mauritanians', citizenship: 'Mauritanians' },
                { id: 'Mauritians', citizenship: 'Mauritians' },
                { id: 'Mexicans', citizenship: 'Mexicans' },
                { id: 'Micronesians', citizenship: 'Micronesians' },
                { id: 'Moldovans', citizenship: 'Moldovans' },
                { id: 'Monégasque', citizenship: 'Monégasque' },
                { id: 'Mongolians', citizenship: 'Mongolians' },
                { id: 'Montenegrins', citizenship: 'Montenegrins' },
                { id: 'Moroccans', citizenship: 'Moroccans' },
                { id: 'Mozambicans', citizenship: 'Mozambicans' },
                { id: 'Namibians', citizenship: 'Namibians' },
                { id: 'Nauruans', citizenship: 'Nauruans' },
                { id: 'Nepalese', citizenship: 'Nepalese' },
                { id: 'New Zealanders', citizenship: 'New Zealanders' },
                { id: 'Nicaraguans', citizenship: 'Nicaraguans' },
                { id: 'Nigeriens', citizenship: 'Nigeriens' },
                // { id: 'Nigerians', citizenship: 'Nigerians' },
                { id: 'Norwegians', citizenship: 'Norwegians' },
                { id: 'Omani', citizenship: 'Omani' },
                { id: 'Pakistanis', citizenship: 'Pakistanis' },
                { id: 'Palauans', citizenship: 'Palauans' },
                { id: 'Palestinians', citizenship: 'Palestinians' },
                { id: 'Panamanians', citizenship: 'Panamanians' },
                { id: 'Papua New Guineans', citizenship: 'Papua New Guineans' },
                { id: 'Paraguayans', citizenship: 'Paraguayans' },
                { id: 'Peruvians', citizenship: 'Peruvians' },
                { id: 'Poles', citizenship: 'Poles' },
                { id: 'Portuguese', citizenship: 'Portuguese' },
                { id: 'Puerto Ricans', citizenship: 'Puerto Ricans' },
                { id: 'Qatari', citizenship: 'Qatari' },
                { id: 'Quebecers', citizenship: 'Quebecers' },
                { id: 'Réunionnais', citizenship: 'Réunionnais' },
                { id: 'Romanians', citizenship: 'Romanians' },
                { id: 'Russians', citizenship: 'Russians' },
                { id: 'Baltic Russians', citizenship: 'Baltic Russians' },
                { id: 'Rwandans', citizenship: 'Rwandans' },
                { id: 'Saint Kitts and Nevis', citizenship: 'Saint Kitts and Nevis' },
                { id: 'Saint Lucians', citizenship: 'Saint Lucians' },
                { id: 'Salvadorans', citizenship: 'Salvadorans' },
                { id: 'Sammarinese', citizenship: 'Sammarinese' },
                { id: 'Samoans', citizenship: 'Samoans' },
                { id: 'São Tomé and Príncipe', citizenship: 'São Tomé and Príncipe' },
                { id: 'Saudis', citizenship: 'Saudis' },
                { id: 'Scots', citizenship: 'Scots' },
                { id: 'Senegalese', citizenship: 'Senegalese' },
                { id: 'Serbs', citizenship: 'Serbs' },
                { id: 'Seychellois', citizenship: 'Seychellois' },
                { id: 'Sierra Leoneans', citizenship: 'Sierra Leoneans' },
                { id: 'Singaporeans', citizenship: 'Singaporeans' },
                { id: 'Slovaks', citizenship: 'Slovaks' },
                { id: 'Slovenes', citizenship: 'Slovenes' },
                { id: 'Solomon Islanders', citizenship: 'Solomon Islanders' },
                { id: 'Somalis', citizenship: 'Somalis' },
                { id: 'Somalilanders', citizenship: 'Somalilanders' },
                { id: 'Sotho', citizenship: 'Sotho' },
                { id: 'South Africans', citizenship: 'South Africans' },
                { id: 'Spaniards', citizenship: 'Spaniards' },
                { id: 'Sri Lankans', citizenship: 'Sri Lankans' },
                { id: 'Sudanese', citizenship: 'Sudanese' },
                { id: 'Surinamese', citizenship: 'Surinamese' },
                { id: 'Swazi', citizenship: 'Swazi' },
                { id: 'Swedes', citizenship: 'Swedes' },
                { id: 'Swiss', citizenship: 'Swiss' },
                { id: 'Syriacs', citizenship: 'Syriacs' },
                { id: 'Syrians', citizenship: 'Syrians' },
                { id: 'Taiwanese', citizenship: 'Taiwanese' },
                { id: 'Tamils', citizenship: 'Tamils' },
                { id: 'Tajik', citizenship: 'Tajik' },
                { id: 'Tanzanians', citizenship: 'Tanzanians' },
                { id: 'Thais', citizenship: 'Thais' },
                { id: 'Tibetans', citizenship: 'Tibetans' },
                { id: 'Tobagonians', citizenship: 'Tobagonians' },
                { id: 'Togolese', citizenship: 'Togolese' },
                { id: 'Tongans', citizenship: 'Tongans' },
                { id: 'Trinidadians', citizenship: 'Trinidadians' },
                { id: 'Tunisians', citizenship: 'Tunisians' },
                { id: 'Tuvaluans', citizenship: 'Tuvaluans' },
                { id: 'Ugandans', citizenship: 'Ugandans' },
                { id: 'Ukrainians', citizenship: 'Ukrainians' },
                { id: 'Uruguayans', citizenship: 'Uruguayans' },
                { id: 'Uzbeks', citizenship: 'Uzbeks' },
                { id: 'Vanuatuans', citizenship: 'Vanuatuans' },
                { id: 'Venezuelans', citizenship: 'Venezuelans' },
                { id: 'Vietnamese', citizenship: 'Vietnamese' },
                { id: 'Vincentians', citizenship: 'Vincentians' },
                { id: 'Yemenis', citizenship: 'Yemenis' },
                { id: 'Zambians', citizenship: 'Zambians' },
                { id: 'Zimbabweans', citizenship: 'Zimbabweans' },

                { id: 'Angamis', citizenship: 'Angamis' },
                { id: 'Aromanians', citizenship: 'Aromanians' },
                { id: 'Assyrians', citizenship: 'Assyrians' },
                // { id: 'Basques', citizenship: 'Basques' },
                { id: 'Bengalis', citizenship: 'Bengalis' },
                { id: 'Berbers', citizenship: 'Berbers' },
                { id: 'Boers', citizenship: 'Boers' },
                { id: 'Buryats', citizenship: 'Buryats' },
                { id: 'Cajuns', citizenship: 'Cajuns' },
                { id: 'Catalans', citizenship: 'Catalans' },
                { id: 'Celts', citizenship: 'Celts' },
                { id: 'Chuvash', citizenship: 'Chuvash' },
                { id: 'Han Chinese', citizenship: 'Han Chinese' },
                { id: 'Hispanic and Latino Americans', citizenship: 'Hispanic and Latino Americans' },
                { id: 'Hutus', citizenship: 'Hutus' },
                { id: 'Igbo', citizenship: 'Igbo' },
                { id: 'Indigenous Australians', citizenship: 'Indigenous Australians' },
                { id: 'Indigenous people of the Americas', citizenship: 'Indigenous people of the Americas' },
                { id: 'First Nations', citizenship: 'First Nations' },
                { id: 'Huaorani people', citizenship: 'Huaorani people' },
                { id: 'Inuit', citizenship: 'Inuit' },
                { id: 'Canadian Inuit', citizenship: 'Canadian Inuit' },
                { id: 'Greenlandic Inuit', citizenship: 'Greenlandic Inuit' },
                { id: 'Métis people', citizenship: 'Métis people' },
                { id: 'Native Americans', citizenship: 'Native Americans' },
                { id: 'Malayali', citizenship: 'Malayali' },
                { id: 'Jews', citizenship: 'Jews' },
                { id: 'Kannadigas', citizenship: 'Kannadigas' },
                { id: 'Karen', citizenship: 'Karen' },
                { id: 'Kodavas', citizenship: 'Kodavas' },
                { id: 'Kurds', citizenship: 'Kurds' },
                // { id: 'Macedonians', citizenship: 'Macedonians' },
                { id: 'Malays', citizenship: 'Malays' },
                { id: 'Nagas', citizenship: 'Nagas' },
                { id: 'Roma', citizenship: 'Roma' },
                { id: 'Samis', citizenship: 'Samis' },
                { id: 'Silesians', citizenship: 'Silesians' },
                { id: 'Sindhis', citizenship: 'Sindhis' },
                { id: 'Sinhalese', citizenship: 'Sinhalese' },
                // { id: 'Syriacs', citizenship: 'Syriacs' },
                // { id: 'Tamils', citizenship: 'Tamils' },
                { id: 'Tatars', citizenship: 'Tatars' },
                { id: 'Tutsis', citizenship: 'Tutsis' },
                { id: 'Zulus', citizenship: 'Zulus' },
            ],

            // Store values from API
            SpecialityData: [],
            ProcedureData: [],
            SubSpecialitydata: [],
            ProcedureDataForAdvBoard: [],
            SubSpecialitydataForAdvBoard: [],
            ProcedureDataForClinicalTrial: [],
            SubSpecialitydataClinicalTrial: [],
            ProcedureDataForEventDetails: [],
            SubSpecialitydataEventDetails: [],
            ProcedureDataForGuidelines: [],
            SubSpecialitydataGuidelines: [],
            ProcedureDataForPublicationDetails: [],
            SubSpecialitydataPublicationDetails: [],


            CountryListData: [],
            StateData: [],
            CityData: [],
            EmployeeListData: [],

            // emp_unique_id: this.props.location.state.emp_unique_id,
            // SrNO: this.props.location.state.SrNO,
            // project_id: this.props.location.state.project_id,
            // RID: this.props.location.state.RID,
            // resercher_name: this.props.location.state.resercher_name,
            // Project_Name: this.props.location.state.Project_Name,
            emp_unique_id: '',
            SrNO: '',
            project_id: '',
            RID: '',
            resercher_name: '',
            Project_Name: '',

            // to recall the procedure and subspeciality multiple dropdowns
            showProcedureSubSpecialityDropdown: false,
            showProcedureSubSpecialityDropdownAdvBoard: false,
            showProcedureSubSpecialityDropdownClinicalTrial: false,
            showProcedureSubSpecialityDropdownEventDetails: false,
            showProcedureSubSpecialityDropdownGuidelines: false,
            showProcedureSubSpecialityDropdownPublicationDetails: false,

            // set procedre and sub speciality 
            ProcedureType: [],
            SubSpecialityType: [],
            SpecilityTypeEducation: [],
            ProcedureTypeAdvBoard: [],
            SubSpecialityTypeAdvBoard: [],
            ProcedureTypeClinicalTrial: [],
            SubSpecialityTypeClinicalTrial: [],
            ProcedureTypeEventDetails: [],
            SubSpecialityTypeEventDetails: [],
            ProcedureTypeGuidelines: [],
            SubSpecialityTypeGuidelines: [],
            ProcedureTypePublicationDetails: [],
            SubSpecialityTypePublicationDetails: [],

            // to get values filled in form
            KOLType: '',
            // personal information
            PersonalInformation: {
                // id: this.props.location.state.SrNO,
                kol_unique_id: sessionStorage.getItem('emp_unique_id'),
                kol_type: '',
                project_id: sessionStorage.getItem('project_id'),
                // project_id: 'CHCPI0002',
                salutation: '',
                salutation_links: '',
                salutation_comment: '',
                salutation_status: '',
                doctor_full_name: '',
                doctor_full_name_links: '',
                doctor_full_name_comment: '',
                doctor_full_name_status: '',
                first_name: '',
                first_name_links: '',
                first_name_comment: '',
                first_name_status: '',
                middle_name: '',
                middle_name_links: '',
                middle_name_comment: '',
                middle_name_status: '',
                last_name: '',
                last_name_links: '',
                last_name_comment: '',
                last_name_status: '',
                qualification: '',
                qualification_links: '',
                qualification_comment: '',
                qualification_status: '',
                experience: '',
                experience_links: '',
                experience_comment: '',
                experience_status: '',
                medical_schools: '',
                medical_schools_links: '',
                medical_schools_comment: '',
                medical_schools_status: '',
                medical_registration_no: '',
                medical_registration_no_links: '',
                medical_registration_no_comment: '',
                medical_registration_no_status: '',
                NPI_no: '',
                NPI_no_links: '',
                NPI_no_comment: '',
                NPI_no_status: '',
                brand: '',
                brand_links: '',
                brand_comment: '',
                brand_status: '',
                procedure_type_id: '',
                procedure_type_links: '',
                procedure_type_comment: '',
                procedure_type_status: '',
                specialty_id: '',
                specialty_links: '',
                specialty_comment: '',
                specialty_status: '',
                sub_specialty_id: '',
                sub_specialty_links: '',
                sub_specialty_comment: '',
                sub_specialty_status: '',
                doctor_contact_no: '',
                doctor_contact_links: '',
                doctor_contact_comment: '',
                doctor_contact_status: '',
                doctor_email: '',
                doctor_email_links: '',
                doctor_email_comment: '',
                doctor_email_status: '',
                fax: '',
                fax_links: '',
                fax_comment: '',
                fax_status: '',
                linked_in_profile: '',
                linked_in_profile_links: '',
                linked_in_profile_comment: '',
                linked_in_profile_status: '',
                twitter_handle: '',
                twitter_handle_links: '',
                twitter_handle_comment: '',
                twitter_handle_status: '',
                assistance_contact_no: '',
                assistance_contact_no_links: '',
                assistance_contact_no_comment: '',
                assistance_contact_no_status: '',
                assistance_email: '',
                assistance_email_links: '',
                assistance_email_comment: '',
                assistance_email_status: '',
                website: '',
                website_links: '',
                website_comment: '',
                website_status: '',
                contract: '',
                contract_file: '',
                contract_comment: '',
                contract_status: '',
                address_1: '',
                address_2: '',
                address_links: '',
                address_comment: '',
                address_status: '',
                state_id: '',
                state_links: '',
                state_comment: '',
                state_status: '',
                country_id: '',
                country_links: '',
                country_comment: '',
                country_status: '',
                zip_code: '',
                zip_code_comment: '',
                zip_code_status: '',
                // last_updated_date: '',
                procedure_potential: '',
                procedure_potential_links: '',
                procedure_potential_comment: '',
                procedure_potential_status: '',
                researcher_id: sessionStorage.getItem('researcher_id'),
                password: '',
                research_total_survey: '',
                research_completed_survey: '',
                research_incentivised_survey: '',
                incentive_accured_points: '',
                incentive_reedeemed_points: '',
            },
            profile_photo: '',
            ContractFileName: '',
            // Work Details
            WorkDetails: [{
                kol_id: sessionStorage.getItem('emp_unique_id'),
                current_place_of_work: '',
                current_place_of_work_links: '',
                current_place_of_work_comment: '',
                current_place_of_work_status: '',
                designation: '',
                designation_links: '',
                designation_comment: '',
                designation_status: '',
                department: '',
                department_links: '',
                department_comment: '',
                department_status: '',
            }],
            // Biography
            Biography: {
                kol_id: sessionStorage.getItem('emp_unique_id'),
                citizenship: '',
                citizenship_links: '',
                citizenship_comment: '',
                citizenship_status: '',
                licensed_regions: '',
                licensed_regions_links: '',
                licensed_regions_comment: '',
                licensed_regions_status: '',
                areas_of_interest: '',
                areas_of_interest_links: '',
                areas_of_interest_comment: '',
                areas_of_interest_status: '',
                language_spoken: '',
                language_spoken_links: '',
                language_spoken_comment: '',
                language_spoken_status: '',
                work_phone: '',
                work_phone_links: '',
                work_phone_comment: '',
                work_phone_status: '',
                work_email: '',
                work_email_links: '',
                work_email_comment: '',
                work_email_status: '',
                linkedin_followers: '',
                linkedin_followers_links: '',
                linkedin_followers_comment: '',
                linkedin_followers_status: '',
                twitter_followers: '',
                twitter_followers_links: '',
                twitter_followers_comment: '',
                twitter_followers_status: '',
                biography: '',
                biography_links: '',
                biography_comment: '',
                biography_status: '',

                resume_comment: '',
                resume_status: '',
            },
            LanguageSpoken: [],
            BiographyResume: '',
            BiographyResumeFileName: '',
            // Professional membership
            ProfessionalMemberships: [{
                kol_id: sessionStorage.getItem('emp_unique_id'),
                // kol_id: 'sa2',
                organization: '',
                organization_links: '',
                organization_comment: '',
                organization_status: '',
                organization_type: '',
                organization_type_links: '',
                organization_type_comment: '',
                organization_type_status: '',
                source_link: '',
                source_link_links: '',
                source_link_comment: '',
                source_link_status: '',
                location: '',
                location_links: '',
                location_comment: '',
                location_status: '',
                position: '',
                position_links: '',
                position_comment: '',
                position_status: '',
                tenure: '',
                tenure_link: '',
                tenure_comment: '',
                tenure_status: '',
                tenure1: '',
                tenure1_comment: '',
                tenure1_status: '',
                organization_website: '',
                organization_website_links: '',
                organization_website_comment: '',
                organization_website_status: '',
            },],
            // Education
            Education: [{
                kol_id: sessionStorage.getItem('emp_unique_id'),
                institute_name: '',
                institute_name_links: '',
                institute_name_comment: '',
                institute_name_status: '',
                institute_type: '',
                institute_type_links: '',
                institute_type_comment: '',
                institute_type_status: '',
                source_link: '',
                source_link_links: '',
                source_link_comment: '',
                source_link_status: '',
                degrees: '',
                degrees_links: '',
                degrees_comment: '',
                degrees_status: '',
                speciality_id: '',
                speciality_links: '',
                speciality_comment: '',
                speciality_status: '',
                years_attended_from: '',
                years_attended_to: '',
                years_attended_links: '',
                years_attended_comment: '',
                years_attended_staus: '',
            }],
            // Advisory Borad
            AdvisoryBoard: [{
                kol_id: sessionStorage.getItem('emp_unique_id'),
                year: '',
                year_links: '',
                year_comment: '',
                year_status: '',
                advisory_board: '',
                advisory_board_links: '',
                advisory_board_comment: '',
                advisory_board_status: '',
                topic: '',
                topic_links: '',
                topic_comment: '',
                topic_status: '',
                speciality_id: '',
                speciality_links: '',
                speciality_comment: '',
                speciality_status: '',
                sub_specialty_id: '',
                sub_speciality_links: '',
                sub_speciality_comment: '',
                sub_speciality_status: '',
                procedure_type_id: '',
                procedure_type_links: '',
                procedure_type_comment: '',
                procedure_type_status: '',
                company: '',
                company_links: '',
                company_comment: '',
                company_status: '',
                type_of_company: '',
                type_of_company_links: '',
                type_of_company_comment: '',
                type_of_company_status: '',
                role_id: '',
                role_links: '',
                role_comment: '',
                role_status: '',
                manual_weightage: '',
            }],
            //Awards & Homnours
            AwardsHonours: [{
                kol_id: sessionStorage.getItem('emp_unique_id'),
                year: '',
                year_links: '',
                year_comment: '',
                year_status: '',
                awards_honours: '',
                awards_honours_links: '',
                awards_honours_commen: '',
                awards_honours_status: '',
                types: '',
                types_links: '',
                types_comment: '',
                types_status: '',
            }],
            // Books & Monographs
            BooksMonographs: [{
                kol_id: sessionStorage.getItem('emp_unique_id'),
                year: '',
                year_links: '',
                year_comment: '',
                year_status: '',
                title: '',
                title_links: '',
                title_comment: '',
                title_status: '',
                author: '',
                author_links: '',
                author_comment: '',
                author_status: '',
                publication: '',
                publication_links: '',
                publication_comment: '',
                publication_status: '',
                edition: '',
                edition_links: '',
                edition_comment: '',
                edition_status: '',
                pages: '',
                pages_links: '',
                pages_comment: '',
                pages_status: '',
                editors: '',
                editors_links: '',
                editors_comment: '',
                editors_status: '',
            }],
            // Commitees
            Commitees: [{
                kol_id: sessionStorage.getItem('emp_unique_id'),
                year: '',
                year_links: '',
                year_comment: '',
                year_status: '',
                committee: '',
                committee_links: '',
                committee_comment: '',
                committee_status: '',
                role_id: '',
                role_links: '',
                role_comment: '',
                role_status: '',
            }],
            // Clinical Trial
            ClinicalTrialDetails: [{
                kol_id: sessionStorage.getItem('emp_unique_id'),
                speciality_id: '',
                speciality_links: '',
                speciality_comment: '',
                speciality_status: '',
                sub_speciality: '',
                sub_speciality_links: '',
                sub_speciality_comment: '',
                sub_speciality_status: '',
                procedure_type: '',
                procedure_type_links: '',
                procedure_type_comment: '',
                procedure_type_status: '',
                study_details: '',
                study_details_links: '',
                study_details_comment: '',
                study_details_status: '',
                source_link: '',
                source_link_links: '',
                source_link_comment: '',
                source_link_status: '',
                start_date: '',
                start_date_links: '',
                start_date_comment: '',
                start_date_status: '',
                end_date: '',
                end_date_links: '',
                end_date_comment: '',
                end_date_status: '',
                type: '',
                type_links: '',
                type_comment: '',
                type_status: '',
                phase: '',
                phase_links: '',
                phase_comment: '',
                phase_status: '',
                role_id: '',
                role_links: '',
                role_comment: '',
                role_status: '',
                no_of_study_locations: '',
                no_of_study_locations_links: '',
                no_of_study_locations_comment: '',
                no_of_study_locations_status: '',
                conditions: '',
                conditions_links: '',
                conditions_comment: '',
                conditions_status: '',
                treatment_intervention: '',
                treatment_intervention_links: '',
                treatment_intervention_comment: '',
                treatment_intervention_status: '',
                primary_outcomes: '',
                primary_outcomes_links: '',
                primary_outcomes_comment: '',
                primary_outcomes_status: '',
                secondary_outcomes: '',
                secondary_outcomes_links: '',
                secondary_outcomes_comment: '',
                secondary_outcomes_status: '',
                sponsors: '',
                sponsors_links: '',
                sponsors_comment: '',
                sponsors_status: '',
                collaborators: '',
                collaborators_links: '',
                collaborators_comment: '',
                collaborators_status: '',
                manual_weightage: '',
            }],
            // Event Details
            EventsDetails: [{
                kol_id: sessionStorage.getItem('emp_unique_id'),
                speciality_id: '',
                speciality_links: '',
                speciality_comment: '',
                speciality_status: '',
                sub_speciality: '',
                sub_speciality_links: '',
                sub_speciality_comment: '',
                sub_speciality_status: '',
                procedure_type: '',
                procedure_type_links: '',
                procedure_type_comment: '',
                procedure_type_status: '',
                events: '',
                events_links: '',
                events_comment: '',
                events_status: '',
                event_type: '',
                event_type_links: '',
                event_type_comment: '',
                event_type_status: '',
                event_desc: '',
                event_desc_links: '',
                event_desc_comment: '',
                event_desc_status: '',
                event_topic: '',
                event_topic_links: '',
                event_topic_comment: '',
                event_topic_status: '',
                source_link: '',
                source_link_links: '',
                source_link_comment: '',
                source_link_status: '',
                conditions: '',
                conditions_links: '',
                conditions_comment: '',
                conditions_status: '',
                intervention: '',
                intervention_links: '',
                intervention_comment: '',
                intervention_status: '',
                date: '',
                date_links: '',
                date_comment: '',
                date_status: '',
                session_type: '',
                session_type_links: '',
                session_type_comment: '',
                session_type_status: '',
                role_id: '',
                role_links: '',
                role_comment: '',
                role_status: '',
                event_sponsor: '',
                event_sponsor_links: '',
                event_sponsor_comment: '',
                event_sponsor_status: '',
                total_event_attendees: '',
                total_event_attendees_links: '',
                total_event_attendees_comment: '',
                total_event_attendees_status: '',
                country_id: '',
                country_links: '',
                country_comment: '',
                country_status: '',
                manual_weightage: '',
            }],
            // Grants
            Grants: [{
                kol_id: sessionStorage.getItem('emp_unique_id'),
                company: '',
                company_links: '',
                company_comment: '',
                company_status: '',
                title: '',
                title_links: '',
                title_comment: '',
                title_status: '',
                year: '',
                year_links: '',
                year_comment: '',
                year_status: '',
                amount: '',
                amount_links: '',
                amount_comment: '',
                amount_status: '',
                co_investigator: '',
                co_investigator_links: '',
                co_investigator_comment: '',
                co_investigator_status: '',
            }],
            // Guidelines
            Guidelines: [{
                kol_id: sessionStorage.getItem('emp_unique_id'),
                speciality_id: '',
                speciality_links: '',
                speciality_comment: '',
                speciality_status: '',
                sub_speciality: '',
                sub_speciality_links: '',
                sub_speciality_comment: '',
                sub_speciality_status: '',
                procedure_type: '',
                procedure_type_links: '',
                procedure_type_comment: '',
                procedure_type_status: '',
                year: '',
                year_links: '',
                year_comment: '',
                year_status: '',
                organization: '',
                organization_links: '',
                organization_comment: '',
                organization_status: '',
                type_of_organization: '',
                type_of_organization_links: '',
                type_of_organization_comment: '',
                type_of_organization_status: '',
                company: '',
                company_links: '',
                company_comment: '',
                company_status: '',
                title: '',
                title_links: '',
                title_comment: '',
                title_status: '',
                manual_weightage: '',
            }],
            // HospitalAffiliations
            HospitalAffiliations: [{
                kol_id: sessionStorage.getItem('emp_unique_id'),
                hospital: '',
                hospital_links: '',
                hospital_comment: '',
                hospital_status: '',
                city_id: '',
                city_links: '',
                city_comment: '',
                city_status: '',
                state_id: '',
                state_links: '',
                state_comment: '',
                state_status: '',
                country_id: '',
                country_links: '',
                country_comment: '',
                country_status: '',
            }],
            StateDataForHospitalAffiliations: [],
            CityDataForHospitalAffiliations: [],
            // Interactions
            Interactions: [{
                kol_id: sessionStorage.getItem('emp_unique_id'),
                date: '',
                date_links: '',
                date_comment: '',
                date_status: '',
                opinion_leader: '',
                opinion_leader_links: '',
                opinion_leader_comment: '',
                opinion_leader_status: '',
                entered_by: '',
                entered_by_links: '',
                entered_by_comment: '',
                entered_by_status: '',
                product: '',
                product_links: '',
                product_comment: '',
                product_status: '',
                type: '',
                type_links: '',
                type_comment: '',
                type_status: '',
                mode: '',
                mode_links: '',
                mode_comment: '',
                mode_status: '',
                time: '',
                time_links: '',
                time_comment: '',
                time_status: '',
                activity_or_tool_used: '',
                activity_or_tool_used_links: '',
                activity_or_tool_used_comment: '',
                activity_or_tool_used_status: '',
                comments: '',
                comments_links: '',
                comments_comment: '',
                comments_status: '',
            }],
            // PressItem
            PressItem: [{
                kol_id: sessionStorage.getItem('emp_unique_id'),
                title: '',
                title_links: '',
                title_comment: '',
                title_status: '',
                source_link: '',
                source_link_links: '',
                source_link_comment: '',
                source_link_status: '',
                date: '',
                date_links: '',
                date_comment: '',
                date_status: '',
            }],
            // Presentation
            Presentation: [{
                kol_id: sessionStorage.getItem('emp_unique_id'),
                title: '',
                title_links: '',
                title_comment: '',
                title_status: '',
                type: '',
                type_links: '',
                type_comment: '',
                type_status: '',
                sponsor: '',
                sponsor_links: '',
                sponsor_comment: '',
                sponsor_status: '',
                location: '',
                location_links: '',
                location_comment: '',
                location_status: '',
                date: '',
                date_links: '',
                date_comment: '',
                date_status: '',
            }],
            // PublicationDetails
            PublicationDetails: [{
                kol_id: sessionStorage.getItem('emp_unique_id'),
                speciality_id: '',
                speciality_links: '',
                speciality_comment: '',
                speciality_status: '',
                sub_speciality: '',
                sub_speciality_links: '',
                sub_speciality_comment: '',
                sub_speciality_status: '',
                procedure_type: '',
                procedure_type_links: '',
                procedure_type_comment: '',
                procedure_type_status: '',
                journal: '',
                journal_links: '',
                journal_comment: '',
                journal_status: '',
                type_of_journal: '',
                type_of_journal_links: '',
                type_of_journal_comment: '',
                type_of_journal_status: '',
                publication_title: '',
                publication_title_links: '',
                publication_title_comment: '',
                publication_title_status: '',
                date: '',
                date_links: '',
                date_comment: '',
                date_status: '',
                source_link: '',
                source_link_links: '',
                source_link_comment: '',
                source_link_status: '',
                position: '',
                position_links: '',
                position_comment: '',
                position_status: '',
                co_author: '',
                co_author_links: '',
                co_author_comment: '',
                co_author_status: '',
                key_words: '',
                key_words_links: '',
                key_words_comment: '',
                key_words_status: '',
                manual_weightage: '',
            }],
            // SunshinePayment
            SunshinePayment: [{
                kol_id: sessionStorage.getItem('emp_unique_id'),
                date: '',
                date_links: '',
                date_comment: '',
                date_status: '',
                type: '',
                type_links: '',
                type_comment: '',
                type_status: '',
                devices: '',
                devices_links: '',
                devices_comment: '',
                devices_status: '',
                location: '',
                location_links: '',
                location_comment: '',
                location_status: '',
                amount: '',
                amount_links: '',
                amount_comment: '',
                amount_status: '',
                payment_type: '',
                payment_type_links: '',
                payment_type_comment: '',
                payment_type_status: '',
                OL_address: '',
                OL_address_links: '',
                OL_address_comment: '',
                OL_address_status: '',
                speciality_type_id: '',
                speciality_type_links: '',
                speciality_type_comment: '',
                speciality_type_status: '',
                NPI: '',
                NPI_links: '',
                NPI_comment: '',
                NPI_status: '',
            }],


            // OrganizationSourceUrl:[{ OrgSourceUrl:'' }],
            // for adding new source multiple
            items: [],
            value: "",

            // for adding new source multiple peronal info
            valuePersonalInfoAddSource: '',

            itemsSalutation: [],
            itemsDoctorFullName: [],
            itemsFirstName: [],
            itemsMiddleName: [],
            itemsLastName: [],
            itemsQualification: [],
            itemsExperience: [],
            itemsMedicalSchool: [],
            itemsMedicalRegisterNo: [],
            itemsNpiNo: [],
            itemsPlaceWork: [],
            itemsDesignation: [],
            itemsBrand: [],
            itemsDepartment: [],
            itemSpeciality: [],
            itemsProcedure: [],
            itemsSubSpeciality: [],
            itemsDoctorContact: [],
            itemsDoctorEmail: [],
            itemsFax: [],
            itemsLinkedIn: [],
            itemsTwitter: [],
            itemsAssistanceContact: [],
            itemsAssistanceEmail: [],
            itemsWebsite: [],
            itemsCountry: [],
            itemsState: [],
            itemsAddress1: [],
            itemsProcedurePotential: [],

            // for adding new source multiple Biography
            valueBiographyAddSource: '',

            itemsCitizenship: [],
            itemsLicensedRegions: [],
            itemsAreasOfInterest: [],
            itemsLanguageSpoken: [],
            itemsWorkPhone: [],
            itemsWorkEmail: [],
            itemsLinkedInFolllowers: [],
            itemsTwitterFollowers: [],
            itemsBiography: [],



            // variables for error
            // personal info
            koltypeErr: '',
            salutationErr: '',
            doctorfullnameErr: '',
            firstnameErr: '',
            lastnameErr: '',
            qualificationErr: '',
            experienceErr: '',
            medicalschoolErr: '',
            placeofworkErr: '',
            designationErr: '',
            departmentErr: '',
            specialityErr: '',
            proceduretypeErr: '',
            subspecialitytypeErr: '',
            doctorcontactnoErr: '',
            doctoremailErr: '',
            countryErr: '',
            stateErr: '',
            address1Err: '',
            zipcodeErr: '',
            // biography
            citizenshipErr: '',
            licensedregionsErr: '',
            areasofinterestErr: '',
            // workphoneErr: '',
            // workemailErr: '',
            biographyErr: '',
            // Professional Membership
            organizationErr: '',
            organizationtypeErr: '',
            sourcelinkErr: '',
            positionErr: '',
            // tenureErr: '',
            // tenureToErr: '',
            //Education
            institutenameErr: '',
            institutetypeErr: '',
            sourcelinkEducationErr: '',
            degreesErr: '',
            specialityEducationErr: '',
            yearsattendedfromErr: '',
            yearsattendedtoErr: '',
            // Advisory Board
            specialityAdvBoardErr: '',
            // subspecialityAdvBoardErr: '',
            // procedureAdvBoardErr: '',
            // yearErr: '',
            companyAdvBoardErr: '',
            typeofCompanyErr: '',
            advisoryboardErr: '',
            // topicErr: '',
            roleAdvBoardErr: '',
            manualAdvBoardErr: '',
            // Awards and honours
            // yearAwardsErr: '',
            awardsnhonourErr: '',
            // Books Monographs
            yearBooksErr: '',
            titleErr: '',
            authorErr: '',
            publicationErr: '',
            // editorsErr: '',
            // Commitees
            // yearCommiteeErr: '',
            commiteeErr: '',
            roleErr: '',
            // Clinical Trial Details
            specialityClinicalErr: '',
            subSpecialityClinicalErr: '',
            procedureClinicalErr: '',
            noStudyLocationErr: '',
            studydetailsErr: '',
            sourcelinkClinicalErr: '',
            startdateErr: '',
            enddateErr: '',
            phaseErr: '',
            roleClinicalErr: '',
            manualClinicaldErr: '',
            // Event Details
            specialityEventErr: '',
            subSpecialityEventErr: '',
            procedureEventErr: '',
            eventNameErr: '',
            eventTypeErr: '',
            eventdescriptionErr: '',
            eventtopicErr: '',
            sourcelinkEventErr: '',
            sessiontypeErr: '',
            roleEventsErr: '',
            manualAdvEventDetails: '',
            // Grants
            companyErr: '',
            titleGrantsErr: '',
            yearGrantsErr: '',
            // Guidelines
            specialityGuidelinesErr: '',
            subSpecialityGuidelinesErr: '',
            procedureGuidelinesErr: '',
            companyGuidelinesErr: '',
            typeOfCompanyGuidelinesErr: '',
            yearGuidelinesErr: '',
            titleGuidelinesErr: '',
            manualGuidelines: '',
            // Hospital Affiliations
            hospitalErr: '',
            countryHospitalErr: '',
            // Interactions
            dateInteractionErr: '',
            opinionleaderErr: '',
            enteredbyErr: '',
            producttopicErr: '',
            typeInteractionErr: '',
            modeInteractionErr: '',
            timeInteractionErr: '',
            // Press Item
            titlePressErr: '',
            sourcelinkPressErr: '',
            datePressErr: '',
            // Presentation
            titlePresentationErr: '',
            typePresentationErr: '',
            sponsorPresentationErr: '',
            locationPresentationErr: '',
            datePresentationErr: '',
            // Publication Details
            specialityPublicationErr: '',
            subSpecialityPublicationErr: '',
            procedurePublicationErr: '',
            typeOfJournelErr: '',
            journalErr: '',
            publicationtitleErr: '',
            datePublicationErr: '',
            sourcelinkPublicationErr: '',
            positionPublicationErr: '',
            coauthorPublicationErr: '',
            keywordsErr: '',
            manualPublication: '',
            // Sunshine Payment
            paymenttypeErr: '',
            specialitytypeSunshineErr: '',
            // Procedure potential
            procedurepotentialErr: '',


            // Variables for add source
            SalutationAddSource: false,
            DFullNameAddSource: false,
            FirstNameAddSource: false,
            MiddleNameAddSource: false,
            LastNameAddSource: false,
            QualificationAddSource: false,
            ExperienceAddSource: false,
            MedicalSchoolsAddSource: false,
            MedicalRegistrationNoAddSource: false,
            NPINoAddSource: false,
            PlaceOfWorkAddSource: false,
            DesignationAddSource: false,
            BrandAddSource: false,
            DepartmentAddSource: false,
            SpecialityAddSource: false,
            ProcedureTypeAddSource: false,
            SubSpecialityTypeAddSource: false,
            DoctorContactNoAddSource: false,
            DoctorEmailAddSource: false,
            FaxAddSource: false,
            LinkedInProfileAddSource: false,
            TwitterHandleAddSource: false,
            AssistanceContactNoAddSource: false,
            AssistanceEmailAddSource: false,
            WebsiteAddSource: false,
            CountryAddSource: false,
            StateAddSource: false,
            Address1AddSource: false,
            Address2AddSource: false,

            // Biography
            CitizenshipAddSource: false,
            LicensedRegionsAddSource: false,
            AreasofInterestAddSource: false,
            LanguageSpokenAddSource: false,
            WorkPhoneAddSource: false,
            WorkEmailAddSource: false,
            LinkedInFollowersAddSource: false,
            TwitterFollowersAddSource: false,
            BiographyFieldAddSource: false,

            // Professional Membership
            OrganizationAddSource: false,
            OrganizationTypeAddSource: false,
            SourceLinkAddSource: false,
            LocationAddSource: false,
            PositionAddSource: false,
            TenureAddSource: false,
            OrganizationWebsiteAddSource: false,
            ProfileCountAddSource: false,

            // Education
            InstituteNameAddSource: false,
            InstituteTypeAddSource: false,
            SourceLinkEducationAddSource: false,
            DegreesAddSource: false,
            SpecialityEducationAddSource: false,
            YearsAttendedAddSource: false,

            // Advisory Borad
            SpecialityAdvBoardAddSource: false,
            SubSpecialityAdvBoardAddSource: false,
            ProcedureAdvBoardAddSource: false,
            YearAddSource: false,
            CompanyOrgAddSource: false,
            TypeOfCompanyOrgAddSource: false,
            AdvisoryBoardSource: false,
            TopicSource: false,
            RoleAdvBoardAddSource: false,

            // Awards honours
            YearAwardSource: false,
            AwardsHonoursSource: false,
            TypesSource: false,

            // Books monographs
            YearBooksSource: false,
            titleSource: false,
            AuthorSource: false,
            PublicationSource: false,
            EditionSource: false,
            PagesSource: false,
            EditorsSource: false,

            // Commitees
            YearCommiteesSource: false,
            CommiteeSource: false,
            RoleSource: false,

            // Clinical trial details
            SpecialityClinicalSource: false,
            SubSpecialityClinicalSource: false,
            ProcedureClinicalSource: false,
            StudyDetailsSource: false,
            SourceLinkClinicalTrialSource: false,
            StartDateClinicalTrialSource: false,
            EndDateClinicalTrialSource: false,
            TypeSource: false,
            PhaseSource: false,
            RoleClinicalTrialSource: false,
            NoStudyLocationClinicalTrialSource: false,
            conditionsSource: false,
            TreatmentInterventionSource: false,
            PrimaryOutcomeSource: false,
            SecondaryOutcomesSource: false,
            SponsorSource: false,
            CollaboratorsSource: false,

            // Event Details
            SpecialityEventSource: false,
            EventDescriptionSource: false,
            SubSpecialityEventSource: false,
            ProceduereEventSource: false,
            EventNameSource: false,
            EventTypeEventsSource: false,
            EventTopicSource: false,
            SourcelinEventkSource: false,
            ConditionsEventsSource: false,
            InterventionEventsSource: false,
            DateEventsSource: false,
            SessionTypeSource: false,
            RoleEventsSource: false,
            EventSponsorSource: false,
            TotalEventAttendeesSource: false,
            CountryEventsSource: false,

            // Grants
            CompanySource: false,
            TitleGrantsSource: false,
            YearGrantsSource: false,
            AmountGrantsSource: false,
            CoInvestigatorSource: false,

            // Guidelines
            SpecialityGuidlinesSource: false,
            SubSpecialityGuidlinesSource: false,
            ProcedureGuidlinesSource: false,
            YearGuidlinesSource: false,
            OrganizationGuidlinesSource: false,
            CompanyOrganizationGuidlinesSource: false,
            TypeOfOrganizationGuidlinesSource: false,
            TitleGuidlinesSource: false,

            // Hospital Affiliations
            HospitalSource: false,
            CountryHospitalAffSource: false,
            StateHospitalAffSource: false,
            CityHospitalAffSource: false,

            // Interactions
            DateInteractionsSource: false,
            OpinionLeaderSource: false,
            EnteredBySource: false,
            ProductTopicSource: false,
            TypeInteractionSource: false,
            ModeInteractionSource: false,
            TimeInteractionSource: false,
            ActivityToolUsedSource: false,
            CommentsKeywordsSource: false,

            // Press Item
            TitlePressItemSource: false,
            SourceLinkPressItemSource: false,
            DatePressItemSource: false,

            // Presentation
            TitlePresentationSource: false,
            TypePresentationSource: false,
            SponsorPresentationSource: false,
            LocationPresentationSource: false,
            DatePresentationSource: false,

            // Publication Details
            SpecialityPublicationSource: false,
            SubSpecialityPublicationSource: false,
            ProcedurePublicationSource: false,
            JournalSource: false,
            TypeOfJournelPublicationSource: false,
            PublicationTitleSource: false,
            DatePublicationSource: false,
            SourceLinkPublicationSource: false,
            PositionPublicationSource: false,
            CoAuthorPublicationSource: false,
            KeyWordsPublicationSource: false,

            // Sunshine Payment
            DateSunshineSource: false,
            TypeSunshineSource: false,
            DrugDevicesSource: false,
            LocationSunshineSource: false,
            AmountSunshineSource: false,
            PaymentTypeSunshineSource: false,
            OLAddressSunshineSource: false,
            SpecialitySunshineSource: false,
            NPISunshineSource: false,

            // Procedure Potential
            ProcedurePotentialSource: false,

            // vriales for error
            // doctoremailError: '',
        };
    }

    componentDidMount = async () => {

        let getSession_token = await sessionStorage.getItem('token')
        if (getSession_token !== null) {
        await this.setState({
            token: getSession_token,
        })
        }

        let getSession_Researcher_Id = await sessionStorage.getItem('id')
        if (getSession_Researcher_Id !== null) {
            await this.setState({
                RID: getSession_Researcher_Id,
            })
        }
        let getSession_Researcher_Name = await sessionStorage.getItem('name')
        if (getSession_Researcher_Name !== null) {
            await this.setState({
                resercher_name: getSession_Researcher_Name,
            })
        }
        let getSession_Project_Id = await sessionStorage.getItem('project_id')
        if (getSession_Project_Id !== null) {
            await this.setState({
                project_id: getSession_Project_Id,
            })
        }
        let getSession_Project_Name = await sessionStorage.getItem('Project_Name')
        if (getSession_Project_Name !== null) {
            await this.setState({
                Project_Name: getSession_Project_Name,
            })
        }
        let getSession_Emp_Unique_Id = await sessionStorage.getItem('emp_unique_id')
        if (getSession_Emp_Unique_Id !== null) {
            await this.setState({
                emp_unique_id: getSession_Emp_Unique_Id,
            })
        }
        let getSession_Sr_No = await sessionStorage.getItem('SrNO')
        if (getSession_Sr_No !== null) {
            await this.setState({
                SrNO: getSession_Sr_No,
            })
        }

        console.log("getSession_Researcher_Id ===== ", getSession_Researcher_Id)
        console.log("getSession_Researcher_Name ===== ", getSession_Researcher_Name)
        console.log("getSession_Project_Id ===== ", getSession_Project_Id)
        console.log("getSession_Project_Name ===== ", getSession_Project_Name)
        console.log("getSession_Emp_Unique_Id ===== ", getSession_Emp_Unique_Id)
        console.log("getSession_Sr_No ===== ", getSession_Sr_No)

        this.setState({
            ShowBar: true,
        })

        const options = {
            method: 'POST',
        }

        //  Speciality type data
        fetch(global.websiteUrl+'/service/Speciality/get_SpecialityList', options)
            .then((Response) => Response.json())
            .then((findresponse) => {
                console.log(findresponse)
                var testDataSpeciality = findresponse.data;
                console.log("testDataSpeciality", JSON.stringify(testDataSpeciality));
                this.setState({
                    SpecialityData: findresponse.data,
                })
            })

        // Country List
        fetch(global.websiteUrl+'/service/Location/get_CountryList', options)
            .then((Response) => Response.json())
            .then((findresponse) => {
                console.log(findresponse)
                var TestCountryList = findresponse.Country_Details;
                // var TestKolIddata= findresponse.kol_system_id;
                console.log("TestCountryList", JSON.stringify(TestCountryList));
                // console.log("TestKolIddata",JSON.stringify(TestKolIddata));
                this.setState({
                    CountryListData: findresponse.Country_Details,
                })
            })

        // employee list
        fetch(global.websiteUrl+'/service/Employee/get_EmployeeList', options)
            .then((Response) => Response.json())
            .then((findresponse) => {
                console.log(findresponse)
                var TestEmployeeData = findresponse.Employee_Details;
                console.log("TestEmployeeData", JSON.stringify(TestEmployeeData));
                this.setState({
                    EmployeeListData: findresponse.Employee_Details,
                })
                this.setState({
                    ShowBar: false,
                })
            })

    }

    validatesaveasdraft = () => {
        let specialityErr = '';
        let koltypeErr = '';
        let doctorfullnameErr = '';
        let firstnameErr = '';


        if (!this.state.PersonalInformation.specialty_id) {
            specialityErr = 'Please Select Speciality';
        }

        if (!this.state.PersonalInformation.kol_type) {
            koltypeErr = 'Please Select KOL Type';
        }

        if (!this.state.PersonalInformation.doctor_full_name) {
            doctorfullnameErr = 'Please Enter Doctors Full Name ';
        }

        if (!this.state.PersonalInformation.first_name) {
            firstnameErr = 'Please Enter First Name';
        }

        if (specialityErr || koltypeErr || doctorfullnameErr || firstnameErr) {
            this.setState({ specialityErr, koltypeErr, doctorfullnameErr, firstnameErr });
            return false;
        }
        return true;
    }

    // Function for validation
    validate = () => {
        //     let doctorEmailError = '';
        //     if(!this.state.DoctorEmail)  {
        //         doctorEmailError = 'Please Enter Email';
        //     }else if(typeof this.state.DoctorEmail !== "undefined"){
        //            let lastAtPos = this.state.DoctorEmail.lastIndexOf('@');
        //            let lastDotPos = this.state.DoctorEmail.lastIndexOf('.');

        //            if (!(lastAtPos < lastDotPos && lastAtPos > 0 )) {
        //             //   formIsValid = false;
        //               doctorEmailError = "Email is not valid";
        //               if(doctorEmailError) {
        //                 this.setState({doctorEmailError})
        //               }
        //             }else {
        //               this.setState({doctorEmailError: null})    
        //             }
        //             console.log('i am here1');
        //         }else {
        //           console.log('i am here');
        //           this.setState({doctorEmailError: null})
        //         }

        //     if( doctorEmailError ){
        //         this.setState({ doctorEmailError });
        //         return false;
        //     }
        // return true;
        let koltypeErr = '';
        let salutationErr = '';
        let doctorfullnameErr = '';
        let firstnameErr = '';
        let lastnameErr = '';
        let qualificationErr = '';
        let experienceErr = '';
        let medicalschoolErr = '';
        let specialityErr = '';
        let proceduretypeErr = '';
        let subspecialitytypeErr = '';
        let doctorcontactnoErr = '';
        let doctoremailErr = '';
        let countryErr = '';
        let stateErr = '';
        let address1Err = '';
        let zipcodeErr = '';
        // let lastupdateddateErr = '';

        let placeofworkErr = '';
        let designationErr = '';
        let departmentErr = '';

        let citizenshipErr = '';
        // let licensedregionsErr = '';
        let areasofinterestErr = '';
        // let workphoneErr = '';
        // let workemailErr = '';
        let biographyErr = '';

        let organizationErr = '';
        let organizationtypeErr = '';
        let sourcelinkErr = '';
        let positionErr = '';
        // let tenureErr = '';
        // let tenureToErr= '';

        let institutenameErr = '';
        let institutetypeErr = '';
        let sourcelinkEducationErr = '';
        let degreesErr = '';
        let specialityEducationErr = '';
        let yearsattendedfromErr = '';
        let yearsattendedtoErr = '';

        let specialityAdvBoardErr = '';
        // let subspecialityAdvBoardErr= '';
        // let procedureAdvBoardErr= '';
        // let yearErr= '';
        let companyAdvBoardErr = '';
        let typeofCompanyErr = '';
        let advisoryboardErr = '';
        // let topicErr= '';
        let roleAdvBoardErr = '';
        let manualAdvBoardErr = '';

        // let yearAwardsErr= '';
        let awardsnhonourErr = '';

        let yearBooksErr = '';
        let titleErr = '';
        let authorErr = '';
        let publicationErr = '';
        // let editorsErr= '';

        // let yearCommiteeErr= '';
        let commiteeErr = '';
        let roleErr = '';

        let specialityClinicalErr = '';
        let subSpecialityClinicalErr = '';
        let procedureClinicalErr = '';
        let noStudyLocationErr = '';
        let studydetailsErr = '';
        let sourcelinkClinicalErr = '';
        let startdateErr = '';
        let enddateErr = '';
        let phaseErr = '';
        let roleClinicalErr = '';
        let manualClinicaldErr = '';

        let specialityEventErr = '';
        let subSpecialityEventErr = '';
        let procedureEventErr = '';
        let eventNameErr = '';
        let eventTypeErr = '';
        let eventdescriptionErr = '';
        let eventtopicErr = '';
        let sourcelinkEventErr = '';
        let sessiontypeErr = '';
        let roleEventsErr = '';
        let manualAdvEventDetails = '';

        let companyErr = '';
        let titleGrantsErr = '';
        let yearGrantsErr = '';

        let specialityGuidelinesErr = '';
        let subSpecialityGuidelinesErr = '';
        let procedureGuidelinesErr = '';
        let typeOfCompanyGuidelinesErr = '';
        let companyGuidelinesErr = '';
        let yearGuidelinesErr = '';
        let titleGuidelinesErr = '';
        let manualGuidelines = '';

        let hospitalErr = '';
        let countryHospitalErr = '';

        let dateInteractionErr = '';
        let opinionleaderErr = '';
        let enteredbyErr = '';
        let producttopicErr = '';
        let typeInteractionErr = '';
        let modeInteractionErr = '';
        let timeInteractionErr = '';

        let titlePressErr = '';
        let sourcelinkPressErr = '';
        let datePressErr = '';

        let titlePresentationErr = '';
        let typePresentationErr = '';
        let sponsorPresentationErr = '';
        let locationPresentationErr = '';
        let datePresentationErr = '';

        let specialityPublicationErr = '';
        let subSpecialityPublicationErr = '';
        let procedurePublicationErr = '';
        let typeOfJournelErr = '';
        let journalErr = '';
        let publicationtitleErr = '';
        let datePublicationErr = '';
        let sourcelinkPublicationErr = '';
        let positionPublicationErr = '';
        let coauthorPublicationErr = '';
        let keywordsErr = '';
        let manualPublication = '';

        let paymenttypeErr = '';
        let specialitytypeSunshineErr = '';

        let procedurepotentialErr = '';


        if (!this.state.PersonalInformation.kol_type) {
            koltypeErr = 'Please Select KOL Type';
        }
        if (!this.state.PersonalInformation.salutation) {
            salutationErr = 'Please Select Salutation';
        }
        if (!this.state.PersonalInformation.doctor_full_name) {
            doctorfullnameErr = 'Please Enter Full Name';
        }
        if (!this.state.PersonalInformation.first_name) {
            firstnameErr = 'Please Enter First Name';
        }
        if (!this.state.PersonalInformation.last_name) {
            lastnameErr = 'Please Enter Last Name';
        }
        if (!this.state.PersonalInformation.qualification) {
            qualificationErr = 'Please Enter Qualification';
        }
        // if (!this.state.PersonalInformation.experience) {
        //     experienceErr = 'Please Enter Experience';
        // }
        if(this.state.PersonalInformation.kol_type == "KOL Community"){
            if ( !this.state.PersonalInformation.experience) {
              experienceErr = 'Please Select Experience';
            }
          }
        // if(!this.state.PersonalInformation.medical_schools)  {
        //     medicalschoolErr = 'Please Enter Medical School';
        // }
        if (!this.state.PersonalInformation.specialty_id) {
            specialityErr = 'Please Select Speciality';
        }
        if (!this.state.PersonalInformation.procedure_type_id) {
            proceduretypeErr = 'Please Select Procedure Type';
        }
        if (!this.state.PersonalInformation.sub_specialty_id) {
            subspecialitytypeErr = 'Please Select Sub Speciality Type';
        }
        if (!this.state.PersonalInformation.doctor_contact_no) {
            doctorcontactnoErr = 'Please Enter Contact No';
        }
        if (!this.state.PersonalInformation.doctor_email) {
            doctoremailErr = 'Please Enter Email';
        }
        if (!this.state.PersonalInformation.country_id) {
            countryErr = 'Please Select Country';
        }
        if (!this.state.PersonalInformation.state_id) {
            stateErr = 'Please Select State';
        }
        if (!this.state.PersonalInformation.address_1) {
            address1Err = 'Please Enter Address';
        }
        if (!this.state.PersonalInformation.zip_code) {
            zipcodeErr = 'Please Enter Zip Code';
        }
        // if(!this.state.PersonalInformation.last_updated_date)  {
        //     lastupdateddateErr = 'Please Enter Date';
        // }

        // Work Details
        {
            this.state.WorkDetails.map((el, i) => {
                if (!el.current_place_of_work) {
                    placeofworkErr = 'Please Enter Current Place of Work';
                }
                if (!el.designation) {
                    designationErr = 'Please Enter Designation';
                }
                if (!el.department) {
                    departmentErr = 'Please Enter Department';
                }
            })
        }

        // Biography validation starts here
        if (this.state.KOLType == 'Payers Community' || this.state.KOLType == 'HTA Experts' ||
            this.state.KOLType == 'Clinical Commission Group') {
            if (!this.state.Biography.citizenship) {
                citizenshipErr = 'Please Select Citizenship';
            }
            if (!this.state.Biography.areas_of_interest) {
                areasofinterestErr = 'Please Enter Areas of Interest';
            }
            if (!this.state.Biography.biography) {
                biographyErr = 'Please Enter Biography';
            }
        }

        if (this.state.KOLType == 'KOL Community' || this.state.KOLType == '') {
            if (!this.state.Biography.citizenship) {
                citizenshipErr = 'Please Select Citizenship';
            }
            // if(!this.state.Biography.licensed_regions)  {
            //     licensedregionsErr = 'Please Enter Licensed Regions';
            // }
            if (!this.state.Biography.areas_of_interest) {
                areasofinterestErr = 'Please Enter Areas of Interest';
            }
            // if(!this.state.Biography.work_email)  {
            //     workemailErr = 'Please Enter Work Email';
            // }
            if (!this.state.Biography.biography) {
                biographyErr = 'Please Enter Biography';
            }
        }

        // Professional info validations starts here
        {
            this.state.ProfessionalMemberships.map((el, i) => {
                if (!el.organization) {
                    organizationErr = 'Please Enter Organization';
                }
                if (!el.organization_type) {
                    organizationtypeErr = 'Please Enter Organization Type';
                }
                if (!el.source_link) {
                    sourcelinkErr = 'Please Enter Source Link';
                }
                if (!el.position) {
                    positionErr = 'Please Enter Position';
                }
                // if(!el.tenure){
                //     tenureErr = 'Please Enter Tenure';
                // } 
                // if(!el.tenure1){
                //     tenureToErr = 'Please Enter Tenure';
                // } 
            })
        }
        // Education
        {
            this.state.Education.map((el, i) => {
                if (!el.institute_name) {
                    institutenameErr = 'Please Enter Institute Name';
                }
                if (!el.institute_type) {
                    institutetypeErr = 'Please Enter Institute Type';
                }
                if (!el.source_link) {
                    sourcelinkEducationErr = 'Please Enter Source Link';
                }
                if (!el.degrees) {
                    degreesErr = 'Please Enter Degrees';
                }
                if (!el.speciality_id) {
                    specialityEducationErr = 'Please Select Speciality';
                }
                if (!el.years_attended_from) {
                    yearsattendedfromErr = 'Please Enter Years Attended From';
                }
                if (!el.years_attended_to) {
                    yearsattendedtoErr = 'Please Enter Years Attended To';
                }
            })
        }
        // Advisory Board
        {
            this.state.AdvisoryBoard.map((el, i) => {
                if (!el.speciality_id) {
                    specialityAdvBoardErr = 'Please Select Speciality';
                }
                // if(!el.sub_specialty_id){
                //     subspecialityAdvBoardErr = 'Please Select Sub Speciality';
                // }
                // if(!el.procedure_type_id){
                //     procedureAdvBoardErr = 'Please Select Procedure Type';
                // }
                // if(!el.year){
                //     yearErr = 'Please Enter Year';
                // }
                if (!el.company) {
                    companyAdvBoardErr = 'Please Enter Company';
                }
                if (!el.type_of_company) {
                    typeofCompanyErr = 'Please Enter Company Type';
                }
                if (!el.advisory_board) {
                    advisoryboardErr = 'Please Enter Advisory Board';
                }
                // if(!el.topic){
                //     topicErr = 'Please Enter Topic';
                // }
                if (!el.role_id) {
                    roleAdvBoardErr = 'Please Select Role ';
                }
                // if (!el.manual_weightage) {
                //     manualAdvBoardErr = 'Please Select Manual Weightage ';
                // }

            })
        }
        // Awards honours
        {
            this.state.AwardsHonours.map((el, i) => {
                // if(!el.year){
                //     yearAwardsErr = 'Please Enter Year';
                // }
                if (!el.awards_honours) {
                    awardsnhonourErr = 'Please Enter Awards and Honours';
                }
            })
        }
        // Books Monographs
        {
            this.state.BooksMonographs.map((el, i) => {
                if (!el.year) {
                    yearBooksErr = 'Please Enter Year';
                }
                if (!el.title) {
                    titleErr = 'Please Enter Title';
                }
                if (!el.author) {
                    authorErr = 'Please Enter Author';
                }
                if (!el.publication) {
                    publicationErr = 'Please Enter Publication';
                }
                // if(!el.editors){
                //     editorsErr = 'Please Enter Editors';
                // }
            })
        }
        // Commitees
        {
            this.state.Commitees.map((el, i) => {
                // if(!el.year){
                //     yearCommiteeErr = 'Please Enter Year';
                // } 
                if (!el.committee) {
                    commiteeErr = 'Please Enter Commitee';
                }
                if (!el.role_id) {
                    roleErr = 'Please Select Role';
                }
            })
        }
        // Clinical trial details
        {
            this.state.ClinicalTrialDetails.map((el, i) => {
                if (!el.speciality_id) {
                    specialityClinicalErr = 'Please Select Speciality';
                }
                if (!el.sub_speciality) {
                    subSpecialityClinicalErr = 'Please Select Sub Speciality';
                }
                if (!el.procedure_type) {
                    procedureClinicalErr = 'Please Select Procedure Type';
                }
                if (!el.no_of_study_locations) {
                    noStudyLocationErr = 'Please Select No Of Study Locations';
                }
                if (!el.study_details) {
                    studydetailsErr = 'Please Enter Study Details';
                }
                if (!el.source_link) {
                    sourcelinkClinicalErr = 'Please Enter Source Link';
                }
                if (!el.start_date) {
                    startdateErr = 'Please Enter Start Date';
                }
                if (!el.end_date) {
                    enddateErr = 'Please Enter End Date';
                }
                if (!el.phase) {
                    phaseErr = 'Please Select Phase';
                }
                if (!el.role_id) {
                    roleClinicalErr = 'Please Select Role';
                }
                // if (!el.manual_weightage) {
                //     manualClinicaldErr = 'Please Select Manual Weightage';
                // }
            })
        }
        // Event Details
        {
            this.state.EventsDetails.map((el, i) => {
                if (!el.speciality_id) {
                    specialityEventErr = 'Please Select Speciality';
                }
                if (!el.sub_speciality) {
                    subSpecialityEventErr = 'Please Select Sub Speciality';
                }
                if (!el.procedure_type) {
                    procedureEventErr = 'Please Select Procedure Type';
                }
                if (!el.events) {
                    eventNameErr = 'Please Enter Event Name';
                }
                if (!el.event_type) {
                    eventTypeErr = 'Please Select Event Type';
                }
                if (!el.event_desc) {
                    eventdescriptionErr = 'Please Enter Event Description';
                }
                if (!el.event_topic) {
                    eventtopicErr = 'Please Enter Event Topic';
                }
                if (!el.source_link) {
                    sourcelinkEventErr = 'Please Enter Source Link';
                }
                if (!el.session_type) {
                    sessiontypeErr = 'Please Enter Session Type';
                }
                if (!el.role_id) {
                    roleEventsErr = 'Please Select Role';
                }
                // if (!el.manual_weightage) {
                //     manualAdvEventDetails = 'Please Select Manual Weightage';
                // }
            })
        }
        // Grants
        {
            this.state.Grants.map((el, i) => {
                if (!el.company) {
                    companyErr = 'Please Enter Company/Organization';
                }
                if (!el.title) {
                    titleGrantsErr = 'Please Enter Title';
                }
                if (!el.year) {
                    yearGrantsErr = 'Please Enter Year';
                }
            })
        }
        // Guidelines
        {
            this.state.Guidelines.map((el, i) => {
                if (!el.speciality_id) {
                    specialityGuidelinesErr = 'Please Select Speciality';
                }
                if (!el.sub_speciality) {
                    subSpecialityGuidelinesErr = 'Please Select Sub Speciality';
                }
                if (!el.procedure_type) {
                    procedureGuidelinesErr = 'Please Select Procedure Type';
                }
                if (!el.organization) {
                    companyGuidelinesErr = 'Please Enter Company';
                }
                if (!el.type_of_organization) {
                    typeOfCompanyGuidelinesErr = 'Please Select Type of Organization';
                }
                if (!el.year) {
                    yearGuidelinesErr = 'Please Enter Year';
                }
                if (!el.title) {
                    titleGuidelinesErr = 'Please Enter Title';
                }
                // if (!el.manual_weightage) {
                //     manualGuidelines = 'Please Enter Manual Weightage';
                // }
            })
        }
        // Hospital Affiliations
        {
            this.state.HospitalAffiliations.map((el, i) => {
                if (!el.hospital) {
                    hospitalErr = 'Please Enter Hospital';
                }
                if (!el.country_id) {
                    countryHospitalErr = 'Please Select Country';
                }
            })
        }
        // Interactions
        {
            this.state.Interactions.map((el, i) => {
                if (el.date || el.opinion_leader || el.entered_by || el.product || el.type || el.mode || el.time ||
                    el.activity_or_tool_used || el.comments) {
                    if (!el.date) {
                        dateInteractionErr = 'Please Enter Date';
                    }
                    if (!el.opinion_leader) {
                        opinionleaderErr = 'Please Enter Openion Leader';
                    }
                    if (!el.entered_by) {
                        enteredbyErr = 'Please Enter Entered By';
                    }
                    if (!el.product) {
                        producttopicErr = 'Please Enter Product/Topic';
                    }
                    if (!el.type) {
                        typeInteractionErr = 'Please Enter Type';
                    }
                    if (!el.mode) {
                        modeInteractionErr = 'Please Enter Mode';
                    }
                    if (!el.time) {
                        timeInteractionErr = 'Please Enter Time';
                    }
                }
            })
        }
        // Press Item
        {
            if (this.state.KOLType == 'KOL Community') {
                this.state.PressItem.map((el, i) => {
                    if (!el.title) {
                        titlePressErr = 'Please Enter Title';
                    }
                    if (!el.source_link) {
                        sourcelinkPressErr = 'Please Enter Source Link';
                    }
                    if (!el.date) {
                        datePressErr = 'Please Enter Date';
                    }
                })
            }
        }
        // Presentation
        {
            if (this.state.KOLType == 'KOL Community') {
                this.state.Presentation.map((el, i) => {
                    if (!el.title) {
                        titlePresentationErr = 'Please Enter Title';
                    }
                    if (!el.type) {
                        typePresentationErr = 'Please Enter Type';
                    }
                    if (!el.sponsor) {
                        sponsorPresentationErr = 'Please Enter Sponser';
                    }
                    if (!el.location) {
                        locationPresentationErr = 'Please Enter Location';
                    }
                    if (!el.date) {
                        datePresentationErr = 'Please Enter Date';
                    }
                })
            }
        }
        // Publication Details
        {
            this.state.PublicationDetails.map((el, i) => {
                if (!el.speciality_id) {
                    specialityPublicationErr = 'Please Select Speciality';
                }
                if (!el.sub_speciality) {
                    subSpecialityPublicationErr = 'Please Select Sub Speciality';
                }
                if (!el.procedure_type) {
                    procedurePublicationErr = 'Please Select Procedure Type';
                }
                if (!el.type_of_journal) {
                    typeOfJournelErr = 'Please Enter Type of Journal';
                }
                if (!el.journal) {
                    journalErr = 'Please Enter Journal';
                }
                if (!el.publication_title) {
                    publicationtitleErr = 'Please Enter Publication Title';
                }
                if (!el.date) {
                    datePublicationErr = 'Please Enter Date';
                }
                if (!el.source_link) {
                    sourcelinkPublicationErr = 'Please Enter Source Link';
                }
                if (!el.position) {
                    positionErr = 'Please Enter Position';
                }
                if (!el.co_author) {
                    coauthorPublicationErr = 'Please Enter Co-Author';
                }
                if (!el.key_words) {
                    keywordsErr = 'Please Enter Key Words';
                }
                // if (!el.manual_weightage) {
                //     manualPublication = 'Please Enter Manual Weightage';
                // }
            })
        }
        // Sunshine payment
        {
            this.state.SunshinePayment.map((el, i) => {
                if (el.date || el.type || el.devices || el.location || el.amount || el.payment_type ||
                    el.OL_address || el.speciality_type_id || el.NPI) {
                    if (!el.payment_type) {
                        paymenttypeErr = 'Please Enter Payment';
                    }
                    if (!el.speciality_type_id) {
                        specialitytypeSunshineErr = 'Please Select Speciality';
                    }
                }
            })
        }
        // Procedure Potential
        if (!this.state.PersonalInformation.procedure_potential) {
            procedurepotentialErr = 'Please Select Procedure Potential';
        }


        if (citizenshipErr || areasofinterestErr || biographyErr
            || doctorfullnameErr || salutationErr || firstnameErr || koltypeErr ||
            lastnameErr || qualificationErr || experienceErr || medicalschoolErr || placeofworkErr || designationErr ||
            departmentErr || specialityErr || proceduretypeErr || subspecialitytypeErr || doctorcontactnoErr ||
            doctoremailErr || countryErr || stateErr || address1Err || zipcodeErr ||
            organizationErr || organizationtypeErr || sourcelinkErr || positionErr ||
            institutenameErr || institutetypeErr || sourcelinkEducationErr || degreesErr || specialityEducationErr ||
            yearsattendedfromErr || yearsattendedtoErr ||
            specialityAdvBoardErr ||
            companyAdvBoardErr || typeofCompanyErr || advisoryboardErr || roleAdvBoardErr || manualAdvBoardErr ||
            awardsnhonourErr ||
            yearBooksErr || titleErr || authorErr || publicationErr ||
            commiteeErr || roleErr ||
            specialityClinicalErr || subSpecialityClinicalErr || procedureClinicalErr || noStudyLocationErr ||
            studydetailsErr || sourcelinkClinicalErr || startdateErr || enddateErr || phaseErr || roleClinicalErr || manualClinicaldErr ||
            specialityEventErr || subSpecialityEventErr || procedureEventErr || eventNameErr || eventTypeErr ||
            eventdescriptionErr || eventtopicErr || sourcelinkEventErr || sessiontypeErr || roleEventsErr || manualAdvEventDetails ||
            companyErr || titleGrantsErr || yearGrantsErr ||
            specialityGuidelinesErr || subSpecialityGuidelinesErr || procedureGuidelinesErr || typeOfCompanyGuidelinesErr ||
            yearGuidelinesErr || companyGuidelinesErr || titleGuidelinesErr || manualGuidelines ||
            hospitalErr || countryHospitalErr ||
            dateInteractionErr || opinionleaderErr || enteredbyErr || producttopicErr || typeInteractionErr ||
            modeInteractionErr || timeInteractionErr ||
            titlePressErr || sourcelinkPressErr || datePressErr ||
            titlePresentationErr || typePresentationErr || sponsorPresentationErr || locationPresentationErr ||
            datePresentationErr ||
            specialityPublicationErr || subSpecialityPublicationErr || procedurePublicationErr || typeOfJournelErr ||
            journalErr || publicationtitleErr || datePublicationErr || sourcelinkPublicationErr || positionErr ||
            coauthorPublicationErr || keywordsErr || manualPublication ||
            paymenttypeErr || specialitytypeSunshineErr ||
            procedurepotentialErr) {
            this.setState({
                citizenshipErr, areasofinterestErr,
                biographyErr, doctorfullnameErr, salutationErr, firstnameErr, koltypeErr,
                lastnameErr, qualificationErr, experienceErr, medicalschoolErr, placeofworkErr, designationErr,
                departmentErr, specialityErr, proceduretypeErr, subspecialitytypeErr, doctorcontactnoErr,
                doctoremailErr, countryErr, stateErr, address1Err, zipcodeErr,
                organizationErr, organizationtypeErr, sourcelinkErr, positionErr,
                institutenameErr, institutetypeErr, sourcelinkEducationErr, degreesErr, specialityEducationErr,
                yearsattendedfromErr, yearsattendedtoErr,
                specialityAdvBoardErr,
                companyAdvBoardErr, typeofCompanyErr, advisoryboardErr, roleAdvBoardErr, manualAdvBoardErr,
                awardsnhonourErr,
                yearBooksErr, titleErr, authorErr, publicationErr,
                commiteeErr, roleErr,
                specialityClinicalErr, subSpecialityClinicalErr, procedureClinicalErr, noStudyLocationErr,
                studydetailsErr, sourcelinkClinicalErr, startdateErr, enddateErr, phaseErr, roleClinicalErr, manualClinicaldErr,
                specialityEventErr, subSpecialityEventErr, procedureEventErr, eventNameErr, eventTypeErr,
                eventdescriptionErr, eventtopicErr, sourcelinkEventErr, sessiontypeErr, roleEventsErr, manualAdvEventDetails,
                companyErr, titleGrantsErr, yearGrantsErr,
                specialityGuidelinesErr, subSpecialityGuidelinesErr, procedureGuidelinesErr, typeOfCompanyGuidelinesErr,
                yearGuidelinesErr, companyGuidelinesErr, titleGuidelinesErr, manualGuidelines,
                hospitalErr, countryHospitalErr,
                dateInteractionErr, opinionleaderErr, enteredbyErr, producttopicErr, typeInteractionErr,
                modeInteractionErr, timeInteractionErr,
                titlePressErr, sourcelinkPressErr, datePressErr,
                titlePresentationErr, typePresentationErr, sponsorPresentationErr, locationPresentationErr,
                datePresentationErr,
                specialityPublicationErr, subSpecialityPublicationErr, procedurePublicationErr, typeOfJournelErr,
                journalErr, publicationtitleErr, datePublicationErr, sourcelinkPublicationErr,
                positionErr, coauthorPublicationErr, keywordsErr, manualPublication,
                paymenttypeErr, specialitytypeSunshineErr,
                procedurepotentialErr
            });
            return false;
        }
        return true;
    }

    getPhoto = event => {
        event.preventDefault();
        const filesFormats=["image/jpeg","image/png","image/jpg"];
        const isRightFormat = filesFormats.includes(event.target.files[0].type);
        //alert(event.target.files[0].type);
        console.log(isRightFormat);
        if(isRightFormat)
        {
        
        let reader = new FileReader();
        let file = event.target.files[0];
        // this.setState({file: e.target.files[0],})
        console.log("file my", this.state.profile_photo);
        reader.onloadend = () => {
            this.setState({
                profile_photo: file,
                imagePreviewUrl: reader.result,
                ImageErr:'',
            });
        }
        reader.readAsDataURL(file);

    }
    else
    {
      this.setState({

      ImageErr:'only JPEG , PNG , JPG Allowed',
      });
      //alert('only JPEG , PNG , JPG Allowed');
    }


    }

    // Function for drawer side selection
    PersonalInformationFun = async () => {
        await this.setState({
            BiographyFlag: false,
            ProfessionalMembershipFlag: false,
            EducationFlag: false,
            AdvisoryBoardFlag: false,
            AwardsAndHonoursFlag: false,
            BooksAndMonographFlag: false,
            CommiteesFlag: false,
            ClinicalTrialDetailsFlag: false,
            EventsDetailsFlag: false,
            GrantsFlag: false,
            GuidelinesFlag: false,
            HospitalAffiliationsFlag: false,
            InteractionsFlag: false,
            PressItemFlag: false,
            PresentationsFlag: false,
            PublicationDetailsFlag: false,
            SunshinePaymentFlag: false,
            ProcedurePotentialFlag: false,
            PersonalInformationFlag: true,
        })
    }

    BiographyFun = async () => {
        await this.setState({
            PersonalInformationFlag: false,
            ProfessionalMembershipFlag: false,
            EducationFlag: false,
            AdvisoryBoardFlag: false,
            AwardsAndHonoursFlag: false,
            BooksAndMonographFlag: false,
            CommiteesFlag: false,
            ClinicalTrialDetailsFlag: false,
            EventsDetailsFlag: false,
            GrantsFlag: false,
            GuidelinesFlag: false,
            HospitalAffiliationsFlag: false,
            InteractionsFlag: false,
            PressItemFlag: false,
            PresentationsFlag: false,
            PublicationDetailsFlag: false,
            SunshinePaymentFlag: false,
            ProcedurePotentialFlag: false,
            BiographyFlag: true,
        })
    }

    ProfessionalMembershipFun = async () => {
        await this.setState({
            PersonalInformationFlag: false,
            BiographyFlag: false,
            EducationFlag: false,
            AdvisoryBoardFlag: false,
            AwardsAndHonoursFlag: false,
            BooksAndMonographFlag: false,
            CommiteesFlag: false,
            ClinicalTrialDetailsFlag: false,
            EventsDetailsFlag: false,
            GrantsFlag: false,
            GuidelinesFlag: false,
            HospitalAffiliationsFlag: false,
            InteractionsFlag: false,
            PressItemFlag: false,
            PresentationsFlag: false,
            PublicationDetailsFlag: false,
            SunshinePaymentFlag: false,
            ProcedurePotentialFlag: false,
            ProfessionalMembershipFlag: true,
        })
    }

    EducationFun = async () => {
        await this.setState({
            PersonalInformationFlag: false,
            BiographyFlag: false,
            ProfessionalMembershipFlag: false,
            AdvisoryBoardFlag: false,
            AwardsAndHonoursFlag: false,
            BooksAndMonographFlag: false,
            CommiteesFlag: false,
            ClinicalTrialDetailsFlag: false,
            EventsDetailsFlag: false,
            GrantsFlag: false,
            GuidelinesFlag: false,
            HospitalAffiliationsFlag: false,
            InteractionsFlag: false,
            PressItemFlag: false,
            PresentationsFlag: false,
            PublicationDetailsFlag: false,
            SunshinePaymentFlag: false,
            ProcedurePotentialFlag: false,
            EducationFlag: true,
        })
    }

    AdvisoryBoardFun = async () => {
        await this.setState({
            PersonalInformationFlag: false,
            BiographyFlag: false,
            ProfessionalMembershipFlag: false,
            EducationFlag: false,
            AwardsAndHonoursFlag: false,
            BooksAndMonographFlag: false,
            CommiteesFlag: false,
            ClinicalTrialDetailsFlag: false,
            EventsDetailsFlag: false,
            GrantsFlag: false,
            GuidelinesFlag: false,
            HospitalAffiliationsFlag: false,
            InteractionsFlag: false,
            PressItemFlag: false,
            PresentationsFlag: false,
            PublicationDetailsFlag: false,
            SunshinePaymentFlag: false,
            ProcedurePotentialFlag: false,
            AdvisoryBoardFlag: true,
        })
    }

    AwardsAndHonoursFun = async () => {
        await this.setState({
            PersonalInformationFlag: false,
            BiographyFlag: false,
            ProfessionalMembershipFlag: false,
            EducationFlag: false,
            AdvisoryBoardFlag: false,
            BooksAndMonographFlag: false,
            CommiteesFlag: false,
            ClinicalTrialDetailsFlag: false,
            EventsDetailsFlag: false,
            GrantsFlag: false,
            GuidelinesFlag: false,
            HospitalAffiliationsFlag: false,
            InteractionsFlag: false,
            PressItemFlag: false,
            PresentationsFlag: false,
            PublicationDetailsFlag: false,
            SunshinePaymentFlag: false,
            ProcedurePotentialFlag: false,
            AwardsAndHonoursFlag: true,
        })
    }

    BooksAndMonographFun = async () => {
        await this.setState({
            PersonalInformationFlag: false,
            BiographyFlag: false,
            ProfessionalMembershipFlag: false,
            EducationFlag: false,
            AdvisoryBoardFlag: false,
            AwardsAndHonoursFlag: false,
            CommiteesFlag: false,
            ClinicalTrialDetailsFlag: false,
            EventsDetailsFlag: false,
            GrantsFlag: false,
            GuidelinesFlag: false,
            HospitalAffiliationsFlag: false,
            InteractionsFlag: false,
            PressItemFlag: false,
            PresentationsFlag: false,
            PublicationDetailsFlag: false,
            SunshinePaymentFlag: false,
            ProcedurePotentialFlag: false,
            BooksAndMonographFlag: true,
        })
    }

    CommiteesFun = async () => {
        await this.setState({
            PersonalInformationFlag: false,
            BiographyFlag: false,
            ProfessionalMembershipFlag: false,
            EducationFlag: false,
            AdvisoryBoardFlag: false,
            AwardsAndHonoursFlag: false,
            BooksAndMonographFlag: false,
            ClinicalTrialDetailsFlag: false,
            EventsDetailsFlag: false,
            GrantsFlag: false,
            GuidelinesFlag: false,
            HospitalAffiliationsFlag: false,
            InteractionsFlag: false,
            PressItemFlag: false,
            PresentationsFlag: false,
            PublicationDetailsFlag: false,
            SunshinePaymentFlag: false,
            ProcedurePotentialFlag: false,
            CommiteesFlag: true,
        })
    }

    ClinicalTrialDetailsFun = async () => {
        await this.setState({
            PersonalInformationFlag: false,
            BiographyFlag: false,
            ProfessionalMembershipFlag: false,
            EducationFlag: false,
            AdvisoryBoardFlag: false,
            AwardsAndHonoursFlag: false,
            BooksAndMonographFlag: false,
            CommiteesFlag: false,
            EventsDetailsFlag: false,
            GrantsFlag: false,
            GuidelinesFlag: false,
            HospitalAffiliationsFlag: false,
            InteractionsFlag: false,
            PressItemFlag: false,
            PresentationsFlag: false,
            PublicationDetailsFlag: false,
            SunshinePaymentFlag: false,
            ProcedurePotentialFlag: false,
            ClinicalTrialDetailsFlag: true,
        })
    }

    EventsDetailsFun = async () => {
        await this.setState({
            PersonalInformationFlag: false,
            BiographyFlag: false,
            ProfessionalMembershipFlag: false,
            EducationFlag: false,
            AdvisoryBoardFlag: false,
            AwardsAndHonoursFlag: false,
            BooksAndMonographFlag: false,
            CommiteesFlag: false,
            ClinicalTrialDetailsFlag: false,
            GrantsFlag: false,
            GuidelinesFlag: false,
            HospitalAffiliationsFlag: false,
            InteractionsFlag: false,
            PressItemFlag: false,
            PresentationsFlag: false,
            PublicationDetailsFlag: false,
            SunshinePaymentFlag: false,
            ProcedurePotentialFlag: false,
            EventsDetailsFlag: true,
        })
    }

    GrantsFun = async () => {
        await this.setState({
            PersonalInformationFlag: false,
            BiographyFlag: false,
            ProfessionalMembershipFlag: false,
            EducationFlag: false,
            AdvisoryBoardFlag: false,
            AwardsAndHonoursFlag: false,
            BooksAndMonographFlag: false,
            CommiteesFlag: false,
            ClinicalTrialDetailsFlag: false,
            EventsDetailsFlag: false,
            GuidelinesFlag: false,
            HospitalAffiliationsFlag: false,
            InteractionsFlag: false,
            PressItemFlag: false,
            PresentationsFlag: false,
            PublicationDetailsFlag: false,
            SunshinePaymentFlag: false,
            ProcedurePotentialFlag: false,
            GrantsFlag: true,
        })
    }

    GuidelinesFun = async () => {
        await this.setState({
            PersonalInformationFlag: false,
            BiographyFlag: false,
            ProfessionalMembershipFlag: false,
            EducationFlag: false,
            AdvisoryBoardFlag: false,
            AwardsAndHonoursFlag: false,
            BooksAndMonographFlag: false,
            CommiteesFlag: false,
            ClinicalTrialDetailsFlag: false,
            EventsDetailsFlag: false,
            GrantsFlag: false,
            HospitalAffiliationsFlag: false,
            InteractionsFlag: false,
            PressItemFlag: false,
            PresentationsFlag: false,
            PublicationDetailsFlag: false,
            SunshinePaymentFlag: false,
            ProcedurePotentialFlag: false,
            GuidelinesFlag: true,
        })
    }

    HospitalAffiliationsFun = async () => {
        await this.setState({
            PersonalInformationFlag: false,
            BiographyFlag: false,
            ProfessionalMembershipFlag: false,
            EducationFlag: false,
            AdvisoryBoardFlag: false,
            AwardsAndHonoursFlag: false,
            BooksAndMonographFlag: false,
            CommiteesFlag: false,
            ClinicalTrialDetailsFlag: false,
            EventsDetailsFlag: false,
            GrantsFlag: false,
            GuidelinesFlag: false,
            InteractionsFlag: false,
            PressItemFlag: false,
            PresentationsFlag: false,
            PublicationDetailsFlag: false,
            SunshinePaymentFlag: false,
            ProcedurePotentialFlag: false,
            HospitalAffiliationsFlag: true,
        })
    }

    InteractionsFun = async () => {
        await this.setState({
            PersonalInformationFlag: false,
            BiographyFlag: false,
            ProfessionalMembershipFlag: false,
            EducationFlag: false,
            AdvisoryBoardFlag: false,
            AwardsAndHonoursFlag: false,
            BooksAndMonographFlag: false,
            CommiteesFlag: false,
            ClinicalTrialDetailsFlag: false,
            EventsDetailsFlag: false,
            GrantsFlag: false,
            GuidelinesFlag: false,
            HospitalAffiliationsFlag: false,
            PressItemFlag: false,
            PresentationsFlag: false,
            PublicationDetailsFlag: false,
            SunshinePaymentFlag: false,
            ProcedurePotentialFlag: false,
            InteractionsFlag: true,
        })
    }

    PressItemFun = async () => {
        await this.setState({
            PersonalInformationFlag: false,
            BiographyFlag: false,
            ProfessionalMembershipFlag: false,
            EducationFlag: false,
            AdvisoryBoardFlag: false,
            AwardsAndHonoursFlag: false,
            BooksAndMonographFlag: false,
            CommiteesFlag: false,
            ClinicalTrialDetailsFlag: false,
            EventsDetailsFlag: false,
            GrantsFlag: false,
            GuidelinesFlag: false,
            HospitalAffiliationsFlag: false,
            InteractionsFlag: false,
            PresentationsFlag: false,
            PublicationDetailsFlag: false,
            SunshinePaymentFlag: false,
            ProcedurePotentialFlag: false,
            PressItemFlag: true,
        })
    }

    PresentationsFun = async () => {
        await this.setState({
            PersonalInformationFlag: false,
            BiographyFlag: false,
            ProfessionalMembershipFlag: false,
            EducationFlag: false,
            AdvisoryBoardFlag: false,
            AwardsAndHonoursFlag: false,
            BooksAndMonographFlag: false,
            CommiteesFlag: false,
            ClinicalTrialDetailsFlag: false,
            EventsDetailsFlag: false,
            GrantsFlag: false,
            GuidelinesFlag: false,
            HospitalAffiliationsFlag: false,
            InteractionsFlag: false,
            PressItemFlag: false,
            PublicationDetailsFlag: false,
            SunshinePaymentFlag: false,
            ProcedurePotentialFlag: false,
            PresentationsFlag: true,
        })
    }

    PublicationDetailsFun = async () => {
        await this.setState({
            PersonalInformationFlag: false,
            BiographyFlag: false,
            ProfessionalMembershipFlag: false,
            EducationFlag: false,
            AdvisoryBoardFlag: false,
            AwardsAndHonoursFlag: false,
            BooksAndMonographFlag: false,
            CommiteesFlag: false,
            ClinicalTrialDetailsFlag: false,
            EventsDetailsFlag: false,
            GrantsFlag: false,
            GuidelinesFlag: false,
            HospitalAffiliationsFlag: false,
            InteractionsFlag: false,
            PressItemFlag: false,
            PresentationsFlag: false,
            SunshinePaymentFlag: false,
            ProcedurePotentialFlag: false,
            PublicationDetailsFlag: true,
        })
    }

    SunshinePaymentFun = async () => {
        await this.setState({
            PersonalInformationFlag: false,
            BiographyFlag: false,
            ProfessionalMembershipFlag: false,
            EducationFlag: false,
            AdvisoryBoardFlag: false,
            AwardsAndHonoursFlag: false,
            BooksAndMonographFlag: false,
            CommiteesFlag: false,
            ClinicalTrialDetailsFlag: false,
            EventsDetailsFlag: false,
            GrantsFlag: false,
            GuidelinesFlag: false,
            HospitalAffiliationsFlag: false,
            InteractionsFlag: false,
            PressItemFlag: false,
            PresentationsFlag: false,
            PublicationDetailsFlag: false,
            ProcedurePotentialFlag: false,
            SunshinePaymentFlag: true,
        })
    }

    ProcedurePotentialFun = async () => {
        await this.setState({
            PersonalInformationFlag: false,
            BiographyFlag: false,
            ProfessionalMembershipFlag: false,
            EducationFlag: false,
            AdvisoryBoardFlag: false,
            AwardsAndHonoursFlag: false,
            BooksAndMonographFlag: false,
            CommiteesFlag: false,
            ClinicalTrialDetailsFlag: false,
            EventsDetailsFlag: false,
            GrantsFlag: false,
            GuidelinesFlag: false,
            HospitalAffiliationsFlag: false,
            InteractionsFlag: false,
            PressItemFlag: false,
            PresentationsFlag: false,
            PublicationDetailsFlag: false,
            SunshinePaymentFlag: false,
            ProcedurePotentialFlag: true,
        })
    }


    // Functions for add source variable toggle
    SalutaionAddSourceFun = async () => {
        await this.setState({
            SalutationAddSource: !this.state.SalutationAddSource,
        })
    }

    DFullNameAddSourceFun = async () => {
        await this.setState({
            DFullNameAddSource: !this.state.DFullNameAddSource,
        })
    }

    FirstNameAddSourceFun = async () => {
        await this.setState({
            FirstNameAddSource: !this.state.FirstNameAddSource,
        })
    }

    MiddleNameAddSourceFun = async () => {
        await this.setState({
            MiddleNameAddSource: !this.state.MiddleNameAddSource,
        })
    }

    LastNameAddSourceFun = async () => {
        await this.setState({
            LastNameAddSource: !this.state.LastNameAddSource,
        })
    }

    QualificationAddSourceFun = async () => {
        await this.setState({
            QualificationAddSource: !this.state.QualificationAddSource,
        })
    }

    ExperienceAddSourceFun = async () => {
        await this.setState({
            ExperienceAddSource: !this.state.ExperienceAddSource,
        })
    }

    MedicalSchoolsAddSourceFun = async () => {
        await this.setState({
            MedicalSchoolsAddSource: !this.state.MedicalSchoolsAddSource,
        })
    }

    MedicalRegistrationNoAddSourceFun = async () => {
        await this.setState({
            MedicalRegistrationNoAddSource: !this.state.MedicalRegistrationNoAddSource,
        })
    }

    NPINoAddSourceFun = async () => {
        await this.setState({
            NPINoAddSource: !this.state.NPINoAddSource,
        })
    }

    PlaceOfWorkAddSourceFun = async () => {
        await this.setState({
            PlaceOfWorkAddSource: !this.state.PlaceOfWorkAddSource,
        })
    }

    DesignationAddSourceFun = async () => {
        await this.setState({
            DesignationAddSource: !this.state.DesignationAddSource,
        })
    }

    BrandAddSourceFun = async () => {
        await this.setState({
            BrandAddSource: !this.state.BrandAddSource,
        })
    }

    DepartmentAddSourceFun = async () => {
        await this.setState({
            DepartmentAddSource: !this.state.DepartmentAddSource,
        })
    }

    SpecialityAddSourceFun = async () => {
        await this.setState({
            SpecialityAddSource: !this.state.SpecialityAddSource,
        })
    }

    ProcedureTypeAddSourceFun = async () => {
        await this.setState({
            ProcedureTypeAddSource: !this.state.ProcedureTypeAddSource,
        })
    }

    SubSpecialityTypeAddSourceFun = async () => {
        await this.setState({
            SubSpecialityTypeAddSource: !this.state.SubSpecialityTypeAddSource,
        })
    }

    DoctorContactNoAddSourceFunc = async () => {
        await this.setState({
            DoctorContactNoAddSource: !this.state.DoctorContactNoAddSource,
        })
    }

    DoctorEmailAddSourceFun = async () => {
        await this.setState({
            DoctorEmailAddSource: !this.state.DoctorEmailAddSource,
        })
    }


    FaxAddSourceFun = async () => {
        await this.setState({
            FaxAddSource: !this.state.FaxAddSource,
        })
    }

    LinkedInProfileAddSourceFun = async () => {
        await this.setState({
            LinkedInProfileAddSource: !this.state.LinkedInProfileAddSource,
        })
    }

    TwitterHandleAddSourceFun = async () => {
        await this.setState({
            TwitterHandleAddSource: !this.state.TwitterHandleAddSource,
        })
    }

    AssistanceContactNoAddSourceFunc = async () => {
        await this.setState({
            AssistanceContactNoAddSource: !this.state.AssistanceContactNoAddSource,
        })
    }

    AssistanceEmailAddSourceFun = async () => {
        await this.setState({
            AssistanceEmailAddSource: !this.state.AssistanceEmailAddSource,
        })
    }

    WebsiteAddSourceFun = async () => {
        await this.setState({
            WebsiteAddSource: !this.state.WebsiteAddSource,
        })
    }

    CountryAddSourceFun = async () => {
        await this.setState({
            CountryAddSource: !this.state.CountryAddSource,
        })
    }

    StateAddSourceFun = async () => {
        await this.setState({
            StateAddSource: !this.state.StateAddSource,
        })
    }

    Address1AddSourceFun = async () => {
        await this.setState({
            Address1AddSource: !this.state.Address1AddSource,
        })
    }

    Address2AddSourceFun = async () => {
        await this.setState({
            Address2AddSource: !this.state.Address2AddSource,
        })
    }

    CitizenshipAddSourceFun = async () => {
        await this.setState({
            CitizenshipAddSource: !this.state.CitizenshipAddSource,
        })
    }

    LicensedRegionsAddSourceFun = async () => {
        await this.setState({
            LicensedRegionsAddSource: !this.state.LicensedRegionsAddSource,
        })
    }

    AreasofInterestAddSourceFun = async () => {
        await this.setState({
            AreasofInterestAddSource: !this.state.AreasofInterestAddSource,
        })
    }

    LanguageSpokenAddSourceFun = async () => {
        await this.setState({
            LanguageSpokenAddSource: !this.state.LanguageSpokenAddSource,
        })
    }

    WorkPhoneAddSourceFun = async () => {
        await this.setState({
            WorkPhoneAddSource: !this.state.WorkPhoneAddSource,
        })
    }

    WorkEmailAddSourceFun = async () => {
        await this.setState({
            WorkEmailAddSource: !this.state.WorkEmailAddSource,
        })
    }

    LinkedInFollowersAddSourceFun = async () => {
        await this.setState({
            LinkedInFollowersAddSource: !this.state.LinkedInFollowersAddSource,
        })
    }

    TwitterFollowersAddSourceFun = async () => {
        await this.setState({
            TwitterFollowersAddSource: !this.state.TwitterFollowersAddSource,
        })
    }

    BiographyFieldAddSourceFun = async () => {
        await this.setState({
            BiographyFieldAddSource: !this.state.BiographyFieldAddSource,
        })
    }

    // Professional membership
    OrganizationAddSourceFun = async () => {
        await this.setState({
            OrganizationAddSource: !this.state.OrganizationAddSource,
        })
    }

    OrganizationTypeAddSourceFun = async () => {
        await this.setState({
            OrganizationTypeAddSource: !this.state.OrganizationTypeAddSource,
        })
    }

    SourceLinkAddSourceFun = async () => {
        await this.setState({
            SourceLinkAddSource: !this.state.SourceLinkAddSource,
        })
    }

    LocationAddSourceFun = async () => {
        await this.setState({
            LocationAddSource: !this.state.LocationAddSource,
        })
    }

    PositionAddSourceFun = async () => {
        await this.setState({
            PositionAddSource: !this.state.PositionAddSource,
        })
    }

    TenureAddSourceFun = async () => {
        await this.setState({
            TenureAddSource: !this.state.TenureAddSource,
        })
    }

    OrganizationWebsiteAddSourceFun = async () => {
        await this.setState({
            OrganizationWebsiteAddSource: !this.state.OrganizationWebsiteAddSource,
        })
    }

    ProfileCountAddSourceFun = async () => {
        await this.setState({
            ProfileCountAddSource: !this.state.ProfileCountAddSource,
        })
    }

    // Education
    InstituteNameAddSourceFun = async () => {
        await this.setState({
            InstituteNameAddSource: !this.state.InstituteNameAddSource,
        })
    }

    InstituteTypeAddSourceFun = async () => {
        await this.setState({
            InstituteTypeAddSource: !this.state.InstituteTypeAddSource,
        })
    }

    SourceLinkEducationAddSourceFun = async () => {
        await this.setState({
            SourceLinkEducationAddSource: !this.state.SourceLinkEducationAddSource,
        })
    }

    DegreesAddSourceFun = async () => {
        await this.setState({
            DegreesAddSource: !this.state.DegreesAddSource,
        })
    }

    SpecialityEducationAddSourceFun = async () => {
        await this.setState({
            SpecialityEducationAddSource: !this.state.SpecialityEducationAddSource,
        })
    }

    YearsAttendedAddSourceFun = async () => {
        await this.setState({
            YearsAttendedAddSource: !this.state.YearsAttendedAddSource,
        })
    }

    // Advisory board
    SpecialityAdvBoardAddSourceFun = async () => {
        await this.setState({
            SpecialityAdvBoardAddSource: !this.state.SpecialityAdvBoardAddSource,
        })
    }

    SubSpecialityAdvBoardAddSourceFun = async () => {
        await this.setState({
            SubSpecialityAdvBoardAddSource: !this.state.SubSpecialityAdvBoardAddSource,
        })
    }

    ProcedureAdvBoardAddSourceFun = async () => {
        await this.setState({
            ProcedureAdvBoardAddSource: !this.state.ProcedureAdvBoardAddSource,
        })
    }

    YearAddSourceFun = async () => {
        await this.setState({
            YearAddSource: !this.state.YearAddSource,
        })
    }

    CompanyOrgAddSourceFun = async () => {
        await this.setState({
            CompanyOrgAddSource: !this.state.CompanyOrgAddSource,
        })
    }

    TypeOfCompanyOrgAddSourceFun = async () => {
        await this.setState({
            TypeOfCompanyOrgAddSource: !this.state.TypeOfCompanyOrgAddSource,
        })
    }

    AdvisoryBoardSourceFun = async () => {
        await this.setState({
            AdvisoryBoardSource: !this.state.AdvisoryBoardSource,
        })
    }

    TopicSourceFun = async () => {
        await this.setState({
            TopicSource: !this.state.TopicSource,
        })
    }

    RoleAdvBoardAddSourceFun = async () => {
        await this.setState({
            RoleAdvBoardAddSource: !this.state.RoleAdvBoardAddSource,
        })
    }

    // Awards and honours
    YearAwardSourceFun = async () => {
        await this.setState({
            YearAwardSource: !this.state.YearAwardSource,
        })
    }

    AwardsHonoursSourceFun = async () => {
        await this.setState({
            AwardsHonoursSource: !this.state.AwardsHonoursSource,
        })
    }

    TypesSourceFun = async () => {
        await this.setState({
            TypesSource: !this.state.TypesSource,
        })
    }

    // Books monographs
    YearBooksSourceFun = async () => {
        await this.setState({
            YearBooksSource: !this.state.YearBooksSource,
        })
    }

    titleSourceFun = async () => {
        await this.setState({
            titleSource: !this.state.titleSource,
        })
    }

    AuthorSourceFun = async () => {
        await this.setState({
            AuthorSource: !this.state.AuthorSource,
        })
    }

    PublicationSourceFun = async () => {
        await this.setState({
            PublicationSource: !this.state.PublicationSource,
        })
    }

    EditionSourceFun = async () => {
        await this.setState({
            EditionSource: !this.state.EditionSource,
        })
    }

    PagesSourceFun = async () => {
        await this.setState({
            PagesSource: !this.state.PagesSource,
        })
    }

    EditorsSourceFun = async () => {
        await this.setState({
            EditorsSource: !this.state.EditorsSource,
        })
    }

    // Commitees
    YearCommiteesSourceFun = async () => {
        await this.setState({
            YearCommiteesSource: !this.state.YearCommiteesSource,
        })
    }

    CommiteeSourceFun = async () => {
        await this.setState({
            CommiteeSource: !this.state.CommiteeSource,
        })
    }

    RoleSourceFun = async () => {
        await this.setState({
            RoleSource: !this.state.RoleSource,
        })
    }

    // Clinical Trial Details
    SpecialityClinicalSourceFun = async () => {
        await this.setState({
            SpecialityClinicalSource: !this.state.SpecialityClinicalSource,
        })
    }

    SubSpecialityClinicalSourceFun = async () => {
        await this.setState({
            SubSpecialityClinicalSource: !this.state.SubSpecialityClinicalSource,
        })
    }

    ProcedureClinicalSourceFun = async () => {
        await this.setState({
            ProcedureClinicalSource: !this.state.ProcedureClinicalSource,
        })
    }

    StudyDetailsSourceFun = async () => {
        await this.setState({
            StudyDetailsSource: !this.state.StudyDetailsSource,
        })
    }

    SourceLinkClinicalTrialSourceFun = async () => {
        await this.setState({
            SourceLinkClinicalTrialSource: !this.state.SourceLinkClinicalTrialSource,
        })
    }

    StartDateClinicalTrialSourceFun = async () => {
        await this.setState({
            StartDateClinicalTrialSource: !this.state.StartDateClinicalTrialSource,
        })
    }

    EndDateClinicalTrialSourceFun = async () => {
        await this.setState({
            EndDateClinicalTrialSource: !this.state.EndDateClinicalTrialSource,
        })
    }

    TypeSourceFun = async () => {
        await this.setState({
            TypeSource: !this.state.TypeSource,
        })
    }

    PhaseSourceFun = async () => {
        await this.setState({
            PhaseSource: !this.state.PhaseSource,
        })
    }

    RoleClinicalTrialSourceFun = async () => {
        await this.setState({
            RoleClinicalTrialSource: !this.state.RoleClinicalTrialSource,
        })
    }

    NoStudyLocationClinicalTrialSourceFun = async () => {
        await this.setState({
            NoStudyLocationClinicalTrialSource: !this.state.NoStudyLocationClinicalTrialSource,
        })
    }

    conditionsSourceFun = async () => {
        await this.setState({
            conditionsSource: !this.state.conditionsSource,
        })
    }

    TreatmentInterventionSourceFun = async () => {
        await this.setState({
            TreatmentInterventionSource: !this.state.TreatmentInterventionSource,
        })
    }

    PrimaryOutcomeSourceFun = async () => {
        await this.setState({
            PrimaryOutcomeSource: !this.state.PrimaryOutcomeSource,
        })
    }

    SecondaryOutcomesSourceFun = async () => {
        await this.setState({
            SecondaryOutcomesSource: !this.state.SecondaryOutcomesSource,
        })
    }

    SponsorSourceFun = async () => {
        await this.setState({
            SponsorSource: !this.state.SponsorSource,
        })
    }

    CollaboratorsSourceFun = async () => {
        await this.setState({
            CollaboratorsSource: !this.state.CollaboratorsSource,
        })
    }

    // Event Details
    SpecialityEventSourceFun = async () => {
        await this.setState({
            SpecialityEventSource: !this.state.SpecialityEventSource,
        })
    }
    SubSpecialityEventSourceFun = async () => {
        await this.setState({
            SubSpecialityEventSource: !this.state.SubSpecialityEventSource,
        })
    }
    ProceduereEventSourceFun = async () => {
        await this.setState({
            ProceduereEventSource: !this.state.ProceduereEventSource,
        })
    }
    EventNameSourceFun = async () => {
        await this.setState({
            EventNameSource: !this.state.EventNameSource,
        })
    }
    EventTypeEventsSourceFun = async () => {
        await this.setState({
            EventTypeEventsSource: !this.state.EventTypeEventsSource,
        })
    }
    EventDescriptionSourceFun = async () => {
        await this.setState({
            EventDescriptionSource: !this.state.EventDescriptionSource,
        })
    }
    EventTopicSourceFun = async () => {
        await this.setState({
            EventTopicSource: !this.state.EventTopicSource,
        })
    }

    SourcelinEventkSourceFun = async () => {
        await this.setState({
            SourcelinEventkSource: !this.state.SourcelinEventkSource,
        })
    }

    ConditionsEventsSourceFun = async () => {
        await this.setState({
            ConditionsEventsSource: !this.state.ConditionsEventsSource,
        })
    }

    InterventionEventsSourceFun = async () => {
        await this.setState({
            InterventionEventsSource: !this.state.InterventionEventsSource,
        })
    }

    DateEventsSourceFun = async () => {
        await this.setState({
            DateEventsSource: !this.state.DateEventsSource,
        })
    }

    SessionTypeSourceFun = async () => {
        await this.setState({
            SessionTypeSource: !this.state.SessionTypeSource,
        })
    }

    RoleEventsSourceFun = async () => {
        await this.setState({
            RoleEventsSource: !this.state.RoleEventsSource,
        })
    }

    EventSponsorSourceFun = async () => {
        await this.setState({
            EventSponsorSource: !this.state.EventSponsorSource,
        })
    }

    TotalEventAttendeesSourceFun = async () => {
        await this.setState({
            TotalEventAttendeesSource: !this.state.TotalEventAttendeesSource,
        })
    }

    CountryEventsSourceFun = async () => {
        await this.setState({
            CountryEventsSource: !this.state.CountryEventsSource,
        })
    }

    // Grants
    CompanySourceFun = async () => {
        await this.setState({
            CompanySource: !this.state.CompanySource,
        })
    }

    TitleGrantsSourceFun = async () => {
        await this.setState({
            TitleGrantsSource: !this.state.TitleGrantsSource,
        })
    }

    YearGrantsSourceFun = async () => {
        await this.setState({
            YearGrantsSource: !this.state.YearGrantsSource,
        })
    }

    AmountGrantsSourceFun = async () => {
        await this.setState({
            AmountGrantsSource: !this.state.AmountGrantsSource,
        })
    }

    CoInvestigatorSourceFun = async () => {
        await this.setState({
            CoInvestigatorSource: !this.state.CoInvestigatorSource,
        })
    }

    // Guidelines
    SpecialityGuidlinesSourceFun = async () => {
        await this.setState({
            SpecialityGuidlinesSource: !this.state.SpecialityGuidlinesSource,
        })
    }

    SubSpecialityGuidlinesSourceFun = async () => {
        await this.setState({
            SubSpecialityGuidlinesSource: !this.state.SubSpecialityGuidlinesSource,
        })
    }

    ProcedureGuidlinesSourceFun = async () => {
        await this.setState({
            ProcedureGuidlinesSource: !this.state.ProcedureGuidlinesSource,
        })
    }

    YearGuidlinesSourceFun = async () => {
        await this.setState({
            YearGuidlinesSource: !this.state.YearGuidlinesSource,
        })
    }

    OrganizationGuidlinesSourceFun = async () => {
        await this.setState({
            OrganizationGuidlinesSource: !this.state.OrganizationGuidlinesSource,
        })
    }

    TypeOfOrganizationGuidlinesSourceFun = async () => {
        await this.setState({
            TypeOfOrganizationGuidlinesSource: !this.state.TypeOfOrganizationGuidlinesSource,
        })
    }

    CompanyOrganizationGuidlinesSourceFun = async () => {
        await this.setState({
            CompanyOrganizationGuidlinesSource: !this.state.CompanyOrganizationGuidlinesSource,
        })
    }

    TitleGuidlinesSourceFun = async () => {
        await this.setState({
            TitleGuidlinesSource: !this.state.TitleGuidlinesSource,
        })
    }

    // Hospital Affiliations
    HospitalSourceFun = async () => {
        await this.setState({
            HospitalSource: !this.state.HospitalSource,
        })
    }

    CountryHospitalAffSourceFun = async () => {
        await this.setState({
            CountryHospitalAffSource: !this.state.CountryHospitalAffSource,
        })
    }

    StateHospitalAffSourceFun = async () => {
        await this.setState({
            StateHospitalAffSource: !this.state.StateHospitalAffSource,
        })
    }

    CityHospitalAffSourceFun = async () => {
        await this.setState({
            CityHospitalAffSource: !this.state.CityHospitalAffSource,
        })
    }

    // Interactions
    DateInteractionsSourceFun = async () => {
        await this.setState({
            DateInteractionsSource: !this.state.DateInteractionsSource,
        })
    }

    OpinionLeaderSourceFun = async () => {
        await this.setState({
            OpinionLeaderSource: !this.state.OpinionLeaderSource,
        })
    }

    EnteredBySourceFun = async () => {
        await this.setState({
            EnteredBySource: !this.state.EnteredBySource,
        })
    }

    ProductTopicSourceFun = async () => {
        await this.setState({
            ProductTopicSource: !this.state.ProductTopicSource,
        })
    }

    TypeInteractionSourceFun = async () => {
        await this.setState({
            TypeInteractionSource: !this.state.TypeInteractionSource,
        })
    }

    ModeInteractionSourceFun = async () => {
        await this.setState({
            ModeInteractionSource: !this.state.ModeInteractionSource,
        })
    }

    TimeInteractionSourceFun = async () => {
        await this.setState({
            TimeInteractionSource: !this.state.TimeInteractionSource,
        })
    }

    ActivityToolUsedSourceFun = async () => {
        await this.setState({
            ActivityToolUsedSource: !this.state.ActivityToolUsedSource,
        })
    }

    CommentsKeywordsSourceFun = async () => {
        await this.setState({
            CommentsKeywordsSource: !this.state.CommentsKeywordsSource,
        })
    }

    // Press Item
    TitlePressItemSourceFun = async () => {
        await this.setState({
            TitlePressItemSource: !this.state.TitlePressItemSource,
        })
    }

    SourceLinkPressItemSourceFun = async () => {
        await this.setState({
            SourceLinkPressItemSource: !this.state.SourceLinkPressItemSource,
        })
    }

    DatePressItemSourceFun = async () => {
        await this.setState({
            DatePressItemSource: !this.state.DatePressItemSource,
        })
    }

    // Presentation
    TitlePresentationSourceFun = async () => {
        await this.setState({
            TitlePresentationSource: !this.state.TitlePresentationSource,
        })
    }

    TypePresentationSourceFun = async () => {
        await this.setState({
            TypePresentationSource: !this.state.TypePresentationSource,
        })
    }

    SponsorPresentationSourceFun = async () => {
        await this.setState({
            SponsorPresentationSource: !this.state.SponsorPresentationSource,
        })
    }

    LocationPresentationSourceFun = async () => {
        await this.setState({
            LocationPresentationSource: !this.state.LocationPresentationSource,
        })
    }

    DatePresentationSourceFun = async () => {
        await this.setState({
            DatePresentationSource: !this.state.DatePresentationSource,
        })
    }

    // Publication Details
    SpecialityPublicationSourceFun = async () => {
        await this.setState({
            SpecialityPublicationSource: !this.state.SpecialityPublicationSource,
        })
    }

    SubSpecialityPublicationSourceFun = async () => {
        await this.setState({
            SubSpecialityPublicationSource: !this.state.SubSpecialityPublicationSource,
        })
    }

    ProcedurePublicationSourceFun = async () => {
        await this.setState({
            ProcedurePublicationSource: !this.state.ProcedurePublicationSource,
        })
    }

    JournalSourceFun = async () => {
        await this.setState({
            JournalSource: !this.state.JournalSource,
        })
    }

    TypeOfJournelPublicationSourceFun = async () => {
        await this.setState({
            TypeOfJournelPublicationSource: !this.state.TypeOfJournelPublicationSource,
        })
    }

    PublicationTitleSourceFun = async () => {
        await this.setState({
            PublicationTitleSource: !this.state.PublicationTitleSource,
        })
    }

    DatePublicationSourceFun = async () => {
        await this.setState({
            DatePublicationSource: !this.state.DatePublicationSource,
        })
    }

    SourceLinkPublicationSourceFun = async () => {
        await this.setState({
            SourceLinkPublicationSource: !this.state.SourceLinkPublicationSource,
        })
    }

    PositionPublicationSourceFun = async () => {
        await this.setState({
            PositionPublicationSource: !this.state.PositionPublicationSource,
        })
    }

    CoAuthorPublicationSourceFun = async () => {
        await this.setState({
            CoAuthorPublicationSource: !this.state.CoAuthorPublicationSource,
        })
    }

    KeyWordsPublicationSourceFun = async () => {
        await this.setState({
            KeyWordsPublicationSource: !this.state.KeyWordsPublicationSource,
        })
    }

    // Sunshine Payment
    DateSunshineSourceFun = async () => {
        await this.setState({
            DateSunshineSource: !this.state.DateSunshineSource,
        })
    }

    TypeSunshineSourceFun = async () => {
        await this.setState({
            TypeSunshineSource: !this.state.TypeSunshineSource,
        })
    }

    DrugDevicesSourceFun = async () => {
        await this.setState({
            DrugDevicesSource: !this.state.DrugDevicesSource,
        })
    }

    LocationSunshineSourceFun = async () => {
        await this.setState({
            LocationSunshineSource: !this.state.LocationSunshineSource,
        })
    }

    AmountSunshineSourceFun = async () => {
        await this.setState({
            AmountSunshineSource: !this.state.AmountSunshineSource,
        })
    }

    PaymentTypeSunshineSourceFun = async () => {
        await this.setState({
            PaymentTypeSunshineSource: !this.state.PaymentTypeSunshineSource,
        })
    }

    OLAddressSunshineSourceFun = async () => {
        await this.setState({
            OLAddressSunshineSource: !this.state.OLAddressSunshineSource,
        })
    }

    SpecialitySunshineSourceFun = async () => {
        await this.setState({
            SpecialitySunshineSource: !this.state.SpecialitySunshineSource,
        })
    }

    NPISunshineSourceFun = async () => {
        await this.setState({
            NPISunshineSource: !this.state.NPISunshineSource,
        })
    }

    // Procedure Potential
    ProcedurePotentialSourceFun = async () => {
        await this.setState({
            ProcedurePotentialSource: !this.state.ProcedurePotentialSource,
        })
    }

    //Biography upload resume function
    onUploadResume = async (event) => {
        
        const filesFormats= [".doc", ".docx", "application/pdf"];
        console.log(event.target.file);
    
        const isRightFormat=filesFormats.includes(event.target.files[0].type);
        console.log(isRightFormat);
       // alert(event.target.files[0].type);
      if(isRightFormat)
     {
        
        console.log("event.target.files[0] resume", event.target.files[0])

        await this.setState({
            BiographyResume: event.target.files[0],
            BiographyResumeFileName: event.target.files[0].name,
            ResumeErr:'',
        });
        console.log("event of resume BiographyResumeFileName", this.state.BiographyResume)

    }
    else
    {
      this.setState( {
  
        ResumeErr:"only .doc,.docx,.pdf file allowed",
      });
      
    }
  

    }

    // Personal Info Upload Contract
    onUploadContract = async (event) => {
        console.log('Contract file event', event)
        var contract_file2 = event.target.files[0];
        console.log("contract_file2", contract_file2);
        // let { PersonalInformation } = this.state;
        // PersonalInformation.contract_file = event.target.files[0];
        await this.setState({
            // PersonalInformation,
            contract_file: event.target.files[0],
            ContractFileName: event.target.files[0].name
        });
        // console.log("contract file ------",this.state.PersonalInformation.contract_file)
    }

    // To get procedure 
    getProcedureList = () => {
        console.log("in Procedure list");
        console.log("speciality_type_id", this.state.PersonalInformation.specialty_id);


        const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
        let formData = new FormData();
        formData.append('speciality_type_id', this.state.PersonalInformation.specialty_id);
        formData.append('search_type', "Procedure");

        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var proceduredata = response.data;
                console.log("proceduredata", JSON.stringify(proceduredata));
                this.setState({
                    ProcedureData: response.data.data,
                })
                console.log("ProcedureData", this.state.ProcedureData)
            })
            .catch(error => {
                console.log(error);
            });
    }

    // To get sub speciality list 
    getSubSpecialityList = () => {
        console.log("in sub speciality list");
        const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
        let formData = new FormData();
        formData.append('speciality_type_id', this.state.PersonalInformation.specialty_id);
        formData.append('search_type', "Sub Speciality");

        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var SubSpecialitydata = response.data;
                console.log("SubSpecialitydata", JSON.stringify(SubSpecialitydata));
                this.setState({
                    SubSpecialitydata: response.data.data,
                })
                console.log("SubSpecialitydata", this.state.SubSpecialitydata)
            })
            .catch(error => {
                console.log(error);
            });
    }

    //  To get procedure  list for adv board
    getProcedureListAdvBoard = (spe_id) => {
        console.log(spe_id)
        const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
        let formData = new FormData();
        formData.append('speciality_type_id', spe_id);
        formData.append('search_type', "Procedure");

        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var proceduredata = response.data;
                console.log("proceduredata", JSON.stringify(proceduredata));
                this.setState({
                    ProcedureDataForAdvBoard: response.data.data,
                })
                console.log("ProcedureDataForAdvBoard", this.state.ProcedureDataForAdvBoard)
            })
            .catch(error => {
                console.log(error);
            });
    }

    // To get sub speciality list adv board
    getSubSpecialityListAdvBoard = (spe_id) => {
        console.log(spe_id)
        console.log("in sub speciality list");
        const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
        let formData = new FormData();
        formData.append('speciality_type_id', spe_id);
        formData.append('search_type', "Sub Speciality");

        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var SubSpecialitydata = response.data;
                console.log("SubSpecialitydata", JSON.stringify(SubSpecialitydata));
                this.setState({
                    SubSpecialitydataForAdvBoard: response.data.data,
                })
                console.log("SubSpecialitydataForAdvBoard", this.state.SubSpecialitydataForAdvBoard)
            })
            .catch(error => {
                console.log(error);
            });
    }

    //  To get procedure  list for Clinical trial
    getProcedureListClinicalTrial = (spe_id) => {
        console.log(spe_id)
        const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
        let formData = new FormData();
        formData.append('speciality_type_id', spe_id);
        formData.append('search_type', "Procedure");

        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var ProcedureDataForClinicalTrial = response.data;
                console.log("ProcedureDataForClinicalTrial", JSON.stringify(ProcedureDataForClinicalTrial));
                this.setState({
                    ProcedureDataForClinicalTrial: response.data.data,
                })
                console.log("ProcedureDataForClinicalTrial", this.state.ProcedureDataForClinicalTrial)
            })
            .catch(error => {
                console.log(error);
            });
    }

    // To get sub speciality list for clinical trial
    getSubSpecialityListClinicalTrial = (spe_id) => {
        console.log(spe_id)
        console.log("in sub speciality list");
        const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
        let formData = new FormData();
        formData.append('speciality_type_id', spe_id);
        formData.append('search_type', "Sub Speciality");

        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var SubSpecialitydataClinicalTrial = response.data;
                console.log("SubSpecialitydataClinicalTrial", JSON.stringify(SubSpecialitydataClinicalTrial));
                this.setState({
                    SubSpecialitydataClinicalTrial: response.data.data,
                })
                console.log("SubSpecialitydataClinicalTrial", this.state.SubSpecialitydataClinicalTrial)
            })
            .catch(error => {
                console.log(error);
            });
    }

    //  To get procedure  list for Event Details
    getProcedureListEventsDetails = (spe_id) => {
        console.log(spe_id)
        const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
        let formData = new FormData();
        formData.append('speciality_type_id', spe_id);
        formData.append('search_type', "Procedure");

        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var ProcedureDataForEventDetails = response.data;
                console.log("ProcedureDataForEventDetails", JSON.stringify(ProcedureDataForEventDetails));
                this.setState({
                    ProcedureDataForEventDetails: response.data.data,
                })
                console.log("ProcedureDataForEventDetails", this.state.ProcedureDataForEventDetails)
            })
            .catch(error => {
                console.log(error);
            });
    }

    // To get sub speciality list for Event Details
    getSubSpecialityListEventsDetails = (spe_id) => {
        console.log(spe_id)
        console.log("in sub speciality list");
        const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
        let formData = new FormData();
        formData.append('speciality_type_id', spe_id);
        formData.append('search_type', "Sub Speciality");

        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var SubSpecialitydataEventDetails = response.data;
                console.log("SubSpecialitydataEventDetails", JSON.stringify(SubSpecialitydataEventDetails));
                this.setState({
                    SubSpecialitydataEventDetails: response.data.data,
                })
                console.log("SubSpecialitydataEventDetails", this.state.SubSpecialitydataEventDetails)
            })
            .catch(error => {
                console.log(error);
            });
    }

    //  To get procedure  list for Guidelines
    getProcedureListGuidelines = (spe_id) => {
        console.log(spe_id)
        const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
        let formData = new FormData();
        formData.append('speciality_type_id', spe_id);
        formData.append('search_type', "Procedure");

        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var ProcedureDataForGuidelines = response.data;
                console.log("ProcedureDataForGuidelines", JSON.stringify(ProcedureDataForGuidelines));
                this.setState({
                    ProcedureDataForGuidelines: response.data.data,
                })
                console.log("ProcedureDataForGuidelines", this.state.ProcedureDataForGuidelines)
            })
            .catch(error => {
                console.log(error);
            });
    }

    // To get sub speciality list for Guidelines
    getSubSpecialityListGuidelines = (spe_id) => {
        console.log(spe_id)
        console.log("in sub speciality list");
        const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
        let formData = new FormData();
        formData.append('speciality_type_id', spe_id);
        formData.append('search_type', "Sub Speciality");

        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var SubSpecialitydataGuidelines = response.data;
                console.log("SubSpecialitydataGuidelines", JSON.stringify(SubSpecialitydataGuidelines));
                this.setState({
                    SubSpecialitydataGuidelines: response.data.data,
                })
                console.log("SubSpecialitydataGuidelines", this.state.SubSpecialitydataGuidelines)
            })
            .catch(error => {
                console.log(error);
            });
    }

    //  To get procedure  list for Publication Details
    getProcedureListPublicationDetails = (spe_id) => {
        console.log(spe_id)
        const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
        let formData = new FormData();
        formData.append('speciality_type_id', spe_id);
        formData.append('search_type', "Procedure");

        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var ProcedureDataForPublicationDetails = response.data;
                console.log("ProcedureDataForPublicationDetails", JSON.stringify(ProcedureDataForPublicationDetails));
                this.setState({
                    ProcedureDataForPublicationDetails: response.data.data,
                })
                console.log("ProcedureDataForPublicationDetails", this.state.ProcedureDataForPublicationDetails)
            })
            .catch(error => {
                console.log(error);
            });
    }

    // To get sub speciality list for Publication Details
    getSubSpecialityListPublicationDetails = (spe_id) => {
        console.log(spe_id)
        console.log("in sub speciality list");
        const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
        let formData = new FormData();
        formData.append('speciality_type_id', spe_id);
        formData.append('search_type', "Sub Speciality");

        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var SubSpecialitydataPublicationDetails = response.data;
                console.log("SubSpecialitydataPublicationDetails", JSON.stringify(SubSpecialitydataPublicationDetails));
                this.setState({
                    SubSpecialitydataPublicationDetails: response.data.data,
                })
                console.log("SubSpecialitydataPublicationDetails", this.state.SubSpecialitydataPublicationDetails)
            })
            .catch(error => {
                console.log(error);
            });
    }

    // To get state list
    getStateList = () => {
        console.log("in state list");
        console.log("country id", this.state.PersonalInformation.country_id)

        const url = global.websiteUrl+'/service/Location/get_StateList';
        let formData = new FormData();
        formData.append('country_id', this.state.PersonalInformation.country_id);

        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var testStateData = response.data.Country_Details;
                console.log("testStateData", JSON.stringify(testStateData));
                this.setState({
                    StateData: response.data.Country_Details,
                })
                console.log("StateData======", this.state.StateData)
            })
            .catch(error => {
                console.log(error);
            });
    }

    // To get City list
    getCityList = () => {
        console.log("in city list");
        console.log("country id", this.state.PersonalInformation.country_id)

        const url = global.websiteUrl+'/service/Location/get_CityList';
        let formData = new FormData();
        formData.append('country_id', this.state.PersonalInformation.country_id);

        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var testCityData = response.data.Country_Details;
                console.log("testCityData", JSON.stringify(testCityData));
                this.setState({
                    CityData: response.data.Country_Details,
                })
                console.log("CityData======", this.state.CityData)
            })
            .catch(error => {
                console.log(error);
            });
    }

    // 
    handleChangeBiography(i, e) {
        console.log(i);
        console.log(e.target);
        const { name, value } = e.target;
        let Biography = [...this.state.Biography];
        Biography[i] = { ...Biography[i], [name]: value };
        this.setState({ Biography });
    }

    // Personal Information Add Source Funs starts here
    handleChangePersonalInfoAddSource = (evt) => {
        this.setState({
            valuePersonalInfoAddSource: evt.target.value,
            error: null
        });
    };

    handleKeyDownSalutation = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsSalutation: [...this.state.itemsSalutation, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsSalutation.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.salutation_links = output.join(",");
                await this.setState({ PersonalInformation });

                console.log("check this", this.state.PersonalInformation)
                console.log(output.join(","))
            }
        }
    };
    handleDeleteSalutation = async (item) => {
        await this.setState({
            itemsSalutation: this.state.itemsSalutation.filter((i) => i !== item)
        });
        var output = this.state.itemsSalutation.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.salutation_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownDoctorFullName = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsDoctorFullName: [...this.state.itemsDoctorFullName, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsDoctorFullName.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.doctor_full_name_links = output.join(",");
                await this.setState({ PersonalInformation });

                console.log("check this", this.state.PersonalInformation)
                console.log(output.join(","))
            }
        }
    };
    handleDeleteDoctorFullName = async (item) => {
        await this.setState({
            itemsDoctorFullName: this.state.itemsDoctorFullName.filter((i) => i !== item)
        });
        var output = this.state.itemsDoctorFullName.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.doctor_full_name_links = output.join(",");
        await this.setState({ PersonalInformation });

        console.log("check this", this.state.PersonalInformation.doctor_full_name_links)
        console.log(output.join(","))
        console.log("items", this.state.items)
    };

    handleKeyDownFirstName = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsFirstName: [...this.state.itemsFirstName, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsFirstName.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.first_name_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteFirstName = async (item) => {
        await this.setState({
            itemsFirstName: this.state.itemsFirstName.filter((i) => i !== item)
        });
        var output = this.state.itemsFirstName.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.first_name_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownMiddleName = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsMiddleName: [...this.state.itemsMiddleName, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsMiddleName.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.middle_name_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteMiddleName = async (item) => {
        await this.setState({
            itemsMiddleName: this.state.itemsMiddleName.filter((i) => i !== item)
        });
        var output = this.state.itemsMiddleName.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.middle_name_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownLastName = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsLastName: [...this.state.itemsLastName, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsLastName.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.last_name_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteLastName = async (item) => {
        await this.setState({
            itemsLastName: this.state.itemsLastName.filter((i) => i !== item)
        });
        var output = this.state.itemsLastName.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.last_name_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownQualification = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsQualification: [...this.state.itemsQualification, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsQualification.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.qualification_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteQualification = async (item) => {
        await this.setState({
            itemsQualification: this.state.itemsQualification.filter((i) => i !== item)
        });
        var output = this.state.itemsQualification.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.qualification_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownExperience = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsExperience: [...this.state.itemsExperience, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsExperience.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.experience_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteExperience = async (item) => {
        await this.setState({
            itemsExperience: this.state.itemsExperience.filter((i) => i !== item)
        });
        var output = this.state.itemsExperience.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.experience_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownMedicalSchool = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsMedicalSchool: [...this.state.itemsMedicalSchool, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsMedicalSchool.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.medical_schools_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteMedicalSchool = async (item) => {
        await this.setState({
            itemsMedicalSchool: this.state.itemsMedicalSchool.filter((i) => i !== item)
        });
        var output = this.state.itemsMedicalSchool.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.medical_schools_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownMedicalMedicalRegister = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsMedicalRegisterNo: [...this.state.itemsMedicalRegisterNo, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsMedicalRegisterNo.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.medical_registration_no_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteMedicalRegister = async (item) => {
        await this.setState({
            itemsMedicalRegisterNo: this.state.itemsMedicalRegisterNo.filter((i) => i !== item)
        });
        var output = this.state.itemsMedicalRegisterNo.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.medical_registration_no_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownNpiNo = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsNpiNo: [...this.state.itemsNpiNo, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsNpiNo.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.NPI_no_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteNpiNo = async (item) => {
        await this.setState({
            itemsNpiNo: this.state.itemsNpiNo.filter((i) => i !== item)
        });
        var output = this.state.itemsNpiNo.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.NPI_no_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownPlaceOfWork = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsPlaceWork: [...this.state.itemsPlaceWork, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsPlaceWork.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.place_of_work_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeletePlaceOfWork = async (item) => {
        await this.setState({
            itemsPlaceWork: this.state.itemsPlaceWork.filter((i) => i !== item)
        });
        var output = this.state.itemsPlaceWork.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.place_of_work_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownDesignation = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsDesignation: [...this.state.itemsDesignation, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsDesignation.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.designation_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteDesignation = async (item) => {
        await this.setState({
            itemsDesignation: this.state.itemsDesignation.filter((i) => i !== item)
        });
        var output = this.state.itemsDesignation.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.designation_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownBrand = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsBrand: [...this.state.itemsBrand, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsBrand.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.brand_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteBrand = async (item) => {
        await this.setState({
            itemsBrand: this.state.itemsBrand.filter((i) => i !== item)
        });
        var output = this.state.itemsBrand.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.brand_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownDepartment = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsDepartment: [...this.state.itemsDepartment, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsDepartment.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.department_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteDepartment = async (item) => {
        await this.setState({
            itemsDepartment: this.state.itemsDepartment.filter((i) => i !== item)
        });
        var output = this.state.itemsDepartment.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.department_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownSpeciality = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemSpeciality: [...this.state.itemSpeciality, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemSpeciality.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.specialty_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteSpeciality = async (item) => {
        await this.setState({
            itemSpeciality: this.state.itemSpeciality.filter((i) => i !== item)
        });
        var output = this.state.itemSpeciality.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.specialty_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownProcedure = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsProcedure: [...this.state.itemsProcedure, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsProcedure.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.procedure_type_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteProcedure = async (item) => {
        await this.setState({
            itemsProcedure: this.state.itemsProcedure.filter((i) => i !== item)
        });
        var output = this.state.itemsProcedure.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.procedure_type_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownSubSpeciality = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsSubSpeciality: [...this.state.itemsSubSpeciality, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsSubSpeciality.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.sub_specialty_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteSubspeciality = async (item) => {
        await this.setState({
            itemsSubSpeciality: this.state.itemsSubSpeciality.filter((i) => i !== item)
        });
        var output = this.state.itemsSubSpeciality.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.sub_specialty_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownDoctorsContact = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsDoctorContact: [...this.state.itemsDoctorContact, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsDoctorContact.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.doctor_contact_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteDoctorsContact = async (item) => {
        await this.setState({
            itemsDoctorContact: this.state.itemsDoctorContact.filter((i) => i !== item)
        });
        var output = this.state.itemsDoctorContact.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.doctor_contact_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownDoctorsEmail = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsDoctorEmail: [...this.state.itemsDoctorEmail, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsDoctorEmail.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.doctor_email_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteDoctorsEmail = async (item) => {
        await this.setState({
            itemsDoctorEmail: this.state.itemsDoctorEmail.filter((i) => i !== item)
        });
        var output = this.state.itemsDoctorEmail.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.doctor_email_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownFax = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsFax: [...this.state.itemsFax, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsFax.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.fax_links = output.join(",");
                await this.setState({ PersonalInformation });

                console.log("check this", this.state.PersonalInformation)
                console.log(output.join(","))
            }
        }
    };
    handleDeleteFax = async (item) => {
        await this.setState({
            itemsFax: this.state.itemsFax.filter((i) => i !== item)
        });
        var output = this.state.itemsFax.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.fax_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownLinkedIn = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsLinkedIn: [...this.state.itemsLinkedIn, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsLinkedIn.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.linked_in_profile_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteLinkedIn = async (item) => {
        await this.setState({
            itemsLinkedIn: this.state.itemsLinkedIn.filter((i) => i !== item)
        });
        var output = this.state.itemsLinkedIn.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.linked_in_profile_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownTwitter = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsTwitter: [...this.state.itemsTwitter, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsTwitter.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.twitter_handle_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteTwitter = async (item) => {
        await this.setState({
            itemsTwitter: this.state.itemsTwitter.filter((i) => i !== item)
        });
        var output = this.state.itemsTwitter.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.twitter_handle_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownAssisContact = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsAssistanceContact: [...this.state.itemsAssistanceContact, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsAssistanceContact.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.assistance_contact_no_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteAssisContact = async (item) => {
        await this.setState({
            itemsAssistanceContact: this.state.itemsAssistanceContact.filter((i) => i !== item)
        });
        var output = this.state.itemsAssistanceContact.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.assistance_contact_no_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownAssisEmail = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsAssistanceEmail: [...this.state.itemsAssistanceEmail, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsAssistanceEmail.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.assistance_email_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteAssisEmail = async (item) => {
        await this.setState({
            itemsAssistanceEmail: this.state.itemsAssistanceEmail.filter((i) => i !== item)
        });
        var output = this.state.itemsAssistanceEmail.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.assistance_email_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownWebsite = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsWebsite: [...this.state.itemsWebsite, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsWebsite.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.website_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteWebsite = async (item) => {
        await this.setState({
            itemsWebsite: this.state.itemsWebsite.filter((i) => i !== item)
        });
        var output = this.state.itemsWebsite.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.website_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownCountry = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsCountry: [...this.state.itemsCountry, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsCountry.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.country_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteCountry = async (item) => {
        await this.setState({
            itemsCountry: this.state.itemsCountry.filter((i) => i !== item)
        });
        var output = this.state.itemsCountry.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.country_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownState = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsState: [...this.state.itemsState, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsState.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.state_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteState = async (item) => {
        await this.setState({
            itemsState: this.state.itemsState.filter((i) => i !== item)
        });
        var output = this.state.itemsState.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.state_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownAddress1 = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsAddress1: [...this.state.itemsAddress1, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsAddress1.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.address_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteAddress1 = async (item) => {
        await this.setState({
            itemsAddress1: this.state.itemsAddress1.filter((i) => i !== item)
        });
        var output = this.state.itemsAddress1.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.address_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    handleKeyDownProcedurePotential = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valuePersonalInfoAddSource.trim();
            if (this.state.valuePersonalInfoAddSource !== '') {
                await this.setState({
                    itemsProcedurePotential: [...this.state.itemsProcedurePotential, this.state.valuePersonalInfoAddSource],
                    valuePersonalInfoAddSource: ""
                });

                var output = this.state.itemsProcedurePotential.map(function (item) {
                    return item;
                });

                let { PersonalInformation } = this.state;
                PersonalInformation.procedure_potential_links = output.join(",");
                await this.setState({ PersonalInformation });
            }
        }
    };
    handleDeleteProcedurePotential = async (item) => {
        await this.setState({
            itemsProcedurePotential: this.state.itemsProcedurePotential.filter((i) => i !== item)
        });
        var output = this.state.itemsProcedurePotential.map(function (item) {
            return item;
        });

        let { PersonalInformation } = this.state;
        PersonalInformation.procedure_potential_links = output.join(",");
        await this.setState({ PersonalInformation });
    };

    // Biography Add Source Funs starts here
    handleChangeBiographyAddSource = (evt) => {
        this.setState({
            valueBiographyAddSource: evt.target.value,
            error: null
        });
    };

    handleKeyDownCitzenship = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valueBiographyAddSource.trim();
            if (this.state.valueBiographyAddSource !== '') {
                await this.setState({
                    itemsCitizenship: [...this.state.itemsCitizenship, this.state.valueBiographyAddSource],
                    valueBiographyAddSource: ""
                });

                var output = this.state.itemsCitizenship.map(function (item) {
                    return item;
                });

                let { Biography } = this.state;
                Biography.citizenship_links = output.join(",");
                await this.setState({ Biography });

                console.log("check this", this.state.Biography)
                console.log(output.join(","))
            }
        }
    };
    handleDeleteCitizenship = async (item) => {
        await this.setState({
            itemsCitizenship: this.state.itemsCitizenship.filter((i) => i !== item)
        });
        var output = this.state.itemsCitizenship.map(function (item) {
            return item;
        });

        let { Biography } = this.state;
        Biography.citizenship_links = output.join(",");
        await this.setState({ Biography });
    };

    handleKeyDownLicenseRegions = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valueBiographyAddSource.trim();
            if (this.state.valueBiographyAddSource !== '') {
                await this.setState({
                    itemsLicensedRegions: [...this.state.itemsLicensedRegions, this.state.valueBiographyAddSource],
                    valueBiographyAddSource: ""
                });

                var output = this.state.itemsLicensedRegions.map(function (item) {
                    return item;
                });

                let { Biography } = this.state;
                Biography.licensed_regions_links = output.join(",");
                await this.setState({ Biography });

                console.log("check this", this.state.Biography)
                console.log(output.join(","))
            }
        }
    };
    handleDeleteLicenseRegions = async (item) => {
        await this.setState({
            itemsLicensedRegions: this.state.itemsLicensedRegions.filter((i) => i !== item)
        });
        var output = this.state.itemsLicensedRegions.map(function (item) {
            return item;
        });

        let { Biography } = this.state;
        Biography.licensed_regions_links = output.join(",");
        await this.setState({ Biography });
    };

    handleKeyDownAreasOfInterest = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valueBiographyAddSource.trim();
            if (this.state.valueBiographyAddSource !== '') {
                await this.setState({
                    itemsAreasOfInterest: [...this.state.itemsAreasOfInterest, this.state.valueBiographyAddSource],
                    valueBiographyAddSource: ""
                });

                var output = this.state.itemsAreasOfInterest.map(function (item) {
                    return item;
                });

                let { Biography } = this.state;
                Biography.areas_of_interest_links = output.join(",");
                await this.setState({ Biography });

                console.log("check this", this.state.Biography)
                console.log(output.join(","))
            }
        }
    };
    handleDeleteAreasOfInterest = async (item) => {
        await this.setState({
            itemsAreasOfInterest: this.state.itemsAreasOfInterest.filter((i) => i !== item)
        });
        var output = this.state.itemsAreasOfInterest.map(function (item) {
            return item;
        });

        let { Biography } = this.state;
        Biography.areas_of_interest_links = output.join(",");
        await this.setState({ Biography });
    };

    handleKeyDownLanguage = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valueBiographyAddSource.trim();
            if (this.state.valueBiographyAddSource !== '') {
                await this.setState({
                    itemsLanguageSpoken: [...this.state.itemsLanguageSpoken, this.state.valueBiographyAddSource],
                    valueBiographyAddSource: ""
                });

                var output = this.state.itemsLanguageSpoken.map(function (item) {
                    return item;
                });

                let { Biography } = this.state;
                Biography.language_spoken_links = output.join(",");
                await this.setState({ Biography });

                console.log("check this", this.state.Biography)
                console.log(output.join(","))
            }
        }
    };
    handleDeleteLanguage = async (item) => {
        await this.setState({
            itemsLanguageSpoken: this.state.itemsLanguageSpoken.filter((i) => i !== item)
        });
        var output = this.state.itemsLanguageSpoken.map(function (item) {
            return item;
        });

        let { Biography } = this.state;
        Biography.language_spoken_links = output.join(",");
        await this.setState({ Biography });
    };

    handleKeyDownWorkPhone = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valueBiographyAddSource.trim();
            if (this.state.valueBiographyAddSource !== '') {
                await this.setState({
                    itemsWorkPhone: [...this.state.itemsWorkPhone, this.state.valueBiographyAddSource],
                    valueBiographyAddSource: ""
                });

                var output = this.state.itemsWorkPhone.map(function (item) {
                    return item;
                });

                let { Biography } = this.state;
                Biography.work_phone_links = output.join(",");
                await this.setState({ Biography });

                console.log("check this", this.state.Biography)
                console.log(output.join(","))
            }
        }
    };
    handleDeleteWorkPhone = async (item) => {
        await this.setState({
            itemsWorkPhone: this.state.itemsWorkPhone.filter((i) => i !== item)
        });
        var output = this.state.itemsWorkPhone.map(function (item) {
            return item;
        });

        let { Biography } = this.state;
        Biography.work_phone_links = output.join(",");
        await this.setState({ Biography });
    };

    handleKeyDownWorkEmail = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valueBiographyAddSource.trim();
            if (this.state.valueBiographyAddSource !== '') {
                await this.setState({
                    itemsWorkEmail: [...this.state.itemsWorkEmail, this.state.valueBiographyAddSource],
                    valueBiographyAddSource: ""
                });

                var output = this.state.itemsWorkEmail.map(function (item) {
                    return item;
                });

                let { Biography } = this.state;
                Biography.work_email_links = output.join(",");
                await this.setState({ Biography });

                console.log("check this", this.state.Biography)
                console.log(output.join(","))
            }
        }
    };
    handleDeleteWorkEmail = async (item) => {
        await this.setState({
            itemsWorkEmail: this.state.itemsWorkEmail.filter((i) => i !== item)
        });
        var output = this.state.itemsWorkEmail.map(function (item) {
            return item;
        });

        let { Biography } = this.state;
        Biography.work_email_links = output.join(",");
        await this.setState({ Biography });
    };

    handleKeyDownLinkedInFollowers = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valueBiographyAddSource.trim();
            if (this.state.valueBiographyAddSource !== '') {
                await this.setState({
                    itemsLinkedInFolllowers: [...this.state.itemsLinkedInFolllowers, this.state.valueBiographyAddSource],
                    valueBiographyAddSource: ""
                });

                var output = this.state.itemsLinkedInFolllowers.map(function (item) {
                    return item;
                });

                let { Biography } = this.state;
                Biography.linkedin_followers_links = output.join(",");
                await this.setState({ Biography });

                console.log("check this", this.state.Biography)
                console.log(output.join(","))
            }
        }
    };
    handleDeleteLinkedInFollowers = async (item) => {
        await this.setState({
            itemsLinkedInFolllowers: this.state.itemsLinkedInFolllowers.filter((i) => i !== item)
        });
        var output = this.state.itemsLinkedInFolllowers.map(function (item) {
            return item;
        });

        let { Biography } = this.state;
        Biography.linkedin_followers_links = output.join(",");
        await this.setState({ Biography });
    };

    handleKeyDownTwitterFollowers = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valueBiographyAddSource.trim();
            if (this.state.valueBiographyAddSource !== '') {
                await this.setState({
                    itemsTwitterFollowers: [...this.state.itemsTwitterFollowers, this.state.valueBiographyAddSource],
                    valueBiographyAddSource: ""
                });

                var output = this.state.itemsTwitterFollowers.map(function (item) {
                    return item;
                });

                let { Biography } = this.state;
                Biography.twitter_followers_links = output.join(",");
                await this.setState({ Biography });

                console.log("check this", this.state.Biography)
                console.log(output.join(","))
            }
        }
    };
    handleDeleteTwitterFollowers = async (item) => {
        await this.setState({
            itemsTwitterFollowers: this.state.itemsTwitterFollowers.filter((i) => i !== item)
        });
        var output = this.state.itemsTwitterFollowers.map(function (item) {
            return item;
        });

        let { Biography } = this.state;
        Biography.twitter_followers_links = output.join(",");
        await this.setState({ Biography });
    };

    handleKeyDownBiography = async (evt) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.valueBiographyAddSource.trim();
            if (this.state.valueBiographyAddSource !== '') {
                await this.setState({
                    itemsBiography: [...this.state.itemsBiography, this.state.valueBiographyAddSource],
                    valueBiographyAddSource: ""
                });

                var output = this.state.itemsBiography.map(function (item) {
                    return item;
                });

                let { Biography } = this.state;
                Biography.biography_links = output.join(",");
                await this.setState({ Biography });

                console.log("check this", this.state.Biography)
                console.log(output.join(","))
            }
        }
    };
    handleDeleteBiography = async (item) => {
        await this.setState({
            itemsBiography: this.state.itemsBiography.filter((i) => i !== item)
        });
        var output = this.state.itemsBiography.map(function (item) {
            return item;
        });

        let { Biography } = this.state;
        Biography.biography_links = output.join(",");
        await this.setState({ Biography });
    };

    // to set values of Work Details array
    handleChangeWorkDetails(i, e) {
        console.log(i);
        console.log(e.target);
        const { name, value } = e.target;
        let WorkDetails = [...this.state.WorkDetails];
        WorkDetails[i] = { ...WorkDetails[i], [name]: value };
        this.setState({ WorkDetails });
    }

    removeClickWorkDetails(i) {
        let WorkDetails = [...this.state.WorkDetails];
        WorkDetails.splice(i, 1);
        this.setState({ WorkDetails });
    }

    addClickWorkDetails = async () => {
        await this.setState(prevState => ({
            WorkDetails: [...prevState.WorkDetails, {
                // kol_id: this.props.location.state.emp_unique_id,
                kol_id: sessionStorage.getItem('emp_unique_id'),
                current_place_of_work: '',
                current_place_of_work_links: '',
                current_place_of_work_comment: '',
                current_place_of_work_status: '',
                designation: '',
                designation_links: '',
                designation_comment: '',
                designation_status: '',
                department: '',
                department_links: '',
                department_comment: '',
                department_status: '',
            }]
        }))

        this.setState({ items: [] })
    }

    handleChangeWorkingDetailsAddSource = (evt) => {
        // console.log("hdl : "+evt.target.value)
        this.setState({
            value: evt.target.value,
            error: null
        });
    };

    handleKeyDownWorkingDetails = async (evt, index, name) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();
            console.log('name', name)
            console.log('index', index)

            var value = this.state.value.trim();
            if (value !== '') {
                console.log("ite input : " + JSON.stringify(this.state.WorkDetails[index].current_place_of_work_links))
                // organization_type_links
                //    if (value && this.isValid(value)) {
                if (name == 'current_place_of_work_links') {
                    await this.setState({
                        items: [...this.state.WorkDetails[index].current_place_of_work_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'designation_links') {
                    await this.setState({
                        items: [...this.state.WorkDetails[index].designation_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'department_links') {
                    await this.setState({
                        items: [...this.state.WorkDetails[index].department_links.split(","), this.state.value],
                        value: ""
                    });
                }

                console.log("key d : " + JSON.stringify(this.state.items.toString()))

                let list = [...this.state.WorkDetails];
                list[index] = { ...list[index], [name]: this.state.items.toString() };
                //  console.log("list : "+JSON.stringify(list));
                this.setState({ WorkDetails: list });
            }
        }
    }

    handleDeleteWorkingDetails = async (array, item, index, name) => {
        console.log('in del : ' + JSON.stringify(array));
        await this.setState({
            items: array.flat().filter((i) => i !== item)
        });
        let list = [...this.state.WorkDetails];
        list[index] = { ...list[index], [name]: this.state.items.toString() };
        console.log("list : " + JSON.stringify(list));
        this.setState({ WorkDetails: list });
    }

    // to set values of Professional Membership array
    handleChangeProfessionalMembership(i, e) {
        console.log(i);
        console.log(e.target);
        const { name, value } = e.target;
        let ProfessionalMemberships = [...this.state.ProfessionalMemberships];
        ProfessionalMemberships[i] = { ...ProfessionalMemberships[i], [name]: value };
        this.setState({ ProfessionalMemberships });
    }

    removeClickProfessionalMembership(i) {
        let ProfessionalMemberships = [...this.state.ProfessionalMemberships];
        ProfessionalMemberships.splice(i, 1);
        this.setState({ ProfessionalMemberships });
    }

    addClickProfessionalMembership = async () => {
        await this.setState(prevState => ({
            ProfessionalMemberships: [...prevState.ProfessionalMemberships, {
                // kol_id: this.props.location.state.emp_unique_id,
                kol_id: sessionStorage.getItem('emp_unique_id'),
                organization: '',
                organization_links: '',
                organization_comment: '',
                organization_status: '',
                organization_type: '',
                organization_type_links: '',
                organization_type_comment: '',
                organization_type_status: '',
                source_link: '',
                source_link_links: '',
                source_link_comment: '',
                source_link_status: '',
                location: '',
                location_links: '',
                location_comment: '',
                location_status: '',
                position: '',
                position_links: '',
                position_comment: '',
                position_status: '',
                tenure: '',
                tenure_link: '',
                tenure_comment: '',
                tenure_status: '',
                tenure1: '',
                tenure1_comment: '',
                tenure1_status: '',
                organization_website: '',
                organization_website_links: '',
                organization_website_comment: '',
                organization_website_status: '',
            }]
        }))

        this.setState({ items: [] })
    }

    handleInputChangemulti = (item, index, name) => {
        // const { name, value } = e.target;
        //  console.log("item :"+JSON.stringify(item))
        // const list = [...this.state.inputList];
        //   console.log("e.tar :"+JSON.stringify(list))
        // list[index][name] = item;
        // this.setState({ inputList: list });

        //  const { name, value } = e.target;
        let ProfessionalMemberships = [...this.state.ProfessionalMemberships];
        ProfessionalMemberships[index] = { ...ProfessionalMemberships[index], [name]: item };
        this.setState({ ProfessionalMemberships });
    }

    handleChangeProfAddSource = (evt) => {
        // console.log("hdl : "+evt.target.value)
        this.setState({
            value: evt.target.value,
            error: null
        });
    };

    handleKeyDownProfAddSource = async (evt, index, name) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();
            console.log('name', name)
            console.log('index', index)

            var value = this.state.value.trim();
            if (value !== '') {
                console.log("ite input : " + JSON.stringify(this.state.ProfessionalMemberships[index].organization_links))
                // organization_type_links
                //    if (value && this.isValid(value)) {
                if (name == 'organization_links') {
                    await this.setState({
                        items: [...this.state.ProfessionalMemberships[index].organization_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'organization_type_links') {
                    await this.setState({
                        items: [...this.state.ProfessionalMemberships[index].organization_type_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'source_link_links') {
                    await this.setState({
                        items: [...this.state.ProfessionalMemberships[index].source_link_links.split(","), this.state.value],
                        value: ""
                    });
                }

                else if (name == 'location_links') {
                    await this.setState({
                        items: [...this.state.ProfessionalMemberships[index].location_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'position_links') {
                    await this.setState({
                        items: [...this.state.ProfessionalMemberships[index].position_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'tenure_link') {
                    await this.setState({
                        items: [...this.state.ProfessionalMemberships[index].tenure_link.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'organization_website_links') {
                    await this.setState({
                        items: [...this.state.ProfessionalMemberships[index].organization_website_links.split(","), this.state.value],
                        value: ""
                    });
                }

                console.log("key d : " + JSON.stringify(this.state.items.toString()))

                let list = [...this.state.ProfessionalMemberships];
                list[index] = { ...list[index], [name]: this.state.items.toString() };
                //  console.log("list : "+JSON.stringify(list));
                this.setState({ ProfessionalMemberships: list });
            }
        }
    }

    handleDeleteProfAddSource = async (arrayOrganization, item, index, name) => {
        console.log('in del : ' + JSON.stringify(arrayOrganization));
        await this.setState({
            items: arrayOrganization.flat().filter((i) => i !== item)
        });
        let list = [...this.state.ProfessionalMemberships];
        list[index] = { ...list[index], [name]: this.state.items.toString() };
        console.log("list : " + JSON.stringify(list));
        this.setState({ ProfessionalMemberships: list });
    }

    handleSubmitProfessional = (event) => {
        console.log("Professional Membership:", this.state.ProfessionalMemberships)
        event.preventDefault();
    }

    // to set values of Education array
    handleChangeEducation(i, e) {
        console.log(i);
        console.log(e.target);
        const { name, value } = e.target;
        let Education = [...this.state.Education];
        Education[i] = { ...Education[i], [name]: value };
        this.setState({ Education });

        if (i == 0) {
            console.log("Education index is 0");
            if (name == 'institute_name') {
                let { PersonalInformation } = this.state;
                PersonalInformation.medical_schools = e.target.value;
                this.setState({ PersonalInformation });
            }
            if (name == 'degrees') {
                let { PersonalInformation } = this.state;
                PersonalInformation.qualification = e.target.value;
                this.setState({ PersonalInformation });
            }
        }
    }

    removeClickEducation(i) {
        let Education = [...this.state.Education];
        Education.splice(i, 1);
        this.setState({ Education });
    }

    addClickEducation() {
        this.setState(prevState => ({
            Education: [...prevState.Education, {
                // kol_id: this.props.location.state.emp_unique_id,
                kol_id: sessionStorage.getItem('emp_unique_id'),
                institute_name: '',
                institute_name_links: '',
                institute_name_comment: '',
                institute_name_status: '',
                institute_type: '',
                institute_type_links: '',
                institute_type_comment: '',
                institute_type_status: '',
                source_link: '',
                source_link_links: '',
                source_link_comment: '',
                source_link_status: '',
                degrees: '',
                degrees_links: '',
                degrees_comment: '',
                degrees_status: '',
                speciality_id: '',
                speciality_links: '',
                speciality_comment: '',
                speciality_status: '',
                years_attended_from: '',
                years_attended_to: '',
                years_attended_links: '',
                years_attended_comment: '',
                years_attended_staus: '',
            }]
        }))
        this.setState({ items: [] })
    }

    handleChangeEducationAddSource = (evt) => {
        // console.log("hdl : "+evt.target.value)
        this.setState({
            value: evt.target.value,
            error: null
        });
    };

    handleKeyDownEducationAddSource = async (evt, index, name) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();
            console.log('name', name)
            console.log('index', index)

            var value = this.state.value.trim();
            if (value !== '') {
                console.log("ite input : " + JSON.stringify(this.state.Education[index].institute_name_links))

                if (name == 'institute_name_links') {
                    await this.setState({
                        items: [...this.state.Education[index].institute_name_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'institute_type_links') {
                    await this.setState({
                        items: [...this.state.Education[index].institute_type_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'source_link_links') {
                    await this.setState({
                        items: [...this.state.Education[index].source_link_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'degrees_links') {
                    await this.setState({
                        items: [...this.state.Education[index].degrees_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'speciality_links') {
                    await this.setState({
                        items: [...this.state.Education[index].speciality_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'years_attended_links') {
                    await this.setState({
                        items: [...this.state.Education[index].years_attended_links.split(","), this.state.value],
                        value: ""
                    });
                }


                console.log("key d : " + JSON.stringify(this.state.items.toString()))

                let list = [...this.state.Education];
                list[index] = { ...list[index], [name]: this.state.items.toString() };
                //  console.log("list : "+JSON.stringify(list));
                this.setState({ Education: list });
            }
        }
    }

    handleDeleteEducationAddSource = async (array, item, index, name) => {
        console.log('in del : ' + JSON.stringify(array));
        await this.setState({
            items: array.flat().filter((i) => i !== item)
        });
        let list = [...this.state.Education];
        list[index] = { ...list[index], [name]: this.state.items.toString() };
        console.log("list : " + JSON.stringify(list));
        this.setState({ Education: list });
    }


    handleSubmitEducation = (event) => {
        console.log("Education:", this.state.Education)
        event.preventDefault();
    }

    // to set values of advisory board array
    handleChangeAdvisoryBoard(i, e) {
        console.log(i);
        console.log(e.target);
        const { name, value } = e.target;
        let AdvisoryBoard = [...this.state.AdvisoryBoard];
        AdvisoryBoard[i] = { ...AdvisoryBoard[i], [name]: value };
        this.setState({ AdvisoryBoard });
    }

    removeClickAdvisoryBoard(i) {
        let AdvisoryBoard = [...this.state.AdvisoryBoard];
        AdvisoryBoard.splice(i, 1);
        this.setState({ AdvisoryBoard });
    }

    addClickAdvisoryBoard() {
        this.setState(prevState => ({
            AdvisoryBoard: [...prevState.AdvisoryBoard, {
                // kol_id: this.props.location.state.emp_unique_id,
                kol_id: sessionStorage.getItem('emp_unique_id'),
                year: '',
                year_links: '',
                year_comment: '',
                year_status: '',
                advisory_board: '',
                advisory_board_links: '',
                advisory_board_comment: '',
                advisory_board_status: '',
                topic: '',
                topic_links: '',
                topic_comment: '',
                topic_status: '',
                speciality_id: '',
                speciality_links: '',
                speciality_comment: '',
                speciality_status: '',
                sub_specialty_id: '',
                sub_speciality_links: '',
                sub_speciality_comment: '',
                sub_speciality_status: '',
                procedure_type_id: '',
                procedure_type_links: '',
                procedure_type_comment: '',
                procedure_type_status: '',
                company: '',
                company_links: '',
                company_comment: '',
                company_status: '',
                type_of_company: '',
                type_of_company_links: '',
                type_of_company_comment: '',
                type_of_company_status: '',
                role_id: '',
                role_links: '',
                role_comment: '',
                role_status: '',
                manual_weightage: '',
            }]
        }))
        this.setState({ items: [] })
    }

    handleChangeAdvBoardAddSource = (evt) => {
        this.setState({
            value: evt.target.value,
            error: null
        });
    };

    handleKeyDownAdvBoardAddSource = async (evt, index, name) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();
            console.log('name', name)
            console.log('index', index)

            var value = this.state.value.trim();
            if (value !== '') {
                console.log("ite input : " + JSON.stringify(this.state.AdvisoryBoard[index].speciality_links))

                if (name == 'speciality_links') {
                    await this.setState({
                        items: [...this.state.AdvisoryBoard[index].speciality_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'sub_speciality_links') {
                    await this.setState({
                        items: [...this.state.AdvisoryBoard[index].sub_speciality_links.split(","), this.state.value],
                        value: ""
                    });
                }

                else if (name == 'procedure_type_links') {
                    await this.setState({
                        items: [...this.state.AdvisoryBoard[index].procedure_type_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'year_links') {
                    await this.setState({
                        items: [...this.state.AdvisoryBoard[index].year_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'company_links') {
                    await this.setState({
                        items: [...this.state.AdvisoryBoard[index].company_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'type_of_company_links') {
                    await this.setState({
                        items: [...this.state.AdvisoryBoard[index].type_of_company_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'advisory_board_links') {
                    await this.setState({
                        items: [...this.state.AdvisoryBoard[index].advisory_board_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'topic_links') {
                    await this.setState({
                        items: [...this.state.AdvisoryBoard[index].topic_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'role_links') {
                    await this.setState({
                        items: [...this.state.AdvisoryBoard[index].role_links.split(","), this.state.value],
                        value: ""
                    });
                }

                console.log("key d : " + JSON.stringify(this.state.items.toString()))

                let list = [...this.state.AdvisoryBoard];
                list[index] = { ...list[index], [name]: this.state.items.toString() };
                //  console.log("list : "+JSON.stringify(list));
                this.setState({ AdvisoryBoard: list });
            }
        }
    }

    handleDeleteAdvBoardAddSource = async (array, item, index, name) => {
        console.log('in del : ' + JSON.stringify(array));
        await this.setState({
            items: array.flat().filter((i) => i !== item)
        });
        let list = [...this.state.AdvisoryBoard];
        list[index] = { ...list[index], [name]: this.state.items.toString() };
        console.log("list : " + JSON.stringify(list));
        this.setState({ AdvisoryBoard: list });
    }

    handleSubmitAdvisoryBoard = (event) => {
        console.log("AdvisoryBoard:", this.state.AdvisoryBoard)
        event.preventDefault();
    }

    // awards and honours
    handleChangeAwardsHonours(i, e) {
        console.log(i);
        console.log(e.target);
        const { name, value } = e.target;
        let AwardsHonours = [...this.state.AwardsHonours];
        AwardsHonours[i] = { ...AwardsHonours[i], [name]: value };
        this.setState({ AwardsHonours });
    }

    removeClickAwardsHonours(i) {
        let AwardsHonours = [...this.state.AwardsHonours];
        AwardsHonours.splice(i, 1);
        this.setState({ AwardsHonours });
    }

    addClickAwardsHonours() {
        this.setState(prevState => ({
            AwardsHonours: [...prevState.AwardsHonours, {
                // kol_id: this.props.location.state.emp_unique_id,
                kol_id: sessionStorage.getItem('emp_unique_id'),
                year: '',
                year_links: '',
                year_comment: '',
                year_status: '',
                awards_honours: '',
                awards_honours_links: '',
                awards_honours_commen: '',
                awards_honours_status: '',
                types: '',
                types_links: '',
                types_comment: '',
                types_status: '',
            }]
        }))
        this.setState({ items: [] })
    }

    handleChangeAwardsHonoursAddSource = (evt) => {
        this.setState({
            value: evt.target.value,
            error: null
        });
    };

    handleKeyDownAwardsHonoursAddSource = async (evt, index, name) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();
            console.log('name', name)
            console.log('index', index)

            var value = this.state.value.trim();
            if (value !== '') {
                console.log("ite input : " + JSON.stringify(this.state.AwardsHonours[index].year_links))

                if (name == 'year_links') {
                    await this.setState({
                        items: [...this.state.AwardsHonours[index].year_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'awards_honours_links') {
                    await this.setState({
                        items: [...this.state.AwardsHonours[index].awards_honours_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'types_links') {
                    await this.setState({
                        items: [...this.state.AwardsHonours[index].types_links.split(","), this.state.value],
                        value: ""
                    });
                }

                console.log("key d : " + JSON.stringify(this.state.items.toString()))

                let list = [...this.state.AwardsHonours];
                list[index] = { ...list[index], [name]: this.state.items.toString() };
                //  console.log("list : "+JSON.stringify(list));
                this.setState({ AwardsHonours: list });
            }
        }
    }

    handleDeleteAwardsHonoursAddSource = async (array, item, index, name) => {
        console.log('in del : ' + JSON.stringify(array));
        await this.setState({
            items: array.flat().filter((i) => i !== item)
        });
        let list = [...this.state.AwardsHonours];
        list[index] = { ...list[index], [name]: this.state.items.toString() };
        console.log("list : " + JSON.stringify(list));
        this.setState({ AwardsHonours: list });
    }

    handleSubmitAwardsHonours = (event) => {
        console.log("AwardsHonours:", this.state.AwardsHonours)
        event.preventDefault();
    }

    // Books monographs
    handleChangeBooksMonographs(i, e) {
        console.log(i);
        console.log(e.target);
        const { name, value } = e.target;
        let BooksMonographs = [...this.state.BooksMonographs];
        BooksMonographs[i] = { ...BooksMonographs[i], [name]: value };
        this.setState({ BooksMonographs });
    }

    removeClickBooksMonographs(i) {
        let BooksMonographs = [...this.state.BooksMonographs];
        BooksMonographs.splice(i, 1);
        this.setState({ BooksMonographs });
    }

    addClickBooksMonographs() {
        this.setState(prevState => ({
            BooksMonographs: [...prevState.BooksMonographs, {
                // kol_id: this.props.location.state.emp_unique_id,
                kol_id: sessionStorage.getItem('emp_unique_id'),
                year: '',
                year_links: '',
                year_comment: '',
                year_status: '',
                title: '',
                title_links: '',
                title_comment: '',
                title_status: '',
                author: '',
                author_links: '',
                author_comment: '',
                author_status: '',
                publication: '',
                publication_links: '',
                publication_comment: '',
                publication_status: '',
                edition: '',
                edition_links: '',
                edition_comment: '',
                edition_status: '',
                pages: '',
                pages_links: '',
                pages_comment: '',
                pages_status: '',
                editors: '',
                editors_links: '',
                editors_comment: '',
                editors_status: '',
            }]
        }))
        this.setState({ items: [] })
    }

    handleChangeBooksMonographsAddSource = (evt) => {
        this.setState({
            value: evt.target.value,
            error: null
        });
    };

    handleKeyDownBooksMonographsAddSource = async (evt, index, name) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();
            console.log('name', name)
            console.log('index', index)

            var value = this.state.value.trim();
            if (value !== '') {
                console.log("ite input : " + JSON.stringify(this.state.BooksMonographs[index].year_links))

                if (name == 'year_links') {
                    await this.setState({
                        items: [...this.state.BooksMonographs[index].year_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'title_links') {
                    await this.setState({
                        items: [...this.state.BooksMonographs[index].title_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'author_links') {
                    await this.setState({
                        items: [...this.state.BooksMonographs[index].author_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'publication_links') {
                    await this.setState({
                        items: [...this.state.BooksMonographs[index].publication_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'edition_links') {
                    await this.setState({
                        items: [...this.state.BooksMonographs[index].edition_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'pages_links') {
                    await this.setState({
                        items: [...this.state.BooksMonographs[index].pages_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'editors_links') {
                    await this.setState({
                        items: [...this.state.BooksMonographs[index].editors_links.split(","), this.state.value],
                        value: ""
                    });
                }


                console.log("key d : " + JSON.stringify(this.state.items.toString()))

                let list = [...this.state.BooksMonographs];
                list[index] = { ...list[index], [name]: this.state.items.toString() };
                //  console.log("list : "+JSON.stringify(list));
                this.setState({ BooksMonographs: list });
            }
        }
    }

    handleDeleteBooksMonographsAddSource = async (array, item, index, name) => {
        console.log('in del : ' + JSON.stringify(array));
        await this.setState({
            items: array.flat().filter((i) => i !== item)
        });
        let list = [...this.state.BooksMonographs];
        list[index] = { ...list[index], [name]: this.state.items.toString() };
        console.log("list : " + JSON.stringify(list));
        this.setState({ BooksMonographs: list });
    }

    handleSubmitBooksMonographs = (event) => {
        console.log("BooksMonographs:", this.state.BooksMonographs)
        event.preventDefault();
    }

    // Commitees
    handleChangeCommitees(i, e) {
        console.log(i);
        console.log(e.target);
        const { name, value } = e.target;
        let Commitees = [...this.state.Commitees];
        Commitees[i] = { ...Commitees[i], [name]: value };
        this.setState({ Commitees });
    }

    removeClickCommitees(i) {
        let Commitees = [...this.state.Commitees];
        Commitees.splice(i, 1);
        this.setState({ Commitees });
    }

    addClickCommitees() {
        this.setState(prevState => ({
            Commitees: [...prevState.Commitees, {
                // kol_id: this.props.location.state.emp_unique_id,
                kol_id: sessionStorage.getItem('emp_unique_id'),
                year: '',
                year_links: '',
                year_comment: '',
                year_status: '',
                committee: '',
                committee_links: '',
                committee_comment: '',
                committee_status: '',
                role_id: '',
                role_links: '',
                role_comment: '',
                role_status: '',
            }]
        }))
        this.setState({ items: [] })
    }

    handleChangeCommiteesAddSource = (evt) => {
        this.setState({
            value: evt.target.value,
            error: null
        });
    };

    handleKeyDownCommiteesAddSource = async (evt, index, name) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();
            console.log('name', name)
            console.log('index', index)

            var value = this.state.value.trim();
            if (value !== '') {
                console.log("ite input : " + JSON.stringify(this.state.Commitees[index].year_links))

                if (name == 'year_links') {
                    await this.setState({
                        items: [...this.state.Commitees[index].year_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'committee_links') {
                    await this.setState({
                        items: [...this.state.Commitees[index].committee_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'role_links') {
                    await this.setState({
                        items: [...this.state.Commitees[index].role_links.split(","), this.state.value],
                        value: ""
                    });
                }

                console.log("key d : " + JSON.stringify(this.state.items.toString()))

                let list = [...this.state.Commitees];
                list[index] = { ...list[index], [name]: this.state.items.toString() };
                //  console.log("list : "+JSON.stringify(list));
                this.setState({ Commitees: list });
            }
        }
    }

    handleDeleteCommiteesAddSource = async (array, item, index, name) => {
        console.log('in del : ' + JSON.stringify(array));
        await this.setState({
            items: array.flat().filter((i) => i !== item)
        });
        let list = [...this.state.Commitees];
        list[index] = { ...list[index], [name]: this.state.items.toString() };
        console.log("list : " + JSON.stringify(list));
        this.setState({ Commitees: list });
    }

    handleSubmitCommitees = (event) => {
        console.log("Commitees:", this.state.Commitees)
        event.preventDefault();
    }

    // Clinical trial details 
    handleChangeClinicalTrialDetails(i, e) {
        console.log(i);
        console.log(e.target);
        const { name, value } = e.target;
        if (name == 'primary_outcomes') {
            var re = /^[a-z\- ]+$/i;
            if (re.test(e.target.value)) {
                let ClinicalTrialDetails = [...this.state.ClinicalTrialDetails];
                ClinicalTrialDetails[i] = { ...ClinicalTrialDetails[i], [name]: value };
                this.setState({ ClinicalTrialDetails });
            }
            if (e.target.value.length == '0') {
                let ClinicalTrialDetails = [...this.state.ClinicalTrialDetails];
                ClinicalTrialDetails[i] = { ...ClinicalTrialDetails[i], [name]: '' };
                this.setState({ ClinicalTrialDetails });
            }
        }


        else if (name == 'secondary_outcomes') {
            var re = /^[a-z\- ]+$/i;
            if (re.test(e.target.value)) {
                let ClinicalTrialDetails = [...this.state.ClinicalTrialDetails];
                ClinicalTrialDetails[i] = { ...ClinicalTrialDetails[i], [name]: value };
                this.setState({ ClinicalTrialDetails });
            }
            if (e.target.value.length == '0') {
                let ClinicalTrialDetails = [...this.state.ClinicalTrialDetails];
                ClinicalTrialDetails[i] = { ...ClinicalTrialDetails[i], [name]: '' };
                this.setState({ ClinicalTrialDetails });
            }
        }
        else {
            let ClinicalTrialDetails = [...this.state.ClinicalTrialDetails];
            ClinicalTrialDetails[i] = { ...ClinicalTrialDetails[i], [name]: value };
            this.setState({ ClinicalTrialDetails });
        }
    }



    removeClickClinicalTrialDetails(i) {
        let ClinicalTrialDetails = [...this.state.ClinicalTrialDetails];
        ClinicalTrialDetails.splice(i, 1);
        this.setState({ ClinicalTrialDetails });
    }

    addClickClinicalTrialDetails() {
        this.setState(prevState => ({
            ClinicalTrialDetails: [...prevState.ClinicalTrialDetails, {
                // kol_id: this.props.location.state.emp_unique_id,
                kol_id: sessionStorage.getItem('emp_unique_id'),
                speciality_id: '',
                speciality_links: '',
                speciality_comment: '',
                speciality_status: '',
                sub_speciality: '',
                sub_speciality_links: '',
                sub_speciality_comment: '',
                sub_speciality_status: '',
                procedure_type: '',
                procedure_type_links: '',
                procedure_type_comment: '',
                procedure_type_status: '',
                study_details: '',
                study_details_links: '',
                study_details_comment: '',
                study_details_status: '',
                source_link: '',
                source_link_links: '',
                source_link_comment: '',
                source_link_status: '',
                start_date: '',
                start_date_links: '',
                start_date_comment: '',
                start_date_status: '',
                end_date: '',
                end_date_links: '',
                end_date_comment: '',
                end_date_status: '',
                type: '',
                type_links: '',
                type_comment: '',
                type_status: '',
                phase: '',
                phase_links: '',
                phase_comment: '',
                phase_status: '',
                role_id: '',
                role_links: '',
                role_comment: '',
                role_status: '',
                no_of_study_locations: '',
                no_of_study_locations_links: '',
                no_of_study_locations_comment: '',
                no_of_study_locations_status: '',
                conditions: '',
                conditions_links: '',
                conditions_comment: '',
                conditions_status: '',
                treatment_intervention: '',
                treatment_intervention_links: '',
                treatment_intervention_comment: '',
                treatment_intervention_status: '',
                primary_outcomes: '',
                primary_outcomes_links: '',
                primary_outcomes_comment: '',
                primary_outcomes_status: '',
                secondary_outcomes: '',
                secondary_outcomes_links: '',
                secondary_outcomes_comment: '',
                secondary_outcomes_status: '',
                sponsors: '',
                sponsors_links: '',
                sponsors_comment: '',
                sponsors_status: '',
                collaborators: '',
                collaborators_links: '',
                collaborators_comment: '',
                collaborators_status: '',
                manual_weightage: '',
            }]
        }))
        this.setState({ items: [] })
    }

    handleChangeClinicalAddSource = (evt) => {
        this.setState({
            value: evt.target.value,
            error: null
        });
    };

    handleKeyDownClinicalAddSource = async (evt, index, name) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();
            console.log('name', name)
            console.log('index', index)

            var value = this.state.value.trim();
            if (value !== '') {
                console.log("ite input : " + JSON.stringify(this.state.ClinicalTrialDetails[index].speciality_links))

                if (name == 'speciality_links') {
                    await this.setState({
                        items: [...this.state.ClinicalTrialDetails[index].speciality_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'sub_speciality_links') {
                    await this.setState({
                        items: [...this.state.ClinicalTrialDetails[index].sub_speciality_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'procedure_type_links') {
                    await this.setState({
                        items: [...this.state.ClinicalTrialDetails[index].procedure_type_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'study_details_links') {
                    await this.setState({
                        items: [...this.state.ClinicalTrialDetails[index].study_details_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'source_link_links') {
                    await this.setState({
                        items: [...this.state.ClinicalTrialDetails[index].source_link_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'start_date_links') {
                    await this.setState({
                        items: [...this.state.ClinicalTrialDetails[index].start_date_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'end_date_links') {
                    await this.setState({
                        items: [...this.state.ClinicalTrialDetails[index].end_date_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'type_links') {
                    await this.setState({
                        items: [...this.state.ClinicalTrialDetails[index].type_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'phase_links') {
                    await this.setState({
                        items: [...this.state.ClinicalTrialDetails[index].phase_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'role_links') {
                    await this.setState({
                        items: [...this.state.ClinicalTrialDetails[index].role_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'no_of_study_locations_links') {
                    await this.setState({
                        items: [...this.state.ClinicalTrialDetails[index].no_of_study_locations_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'conditions_links') {
                    await this.setState({
                        items: [...this.state.ClinicalTrialDetails[index].conditions_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'treatment_intervention_links') {
                    await this.setState({
                        items: [...this.state.ClinicalTrialDetails[index].treatment_intervention_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'primary_outcomes_links') {
                    await this.setState({
                        items: [...this.state.ClinicalTrialDetails[index].primary_outcomes_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'secondary_outcomes_links') {
                    await this.setState({
                        items: [...this.state.ClinicalTrialDetails[index].secondary_outcomes_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'sponsors_links') {
                    await this.setState({
                        items: [...this.state.ClinicalTrialDetails[index].sponsors_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'collaborators_links') {
                    await this.setState({
                        items: [...this.state.ClinicalTrialDetails[index].collaborators_links.split(","), this.state.value],
                        value: ""
                    });
                }

                console.log("key d : " + JSON.stringify(this.state.items.toString()))

                let list = [...this.state.ClinicalTrialDetails];
                list[index] = { ...list[index], [name]: this.state.items.toString() };
                //  console.log("list : "+JSON.stringify(list));
                this.setState({ ClinicalTrialDetails: list });
            }
        }
    }

    handleDeleteClinicalAddSource = async (array, item, index, name) => {
        console.log('in del : ' + JSON.stringify(array));
        await this.setState({
            items: array.flat().filter((i) => i !== item)
        });
        let list = [...this.state.ClinicalTrialDetails];
        list[index] = { ...list[index], [name]: this.state.items.toString() };
        console.log("list : " + JSON.stringify(list));
        this.setState({ ClinicalTrialDetails: list });
    }

    handleSubmitClinicalTrialDetails = (event) => {
        console.log("ClinicalTrialDetails:", this.state.ClinicalTrialDetails)
        event.preventDefault();
    }

    // Event details
    handleChangeEventsDetails(i, e) {
        console.log(i);
        console.log(e.target);
        const { name, value } = e.target;
        let EventsDetails = [...this.state.EventsDetails];
        EventsDetails[i] = { ...EventsDetails[i], [name]: value };
        this.setState({ EventsDetails });
    }

    removeClickEventsDetails(i) {
        let EventsDetails = [...this.state.EventsDetails];
        EventsDetails.splice(i, 1);
        this.setState({ EventsDetails });
    }

    addClickEventsDetails() {
        this.setState(prevState => ({
            EventsDetails: [...prevState.EventsDetails, {
                // kol_id: this.props.location.state.emp_unique_id,
                kol_id: sessionStorage.getItem('emp_unique_id'),
                speciality_id: '',
                speciality_links: '',
                speciality_comment: '',
                speciality_status: '',
                sub_speciality: '',
                sub_speciality_links: '',
                sub_speciality_comment: '',
                sub_speciality_status: '',
                procedure_type: '',
                procedure_type_links: '',
                procedure_type_comment: '',
                procedure_type_status: '',
                events: '',
                events_links: '',
                events_comment: '',
                events_status: '',
                event_type: '',
                event_type_links: '',
                event_type_comment: '',
                event_type_status: '',
                event_desc: '',
                event_desc_links: '',
                event_desc_comment: '',
                event_desc_status: '',
                event_topic: '',
                event_topic_links: '',
                event_topic_comment: '',
                event_topic_status: '',
                source_link: '',
                source_link_links: '',
                source_link_comment: '',
                source_link_status: '',
                conditions: '',
                conditions_links: '',
                conditions_comment: '',
                conditions_status: '',
                intervention: '',
                intervention_links: '',
                intervention_comment: '',
                intervention_status: '',
                date: '',
                date_links: '',
                date_comment: '',
                date_status: '',
                session_type: '',
                session_type_links: '',
                session_type_comment: '',
                session_type_status: '',
                role_id: '',
                role_links: '',
                role_comment: '',
                role_status: '',
                event_sponsor: '',
                event_sponsor_links: '',
                event_sponsor_comment: '',
                event_sponsor_status: '',
                total_event_attendees: '',
                total_event_attendees_links: '',
                total_event_attendees_comment: '',
                total_event_attendees_status: '',
                country_id: '',
                country_links: '',
                country_comment: '',
                country_status: '',
                manual_weightage: '',
            }]
        }))
        this.setState({ items: [] })
    }

    handleChangeEventAddSource = (evt) => {
        this.setState({
            value: evt.target.value,
            error: null
        });
    };

    handleKeyDownEventAddSource = async (evt, index, name) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();
            console.log('name', name)
            console.log('index', index)

            var value = this.state.value.trim();
            if (value !== '') {
                console.log("ite input : " + JSON.stringify(this.state.EventsDetails[index].speciality_links))

                if (name == 'speciality_links') {
                    await this.setState({
                        items: [...this.state.EventsDetails[index].speciality_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'sub_speciality_links') {
                    await this.setState({
                        items: [...this.state.EventsDetails[index].sub_speciality_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'procedure_type_links') {
                    await this.setState({
                        items: [...this.state.EventsDetails[index].procedure_type_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'events_links') {
                    await this.setState({
                        items: [...this.state.EventsDetails[index].events_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'event_type_links') {
                    await this.setState({
                        items: [...this.state.EventsDetails[index].event_type_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'event_desc_links') {
                    await this.setState({
                        items: [...this.state.EventsDetails[index].event_desc_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'event_topic_links') {
                    await this.setState({
                        items: [...this.state.EventsDetails[index].event_topic_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'source_link_links') {
                    await this.setState({
                        items: [...this.state.EventsDetails[index].source_link_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'conditions_links') {
                    await this.setState({
                        items: [...this.state.EventsDetails[index].conditions_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'intervention_links') {
                    await this.setState({
                        items: [...this.state.EventsDetails[index].intervention_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'date_links') {
                    await this.setState({
                        items: [...this.state.EventsDetails[index].date_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'session_type_links') {
                    await this.setState({
                        items: [...this.state.EventsDetails[index].session_type_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'role_links') {
                    await this.setState({
                        items: [...this.state.EventsDetails[index].role_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'event_sponsor_links') {
                    await this.setState({
                        items: [...this.state.EventsDetails[index].event_sponsor_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'total_event_attendees_links') {
                    await this.setState({
                        items: [...this.state.EventsDetails[index].total_event_attendees_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'country_links') {
                    await this.setState({
                        items: [...this.state.EventsDetails[index].country_links.split(","), this.state.value],
                        value: ""
                    });
                }

                console.log("key d : " + JSON.stringify(this.state.items.toString()))

                let list = [...this.state.EventsDetails];
                list[index] = { ...list[index], [name]: this.state.items.toString() };
                //  console.log("list : "+JSON.stringify(list));
                this.setState({ EventsDetails: list });
            }
        }
    }

    handleDeleteEventAddSource = async (array, item, index, name) => {
        console.log('in del : ' + JSON.stringify(array));
        await this.setState({
            items: array.flat().filter((i) => i !== item)
        });
        let list = [...this.state.EventsDetails];
        list[index] = { ...list[index], [name]: this.state.items.toString() };
        console.log("list : " + JSON.stringify(list));
        this.setState({ EventsDetails: list });
    }

    handleSubmitEventsDetails = (event) => {
        console.log("EventsDetails:", this.state.EventsDetails)
        event.preventDefault();
    }

    // Grants
    handleChangeGrants(i, e) {
        console.log(i);
        console.log(e.target);
        const { name, value } = e.target;
        let Grants = [...this.state.Grants];
        Grants[i] = { ...Grants[i], [name]: value };
        this.setState({ Grants });
    }

    removeClickGrants(i) {
        let Grants = [...this.state.Grants];
        Grants.splice(i, 1);
        this.setState({ Grants });
    }

    addClickGrants() {
        this.setState(prevState => ({
            Grants: [...prevState.Grants, {
                // kol_id: this.props.location.state.emp_unique_id,
                kol_id: sessionStorage.getItem('emp_unique_id'),
                company: '',
                company_links: '',
                company_comment: '',
                company_status: '',
                title: '',
                title_links: '',
                title_comment: '',
                title_status: '',
                year: '',
                year_links: '',
                year_comment: '',
                year_status: '',
                amount: '',
                amount_links: '',
                amount_comment: '',
                amount_status: '',
                co_investigator: '',
                co_investigator_links: '',
                co_investigator_comment: '',
                co_investigator_status: '',
            }]
        }))
        this.setState({ items: [] })
    }

    handleChangeGrantsAddSource = (evt) => {
        this.setState({
            value: evt.target.value,
            error: null
        });
    };

    handleKeyDownGrantsAddSource = async (evt, index, name) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();
            console.log('name', name)
            console.log('index', index)

            var value = this.state.value.trim();
            if (value !== '') {
                console.log("ite input : " + JSON.stringify(this.state.Grants[index].company_links))

                if (name == 'company_links') {
                    await this.setState({
                        items: [...this.state.Grants[index].company_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'title_links') {
                    await this.setState({
                        items: [...this.state.Grants[index].title_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'year_links') {
                    await this.setState({
                        items: [...this.state.Grants[index].year_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'amount_links') {
                    await this.setState({
                        items: [...this.state.Grants[index].amount_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'co_investigator_links') {
                    await this.setState({
                        items: [...this.state.Grants[index].co_investigator_links.split(","), this.state.value],
                        value: ""
                    });
                }

                console.log("key d : " + JSON.stringify(this.state.items.toString()))

                let list = [...this.state.Grants];
                list[index] = { ...list[index], [name]: this.state.items.toString() };
                //  console.log("list : "+JSON.stringify(list));
                this.setState({ Grants: list });
            }
        }
    }

    handleDeleteGrantsAddSource = async (array, item, index, name) => {
        console.log('in del : ' + JSON.stringify(array));
        await this.setState({
            items: array.flat().filter((i) => i !== item)
        });
        let list = [...this.state.Grants];
        list[index] = { ...list[index], [name]: this.state.items.toString() };
        console.log("list : " + JSON.stringify(list));
        this.setState({ Grants: list });
    }

    handleSubmitGrants = (event) => {
        console.log("Grants:", this.state.Grants)
        event.preventDefault();
    }

    // Guidelines
    handleChangeGuidelines(i, e) {
        console.log(i);
        console.log(e.target);
        const { name, value } = e.target;
        let Guidelines = [...this.state.Guidelines];
        Guidelines[i] = { ...Guidelines[i], [name]: value };
        this.setState({ Guidelines });
    }

    removeClickGuidelines(i) {
        let Guidelines = [...this.state.Guidelines];
        Guidelines.splice(i, 1);
        this.setState({ Guidelines });
    }

    addClickGuidelines() {
        this.setState(prevState => ({
            Guidelines: [...prevState.Guidelines, {
                // kol_id: this.props.location.state.emp_unique_id,
                kol_id: sessionStorage.getItem('emp_unique_id'),
                speciality_id: '',
                speciality_links: '',
                speciality_comment: '',
                speciality_status: '',
                sub_speciality: '',
                sub_speciality_links: '',
                sub_speciality_comment: '',
                sub_speciality_status: '',
                procedure_type: '',
                procedure_type_links: '',
                procedure_type_comment: '',
                procedure_type_status: '',
                year: '',
                year_links: '',
                year_comment: '',
                year_status: '',
                organization: '',
                organization_links: '',
                organization_comment: '',
                organization_status: '',
                type_of_organization: '',
                type_of_organization_links: '',
                type_of_organization_comment: '',
                type_of_organization_status: '',
                company: '',
                company_links: '',
                company_comment: '',
                company_status: '',
                title: '',
                title_links: '',
                title_comment: '',
                title_status: '',
                manual_weightage: '',
            }]
        }))
        this.setState({ items: [] })
    }

    handleChangeGuidelinesAddSource = (evt) => {
        this.setState({
            value: evt.target.value,
            error: null
        });
    };

    handleKeyDownGuidelinesAddSource = async (evt, index, name) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();
            console.log('name', name)
            console.log('index', index)

            var value = this.state.value.trim();
            if (value !== '') {
                console.log("ite input : " + JSON.stringify(this.state.Guidelines[index].speciality_links))

                if (name == 'speciality_links') {
                    await this.setState({
                        items: [...this.state.Guidelines[index].speciality_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'sub_speciality_links') {
                    await this.setState({
                        items: [...this.state.Guidelines[index].sub_speciality_links.split(","), this.state.value],
                        value: ""
                    });
                }

                else if (name == 'procedure_type_links') {
                    await this.setState({
                        items: [...this.state.Guidelines[index].procedure_type_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'year_links') {
                    await this.setState({
                        items: [...this.state.Guidelines[index].year_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'organization_links') {
                    await this.setState({
                        items: [...this.state.Guidelines[index].organization_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'type_of_organization_links') {
                    await this.setState({
                        items: [...this.state.Guidelines[index].type_of_organization_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'title_links') {
                    await this.setState({
                        items: [...this.state.Guidelines[index].title_links.split(","), this.state.value],
                        value: ""
                    });
                }

                console.log("key d : " + JSON.stringify(this.state.items.toString()))

                let list = [...this.state.Guidelines];
                list[index] = { ...list[index], [name]: this.state.items.toString() };
                //  console.log("list : "+JSON.stringify(list));
                this.setState({ Guidelines: list });
            }
        }
    }

    handleDeleteGuidelinessAddSource = async (array, item, index, name) => {
        console.log('in del : ' + JSON.stringify(array));
        await this.setState({
            items: array.flat().filter((i) => i !== item)
        });
        let list = [...this.state.Guidelines];
        list[index] = { ...list[index], [name]: this.state.items.toString() };
        console.log("list : " + JSON.stringify(list));
        this.setState({ Guidelines: list });
    }

    handleSubmitGuidelines = (event) => {
        console.log("Guidelines:", this.state.Guidelines)
        event.preventDefault();
    }

    // HospitalAffiliations
    handleChangeHospitalAffiliations(i, e) {
        console.log(i);
        console.log(e.target);
        console.log(e.target.name);
        console.log(e.target.value);
        const { name, value } = e.target;
        let HospitalAffiliations = [...this.state.HospitalAffiliations];
        HospitalAffiliations[i] = { ...HospitalAffiliations[i], [name]: value };
        this.setState({ HospitalAffiliations });

        if (e.target.name == "country_id") {
            console.log("yes this is country id")

            const url = global.websiteUrl+'/service/Location/get_StateList';
            let formData = new FormData();
            formData.append('country_id', e.target.value);

            const config = {
                //   headers: { 'content-type': 'multipart/form-data' }
            }
            axios.post(url, formData, config)
                .then(response => {
                    console.log(response);
                    var testStateData = response.data.Country_Details;
                    console.log("testStateData", JSON.stringify(testStateData));
                    this.setState({
                        StateDataForHospitalAffiliations: response.data.Country_Details,
                    })
                    console.log("StateDataForHospitalAffiliations===", this.state.StateDataForHospitalAffiliations)
                })
                .catch(error => {
                    console.log(error);
                });
        }

        if (e.target.name == "country_id") {
            console.log("yes this is country id 2")

            const url = global.websiteUrl+'/service/Location/get_CityList';
            let formData = new FormData();
            formData.append('country_id', e.target.value);

            const config = {
                //   headers: { 'content-type': 'multipart/form-data' }
            }
            axios.post(url, formData, config)
                .then(response => {
                    console.log(response);
                    var testCityData = response.data.Country_Details;
                    console.log("testCityData", JSON.stringify(testCityData));
                    this.setState({
                        CityDataForHospitalAffiliations: response.data.Country_Details,
                    })
                    console.log("CityDataForHospitalAffiliations====", this.state.CityDataForHospitalAffiliations)
                })
                .catch(error => {
                    console.log(error);
                });

        }
    }

    removeClickHospitalAffiliations(i) {
        let HospitalAffiliations = [...this.state.HospitalAffiliations];
        HospitalAffiliations.splice(i, 1);
        this.setState({ HospitalAffiliations });
    }

    addClickHospitalAffiliations() {
        this.setState(prevState => ({
            HospitalAffiliations: [...prevState.HospitalAffiliations, {
                // kol_id: this.props.location.state.emp_unique_id,
                kol_id: sessionStorage.getItem('emp_unique_id'),
                hospital: '',
                hospital_links: '',
                hospital_comment: '',
                hospital_status: '',
                city_id: '',
                city_links: '',
                city_comment: '',
                city_status: '',
                state_id: '',
                state_links: '',
                state_comment: '',
                state_status: '',
                country_id: '',
                country_links: '',
                country_comment: '',
                country_status: '',
            }]
        }))
        this.setState({ items: [] })
    }

    handleChangeHospitalAddSource = (evt) => {
        this.setState({
            value: evt.target.value,
            error: null
        });
    };

    handleKeyDownHospitalAddSource = async (evt, index, name) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();
            console.log('name', name)
            console.log('index', index)

            var value = this.state.value.trim();
            if (value !== '') {
                console.log("ite input : " + JSON.stringify(this.state.HospitalAffiliations[index].hospital_links))

                if (name == 'hospital_links') {
                    await this.setState({
                        items: [...this.state.HospitalAffiliations[index].hospital_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'city_links') {
                    await this.setState({
                        items: [...this.state.HospitalAffiliations[index].city_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'state_links') {
                    await this.setState({
                        items: [...this.state.HospitalAffiliations[index].state_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'country_links') {
                    await this.setState({
                        items: [...this.state.HospitalAffiliations[index].country_links.split(","), this.state.value],
                        value: ""
                    });
                }

                console.log("key d : " + JSON.stringify(this.state.items.toString()))

                let list = [...this.state.HospitalAffiliations];
                list[index] = { ...list[index], [name]: this.state.items.toString() };
                //  console.log("list : "+JSON.stringify(list));
                this.setState({ HospitalAffiliations: list });
            }
        }
    }

    handleDeleteHospitalAddSource = async (array, item, index, name) => {
        console.log('in del : ' + JSON.stringify(array));
        await this.setState({
            items: array.flat().filter((i) => i !== item)
        });
        let list = [...this.state.HospitalAffiliations];
        list[index] = { ...list[index], [name]: this.state.items.toString() };
        console.log("list : " + JSON.stringify(list));
        this.setState({ HospitalAffiliations: list });
    }

    handleSubmitHospitalAffiliations = (event) => {
        console.log("HospitalAffiliations:", this.state.HospitalAffiliations)
        event.preventDefault();
    }

    // Interactions
    handleChangeInteractions(i, e) {
        console.log(i);
        console.log(e.target);
        const { name, value } = e.target;
        let Interactions = [...this.state.Interactions];
        Interactions[i] = { ...Interactions[i], [name]: value };
        this.setState({ Interactions });
    }

    removeClickInteractions(i) {
        let Interactions = [...this.state.Interactions];
        Interactions.splice(i, 1);
        this.setState({ Interactions });
    }

    addClickInteractions() {
        this.setState(prevState => ({
            Interactions: [...prevState.Interactions, {
                // kol_id: this.props.location.state.emp_unique_id,
                kol_id: sessionStorage.getItem('emp_unique_id'),
                date: '',
                date_links: '',
                date_comment: '',
                date_status: '',
                opinion_leader: '',
                opinion_leader_links: '',
                opinion_leader_comment: '',
                opinion_leader_status: '',
                entered_by: '',
                entered_by_links: '',
                entered_by_comment: '',
                entered_by_status: '',
                product: '',
                product_links: '',
                product_comment: '',
                product_status: '',
                type: '',
                type_links: '',
                type_comment: '',
                type_status: '',
                mode: '',
                mode_links: '',
                mode_comment: '',
                mode_status: '',
                time: '',
                time_links: '',
                time_comment: '',
                time_status: '',
                activity_or_tool_used: '',
                activity_or_tool_used_links: '',
                activity_or_tool_used_comment: '',
                activity_or_tool_used_status: '',
                comments: '',
                comments_links: '',
                comments_comment: '',
                comments_status: '',
            }]
        }))
        this.setState({ items: [] })
    }

    handleChangeInteractionAddSource = (evt) => {
        this.setState({
            value: evt.target.value,
            error: null
        });
    };

    handleKeyDownInteractionAddSource = async (evt, index, name) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();
            console.log('name', name)
            console.log('index', index)

            var value = this.state.value.trim();
            if (value !== '') {
                console.log("ite input : " + JSON.stringify(this.state.Interactions[index].date_links))

                if (name == 'date_links') {
                    await this.setState({
                        items: [...this.state.Interactions[index].date_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'opinion_leader_links') {
                    await this.setState({
                        items: [...this.state.Interactions[index].opinion_leader_links.split(","), this.state.value],
                        value: ""
                    });
                }

                else if (name == 'entered_by_links') {
                    await this.setState({
                        items: [...this.state.Interactions[index].entered_by_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'product_links') {
                    await this.setState({
                        items: [...this.state.Interactions[index].product_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'type_links') {
                    await this.setState({
                        items: [...this.state.Interactions[index].type_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'mode_links') {
                    await this.setState({
                        items: [...this.state.Interactions[index].mode_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'time_links') {
                    await this.setState({
                        items: [...this.state.Interactions[index].time_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'activity_or_tool_used_links') {
                    await this.setState({
                        items: [...this.state.Interactions[index].activity_or_tool_used_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'comments_links') {
                    await this.setState({
                        items: [...this.state.Interactions[index].comments_links.split(","), this.state.value],
                        value: ""
                    });
                }

                console.log("key d : " + JSON.stringify(this.state.items.toString()))

                let list = [...this.state.Interactions];
                list[index] = { ...list[index], [name]: this.state.items.toString() };
                //  console.log("list : "+JSON.stringify(list));
                this.setState({ Interactions: list });
            }
        }
    }

    handleDeleteInteractionAddSource = async (array, item, index, name) => {
        console.log('in del : ' + JSON.stringify(array));
        await this.setState({
            items: array.flat().filter((i) => i !== item)
        });
        let list = [...this.state.Interactions];
        list[index] = { ...list[index], [name]: this.state.items.toString() };
        console.log("list : " + JSON.stringify(list));
        this.setState({ Interactions: list });
    }

    handleSubmitInteractions = (event) => {
        console.log("Interactions:", this.state.Interactions)
        event.preventDefault();
    }

    // Press Item
    handleChangePressItem(i, e) {
        console.log(i);
        console.log(e.target);
        const { name, value } = e.target;
        let PressItem = [...this.state.PressItem];
        PressItem[i] = { ...PressItem[i], [name]: value };
        this.setState({ PressItem });
    }

    removeClickPressItem(i) {
        let PressItem = [...this.state.PressItem];
        PressItem.splice(i, 1);
        this.setState({ PressItem });
    }

    addClickPressItem() {
        this.setState(prevState => ({
            PressItem: [...prevState.PressItem, {
                // kol_id: this.props.location.state.emp_unique_id,
                kol_id: sessionStorage.getItem('emp_unique_id'),
                title: '',
                title_links: '',
                title_comment: '',
                title_status: '',
                source_link: '',
                source_link_links: '',
                source_link_comment: '',
                source_link_status: '',
                date: '',
                date_links: '',
                date_comment: '',
                date_status: '',
            }]
        }))
        this.setState({ items: [] })
    }

    handleChangePressAddSource = (evt) => {
        this.setState({
            value: evt.target.value,
            error: null
        });
    };

    handleKeyDownPressAddSource = async (evt, index, name) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();
            console.log('name', name)
            console.log('index', index)

            var value = this.state.value.trim();
            if (value !== '') {
                console.log("ite input : " + JSON.stringify(this.state.PressItem[index].title_links))

                if (name == 'title_links') {
                    await this.setState({
                        items: [...this.state.PressItem[index].title_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'source_link_links') {
                    await this.setState({
                        items: [...this.state.PressItem[index].source_link_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'date_links') {
                    await this.setState({
                        items: [...this.state.PressItem[index].date_links.split(","), this.state.value],
                        value: ""
                    });
                }


                console.log("key d : " + JSON.stringify(this.state.items.toString()))

                let list = [...this.state.PressItem];
                list[index] = { ...list[index], [name]: this.state.items.toString() };
                //  console.log("list : "+JSON.stringify(list));
                this.setState({ PressItem: list });
            }
        }
    }

    handleDeletePressAddSource = async (array, item, index, name) => {
        console.log('in del : ' + JSON.stringify(array));
        await this.setState({
            items: array.flat().filter((i) => i !== item)
        });
        let list = [...this.state.PressItem];
        list[index] = { ...list[index], [name]: this.state.items.toString() };
        console.log("list : " + JSON.stringify(list));
        this.setState({ PressItem: list });
    }

    handleSubmitPressItem = (event) => {
        console.log("PressItem:", this.state.PressItem)
        event.preventDefault();
    }

    // Presentation
    handleChangePresentation(i, e) {
        console.log(i);
        console.log(e.target);
        const { name, value } = e.target;
        let Presentation = [...this.state.Presentation];
        Presentation[i] = { ...Presentation[i], [name]: value };
        this.setState({ Presentation });
    }

    removeClickPresentation(i) {
        let Presentation = [...this.state.Presentation];
        Presentation.splice(i, 1);
        this.setState({ Presentation });
    }

    addClickPresentation() {
        this.setState(prevState => ({
            Presentation: [...prevState.Presentation, {
                // kol_id: this.props.location.state.emp_unique_id,
                kol_id: sessionStorage.getItem('emp_unique_id'),
                title: '',
                title_links: '',
                title_comment: '',
                title_status: '',
                type: '',
                type_links: '',
                type_comment: '',
                type_status: '',
                sponsor: '',
                sponsor_links: '',
                sponsor_comment: '',
                sponsor_status: '',
                location: '',
                location_links: '',
                location_comment: '',
                location_status: '',
                date: '',
                date_links: '',
                date_comment: '',
                date_status: '',
            }]
        }))
        this.setState({ items: [] })
    }

    handleChangePresentationAddSource = (evt) => {
        this.setState({
            value: evt.target.value,
            error: null
        });
    };

    handleKeyDownPresentationAddSource = async (evt, index, name) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();
            console.log('name', name)
            console.log('index', index)

            var value = this.state.value.trim();
            if (value !== '') {
                console.log("ite input : " + JSON.stringify(this.state.Presentation[index].title_links))

                if (name == 'title_links') {
                    await this.setState({
                        items: [...this.state.Presentation[index].title_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'type_links') {
                    await this.setState({
                        items: [...this.state.Presentation[index].type_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'sponsor_links') {
                    await this.setState({
                        items: [...this.state.Presentation[index].sponsor_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'location_links') {
                    await this.setState({
                        items: [...this.state.Presentation[index].location_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'date_links') {
                    await this.setState({
                        items: [...this.state.Presentation[index].date_links.split(","), this.state.value],
                        value: ""
                    });
                }

                console.log("key d : " + JSON.stringify(this.state.items.toString()))

                let list = [...this.state.Presentation];
                list[index] = { ...list[index], [name]: this.state.items.toString() };
                //  console.log("list : "+JSON.stringify(list));
                this.setState({ Presentation: list });
            }
        }
    }

    handleDeletePresentationAddSource = async (array, item, index, name) => {
        console.log('in del : ' + JSON.stringify(array));
        await this.setState({
            items: array.flat().filter((i) => i !== item)
        });
        let list = [...this.state.Presentation];
        list[index] = { ...list[index], [name]: this.state.items.toString() };
        console.log("list : " + JSON.stringify(list));
        this.setState({ Presentation: list });
    }

    handleSubmitPresentation = (event) => {
        console.log("Presentation:", this.state.Presentation)
        event.preventDefault();
    }

    // Publication Details
    handleChangePublicationDetails(i, e) {
        console.log(i);
        console.log(e.target);
        const { name, value } = e.target;
        let PublicationDetails = [...this.state.PublicationDetails];
        PublicationDetails[i] = { ...PublicationDetails[i], [name]: value };
        this.setState({ PublicationDetails });
    }

    removeClickPublicationDetails(i) {
        let PublicationDetails = [...this.state.PublicationDetails];
        PublicationDetails.splice(i, 1);
        this.setState({ PublicationDetails });
    }

    addClickPublicationDetails() {
        this.setState(prevState => ({
            PublicationDetails: [...prevState.PublicationDetails, {
                // kol_id: this.props.location.state.emp_unique_id,
                kol_id: sessionStorage.getItem('emp_unique_id'),
                speciality_id: '',
                speciality_links: '',
                speciality_comment: '',
                speciality_status: '',
                sub_speciality: '',
                sub_speciality_links: '',
                sub_speciality_comment: '',
                sub_speciality_status: '',
                procedure_type: '',
                procedure_type_links: '',
                procedure_type_comment: '',
                procedure_type_status: '',
                journal: '',
                journal_links: '',
                journal_comment: '',
                journal_status: '',
                type_of_journal: '',
                type_of_journal_links: '',
                type_of_journal_comment: '',
                type_of_journal_status: '',
                publication_title: '',
                publication_title_links: '',
                publication_title_comment: '',
                publication_title_status: '',
                date: '',
                date_links: '',
                date_comment: '',
                date_status: '',
                source_link: '',
                source_link_links: '',
                source_link_comment: '',
                source_link_status: '',
                position: '',
                position_links: '',
                position_comment: '',
                position_status: '',
                co_author: '',
                co_author_links: '',
                co_author_comment: '',
                co_author_status: '',
                key_words: '',
                key_words_links: '',
                key_words_comment: '',
                key_words_status: '',
                manual_weightage: '',
            }]
        }))
        this.setState({ items: [] })
    }

    handleChangePublicationAddSource = (evt) => {
        this.setState({
            value: evt.target.value,
            error: null
        });
    };

    handleKeyDownPublicationAddSource = async (evt, index, name) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();
            console.log('name', name)
            console.log('index', index)

            var value = this.state.value.trim();
            if (value !== '') {
                console.log("ite input : " + JSON.stringify(this.state.PublicationDetails[index].speciality_links))

                if (name == 'speciality_links') {
                    await this.setState({
                        items: [...this.state.PublicationDetails[index].speciality_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'sub_speciality_links') {
                    await this.setState({
                        items: [...this.state.PublicationDetails[index].sub_speciality_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'procedure_type_links') {
                    await this.setState({
                        items: [...this.state.PublicationDetails[index].procedure_type_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'journal_links') {
                    await this.setState({
                        items: [...this.state.PublicationDetails[index].journal_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'type_of_journal_links') {
                    await this.setState({
                        items: [...this.state.PublicationDetails[index].type_of_journal_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'publication_title_links') {
                    await this.setState({
                        items: [...this.state.PublicationDetails[index].publication_title_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'date_links') {
                    await this.setState({
                        items: [...this.state.PublicationDetails[index].date_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'source_link_links') {
                    await this.setState({
                        items: [...this.state.PublicationDetails[index].source_link_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'position_links') {
                    await this.setState({
                        items: [...this.state.PublicationDetails[index].position_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'co_author_links') {
                    await this.setState({
                        items: [...this.state.PublicationDetails[index].co_author_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'key_words_links') {
                    await this.setState({
                        items: [...this.state.PublicationDetails[index].key_words_links.split(","), this.state.value],
                        value: ""
                    });
                }

                console.log("key d : " + JSON.stringify(this.state.items.toString()))

                let list = [...this.state.PublicationDetails];
                list[index] = { ...list[index], [name]: this.state.items.toString() };
                //  console.log("list : "+JSON.stringify(list));
                this.setState({ PublicationDetails: list });
            }
        }
    }

    handleDeletePublicationAddSource = async (array, item, index, name) => {
        console.log('in del : ' + JSON.stringify(array));
        await this.setState({
            items: array.flat().filter((i) => i !== item)
        });
        let list = [...this.state.PublicationDetails];
        list[index] = { ...list[index], [name]: this.state.items.toString() };
        console.log("list : " + JSON.stringify(list));
        this.setState({ PublicationDetails: list });
    }

    handleSubmitPublicationDetails = (event) => {
        console.log("PublicationDetails:", this.state.PublicationDetails)
        event.preventDefault();
    }

    // Sunshine Payment
    handleChangeSunshinePayment(i, e) {
        console.log(i);
        console.log(e.target);
        const { name, value } = e.target;
        if (name == 'NPI') {
            var re = /^[a-z0-9]+$/i;
            if (re.test(e.target.value)) {
                let SunshinePayment = [...this.state.SunshinePayment];
                SunshinePayment[i] = { ...SunshinePayment[i], [name]: value };
                this.setState({ SunshinePayment });
            }
            if (e.target.value.length == '0') {
                let SunshinePayment = [...this.state.SunshinePayment];
                SunshinePayment[i] = { ...SunshinePayment[i], [name]: '' };
                this.setState({ SunshinePayment });
            }
        }
        else {
            let SunshinePayment = [...this.state.SunshinePayment];
            SunshinePayment[i] = { ...SunshinePayment[i], [name]: value };
            this.setState({ SunshinePayment });
        }
    }

    removeClickSunshinePayment(i) {
        let SunshinePayment = [...this.state.SunshinePayment];
        SunshinePayment.splice(i, 1);
        this.setState({ SunshinePayment });
    }

    addClickSunshinePayment() {
        this.setState(prevState => ({
            SunshinePayment: [...prevState.SunshinePayment, {
                // kol_id: this.props.location.state.emp_unique_id,
                kol_id: sessionStorage.getItem('emp_unique_id'),
                date: '',
                date_links: '',
                date_comment: '',
                date_status: '',
                type: '',
                type_links: '',
                type_comment: '',
                type_status: '',
                devices: '',
                devices_links: '',
                devices_comment: '',
                devices_status: '',
                location: '',
                location_links: '',
                location_comment: '',
                location_status: '',
                amount: '',
                amount_links: '',
                amount_comment: '',
                amount_status: '',
                payment_type: '',
                payment_type_links: '',
                payment_type_comment: '',
                payment_type_status: '',
                OL_address: '',
                OL_address_links: '',
                OL_address_comment: '',
                OL_address_status: '',
                speciality_type_id: '',
                speciality_type_links: '',
                speciality_type_comment: '',
                speciality_type_status: '',
                NPI: '',
                NPI_links: '',
                NPI_comment: '',
                NPI_status: '',
            }]
        }))
        this.setState({ items: [] })
    }

    handleChangeSunshineAddSource = (evt) => {
        this.setState({
            value: evt.target.value,
            error: null
        });
    };

    handleKeyDownSunshineAddSource = async (evt, index, name) => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();
            console.log('name', name)
            console.log('index', index)

            var value = this.state.value.trim();
            if (value !== '') {
                console.log("ite input : " + JSON.stringify(this.state.SunshinePayment[index].date_links))

                if (name == 'date_links') {
                    await this.setState({
                        items: [...this.state.SunshinePayment[index].date_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'type_links') {
                    await this.setState({
                        items: [...this.state.SunshinePayment[index].type_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'devices_links') {
                    await this.setState({
                        items: [...this.state.SunshinePayment[index].devices_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'location_links') {
                    await this.setState({
                        items: [...this.state.SunshinePayment[index].location_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'amount_links') {
                    await this.setState({
                        items: [...this.state.SunshinePayment[index].amount_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'payment_type_links') {
                    await this.setState({
                        items: [...this.state.SunshinePayment[index].payment_type_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'OL_address_links') {
                    await this.setState({
                        items: [...this.state.SunshinePayment[index].OL_address_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'speciality_type_links') {
                    await this.setState({
                        items: [...this.state.SunshinePayment[index].speciality_type_links.split(","), this.state.value],
                        value: ""
                    });
                }
                else if (name == 'NPI_links') {
                    await this.setState({
                        items: [...this.state.SunshinePayment[index].NPI_links.split(","), this.state.value],
                        value: ""
                    });
                }

                console.log("key d : " + JSON.stringify(this.state.items.toString()))

                let list = [...this.state.SunshinePayment];
                list[index] = { ...list[index], [name]: this.state.items.toString() };
                //  console.log("list : "+JSON.stringify(list));
                this.setState({ SunshinePayment: list });
            }
        }
    }

    handleDeleteSunshineAddSource = async (array, item, index, name) => {
        console.log('in del : ' + JSON.stringify(array));
        await this.setState({
            items: array.flat().filter((i) => i !== item)
        });
        let list = [...this.state.SunshinePayment];
        list[index] = { ...list[index], [name]: this.state.items.toString() };
        console.log("list : " + JSON.stringify(list));
        this.setState({ SunshinePayment: list });
    }

    handleSubmitSunshinePayment = (event) => {
        console.log("SunshinePayment:", this.state.SunshinePayment)
        event.preventDefault();
    }

    // set procedure potential
    setProcedurePotential = async (event) => {
        // console.log(event.target.value);
        // await this.setState({ procedure_potential: event.target.value });
        let { PersonalInformation } = this.state;
        PersonalInformation.procedure_potential = event.target.value;
        await this.setState({ PersonalInformation });
    }

    // after Selecting KOL type from personal info
    afterSelectingKOL = () => {
        console.log("in function yeeeeeeeeeeee")
        console.log("personalinformation.koltype", this.state.PersonalInformation.kol_type)

        if (this.state.PersonalInformation.kol_type == 'Payers Community' ||
            this.state.PersonalInformation.kol_type == 'HTA Experts' ||
            this.state.PersonalInformation.kol_type == 'Clinical Commission Group') {
            this.setState({
                PressItem: [{
                    // kol_id: this.props.location.state.emp_unique_id,
                    kol_id: sessionStorage.getItem('emp_unique_id'),
                    title: '',
                    title_links: '',
                    source_link: '',
                    source_link_links: '',
                    date: '',
                    date_links: '',
                }],
                // Presentation
                Presentation: [{
                    // kol_id: this.props.location.state.emp_unique_id,
                    kol_id: sessionStorage.getItem('emp_unique_id'),
                    title: '',
                    title_links: '',
                    type: '',
                    type_links: '',
                    sponsor: '',
                    sponsor_links: '',
                    location: '',
                    location_links: '',
                    date: '',
                    date_links: '',
                }],
            })

            let { Biography } = this.state;
            Biography.licensed_regions = '';
            Biography.licensed_regions_links = '';
            Biography.work_phone = '';
            Biography.work_phone_links = '';
            Biography.work_email = '';
            Biography.work_email_links = '';
            Biography.twitter_followers = '';
            Biography.twitter_followers_links = '';
            Biography.biography = '';
            Biography.biography_links = '';
            this.setState({ Biography });



            console.log('Press Item after selectig kol different', this.state.PressItem)
        }

    }

    // after selection salutation
    afterSelectSalutation = () => {
        console.log("in function yeeeeeeeeeeee")
        console.log("personalinformation.koltype", this.state.PersonalInformation.kol_type)
        console.log("personalinformation.salutation", this.state.PersonalInformation.salutation)

        if (this.state.PersonalInformation.kol_type) {
            if (this.state.PersonalInformation.kol_type == 'Payers Community' ||
                this.state.PersonalInformation.kol_type == 'HTA Experts' ||
                this.state.PersonalInformation.kol_type == 'Clinical Commission Group') {
                if (this.state.PersonalInformation.salutation == 'Mr.' ||
                    this.state.PersonalInformation.salutation == 'Ms.' ||
                    this.state.PersonalInformation.salutation == 'Mrs.' ||
                    this.state.PersonalInformation.salutation == 'Prof.' ||
                    this.state.PersonalInformation.salutation == 'Other') {
                    let { PersonalInformation } = this.state;
                    PersonalInformation.medical_schools = '';
                    PersonalInformation.medical_registration_no = '';
                    PersonalInformation.NPI_no = '';
                    this.setState({ PersonalInformation });
                }
            }
        }
        console.log("personal information after selecting salutation", this.state.PersonalInformation)
    }

    SaveAsDraftFunc = () => {
        console.log("biography array", this.state.Biography)
        console.log("resume", this.state.BiographyResume)
        console.log("photo", this.state.profile_photo)
        console.log("personal info", this.state.PersonalInformation)
        console.log("Work Details array", this.state.WorkDetails)
        console.log("professional info", this.state.ProfessionalMemberships)
        console.log("Education", this.state.Education)
        console.log("AdvisoryBoard", this.state.AdvisoryBoard)
        console.log("AwardsHonours", this.state.AwardsHonours)
        console.log("BooksMonographs", this.state.BooksMonographs)
        console.log("Commitees", this.state.Commitees)
        console.log("ClinicalTrialDetails", this.state.ClinicalTrialDetails)
        console.log("EventsDetails", this.state.EventsDetails)
        console.log("Grants", this.state.Grants)
        console.log("Guidelines", this.state.Guidelines)
        console.log("HospitalAffiliations", this.state.HospitalAffiliations)
        console.log("Interactions", this.state.Interactions)
        console.log("PressItem", this.state.PressItem)
        console.log("Presentations", this.state.Presentation)
        console.log("PublicationDetails", this.state.PublicationDetails)
        console.log("SunshinePayment", this.state.SunshinePayment)
        console.log("KOL TYPE", this.state.KOLType)
        console.log("contract_file", this.state.contract_file)
        const isValid = this.validatesaveasdraft();
        if (isValid) {
            this.setState({
                ShowProcedure: true,
            })
            // const url = global.websiteUrl+'/service/Researcher/researcher';
            const url = global.websiteUrl+'/service/KOL/KOL';
            let formData = new FormData();
            formData.append('PersonalInformation', JSON.stringify(this.state.PersonalInformation));
            formData.append('contract_file', this.state.contract_file);
            formData.append('WorkDetails', JSON.stringify(this.state.WorkDetails));
            formData.append('profile_photo', this.state.profile_photo);
            formData.append('Biography', JSON.stringify(this.state.Biography));
            formData.append('resume', this.state.BiographyResume);
            formData.append('ProfessionalMemberships', JSON.stringify(this.state.ProfessionalMemberships));
            formData.append('Education', JSON.stringify(this.state.Education));
            formData.append('AdvisoryBoard', JSON.stringify(this.state.AdvisoryBoard));
            formData.append('AwardsHonours', JSON.stringify(this.state.AwardsHonours));
            formData.append('BooksMonographs', JSON.stringify(this.state.BooksMonographs));
            formData.append('Commitees', JSON.stringify(this.state.Commitees));
            formData.append('ClinicalTrialDetails', JSON.stringify(this.state.ClinicalTrialDetails));
            formData.append('EventsDetails', JSON.stringify(this.state.EventsDetails));
            formData.append('Grants', JSON.stringify(this.state.Grants));
            formData.append('Guidelines', JSON.stringify(this.state.Guidelines));
            formData.append('HospitalAffiliations', JSON.stringify(this.state.HospitalAffiliations));
            formData.append('Interactions', JSON.stringify(this.state.Interactions));
            formData.append('PressItem', JSON.stringify(this.state.PressItem));
            formData.append('Presentations', JSON.stringify(this.state.Presentation));
            formData.append('PublicationDetails', JSON.stringify(this.state.PublicationDetails));
            formData.append('SunshinePayment', JSON.stringify(this.state.SunshinePayment));
            formData.append('kol_status', "Work In Progress");
            formData.append('project_id', this.state.project_id);

            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }

            axios.post(url, formData, config)
                .then(response => {
                    console.log(response);
                    var data = response.data;
                    console.log("data", data);
                    var status = response.data.status;
                    console.log("status", status);
                    this.setState({
                        ShowProcedure: false,
                    })
                    if (response.data.status == true) {
                        Swal.fire({
                            icon: 'success',
                            text: response.data.message,
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'OK'
                        })
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            text: response.data.message,
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'OK'
                        })
                    }
                })
                .catch(error => {
                    console.log(error);
                });
            this.setState({
                // personal info
                specialityErr: '',
                koltypeErr: '',
                doctorfullnameErr: '',
                firstnameErr: '',
            })
        }
        else {
            {
                Swal.fire({
                    // info
                    icon: 'error',
                    text: 'Please Fill All Mandatory Fields',
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK'
                })
            }
        }
    }


    SendToQUFunc = () => {

        console.log("biography array", this.state.Biography)
        console.log("resume", this.state.BiographyResume)
        console.log("photo", this.state.profile_photo)
        console.log("personal info", this.state.PersonalInformation)
        console.log('WorkDetails', this.state.WorkDetails)
        console.log("professional info", this.state.ProfessionalMemberships)
        console.log("Education", this.state.Education)
        console.log("AdvisoryBoard", this.state.AdvisoryBoard)
        console.log("AwardsHonours", this.state.AwardsHonours)
        console.log("BooksMonographs", this.state.BooksMonographs)
        console.log("Commitees", this.state.Commitees)
        console.log("ClinicalTrialDetails", this.state.ClinicalTrialDetails)
        console.log("EventsDetails", this.state.EventsDetails)
        console.log("Grants", this.state.Grants)
        console.log("Guidelines", this.state.Guidelines)
        console.log("HospitalAffiliations", this.state.HospitalAffiliations)
        console.log("Interactions", this.state.Interactions)
        console.log("PressItem", this.state.PressItem)
        console.log("Presentations", this.state.Presentation)
        console.log("PublicationDetails", this.state.PublicationDetails)
        console.log("SunshinePayment", this.state.SunshinePayment)
        console.log("KOL TYPE", this.state.KOLType)

        const isValid = this.validate();
        if (isValid) {
            this.setState({
                ShowProcedure: true,
            })
            // const url = global.websiteUrl+'/service/Researcher/researcher';
            const url = global.websiteUrl+'/service/KOL/KOL';
            let formData = new FormData();
            formData.append('PersonalInformation', JSON.stringify(this.state.PersonalInformation));
            formData.append('WorkDetails', JSON.stringify(this.state.WorkDetails));
            formData.append('profile_photo', this.state.profile_photo);
            formData.append('Biography', JSON.stringify(this.state.Biography));
            formData.append('resume', this.state.BiographyResume);
            formData.append('ProfessionalMemberships', JSON.stringify(this.state.ProfessionalMemberships));
            formData.append('Education', JSON.stringify(this.state.Education));
            formData.append('AdvisoryBoard', JSON.stringify(this.state.AdvisoryBoard));
            formData.append('AwardsHonours', JSON.stringify(this.state.AwardsHonours));
            formData.append('BooksMonographs', JSON.stringify(this.state.BooksMonographs));
            formData.append('Commitees', JSON.stringify(this.state.Commitees));
            formData.append('ClinicalTrialDetails', JSON.stringify(this.state.ClinicalTrialDetails));
            formData.append('EventsDetails', JSON.stringify(this.state.EventsDetails));
            formData.append('Grants', JSON.stringify(this.state.Grants));
            formData.append('Guidelines', JSON.stringify(this.state.Guidelines));
            formData.append('HospitalAffiliations', JSON.stringify(this.state.HospitalAffiliations));
            formData.append('Interactions', JSON.stringify(this.state.Interactions));
            formData.append('PressItem', JSON.stringify(this.state.PressItem));
            formData.append('Presentations', JSON.stringify(this.state.Presentation));
            formData.append('PublicationDetails', JSON.stringify(this.state.PublicationDetails));
            formData.append('SunshinePayment', JSON.stringify(this.state.SunshinePayment));
            formData.append('kol_status', "Submited");
            formData.append('project_id', this.state.project_id);

            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            axios.post(url, formData, config)
                .then(response => {
                    console.log(response);
                    var data = response.data;
                    console.log("data", data);
                    var status = response.data.status;
                    console.log("status", status);
                    this.setState({
                        ShowProcedure: false,
                    })
                    if (response.data.status == true) {
                        Swal.fire({
                            icon: 'success',
                            text: response.data.message,
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'OK'
                        })
                        // this.setState({redirect: true})
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            text: response.data.message,
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'OK'
                        })
                    }
                })
                .catch(error => {
                    console.log(error);
                });

            this.setState({
                // personal info
                koltypeErr: '',
                salutationErr: '',
                doctorfullnameErr: '',
                firstnameErr: '',
                lastnameErr: '',
                qualificationErr: '',
                experienceErr: '',
                medicalschoolErr: '',
                placeofworkErr: '',
                designationErr: '',
                departmentErr: '',
                specialityErr: '',
                proceduretypeErr: '',
                subspecialitytypeErr: '',
                doctorcontactnoErr: '',
                doctoremailErr: '',
                countryErr: '',
                stateErr: '',
                address1Err: '',
                zipcodeErr: '',
                // biography
                citizenshipErr: '',
                areasofinterestErr: '',
                biographyErr: '',
                // Professional Membership
                organizationErr: '',
                organizationtypeErr: '',
                sourcelinkErr: '',
                positionErr: '',
                // tenureErr: '',
                // tenureToErr: '',
                // Education
                institutenameErr: '',
                institutetypeErr: '',
                sourcelinkEducationErr: '',
                degreesErr: '',
                specialityEducationErr: '',
                yearsattendedfromErr: '',
                yearsattendedtoErr: '',
                // AdvisoryBoard
                specialityAdvBoardErr: '',
                // subspecialityAdvBoardErr: '',
                // procedureAdvBoardErr: '',
                // yearErr: '',
                companyAdvBoardErr: '',
                typeofCompanyErr: '',
                advisoryboardErr: '',
                // topicErr: '',
                roleAdvBoardErr: '',
                manualAdvBoardErr: '',
                // awards honours
                // yearAwardsErr: '',
                awardsnhonourErr: '',
                // Books Monographs
                yearBooksErr: '',
                titleErr: '',
                authorErr: '',
                publicationErr: '',
                // editorsErr: '',
                // Commitees
                // yearCommiteeErr: '',
                commiteeErr: '',
                roleErr: '',
                // Clinical trial details
                specialityClinicalErr: '',
                subSpecialityClinicalErr: '',
                procedureClinicalErr: '',
                noStudyLocationErr: '',
                studydetailsErr: '',
                sourcelinkClinicalErr: '',
                startdateErr: '',
                enddateErr: '',
                phaseErr: '',
                roleClinicalErr: '',
                manualClinicaldErr: '',
                // Event Details
                specialityEventErr: '',
                subSpecialityEventErr: '',
                procedureEventErr: '',
                eventNameErr: '',
                eventTypeErr: '',
                eventdescriptionErr: '',
                eventtopicErr: '',
                sourcelinkEventErr: '',
                sessiontypeErr: '',
                roleEventsErr: '',
                manualAdvEventDetails: '',
                // Grants
                companyErr: '',
                titleGrantsErr: '',
                yearGrantsErr: '',
                // Guidelines
                specialityGuidelinesErr: '',
                subSpecialityGuidelinesErr: '',
                procedureGuidelinesErr: '',
                companyGuidelinesErr: '',
                typeOfCompanyGuidelinesErr: '',
                yearGuidelinesErr: '',
                titleGuidelinesErr: '',
                manualGuidelines: '',
                // Hospital Affiliations
                hospitalErr: '',
                countryHospitalErr: '',
                // Interactions
                dateInteractionErr: '',
                opinionleaderErr: '',
                enteredbyErr: '',
                producttopicErr: '',
                typeInteractionErr: '',
                modeInteractionErr: '',
                timeInteractionErr: '',
                // Press Item
                titlePressErr: '',
                sourcelinkPressErr: '',
                datePressErr: '',
                // Presentation
                titlePresentationErr: '',
                typePresentationErr: '',
                sponsorPresentationErr: '',
                locationPresentationErr: '',
                datePresentationErr: '',
                // Publication Details
                specialityPublicationErr: '',
                subSpecialityPublicationErr: '',
                procedurePublicationErr: '',
                typeOfJournelErr: '',
                journalErr: '',
                publicationtitleErr: '',
                datePublicationErr: '',
                sourcelinkPublicationErr: '',
                positionPublicationErr: '',
                coauthorPublicationErr: '',
                keywordsErr: '',
                manualPublication: '',
                // Sunshine payment
                paymenttypeErr: '',
                specialitytypeSunshineErr: '',
                // Procedure potential
                procedurepotentialErr: '',
            });

        }
        else {
            {
                Swal.fire({
                    icon: 'error',
                    text: 'Please Fill All Mandatory Fields',
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK'
                })
            }
        }
    }

    BackToResercherKolList() {
        this.setState({ RedirectResercherKolList: true })
    }

    ProcedureDropdown = async () => {
        console.log("n procedure dropdown")
        await this.setState({ showProcedureSubSpecialityDropdown: true })
    }

    ProcedureDropdownAdvBoard = async () => {
        console.log("In procedure dropdown adb board")
        await this.setState({ showProcedureSubSpecialityDropdownAdvBoard: true })
    }

    ProcedureDropdownClinicalTrial = async () => {
        console.log("In procedure dropdown Clinical Trial")
        await this.setState({ showProcedureSubSpecialityDropdownClinicalTrial: true })
    }

    ProcedureDropdownEventDetails = async () => {
        console.log("In procedure dropdown Event Details")
        await this.setState({ showProcedureSubSpecialityDropdownEventDetails: true })
    }

    ProcedureDropdownGuidelines = async () => {
        console.log("In procedure dropdown Guidelines")
        await this.setState({ showProcedureSubSpecialityDropdownGuidelines: true })
    }

    ProcedureDropdownPublicationDetails = async () => {
        console.log("In procedure Publication Details")
        await this.setState({ showProcedureSubSpecialityDropdownPublicationDetails: true })
    }

    onKeyPressRestrictOnlyChar(e) {
        return (e.charCode >= 65 && e.charCode <= 90) || (e.charCode >= 97 && e.charCode <= 122);
    }

    render() {

        if (this.state.RedirectResercherKolList) {
            return (<Redirect to={{
                pathname: '/ResercheViewKolList',
                state: {
                    RID: this.state.RID,
                    resercher_name: this.state.resercher_name,
                    project_id: this.state.project_id,
                    Project_Name: this.state.Project_Name,
                }
            }} />)
        }

        // console.log("employye id",this.state.emp_unique_id)
        let { imagePreviewUrl } = this.state;
        let imagePreview = null;
        if (imagePreviewUrl) {
            imagePreview = (<img style={{ height: '150px', width: '100px' }} src={imagePreviewUrl} />);
        } else {
            imagePreview = (<div className="photo-box"></div>);
        }

        let speciality_type = this.state.SpecialityData;
        let OptionsSpeciality = speciality_type.map((speciality_type) =>
            <option key={speciality_type.id} value={speciality_type.id}>
                {speciality_type.speciality_type}</option>
        );

        let Country_list = this.state.CountryListData;
        let OptionsCountry = Country_list.map((Country_list) =>
            <option key={Country_list.id} value={Country_list.id}>
                {Country_list.country_name}</option>
        );

        let state_list = this.state.StateData;
        let OptionsState = state_list.map((state_list) =>
            <option key={state_list.id} value={state_list.id}>
                {state_list.state_name}</option>
        );

        let City_list = this.state.CityData;
        let OptionsCity = City_list.map((City_list) =>
            <option key={City_list.id} value={City_list.id}>
                {City_list.city_name}</option>
        );

        let state_list_hospital = this.state.StateDataForHospitalAffiliations;
        let OptionStateHospitalAff = state_list_hospital.map((state_list_hospital) =>
            <option key={state_list_hospital.id} value={state_list_hospital.id}>
                {state_list_hospital.state_name}</option>
        );

        let city_list_hospital = this.state.CityDataForHospitalAffiliations;
        let OptionCityHospitalAff = city_list_hospital.map((city_list_hospital) =>
            <option key={city_list_hospital.id} value={city_list_hospital.id}>
                {city_list_hospital.city_name}</option>
        );

        let employee_list = this.state.EmployeeListData;
        let OptionsEmployee = employee_list.map((employee_list) =>
            <option key={employee_list.emp_id} value={employee_list.emp_id}>
                {employee_list.emp_name}</option>
        );

        let citizenship_list = this.state.CitizenshipdDrop;
        let OptionsCitizenship = citizenship_list.map((citizenship_list) =>
            <option key={citizenship_list.id} value={citizenship_list.id}>
                {citizenship_list.citizenship}</option>
        );

        // var arrayOrganization = [];

        // var arrayOrganizationType = [];


        return (
            <body>
                <div style={{marginTop:'15vh' }}>
                    <div>
                        {this.state.ShowBar ? <div style={{ position: 'fixed', top: '0' }}>
                            <LoadingBar
                                progress='100'
                                // onLoaderFinished={() => setProgress(0)}
                                //  progress={progress}
                                height={3} color="#A90007" transitionTime={4000}
                                loaderSpeed={4000} />
                        </div>
                            : null}
                    </div>

                    {this.state.ShowProcedure ? <Modal isOpen={true} className='propagateLoader' centered>
                        <PropagateLoader color={'#A90007'} size='40px' loading='true' />
                    </Modal> : null}

                    <div>
                        <div>
                            <ResercherHeader ResercherName={this.state.resercher_name} />
                            <div class="container">
                                <div class="content-wrapper">
                                    <div class="screen-headingbox">
                                        <div class="back-section">
                                            {/* <a> */}
                                            {/* <Link to="/ResercherDashboard">
                            <img 
                                src={require('../Assets/Images/back-arrow.svg')} 
                            />
                            Back
                        </Link> */}
                                            {/* </a> */}
                                            <a onClick={this.BackToResercherKolList.bind(this)}>
                                                <img src={require('../Assets/Images/back-arrow.svg')} />Back</a>


                                        </div>
                                        <h2>Profile Details </h2>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 col-xs-12 profile-box">
                                            <div class="sidebar sticky">
                                                <ul class="cetas-leftnav">
                                                    <li>
                                                        <a onClick={this.PersonalInformationFun}>
                                                            {
                                                                (this.state.PersonalInformationFlag) ? (
                                                                    <div>
                                                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                                            Personal Information</text>
                                                                        {
                                                                            (this.state.salutationErr || this.state.koltypeErr || this.state.doctorfullnameErr || this.state.firstnameErr || this.state.lastnameErr || this.state.qualificationErr || this.state.experienceErr ||
                                                                                this.state.medicalschoolErr || this.state.placeofworkErr || this.state.designationErr || this.state.departmentErr || this.state.specialityErr || this.state.proceduretypeErr ||
                                                                                this.state.subspecialitytypeErr || this.state.doctorcontactnoErr || this.state.doctoremailErr || this.state.countryErr || this.state.stateErr || this.state.address1Err ||
                                                                                this.state.zipcodeErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )
                                                                        }
                                                                    </div>

                                                                ) : (
                                                                    <div>
                                                                        <text>Personal Information</text>
                                                                        {
                                                                            (this.state.salutationErr || this.state.koltypeErr || this.state.doctorfullnameErr || this.state.firstnameErr || this.state.lastnameErr || this.state.qualificationErr || this.state.experienceErr ||
                                                                                this.state.medicalschoolErr || this.state.placeofworkErr || this.state.designationErr || this.state.departmentErr || this.state.specialityErr || this.state.proceduretypeErr ||
                                                                                this.state.subspecialitytypeErr || this.state.doctorcontactnoErr || this.state.doctoremailErr || this.state.countryErr || this.state.stateErr || this.state.address1Err ||
                                                                                this.state.zipcodeErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )

                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a onClick={this.BiographyFun}>
                                                            {
                                                                (this.state.BiographyFlag) ? (
                                                                    <div>
                                                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                                            Biography</text>
                                                                        {
                                                                            (this.state.KOLType == 'KOL Community' || this.state.KOLType == '') ? (
                                                                                (this.state.citizenshipErr || this.state.areasofinterestErr ||
                                                                                    this.state.biographyErr) ? (
                                                                                    <span class="dot"></span>
                                                                                ) : (
                                                                                    <div></div>
                                                                                )
                                                                            ) : (
                                                                                (this.state.citizenshipErr || this.state.areasofinterestErr ||
                                                                                    this.state.biographyErr) ? (
                                                                                    <span class="dot"></span>
                                                                                ) : (
                                                                                    <div></div>
                                                                                )
                                                                            )


                                                                        }
                                                                    </div>

                                                                ) : (
                                                                    <div><text>Biography</text>
                                                                        {
                                                                            (this.state.KOLType == 'KOL Community' || this.state.KOLType == '') ? (
                                                                                (this.state.citizenshipErr || this.state.areasofinterestErr ||
                                                                                    this.state.biographyErr) ? (
                                                                                    <span class="dot"></span>
                                                                                ) : (
                                                                                    <div></div>
                                                                                )
                                                                            ) : (
                                                                                (this.state.citizenshipErr || this.state.areasofinterestErr ||
                                                                                    this.state.biographyErr) ? (
                                                                                    <span class="dot"></span>
                                                                                ) : (
                                                                                    <div></div>
                                                                                )
                                                                            )


                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a onClick={this.ProfessionalMembershipFun}>
                                                            {
                                                                (this.state.ProfessionalMembershipFlag) ? (
                                                                    <div>
                                                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                                            Professional Membership</text>
                                                                        {
                                                                            (this.state.organizationErr || this.state.organizationtypeErr || this.state.sourcelinkErr ||
                                                                                this.state.positionErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )

                                                                        }
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <text>Professional Membership</text>
                                                                        {
                                                                            (this.state.organizationErr || this.state.organizationtypeErr || this.state.sourcelinkErr ||
                                                                                this.state.positionErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )

                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a onClick={this.EducationFun}>
                                                            {
                                                                (this.state.EducationFlag) ? (
                                                                    <div>
                                                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                                            Education</text>
                                                                        {
                                                                            (this.state.institutenameErr || this.state.institutetypeErr || this.state.sourcelinkEducationErr ||
                                                                                this.state.degreesErr || this.state.specialityEducationErr || this.state.yearsattendedfromErr ||
                                                                                this.state.yearsattendedtoErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )

                                                                        }
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <text>Education</text>
                                                                        {
                                                                            (this.state.institutenameErr || this.state.institutetypeErr || this.state.sourcelinkEducationErr ||
                                                                                this.state.degreesErr || this.state.specialityEducationErr || this.state.yearsattendedfromErr ||
                                                                                this.state.yearsattendedtoErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )

                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a onClick={this.AdvisoryBoardFun}>
                                                            {
                                                                (this.state.AdvisoryBoardFlag) ? (
                                                                    <div>
                                                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                                            Advisory Board</text>
                                                                        {
                                                                            (this.state.specialityAdvBoardErr ||
                                                                                this.state.companyAdvBoardErr ||
                                                                                this.state.typeofCompanyErr || this.state.advisoryboardErr ||
                                                                                this.state.roleAdvBoardErr || this.state.manualAdvBoardErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )

                                                                        }
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <text>Advisory Board</text>
                                                                        {
                                                                            (this.state.specialityAdvBoardErr ||
                                                                                this.state.companyAdvBoardErr ||
                                                                                this.state.typeofCompanyErr || this.state.advisoryboardErr ||
                                                                                this.state.roleAdvBoardErr || this.state.manualAdvBoardErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )

                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a onClick={this.AwardsAndHonoursFun}>
                                                            {
                                                                (this.state.AwardsAndHonoursFlag) ? (
                                                                    <div>
                                                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                                            Awards & Honours</text>
                                                                        {
                                                                            (this.state.awardsnhonourErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )

                                                                        }
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <text>Awards & Honours</text>
                                                                        {
                                                                            (this.state.awardsnhonourErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )

                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a onClick={this.BooksAndMonographFun}>
                                                            {
                                                                (this.state.BooksAndMonographFlag) ? (
                                                                    <div>
                                                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                                            Books & Monographs</text>
                                                                        {
                                                                            (this.state.yearBooksErr || this.state.titleErr || this.state.authorErr ||
                                                                                this.state.publicationErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )

                                                                        }
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <text>Books & Monographs</text>
                                                                        {
                                                                            (this.state.yearBooksErr || this.state.titleErr || this.state.authorErr ||
                                                                                this.state.publicationErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )

                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a onClick={this.CommiteesFun}>
                                                            {
                                                                (this.state.CommiteesFlag) ? (
                                                                    <div>
                                                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                                        Committees</text>
                                                                        {
                                                                            (this.state.commiteeErr || this.state.roleErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )

                                                                        }
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <text>Committees</text>
                                                                        {
                                                                            (this.state.commiteeErr || this.state.roleErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )

                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a onClick={this.ClinicalTrialDetailsFun}>
                                                            {
                                                                (this.state.ClinicalTrialDetailsFlag) ? (
                                                                    <div>
                                                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                                            Clinical Trial Details</text>
                                                                        {
                                                                            (this.state.specialityClinicalErr || this.state.subSpecialityClinicalErr ||
                                                                                this.state.procedureClinicalErr || this.state.noStudyLocationErr ||
                                                                                this.state.studydetailsErr || this.state.sourcelinkClinicalErr || this.state.startdateErr ||
                                                                                this.state.enddateErr || this.state.phaseErr || this.state.roleClinicalErr || this.state.manualClinicaldErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )

                                                                        }
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <text>Clinical Trial Details</text>
                                                                        {
                                                                            (this.state.specialityClinicalErr || this.state.subSpecialityClinicalErr ||
                                                                                this.state.procedureClinicalErr || this.state.noStudyLocationErr ||
                                                                                this.state.studydetailsErr || this.state.sourcelinkClinicalErr || this.state.startdateErr ||
                                                                                this.state.enddateErr || this.state.phaseErr || this.state.roleClinicalErr || this.state.manualClinicaldErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )

                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a onClick={this.EventsDetailsFun}>
                                                            {
                                                                (this.state.EventsDetailsFlag) ? (
                                                                    <div>
                                                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                                            Events Details</text>
                                                                        {
                                                                            (this.state.specialityEventErr || this.state.subSpecialityEventErr ||
                                                                                this.state.procedureEventErr || this.state.eventNameErr || this.state.eventTypeErr ||
                                                                                this.state.eventdescriptionErr || this.state.eventtopicErr || this.state.sourcelinkEventErr ||
                                                                                this.state.sessiontypeErr || this.state.roleEventsErr || this.state.manualAdvEventDetails) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )

                                                                        }
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <text>Events Details</text>
                                                                        {
                                                                            (this.state.specialityEventErr || this.state.subSpecialityEventErr ||
                                                                                this.state.procedureEventErr || this.state.eventNameErr || this.state.eventTypeErr ||
                                                                                this.state.eventdescriptionErr || this.state.eventtopicErr || this.state.sourcelinkEventErr ||
                                                                                this.state.sessiontypeErr || this.state.roleEventsErr || this.state.manualAdvEventDetails) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )

                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a onClick={this.GrantsFun}>
                                                            {
                                                                (this.state.GrantsFlag) ? (
                                                                    <div>
                                                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                                            Grants</text>
                                                                        {
                                                                            (this.state.companyErr || this.state.titleGrantsErr || this.state.yearGrantsErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )

                                                                        }
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <text>Grants</text>
                                                                        {
                                                                            (this.state.companyErr || this.state.titleGrantsErr || this.state.yearGrantsErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )

                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a onClick={this.GuidelinesFun}>
                                                            {
                                                                (this.state.GuidelinesFlag) ? (
                                                                    <div>
                                                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                                            Guidelines</text>
                                                                        {
                                                                            (this.state.specialityGuidelinesErr || this.state.subSpecialityGuidelinesErr ||
                                                                                this.state.procedureGuidelinesErr || this.state.typeOfCompanyGuidelinesErr ||
                                                                                this.state.yearGuidelinesErr || this.state.companyGuidelinesErr || this.state.titleGuidelinesErr ||
                                                                                this.state.manualGuidelines) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )

                                                                        }
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <text>Guidelines</text>
                                                                        {
                                                                            (this.state.specialityGuidelinesErr || this.state.subSpecialityGuidelinesErr ||
                                                                                this.state.procedureGuidelinesErr || this.state.typeOfCompanyGuidelinesErr ||
                                                                                this.state.yearGuidelinesErr || this.state.companyGuidelinesErr || this.state.titleGuidelinesErr ||
                                                                                this.state.manualGuidelines) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )

                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a onClick={this.HospitalAffiliationsFun}>
                                                            {
                                                                (this.state.HospitalAffiliationsFlag) ? (
                                                                    <div>
                                                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                                            Hospital Affiliations</text>
                                                                        {
                                                                            (this.state.hospitalErr || this.state.countryHospitalErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )

                                                                        }
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <text>Hospital Affiliations</text>
                                                                        {
                                                                            (this.state.hospitalErr || this.state.countryHospitalErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )

                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a onClick={this.InteractionsFun}>
                                                            {
                                                                (this.state.InteractionsFlag) ? (
                                                                    <div>
                                                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                                            Interactions</text>
                                                                        {
                                                                            (this.state.dateInteractionErr || this.state.opinionleaderErr || this.state.enteredbyErr ||
                                                                                this.state.producttopicErr || this.state.typeInteractionErr || this.state.modeInteractionErr ||
                                                                                this.state.timeInteractionErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )

                                                                        }
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <text>Interactions</text>
                                                                        {
                                                                            (this.state.dateInteractionErr || this.state.opinionleaderErr || this.state.enteredbyErr ||
                                                                                this.state.producttopicErr || this.state.typeInteractionErr || this.state.modeInteractionErr ||
                                                                                this.state.timeInteractionErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )

                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        </a>
                                                    </li>
                                                    {
                                                        (this.state.KOLType == 'Payers Community' || this.state.KOLType == 'HTA Experts' ||
                                                            this.state.KOLType == 'Clinical Commission Group') ? (
                                                            <div></div>
                                                        ) : (
                                                            <li>
                                                                <a onClick={this.PressItemFun}>
                                                                    {
                                                                        (this.state.PressItemFlag) ? (
                                                                            <div>
                                                                                <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                                                    Press Item</text>
                                                                                {
                                                                                    (this.state.titlePressErr || this.state.sourcelinkPressErr || this.state.datePressErr) ? (
                                                                                        <span class="dot"></span>
                                                                                    ) : (
                                                                                        <div></div>
                                                                                    )

                                                                                }
                                                                            </div>
                                                                        ) : (
                                                                            <div>
                                                                                <text>Press Item</text>
                                                                                {
                                                                                    (this.state.titlePressErr || this.state.sourcelinkPressErr || this.state.datePressErr) ? (
                                                                                        <span class="dot"></span>
                                                                                    ) : (
                                                                                        <div></div>
                                                                                    )

                                                                                }
                                                                            </div>
                                                                        )
                                                                    }
                                                                </a>
                                                            </li>
                                                        )
                                                    }

                                                    {
                                                        (this.state.KOLType == 'Payers Community' || this.state.KOLType == 'HTA Experts' ||
                                                            this.state.KOLType == 'Clinical Commission Group') ? (
                                                            <div></div>
                                                        ) : (
                                                            <li>
                                                                <a onClick={this.PresentationsFun}>
                                                                    {
                                                                        (this.state.PresentationsFlag) ? (
                                                                            <div>
                                                                                <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                                                    Presentations</text>
                                                                                {
                                                                                    (this.state.titlePresentationErr || this.state.typePresentationErr ||
                                                                                        this.state.sponsorPresentationErr || this.state.locationPresentationErr ||
                                                                                        this.state.datePresentationErr) ? (
                                                                                        <span class="dot"></span>
                                                                                    ) : (
                                                                                        <div></div>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        ) : (
                                                                            <div>
                                                                                <text>Presentations</text>
                                                                                {
                                                                                    (this.state.titlePresentationErr || this.state.typePresentationErr ||
                                                                                        this.state.sponsorPresentationErr || this.state.locationPresentationErr ||
                                                                                        this.state.datePresentationErr) ? (
                                                                                        <span class="dot"></span>
                                                                                    ) : (
                                                                                        <div></div>
                                                                                    )
                                                                                }
                                                                            </div>
                                                                        )
                                                                    }
                                                                </a>
                                                            </li>
                                                        )
                                                    }

                                                    <li>
                                                        <a onClick={this.PublicationDetailsFun}>
                                                            {
                                                                (this.state.PublicationDetailsFlag) ? (
                                                                    <div>
                                                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                                            Publication Details</text>
                                                                        {
                                                                            (this.state.specialityPublicationErr || this.state.subSpecialityPublicationErr ||
                                                                                this.state.procedurePublicationErr || this.state.typeOfJournelErr ||
                                                                                this.state.journalErr || this.state.publicationtitleErr || this.state.datePublicationErr ||
                                                                                this.state.sourcelinkPublicationErr || this.state.positionErr ||
                                                                                this.state.coauthorPublicationErr || this.state.keywordsErr ||
                                                                                this.state.manualPublication) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <text>Publication Details</text>
                                                                        {
                                                                            (this.state.specialityPublicationErr || this.state.subSpecialityPublicationErr ||
                                                                                this.state.procedurePublicationErr || this.state.typeOfJournelErr ||
                                                                                this.state.journalErr || this.state.publicationtitleErr || this.state.datePublicationErr ||
                                                                                this.state.sourcelinkPublicationErr || this.state.positionErr ||
                                                                                this.state.coauthorPublicationErr || this.state.keywordsErr ||
                                                                                this.state.manualPublication) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a onClick={this.SunshinePaymentFun}>
                                                            {
                                                                (this.state.SunshinePaymentFlag) ? (
                                                                    <div>
                                                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                                            Sunshine Payment</text>
                                                                        {
                                                                            (this.state.paymenttypeErr || this.state.specialitytypeSunshineErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <text>Sunshine Payment</text>
                                                                        {
                                                                            (this.state.paymenttypeErr || this.state.specialitytypeSunshineErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a onClick={this.ProcedurePotentialFun}>
                                                            {
                                                                (this.state.ProcedurePotentialFlag) ? (
                                                                    <div>
                                                                        <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                                                            Procedure Potential</text>
                                                                        {
                                                                            (this.state.procedurepotentialErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <text>Procedure Potential</text>
                                                                        {
                                                                            (this.state.procedurepotentialErr) ? (
                                                                                <span class="dot"></span>
                                                                            ) : (
                                                                                <div></div>
                                                                            )
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        </a>
                                                    </li>
                                                    <li></li>
                                                </ul>
                                                <div style={{ marginTop: '50%' }}></div>
                                            </div>
                                        </div>
                                        <div class="col-md-5">
                                            <div class="scroll-box">
                                                <a id="overall-details"></a>
                                                {/* Personal information form */}
                                                {
                                                    (this.state.PersonalInformationFlag == true) ? (
                                                        <section style={{ marginBottom: '30%' }}>
                                                            <h3 class="mt-30">Personal Information </h3>
                                                            <span class="mandatory">Note: All * fields are mandatory </span>
                                                            <div class="scrollable-box">
                                                                <form>
                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                            <div class="form-group">
                                                                                {/* <label for="exampleInputEmail1">Select KOL type </label>
                                                    <span class="mandatory">*</span>
                                                        <select class="form-control" 
                                                            id="exampleFormControlSelect1">
                                                            <option>Select Speciality</option>
                                                            <option>Researcher</option>
                                                            <option>Manager</option>
                                                            <option>Data entry</option>
                                                            <option>5</option>
                                                        </select> */}
                                                                                <label for="exampleFormControlTextarea1">
                                                                                    Select KOL Type <span class="mandatory">*</span></label>
                                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                                    style={{ height: '50px' }}
                                                                                    name="KOLType"
                                                                                    autofocus="true"
                                                                                    value={this.state.KOLType}
                                                                                    onChange={async (e) => {
                                                                                        let { PersonalInformation } = this.state;
                                                                                        PersonalInformation.kol_type = e.target.value;
                                                                                        await this.setState({ PersonalInformation, KOLType: e.target.value });
                                                                                        console.log("KolType", this.state.KOLType)
                                                                                        { this.afterSelectingKOL() }
                                                                                        // below afterSelectSalutation call is just if user 1st selects the saalutation and then selects KOL
                                                                                        { this.afterSelectSalutation() }
                                                                                        // {
                                                                                        // ( e.target.value ==  'Payers Community')?(
                                                                                        //     <div> {console.log("yes")} </div>
                                                                                        // ):(
                                                                                        //     <div> {console.log("no")} </div>
                                                                                        // )
                                                                                        // }
                                                                                    }}
                                                                                >
                                                                                    <option value="0" >--Select KOL--</option>
                                                                                    <option value="KOL Community" >KOL Community</option>
                                                                                    <option value="Payers Community" >Payers Community</option>
                                                                                    <option value="HTA Experts" >HTA Experts</option>
                                                                                    <option value="Clinical Commission Group" >Clinical Commission Group</option>
                                                                                </Input>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{
                                                                        color: "red", fontSize: "12px",
                                                                        marginBottom: "5px",
                                                                    }}>
                                                                        {this.state.koltypeErr}</div>
                                                                    <hr />
                                                                    <div class="row">
                                                                        <div class="col-md-4">
                                                                            {imagePreview}
                                                                            {/* <div class="photo-box"></div> */}
                                                                            <div>
                                                                                <input type='file'
                                                                                    id="myInput"
                                                                                    accept="image/*"
                                                                                    onChange={this.getPhoto} hidden />
                                                                                <label htmlFor="myInput"
                                                                                    style={{ color: 'blue', fontSize: "15px", }}>
                                                                                    Upload Photo
                                                                                </label>
                                                                            </div>
                                                                            <div className="loginErrCreateProject">{this.state.ImageErr}</div>

                                                                        </div>

                                                                        <div class="col-md-8">
                                                                            <div class="form-group bottom-seperator pb-30">
                                                                                <label for="exampleFormControlTextarea1">SI. No.</label>
                                                                                {/* <input class="form-control" type="text" 
                                                        placeholder={this.state.SrNO} 
                                                        readonly="" /> */}
                                                                                <text className="form-control">{this.state.SrNO}</text>
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <label for="exampleFormControlTextarea1">
                                                                                    Unique id</label>
                                                                                {/* <input class="form-control" type="text" 
                                                                placeholder="UTFI8782" readonly="" /> */}
                                                                                <text className="form-control">{this.state.emp_unique_id}</text>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div class="form-group">

                                                                        <label for="exampleFormControlTextarea1">
                                                                            Salutation <span class="mandatory">*</span></label>
                                                                        <Input type="select" id="roleSelect" className="form-control"
                                                                            style={{ height: '50px' }}
                                                                            name="salutation"
                                                                            value={this.state.PersonalInformation.salutation}
                                                                            onChange={async (e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.salutation = e.target.value;
                                                                                await this.setState({ PersonalInformation });
                                                                                { this.afterSelectSalutation() }
                                                                            }}
                                                                        >
                                                                            <option value="0" >--Select Salutation--</option>
                                                                            <option value="Mr." >Mr.</option>
                                                                            <option value="Ms." >Ms.</option>
                                                                            <option value="Mrs." >Mrs.</option>
                                                                            <option value="Dr." >Dr.</option>
                                                                            <option value="Prof." >Prof.</option>
                                                                            <option value="Other" >Other</option>
                                                                        </Input>
                                                                        <div style={{
                                                                            color: "red", fontSize: "12px", marginTop: "1%",
                                                                            marginBottom: "5px",
                                                                        }}>
                                                                            {this.state.salutationErr}</div>
                                                                        <a class="float-right" style={{ color: '#007bff', marginTop: '0.5%' }}
                                                                            onClick={this.SalutaionAddSourceFun}>+ Add source</a>
                                                                        {
                                                                            (!this.state.SalutationAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div style={{ marginTop: '8%' }}>
                                                                                    {this.state.itemsSalutation.map((itemsSalutation) => (
                                                                                        <div className="tag-item" key={itemsSalutation}>
                                                                                            {itemsSalutation}
                                                                                            <button
                                                                                                type="button"
                                                                                                className="button"
                                                                                                onClick={() => this.handleDeleteSalutation(itemsSalutation)}
                                                                                            >
                                                                                                &times;
                                                                                            </button>
                                                                                        </div>
                                                                                    ))}
                                                                                    <input
                                                                                        class="form-control-addsource-Personal"
                                                                                        value={this.state.valuePersonalInfoAddSource}
                                                                                        placeholder="Type or paste source link and press `Enter`"
                                                                                        onKeyDown={this.handleKeyDownSalutation}
                                                                                        onChange={this.handleChangePersonalInfoAddSource}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        }

                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                            Doctor's Full Name <span class="mandatory">*</span></label>
                                                                        <input type="text" class="form-control"
                                                                            // onKeyPress={event => this.onKeyPressRestrictOnlyChar(event)}
                                                                            // onKeyPress={event => (event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122)}
                                                                            id="input3" placeholder="Enter Full Name"
                                                                            name="doctor_full_name"
                                                                            value={this.state.PersonalInformation.doctor_full_name}

                                                                            onChange={(e) => {
                                                                                var re = /^[a-z\- ]+$/i;
                                                                                console.log('return : ' + re.test(e.target.value))
                                                                                if (re.test(e.target.value)) {
                                                                                    // this.onKeyPressRestrictOnlyChar(e)
                                                                                    let { PersonalInformation } = this.state;
                                                                                    PersonalInformation.doctor_full_name = e.target.value;
                                                                                    this.setState({ PersonalInformation });
                                                                                }
                                                                                // console.log("e.length",e.target.value.length)
                                                                                if (e.target.value.length == '0') {
                                                                                    // this.onKeyPressRestrictOnlyChar(e)
                                                                                    let { PersonalInformation } = this.state;
                                                                                    PersonalInformation.doctor_full_name = '';
                                                                                    this.setState({ PersonalInformation });
                                                                                }
                                                                            }}
                                                                        />
                                                                        <div className="loginErrCreateProject">{this.state.doctorfullnameErr}</div>
                                                                        <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                            onClick={this.DFullNameAddSourceFun}>+ Add source</a>
                                                                        {
                                                                            (!this.state.DFullNameAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div style={{ marginTop: '8%' }}>
                                                                                    {/* <input type="text" class="form-control" 
                                                        id="input4" placeholder="Paste Source URL" 
                                                        name="doctor_full_name_links" 
                                                        value={this.state.PersonalInformation.doctor_full_name_links} 
                                                        onChange={(e) => {
                                                            let { PersonalInformation } = this.state;
                                                            PersonalInformation.doctor_full_name_links = e.target.value;
                                                            this.setState({ PersonalInformation });
                                                        }}
                                                    /> */}
                                                                                    {this.state.itemsDoctorFullName.map((itemsDoctorFullName) => (
                                                                                        <div className="tag-item" key={itemsDoctorFullName}>
                                                                                            {itemsDoctorFullName}
                                                                                            <button
                                                                                                type="button"
                                                                                                className="button"
                                                                                                onClick={() => this.handleDeleteDoctorFullName(itemsDoctorFullName)}
                                                                                            >
                                                                                                &times;
                                                                                            </button>
                                                                                        </div>
                                                                                    ))}
                                                                                    <input
                                                                                        class="form-control-addsource-Personal"
                                                                                        value={this.state.valuePersonalInfoAddSource}
                                                                                        placeholder="Type or paste source link and press `Enter`"
                                                                                        onKeyDown={this.handleKeyDownDoctorFullName}
                                                                                        onChange={this.handleChangePersonalInfoAddSource}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        }
                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                                            First Name <span class="mandatory">*</span></label>
                                                                        <input type="text" class="form-control"
                                                                            autocomplete="off"
                                                                            id="input5" placeholder="Enter First Name"
                                                                            name="first_name"
                                                                            value={this.state.PersonalInformation.first_name}
                                                                            onChange={(e) => {
                                                                                var re = /^[a-z\- ]+$/i;
                                                                                if (re.test(e.target.value)) {
                                                                                    let { PersonalInformation } = this.state;
                                                                                    PersonalInformation.first_name = e.target.value;
                                                                                    this.setState({ PersonalInformation });
                                                                                }
                                                                                if (e.target.value.length == '0') {
                                                                                    // this.onKeyPressRestrictOnlyChar(e)
                                                                                    let { PersonalInformation } = this.state;
                                                                                    PersonalInformation.first_name = '';
                                                                                    this.setState({ PersonalInformation });
                                                                                }
                                                                            }}
                                                                        />
                                                                        <div className="loginErrCreateProject">{this.state.firstnameErr}</div>
                                                                        <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                            onClick={this.FirstNameAddSourceFun}>+ Add source</a>
                                                                        {
                                                                            (!this.state.FirstNameAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div style={{ marginTop: '8%' }}>
                                                                                    {this.state.itemsFirstName.map((itemsFirstName) => (
                                                                                        <div className="tag-item" key={itemsFirstName}>
                                                                                            {itemsFirstName}
                                                                                            <button
                                                                                                type="button"
                                                                                                className="button"
                                                                                                onClick={() => this.handleDeleteFirstName(itemsFirstName)}
                                                                                            >
                                                                                                &times;
                                                                                            </button>
                                                                                        </div>
                                                                                    ))}
                                                                                    <input
                                                                                        class="form-control-addsource-Personal"
                                                                                        value={this.state.valuePersonalInfoAddSource}
                                                                                        placeholder="Type or paste source link and press `Enter`"
                                                                                        onKeyDown={this.handleKeyDownFirstName}
                                                                                        onChange={this.handleChangePersonalInfoAddSource}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        }

                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                                            Middle Name </label>
                                                                        <input type="text" class="form-control"
                                                                            autocomplete="off"
                                                                            id="input5" placeholder="Enter Middle Name"
                                                                            name="middle_name"
                                                                            value={this.state.PersonalInformation.middle_name}
                                                                            onChange={(e) => {
                                                                                var re = /^[a-z\- ]+$/i;
                                                                                if (re.test(e.target.value)) {
                                                                                    let { PersonalInformation } = this.state;
                                                                                    PersonalInformation.middle_name = e.target.value;
                                                                                    this.setState({ PersonalInformation });
                                                                                }
                                                                                if (e.target.value.length == '0') {
                                                                                    // this.onKeyPressRestrictOnlyChar(e)
                                                                                    let { PersonalInformation } = this.state;
                                                                                    PersonalInformation.middle_name = '';
                                                                                    this.setState({ PersonalInformation });
                                                                                }
                                                                            }}
                                                                        />
                                                                        <a class="float-right" style={{ marginTop: '-3%', color: '#007bff' }}
                                                                            onClick={this.MiddleNameAddSourceFun}>+ Add source</a>
                                                                        {
                                                                            (!this.state.MiddleNameAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div style={{ marginTop: '8%' }}>
                                                                                    {this.state.itemsMiddleName.map((itemsMiddleName) => (
                                                                                        <div className="tag-item" key={itemsMiddleName}>
                                                                                            {itemsMiddleName}
                                                                                            <button
                                                                                                type="button"
                                                                                                className="button"
                                                                                                onClick={() => this.handleDeleteMiddleName(itemsMiddleName)}
                                                                                            >
                                                                                                &times;
                                                                                            </button>
                                                                                        </div>
                                                                                    ))}
                                                                                    <input
                                                                                        class="form-control-addsource-Personal"
                                                                                        value={this.state.valuePersonalInfoAddSource}
                                                                                        placeholder="Type or paste source link and press `Enter`"
                                                                                        onKeyDown={this.handleKeyDownMiddleName}
                                                                                        onChange={this.handleChangePersonalInfoAddSource}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        }

                                                                        <label for="exampleFormControlTextarea1" >
                                                                            Last Name <span class="mandatory">*</span></label>
                                                                        <input type="text" class="form-control"
                                                                            autocomplete="off"
                                                                            id="input5" placeholder="Enter Last Name"
                                                                            name="last_name"
                                                                            value={this.state.PersonalInformation.last_name}
                                                                            onChange={(e) => {
                                                                                var re = /^[a-z\- ]+$/i;
                                                                                if (re.test(e.target.value)) {
                                                                                    let { PersonalInformation } = this.state;
                                                                                    PersonalInformation.last_name = e.target.value;
                                                                                    this.setState({ PersonalInformation });
                                                                                }
                                                                                if (e.target.value.length == '0') {
                                                                                    // this.onKeyPressRestrictOnlyChar(e)
                                                                                    let { PersonalInformation } = this.state;
                                                                                    PersonalInformation.last_name = '';
                                                                                    this.setState({ PersonalInformation });
                                                                                }
                                                                            }}
                                                                        />
                                                                        <div className="loginErrCreateProject">{this.state.lastnameErr}</div>
                                                                        <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                            onClick={this.LastNameAddSourceFun}>+ Add source</a>
                                                                        {
                                                                            (!this.state.LastNameAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div style={{ marginTop: '8%' }}>
                                                                                    {this.state.itemsLastName.map((itemsLastName) => (
                                                                                        <div className="tag-item" key={itemsLastName}>
                                                                                            {itemsLastName}
                                                                                            <button
                                                                                                type="button"
                                                                                                className="button"
                                                                                                onClick={() => this.handleDeleteLastName(itemsLastName)}
                                                                                            >
                                                                                                &times;
                                                                                            </button>
                                                                                        </div>
                                                                                    ))}
                                                                                    <input
                                                                                        class="form-control-addsource-Personal"
                                                                                        value={this.state.valuePersonalInfoAddSource}
                                                                                        placeholder="Type or paste source link and press `Enter`"
                                                                                        onKeyDown={this.handleKeyDownLastName}
                                                                                        onChange={this.handleChangePersonalInfoAddSource}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        }

                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                                            Qualification  <span class="mandatory">*</span></label>
                                                                        <input type="text" class="form-control disabled"
                                                                            autocomplete="off"
                                                                            id="input6" placeholder="Enter Qualification "
                                                                            name="qualification"
                                                                            value={this.state.PersonalInformation.qualification}
                                                                        // onChange={(e) => {
                                                                        //     let { PersonalInformation } = this.state;
                                                                        //     PersonalInformation.qualification = e.target.value;
                                                                        //     this.setState({ PersonalInformation });
                                                                        // }}
                                                                        />
                                                                        <div className="loginErrCreateProject">{this.state.qualificationErr}</div>
                                                                        <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                            onClick={this.QualificationAddSourceFun}>+ Add source</a>
                                                                        {
                                                                            (!this.state.QualificationAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div style={{ marginTop: '8%' }}>
                                                                                    {this.state.itemsQualification.map((itemsQualification) => (
                                                                                        <div className="tag-item" key={itemsQualification}>
                                                                                            {itemsQualification}
                                                                                            <button
                                                                                                type="button"
                                                                                                className="button"
                                                                                                onClick={() => this.handleDeleteQualification(itemsQualification)}
                                                                                            >
                                                                                                &times;
                                                                                            </button>
                                                                                        </div>
                                                                                    ))}
                                                                                    <input
                                                                                        class="form-control-addsource-Personal"
                                                                                        value={this.state.valuePersonalInfoAddSource}
                                                                                        placeholder="Type or paste source link and press `Enter`"
                                                                                        onKeyDown={this.handleKeyDownQualification}
                                                                                        onChange={this.handleChangePersonalInfoAddSource}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        }

                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                                            Clinical Experience <span class="mandatory">*</span></label>
                                                                        <Input type="select" id="roleSelect" className="form-control"
                                                                            style={{ height: '50px' }}
                                                                            name="experience"
                                                                            value={this.state.PersonalInformation.experience}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.experience = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        >
                                                                            <option value="0" >--Select Experience--</option>
                                                                            <option value="1 year" >1 year</option>
                                                                            <option value="2 years" >2 years</option>
                                                                            <option value="3 years" >3 years</option>
                                                                            <option value="4 years" >4 years</option>
                                                                            <option value="5 years" >5 years</option>
                                                                            <option value="6 years" >6 years</option>
                                                                            <option value="7 years" >7 years</option>
                                                                            <option value="8 years" >8 years</option>
                                                                            <option value="9 years" >9 years</option>
                                                                            <option value="10 years" >10 years</option>
                                                                            <option value="11 years" >11 years</option>
                                                                            <option value="12 years" >12 years</option>
                                                                            <option value="13 years" >13 years</option>
                                                                            <option value="14 years" >14 years</option>
                                                                            <option value="15 years" >15 years</option>
                                                                            <option value="16 years" >16 years</option>
                                                                            <option value="17 years" >17 years</option>
                                                                            <option value="18 years" >18 years</option>
                                                                            <option value="19 years" >19 years</option>
                                                                            <option value="20 years" >20 years</option>
                                                                            <option value="21 years" >21 years</option>
                                                                            <option value="22 years" >22 years</option>
                                                                            <option value="23 years" >23 years</option>
                                                                            <option value="24 years" >24 years</option>
                                                                            <option value="25+ years" >25+ years</option>
                                                                            <option value="N/A" >N/A</option>
                                                                        </Input>
                                                                        <div style={{
                                                                            color: "red", fontSize: "12px", marginTop: "1%",
                                                                            marginBottom: "5px",
                                                                        }}>
                                                                            {this.state.experienceErr}</div>
                                                                        <a class="float-right" style={{ marginTop: '0.5%', color: '#007bff' }}
                                                                            onClick={this.ExperienceAddSourceFun}>+ Add source</a>
                                                                        {
                                                                            (!this.state.ExperienceAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div style={{ marginTop: '8%' }}>
                                                                                    {this.state.itemsExperience.map((itemsExperience) => (
                                                                                        <div className="tag-item" key={itemsExperience}>
                                                                                            {itemsExperience}
                                                                                            <button
                                                                                                type="button"
                                                                                                className="button"
                                                                                                onClick={() => this.handleDeleteExperience(itemsExperience)}
                                                                                            >
                                                                                                &times;
                                                                                            </button>
                                                                                        </div>
                                                                                    ))}
                                                                                    <input
                                                                                        class="form-control-addsource-Personal"
                                                                                        value={this.state.valuePersonalInfoAddSource}
                                                                                        placeholder="Type or paste source link and press `Enter`"
                                                                                        onKeyDown={this.handleKeyDownExperience}
                                                                                        onChange={this.handleChangePersonalInfoAddSource}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        }

                                                                        {/* {this.forMedicalInformation()} */}
                                                                        {
                                                                            (this.state.PersonalInformation.kol_type == 'HTA Experts' ||
                                                                                this.state.PersonalInformation.kol_type == 'Payers Community' ||
                                                                                this.state.PersonalInformation.kol_type == 'Clinical Commission Group') ? (
                                                                                (this.state.PersonalInformation.salutation == 'Dr.') ? (
                                                                                    <div>
                                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                            {/* Medical Schools  */}
                                                                                            Alma mater
                                                                                        </label>
                                                                                        <input type="text" class="form-control disabled"
                                                                                            autocomplete="off"
                                                                                            id="input7" placeholder="Enter Alma mater"
                                                                                            name="medical_schools"
                                                                                            value={this.state.PersonalInformation.medical_schools}
                                                                                        // onChange={(e) => {
                                                                                        //     let { PersonalInformation } = this.state;
                                                                                        //     PersonalInformation.medical_schools = e.target.value;
                                                                                        //     this.setState({ PersonalInformation });
                                                                                        // }}
                                                                                        />
                                                                                        <div className="loginErrCreateProject">{this.state.medicalschoolErr}</div>
                                                                                        <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                                            onClick={this.MedicalSchoolsAddSourceFun}>+ Add source</a>
                                                                                        {
                                                                                            (!this.state.MedicalSchoolsAddSource) ? (
                                                                                                <div></div>
                                                                                            ) : (
                                                                                                <div style={{ marginTop: '8%' }}>
                                                                                                    {this.state.itemsMedicalSchool.map((itemsMedicalSchool) => (
                                                                                                        <div className="tag-item" key={itemsMedicalSchool}>
                                                                                                            {itemsMedicalSchool}
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className="button"
                                                                                                                onClick={() => this.handleDeleteMedicalSchool(itemsMedicalSchool)}
                                                                                                            >
                                                                                                                &times;
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    ))}
                                                                                                    <input
                                                                                                        class="form-control-addsource-Personal"
                                                                                                        value={this.state.valuePersonalInfoAddSource}
                                                                                                        placeholder="Type or paste source link and press `Enter`"
                                                                                                        onKeyDown={this.handleKeyDownMedicalSchool}
                                                                                                        onChange={this.handleChangePersonalInfoAddSource}
                                                                                                    />
                                                                                                </div>
                                                                                            )
                                                                                        }

                                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                            License No. </label>
                                                                                        <input type="text" class="form-control"
                                                                                            id="input7" placeholder="Enter License No. "
                                                                                            name="medical_registration_no"
                                                                                            value={this.state.PersonalInformation.medical_registration_no}
                                                                                            onChange={(e) => {
                                                                                                var re = /^[a-z0-9]+$/i;
                                                                                                if (re.test(e.target.value)) {
                                                                                                    let { PersonalInformation } = this.state;
                                                                                                    PersonalInformation.medical_registration_no = e.target.value;
                                                                                                    this.setState({ PersonalInformation });
                                                                                                }
                                                                                                if (e.target.value.length == '0') {
                                                                                                    let { PersonalInformation } = this.state;
                                                                                                    PersonalInformation.medical_registration_no = '';
                                                                                                    this.setState({ PersonalInformation });
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                        <a class="float-right" style={{ marginTop: '-3%', color: '#007bff' }}
                                                                                            onClick={this.MedicalRegistrationNoAddSourceFun}>+ Add source</a>
                                                                                        {
                                                                                            (!this.state.MedicalRegistrationNoAddSource) ? (
                                                                                                <div></div>
                                                                                            ) : (
                                                                                                <div style={{ marginTop: '8%' }}>
                                                                                                    {this.state.itemsMedicalRegisterNo.map((itemsMedicalRegisterNo) => (
                                                                                                        <div className="tag-item" key={itemsMedicalRegisterNo}>
                                                                                                            {itemsMedicalRegisterNo}
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className="button"
                                                                                                                onClick={() => this.handleDeleteMedicalRegister(itemsMedicalRegisterNo)}
                                                                                                            >
                                                                                                                &times;
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    ))}
                                                                                                    <input
                                                                                                        class="form-control-addsource-Personal"
                                                                                                        value={this.state.valuePersonalInfoAddSource}
                                                                                                        placeholder="Type or paste source link and press `Enter`"
                                                                                                        onKeyDown={this.handleKeyDownMedicalMedicalRegister}
                                                                                                        onChange={this.handleChangePersonalInfoAddSource}
                                                                                                    />
                                                                                                </div>
                                                                                            )
                                                                                        }

                                                                                        {
                                                                                            (this.state.PersonalInformation.country_id == '1') ? (
                                                                                                <div>
                                                                                                    <label for="exampleFormControlTextarea1" style={{ marginTop: '1%' }}>
                                                                                                        NPI No. </label>
                                                                                                    <input type="text" maxlength="10" class="form-control"
                                                                                                        id="input7" placeholder="Enter NPI No. "
                                                                                                        name="NPI_no"
                                                                                                        value={this.state.PersonalInformation.NPI_no}
                                                                                                        onChange={(e) => {
                                                                                                            var re = /^[a-z0-9]+$/i;
                                                                                                            if (re.test(e.target.value)) {
                                                                                                                let { PersonalInformation } = this.state;
                                                                                                                PersonalInformation.NPI_no = e.target.value;
                                                                                                                this.setState({ PersonalInformation });
                                                                                                            }
                                                                                                            if (e.target.value.length == '0') {
                                                                                                                // this.onKeyPressRestrictOnlyChar(e)
                                                                                                                let { PersonalInformation } = this.state;
                                                                                                                PersonalInformation.NPI_no = '';
                                                                                                                this.setState({ PersonalInformation });
                                                                                                            }
                                                                                                        }}
                                                                                                    />
                                                                                                    <a class="float-right" style={{ marginTop: '-3%', color: '#007bff' }}
                                                                                                        onClick={this.NPINoAddSourceFun}>+ Add source</a>
                                                                                                    {
                                                                                                        (!this.state.NPINoAddSource) ? (
                                                                                                            <div></div>
                                                                                                        ) : (
                                                                                                            <div style={{ marginTop: '8%' }}>
                                                                                                                {this.state.itemsNpiNo.map((itemsNpiNo) => (
                                                                                                                    <div className="tag-item" key={itemsNpiNo}>
                                                                                                                        {itemsNpiNo}
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="button"
                                                                                                                            onClick={() => this.handleDeleteNpiNo(itemsNpiNo)}
                                                                                                                        >
                                                                                                                            &times;
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                ))}
                                                                                                                <input
                                                                                                                    class="form-control-addsource-Personal"
                                                                                                                    value={this.state.valuePersonalInfoAddSource}
                                                                                                                    placeholder="Type or paste source link and press `Enter`"
                                                                                                                    onKeyDown={this.handleKeyDownNpiNo}
                                                                                                                    onChange={this.handleChangePersonalInfoAddSource}
                                                                                                                />
                                                                                                            </div>
                                                                                                        )
                                                                                                    }

                                                                                                </div>
                                                                                            ) : (
                                                                                                <div></div>
                                                                                            )
                                                                                        }

                                                                                    </div>
                                                                                ) :
                                                                                    (
                                                                                        <div></div>
                                                                                    )

                                                                            ) :
                                                                                (
                                                                                    <div>
                                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                            {/* Medical Schools  */}
                                                                                            Alma mater
                                                                                            <span class="mandatory">*</span></label>
                                                                                        <input type="text" class="form-control disabled"
                                                                                            autocomplete="off"
                                                                                            id="input7" placeholder="Enter Alma mater"
                                                                                            name="medical_schools"
                                                                                            value={this.state.PersonalInformation.medical_schools}
                                                                                        // onChange={(e) => {
                                                                                        //     let { PersonalInformation } = this.state;
                                                                                        //     PersonalInformation.medical_schools = e.target.value;
                                                                                        //     this.setState({ PersonalInformation });
                                                                                        // }}
                                                                                        />
                                                                                        <div className="loginErrCreateProject">{this.state.medicalschoolErr}</div>
                                                                                        <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                                            onClick={this.MedicalSchoolsAddSourceFun}>+ Add source</a>
                                                                                        {
                                                                                            (!this.state.MedicalSchoolsAddSource) ? (
                                                                                                <div></div>
                                                                                            ) : (
                                                                                                <div style={{ marginTop: '8%' }}>
                                                                                                    {this.state.itemsMedicalSchool.map((itemsMedicalSchool) => (
                                                                                                        <div className="tag-item" key={itemsMedicalSchool}>
                                                                                                            {itemsMedicalSchool}
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className="button"
                                                                                                                onClick={() => this.handleDeleteMedicalSchool(itemsMedicalSchool)}
                                                                                                            >
                                                                                                                &times;
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    ))}
                                                                                                    <input
                                                                                                        class="form-control-addsource-Personal"
                                                                                                        value={this.state.valuePersonalInfoAddSource}
                                                                                                        placeholder="Type or paste source link and press `Enter`"
                                                                                                        onKeyDown={this.handleKeyDownMedicalSchool}
                                                                                                        onChange={this.handleChangePersonalInfoAddSource}
                                                                                                    />
                                                                                                </div>
                                                                                            )
                                                                                        }

                                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                            License No. </label>
                                                                                        <input type="text" class="form-control"
                                                                                            id="input7" placeholder="Enter License No. "
                                                                                            name="medical_registration_no"
                                                                                            value={this.state.PersonalInformation.medical_registration_no}
                                                                                            onChange={(e) => {
                                                                                                var re = /^[a-z0-9]+$/i;
                                                                                                if (re.test(e.target.value)) {
                                                                                                    let { PersonalInformation } = this.state;
                                                                                                    PersonalInformation.medical_registration_no = e.target.value;
                                                                                                    this.setState({ PersonalInformation });
                                                                                                }
                                                                                                if (e.target.value.length == '0') {
                                                                                                    let { PersonalInformation } = this.state;
                                                                                                    PersonalInformation.medical_registration_no = '';
                                                                                                    this.setState({ PersonalInformation });
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                        <a class="float-right" style={{ marginTop: '-3%', color: '#007bff' }}
                                                                                            onClick={this.MedicalRegistrationNoAddSourceFun}>+ Add source</a>
                                                                                        {
                                                                                            (!this.state.MedicalRegistrationNoAddSource) ? (
                                                                                                <div></div>
                                                                                            ) : (
                                                                                                <div style={{ marginTop: '8%' }}>
                                                                                                    {this.state.itemsMedicalRegisterNo.map((itemsMedicalRegisterNo) => (
                                                                                                        <div className="tag-item" key={itemsMedicalRegisterNo}>
                                                                                                            {itemsMedicalRegisterNo}
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className="button"
                                                                                                                onClick={() => this.handleDeleteMedicalRegister(itemsMedicalRegisterNo)}
                                                                                                            >
                                                                                                                &times;
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    ))}
                                                                                                    <input
                                                                                                        class="form-control-addsource-Personal"
                                                                                                        value={this.state.valuePersonalInfoAddSource}
                                                                                                        placeholder="Type or paste source link and press `Enter`"
                                                                                                        onKeyDown={this.handleKeyDownMedicalMedicalRegister}
                                                                                                        onChange={this.handleChangePersonalInfoAddSource}
                                                                                                    />
                                                                                                </div>
                                                                                            )
                                                                                        }

                                                                                        {
                                                                                            (this.state.PersonalInformation.country_id == '1') ? (
                                                                                                <div>
                                                                                                    <label for="exampleFormControlTextarea1" style={{ marginTop: '1%' }}>
                                                                                                        NPI No. </label>
                                                                                                    <input type="text" maxlength="10" class="form-control"
                                                                                                        id="input7" placeholder="Enter NPI No. "
                                                                                                        name="NPI_no"
                                                                                                        value={this.state.PersonalInformation.NPI_no}
                                                                                                        onChange={(e) => {
                                                                                                            var re = /^[a-z0-9]+$/i;
                                                                                                            if (re.test(e.target.value)) {
                                                                                                                let { PersonalInformation } = this.state;
                                                                                                                PersonalInformation.NPI_no = e.target.value;
                                                                                                                this.setState({ PersonalInformation });
                                                                                                            }
                                                                                                            if (e.target.value.length == '0') {
                                                                                                                // this.onKeyPressRestrictOnlyChar(e)
                                                                                                                let { PersonalInformation } = this.state;
                                                                                                                PersonalInformation.NPI_no = '';
                                                                                                                this.setState({ PersonalInformation });
                                                                                                            }
                                                                                                        }}
                                                                                                    />
                                                                                                    <a class="float-right" style={{ marginTop: '-3%', color: '#007bff' }}
                                                                                                        onClick={this.NPINoAddSourceFun}>+ Add source</a>
                                                                                                    {
                                                                                                        (!this.state.NPINoAddSource) ? (
                                                                                                            <div></div>
                                                                                                        ) : (
                                                                                                            <div style={{ marginTop: '8%' }}>
                                                                                                                {this.state.itemsNpiNo.map((itemsNpiNo) => (
                                                                                                                    <div className="tag-item" key={itemsNpiNo}>
                                                                                                                        {itemsNpiNo}
                                                                                                                        <button
                                                                                                                            type="button"
                                                                                                                            className="button"
                                                                                                                            onClick={() => this.handleDeleteNpiNo(itemsNpiNo)}
                                                                                                                        >
                                                                                                                            &times;
                                                                                                                        </button>
                                                                                                                    </div>
                                                                                                                ))}
                                                                                                                <input
                                                                                                                    class="form-control-addsource-Personal"
                                                                                                                    value={this.state.valuePersonalInfoAddSource}
                                                                                                                    placeholder="Type or paste source link and press `Enter`"
                                                                                                                    onKeyDown={this.handleKeyDownNpiNo}
                                                                                                                    onChange={this.handleChangePersonalInfoAddSource}
                                                                                                                />
                                                                                                            </div>
                                                                                                        )
                                                                                                    }
                                                                                                </div>
                                                                                            ) : (
                                                                                                <div></div>
                                                                                            )
                                                                                        }

                                                                                    </div>
                                                                                )
                                                                        }

                                                                        {/* <label for="exampleFormControlTextarea1" style={{marginTop:'4%'}}>
                                               Brand </label>
                                            <input type="text" class="form-control" 
                                                id="input7" placeholder="Enter Brand " 
                                                name="brand"
                                                value={this.state.PersonalInformation.brand} 
                                                onChange={(e) => {
                                                    let { PersonalInformation } = this.state;
                                                    PersonalInformation.brand = e.target.value;
                                                    this.setState({ PersonalInformation });
                                                }}
                                            />
                                            <a class="float-right" style={{marginTop:'-3%', color:'#007bff'}}
                                                onClick={this.BrandAddSourceFun}>+ Add source</a>                                            
                                            {
                                                ( !this.state.BrandAddSource ) ? (
                                                    <div></div>
                                                ) : (
                                                    <div style={{marginTop: '8%'}}>
                                                        {this.state.itemsBrand.map((itemsBrand) => (
                                                        <div className="tag-item" key={itemsBrand}>
                                                            {itemsBrand}
                                                            <button
                                                            type="button"
                                                            className="button"
                                                            onClick={() => this.handleDeleteBrand(itemsBrand)}
                                                            >
                                                            &times;
                                                            </button>
                                                        </div>
                                                        ))}
                                                        <input
                                                            class="form-control-addsource-Personal" 
                                                            value={this.state.valuePersonalInfoAddSource}
                                                            placeholder="Type or paste source link and press `Enter`"
                                                            onKeyDown={this.handleKeyDownBrand}
                                                            onChange={this.handleChangePersonalInfoAddSource}
                                                        />
                                                    </div>
                                                )
                                            } */}

                                                                        <div>
                                                                            <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                                                Speciality <span class="mandatory">*</span></label>
                                                                            <Input type="select" id="roleSelect" className="form-control"
                                                                                style={{ height: '50px' }}
                                                                                // value={this.state.Speciality}
                                                                                //     onChange={async(e) => {
                                                                                //     let { Speciality } = this.state;
                                                                                //     Speciality = e.target.value;
                                                                                //     await this.setState({ Speciality });
                                                                                //     {this.getProcedureList()} 
                                                                                //     {this.getSubSpecialityList()}
                                                                                // }}
                                                                                name="specialty_id"
                                                                                value={this.state.PersonalInformation.specialty_id}
                                                                                onChange={async (e) => {
                                                                                    let { PersonalInformation } = this.state;
                                                                                    PersonalInformation.specialty_id = e.target.value;
                                                                                    PersonalInformation.procedure_type_id = '';
                                                                                    PersonalInformation.sub_specialty_id = '';
                                                                                    await this.setState({ PersonalInformation });
                                                                                    await this.setState({
                                                                                        ProcedureType: [],
                                                                                        SubSpecialityType: [],
                                                                                        showProcedureSubSpecialityDropdown: false,
                                                                                    })
                                                                                    { this.getProcedureList() }
                                                                                    { this.getSubSpecialityList() }
                                                                                    console.log('ProcedureType', this.state.ProcedureType)
                                                                                    console.log('PersonalInformation.procedure_type_id', this.state.PersonalInformation)
                                                                                    { this.ProcedureDropdown() }
                                                                                }}
                                                                            >
                                                                                <option value="0" >--Select Speciality--</option>
                                                                                {OptionsSpeciality}
                                                                            </Input>
                                                                            <div style={{
                                                                                color: "red", fontSize: "12px", marginTop: "1%",
                                                                                marginBottom: "5px",
                                                                            }}>
                                                                                {this.state.specialityErr}</div>
                                                                            <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                                onClick={this.SpecialityAddSourceFun}>+ Add source</a>
                                                                            {
                                                                                (!this.state.SpecialityAddSource) ? (
                                                                                    <div></div>
                                                                                ) : (
                                                                                    <div style={{ marginTop: '8%' }}>
                                                                                        {this.state.itemSpeciality.map((itemSpeciality) => (
                                                                                            <div className="tag-item" key={itemSpeciality}>
                                                                                                {itemSpeciality}
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="button"
                                                                                                    onClick={() => this.handleDeleteSpeciality(itemSpeciality)}
                                                                                                >
                                                                                                    &times;
                                                                                                </button>
                                                                                            </div>
                                                                                        ))}
                                                                                        <input
                                                                                            class="form-control-addsource-Personal"
                                                                                            value={this.state.valuePersonalInfoAddSource}
                                                                                            placeholder="Type or paste source link and press `Enter`"
                                                                                            onKeyDown={this.handleKeyDownSpeciality}
                                                                                            onChange={this.handleChangePersonalInfoAddSource}
                                                                                        />
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>

                                                                        {/* Sub Speciality Dropdown starts here */}
                                                                        {
                                                                            // ( !this.state.PersonalInformation.specialty_id ) ? (
                                                                            (this.state.showProcedureSubSpecialityDropdown == false) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div>
                                                                                    <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                        Sub Speciality Type <span class="mandatory">*</span></label>
                                                                                    <Multiselect
                                                                                        data={this.state.SubSpecialitydata}
                                                                                        placeholder="--Select Sub Speciality--"
                                                                                        valueField='id'
                                                                                        textField='sub_speciality_type'
                                                                                        defaultValue={this.state.SubSpecialityType}
                                                                                        onSelect={(valueField) => console.log(valueField)}
                                                                                        onChange={(event) => {
                                                                                            console.log(event);
                                                                                            var joined = [];
                                                                                            console.log("length : " + event.length);
                                                                                            if (event.length === 0) {
                                                                                                this.setState({ SubSpecialityType: [] });
                                                                                            }
                                                                                            event.map(async (data) => {
                                                                                                console.log("data in eve map : " + JSON.stringify(data));
                                                                                                joined = joined.concat(data.id);
                                                                                                console.log("data join : " + joined);
                                                                                                await this.setState({
                                                                                                    SubSpecialityType: joined
                                                                                                });
                                                                                                console.log("check this", this.state.SubSpecialityType)

                                                                                                var output = this.state.SubSpecialityType.map(function (item) {
                                                                                                    return item;
                                                                                                });

                                                                                                let { PersonalInformation } = this.state;
                                                                                                PersonalInformation.sub_specialty_id = output.join(",");
                                                                                                this.setState({ PersonalInformation });

                                                                                                console.log("check this", this.state.PersonalInformation.sub_specialty_id)
                                                                                                console.log(output.join(","))
                                                                                            })
                                                                                        }}
                                                                                    />
                                                                                    <div style={{
                                                                                        color: "red", fontSize: "12px", marginTop: "1%",
                                                                                        marginBottom: "5px",
                                                                                    }}>{this.state.subspecialitytypeErr}</div>
                                                                                    <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                                        onClick={this.SubSpecialityTypeAddSourceFun}>+ Add source</a>
                                                                                    {
                                                                                        (!this.state.SubSpecialityTypeAddSource) ? (
                                                                                            <div></div>
                                                                                        ) : (
                                                                                            <div style={{ marginTop: '8%' }}>
                                                                                                {this.state.itemsSubSpeciality.map((itemsSubSpeciality) => (
                                                                                                    <div className="tag-item" key={itemsSubSpeciality}>
                                                                                                        {itemsSubSpeciality}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteSubspeciality(itemsSubSpeciality)}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))}
                                                                                                <input
                                                                                                    class="form-control-addsource-Personal"
                                                                                                    value={this.state.valuePersonalInfoAddSource}
                                                                                                    placeholder="Type or paste source link and press `Enter`"
                                                                                                    onKeyDown={this.handleKeyDownSubSpeciality}
                                                                                                    onChange={this.handleChangePersonalInfoAddSource}
                                                                                                />
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        }

                                                                        {/* Procedure Dropdown starts here */}
                                                                        {
                                                                            // ( !this.state.PersonalInformation.specialty_id ) ? (
                                                                            (this.state.showProcedureSubSpecialityDropdown == false) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div>
                                                                                    <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                        Procedure Type <span class="mandatory">*</span></label>
                                                                                    <Multiselect
                                                                                        data={this.state.ProcedureData}
                                                                                        placeholder="--Select Procedure Type--"
                                                                                        valueField='id'
                                                                                        textField='procedure_type'
                                                                                        defaultValue={this.state.ProcedureType}
                                                                                        onSelect={(valueField) => console.log(valueField)}

                                                                                        onChange={(event) => {
                                                                                            console.log(event);
                                                                                            var joined = [];
                                                                                            console.log("length : " + event.length);
                                                                                            if (event.length === 0) {
                                                                                                this.setState({ ProcedureType: [] });
                                                                                            }
                                                                                            event.map(async (data) => {
                                                                                                console.log("data in eve map : " + JSON.stringify(data));
                                                                                                joined = joined.concat(data.id);
                                                                                                console.log("data join : " + joined);
                                                                                                await this.setState({
                                                                                                    ProcedureType: joined
                                                                                                });
                                                                                                console.log("check this ", this.state.ProcedureType)

                                                                                                var output = this.state.ProcedureType.map(function (item) {
                                                                                                    return item;
                                                                                                });

                                                                                                let { PersonalInformation } = this.state;
                                                                                                PersonalInformation.procedure_type_id = output.join(",");
                                                                                                this.setState({ PersonalInformation });

                                                                                                console.log("check this", this.state.PersonalInformation.procedure_type_id)
                                                                                                console.log(output.join(","))
                                                                                            })
                                                                                        }}
                                                                                    />

                                                                                    <div style={{
                                                                                        color: "red", fontSize: "12px", marginTop: "1%",
                                                                                        marginBottom: "5px",
                                                                                    }}>{this.state.proceduretypeErr}</div>
                                                                                    <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                                        onClick={this.ProcedureTypeAddSourceFun}>+ Add source</a>
                                                                                    {
                                                                                        (!this.state.ProcedureTypeAddSource) ? (
                                                                                            <div></div>
                                                                                        ) : (
                                                                                            <div style={{ marginTop: '8%' }}>
                                                                                                {this.state.itemsProcedure.map((itemsProcedure) => (
                                                                                                    <div className="tag-item" key={itemsProcedure}>
                                                                                                        {itemsProcedure}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteProcedure(itemsProcedure)}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))}
                                                                                                <input
                                                                                                    class="form-control-addsource-Personal"
                                                                                                    value={this.state.valuePersonalInfoAddSource}
                                                                                                    placeholder="Type or paste source link and press `Enter`"
                                                                                                    onKeyDown={this.handleKeyDownProcedure}
                                                                                                    onChange={this.handleChangePersonalInfoAddSource}
                                                                                                />
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        }

                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                            Doctor Contact No. <span class="mandatory">*</span></label>
                                                                        <PhoneInput
                                                                            inputStyle={{
                                                                                width: "100%",
                                                                                height: "50px",
                                                                                fontSize: "15px",
                                                                            }}
                                                                            width="50%"
                                                                            country={'in'}
                                                                            value={this.state.PersonalInformation.doctor_contact_no}
                                                                            onChange={(DoctorContactNo) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.doctor_contact_no = DoctorContactNo;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                        <div style={{
                                                                            color: "red", fontSize: "12px", marginTop: "1%",
                                                                            marginBottom: "5px",
                                                                        }}>{this.state.doctorcontactnoErr}</div>
                                                                        <a class="float-right" style={{ marginTop: '-1', color: '#007bff' }}
                                                                            onClick={this.DoctorContactNoAddSourceFunc}>+ Add source</a>
                                                                        {
                                                                            (!this.state.DoctorContactNoAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div style={{ marginTop: '8%' }}>
                                                                                    {this.state.itemsDoctorContact.map((itemsDoctorContact) => (
                                                                                        <div className="tag-item" key={itemsDoctorContact}>
                                                                                            {itemsDoctorContact}
                                                                                            <button
                                                                                                type="button"
                                                                                                className="button"
                                                                                                onClick={() => this.handleDeleteDoctorsContact(itemsDoctorContact)}
                                                                                            >
                                                                                                &times;
                                                                                            </button>
                                                                                        </div>
                                                                                    ))}
                                                                                    <input
                                                                                        class="form-control-addsource-Personal"
                                                                                        value={this.state.valuePersonalInfoAddSource}
                                                                                        placeholder="Type or paste source link and press `Enter`"
                                                                                        onKeyDown={this.handleKeyDownDoctorsContact}
                                                                                        onChange={this.handleChangePersonalInfoAddSource}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        }

                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '5%' }}>
                                                                            Doctor Email <span class="mandatory">*</span></label>
                                                                        <input type="text" class="form-control"
                                                                            id="input7" placeholder="Enter Email "
                                                                            name="doctor_email"
                                                                            value={this.state.PersonalInformation.doctor_email}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.doctor_email = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                        <div className="loginErrCreateProject">{this.state.doctoremailErr}</div>
                                                                        <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                            onClick={this.DoctorEmailAddSourceFun}>+ Add source</a>
                                                                        {
                                                                            (!this.state.DoctorEmailAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div style={{ marginTop: '8%' }}>
                                                                                    {this.state.itemsDoctorEmail.map((itemsDoctorEmail) => (
                                                                                        <div className="tag-item" key={itemsDoctorEmail}>
                                                                                            {itemsDoctorEmail}
                                                                                            <button
                                                                                                type="button"
                                                                                                className="button"
                                                                                                onClick={() => this.handleDeleteDoctorsEmail(itemsDoctorEmail)}
                                                                                            >
                                                                                                &times;
                                                                                            </button>
                                                                                        </div>
                                                                                    ))}
                                                                                    <input
                                                                                        class="form-control-addsource-Personal"
                                                                                        value={this.state.valuePersonalInfoAddSource}
                                                                                        placeholder="Type or paste source link and press `Enter`"
                                                                                        onKeyDown={this.handleKeyDownDoctorsEmail}
                                                                                        onChange={this.handleChangePersonalInfoAddSource}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        }

                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                            Fax </label>
                                                                        <input type="text" class="form-control"
                                                                            id="input7" placeholder="Enter Fax"
                                                                            value={this.state.PersonalInformation.fax}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.fax = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                        <a class="float-right" style={{ marginTop: '-3%', color: '#007bff' }}
                                                                            onClick={this.FaxAddSourceFun}>+ Add source</a>
                                                                        {
                                                                            (!this.state.FaxAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div style={{ marginTop: '8%' }}>
                                                                                    {this.state.itemsFax.map((itemsFax) => (
                                                                                        <div className="tag-item" key={itemsFax}>
                                                                                            {itemsFax}
                                                                                            <button
                                                                                                type="button"
                                                                                                className="button"
                                                                                                onClick={() => this.handleDeleteFax(itemsFax)}
                                                                                            >
                                                                                                &times;
                                                                                            </button>
                                                                                        </div>
                                                                                    ))}
                                                                                    <input
                                                                                        class="form-control-addsource-Personal"
                                                                                        value={this.state.valuePersonalInfoAddSource}
                                                                                        placeholder="Type or paste source link and press `Enter`"
                                                                                        onKeyDown={this.handleKeyDownFax}
                                                                                        onChange={this.handleChangePersonalInfoAddSource}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        }

                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                            LinkedIn Profile </label>
                                                                        <input type="text" class="form-control"
                                                                            id="input7" placeholder="Enter LinkedIn Profile "
                                                                            name="linked_in_profile"
                                                                            value={this.state.PersonalInformation.linked_in_profile}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.linked_in_profile = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                        <a class="float-right" style={{ marginTop: '-3%', color: '#007bff' }}
                                                                            onClick={this.LinkedInProfileAddSourceFun}>+ Add source</a>
                                                                        {
                                                                            (!this.state.LinkedInProfileAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div style={{ marginTop: '8%' }}>
                                                                                    {this.state.itemsLinkedIn.map((itemsLinkedIn) => (
                                                                                        <div className="tag-item" key={itemsLinkedIn}>
                                                                                            {itemsLinkedIn}
                                                                                            <button
                                                                                                type="button"
                                                                                                className="button"
                                                                                                onClick={() => this.handleDeleteLinkedIn(itemsLinkedIn)}
                                                                                            >
                                                                                                &times;
                                                                                            </button>
                                                                                        </div>
                                                                                    ))}
                                                                                    <input
                                                                                        class="form-control-addsource-Personal"
                                                                                        value={this.state.valuePersonalInfoAddSource}
                                                                                        placeholder="Type or paste source link and press `Enter`"
                                                                                        onKeyDown={this.handleKeyDownLinkedIn}
                                                                                        onChange={this.handleChangePersonalInfoAddSource}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        }

                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '1%' }}>
                                                                            Twitter Handle </label>
                                                                        <input type="text" class="form-control"
                                                                            id="input7" placeholder="Enter Twitter Profile "
                                                                            name="twitter_handle"
                                                                            value={this.state.PersonalInformation.twitter_handle}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.twitter_handle = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                        <a class="float-right" style={{ marginTop: '-3%', color: '#007bff' }}
                                                                            onClick={this.TwitterHandleAddSourceFun}>+ Add source</a>
                                                                        {
                                                                            (!this.state.TwitterHandleAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div style={{ marginTop: '8%' }}>
                                                                                    {this.state.itemsTwitter.map((itemsTwitter) => (
                                                                                        <div className="tag-item" key={itemsTwitter}>
                                                                                            {itemsTwitter}
                                                                                            <button
                                                                                                type="button"
                                                                                                className="button"
                                                                                                onClick={() => this.handleDeleteTwitter(itemsTwitter)}
                                                                                            >
                                                                                                &times;
                                                                                            </button>
                                                                                        </div>
                                                                                    ))}
                                                                                    <input
                                                                                        class="form-control-addsource-Personal"
                                                                                        value={this.state.valuePersonalInfoAddSource}
                                                                                        placeholder="Type or paste source link and press `Enter`"
                                                                                        onKeyDown={this.handleKeyDownTwitter}
                                                                                        onChange={this.handleChangePersonalInfoAddSource}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        }

                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                            Assistant/Secretary Contact No. </label>
                                                                        <PhoneInput
                                                                            inputStyle={{
                                                                                width: "100%",
                                                                                height: "50px",
                                                                                fontSize: "15px",
                                                                            }}
                                                                            width="50%"
                                                                            country={'in'}
                                                                            value={this.state.PersonalInformation.assistance_contact_no}
                                                                            onChange={(AssistanceContactNo) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.assistance_contact_no = AssistanceContactNo;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                        <a class="float-right" style={{ marginTop: '0.5%', color: '#007bff' }}
                                                                            onClick={this.AssistanceContactNoAddSourceFunc}>+ Add source</a>
                                                                        {
                                                                            (!this.state.AssistanceContactNoAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div style={{ marginTop: '8%' }}>
                                                                                    {this.state.itemsAssistanceContact.map((itemsAssistanceContact) => (
                                                                                        <div className="tag-item" key={itemsAssistanceContact}>
                                                                                            {itemsAssistanceContact}
                                                                                            <button
                                                                                                type="button"
                                                                                                className="button"
                                                                                                onClick={() => this.handleDeleteAssisContact(itemsAssistanceContact)}
                                                                                            >
                                                                                                &times;
                                                                                            </button>
                                                                                        </div>
                                                                                    ))}
                                                                                    <input
                                                                                        class="form-control-addsource-Personal"
                                                                                        value={this.state.valuePersonalInfoAddSource}
                                                                                        placeholder="Type or paste source link and press `Enter`"
                                                                                        onKeyDown={this.handleKeyDownAssisContact}
                                                                                        onChange={this.handleChangePersonalInfoAddSource}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        }

                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '5%' }}>
                                                                            Assistant E-mail </label>
                                                                        <input type="text" class="form-control"
                                                                            id="input7" placeholder="Enter Email "
                                                                            name="assistance_email"
                                                                            value={this.state.PersonalInformation.assistance_email}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.assistance_email = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                        <a class="float-right" style={{ marginTop: '-3%', color: '#007bff' }}
                                                                            onClick={this.AssistanceEmailAddSourceFun}>+ Add source</a>
                                                                        {
                                                                            (!this.state.AssistanceEmailAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div style={{ marginTop: '8%' }}>
                                                                                    {this.state.itemsAssistanceEmail.map((itemsAssistanceEmail) => (
                                                                                        <div className="tag-item" key={itemsAssistanceEmail}>
                                                                                            {itemsAssistanceEmail}
                                                                                            <button
                                                                                                type="button"
                                                                                                className="button"
                                                                                                onClick={() => this.handleDeleteAssisEmail(itemsAssistanceEmail)}
                                                                                            >
                                                                                                &times;
                                                                                            </button>
                                                                                        </div>
                                                                                    ))}
                                                                                    <input
                                                                                        class="form-control-addsource-Personal"
                                                                                        value={this.state.valuePersonalInfoAddSource}
                                                                                        placeholder="Type or paste source link and press `Enter`"
                                                                                        onKeyDown={this.handleKeyDownAssisEmail}
                                                                                        onChange={this.handleChangePersonalInfoAddSource}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        }

                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '1%' }}>
                                                                            Website </label>
                                                                        <input type="text" class="form-control"
                                                                            id="input7" placeholder="Enter Website "
                                                                            name="website"
                                                                            value={this.state.PersonalInformation.website}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.website = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                        <a class="float-right" style={{ marginTop: '-3%', color: '#007bff' }}
                                                                            onClick={this.WebsiteAddSourceFun}>+ Add source</a>
                                                                        {
                                                                            (!this.state.WebsiteAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div style={{ marginTop: '8%' }}>
                                                                                    {this.state.itemsWebsite.map((itemsWebsite) => (
                                                                                        <div className="tag-item" key={itemsWebsite}>
                                                                                            {itemsWebsite}
                                                                                            <button
                                                                                                type="button"
                                                                                                className="button"
                                                                                                onClick={() => this.handleDeleteWebsite(itemsWebsite)}
                                                                                            >
                                                                                                &times;
                                                                                            </button>
                                                                                        </div>
                                                                                    ))}
                                                                                    <input
                                                                                        class="form-control-addsource-Personal"
                                                                                        value={this.state.valuePersonalInfoAddSource}
                                                                                        placeholder="Type or paste source link and press `Enter`"
                                                                                        onKeyDown={this.handleKeyDownWebsite}
                                                                                        onChange={this.handleChangePersonalInfoAddSource}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        }

                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '1%' }}>
                                                                            Contract </label>
                                                                        <input type="text" class="form-control"
                                                                            id="input7" placeholder="Enter Contract "
                                                                            name="contract"
                                                                            value={this.state.PersonalInformation.contract}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.contract = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />

                                                                        <label for="contractfile" style={{ marginTop: '3%' }}>
                                                                            Contract File </label>
                                                                        <div class="form-control" style={{ height: '25%' }}>
                                                                            <input type='file'
                                                                                id="contractfile"
                                                                                // accept="image/*"
                                                                                onChange={this.onUploadContract} hidden />
                                                                            <label htmlFor="contractfile"
                                                                                style={{ color: 'blue', fontSize: "15px", marginTop: '1.5%' }}>
                                                                                Upload Contract File
                                                                            </label>
                                                                            {
                                                                                (this.state.ContractFileName) ? (
                                                                                    <div class="float-right" style={{ marginTop: '1.5%' }}>
                                                                                        <text>{this.state.ContractFileName}</text>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div class="float-right" style={{ marginTop: '1.5%' }}>
                                                                                        No file Choosen
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </div>

                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '1%' }}>
                                                                            Country <span class="mandatory">*</span></label>
                                                                        <Input type="select" id="roleSelect" className="form-control"
                                                                            style={{ height: '50px' }}
                                                                            // value={this.state.Country}
                                                                            //     onChange={async(e) => {
                                                                            //     let { Country } = this.state;
                                                                            //     Country = e.target.value;
                                                                            //     await this.setState({ Country });
                                                                            //     {this.getStateList()}
                                                                            //     {this.getCityList()}
                                                                            // }}
                                                                            name="country_id"
                                                                            value={this.state.PersonalInformation.country_id}
                                                                            onChange={async (e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.country_id = e.target.value;
                                                                                PersonalInformation.NPI_no = '';
                                                                                await this.setState({ PersonalInformation });
                                                                                { this.getStateList() }
                                                                                { this.getCityList() }
                                                                            }}
                                                                        >
                                                                            <option value="0" >--Select Country--</option>
                                                                            {OptionsCountry}
                                                                        </Input>
                                                                        <div style={{
                                                                            color: "red", fontSize: "12px", marginTop: "1%",
                                                                            marginBottom: "5px",
                                                                        }}>{this.state.countryErr}</div>
                                                                        <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                            onClick={this.CountryAddSourceFun}>+ Add source</a>
                                                                        {
                                                                            (!this.state.CountryAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div style={{ marginTop: '8%' }}>
                                                                                    {this.state.itemsCountry.map((itemsCountry) => (
                                                                                        <div className="tag-item" key={itemsCountry}>
                                                                                            {itemsCountry}
                                                                                            <button
                                                                                                type="button"
                                                                                                className="button"
                                                                                                onClick={() => this.handleDeleteCountry(itemsCountry)}
                                                                                            >
                                                                                                &times;
                                                                                            </button>
                                                                                        </div>
                                                                                    ))}
                                                                                    <input
                                                                                        class="form-control-addsource-Personal"
                                                                                        value={this.state.valuePersonalInfoAddSource}
                                                                                        placeholder="Type or paste source link and press `Enter`"
                                                                                        onKeyDown={this.handleKeyDownCountry}
                                                                                        onChange={this.handleChangePersonalInfoAddSource}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        }

                                                                        {/* State Dropdown starts here */}
                                                                        {
                                                                            (!this.state.PersonalInformation.country_id) ? (
                                                                                // <label for="exampleFormControlTextarea1">Procedure Type</label>
                                                                                <div></div>
                                                                            ) : (
                                                                                <div>
                                                                                    <label for="exampleFormControlTextarea1"
                                                                                        style={{ marginTop: '5%' }}>
                                                                                        State <span class="mandatory">*</span></label>
                                                                                    <Input type="select" id="roleSelect" className="form-control"
                                                                                        style={{ height: '50px' }}
                                                                                        // value={this.state.State}
                                                                                        //     onChange={async(e) => {
                                                                                        //     let { State } = this.state;
                                                                                        //     State = e.target.value;
                                                                                        //     await this.setState({ State });
                                                                                        // }}
                                                                                        value={this.state.PersonalInformation.state_id}
                                                                                        onChange={async (e) => {
                                                                                            let { PersonalInformation } = this.state;
                                                                                            PersonalInformation.state_id = e.target.value;
                                                                                            await this.setState({ PersonalInformation });
                                                                                        }}
                                                                                    >
                                                                                        <option value="0" >--Select State--</option>
                                                                                        {OptionsState}
                                                                                    </Input>
                                                                                    <div style={{
                                                                                        color: "red", fontSize: "12px", marginTop: "1%",
                                                                                        marginBottom: "5px",
                                                                                    }}>{this.state.stateErr}</div>
                                                                                    <a class="float-right" style={{ marginTop: '0.5%', color: '#007bff' }}
                                                                                        onClick={this.StateAddSourceFun}>+ Add source</a>
                                                                                    {
                                                                                        (!this.state.StateAddSource) ? (
                                                                                            <div></div>
                                                                                        ) : (
                                                                                            <div style={{ marginTop: '8%' }}>
                                                                                                {this.state.itemsState.map((itemsState) => (
                                                                                                    <div className="tag-item" key={itemsState}>
                                                                                                        {itemsState}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteState(itemsState)}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))}
                                                                                                <input
                                                                                                    class="form-control-addsource-Personal"
                                                                                                    value={this.state.valuePersonalInfoAddSource}
                                                                                                    placeholder="Type or paste source link and press `Enter`"
                                                                                                    onKeyDown={this.handleKeyDownState}
                                                                                                    onChange={this.handleChangePersonalInfoAddSource}
                                                                                                />
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        }

                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                            Primary Work Address <span class="mandatory">*</span></label>
                                                                        <input type="text" class="form-control"
                                                                            id="input7" placeholder="Enter First Address "
                                                                            name="address_1"
                                                                            value={this.state.PersonalInformation.address_1}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.address_1 = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                        <div className="loginErrCreateProject">{this.state.address1Err}</div>
                                                                        <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                            onClick={this.Address1AddSourceFun}>+ Add source</a>
                                                                        {
                                                                            (!this.state.Address1AddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div style={{ marginTop: '8%' }}>
                                                                                    {this.state.itemsAddress1.map((itemsAddress1) => (
                                                                                        <div className="tag-item" key={itemsAddress1}>
                                                                                            {itemsAddress1}
                                                                                            <button
                                                                                                type="button"
                                                                                                className="button"
                                                                                                onClick={() => this.handleDeleteAddress1(itemsAddress1)}
                                                                                            >
                                                                                                &times;
                                                                                            </button>
                                                                                        </div>
                                                                                    ))}
                                                                                    <input
                                                                                        class="form-control-addsource-Personal"
                                                                                        value={this.state.valuePersonalInfoAddSource}
                                                                                        placeholder="Type or paste source link and press `Enter`"
                                                                                        onKeyDown={this.handleKeyDownAddress1}
                                                                                        onChange={this.handleChangePersonalInfoAddSource}
                                                                                    />
                                                                                </div>
                                                                            )
                                                                        }

                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                            Primary Work Address 2 </label>
                                                                        <input type="text" class="form-control"
                                                                            id="input7" placeholder="Enter Second Address "
                                                                            name="address_2"
                                                                            value={this.state.PersonalInformation.address_2}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.address_2 = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                        {/* <a class="float-right" style={{marginTop:'-3%', color:'#007bff'}}
                                                onClick={this.Address2AddSourceFun}>+ Add source</a>                                            
                                            {
                                                ( !this.state.Address2AddSource ) ? (
                                                    <div></div>
                                                ) : (
                                                    <div style={{marginTop: '8%'}}>
                                                    <input type="text" class="form-control" 
                                                        id="input4" placeholder="Paste Source URL" 
                                                        value={this.state.Address2SourceUrl} 
                                                        onChange={(e) => {
                                                            let { Address2SourceUrl } = this.state;
                                                            Address2SourceUrl = e.target.value;
                                                            this.setState({ Address2SourceUrl });
                                                        }}/>
                                                    </div>
                                                )
                                            } */}

                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '1%' }}>
                                                                            Zip Code <span class="mandatory">*</span></label>
                                                                        <input type="text" class="form-control"
                                                                            id="input7" placeholder="Enter Zip Code "
                                                                            name="zip_code"
                                                                            value={this.state.PersonalInformation.zip_code}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.zip_code = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                        <div className="loginErrCreateProject">{this.state.zipcodeErr}</div>

                                                                        {/* Work details */}
                                                                        {
                                                                            this.state.WorkDetails.map((el, i) => {

                                                                                var arrayCurruntPlaceOfWork = [];
                                                                                var arrayDesignation = [];
                                                                                var arrayDepartment = [];

                                                                                if (el.current_place_of_work_links !== '') {
                                                                                    arrayCurruntPlaceOfWork = el.current_place_of_work_links.split(',')
                                                                                    arrayCurruntPlaceOfWork = arrayCurruntPlaceOfWork.splice(1)
                                                                                }
                                                                                if (el.designation_links !== '') {
                                                                                    arrayDesignation = el.designation_links.split(',')
                                                                                    arrayDesignation = arrayDesignation.splice(1)
                                                                                }
                                                                                if (el.department_links !== '') {
                                                                                    arrayDepartment = el.department_links.split(',')
                                                                                    arrayDepartment = arrayDepartment.splice(1)
                                                                                }

                                                                                return (
                                                                                    <div key={i}>
                                                                                        {
                                                                                            (i == 0) ? (
                                                                                                <div></div>
                                                                                            ) : (
                                                                                                <div>
                                                                                                    <h3 class="mt-30">Work Details {i + 1}</h3>
                                                                                                    {this.state.WorkDetails.length !== 1 &&
                                                                                                        <a class="float-right" style={{ marginTop: '-8%', color: '#007bff' }}
                                                                                                            onClick={this.removeClickWorkDetails.bind(this, i)}>x</a>
                                                                                                    }
                                                                                                </div>
                                                                                            )
                                                                                        }

                                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                                                            Current Place of Work <span class="mandatory">*</span></label>
                                                                                        <input type="text" class="form-control"
                                                                                            id="input7" placeholder="Enter Current Place of Work"
                                                                                            name="current_place_of_work" value={el.current_place_of_work || ''}
                                                                                            onChange={this.handleChangeWorkDetails.bind(this, i)}
                                                                                        />
                                                                                        <div className="loginErrCreateProject">
                                                                                            {
                                                                                                (el.current_place_of_work != '') ? (<div></div>) : (this.state.placeofworkErr)
                                                                                            }
                                                                                        </div>
                                                                                        <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                                            onClick={this.PlaceOfWorkAddSourceFun}>+ Add source</a>
                                                                                        {
                                                                                            (!this.state.PlaceOfWorkAddSource) ? (
                                                                                                <div></div>
                                                                                            ) : (
                                                                                                <div style={{ marginTop: '8%' }}>
                                                                                                    {
                                                                                                        arrayCurruntPlaceOfWork.flat().map((item) => (
                                                                                                            <div class="col-md-5" key={item}>
                                                                                                                {item}
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="button"
                                                                                                                    onClick={() => this.handleDeleteWorkingDetails(el.current_place_of_work_links.split(','), item, i, "current_place_of_work_links")}
                                                                                                                >
                                                                                                                    &times;
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        ))
                                                                                                    }
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        class="form-control-addsource"
                                                                                                        value={this.state.value}
                                                                                                        placeholder="Type or paste source link and press `Enter`"
                                                                                                        onKeyDown={e => this.handleKeyDownWorkingDetails(e, i, "current_place_of_work_links")}
                                                                                                        onChange={this.handleChangeWorkingDetailsAddSource}
                                                                                                    />
                                                                                                </div>
                                                                                            )
                                                                                        }

                                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                            Designation <span class="mandatory">*</span></label>
                                                                                        <input type="text" class="form-control"
                                                                                            id="input7" placeholder="Enter Designation"
                                                                                            name="designation" value={el.designation || ''}
                                                                                            onChange={this.handleChangeWorkDetails.bind(this, i)}
                                                                                        />
                                                                                        <div className="loginErrCreateProject">
                                                                                            {
                                                                                                (el.designation != '') ? (<div></div>) : (this.state.designationErr)
                                                                                            }
                                                                                        </div>
                                                                                        <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                                            onClick={this.DesignationAddSourceFun}>+ Add source</a>
                                                                                        {
                                                                                            (!this.state.DesignationAddSource) ? (
                                                                                                <div></div>
                                                                                            ) : (
                                                                                                <div style={{ marginTop: '8%' }}>
                                                                                                    {
                                                                                                        arrayDesignation.flat().map((item) => (
                                                                                                            <div class="col-md-5" key={item}>
                                                                                                                {item}
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="button"
                                                                                                                    onClick={() => this.handleDeleteWorkingDetails(el.designation_links.split(','), item, i, "designation_links")}
                                                                                                                >
                                                                                                                    &times;
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        ))
                                                                                                    }
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        class="form-control-addsource"
                                                                                                        value={this.state.value}
                                                                                                        placeholder="Type or paste source link and press `Enter`"
                                                                                                        onKeyDown={e => this.handleKeyDownWorkingDetails(e, i, "designation_links")}
                                                                                                        onChange={this.handleChangeWorkingDetailsAddSource}
                                                                                                    />
                                                                                                </div>
                                                                                            )
                                                                                        }

                                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                            Department <span class="mandatory">*</span></label>
                                                                                        <input type="text" class="form-control"
                                                                                            id="input7" placeholder="Enter Department"
                                                                                            name="department" value={el.department || ''}
                                                                                            onChange={this.handleChangeWorkDetails.bind(this, i)}
                                                                                        />
                                                                                        <div className="loginErrCreateProject">
                                                                                            {
                                                                                                (el.department != '') ? (<div></div>) : (this.state.departmentErr)
                                                                                            }
                                                                                        </div>
                                                                                        <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                                            onClick={this.DepartmentAddSourceFun}>+ Add source</a>
                                                                                        {
                                                                                            (!this.state.DepartmentAddSource) ? (
                                                                                                <div></div>
                                                                                            ) : (
                                                                                                <div style={{ marginTop: '8%' }}>
                                                                                                    {
                                                                                                        arrayDepartment.flat().map((item) => (
                                                                                                            <div class="col-md-5" key={item}>
                                                                                                                {item}
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="button"
                                                                                                                    onClick={() => this.handleDeleteWorkingDetails(el.department_links.split(','), item, i, "department_links")}
                                                                                                                >
                                                                                                                    &times;
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        ))
                                                                                                    }
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        class="form-control-addsource"
                                                                                                        value={this.state.value}
                                                                                                        placeholder="Type or paste source link and press `Enter`"
                                                                                                        onKeyDown={e => this.handleKeyDownWorkingDetails(e, i, "department_links")}
                                                                                                        onChange={this.handleChangeWorkingDetailsAddSource}
                                                                                                    />
                                                                                                </div>
                                                                                            )
                                                                                        }



                                                                                        <hr />
                                                                                        {/* Add More and remove */}

                                                                                        {this.state.WorkDetails.length - 1 === i &&
                                                                                            <a style={{ marginTop: '5%', color: '#007bff' }}
                                                                                                onClick={this.addClickWorkDetails.bind(this)}>+ Add More Work Details</a>
                                                                                        }


                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }

                                                                        {/* <label for="exampleFormControlTextarea1" style={{marginTop:'3%'}}>
                                               Last Updated Date <span class="mandatory">*</span></label>
                                            <input type="date" class="form-control" 
                                                id="input7" placeholder="Choose Date " 
                                                name="last_updated_date"
                                                value={this.state.PersonalInformation.last_updated_date} 
                                                onChange={(e) => {
                                                    let { PersonalInformation } = this.state;
                                                    PersonalInformation.last_updated_date = e.target.value;
                                                    this.setState({ PersonalInformation });
                                                }}
                                            />
                                            <div className="loginErrCreateProject">{this.state.lastupdateddateErr}</div> */}

                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </section>
                                                    ) : (
                                                        <div>
                                                        </div>
                                                    )
                                                }
                                                {/* Biography form start here*/}
                                                {
                                                    (this.state.BiographyFlag == true) ? (
                                                        <section style={{ marginBottom: '50%' }}>
                                                            <h3 class="mt-30">Biography</h3>
                                                            <div class="scrollable-box">
                                                                <form>
                                                                    {
                                                                        (this.state.KOLType == 'KOL Community' || this.state.KOLType == '') ? (
                                                                            <div class="form-group">
                                                                                <div class="form-group">
                                                                                    <label for="exampleFormControlTextarea1">
                                                                                        Citizenship <span class="mandatory">*</span></label>
                                                                                    <Input type="select" id="roleSelect" className="form-control"
                                                                                        //  maxMenuHeight={10}
                                                                                        style={{ height: '50px' }}
                                                                                        // autofocus="true"
                                                                                        name="citizenship"
                                                                                        value={this.state.Biography.citizenship}
                                                                                        onChange={(e) => {
                                                                                            let { Biography } = this.state;
                                                                                            Biography.citizenship = e.target.value;
                                                                                            this.setState({ Biography });
                                                                                        }}
                                                                                    >
                                                                                        <option value="0" >--Select Citizenship--</option>
                                                                                        {OptionsCitizenship}
                                                                                        {/* <option value = "Afghans" >Afghans</option>
                                            <option value = "Albanians" >Albanians</option> */}


                                                                                    </Input>
                                                                                </div>
                                                                                <div className="loginErrCreateProject">{this.state.citizenshipErr}</div>
                                                                                <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                                    onClick={this.CitizenshipAddSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.CitizenshipAddSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div style={{ marginTop: '8%' }}>
                                                                                            {this.state.itemsCitizenship.map((itemsCitizenship) => (
                                                                                                <div className="tag-item" key={itemsCitizenship}>
                                                                                                    {itemsCitizenship}
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="button"
                                                                                                        onClick={() => this.handleDeleteCitizenship(itemsCitizenship)}
                                                                                                    >
                                                                                                        &times;
                                                                                                    </button>
                                                                                                </div>
                                                                                            ))}
                                                                                            <input
                                                                                                class="form-control-addsource-Personal"
                                                                                                value={this.state.valueBiographyAddSource}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={this.handleKeyDownCitzenship}
                                                                                                onChange={this.handleChangeBiographyAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                {/* <label for="exampleFormControlTextarea1" style={{marginTop:'3%'}}>
                                        Licensed Regions <span class="mandatory">*</span></label>
                                    <input type="text" class="form-control" 
                                        id="input7" placeholder="Enter Licensed Regions " 
                                        name="licensed_regions" 
                                        value={this.state.Biography.licensed_regions} 
                                        onChange={(e) => {
                                            let { Biography } = this.state;
                                            Biography.licensed_regions = e.target.value;
                                            this.setState({ Biography });
                                        }}  
                                    />
                                    <div className="loginErrCreateProject">{this.state.licensedregionsErr}</div>
                                    <a class="float-right" style={{marginTop:'-1%', color:'#007bff'}}
                                        onClick={this.LicensedRegionsAddSourceFun}>+ Add source</a>
                                    {
                                        ( !this.state.LicensedRegionsAddSource ) ? (
                                            <div></div>
                                        ) : (
                                        <div style={{marginTop: '8%'}}>
                                            {this.state.itemsLicensedRegions.map((itemsLicensedRegions) => (
                                            <div className="tag-item" key={itemsLicensedRegions}>
                                                {itemsLicensedRegions}
                                                <button
                                                type="button"
                                                className="button"
                                                onClick={() => this.handleDeleteLicenseRegions(itemsLicensedRegions)}
                                                >
                                                &times;
                                                </button>
                                            </div>
                                            ))}
                                            <input
                                                class="form-control-addsource-Personal" 
                                                value={this.state.valueBiographyAddSource}
                                                placeholder="Type or paste source link and press `Enter`"
                                                onKeyDown={this.handleKeyDownLicenseRegions}
                                                onChange={this.handleChangeBiographyAddSource}
                                            />
                                        </div>
                                        )
                                    } */}

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                                                    Language Spoken </label>
                                                                                <Multiselect
                                                                                    data={this.state.Language}
                                                                                    name="language_spoken"
                                                                                    placeholder='--Select Lanuages--'
                                                                                    // value= {el.areas_of_interest_links ||''}
                                                                                    valueField='id'
                                                                                    textField='language'
                                                                                    defaultValue={this.state.LanguageSpoken}
                                                                                    onSelect={(valueField) => console.log(valueField)}
                                                                                    // onChange={(event)=>{
                                                                                    //     console.log(event);
                                                                                    //     event.map(async(data)=>{
                                                                                    //     var joined = this.state.LanguageSpoken.concat(data.id);
                                                                                    //     await this.setState({
                                                                                    //         LanguageSpoken: joined
                                                                                    //     });  
                                                                                    //         var output = this.state.LanguageSpoken.map( function(item){
                                                                                    //         return item;
                                                                                    //         });

                                                                                    //         let { Biography } = this.state;
                                                                                    //         Biography.language_spoken = output.join(",") ;
                                                                                    //         this.setState({ Biography });  

                                                                                    //         console.log("check this",this.state.Biography.language_spoken)
                                                                                    //         console.log( output.join(",") )
                                                                                    //     })
                                                                                    // }}
                                                                                    onChange={(event) => {
                                                                                        console.log(event);
                                                                                        var joined = [];
                                                                                        console.log("length : " + event.length);
                                                                                        if (event.length === 0) {
                                                                                            this.setState({ LanguageSpoken: [] });
                                                                                        }
                                                                                        event.map(async (data) => {
                                                                                            console.log("data in eve map : " + JSON.stringify(data));
                                                                                            joined = joined.concat(data.id);
                                                                                            console.log("data join : " + joined);
                                                                                            await this.setState({
                                                                                                LanguageSpoken: joined
                                                                                            });
                                                                                            console.log("check this", this.state.LanguageSpoken)

                                                                                            var output = this.state.LanguageSpoken.map(function (item) {
                                                                                                return item;
                                                                                            });

                                                                                            let { Biography } = this.state;
                                                                                            Biography.language_spoken = output.join(",");
                                                                                            this.setState({ Biography });

                                                                                            console.log("check this", this.state.Biography.language_spoken)
                                                                                            console.log(output.join(","))
                                                                                        })
                                                                                    }}

                                                                                />
                                                                                {/* <a class="float-right" style={{marginTop:'0.5%', color:'#007bff'}}
                                            onClick={this.LanguageSpokenAddSourceFun}>+ Add source</a>
                                        {
                                            ( !this.state.LanguageSpokenAddSource) ? (
                                                <div></div>
                                            ) : (
                                            <div style={{marginTop: '8%'}}>
                                                {this.state.itemsLanguageSpoken.map((itemsLanguageSpoken) => (
                                                <div className="tag-item" key={itemsLanguageSpoken}>
                                                    {itemsLanguageSpoken}
                                                    <button
                                                    type="button"
                                                    className="button"
                                                    onClick={() => this.handleDeleteLanguage(itemsLanguageSpoken)}
                                                    >
                                                    &times;
                                                    </button>
                                                </div>
                                                ))}
                                                <input
                                                    class="form-control-addsource-Personal" 
                                                    value={this.state.valueBiographyAddSource}
                                                    placeholder="Type or paste source link and press `Enter`"
                                                    onKeyDown={this.handleKeyDownLanguage}
                                                    onChange={this.handleChangeBiographyAddSource}
                                                />
                                            </div>
                                            )
                                        } */}

                                                                                {/* <label for="exampleFormControlTextarea1" style={{marginTop:'4%'}}>
                                            Work Phone <span class="mandatory">*</span></label>
                                        <PhoneInput
                                            inputStyle={{
                                                width: "100%",
                                                height: "50px",
                                                fontSize: "15px",
                                            }}
                                            width= "50%"
                                            country={'in'}
                                            // value={this.state.WorkPhone}
                                            // onChange={WorkPhone => this.setState({ WorkPhone })}
                                            value={this.state.Biography.work_phone} 
                                                onChange={(WorkPhone) => {
                                                    let { Biography } = this.state;
                                                    Biography.work_phone = WorkPhone;
                                                    this.setState({ Biography });
                                                }}
                                        />
                                        <div style={{color: "red", fontSize: "12px", marginTop:"1%",
                                                        marginBottom: "5px", }}>{this.state.workphoneErr}</div>
                                        <a class="float-right" style={{ color:'#007bff'}}
                                            onClick={this.WorkPhoneAddSourceFun}>+ Add source</a>
                                        {
                                            ( !this.state.WorkPhoneAddSource) ? (
                                                <div></div>
                                            ) : (
                                            <div style={{marginTop: '8%'}}>
                                                {this.state.itemsWorkPhone.map((itemsWorkPhone) => (
                                                <div className="tag-item" key={itemsWorkPhone}>
                                                    {itemsWorkPhone}
                                                    <button
                                                    type="button"
                                                    className="button"
                                                    onClick={() => this.handleDeleteWorkPhone(itemsWorkPhone)}
                                                    >
                                                    &times;
                                                    </button>
                                                </div>
                                                ))}
                                                <input
                                                    class="form-control-addsource-Personal" 
                                                    value={this.state.valueBiographyAddSource}
                                                    placeholder="Type or paste source link and press `Enter`"
                                                    onKeyDown={this.handleKeyDownWorkPhone}
                                                    onChange={this.handleChangeBiographyAddSource}
                                                />
                                            </div>
                                            )
                                        } */}

                                                                                {/* <label for="exampleFormControlTextarea1" style={{marginTop:'4%'}}>
                                            Work Email <span class="mandatory">*</span></label>
                                        <input type="text" class="form-control" 
                                            id="input7" placeholder="Enter Work Email " 
                                            value={this.state.Biography.work_email} 
                                            onChange={(e) => {
                                                let { Biography } = this.state;
                                                Biography.work_email = e.target.value;
                                                this.setState({ Biography });
                                            }}
                                        />
                                        <div className="loginErrCreateProject">{this.state.workemailErr}</div>
                                        <a class="float-right" style={{color:'#007bff', marginTop:'-1%'}}
                                            onClick={this.WorkEmailAddSourceFun}>+ Add source</a>
                                        {
                                            ( !this.state.WorkEmailAddSource) ? (
                                                <div></div>
                                            ) : (
                                            <div style={{marginTop: '8%'}}>
                                                {this.state.itemsWorkEmail.map((itemsWorkEmail) => (
                                                <div className="tag-item" key={itemsWorkEmail}>
                                                    {itemsWorkEmail}
                                                    <button
                                                    type="button"
                                                    className="button"
                                                    onClick={() => this.handleDeleteWorkEmail(itemsWorkEmail)}
                                                    >
                                                    &times;
                                                    </button>
                                                </div>
                                                ))}
                                                <input
                                                    class="form-control-addsource-Personal" 
                                                    value={this.state.valueBiographyAddSource}
                                                    placeholder="Type or paste source link and press `Enter`"
                                                    onKeyDown={this.handleKeyDownWorkEmail}
                                                    onChange={this.handleChangeBiographyAddSource}
                                                />
                                            </div>
                                            )
                                        } */}

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                                                    LinkedIn Followers</label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter LinkedIn Followers"
                                                                                    value={this.state.Biography.linkedin_followers}
                                                                                    onChange={(e) => {
                                                                                        let { Biography } = this.state;
                                                                                        Biography.linkedin_followers = e.target.value;
                                                                                        this.setState({ Biography });
                                                                                    }}
                                                                                />
                                                                                <a class="float-right" style={{ marginTop: '-3%', color: '#007bff' }}
                                                                                    onClick={this.LinkedInFollowersAddSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.LinkedInFollowersAddSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div style={{ marginTop: '8%' }}>
                                                                                            {this.state.itemsLinkedInFolllowers.map((itemsLinkedInFolllowers) => (
                                                                                                <div className="tag-item" key={itemsLinkedInFolllowers}>
                                                                                                    {itemsLinkedInFolllowers}
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="button"
                                                                                                        onClick={() => this.handleDeleteLinkedInFollowers(itemsLinkedInFolllowers)}
                                                                                                    >
                                                                                                        &times;
                                                                                                    </button>
                                                                                                </div>
                                                                                            ))}
                                                                                            <input
                                                                                                class="form-control-addsource-Personal"
                                                                                                value={this.state.valueBiographyAddSource}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={this.handleKeyDownLinkedInFollowers}
                                                                                                onChange={this.handleChangeBiographyAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Twitter Followers </label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Twitter Followers"
                                                                                    value={this.state.Biography.twitter_followers}
                                                                                    onChange={(e) => {
                                                                                        let { Biography } = this.state;
                                                                                        Biography.twitter_followers = e.target.value;
                                                                                        this.setState({ Biography });
                                                                                    }}
                                                                                />
                                                                                <a class="float-right" style={{ marginTop: '-3%', color: '#007bff' }}
                                                                                    onClick={this.TwitterFollowersAddSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.TwitterFollowersAddSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div style={{ marginTop: '8%' }}>
                                                                                            {this.state.itemsTwitterFollowers.map((itemsTwitterFollowers) => (
                                                                                                <div className="tag-item" key={itemsTwitterFollowers}>
                                                                                                    {itemsTwitterFollowers}
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="button"
                                                                                                        onClick={() => this.handleDeleteTwitterFollowers(itemsTwitterFollowers)}
                                                                                                    >
                                                                                                        &times;
                                                                                                    </button>
                                                                                                </div>
                                                                                            ))}
                                                                                            <input
                                                                                                class="form-control-addsource-Personal"
                                                                                                value={this.state.valueBiographyAddSource}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={this.handleKeyDownTwitterFollowers}
                                                                                                onChange={this.handleChangeBiographyAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Biography  <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Biography "
                                                                                    value={this.state.Biography.biography}
                                                                                    onChange={(e) => {
                                                                                        let { Biography } = this.state;
                                                                                        Biography.biography = e.target.value;
                                                                                        this.setState({ Biography });
                                                                                    }}
                                                                                />
                                                                                <div className="loginErrCreateProject">{this.state.biographyErr}</div>
                                                                                <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                                    onClick={this.BiographyFieldAddSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.BiographyFieldAddSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div style={{ marginTop: '8%' }}>
                                                                                            {this.state.itemsBiography.map((itemsBiography) => (
                                                                                                <div className="tag-item" key={itemsBiography}>
                                                                                                    {itemsBiography}
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="button"
                                                                                                        onClick={() => this.handleDeleteBiography(itemsBiography)}
                                                                                                    >
                                                                                                        &times;
                                                                                                    </button>
                                                                                                </div>
                                                                                            ))}
                                                                                            <input
                                                                                                class="form-control-addsource-Personal"
                                                                                                value={this.state.valueBiographyAddSource}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={this.handleKeyDownBiography}
                                                                                                onChange={this.handleChangeBiographyAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                                                    Areas of Interest <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Areas of Interest"
                                                                                    name="areas_of_interest"
                                                                                    value={this.state.Biography.areas_of_interest}
                                                                                    onChange={(e) => {
                                                                                        let { Biography } = this.state;
                                                                                        Biography.areas_of_interest = e.target.value;
                                                                                        this.setState({ Biography });
                                                                                    }}
                                                                                />
                                                                                <div className="loginErrCreateProject">{this.state.areasofinterestErr}</div>
                                                                                <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                                    onClick={this.AreasofInterestAddSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.AreasofInterestAddSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div style={{ marginTop: '8%' }}>
                                                                                            {this.state.itemsAreasOfInterest.map((itemsAreasOfInterest) => (
                                                                                                <div className="tag-item" key={itemsAreasOfInterest}>
                                                                                                    {itemsAreasOfInterest}
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="button"
                                                                                                        onClick={() => this.handleDeleteAreasOfInterest(itemsAreasOfInterest)}
                                                                                                    >
                                                                                                        &times;
                                                                                                    </button>
                                                                                                </div>
                                                                                            ))}
                                                                                            <input
                                                                                                class="form-control-addsource-Personal"
                                                                                                value={this.state.valueBiographyAddSource}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={this.handleKeyDownAreasOfInterest}
                                                                                                onChange={this.handleChangeBiographyAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                {/* {
                                            ( this.state.BiographyResume )?(
                                                <div style={{marginTop:'10%'}}>
                                                    <text>Your Resume is stored...If yo want to
                                                        change it then click on choose file</text>
                                                </div>
                                            ):(<div></div>)
                                        }
                                        <label for="resume" style={{marginTop:'3%'}}>
                                            Upload Resume  <span class="mandatory">*</span></label>
                                        <input id="resume" type="file" onChange={this.onUploadResume} />  */}

                                                                                <label for="resume" style={{ marginTop: '3%' }}>
                                                                                    Resume/CV</label>
                                                                                <div class="form-control" style={{ height: '25%' }}>
                                                                                    <input type='file'
                                                                                        accept=".doc,.docx,.pdf"
                                                                                        id="myInputResume"
                                                                                        // accept="image/*"
                                                                                        onChange={this.onUploadResume} hidden />
                                                                                    <label htmlFor="myInputResume"
                                                                                        style={{ color: 'blue', fontSize: "15px", marginTop: '1.5%' }}>
                                                                                        Upload Resume
                                                                                    </label>
                                                                                    {
                                                                                        (this.state.BiographyResumeFileName) ? (
                                                                                            <div class="float-right" style={{ marginTop: '1.5%' }}>
                                                                                                <text>{this.state.BiographyResumeFileName}</text>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <div class="float-right" style={{ marginTop: '1.5%' }}>
                                                                                                No file Choosen
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                </div>

                                                                            </div>
                                                                        ) : (

                                                                            <div class="form-group">
                                                                                <div class="form-group">
                                                                                    <label for="exampleFormControlTextarea1">
                                                                                        Citizenship <span class="mandatory">*</span></label>
                                                                                    <Input type="select" id="roleSelect" className="form-control"
                                                                                        style={{ height: '50px' }}
                                                                                        // autofocus="true"
                                                                                        name="citizenship"
                                                                                        value={this.state.Biography.citizenship}
                                                                                        onChange={(e) => {
                                                                                            let { Biography } = this.state;
                                                                                            Biography.citizenship = e.target.value;
                                                                                            this.setState({ Biography });
                                                                                        }}
                                                                                    >
                                                                                        <option value="0" >--Select Citizenship--</option>
                                                                                        {OptionsCitizenship}
                                                                                        {/* <option value = "Afghans" >Afghans</option>
                                            <option value = "Albanians" >Albanians</option> */}
                                                                                    </Input>
                                                                                </div>
                                                                                <div className="loginErrCreateProject">{this.state.citizenshipErr}</div>
                                                                                <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                                    onClick={this.CitizenshipAddSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.CitizenshipAddSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div style={{ marginTop: '8%' }}>
                                                                                            {this.state.itemsCitizenship.map((itemsCitizenship) => (
                                                                                                <div className="tag-item" key={itemsCitizenship}>
                                                                                                    {itemsCitizenship}
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="button"
                                                                                                        onClick={() => this.handleDeleteCitizenship(itemsCitizenship)}
                                                                                                    >
                                                                                                        &times;
                                                                                                    </button>
                                                                                                </div>
                                                                                            ))}
                                                                                            <input
                                                                                                class="form-control-addsource-Personal"
                                                                                                value={this.state.valueBiographyAddSource}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={this.handleKeyDownCitzenship}
                                                                                                onChange={this.handleChangeBiographyAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                                                    Language Spoken </label>
                                                                                <Multiselect
                                                                                    data={this.state.Language}
                                                                                    name="language_spoken"
                                                                                    placeholder='--Select Lanuages--'
                                                                                    // value= {el.areas_of_interest_links ||''}
                                                                                    valueField='id'
                                                                                    textField='language'
                                                                                    defaultValue={this.state.LanguageSpoken}
                                                                                    onSelect={(valueField) => console.log(valueField)}
                                                                                    // onChange={(event)=>{
                                                                                    //     console.log(event);
                                                                                    //     event.map(async(data)=>{ 
                                                                                    //     var joined = this.state.LanguageSpoken.concat(data.id);
                                                                                    //     await this.setState({
                                                                                    //         LanguageSpoken: joined
                                                                                    //     });  
                                                                                    //         // let { Biography } = this.state;
                                                                                    //         // Biography.language_spoken = this.state.LanguageSpoken;
                                                                                    //         // this.setState({ Biography });                                          
                                                                                    //         // console.log("check this",this.state.LanguageSpoken)

                                                                                    //         var output = this.state.LanguageSpoken.map( function(item){
                                                                                    //         return item;
                                                                                    //         });

                                                                                    //         let { Biography } = this.state;
                                                                                    //         Biography.language_spoken = output.join(",") ;
                                                                                    //         this.setState({ Biography });  

                                                                                    //         console.log("check this",this.state.Biography.language_spoken)
                                                                                    //         console.log( output.join(",") )
                                                                                    //     })
                                                                                    // }}
                                                                                    onChange={(event) => {
                                                                                        console.log(event);
                                                                                        var joined = [];
                                                                                        console.log("length : " + event.length);
                                                                                        if (event.length === 0) {
                                                                                            this.setState({ LanguageSpoken: [] });
                                                                                        }
                                                                                        event.map(async (data) => {
                                                                                            console.log("data in eve map : " + JSON.stringify(data));
                                                                                            joined = joined.concat(data.id);
                                                                                            console.log("data join : " + joined);
                                                                                            await this.setState({
                                                                                                LanguageSpoken: joined
                                                                                            });
                                                                                            console.log("check this", this.state.LanguageSpoken)

                                                                                            var output = this.state.LanguageSpoken.map(function (item) {
                                                                                                return item;
                                                                                            });

                                                                                            let { Biography } = this.state;
                                                                                            Biography.language_spoken = output.join(",");
                                                                                            this.setState({ Biography });

                                                                                            console.log("check this", this.state.Biography.language_spoken)
                                                                                            console.log(output.join(","))
                                                                                        })
                                                                                    }}
                                                                                />
                                                                                {/* <a class="float-right" style={{marginTop:'0.5%', color:'#007bff'}}
                                            onClick={this.LanguageSpokenAddSourceFun}>+ Add source</a>
                                        {
                                            ( !this.state.LanguageSpokenAddSource) ? (
                                                <div></div>
                                            ) : (
                                            <div style={{marginTop: '8%'}}>
                                                {this.state.itemsLanguageSpoken.map((itemsLanguageSpoken) => (
                                                <div className="tag-item" key={itemsLanguageSpoken}>
                                                    {itemsLanguageSpoken}
                                                    <button
                                                    type="button"
                                                    className="button"
                                                    onClick={() => this.handleDeleteLanguage(itemsLanguageSpoken)}
                                                    >
                                                    &times;
                                                    </button>
                                                </div>
                                                ))}
                                                <input
                                                    class="form-control-addsource-Personal" 
                                                    value={this.state.valueBiographyAddSource}
                                                    placeholder="Type or paste source link and press `Enter`"
                                                    onKeyDown={this.handleKeyDownLanguage}
                                                    onChange={this.handleChangeBiographyAddSource}
                                                />
                                            </div>
                                            )
                                        } */}

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                                                    LinkedIn Followers</label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter LinkedIn Followers"
                                                                                    value={this.state.Biography.linkedin_followers}
                                                                                    onChange={(e) => {
                                                                                        let { Biography } = this.state;
                                                                                        Biography.linkedin_followers = e.target.value;
                                                                                        this.setState({ Biography });
                                                                                    }}
                                                                                />
                                                                                <a class="float-right" style={{ marginTop: '-3%', color: '#007bff' }}
                                                                                    onClick={this.LinkedInFollowersAddSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.LinkedInFollowersAddSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div style={{ marginTop: '8%' }}>
                                                                                            {this.state.itemsLinkedInFolllowers.map((itemsLinkedInFolllowers) => (
                                                                                                <div className="tag-item" key={itemsLinkedInFolllowers}>
                                                                                                    {itemsLinkedInFolllowers}
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="button"
                                                                                                        onClick={() => this.handleDeleteLinkedInFollowers(itemsLinkedInFolllowers)}
                                                                                                    >
                                                                                                        &times;
                                                                                                    </button>
                                                                                                </div>
                                                                                            ))}
                                                                                            <input
                                                                                                class="form-control-addsource-Personal"
                                                                                                value={this.state.valueBiographyAddSource}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={this.handleKeyDownLinkedInFollowers}
                                                                                                onChange={this.handleChangeBiographyAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                {/* <label for="exampleFormControlTextarea1" style={{marginTop:'2%'}}>
                                            Fax </label>
                                        <input type="text" class="form-control" 
                                            id="input7" placeholder="Enter Fax" 
                                            value={this.state.Biography.fax} 
                                            onChange={(e) => {
                                                let { Biography } = this.state;
                                                Biography.fax = e.target.value;
                                                this.setState({ Biography });
                                            }}
                                        />
                                        <a class="float-right" style={{marginTop:'-3%', color:'#007bff'}}
                                            onClick={this.FaxAddSourceFun}>+ Add source</a>
                                        {
                                            ( !this.state.FaxAddSource) ? (
                                                <div></div>
                                            ) : (
                                            <div style={{marginTop: '8%'}}>
                                                {this.state.itemsFax.map((itemsFax) => (
                                                <div className="tag-item" key={itemsFax}>
                                                    {itemsFax}
                                                    <button
                                                    type="button"
                                                    className="button"
                                                    onClick={() => this.handleDeleteFax(itemsFax)}
                                                    >
                                                    &times;
                                                    </button>
                                                </div>
                                                ))}
                                                <input
                                                    class="form-control-addsource-Personal" 
                                                    value={this.state.valueBiographyAddSource}
                                                    placeholder="Type or paste source link and press `Enter`"
                                                    onKeyDown={this.handleKeyDownFax}
                                                    onChange={this.handleChangeBiographyAddSource}
                                                />
                                            </div>
                                            )
                                        } */}

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Biography  <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Biography "
                                                                                    value={this.state.Biography.biography}
                                                                                    onChange={(e) => {
                                                                                        let { Biography } = this.state;
                                                                                        Biography.biography = e.target.value;
                                                                                        this.setState({ Biography });
                                                                                    }}
                                                                                />
                                                                                <div className="loginErrCreateProject">{this.state.biographyErr}</div>
                                                                                <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                                    onClick={this.BiographyFieldAddSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.BiographyFieldAddSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div style={{ marginTop: '8%' }}>
                                                                                            {this.state.itemsBiography.map((itemsBiography) => (
                                                                                                <div className="tag-item" key={itemsBiography}>
                                                                                                    {itemsBiography}
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="button"
                                                                                                        onClick={() => this.handleDeleteBiography(itemsBiography)}
                                                                                                    >
                                                                                                        &times;
                                                                                                    </button>
                                                                                                </div>
                                                                                            ))}
                                                                                            <input
                                                                                                class="form-control-addsource-Personal"
                                                                                                value={this.state.valueBiographyAddSource}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={this.handleKeyDownBiography}
                                                                                                onChange={this.handleChangeBiographyAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                                                    Areas of Interest <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Areas of Interest"
                                                                                    name="areas_of_interest"
                                                                                    value={this.state.Biography.areas_of_interest}
                                                                                    onChange={(e) => {
                                                                                        let { Biography } = this.state;
                                                                                        Biography.areas_of_interest = e.target.value;
                                                                                        this.setState({ Biography });
                                                                                    }}
                                                                                />
                                                                                <div className="loginErrCreateProject">{this.state.areasofinterestErr}</div>
                                                                                <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                                    onClick={this.AreasofInterestAddSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.AreasofInterestAddSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div style={{ marginTop: '8%' }}>
                                                                                            {this.state.itemsAreasOfInterest.map((itemsAreasOfInterest) => (
                                                                                                <div className="tag-item" key={itemsAreasOfInterest}>
                                                                                                    {itemsAreasOfInterest}
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="button"
                                                                                                        onClick={() => this.handleDeleteAreasOfInterest(itemsAreasOfInterest)}
                                                                                                    >
                                                                                                        &times;
                                                                                                    </button>
                                                                                                </div>
                                                                                            ))}
                                                                                            <input
                                                                                                class="form-control-addsource-Personal"
                                                                                                value={this.state.valueBiographyAddSource}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={this.handleKeyDownAreasOfInterest}
                                                                                                onChange={this.handleChangeBiographyAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="resume" style={{ marginTop: '3%' }}>
                                                                                    Resume/CV </label>
                                                                                <div class="form-control" style={{ height: '25%' }}>
                                                                                    <input type='file'
                                                                                        accept=".doc,.docx,.pdf"
                                                                                        id="myInputResume"
                                                                                        // accept="image/*"
                                                                                        onChange={this.onUploadResume} hidden />
                                                                                    <label htmlFor="myInputResume"
                                                                                        style={{ color: 'blue', fontSize: "15px", marginTop: '1.5%' }}>
                                                                                        Upload Resume
                                                                                    </label>
                                                                                    {
                                                                                        (this.state.BiographyResumeFileName) ? (
                                                                                            <div class="float-right" style={{ marginTop: '1.5%' }}>
                                                                                                <text>{this.state.BiographyResumeFileName}</text>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <div class="float-right" style={{ marginTop: '1.5%' }}>
                                                                                                No file Choosen
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                                <div className="loginErrCreateProject">{this.state.ResumeErr}</div>
 
                                                                            </div>
                                                                        )

                                                                    }
                                                                </form>
                                                            </div>
                                                        </section>
                                                    ) : (
                                                        <div>
                                                        </div>
                                                    )
                                                }
                                                {/* Professional Membership form start here*/}
                                                {
                                                    (this.state.ProfessionalMembershipFlag == true) ? (
                                                        <section style={{ marginBottom: '50%' }}>
                                                            <h3 class="mt-30">Professional Membership 1</h3>
                                                            <form>
                                                                {
                                                                    this.state.ProfessionalMemberships.map((el, i) => {
                                                                        var arrayOrganization = [];
                                                                        var arrayOrganizationType = [];
                                                                        var arraySourceLinkProff = [];
                                                                        var arrayLocation = [];
                                                                        var arrayPosition = [];
                                                                        var arrayTenure = [];
                                                                        var arrayOrganizationWebsite = [];
                                                                        if (el.organization_links !== '') {
                                                                            // arrayOrganization.push(el.organization_links)
                                                                            arrayOrganization = el.organization_links.split(',')
                                                                            arrayOrganization = arrayOrganization.splice(1)
                                                                            // console.log("arrayOrganization===",arrayOrganization)
                                                                        }
                                                                        if (el.organization_type_links !== '') {
                                                                            //    arrayOrganizationType.push(el.organization_type_links)
                                                                            arrayOrganizationType = el.organization_type_links.split(',')
                                                                            arrayOrganizationType = arrayOrganizationType.splice(1)
                                                                            // console.log("arrayOrganizationType===",arrayOrganizationType)
                                                                        }
                                                                        if (el.source_link_links !== '') {
                                                                            arraySourceLinkProff = el.source_link_links.split(',')
                                                                            arraySourceLinkProff = arraySourceLinkProff.splice(1)
                                                                        }
                                                                        if (el.location_links !== '') {
                                                                            arrayLocation = el.location_links.split(',')
                                                                            arrayLocation = arrayLocation.splice(1)
                                                                        }
                                                                        if (el.position_links !== '') {
                                                                            arrayPosition = el.position_links.split(',')
                                                                            arrayPosition = arrayPosition.splice(1)
                                                                        }
                                                                        if (el.tenure_link !== '') {
                                                                            arrayTenure = el.tenure_link.split(',')
                                                                            arrayTenure = arrayTenure.splice(1)
                                                                        }
                                                                        if (el.organization_website_links !== '') {
                                                                            arrayOrganizationWebsite = el.organization_website_links.split(',')
                                                                            arrayOrganizationWebsite = arrayOrganizationWebsite.splice(1)
                                                                        }

                                                                        return (
                                                                            <div key={i}>
                                                                                {
                                                                                    (i == 0) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            <h3 class="mt-30">Professional Membership {i + 1}</h3>
                                                                                            {this.state.ProfessionalMemberships.length !== 1 &&
                                                                                                <a class="float-right" style={{ marginTop: '-8.5%', color: '#007bff' }}
                                                                                                    onClick={this.removeClickProfessionalMembership.bind(this, i)}>x</a>
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Organization  <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    autofocus="true"
                                                                                    id="input7" placeholder="Enter Organization "
                                                                                    name="organization" value={el.organization || ''}
                                                                                    onChange={this.handleChangeProfessionalMembership.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject">
                                                                                    {
                                                                                        (el.organization != '') ? (<div></div>) : (this.state.organizationErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.OrganizationAddSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.OrganizationAddSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {/* <input type="text" class="form-control" 
                                                id="input7" placeholder="Source URL" 
                                                name="organization_links" value={el.organization_links ||''}
                                                onChange={this.handleChangeProfessionalMembership.bind(this, i)}  
                                                />   */}
                                                                                            {/* <MultipleValueTextInput
                                                    onItemAdded={(item, allItems) => this.handleInputChangemulti(allItems, i,"organization_links") }
                                                    onItemDeleted={(item, allItems) => this.handleInputChangemulti(allItems, i,"organization_links")}
                                                    // value={el.organization_links ||''}
                                                    name="organization_links"
                                                    placeholder="Enter whatever items you want; separate them with COMMA or ENTER."
                                                /> */}
                                                                                            {
                                                                                                arrayOrganization.flat().map((item, index) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {console.log('item', item)}
                                                                                                        {console.log('index', index)}
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteProfAddSource(el.organization_links.split(','), item, i, "organization_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownProfAddSource(e, i, "organization_links")}
                                                                                                onChange={this.handleChangeProfAddSource}
                                                                                            //   onPaste={this.handlePaste}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }


                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Organization Type <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Organization Type"
                                                                                    name="organization_type" value={el.organization_type || ''}
                                                                                    onChange={this.handleChangeProfessionalMembership.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject">
                                                                                    {
                                                                                        (el.organization_type != '') ? (<div></div>) : (this.state.organizationtypeErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.OrganizationTypeAddSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.OrganizationTypeAddSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {/* <input type="text" class="form-control" 
                                                id="input7" placeholder="Source URL" 
                                                name="organization_type_links" value={el.organization_type_links ||''}
                                                onChange={this.handleChangeProfessionalMembership.bind(this, i)}  
                                                />   */}
                                                                                            {
                                                                                                arrayOrganizationType.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteProfAddSource(el.organization_type_links.split(','), item, i, "organization_type_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownProfAddSource(e, i, "organization_type_links")}
                                                                                                onChange={this.handleChangeProfAddSource}
                                                                                            //   onPaste={this.handlePaste}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }


                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Source Link <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Source Link"
                                                                                    name="source_link" value={el.source_link || ''}
                                                                                    onChange={this.handleChangeProfessionalMembership.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject">
                                                                                    {
                                                                                        (el.source_link != '') ? (<div></div>) : (this.state.sourcelinkErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.SourceLinkAddSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.SourceLinkAddSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arraySourceLinkProff.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteProfAddSource(el.source_link_links.split(','), item, i, "source_link_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownProfAddSource(e, i, "source_link_links")}
                                                                                                onChange={this.handleChangeProfAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Location</label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Location"
                                                                                    name="location" value={el.location || ''}
                                                                                    onChange={this.handleChangeProfessionalMembership.bind(this, i)}
                                                                                />
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.LocationAddSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.LocationAddSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayLocation.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteProfAddSource(el.location_links.split(','), item, i, "location_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownProfAddSource(e, i, "location_links")}
                                                                                                onChange={this.handleChangeProfAddSource}
                                                                                            //   onPaste={this.handlePaste}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Position <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Position"
                                                                                    name="position" value={el.position || ''}
                                                                                    onChange={this.handleChangeProfessionalMembership.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject">
                                                                                    {
                                                                                        (el.position != '') ? (<div></div>) : (this.state.positionErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.PositionAddSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.PositionAddSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayPosition.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteProfAddSource(el.position_links.split(','), item, i, "position_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownProfAddSource(e, i, "position_links")}
                                                                                                onChange={this.handleChangeProfAddSource}
                                                                                            //   onPaste={this.handlePaste}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Tenure <span class="mandatory">*</span></label>
                                                                                <br />
                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '-2%' }}>
                                                                                    From </label>
                                                                                <input type="date" class="form-control"
                                                                                    id="input7" placeholder="Enter Tenure"
                                                                                    name="tenure" value={el.tenure || ''}
                                                                                    onChange={this.handleChangeProfessionalMembership.bind(this, i)}
                                                                                />
                                                                                {/* <div className="loginErrCreateProject">
                                                {
                                                (el.tenure != '') ?( <div></div>) :(this.state.tenureErr)
                                                }
                                            </div> */}
                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    To </label>
                                                                                <input type="date" class="form-control"
                                                                                    id="input7" placeholder="Enter Tenure"
                                                                                    name="tenure1" value={el.tenure1 || ''}
                                                                                    onChange={this.handleChangeProfessionalMembership.bind(this, i)}
                                                                                />
                                                                                {/* <div className="loginErrCreateProject">
                                                {
                                                (el.tenure1 != '') ?( <div></div>) :(this.state.tenureToErr)
                                                }
                                            </div> */}
                                                                                <a class="float-right" style={{ color: '#007bff' }}
                                                                                    onClick={this.TenureAddSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.TenureAddSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayTenure.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteProfAddSource(el.tenure_link.split(','), item, i, "tenure_link")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownProfAddSource(e, i, "tenure_link")}
                                                                                                onChange={this.handleChangeProfAddSource}
                                                                                            //   onPaste={this.handlePaste}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Organization Website</label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Organization Website"
                                                                                    name="organization_website" value={el.organization_website || ''}
                                                                                    onChange={this.handleChangeProfessionalMembership.bind(this, i)}
                                                                                />
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.OrganizationWebsiteAddSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.OrganizationWebsiteAddSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayOrganizationWebsite.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteProfAddSource(el.organization_website_links.split(','), item, i, "organization_website_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownProfAddSource(e, i, "organization_website_links")}
                                                                                                onChange={this.handleChangeProfAddSource}
                                                                                            //   onPaste={this.handlePaste}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }



                                                                                <hr />

                                                                                {/* Add More and remove */}
                                                                                {/* {this.state.ProfessionalMemberships.length !== 1 && 
                                                <a class="float-right" style={{marginTop:'-2%', color:'#007bff'}}
                                                onClick={this.removeClickProfessionalMembership.bind(this, i)}>- Remove</a>
                                            } */}
                                                                                {this.state.ProfessionalMemberships.length - 1 === i &&
                                                                                    <a style={{ marginTop: '5%', color: '#007bff' }}
                                                                                        onClick={this.addClickProfessionalMembership.bind(this)}>+ Add More Professional Membership</a>
                                                                                }

                                                                                {/* <input type="submit" value="Submit" onClick={this.handleSubmitProfessional.bind(this)}/> */}
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </form>
                                                        </section>
                                                    ) : (
                                                        <div>
                                                        </div>
                                                    )
                                                }
                                                {/* Education form start here */}
                                                {
                                                    (this.state.EducationFlag == true) ? (
                                                        <section style={{ marginBottom: '50%' }}>
                                                            <h3 class="mt-30">Highest Education </h3>
                                                            <form>
                                                                {
                                                                    this.state.Education.map((el, i) => {
                                                                        var arrayInstituteName = [];
                                                                        var arrayInstituteType = [];
                                                                        var arraySourceLinksEducation = [];
                                                                        var arrayDegrees = [];
                                                                        var arraySpecialityEducation = [];
                                                                        var arrayYearsAttended = [];

                                                                        if (el.institute_name_links !== '') {
                                                                            arrayInstituteName = el.institute_name_links.split(',')
                                                                            arrayInstituteName = arrayInstituteName.splice(1)
                                                                        }
                                                                        if (el.institute_type_links !== '') {
                                                                            arrayInstituteType = el.institute_type_links.split(',')
                                                                            arrayInstituteType = arrayInstituteType.splice(1)
                                                                        }
                                                                        if (el.source_link_links !== '') {
                                                                            arraySourceLinksEducation = el.source_link_links.split(',')
                                                                            arraySourceLinksEducation = arraySourceLinksEducation.splice(1)
                                                                        }
                                                                        if (el.degrees_links !== '') {
                                                                            arrayDegrees = el.degrees_links.split(',')
                                                                            arrayDegrees = arrayDegrees.splice(1)
                                                                        }
                                                                        if (el.speciality_links !== '') {
                                                                            arraySpecialityEducation = el.speciality_links.split(',')
                                                                            arraySpecialityEducation = arraySpecialityEducation.splice(1)
                                                                        }
                                                                        if (el.years_attended_links !== '') {
                                                                            arrayYearsAttended = el.years_attended_links.split(',')
                                                                            arrayYearsAttended = arrayYearsAttended.splice(1)
                                                                        }

                                                                        return (
                                                                            <div key={i}>
                                                                                {
                                                                                    (i == 0) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            <h3 class="mt-30">Education {i + 1}</h3>
                                                                                            {this.state.Education.length !== 1 &&
                                                                                                <a class="float-right" style={{ marginTop: '-8.5%', color: '#007bff' }}
                                                                                                    onClick={this.removeClickEducation.bind(this, i)}>x</a>
                                                                                            }

                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    {/* Institute Name   */}
                                                                                    Alma mater
                                                                                    <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    autocomplete="off"
                                                                                    autofocus="true"
                                                                                    id="input7" placeholder="Enter Alma mater"
                                                                                    name="institute_name" value={el.institute_name || ''}
                                                                                    onChange={this.handleChangeEducation.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject">
                                                                                    {
                                                                                        (el.institute_name != '') ? (<div></div>) : (this.state.institutenameErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.InstituteNameAddSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.InstituteNameAddSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayInstituteName.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteEducationAddSource(el.institute_name_links.split(','), item, i, "institute_name_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownEducationAddSource(e, i, "institute_name_links")}
                                                                                                onChange={this.handleChangeEducationAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Institute Type  <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Institute Type"
                                                                                    name="institute_type" value={el.institute_type || ''}
                                                                                    onChange={this.handleChangeEducation.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject">
                                                                                    {
                                                                                        (el.institute_type != '') ? (<div></div>) : (this.state.institutetypeErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.InstituteTypeAddSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.InstituteTypeAddSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayInstituteType.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteEducationAddSource(el.institute_type_links.split(','), item, i, "institute_type_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownEducationAddSource(e, i, "institute_type_links")}
                                                                                                onChange={this.handleChangeEducationAddSource}
                                                                                            //   onPaste={this.handlePaste}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Source Link  <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Source Link"
                                                                                    name="source_link" value={el.source_link || ''}
                                                                                    onChange={this.handleChangeEducation.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject">
                                                                                    {
                                                                                        (el.source_link != '') ? (<div></div>) : (this.state.sourcelinkEducationErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.SourceLinkEducationAddSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.SourceLinkEducationAddSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arraySourceLinksEducation.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteEducationAddSource(el.source_link_links.split(','), item, i, "source_link_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownEducationAddSource(e, i, "source_link_links")}
                                                                                                onChange={this.handleChangeEducationAddSource}
                                                                                            //   onPaste={this.handlePaste}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    {/* Degrees   */}
                                                                                    Qualification
                                                                                    <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Degree"
                                                                                    name="degrees" value={el.degrees || ''}
                                                                                    onChange={this.handleChangeEducation.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject">
                                                                                    {
                                                                                        (el.degrees != '') ? (<div></div>) : (this.state.degreesErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.DegreesAddSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.DegreesAddSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayDegrees.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteEducationAddSource(el.degrees_links.split(','), item, i, "degrees_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownEducationAddSource(e, i, "degrees_links")}
                                                                                                onChange={this.handleChangeEducationAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Speciality  <span class="mandatory">*</span></label>
                                                                                <Multiselect
                                                                                    data={this.state.SpecialityData}
                                                                                    valueField='id'
                                                                                    // valueField='speciality_type'
                                                                                    textField='speciality_type'
                                                                                    // defaultValue={el.speciality_id}
                                                                                    // defaultValue={(el.speciality_id).split(",")}
                                                                                    // defaultvalue={(el.speciality_id !== []) ? ((el.speciality_id).split(",")):('')}
                                                                                    defaultValue={(el.speciality_id != '') ? (el.speciality_id.split(",")) : ([])}
                                                                                    onSelect={(valueField) => console.log(valueField)}
                                                                                    onChange={(event) => {
                                                                                        console.log(event);
                                                                                        var joined = [];
                                                                                        console.log("length : " + event.length);
                                                                                        if (event.length === 0) {
                                                                                            this.setState({ SpecilityTypeEducation: [] });
                                                                                        }
                                                                                        event.map(async (data) => {
                                                                                            console.log("data in eve map : " + JSON.stringify(data));
                                                                                            joined = joined.concat(data.id);
                                                                                            console.log("data join : " + joined);
                                                                                            await this.setState({
                                                                                                SpecilityTypeEducation: joined
                                                                                            });
                                                                                            console.log("check this ", this.state.SpecilityTypeEducation)

                                                                                            var output = this.state.SpecilityTypeEducation.map(function (item) {
                                                                                                return item;
                                                                                            });

                                                                                            let list = [...this.state.Education];
                                                                                            list[i] = { ...list[i], ["speciality_id"]: output.join(",") };

                                                                                            this.setState({ Education: list });
                                                                                        })
                                                                                    }}
                                                                                />
                                                                                <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                                                    {
                                                                                        (el.speciality_id != '') ? (<div></div>) : (this.state.specialityEducationErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '1%', color: '#007bff' }}
                                                                                    onClick={this.SpecialityEducationAddSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.SpecialityEducationAddSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arraySpecialityEducation.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteEducationAddSource(el.speciality_links.split(','), item, i, "speciality_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownEducationAddSource(e, i, "speciality_links")}
                                                                                                onChange={this.handleChangeEducationAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                    Years Attended  <span class="mandatory">*</span></label>
                                                                                <br />
                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    From </label>
                                                                                <input type="date" class="form-control"
                                                                                    id="input7" placeholder="From"
                                                                                    name="years_attended_from" value={el.years_attended_from || ''}
                                                                                    onChange={this.handleChangeEducation.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject">
                                                                                    {
                                                                                        (el.years_attended_from != '') ? (<div></div>) : (this.state.yearsattendedfromErr)
                                                                                    }
                                                                                </div>
                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    To  </label>
                                                                                <input type="date" class="form-control"
                                                                                    id="input7" placeholder="To"
                                                                                    name="years_attended_to" value={el.years_attended_to || ''}
                                                                                    onChange={this.handleChangeEducation.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject">
                                                                                    {
                                                                                        (el.years_attended_to != '') ? (<div></div>) : (this.state.yearsattendedtoErr)
                                                                                    }
                                                                                    <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                        onClick={this.YearsAttendedAddSourceFun}>+ Add source</a>
                                                                                    {
                                                                                        (!this.state.YearsAttendedAddSource) ? (
                                                                                            <div></div>
                                                                                        ) : (
                                                                                            <div>
                                                                                                {
                                                                                                    arrayYearsAttended.flat().map((item) => (
                                                                                                        <div class="col-md-5" key={item}>
                                                                                                            {item}
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className="button"
                                                                                                                onClick={() => this.handleDeleteEducationAddSource(el.years_attended_links.split(','), item, i, "years_attended_links")}
                                                                                                            >
                                                                                                                &times;
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    ))
                                                                                                }
                                                                                                <input
                                                                                                    type="text"
                                                                                                    class="form-control-addsource"
                                                                                                    value={this.state.value}
                                                                                                    placeholder="Type or paste source link and press `Enter`"
                                                                                                    onKeyDown={e => this.handleKeyDownEducationAddSource(e, i, "years_attended_links")}
                                                                                                    onChange={this.handleChangeEducationAddSource}
                                                                                                />
                                                                                            </div>
                                                                                        )
                                                                                    }

                                                                                </div>

                                                                                <hr />
                                                                                {/* Add More and remove */}
                                                                                {/* {this.state.Education.length !== 1 && 
                                            <a class="float-right" style={{marginTop:'-2%', color:'#007bff'}}
                                            onClick={this.removeClickEducation.bind(this, i)}>- Remove</a>
                                        } */}
                                                                                {this.state.Education.length - 1 === i &&
                                                                                    <a style={{ marginTop: '5%', color: '#007bff' }}
                                                                                        onClick={this.addClickEducation.bind(this)}>+ Add More Education</a>
                                                                                }

                                                                                {/* <input type="submit" value="Submit" onClick={this.handleSubmitEducation.bind(this)}/> */}
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </form>
                                                        </section>
                                                    ) : (
                                                        <div>
                                                        </div>
                                                    )
                                                }
                                                {/* Advisory Board form */}
                                                {
                                                    (this.state.AdvisoryBoardFlag == true) ? (
                                                        <section style={{ marginBottom: '50%' }}>
                                                            <h3 class="mt-30">Advisory Board 1</h3>
                                                            <form>
                                                                {
                                                                    this.state.AdvisoryBoard.map((el, i) => {
                                                                        var arraySpecialityAdvBoard = [];
                                                                        var arraySubSpecialityAdvBoard = [];
                                                                        var arrayProcedureAdvBoard = [];
                                                                        var arrayYearAdvBoard = [];
                                                                        var arrayCompany = [];
                                                                        var arrayTypeofCompany = [];
                                                                        var arrayAdvisoryBoard = [];
                                                                        var arrayTopic = [];
                                                                        var arrayRole = [];

                                                                        if (el.speciality_links !== '') {
                                                                            arraySpecialityAdvBoard = el.speciality_links.split(',')
                                                                            arraySpecialityAdvBoard = arraySpecialityAdvBoard.splice(1)
                                                                        }
                                                                        if (el.sub_speciality_links !== '') {
                                                                            arraySubSpecialityAdvBoard = el.sub_speciality_links.split(',')
                                                                            arraySubSpecialityAdvBoard = arraySubSpecialityAdvBoard.splice(1)
                                                                        }
                                                                        if (el.procedure_type_links !== '') {
                                                                            arrayProcedureAdvBoard = el.procedure_type_links.split(',')
                                                                            arrayProcedureAdvBoard = arrayProcedureAdvBoard.splice(1)
                                                                        }
                                                                        if (el.year_links !== '') {
                                                                            arrayYearAdvBoard = el.year_links.split(',')
                                                                            arrayYearAdvBoard = arrayYearAdvBoard.splice(1)
                                                                        }
                                                                        if (el.company_links !== '') {
                                                                            arrayCompany = el.company_links.split(',')
                                                                            arrayCompany = arrayCompany.splice(1)
                                                                        }
                                                                        if (el.type_of_company_links !== '') {
                                                                            arrayTypeofCompany = el.type_of_company_links.split(',')
                                                                            arrayTypeofCompany = arrayTypeofCompany.splice(1)
                                                                        }
                                                                        if (el.advisory_board_links !== '') {
                                                                            arrayAdvisoryBoard = el.advisory_board_links.split(',')
                                                                            arrayAdvisoryBoard = arrayAdvisoryBoard.splice(1)
                                                                        }
                                                                        if (el.topic_links !== '') {
                                                                            arrayTopic = el.topic_links.split(',')
                                                                            arrayTopic = arrayTopic.splice(1)
                                                                        }
                                                                        if (el.role_links !== '') {
                                                                            arrayRole = el.role_links.split(',')
                                                                            arrayRole = arrayRole.splice(1)
                                                                        }


                                                                        return (
                                                                            <div key={i}>
                                                                                {
                                                                                    (i == 0) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            <h3 class="mt-30">Advisory Board {i + 1}</h3>
                                                                                            {this.state.AdvisoryBoard.length !== 1 &&
                                                                                                <a class="float-right" style={{ marginTop: '-8%', color: '#007bff' }}
                                                                                                    onClick={this.removeClickAdvisoryBoard.bind(this, i)}>x</a>
                                                                                            }

                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                                                    Speciality <span class="mandatory">*</span></label>
                                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                                    style={{ height: '50px' }}
                                                                                    name="speciality_id"
                                                                                    value={el.speciality_id || ''}
                                                                                    onChange={async (e) => {
                                                                                        console.log(i)
                                                                                        console.log(e.target.value)
                                                                                        const { name, value } = e.target;
                                                                                        let AdvisoryBoard = [...this.state.AdvisoryBoard]
                                                                                        AdvisoryBoard[i] = { ...AdvisoryBoard[i], [name]: value }
                                                                                        this.setState({ AdvisoryBoard });

                                                                                        { this.getProcedureListAdvBoard(e.target.value) }
                                                                                        { this.getSubSpecialityListAdvBoard(e.target.value) }

                                                                                        await this.setState({
                                                                                            ProcedureTypeAdvBoard: [],
                                                                                            SubSpecialityTypeAdvBoard: [],
                                                                                            showProcedureSubSpecialityDropdownAdvBoard: false,
                                                                                        })
                                                                                        // let AdvisoryBoard = [...this.state.AdvisoryBoard]
                                                                                        AdvisoryBoard[i] = { ...AdvisoryBoard[i], procedure_type_id: '' }
                                                                                        AdvisoryBoard[i] = { ...AdvisoryBoard[i], sub_specialty_id: '' }
                                                                                        this.setState({ AdvisoryBoard });

                                                                                        { this.ProcedureDropdownAdvBoard() }
                                                                                    }}
                                                                                >
                                                                                    <option value="0" >--Select Speciality--</option>
                                                                                    {OptionsSpeciality}
                                                                                </Input>
                                                                                <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                                                    {
                                                                                        (el.speciality_id != '') ? (<div></div>) : (this.state.specialityAdvBoardErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '1%', color: '#007bff' }}
                                                                                    onClick={this.SpecialityAdvBoardAddSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.SpecialityAdvBoardAddSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arraySpecialityAdvBoard.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteAdvBoardAddSource(el.speciality_links.split(','), item, i, "speciality_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownAdvBoardAddSource(e, i, "speciality_links")}
                                                                                                onChange={this.handleChangeAdvBoardAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                {/* Sub Specilality dropdown starts here */}
                                                                                {
                                                                                    (this.state.showProcedureSubSpecialityDropdownAdvBoard == false) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                                Sub Speciality Type </label>
                                                                                            <Multiselect
                                                                                                data={this.state.SubSpecialitydataForAdvBoard}
                                                                                                placeholder="--Select Sub Speciality--"
                                                                                                valueField='sub_speciality_type'
                                                                                                textField='sub_speciality_type'
                                                                                                // defaultValue={(el.sub_specialty_id).split(",")}
                                                                                                defaultValue={(el.sub_specialty_id != '') ? (el.sub_specialty_id.split(",")) : ([])}
                                                                                                onSelect={(valueField) => console.log(valueField)}

                                                                                                onChange={(event) => {
                                                                                                    console.log(event);
                                                                                                    var joined = [];
                                                                                                    console.log("length : " + event.length);
                                                                                                    if (event.length === 0) {
                                                                                                        this.setState({ SubSpecialityTypeAdvBoard: [] });
                                                                                                    }
                                                                                                    event.map(async (data) => {
                                                                                                        console.log("data in eve map : " + JSON.stringify(data));
                                                                                                        joined = joined.concat(data.sub_speciality_type);
                                                                                                        console.log("data join : " + joined);
                                                                                                        await this.setState({
                                                                                                            SubSpecialityTypeAdvBoard: joined
                                                                                                        });
                                                                                                        console.log("check this ", this.state.SubSpecialityTypeAdvBoard)

                                                                                                        var output = this.state.SubSpecialityTypeAdvBoard.map(function (item) {
                                                                                                            return item;
                                                                                                        });

                                                                                                        let list = [...this.state.AdvisoryBoard];
                                                                                                        list[i] = { ...list[i], ["sub_specialty_id"]: output.join(",") };

                                                                                                        this.setState({ AdvisoryBoard: list });
                                                                                                    })
                                                                                                }}
                                                                                            />
                                                                                            {/* <div className="loginErrCreateProject" style={{marginTop:'1.5%'}}>
                                                    {
                                                    (el.sub_specialty_id != '') ?( <div></div>) :(this.state.subspecialityAdvBoardErr )
                                                    }
                                                </div> */}
                                                                                            <a class="float-right" style={{ marginTop: '1%', color: '#007bff' }}
                                                                                                onClick={this.SubSpecialityAdvBoardAddSourceFun}>+ Add source</a>
                                                                                            {
                                                                                                (!this.state.SubSpecialityAdvBoardAddSource) ? (
                                                                                                    <div></div>
                                                                                                ) : (
                                                                                                    <div>
                                                                                                        {
                                                                                                            arraySubSpecialityAdvBoard.flat().map((item) => (
                                                                                                                <div class="col-md-5" key={item}>
                                                                                                                    {item}
                                                                                                                    <button
                                                                                                                        type="button"
                                                                                                                        className="button"
                                                                                                                        onClick={() => this.handleDeleteAdvBoardAddSource(el.sub_speciality_links.split(','), item, i, "sub_speciality_links")}
                                                                                                                    >
                                                                                                                        &times;
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                            ))
                                                                                                        }
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            class="form-control-addsource"
                                                                                                            value={this.state.value}
                                                                                                            placeholder="Type or paste source link and press `Enter`"
                                                                                                            onKeyDown={e => this.handleKeyDownAdvBoardAddSource(e, i, "sub_speciality_links")}
                                                                                                            onChange={this.handleChangeAdvBoardAddSource}
                                                                                                        />
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                {/* Procedure Dropdown starts here */}
                                                                                {
                                                                                    (this.state.showProcedureSubSpecialityDropdownAdvBoard == false) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                                Procedure Type </label>
                                                                                            <Multiselect
                                                                                                data={this.state.ProcedureDataForAdvBoard}
                                                                                                placeholder="--Select Procedure Type--"
                                                                                                valueField='procedure_type'
                                                                                                textField='procedure_type'
                                                                                                // defaultValue={(el.procedure_type_id).split(",")}
                                                                                                defaultValue={(el.procedure_type_id != '') ? (el.procedure_type_id.split(",")) : ([])}
                                                                                                onSelect={(valueField) => console.log(valueField)}

                                                                                                onChange={(event) => {
                                                                                                    console.log(event);
                                                                                                    var joined = [];
                                                                                                    console.log("length : " + event.length);
                                                                                                    if (event.length === 0) {
                                                                                                        this.setState({ ProcedureTypeAdvBoard: [] });
                                                                                                    }
                                                                                                    event.map(async (data) => {
                                                                                                        console.log("data in eve map : " + JSON.stringify(data));
                                                                                                        joined = joined.concat(data.procedure_type);
                                                                                                        console.log("data join : " + joined);
                                                                                                        await this.setState({
                                                                                                            ProcedureTypeAdvBoard: joined
                                                                                                        });
                                                                                                        console.log("check this ", this.state.ProcedureTypeAdvBoard)

                                                                                                        var output = this.state.ProcedureTypeAdvBoard.map(function (item) {
                                                                                                            return item;
                                                                                                        });

                                                                                                        let list = [...this.state.AdvisoryBoard];
                                                                                                        list[i] = { ...list[i], ["procedure_type_id"]: output.join(",") };

                                                                                                        this.setState({ AdvisoryBoard: list });
                                                                                                    })
                                                                                                }}
                                                                                            />
                                                                                            {/* <div className="loginErrCreateProject" style={{marginTop:'1.5%'}}>
                                                    {
                                                    (el.procedure_type_id != '') ?( <div></div>) :(this.state.procedureAdvBoardErr )
                                                    }
                                                </div> */}
                                                                                            <a class="float-right" style={{ marginTop: '1%', color: '#007bff' }}
                                                                                                onClick={this.ProcedureAdvBoardAddSourceFun}>+ Add source</a>
                                                                                            {
                                                                                                (!this.state.ProcedureAdvBoardAddSource) ? (
                                                                                                    <div></div>
                                                                                                ) : (
                                                                                                    <div>
                                                                                                        {
                                                                                                            arrayProcedureAdvBoard.flat().map((item) => (
                                                                                                                <div class="col-md-5" key={item}>
                                                                                                                    {item}
                                                                                                                    <button
                                                                                                                        type="button"
                                                                                                                        className="button"
                                                                                                                        onClick={() => this.handleDeleteAdvBoardAddSource(el.procedure_type_links.split(','), item, i, "procedure_type_links")}
                                                                                                                    >
                                                                                                                        &times;
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                            ))
                                                                                                        }
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            class="form-control-addsource"
                                                                                                            value={this.state.value}
                                                                                                            placeholder="Type or paste source link and press `Enter`"
                                                                                                            onKeyDown={e => this.handleKeyDownAdvBoardAddSource(e, i, "procedure_type_links")}
                                                                                                            onChange={this.handleChangeAdvBoardAddSource}
                                                                                                        />
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        </div>

                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                    Year </label>
                                                                                <input type="date" class="form-control"
                                                                                    autofocus="true"
                                                                                    id="input7" placeholder="Enter Year"
                                                                                    name="year" value={el.year || ''}
                                                                                    onChange={this.handleChangeAdvisoryBoard.bind(this, i)}
                                                                                />
                                                                                {/* <YearPicker class="form-control" 
                                            name="year" 
                                            value={el.year ||''}
                                            onChange={this.handleChangeAdvisoryBoard.bind(this, i)}
                                        /> */}
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.year != '') ? (<div></div>) : (this.state.yearErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.YearAddSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.YearAddSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayYearAdvBoard.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteAdvBoardAddSource(el.year_links.split(','), item, i, "year_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownAdvBoardAddSource(e, i, "year_links")}
                                                                                                onChange={this.handleChangeAdvBoardAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Company/Org  <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    autofocus="true"
                                                                                    id="input7" placeholder="Enter Company/Org"
                                                                                    name="company" value={el.company || ''}
                                                                                    onChange={this.handleChangeAdvisoryBoard.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.company != '') ? (<div></div>) : (this.state.companyAdvBoardErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.CompanyOrgAddSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.CompanyOrgAddSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayCompany.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteAdvBoardAddSource(el.company_links.split(','), item, i, "company_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownAdvBoardAddSource(e, i, "company_links")}
                                                                                                onChange={this.handleChangeAdvBoardAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Type Of Company/Org  <span class="mandatory">*</span></label>
                                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                                    style={{ height: '50px' }}
                                                                                    name="type_of_company"
                                                                                    value={el.type_of_company || ''}
                                                                                    onChange={this.handleChangeAdvisoryBoard.bind(this, i)}
                                                                                >
                                                                                    <option value="0" >--Select Type Of Company--</option>
                                                                                    <option value="International/MNC" >International/MNC</option>
                                                                                    <option value="National" >National</option>
                                                                                </Input>
                                                                                <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                                                    {
                                                                                        (el.type_of_company != '') ? (<div></div>) : (this.state.typeofCompanyErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '1%', color: '#007bff' }}
                                                                                    onClick={this.TypeOfCompanyOrgAddSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.TypeOfCompanyOrgAddSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayTypeofCompany.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteAdvBoardAddSource(el.type_of_company_links.split(','), item, i, "type_of_company_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownAdvBoardAddSource(e, i, "type_of_company_links")}
                                                                                                onChange={this.handleChangeAdvBoardAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                    Advisory Board  <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Advisory Board"
                                                                                    name="advisory_board" value={el.advisory_board || ''}
                                                                                    onChange={this.handleChangeAdvisoryBoard.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.advisory_board != '') ? (<div></div>) : (this.state.advisoryboardErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.AdvisoryBoardSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.AdvisoryBoardSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayAdvisoryBoard.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteAdvBoardAddSource(el.advisory_board_links.split(','), item, i, "advisory_board_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownAdvBoardAddSource(e, i, "advisory_board_links")}
                                                                                                onChange={this.handleChangeAdvBoardAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Topic  <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Topic"
                                                                                    name="topic" value={el.topic || ''}
                                                                                    onChange={this.handleChangeAdvisoryBoard.bind(this, i)}
                                                                                />
                                                                                {/* <div className="loginErrCreateProject" >
                                            {
                                            (el.topic != '') ?( <div></div>) :(this.state.topicErr )
                                            }
                                        </div> */}
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.TopicSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.TopicSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayTopic.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteAdvBoardAddSource(el.topic_links.split(','), item, i, "topic_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownAdvBoardAddSource(e, i, "topic_links")}
                                                                                                onChange={this.handleChangeAdvBoardAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Role  <span class="mandatory">*</span></label>
                                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                                    style={{ height: '50px' }}
                                                                                    name="role_id"
                                                                                    value={el.role_id || ''}
                                                                                    onChange={this.handleChangeAdvisoryBoard.bind(this, i)}
                                                                                >
                                                                                    <option value="0" >--Select Role--</option>
                                                                                    <option value="Board Member" >Board Member</option>
                                                                                    <option value="Advisory Committee" >Advisory Committee</option>
                                                                                    <option value="Chair" >Chair</option>
                                                                                    <option value="Co-Chair" >Co-Chair</option>
                                                                                    <option value="Member" >Member</option>
                                                                                </Input>
                                                                                <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                                                    {
                                                                                        (el.role_id != '') ? (<div></div>) : (this.state.roleAdvBoardErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '1%', color: '#007bff' }}
                                                                                    onClick={this.RoleAdvBoardAddSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.RoleAdvBoardAddSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayRole.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteAdvBoardAddSource(el.role_links.split(','), item, i, "role_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownAdvBoardAddSource(e, i, "role_links")}
                                                                                                onChange={this.handleChangeAdvBoardAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                {/* <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Manual weightage  <span class="mandatory">*</span></label>
                                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                                    style={{ height: '50px' }}
                                                                                    name="manual_weightage"
                                                                                    value={el.manual_weightage || ''}
                                                                                    onChange={this.handleChangeAdvisoryBoard.bind(this, i)}
                                                                                >
                                                                                    <option value="0" >--Select Manual weightage--</option>
                                                                                    <option value="1" >1</option>
                                                                                    <option value="2" >2</option>
                                                                                    <option value="3" >3</option>
                                                                                    <option value="4" >4</option>
                                                                                    <option value="5" >5</option>
                                                                                </Input>
                                                                                <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                                                    {
                                                                                        (el.manual_weightage != '') ? (<div></div>) : (this.state.manualAdvBoardErr)
                                                                                    }
                                                                                </div> */}

                                                                                <hr />
                                                                                {/* Add More */}

                                                                                {this.state.AdvisoryBoard.length - 1 === i &&
                                                                                    <a style={{ marginTop: '5%', color: '#007bff' }}
                                                                                        onClick={this.addClickAdvisoryBoard.bind(this)}>+ Add More Advisory Board</a>
                                                                                }
                                                                                {/* <input type="submit" value="Submit" onClick={this.handleSubmitAdvisoryBoard.bind(this)}/> */}

                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </form>
                                                        </section>
                                                    ) : (
                                                        <div>
                                                        </div>
                                                    )
                                                }
                                                {/* Awards & Honours form */}
                                                {
                                                    (this.state.AwardsAndHonoursFlag == true) ? (
                                                        <section style={{ marginBottom: '50%' }}>
                                                            <h3 class="mt-30">Awards & Honours 1</h3>
                                                            <form>
                                                                {
                                                                    this.state.AwardsHonours.map((el, i) => {
                                                                        var arrayYearAwards = [];
                                                                        var arrayAwardsnHonours = [];
                                                                        var arrayTypesAwards = [];

                                                                        if (el.year_links !== '') {
                                                                            arrayYearAwards = el.year_links.split(',')
                                                                            arrayYearAwards = arrayYearAwards.splice(1)
                                                                        }
                                                                        if (el.awards_honours_links !== '') {
                                                                            arrayAwardsnHonours = el.awards_honours_links.split(',')
                                                                            arrayAwardsnHonours = arrayAwardsnHonours.splice(1)
                                                                        }
                                                                        if (el.types_links !== '') {
                                                                            arrayTypesAwards = el.types_links.split(',')
                                                                            arrayTypesAwards = arrayTypesAwards.splice(1)
                                                                        }

                                                                        return (
                                                                            <div key={i}>
                                                                                {
                                                                                    (i == 0) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            <h3 class="mt-30">Awards & Honours {i + 1}</h3>
                                                                                            {this.state.AwardsHonours.length !== 1 &&
                                                                                                <a class="float-right" style={{ marginTop: '-8%', color: '#007bff' }}
                                                                                                    onClick={this.removeClickAwardsHonours.bind(this, i)}>x</a>
                                                                                            }

                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Year </label>
                                                                                <input type="date" class="form-control"
                                                                                    autofocus="true"
                                                                                    id="input7" placeholder="Enter Year"
                                                                                    name="year" value={el.year || ''}
                                                                                    onChange={this.handleChangeAwardsHonours.bind(this, i)}
                                                                                />
                                                                                {/* <div className="loginErrCreateProject" >
                                            {
                                            (el.year != '') ?( <div></div>) :(this.state.yearAwardsErr )
                                            }
                                        </div> */}
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.YearAwardSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.YearAwardSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayYearAwards.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteAwardsHonoursAddSource(el.year_links.split(','), item, i, "year_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownAwardsHonoursAddSource(e, i, "year_links")}
                                                                                                onChange={this.handleChangeAwardsHonoursAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Awards & Honours  <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Awards And Honours"
                                                                                    name="awards_honours" value={el.awards_honours || ''}
                                                                                    onChange={this.handleChangeAwardsHonours.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.awards_honours != '') ? (<div></div>) : (this.state.awardsnhonourErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.AwardsHonoursSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.AwardsHonoursSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayAwardsnHonours.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteAwardsHonoursAddSource(el.awards_honours_links.split(','), item, i, "awards_honours_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownAwardsHonoursAddSource(e, i, "awards_honours_links")}
                                                                                                onChange={this.handleChangeAwardsHonoursAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Types  </label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Types"
                                                                                    name="types" value={el.types || ''}
                                                                                    onChange={this.handleChangeAwardsHonours.bind(this, i)}
                                                                                />
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.TypesSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.TypesSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayTypesAwards.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteAwardsHonoursAddSource(el.types_links.split(','), item, i, "types_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownAwardsHonoursAddSource(e, i, "types_links")}
                                                                                                onChange={this.handleChangeAwardsHonoursAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <hr />
                                                                                {/* Add More and remove */}

                                                                                {this.state.AwardsHonours.length - 1 === i &&
                                                                                    <a style={{ marginTop: '5%', color: '#007bff' }}
                                                                                        onClick={this.addClickAwardsHonours.bind(this)}>+ Add More Awards & Honours</a>
                                                                                }
                                                                                {/* <input type="submit" value="Submit" onClick={this.handleSubmitAwardsHonours.bind(this)}/> */}
                                                                            </div>
                                                                        )
                                                                    })

                                                                }
                                                            </form>
                                                        </section>
                                                    ) : (
                                                        <div>
                                                        </div>
                                                    )
                                                }

                                                {/* Books and Monographs form */}
                                                {
                                                    (this.state.BooksAndMonographFlag == true) ? (
                                                        <section style={{ marginBottom: '50%' }}>
                                                            <h3 class="mt-30">Books & Monographs 1</h3>
                                                            <form>
                                                                {
                                                                    this.state.BooksMonographs.map((el, i) => {
                                                                        var arrayYearBooks = [];
                                                                        var arrayTitleBooks = [];
                                                                        var arrayAuthor = [];
                                                                        var arrayPublicationBooks = [];
                                                                        var arrayEdition = [];
                                                                        var arrayPages = [];
                                                                        var arrayEditors = []

                                                                        if (el.year_links !== '') {
                                                                            arrayYearBooks = el.year_links.split(',')
                                                                            arrayYearBooks = arrayYearBooks.splice(1)
                                                                        }
                                                                        if (el.title_links !== '') {
                                                                            arrayTitleBooks = el.title_links.split(',')
                                                                            arrayTitleBooks = arrayTitleBooks.splice(1)
                                                                        }
                                                                        if (el.author_links !== '') {
                                                                            arrayAuthor = el.author_links.split(',')
                                                                            arrayAuthor = arrayAuthor.splice(1)
                                                                        }
                                                                        if (el.publication_links !== '') {
                                                                            arrayPublicationBooks = el.publication_links.split(',')
                                                                            arrayPublicationBooks = arrayPublicationBooks.splice(1)
                                                                        }
                                                                        if (el.edition_links !== '') {
                                                                            arrayEdition = el.edition_links.split(',')
                                                                            arrayEdition = arrayEdition.splice(1)
                                                                        }
                                                                        if (el.pages_links !== '') {
                                                                            arrayPages = el.pages_links.split(',')
                                                                            arrayPages = arrayPages.splice(1)
                                                                        }
                                                                        if (el.editors_links !== '') {
                                                                            arrayEditors = el.editors_links.split(',')
                                                                            arrayEditors = arrayEditors.splice(1)
                                                                        }

                                                                        return (
                                                                            <div key={i}>
                                                                                {
                                                                                    (i == 0) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            <h3 class="mt-30">Books & Monographs {i + 1}</h3>
                                                                                            {this.state.BooksMonographs.length !== 1 &&
                                                                                                <a class="float-right" style={{ marginTop: '-8%', color: '#007bff' }}
                                                                                                    onClick={this.removeClickBooksMonographs.bind(this, i)}>x</a>
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Year <span class="mandatory">*</span></label>
                                                                                <input type="date" class="form-control"
                                                                                    autofocus="true"
                                                                                    id="input7" placeholder="Enter Year"
                                                                                    name="year" value={el.year || ''}
                                                                                    onChange={this.handleChangeBooksMonographs.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.year != '') ? (<div></div>) : (this.state.yearBooksErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.YearBooksSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.YearBooksSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayYearBooks.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteBooksMonographsAddSource(el.year_links.split(','), item, i, "year_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownBooksMonographsAddSource(e, i, "year_links")}
                                                                                                onChange={this.handleChangeBooksMonographsAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Title <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Title"
                                                                                    name="title" value={el.title || ''}
                                                                                    onChange={this.handleChangeBooksMonographs.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.title != '') ? (<div></div>) : (this.state.titleErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.titleSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.titleSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayTitleBooks.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteBooksMonographsAddSource(el.title_links.split(','), item, i, "title_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownBooksMonographsAddSource(e, i, "title_links")}
                                                                                                onChange={this.handleChangeBooksMonographsAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Author <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Author"
                                                                                    name="author" value={el.author || ''}
                                                                                    onChange={this.handleChangeBooksMonographs.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.author != '') ? (<div></div>) : (this.state.authorErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.AuthorSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.AuthorSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayAuthor.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteBooksMonographsAddSource(el.author_links.split(','), item, i, "author_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownBooksMonographsAddSource(e, i, "author_links")}
                                                                                                onChange={this.handleChangeBooksMonographsAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Publication <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Publication"
                                                                                    name="publication" value={el.publication || ''}
                                                                                    onChange={this.handleChangeBooksMonographs.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.publication != '') ? (<div></div>) : (this.state.publicationErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.PublicationSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.PublicationSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayPublicationBooks.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteBooksMonographsAddSource(el.publication_links.split(','), item, i, "publication_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownBooksMonographsAddSource(e, i, "publication_links")}
                                                                                                onChange={this.handleChangeBooksMonographsAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Edition </label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Edition"
                                                                                    name="edition" value={el.edition || ''}
                                                                                    onChange={this.handleChangeBooksMonographs.bind(this, i)}
                                                                                />
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.EditionSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.EditionSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayEdition.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteBooksMonographsAddSource(el.edition_links.split(','), item, i, "edition_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownBooksMonographsAddSource(e, i, "edition_links")}
                                                                                                onChange={this.handleChangeBooksMonographsAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Pages </label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Pages"
                                                                                    name="pages" value={el.pages || ''}
                                                                                    onChange={this.handleChangeBooksMonographs.bind(this, i)}
                                                                                />
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.PagesSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.PagesSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayPages.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteBooksMonographsAddSource(el.pages_links.split(','), item, i, "pages_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownBooksMonographsAddSource(e, i, "pages_links")}
                                                                                                onChange={this.handleChangeBooksMonographsAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Editors </label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Editors"
                                                                                    name="editors" value={el.editors || ''}
                                                                                    onChange={this.handleChangeBooksMonographs.bind(this, i)}
                                                                                />
                                                                                {/* <div className="loginErrCreateProject" >
                                                {
                                                (el.editors != '') ?( <div></div>) :(this.state.editorsErr )
                                                }
                                            </div> */}
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.EditorsSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.EditorsSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayEditors.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteBooksMonographsAddSource(el.editors_links.split(','), item, i, "editors_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownBooksMonographsAddSource(e, i, "editors_links")}
                                                                                                onChange={this.handleChangeBooksMonographsAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <hr />
                                                                                {/* Add More and remove */}
                                                                                {this.state.BooksMonographs.length - 1 === i &&
                                                                                    <a style={{ marginTop: '5%', color: '#007bff' }}
                                                                                        onClick={this.addClickBooksMonographs.bind(this)}>+ Add More Books & Monographs</a>
                                                                                }
                                                                                {/* <input type="submit" value="Submit" onClick={this.handleSubmitBooksMonographs.bind(this)}/> */}

                                                                            </div>
                                                                        )
                                                                    })

                                                                }
                                                            </form>
                                                        </section>
                                                    ) : (
                                                        <div>
                                                        </div>
                                                    )
                                                }

                                                {/* Commitees form */}
                                                {
                                                    (this.state.CommiteesFlag == true) ? (
                                                        <section style={{ marginBottom: '50%' }}>
                                                            <h3 class="mt-30">Committees 1</h3>
                                                            <form>
                                                                {
                                                                    this.state.Commitees.map((el, i) => {
                                                                        var arrayYearComitees = [];
                                                                        var arrayCommeitees = [];
                                                                        var arrayRoleCommitees = [];

                                                                        if (el.year_links !== '') {
                                                                            arrayYearComitees = el.year_links.split(',')
                                                                            arrayYearComitees = arrayYearComitees.splice(1)
                                                                        }
                                                                        if (el.committee_links !== '') {
                                                                            arrayCommeitees = el.committee_links.split(',')
                                                                            arrayCommeitees = arrayCommeitees.splice(1)
                                                                        }
                                                                        if (el.role_links !== '') {
                                                                            arrayRoleCommitees = el.role_links.split(',')
                                                                            arrayRoleCommitees = arrayRoleCommitees.splice(1)
                                                                        }

                                                                        return (
                                                                            <div key={i}>
                                                                                {
                                                                                    (i == 0) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            <h3 class="mt-30">Committees {i + 1}</h3>
                                                                                            {this.state.Commitees.length !== 1 &&
                                                                                                <a class="float-right" style={{ marginTop: '-8%', color: '#007bff' }}
                                                                                                    onClick={this.removeClickCommitees.bind(this, i)}>x</a>
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Year</label>
                                                                                <input type="date" class="form-control"
                                                                                    autofocus="true"
                                                                                    id="input7" placeholder="Enter Publication"
                                                                                    name="year" value={el.year || ''}
                                                                                    onChange={this.handleChangeCommitees.bind(this, i)}
                                                                                />
                                                                                {/* <div className="loginErrCreateProject" >
                                                {
                                                (el.year != '') ?( <div></div>) :(this.state.yearCommiteeErr )
                                                }
                                            </div> */}
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.YearCommiteesSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.YearCommiteesSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayYearComitees.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteCommiteesAddSource(el.year_links.split(','), item, i, "year_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste email source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownCommiteesAddSource(e, i, "year_links")}
                                                                                                onChange={this.handleChangeCommiteesAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Commitee <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Commitee"
                                                                                    name="committee" value={el.committee || ''}
                                                                                    onChange={this.handleChangeCommitees.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.committee != '') ? (<div></div>) : (this.state.commiteeErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.CommiteeSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.CommiteeSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayCommeitees.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteCommiteesAddSource(el.committee_links.split(','), item, i, "committee_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste email source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownCommiteesAddSource(e, i, "committee_links")}
                                                                                                onChange={this.handleChangeCommiteesAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Role <span class="mandatory">*</span></label>
                                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                                    style={{ height: '50px' }}
                                                                                    name="role_id"
                                                                                    value={el.role_id || ''}
                                                                                    onChange={this.handleChangeCommitees.bind(this, i)}
                                                                                >
                                                                                    <option value="0" >--Select Role--</option>
                                                                                    <option value="President" >President</option>
                                                                                    <option value="Chairman" >Chairman</option>
                                                                                    <option value="Secretary" >Secretary</option>
                                                                                    <option value="Treasurer" >Treasurer</option>
                                                                                    <option value="Marketing and Promotion Officer" >Marketing and Promotion Officer</option>
                                                                                    <option value="Health and Safety Officer" >Health and Safety Officer</option>
                                                                                    <option value="Volunteer Coordinator" >Volunteer Coordinator</option>
                                                                                    <option value="Other" >Other</option>
                                                                                </Input>
                                                                                <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                                                    {
                                                                                        (el.role_id != '') ? (<div></div>) : (this.state.roleErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '1%', color: '#007bff' }}
                                                                                    onClick={this.RoleSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.RoleSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayRoleCommitees.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteCommiteesAddSource(el.role_links.split(','), item, i, "role_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste email source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownCommiteesAddSource(e, i, "role_links")}
                                                                                                onChange={this.handleChangeCommiteesAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <hr />
                                                                                {/* Add More and remove */}
                                                                                {this.state.Commitees.length - 1 === i &&
                                                                                    <a style={{ marginTop: '5%', color: '#007bff' }}
                                                                                        onClick={this.addClickCommitees.bind(this)}>+ Add More Committees</a>
                                                                                }
                                                                                {/* <input type="submit" value="Submit" onClick={this.handleSubmitCommitees.bind(this)}/> */}

                                                                            </div>
                                                                        )
                                                                    })

                                                                }
                                                            </form>
                                                        </section>
                                                    ) : (
                                                        <div>
                                                        </div>
                                                    )
                                                }

                                                {/* Clinical Trial Details form */}
                                                {
                                                    (this.state.ClinicalTrialDetailsFlag == true) ? (
                                                        <section style={{ marginBottom: '50%' }}>
                                                            <h3 class="mt-30">Clinical Trial Details 1</h3>
                                                            <form>
                                                                {
                                                                    this.state.ClinicalTrialDetails.map((el, i) => {
                                                                        var arraySpecialityClinical = [];
                                                                        var arraySubSpecialityClinical = [];
                                                                        var arrayProcedureClinical = [];
                                                                        var arrayStudyDetails = [];
                                                                        var arraySourceLinkClinical = [];
                                                                        var arrayStartDateClinical = [];
                                                                        var arrayEndDateClinical = [];
                                                                        var arrayTypeClinical = [];
                                                                        var arrayPhaseClinical = [];
                                                                        var arrayRoleClinical = [];
                                                                        var arrayNoofStudy = [];
                                                                        var arrayCondition = [];
                                                                        var arrayTreatmentInv = [];
                                                                        var arrayPrimaryOutcome = [];
                                                                        var arraySecondaryOutcome = [];
                                                                        var arraySponser = [];
                                                                        var arrayCollaboraters = [];

                                                                        if (el.speciality_links !== '') {
                                                                            arraySpecialityClinical = el.speciality_links.split(',')
                                                                            arraySpecialityClinical = arraySpecialityClinical.splice(1)
                                                                        }
                                                                        if (el.sub_speciality_links !== '') {
                                                                            arraySubSpecialityClinical = el.sub_speciality_links.split(',')
                                                                            arraySubSpecialityClinical = arraySubSpecialityClinical.splice(1)
                                                                        }
                                                                        if (el.procedure_type_links !== '') {
                                                                            arrayProcedureClinical = el.procedure_type_links.split(',')
                                                                            arrayProcedureClinical = arrayProcedureClinical.splice(1)
                                                                        }
                                                                        if (el.study_details_links !== '') {
                                                                            arrayStudyDetails = el.study_details_links.split(',')
                                                                            arrayStudyDetails = arrayStudyDetails.splice(1)
                                                                        }
                                                                        if (el.source_link_links !== '') {
                                                                            arraySourceLinkClinical = el.source_link_links.split(',')
                                                                            arraySourceLinkClinical = arraySourceLinkClinical.splice(1)
                                                                        }
                                                                        if (el.start_date_links !== '') {
                                                                            arrayStartDateClinical = el.start_date_links.split(',')
                                                                            arrayStartDateClinical = arrayStartDateClinical.splice(1)
                                                                        }
                                                                        if (el.end_date_links !== '') {
                                                                            arrayEndDateClinical = el.end_date_links.split(',')
                                                                            arrayEndDateClinical = arrayEndDateClinical.splice(1)
                                                                        }
                                                                        if (el.type_links !== '') {
                                                                            arrayTypeClinical = el.type_links.split(',')
                                                                            arrayTypeClinical = arrayTypeClinical.splice(1)
                                                                        }
                                                                        if (el.phase_links !== '') {
                                                                            arrayPhaseClinical = el.phase_links.split(',')
                                                                            arrayPhaseClinical = arrayPhaseClinical.splice(1)
                                                                        }
                                                                        if (el.role_links !== '') {
                                                                            arrayRoleClinical = el.role_links.split(',')
                                                                            arrayRoleClinical = arrayRoleClinical.splice(1)
                                                                        }
                                                                        if (el.no_of_study_locations_links !== '') {
                                                                            arrayNoofStudy = el.no_of_study_locations_links.split(',')
                                                                            arrayNoofStudy = arrayNoofStudy.splice(1)
                                                                        }
                                                                        if (el.conditions_links !== '') {
                                                                            arrayCondition = el.conditions_links.split(',')
                                                                            arrayCondition = arrayCondition.splice(1)
                                                                        }
                                                                        if (el.treatment_intervention_links !== '') {
                                                                            arrayTreatmentInv = el.treatment_intervention_links.split(',')
                                                                            arrayTreatmentInv = arrayTreatmentInv.splice(1)
                                                                        }
                                                                        if (el.primary_outcomes_links !== '') {
                                                                            arrayPrimaryOutcome = el.primary_outcomes_links.split(',')
                                                                            arrayPrimaryOutcome = arrayPrimaryOutcome.splice(1)
                                                                        }
                                                                        if (el.secondary_outcomes_links !== '') {
                                                                            arraySecondaryOutcome = el.secondary_outcomes_links.split(',')
                                                                            arraySecondaryOutcome = arraySecondaryOutcome.splice(1)
                                                                        }
                                                                        if (el.sponsors_links !== '') {
                                                                            arraySponser = el.sponsors_links.split(',')
                                                                            arraySponser = arraySponser.splice(1)
                                                                        }
                                                                        if (el.collaborators_links !== '') {
                                                                            arrayCollaboraters = el.collaborators_links.split(',')
                                                                            arrayCollaboraters = arrayCollaboraters.splice(1)
                                                                        }

                                                                        return (
                                                                            <div key={i}>
                                                                                {
                                                                                    (i == 0) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            <h3 class="mt-30">Clinical Trial Details {i + 1}</h3>
                                                                                            {this.state.ClinicalTrialDetails.length !== 1 &&
                                                                                                <a class="float-right" style={{ marginTop: '-8%', color: '#007bff' }}
                                                                                                    onClick={this.removeClickClinicalTrialDetails.bind(this, i)}>x</a>
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                                                    Speciality <span class="mandatory">*</span></label>
                                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                                    style={{ height: '50px' }}
                                                                                    name="speciality_id"
                                                                                    value={el.speciality_id || ''}
                                                                                    onChange={async (e) => {
                                                                                        console.log(i)
                                                                                        console.log(e.target.value)
                                                                                        const { name, value } = e.target;

                                                                                        let ClinicalTrialDetails = [...this.state.ClinicalTrialDetails]
                                                                                        ClinicalTrialDetails[i] = { ...ClinicalTrialDetails[i], [name]: value }
                                                                                        this.setState({ ClinicalTrialDetails });

                                                                                        { this.getProcedureListClinicalTrial(e.target.value) }
                                                                                        { this.getSubSpecialityListClinicalTrial(e.target.value) }

                                                                                        await this.setState({
                                                                                            ProcedureTypeClinicalTrial: [],
                                                                                            SubSpecialityTypeClinicalTrial: [],
                                                                                            showProcedureSubSpecialityDropdownClinicalTrial: false,
                                                                                        })

                                                                                        ClinicalTrialDetails[i] = { ...ClinicalTrialDetails[i], procedure_type: '' }
                                                                                        ClinicalTrialDetails[i] = { ...ClinicalTrialDetails[i], sub_speciality: '' }
                                                                                        this.setState({ ClinicalTrialDetails });

                                                                                        { this.ProcedureDropdownClinicalTrial() }
                                                                                    }}
                                                                                >
                                                                                    <option value="0" >--Select Speciality--</option>
                                                                                    {OptionsSpeciality}
                                                                                </Input>
                                                                                <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                                                    {
                                                                                        (el.speciality_id != '') ? (<div></div>) : (this.state.specialityClinicalErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '1%', color: '#007bff' }}
                                                                                    onClick={this.SpecialityClinicalSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.SpecialityClinicalSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arraySpecialityClinical.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteClinicalAddSource(el.speciality_links.split(','), item, i, "speciality_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste email source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownClinicalAddSource(e, i, "speciality_links")}
                                                                                                onChange={this.handleChangeClinicalAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }


                                                                                {/* Sub Specilality dropdown starts here */}
                                                                                {
                                                                                    (this.state.showProcedureSubSpecialityDropdownClinicalTrial == false) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                                Sub Speciality Type <span class="mandatory">*</span></label>
                                                                                            <Multiselect
                                                                                                data={this.state.SubSpecialitydataClinicalTrial}
                                                                                                placeholder="--Select Sub Speciality--"
                                                                                                valueField='sub_speciality_type'
                                                                                                textField='sub_speciality_type'
                                                                                                // defaultValue={(el.sub_speciality).split(",")}
                                                                                                defaultValue={(el.sub_speciality != '') ? (el.sub_speciality.split(",")) : ([])}
                                                                                                onSelect={(valueField) => console.log(valueField)}

                                                                                                onChange={(event) => {
                                                                                                    console.log(event);
                                                                                                    var joined = [];
                                                                                                    console.log("length : " + event.length);
                                                                                                    if (event.length === 0) {
                                                                                                        this.setState({ SubSpecialityTypeClinicalTrial: [] });
                                                                                                    }
                                                                                                    event.map(async (data) => {
                                                                                                        console.log("data in eve map : " + JSON.stringify(data));
                                                                                                        joined = joined.concat(data.sub_speciality_type);
                                                                                                        console.log("data join : " + joined);
                                                                                                        await this.setState({
                                                                                                            SubSpecialityTypeClinicalTrial: joined
                                                                                                        });
                                                                                                        console.log("check this ", this.state.SubSpecialityTypeClinicalTrial)

                                                                                                        var output = this.state.SubSpecialityTypeClinicalTrial.map(function (item) {
                                                                                                            return item;
                                                                                                        });

                                                                                                        let list = [...this.state.ClinicalTrialDetails];
                                                                                                        list[i] = { ...list[i], ["sub_speciality"]: output.join(",") };

                                                                                                        this.setState({ ClinicalTrialDetails: list });
                                                                                                    })
                                                                                                }}
                                                                                            />
                                                                                            <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                                                                {
                                                                                                    (el.sub_speciality != '') ? (<div></div>) : (this.state.subSpecialityClinicalErr)
                                                                                                }
                                                                                            </div>
                                                                                            <a class="float-right" style={{ marginTop: '1%', color: '#007bff' }}
                                                                                                onClick={this.SubSpecialityClinicalSourceFun}>+ Add source</a>
                                                                                            {
                                                                                                (!this.state.SubSpecialityClinicalSource) ? (
                                                                                                    <div></div>
                                                                                                ) : (
                                                                                                    <div>
                                                                                                        {
                                                                                                            arraySubSpecialityClinical.flat().map((item) => (
                                                                                                                <div class="col-md-5" key={item}>
                                                                                                                    {item}
                                                                                                                    <button
                                                                                                                        type="button"
                                                                                                                        className="button"
                                                                                                                        onClick={() => this.handleDeleteClinicalAddSource(el.sub_speciality_links.split(','), item, i, "sub_speciality_links")}
                                                                                                                    >
                                                                                                                        &times;
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                            ))
                                                                                                        }
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            class="form-control-addsource"
                                                                                                            value={this.state.value}
                                                                                                            placeholder="Type or paste email source link and press `Enter`"
                                                                                                            onKeyDown={e => this.handleKeyDownClinicalAddSource(e, i, "sub_speciality_links")}
                                                                                                            onChange={this.handleChangeClinicalAddSource}
                                                                                                        />
                                                                                                    </div>
                                                                                                )
                                                                                            }

                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                {/* Procedure Dropdown starts here */}
                                                                                {
                                                                                    (this.state.showProcedureSubSpecialityDropdownClinicalTrial == false) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                                Procedure Type <span class="mandatory">*</span></label>
                                                                                            <Multiselect
                                                                                                data={this.state.ProcedureDataForClinicalTrial}
                                                                                                placeholder="--Select Procedure Type--"
                                                                                                valueField='procedure_type'
                                                                                                textField='procedure_type'
                                                                                                // defaultValue={(el.procedure_type).split(",")}
                                                                                                defaultValue={(el.procedure_type != '') ? (el.procedure_type.split(",")) : ([])}
                                                                                                onSelect={(valueField) => console.log(valueField)}

                                                                                                onChange={(event) => {
                                                                                                    console.log(event);
                                                                                                    var joined = [];
                                                                                                    console.log("length : " + event.length);
                                                                                                    if (event.length === 0) {
                                                                                                        this.setState({ ProcedureTypeClinicalTrial: [] });
                                                                                                    }
                                                                                                    event.map(async (data) => {
                                                                                                        console.log("data in eve map : " + JSON.stringify(data));
                                                                                                        joined = joined.concat(data.procedure_type);
                                                                                                        console.log("data join : " + joined);
                                                                                                        await this.setState({
                                                                                                            ProcedureTypeClinicalTrial: joined
                                                                                                        });
                                                                                                        console.log("check this ", this.state.ProcedureTypeClinicalTrial)

                                                                                                        var output = this.state.ProcedureTypeClinicalTrial.map(function (item) {
                                                                                                            return item;
                                                                                                        });

                                                                                                        let list = [...this.state.ClinicalTrialDetails];
                                                                                                        list[i] = { ...list[i], ["procedure_type"]: output.join(",") };

                                                                                                        this.setState({ ClinicalTrialDetails: list });
                                                                                                    })
                                                                                                }}
                                                                                            />
                                                                                            <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                                                                {
                                                                                                    (el.procedure_type != '') ? (<div></div>) : (this.state.procedureClinicalErr)
                                                                                                }
                                                                                            </div>
                                                                                            <a class="float-right" style={{ marginTop: '1%', color: '#007bff' }}
                                                                                                onClick={this.ProcedureClinicalSourceFun}>+ Add source</a>
                                                                                            {
                                                                                                (!this.state.ProcedureClinicalSource) ? (
                                                                                                    <div></div>
                                                                                                ) : (
                                                                                                    <div>
                                                                                                        {
                                                                                                            arrayProcedureClinical.flat().map((item) => (
                                                                                                                <div class="col-md-5" key={item}>
                                                                                                                    {item}
                                                                                                                    <button
                                                                                                                        type="button"
                                                                                                                        className="button"
                                                                                                                        onClick={() => this.handleDeleteClinicalAddSource(el.procedure_type_links.split(','), item, i, "procedure_type_links")}
                                                                                                                    >
                                                                                                                        &times;
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                            ))
                                                                                                        }
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            class="form-control-addsource"
                                                                                                            value={this.state.value}
                                                                                                            placeholder="Type or paste email source link and press `Enter`"
                                                                                                            onKeyDown={e => this.handleKeyDownClinicalAddSource(e, i, "procedure_type_links")}
                                                                                                            onChange={this.handleChangeClinicalAddSource}
                                                                                                        />
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        </div>

                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Study Details <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    autofocus="true"
                                                                                    id="input7" placeholder="Enter Study Details"
                                                                                    name="study_details" value={el.study_details || ''}
                                                                                    onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.study_details != '') ? (<div></div>) : (this.state.studydetailsErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.StudyDetailsSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.StudyDetailsSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayStudyDetails.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteClinicalAddSource(el.study_details_links.split(','), item, i, "study_details_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste email source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownClinicalAddSource(e, i, "study_details_links")}
                                                                                                onChange={this.handleChangeClinicalAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Source Link <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Source Link"
                                                                                    name="source_link" value={el.source_link || ''}
                                                                                    onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.source_link != '') ? (<div></div>) : (this.state.sourcelinkClinicalErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.SourceLinkClinicalTrialSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.SourceLinkClinicalTrialSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arraySourceLinkClinical.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteClinicalAddSource(el.source_link_links.split(','), item, i, "source_link_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste email source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownClinicalAddSource(e, i, "source_link_links")}
                                                                                                onChange={this.handleChangeClinicalAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Start Date <span class="mandatory">*</span></label>
                                                                                <input type="date" class="form-control"
                                                                                    id="input7" placeholder="Enter Start Date"
                                                                                    name="start_date" value={el.start_date || ''}
                                                                                    onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.start_date != '') ? (<div></div>) : (this.state.startdateErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.StartDateClinicalTrialSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.StartDateClinicalTrialSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayStartDateClinical.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteClinicalAddSource(el.start_date_links.split(','), item, i, "start_date_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste email source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownClinicalAddSource(e, i, "start_date_links")}
                                                                                                onChange={this.handleChangeClinicalAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    End Date <span class="mandatory">*</span></label>
                                                                                <input type="date" class="form-control"
                                                                                    id="input7" placeholder="Enter End Date"
                                                                                    name="end_date" value={el.end_date || ''}
                                                                                    onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.end_date != '') ? (<div></div>) : (this.state.enddateErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.EndDateClinicalTrialSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.EndDateClinicalTrialSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayEndDateClinical.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteClinicalAddSource(el.end_date_links.split(','), item, i, "end_date_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste email source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownClinicalAddSource(e, i, "end_date_links")}
                                                                                                onChange={this.handleChangeClinicalAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Type </label>
                                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                                    style={{ height: '50px' }}
                                                                                    name="type"
                                                                                    value={el.type || ''}
                                                                                    onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                                                                >
                                                                                    <option value="0" >--Select Type--</option>
                                                                                    <option value="Interventional" >Interventional</option>
                                                                                    <option value="Observational" >Observational</option>
                                                                                    <option value="Pilot Study" >Pilot Study</option>
                                                                                    <option value="Feasibility Study" >Feasibility Study</option>
                                                                                    <option value="Prevnetion Trials" >Prevnetion Trials</option>
                                                                                    <option value="Screening Trials" >Screening Trials</option>
                                                                                    <option value="Treatment Trials" >Treatment Trials</option>
                                                                                    <option value="Multi Arm Trials" >Multi Arm Trials</option>
                                                                                    <option value="Multi Stage Trials" >Multi Stage Trials</option>
                                                                                    <option value="Cohort Studies" >Cohort Studies</option>
                                                                                    <option value="Case Control Studies" >Case Control Studies</option>
                                                                                    <option value="Cross Sectional Studies" >Cross Sectional Studies</option>
                                                                                </Input>
                                                                                <a class="float-right" style={{ marginTop: '1%', color: '#007bff' }}
                                                                                    onClick={this.TypeSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.TypeSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayTypeClinical.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteClinicalAddSource(el.type_links.split(','), item, i, "type_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste email source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownClinicalAddSource(e, i, "type_links")}
                                                                                                onChange={this.handleChangeClinicalAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                    Phase <span class="mandatory">*</span> </label>
                                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                                    style={{ height: '50px' }}
                                                                                    name="phase"
                                                                                    value={el.phase || ''}
                                                                                    onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                                                                >
                                                                                    <option value="" >--Select Phase--</option>
                                                                                    <option value="NA" >NA</option>
                                                                                    <option value="Phase I" >Phase I</option>
                                                                                    <option value="Phase II" >Phase II</option>
                                                                                    <option value="Phase III" >Phase III</option>
                                                                                    <option value="Phase IV" >Phase IV</option>
                                                                                    <option value="Phase I/II" >Phase I/II</option>
                                                                                    <option value="Phase II/III" >Phase II/III</option>
                                                                                    <option value="Phase III/IV" >Phase III/IV</option>
                                                                                </Input>
                                                                                <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                                                    {
                                                                                        (el.phase != '') ? (<div></div>) : (this.state.phaseErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '1%', color: '#007bff' }}
                                                                                    onClick={this.PhaseSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.PhaseSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayPhaseClinical.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteClinicalAddSource(el.phase_links.split(','), item, i, "phase_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownClinicalAddSource(e, i, "phase_links")}
                                                                                                onChange={this.handleChangeClinicalAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                    Role <span class="mandatory">*</span> </label>
                                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                                    style={{ height: '50px' }}
                                                                                    name="role_id"
                                                                                    value={el.role_id || ''}
                                                                                    onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                                                                >
                                                                                    <option value="0" >--Select Role--</option>
                                                                                    <option value="Study Chair" >Study Chair</option>
                                                                                    <option value="Study Director" >Study Director</option>
                                                                                    <option value="Investigator" >Investigator</option>
                                                                                    <option value="Principal Investigator/Author" >Principal Investigator/Author</option>
                                                                                    <option value="No Role" >No Role</option>

                                                                                </Input>
                                                                                <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                                                    {
                                                                                        (el.role_id != '') ? (<div></div>) : (this.state.roleClinicalErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '1%', color: '#007bff' }}
                                                                                    onClick={this.RoleClinicalTrialSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.RoleClinicalTrialSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayRoleClinical.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteClinicalAddSource(el.role_links.split(','), item, i, "role_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownClinicalAddSource(e, i, "role_links")}
                                                                                                onChange={this.handleChangeClinicalAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                    No. of Study Locations <span class="mandatory">*</span> </label>
                                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                                    style={{ height: '50px' }}
                                                                                    name="no_of_study_locations"
                                                                                    value={el.no_of_study_locations || ''}
                                                                                    onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                                                                >
                                                                                    <option value="0" >--Select No of Study Locations--</option>
                                                                                    <option value="1" >1</option>
                                                                                    <option value="10" >1-10</option>
                                                                                    <option value="50" >11-50</option>
                                                                                    <option value="10000" >more than 50</option>
                                                                                    {/* <option value="1" >1</option>
                                                                                    <option value="1-10" >1-10</option>
                                                                                    <option value="11-50" >11-50</option>
                                                                                    <option value="more than 50" >more than 50</option> */}
                                                                                </Input>
                                                                                <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                                                    {
                                                                                        (el.no_of_study_locations != '') ? (<div></div>) : (this.state.noStudyLocationErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '1%', color: '#007bff' }}
                                                                                    onClick={this.NoStudyLocationClinicalTrialSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.NoStudyLocationClinicalTrialSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayNoofStudy.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteClinicalAddSource(el.no_of_study_locations_links.split(','), item, i, "no_of_study_locations_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownClinicalAddSource(e, i, "no_of_study_locations_links")}
                                                                                                onChange={this.handleChangeClinicalAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                    Conditions</label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Conditions"
                                                                                    name="conditions" value={el.conditions || ''}
                                                                                    onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                                                                />
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.conditionsSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.conditionsSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayCondition.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteClinicalAddSource(el.conditions_links.split(','), item, i, "conditions_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownClinicalAddSource(e, i, "conditions_links")}
                                                                                                onChange={this.handleChangeClinicalAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Treatment/Intervention</label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Treatment/Intervention"
                                                                                    name="treatment_intervention" value={el.treatment_intervention || ''}
                                                                                    onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                                                                />
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.TreatmentInterventionSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.TreatmentInterventionSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayTreatmentInv.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteClinicalAddSource(el.treatment_intervention_links.split(','), item, i, "treatment_intervention_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownClinicalAddSource(e, i, "treatment_intervention_links")}
                                                                                                onChange={this.handleChangeClinicalAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }


                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Primary Outcome</label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Primary Outcome"
                                                                                    name="primary_outcomes" value={el.primary_outcomes || ''}
                                                                                    // onChange={(e) => {
                                                                                    //     var re = /^[a-z\- ]+$/i;
                                                                                    //     if(re.test(e.target.value)){
                                                                                    //     this.handleChangeClinicalTrialDetails.bind(e, i)
                                                                                    //     }
                                                                                    // }} 
                                                                                    onChange={
                                                                                        this.handleChangeClinicalTrialDetails.bind(this, i)
                                                                                    }
                                                                                />
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.PrimaryOutcomeSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.PrimaryOutcomeSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayPrimaryOutcome.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteClinicalAddSource(el.primary_outcomes_links.split(','), item, i, "primary_outcomes_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownClinicalAddSource(e, i, "primary_outcomes_links")}
                                                                                                onChange={this.handleChangeClinicalAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Secondary Outcomes </label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Secondary Outcomes"
                                                                                    name="secondary_outcomes" value={el.secondary_outcomes || ''}
                                                                                    onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                                                                />
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.SecondaryOutcomesSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.SecondaryOutcomesSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arraySecondaryOutcome.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteClinicalAddSource(el.secondary_outcomes_links.split(','), item, i, "secondary_outcomes_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownClinicalAddSource(e, i, "secondary_outcomes_links")}
                                                                                                onChange={this.handleChangeClinicalAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Sponsor </label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Sponsor"
                                                                                    name="sponsors" value={el.sponsors || ''}
                                                                                    onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                                                                />
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.SponsorSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.SponsorSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arraySponser.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteClinicalAddSource(el.sponsors_links.split(','), item, i, "sponsors_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownClinicalAddSource(e, i, "sponsors_links")}
                                                                                                onChange={this.handleChangeClinicalAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Collaborators </label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Collaborators"
                                                                                    name="collaborators" value={el.collaborators || ''}
                                                                                    onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                                                                />
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.CollaboratorsSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.CollaboratorsSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayCollaboraters.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteClinicalAddSource(el.collaborators_links.split(','), item, i, "collaborators_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownClinicalAddSource(e, i, "collaborators_links")}
                                                                                                onChange={this.handleChangeClinicalAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                {/* <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Manual weightage  <span class="mandatory">*</span></label>
                                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                                    style={{ height: '50px' }}
                                                                                    name="manual_weightage"
                                                                                    value={el.manual_weightage || ''}
                                                                                    onChange={this.handleChangeClinicalTrialDetails.bind(this, i)}
                                                                                >
                                                                                    <option value="0" >--Select Manual weightage--</option>
                                                                                    <option value="1" >1</option>
                                                                                    <option value="2" >2</option>
                                                                                    <option value="3" >3</option>
                                                                                    <option value="4" >4</option>
                                                                                    <option value="5" >5</option>
                                                                                </Input>
                                                                                <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                                                    {
                                                                                        (el.manual_weightage != '') ? (<div></div>) : (this.state.manualClinicaldErr)
                                                                                    }
                                                                                </div> */}

                                                                                <hr />
                                                                                {/* Add More and remove */}

                                                                                {this.state.ClinicalTrialDetails.length - 1 === i &&
                                                                                    <a style={{ marginTop: '5%', color: '#007bff' }}
                                                                                        onClick={this.addClickClinicalTrialDetails.bind(this)}>+ Add More Clinical Trial Details</a>
                                                                                }
                                                                                {/* <input type="submit" value="Submit" onClick={this.handleSubmitClinicalTrialDetails.bind(this)}/> */}

                                                                            </div>
                                                                        )
                                                                    })

                                                                }
                                                            </form>
                                                        </section>
                                                    ) : (
                                                        <div>
                                                        </div>
                                                    )
                                                }

                                                {/* Event Details form */}
                                                {
                                                    (this.state.EventsDetailsFlag == true) ? (
                                                        <section style={{ marginBottom: '50%' }}>
                                                            <h3 class="mt-30">Event Details 1</h3>
                                                            <form>
                                                                {
                                                                    this.state.EventsDetails.map((el, i) => {
                                                                        var arraySpecialityEvent = [];
                                                                        var arraySuSpecialityEvent = [];
                                                                        var arrayProcedureEvent = [];
                                                                        var arrayEventName = [];
                                                                        var arrayEventType = [];
                                                                        var arrayEventDescription = [];
                                                                        var arrayEventTopic = [];
                                                                        var arraySourceEvent = [];
                                                                        var arrayConditions = [];
                                                                        var arrayInterventation = [];
                                                                        var arrayDateEvent = [];
                                                                        var arraySessionType = [];
                                                                        var arrayRoleEvent = [];
                                                                        var arrayEventSponser = [];
                                                                        var arrayTotalEventAttendies = [];
                                                                        var arrayCountryEvent = [];

                                                                        if (el.speciality_links !== '') {
                                                                            arraySpecialityEvent = el.speciality_links.split(',')
                                                                            arraySpecialityEvent = arraySpecialityEvent.splice(1)
                                                                        }
                                                                        if (el.sub_speciality_links !== '') {
                                                                            arraySuSpecialityEvent = el.sub_speciality_links.split(',')
                                                                            arraySuSpecialityEvent = arraySuSpecialityEvent.splice(1)
                                                                        }
                                                                        if (el.procedure_type_links !== '') {
                                                                            arrayProcedureEvent = el.procedure_type_links.split(',')
                                                                            arrayProcedureEvent = arrayProcedureEvent.splice(1)
                                                                        }
                                                                        if (el.events_links !== '') {
                                                                            arrayEventName = el.events_links.split(',')
                                                                            arrayEventName = arrayEventName.splice(1)
                                                                        }
                                                                        if (el.event_type_links !== '') {
                                                                            arrayEventType = el.event_type_links.split(',')
                                                                            arrayEventType = arrayEventType.splice(1)
                                                                        }
                                                                        if (el.event_desc_links !== '') {
                                                                            arrayEventDescription = el.event_desc_links.split(',')
                                                                            arrayEventDescription = arrayEventDescription.splice(1)
                                                                        }
                                                                        if (el.event_topic_links !== '') {
                                                                            arrayEventTopic = el.event_topic_links.split(',')
                                                                            arrayEventTopic = arrayEventTopic.splice(1)
                                                                        }
                                                                        if (el.source_link_links !== '') {
                                                                            arraySourceEvent = el.source_link_links.split(',')
                                                                            arraySourceEvent = arraySourceEvent.splice(1)
                                                                        }
                                                                        if (el.conditions_links !== '') {
                                                                            arrayConditions = el.conditions_links.split(',')
                                                                            arrayConditions = arrayConditions.splice(1)
                                                                        }
                                                                        if (el.intervention_links !== '') {
                                                                            arrayInterventation = el.intervention_links.split(',')
                                                                            arrayInterventation = arrayInterventation.splice(1)
                                                                        }
                                                                        if (el.date_links !== '') {
                                                                            arrayDateEvent = el.date_links.split(',')
                                                                            arrayDateEvent = arrayDateEvent.splice(1)
                                                                        }
                                                                        if (el.session_type_links !== '') {
                                                                            arraySessionType = el.session_type_links.split(',')
                                                                            arraySessionType = arraySessionType.splice(1)
                                                                        }
                                                                        if (el.role_links !== '') {
                                                                            arrayRoleEvent = el.role_links.split(',')
                                                                            arrayRoleEvent = arrayRoleEvent.splice(1)
                                                                        }
                                                                        if (el.event_sponsor_links !== '') {
                                                                            arrayEventSponser = el.event_sponsor_links.split(',')
                                                                            arrayEventSponser = arrayEventSponser.splice(1)
                                                                        }
                                                                        if (el.total_event_attendees_links !== '') {
                                                                            arrayTotalEventAttendies = el.total_event_attendees_links.split(',')
                                                                            arrayTotalEventAttendies = arrayTotalEventAttendies.splice(1)
                                                                        }
                                                                        if (el.country_links !== '') {
                                                                            arrayCountryEvent = el.country_links.split(',')
                                                                            arrayCountryEvent = arrayCountryEvent.splice(1)
                                                                        }

                                                                        return (
                                                                            <div key={i}>
                                                                                {
                                                                                    (i == 0) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            <h3 class="mt-30">Event Details {i + 1}</h3>
                                                                                            {this.state.EventsDetails.length !== 1 &&
                                                                                                <a class="float-right" style={{ marginTop: '-8%', color: '#007bff' }}
                                                                                                    onClick={this.removeClickEventsDetails.bind(this, i)}>x</a>
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                                                    Speciality <span class="mandatory">*</span></label>
                                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                                    style={{ height: '50px' }}
                                                                                    name="speciality_id"
                                                                                    value={el.speciality_id || ''}
                                                                                    onChange={async (e) => {
                                                                                        console.log(i)
                                                                                        console.log(e.target.value)
                                                                                        const { name, value } = e.target;

                                                                                        let EventsDetails = [...this.state.EventsDetails]
                                                                                        EventsDetails[i] = { ...EventsDetails[i], [name]: value }
                                                                                        this.setState({ EventsDetails });

                                                                                        { this.getProcedureListEventsDetails(e.target.value) }
                                                                                        { this.getSubSpecialityListEventsDetails(e.target.value) }

                                                                                        await this.setState({
                                                                                            ProcedureTypeEventDetails: [],
                                                                                            SubSpecialityTypeEventDetails: [],
                                                                                            showProcedureSubSpecialityDropdownEventDetails: false,
                                                                                        })

                                                                                        EventsDetails[i] = { ...EventsDetails[i], procedure_type: '' }
                                                                                        EventsDetails[i] = { ...EventsDetails[i], sub_speciality: '' }
                                                                                        this.setState({ EventsDetails });

                                                                                        { this.ProcedureDropdownEventDetails() }
                                                                                    }}
                                                                                >
                                                                                    <option value="0" >--Select Speciality--</option>
                                                                                    {OptionsSpeciality}
                                                                                </Input>
                                                                                <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                                                    {
                                                                                        (el.speciality_id != '') ? (<div></div>) : (this.state.specialityEventErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '1%', color: '#007bff' }}
                                                                                    onClick={this.SpecialityEventSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.SpecialityEventSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arraySpecialityEvent.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteEventAddSource(el.speciality_links.split(','), item, i, "speciality_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownEventAddSource(e, i, "speciality_links")}
                                                                                                onChange={this.handleChangeEventAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                {/* Sub Specilality dropdown starts here */}
                                                                                {
                                                                                    (this.state.showProcedureSubSpecialityDropdownEventDetails == false) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                                Sub Speciality Type <span class="mandatory">*</span></label>
                                                                                            <Multiselect
                                                                                                data={this.state.SubSpecialitydataEventDetails}
                                                                                                placeholder="--Select Sub Speciality--"
                                                                                                valueField='sub_speciality_type'
                                                                                                textField='sub_speciality_type'
                                                                                                // defaultValue={(el.sub_speciality).split(",")}
                                                                                                defaultValue={(el.sub_speciality != '') ? (el.sub_speciality.split(",")) : ([])}
                                                                                                onSelect={(valueField) => console.log(valueField)}

                                                                                                onChange={(event) => {
                                                                                                    console.log(event);
                                                                                                    var joined = [];
                                                                                                    console.log("length : " + event.length);
                                                                                                    if (event.length === 0) {
                                                                                                        this.setState({ SubSpecialityTypeEventDetails: [] });
                                                                                                    }
                                                                                                    event.map(async (data) => {
                                                                                                        console.log("data in eve map : " + JSON.stringify(data));
                                                                                                        joined = joined.concat(data.sub_speciality_type);
                                                                                                        console.log("data join : " + joined);
                                                                                                        await this.setState({
                                                                                                            SubSpecialityTypeEventDetails: joined
                                                                                                        });
                                                                                                        console.log("check this ", this.state.SubSpecialityTypeEventDetails)

                                                                                                        var output = this.state.SubSpecialityTypeEventDetails.map(function (item) {
                                                                                                            return item;
                                                                                                        });

                                                                                                        let list = [...this.state.EventsDetails];
                                                                                                        list[i] = { ...list[i], ["sub_speciality"]: output.join(",") };

                                                                                                        this.setState({ EventsDetails: list });
                                                                                                    })
                                                                                                }}
                                                                                            />
                                                                                            <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                                                                {
                                                                                                    (el.sub_speciality != '') ? (<div></div>) : (this.state.subSpecialityEventErr)
                                                                                                }
                                                                                            </div>
                                                                                            <a class="float-right" style={{ marginTop: '1%', color: '#007bff' }}
                                                                                                onClick={this.SubSpecialityEventSourceFun}>+ Add source</a>
                                                                                            {
                                                                                                (!this.state.SubSpecialityEventSource) ? (
                                                                                                    <div></div>
                                                                                                ) : (
                                                                                                    <div>
                                                                                                        {
                                                                                                            arraySuSpecialityEvent.flat().map((item) => (
                                                                                                                <div class="col-md-5" key={item}>
                                                                                                                    {item}
                                                                                                                    <button
                                                                                                                        type="button"
                                                                                                                        className="button"
                                                                                                                        onClick={() => this.handleDeleteEventAddSource(el.sub_speciality_links.split(','), item, i, "sub_speciality_links")}
                                                                                                                    >
                                                                                                                        &times;
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                            ))
                                                                                                        }
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            class="form-control-addsource"
                                                                                                            value={this.state.value}
                                                                                                            placeholder="Type or paste source link and press `Enter`"
                                                                                                            onKeyDown={e => this.handleKeyDownEventAddSource(e, i, "sub_speciality_links")}
                                                                                                            onChange={this.handleChangeEventAddSource}
                                                                                                        />
                                                                                                    </div>
                                                                                                )
                                                                                            }

                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                {/* Procedure Dropdown starts here */}
                                                                                {
                                                                                    (this.state.showProcedureSubSpecialityDropdownEventDetails == false) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                                Procedure Type <span class="mandatory">*</span></label>
                                                                                            <Multiselect
                                                                                                data={this.state.ProcedureDataForEventDetails}
                                                                                                placeholder="--Select Procedure Type--"
                                                                                                valueField='procedure_type'
                                                                                                textField='procedure_type'
                                                                                                // defaultValue={(el.procedure_type).split(",")}
                                                                                                defaultValue={(el.procedure_type != '') ? (el.procedure_type.split(",")) : ([])}
                                                                                                onSelect={(valueField) => console.log(valueField)}

                                                                                                onChange={(event) => {
                                                                                                    console.log(event);
                                                                                                    var joined = [];
                                                                                                    console.log("length : " + event.length);
                                                                                                    if (event.length === 0) {
                                                                                                        this.setState({ ProcedureTypeEventDetails: [] });
                                                                                                    }
                                                                                                    event.map(async (data) => {
                                                                                                        console.log("data in eve map : " + JSON.stringify(data));
                                                                                                        joined = joined.concat(data.procedure_type);
                                                                                                        console.log("data join : " + joined);
                                                                                                        await this.setState({
                                                                                                            ProcedureTypeEventDetails: joined
                                                                                                        });
                                                                                                        console.log("check this ", this.state.ProcedureTypeEventDetails)

                                                                                                        var output = this.state.ProcedureTypeEventDetails.map(function (item) {
                                                                                                            return item;
                                                                                                        });

                                                                                                        let list = [...this.state.EventsDetails];
                                                                                                        list[i] = { ...list[i], ["procedure_type"]: output.join(",") };

                                                                                                        this.setState({ EventsDetails: list });
                                                                                                    })
                                                                                                }}
                                                                                            />
                                                                                            <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                                                                {
                                                                                                    (el.procedure_type != '') ? (<div></div>) : (this.state.procedureEventErr)
                                                                                                }
                                                                                            </div>
                                                                                            <a class="float-right" style={{ marginTop: '1%', color: '#007bff' }}
                                                                                                onClick={this.ProceduereEventSourceFun}>+ Add source</a>
                                                                                            {
                                                                                                (!this.state.ProceduereEventSource) ? (
                                                                                                    <div></div>
                                                                                                ) : (
                                                                                                    <div>
                                                                                                        {
                                                                                                            arrayProcedureEvent.flat().map((item) => (
                                                                                                                <div class="col-md-5" key={item}>
                                                                                                                    {item}
                                                                                                                    <button
                                                                                                                        type="button"
                                                                                                                        className="button"
                                                                                                                        onClick={() => this.handleDeleteEventAddSource(el.procedure_type_links.split(','), item, i, "procedure_type_links")}
                                                                                                                    >
                                                                                                                        &times;
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                            ))
                                                                                                        }
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            class="form-control-addsource"
                                                                                                            value={this.state.value}
                                                                                                            placeholder="Type or paste source link and press `Enter`"
                                                                                                            onKeyDown={e => this.handleKeyDownEventAddSource(e, i, "procedure_type_links")}
                                                                                                            onChange={this.handleChangeEventAddSource}
                                                                                                        />
                                                                                                    </div>
                                                                                                )
                                                                                            }

                                                                                        </div>

                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                    Events/Conference Name <span class="mandatory">*</span> </label>
                                                                                <input type="text" class="form-control"
                                                                                    autofocus="true"
                                                                                    id="input7" placeholder="Enter Event Description"
                                                                                    name="events" value={el.events || ''}
                                                                                    onChange={this.handleChangeEventsDetails.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.events != '') ? (<div></div>) : (this.state.eventNameErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.EventNameSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.EventNameSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayEventName.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteEventAddSource(el.events_links.split(','), item, i, "events_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownEventAddSource(e, i, "events_links")}
                                                                                                onChange={this.handleChangeEventAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Event Type <span class="mandatory">*</span></label>
                                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                                    style={{ height: '50px' }}
                                                                                    name="event_type"
                                                                                    value={el.event_type || ''}
                                                                                    onChange={this.handleChangeEventsDetails.bind(this, i)}
                                                                                >
                                                                                    <option value="0" >--Select Event Type--</option>
                                                                                    <option value="International" >International</option>
                                                                                    <option value="National" >National</option>
                                                                                    <option value="Local" >Local</option>
                                                                                </Input>
                                                                                <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                                                    {
                                                                                        (el.event_type != '') ? (<div></div>) : (this.state.eventTypeErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '1%', color: '#007bff' }}
                                                                                    onClick={this.EventTypeEventsSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.EventTypeEventsSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayEventType.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteEventAddSource(el.event_type_links.split(','), item, i, "event_type_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownEventAddSource(e, i, "event_type_links")}
                                                                                                onChange={this.handleChangeEventAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                    Event Description <span class="mandatory">*</span> </label>
                                                                                <input type="text" class="form-control"
                                                                                    autofocus="true"
                                                                                    id="input7" placeholder="Enter Event Description"
                                                                                    name="event_desc" value={el.event_desc || ''}
                                                                                    onChange={this.handleChangeEventsDetails.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.event_desc != '') ? (<div></div>) : (this.state.eventdescriptionErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.EventDescriptionSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.EventDescriptionSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayEventDescription.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteEventAddSource(el.event_desc_links.split(','), item, i, "event_desc_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownEventAddSource(e, i, "event_desc_links")}
                                                                                                onChange={this.handleChangeEventAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Event Topic <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Event Topic"
                                                                                    name="event_topic" value={el.event_topic || ''}
                                                                                    onChange={this.handleChangeEventsDetails.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.event_topic != '') ? (<div></div>) : (this.state.eventtopicErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.EventTopicSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.EventTopicSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayEventTopic.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteEventAddSource(el.event_topic_links.split(','), item, i, "event_topic_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownEventAddSource(e, i, "event_topic_links")}
                                                                                                onChange={this.handleChangeEventAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Source link <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Source link"
                                                                                    name="source_link" value={el.source_link || ''}
                                                                                    onChange={this.handleChangeEventsDetails.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.source_link != '') ? (<div></div>) : (this.state.sourcelinkEventErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.SourcelinEventkSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.SourcelinEventkSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arraySourceEvent.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteEventAddSource(el.source_link_links.split(','), item, i, "source_link_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownEventAddSource(e, i, "source_link_links")}
                                                                                                onChange={this.handleChangeEventAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Conditions </label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Conditions"
                                                                                    name="conditions" value={el.conditions || ''}
                                                                                    onChange={this.handleChangeEventsDetails.bind(this, i)}
                                                                                />
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.ConditionsEventsSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.ConditionsEventsSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayConditions.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteEventAddSource(el.conditions_links.split(','), item, i, "conditions_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownEventAddSource(e, i, "conditions_links")}
                                                                                                onChange={this.handleChangeEventAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Intervention </label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Intervention"
                                                                                    name="intervention" value={el.intervention || ''}
                                                                                    onChange={this.handleChangeEventsDetails.bind(this, i)}
                                                                                />
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.InterventionEventsSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.InterventionEventsSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayInterventation.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteEventAddSource(el.intervention_links.split(','), item, i, "intervention_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownEventAddSource(e, i, "intervention_links")}
                                                                                                onChange={this.handleChangeEventAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Date </label>
                                                                                <input type="date" class="form-control"
                                                                                    id="input7" placeholder="Enter Date"
                                                                                    name="date" value={el.date || ''}
                                                                                    onChange={this.handleChangeEventsDetails.bind(this, i)}
                                                                                />
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.DateEventsSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.DateEventsSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayDateEvent.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteEventAddSource(el.date_links.split(','), item, i, "date_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownEventAddSource(e, i, "date_links")}
                                                                                                onChange={this.handleChangeEventAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Session Type <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Session Type"
                                                                                    name="session_type" value={el.session_type || ''}
                                                                                    onChange={this.handleChangeEventsDetails.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.session_type != '') ? (<div></div>) : (this.state.sessiontypeErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.SessionTypeSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.SessionTypeSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arraySessionType.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteEventAddSource(el.session_type_links.split(','), item, i, "session_type_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownEventAddSource(e, i, "session_type_links")}
                                                                                                onChange={this.handleChangeEventAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Role <span class="mandatory">*</span></label>
                                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                                    style={{ height: '50px' }}
                                                                                    name="role_id"
                                                                                    value={el.role_id || ''}
                                                                                    onChange={this.handleChangeEventsDetails.bind(this, i)}
                                                                                >
                                                                                    <option value="0" >--Select Role--</option>
                                                                                    <option value="Event Chairperson" >Event Chairperson</option>
                                                                                    <option value="Regular" >Moderator/Session Chair</option>
                                                                                    <option value="Regular" >Regular</option>
                                                                                    <option value="Keynote Speaker" >Keynote Speaker</option>
                                                                                </Input>
                                                                                <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                                                    {
                                                                                        (el.role_id != '') ? (<div></div>) : (this.state.roleEventsErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '1%', color: '#007bff' }}
                                                                                    onClick={this.RoleEventsSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.RoleEventsSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayRoleEvent.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteEventAddSource(el.role_links.split(','), item, i, "role_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownEventAddSource(e, i, "role_links")}
                                                                                                onChange={this.handleChangeEventAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Event Sponsor </label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Event Sponsor"
                                                                                    name="event_sponsor" value={el.event_sponsor || ''}
                                                                                    onChange={this.handleChangeEventsDetails.bind(this, i)}
                                                                                />
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.EventSponsorSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.EventSponsorSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayEventSponser.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteEventAddSource(el.event_sponsor_links.split(','), item, i, "event_sponsor_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownEventAddSource(e, i, "event_sponsor_links")}
                                                                                                onChange={this.handleChangeEventAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Total Event Attendees </label>
                                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                                    style={{ height: '50px' }}
                                                                                    name="total_event_attendees"
                                                                                    value={el.total_event_attendees || ''}
                                                                                    onChange={this.handleChangeEventsDetails.bind(this, i)}
                                                                                >
                                                                                    <option value="0" >--Select Total Event Attendees--</option>
                                                                                    <option value="0-10" >0-10</option>
                                                                                    <option value="10-20" >10-20</option>
                                                                                    <option value="20-30" >20-30</option>
                                                                                    <option value="30-40" >30-40</option>
                                                                                    <option value="50+" >50+</option>
                                                                                </Input>
                                                                                <a class="float-right" style={{ marginTop: '1%', color: '#007bff' }}
                                                                                    onClick={this.TotalEventAttendeesSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.TotalEventAttendeesSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayTotalEventAttendies.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteEventAddSource(el.total_event_attendees_links.split(','), item, i, "total_event_attendees_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownEventAddSource(e, i, "total_event_attendees_links")}
                                                                                                onChange={this.handleChangeEventAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Country</label>
                                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                                    style={{ height: '50px' }}
                                                                                    name="country_id"
                                                                                    value={el.country_id || ''}
                                                                                    onChange={this.handleChangeEventsDetails.bind(this, i)}
                                                                                >
                                                                                    <option value="0" >--Select Country--</option>
                                                                                    {OptionsCountry}
                                                                                </Input>
                                                                                <a class="float-right" style={{ marginTop: '1%', color: '#007bff' }}
                                                                                    onClick={this.CountryEventsSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.CountryEventsSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayCountryEvent.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteEventAddSource(el.country_links.split(','), item, i, "country_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownEventAddSource(e, i, "country_links")}
                                                                                                onChange={this.handleChangeEventAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }


                                                                                {/* <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Manual weightage  <span class="mandatory">*</span></label>
                                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                                    style={{ height: '50px' }}
                                                                                    name="manual_weightage"
                                                                                    value={el.manual_weightage || ''}
                                                                                    onChange={this.handleChangeEventsDetails.bind(this, i)}
                                                                                >
                                                                                    <option value="0" >--Select Manual weightage--</option>
                                                                                    <option value="1" >1</option>
                                                                                    <option value="2" >2</option>
                                                                                    <option value="3" >3</option>
                                                                                    <option value="4" >4</option>
                                                                                    <option value="5" >5</option>
                                                                                </Input>
                                                                                <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                                                    {
                                                                                        (el.manual_weightage != '') ? (<div></div>) : (this.state.manualAdvEventDetails)
                                                                                    }
                                                                                </div> */}

                                                                                <hr />
                                                                                {/* Add More and remove */}

                                                                                {this.state.EventsDetails.length - 1 === i &&
                                                                                    <a style={{ marginTop: '5%', color: '#007bff' }}
                                                                                        onClick={this.addClickEventsDetails.bind(this)}>+ Add More Event Details</a>
                                                                                }
                                                                                {/* <input type="submit" value="Submit" onClick={this.handleSubmitEventsDetails.bind(this)}/> */}

                                                                            </div>
                                                                        )
                                                                    })

                                                                }
                                                            </form>
                                                        </section>
                                                    ) : (
                                                        <div>
                                                        </div>
                                                    )
                                                }

                                                {/* Grants form */}
                                                {
                                                    (this.state.GrantsFlag == true) ? (
                                                        <section style={{ marginBottom: '50%' }}>
                                                            <h3 class="mt-30">Grants 1</h3>

                                                            <form>
                                                                {
                                                                    this.state.Grants.map((el, i) => {
                                                                        var arrayCompanyGrants = [];
                                                                        var arrayTitleGrants = [];
                                                                        var arrayYearGrants = [];
                                                                        var arrayAmountGrants = [];
                                                                        var arrayCoInvestigator = [];

                                                                        if (el.company_links !== '') {
                                                                            arrayCompanyGrants = el.company_links.split(',')
                                                                            arrayCompanyGrants = arrayCompanyGrants.splice(1)
                                                                        }
                                                                        if (el.title_links !== '') {
                                                                            arrayTitleGrants = el.title_links.split(',')
                                                                            arrayTitleGrants = arrayTitleGrants.splice(1)
                                                                        }
                                                                        if (el.year_links !== '') {
                                                                            arrayYearGrants = el.year_links.split(',')
                                                                            arrayYearGrants = arrayYearGrants.splice(1)
                                                                        }
                                                                        if (el.amount_links !== '') {
                                                                            arrayAmountGrants = el.amount_links.split(',')
                                                                            arrayAmountGrants = arrayAmountGrants.splice(1)
                                                                        }
                                                                        if (el.co_investigator_links !== '') {
                                                                            arrayCoInvestigator = el.co_investigator_links.split(',')
                                                                            arrayCoInvestigator = arrayCoInvestigator.splice(1)
                                                                        }

                                                                        return (
                                                                            <div key={i}>
                                                                                {
                                                                                    (i == 0) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            <h3 class="mt-30">Grants {i + 1}</h3>
                                                                                            {this.state.Grants.length !== 1 &&
                                                                                                <a class="float-right" style={{ marginTop: '-8%', color: '#007bff' }}
                                                                                                    onClick={this.removeClickGrants.bind(this, i)}>x</a>
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Company/Organization <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    autofocus="true"
                                                                                    id="input7" placeholder="Enter Company/Organization"
                                                                                    name="company" value={el.company || ''}
                                                                                    onChange={this.handleChangeGrants.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.company != '') ? (<div></div>) : (this.state.companyErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.CompanySourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.CompanySource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayCompanyGrants.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteGrantsAddSource(el.company_links.split(','), item, i, "company_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownGrantsAddSource(e, i, "company_links")}
                                                                                                onChange={this.handleChangeGrantsAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Title <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Title"
                                                                                    name="title" value={el.title || ''}
                                                                                    onChange={this.handleChangeGrants.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.title != '') ? (<div></div>) : (this.state.titleGrantsErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.TitleGrantsSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.TitleGrantsSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayTitleGrants.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteGrantsAddSource(el.title_links.split(','), item, i, "title_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownGrantsAddSource(e, i, "title_links")}
                                                                                                onChange={this.handleChangeGrantsAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Year <span class="mandatory">*</span></label>
                                                                                <input type="date" class="form-control"
                                                                                    id="input7" placeholder="Enter Year"
                                                                                    name="year" value={el.year || ''}
                                                                                    onChange={this.handleChangeGrants.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.year != '') ? (<div></div>) : (this.state.yearGrantsErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.YearGrantsSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.YearGrantsSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayYearGrants.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteGrantsAddSource(el.year_links.split(','), item, i, "year_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownGrantsAddSource(e, i, "year_links")}
                                                                                                onChange={this.handleChangeGrantsAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Amount </label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Amount"
                                                                                    name="amount" value={el.amount || ''}
                                                                                    onChange={this.handleChangeGrants.bind(this, i)}
                                                                                />
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.AmountGrantsSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.AmountGrantsSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayAmountGrants.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteGrantsAddSource(el.amount_links.split(','), item, i, "amount_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownGrantsAddSource(e, i, "amount_links")}
                                                                                                onChange={this.handleChangeGrantsAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Co-Investigator </label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Co-Investigator"
                                                                                    name="co_investigator" value={el.co_investigator || ''}
                                                                                    onChange={this.handleChangeGrants.bind(this, i)}
                                                                                />
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.CoInvestigatorSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.CoInvestigatorSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayCoInvestigator.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteGrantsAddSource(el.co_investigator_links.split(','), item, i, "co_investigator_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownGrantsAddSource(e, i, "co_investigator_links")}
                                                                                                onChange={this.handleChangeGrantsAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <hr />
                                                                                {/* Add More and remove */}

                                                                                {this.state.Grants.length - 1 === i &&
                                                                                    <a style={{ marginTop: '5%', color: '#007bff' }}
                                                                                        onClick={this.addClickGrants.bind(this)}>+ Add More Grants</a>
                                                                                }
                                                                                {/* <input type="submit" value="Submit" onClick={this.handleSubmitGrants.bind(this)}/> */}

                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </form>
                                                        </section>
                                                    ) : (
                                                        <div>
                                                        </div>
                                                    )
                                                }

                                                {/* Guidlines form */}
                                                {
                                                    (this.state.GuidelinesFlag == true) ? (
                                                        <section style={{ marginBottom: '50%' }}>
                                                            <h3 class="mt-30">Guidelines 1</h3>
                                                            <form>
                                                                {
                                                                    this.state.Guidelines.map((el, i) => {
                                                                        var arraySpecialityGuidelines = [];
                                                                        var arraysunSpecialityGuidelines = [];
                                                                        var arrayProcedureGuidelines = [];
                                                                        var arrayYearGuidelines = [];
                                                                        var arrayOrganizatoinGuidelines = [];
                                                                        var arrayTypeofOrganizationGuidlines = [];
                                                                        var arrayTitleGuidelines = [];

                                                                        if (el.speciality_links !== '') {
                                                                            arraySpecialityGuidelines = el.speciality_links.split(',')
                                                                            arraySpecialityGuidelines = arraySpecialityGuidelines.splice(1)
                                                                        }
                                                                        if (el.sub_speciality_links !== '') {
                                                                            arraysunSpecialityGuidelines = el.sub_speciality_links.split(',')
                                                                            arraysunSpecialityGuidelines = arraysunSpecialityGuidelines.splice(1)
                                                                        }
                                                                        if (el.procedure_type_links !== '') {
                                                                            arrayProcedureGuidelines = el.procedure_type_links.split(',')
                                                                            arrayProcedureGuidelines = arrayProcedureGuidelines.splice(1)
                                                                        }
                                                                        if (el.year_links !== '') {
                                                                            arrayYearGuidelines = el.year_links.split(',')
                                                                            arrayYearGuidelines = arrayYearGuidelines.splice(1)
                                                                        }
                                                                        if (el.organization_links !== '') {
                                                                            arrayOrganizatoinGuidelines = el.organization_links.split(',')
                                                                            arrayOrganizatoinGuidelines = arrayOrganizatoinGuidelines.splice(1)
                                                                        }
                                                                        if (el.type_of_organization_links !== '') {
                                                                            arrayTypeofOrganizationGuidlines = el.type_of_organization_links.split(',')
                                                                            arrayTypeofOrganizationGuidlines = arrayTypeofOrganizationGuidlines.splice(1)
                                                                        }
                                                                        if (el.title_links !== '') {
                                                                            arrayTitleGuidelines = el.title_links.split(',')
                                                                            arrayTitleGuidelines = arrayTitleGuidelines.splice(1)
                                                                        }

                                                                        return (
                                                                            <div key={i}>
                                                                                {
                                                                                    (i == 0) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            <h3 class="mt-30">Guidelines {i + 1}</h3>
                                                                                            {this.state.Guidelines.length !== 1 &&
                                                                                                <a class="float-right" style={{ marginTop: '-8%', color: '#007bff' }}
                                                                                                    onClick={this.removeClickGuidelines.bind(this, i)}>x</a>
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                                                    Speciality <span class="mandatory">*</span></label>
                                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                                    style={{ height: '50px' }}
                                                                                    name="speciality_id"
                                                                                    value={el.speciality_id || ''}
                                                                                    onChange={async (e) => {
                                                                                        console.log(i)
                                                                                        console.log(e.target.value)
                                                                                        const { name, value } = e.target;

                                                                                        let Guidelines = [...this.state.Guidelines]
                                                                                        Guidelines[i] = { ...Guidelines[i], [name]: value }
                                                                                        this.setState({ Guidelines });

                                                                                        { this.getProcedureListGuidelines(e.target.value) }
                                                                                        { this.getSubSpecialityListGuidelines(e.target.value) }

                                                                                        await this.setState({
                                                                                            ProcedureTypeGuidelines: [],
                                                                                            SubSpecialityTypeGuidelines: [],
                                                                                            showProcedureSubSpecialityDropdownGuidelines: false,
                                                                                        })

                                                                                        Guidelines[i] = { ...Guidelines[i], procedure_type: '' }
                                                                                        Guidelines[i] = { ...Guidelines[i], sub_speciality: '' }
                                                                                        this.setState({ Guidelines });

                                                                                        { this.ProcedureDropdownGuidelines() }
                                                                                    }}
                                                                                >
                                                                                    <option value="0" >--Select Speciality--</option>
                                                                                    {OptionsSpeciality}
                                                                                </Input>
                                                                                <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                                                    {
                                                                                        (el.speciality_id != '') ? (<div></div>) : (this.state.specialityGuidelinesErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '2%', color: '#007bff' }}
                                                                                    onClick={this.SpecialityGuidlinesSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.SpecialityGuidlinesSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arraySpecialityGuidelines.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteGuidelinessAddSource(el.speciality_links.split(','), item, i, "speciality_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownGuidelinesAddSource(e, i, "speciality_links")}
                                                                                                onChange={this.handleChangeGuidelinesAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                {/* Sub Specilality dropdown starts here */}
                                                                                {
                                                                                    (this.state.showProcedureSubSpecialityDropdownGuidelines == false) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                                Sub Speciality Type <span class="mandatory">*</span></label>
                                                                                            <Multiselect
                                                                                                data={this.state.SubSpecialitydataGuidelines}
                                                                                                placeholder="--Select Sub Speciality--"
                                                                                                valueField='sub_speciality_type'
                                                                                                textField='sub_speciality_type'
                                                                                                // defaultValue={(el.sub_speciality).split(",")}
                                                                                                defaultValue={(el.sub_speciality != '') ? (el.sub_speciality.split(",")) : ([])}
                                                                                                onSelect={(valueField) => console.log(valueField)}

                                                                                                onChange={(event) => {
                                                                                                    console.log(event);
                                                                                                    var joined = [];
                                                                                                    console.log("length : " + event.length);
                                                                                                    if (event.length === 0) {
                                                                                                        this.setState({ SubSpecialityTypeGuidelines: [] });
                                                                                                    }
                                                                                                    event.map(async (data) => {
                                                                                                        console.log("data in eve map : " + JSON.stringify(data));
                                                                                                        joined = joined.concat(data.sub_speciality_type);
                                                                                                        console.log("data join : " + joined);
                                                                                                        await this.setState({
                                                                                                            SubSpecialityTypeGuidelines: joined
                                                                                                        });
                                                                                                        console.log("check this ", this.state.SubSpecialityTypeGuidelines)

                                                                                                        var output = this.state.SubSpecialityTypeGuidelines.map(function (item) {
                                                                                                            return item;
                                                                                                        });

                                                                                                        let list = [...this.state.Guidelines];
                                                                                                        list[i] = { ...list[i], ["sub_speciality"]: output.join(",") };

                                                                                                        this.setState({ Guidelines: list });
                                                                                                    })
                                                                                                }}
                                                                                            />
                                                                                            <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                                                                {
                                                                                                    (el.sub_speciality != '') ? (<div></div>) : (this.state.subSpecialityGuidelinesErr)
                                                                                                }
                                                                                            </div>
                                                                                            <a class="float-right" style={{ marginTop: '2%', color: '#007bff' }}
                                                                                                onClick={this.SubSpecialityGuidlinesSourceFun}>+ Add source</a>
                                                                                            {
                                                                                                (!this.state.SubSpecialityGuidlinesSource) ? (
                                                                                                    <div></div>
                                                                                                ) : (
                                                                                                    <div>
                                                                                                        {
                                                                                                            arraysunSpecialityGuidelines.flat().map((item) => (
                                                                                                                <div class="col-md-5" key={item}>
                                                                                                                    {item}
                                                                                                                    <button
                                                                                                                        type="button"
                                                                                                                        className="button"
                                                                                                                        onClick={() => this.handleDeleteGuidelinessAddSource(el.sub_speciality_links.split(','), item, i, "sub_speciality_links")}
                                                                                                                    >
                                                                                                                        &times;
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                            ))
                                                                                                        }
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            class="form-control-addsource"
                                                                                                            value={this.state.value}
                                                                                                            placeholder="Type or paste source link and press `Enter`"
                                                                                                            onKeyDown={e => this.handleKeyDownGuidelinesAddSource(e, i, "sub_speciality_links")}
                                                                                                            onChange={this.handleChangeGuidelinesAddSource}
                                                                                                        />
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                {/* Procedure Dropdown starts here */}
                                                                                {
                                                                                    (this.state.showProcedureSubSpecialityDropdownGuidelines == false) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                                Procedure Type <span class="mandatory">*</span></label>
                                                                                            <Multiselect
                                                                                                data={this.state.ProcedureDataForGuidelines}
                                                                                                placeholder="--Select Procedure Type--"
                                                                                                valueField='procedure_type'
                                                                                                textField='procedure_type'
                                                                                                // defaultValue={(el.procedure_type).split(",")}
                                                                                                defaultValue={(el.procedure_type != '') ? (el.procedure_type.split(",")) : ([])}
                                                                                                onSelect={(valueField) => console.log(valueField)}

                                                                                                onChange={(event) => {
                                                                                                    console.log(event);
                                                                                                    var joined = [];
                                                                                                    console.log("length : " + event.length);
                                                                                                    if (event.length === 0) {
                                                                                                        this.setState({ ProcedureTypeGuidelines: [] });
                                                                                                    }
                                                                                                    event.map(async (data) => {
                                                                                                        console.log("data in eve map : " + JSON.stringify(data));
                                                                                                        joined = joined.concat(data.procedure_type);
                                                                                                        console.log("data join : " + joined);
                                                                                                        await this.setState({
                                                                                                            ProcedureTypeGuidelines: joined
                                                                                                        });
                                                                                                        console.log("check this ", this.state.ProcedureTypeGuidelines)

                                                                                                        var output = this.state.ProcedureTypeGuidelines.map(function (item) {
                                                                                                            return item;
                                                                                                        });

                                                                                                        let list = [...this.state.Guidelines];
                                                                                                        list[i] = { ...list[i], ["procedure_type"]: output.join(",") };

                                                                                                        this.setState({ Guidelines: list });
                                                                                                    })
                                                                                                }}
                                                                                            />
                                                                                            <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                                                                {
                                                                                                    (el.procedure_type != '') ? (<div></div>) : (this.state.procedureGuidelinesErr)
                                                                                                }
                                                                                            </div>
                                                                                            <a class="float-right" style={{ marginTop: '2%', color: '#007bff' }}
                                                                                                onClick={this.ProcedureGuidlinesSourceFun}>+ Add source</a>
                                                                                            {
                                                                                                (!this.state.ProcedureGuidlinesSource) ? (
                                                                                                    <div></div>
                                                                                                ) : (
                                                                                                    <div>
                                                                                                        {
                                                                                                            arrayProcedureGuidelines.flat().map((item) => (
                                                                                                                <div class="col-md-5" key={item}>
                                                                                                                    {item}
                                                                                                                    <button
                                                                                                                        type="button"
                                                                                                                        className="button"
                                                                                                                        onClick={() => this.handleDeleteGuidelinessAddSource(el.procedure_type_links.split(','), item, i, "procedure_type_links")}
                                                                                                                    >
                                                                                                                        &times;
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                            ))
                                                                                                        }
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            class="form-control-addsource"
                                                                                                            value={this.state.value}
                                                                                                            placeholder="Type or paste source link and press `Enter`"
                                                                                                            onKeyDown={e => this.handleKeyDownGuidelinesAddSource(e, i, "procedure_type_links")}
                                                                                                            onChange={this.handleChangeGuidelinesAddSource}
                                                                                                        />
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        </div>

                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                    Year <span class="mandatory">*</span></label>
                                                                                <input type="date" class="form-control"
                                                                                    autofocus="true"
                                                                                    id="input7" placeholder="Enter Year"
                                                                                    name="year" value={el.year || ''}
                                                                                    onChange={this.handleChangeGuidelines.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.year != '') ? (<div></div>) : (this.state.yearGuidelinesErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.YearGuidlinesSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.YearGuidlinesSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayYearGuidelines.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteGuidelinessAddSource(el.year_links.split(','), item, i, "year_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownGuidelinesAddSource(e, i, "year_links")}
                                                                                                onChange={this.handleChangeGuidelinesAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Organization <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    autofocus="true"
                                                                                    id="input7" placeholder="Enter organization"
                                                                                    name="organization" value={el.organization || ''}
                                                                                    onChange={this.handleChangeGuidelines.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.organization != '') ? (<div></div>) : (this.state.companyGuidelinesErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.OrganizationGuidlinesSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.OrganizationGuidlinesSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayOrganizatoinGuidelines.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteGuidelinessAddSource(el.organization_links.split(','), item, i, "organization_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownGuidelinesAddSource(e, i, "organization_links")}
                                                                                                onChange={this.handleChangeGuidelinesAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Type Of Organization<span class="mandatory">*</span></label>
                                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                                    style={{ height: '50px' }}
                                                                                    name="type_of_organization"
                                                                                    value={el.type_of_organization || ''}
                                                                                    onChange={this.handleChangeGuidelines.bind(this, i)}
                                                                                >
                                                                                    <option value="0" >--Select Role--</option>
                                                                                    <option value="International" >International</option>
                                                                                    <option value="National" >National</option>
                                                                                    <option value="Local" >Local</option>
                                                                                </Input>
                                                                                <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                                                    {
                                                                                        (el.type_of_organization != '') ? (<div></div>) : (this.state.typeOfCompanyGuidelinesErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '1%', color: '#007bff' }}
                                                                                    onClick={this.TypeOfOrganizationGuidlinesSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.TypeOfOrganizationGuidlinesSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayTypeofOrganizationGuidlines.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteGuidelinessAddSource(el.type_of_organization_links.split(','), item, i, "type_of_organization_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownGuidelinesAddSource(e, i, "type_of_organization_links")}
                                                                                                onChange={this.handleChangeGuidelinesAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                {/* <label for="exampleFormControlTextarea1" style={{marginTop:'2%'}}>
                                                Company/Organization <span class="mandatory">*</span></label>
                                            <input type="text" class="form-control" 
                                                id="input7" placeholder="Enter Company/Organization" 
                                                name="company" value={el.company ||''}
                                                onChange={this.handleChangeGuidelines.bind(this, i)}   
                                            />
                                            <a class="float-right" style={{marginTop:'-2%', color:'#007bff'}}
                                            onClick={this.CompanyOrganizationGuidlinesSourceFun}>+ Add source</a>  
                                            {
                                                ( !this.state.CompanyOrganizationGuidlinesSource) ? (
                                                    <div></div>
                                                ) : (
                                                <div>
                                                <input type="text" class="form-control" 
                                                id="input7" placeholder="Source URL" 
                                                name="company_links" value={el.company_links ||''}
                                                onChange={this.handleChangeGuidelines.bind(this, i)}  
                                                />  
                                                </div>
                                                )
                                            } */}

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                    Title <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Title"
                                                                                    name="title" value={el.title || ''}
                                                                                    onChange={this.handleChangeGuidelines.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject">
                                                                                    {
                                                                                        (el.title != '') ? (<div></div>) : (this.state.titleGuidelinesErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.TitleGuidlinesSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.TitleGuidlinesSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayTitleGuidelines.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteGuidelinessAddSource(el.title_links.split(','), item, i, "title_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownGuidelinesAddSource(e, i, "title_links")}
                                                                                                onChange={this.handleChangeGuidelinesAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                {/* <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Manual weightage  <span class="mandatory">*</span></label>
                                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                                    style={{ height: '50px' }}
                                                                                    name="manual_weightage"
                                                                                    value={el.manual_weightage || ''}
                                                                                    onChange={this.handleChangeGuidelines.bind(this, i)}
                                                                                >
                                                                                    <option value="0" >--Select Manual weightage--</option>
                                                                                    <option value="1" >1</option>
                                                                                    <option value="2" >2</option>
                                                                                    <option value="3" >3</option>
                                                                                    <option value="4" >4</option>
                                                                                    <option value="5" >5</option>
                                                                                </Input>
                                                                                <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                                                    {
                                                                                        (el.manual_weightage != '') ? (<div></div>) : (this.state.manualGuidelines)
                                                                                    }
                                                                                </div> */}

                                                                                <hr />
                                                                                {/* Add More and remove */}

                                                                                {this.state.Guidelines.length - 1 === i &&
                                                                                    <a style={{ marginTop: '5%', color: '#007bff' }}
                                                                                        onClick={this.addClickGuidelines.bind(this)}>+ Add More Guidelines</a>
                                                                                }
                                                                                {/* <input type="submit" value="Submit" onClick={this.handleSubmitGuidelines.bind(this)}/> */}


                                                                            </div>
                                                                        )
                                                                    })

                                                                }
                                                            </form>
                                                        </section>
                                                    ) : (
                                                        <div>
                                                        </div>
                                                    )
                                                }

                                                {/* Hospital Affiliations form */}
                                                {
                                                    (this.state.HospitalAffiliationsFlag == true) ? (
                                                        <section style={{ marginBottom: '50%' }}>
                                                            <h3 class="mt-30">Hospital Affiliations 1</h3>
                                                            <form>
                                                                {
                                                                    this.state.HospitalAffiliations.map((el, i) => {
                                                                        var arrayHospital = [];
                                                                        var arrayCityHospital = [];
                                                                        var arrayStateHospital = [];
                                                                        var arrayCountryHospital = [];

                                                                        if (el.hospital_links !== '') {
                                                                            arrayHospital = el.hospital_links.split(',')
                                                                            arrayHospital = arrayHospital.splice(1)
                                                                        }
                                                                        if (el.city_links !== '') {
                                                                            arrayCityHospital = el.city_links.split(',')
                                                                            arrayCityHospital = arrayCityHospital.splice(1)
                                                                        }
                                                                        if (el.state_links !== '') {
                                                                            arrayStateHospital = el.state_links.split(',')
                                                                            arrayStateHospital = arrayStateHospital.splice(1)
                                                                        }
                                                                        if (el.country_links !== '') {
                                                                            arrayCountryHospital = el.country_links.split(',')
                                                                            arrayCountryHospital = arrayCountryHospital.splice(1)
                                                                        }

                                                                        return (
                                                                            <div key={i}>
                                                                                {
                                                                                    (i == 0) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            <h3 class="mt-30">Hospital Affiliations {i + 1}</h3>
                                                                                            {this.state.HospitalAffiliations.length !== 1 &&
                                                                                                <a class="float-right" style={{ marginTop: '-8%', color: '#007bff' }}
                                                                                                    onClick={this.removeClickHospitalAffiliations.bind(this, i)}>x</a>
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Hospitals <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    autofocus="true"
                                                                                    id="input7" placeholder="Enter Hospitals"
                                                                                    name="hospital" value={el.hospital || ''}
                                                                                    onChange={this.handleChangeHospitalAffiliations.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject">
                                                                                    {
                                                                                        (el.hospital != '') ? (<div></div>) : (this.state.hospitalErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-1%', color: '#007bff' }}
                                                                                    onClick={this.HospitalSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.HospitalSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayHospital.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteHospitalAddSource(el.hospital_links.split(','), item, i, "hospital_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownHospitalAddSource(e, i, "hospital_links")}
                                                                                                onChange={this.handleChangeHospitalAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Country <span class="mandatory">*</span></label>
                                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                                    style={{ height: '50px' }}
                                                                                    name="country_id"
                                                                                    value={el.country_id || ''}
                                                                                    onChange={this.handleChangeHospitalAffiliations.bind(this, i)}
                                                                                >
                                                                                    <option value="0" >--Select Country--</option>
                                                                                    {OptionsCountry}
                                                                                </Input>
                                                                                <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                                                    {
                                                                                        (el.country_id != '') ? (<div></div>) : (this.state.countryHospitalErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '1%', color: '#007bff' }}
                                                                                    onClick={this.CountryHospitalAffSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.CountryHospitalAffSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayCountryHospital.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteHospitalAddSource(el.country_links.split(','), item, i, "country_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownHospitalAddSource(e, i, "country_links")}
                                                                                                onChange={this.handleChangeHospitalAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                    State</label>
                                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                                    style={{ height: '50px' }}
                                                                                    name="state_id"
                                                                                    value={el.state_id || ''}
                                                                                    onChange={this.handleChangeHospitalAffiliations.bind(this, i)}
                                                                                >
                                                                                    <option value="0" >--Select State--</option>
                                                                                    {OptionStateHospitalAff}
                                                                                </Input>
                                                                                <a class="float-right" style={{ marginTop: '1%', color: '#007bff' }}
                                                                                    onClick={this.StateHospitalAffSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.StateHospitalAffSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayStateHospital.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteHospitalAddSource(el.state_links.split(','), item, i, "state_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownHospitalAddSource(e, i, "state_links")}
                                                                                                onChange={this.handleChangeHospitalAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                    City</label>
                                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                                    style={{ height: '50px' }}
                                                                                    name="city_id"
                                                                                    value={el.city_id || ''}
                                                                                    onChange={this.handleChangeHospitalAffiliations.bind(this, i)}
                                                                                >
                                                                                    <option value="0" >--Select City--</option>
                                                                                    {OptionCityHospitalAff}
                                                                                </Input>
                                                                                <a class="float-right" style={{ marginTop: '1%', color: '#007bff' }}
                                                                                    onClick={this.CityHospitalAffSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.CityHospitalAffSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayCityHospital.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteHospitalAddSource(el.city_links.split(','), item, i, "city_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownHospitalAddSource(e, i, "city_links")}
                                                                                                onChange={this.handleChangeHospitalAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <hr />
                                                                                {/* Add More and remove */}

                                                                                {this.state.HospitalAffiliations.length - 1 === i &&
                                                                                    <a style={{ marginTop: '5%', color: '#007bff' }}
                                                                                        onClick={this.addClickHospitalAffiliations.bind(this)}>+ Add More Hospital Affiliations</a>
                                                                                }
                                                                                {/* <input type="submit" value="Submit" onClick={this.handleSubmitHospitalAffiliations.bind(this)}/> */}

                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </form>
                                                        </section>
                                                    ) : (
                                                        <div>
                                                        </div>
                                                    )
                                                }

                                                {/* Interactions form */}
                                                {
                                                    (this.state.InteractionsFlag == true) ? (
                                                        <section style={{ marginBottom: '50%' }}>
                                                            <h3 class="mt-30">Interactions 1</h3>
                                                            <form>
                                                                {
                                                                    this.state.Interactions.map((el, i) => {
                                                                        var arrayDateInteractions = [];
                                                                        var arrayOpinionLeader = [];
                                                                        var arrayEnteredBy = [];
                                                                        var arrayProduct = [];
                                                                        var arrayTypeInteractions = [];
                                                                        var arrayMode = [];
                                                                        var arrayTime = [];
                                                                        var arrayActivityTool = [];
                                                                        var arrayCommentsInteractions = [];

                                                                        if (el.date_links !== '') {
                                                                            arrayDateInteractions = el.date_links.split(',')
                                                                            arrayDateInteractions = arrayDateInteractions.splice(1)
                                                                        }
                                                                        if (el.opinion_leader_links !== '') {
                                                                            arrayOpinionLeader = el.opinion_leader_links.split(',')
                                                                            arrayOpinionLeader = arrayOpinionLeader.splice(1)
                                                                        }
                                                                        if (el.entered_by_links !== '') {
                                                                            arrayEnteredBy = el.entered_by_links.split(',')
                                                                            arrayEnteredBy = arrayEnteredBy.splice(1)
                                                                        }
                                                                        if (el.product_links !== '') {
                                                                            arrayProduct = el.product_links.split(',')
                                                                            arrayProduct = arrayProduct.splice(1)
                                                                        }
                                                                        if (el.type_links !== '') {
                                                                            arrayTypeInteractions = el.type_links.split(',')
                                                                            arrayTypeInteractions = arrayTypeInteractions.splice(1)
                                                                        }
                                                                        if (el.mode_links !== '') {
                                                                            arrayMode = el.mode_links.split(',')
                                                                            arrayMode = arrayMode.splice(1)
                                                                        }
                                                                        if (el.time_links !== '') {
                                                                            arrayTime = el.time_links.split(',')
                                                                            arrayTime = arrayTime.splice(1)
                                                                        }
                                                                        if (el.activity_or_tool_used_links !== '') {
                                                                            arrayActivityTool = el.activity_or_tool_used_links.split(',')
                                                                            arrayActivityTool = arrayActivityTool.splice(1)
                                                                        }
                                                                        if (el.comments_links !== '') {
                                                                            arrayCommentsInteractions = el.comments_links.split(',')
                                                                            arrayCommentsInteractions = arrayCommentsInteractions.splice(1)
                                                                        }

                                                                        return (
                                                                            <div key={i}>
                                                                                {
                                                                                    (i == 0) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            <h3 class="mt-30">Interactions {i + 1}</h3>
                                                                                            {this.state.Interactions.length !== 1 &&
                                                                                                <a class="float-right" style={{ marginTop: '-8%', color: '#007bff' }}
                                                                                                    onClick={this.removeClickInteractions.bind(this, i)}>x</a>
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Date <span class="mandatory">*</span></label>
                                                                                <input type="date" class="form-control"
                                                                                    autofocus="true"
                                                                                    id="input7" placeholder="Enter Date"
                                                                                    name="date" value={el.date || ''}
                                                                                    onChange={this.handleChangeInteractions.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.date != '') ? (<div></div>) : (this.state.dateInteractionErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.DateInteractionsSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.DateInteractionsSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayDateInteractions.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteInteractionAddSource(el.date_links.split(','), item, i, "date_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownInteractionAddSource(e, i, "date_links")}
                                                                                                onChange={this.handleChangeInteractionAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Opinion Leader <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Opinion Leader"
                                                                                    name="opinion_leader" value={el.opinion_leader || ''}
                                                                                    onChange={this.handleChangeInteractions.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.opinion_leader != '') ? (<div></div>) : (this.state.opinionleaderErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.OpinionLeaderSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.OpinionLeaderSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayOpinionLeader.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteInteractionAddSource(el.opinion_leader_links.split(','), item, i, "opinion_leader_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownInteractionAddSource(e, i, "opinion_leader_links")}
                                                                                                onChange={this.handleChangeInteractionAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Entered By<span class="mandatory">*</span></label>
                                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                                    style={{ height: '50px' }}
                                                                                    name="entered_by"
                                                                                    value={el.entered_by || ''}
                                                                                    onChange={this.handleChangeInteractions.bind(this, i)}
                                                                                >
                                                                                    <option value="0" >--Select Employee--</option>
                                                                                    {OptionsEmployee}
                                                                                </Input>
                                                                                <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                                                    {
                                                                                        (el.entered_by != '') ? (<div></div>) : (this.state.enteredbyErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '1%', color: '#007bff' }}
                                                                                    onClick={this.EnteredBySourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.EnteredBySource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayEnteredBy.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteInteractionAddSource(el.entered_by_links.split(','), item, i, "entered_by_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownInteractionAddSource(e, i, "entered_by_links")}
                                                                                                onChange={this.handleChangeInteractionAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                    Product/Topic<span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Product/Topic"
                                                                                    name="product" value={el.product || ''}
                                                                                    onChange={this.handleChangeInteractions.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.product != '') ? (<div></div>) : (this.state.producttopicErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.ProductTopicSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.ProductTopicSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayProduct.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteInteractionAddSource(el.product_links.split(','), item, i, "product_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownInteractionAddSource(e, i, "product_links")}
                                                                                                onChange={this.handleChangeInteractionAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Type <span class="mandatory">*</span></label>
                                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                                    style={{ height: '50px' }}
                                                                                    name="type"
                                                                                    value={el.type || ''}
                                                                                    onChange={this.handleChangeInteractions.bind(this, i)}
                                                                                >
                                                                                    <option value="0" >--Select Type--</option>
                                                                                    <option value="Face to Face" >Face to Face</option>
                                                                                    <option value="Web Conference" >Web Conference</option>
                                                                                    <option value="Tele Conference" >Tele Conference</option>
                                                                                    <option value="Email" >Email</option>

                                                                                </Input>
                                                                                <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                                                    {
                                                                                        (el.type != '') ? (<div></div>) : (this.state.typeInteractionErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '1%', color: '#007bff' }}
                                                                                    onClick={this.TypeInteractionSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.TypeInteractionSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayTypeInteractions.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteInteractionAddSource(el.type_links.split(','), item, i, "type_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownInteractionAddSource(e, i, "type_links")}
                                                                                                onChange={this.handleChangeInteractionAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                    Mode <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Mode"
                                                                                    name="mode" value={el.mode || ''}
                                                                                    onChange={this.handleChangeInteractions.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.mode != '') ? (<div></div>) : (this.state.modeInteractionErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.ModeInteractionSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.ModeInteractionSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayMode.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteInteractionAddSource(el.mode_links.split(','), item, i, "mode_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownInteractionAddSource(e, i, "mode_links")}
                                                                                                onChange={this.handleChangeInteractionAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Time <span class="mandatory">*</span></label>
                                                                                <input type="time" class="form-control"
                                                                                    id="input7" placeholder="Enter Time"
                                                                                    name="time" value={el.time || ''}
                                                                                    onChange={this.handleChangeInteractions.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.time != '') ? (<div></div>) : (this.state.timeInteractionErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.TimeInteractionSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.TimeInteractionSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayTime.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteInteractionAddSource(el.time_links.split(','), item, i, "time_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownInteractionAddSource(e, i, "time_links")}
                                                                                                onChange={this.handleChangeInteractionAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Activity/Tool Used </label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Activity/Tool Used"
                                                                                    name="activity_or_tool_used" value={el.activity_or_tool_used || ''}
                                                                                    onChange={this.handleChangeInteractions.bind(this, i)}
                                                                                />
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.ActivityToolUsedSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.ActivityToolUsedSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayActivityTool.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteInteractionAddSource(el.activity_or_tool_used_links.split(','), item, i, "activity_or_tool_used_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownInteractionAddSource(e, i, "activity_or_tool_used_links")}
                                                                                                onChange={this.handleChangeInteractionAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Comments/Keywords </label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Comments/Keywords"
                                                                                    name="comments" value={el.comments || ''}
                                                                                    onChange={this.handleChangeInteractions.bind(this, i)}
                                                                                />
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.CommentsKeywordsSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.CommentsKeywordsSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayCommentsInteractions.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteInteractionAddSource(el.comments_links.split(','), item, i, "comments_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownInteractionAddSource(e, i, "comments_links")}
                                                                                                onChange={this.handleChangeInteractionAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <hr />
                                                                                {/* Add More and remove */}

                                                                                {this.state.Interactions.length - 1 === i &&
                                                                                    <a style={{ marginTop: '5%', color: '#007bff' }}
                                                                                        onClick={this.addClickInteractions.bind(this)}>+ Add More Interactions</a>
                                                                                }
                                                                                {/* <input type="submit" value="Submit" onClick={this.handleSubmitInteractions.bind(this)}/> */}

                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </form>
                                                        </section>
                                                    ) : (
                                                        <div>
                                                        </div>
                                                    )
                                                }

                                                {/* Press Item form */}
                                                {
                                                    (this.state.PressItemFlag == true) ? (
                                                        <section style={{ marginBottom: '50%' }}>
                                                            <h3 class="mt-30">Press Item 1 </h3>
                                                            <form>
                                                                {
                                                                    this.state.PressItem.map((el, i) => {
                                                                        var arrayTitlePress = [];
                                                                        var arraySourcePress = [];
                                                                        var arrayDatePress = [];

                                                                        if (el.title_links !== '') {
                                                                            arrayTitlePress = el.title_links.split(',')
                                                                            arrayTitlePress = arrayTitlePress.splice(1)
                                                                        }
                                                                        if (el.source_link_links !== '') {
                                                                            arraySourcePress = el.source_link_links.split(',')
                                                                            arraySourcePress = arraySourcePress.splice(1)
                                                                        }
                                                                        if (el.date_links !== '') {
                                                                            arrayDatePress = el.date_links.split(',')
                                                                            arrayDatePress = arrayDatePress.splice(1)
                                                                        }

                                                                        return (
                                                                            <div key={i}>
                                                                                {
                                                                                    (i == 0) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            <h3 class="mt-30">Press Item {i + 1}</h3>
                                                                                            {this.state.PressItem.length !== 1 &&
                                                                                                <a class="float-right" style={{ marginTop: '-8%', color: '#007bff' }}
                                                                                                    onClick={this.removeClickPressItem.bind(this, i)}>x</a>
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Title <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    autofocus="true"
                                                                                    id="input7" placeholder="Enter Title"
                                                                                    name="title" value={el.title || ''}
                                                                                    onChange={this.handleChangePressItem.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.title != '') ? (<div></div>) : (this.state.titlePressErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.TitlePressItemSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.TitlePressItemSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayTitlePress.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeletePressAddSource(el.title_links.split(','), item, i, "title_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownPressAddSource(e, i, "title_links")}
                                                                                                onChange={this.handleChangePressAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Source Link <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Source Link"
                                                                                    name="source_link" value={el.source_link || ''}
                                                                                    onChange={this.handleChangePressItem.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.source_link != '') ? (<div></div>) : (this.state.sourcelinkPressErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.SourceLinkPressItemSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.SourceLinkPressItemSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arraySourcePress.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeletePressAddSource(el.source_link_links.split(','), item, i, "source_link_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownPressAddSource(e, i, "source_link_links")}
                                                                                                onChange={this.handleChangePressAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Date <span class="mandatory">*</span></label>
                                                                                <input type="date" class="form-control"
                                                                                    id="input7" placeholder="Enter Date"
                                                                                    name="date" value={el.date || ''}
                                                                                    onChange={this.handleChangePressItem.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.date != '') ? (<div></div>) : (this.state.datePressErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.DatePressItemSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.DatePressItemSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayDatePress.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeletePressAddSource(el.date_links.split(','), item, i, "date_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownPressAddSource(e, i, "date_links")}
                                                                                                onChange={this.handleChangePressAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <hr />
                                                                                {/* Add More and remove */}

                                                                                {this.state.PressItem.length - 1 === i &&
                                                                                    <a style={{ marginTop: '5%', color: '#007bff' }}
                                                                                        onClick={this.addClickPressItem.bind(this)}>+ Add More Press Item</a>
                                                                                }
                                                                                {/* <input type="submit" value="Submit" onClick={this.handleSubmitPressItem.bind(this)}/> */}

                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </form>
                                                        </section>
                                                    ) : (
                                                        <div>
                                                        </div>
                                                    )
                                                }

                                                {/* Presentations form */}
                                                {
                                                    (this.state.PresentationsFlag == true) ? (
                                                        <section style={{ marginBottom: '50%' }}>
                                                            <h3 class="mt-30">Presentations 1</h3>
                                                            <form>
                                                                {
                                                                    this.state.Presentation.map((el, i) => {
                                                                        var arrayTitlePresentation = [];
                                                                        var arrayTypePresentation = [];
                                                                        var arraySponserPresentation = [];
                                                                        var arrayLocationPresentation = [];
                                                                        var arrayDatePresentation = [];

                                                                        if (el.title_links !== '') {
                                                                            arrayTitlePresentation = el.title_links.split(',')
                                                                            arrayTitlePresentation = arrayTitlePresentation.splice(1)
                                                                        }
                                                                        if (el.type_links !== '') {
                                                                            arrayTypePresentation = el.type_links.split(',')
                                                                            arrayTypePresentation = arrayTypePresentation.splice(1)
                                                                        }
                                                                        if (el.sponsor_links !== '') {
                                                                            arraySponserPresentation = el.sponsor_links.split(',')
                                                                            arraySponserPresentation = arraySponserPresentation.splice(1)
                                                                        }
                                                                        if (el.location_links !== '') {
                                                                            arrayLocationPresentation = el.location_links.split(',')
                                                                            arrayLocationPresentation = arrayLocationPresentation.splice(1)
                                                                        }
                                                                        if (el.date_links !== '') {
                                                                            arrayDatePresentation = el.date_links.split(',')
                                                                            arrayDatePresentation = arrayDatePresentation.splice(1)
                                                                        }

                                                                        return (
                                                                            <div key={i}>
                                                                                {
                                                                                    (i == 0) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            <h3 class="mt-30">Presentations {i + 1}</h3>
                                                                                            {this.state.Presentation.length !== 1 &&
                                                                                                <a class="float-right" style={{ marginTop: '-8%', color: '#007bff' }}
                                                                                                    onClick={this.removeClickPresentation.bind(this, i)}>x</a>
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Title <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    autofocus="true"
                                                                                    id="input7" placeholder="Enter Title"
                                                                                    name="title" value={el.title || ''}
                                                                                    onChange={this.handleChangePresentation.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.title != '') ? (<div></div>) : (this.state.titlePresentationErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.TitlePresentationSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.TitlePresentationSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayTitlePresentation.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeletePresentationAddSource(el.title_links.split(','), item, i, "title_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownPresentationAddSource(e, i, "title_links")}
                                                                                                onChange={this.handleChangePresentationAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Type <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Type"
                                                                                    name="type" value={el.type || ''}
                                                                                    onChange={this.handleChangePresentation.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.type != '') ? (<div></div>) : (this.state.typePresentationErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.TypePresentationSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.TypePresentationSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayTypePresentation.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeletePresentationAddSource(el.type_links.split(','), item, i, "type_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownPresentationAddSource(e, i, "type_links")}
                                                                                                onChange={this.handleChangePresentationAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Sponsor <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Sponsor"
                                                                                    name="sponsor" value={el.sponsor || ''}
                                                                                    onChange={this.handleChangePresentation.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.sponsor != '') ? (<div></div>) : (this.state.sponsorPresentationErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.SponsorPresentationSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.SponsorPresentationSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arraySponserPresentation.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeletePresentationAddSource(el.sponsor_links.split(','), item, i, "sponsor_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownPresentationAddSource(e, i, "sponsor_links")}
                                                                                                onChange={this.handleChangePresentationAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Location <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Location"
                                                                                    name="location" value={el.location || ''}
                                                                                    onChange={this.handleChangePresentation.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.location != '') ? (<div></div>) : (this.state.locationPresentationErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.LocationPresentationSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.LocationPresentationSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayLocationPresentation.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeletePresentationAddSource(el.location_links.split(','), item, i, "location_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownPresentationAddSource(e, i, "location_links")}
                                                                                                onChange={this.handleChangePresentationAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Date <span class="mandatory">*</span></label>
                                                                                <input type="date" class="form-control"
                                                                                    id="input7" placeholder="Enter Date"
                                                                                    name="date" value={el.date || ''}
                                                                                    onChange={this.handleChangePresentation.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.date != '') ? (<div></div>) : (this.state.datePresentationErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.DatePresentationSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.DatePresentationSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayDatePresentation.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeletePresentationAddSource(el.date_links.split(','), item, i, "date_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownPresentationAddSource(e, i, "date_links")}
                                                                                                onChange={this.handleChangePresentationAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <hr />
                                                                                {/* Add More and remove */}

                                                                                {this.state.Presentation.length - 1 === i &&
                                                                                    <a style={{ marginTop: '5%', color: '#007bff' }}
                                                                                        onClick={this.addClickPresentation.bind(this)}>+ Add More Presentations</a>
                                                                                }
                                                                                {/* <input type="submit" value="Submit" onClick={this.handleSubmitPresentation.bind(this)}/> */}

                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </form>
                                                        </section>
                                                    ) : (
                                                        <div>
                                                        </div>
                                                    )
                                                }

                                                {/* Publication Details form */}
                                                {
                                                    (this.state.PublicationDetailsFlag == true) ? (
                                                        <section style={{ marginBottom: '50%' }}>
                                                            <h3 class="mt-30">Publication Details 1</h3>
                                                            <form>
                                                                {
                                                                    this.state.PublicationDetails.map((el, i) => {
                                                                        var arraySpecialityPublication = [];
                                                                        var arraySubSpecialityPublication = [];
                                                                        var arrayProcedurePublication = [];
                                                                        var arrayJournel = [];
                                                                        var arrayTypeOfJournel = [];
                                                                        var arrayPublicationTitle = [];
                                                                        var arrayDatePublication = [];
                                                                        var arraySourcePublication = [];
                                                                        var arrayPositionPublication = [];
                                                                        var arrayCoAuthor = [];
                                                                        var arrayKeyWords = [];

                                                                        if (el.speciality_links !== '') {
                                                                            arraySpecialityPublication = el.speciality_links.split(',')
                                                                            arraySpecialityPublication = arraySpecialityPublication.splice(1)
                                                                        }
                                                                        if (el.sub_speciality_links !== '') {
                                                                            arraySubSpecialityPublication = el.sub_speciality_links.split(',')
                                                                            arraySubSpecialityPublication = arraySubSpecialityPublication.splice(1)
                                                                        }
                                                                        if (el.procedure_type_links !== '') {
                                                                            arrayProcedurePublication = el.procedure_type_links.split(',')
                                                                            arrayProcedurePublication = arrayProcedurePublication.splice(1)
                                                                        }
                                                                        if (el.journal_links !== '') {
                                                                            arrayJournel = el.journal_links.split(',')
                                                                            arrayJournel = arrayJournel.splice(1)
                                                                        }
                                                                        if (el.type_of_journal_links !== '') {
                                                                            arrayTypeOfJournel = el.type_of_journal_links.split(',')
                                                                            arrayTypeOfJournel = arrayTypeOfJournel.splice(1)
                                                                        }
                                                                        if (el.publication_title_links !== '') {
                                                                            arrayPublicationTitle = el.publication_title_links.split(',')
                                                                            arrayPublicationTitle = arrayPublicationTitle.splice(1)
                                                                        }
                                                                        if (el.date_links !== '') {
                                                                            arrayDatePublication = el.date_links.split(',')
                                                                            arrayDatePublication = arrayDatePublication.splice(1)
                                                                        }
                                                                        if (el.source_link_links !== '') {
                                                                            arraySourcePublication = el.source_link_links.split(',')
                                                                            arraySourcePublication = arraySourcePublication.splice(1)
                                                                        }
                                                                        if (el.position_links !== '') {
                                                                            arrayPositionPublication = el.position_links.split(',')
                                                                            arrayPositionPublication = arrayPositionPublication.splice(1)
                                                                        }
                                                                        if (el.co_author_links !== '') {
                                                                            arrayCoAuthor = el.co_author_links.split(',')
                                                                            arrayCoAuthor = arrayCoAuthor.splice(1)
                                                                        }
                                                                        if (el.key_words_links !== '') {
                                                                            arrayKeyWords = el.key_words_links.split(',')
                                                                            arrayKeyWords = arrayKeyWords.splice(1)
                                                                        }

                                                                        return (
                                                                            <div key={i}>
                                                                                {
                                                                                    (i == 0) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            <h3 class="mt-30">Publication Details {i + 1}</h3>
                                                                                            {this.state.PublicationDetails.length !== 1 &&
                                                                                                <a class="float-right" style={{ marginTop: '-8%', color: '#007bff' }}
                                                                                                    onClick={this.removeClickPublicationDetails.bind(this, i)}>x</a>
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}>
                                                                                    Speciality <span class="mandatory">*</span></label>
                                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                                    style={{ height: '50px' }}
                                                                                    name="speciality_id"
                                                                                    value={el.speciality_id || ''}
                                                                                    onChange={async (e) => {
                                                                                        console.log(i)
                                                                                        console.log(e.target.value)
                                                                                        const { name, value } = e.target;

                                                                                        let PublicationDetails = [...this.state.PublicationDetails]
                                                                                        PublicationDetails[i] = { ...PublicationDetails[i], [name]: value }
                                                                                        this.setState({ PublicationDetails });

                                                                                        { this.getProcedureListPublicationDetails(e.target.value) }
                                                                                        { this.getSubSpecialityListPublicationDetails(e.target.value) }

                                                                                        await this.setState({
                                                                                            ProcedureTypePublicationDetails: [],
                                                                                            SubSpecialityTypePublicationDetails: [],
                                                                                            showProcedureSubSpecialityDropdownPublicationDetails: false,
                                                                                        })

                                                                                        PublicationDetails[i] = { ...PublicationDetails[i], procedure_type: '' }
                                                                                        PublicationDetails[i] = { ...PublicationDetails[i], sub_speciality: '' }
                                                                                        this.setState({ PublicationDetails });

                                                                                        { this.ProcedureDropdownPublicationDetails() }
                                                                                    }}
                                                                                >
                                                                                    <option value="0" >--Select Speciality--</option>
                                                                                    {OptionsSpeciality}
                                                                                </Input>
                                                                                <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                                                    {
                                                                                        (el.speciality_id != '') ? (<div></div>) : (this.state.specialityPublicationErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '1%', color: '#007bff' }}
                                                                                    onClick={this.SpecialityPublicationSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.SpecialityPublicationSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arraySpecialityPublication.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeletePublicationAddSource(el.speciality_links.split(','), item, i, "speciality_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownPublicationAddSource(e, i, "speciality_links")}
                                                                                                onChange={this.handleChangePublicationAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                {/* Sub Specilality dropdown starts here */}
                                                                                {
                                                                                    (this.state.showProcedureSubSpecialityDropdownPublicationDetails == false) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                                Sub Speciality Type <span class="mandatory">*</span></label>
                                                                                            <Multiselect
                                                                                                data={this.state.SubSpecialitydataPublicationDetails}
                                                                                                placeholder="--Select Sub Speciality--"
                                                                                                valueField='sub_speciality_type'
                                                                                                textField='sub_speciality_type'
                                                                                                // defaultValue={(el.sub_speciality).split(",")}
                                                                                                defaultValue={(el.sub_speciality != '') ? (el.sub_speciality.split(",")) : ([])}
                                                                                                onSelect={(valueField) => console.log(valueField)}

                                                                                                onChange={(event) => {
                                                                                                    console.log(event);
                                                                                                    var joined = [];
                                                                                                    console.log("length : " + event.length);
                                                                                                    if (event.length === 0) {
                                                                                                        this.setState({ SubSpecialityTypePublicationDetails: [] });
                                                                                                    }
                                                                                                    event.map(async (data) => {
                                                                                                        console.log("data in eve map : " + JSON.stringify(data));
                                                                                                        joined = joined.concat(data.sub_speciality_type);
                                                                                                        console.log("data join : " + joined);
                                                                                                        await this.setState({
                                                                                                            SubSpecialityTypePublicationDetails: joined
                                                                                                        });
                                                                                                        console.log("check this ", this.state.SubSpecialityTypePublicationDetails)

                                                                                                        var output = this.state.SubSpecialityTypePublicationDetails.map(function (item) {
                                                                                                            return item;
                                                                                                        });

                                                                                                        let list = [...this.state.PublicationDetails];
                                                                                                        list[i] = { ...list[i], ["sub_speciality"]: output.join(",") };

                                                                                                        this.setState({ PublicationDetails: list });
                                                                                                    })
                                                                                                }}
                                                                                            />
                                                                                            <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                                                                {
                                                                                                    (el.sub_speciality != '') ? (<div></div>) : (this.state.subSpecialityPublicationErr)
                                                                                                }
                                                                                            </div>
                                                                                            <a class="float-right" style={{ marginTop: '1%', color: '#007bff' }}
                                                                                                onClick={this.SubSpecialityPublicationSourceFun}>+ Add source</a>
                                                                                            {
                                                                                                (!this.state.SubSpecialityPublicationSource) ? (
                                                                                                    <div></div>
                                                                                                ) : (
                                                                                                    <div>
                                                                                                        {
                                                                                                            arraySubSpecialityPublication.flat().map((item) => (
                                                                                                                <div class="col-md-5" key={item}>
                                                                                                                    {item}
                                                                                                                    <button
                                                                                                                        type="button"
                                                                                                                        className="button"
                                                                                                                        onClick={() => this.handleDeletePublicationAddSource(el.sub_speciality_links.split(','), item, i, "sub_speciality_links")}
                                                                                                                    >
                                                                                                                        &times;
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                            ))
                                                                                                        }
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            class="form-control-addsource"
                                                                                                            value={this.state.value}
                                                                                                            placeholder="Type or paste source link and press `Enter`"
                                                                                                            onKeyDown={e => this.handleKeyDownPublicationAddSource(e, i, "sub_speciality_links")}
                                                                                                            onChange={this.handleChangePublicationAddSource}
                                                                                                        />
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                {/* Procedure Dropdown starts here */}
                                                                                {
                                                                                    (this.state.showProcedureSubSpecialityDropdownPublicationDetails == false) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                                Procedure Type <span class="mandatory">*</span></label>
                                                                                            <Multiselect
                                                                                                data={this.state.ProcedureDataForPublicationDetails}
                                                                                                placeholder="--Select Procedure Type--"
                                                                                                valueField='procedure_type'
                                                                                                textField='procedure_type'
                                                                                                // defaultValue={(el.procedure_type).split(",")}
                                                                                                defaultValue={(el.procedure_type != '') ? (el.procedure_type.split(",")) : ([])}
                                                                                                onSelect={(valueField) => console.log(valueField)}

                                                                                                onChange={(event) => {
                                                                                                    console.log(event);
                                                                                                    var joined = [];
                                                                                                    console.log("length : " + event.length);
                                                                                                    if (event.length === 0) {
                                                                                                        this.setState({ ProcedureTypePublicationDetails: [] });
                                                                                                    }
                                                                                                    event.map(async (data) => {
                                                                                                        console.log("data in eve map : " + JSON.stringify(data));
                                                                                                        joined = joined.concat(data.procedure_type);
                                                                                                        console.log("data join : " + joined);
                                                                                                        await this.setState({
                                                                                                            ProcedureTypePublicationDetails: joined
                                                                                                        });
                                                                                                        console.log("check this ", this.state.ProcedureTypePublicationDetails)

                                                                                                        var output = this.state.ProcedureTypePublicationDetails.map(function (item) {
                                                                                                            return item;
                                                                                                        });

                                                                                                        let list = [...this.state.PublicationDetails];
                                                                                                        list[i] = { ...list[i], ["procedure_type"]: output.join(",") };

                                                                                                        this.setState({ PublicationDetails: list });
                                                                                                    })
                                                                                                }}
                                                                                            />
                                                                                            <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                                                                {
                                                                                                    (el.procedure_type != '') ? (<div></div>) : (this.state.procedurePublicationErr)
                                                                                                }
                                                                                            </div>
                                                                                            <a class="float-right" style={{ marginTop: '1%', color: '#007bff' }}
                                                                                                onClick={this.ProcedurePublicationSourceFun}>+ Add source</a>
                                                                                            {
                                                                                                (!this.state.ProcedurePublicationSource) ? (
                                                                                                    <div></div>
                                                                                                ) : (
                                                                                                    <div>
                                                                                                        {
                                                                                                            arrayProcedurePublication.flat().map((item) => (
                                                                                                                <div class="col-md-5" key={item}>
                                                                                                                    {item}
                                                                                                                    <button
                                                                                                                        type="button"
                                                                                                                        className="button"
                                                                                                                        onClick={() => this.handleDeletePublicationAddSource(el.procedure_type_links.split(','), item, i, "procedure_type_links")}
                                                                                                                    >
                                                                                                                        &times;
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                            ))
                                                                                                        }
                                                                                                        <input
                                                                                                            type="text"
                                                                                                            class="form-control-addsource"
                                                                                                            value={this.state.value}
                                                                                                            placeholder="Type or paste source link and press `Enter`"
                                                                                                            onKeyDown={e => this.handleKeyDownPublicationAddSource(e, i, "procedure_type_links")}
                                                                                                            onChange={this.handleChangePublicationAddSource}
                                                                                                        />
                                                                                                    </div>
                                                                                                )
                                                                                            }

                                                                                        </div>

                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                    Journal <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    autofocus="true"
                                                                                    id="input7" placeholder="Enter Journal"
                                                                                    name="journal" value={el.journal || ''}
                                                                                    onChange={this.handleChangePublicationDetails.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.journal != '') ? (<div></div>) : (this.state.journalErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.JournalSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.JournalSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayJournel.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeletePublicationAddSource(el.journal_links.split(','), item, i, "journal_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownPublicationAddSource(e, i, "journal_links")}
                                                                                                onChange={this.handleChangePublicationAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Type of Journal/Publication <span class="mandatory">*</span></label>
                                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                                    style={{ height: '50px' }}
                                                                                    name="type_of_journal"
                                                                                    value={el.type_of_journal || ''}
                                                                                    onChange={this.handleChangePublicationDetails.bind(this, i)}
                                                                                >
                                                                                    <option value="0" >--Select Type of Journal/Publication--</option>
                                                                                    <option value="Global" >Global</option>
                                                                                    <option value="Local/National" >Local/National</option>
                                                                                </Input>
                                                                                <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                                                    {
                                                                                        (el.type_of_journal != '') ? (<div></div>) : (this.state.typeOfJournelErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '1%', color: '#007bff' }}
                                                                                    onClick={this.TypeOfJournelPublicationSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.TypeOfJournelPublicationSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayTypeOfJournel.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeletePublicationAddSource(el.type_of_journal_links.split(','), item, i, "type_of_journal_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownPublicationAddSource(e, i, "type_of_journal_links")}
                                                                                                onChange={this.handleChangePublicationAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                    Publication Title <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Publication Title"
                                                                                    name="publication_title" value={el.publication_title || ''}
                                                                                    onChange={this.handleChangePublicationDetails.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.publication_title != '') ? (<div></div>) : (this.state.publicationtitleErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.PublicationTitleSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.PublicationTitleSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayPublicationTitle.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeletePublicationAddSource(el.publication_title_links.split(','), item, i, "publication_title_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownPublicationAddSource(e, i, "publication_title_links")}
                                                                                                onChange={this.handleChangePublicationAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Date <span class="mandatory">*</span></label>
                                                                                <input type="date" class="form-control"
                                                                                    id="input7" placeholder="Enter Date"
                                                                                    name="date" value={el.date || ''}
                                                                                    onChange={this.handleChangePublicationDetails.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.date != '') ? (<div></div>) : (this.state.datePublicationErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.DatePublicationSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.DatePublicationSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayDatePublication.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeletePublicationAddSource(el.date_links.split(','), item, i, "date_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownPublicationAddSource(e, i, "date_links")}
                                                                                                onChange={this.handleChangePublicationAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Source Link <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Source Link"
                                                                                    name="source_link" value={el.source_link || ''}
                                                                                    onChange={this.handleChangePublicationDetails.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.source_link != '') ? (<div></div>) : (this.state.sourcelinkPublicationErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.SourceLinkPublicationSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.SourceLinkPublicationSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arraySourcePublication.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeletePublicationAddSource(el.source_link_links.split(','), item, i, "source_link_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownPublicationAddSource(e, i, "source_link_links")}
                                                                                                onChange={this.handleChangePublicationAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Position <span class="mandatory">*</span></label>
                                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                                    style={{ height: '50px' }}
                                                                                    name="position"
                                                                                    value={el.position || ''}
                                                                                    onChange={this.handleChangePublicationDetails.bind(this, i)}
                                                                                >
                                                                                    <option value="0" >--Select Position--</option>
                                                                                    <option value="First Author" >First Author</option>
                                                                                    <option value="Second Author" >Second Author</option>
                                                                                    <option value="Last Author" >Last Author</option>
                                                                                </Input>
                                                                                <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                                                    {
                                                                                        (el.position != '') ? (<div></div>) : (this.state.positionPublicationErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '1%', color: '#007bff' }}
                                                                                    onClick={this.PositionPublicationSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.PositionPublicationSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayPositionPublication.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeletePublicationAddSource(el.position_links.split(','), item, i, "position_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownPublicationAddSource(e, i, "position_links")}
                                                                                                onChange={this.handleChangePublicationAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Co-Author <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Co-Author"
                                                                                    name="co_author" value={el.co_author || ''}
                                                                                    onChange={this.handleChangePublicationDetails.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.co_author != '') ? (<div></div>) : (this.state.coauthorPublicationErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.CoAuthorPublicationSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.CoAuthorPublicationSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayCoAuthor.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeletePublicationAddSource(el.co_author_links.split(','), item, i, "co_author_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownPublicationAddSource(e, i, "co_author_links")}
                                                                                                onChange={this.handleChangePublicationAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Key Words <span class="mandatory">*</span></label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Key Words"
                                                                                    name="key_words" value={el.key_words || ''}
                                                                                    onChange={this.handleChangePublicationDetails.bind(this, i)}
                                                                                />
                                                                                <div className="loginErrCreateProject" >
                                                                                    {
                                                                                        (el.key_words != '') ? (<div></div>) : (this.state.keywordsErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.KeyWordsPublicationSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.KeyWordsPublicationSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayKeyWords.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeletePublicationAddSource(el.key_words_links.split(','), item, i, "key_words_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownPublicationAddSource(e, i, "key_words_links")}
                                                                                                onChange={this.handleChangePublicationAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                {/* <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Manual weightage  <span class="mandatory">*</span></label>
                                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                                    style={{ height: '50px' }}
                                                                                    name="manual_weightage"
                                                                                    value={el.manual_weightage || ''}
                                                                                    onChange={this.handleChangePublicationDetails.bind(this, i)}
                                                                                >
                                                                                    <option value="0" >--Select Manual weightage--</option>
                                                                                    <option value="1" >1</option>
                                                                                    <option value="2" >2</option>
                                                                                    <option value="3" >3</option>
                                                                                    <option value="4" >4</option>
                                                                                    <option value="5" >5</option>
                                                                                </Input>
                                                                                <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                                                    {
                                                                                        (el.manual_weightage != '') ? (<div></div>) : (this.state.manualPublication)
                                                                                    }
                                                                                </div> */}

                                                                                <hr />
                                                                                {/* Add More and remove */}

                                                                                {this.state.PublicationDetails.length - 1 === i &&
                                                                                    <a style={{ marginTop: '5%', color: '#007bff' }}
                                                                                        onClick={this.addClickPublicationDetails.bind(this)}>+ Add More Publication Details</a>
                                                                                }
                                                                                {/* <input type="submit" value="Submit" onClick={this.handleSubmitPublicationDetails.bind(this)}/> */}

                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </form>
                                                        </section>
                                                    ) : (
                                                        <div>
                                                        </div>
                                                    )
                                                }

                                                {/* Sunshine Payment form */}
                                                {
                                                    (this.state.SunshinePaymentFlag == true) ? (
                                                        <section style={{ marginBottom: '50%' }}>
                                                            <h3 class="mt-30">Sunshine Payment 1</h3>
                                                            <form>
                                                                {
                                                                    this.state.SunshinePayment.map((el, i) => {
                                                                        var arrayDateSunshine = [];
                                                                        var arrayTypeSunshine = [];
                                                                        var arrayDevices = [];
                                                                        var arrayLocationSunshine = [];
                                                                        var arrayAmountSunshine = [];
                                                                        var arrayPaymentType = [];
                                                                        var arrayOLAddress = [];
                                                                        var arraySpecialitySunshine = [];
                                                                        var arrayNPILinks = [];

                                                                        if (el.date_links !== '') {
                                                                            arrayDateSunshine = el.date_links.split(',')
                                                                            arrayDateSunshine = arrayDateSunshine.splice(1)
                                                                        }
                                                                        if (el.type_links !== '') {
                                                                            arrayTypeSunshine = el.type_links.split(',')
                                                                            arrayTypeSunshine = arrayTypeSunshine.splice(1)
                                                                        }
                                                                        if (el.devices_links !== '') {
                                                                            arrayDevices = el.devices_links.split(',')
                                                                            arrayDevices = arrayDevices.splice(1)
                                                                        }
                                                                        if (el.location_links !== '') {
                                                                            arrayLocationSunshine = el.location_links.split(',')
                                                                            arrayLocationSunshine = arrayLocationSunshine.splice(1)
                                                                        }
                                                                        if (el.amount_links !== '') {
                                                                            arrayAmountSunshine = el.amount_links.split(',')
                                                                            arrayAmountSunshine = arrayAmountSunshine.splice(1)
                                                                        }
                                                                        if (el.payment_type_links !== '') {
                                                                            arrayPaymentType = el.payment_type_links.split(',')
                                                                            arrayPaymentType = arrayPaymentType.splice(1)
                                                                        }
                                                                        if (el.OL_address_links !== '') {
                                                                            arrayOLAddress = el.OL_address_links.split(',')
                                                                            arrayOLAddress = arrayOLAddress.splice(1)
                                                                        }
                                                                        if (el.speciality_type_links !== '') {
                                                                            arraySpecialitySunshine = el.speciality_type_links.split(',')
                                                                            arraySpecialitySunshine = arraySpecialitySunshine.splice(1)
                                                                        }
                                                                        if (el.NPI_links !== '') {
                                                                            arrayNPILinks = el.NPI_links.split(',')
                                                                            arrayNPILinks = arrayNPILinks.splice(1)
                                                                        }

                                                                        return (
                                                                            <div key={i}>
                                                                                {
                                                                                    (i == 0) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            <h3 class="mt-30">Sunshine Payment {i + 1}</h3>
                                                                                            {this.state.SunshinePayment.length !== 1 &&
                                                                                                <a class="float-right" style={{ marginTop: '-8%', color: '#007bff' }}
                                                                                                    onClick={this.removeClickSunshinePayment.bind(this, i)}>x</a>
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Date </label>
                                                                                <input type="date" class="form-control"
                                                                                    autofocus="true"
                                                                                    id="input7" placeholder="Enter Date"
                                                                                    name="date" value={el.date || ''}
                                                                                    onChange={this.handleChangeSunshinePayment.bind(this, i)}
                                                                                />
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.DateSunshineSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.DateSunshineSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayDateSunshine.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteSunshineAddSource(el.date_links.split(','), item, i, "date_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownSunshineAddSource(e, i, "date_links")}
                                                                                                onChange={this.handleChangeSunshineAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Type</label>
                                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                                    style={{ height: '50px' }}
                                                                                    name="type"
                                                                                    value={el.type || ''}
                                                                                    onChange={this.handleChangeSunshinePayment.bind(this, i)}
                                                                                >
                                                                                    <option value="0" >--Select Type--</option>
                                                                                    <option value="Consulting Fees" >Consulting Fees</option>
                                                                                    <option value="Advisory Board" >Advisory Board</option>
                                                                                    <option value="Honararia" >Honararia</option>
                                                                                    <option value="Gifts " >Gifts </option>
                                                                                    <option value="Entairnment" >Entairnment</option>
                                                                                    <option value="Food and Beverage" >Food and Beverage</option>
                                                                                    <option value="Travel and Accomodation" >Travel and Accomodation</option>
                                                                                    <option value="Educational Grants" >Educational Grants</option>
                                                                                    <option value="Research Grant" >Research Grant</option>
                                                                                    <option value="Charitable Contribution" >Charitable Contribution</option>
                                                                                    <option value="Payment to third party" >Payment to third party</option>
                                                                                    <option value="Royalty/Licence" >Royalty/Licence</option>
                                                                                    <option value="Speaker/Faculty for CE" >Speaker/Faculty for CE</option>
                                                                                    <option value="Onership/Investment Interesrt" >Onership/Investment Interesrt</option>
                                                                                    <option value="Other Services" >Other Services</option>
                                                                                </Input>
                                                                                <a class="float-right" style={{ marginTop: '1%', color: '#007bff' }}
                                                                                    onClick={this.TypeSunshineSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.TypeSunshineSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayTypeSunshine.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteSunshineAddSource(el.type_links.split(','), item, i, "type_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownSunshineAddSource(e, i, "type_links")}
                                                                                                onChange={this.handleChangeSunshineAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                    Drug/Devices </label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Drug/Devices"
                                                                                    name="devices" value={el.devices || ''}
                                                                                    onChange={this.handleChangeSunshinePayment.bind(this, i)}
                                                                                />
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.DrugDevicesSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.DrugDevicesSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayDevices.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteSunshineAddSource(el.devices_links.split(','), item, i, "devices_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownSunshineAddSource(e, i, "devices_links")}
                                                                                                onChange={this.handleChangeSunshineAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Location </label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Location"
                                                                                    name="location" value={el.location || ''}
                                                                                    onChange={this.handleChangeSunshinePayment.bind(this, i)}
                                                                                />
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.LocationSunshineSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.LocationSunshineSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayLocationSunshine.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteSunshineAddSource(el.location_links.split(','), item, i, "location_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownSunshineAddSource(e, i, "location_links")}
                                                                                                onChange={this.handleChangeSunshineAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Amount </label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter Amount"
                                                                                    name="amount" value={el.amount || ''}
                                                                                    onChange={this.handleChangeSunshinePayment.bind(this, i)}
                                                                                />
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.AmountSunshineSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.AmountSunshineSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayAmountSunshine.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteSunshineAddSource(el.amount_links.split(','), item, i, "amount_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownSunshineAddSource(e, i, "amount_links")}
                                                                                                onChange={this.handleChangeSunshineAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Payment Type  <span class="mandatory">*</span> </label>
                                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                                    style={{ height: '50px' }}
                                                                                    name="payment_type"
                                                                                    value={el.payment_type || ''}
                                                                                    onChange={this.handleChangeSunshinePayment.bind(this, i)}
                                                                                >
                                                                                    <option value="0" >--Select Type--</option>
                                                                                    <option value="Cash" >Cash</option>
                                                                                    <option value="Bank Transfer" >Bank Transfer</option>
                                                                                    <option value="Debit Card" >Debit Card</option>
                                                                                    <option value="Credit Card" >Credit Card</option>
                                                                                    <option value="PayPal" >PayPal</option>
                                                                                </Input>
                                                                                <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                                                    {
                                                                                        (el.payment_type != '') ? (<div></div>) : (this.state.paymenttypeErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '1%', color: '#007bff' }}
                                                                                    onClick={this.PaymentTypeSunshineSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.PaymentTypeSunshineSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayPaymentType.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteSunshineAddSource(el.payment_type_links.split(','), item, i, "payment_type_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownSunshineAddSource(e, i, "payment_type_links")}
                                                                                                onChange={this.handleChangeSunshineAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                    OL Address </label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter OL Address"
                                                                                    name="OL_address" value={el.OL_address || ''}
                                                                                    onChange={this.handleChangeSunshinePayment.bind(this, i)}
                                                                                />
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.OLAddressSunshineSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.OLAddressSunshineSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayOLAddress.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteSunshineAddSource(el.OL_address_links.split(','), item, i, "OL_address_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownSunshineAddSource(e, i, "OL_address_links")}
                                                                                                onChange={this.handleChangeSunshineAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                    Speciality Type  <span class="mandatory">*</span> </label>
                                                                                <Input type="select" id="roleSelect" className="form-control"
                                                                                    style={{ height: '50px' }}
                                                                                    name="speciality_type_id"
                                                                                    value={el.speciality_type_id || ''}
                                                                                    onChange={this.handleChangeSunshinePayment.bind(this, i)}
                                                                                >
                                                                                    <option value="0" >--Select Type--</option>
                                                                                    {OptionsSpeciality}
                                                                                </Input>
                                                                                <div className="loginErrCreateProject" style={{ marginTop: '1.5%' }}>
                                                                                    {
                                                                                        (el.speciality_type_id != '') ? (<div></div>) : (this.state.specialitytypeSunshineErr)
                                                                                    }
                                                                                </div>
                                                                                <a class="float-right" style={{ marginTop: '1%', color: '#007bff' }}
                                                                                    onClick={this.SpecialitySunshineSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.SpecialitySunshineSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arraySpecialitySunshine.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteSunshineAddSource(el.speciality_type_links.split(','), item, i, "speciality_type_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownSunshineAddSource(e, i, "speciality_type_links")}
                                                                                                onChange={this.handleChangeSunshineAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                    NPI# </label>
                                                                                <input type="text" class="form-control"
                                                                                    id="input7" placeholder="Enter NPI"
                                                                                    name="NPI" value={el.NPI || ''}
                                                                                    onChange={this.handleChangeSunshinePayment.bind(this, i)}
                                                                                />
                                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                                    onClick={this.NPISunshineSourceFun}>+ Add source</a>
                                                                                {
                                                                                    (!this.state.NPISunshineSource) ? (
                                                                                        <div></div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            {
                                                                                                arrayNPILinks.flat().map((item) => (
                                                                                                    <div class="col-md-5" key={item}>
                                                                                                        {item}
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="button"
                                                                                                            onClick={() => this.handleDeleteSunshineAddSource(el.NPI_links.split(','), item, i, "NPI_links")}
                                                                                                        >
                                                                                                            &times;
                                                                                                        </button>
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                            <input
                                                                                                type="text"
                                                                                                class="form-control-addsource"
                                                                                                value={this.state.value}
                                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                                onKeyDown={e => this.handleKeyDownSunshineAddSource(e, i, "NPI_links")}
                                                                                                onChange={this.handleChangeSunshineAddSource}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <hr />
                                                                                {/* Add More and remove */}

                                                                                {this.state.SunshinePayment.length - 1 === i &&
                                                                                    <a style={{ marginTop: '5%', color: '#007bff' }}
                                                                                        onClick={this.addClickSunshinePayment.bind(this)}>+ Add More Sunshine Payment</a>
                                                                                }
                                                                                {/* <input type="submit" value="Submit" onClick={this.handleSubmitSunshinePayment.bind(this)}/> */}

                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </form>
                                                        </section>
                                                    ) : (
                                                        <div>
                                                        </div>
                                                    )
                                                }

                                                {/* Procedure Potential form */}
                                                {
                                                    (this.state.ProcedurePotentialFlag == true) ? (
                                                        <section style={{ marginBottom: '50%' }}>
                                                            <h3 class="mt-30">Procedure Potential</h3>
                                                            <div className="loginErrCreateProject">{this.state.procedurepotentialErr}</div>
                                                            <form>
                                                                <div onChange={this.setProcedurePotential.bind(this)} style={{ marginTop: '5%' }}>
                                                                    <label> <input type="radio" value="High" name="ProcedurePotential" /> High</label>
                                                                    <label> <input type="radio" value="Medium" name="ProcedurePotential" /> Medium</label>
                                                                    <label> <input type="radio" value="Low" name="ProcedurePotential" /> Low</label>
                                                                </div>
                                                                <a class="float-right" style={{ marginTop: '-2%', color: '#007bff' }}
                                                                    onClick={this.ProcedurePotentialSourceFun}>+ Add source</a>
                                                                {
                                                                    (!this.state.ProcedurePotentialSource) ? (
                                                                        <div></div>
                                                                    ) : (
                                                                        <div>
                                                                            {this.state.itemsProcedurePotential.map((itemsProcedurePotential) => (
                                                                                <div className="tag-item" key={itemsProcedurePotential}>
                                                                                    {itemsProcedurePotential}
                                                                                    <button
                                                                                        type="button"
                                                                                        className="button"
                                                                                        onClick={() => this.handleDeleteProcedurePotential(itemsProcedurePotential)}
                                                                                    >
                                                                                        &times;
                                                                                    </button>
                                                                                </div>
                                                                            ))}
                                                                            <input
                                                                                class="form-control-addsource-Personal"
                                                                                value={this.state.valuePersonalInfoAddSource}
                                                                                placeholder="Type or paste source link and press `Enter`"
                                                                                onKeyDown={this.handleKeyDownProcedurePotential}
                                                                                onChange={this.handleChangePersonalInfoAddSource}
                                                                            />
                                                                        </div>
                                                                    )
                                                                }
                                                            </form>
                                                        </section>
                                                    ) : (
                                                        <div>
                                                        </div>
                                                    )
                                                }




                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="action-btn-container">
                                <div class="container">
                                    <div class="col-md-7 offset-md-3">
                                        <button class="cetas-button" onClick={this.SaveAsDraftFunc}>Save as Draft</button>
                                        <button class="cetas-button" data-toggle="modal" data-target="#edit-item"
                                            onClick={this.SendToQUFunc}>Send to QC</button>

                                    </div>
                                </div>
                            </div>
                            <footer class="footer-fixed">
                                <div class="cetas-footer">
                                    <div class="container">
                                        <p>All rights reserved. www.cetashealthcare.com</p>
                                    </div>

                                </div>
                            </footer>
                        </div>
                    </div>
                </div>
            </body>
        );

    }
}

export default KolProfileForm;