import React, { Component } from 'react';
import Chart from "react-apexcharts";
import { displayName } from 'react-widgets/lib/Calendar';

var _seed = 42;
Math.random = function () {
  _seed = _seed * 16807 % 2147483647;
  return (_seed - 1) / 2147483646;
};

class ApexChart extends Component {
  constructor(props) {
    super(props);
    console.log('props of apex chart', this.props.Data);
    this.state = {
      series: [{
        name: this.props.Data.year.last_year,
        type: 'column',
        data: [
          this.props.Data.kol_guidlines.count_last_year,
          this.props.Data.kol_clinical_trial_details.count_last_year,
          this.props.Data.kol_publication_details.count_last_year,
          this.props.Data.kol_advisory_board.count_last_year,
          this.props.Data.kol_event_details.count_last_year,
        ]
      }, {
        name: this.props.Data.year.mid_year,
        type: 'column',
        data: [
          this.props.Data.kol_guidlines.count_mid_year,
          this.props.Data.kol_clinical_trial_details.count_mid_year,
          this.props.Data.kol_publication_details.count_mid_year,
          this.props.Data.kol_advisory_board.count_mid_year,
          this.props.Data.kol_event_details.count_mid_year,
        ]
      }, {
        name: this.props.Data.year.latest_year,
        type: 'column',
        data: [
          this.props.Data.kol_guidlines.count_latest_year,
          this.props.Data.kol_clinical_trial_details.count_latest_year,
          this.props.Data.kol_publication_details.count_latest_year,
          this.props.Data.kol_advisory_board.count_latest_year,
          this.props.Data.kol_event_details.count_latest_year
        ]
      }, {
        name: 'Growth',
        type: 'line',
        data: [
          this.props.Data.Growth.kol_guidelines,
          this.props.Data.Growth.kol_clinical_trial_details,
          this.props.Data.Growth.kol_publication_details,
          this.props.Data.Growth.kol_advisory_board,
          this.props.Data.Growth.kol_events_details
        ]
      }],
      options: {
        chart: {
          height: 300,
          width: 400,
          // width: Number || String,
          type: 'line',
          stacked: false
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          width: [0.5, 1, 1, 4]
        },
        title: {
          text: 'Engagement Summary',
          align: 'left',
          height: 13,
          offsetX: 110
        },
        xaxis: {
          categories: ['Clinical Guidelines', 'Investigations', 'Publications', 'Ad Boards', 'Speakerships'],
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#252525'
            },
            labels: {
              style: {
                colors: '#000000',
              }
            },
            title: {
              text: "Counts",
              style: {
                color: '#252525',
              }
            },
            tooltip: {
              enabled: true
            }
          },
          {
            seriesName: this.props.Data.year.last_year,
            opposite: true,
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
              // color: '#00E396'
            },
            labels: {
              show: false,
            },
            title: {
              // text: "2019",
              style: {
                color: '#00E396',
              }
            },
          },
          {
            // seriesName: '2020',
            opposite: true,
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
              color: '#00E396'
            },
            labels: {
              show: false,
            },
            title: {
              // text: "2020",
              style: {
                color: '#00E396',
              }
            },
          }, {
            seriesName: 'Growth',
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#ff536c'
            },
            labels: {
              style: {
                colors: '#ff536c',
              },
            },
            title: {
              text: "Growth",
              style: {
                color: '#ff536c',
              }
            }
          },
        ],
        tooltip: {
          followCursor: true,
          enabled: true,
          // fixed: {
          //   enabled: true,
          //   position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
          //   offsetY: 30,
          //   offsetX: 60
          // },
        },
        legend: {
          horizontalAlign: 'left',
          offsetX: 40
        }
      },
    };
  }

  render() {
    return (
      <div>
        <div id="chart">
          <Chart options={this.state.options} series={this.state.series} type="line" height={300} />
        </div>
        <div id="html-dist"></div>
      </div>
    );
  }
}
export default ApexChart;