import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button } from 'reactstrap';
import filterFactory, { textFilter, customFilter, selectFilter, Comparator, FILTER_TYPES } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import LoadingBar from "react-top-loading-bar";

import EmployeeHeader from './EmployeeHeader';

class EmployeeDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kol_id: '',
      employee_name: '',
      // ProfileBySession: this.props.location.state.ProfileBySession,

      RedirectFlag: false,

      //Flag to show form of selected drawer item
      PersonalInformationFlag: true,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,

      // to store data fetch from api
      PersonalInformation: {},
      WorkDetails: [],
      Biography: {},
      ProfessionalMemberships: [],
      Education: [],
      AdvisoryBoard: [],
      AwardsHonours: [],
      BooksMonographs: [],
      Commitees: [],
      ClinicalTrialDetails: [],
      EventsDetails: [],
      Grants: [],
      Guidelines: [],
      HospitalAffiliations: [],
      Interactions: [],
      PressItem: [],
      Presentation: [],
      PublicationDetails: [],
      SunshinePayment: [],

      profile_photo: '',
      LanguageSpoken: [],
      BiographyResume: '',
      profile_photo_for_edit: '',
      BiographyResume_edit: '',
      BiographyResumeFileName: '',
      FinalResumeName: '',

      CountryListData: [],
      StateData: [],
      CityData: [],
      EmployeeListData: [],

      // all columns 
      columnsWorkDetails: [
        {
          dataField: 'current_place_of_work',
          text: 'Current Place of Work',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            // return { textAlign: 'center', width: '10%' };
            return { width: '10%' };
          },
        },
        {
          dataField: 'designation',
          text: 'Designation',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'department',
          text: 'Department',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        // new
        {
          dataField: 'from_year',
          text: 'From Year',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
              return { width: '10%' };
          },
        },
        // new
        {
            dataField: 'to_year',
            text: 'To Year',
            // align: 'center',
            headerStyle: (colum, colIndex) => {
                return { width: '10%' };
            },
        },
      ],

      columnsProfessionalMemberships: [
        {
          dataField: 'organization',
          text: 'Organization',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'organization_type',
          text: 'Organization Type',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'location',
          text: 'Location',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'position',
          text: 'Position',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '8%' };
          },
        },
        {
          dataField: 'tenure',
          text: 'Tenure',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '8%' };
          },
        },
        {
          dataField: 'organization_website',
          text: 'Organization Website',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'source_link',
          text: 'Source Link',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
      ],

      columnsEducation: [
        {
          dataField: 'institute_name',
          text: 'Alma  Mater',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'institute_type',
          text: 'Alma  Mater Type',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'degrees',
          text: 'Qualification',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          // dataField: 'speciality_id',
          dataField: 'speciality_type',
          text: 'Speciality',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'years_attended_from',
          text: 'Years Attended From',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'years_attended_to',
          text: 'Years Attended To',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'source_link',
          text: 'Institution Link',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
      ],

      columnsAdvisoryBord: [
        {
          dataField: 'speciality_type',
          text: 'Speciality Type',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        // new
        {
          dataField: 'to_year',
          text: 'To Year',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
              return { width: '10%' };
          },
        },
        {
          dataField: 'sub_specialty_id',
          text: 'Sub Speciality Type',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'procedure_type_id',
          text: 'Procedure Type',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'year',
          text: 'Year',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'company',
          text: 'Company',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'type_of_company',
          text: 'Type of Company',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'advisory_board',
          text: 'Advisory Board',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'topic',
          text: 'Topic',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'role_id',
          text: 'Role',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        // {
        //     dataField: 'manual_weightage',
        //     text: 'Manual Weightage',
        //     // align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //     return { width: '15%' };
        //     },
        // },
      ],

      columnsAwardsHonours: [
        {
          dataField: 'year',
          text: 'Year',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '5%' };
          },
        },
        // new
        {
          dataField: 'to_year',
          text: 'To Year',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
              return { width: '5%' };
          },
        },
        {
          dataField: 'awards_honours',
          text: 'Awards Honours',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '5%' };
          },
        },
        {
          dataField: 'types',
          text: 'Types of Awards & Honours',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '5%' };
          },
        },
      ],

      columnsBooksMonographs: [
        {
          dataField: 'year',
          text: 'Year',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'title',
          text: 'Title',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'author',
          text: 'Author',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'publication',
          text: 'Publication',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'edition',
          text: 'Edition',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '8%' };
          },
        },
        {
          dataField: 'pages',
          text: 'Pages',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '8%' };
          },
        },
        {
          dataField: 'editors',
          text: 'Editors',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '20%' };
          },
        },
      ],

      columnsCommitees: [
        {
          dataField: 'year',
          text: 'Year',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        // new
        {
          dataField: 'body_org_name',
          text: 'Body/Org Name',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
              return { width: '10%' };
          },
        },
        {
          dataField: 'committee',
          text: 'Committee',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'role_id',
          text: 'Role',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
      ],

      columnsClinicalTrialDetails: [
        {
          dataField: 'speciality_type',
          text: 'Speciality Type',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'sub_speciality',
          text: 'Sub Speciality Type',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'procedure_type',
          text: 'Procedure Type',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'study_details',
          text: 'Study Details',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'start_date',
          text: 'Start Date',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '8%' };
          },
        },
        {
          dataField: 'end_date',
          text: 'End Date',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '8%' };
          },
        },
        {
          dataField: 'type',
          text: 'Type',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '8%' };
          },
        },
        {
          dataField: 'phase',
          text: 'Phase',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '8%' };
          },
        },
        {
          dataField: 'role_id',
          text: 'Role',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '8%' };
          },
        },
        {
          dataField: 'no_of_study_locations',
          text: 'No of Study Locations',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'conditions',
          text: 'Condition',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'conditions',
          text: 'Condition',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'treatment_intervention',
          text: 'Treatment Intervention',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'primary_outcomes',
          text: 'Primary Outcomes',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'secondary_outcomes',
          text: 'Secondary Outcomes',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'sponsors',
          text: 'Sponsors',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'collaborators',
          text: 'Collaborators',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        // {
        //     dataField: 'manual_weightage',
        //     text: 'Manual Weightage',
        //     // align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //     return { width: '10%' };
        //     },
        // },
        {
          dataField: 'source_link',
          text: 'Source Link',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
      ],

      columnsEventsDetails: [
        {
          dataField: 'speciality_type',
          text: 'Speciality Type',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'sub_speciality',
          text: 'Sub Speciality Type',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'procedure_type',
          text: 'Procedure Type',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'events',
          text: 'Event Name',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'event_type',
          text: 'Event Type',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'event_desc',
          text: 'Event Description',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'event_topic',
          text: 'Event Topic',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'conditions',
          text: 'Conditions',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'intervention',
          text: 'Iterventions',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'date',
          text: 'Date',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '8%' };
          },
        },
        {
          dataField: 'session_type',
          text: 'Session Type',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'role_id',
          text: 'Role',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '8%' };
          },
        },
        {
          dataField: 'event_sponsor',
          text: 'Event Sponsor',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'total_event_attendees',
          text: 'Total Event Attendees',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'country_name',
          text: 'Country',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'source_link',
          text: 'Source Link',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
      ],

      columnsGrants: [
        {
          dataField: 'company',
          text: 'Company',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'title',
          text: 'Title',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'year',
          text: 'Year',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'amount',
          text: 'Amount',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'co_investigator',
          text: 'Co-Investigator',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
      ],

      columnsGuidelines: [
        {
          dataField: 'speciality_type',
          text: 'Speciality Type',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'sub_speciality',
          text: 'Sub Speciality Type',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'procedure_type',
          text: 'Procedure Type',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'year',
          text: 'Co-Year',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'company',
          text: 'Company',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'title',
          text: 'Title',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'organization',
          text: 'Organization',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'type_of_organization',
          text: 'Type Of Organization',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        // {
        //     dataField: 'manual_weightage',
        //     text: 'Manual Weightage',
        //     // align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //     return {width: '8%' };
        //     },
        // },
      ],

      columnsHospitalAffiliations: [
        {
          dataField: 'hospital',
          text: 'Hospital',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        // new
        {
          dataField: 'role',
          text: 'Role',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
              return { width: '10%' };
          },
        },
        // new
        {
            dataField: 'from_year',
            text: 'From Year',
            // align: 'center',
            headerStyle: (colum, colIndex) => {
                return { width: '10%' };
            },
        },
        // new
        {
            dataField: 'to_year',
            text: 'To Year',
            // align: 'center',
            headerStyle: (colum, colIndex) => {
                return { width: '10%' };
            },
        },
        {
          dataField: 'city_name',
          text: 'City',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'state_name',
          text: 'State',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'country_name',
          text: 'Country',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
      ],

      columnsInteractions: [
        {
          dataField: 'date',
          text: 'Date',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'opinion_leader',
          text: 'Opinion Leader',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'emp_name',
          text: 'Entered By',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'product',
          text: 'Product',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'type',
          text: 'Type',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'mode',
          text: 'Mode',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'time',
          text: 'Time',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'activity_or_tool_used',
          text: 'Activity or Toll Used',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '13%' };
          },
        },
        {
          dataField: 'comments',
          text: 'Comments',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '13%' };
          },
        },
      ],

      columnsPressItem: [
        {
          dataField: 'title',
          text: 'Title',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        // new
        {
          dataField: 'quote',
          text: 'Quote',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
              return { width: '10%' };
          },
        },
        {
          dataField: 'date',
          text: 'Date',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'source_link',
          text: 'Press Item Link',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
      ],

      columnsPresentation: [
        {
          dataField: 'title',
          text: 'Title',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        // new
        {
          dataField: 'event_name',
          text: 'Event Name',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
              return { width: '10%' };
          },
        },
        {
          dataField: 'type',
          text: 'Type',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'sponsor',
          text: 'Sponsor',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'location',
          text: 'Location',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'date',
          text: 'Date',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
      ],

      columnsPublicationDetails: [
        {
          dataField: 'speciality_type',
          text: 'Speciality Type',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'sub_speciality',
          text: 'Sub Speciality Type',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'procedure_type',
          text: 'Procedure Type',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'journal',
          text: 'Journal',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'type_of_journal',
          text: 'Type of Journal',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'publication_title',
          text: 'Publication Title',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'date',
          text: 'Date',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '8%' };
          },
        },
        {
          dataField: 'position',
          text: 'Position',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'co_author',
          text: 'Co-Author',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'key_words',
          text: 'Key Words',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        // {
        //     dataField: 'manual_weightage',
        //     text: 'Manual Weightage',
        //     // align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //     return { width: '8%' };
        //     },
        // },
        {
          dataField: 'source_link',
          text: 'Source Link',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
      ],

      columnsSunshinePayment: [
        {
          dataField: 'date',
          text: 'Date',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'type',
          text: 'Type',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'devices',
          text: 'Devices',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'location',
          text: 'Location',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'amount',
          text: 'Amount',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'payment_type',
          text: 'Payment Type',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
        {
          dataField: 'OL_address',
          text: 'Address',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'speciality_type',
          text: 'Speciality Type',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' };
          },
        },
        {
          dataField: 'NPI',
          text: 'NPI',
          // align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' };
          },
        },
      ],

    };
  }

  // Function for drawer side selection
  PersonalInformationFun = async () => {
    await this.setState({
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      PersonalInformationFlag: true,
    })
  }

  BiographyFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      BiographyFlag: true,
    })
  }

  ProfessionalMembershipFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      ProfessionalMembershipFlag: true,
    })
  }

  EducationFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      EducationFlag: true,
    })
  }

  AdvisoryBoardFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      AdvisoryBoardFlag: true,
    })
  }

  AwardsAndHonoursFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      AwardsAndHonoursFlag: true,
    })
  }

  BooksAndMonographFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      BooksAndMonographFlag: true,
    })
  }

  CommiteesFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      CommiteesFlag: true,
    })
  }

  ClinicalTrialDetailsFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      ClinicalTrialDetailsFlag: true,
    })
  }

  EventsDetailsFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      EventsDetailsFlag: true,
    })
  }

  GrantsFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      GrantsFlag: true,
    })
  }

  GuidelinesFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      GuidelinesFlag: true,
    })
  }

  HospitalAffiliationsFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      HospitalAffiliationsFlag: true,
    })
  }

  InteractionsFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      InteractionsFlag: true,
    })
  }

  PressItemFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      PressItemFlag: true,
    })
  }

  PresentationsFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      PresentationsFlag: true,
    })
  }

  PublicationDetailsFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: false,
      PublicationDetailsFlag: true,
    })
  }

  SunshinePaymentFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      ProcedurePotentialFlag: false,
      SunshinePaymentFlag: true,
    })
  }

  ProcedurePotentialFun = async () => {
    await this.setState({
      PersonalInformationFlag: false,
      BiographyFlag: false,
      ProfessionalMembershipFlag: false,
      EducationFlag: false,
      AdvisoryBoardFlag: false,
      AwardsAndHonoursFlag: false,
      BooksAndMonographFlag: false,
      CommiteesFlag: false,
      ClinicalTrialDetailsFlag: false,
      EventsDetailsFlag: false,
      GrantsFlag: false,
      GuidelinesFlag: false,
      HospitalAffiliationsFlag: false,
      InteractionsFlag: false,
      PressItemFlag: false,
      PresentationsFlag: false,
      PublicationDetailsFlag: false,
      SunshinePaymentFlag: false,
      ProcedurePotentialFlag: true,
    })
  }

  componentDidMount() {
    let getSession_Kol_Id = sessionStorage.getItem('kol_id')
    if (getSession_Kol_Id !== null) {

      this.setState({
        kol_id: JSON.parse(getSession_Kol_Id),
      })

    }

    let getSession_Employee_Name = sessionStorage.getItem('employee_name')
    if (getSession_Employee_Name !== null) {
      this.setState({
        employee_name: getSession_Employee_Name,
      })
    }
    console.log("kol_id", this.state.kol_id);
    console.log("getSession_Kol_Id", getSession_Kol_Id);

    this.setState({
      ShowBar: true,
    })
    const options = {
      method: 'POST',
    }
    const url = global.websiteUrl+'/service/KOL_Dashboard/get_FetchKOLInfo';
    let formData = new FormData();
    // formData.append('kol_id','CHCUI001');
    formData.append('kol_id', JSON.parse(getSession_Kol_Id));
    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }

    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var lgdata = response.data;
        console.log("lgdata=====", JSON.stringify(lgdata));
        var KOL_Personal_Info = response.data.KOL_Personal_Info;
        console.log("KOL_Personal_Info==========", JSON.stringify(KOL_Personal_Info));
        var KOL_Work_Details = response.data.KOL_Work_Details;
        console.log("KOL_Work_Details==========", JSON.stringify(KOL_Work_Details));
        this.setState({
          PersonalInformation: KOL_Personal_Info,
          WorkDetails: response.data.KOL_Work_Details,
          Biography: response.data.KOL_Biography,
          ProfessionalMemberships: response.data.KOL_Professional_Memberships,
          Education: response.data.KOL_Education,
          AdvisoryBoard: response.data.KOL_Advisory_Board,
          AwardsHonours: response.data.KOL_Awards_Honours,
          BooksMonographs: response.data.KOL_Books_Monographs,
          Commitees: response.data.KOL_Commitees,
          ClinicalTrialDetails: response.data.KOL_Clinical_Trial_Details,
          EventsDetails: response.data.KOL_Events_Details,
          Grants: response.data.KOL_Grants,
          Guidelines: response.data.KOL_Guidelines,
          HospitalAffiliations: response.data.KOL_Hospital_Affiliations,
          Interactions: response.data.KOL_Interactions,
          PressItem: response.data.KOL_Press_Item,
          Presentation: response.data.KOL_Presentations,
          PublicationDetails: response.data.KOL_Publication_Details,
          SunshinePayment: response.data.KOL_Sunshine_Payment,

          profile_photo_for_edit: response.data.KOL_Profile_Photo,
          BiographyResume_edit: response.data.KOL_Resume,
          BiographyResumeFileName: response.data.KOL_Resume,

          profile_photo: response.data.KOL_Profile_Photo,
          // BiographyResume: response.data.KOL_Resume, 

        })

        this.setState({
          ShowBar: false,
          // ContractFileName: ContractFileNameapi
        })
        console.log("PersonalInformation", this.state.PersonalInformation);
        console.log("profile_photo_for_edit", this.state.profile_photo_for_edit);
        console.log("Education", this.state.Education);
        console.log("WorkDetails===============", this.state.WorkDetails);

        // to convert country id to string 
        if (KOL_Personal_Info.country_id) {
          if (KOL_Personal_Info.country_id !== '0') {
            const options = {
              method: 'POST',
            }
            fetch(global.websiteUrl+'/service/Location/get_CountryList', options)
              .then((Response) => Response.json())
              .then((findresponse) => {
                console.log(findresponse)
                var testDataCountryList = findresponse.Country_Details;
                console.log("testDataCountryList", JSON.stringify(testDataCountryList));


                testDataCountryList.forEach(element => {
                  if (element.id == "0") {
                    let { PersonalInformation } = this.state;
                    PersonalInformation.country_id = 'NA';
                    this.setState({ PersonalInformation });
                  }
                  if (element.id == this.state.PersonalInformation.country_id) {
                    let { PersonalInformation } = this.state;
                    PersonalInformation.country_id = element.country_name;
                    this.setState({ PersonalInformation });
                  }
                })
              })
          }
        }

        if (KOL_Personal_Info.country_id) {
          if (KOL_Personal_Info.country_id !== '0') {
            const url = global.websiteUrl+'/service/Location/get_StateList';
            let formData = new FormData();
            formData.append('country_id', this.state.PersonalInformation.country_id);

            const config = {
              //   headers: { 'content-type': 'multipart/form-data' }
            }
            axios.post(url, formData, config)
              .then(response => {
                console.log(response);
                var testStateData = response.data.Country_Details;
                console.log("testStateData", JSON.stringify(testStateData));
                this.setState({
                  StateData: response.data.Country_Details,
                })
                console.log("StateData======", this.state.StateData)

                testStateData.forEach(element => {
                  if (element.id == "0") {
                    let { PersonalInformation } = this.state;
                    PersonalInformation.state_id = "NA";
                    this.setState({ PersonalInformation });
                  }
                  if (element.id == this.state.PersonalInformation.state_id) {
                    let { PersonalInformation } = this.state;
                    PersonalInformation.state_id = element.state_name;
                    this.setState({ PersonalInformation });
                  }
                })
              })
              .catch(error => {
                console.log(error);
              });
          }
        }

        if (KOL_Personal_Info.country_id) {
          if (KOL_Personal_Info.country_id !== '0') {
            const url = global.websiteUrl+'/service/Location/get_CityList';
            let formData = new FormData();
            formData.append('country_id', this.state.PersonalInformation.country_id);

            const config = {
              //   headers: { 'content-type': 'multipart/form-data' }
            }
            axios.post(url, formData, config)
              .then(response => {
                console.log(response);
                var testCityData = response.data.Country_Details;
                console.log("testCityData", JSON.stringify(testCityData));
                this.setState({
                  CityData: response.data.Country_Details,
                })
                console.log("CityData======", this.state.CityData)
              })
              .catch(error => {
                console.log(error);
              });


          }
        }

        if (KOL_Personal_Info.specialty_id) {
          fetch(global.websiteUrl+'/service/Speciality/get_SpecialityList', options)
            .then((Response) => Response.json())
            .then((findresponse) => {
              console.log(findresponse)
              var testDataSpeciality = findresponse.data;
              console.log("testDataSpeciality", JSON.stringify(testDataSpeciality));

              testDataSpeciality.forEach(element => {
                if (element.id == this.state.PersonalInformation.specialty_id) {
                  let { PersonalInformation } = this.state;
                  PersonalInformation.specialty_id = element.speciality_type;
                  this.setState({ PersonalInformation });
                }
              })
            })
        }

        // if(KOL_Personal_Info.specialty_id){
        //     console.log("in Procedure list");
        //     console.log("speciality_type_id",this.state.PersonalInformation.specialty_id);           
        //     const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
        //     let formData = new FormData();
        //     formData.append('speciality_type_id',this.state.PersonalInformation.specialty_id);
        //     formData.append('search_type',"Procedure");

        //     const config = {     
        //     //   headers: { 'content-type': 'multipart/form-data' }
        //     }
        //     axios.post(url, formData, config)
        //         .then(response => {
        //             console.log(response);
        //             var proceduredata= response.data;
        //             console.log("proceduredata",JSON.stringify(proceduredata));
        //             this.setState({
        //             ProcedureData : response.data.data,
        //             showProcedureSubSpecialityDropdown: false,
        //             showProcedureSubSpecialityDropdownAdvBoard: false,
        //             }) 
        //             console.log("ProcedureData",this.state.ProcedureData)
        //             this.setState({
        //             showProcedureSubSpecialityDropdown: true,
        //             showProcedureSubSpecialityDropdownAdvBoard: true,
        //             }) 

        //             var proceduredataFromJSON2 = [];
        //             var proceduredata= response.data.data;
        //             var proceduredataFromJSON = this.state.PersonalInformation.procedure_type_id.split(',');
        //             if(proceduredata){
        //                 proceduredata.forEach(element => {
        //                    proceduredataFromJSON.forEach(elementProcedure => {
        //                         if( element.id == elementProcedure ){
        //                             console.log("same option is here Team Members",element);
        //                             var joined = proceduredataFromJSON2.concat(element.procedure_type);
        //                             proceduredataFromJSON2 = joined;
        //                             console.log("proceduredataFromJSON2",proceduredataFromJSON2);
        //                         }
        //                     })       
        //                 })
        //             }
        //             let { PersonalInformation } = this.state;
        //             PersonalInformation.procedure_type_id = proceduredataFromJSON2.toString();
        //             this.setState({ PersonalInformation });

        //         })
        //         .catch(error => {
        //             console.log(error);
        //         }); 
        // }

        // if(KOL_Personal_Info.specialty_id){
        //     console.log("in sub speciality list");
        //     const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
        //     let formData = new FormData();
        //     formData.append('speciality_type_id',this.state.PersonalInformation.specialty_id);
        //     formData.append('search_type',"Sub Speciality");

        //     const config = {     
        //     //   headers: { 'content-type': 'multipart/form-data' }
        //     }
        //     axios.post(url, formData, config)
        //         .then(response => {
        //             console.log(response);
        //             var SubSpecialitydata= response.data;
        //             console.log("SubSpecialitydata",JSON.stringify(SubSpecialitydata));
        //             this.setState({
        //             SubSpecialitydata : response.data.data,
        //             showProcedureSubSpecialityDropdown: false,
        //             }) 
        //             console.log("SubSpecialitydata",this.state.SubSpecialitydata)
        //             this.setState({
        //             showProcedureSubSpecialityDropdown: true,
        //             }) 

        //             var subSpecialitydataFromJSON2 = [];
        //             var subSpecialitydata= response.data.data;
        //             var subSpecialitydataFromJSON = this.state.PersonalInformation.sub_specialty_id.split(',');
        //             if(subSpecialitydata){
        //                 subSpecialitydata.forEach(element => {
        //                    subSpecialitydataFromJSON.forEach(elementSubSpeciality => {
        //                         if( element.id == elementSubSpeciality ){
        //                             console.log("same option is here Team Members",element);
        //                             var joined = subSpecialitydataFromJSON2.concat(element.sub_speciality_type);
        //                             subSpecialitydataFromJSON2 = joined;
        //                             console.log("subSpecialitydataFromJSON2",subSpecialitydataFromJSON2);
        //                         }
        //                     })       
        //                 })
        //             }
        //             let { PersonalInformation } = this.state;
        //             PersonalInformation.sub_specialty_id = subSpecialitydataFromJSON2.toString();
        //             this.setState({ PersonalInformation });
        //             // this.setState({
        //             //     TeamMembers: TeamMembers3.toString(),
        //             // })

        //         })
        //         .catch(error => {
        //             console.log(error);
        //         }); 
        // }



      })
  }

  // rediect funcs
  BackToDashboard() {
    this.setState({ RedirectFlag: true })
  }

  render() {
    if (this.state.RedirectFlag) {
      return (<Redirect to={{
        pathname: '/EmployeeDashboard',
      }} />)
    }

    let dataProfessionalMembership = this.state.ProfessionalMemberships.map((dataProfessionalMembership) => {
      return (

        <tr key={dataProfessionalMembership.organization}>
          <td>{dataProfessionalMembership.organization}</td>
          <td>{dataProfessionalMembership.organization_type}</td>
          <td>{dataProfessionalMembership.source_link}</td>
          <td>{dataProfessionalMembership.location}</td>
          <td>{dataProfessionalMembership.position}</td>
          <td>{dataProfessionalMembership.tenure}</td>
          <td>{dataProfessionalMembership.organization_website}</td>
        </tr>

      )
    });

    return (
      <body>
        <div>
          <div>
            <div>
              <EmployeeHeader EmployeeName={this.state.employee_name} />
              <div class="container">
                <div class="content-wrapper">
                  <div class="screen-headingbox">
                    <div className="back-section">
                      <a onClick={this.BackToDashboard.bind(this)}>
                        <img src={require('../Assets/Images/back-arrow.svg')} />
                        Back
                      </a>
                    </div>
                    <h2>Profile Details </h2>
                  </div>

                  <div class="row">
                    <div class="col-md-3 col-sm-12 col-xs-12 profile-box">
                      <div class="sidebar sticky">
                        <ul class="cetas-leftnav">
                          <li>
                            <a onClick={this.PersonalInformationFun}>
                              {
                                (this.state.PersonalInformationFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Personal Information</text>
                                  </div>

                                ) : (
                                  <div>
                                    <text>Personal Information</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.BiographyFun}>
                              {
                                (this.state.BiographyFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Biography</text>
                                  </div>

                                ) : (
                                  <div><text>Biography</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li class="submenu-link">
                            <a onClick={this.ProfessionalMembershipFun}>
                              {
                                (this.state.ProfessionalMembershipFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Professional Membership</text>
                                  </div>
                                ) : (
                                  <div>
                                    <text>Professional Membership</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.EducationFun}>
                              {
                                (this.state.EducationFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Education</text>
                                  </div>
                                ) : (
                                  <div>
                                    <text>Education</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.AdvisoryBoardFun}>
                              {
                                (this.state.AdvisoryBoardFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Advisory Board</text>
                                  </div>
                                ) : (
                                  <div>
                                    <text>Advisory Board</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.AwardsAndHonoursFun}>
                              {
                                (this.state.AwardsAndHonoursFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Awards & Honours</text>
                                  </div>
                                ) : (
                                  <div>
                                    <text>Awards & Honours</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.BooksAndMonographFun}>
                              {
                                (this.state.BooksAndMonographFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Books & Monographs</text>
                                  </div>
                                ) : (
                                  <div>
                                    <text>Books & Monographs</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.CommiteesFun}>
                              {
                                (this.state.CommiteesFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Commitees</text>
                                  </div>
                                ) : (
                                  <div>
                                    <text>Commitees</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.ClinicalTrialDetailsFun}>
                              {
                                (this.state.ClinicalTrialDetailsFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Clinical Trial Details</text>
                                  </div>
                                ) : (
                                  <div>
                                    <text>Clinical Trial Details</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.EventsDetailsFun}>
                              {
                                (this.state.EventsDetailsFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Events Details</text>
                                  </div>
                                ) : (
                                  <div>
                                    <text>Events Details</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.GrantsFun}>
                              {
                                (this.state.GrantsFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Grants</text>
                                  </div>
                                ) : (
                                  <div>
                                    <text>Grants</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.GuidelinesFun}>
                              {
                                (this.state.GuidelinesFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Guidelines</text>
                                  </div>
                                ) : (
                                  <div>
                                    <text>Guidelines</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.HospitalAffiliationsFun}>
                              {
                                (this.state.HospitalAffiliationsFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Hospital Affiliations</text>
                                  </div>
                                ) : (
                                  <div>
                                    <text>Hospital Affiliations</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.InteractionsFun}>
                              {
                                (this.state.InteractionsFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Interactions</text>
                                  </div>
                                ) : (
                                  <div>
                                    <text>Interactions</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.PressItemFun}>
                              {
                                (this.state.PressItemFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Press Item</text>
                                  </div>
                                ) : (
                                  <div>
                                    <text>Press Item</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.PresentationsFun}>
                              {
                                (this.state.PresentationsFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Presentations</text>
                                  </div>
                                ) : (
                                  <div>
                                    <text>Presentations</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.PublicationDetailsFun}>
                              {
                                (this.state.PublicationDetailsFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Publication Details</text>
                                  </div>
                                ) : (
                                  <div>
                                    <text>Publication Details</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          <li>
                            <a onClick={this.SunshinePaymentFun}>
                              {
                                (this.state.SunshinePaymentFlag) ? (
                                  <div>
                                    <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                      Sunshine Payment</text>
                                  </div>
                                ) : (
                                  <div>
                                    <text>Sunshine Payment</text>
                                  </div>
                                )
                              }
                            </a>
                          </li>
                          {/* <li>
                                <a onClick={this.ProcedurePotentialFun}>
                                    {
                                        (this.state.ProcedurePotentialFlag) ? (
                                            <div>
                                            <text style={{color:'#A90007', fontWeight:'bold'}}>
                                                Procedure Potential</text>
                                            </div>
                                        )  : (
                                            <div>
                                            <text>Procedure Potential</text>
                                            </div>
                                        )
                                    }
                                </a>
                            </li> */}
                        </ul>
                        <div style={{ marginTop: '10%' }}></div>
                      </div>
                    </div>
                    <div class="col-md-9">
                      <div class="scroll-box">
                        <div class="doctor-details sticky">
                          <div class="profile-section">
                            <div class="img-box">
                              {/* <img src="img/doctor.jpg" class="pro-image rounded-circle" /> */}
                              <img
                                src={this.state.profile_photo_for_edit} />
                            </div>
                            <div class="row">
                              <div class="col-md-12">
                                <div class="pro-description">
                                  <p class="float-left">
                                    <span class="bold">{this.state.PersonalInformation.salutation} 
                                    {this.state.PersonalInformation.doctor_full_name}&nbsp;
                                    {this.state.PersonalInformation.suffix}
                                    </span><br />
                                    {/* Director - Cardiovascular clinical health research center */}
                                  </p>
                                </div>
                                <div class="pro-description float-right">
                                  {/* <p class="float-left img-box" style={{borderBottom:'solid 1px', borderTop:'solid 0.5px', 
                                                borderLeft:'solid 0.5px', borderRight:'solid 0.5px'}}>
                                                <span class="bold" >Overall Rating</span><br/>
                                                <span class="mandatory" style={{marginLeft:'35%'}}>{this.state.PersonalInformation.aggregate_score}</span>
                                            </p> */}
                                  <p class="overall-rating float-left" style={{ marginTop: '-30px !important', padding: '10px !important' }}>
                                    <span class="bold" >Overall</span><br />
                                    <span class="bold" >Rating</span><br />
                                    <span class="bold" style={{ marginLeft: '35%' }}>{this.state.PersonalInformation.aggregate_score}</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <a id="overall-details"></a>
                        {
                          (this.state.PersonalInformationFlag == true) ? (
                            <section class="mt-50 " >
                              <h3 class="mt-30">Overall Details</h3>
                              <div class="scrollable-box">
                                <div class="pro-description">
                                  <p>
                                    <span class="bold">Degree: </span><br />
                                    {this.state.PersonalInformation.qualification}
                                  </p>
                                </div>
                                <div class="pro-description">
                                  <p>
                                    <span class="bold">Experience: </span><br />
                                    {this.state.PersonalInformation.experience}
                                  </p>
                                </div>
                                <div class="pro-description">
                                  <p>
                                    <span class="bold">Alma  Mater: </span><br />
                                    {this.state.PersonalInformation.medical_schools}
                                  </p>
                                </div>
                                <div class="pro-description">
                                  <p>
                                    <span class="bold">Licens No: </span><br />
                                    {this.state.PersonalInformation.medical_registration_no}
                                  </p>
                                </div>
                                <div class="pro-description">
                                  <p>
                                    <span class="bold">NPI No: </span><br />
                                    {this.state.PersonalInformation.NPI_no}
                                  </p>
                                </div>
                                <div class="pro-description">
                                  <p>
                                    <span class="bold">Speciality Type</span><br />
                                    {this.state.PersonalInformation.specialty_id}
                                  </p>
                                </div>
                                <div class="pro-description">
                                  <p>
                                    <span class="bold">Sub Speciality Type</span><br />
                                    {this.state.PersonalInformation.sub_specialty_id}
                                  </p>
                                </div>
                                <div class="pro-description">
                                  <p>
                                    <span class="bold">Procedure Type</span><br />
                                    {this.state.PersonalInformation.procedure_type_id}
                                  </p>
                                </div>
                                <div class="pro-description">
                                  <p>
                                    <span class="bold">Contact Details: </span><br />
                                    <img src={require('../Assets/Images/phone.svg')} />&nbsp;&nbsp;
                                    {this.state.PersonalInformation.doctor_contact_no} <br />
                                    <img src={require('../Assets/Images/fax.svg')} /> &nbsp;&nbsp;
                                    {this.state.PersonalInformation.fax} <br />
                                    <img src={require('../Assets/Images/message.svg')} /> &nbsp;&nbsp;
                                    {this.state.PersonalInformation.doctor_email}
                                  </p>
                                </div>
                                <div class="pro-description">
                                  <p>
                                    <span class="bold">LinkedIn Profile</span><br />
                                    {/* {this.state.PersonalInformation.linked_in_profile} */}
                                    <a href={this.state.PersonalInformation.linked_in_profile} target="_blank">
                                      <text>{this.state.PersonalInformation.linked_in_profile}</text>
                                    </a>
                                  </p>
                                </div>
                                <div class="pro-description">
                                  <p>
                                    <span class="bold">Twitter Handle</span><br />
                                    <a href={this.state.PersonalInformation.twitter_handle} target="_blank">
                                      <text>{this.state.PersonalInformation.twitter_handle}</text>
                                    </a>
                                  </p>
                                </div>
                                <div class="pro-description">
                                  <p>
                                    <span class="bold">Assistance Contact No</span> <br />
                                    <img src={require('../Assets/Images/phone.svg')} />
                                    {this.state.PersonalInformation.assistance_contact_no}
                                  </p>
                                </div>
                                <div class="pro-description">
                                  <p>
                                    <span class="bold">Assistance Email</span> <br />
                                    {this.state.PersonalInformation.assistance_email}
                                  </p>
                                </div>
                                <div class="pro-description">
                                  <p>
                                    <span class="bold">Website</span> <br />
                                    <a href={this.state.PersonalInformation.website} target="_blank">
                                      <text>{this.state.PersonalInformation.website}</text>
                                    </a>
                                  </p>
                                </div>
                                <div class="pro-description">
                                  <p>
                                    <span class="bold">Primary Work Address</span> <br />
                                    {this.state.PersonalInformation.address_1}
                                  </p>
                                </div>
                                <div class="pro-description">
                                  <p>
                                    <span class="bold">Primary Work Address 2</span> <br />
                                    {this.state.PersonalInformation.address_2}
                                  </p>
                                </div>
                                <div class="pro-description">
                                  <p>
                                    <span class="bold">Country</span> <br />
                                    {this.state.PersonalInformation.country_id}
                                  </p>
                                </div>
                                <div class="pro-description">
                                  <p>
                                    <span class="bold">State</span> <br />
                                    {this.state.PersonalInformation.state_id}
                                  </p>
                                </div>
                                <div class="pro-description">
                                  <p>
                                    <span class="bold">Procedure Potential</span> <br />
                                    {this.state.PersonalInformation.procedure_potential}
                                  </p>
                                </div>
                                <div class="pro-description">
                                  <p>
                                    <span class="bold">Zip Code</span> <br />
                                    {this.state.PersonalInformation.zip_code}
                                  </p>
                                </div>
                                <div class="pro-description">
                                  <p>
                                    <span class="bold">contract</span> <br />
                                    {this.state.PersonalInformation.contract}
                                  </p>
                                </div>
                              </div>

                              {/* Work Details */}
                              {/* {
                            (this.state.EducationFlag == true ) ? ( */}
                              <div className="emplist-box" style={{ marginTop: '5%', marginBottom: '5%' }}>
                                <div className="employee-actions">
                                  <h3 class="mt-30">Work Details</h3>
                                  <div className="table-infobox">
                                    <p className="list-count" style={{ color: 'white' }}>
                                      {this.state.WorkDetails.length}
                                    </p>
                                  </div>
                                </div>
                                <div style={{ width: '100%' }}>
                                  <BootstrapTable
                                    keyField='id'
                                    noDataIndication="Table is Empty"
                                    data={this.state.WorkDetails}
                                    columns={this.state.columnsWorkDetails}
                                    filter={filterFactory()}
                                    striped
                                    hover
                                    condensed
                                  />
                                </div>
                              </div>
                              {/* ) : (
                                <div>
                                </div>
                            )
                        } */}
                              <div style={{ marginTop: '10%' }}></div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        <a id="advisory-board"></a>
                        {
                          (this.state.BiographyFlag == true) ? (
                            <section class="mt-50 " >
                              <h3 class="mt-30">Biography Details</h3>
                              <div class="scrollable-box">
                                <div class="pro-description">
                                  <p>
                                    <span class="bold">Citizenship: </span><br />
                                    {this.state.Biography.citizenship}
                                  </p>
                                </div>
                                <div class="pro-description">
                                  <p>
                                    <span class="bold">Language Spoken: </span><br />
                                    {this.state.Biography.language_spoken}
                                  </p>
                                </div>
                                <div class="pro-description">
                                  <p>
                                    <span class="bold">Linkedin Followers: </span><br />
                                    {this.state.Biography.linkedin_followers}
                                  </p>
                                </div>
                                <div class="pro-description">
                                  <p>
                                    <span class="bold">Twitter Followers: </span><br />
                                    {this.state.Biography.twitter_followers}
                                  </p>
                                </div>
                                <div class="pro-description">
                                  <p>
                                    <span class="bold">Biography: </span><br />
                                    {this.state.Biography.biography}
                                  </p>
                                </div>
                                <div class="pro-description">
                                  <p>
                                    <span class="bold">Areas Of Interest: </span><br />
                                    {this.state.Biography.areas_of_interest}
                                  </p>
                                </div>
                                {/* <div class="pro-description">
                                            <p>
                                                <span class="bold">Licensed Regions: </span><br/> 
                                                {this.state.Biography.licensed_regions}
                                            </p>
                                        </div>*/}
                                {/* <div class="pro-description">
                                            <p>
                                                <span class="bold">Work Phone: </span><br/> 
                                                <img src={require('../Assets/Images/phone.svg')} />
                                                {this.state.Biography.work_phone}
                                            </p>
                                        </div> */}
                                {/* <div class="pro-description">
                                            <p>
                                                <span class="bold">Work Email: </span><br/> 
                                                {this.state.Biography.work_email}
                                            </p>
                                        </div> */}
                                <div class="pro-description">
                                  <p>
                                    <span class="bold">Resume: </span><br />
                                    <a
                                      href={this.state.Biography.resume} target="_blank">
                                      <text>{this.state.Biography.resume}</text>
                                    </a>
                                  </p>
                                </div>
                              </div>
                              <div style={{ marginTop: '10%' }}></div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* Professional membership */}
                        {
                          (this.state.ProfessionalMembershipFlag == true) ? (
                            <section class="mt-50 " >
                              <h3 class="mt-30">Professional Membership Details</h3>
                              <div class="scrollable-box">
                                {/* <div className="emplist-box-boot" style={{width:'200%'}}>  */}
                                <div style={{ width: '100%', overflowX: 'scroll' }}>
                                  <div style={{ width: '200%' }}>
                                    <BootstrapTable
                                      // responsive  
                                      // scrollX   
                                      // wrapperClasses="table-responsive"
                                      // wrapperClasses ="tablebootstrap" 
                                      // style={{width:'200%'}}   
                                      noDataIndication="Table is Empty"
                                      keyField='id'
                                      data={this.state.ProfessionalMemberships}
                                      columns={this.state.columnsProfessionalMemberships}
                                      filter={filterFactory()}
                                      striped
                                      hover
                                      condensed
                                    />
                                  </div>
                                </div>
                                {/* <table id="datatable" class="table table-striped table-bordered" 
                                        style={{overflow:'auto'}} scrollX 
                                    cellspacing="0" width="1000%">
                                        <thead>
                                                <tr>
                                                    <th className="WidthForTable">organization</th>
                                                    <th>organization_type</th>
                                                    <th>Asource_link</th>
                                                    <th>location</th>
                                                    <th>position</th>
                                                    <th>tenure</th>
                                                    <th>organization_website</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {dataProfessionalMembership}
                                            </tbody>
                                    </table> */}
                              </div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* Education */}
                        {
                          (this.state.EducationFlag == true) ? (
                            <section class="mt-50 " >
                              <h3 class="mt-30">Education Details</h3>
                              <div class="scrollable-box">
                                {/* <div className="emplist-box-boot" style={{width:'200%'}}>  */}
                                <div style={{ width: '100%', overflowX: 'scroll' }}>
                                  <div style={{ width: '210%' }}>
                                    <BootstrapTable
                                      noDataIndication="Table is Empty"
                                      keyField='id'
                                      data={this.state.Education}
                                      columns={this.state.columnsEducation}
                                      filter={filterFactory()}
                                      striped
                                      hover
                                      condensed
                                    />
                                  </div>
                                </div>
                              </div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* advisory board */}
                        {
                          (this.state.AdvisoryBoardFlag == true) ? (
                            <section class="mt-50 " >
                              <h3 class="mt-30">Advisory Board Details</h3>
                              <div class="scrollable-box">
                                {/* <div className="emplist-box-boot" style={{width:'200%'}}>  */}
                                <div style={{ width: '100%', overflowX: 'scroll' }}>
                                  <div style={{ width: '270%' }}>
                                    <BootstrapTable
                                      noDataIndication="Table is Empty"
                                      keyField='id'
                                      data={this.state.AdvisoryBoard}
                                      columns={this.state.columnsAdvisoryBord}
                                      striped
                                      hover
                                      condensed
                                    />
                                  </div>
                                </div>
                              </div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* Awards and honours */}
                        {
                          (this.state.AwardsAndHonoursFlag == true) ? (
                            <section class="mt-50 " >
                              <h3 class="mt-30">Awards and honours Details</h3>
                              <div class="scrollable-box">
                                <div style={{ width: '100%', overflowX: 'scroll' }}>
                                  <BootstrapTable
                                    noDataIndication="Table is Empty"
                                    keyField='id'
                                    data={this.state.AwardsHonours}
                                    columns={this.state.columnsAwardsHonours}
                                    striped
                                    hover
                                    condensed
                                  />
                                </div>
                              </div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* Books and Monographs */}
                        {
                          (this.state.BooksAndMonographFlag == true) ? (
                            <section class="mt-50 " >
                              <h3 class="mt-30">Advisory Board Details</h3>
                              <div class="scrollable-box">
                                {/* <div className="emplist-box-boot" style={{width:'200%'}}>  */}
                                <div style={{ width: '100%', overflowX: 'scroll' }}>
                                  <div style={{ width: '250%' }}>
                                    <BootstrapTable
                                      noDataIndication="Table is Empty"
                                      keyField='id'
                                      data={this.state.BooksMonographs}
                                      columns={this.state.columnsBooksMonographs}
                                      striped
                                      hover
                                      condensed
                                    />
                                  </div>
                                </div>
                              </div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* Commitees */}
                        {
                          (this.state.CommiteesFlag == true) ? (
                            <section class="mt-50 " >
                              <h3 class="mt-30">Commitee Details</h3>
                              <div class="scrollable-box">
                                <div style={{ width: '100%', overflowX: 'scroll' }}>
                                  <BootstrapTable
                                    noDataIndication="Table is Empty"
                                    keyField='id'
                                    data={this.state.Commitees}
                                    columns={this.state.columnsCommitees}
                                    striped
                                    hover
                                    condensed
                                  />
                                </div>
                              </div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* Clinical Trial Details */}
                        {
                          (this.state.ClinicalTrialDetailsFlag == true) ? (
                            <section class="mt-50 " >
                              <h3 class="mt-30"> Clinical Trial Details</h3>
                              <div class="scrollable-box">
                                {/* <div className="emplist-box-boot" style={{width:'200%'}}>  */}
                                <div style={{ width: '100%', overflowX: 'scroll' }}>
                                  <div style={{ width: '400%' }}>
                                    <BootstrapTable
                                      noDataIndication="Table is Empty"
                                      keyField='id'
                                      data={this.state.ClinicalTrialDetails}
                                      columns={this.state.columnsClinicalTrialDetails}
                                      striped
                                      hover
                                      condensed
                                    />
                                  </div>
                                </div>
                              </div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* Event Details */}
                        {
                          (this.state.EventsDetailsFlag == true) ? (
                            <section class="mt-50 " >
                              <h3 class="mt-30">Event Details</h3>
                              <div class="scrollable-box">
                                <div style={{ width: '100%', overflowX: 'scroll' }}>
                                  <div style={{ width: '300%' }}>
                                    <BootstrapTable
                                      noDataIndication="Table is Empty"
                                      keyField='id'
                                      data={this.state.EventsDetails}
                                      columns={this.state.columnsEventsDetails}
                                      striped
                                      hover
                                      condensed
                                    />
                                  </div>
                                </div>
                              </div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* grants */}
                        {
                          (this.state.GrantsFlag == true) ? (
                            <section class="mt-50 " >
                              <h3 class="mt-30">Grants Details</h3>
                              <div class="scrollable-box">
                                {/* <div className="emplist-box-boot" style={{width:'200%'}}>  */}
                                <div style={{ width: '100%', overflowX: 'scroll' }}>
                                  <BootstrapTable
                                    noDataIndication="Table is Empty"
                                    keyField='id'
                                    data={this.state.Grants}
                                    columns={this.state.columnsGrants}
                                    striped
                                    hover
                                    condensed
                                  />
                                </div>
                              </div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* Guidelines */}
                        {
                          (this.state.GuidelinesFlag == true) ? (
                            <section class="mt-50 " >
                              <h3 class="mt-30">Guidelines Details</h3>
                              <div class="scrollable-box">
                                <div style={{ width: '100%', overflowX: 'scroll' }}>
                                  <div style={{ width: '250%' }}>
                                    <BootstrapTable
                                      noDataIndication="Table is Empty"
                                      keyField='id'
                                      data={this.state.Guidelines}
                                      columns={this.state.columnsGuidelines}
                                      striped
                                      hover
                                      condensed
                                    />
                                  </div>
                                </div>
                              </div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* Hospital Affiliations */}
                        {
                          (this.state.HospitalAffiliationsFlag == true) ? (
                            <section class="mt-50 " >
                              <h3 class="mt-30">Hospital Affiliations Details</h3>
                              <div class="scrollable-box">
                                <div style={{ width: '100%', overflowX: 'scroll' }}>
                                  <BootstrapTable
                                    noDataIndication="Table is Empty"
                                    keyField='id'
                                    data={this.state.HospitalAffiliations}
                                    columns={this.state.columnsHospitalAffiliations}
                                    striped
                                    hover
                                    condensed
                                  />
                                </div>
                              </div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* Interactions */}
                        {
                          (this.state.InteractionsFlag == true) ? (
                            <section class="mt-50 " >
                              <h3 class="mt-30">Interactions Details</h3>
                              <div class="scrollable-box">
                                <div style={{ width: '100%', overflowX: 'scroll' }}>
                                  <div style={{ width: '250%' }}>
                                    <BootstrapTable
                                      noDataIndication="Table is Empty"
                                      keyField='id'
                                      data={this.state.Interactions}
                                      columns={this.state.columnsInteractions}
                                      striped
                                      hover
                                      condensed
                                    />
                                  </div>
                                </div>
                              </div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* Press Items */}
                        {
                          (this.state.PressItemFlag == true) ? (
                            <section class="mt-50 " >
                              <h3 class="mt-30">Press Items Details</h3>
                              <div class="scrollable-box">
                                <div style={{ width: '100%', overflowX: 'scroll' }}>
                                  <div style={{ width: '150%' }}>
                                    <BootstrapTable
                                      noDataIndication="Table is Empty"
                                      keyField='id'
                                      data={this.state.PressItem}
                                      columns={this.state.columnsPressItem}
                                      striped
                                      hover
                                      condensed
                                    />
                                  </div>
                                </div>
                              </div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* Presentation */}
                        {
                          (this.state.PresentationsFlag == true) ? (
                            <section class="mt-50 " >
                              <h3 class="mt-30">Presentation Details</h3>
                              <div class="scrollable-box">
                                <div style={{ width: '100%', overflowX: 'scroll' }}>
                                  <BootstrapTable
                                    noDataIndication="Table is Empty"
                                    keyField='id'
                                    data={this.state.Presentation}
                                    columns={this.state.columnsPresentation}
                                    striped
                                    hover
                                    condensed
                                  />
                                </div>
                              </div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* Publication Details */}
                        {
                          (this.state.PublicationDetailsFlag == true) ? (
                            <section class="mt-50 " >
                              <h3 class="mt-30">Publication Details</h3>
                              <div class="scrollable-box">
                                <div style={{ width: '100%', overflowX: 'scroll' }}>
                                  <div style={{ width: '300%' }}>
                                    <BootstrapTable
                                      noDataIndication="Table is Empty"
                                      keyField='id'
                                      data={this.state.PublicationDetails}
                                      columns={this.state.columnsPublicationDetails}
                                      striped
                                      hover
                                      condensed
                                    />
                                  </div>
                                </div>
                              </div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* Sunshine Payment */}
                        {
                          (this.state.SunshinePaymentFlag == true) ? (
                            <section class="mt-50 " >
                              <h3 class="mt-30">Sunshine Payment Details</h3>
                              <div class="scrollable-box">
                                <div style={{ width: '100%', overflowX: 'scroll' }}>
                                  <div style={{ width: '250%' }}>
                                    <BootstrapTable
                                      noDataIndication="Table is Empty"
                                      keyField='id'
                                      data={this.state.SunshinePayment}
                                      columns={this.state.columnsSunshinePayment}
                                      striped
                                      hover
                                      condensed
                                    />
                                  </div>
                                </div>
                              </div>
                            </section>
                          ) : (
                            <div>
                            </div>
                          )
                        }

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <footer class="footer-fixed">
            <div class="cetas-footer">
              <div class="container">
                <p>All rights reserved. www.cetashealthcare.com</p>
              </div>
            </div>
          </footer>
        </div>
      </body>
    );

  }
}

export default EmployeeDetails;