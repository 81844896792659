import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, customFilter, selectFilter, Comparator, FILTER_TYPES } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { Button, Spinner } from 'reactstrap';
import Axios from 'axios';
import { Redirect, Link } from 'react-router-dom';
import LoadingBar from "react-top-loading-bar";
import Swal from 'sweetalert2';

import ResercherHeader from './ResercherHeader';

import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';

const { ExportCSVButton } = CSVExport;

const headerSortingStyle = { backgroundColor: 'grey' };

const { SearchBar } = Search;

const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total ml-2">
        Showing {from} to {to} of {size} Results
    </span>
);

// class Researcher_View_KolList extends Component {
class ResercheViewKolList extends Component {
    constructor(props) {

        super(props)

        this.state = {

            token:'',

            system_id: '',
            delete_id: '',

            // To show Add new Kol button
            showAddnewKol: false,

            // for redirecting
            redirectFormManagement: false,
            RedirectEditKOL: false,
            RedirectIdentifireForm: false,
            RedirectcheckduplicateForm:false,
            RedirectEdiIdentifireForm: false,

            // KOL IDS for new kol
            emp_unique_id: '',
            SrNO: '',

            kol_unique_id: '',
            kol_status: '',

            //Kol ID
            // RID: this.props.location.state.RID,
            // resercher_name: this.props.location.state.resercher_name,
            // project_id: this.props.location.state.project_id,
            // Project_Name: this.props.location.state.Project_Name,
            RID: '',
            resercher_name: '',
            project_id: '',
            Project_Name: '',

            RedirectFlag: false,

            //To show Spinner
            ShowBar: true,

            // State for saving data
            projectList: [],
            Project_Count: [],
            Rejected_Count: [],
            Approved_Count: [],

            onPriceFilter: null,
            onProductilters: null,

            // State for displaying data
            status: '',

            // Column state for Bootstrap Table 
            columns: [
                {
                    dataField: 'name',
                    text: 'KOL Name',
                    sort: true,
                    formatter: this.link,
                    filter: textFilter(),

                    filterRenderer: (onFilter, column) => {
                        this.onTextFilter = onFilter;
                        return null;
                    },
                    style: (cell, row, rowIndex, colIndex) => {
                        return {
                            color: '#41A2FF'
                        };
                    },
                },
                {
                    dataField: 'kol_type',
                    text: 'KOL Type',
                    sort: true,
                    // filter: textFilter(),

                    // filterRenderer: (onFilter, column) => {
                    //     this.onTextFilter = onFilter;
                    //     return null;
                    // },
                },
                {
                    dataField: 'speciality_type',
                    text: 'Area of Work',
                    sort: true,
                    // filter: textFilter(),

                    // filterRenderer: (onFilter, column) => {
                    //     this.onTextFilter = onFilter;
                    //     return null;
                    // },
                },
                {
                    dataField: 'kol_updated_date',
                    text: 'Last Updated Date',
                },
                // {
                //     dataField: 'emp_name',
                //     text: 'Rank',
                //     sort: true,
                //     filter: textFilter(),

                //     filterRenderer: (onFilter, column) => {
                //         this.onTextFilter = onFilter;
                //         return null;
                //     },
                // },
                {
                    dataField: "kol_status",
                    text: "Status",
                    sort: true,
                    filter: textFilter(),
                    formatter:this.kolstatuscolour,

                    filterRenderer: (onFilter, column) => {
                        this.onStatusFilters = onFilter;
                        return null;
                    },

                    // style: (cell, row, rowIndex, colIndex) => {
                    //     if (row.kol_status == "Submited") {
                    //         return {
                    //             color: '#1FCE39'
                    //         };
                    //     } else if (row.kol_status == "Approved") {
                    //         return {
                    //             color: '#1FCE39'
                    //         };
                    //     } else if (row.kol_status == "Rejected") {
                    //         return {
                    //             color: '#e51c1c'
                    //         };
                    //     } else if (row.kol_status == "Work In Progress") {
                    //         return {
                    //             color: '#FF7F41'
                    //         };
                    //     }
                    //     else if (row.kol_status == "Prelim") {
                    //         return {
                    //             color: '#8e67e0'
                    //         };
                    //     }
                    //     else if (row.kol_status == "Prelim Approved") {
                    //         return {
                    //             color: '#c585f2'
                    //         };
                    //     }
                    //     else if (row.kol_status == "Prelim Rejected") {
                    //         return {
                    //             color: '#0c9c86'
                    //         };
                    //     }
                    // },
                }
            ]
            // End of state
        }
        this.BackToDashboard = this.BackToDashboard.bind(this);
        // End of constructor
    }


    kolstatuscolour = (cell, row) => {
        var id = row.project_id;
        var project_name = row.p_name;
        var project_status = row.project_status;
        var col='';
      
        if (row.kol_status == "Submited") {
                        col = '#1FCE39'
                  
                } else if (row.kol_status == "Approved") {
                    col= '#1FCE39'
                    
                } else if (row.kol_status == "Rejected") {
                        col= '#e51c1c'
                   
                } else if (row.kol_status == "Work In Progress") {
                  
                    col= '#FF7F41'
                  
                }
                else if (row.kol_status == "Prelim") {
                    col= '#8e67e0'
                    
                }
                else if (row.kol_status == "Prelim Approved") {
                        col= '#1FCE39'
                   
                }
                else if (row.kol_status == "Prelim Rejected") {
                   
                        col= '#e51c1c'
                   
                }
      
      
        return (
          <div style={{color:col}}>
           {row.kol_status}
          </div>
        )
      }


    // STARNGE  
    onTextChange = (event) => {
        console.log(event.target.value)
        const { value } = event.target;
        this.onTextFilter(value);
    };

    //STRANGE
    onFilter = (event) => {
        console.log(event.target.value)
        const { value } = event.target;
        this.onStatusFilters(value);
    };

    //Function for toggling state which is for Back to Dashboard
    BackToDashboard() {
        this.setState({ RedirectFlag: true })
    }

    // Method to fetch data from API when component Mounted
    componentDidMount = async () => {
        
        let getSession_token = await sessionStorage.getItem('token')
        if (getSession_token !== null) {
        await this.setState({
            token: getSession_token,
        })
        }

        let getSession_Researcher_Id = await sessionStorage.getItem('id')
        if (getSession_Researcher_Id !== null) {
            await this.setState({
                RID: getSession_Researcher_Id,
            })
        }
        let getSession_Researcher_Name = await sessionStorage.getItem('name')
        if (getSession_Researcher_Name !== null) {
            await this.setState({
                resercher_name: getSession_Researcher_Name,
            })
        }
        let getSession_Project_Id = await sessionStorage.getItem('project_id')
        if (getSession_Project_Id !== null) {
            await this.setState({
                project_id: getSession_Project_Id,
            })
        }
        let getSession_Project_Name = await sessionStorage.getItem('Project_Name')
        if (getSession_Project_Name !== null) {
            await this.setState({
                Project_Name: getSession_Project_Name,
            })
        }

        console.log("getSession_Researcher_Id ===== ", getSession_Researcher_Id)
        console.log("getSession_Researcher_Name ===== ", getSession_Researcher_Name)
        console.log("getSession_Project_Id ===== ", getSession_Project_Id)
        console.log("getSession_Project_Name ===== ", getSession_Project_Name)

        this.setState({
            ShowBar: true,
        })

        // Project List Fetched
        const url = global.websiteUrl+'/service/KOL/get_Project_KOL';
        let formData = new FormData();
        formData.append('project_id', this.state.project_id);
        formData.append('user_type', 'Researcher');

        // formData.append('project_id','CHCPI0002');
        // formData.append('oper', 'del');

        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }

        Axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var lgdata = response.data.KOL_Personal_Info;
                console.log("Fetched data", JSON.stringify(lgdata));
                var lgstatus = response.data.status;
                console.log("Fetched status", JSON.stringify(lgstatus));
                console.log("Message from fetched api", response.data.message);
                var kolIds = response.data.kol_system_id;
                console.log("kolIds", JSON.stringify(kolIds));

                if (lgdata) {
                    this.setState({
                        status: response.data.message,
                        projectList: response.data.KOL_Personal_Info
                    })
                } else {
                    this.setState({
                        status: response.data.message,
                        projectList: []
                    })
                }


                kolIds.map(async (data) => {
                    var testkolid = data.emp_unique_id;
                    console.log("testkolid", testkolid);
                    var testsrno = data.SI_NO;
                    console.log("testsrno", testsrno);
                    this.setState({
                        emp_unique_id: testkolid,
                        SrNO: testsrno,
                    })
                    sessionStorage.setItem('emp_unique_id', testkolid)
                    sessionStorage.setItem('SrNO', testsrno)
                })

                this.setState({
                    ShowBar: false,
                    showAddnewKol: true
                })

            });
        console.log("PROJECT ID FROM DASHBOARD", this.state.project_id)
        console.log("PROJECT Namwe FROM DASHBOARD", this.state.Project_Name)
        console.log("VKBackRID FROM DASHBOARD", this.state.RID)
    }

    //Funtion to apply links to project names
    link = (cell, row) => {
        // console.log(cell)
        // console.log(row)
        var kol_unique_id = row.kol_unique_id;
        var kol_status = row.kol_status;
        return (
            <div>
                <a style={{
           color: '#069',
          textDecoration: 'underline', cursor: 'pointer'
        }}
 onClick={this.GoToEditKOL.bind(this, kol_unique_id, kol_status)}> {row.name} </a>
            </div>
        )
    }

    GoToEditKOL(kol_unique_id, kol_status) {
        console.log('Yep Here');
        console.log('Id Here', kol_unique_id);
        if (kol_status == "Prelim") {
            Swal.fire({
                icon: 'error',
                text: 'Prelim Profile is under review',
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
            })
        }
        else if (kol_status == "Approved") {
            Swal.fire({
                icon: 'error',
                text: 'Profile is Approved',
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
            })
        }
        else if (kol_status == "Prelim Rejected") {           
           
            this.setState({                

                RedirectEdiIdentifireForm: true,

                // kol_unique_id: kol_unique_id, 
                // kol_status: kol_status 
            })
        }
        else {
            this.setState({
                RedirectEditKOL: true,
                // kol_unique_id: kol_unique_id, 
                // kol_status: kol_status 
            })
        }
        sessionStorage.setItem('kol_unique_id', kol_unique_id)
        sessionStorage.setItem('kol_status', kol_status)
    }

    formManagementNav() {
        // this.setState({redirectFormManagement: true})
        this.setState({ RedirectIdentifireForm: true })
    }

    formcheckduplicate(){this.setState({ RedirectcheckduplicateForm: true })}


    render() {

        if (this.state.redirectFormManagement) {
            // return (<Redirect to={'/KolProfileForm'}/>)
            //  emp_unique_id : '',
            // SrNO: '', 
            return (<Redirect to={{
                pathname: '/KolProfileForm',
                // state:{ 
                //     SrNO: this.state.SrNO , 
                //     emp_unique_id: this.state.emp_unique_id , 
                //     project_id: this.state.project_id,
                //     RID: this.state.RID ,
                //     resercher_name: this.state.resercher_name,
                //     Project_Name:  this.state.Project_Name,
                //     } 
            }}
            />)
        }

        if (this.state.RedirectEditKOL) {
            // return (<Redirect to={'/KolProfileForm'}/>)
            //  emp_unique_id : '',
            // SrNO: '', 
            return (<Redirect to={{
                pathname: '/EditKolProfileForm',
                // state:{ 
                //     emp_unique_id: this.state.emp_unique_id, 
                //     kol_unique_id: this.state.kol_unique_id,
                //     SrNO: this.state.SrNO,
                //     project_id: this.state.project_id,
                //     kol_status: this.state.kol_status,
                //     RID: this.state.RID ,
                //     resercher_name: this.state.resercher_name,
                //     Project_Name:  this.state.Project_Name,
                // } 
            }} />)
        }

        if (this.state.RedirectFlag) {
            return (<Redirect to={{
                pathname: '/ResercherDashboard',
                state: {
                    RID: this.state.RID,
                    resercher_name: this.state.resercher_name,
                }
            }} />)
        }

        if (this.state.RedirectIdentifireForm) {
            return (<Redirect to={{
                pathname: '/IdentifierForm',
            }} />)
        }

        if (this.state.RedirectcheckduplicateForm) {
            return (<Redirect to={{
                pathname: '/DuplicateViewKolList',
            }} />)
        }

        if (this.state.RedirectEdiIdentifireForm) {
            return (<Redirect to={{
                pathname: '/EditIdentifireForm',
            }} />)
        }

        const pageButtonRenderer = ({
            page,
            active,
            disable,
            title,
            onPageChange
        }) => {
            const handleClick = (e) => {
                e.preventDefault();
                onPageChange(page);
            };
            return (
                <Button outline color="danger" className="mr-2" onClick={handleClick} > {page} </Button>
            );
        };

        const options = {
            pageButtonRenderer,
            paginationSize: 4,
            pageStartIndex: 1,
            // alwaysShowAllBtns: true, // Always show next and previous button
            // withFirstAndLast: false, // Hide the going to First and Last page button
            // hideSizePerPage: true, // Hide the sizePerPage dropdown always
            // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: customTotal,
            disablePageTitle: true,
            sizePerPageList: [{
                text: '50', value: 50
            }, {
                text: '100', value: 100
            }, {
                text: '200', value: 200
            }, {
                text: 'All', value: this.state.projectList.length
            }]  // A numeric array is also available. the purpose of above example is custom the text

        };



        return (
            <div style={{marginTop:'15vh' }}>
                {this.state.ShowBar ?
                    <div style={{ position: 'fixed', top: '0' }}>
                        <LoadingBar
                            progress='100'
                            // onLoaderFinished={() => setProgress(0)}
                            //  progress={progress}
                            height={3} color="#A90007" transitionTime={4000}
                            loaderSpeed={4000} />
                    </div>
                    : null
                }
                <div>
                    <div>
                        <div>
                            <ResercherHeader ResercherName={this.state.resercher_name} />
                            <div class="container">
                                <div class="content-wrapper">
                                    <div class="screen-headingbox">
                                        <div className="back-section"  >
                                            <a onClick={this.BackToDashboard.bind(this)} >
                                                <img src={require('../Assets/Images/back-arrow.svg')} className="backbutton" />
                                                Back
                                            </a>
                                        </div>
                                        <h2>KOL List
                                             
                                         <button style={{ visibility: this.state.showAddnewKol ? "visible" : "hidden" }}
                                            class="cetas-button float-right"
                                            onClick={this.formManagementNav.bind(this)}>+ Add New KOL</button>

                                            <button class="cetas-button float-right" style={{ visibility: this.state.showAddnewKol ? "visible" : "hidden" }}
                                            onClick={this.formcheckduplicate.bind(this)}> Duplicate KOL check</button>

                                        </h2>
                                    </div>
                                    {/* <h3 class="mt-30 mb-30"></h3> */}
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-xs-12 emplist-box">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h3>Total KOL Profiles for {this.state.Project_Name}</h3><br/>
                                                    <div className="employee-actions">
                                                        <div className="table-infobox">
                                                            <p className="list-count" style={{ color:'black' , fontWeight:'bold' }}>{this.state.projectList.length} KOL's Listed</p>
                                                            <div className="filter-box float-right mb-30">
                                                                <div className="input-group">
                                                                    {/* <div className="input-group-prepend">
                                                                                <div className="input-group-text"><img src={require("../Assets/Images/search.svg")} /></div>
                                                                            </div>
                                                                            <input type="text" className="form-control" id="inlineFormInputGroupUsername" placeholder="Search by Name, project"
                                                                                onChange={(e) => { this.onTextChange(e) }}
                                                                            /> */}
                                                                </div>
                                                                <div className="input-group">
                                                                    {/* <div className="input-group-prepend">
                                                                                <div className="input-group-text"><img src={require("../Assets/Images/search.svg")} /></div>
                                                                            </div>
                                                                            <select className="form-control no-border-top no-border-left no-border-right" id="exampleFormControlSelect1"
                                                                                onChange={(e) => { this.onTextChange(e) }}
                                                                            >
                                                                                <option value="">Sort by alphabates</option>
                                                                                <option>A</option>
                                                                                <option>B</option>
                                                                                <option>C</option>
                                                                                <option>D</option>
                                                                            </select> */}
                                                                    <div className="input-group-prepend">
                                                                        <div className="input-group-text"><img src={require("../Assets/Images/search.svg")} /></div>
                                                                    </div>
                                                                    <input type="text" className="form-control" id="inlineFormInputGroupUsername" placeholder="Search by KOL Name"
                                                                        onChange={(e) => { this.onTextChange(e) }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="sort-roll">
                                                            <select className="form-control" id="exampleFormControlSelect1"
                                                                onChange={(e) => { this.onFilter(e) }}
                                                            >
                                                                <option value="">Select Status</option>
                                                                <option>Submited</option>
                                                                <option>Work In Progress</option>
                                                                <option>Approved</option>
                                                                <option>Rejected</option>
                                                                <option>Prelim</option>
                                                                <option>Prelim Approved</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <ToolkitProvider
                                                        keyField="emp_id"
                                                        data={this.state.projectList}
                                                        columns={this.state.columns}
                                                        search={this.customMatchFunc}
                                                        //exportCSV
                                                        exportCSV={{
                                                            fileName: 'cetas.csv',
                                                            noAutoBOM: false,
                                                        }}
                                                    >
                                                        {
                                                            props => (
                                                                <div>
                                                                    {/* <ExportCSVButton { ...props.csvProps }>Export CSV!!</ExportCSVButton> */}
                                                                    {/* <hr /> */}
                                                                    <BootstrapTable
                                                                        {...props.baseProps}
                                                                        // selectRow={ selectRow }
                                                                        noDataIndication="Table is Empty"
                                                                        striped
                                                                        hover
                                                                        condensed
                                                                        loading={true}
                                                                        pagination={paginationFactory(options)}
                                                                        filter={filterFactory()}
                                                                        rowStyle={ { backgroundColor: 'White' } }

                                                                    // cellEdit={ cellEditFactory({
                                                                    //     mode: 'click',
                                                                    //     blurToSave: true

                                                                    // }) }
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    </ToolkitProvider>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ResercheViewKolList;