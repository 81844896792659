import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import {
  FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, Alert,
  Container, Row, Col
} from 'reactstrap';
import axios from 'axios';
import "react-widgets/dist/css/react-widgets.css";
import { Multiselect } from 'react-widgets';
import Swal from 'sweetalert2';
import Select from 'react-select';
import LoadingBar from "react-top-loading-bar";
import { RingLoader, FadeLoader, DotLoader, PropagateLoader } from 'react-spinners';
import PhoneInput from 'react-phone-input-2';

import ManagerHeader from './ManagerHeader';

const customStyles = {
  control: base => ({
    ...base,
    height: 50,
    // minHeight: 35
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: 50,
    align: 'center',
    padding: '-1px'
  }),
  // for list of options
  // option: (provided, state) => ({
  //     ...provided,
  //     padding: 20,
  // }),
};

class CreateProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ShowProcedure: false,
      ShowBar: false,
      selectedFile: null,

      RedirectManagerDashoard: false,

      // variables from dashboard
      // idForCreateNewProject: this.props.location.state.idForCreateNewProject,
      // manager_id: this.props.location.state.manager_id,
      // manager_name: this.props.location.state.manager_name,
      idForCreateNewProject: '',
      manager_id: '',
      manager_name: '',

      // variables for create new client
      isClient: false,
      Client_Unique_id: '',
      //States for Client Add form
      ClientDataforAddClient: {
        salutation: '',
        company_nm: '',
        clientName: '',
        email_id: '',
        mb_no: '',
        designation: '',
        linkedInProfile: '',

        speciality_id: '',
        sub_speciality_id: '',
        procedure_id: '',
        role: 'administrator',
      },
      SubSpecialityTypeClient: [],
      SubSpecialityTypeClientName: [],
      ProcedureTypeClient: [],
      ProcedureTypeClientName: [],
      //states for error messages
      ComponynmError: '',
      email_idError: '',
      clientNameError: '',
      mb_noError: '',
      clientSalutationErr: '',
      ClientRoleErr: '',
      clientDesignationErr: '',
      clientLinkedInProfileErr: '',

      // AutoComplete states
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: "",
      suggestions: [],

      selectedOptionCompany: '',
      selectedOptionClient: '',

      selectedOptionTeamMembers: '',

      companynameforcreate: '',

      TeamMembersName: '',
      // to get data from dropdown
      EngagementTypeData: [],
      SpecialityData: [],
      NoOfParticipantsData: [],
      EmployeeListData: [],
      managerListData: [],
      teamMembersData: [],
      ProcedureData: [],
      SubSpecialityData: [],
      CountryListData: [],
      CompanyListData: [],
      ClientData: [],
      ProcedureDataForClient: [],
      SubSpecialityDataForClient: [],
      reportingManagerListData: [],

      // To set form values
      ProjectName: '',
      CompanyName: '',
      ClientName: '',
      RequiredKOLProfile: '',
      ProjectScope: '',
      Market: [],
      StartDate: '',
      EndDate: '',
      ProjectPlan: '',
      ProjectPraposal: '',
      ProjectSow: '',
      EventName: '',
      EngagementType: '',
      ProcedureType: '',
      SubSpecialityType: '',
      Speciality: '',
      NoOfParticipants: '',
      ProjectManager: '',
      ReportingManagerName: '',
      ReportingManagerEmail: '',
      TeamMembers: [],
      SubmitedBy: '',

      // toggle report manager email
      showReprtingManagerEmail: false,

      // to extract file names
      ProjectPlanFileName: '',
      ProjectPraposalFileName: '',
      ProjectSowFileName: '',

      // To set validation errors
      pnameErr: '',
      cmpnynameErr: '',
      clientnameErr: '',
      requirepErr: '',
      pscopeErr: '',
      marketErr: '',
      sdateErr: '',
      edateErr: '',
      // pplanErr: '',
      // ppraposalErr: '',
      psowErr: '',
      // enameErr: '',
      etypeErr: '',
      // ptypeErr: '',
      // specialityErr: '',
      // nofparticipantsErr: '',
      // pmanagerErr: '',
      tmemberErr: '',
      // sbyErr: '',
      reportingManagErr: '',

      value: [],
      selectedValue: [],
      options: [
        { id: 1, name: 'orange' },
        { id: 2, name: 'purple' },
        { id: 3, name: 'red' },
        { id: 4, name: 'blue' },
      ]
    };
  }

  validate = () => {
    let pnameErr = '';
    let cmpnynameErr = '';
    let clientnameErr = '';
    let requirepErr = '';
    let pscopeErr = '';
    let marketErr = '';
    let sdateErr = '';
    let edateErr = '';
    // let pplanErr = '';
    // let ppraposalErr = '';
    let psowErr = '';
    // let enameErr = '';
    let etypeErr = '';
    // let ptypeErr ='';
    // let specialityErr = '';
    // let nofparticipantsErr = '';
    // let pmanagerErr = '';
    let tmemberErr = '';
    let reportingManagErr = '';
    // let sbyErr = '';


    if (!this.state.ProjectName) {
      pnameErr = 'Please Enter Project Name';
    }
    if (!this.state.CompanyName) {
      cmpnynameErr = 'Please Select Company Name';
    }
    if (!this.state.ClientName) {
      clientnameErr = 'Please Select Client';
    }
    if (!this.state.RequiredKOLProfile) {
      requirepErr = 'Please Enter Required KOL Profiles';
    }
    if (!this.state.ProjectScope) {
      pscopeErr = 'Please Enter Project Scope';
    }
    if (this.state.Market.length <= 0) {
      marketErr = 'Please Select Market';
    }
    if (!this.state.StartDate) {
      sdateErr = 'Please Enter Start date';
    }
    if (!this.state.EndDate) {
      edateErr = 'Please Enter End date';
    }
    // if(!this.state.ProjectPlan)  {
    //     pplanErr = 'Please Upload Project plan';
    // }
    // if(!this.state.ProjectPraposal)  {
    //     ppraposalErr = 'Please Upload Project Praposal';
    // }
    if (!this.state.ProjectSow) {
      psowErr = 'Please Upload Project Sow';
    }
    if (this.state.EventName) {
      // enameErr = 'Please Enter Event Name';
      if (!this.state.EngagementType) {
        etypeErr = 'Please select Engagement type';
      }
    }

    // if(!this.state.ProcedureType)  {
    //     ptypeErr = 'Please select Procedute type';
    // }
    // if(!this.state.Speciality)  {
    //     specialityErr = 'Please select Speciality type';
    // }
    // if(!this.state.NoOfParticipants)  {
    //     nofparticipantsErr = 'Please select No of Participants';
    // }
    // if(!this.state.ProjectManager)  {
    //     pmanagerErr = 'Please select Project Manager';
    // }
    if (this.state.TeamMembers.length <= 0) {
      tmemberErr = 'Please select Team Members';
    }
    // if(!this.state.SubmitedBy)  {
    //     sbyErr = 'Please Enter name';
    // }
    if (!this.state.ReportingManagerName) {
      reportingManagErr = 'Please Select Reporting Manager Name';
    }



    if (pnameErr || cmpnynameErr || clientnameErr || requirepErr || pscopeErr || marketErr || sdateErr || edateErr ||
      psowErr || etypeErr || tmemberErr || reportingManagErr) {
      this.setState({
        pnameErr, cmpnynameErr, clientnameErr, requirepErr, pscopeErr, marketErr, sdateErr,
        edateErr, psowErr, etypeErr, tmemberErr, reportingManagErr
      });
      return false;
    }
    return true;
  }

  validateSave = () => {
    let pnameErr = '';
    let psowErr = '';

    if (!this.state.ProjectName) {
      pnameErr = 'Please Enter Project Name';
    }
    if (!this.state.ProjectSow) {
      psowErr = 'Please Upload Project Sow';
    }

    if (pnameErr || psowErr) {
      this.setState({ pnameErr, psowErr });
      return false;
    }
    return true;
  }

  componentDidMount = async () => {
  //   // Quote Session start

  //   // sessionStorage.setItem("quote_client_id",row.client_id);
  //   // sessionStorage.setItem("quote_client_name",row.client_name);
  //   // sessionStorage.setItem("quote_speciality_id",row.speciality_id);
  //   // sessionStorage.setItem("quote_procedure_type_id",row.procedure_type_id);
  //   // sessionStorage.setItem("quote_country_id",row.country_id);
  //   // sessionStorage.setItem("quote_no_of_kol",row.no_of_kol);
    
  //   let getSession_quote_client_id = await sessionStorage.getItem('quote_client_name')
  //  // alert(getSession_quote_client_id);
  //   if (getSession_quote_client_id !== null) {
  //     await this.setState({
  //      // selectedOptionClient: "BLUOPAL ADVISORS",
  //      selectedOptionCompany:'Bluopal',
  //     })
  //   }

  // alert(this.state.selectedOptionCompany);

  //   let getSession_quote_speciality_id = await sessionStorage.getItem('quote_speciality_id')
  //   if (getSession_quote_speciality_id !== null) {
  //     await this.setState({
  //       Speciality: getSession_quote_speciality_id,
  //     })

  //     //alert(getSession_quote_speciality_id);
  //   }

    
  //   let getSession_quote_procedure_type_id = await sessionStorage.getItem('quote_procedure_type_id')
  //   if (getSession_quote_procedure_type_id !== null) {
  //     await this.setState({
  //       procedure_id: getSession_quote_procedure_type_id,
  //     })
  //   }

  //   let getSession_quote_country_id = await sessionStorage.getItem('quote_country_id')
  //   if (getSession_quote_country_id !== null) {
  //     await this.setState({
  //       country: getSession_quote_country_id,
  //     })
  //   }

    
  //   let getSession_quote_no_of_kol = await sessionStorage.getItem('quote_no_of_kol')
  //   if (getSession_quote_no_of_kol !== null) {
  //     await this.setState({
  //       RequiredKOLProfile: getSession_quote_no_of_kol,
  //     })
  //   }
  //   // Quote Session end
    
    
    let getSession_Id_For_Create_New_Project = await sessionStorage.getItem('idForCreateNewProject')
    if (getSession_Id_For_Create_New_Project !== null) {
      await this.setState({
        idForCreateNewProject: getSession_Id_For_Create_New_Project,
      })
    }
    let getSession_Manager_Name = await sessionStorage.getItem('name')
    if (getSession_Manager_Name !== null) {
      await this.setState({
        manager_name: getSession_Manager_Name,
      })
    }
    let getSession_Manager_ID = await sessionStorage.getItem('id')
    if (getSession_Manager_ID !== null) {
      await this.setState({
        manager_id: getSession_Manager_ID,
      })
    }

    this.setState({
      ShowBar: true,
    })

    const options = {
      method: 'POST',
    }

    // Api to get engagement type data.
    // fetch(global.websiteUrl+'/service/EngagementType/get_EngagementTypeList', options)
    // .then((Response) => Response.json())
    // //   .then((Response) => Response.text())
    // .then((findresponse)=>
    // {
    //     console.log(findresponse)
    //     var testDataEngagement= findresponse.data;
    //     console.log("testDataEngagement",JSON.stringify(testDataEngagement));
    //     this.setState({
    //         EngagementTypeData:findresponse.data,
    //     })        
    // })

    //  Speciality type data
    fetch(global.websiteUrl+'/service/Speciality/get_SpecialityList', options)
      .then((Response) => Response.json())
      .then((findresponse) => {
        console.log(findresponse)
        var testDataSpeciality = findresponse.data;
        console.log("testDataSpeciality", JSON.stringify(testDataSpeciality));
        this.setState({
          SpecialityData: findresponse.data,
        })
      })

    // No of participants
    // fetch(global.websiteUrl+'/service/No_Of_Participants/get_no_of_ParticipantsList', options)
    // .then((Response) => Response.json())
    // .then((findresponse)=>
    // {
    //     console.log(findresponse)
    //     var testDataNoOfParticipants= findresponse.data;
    //     console.log("testDataNoOfParticipants",JSON.stringify(testDataNoOfParticipants));
    //     this.setState({
    //         NoOfParticipantsData:findresponse.data,
    //     })        
    // })

    // Employee Details / List
    fetch(global.websiteUrl+'/service/Employee/get_EmployeeList', options)
      .then((Response) => Response.json())
      .then((findresponse) => {
        console.log(findresponse)
        var testDataEmployeeList = findresponse.Employee_Details;
        console.log("testDataEmployeeList", JSON.stringify(testDataEmployeeList));
        this.setState({
          EmployeeListData: findresponse.Employee_Details,
        })

        // To get single column values
        testDataEmployeeList.forEach(element => {
          if (element.role_type == "project manager") {
            var joined = this.state.managerListData.concat(element);
            this.setState({
              managerListData: joined
            })
            console.log('managerListData', this.state.managerListData);
          }
        })
        testDataEmployeeList.forEach(element => {
          if (element.role_type == "researcher") {
            var joined = this.state.teamMembersData.concat(element);
            this.setState({
              teamMembersData: joined
            })
            console.log('teamMembersData', this.state.teamMembersData);
          }
        })
        testDataEmployeeList.forEach(element => {
          if (element.role_type == "reporting manager") {
            var joined = this.state.reportingManagerListData.concat(element);
            this.setState({
              reportingManagerListData: joined
            })
            console.log('reportingManagerListData', this.state.reportingManagerListData);
          }
        })
      })

    // Country list(market) 
    fetch(global.websiteUrl+'/service/Location/get_CountryList', options)
      .then((Response) => Response.json())
      .then((findresponse) => {
        console.log(findresponse)
        var testDataCountryList = findresponse.Country_Details;
        console.log("testDataCountryList", JSON.stringify(testDataCountryList));
        this.setState({
          CountryListData: findresponse.Country_Details,
          Client_Unique_id: findresponse.client_system_id,
        })
        console.log("Client_Unique_id", this.state.Client_Unique_id);
      })

    // Company list fetch
    fetch(global.websiteUrl+'/service/Client/get_CompanyList', options)
      .then((Response) => Response.json())
      .then((findresponse) => {
        console.log(findresponse)
        var testDataCompanyList = findresponse.Employee_Details;
        console.log("testDataCompanyList", JSON.stringify(testDataCompanyList));
        this.setState({
          CompanyListData: findresponse.Employee_Details,
          suggestions: findresponse.Employee_Details,
        })
        console.log("CompanyListData", this.state.CompanyListData);
        this.setState({
          ShowBar: false,
        })
      })

  }

  // On file select (from the pop up) 
  onFileChangeProjectPlan = event => {
    // Update the state 
    const filesFormats=[".doc",".docx",".xls",".xlsx","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","sheet","wordprocessingml","application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/pdf"];
    const isRightFormat= filesFormats.includes(event.target.files[0].type)
    if(isRightFormat)
    {
        this.setState({
          ProjectPlan: event.target.files[0],
          ProjectPlanFileName: event.target.files[0].name,
          pplanErr:'',
        });
  }
  else
  {
    this.setState({
      pplanErr:"only .doc,.docx,.xls,.xlsx,.pdf file allowed",
                               
    })
  }
    
  };

  onFileChangeProjectPraposal = event => {
    const filesFormats=[".doc",".docx",".xls",".xlsx","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","sheet","wordprocessingml","application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/pdf"];
    const isRightFormat= filesFormats.includes(event.target.files[0].type)
    if(isRightFormat)
    {
    this.setState({
      ProjectPraposal: event.target.files[0],
      ProjectPraposalFileName: event.target.files[0].name,
      ppraposalErr:'',
    });
  }
  else
  {
    this.setState({
      ppraposalErr:"only .doc,.docx,.xls,.xlsx,.pdf file allowed",
                               
    })
  }
  

  }

  onFileChangeProjectSow = event => {
    const filesFormats=[".doc",".docx",".xls",".xlsx","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","sheet","wordprocessingml","application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/pdf"];
    const isRightFormat= filesFormats.includes(event.target.files[0].type)
    if(isRightFormat)
    {
    this.setState({
      ProjectSow: event.target.files[0],
      ProjectSowFileName: event.target.files[0].name,
      psowErr:'',
    });

  }
    else
  {
    this.setState({
      psowErr:"only .doc,.docx,.xls,.xlsx,.pdf file allowed",
                               
    })
  }
  
  }

  onFileUpload = () => {
    // Create an object of formData 
    // const formData = new FormData(); 

    // // Update the formData object 
    // formData.append( 
    //     "myFile", 
    //     this.state.selectedFile, 
    //     this.state.selectedFile.name 
    // ); 

    // // Details of the uploaded file 
    // console.log(this.state.selectedFile); 

    // // Request made to the backend api 
    // // Send formData object 
    // axios.post("api/uploadfile", formData); 
  };

  submit() {
    console.log("Project id", this.state.idForCreateNewProject);
    console.log("Project Name", this.state.ProjectName);
    console.log("Project Scope", this.state.ProjectScope);
    console.log("market", this.state.Market);
    console.log("StartDate", this.state.StartDate);
    console.log("EndDate", this.state.EndDate);
    console.log("ProjectPlan", this.state.ProjectPlan);
    console.log("ProjectPraposal", this.state.ProjectPraposal);
    console.log("ProjectSow", this.state.ProjectSow);
    console.log("EventName", this.state.EventName);
    console.log("EngagementType", this.state.EngagementType);
    console.log("ProcedureType", this.state.ProcedureType);
    console.log("SubSpecialityType", this.state.SubSpecialityType);
    console.log("Speciality", this.state.Speciality);
    console.log("NoOfParticipants", this.state.NoOfParticipants);
    console.log("ProjectManager", this.state.manager_id);
    console.log("TeamMembers", this.state.TeamMembers);
    console.log("SubmitedBy", this.state.SubmitedBy);

    const isValid = this.validate();
    if (isValid) {
      this.setState({
        ShowProcedure: true,
      })
      const url = global.websiteUrl+'/service/Project/project_curd';

      let formData = new FormData();

      formData.append('project_id', this.state.idForCreateNewProject);
      formData.append('p_name', this.state.ProjectName);
      formData.append('company_nm', this.state.CompanyName);
      formData.append('client_unique_id', this.state.ClientName);
      formData.append('required_kol_profile', this.state.RequiredKOLProfile);
      formData.append('market', this.state.Market);
      formData.append('project_scope', this.state.ProjectScope);
      formData.append('start_date', this.state.StartDate);
      formData.append('end_date', this.state.EndDate);
      formData.append('project_plan', this.state.ProjectPlan);
      formData.append('project_proposal', this.state.ProjectPraposal);
      formData.append('project_sow', this.state.ProjectSow);
      formData.append('event_name', this.state.EventName);
      formData.append('engagement_type', this.state.EngagementType);
      
      formData.append('procedure_type_id', this.state.ProcedureType);
      formData.append('sub_speciality_id', this.state.SubSpecialityType);
      formData.append('speciality_id', this.state.Speciality);
      
      formData.append('no_of_participant', this.state.NoOfParticipants);
      formData.append('project_manager_id', this.state.manager_id);
      formData.append('team_members_id', this.state.TeamMembers);
      formData.append('submitted_by_id', this.state.SubmitedBy);
      // formData.append('project_status',"new");
      formData.append('project_status', "Ongoing");
      formData.append('threauptic_area', "area");

      formData.append('shortlisted_respondents', "");
      formData.append('finalist_respondents', "");
      formData.append('comments', "");
      formData.append('project_documents_upload', "");

      formData.append('reporting_manager_name', this.state.ReportingManagerName);
      formData.append('reporting_manager_email', this.state.ReportingManagerEmail);

      formData.append('oper', "add");


      const config = {
        //   headers: { 'content-type': 'multipart/form-data' }
      }

      axios.post(url, formData, config)
        .then(response => {
          console.log(response);
          var lgdata = response.data;
          console.log("lgdata", JSON.stringify(lgdata));
          var lgstatus = response.data.status;
          console.log("lgstatus", JSON.stringify(lgstatus));
          console.log("response message2", response.data.message)
          // if( lgstatus == true ){
          //     alert("Project created Successfully!")
          // }
          // else if(lgstatus == false){
          //     alert("Sorry!!!Something went wrong")
          // }
          this.setState({
            ShowProcedure: false,
          })
          if (response.data.status == true) {

            this.Update_login_Type();

            Swal.fire({
              icon: 'success',
              text: response.data.message,             
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })

            
            this.setState({ RedirectManagerDashoard: true })
            this.setState({
              pnameErr: '',
              cmpnynameErr: '',
              clientnameErr: '',
              requirepErr: '',
              pscopeErr: '',
              marketErr: '',
              sdateErr: '',
              edateErr: '',
              // pplanErr: '',
              // ppraposalErr: '',
              psowErr: '',
              // enameErr: '',
              etypeErr: '',
              // ptypeErr: '',
              // specialityErr: '',
              // nofparticipantsErr: '',
              // pmanagerErr: '',
              tmemberErr: '',
              // sbyErr: '',
              reportingManagErr: '',
      
              ProjectName: '',
              CompanyName: '',
              ClientName: '',
              RequiredKOLProfile: '',
              ProjectScope: '',
              Market: [],
              StartDate: '',
              EndDate: '',
              ProjectPlan: '',
              ProjectPraposal: '',
              ProjectSow: '',
              EventName: '',
              EngagementType: '',
              ProcedureType: '',
              SubSpecialityType: '',
              Speciality: '',
              NoOfParticipants: '',
              ProjectManager: '',
              TeamMembers: [],
              SubmitedBy: '',
            });
          }
          else {
            Swal.fire({
              icon: 'error',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
        })
        .catch(error => {
          console.log(error);
        });

      
    }
  }


Update_login_Type()
{
  //alert(this.state.selectedOptionClient.value);

  var isValid=true;
  if (isValid) {
    this.setState({
      ShowProcedure: true,
    })
    const url = global.websiteUrl+'/service/Project/Update_login_type';

    let formData = new FormData();
    
    formData.append('client_id', this.state.selectedOptionClient.value);
    formData.append('login_type', 'client');
    
    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }

    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        // var lgdata = response.data;
        // console.log("lgdata", JSON.stringify(lgdata));
        // var lgstatus = response.data.status;
        // console.log("lgstatus", JSON.stringify(lgstatus));
        // console.log("response message2", response.data.message)
     

        // alert(response.data.status);
        // if (response.data.status == true) {

        //   Swal.fire({
        //     icon: 'success',
        //     //text: response.data.message,
        //     text: 'Data saved Successfully!',
        //     confirmButtonColor: '#d33',
        //     confirmButtonText: 'OK'
        //   })
         
        // }
      
      })
      .catch(error => {
        console.log(error);
      });

  }
}


  save() {
    console.log("this.state.ReportingManagerNAme====", this.state.ReportingManagerName)
    console.log("this.state.Reporting manager Emial====", this.state.ReportingManagerEmail)
    const isValid = this.validateSave();
    if (isValid) {
      this.setState({
        ShowProcedure: true,
      })
      const url = global.websiteUrl+'/service/Project/project_curd';

      let formData = new FormData();

      formData.append('project_id', this.state.idForCreateNewProject);
      formData.append('p_name', this.state.ProjectName);
      formData.append('company_nm', this.state.CompanyName);
      formData.append('client_unique_id', this.state.ClientName);
      formData.append('required_kol_profile', this.state.RequiredKOLProfile);
      formData.append('market', this.state.Market);
      formData.append('project_scope', this.state.ProjectScope);
      formData.append('start_date', this.state.StartDate);
      formData.append('end_date', this.state.EndDate);
      formData.append('project_plan', this.state.ProjectPlan);
      formData.append('project_proposal', this.state.ProjectPraposal);
      formData.append('project_sow', this.state.ProjectSow);
      formData.append('event_name', this.state.EventName);
      formData.append('engagement_type', this.state.EngagementType);
      formData.append('procedure_type_id', this.state.ProcedureType);
      formData.append('sub_speciality_id', this.state.SubSpecialityType);
      formData.append('speciality_id', this.state.Speciality);



      formData.append('no_of_participant', this.state.NoOfParticipants);
      formData.append('project_manager_id', this.state.manager_id);
      formData.append('team_members_id', this.state.TeamMembers);
      formData.append('submitted_by_id', this.state.SubmitedBy);
      // formData.append('project_status',"work in progress");
      formData.append('project_status', "Upcoming");
      formData.append('threauptic_area', "area");

      formData.append('shortlisted_respondents', "");
      formData.append('finalist_respondents', "");
      formData.append('comments', "");
      formData.append('project_documents_upload', "");

      formData.append('reporting_manager_name', this.state.ReportingManagerName);
      formData.append('reporting_manager_email', this.state.ReportingManagerEmail);

      formData.append('oper', "add");

      const config = {
        //   headers: { 'content-type': 'multipart/form-data' }
      }

      axios.post(url, formData, config)
        .then(response => {
          console.log(response);
          var lgdata = response.data;
          console.log("lgdata", JSON.stringify(lgdata));
          var lgstatus = response.data.status;
          console.log("lgstatus", JSON.stringify(lgstatus));
          console.log("response message2", response.data.message)
          // if( lgstatus == true ){
          //     alert("Project created Successfully!")
          // }
          // else if(lgstatus == false){
          //     alert("Sorry!!!Something went wrong")
          // }
          this.setState({
            ShowProcedure: false,
          })
          if (response.data.status == true) {

            Swal.fire({
              icon: 'success',
              //text: response.data.message,
              text: 'Data saved Successfully!',
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
            this.setState({ RedirectManagerDashoard: true })
          }
          else {
            Swal.fire({
              icon: 'error',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
        })
        .catch(error => {
          console.log(error);
        });

      this.setState({
        pnameErr: '',
        psowErr: '',
      });
    }
  }

  cancel() {
    this.setState({
      RedirectManagerDashoard: true,
    })
  }

  handleChange = (event) => {
    this.setState({
      TeamMembers: event.target.value
    });
    console.log("TeamMembers", this.state.TeamMembers)
  }

  getProcedureList() {
    console.log("in Procedure list");
    console.log("speciality_type_id", this.state.Speciality);

    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
    let formData = new FormData();
    formData.append('speciality_type_id', this.state.Speciality);
    formData.append('search_type', "Procedure");

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var proceduredata = response.data;
        console.log("proceduredata", JSON.stringify(proceduredata));
        // this.setState({
        //    ProcedureData : response.data.data,
        // }) 
        if (response.data.status == true) {
          this.setState({
            ProcedureData: response.data.data,
          })
        }
        else if (response.data.status == false) {
          this.setState({
            ProcedureData: [],
          })
        }
        // console.log("ProcedureData",this.state.ProcedureData)
      })
      .catch(error => {
        console.log(error);
      });
  }

  getSubSpecialityList() {
    console.log("in Procedure list");
    console.log("speciality_type_id", this.state.Speciality);

    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
    let formData = new FormData();
    formData.append('speciality_type_id', this.state.Speciality);
    formData.append('search_type', "Sub Speciality");

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var SubSpecialityData = response.data;
        console.log("SubSpecialityData", JSON.stringify(SubSpecialityData));
        // this.setState({
        //    SubSpecialityData : response.data.data,
        // }) 
        if (response.data.status == true) {
          this.setState({
            SubSpecialityData: response.data.data,
          })
        }
        else if (response.data.status == false) {
          this.setState({
            SubSpecialityData: [],
          })
        }
        console.log("SubSpecialityData====", this.state.SubSpecialityData)
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleChangeCompany = async (selectedOptionCompany) => {
    this.fetchComponyList();
    this.setState({ selectedOptionCompany });
    console.log(`Option selected:`, selectedOptionCompany);

    let Company_name = selectedOptionCompany.value;
    await this.setState({ CompanyName: Company_name, selectedOptionClient: '' });
    console.log("CompanyName", this.state.CompanyName);

    if (selectedOptionCompany !== '') {
       //{ this.getClientList() }
      this.getClientList();

    }
  };

  handleChangeTeamMembers = async (selectedOptionTeamMembers) => {
    // this.fetchComponyList();
    this.setState({ selectedOptionTeamMembers });
    console.log(`Option selected:`, selectedOptionTeamMembers);

    let Team_member = selectedOptionTeamMembers.value;
    await this.setState({ TeamMembers: Team_member, selectedOptionTeamMembers: '' });
    console.log("TeamMembers", this.state.TeamMembers);

    // if( selectedOptionCompany != ''  ){
    //     {this.getClientList()} 
    // }
  };

  getClientList = () => {
    console.log("in client list")
    console.log("company_name", this.state.CompanyName);

    const url = global.websiteUrl+'/service/Client/get_ClientList';
    let formData = new FormData();
    formData.append('company_nm', this.state.CompanyName);

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }

    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var clientListData = response.data.Employee_Details;
        console.log("clientListData", JSON.stringify(clientListData));
        this.setState({
          ClientData: response.data.Employee_Details,
        })
        console.log("ClientData", this.state.ClientData)
      })
      .catch(error => {
        console.log(error);
      });

     
  }

  handleChangeClient = async (selectedOptionClient) => {
    this.setState({ selectedOptionClient });
    console.log(`Option selected:`, selectedOptionClient);

    let Client_name = selectedOptionClient.value;
    await this.setState({ ClientName: Client_name });
    console.log("ClientName", this.state.ClientName);
  };

  // Create new client All functions from here
  toggleCreateNewClient = () => {
    console.log('Client Form')
    this.setState({
      isClient: !this.state.isClient,
      ComponynmError: null,
      email_idError: null,
      clientNameError: null,
      mb_noError: null,
      clientSalutationErr: null,
      clientRoleErr: null,
      clientDesignationErr: null,
      clientLinkedInProfileErr: null,
    })
  }

  CreateClient() {
    if (this.state.ClientDataforAddClient.role == "administrator") {
      this.setState({
        ClientDataforAddClient: {
          linkedInProfile: '',
          speciality_id: '',
          sub_speciality_id: '',
          procedure_id: '',
        }
      })
    }
    console.log("in Create Client", this.state.ClientDataforAddClient);
    console.log("ID", this.state.Client_Unique_id);
    console.log("Name", this.state.ClientDataforAddClient.clientName);
    console.log("user input Name", this.state.userInput);
    console.log("role", this.state.ClientDataforAddClient.role)





    const url = global.websiteUrl+'/service/Client/create_client';
    let formData = new FormData();
    formData.append('login_type', this.state.ClientDataforAddClient.role);
    formData.append('company_nm', this.state.userInput);
    formData.append('client_id', this.state.Client_Unique_id);

    if (this.state.ClientDataforAddClient.salutation !== "Other") {
    
    formData.append('salutation', this.state.ClientDataforAddClient.salutation);
    }
    
    formData.append('client_name', this.state.ClientDataforAddClient.clientName);
    formData.append('email_id', this.state.ClientDataforAddClient.email_id);
    formData.append('mobile_no', this.state.ClientDataforAddClient.mb_no);
    formData.append('designantion', this.state.ClientDataforAddClient.designation);
    formData.append('linkedIn_profile', this.state.ClientDataforAddClient.linkedInProfile);
    formData.append('specialty_id', this.state.ClientDataforAddClient.speciality_id);
    formData.append('sub_specialty_id', this.state.ClientDataforAddClient.sub_speciality_id);
    formData.append('procedure_type_id', this.state.ClientDataforAddClient.procedure_id);

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var ClientDataforAddClient = response.data;
        console.log("clientStatus", JSON.stringify(ClientDataforAddClient));

        var clientstatus = response.data.status;
        var message = response.data.message;
        console.log("message", message)
        this.CompanyRefresh();
        if (clientstatus == true) {
          //    this.fetchComponyList();
          Swal.fire({
            icon: 'success',
            text: message,
            // text: 'Wrong Username or Password!',
            confirmButtonColor: '#A90007',
            confirmButtonText: 'OK'
          })

          
          if (this.state.ClientDataforAddClient.role === "client") {
            this.refreshClientCount();
          }
          window.location.reload();
        }
        else {
          Swal.fire({
            icon: 'error',
            text: message,
            // text: 'Wrong Username or Password!',
            confirmButtonColor: '#A90007',
            confirmButtonText: 'OK'
          })
        }
        this.setState({
          isClient: false,
          userInput: '',
          ClientDataforAddClient: {
            role: '',
            clientName: '',
            email_id: '',
            mb_no: '',
          }
        });
        this.fetchComponyList();
        
      })
      .catch(error => {
        console.log(error);
      });
    this.CompanyRefresh();
    this.fetchComponyList();
    this.getClientList();
    

  }

  refreshClientCount() {
    console.log("in refresh client id")

    const options = {
      method: 'POST',
    }

    fetch(global.websiteUrl+'/service/Location/get_CountryList', options)
      .then((Response) => Response.json())
      .then((findresponse) => {
        console.log(findresponse)
        var testDataCountryList = findresponse.Country_Details;
        console.log("testDataCountryList", JSON.stringify(testDataCountryList));
        this.setState({
          CountryListData: findresponse.Country_Details,
          Client_Unique_id: findresponse.client_system_id,
        })
        console.log("Client_Unique_id", this.state.Client_Unique_id);
      })
  }

  CompanyRefresh() {
    console.log("in refresh company")

    const options = {
      method: 'POST',
    }
    // Company list fetch
    fetch(global.websiteUrl+'/service/Client/get_CompanyList', options)
      .then((Response) => Response.json())
      .then((findresponse) => {
        console.log(findresponse)
        var testDataCompanyList = findresponse.Employee_Details;
        console.log("testDataCompanyList", JSON.stringify(testDataCompanyList));
        this.setState({
          CompanyListData: findresponse.Employee_Details,
        })
        console.log("CompanyListData", this.state.CompanyListData);
      })
  }

  fetchComponyList() {
    console.log(' Fetching company list');
    try {
      console.log(' Fetching company list');
      // Refershing Company list

      const options = {
        method: 'POST',
      }

      fetch(global.websiteUrl+'/service/Client/get_CompanyList', options)
        .then((Response) => Response.json())
        .then((findresponse) => {
          console.log(findresponse)
          var testDataCompanyList = findresponse.Employee_Details;
          console.log("testDataCompanyList", JSON.stringify(testDataCompanyList));
          this.setState({
            // CompanyListData: findresponse.Employee_Details,
            suggestions: findresponse.Employee_Details,
          })
          console.log("CompanyListData", this.state.CompanyListData);
          this.setState({
            ShowBar: false,
          })
        })

    } catch {

    }
  }

  getProcedureListForClient() {
    console.log("in Procedure list for client");
    console.log("speciality_type_id", this.state.ClientDataforAddClient.speciality_id);

    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
    let formData = new FormData();
    formData.append('speciality_type_id', this.state.ClientDataforAddClient.speciality_id);
    formData.append('search_type', "Procedure");

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var proceduredataforClient = response.data;
        console.log("proceduredataforClient", JSON.stringify(proceduredataforClient));
        // this.setState({
        //    ProcedureDataForClient : response.data.data,
        // }) 
        if (response.data.status == true) {
          this.setState({
            ProcedureDataForClient: response.data.data,
          })
        }
        else if (response.data.status == false) {
          this.setState({
            ProcedureDataForClient: [],
          })
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  getSubSpecialityListForClient() {
    console.log("in Procedure list for client");
    console.log("speciality_type_id", this.state.ClientDataforAddClient.speciality_id);

    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
    let formData = new FormData();
    formData.append('speciality_type_id', this.state.ClientDataforAddClient.speciality_id);
    formData.append('search_type', "Sub Speciality");

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var SubSpecialityDataForClient = response.data;
        console.log("SubSpecialityDataForClient", JSON.stringify(SubSpecialityDataForClient));
        // this.setState({
        //    SubSpecialityDataForClient : response.data.data,
        // }) 
        if (response.data.status == true) {
          this.setState({
            SubSpecialityDataForClient: response.data.data,
          })
        }
        else if (response.data.status == false) {
          this.setState({
            SubSpecialityDataForClient: [],
          })
        }
        console.log("SubSpecialityDataForClient====", this.state.SubSpecialityDataForClient)
      })
      .catch(error => {
        console.log(error);
      });
  }

  ////////////////////////////////////AUTOCOMPLETE FUNCTIONS /////////////////////////////////////////////////////
  // Event fired when the input value is changed
  onChange = e => {
    const { suggestions } = this.state;
    //const userInput = e.currentTarget.value;

    console.log("company names", suggestions)
    //// Filter our suggestions that don't contain the user's input
    //const filteredSuggestions = suggestions.filter(suggestion => 
     // suggestion.company_nm.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    //);

    // Update the user input and filtered suggestions, reset the active
    // suggestion and make sure the suggestions are shown
    this.setState({
     // activeSuggestion: 0,
      //filteredSuggestions,
      //showSuggestions: true,
      userInput: e.currentTarget.value
    });
  };

  // Event fired when the user clicks on a suggestion
  onClick = e => {
    // Update the user input and reset the rest of the state
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText
    });
  };

  // Event fired when the user presses a key down
  onKeyDown = e => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    // User pressed the enter key, update the input and close the
    // suggestions
    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion].company_nm
      });
    }
    // User pressed the up arrow, decrement the index
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  handlesubmit(e) {
    e.preventDefault();

    if (this.handleValidation()) {
      this.setState({
        ComponynmError: null,
        email_idError: null,
        clientNameError: null,
        mb_noError: null,
        clientSalutationErr: null,
        clientRoleErr: null,
        clientDesignationErr: null,
        clientLinkedInProfileErr: null,
      })
      this.CreateClient();
    } else {
      // alert("Form has error");
      console.log('Form Has Errors')
    }
  }

  handleValidation() {

    let ComponynmError = "";
    let clientNameError = "";
    let email_idError = "";
    let clientSalutationErr = '';
    let clientRoleErr = '';
    let clientDesignationErr = '';
    let clientLinkedInProfileErr = '';
    let formIsValid = true;

    console.log(this.state.ClientDataforAddClient.clientName);
    console.log(this.state.ClientDataforAddClient.email_id);
    console.log(this.state.ClientDataforAddClient.mb_no);
    console.log(this.state.ClientDataforAddClient.salutation);
    console.log(this.state.ClientDataforAddClient.role);

    // Salutation
    // if (!this.state.ClientDataforAddClient.salutation) {

    //     let { ClientDataforAddClient } = this.state;
    //     ClientDataforAddClient.salutation = ' '
    //     this.setState({ ClientDataforAddClient })

    // }
    if (!this.state.ClientDataforAddClient.salutation) {
      formIsValid = false;
      clientSalutationErr = "Please Select Salutation";

      if (clientSalutationErr) {
        this.setState({ clientSalutationErr })
      }
    } else {
      this.setState({ clientSalutationErr: null })
    }

    if (!this.state.ClientDataforAddClient.role) {
      formIsValid = false;
      clientRoleErr = "Please Select Role";
      if (clientRoleErr) {
        this.setState({ clientRoleErr })
      }
    } else {
      this.setState({ clientRoleErr: null })
    }


    // Company Name
    if (!this.state.userInput) {
      formIsValid = false;
      ComponynmError = "Please Enter Company Name";

      if (ComponynmError) {
        this.setState({ ComponynmError })
      }
    } else {
      this.setState({ ComponynmError: null })
    }

    //Client Name
    if (!this.state.ClientDataforAddClient.clientName) {
      formIsValid = false;
      clientNameError = "Please Enter Client Name";

      if (clientNameError) {
        this.setState({ clientNameError })
      }
    } else if (!this.state.ClientDataforAddClient.clientName.match(/^[a-zA-Z ]+$/)) {
      formIsValid = false;
      clientNameError = "Only letters";

      if (clientNameError) {
        this.setState({ clientNameError })
      }
    } else {
      this.setState({ clientNameError: null })
    }

    //Email
    if (!this.state.ClientDataforAddClient.email_id) {
      formIsValid = false
      email_idError = 'Please Enter Email ID'

      if (email_idError) {
        this.setState({ email_idError })
      }
    } else if (typeof this.state.ClientDataforAddClient.email_id !== "undefined") {
      let lastAtPos = this.state.ClientDataforAddClient.email_id.lastIndexOf('@');
      let lastDotPos = this.state.ClientDataforAddClient.email_id.lastIndexOf('.');

      if (!(lastAtPos < lastDotPos && lastAtPos > 0)) {
        formIsValid = false;
        email_idError = "Email is not valid";
        if (email_idError) {
          this.setState({ email_idError })
        }
      } else {
        this.setState({ email_idError: null })
      }
    } else {
      this.setState({ emailError: null })
    }

    if (!this.state.ClientDataforAddClient.designation) {
      formIsValid = false;
      clientDesignationErr = "Please Enter Designation";

      if (clientDesignationErr) {
        this.setState({ clientDesignationErr })
      }
    } else {
      this.setState({ clientDesignationErr: null })
    }

    // if (this.state.ClientDataforAddClient.role === 'client') {
    //   if (!this.state.ClientDataforAddClient.linkedInProfile) {
    //     formIsValid = false;
    //     clientLinkedInProfileErr = "Please Enter LinkedIn Profile";

    //     if (clientLinkedInProfileErr) {
    //       this.setState({ clientLinkedInProfileErr })
    //     }
    //   } else {
    //     this.setState({ clientLinkedInProfileErr: null })
    //   }
    // }


    // Mobile Number
    // if (!this.state.ClientData.mb_no) {
    //     formIsValid = false;
    //     mb_noError = "Mobile Number needed";

    //     if (mb_noError) {
    //         this.setState({ mb_noError })
    //     }
    // } else if (typeof this.state.ClientData.mb_no !== "undefined") {
    //     var pattern = new RegExp(/^[0-9\b]+$/);
    //     if (!pattern.test(this.state.ClientData.mb_no)) {

    //         formIsValid = false;
    //         mb_noError = "Please enter only number.";
    //         if (mb_noError) {
    //             this.setState({ mb_noError })
    //         }

    //     } else if (this.state.ClientData.mb_no.length != 10) {

    //         formIsValid = false;
    //         mb_noError = "Please enter valid phone number.";
    //         if (mb_noError) {
    //             this.setState({ mb_noError })
    //         }

    //     }
    // } else {
    //     this.setState({ mb_noError: null })
    // }

    console.log("formIsValid", formIsValid)
    return formIsValid;
  }

  getEmailReportingManager() {
    console.log("in email reprt func=-=-=-=")
    console.log("this.state.ReportingManagerName=-=-=-=", this.state.ReportingManagerName)
    this.setState({
      showReprtingManagerEmail: false,
    })
    this.state.reportingManagerListData.map(async (data) => {
      if (data.emp_id == this.state.ReportingManagerName) {
        await this.setState({
          ReportingManagerEmail: data.email_id,
          showReprtingManagerEmail: true,
        })
        console.log('ReportingManagerEmail=-=-=-', this.state.ReportingManagerEmail);
      }
    })
  }

  BackToManagerDashboard() {
    this.setState({ RedirectManagerDashoard: true })
  }


  render() {
    if (this.state.RedirectManagerDashoard) {
      return (<Redirect to={{
        pathname: '/ManagerDashboard',
        // state: {
        //     manager_name: this.state.manager_name,
        //     manager_id: this.state.manager_id,  
        // }
      }} />)
    }
    // Engagement Type Options
    // let eng_type = this.state.EngagementTypeData;
    // let OptionsEngagementTyple = eng_type.map((eng_type) =>
    //     <option key={eng_type.id} value={eng_type.id}>
    //             {eng_type.engagement_type}</option>
    // );

    // Speciality Type options 
    let speciality_type = this.state.SpecialityData;
    let OptionsSpeciality = speciality_type.map((speciality_type) =>
      <option key={speciality_type.id} value={speciality_type.id}>
        {speciality_type.speciality_type}</option>
    );

    // No of Participants Options 
    // let no_of_participants = this.state.NoOfParticipantsData;
    // let OptionsNoOfParticipants = no_of_participants.map((no_of_participants) =>
    //     <option key={no_of_participants.id} value={no_of_participants.id}>
    //             {no_of_participants.no_of_participants}</option>
    // );

    // Employee List 
    let employee_list = this.state.EmployeeListData;
    let OptionsEmployeeList = employee_list.map((employee_list) =>
      <option key={employee_list.emp_id} value={employee_list.emp_id}>
        {employee_list.emp_name}</option>
    );

    // Manager List
    let manager_list = this.state.managerListData;
    let OptionsManagerList = manager_list.map((manager_list) =>
      <option key={manager_list.emp_id} value={manager_list.emp_id}>
        {manager_list.emp_name}</option>
    );

    // Procedure List
    let procedure_list = this.state.ProcedureData;
    let OptionProcedure = procedure_list.map((procedure_list) =>
      <option key={procedure_list.id} value={procedure_list.id}>
        {procedure_list.procedure_type}</option>
    );

    // Sub Speciality List
    let subSpeciality_list = this.state.SubSpecialityData;
    let OptionSubSpeciality = subSpeciality_list.map((subSpeciality_list) =>
      <option key={subSpeciality_list.id} value={subSpeciality_list.id}>
        {subSpeciality_list.sub_speciality_type}</option>
    );
    // Reporting  Manager list
    let reportingManager_list = this.state.reportingManagerListData;
    let OptionReportingManager = reportingManager_list.map((reportingManager_list) =>
      <option key={reportingManager_list.emp_id} value={reportingManager_list.emp_id}>
        {reportingManager_list.emp_name}</option>
    );


    // company name
    const { selectedOptionCompany } = this.state;

    //////////////////////////////////////AUTO COMPLETE AREA//////////////////////////////////////////////
    const {
      onChange,
      onClick,
      onKeyDown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput
      }
    } = this;

    let suggestionsListComponent;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul class="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }

              return (
                <li
                  className={className}
                  key={suggestion}
                  onClick={onClick}
                >
                  {suggestion.company_nm}
                </li>
              );
            })}
          </ul>
        );
      }
    }
    ////////////////////////////////////////END OF AUTO COMPLETE /////////////////////////////////////

    return (
      <body>
        <div style={{marginTop:'15vh' }}>
          {this.state.ShowBar ? <div style={{ position: 'fixed', top: '0' }}>
            <LoadingBar
              progress='100'
              // onLoaderFinished={() => setProgress(0)}
              //  progress={progress}
              height={3} color="#A90007" transitionTime={4000}
              loaderSpeed={4000} />
          </div>
            : null}

          {this.state.ShowProcedure ? <Modal isOpen={true} className='propagateLoader' centered>
            <PropagateLoader color={'#A90007'} size='40px' loading='true' />
          </Modal> : null}
          <div>
            <div>
              <ManagerHeader ManagerName={this.state.manager_name} />
              <div className="container">
                <div className="content-wrapper">
                  <div className="screen-headingbox">
                    <div className="back-section">
                      <a onClick={this.BackToManagerDashboard.bind(this)}>
                        {/* <Link to="/ManagerDashboard"> */}
                        <img
                          src={require('../Assets/Images/back-arrow.svg')}
                        />
                        Back
                        {/* </Link> */}
                      </a>
                    </div>
                    <h2>Create Project <button className="cetas-button float-right" data-toggle="modal"
                      data-target="#new-client-add" onClick={this.toggleCreateNewClient.bind(this)}>
                      Create New Client/ Administrator</button></h2>
                  </div>
                  <h3 className="mt-30 mb-30">Project Details</h3>
                  <div className="row">
                    <div className="col-md-5 col-xs-12 col-sm-5">
                      <div className="form-box">
                        <form>
                          <div className="form-group">
                            <label for="exampleFormControlTextarea1">Project ID</label>
                            <text className="form-control">{this.state.idForCreateNewProject}</text>
                            {/* <input className="form-control" type="text" placeholder="UTFI8782" readonly /> */}
                          </div>
                          <hr />
                          <div className="form-group">
                            {/* <label for="exampleFormControlTextarea1">Project Name</label>
                                <span class="mandatory">*</span> */}
                            <label for="exampleFormControlTextarea1">
                              Project Name <span class="mandatory">*</span></label>
                            <input type="text" className="form-control" id="exampleInputPassword1"
                              autocomplete="off"
                              placeholder="Enter Project Name"
                              value={this.state.ProjectName} onChange={(e) => {
                                let { ProjectName } = this.state;
                                ProjectName = e.target.value;
                                this.setState({ ProjectName });
                              }}
                            />
                          </div>
                          <div className="loginErrCreateProject">{this.state.pnameErr}</div>

                          <div className="form-group" style={{ marginTop: '7%' }}>
                            {/* <label for="exampleFormControlTextarea1">Select/Search Company</label>
                                <span class="mandatory">*</span> */}
                            <label for="exampleFormControlTextarea1">
                              Company Name (Select/Search) <span class="mandatory">*</span></label>
                            {
                              (!this.state.CompanyListData) ? (
                                <Select />

                              ) : (
                                <Select
                                menuPortalTarget={document.body}
                                menuPosition={'absolute'} 
                                styles={customStyles}
                               // menuPortalTarget={document.body} 
                                //styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),height:'10px' }}
                                  className="basic-single" 
                                 // styles={customStyles}
                                  // style={{height:'10'}}
                                  value={this.state.selectedOptionCompany}
                                  onChange={this.handleChangeCompany}
                                  options={this.state.CompanyListData.map((cmpny, index) => {
                                    return {
                                      label: cmpny.company_nm,
                                      value: cmpny.company_nm,
                                      key: index
                                    };
                                  })}
                                />
                              )
                            }

                          </div>
                          <div className="loginErrCreateProject">{this.state.cmpnynameErr}</div>

                          {
                            (!this.state.CompanyName) ? (
                              // <label for="exampleFormControlTextarea1">Procedure Type</label>
                              <div></div>
                            ) : (
                              <div>
                                <div className="form-group" style={{ marginTop: '7%' }}>
                                  {/* <label for="exampleFormControlTextarea1">Select/Search Client</label>
                                    <span class="mandatory">*</span> */}
                                  <label for="exampleFormControlTextarea1">
                                    Client Name (Select/Search) <span class="mandatory">*</span></label>
                                  <Select
                                    className="basic-single"
                                    styles={customStyles}
                                    value={this.state.selectedOptionClient}
                                    onChange={this.handleChangeClient}
                                    options={this.state.ClientData.map((client, index) => {
                                      return {
                                        label: client.clientname,
                                        value: client.client_id,
                                        key: index
                                      };
                                    })}
                                  />
                                </div>
                                <div className="loginErrCreateProject">{this.state.clientnameErr}</div>
                              </div>
                            )
                          }

                          <div className="form-group" style={{ marginTop: '7%' }}>
                            {/* <label for="exampleFormControlTextarea1">Required KOL Profiles </label>
                                <span class="mandatory">*</span> */}
                            <label for="exampleFormControlTextarea1">
                              Sample Size <span class="mandatory">*</span></label>
                            <input type="number" min="0" className="form-control" id="exampleInputPassword1"
                              placeholder="Enter Sample Size"
                              value={this.state.RequiredKOLProfile} onChange={(e) => {
                                let { RequiredKOLProfile } = this.state;
                                RequiredKOLProfile = e.target.value;
                                this.setState({ RequiredKOLProfile });
                              }}
                            />
                          </div>
                          <div className="loginErrCreateProject">{this.state.requirepErr}</div>

                          <div className="form-group" style={{ marginTop: '7%' }}>
                            <label for="exampleFormControlTextarea1">Speciality</label>
                            <Input type="select" id="roleSelect" className="form-control"
                                            style={{ height: '50px' }}
                                            value={this.state.Speciality}
                                            onChange={async (e) => {
                                                let { Speciality } = this.state;
                                                Speciality = e.target.value;
                                                await this.setState({ Speciality });
                                                
                                               
                                                await this.setState({
                                                    ProcedureType: [],
                                                    SubSpecialityType: [],
                                                    showProcedureSubSpecialityDropdown: false,
                                                })
                                               

                                                { this.getProcedureList() }
                                                { this.getSubSpecialityList() }

                                               // console.log('ProcedureType', this.state.ProcedureType)
                                                //console.log('PersonalInformation.procedure_type_id', this.state.PersonalInformation)
                                               // { this.ProcedureDropdown() }

                                            }}>
                                            <option value="" >--Select Speciality--</option>
                                            {OptionsSpeciality}
                                            </Input>
                          </div>
                          {/* <div className="loginErrCreateProject">{this.state.specialityErr}</div> */}

                          <div className="form-group" style={{ marginTop: '7%' }}>
                            {
                              (!this.state.Speciality) ? (
                                // <label for="exampleFormControlTextarea1">Procedure Type</label>
                                <div></div>
                              ) : (
                                <div>
                                  <label for="exampleFormControlTextarea1">Sub Speciality Type</label>
                                  {/* <Input type="select" id="roleSelect" className="form-control"
                                    style={{ height: '50px' }}
                                    value={this.state.SubSpecialityType}
                                    onChange={(e) => {
                                      let { SubSpecialityType } = this.state;
                                      SubSpecialityType = e.target.value;
                                      this.setState({ SubSpecialityType });
                                    }}>
                                    <option value="" >--Select Sub Speciality Type--</option>
                                    {OptionSubSpeciality}
                                  </Input> */}

                     
<Multiselect
                                    data={this.state.SubSpecialityData}
                                    placeholder='--Select Sub Speciality--'
                                    valueField='sub_speciality_type'
                                    textField='sub_speciality_type'
                                    value={this.state.SubSpecialityType}
                                    onSelect={(valueField) => console.log(valueField)}
                                    onChange={(event) => {
                                        console.log(event);
                                        var joined = [];
                                       // var joined1 = [];
                                        console.log("length : " + event.length);
                                        if (event.length === 0) {
                                            this.setState({ SubSpecialityData: [] });
                                        }
                                        event.map(async (data) => {
                                            console.log("data in eve map : " + JSON.stringify(data));
                                            joined = joined.concat(data.sub_speciality_type);
                                            console.log("data join : " + joined);
                                           // joined1 = joined1.concat(data.sub_speciality_type);
                                           // console.log("data join : ", joined1);
                                            await this.setState({
                                                SubSpecialityType: joined
                                                //SubSpecialityData: joined1
                                            });
                                            console.log("check this", this.state.SubSpecialityType)

                                            var output = this.state.SubSpecialityType.map(function (item) {
                                                return item;
                                            });

                                            // let { PersonalInformation, checkDuplicate } = this.state;
                                            // PersonalInformation.sub_specialty_id = output.join(",");
                                            // checkDuplicate.sub_specialty_id = output.join(",");
                                            // this.setState({ PersonalInformation, checkDuplicate });

                                            console.log("check this", this.state.sub_speciality_type)
                                            console.log(output.join(","))
                                            
                                        
                                        })
                                    }}
                                />
                


                                </div>
                              )
                            }
                          </div>


                          <div className="form-group" style={{ marginTop: '7%' }}>
                            {
                              (!this.state.Speciality) ? (
                                // <label for="exampleFormControlTextarea1">Procedure Type</label>
                                <div></div>
                              ) : (
                                <div>
                                  <label for="exampleFormControlTextarea1">Procedure Type</label>
                                 {/* <Input type="select" id="roleSelect" className="form-control"
                                    style={{ height: '50px' }}
                                    value={this.state.ProcedureType}
                                    onChange={(e) => {
                                      let { ProcedureType } = this.state;
                                      ProcedureType = e.target.value;
                                      this.setState({ ProcedureType });
                                    }}>
                                    <option value="" >--Select Procedure Type--</option>
                                    {OptionProcedure}
                                    {/* <option value = "1" >Project Manager</option>
                                            <option value = "2" >Employee</option>
                                            <option value = "3" >Admin</option>
                                            <option value = "4" >Researche</option> 
                                  </Input>  */}


<Multiselect
                                                                                data={this.state.ProcedureData}
                                                                                placeholder="--Select Procedure Type--"
                                                                                valueField='procedure_type'
                                                                                textField='procedure_type'
                                                                                value={this.state.ProcedureType}
                                                                                onSelect={(valueField) => console.log(valueField)}

                                                                                onChange={(event) => {
                                                                                    console.log(event);
                                                                                    var joined = [];
                                                                                    console.log("length : " + event.length);
                                                                                    if (event.length === 0) {
                                                                                        this.setState({ ProcedureType: [] });
                                                                                    }
                                                                                    event.map(async (data) => {
                                                                                        console.log("data in eve map : " + JSON.stringify(data));
                                                                                        joined = joined.concat(data.procedure_type);
                                                                                        console.log("data join : " + joined);
                                                                                        await this.setState({
                                                                                            ProcedureType: joined
                                                                                        });
                                                                                        console.log("check this ", this.state.ProcedureType)

                                                                                        var output = this.state.ProcedureType.map(function (item) {
                                                                                            return item;
                                                                                        });

                                                                                        // let { PersonalInformation, checkDuplicate } = this.state;
                                                                                        // PersonalInformation.procedure_type_id = output.join(",");
                                                                                        // checkDuplicate.procedure_type_id = output.join(",");
                                                                                        // this.setState({ PersonalInformation, checkDuplicate });

                                                                                        console.log("check this", this.state.procedure_type)
                                                                                        console.log(output.join(","))
                                                                                    })
                                                                                }}
                                                                            />


                      
                                </div>
                              )
                            }
                          </div>
                          {/* <div className="loginErrCreateProject">{this.state.ptypeErr}</div> */}

                          <div className="form-group" style={{ marginTop: '7%' }}>
                            {/* <label for="exampleFormControlTextarea1">Project Scope</label>
                                <span class="mandatory">*</span> */}
                            <label for="exampleFormControlTextarea1">
                              Project Scope <span class="mandatory">*</span></label>
                            <textarea className="form-control" id="exampleFormControlTextarea1"
                              placeholder="Enter Project scope" rows="3"
                              value={this.state.ProjectScope} onChange={(e) => {
                                let { ProjectScope } = this.state;
                                ProjectScope = e.target.value;
                                this.setState({ ProjectScope });
                              }}
                            >
                            </textarea>
                          </div>
                          <div className="loginErrCreateProject">{this.state.pscopeErr}</div>

                          <div className="form-group" style={{ marginTop: '7%' }}>
                            {/* <label for="exampleFormControlTextarea1">Markets</label>
                                <span class="mandatory">*</span> */}
                            <label for="exampleFormControlTextarea1">
                              Countries <span class="mandatory">*</span></label>
                            <Multiselect
                              data={this.state.CountryListData}
                              placeholder='--Select Countries--'
                              valueField='id'
                              textField='country_name'
                              onSelect={(valueField) => console.log(valueField)}
                              // onChange={(event)=>{
                              //     console.log(event);
                              //     event.map(async(data)=>{
                              //     var joined = this.state.Market.concat(data.id);
                              //         await this.setState({
                              //             Market: joined
                              //         });
                              //     console.log("check this",this.state.Market)
                              //     })
                              // }}
                              onChange={(event) => {
                                console.log(event);
                                var joined = [];
                                var joined1 = [];
                                console.log("length : " + event.length);
                                if (event.length === 0) {
                                  this.setState({ Market: [] });
                                }
                                event.map(async (data) => {
                                  // console.log(data);
                                  console.log("data in eve map : " + JSON.stringify(data));
                                  joined = joined.concat(data.id);
                                  console.log("data join : " + joined);
                                  await this.setState({
                                    Market: joined
                                  });
                                  console.log("check this", this.state.Market);
                                })
                              }}
                            // onSelect={(event)=>{
                            //     console.log(event);
                            // }}
                            // onClick={(event) =>{
                            //     console.log(event.target);
                            // }}
                            />
                          </div>
                          <div className="loginErrCreateProject">{this.state.marketErr}</div>

                          <div className="form-group" style={{ marginTop: '7%' }}>
                            {/* <label for="exampleFormControlTextarea1">Start Date</label>
                                <span class="mandatory">*</span> */}
                            <label for="exampleFormControlTextarea1">
                              Start Date <span class="mandatory">*</span></label>
                            <input type="date"
                              min={new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate()}
                              className="form-control" id="exampleInputPassword1"
                              placeholder="Enter Product Name"
                              value={this.state.StartDate} onChange={(e) => {
                                let { StartDate } = this.state;
                                StartDate = e.target.value;
                                this.setState({ StartDate });
                              }}
                            />
                          </div>
                          <div className="loginErrCreateProject">{this.state.sdateErr}</div>

                          <div className="form-group" style={{ marginTop: '7%' }}>
                            {/* <label for="exampleFormControlTextarea1">End Date</label>
                                <span class="mandatory">*</span> */}
                            <label for="exampleFormControlTextarea1">
                              End Date <span class="mandatory">*</span></label>
                            <input type="date"
                              min={new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate()}
                              className="form-control" id="exampleInputPassword1"
                              placeholder="Enter Product Name"
                              value={this.state.EndDate} onChange={(e) => {
                                let { EndDate } = this.state;
                                EndDate = e.target.value;
                                this.setState({ EndDate });
                              }}
                            />
                          </div>
                          <div className="loginErrCreateProject">{this.state.edateErr}</div>

                          <label for="ProjectPlan" style={{ marginTop: '3%' }}>
                            Project Plan - Gantt Chart</label>
                          <div class="form-control" style={{ height: '25%' }}>
                            <input type='file'
                              id="ProjectPlan"
                              accept=".doc,.docx,.pdf,.xls,.xlsx"
                              onChange={this.onFileChangeProjectPlan} hidden />
                            <label htmlFor="ProjectPlan"
                              style={{ color: 'blue', fontSize: "15px", marginTop: '1.5%' }}>
                              Upload File
                            </label>
                            {
                              (this.state.ProjectPlanFileName) ? (
                                <div class="float-right" style={{ marginTop: '1.5%' }}>
                                  <text>{this.state.ProjectPlanFileName}</text>
                                </div>
                              ) : (
                                <div class="float-right" style={{ marginTop: '1.5%' }}>
                                  No file Choosen
                                </div>
                              )
                            }
                          </div>
                          <div className="loginErrCreateProject">{this.state.pplanErr}</div>

                          <label for="ProjectPraposal" style={{ marginTop: '3%' }}>
                            Project Proposal</label>
                          <div class="form-control" style={{ height: '25%' }}>
                            <input type='file'
                              id="ProjectPraposal"
                              accept=".doc,.docx,.pdf,.xls,.xlsx"
                             
                              onChange={this.onFileChangeProjectPraposal} hidden />
                            <label htmlFor="ProjectPraposal"
                              style={{ color: 'blue', fontSize: "15px", marginTop: '1.5%' }}>
                              Upload File
                            </label>
                            {
                              (this.state.ProjectPraposalFileName) ? (
                                <div class="float-right" style={{ marginTop: '1.5%' }}>
                                  <text>{this.state.ProjectPraposalFileName}</text>
                                </div>
                              ) : (
                                <div class="float-right" style={{ marginTop: '1.5%' }}>
                                  No file Choosen
                                </div>
                              )
                            }
                          </div>
                          <div className="loginErrCreateProject">{this.state.ppraposalErr}</div>

                          {/* <div className="form-group" style={{marginTop:'7%'}}>
                                <label for="exampleFormControlTextarea1">
                                    Project SOW <span class="mandatory">*</span></label>
                                <input type="file" onChange={this.onFileChangeProjectSow} />          
                            </div>
                            <div className="loginErrCreateProject">{this.state.psowErr}</div> */}

                          <label for="ProjectSow" style={{ marginTop: '3%' }}>
                            Project SOW  <span class="mandatory">*</span></label>
                          <div class="form-control" style={{ height: '25%' }}>
                            <input type='file'
                              id="ProjectSow"
                              accept=".doc,.docx,.pdf,.xls,.xlsx"
                             
                              onChange={this.onFileChangeProjectSow} hidden />
                            <label htmlFor="ProjectSow"
                              style={{ color: 'blue', fontSize: "15px", marginTop: '1.5%' }}>
                              Upload File
                            </label>
                            {
                              (this.state.ProjectSowFileName) ? (
                                <div class="float-right" style={{ marginTop: '1.5%' }}>
                                  <text>{this.state.ProjectSowFileName}</text>
                                </div>
                              ) : (
                                <div class="float-right" style={{ marginTop: '1.5%' }}>
                                  No file Choosen
                                </div>
                              )
                            }
                          </div>
                          <div className="loginErrCreateProject" style={{ marginTop: '2%' }}>
                            {this.state.psowErr}
                          </div>



                          <div className="form-group" style={{ marginTop: '7%' }}>
                            <label for="exampleFormControlTextarea1">Event Name</label>
                            <input type="text" className="form-control" id="exampleInputPassword1"
                              placeholder="Enter Event Name"
                              value={this.state.EventName} onChange={(e) => {
                                let { EventName } = this.state;
                                EventName = e.target.value;
                                this.setState({ EventName });
                              }}
                            />
                          </div>
                          {/* <div className="loginErrCreateProject">{this.state.enameErr}</div> */}

                          <div className="form-group" style={{ marginTop: '7%' }}>
                            <label for="exampleFormControlTextarea1">Engagement Type</label>
                            <Input type="select" id="roleSelect" className="form-control"
                              style={{ height: '50px' }}
                              value={this.state.EngagementType}
                              onChange={(e) => {
                                let { EngagementType } = this.state;
                                EngagementType = e.target.value;
                                //console.log("employee role",e.target.value);
                                this.setState({ EngagementType });
                              }}>
                              <option value="" >--Select Engagement Type--</option>
                              <option value="Face to Face" >Face to Face</option>
                              <option value="Conference Call" >Conference Call</option>
                              <option value="Email" >Email</option>
                              <option value="Phone" >Phone</option>
                              <option value="Webnair" >Webnair</option>
                              <option value="Webcast" >Webcast</option>

                              {/* {OptionsEngagementTyple}   */}
                            </Input>
                          </div>
                          <div className="loginErrCreateProject">{this.state.etypeErr}</div>



                          {/* <div className="form-group" style={{marginTop:'7%'}}>
                                <label for="exampleFormControlTextarea1">No of Participant</label>
                                <Input type="select"  id="roleSelect"  className="form-control" 
                                    style={{height: '50px'}}
                                    value={this.state.NoOfParticipants}
                                        onChange={(e) => {
                                        let { NoOfParticipants } = this.state;
                                        NoOfParticipants = e.target.value;
                                        this.setState({ NoOfParticipants });
                                    }}>
                                    <option value = "0" >--Select No. of Participant--</option>
                                        <option value = "1" >1</option>
                                        <option value = "2" >2</option>
                                        <option value = "3" >3</option>
                                        <option value = "4" >4</option>
                                        <option value = "5" >5</option>
                                        <option value = "6" >6</option>
                                        <option value = "7" >7</option>
                                        <option value = "8" >8</option>
                                        <option value = "9" >9</option>
                                        <option value = "10" >10</option>
                                </Input>
                            </div> */}
                          {/* <div className="loginErrCreateProject">{this.state.nofparticipantsErr}</div> */}

                          <div className="form-group" style={{ marginTop: '7%' }}>
                            {/* <label for="exampleFormControlTextarea1">Project Manager</label>
                                <span class="mandatory">*</span> */}
                            <label for="exampleFormControlTextarea1">
                              Project Manager</label>
                            <text className="form-control">{this.state.manager_name}</text>
                            {/* <Input type="select"  id="roleSelect"  className="form-control" 
                                    style={{height: '50px'}}
                                    value={this.state.ProjectManager}
                                        onChange={(e) => {
                                        let { ProjectManager } = this.state;
                                        ProjectManager = e.target.value;
                                        this.setState({ ProjectManager });
                                    }}>
                                    <option value = "0" >--Select Project Manager--</option>
                                    {OptionsManagerList}
                                </Input> */}
                          </div>
                          {/* <div className="loginErrCreateProject">{this.state.pmanagerErr}</div> */}

                          <div>
                            <label for="exampleFormControlTextarea1">Reporting Manager Name<span class="mandatory">*</span></label>
                            <Input type="select" id="roleSelect" className="form-control"
                              style={{ height: '50px' }}
                              value={this.state.ReportingManagerName}
                              onChange={async (e) => {
                                console.log("REPORTING MANAGER EVENT====", e.target)
                                let { ReportingManagerName, ReportingManagerEmail } = this.state;
                                ReportingManagerName = e.target.value;
                                await this.setState({ ReportingManagerName });
                                { this.getEmailReportingManager() }
                              }}>
                              <option value="" >--Select Reporting Manager--</option>
                              {OptionReportingManager}
                            </Input>
                          </div>
                          <div className="loginErrCreateProject" style={{ marginTop: '2%' }}>
                            {this.state.reportingManagErr}
                          </div>

                          {
                            (this.state.showReprtingManagerEmail == true) ? (
                              <div>
                                <label for="exampleFormControlTextarea1" style={{ marginTop: '3%' }}
                                >Reporting Manager Email ID
                                </label>
                                <text className="form-control disabled" >{this.state.ReportingManagerEmail}</text>
                              </div>
                            ) : (
                              <div></div>
                            )
                          }

                          <div className="form-group" style={{ marginTop: '7%' }}>
                            <label for="exampleFormControlTextarea1">
                              Team Members <span class="mandatory">*</span></label>
                            <Multiselect
                              data={this.state.teamMembersData}
                              placeholder='--Select Team Members--'
                              valueField='emp_id'
                              textField='emp_name'
                              onSelect={(valueField) => console.log(valueField)}
                              // onChange={(event)=>{
                              //     console.log(event);
                              //     event.map(async(data)=>{
                              //     var joined = this.state.TeamMembers.concat(data.emp_id);
                              //         await this.setState({
                              //             TeamMembers: joined
                              //         });
                              //     console.log("check this",this.state.TeamMembers)
                              //     })
                              // }}

                              onChange={(event) => {
                                console.log(event);
                                var joined = [];
                                var joined1 = [];
                                console.log("length : " + event.length);
                                if (event.length === 0) {
                                  this.setState({ TeamMembers: [] });
                                }
                                event.map(async (data) => {
                                  console.log("data in eve map : " + JSON.stringify(data));
                                  joined = joined.concat(data.emp_id);
                                  console.log("data join : " + joined);
                                  joined1 = joined1.concat(data.emp_name);
                                  console.log("data join : ", joined1);
                                  await this.setState({
                                    TeamMembers: joined,
                                    TeamMembersName: joined1
                                  });
                                  console.log("check this", this.state.TeamMembers);
                                  console.log("check this TeamMembersName", this.state.TeamMembersName);
                                })
                              }}

                            />
                          </div>
                          <div className="loginErrCreateProject">{this.state.tmemberErr}</div>
                          <div style={{ marginBottom: '10%' }}></div>

                          {/* <div className="form-group" style={{marginTop:'7%'}}>
                                <label for="exampleFormControlTextarea1">
                                    Submited by <span class="mandatory">*</span></label>
                                <Input type="select"  id="roleSelect"  className="form-control" 
                                    style={{height: '50px'}}
                                    value={this.state.SubmitedBy}
                                        onChange={(e) => {
                                        let { SubmitedBy } = this.state;
                                        SubmitedBy = e.target.value;
                                        this.setState({ SubmitedBy });
                                    }}>
                                    <option value = "0" >--Select Employee--</option>
                                    {OptionsEmployeeList}
                                </Input>
                            </div>
                            <div className="loginErrCreateProject">{this.state.sbyErr}</div> */}

                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="action-btn-container" >
                      <div className="container">
                        <button className="cetas-secondry-button" onClick={this.cancel.bind(this)}>Cancel</button>
                        <button className="cetas-secondry-button" onClick={this.save.bind(this)}>Save</button>
                        <button className="cetas-button" data-toggle="modal" data-target="#success"
                        // onClick={this.Update_login_Type.bind(this)}>Create</button>
                            onClick={this.submit.bind(this)}>Create</button> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer className="footer-fixed">
            <div className="cetas-footer">
              <div className="container">
                <p>All rights reserved. www.cetashealthcare.com</p>
              </div>
            </div>
          </footer>

          {/* /////////////////////// Create New Client/Administratot Form ///////////////////////////// */}
          <Modal isOpen={this.state.isClient} toggle={this.toggleCreateNewClient.bind(this)} style={{ padding: '15px' }}>
            <ModalHeader toggle={this.toggleCreateNewClient.bind(this)} style={{ border: 'none' }}
              cssModule={{
                'modal-title': 'w-100 text-center', 'border-bottom': '0px',
                'padding': '2rem 1rem 0rem 1rem'
              }}>
              <h3>Create Client/Administrator</h3>
            </ModalHeader>
            <ModalBody style={{ padding: '20px 80px 10px 80px' }}>
              <FormGroup>
                <Label for="Unique_id">Unique ID</Label>
                <Input id="title" value={this.state.Client_Unique_id} readOnly />
              </FormGroup>

              <FormGroup>
                <Label for="role_type" style={{ marginTop: '2%' }}>Role</Label>
                <select class="form-control" name="role_type" id="exampleFormControlSelect1"
                  value={this.state.ClientDataforAddClient.role}
                  onChange={(e) => {
                    let { ClientDataforAddClient } = this.state;
                    ClientDataforAddClient.role = e.target.value;
                    this.setState({ ClientDataforAddClient });
                  }}>
                  <option value="client">Client</option>
                  <option value="administrator">Administrator</option>
                </select>
                <div style={{ fontSize: "12px", color: 'red' }}>
                  {this.state.clientRoleErr ? this.state.clientRolenErr : null}
                </div>
              </FormGroup>

              {(this.state.ClientDataforAddClient.role === "client") ?
                (
                  <div>
                    <FormGroup>
                      <Label for="Company_name">Company Name</Label>
                      <Input value={userInput} onChange={onChange} onKeyDown={onKeyDown} />
                      {suggestionsListComponent}
                      <div style={{ fontSize: "12px", color: 'red', marginTop: '-3%' }}>
                        {this.state.ComponynmError ? this.state.ComponynmError : null}
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <Label for="role_type" style={{ marginTop: '2%' }}>Salutation</Label>
                      <select class="form-control" name="role_type" id="exampleFormControlSelect1" onChange={(e) => {
                        let { ClientDataforAddClient } = this.state;
                        ClientDataforAddClient.salutation = e.target.value;
                        this.setState({ ClientDataforAddClient });
                      }}>
                        <option value="">Select Salutation</option>
                        <option>Mr.</option>
                        <option>Ms.</option>
                        <option>Mrs.</option>
                        <option>Dr.</option>
                        <option>Prof.</option>
                        <option>Other</option>
                      </select>
                      <div style={{ fontSize: "12px", color: 'red' }}>
                        {this.state.clientSalutationErr ? this.state.clientSalutationErr : null}
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <Label for="client_name" style={{ marginTop: '1%' }}>Client Name</Label>
                      <Input id="client_name" name="client_name" value={this.state.ClientDataforAddClient.clientName} onChange={(e) => {
                        let { ClientDataforAddClient } = this.state;
                        ClientDataforAddClient.clientName = e.target.value;
                        this.setState({ ClientDataforAddClient });
                      }}
                      />
                      <div style={{ fontSize: "12px", color: 'red', marginTop: '-3%' }}>
                        {this.state.clientNameError ? this.state.clientNameError : null}
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <Label for="client_name" style={{ marginTop: '2%' }}>Designation</Label>
                      <Input id="client_name" name="client_name" value={this.state.ClientDataforAddClient.designation}
                        onChange={(e) => {
                          let { ClientDataforAddClient } = this.state;
                          ClientDataforAddClient.designation = e.target.value;
                          this.setState({ ClientDataforAddClient });
                        }}
                      />
                      <div style={{ fontSize: "12px", color: 'red', marginTop: '-3%' }}>
                        {this.state.clientDesignationErr ? this.state.clientDesignationErr : null}
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <Label for="email" style={{ marginTop: '2%' }}>Email ID</Label>
                      <Input id="email" name="email" type="email" value={this.state.ClientDataforAddClient.email_id} onChange={(e) => {
                        let { ClientDataforAddClient } = this.state;
                        ClientDataforAddClient.email_id = e.target.value;
                        this.setState({ ClientDataforAddClient });
                      }}
                      />
                      <div style={{ fontSize: "12px", color: 'red', marginTop: '-3%' }}>
                        {this.state.email_idError ? this.state.email_idError : null}
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <Label for="Mobile_no" style={{ marginTop: '2%' }}>Phone No.</Label>
                      {/* <Input id="number" name="number" value={this.state.ClientDataforAddClient.mb_no} onChange={(e) => {
                        let { ClientDataforAddClient } = this.state;
                        ClientDataforAddClient.mb_no = e.target.value;
                        this.setState({ ClientDataforAddClient });
                        }}
                        /> */}
                      <PhoneInput
                        inputStyle={{
                          width: "100%",
                          height: "50px",
                          fontSize: "15px",
                        }}
                        width="50%"
                        country={'in'}
                        placeholder="Enter Phone Number"
                        value={this.state.ClientDataforAddClient.mb_no}
                        onChange={(DoctorContactNo) => {
                          let { ClientDataforAddClient } = this.state;
                          ClientDataforAddClient.mb_no = DoctorContactNo;
                          this.setState({ ClientDataforAddClient });
                        }}
                      />
                      <div style={{ fontSize: "12px", color: 'red', marginTop: '-3%' }}>
                        {this.state.mb_noError ? this.state.mb_noError : null}
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <Label for="client_name" style={{ marginTop: '3%' }}>LinkedIn Profile</Label>
                      <Input id="client_name" name="client_name" value={this.state.ClientDataforAddClient.linkedInProfile}
                        onChange={(e) => {
                          let { ClientDataforAddClient } = this.state;
                          ClientDataforAddClient.linkedInProfile = e.target.value;
                          this.setState({ ClientDataforAddClient });
                        }}
                      />
                      <div style={{ fontSize: "12px", color: 'red', marginTop: '-3%' }}>
                        {this.state.clientLinkedInProfileErr ? this.state.clientLinkedInProfileErr : null}
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <Label for="client_name" style={{ marginTop: '2%' }}>Speciality</Label>
                      <Input type="select" id="roleSelect" className="form-control"
                        style={{ height: '50px' }}
                        value={this.state.ClientDataforAddClient.speciality_id}
                        onChange={async (e) => {
                          let { ClientDataforAddClient } = this.state;
                          ClientDataforAddClient.speciality_id = e.target.value;
                          await this.setState({ ClientDataforAddClient });
                          { this.getSubSpecialityListForClient() }
                          { this.getProcedureListForClient() }
                        }}>
                        <option value="" >--Select Speciality--</option>
                        {OptionsSpeciality}
                      </Input>
                    </FormGroup>

                    <FormGroup>
                      <Label for="client_name">Sub Speciality</Label>
                      <Multiselect
                        data={this.state.SubSpecialityDataForClient}
                        placeholder='--Select Sub Speciality--'
                        valueField='id'
                        textField='sub_speciality_type'
                        onSelect={(valueField) => console.log(valueField)}
                        onChange={(event) => {
                          console.log(event);
                          var joined = [];
                          var joined1 = [];
                          console.log("length : " + event.length);
                          if (event.length === 0) {
                            this.setState({ SubSpecialityTypeClient: [] });
                          }
                          event.map(async (data) => {
                            console.log("data in eve map : " + JSON.stringify(data));
                            joined = joined.concat(data.id);
                            console.log("data join : " + joined);
                            joined1 = joined1.concat(data.sub_speciality_type);
                            console.log("data join : ", joined1);
                            await this.setState({
                              SubSpecialityTypeClient: joined,
                              SubSpecialityTypeClientName: joined1
                            });
                            let { ClientDataforAddClient } = this.state;
                            ClientDataforAddClient.sub_speciality_id = joined;
                            await this.setState({ ClientDataforAddClient });

                            console.log("check this", this.state.SubSpecialityTypeClient);
                            console.log("check this SubSpecialityTypeClientName", this.state.SubSpecialityTypeClientName);
                          })
                        }}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="client_name">Procedure Type</Label>
                      <Multiselect
                        data={this.state.ProcedureDataForClient}
                        placeholder='--Select Procedure Type--'
                        valueField='id'
                        textField='procedure_type'
                        onSelect={(valueField) => console.log(valueField)}
                        onChange={(event) => {
                          console.log(event);
                          var joined = [];
                          var joined1 = [];
                          console.log("length : " + event.length);
                          if (event.length === 0) {
                            this.setState({ ProcedureTypeClient: [] });
                          }
                          event.map(async (data) => {
                            console.log("data in eve map : " + JSON.stringify(data));
                            joined = joined.concat(data.id);
                            console.log("data join : " + joined);
                            joined1 = joined1.concat(data.procedure_type);
                            console.log("data join : ", joined1);
                            await this.setState({
                              ProcedureTypeClient: joined,
                              ProcedureTypeClientName: joined1
                            });
                            let { ClientDataforAddClient } = this.state;
                            ClientDataforAddClient.procedure_id = joined;
                            await this.setState({ ClientDataforAddClient });

                            console.log("check this", this.state.ProcedureTypeClient);
                            console.log("check this SubSpecialityTypeClientName", this.state.ProcedureTypeClientName);
                          })
                        }}
                      />
                    </FormGroup>
                  </div>
                )
                :
                (
                  <div>
                    <FormGroup>
                      <Label for="Company_name">Company Name</Label>
                      <Input value={userInput} onChange={onChange} onKeyDown={onKeyDown} />
                      {suggestionsListComponent}
                      <div style={{ fontSize: "12px", color: 'red', marginTop: '-3%' }}>
                        {this.state.ComponynmError ? this.state.ComponynmError : null}
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <Label for="role_type" style={{ marginTop: '2%' }}>Salutation</Label>
                      <select class="form-control" name="role_type" id="exampleFormControlSelect1" onChange={(e) => {
                        let { ClientDataforAddClient } = this.state;
                        ClientDataforAddClient.salutation = e.target.value;
                        this.setState({ ClientDataforAddClient });
                      }}>
                        <option value="">Select Salutation</option>
                        <option>Mr.</option>
                        <option>Ms.</option>
                        <option>Mrs.</option>
                        <option>Dr.</option>
                        <option>Prof.</option>
                        <option>Other</option>
                      </select>
                      <div style={{ fontSize: "12px", color: 'red' }}>
                        {this.state.clientSalutationErr ? this.state.clientSalutationErr : null}
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <Label for="client_name" style={{ marginTop: '1%' }}>Administrator Name</Label>
                      <Input id="client_name" name="client_name" value={this.state.ClientDataforAddClient.clientName} onChange={(e) => {
                        let { ClientDataforAddClient } = this.state;
                        ClientDataforAddClient.clientName = e.target.value;
                        this.setState({ ClientDataforAddClient });
                      }}
                      />
                      <div style={{ fontSize: "12px", color: 'red', marginTop: '-3%' }}>
                        {this.state.clientNameError ? this.state.clientNameError : null}
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <Label for="client_name" style={{ marginTop: '2%' }}>Designation</Label>
                      <Input id="client_name" name="client_name" value={this.state.ClientDataforAddClient.designation}
                        onChange={(e) => {
                          let { ClientDataforAddClient } = this.state;
                          ClientDataforAddClient.designation = e.target.value;
                          this.setState({ ClientDataforAddClient });
                        }}
                      />
                      <div style={{ fontSize: "12px", color: 'red', marginTop: '-3%' }}>
                        {this.state.clientDesignationErr ? this.state.clientDesignationErr : null}
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <Label for="email" style={{ marginTop: '2%' }}>Email ID</Label>
                      <Input id="email" name="email" type="email" value={this.state.ClientDataforAddClient.email_id} onChange={(e) => {
                        let { ClientDataforAddClient } = this.state;
                        ClientDataforAddClient.email_id = e.target.value;
                        this.setState({ ClientDataforAddClient });
                      }}
                      />
                      <div style={{ fontSize: "12px", color: 'red', marginTop: '-3%' }}>
                        {this.state.email_idError ? this.state.email_idError : null}
                      </div>
                    </FormGroup>

                    <FormGroup>
                      <Label for="Mobile_no" style={{ marginTop: '2%' }}>Phone No.</Label>
                      {/* <Input id="number" name="number" value={this.state.ClientDataforAddClient.mb_no} onChange={(e) => {
                        let { ClientDataforAddClient } = this.state;
                        ClientDataforAddClient.mb_no = e.target.value;
                        this.setState({ ClientDataforAddClient });
                        }}
                        /> */}
                      <PhoneInput
                        inputStyle={{
                          width: "100%",
                          height: "50px",
                          fontSize: "15px",
                        }}
                        width="50%"
                        country={'in'}
                        placeholder="Enter Phone Number"
                        value={this.state.ClientDataforAddClient.mb_no}
                        onChange={(DoctorContactNo) => {
                          let { ClientDataforAddClient } = this.state;
                          ClientDataforAddClient.mb_no = DoctorContactNo;
                          this.setState({ ClientDataforAddClient });
                        }}
                      />
                      <div style={{ fontSize: "12px", color: 'red', marginTop: '-3%' }}>
                        {this.state.mb_noError ? this.state.mb_noError : null}
                      </div>
                    </FormGroup>
                  </div>
                )
              }

            </ModalBody>
            <ModalFooter>
              <Button className="cetas-secondry-button" onClick={this.toggleCreateNewClient.bind(this)} >Cancel</Button>
              <Button className="cetas-button" onClick={this.handlesubmit.bind(this)}> Submit </Button>
            </ModalFooter>
          </Modal>



        </div>
      </body>
    );
  }
}

export default CreateProject;