import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import Swal from 'sweetalert2';
var jwt = require('jsonwebtoken');
const jwtkey=require('../config');

class ManagerHeader extends Component {
constructor(props) {
    super(props);
    this.state = { 
        redirect: false,
    };
    
}


componentDidMount = async () => {


    let getSession_wtoken = await sessionStorage.getItem('wtoken')  
    console.log('tok5',getSession_wtoken);

    jwt.verify(getSession_wtoken,jwtkey.jwt.key, async (err, response) => {     
    if(!err)
        {
            
            let getSession_token = await sessionStorage.getItem('token')  
            console.log(getSession_token);
             
           // let getSession_Project_Name = await sessionStorage.getItem('p_name')
   
            let getSession_Manager_ID = await sessionStorage.getItem('id')
            if (getSession_Manager_ID == null) {
                await this.setState({
                    redirect:true,
                })
            }

            console.log('getSession_Manager_ID',getSession_Manager_ID);

        
            let getSession_Manager_Name = await sessionStorage.getItem('name')
            if (getSession_Manager_Name == null) {
                await this.setState({
                    redirect:true,
                })
            }
        
        
             
        }
        else
        {                        
            console.error('error',err.message);
            Swal.fire({
            icon: 'error',
            title: jwtkey.jwt.session_expired_msg_title,
            text:jwtkey.jwt.session_expired_msg,
            // showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK'
            })

            sessionStorage.removeItem('wtoken');
            sessionStorage.removeItem('email')
            sessionStorage.removeItem('id')
            sessionStorage.removeItem('name')
            sessionStorage.removeItem('project_id')
            sessionStorage.removeItem('p_name')
            sessionStorage.removeItem('idForCreateNewProject')
            sessionStorage.removeItem('project_id_for_edit_project')
            sessionStorage.removeItem('status_for_editProject')
            sessionStorage.removeItem('kol_unique_id')              
            this.setState({redirect: true})
        }
        
       
    })
  
}





logout = () => {
    console.log("in logout")
    this.setState({redirect: true})
    sessionStorage.removeItem('wtoken');
    sessionStorage.removeItem('email')
    sessionStorage.removeItem('id')
    sessionStorage.removeItem('name')
    sessionStorage.removeItem('project_id')
    sessionStorage.removeItem('p_name')
    sessionStorage.removeItem('idForCreateNewProject')
    sessionStorage.removeItem('project_id_for_edit_project')
    sessionStorage.removeItem('status_for_editProject')
    sessionStorage.removeItem('kol_unique_id')
}

render() {
    if (this.state.redirect) {
            return (<Redirect to={'/'}/>)
        }
        return (
            <div class="header-nav  fixed-top">
                <div class="container" >
                    <nav class="navbar navbar-expand-lg" >
                        <a className="navbar-brand" href="https://cetas-healthcare.com/" style={{marginLeft:'-4.2vh'  }}>
                            <img
                                // src={require('../Assets/Images/cetas-logo.png')} 
                                src={require('../Assets/Images/Medtech_Gurus_logo.png')}
                            />  
                        </a>
                        <button class="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup"
                                aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <div class="navbar-nav">
                                {/* <a class="nav-item nav-link active" href="#">Home <span
                                    class="sr-only">(current)</span></a>
                                <a class="nav-item nav-link" href="#">Projects</a>
                                <li class="nav-item  dropdown multi-option">
                                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        KOLs
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <a class="dropdown-item" href="#">KOL Ranking</a>
                                        <a class="dropdown-item" href="#">KOL QC</a>
                                    </div>
                                </li>
                                <a class="nav-item nav-link" href="#">
                                    <img src={require('../Assets/Images/setting.svg')} /> Search
                                </a> */}
                            </div>
                        </div>
                        <a class="nav-item nav-link username-box">
                        <img src={require('../Assets/Images/user.svg')}  /> {this.props.ManagerName} </a>

                        <a className="nav-item nav-link username-box  float-right" 
                            onClick={this.logout.bind(this)}>
                            <img 
                                src={require('../Assets/Images/logoutByVibha.svg')} 
                            />
                                Logout
                        </a>
                    </nav>
                </div>
            </div>
        );
    }
}

export default ManagerHeader;