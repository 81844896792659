import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { Input, Container, Row, Col, Modal } from 'reactstrap';
import axios from 'axios';
import { Multiselect } from 'react-widgets';
import Swal from 'sweetalert2';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import LoadingBar from "react-top-loading-bar";
import { RingLoader, FadeLoader, DotLoader, PropagateLoader } from 'react-spinners';

import ManagerHeader from './ManagerHeader';


class ManagerQCIdentifire extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // for image
            file: '',
            imagePreviewUrl: '',

            // Progress
            ShowProcedure: false,
            ShowBar: false,

            // redirect
            RedirectManagerKolList: false,

            kol_unique_id: '',
            manager_name: '',
            manager_id: '',
            project_id: '',

            // to store arrays from api
            PersonalInformation: {},
            WorkDetails: [],
            KOL_Identifiers: {},
            Biography: {},
            ProfessionalMemberships: [],
            Education: [],
            AdvisoryBoard: [],
            AwardsHonours: [],
            BooksMonographs: [],
            Commitees: [],
            ClinicalTrialDetails: [],
            EventsDetails: [],
            Grants: [],
            Guidelines: [],
            HospitalAffiliations: [],
            // Interactions: [],
            Interactions: [],
            PressItem: [],
            Presentation: [],
            PublicationDetails: [],
            SunshinePayment: [],

            kol_comment: '',

            SpecialityData: [],
            CountryListData: [],
            ProcedureData: [],
            SubSpecialitydata: [],
            // CityData: [],
            StateData: [],

            ProcedureType: [],
            SubSpecialityType: [],

            FinalResumeName: '',
            profile_photo_for_edit: '',
            BiographyResume_edit: '',
            BiographyResumeFileName: '',
            profile_photo: '',

            showProcedureSubSpecialityDropdown: false,
            showProcedureSubSpecialityDropdownAdvBoard: false,

            overall_comment: '',

            itemsSalutation: [],
            itemsDoctorFullName: [],
            itemsFirstName: [],
            itemsMiddleName: [],
            itemsLastName: [],
            itemsSuffix: [],
            itemsQualification: [],
            itemsExperience: [],
            itemsMedicalSchool: [],
            itemsMedicalRegisterNo: [],
            itemsNpiNo: [],
            itemsPlaceWork: [],
            itemsDesignation: [],
            itemsBrand: [],
            itemsDepartment: [],
            itemSpeciality: [],
            itemSpeciality2: [],
            itemsProcedure: [],
            itemsSubSpeciality: [],
            itemsexpertise: [],
            
            itemsDoctorContact: [],
            itemsDoctorEmail: [],
            itemsDoctorEmail2: [],
            itemsFax: [],
            itemsLinkedIn: [],
            itemsTwitter: [],
            itemsAssistanceContact: [],
            itemsAssistanceEmail: [],
            itemsWebsite: [],
            itemsCountry: [],
            itemsState: [],
            itemsCity: [],
            itemsAddress1: [],
            itemsAddress2: [],
            itemsProcedurePotential: [],

            // Variables for add source
            SalutationAddSource: true,
            DFullNameAddSource: true,
            FirstNameAddSource: true,
            MiddleNameAddSource: true,
            LastNameAddSource: true,
            SuffixAddSource: true,
            QualificationAddSource: true,
            ExperienceAddSource: true,
            MedicalSchoolsAddSource: true,
            MedicalRegistrationNoAddSource: true,
            NPINoAddSource: true,
            PlaceOfWorkAddSourceFun: true,
            DesignationAddSource: true,
            BrandAddSource: true,
            DepartmentAddSource: true,
            FromYearAddSource: true,
            ToYearAddSource: true,
            SpecialityAddSource: true,Speciality2AddSource: true,
            ProcedureTypeAddSource: true,
            SubSpecialityTypeAddSource: true,
            DoctorContactNoAddSource: true,
            DoctorEmailAddSource: true,
            DoctorEmail2AddSource: true,
            FaxAddSource: true,
            LinkedInProfileAddSource: true,
            TwitterHandleAddSource: true,
            AssistanceContactNoAddSource: true,
            AssistanceEmailAddSource: true,
            WebsiteAddSource: true,
            CountryAddSource: true,
            StateAddSource: true,
            Address1AddSource: true,
            CityPEAddSource: true,
            Address2AddSource: true,

            // varialblesForCheckboxErr
            salutationCheck: '',
            doctorFullNameCheck: '',
            firstNameCheck: '',
            // middaleNameCheck: '',
            lastNameCheck: '',
            // qualificationChech: '',
            // experienceCheck: '',
            // mediSchoolCheck: '',
            mediRegiNoCheck: '',
            NpiCheck: '',
            brancdCheck: '',
            procedureCheck: '',
            specialityCheck: '',
            subSpecialityCheck: '',
            expertiseCheck: '',
            
            doctorContactCheck: '',
            doctorEmailCheck: '',
            doctorEmail2Check: '',
            // linkedinProfileCheck: '',
            // twitterHandleCheck: '',
            // assistanceContactCheck: '',
            // assistaceEmailCheck: '',
            // websiteCheck: '',
            addressCheck: '',
            address2Check: '',
            // faxCheck: '',
            stateCheck: '',
            counterCheck: '',
            procedurePotentialCheck: '',
            zipCheck: '',
            // contractCheck: '',
            // workditails
            placeofWorkCheck: '',
            designantionCheck: '',
            // departmentCheck: '',

            eventConferencTypeCheck: '',
            eventRoleCheck: '',
            advBoardCompanyTypeCheck: '',
            advBoardRoleCheck: '',
            publicationJournalTypeCheck: '',
            publicationRoleCheck: '',
            ClinicalNoOfTrialLocationCheck: '',
            clinicalTrialTypeCheck: '',
            guidelinesOrgTypeCheck: '',
        };
    }

    componentDidMount = async () => {
        let getSession_Manager_ID = await sessionStorage.getItem('id')
        if (getSession_Manager_ID !== null) {
            await this.setState({
                manager_id: getSession_Manager_ID,
            })
        }

        let getSession_Manager_Name = await sessionStorage.getItem('name')
        if (getSession_Manager_Name !== null) {
            await this.setState({
                manager_name: getSession_Manager_Name,
            })
        }

        let getSession_Project_Id = await sessionStorage.getItem('project_id')
        if (getSession_Project_Id !== null) {
            await this.setState({
                project_id: getSession_Project_Id,
            })
        }

        let getSession_Kol_Unique_Id = await sessionStorage.getItem('kol_unique_id')
        if (getSession_Kol_Unique_Id !== null) {
            await this.setState({
                kol_unique_id: getSession_Kol_Unique_Id,
            })
        }

        console.log("getSession_Manager_ID ====", getSession_Manager_ID)
        console.log("getSession_Manager_Name ====", getSession_Manager_Name)
        console.log("getSession_Kol_Unique_Id ====", getSession_Kol_Unique_Id)

        this.setState({
            ShowBar: true,
        })

        const options = {
            method: 'POST',
        }

        //  Speciality type data
        fetch(global.websiteUrl+'/service/Speciality/get_SpecialityList', options)
            .then((Response) => Response.json())
            .then((findresponse) => {
                console.log(findresponse)
                var testDataSpeciality = findresponse.data;
                console.log("testDataSpeciality", JSON.stringify(testDataSpeciality));
                this.setState({
                    SpecialityData: findresponse.data,
                })
            })

        // Country List
        fetch(global.websiteUrl+'/service/Location/get_CountryList', options)
            .then((Response) => Response.json())
            .then((findresponse) => {
                console.log(findresponse)
                var TestCountryList = findresponse.Country_Details;
                // var TestKolIddata= findresponse.kol_system_id;
                console.log("TestCountryList", JSON.stringify(TestCountryList));
                // console.log("TestKolIddata",JSON.stringify(TestKolIddata));
                this.setState({
                    CountryListData: findresponse.Country_Details,
                })
            })

        // const url = global.websiteUrl+'/service/Researcher/get_ResearcherList';
        const url = global.websiteUrl+'/service/KOL/get_KOLList';
        let formData = new FormData();
        // formData.append('kol_id','CHCUI0026');
        // formData.append('kol_id',this.props.location.state.kol_unique_id);
        formData.append('kol_id', getSession_Kol_Unique_Id);
        formData.append('project_id', getSession_Project_Id);
        const config = {
            ////   headers: { 'content-type': 'multipart/form-data' }
           // headers: { 'content-type': 'multipart/form-data' }
        }

        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var lgdata = response.data;
                console.log("lgdata=====", JSON.stringify(lgdata));
                var KOL_Personal_Info = response.data.KOL_Personal_Info;
                console.log("KOL_Personal_Info==========", JSON.stringify(KOL_Personal_Info));
                var KOL_Work_Details = response.data.KOL_Work_Details;
                console.log("KOL_Work_Details==========", JSON.stringify(KOL_Work_Details));
                this.setState({
                    PersonalInformation: KOL_Personal_Info,
                    WorkDetails: response.data.KOL_Work_Details,
                    KOL_Identifiers: response.data.KOL_Identifiers,
                    Biography: response.data.KOL_Biography,
                    ProfessionalMemberships: response.data.KOL_Professional_Memberships,
                    Education: response.data.KOL_Education,
                    AdvisoryBoard: response.data.KOL_Advisory_Board,
                    AwardsHonours: response.data.KOL_Awards_Honours,
                    BooksMonographs: response.data.KOL_Books_Monographs,
                    Commitees: response.data.KOL_Commitees,
                    ClinicalTrialDetails: response.data.KOL_Clinical_Trial_Details,
                    EventsDetails: response.data.KOL_Events_Details,
                    Grants: response.data.KOL_Grants,
                    Guidelines: response.data.KOL_Guidelines,
                    HospitalAffiliations: response.data.KOL_Hospital_Affiliations,
                    Interactions: response.data.KOL_Interactions,
                    PressItem: response.data.KOL_Press_Item,
                    Presentation: response.data.KOL_Presentations,
                    PublicationDetails: response.data.KOL_Publication_Details,
                    SunshinePayment: response.data.KOL_Sunshine_Payment,

                    profile_photo_for_edit: response.data.KOL_Profile_Photo,
                    BiographyResume_edit: response.data.KOL_Resume,
                    BiographyResumeFileName: response.data.KOL_Resume,

                    profile_photo: response.data.KOL_Profile_Photo,
                    // BiographyResume: response.data.KOL_Resume, 

                })
                this.setState({
                    ShowBar: false,
                })
                console.log("PersonalInformation", this.state.PersonalInformation.doctor_full_name);
                console.log("Education", this.state.Education);
                console.log("WorkDetails===============", this.state.WorkDetails);
                console.log("BiographyResume========", this.state.BiographyResume);
                console.log("BiographyResumeFileName========", this.state.BiographyResumeFileName);
                console.log("Interactions===========", this.state.Interactions)

                // biography file name last only
                if (this.state.BiographyResumeFileName !== null) {
                    console.log("in resume name ")
                    var resumename = this.state.BiographyResumeFileName
                    console.log('resumename::::::::::::::', resumename);
                    var finalResumeName = resumename.substr(resumename.lastIndexOf('/') + 1);
                    this.setState({
                        FinalResumeName: finalResumeName,
                    })
                    console.log("finalResumeName", finalResumeName)
                }

                if (this.state.PersonalInformation.salutation_links != '') {
                    this.setState({
                        itemsSalutation: this.state.PersonalInformation.salutation_links.split(','),
                    })
                    console.log("itemsSalutation================", this.state.itemsSalutation);
                }

                if (this.state.PersonalInformation.doctor_full_name_links != '') {
                    this.setState({
                        itemsDoctorFullName: this.state.PersonalInformation.doctor_full_name_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.first_name_links != '') {
                    this.setState({
                        itemsFirstName: this.state.PersonalInformation.first_name_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.middle_name_links != '') {
                    this.setState({
                        itemsMiddleName: this.state.PersonalInformation.middle_name_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.last_name_links != '') {
                    this.setState({
                        itemsLastName: this.state.PersonalInformation.last_name_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.suffix_links != '') {
                    this.setState({
                        itemsSuffix: this.state.PersonalInformation.suffix_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.qualification_links != '') {
                    this.setState({
                        itemsQualification: this.state.PersonalInformation.qualification_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.experience_links != '') {
                    this.setState({
                        itemsExperience: this.state.PersonalInformation.experience_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.medical_schools_links != '') {
                    this.setState({
                        itemsMedicalSchool: this.state.PersonalInformation.medical_schools_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.medical_registration_no_links != '') {
                    this.setState({
                        itemsMedicalRegisterNo: this.state.PersonalInformation.medical_registration_no_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.NPI_no_links != '') {
                    this.setState({
                        itemsNpiNo: this.state.PersonalInformation.NPI_no_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.brand_links != '') {
                    this.setState({
                        itemsBrand: this.state.PersonalInformation.brand_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.specialty_links != '') {
                    this.setState({
                        itemSpeciality: this.state.PersonalInformation.specialty_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.specialty2_links != '') {
                    this.setState({
                        itemSpeciality2: this.state.PersonalInformation.specialty2_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.procedure_type_links != '') {
                    this.setState({
                        itemsProcedure: this.state.PersonalInformation.procedure_type_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.sub_specialty_links != '') {
                    this.setState({
                        itemsSubSpeciality: this.state.PersonalInformation.sub_specialty_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.expertise_links != '') {
                    this.setState({
                        itemsexpertise: this.state.PersonalInformation.expertise_links.split(','),
                    })
                }


                if (this.state.PersonalInformation.doctor_contact_links != '') {
                    this.setState({
                        itemsDoctorContact: this.state.PersonalInformation.doctor_contact_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.doctor_email_links != '') {
                    this.setState({
                        itemsDoctorEmail: this.state.PersonalInformation.doctor_email_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.doctor_email2_links != '') {
                    this.setState({
                        itemsDoctorEmail2: this.state.PersonalInformation.doctor_email2_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.fax_links != '') {
                    this.setState({
                        itemsFax: this.state.PersonalInformation.fax_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.linked_in_profile_links != '') {
                    this.setState({
                        itemsLinkedIn: this.state.PersonalInformation.linked_in_profile_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.twitter_handle_links != '') {
                    this.setState({
                        itemsTwitter: this.state.PersonalInformation.twitter_handle_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.assistance_contact_no_links != '') {
                    this.setState({
                        itemsAssistanceContact: this.state.PersonalInformation.assistance_contact_no_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.assistance_email_links != '') {
                    this.setState({
                        itemsAssistanceEmail: this.state.PersonalInformation.assistance_email_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.website_links != '') {
                    this.setState({
                        itemsWebsite: this.state.PersonalInformation.website_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.country_links != '') {
                    this.setState({
                        itemsCountry: this.state.PersonalInformation.country_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.state_links != '') {
                    this.setState({
                        itemsState: this.state.PersonalInformation.state_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.city_links != '') {
                    this.setState({
                        itemsCity: this.state.PersonalInformation.city_links.split(','),
                    })
                }

                if (this.state.PersonalInformation.address_links != '') {
                    this.setState({
                        itemsAddress1: this.state.PersonalInformation.address_links.split(','),
                    })
                }
                if (this.state.PersonalInformation.address_2links != '') {
                    this.setState({
                        itemsAddress2: this.state.PersonalInformation.address_2links.split(','),
                    })
                }

                if (this.state.PersonalInformation.procedure_potential_links != '') {
                    this.setState({
                        itemsProcedurePotential: this.state.PersonalInformation.procedure_potential_links.split(','),
                    })
                }

                if (KOL_Personal_Info.country_id) {
                    if (KOL_Personal_Info.country_id !== '0') {
                        const url = global.websiteUrl+'/service/Location/get_StateList';
                        let formData = new FormData();
                        formData.append('country_id', this.state.PersonalInformation.country_id);

                        const config = {
                            //   headers: { 'content-type': 'multipart/form-data' }
                        }
                        axios.post(url, formData, config)
                            .then(response => {
                                console.log(response);
                                var testStateData = response.data.Country_Details;
                                console.log("testStateData", JSON.stringify(testStateData));
                                this.setState({
                                    StateData: response.data.Country_Details,
                                })
                                console.log("StateData======", this.state.StateData)
                            })
                            .catch(error => {
                                console.log(error);
                            });
                    }
                }

                // if (KOL_Personal_Info.country_id) {
                //     if (KOL_Personal_Info.country_id !== '0') {
                //         const url = global.websiteUrl+'/service/Location/get_CityList';
                //         let formData = new FormData();
                //         formData.append('country_id', this.state.PersonalInformation.country_id);

                //         const config = {
                //             //   headers: { 'content-type': 'multipart/form-data' }
                //         }
                //         axios.post(url, formData, config)
                //             .then(response => {
                //                 console.log(response);
                //                 var testCityData = response.data.Country_Details;
                //                 console.log("testCityData", JSON.stringify(testCityData));
                //                 this.setState({
                //                     CityData: response.data.Country_Details,
                //                 })
                //                 console.log("CityData======", this.state.CityData)
                //             })
                //             .catch(error => {
                //                 console.log(error);
                //             });
                //     }
                // }

                if (KOL_Personal_Info.specialty_id) {
                    console.log("in Procedure list");
                    console.log("speciality_type_id", this.state.PersonalInformation.specialty_id);


                    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
                    let formData = new FormData();
                    formData.append('speciality_type_id', this.state.PersonalInformation.specialty_id);
                    formData.append('search_type', "Procedure");

                    const config = {
                        //   headers: { 'content-type': 'multipart/form-data' }
                    }
                    axios.post(url, formData, config)
                        .then(response => {
                            console.log(response);
                            var proceduredata = response.data;
                            console.log("proceduredata", JSON.stringify(proceduredata));
                            this.setState({
                                ProcedureData: response.data.data,
                                showProcedureSubSpecialityDropdown: false,
                                showProcedureSubSpecialityDropdownAdvBoard: false,
                            })
                            console.log("ProcedureData", this.state.ProcedureData)
                            this.setState({
                                showProcedureSubSpecialityDropdown: true,
                                showProcedureSubSpecialityDropdownAdvBoard: true,
                            })
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }

                if (KOL_Personal_Info.specialty_id) {
                    console.log("in sub speciality list");
                    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
                    let formData = new FormData();
                    formData.append('speciality_type_id', this.state.PersonalInformation.specialty_id);
                    formData.append('search_type', "Sub Speciality");

                    const config = {
                        //   headers: { 'content-type': 'multipart/form-data' }
                    }
                    axios.post(url, formData, config)
                        .then(response => {
                            console.log(response);
                            var SubSpecialitydata = response.data;
                            console.log("SubSpecialitydata", JSON.stringify(SubSpecialitydata));
                            this.setState({
                                SubSpecialitydata: response.data.data,
                                showProcedureSubSpecialityDropdown: false,
                            })
                            console.log("SubSpecialitydata", this.state.SubSpecialitydata)
                            this.setState({
                                showProcedureSubSpecialityDropdown: true,
                            })
                        })
                        .catch(error => {
                            console.log(error);
                        });
                }

                // to convert procedure id strings into array
                var procedureDefault = KOL_Personal_Info.procedure_type_id
                console.log("procedureDefault", procedureDefault);
                var arrayProcedureDefault = procedureDefault.split(',');
                console.log("arrayProcedureDefault", arrayProcedureDefault);

                arrayProcedureDefault.map(async (data) => {
                    if (data !== "") {
                        var joined = this.state.ProcedureType.concat(data);
                        await this.setState({
                            ProcedureType: joined
                        });
                        console.log("ProcedureTypeDefaultValues", this.state.ProcedureTypeDefaultValues)
                    }
                })

                // to convert sub speciality id strings into array
                var subSpecialityDefault = KOL_Personal_Info.sub_specialty_id
                console.log("subSpecialityDefault", subSpecialityDefault);
                var arraysubSpecialityDefault = subSpecialityDefault.split(',');
                console.log("arraysubSpecialityDefault", arraysubSpecialityDefault);

                arraysubSpecialityDefault.map(async (data) => {
                    if (data !== "") {
                        var joined = this.state.SubSpecialityType.concat(data);
                        await this.setState({
                            SubSpecialityType: joined
                        });
                        console.log("SubSpecialityTypeDefaultValues", this.state.SubSpecialityTypeDefaultValues)
                    }
                })


            })

    }

    // to set values of Work Details array
    handleChangeWorkDetails(i, e) {
        console.log(i);
        console.log(e.target);
        const { name, value } = e.target;
        let WorkDetails = [...this.state.WorkDetails];
        WorkDetails[i] = { ...WorkDetails[i], [name]: value };
        this.setState({ WorkDetails });
    }
    handleChangeCheckBoxWorkDetails = async (i, e) => {
        console.log(i);
        console.log(e.target);
        const { name, checked } = e.target;
        let WorkDetails = [...this.state.WorkDetails];
        WorkDetails[i] = { ...WorkDetails[i], [name]: checked };
        await this.setState({ WorkDetails });
    }

    RejectFunc = () => {

        this.setState({
            ShowProcedure: true,
        })

        console.log("biography array", this.state.Biography)
        console.log("resume sending to api", this.state.BiographyResume)
        console.log("photo sending to api", this.state.profile_photo)
        console.log("personal info", this.state.PersonalInformation)
        console.log('WorkDetails', this.state.WorkDetails)
        console.log("professional info", this.state.ProfessionalMemberships)
        console.log("Education", this.state.Education)
        console.log("AdvisoryBoard", this.state.AdvisoryBoard)
        console.log("AwardsHonours", this.state.AwardsHonours)
        console.log("BooksMonographs", this.state.BooksMonographs)
        console.log("Commitees", this.state.Commitees)
        console.log("ClinicalTrialDetails", this.state.ClinicalTrialDetails)
        console.log("EventsDetails", this.state.EventsDetails)
        console.log("Grants", this.state.Grants)
        console.log("Guidelines", this.state.Guidelines)
        console.log("HospitalAffiliations", this.state.HospitalAffiliations)
        console.log("Interactions", this.state.Interactions)
        console.log("PressItem", this.state.PressItem)
        console.log("Presentations", this.state.Presentation)
        console.log("PublicationDetails", this.state.PublicationDetails)
        console.log("SunshinePayment", this.state.SunshinePayment)
        // console.log("KOL TYPE",this.state.PersonalInformation.kol_type)

        // const isValid = this.validatesaveasdraft();
        // if (isValid){
        this.setState({
            ShowProcedure: true,
        })

        // const url = global.websiteUrl+'/service/Researcher/researcher';
        const url = global.websiteUrl+'/service/KOL/KOL';
        let formData = new FormData();
        formData.append('PersonalInformation', JSON.stringify(this.state.PersonalInformation));
        formData.append('WorkDetails', JSON.stringify(this.state.WorkDetails));
        formData.append('KOl_Identifier', JSON.stringify(this.state.KOL_Identifiers));
        formData.append('profile_photo', this.state.profile_photo);
        formData.append('Biography', JSON.stringify(this.state.Biography));
        formData.append('resume', this.state.BiographyResume);
        formData.append('ProfessionalMemberships', JSON.stringify(this.state.ProfessionalMemberships));
        formData.append('Education', JSON.stringify(this.state.Education));
        formData.append('AdvisoryBoard', JSON.stringify(this.state.AdvisoryBoard));
        formData.append('AwardsHonours', JSON.stringify(this.state.AwardsHonours));
        formData.append('BooksMonographs', JSON.stringify(this.state.BooksMonographs));
        formData.append('Commitees', JSON.stringify(this.state.Commitees));
        formData.append('ClinicalTrialDetails', JSON.stringify(this.state.ClinicalTrialDetails));
        formData.append('EventsDetails', JSON.stringify(this.state.EventsDetails));
        formData.append('Grants', JSON.stringify(this.state.Grants));
        formData.append('Guidelines', JSON.stringify(this.state.Guidelines));
        formData.append('HospitalAffiliations', JSON.stringify(this.state.HospitalAffiliations));
        formData.append('Interactions', JSON.stringify(this.state.Interactions));
        formData.append('PressItem', JSON.stringify(this.state.PressItem));
        formData.append('Presentations', JSON.stringify(this.state.Presentation));
        formData.append('PublicationDetails', JSON.stringify(this.state.PublicationDetails));
        formData.append('SunshinePayment', JSON.stringify(this.state.SunshinePayment));
        formData.append('kol_status', "Prelim Rejected");
        formData.append('kol_comment', this.state.kol_comment);
        // formData.append('kol_status',"Submit");
        formData.append('project_id', this.state.project_id);

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }
        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var data = response.data;
                console.log("data", data);
                var status = response.data.status;
                console.log("status", status);
                this.setState({
                    ShowProcedure: false,
                })
             //   if (response.data.status == true) {
                if (response.status === 200) {

                    Swal.fire({
                        icon: 'success',
                        text: response.data.message,
                        confirmButtonColor: '#d33',
                        confirmButtonText: 'OK'
                    })
                    this.setState({ RedirectManagerKolList: true })
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: response.data.message,
                        confirmButtonColor: '#d33',
                        confirmButtonText: 'OK'
                    })
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    validateCheckAll = () => {
        let salutationCheck = '';
        let doctorFullNameCheck = '';
        let firstNameCheck = '';
        // let middaleNameCheck= '';
        let lastNameCheck = '';
        // let qualificationChech= '';
        // let experienceCheck= '';
        // let mediSchoolCheck= '';
        let mediRegiNoCheck = '';
        let NpiCheck = '';
        // let brancdCheck= '';
        let procedureCheck = '';
        let specialityCheck = '';
        let subSpecialityCheck = '';
        let expertiseCheck = '';
        let doctorContactCheck = '';
        let doctorEmailCheck = '';
        let doctorEmail2Check = '';
        // let linkedinProfileCheck= '';
        // let twitterHandleCheck= '';
        // let assistanceContactCheck= '';
        // let assistaceEmailCheck= '';
        // let websiteCheck= '';
        let addressCheck = '';
        let address2Check = '';
        // let faxCheck= '';
        let stateCheck = '';
        let counterCheck = '';
        let procedurePotentialCheck = '';
        let zipCheck = '';
        // let contractCheck= '';
        // workditails
        let placeofWorkCheck = '';
        let designantionCheck = '';
        // let departmentCheck = '';

        let eventConferencTypeCheck = '';
        let eventRoleCheck = '';
        let advBoardCompanyTypeCheck = '';
        let advBoardRoleCheck = '';
        let publicationJournalTypeCheck = '';
        let publicationRoleCheck = '';
        let ClinicalNoOfTrialLocationCheck = '';
        let clinicalTrialTypeCheck = '';
        let guidelinesOrgTypeCheck = '';

        // Personal Information
        console.log("this.state.PersonalInformation.salutation_status==============", this.state.PersonalInformation.salutation_status)
        if (this.state.PersonalInformation.salutation_status !== true && this.state.PersonalInformation.salutation_status !== 'true') {
            salutationCheck = 'Not Checked';
        }
        if (this.state.PersonalInformation.doctor_full_name_status !== true && this.state.PersonalInformation.doctor_full_name_status !== 'true') {
            doctorFullNameCheck = 'Not Checked';
        }
        if (this.state.PersonalInformation.first_name_status !== true && this.state.PersonalInformation.first_name_status !== 'true') {
            firstNameCheck = 'Not Checked';
        }
        if (this.state.PersonalInformation.last_name_status !== true && this.state.PersonalInformation.last_name_status !== 'true') {
            lastNameCheck = 'Not Checked';
        }

        if (this.state.PersonalInformation.procedure_type_status !== true && this.state.PersonalInformation.procedure_type_status !== 'true') {
            procedureCheck = 'Not Checked';
        }
        if (this.state.PersonalInformation.specialty_status !== true && this.state.PersonalInformation.specialty_status !== 'true') {
            specialityCheck = 'Not Checked';
        }
        if (this.state.PersonalInformation.sub_specialty_status !== true && this.state.PersonalInformation.sub_specialty_status !== 'true') {
            subSpecialityCheck = 'Not Checked';
        }

        if (this.state.PersonalInformation.expertise_status !== true && this.state.PersonalInformation.expertise_status !== 'true') {
            expertiseCheck = 'Not Checked';
        }

        if (this.state.PersonalInformation.doctor_contact_status !== true && this.state.PersonalInformation.doctor_contact_status !== 'true') {
            doctorContactCheck = 'Not Checked';
        }
        if (this.state.PersonalInformation.doctor_email_status !== true && this.state.PersonalInformation.doctor_email_status !== 'true') {
            doctorEmailCheck = 'Not Checked';
        }

        if (this.state.PersonalInformation.doctor_email2_status !== true && this.state.PersonalInformation.doctor_email2_status !== 'true') {
            doctorEmail2Check = 'Not Checked';
        }


        if (this.state.PersonalInformation.address_status !== true && this.state.PersonalInformation.address_status !== 'true') {
            addressCheck = 'Not Checked';
        }

        if (this.state.PersonalInformation.address_2status !== true && this.state.PersonalInformation.address_2status !== 'true') {
            address2Check = 'Not Checked';
        }
        
        if (this.state.PersonalInformation.state_status !== true && this.state.PersonalInformation.state_status !== 'true') {
            stateCheck = 'Not Checked';
        }
        if (this.state.PersonalInformation.country_status !== true && this.state.PersonalInformation.country_status !== 'true') {
            counterCheck = 'Not Checked';
        }

        if (this.state.PersonalInformation.zip_code_status !== true && this.state.PersonalInformation.zip_code_status !== 'true') {
            zipCheck = 'Not Checked';
        }

        {
            this.state.WorkDetails.map((el, i) => {
                if (el.current_place_of_work_status !== true && el.current_place_of_work_status !== 'true') {
                    placeofWorkCheck = 'Not Checked';
                }
                if (el.designation_status !== true && el.designation_status !== 'true') {
                    designantionCheck = 'Not Checked';
                }
                // if (el.department_status !== true && el.department_status !== 'true') {
                //     departmentCheck = 'Not Checked';
                // }
                console.log("WorkDetailsWorkDetailsWorkDetails", this.state.WorkDetails)
            })
        }

        if (this.state.KOL_Identifiers.event_identifier == true) {
            if (this.state.KOL_Identifiers.international_event !== true && this.state.KOL_Identifiers.national_event !== true) {
                eventConferencTypeCheck = 'Please Select At Least One';
            }
            if (this.state.KOL_Identifiers.keynote_speaker_event !== true && this.state.KOL_Identifiers.regular_event !== true) {
                eventRoleCheck = 'Please Select At Least One';
            }
        }

        if (this.state.KOL_Identifiers.advisory_identifier == true) {
            if (this.state.KOL_Identifiers.international_advisory_board !== true && this.state.KOL_Identifiers.national_advisory_board !== true) {
                advBoardCompanyTypeCheck = 'Please Select At Least One';
            }
            if (this.state.KOL_Identifiers.board_member_advisory_board !== true && this.state.KOL_Identifiers.advisory_committee_advisory_board !== true) {
                advBoardRoleCheck = 'Please Select At Least One';
            }
        }

        if (this.state.KOL_Identifiers.guidelines_identifier == true) {
            if (this.state.KOL_Identifiers.international_guideline !== true && this.state.KOL_Identifiers.national_guideline !== true &&
                this.state.KOL_Identifiers.local_guideline !== true) {
                guidelinesOrgTypeCheck = 'Please Select At Least One';
            }
        }

        if (salutationCheck || doctorFullNameCheck || firstNameCheck || lastNameCheck ||
            mediRegiNoCheck || NpiCheck || procedureCheck || specialityCheck ||
            subSpecialityCheck || expertiseCheck || doctorContactCheck || doctorEmailCheck || doctorEmail2Check ||
            addressCheck || stateCheck || address2Check ||
            counterCheck || procedurePotentialCheck || zipCheck ||
            placeofWorkCheck || designantionCheck || eventConferencTypeCheck || eventRoleCheck ||
            advBoardCompanyTypeCheck || advBoardRoleCheck || publicationJournalTypeCheck || publicationRoleCheck ||
            ClinicalNoOfTrialLocationCheck || clinicalTrialTypeCheck || guidelinesOrgTypeCheck) {
            this.setState({
                salutationCheck, doctorFullNameCheck, firstNameCheck, lastNameCheck,
                mediRegiNoCheck, NpiCheck, procedureCheck, specialityCheck,
                subSpecialityCheck,expertiseCheck, doctorContactCheck, doctorEmailCheck,doctorEmail2Check,
                addressCheck, address2Check,stateCheck,
                counterCheck, procedurePotentialCheck, zipCheck,
                placeofWorkCheck, designantionCheck, eventConferencTypeCheck, eventRoleCheck,
                advBoardCompanyTypeCheck, advBoardRoleCheck, publicationJournalTypeCheck, publicationRoleCheck,
                ClinicalNoOfTrialLocationCheck, clinicalTrialTypeCheck, guidelinesOrgTypeCheck
            });
            return false;
        }
        return true;
    }

    ApproveFunc = () => {
        console.log("biography array", this.state.Biography)
        console.log("resume", this.state.BiographyResume)
        console.log("photo", this.state.profile_photo)
        console.log("personal info", this.state.PersonalInformation)
        console.log('WorkDetails', this.state.WorkDetails)
        console.log("professional info", this.state.ProfessionalMemberships)
        console.log("Education", this.state.Education)
        console.log("AdvisoryBoard", this.state.AdvisoryBoard)
        console.log("AwardsHonours", this.state.AwardsHonours)
        console.log("BooksMonographs", this.state.BooksMonographs)
        console.log("Commitees", this.state.Commitees)
        console.log("ClinicalTrialDetails", this.state.ClinicalTrialDetails)
        console.log("EventsDetails", this.state.EventsDetails)
        console.log("Grants", this.state.Grants)
        console.log("Guidelines", this.state.Guidelines)
        console.log("HospitalAffiliations", this.state.HospitalAffiliations)
        console.log("Interactions", this.state.Interactions)
        console.log("PressItem", this.state.PressItem)
        console.log("Presentations", this.state.Presentation)
        console.log("PublicationDetails", this.state.PublicationDetails)
        console.log("SunshinePayment", this.state.SunshinePayment)
        console.log("KOL TYPE", this.state.KOLType)

        const isValid = this.validateCheckAll();
        if (isValid) {
            this.setState({
                ShowProcedure: true,
            })
            // const url = global.websiteUrl+'/service/Researcher/researcher';
            const url = global.websiteUrl+'/service/KOL/KOL';
            let formData = new FormData();
            formData.append('PersonalInformation', JSON.stringify(this.state.PersonalInformation));
            formData.append('WorkDetails', JSON.stringify(this.state.WorkDetails));
            formData.append('KOL_Identifier', JSON.stringify(this.state.KOL_Identifiers));
            formData.append('profile_photo', this.state.profile_photo);
            formData.append('Biography', JSON.stringify(this.state.Biography));
            formData.append('resume', this.state.BiographyResume);
            formData.append('ProfessionalMemberships', JSON.stringify(this.state.ProfessionalMemberships));
            formData.append('Education', JSON.stringify(this.state.Education));
            formData.append('AdvisoryBoard', JSON.stringify(this.state.AdvisoryBoard));
            formData.append('AwardsHonours', JSON.stringify(this.state.AwardsHonours));
            formData.append('BooksMonographs', JSON.stringify(this.state.BooksMonographs));
            formData.append('Commitees', JSON.stringify(this.state.Commitees));
            formData.append('ClinicalTrialDetails', JSON.stringify(this.state.ClinicalTrialDetails));
            formData.append('EventsDetails', JSON.stringify(this.state.EventsDetails));
            formData.append('Grants', JSON.stringify(this.state.Grants));
            formData.append('Guidelines', JSON.stringify(this.state.Guidelines));
            formData.append('HospitalAffiliations', JSON.stringify(this.state.HospitalAffiliations));
            formData.append('Interactions', JSON.stringify(this.state.Interactions));
            formData.append('PressItem', JSON.stringify(this.state.PressItem));
            formData.append('Presentations', JSON.stringify(this.state.Presentation));
            formData.append('PublicationDetails', JSON.stringify(this.state.PublicationDetails));
            formData.append('SunshinePayment', JSON.stringify(this.state.SunshinePayment));
            formData.append('kol_status', "Prelim Approved");
            formData.append('kol_comment', this.state.kol_comment);
            // formData.append('kol_status',"Submit");
            formData.append('project_id', this.state.project_id);

            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            axios.post(url, formData, config)
                .then(response => {
                    console.log(response);
                    var data = response.data;
                    console.log("data", data);
                    var status = response.data.status;
                    console.log("status", status);
                    this.setState({
                        ShowProcedure: false,
                    })
                   // if (response.data.status == true) {
                    if (response.status === 200) {

                        Swal.fire({
                            icon: 'success',
                            //text: response.data.message,
                            text:'Prelim Approved',
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'OK'
                        })
                        this.setState({ RedirectManagerKolList: true })
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            text: response.data.message,
                            confirmButtonColor: '#d33',
                            confirmButtonText: 'OK'
                        })
                    }
                })
                .catch(error => {
                    console.log(error);
                });
            this.setState({
                salutationCheck: '',
                doctorFullNameCheck: '',
                firstNameCheck: '',
                // middaleNameCheck: '',
                lastNameCheck: '',
                // qualificationChech: '',
                // experienceCheck: '',
                // mediSchoolCheck: '',
                mediRegiNoCheck: '',
                NpiCheck: '',
                brancdCheck: '',
                procedureCheck: '',
                specialityCheck: '',
                subSpecialityCheck: '',
                
                expertiseCheck: '',
                
                doctorContactCheck: '',
                doctorEmailCheck: '',
                doctorEmail2Check:'',
                // linkedinProfileCheck: '',
                // twitterHandleCheck: '',
                // assistanceContactCheck: '',
                // assistaceEmailCheck: '',
                // websiteCheck: '',
                addressCheck: '',
                address2Check: '',
                // faxCheck: '',
                stateCheck: '',
                counterCheck: '',
                procedurePotentialCheck: '',
                zipCheck: '',
                // contractCheck: '',
                // workditails
                placeofWorkCheck: '',
                designantionCheck: '',
                // departmentCheck: '',

                eventConferencTypeCheck: '',
                eventRoleCheck: '',
                advBoardCompanyTypeCheck: '',
                advBoardRoleCheck: '',
                publicationJournalTypeCheck: '',
                publicationRoleCheck: '',
                ClinicalNoOfTrialLocationCheck: '',
                clinicalTrialTypeCheck: '',
                guidelinesOrgTypeCheck: '',
            })
        }
        else 
        {
            {
                Swal.fire({
                    icon: 'error',
                    text: 'Please Check All Fields',
                    confirmButtonColor: '#d33',
                    confirmButtonText: 'OK'
                })
            }
        }
    }

    BackToManagerKolList() {
        this.setState({ RedirectManagerKolList: true })
    }

    render() {

    //     if(this.state.PersonalInformation.doctor_email2 === "")
    //     {
        
    //     alert(this.state.PersonalInformation.doctor_email2_status);
    // }      

        if (this.state.RedirectManagerKolList) {
            return (<Redirect to={{
                pathname: '/ManagerKolList',
            }} />)
        }

        let { imagePreviewUrl } = this.state;
        let imagePreview = null;

        if (this.state.profile_photo_for_edit !== 'NULL' && this.state.profile_photo_for_edit) {
            // console.log("in profile photo for edit")
            console.log("image is", this.state.profile_photo_for_edit)
            imagePreview = (<div> <img style={{ height: '150px', width: '100px' }} src={this.state.profile_photo_for_edit} /> </div>)
        }
        else
            if (imagePreviewUrl) {
                console.log("in profile photo original")
                imagePreview = (<img style={{ height: '150px', width: '100px' }} src={imagePreviewUrl} />);
            } else {
                // console.log("in profile photo not selected")
                imagePreview = (<div className="photo-box"></div>);
            }

        let speciality_type = this.state.SpecialityData;
        let OptionsSpeciality = speciality_type.map((speciality_type) =>
            <option key={speciality_type.id} value={speciality_type.id}>
                {speciality_type.speciality_type}</option>
        );

        let Country_list = this.state.CountryListData;
        let OptionsCountry = Country_list.map((Country_list) =>
            <option key={Country_list.id} value={Country_list.id}>
                {Country_list.country_name}</option>
        );

        let state_list = this.state.StateData;
        let OptionsState = state_list.map((state_list) =>
            <option key={state_list.id} value={state_list.id}>
                {state_list.state_name}</option>
        );

        // let City_list = this.state.CityData;
        // let OptionsCity = City_list.map((City_list) =>
        //     <option key={City_list.id} value={City_list.id}>
        //         {City_list.city_name}</option>
        // );

        return (
            <body>
                <div style={{marginTop:'12vh' }}>
                    <div>
                        {this.state.ShowBar ?
                            <div style={{ position: 'fixed', top: '0' }}>
                                <LoadingBar
                                    progress='100'
                                    // onLoaderFinished={() => setProgress(0)}
                                    //  progress={progress}
                                    height={3} color="#A90007" transitionTime={4000}
                                    loaderSpeed={4000} />
                            </div>
                            : null}
                    </div>

                    {this.state.ShowProcedure ? <Modal isOpen={true} className='propagateLoader' centered>
                        <PropagateLoader color={'#A90007'} size='40px' loading='true' />
                    </Modal> : null}
                    <div>
                        <div>
                            <ManagerHeader ManagerName={this.state.manager_name} />
                            <div class="container">
                                <div class="content-wrapper">
                                    <div class="screen-headingbox">
                                        <div class="back-section">
                                            <a onClick={this.BackToManagerKolList.bind(this)}>
                                                <img src={require('../Assets/Images/back-arrow.svg')} />Back</a>
                                        </div>
                                        <h2>Profile Details </h2>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-3 col-sm-12 col-xs-12 profile-box">
                                            <div class="sidebar sticky">
                                                <ul class="cetas-leftnav">
                                                    <li>
                                                        <a>
                                                            <text style={{ color: '#A90007', fontWeight: 'bold' }}>Personal Information</text>
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a>
                                                            <text>Biography</text>
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a>
                                                            <text>Professional Membership</text>
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a>
                                                            <text>Education</text>
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a>
                                                            <text>Advisory Board</text>
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a>
                                                            <text>Awards & Honours</text>
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a>
                                                            <text>Books & Monographs</text>
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a>
                                                            <text>Commitees</text>
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a>
                                                            <text>Clinical Trial Details</text>
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a>
                                                            <text>Events Details</text>
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a>
                                                            <text>Grants</text>
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a>
                                                            <text>Guidelines</text>
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a>
                                                            <text>Hospital Affiliations</text>
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a>
                                                            <text>Interactions</text>
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a>
                                                            <text>Press Item</text>
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a>
                                                            <text>Presentations</text>
                                                        </a>
                                                    </li>


                                                    <li>
                                                        <a>
                                                            <text>Publication Details</text>
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a>
                                                            <text>Sunshine Payment</text>
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a>
                                                            <text>Contract</text>
                                                        </a>
                                                    </li>

                                                    <li>
                                                        <a>
                                                            <text>Procedure Potential</text>
                                                        </a>
                                                    </li>
                                                    <li></li>
                                                    <div style={{ marginTop: '50%' }}></div>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-md-9">
                                            <div class="scroll-box" style={{marginTop:'-100px' }}>
                                                <a id="overall-details"></a>
                                                <section style={{ marginBottom: '30%' }}>
                                                    <h3 class="mt-30">Personal information </h3>
                                                    <span class="mandatory">Note: All * fields are mandatory </span>
                                                    <div class="scrollable-box">
                                                        <form>
                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <div class="form-group">
                                                                        {/* <label for="exampleInputEmail1">Select KOL type </label>
                                                    <span class="mandatory">*</span>
                                                        <select class="form-control" 
                                                            id="exampleFormControlSelect1">
                                                            <option>Select Speciality</option>
                                                            <option>Researcher</option>
                                                            <option>Manager</option>
                                                            <option>Data entry</option>
                                                            <option>5</option>
                                                        </select> */}
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Select KOL Type <span class="mandatory">*</span></label>
                                                                        <Input type="select" id="roleSelect" className="form-control disabled"
                                                                            style={{ height: '50px' }}
                                                                            autofocus="true"
                                                                            name="kol_type"
                                                                            value={this.state.PersonalInformation.kol_type}
                                                                        // onChange={async(e) => {
                                                                        //     let { PersonalInformation } = this.state;
                                                                        //     PersonalInformation.kol_type = e.target.value;
                                                                        //     await this.setState({ PersonalInformation, });
                                                                        //     console.log("PersonalInformation.kol_type",this.state.PersonalInformation.kol_type)
                                                                        //     {this.afterSelectingKOL()}
                                                                        //     // below afterSelectSalutation call is just if user 1st selects the saalutation and then selects KOL
                                                                        //     {this.afterSelectSalutation()}
                                                                        // }}  
                                                                        >
                                                                            <option value="" >--Select KOL--</option>
                                                                            <option value="KOL Community" >HCP</option>
                                                                            <option value="Payers Community" >Payers</option>
                                                                            <option value="HTA Experts" >HTA Experts</option>
                                                                            <option value="Hospital Procurement" >Hospital Procurement Experts</option>
                                                                            <option value="Advocacy Group" >Advocacy Experts</option>
                                                                            <option value="MedTech Experts" >MedTech Experts</option>

                                                                        </Input>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style={{
                                                                color: "red", fontSize: "12px",
                                                                marginBottom: "5px",
                                                            }}>
                                                                {this.state.koltypeErr}</div>
                                                            <hr />
                                                            <div class="row">
                                                                <div class="col-md-4">
                                                                    {imagePreview}
                                                                    {
                                                                        <div>
                                                                            <input type='file'
                                                                                id="myInput"
                                                                                accept="image/*"
                                                                                onChange={this.getPhoto} hidden />
                                                                            {/* <label htmlFor="myInput" 
                                                                    style={{color:'blue', fontSize: "15px",}}>
                                                                Upload Photo
                                                            </label> */}
                                                                        </div>
                                                                    }
                                                                </div>

                                                                <div class="col-md-3" style={{ marginLeft: '-8%' }}>
                                                                    <div class="form-group bottom-seperator pb-30">
                                                                        <label for="exampleFormControlTextarea1">SI. No.</label>
                                                                        {/* <input class="form-control" type="text" 
                                                        placeholder={this.state.SrNO} 
                                                        readonly="" /> */}
                                                                        <text className="form-control">{this.state.PersonalInformation.id}</text>
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Unique ID</label>
                                                                        {/* <input class="form-control" type="text" 
                                                                placeholder="UTFI8782" readonly="" /> */}
                                                                        {/* <text className="form-control">{this.state.PersonalInformation.kol_unique_id}</text> */}
                                                                        <text className="form-control">{this.state.kol_unique_id}</text>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr />
                                                            <div class="form-group">

                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Salutation <span class="mandatory">*</span></label>
                                                                        <Input type="select" id="roleSelect" className="form-control disabled"
                                                                            style={{ height: '50px' }}
                                                                            name="salutation"
                                                                            value={this.state.PersonalInformation.salutation}
                                                                        // onChange={async(e) => {
                                                                        //     let { PersonalInformation } = this.state;
                                                                        //     PersonalInformation.salutation = e.target.value;
                                                                        //     await this.setState({ PersonalInformation });
                                                                        //     {this.afterSelectSalutation()}
                                                                        // }}  
                                                                        >
                                                                            <option value="" >--Select Salutation--</option>
                                                                            <option value="Mr." >Mr.</option>
                                                                            <option value="Ms." >Ms.</option>
                                                                            <option value="Mrs." >Mrs.</option>
                                                                            <option value="Dr." >Dr.</option>
                                                                            <option value="Prof." >Prof.</option>
                                                                            <option value="Other" >Other</option>
                                                                        </Input>
                                                                        <div style={{
                                                                            color: "red", fontSize: "12px", marginTop: "1%",
                                                                            marginBottom: "5px",
                                                                        }}>
                                                                            {this.state.salutationErr}</div>
                                                                        {
                                                                            (!this.state.SalutationAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div style={{ marginTop: '8%' }}>
                                                                                    {/* {console.log("this.state.itemsSalutationthis.state.itemsSalutationthis.state.itemsSalutation",this.state.itemsSalutation)} */}
                                                                                    {this.state.itemsSalutation.map((itemsSalutation) => (
                                                                                        <div className="tag-item" key={itemsSalutation}>
                                                                                            {/* {itemsSalutation} */}
                                                                                            <a href={itemsSalutation} target="_blank">
                                                                                                <text>{itemsSalutation}</text>
                                                                                            </a>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>

                                                                    <div class="col-md-5">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Comment</label>
                                                                        <input type="text" class="form-control-addsource-Personal"
                                                                            id="input3" placeholder="Enter Comment"
                                                                            name="salutation_comment"
                                                                            value={this.state.PersonalInformation.salutation_comment}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.salutation_comment = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div class="col-md-1">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Check</label>
                                                                        <input type='checkbox'
                                                                            class="form-control-addsource-Personal-ckeckbox"
                                                                            // id="input3"
                                                                            name="salutation_status"
                                                                            checked={this.state.PersonalInformation.salutation_status}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.salutation_status = e.target.checked;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                </div>
                                                                <hr />

                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <label for="exampleFormControlTextarea1" >
                                                                            Full Name <span class="mandatory">*</span></label>
                                                                        <input type="text" class="form-control disabled"
                                                                            id="input3" placeholder="Enter Full Name"
                                                                            name="doctor_full_name"
                                                                            value={this.state.PersonalInformation.doctor_full_name}
                                                                        />
                                                                        <div className="loginErrCreateProject">{this.state.doctorfullnameErr}</div>
                                                                        {
                                                                            (!this.state.DFullNameAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div>
                                                                                    {this.state.itemsDoctorFullName.map((itemsDoctorFullName) => (
                                                                                        <div className="tag-item" key={itemsDoctorFullName}>
                                                                                            <a href={itemsDoctorFullName} target="_blank">
                                                                                                <text>{itemsDoctorFullName}</text>
                                                                                            </a>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>

                                                                    <div class="col-md-5">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Comment</label>
                                                                        <input type="text" class="form-control-addsource-Personal"
                                                                            id="input3" placeholder="Enter Comment"
                                                                            name="doctor_full_name_comment"
                                                                            value={this.state.PersonalInformation.doctor_full_name_comment}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.doctor_full_name_comment = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div class="col-md-1">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Check</label>
                                                                        <input type='checkbox'
                                                                            class="form-control-addsource-Personal-ckeckbox"
                                                                            // id="input3"
                                                                            name="doctor_full_name_status"
                                                                            checked={this.state.PersonalInformation.doctor_full_name_status}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.doctor_full_name_status = e.target.checked;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <hr />

                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <label for="exampleFormControlTextarea1" >
                                                                            First Name <span class="mandatory">*</span></label>
                                                                        <input type="text" class="form-control disabled"
                                                                            id="input5" placeholder="Enter First Name"
                                                                            name="first_name"
                                                                            value={this.state.PersonalInformation.first_name}
                                                                        />
                                                                        <div className="loginErrCreateProject">{this.state.firstnameErr}</div>
                                                                        {
                                                                            (!this.state.FirstNameAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div>
                                                                                    {this.state.itemsFirstName.map((itemsFirstName) => (
                                                                                        <div className="tag-item" key={itemsFirstName}>
                                                                                            {/* {itemsFirstName} */}
                                                                                            <a href={itemsFirstName} target="_blank">
                                                                                                <text>{itemsFirstName}</text>
                                                                                            </a>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>

                                                                    <div class="col-md-5">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Comment</label>
                                                                        <input type="text" class="form-control-addsource-Personal"
                                                                            id="input3" placeholder="Enter Comment"
                                                                            name="first_name_comment"
                                                                            value={this.state.PersonalInformation.first_name_comment}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.first_name_comment = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div class="col-md-1">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Check</label>
                                                                        <input type='checkbox'
                                                                            class="form-control-addsource-Personal-ckeckbox"
                                                                            // id="input3"
                                                                            name="first_name_status"
                                                                            checked={this.state.PersonalInformation.first_name_status}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.first_name_status = e.target.checked;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <hr />

                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Middle Name </label>
                                                                        <input type="text" class="form-control disabled"
                                                                            id="input5" placeholder="Enter Middle Name"
                                                                            name="middle_name"
                                                                            value={this.state.PersonalInformation.middle_name}
                                                                        />
                                                                        {
                                                                            (!this.state.MiddleNameAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div>
                                                                                    {this.state.itemsMiddleName.map((itemsMiddleName) => (
                                                                                        <div className="tag-item" key={itemsMiddleName}>
                                                                                            {/* {itemsMiddleName} */}
                                                                                            <a href={itemsMiddleName} target="_blank">
                                                                                                <text>{itemsMiddleName}</text>
                                                                                            </a>

                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>

                                                                    <div class="col-md-5">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Comment</label>
                                                                        <input type="text" class="form-control-addsource-Personal"
                                                                            id="input3" placeholder="Enter Comment"
                                                                            name="middle_name_comment"
                                                                            value={this.state.PersonalInformation.middle_name_comment}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.middle_name_comment = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div class="col-md-1">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Check</label>
                                                                        <input type='checkbox'
                                                                            class="form-control-addsource-Personal-ckeckbox"
                                                                            // id="input3"
                                                                            name="middle_name_status"
                                                                            checked={this.state.PersonalInformation.middle_name_status}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.middle_name_status = e.target.checked;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <hr />

                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <label for="exampleFormControlTextarea1" >
                                                                            Last Name <span class="mandatory">*</span></label>
                                                                        <input type="text" class="form-control disabled"
                                                                            id="input5" placeholder="Enter Last Name"
                                                                            name="last_name"
                                                                            value={this.state.PersonalInformation.last_name}
                                                                        />
                                                                        <div className="loginErrCreateProject">{this.state.lastnameErr}</div>
                                                                        {
                                                                            (!this.state.LastNameAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div>
                                                                                    {this.state.itemsLastName.map((itemsLastName) => (
                                                                                        <div className="tag-item" key={itemsLastName}>
                                                                                            {/* {itemsLastName} */}
                                                                                            <a href={itemsLastName} target="_blank">
                                                                                                <text>{itemsLastName}</text>
                                                                                            </a>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>

                                                                    <div class="col-md-5">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Comment</label>
                                                                        <input type="text" class="form-control-addsource-Personal"
                                                                            id="input3" placeholder="Enter Comment"
                                                                            name="last_name_comment"
                                                                            value={this.state.PersonalInformation.last_name_comment}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.last_name_comment = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div class="col-md-1">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Check</label>
                                                                        <input type='checkbox'
                                                                            class="form-control-addsource-Personal-ckeckbox"
                                                                            // id="input3"
                                                                            name="last_name_status"
                                                                            checked={this.state.PersonalInformation.last_name_status}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.last_name_status = e.target.checked;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <hr />

                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <label for="exampleFormControlTextarea1" >
                                                                            Suffix <span class="mandatory">*</span></label>
                                                                        <input type="text" class="form-control disabled"
                                                                            id="input5" placeholder="Enter Suffix"
                                                                            name="suffix"
                                                                            value={this.state.PersonalInformation.suffix}
                                                                        />
                                                                        {/* <div className="loginErrCreateProject">{this.state.suffixErr}</div> */}
                                                                        {
                                                                            (!this.state.SuffixAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div>
                                                                                    {this.state.itemsSuffix.map((itemsSuffix) => (
                                                                                        <div className="tag-item" key={itemsSuffix}>
                                                                                            {/* {itemsSuffix} */}
                                                                                            <a href={itemsSuffix} target="_blank">
                                                                                                <text>{itemsSuffix}</text>
                                                                                            </a>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>

                                                                    <div class="col-md-5">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Comment</label>
                                                                        <input type="text" class="form-control-addsource-Personal"
                                                                            id="input3" placeholder="Enter Comment"
                                                                            name="suffix_comments"
                                                                            value={this.state.PersonalInformation.suffix_comments}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.suffix_comments = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div class="col-md-1">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Check</label>
                                                                        <input type='checkbox'
                                                                            class="form-control-addsource-Personal-ckeckbox"
                                                                            // id="input3"
                                                                            name="suffix_status"
                                                                            checked={this.state.PersonalInformation.suffix_status}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.suffix_status = e.target.checked;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <hr />

                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Clinical Experience <span class="mandatory">*</span></label>
                                                                        <Input type="select" id="roleSelect" className="form-control disabled"
                                                                            style={{ height: '50px' }}
                                                                            name="experience"
                                                                            value={this.state.PersonalInformation.experience}
                                                                        >
                                                                            <option value="" >--Select Experience--</option>
                                                                            <option value="1 year" >1 year</option>
                                                                            <option value="2 years" >2 years</option>
                                                                            <option value="3 years" >3 years</option>
                                                                            <option value="4 years" >4 years</option>
                                                                            <option value="5 years" >5 years</option>
                                                                            <option value="6 years" >6 years</option>
                                                                            <option value="7 years" >7 years</option>
                                                                            <option value="8 years" >8 years</option>
                                                                            <option value="9 years" >9 years</option>
                                                                            <option value="10 years" >10 years</option>
                                                                            <option value="11 years" >11 years</option>
                                                                            <option value="12 years" >12 years</option>
                                                                            <option value="13 years" >13 years</option>
                                                                            <option value="14 years" >14 years</option>
                                                                            <option value="15 years" >15 years</option>
                                                                            <option value="16 years" >16 years</option>
                                                                            <option value="17 years" >17 years</option>
                                                                            <option value="18 years" >18 years</option>
                                                                            <option value="19 years" >19 years</option>
                                                                            <option value="20 years" >20 years</option>
                                                                            <option value="21 years" >21 years</option>
                                                                            <option value="22 years" >22 years</option>
                                                                            <option value="23 years" >23 years</option>
                                                                            <option value="24 years" >24 years</option>
                                                                            <option value="25+ years" >25+ years</option>
                                                                            <option value="N/A" >N/A</option>
                                                                        </Input>
                                                                        <div style={{
                                                                            color: "red", fontSize: "12px", marginTop: "1%",
                                                                            marginBottom: "5px",
                                                                        }}>
                                                                            {this.state.experienceErr}</div>
                                                                        {
                                                                            (!this.state.ExperienceAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div>
                                                                                    {this.state.itemsExperience.map((itemsExperience) => (
                                                                                        <div className="tag-item" key={itemsExperience}>
                                                                                           
                                                                                            <a href={itemsExperience} target="_blank">
                                                                                                <text>{itemsExperience}</text>
                                                                                            </a>
                                                                                        

                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>

                                                                    <div class="col-md-5">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Comment</label>
                                                                        <input type="text" class="form-control-addsource-Personal"
                                                                            id="input3" placeholder="Enter Comment"
                                                                            name="experience_comment"
                                                                            value={this.state.PersonalInformation.experience_comment}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.experience_comment = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div class="col-md-1">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Check</label>
                                                                        <input type='checkbox'
                                                                            class="form-control-addsource-Personal-ckeckbox"
                                                                            // id="input3"
                                                                            name="experience_status"
                                                                            checked={this.state.PersonalInformation.experience_status}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.experience_status = e.target.checked;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <hr />

                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Qualification  <span class="mandatory">*</span></label>
                                                                        <input type="text" class="form-control disabled"
                                                                            id="input6" placeholder="Enter Qualification "
                                                                            name="qualification"
                                                                            value={this.state.PersonalInformation.qualification}
                                                                        />
                                                                        <div className="loginErrCreateProject">{this.state.qualificationErr}</div>
                                                                        {
                                                                            (!this.state.QualificationAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div>
                                                                                    {this.state.itemsQualification.map((itemsQualification) => (
                                                                                        <div className="tag-item" key={itemsQualification}>
                                                                                            {/* {itemsQualification} */}
                                                                                            <a href={itemsQualification} target="_blank">
                                                                                                <text>{itemsQualification}</text>
                                                                                            </a>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>

                                                                    <div class="col-md-5">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Comment</label>
                                                                        <input type="text" class="form-control-addsource-Personal"
                                                                            id="input3" placeholder="Enter Comment"
                                                                            name="qualification_comment"
                                                                            value={this.state.PersonalInformation.qualification_comment}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.qualification_comment = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div class="col-md-1">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Check</label>
                                                                        <input type='checkbox'
                                                                            class="form-control-addsource-Personal-ckeckbox"
                                                                            // id="input3"
                                                                            name="qualification_status"
                                                                            checked={this.state.PersonalInformation.qualification_status}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.qualification_status = e.target.checked;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <hr />



                                                                {/* {this.forMedicalInformation()} */}
                                                                {
                                                                    (this.state.PersonalInformation.kol_type == 'HTA Experts' ||
                                                                        this.state.PersonalInformation.kol_type == 'Payers Community' ||
                                                                        this.state.PersonalInformation.kol_type == 'Hospital Procurement' ||
                                                                        this.state.PersonalInformation.kol_type == 'Advocacy Group'
                                                                        // this.state.PersonalInformation.kol_type == 'Clinical Commission Group'
                                                                    ) ? (
                                                                        (this.state.PersonalInformation.salutation == 'Dr.') ? (
                                                                            <div>

                                                                                <div class="row">
                                                                                    <div class="col-md-6">
                                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                            {/* Medical Schools  */}
                                                                                            Alma Mater
                                                                                        </label>
                                                                                        <input type="text" class="form-control disabled"
                                                                                            id="input7" placeholder="Enter Alma Mater "
                                                                                            name="medical_schools"
                                                                                            value={this.state.PersonalInformation.medical_schools}
                                                                                        />
                                                                                        <div className="loginErrCreateProject">{this.state.medicalschoolErr}</div>
                                                                                        {
                                                                                            (!this.state.MedicalSchoolsAddSource) ? (
                                                                                                <div></div>
                                                                                            ) : (
                                                                                                <div style={{ marginTop: '8%' }}>
                                                                                                    {this.state.itemsMedicalSchool.map((itemsMedicalSchool) => (
                                                                                                        <div className="tag-item" key={itemsMedicalSchool}>
                                                                                                            {/* {itemsMedicalSchool} */}
                                                                                                            <a href={itemsMedicalSchool} target="_blank">
                                                                                                                <text>{itemsMedicalSchool}</text>
                                                                                                            </a>
                                                                                                        </div>
                                                                                                    ))}
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </div>

                                                                                    <div class="col-md-5">
                                                                                        <label for="exampleFormControlTextarea1">
                                                                                            Comment</label>
                                                                                        <input type="text" class="form-control-addsource-Personal"
                                                                                            id="input3" placeholder="Enter Comment"
                                                                                            name="medical_schools_comment"
                                                                                            value={this.state.PersonalInformation.medical_schools_comment}
                                                                                            onChange={(e) => {
                                                                                                let { PersonalInformation } = this.state;
                                                                                                PersonalInformation.medical_schools_comment = e.target.value;
                                                                                                this.setState({ PersonalInformation });
                                                                                            }}
                                                                                        />
                                                                                    </div>

                                                                                    <div class="col-md-1">
                                                                                        <label for="exampleFormControlTextarea1">
                                                                                            Check</label>
                                                                                        <input type='checkbox'
                                                                                            class="form-control-addsource-Personal-ckeckbox"
                                                                                            // id="input3"
                                                                                            name="medical_schools_status"
                                                                                            checked={this.state.PersonalInformation.medical_schools_status}
                                                                                            onChange={(e) => {
                                                                                                let { PersonalInformation } = this.state;
                                                                                                PersonalInformation.medical_schools_status = e.target.checked;
                                                                                                this.setState({ PersonalInformation });
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>

                                                                                <div class="row">
                                                                                    <div class="col-md-6">
                                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                            License No. </label>
                                                                                        <input type="text" class="form-control disabled"
                                                                                            id="input7" placeholder="Enter License No. "
                                                                                            name="medical_registration_no"
                                                                                            value={this.state.PersonalInformation.medical_registration_no}
                                                                                        />
                                                                                        {
                                                                                            (!this.state.MedicalRegistrationNoAddSource) ? (
                                                                                                <div></div>
                                                                                            ) : (
                                                                                                <div style={{ marginTop: '8%' }}>
                                                                                                    {this.state.itemsMedicalRegisterNo.map((itemsMedicalRegisterNo) => (
                                                                                                        <div className="tag-item" key={itemsMedicalRegisterNo}>
                                                                                                            {/* {itemsMedicalRegisterNo} */}
                                                                                                            <a href={itemsMedicalRegisterNo} target="_blank">
                                                                                                                <text>{itemsMedicalRegisterNo}</text>
                                                                                                            </a>
                                                                                                        </div>
                                                                                                    ))}
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </div>

                                                                                    <div class="col-md-5">
                                                                                        <label for="exampleFormControlTextarea1">
                                                                                            Comment</label>
                                                                                        <input type="text" class="form-control-addsource-Personal"
                                                                                            id="input3" placeholder="Enter Comment"
                                                                                            name="medical_registration_no_comment"
                                                                                            value={this.state.PersonalInformation.medical_registration_no_comment}
                                                                                            onChange={(e) => {
                                                                                                let { PersonalInformation } = this.state;
                                                                                                PersonalInformation.medical_registration_no_comment = e.target.value;
                                                                                                this.setState({ PersonalInformation });
                                                                                            }}
                                                                                        />
                                                                                    </div>

                                                                                    <div class="col-md-1">
                                                                                        <label for="exampleFormControlTextarea1">
                                                                                            Check</label>
                                                                                        <input type='checkbox'
                                                                                            class="form-control-addsource-Personal-ckeckbox"
                                                                                            // id="input3"
                                                                                            name="medical_registration_no_status"
                                                                                            checked={this.state.PersonalInformation.medical_registration_no_status}
                                                                                            onChange={(e) => {
                                                                                                let { PersonalInformation } = this.state;
                                                                                                PersonalInformation.medical_registration_no_status = e.target.checked;
                                                                                                this.setState({ PersonalInformation });
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>



                                                                                {/* {
                                                                ( this.state.PersonalInformation.country_id == '1' ) ? ( */}
                                                                                <div>

                                                                                    <div class="row">
                                                                                        <div class="col-md-6">
                                                                                            <label for="exampleFormControlTextarea1">
                                                                                                NPI No. </label>
                                                                                            <input type="text" maxlength="5" class="form-control disabled"
                                                                                                id="input7" placeholder="Enter NPI No. "
                                                                                                name="NPI_no"
                                                                                                value={this.state.PersonalInformation.NPI_no}
                                                                                            />
                                                                                            {
                                                                                                (!this.state.NPINoAddSource) ? (
                                                                                                    <div></div>
                                                                                                ) : (
                                                                                                    <div style={{ marginTop: '8%' }}>
                                                                                                        {this.state.itemsNpiNo.map((itemsNpiNo) => (
                                                                                                            <div className="tag-item" key={itemsNpiNo}>
                                                                                                                {/* {itemsNpiNo} */}
                                                                                                                <a href={itemsNpiNo} target="_blank">
                                                                                                                    <text>{itemsNpiNo}</text>
                                                                                                                </a>
                                                                                                            </div>
                                                                                                        ))}
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        </div>

                                                                                        <div class="col-md-5">
                                                                                            <label for="exampleFormControlTextarea1">
                                                                                                Comment</label>
                                                                                            <input type="text" class="form-control-addsource-Personal"
                                                                                                id="input3" placeholder="Enter Comment"
                                                                                                name="NPI_no_comment"
                                                                                                value={this.state.PersonalInformation.NPI_no_comment}
                                                                                                onChange={(e) => {
                                                                                                    let { PersonalInformation } = this.state;
                                                                                                    PersonalInformation.NPI_no_comment = e.target.value;
                                                                                                    this.setState({ PersonalInformation });
                                                                                                }}
                                                                                            />
                                                                                        </div>

                                                                                        <div class="col-md-1">
                                                                                            <label for="exampleFormControlTextarea1">
                                                                                                Check</label>
                                                                                            <input type='checkbox'
                                                                                                class="form-control-addsource-Personal-ckeckbox"
                                                                                                // id="input3"
                                                                                                name="NPI_no_status"
                                                                                                checked={this.state.PersonalInformation.NPI_no_status}
                                                                                                onChange={(e) => {
                                                                                                    let { PersonalInformation } = this.state;
                                                                                                    PersonalInformation.NPI_no_status = e.target.checked;
                                                                                                    this.setState({ PersonalInformation });
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <hr />
                                                                                </div>
                                                                                {/* //     ): (
                                                            //         <div></div>
                                                            //     )
                                                            // } */}

                                                                            </div>
                                                                        ) :
                                                                            (
                                                                                <div></div>
                                                                            )

                                                                    ) :
                                                                        (
                                                                            <div>

                                                                                <div class="row">
                                                                                    <div class="col-md-6">
                                                                                        <label for="exampleFormControlTextarea1">
                                                                                            {/* Medical Schools  */}
                                                                                            Alma Mater
                                                                                            <span class="mandatory">*</span></label>
                                                                                        <input type="text" class="form-control disabled"
                                                                                            id="input7" placeholder="Enter Alma Mater"
                                                                                            name="medical_schools"
                                                                                            value={this.state.PersonalInformation.medical_schools}
                                                                                        />
                                                                                        <div className="loginErrCreateProject">{this.state.medicalschoolErr}</div>
                                                                                        {
                                                                                            (!this.state.MedicalSchoolsAddSource) ? (
                                                                                                <div></div>
                                                                                            ) : (
                                                                                                <div style={{ marginTop: '8%' }}>
                                                                                                    {this.state.itemsMedicalSchool.map((itemsMedicalSchool) => (
                                                                                                        <div className="tag-item" key={itemsMedicalSchool}>
                                                                                                            {/* {itemsMedicalSchool} */}
                                                                                                            <a href={itemsMedicalSchool} target="_blank">
                                                                                                                <text>{itemsMedicalSchool}</text>
                                                                                                            </a>
                                                                                                        </div>
                                                                                                    ))}
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </div>

                                                                                    <div class="col-md-5">
                                                                                        <label for="exampleFormControlTextarea1">
                                                                                            Comment</label>
                                                                                        <input type="text" class="form-control-addsource-Personal"
                                                                                            id="input3" placeholder="Enter Comment"
                                                                                            name="medical_schools_comment"
                                                                                            value={this.state.PersonalInformation.medical_schools_comment}
                                                                                            onChange={(e) => {
                                                                                                let { PersonalInformation } = this.state;
                                                                                                PersonalInformation.medical_schools_comment = e.target.value;
                                                                                                this.setState({ PersonalInformation });
                                                                                            }}
                                                                                        />
                                                                                    </div>

                                                                                    <div class="col-md-1">
                                                                                        <label for="exampleFormControlTextarea1">
                                                                                            Check</label>
                                                                                        <input type='checkbox'
                                                                                            class="form-control-addsource-Personal-ckeckbox"
                                                                                            // id="input3"
                                                                                            name="medical_schools_status"
                                                                                            checked={this.state.PersonalInformation.medical_schools_status}
                                                                                            onChange={(e) => {
                                                                                                let { PersonalInformation } = this.state;
                                                                                                PersonalInformation.medical_schools_status = e.target.checked;
                                                                                                this.setState({ PersonalInformation });
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <hr />

                                                                                <div class="row">
                                                                                    <div class="col-md-6">
                                                                                        <label for="exampleFormControlTextarea1">
                                                                                            License No. </label>
                                                                                        <input type="text" class="form-control disabled"
                                                                                            id="input7" placeholder="Enter License No. "
                                                                                            name="medical_registration_no"
                                                                                            value={this.state.PersonalInformation.medical_registration_no}
                                                                                        />
                                                                                        {
                                                                                            (!this.state.MedicalRegistrationNoAddSource) ? (
                                                                                                <div></div>
                                                                                            ) : (
                                                                                                <div style={{ marginTop: '8%' }}>
                                                                                                    {this.state.itemsMedicalRegisterNo.map((itemsMedicalRegisterNo) => (
                                                                                                        <div className="tag-item" key={itemsMedicalRegisterNo}>
                                                                                                            {/* {itemsMedicalRegisterNo} */}
                                                                                                            <a href={itemsMedicalRegisterNo} target="_blank">
                                                                                                                <text>{itemsMedicalRegisterNo}</text>
                                                                                                            </a>
                                                                                                        </div>
                                                                                                    ))}
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </div>

                                                                                    <div class="col-md-5">
                                                                                        <label for="exampleFormControlTextarea1">
                                                                                            Comment</label>
                                                                                        <input type="text" class="form-control-addsource-Personal"
                                                                                            id="input3" placeholder="Enter Comment"
                                                                                            name="medical_registration_no_comment"
                                                                                            value={this.state.PersonalInformation.medical_registration_no_comment}
                                                                                            onChange={(e) => {
                                                                                                let { PersonalInformation } = this.state;
                                                                                                PersonalInformation.medical_registration_no_comment = e.target.value;
                                                                                                this.setState({ PersonalInformation });
                                                                                            }}
                                                                                        />
                                                                                    </div>

                                                                                    <div class="col-md-1">
                                                                                        <label for="exampleFormControlTextarea1">
                                                                                            Check</label>
                                                                                        <input type='checkbox'
                                                                                            class="form-control-addsource-Personal-ckeckbox"
                                                                                            // id="input3"
                                                                                            name="medical_registration_no_status"
                                                                                            checked={this.state.PersonalInformation.medical_registration_no_status}
                                                                                            onChange={(e) => {
                                                                                                let { PersonalInformation } = this.state;
                                                                                                PersonalInformation.medical_registration_no_status = e.target.checked;
                                                                                                this.setState({ PersonalInformation });
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <hr />


                                                                                {/* {
                                                            ( this.state.PersonalInformation.country_id == '1' ) ? ( */}
                                                                                <div>

                                                                                    <div class="row">
                                                                                        <div class="col-md-6">
                                                                                            <label for="exampleFormControlTextarea1" >
                                                                                                NPI No. </label>
                                                                                            <input type="text" maxlength="5" class="form-control disabled"
                                                                                                id="input7" placeholder="Enter NPI No. "
                                                                                                name="NPI_no"
                                                                                                value={this.state.PersonalInformation.NPI_no}
                                                                                            />
                                                                                            {
                                                                                                (!this.state.NPINoAddSource) ? (
                                                                                                    <div></div>
                                                                                                ) : (
                                                                                                    <div style={{ marginTop: '8%' }}>
                                                                                                        {this.state.itemsNpiNo.map((itemsNpiNo) => (
                                                                                                            <div className="tag-item" key={itemsNpiNo}>
                                                                                                                {/* {itemsNpiNo} */}
                                                                                                                <a href={itemsNpiNo} target="_blank">
                                                                                                                    <text>{itemsNpiNo}</text>
                                                                                                                </a>
                                                                                                            </div>
                                                                                                        ))}
                                                                                                    </div>
                                                                                                )
                                                                                            }
                                                                                        </div>

                                                                                        <div class="col-md-5">
                                                                                            <label for="exampleFormControlTextarea1">
                                                                                                Comment</label>
                                                                                            <input type="text" class="form-control-addsource-Personal"
                                                                                                id="input3" placeholder="Enter Comment"
                                                                                                name="NPI_no_comment"
                                                                                                value={this.state.PersonalInformation.NPI_no_comment}
                                                                                                onChange={(e) => {
                                                                                                    let { PersonalInformation } = this.state;
                                                                                                    PersonalInformation.NPI_no_comment = e.target.value;
                                                                                                    this.setState({ PersonalInformation });
                                                                                                }}
                                                                                            />
                                                                                        </div>

                                                                                        <div class="col-md-1">
                                                                                            <label for="exampleFormControlTextarea1">
                                                                                                Check</label>
                                                                                            <input type='checkbox'
                                                                                                class="form-control-addsource-Personal-ckeckbox"
                                                                                                // id="input3"
                                                                                                name="NPI_no_status"
                                                                                                checked={this.state.PersonalInformation.NPI_no_status}
                                                                                                onChange={(e) => {
                                                                                                    let { PersonalInformation } = this.state;
                                                                                                    PersonalInformation.NPI_no_status = e.target.checked;
                                                                                                    this.setState({ PersonalInformation });
                                                                                                }}
                                                                                            />
                                                                                        </div>


                                                                                    </div>
                                                                                    <hr />


                                                                                </div>
                                                                                {/* //     ): (
                                                        //         <div></div>
                                                        //     )
                                                        // } */}


                                                                            </div>
                                                                        )
                                                                }

                                                                {/* <label for="exampleFormControlTextarea1" style={{marginTop:'4%'}}>
                                               Brand </label>
                                            <input type="text" class="form-control" 
                                                id="input7" placeholder="Enter Brand " 
                                                name="brand"
                                                value={this.state.PersonalInformation.brand} 
                                                onChange={(e) => {
                                                    let { PersonalInformation } = this.state;
                                                    PersonalInformation.brand = e.target.value;
                                                    this.setState({ PersonalInformation });
                                                }}
                                            />
                                            <a class="float-right" style={{marginTop:'-3%', color:'#007bff'}}
                                                onClick={this.BrandAddSourceFun}>+ Add source</a>                                            
                                            {
                                                ( !this.state.BrandAddSource ) ? (
                                                    <div></div>
                                                ) : (
                                                    <div style={{marginTop: '8%'}}>
                                                        {this.state.itemsBrand.map((itemsBrand) => (
                                                        <div className="tag-item" key={itemsBrand}>
                                                            {itemsBrand}
                                                            <button
                                                            type="button"
                                                            className="button"
                                                            onClick={() => this.handleDeleteBrand(itemsBrand)}
                                                            >
                                                            &times;
                                                            </button>
                                                        </div>
                                                        ))}
                                                        <input
                                                            class="form-control-addsource-Personal" 
                                                            value={this.state.valuePersonalInfoAddSource}
                                                            placeholder="Type or paste source link and press `Enter`"
                                                            onKeyDown={this.handleKeyDownBrand}
                                                            onChange={this.handleChangePersonalInfoAddSource}
                                                        />
                                                    </div>
                                                )
                                            } */}

                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Speciality <span class="mandatory">*</span></label>
                                                                        <Input type="select" id="roleSelect" className="form-control disabled"
                                                                            style={{ height: '50px' }}
                                                                            name="specialty_id"
                                                                            value={this.state.PersonalInformation.specialty_id}
                                                                        // onChange={async(e) => {
                                                                        //     let { PersonalInformation } = this.state;
                                                                        //     PersonalInformation.specialty_id = e.target.value;
                                                                        //     PersonalInformation.procedure_type_id = '';
                                                                        //     PersonalInformation.sub_specialty_id = '';
                                                                        //     await this.setState({ PersonalInformation });
                                                                        //     await this.setState({
                                                                        //         ProcedureType: [],
                                                                        //         SubSpecialityType: [],
                                                                        //         ProcedureTypeDefaultValues: [],
                                                                        //         SubSpecialityTypeDefaultValues: [],
                                                                        //         showProcedureSubSpecialityDropdown: false,
                                                                        //     })
                                                                        //     {this.getProcedureList()} 
                                                                        //     {this.getSubSpecialityList()}


                                                                        //     console.log('ProcedureType',this.state.ProcedureType)
                                                                        //     console.log('PersonalInformation.procedure_type_id',this.state.PersonalInformation)
                                                                        //     {this.ProcedureDropdown()}
                                                                        // }}                                                    
                                                                        >
                                                                            <option value="" >--Select Speciality--</option>
                                                                            {OptionsSpeciality}
                                                                        </Input>
                                                                        <div style={{
                                                                            color: "red", fontSize: "12px", marginTop: "1%",
                                                                            marginBottom: "5px",
                                                                        }}>
                                                                            {this.state.specialityErr}</div>
                                                                        {
                                                                            (!this.state.SpecialityAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div style={{ marginTop: '8%' }}>
                                                                                    {this.state.itemSpeciality.map((itemSpeciality) => (
                                                                                        <div className="tag-item" key={itemSpeciality}>
                                                                                            {/* {itemSpeciality} */}
                                                                                            <a href={itemSpeciality} target="_blank">
                                                                                                <text>{itemSpeciality}</text>
                                                                                            </a>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>

                                                                    <div class="col-md-5">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Comment</label>
                                                                        <input type="text" class="form-control-addsource-Personal"
                                                                            id="input3" placeholder="Enter Comment"
                                                                            name="specialty_comment"
                                                                            value={this.state.PersonalInformation.specialty_comment}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.specialty_comment = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div class="col-md-1">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Check</label>
                                                                        <input type='checkbox'
                                                                            class="form-control-addsource-Personal-ckeckbox"
                                                                            // id="input3"
                                                                            name="specialty_status"
                                                                            checked={this.state.PersonalInformation.specialty_status}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.specialty_status = e.target.checked;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <hr />




                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <label for="exampleFormControlTextarea1">
                                                                        Secondary Speciality </label>
                                                                        <Input type="select" id="roleSelect" className="form-control disabled"
                                                                            style={{ height: '50px' }}
                                                                            name="specialty2_id"
                                                                            value={this.state.PersonalInformation.specialty2_id}
                                                                                                                            
                                                                        >
                                                                            <option value="" >--Select  Secondary Speciality--</option>
                                                                            {OptionsSpeciality}
                                                                        </Input>
                                                                        <div style={{
                                                                            color: "red", fontSize: "12px", marginTop: "1%",
                                                                            marginBottom: "5px",
                                                                        }}>
                                                                            {this.state.specialityErr}</div>
                                                                        {
                                                                            (!this.state.Speciality2AddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div style={{ marginTop: '8%' }}>
                                                                                    {this.state.itemSpeciality2.map((itemSpeciality2) => (
                                                                                        <div className="tag-item" key={itemSpeciality2}>
                                                               
                                                                                            <a href={itemSpeciality2} target="_blank">
                                                                                                <text>{itemSpeciality2}</text>
                                                                                            </a>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>

                                                                    <div class="col-md-5">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Comment</label>
                                                                        <input type="text" class="form-control-addsource-Personal"
                                                                            id="input3" placeholder="Enter Comment"
                                                                            name="specialty2_comment"
                                                                            value={this.state.PersonalInformation.specialty2_comment}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.specialty2_comment = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div class="col-md-1">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Check</label>
                                                                        <input type='checkbox'
                                                                            class="form-control-addsource-Personal-ckeckbox"
                                                                            // id="input3"
                                                                            name="specialty2_status"
                                                                            checked={this.state.PersonalInformation.specialty2_status}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.specialty2_status = e.target.checked;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <hr />
















                                                                {/* Sub Speciality Dropdown starts here */}
                                                                {
                                                                    // ( !this.state.PersonalInformation.specialty_id ) ? (
                                                                    (this.state.showProcedureSubSpecialityDropdown == false) ? (
                                                                        <div></div>
                                                                    ) : (
                                                                        <div>
                                                                            <div class="row">
                                                                                <div class="col-md-6">
                                                                                    <label for="exampleFormControlTextarea1" >
                                                                                        Sub Speciality Type <span class="mandatory">*</span></label>
                                                                                    <Multiselect
                                                                                        disabled
                                                                                        data={this.state.SubSpecialitydata}
                                                                                        placeholder="--Select Sub Speciality--"
                                                                                        valueField='id'
                                                                                        textField='sub_speciality_type'
                                                                                        defaultValue={this.state.SubSpecialityType}

                                                                                    />
                                                                                    <div style={{
                                                                                        color: "red", fontSize: "12px", marginTop: "1%",
                                                                                        marginBottom: "5px",
                                                                                    }}>{this.state.subspecialitytypeErr}</div>
                                                                                    {
                                                                                        (!this.state.SubSpecialityTypeAddSource) ? (
                                                                                            <div></div>
                                                                                        ) : (
                                                                                            <div style={{ marginTop: '8%' }}>
                                                                                                {this.state.itemsSubSpeciality.map((itemsSubSpeciality) => (
                                                                                                    <div className="tag-item" key={itemsSubSpeciality}>
                                                                                                        {/* {itemsSubSpeciality} */}
                                                                                                        <a href={itemsSubSpeciality} target="_blank">
                                                                                                            <text>{itemsSubSpeciality}</text>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                ))}
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                </div>

                                                                                <div class="col-md-5">
                                                                                    <label for="exampleFormControlTextarea1">
                                                                                        Comment</label>
                                                                                    <input type="text" class="form-control-addsource-Personal"
                                                                                        id="input3" placeholder="Enter Comment"
                                                                                        name="sub_specialty_comment"
                                                                                        value={this.state.PersonalInformation.sub_specialty_comment}
                                                                                        onChange={(e) => {
                                                                                            let { PersonalInformation } = this.state;
                                                                                            PersonalInformation.sub_specialty_comment = e.target.value;
                                                                                            this.setState({ PersonalInformation });
                                                                                        }}
                                                                                    />
                                                                                </div>

                                                                                <div class="col-md-1">
                                                                                    <label for="exampleFormControlTextarea1">
                                                                                        Check</label>
                                                                                    <input type='checkbox'
                                                                                        class="form-control-addsource-Personal-ckeckbox"
                                                                                        // id="input3"
                                                                                        name="sub_specialty_status"
                                                                                        checked={this.state.PersonalInformation.sub_specialty_status}
                                                                                        onChange={(e) => {
                                                                                            let { PersonalInformation } = this.state;
                                                                                            PersonalInformation.sub_specialty_status = e.target.checked;
                                                                                            this.setState({ PersonalInformation });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <hr />

                                                                        </div>
                                                                    )
                                                                }

                                                                {/* Procedure Dropdown starts here */}
                                                                {
                                                                    // ( !this.state.PersonalInformation.specialty_id ) ? (
                                                                    (this.state.showProcedureSubSpecialityDropdown == false) ? (
                                                                        <div></div>
                                                                    ) : (
                                                                        <div>
                                                                            <div class="row">
                                                                                <div class="col-md-6">
                                                                                    <label for="exampleFormControlTextarea1" >
                                                                                        Procedure Type <span class="mandatory">*</span></label>
                                                                                    <Multiselect
                                                                                        disabled
                                                                                        data={this.state.ProcedureData}
                                                                                        placeholder="--Select Procedure Type--"
                                                                                        valueField='id'
                                                                                        textField='procedure_type'
                                                                                        defaultValue={this.state.ProcedureType}

                                                                                    />
                                                                                    <div style={{
                                                                                        color: "red", fontSize: "12px", marginTop: "1%",
                                                                                        marginBottom: "5px",
                                                                                    }}>{this.state.proceduretypeErr}</div>
                                                                                    {
                                                                                        (!this.state.ProcedureTypeAddSource) ? (
                                                                                            <div></div>
                                                                                        ) : (
                                                                                            <div style={{ marginTop: '8%' }}>
                                                                                                {this.state.itemsProcedure.map((itemsProcedure) => (
                                                                                                    <div className="tag-item" key={itemsProcedure}>
                                                                                                        {/* {itemsProcedure} */}
                                                                                                        <a href={itemsProcedure} target="_blank">
                                                                                                            <text>{itemsProcedure}</text>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                ))}
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                </div>

                                                                                <div class="col-md-5">
                                                                                    <label for="exampleFormControlTextarea1">
                                                                                        Comment</label>
                                                                                    <input type="text" class="form-control-addsource-Personal"
                                                                                        id="input3" placeholder="Enter Comment"
                                                                                        name="procedure_type_comment"
                                                                                        value={this.state.PersonalInformation.procedure_type_comment}
                                                                                        onChange={(e) => {
                                                                                            let { PersonalInformation } = this.state;
                                                                                            PersonalInformation.procedure_type_comment = e.target.value;
                                                                                            this.setState({ PersonalInformation });
                                                                                        }}
                                                                                    />
                                                                                </div>

                                                                                <div class="col-md-1">
                                                                                    <label for="exampleFormControlTextarea1">
                                                                                        Check</label>
                                                                                    <input type='checkbox'
                                                                                        class="form-control-addsource-Personal-ckeckbox"
                                                                                        // id="input3"
                                                                                        name="procedure_type_status"
                                                                                        checked={this.state.PersonalInformation.procedure_type_status}
                                                                                        onChange={(e) => {
                                                                                            let { PersonalInformation } = this.state;
                                                                                            PersonalInformation.procedure_type_status = e.target.checked;
                                                                                            this.setState({ PersonalInformation });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <hr />
                                                                        </div>
                                                                    )
                                                                }



<div class="row">
                                                                    <div class="col-md-6">
                                                                        <label for="exampleFormControlTextarea1">
                                                                        expertise <span class="mandatory">*</span></label>
                                                                        <text class="form-control disabled" >
                                                                            {this.state.PersonalInformation.expertise}
                                                                        </text>
                                                                        
                                                                        <div style={{
                                                                            color: "red", fontSize: "12px", marginTop: "1%",
                                                                            marginBottom: "5px",
                                                                        }}>{this.state.expertiseErr}</div>
                                                                        {
                                                                            (!this.state.expertiseAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div style={{ marginTop: '8%' }}>
                                                                                    {this.state.itemsexpertise.map((itemsexpertise) => (
                                                                                        <div className="tag-item" key={itemsexpertise}>
                                                                                            {/* {itemsDoctorContact} */}
                                                                                            <a href={itemsexpertise} target="_blank">
                                                                                                <text>{itemsexpertise}</text>
                                                                                            </a>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>

                                                                    <div class="col-md-5">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Comment</label>
                                                                        <input type="text" class="form-control-addsource-Personal"
                                                                            id="input3" placeholder="Enter Comment"
                                                                            name="expertise_comment"
                                                                            value={this.state.PersonalInformation.expertise_comments}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.expertise_comments = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div class="col-md-1">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Check</label>
                                                                        <input type='checkbox'
                                                                            class="form-control-addsource-Personal-ckeckbox"
                                                                            // id="input3"
                                                                            name="expertise_status"
                                                                            checked={this.state.PersonalInformation.expertise_status}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.expertise_status = e.target.checked;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <hr />




                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Contact No. <span class="mandatory">*</span></label>
                                                                        <text class="form-control disabled" >
                                                                            {this.state.PersonalInformation.doctor_contact_no}
                                                                        </text>
                                                                        {/* <PhoneInput
                                                        inputStyle={{
                                                            width: "100%",
                                                            height: "50px",
                                                            fontSize: "15px",
                                                            cursor: "notAllowed",
                                                            backgroundColor: "#efefef !important",
                                                        }}
                                                        containerStyle={{cursor: "notAllowed", background: "#efefef !important",}}
                                                        // disabled
                                                        width= "50%"
                                                        country={'in'}
                                                        value={this.state.PersonalInformation.doctor_contact_no}
                                                    /> */}
                                                                        <div style={{
                                                                            color: "red", fontSize: "12px", marginTop: "1%",
                                                                            marginBottom: "5px",
                                                                        }}>{this.state.doctorcontactnoErr}</div>
                                                                        {
                                                                            (!this.state.DoctorContactNoAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div style={{ marginTop: '8%' }}>
                                                                                    {this.state.itemsDoctorContact.map((itemsDoctorContact) => (
                                                                                        <div className="tag-item" key={itemsDoctorContact}>
                                                                                            {/* {itemsDoctorContact} */}
                                                                                            <a href={itemsDoctorContact} target="_blank">
                                                                                                <text>{itemsDoctorContact}</text>
                                                                                            </a>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>

                                                                    <div class="col-md-5">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Comment</label>
                                                                        <input type="text" class="form-control-addsource-Personal"
                                                                            id="input3" placeholder="Enter Comment"
                                                                            name="doctor_contact_comment"
                                                                            value={this.state.PersonalInformation.doctor_contact_comment}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.doctor_contact_comment = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div class="col-md-1">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Check</label>
                                                                        <input type='checkbox'
                                                                            class="form-control-addsource-Personal-ckeckbox"
                                                                            // id="input3"
                                                                            name="doctor_contact_status"
                                                                            checked={this.state.PersonalInformation.doctor_contact_status}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.doctor_contact_status = e.target.checked;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <hr />

                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Email ID <span class="mandatory">*</span></label>
                                                                        <input type="text" class="form-control disabled"
                                                                            id="input7" placeholder="Enter Email "
                                                                            name="doctor_email"
                                                                            value={this.state.PersonalInformation.doctor_email}
                                                                        />
                                                                        <div className="loginErrCreateProject">{this.state.doctoremailErr}</div>
                                                                        {
                                                                            (!this.state.DoctorEmailAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div style={{ marginTop: '8%' }}>
                                                                                    {this.state.itemsDoctorEmail.map((itemsDoctorEmail) => (
                                                                                        <div className="tag-item" key={itemsDoctorEmail}>
                                                                                            {/* {itemsDoctorEmail} */}
                                                                                            <a href={itemsDoctorEmail} target="_blank">
                                                                                                <text>{itemsDoctorEmail}</text>
                                                                                            </a>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>

                                                                    <div class="col-md-5">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Comment</label>
                                                                        <input type="text" class="form-control-addsource-Personal"
                                                                            id="input3" placeholder="Enter Comment"
                                                                            name="doctor_email_comment"
                                                                            value={this.state.PersonalInformation.doctor_email_comment}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.doctor_email_comment = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div class="col-md-1">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Check</label>
                                                                        <input type='checkbox'
                                                                            class="form-control-addsource-Personal-ckeckbox"
                                                                            // id="input3"
                                                                            name="doctor_email_status"
                                                                            checked={this.state.PersonalInformation.doctor_email_status}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.doctor_email_status = e.target.checked;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                             
                                                                <hr />

<div class="row">
    <div class="col-md-6">
        <label for="exampleFormControlTextarea1">
           Secondary Email ID </label>
        <input type="text" class="form-control disabled"
            id="input7" placeholder="Enter Secondary Email ID  "
            name="doctor_email2"
            value={this.state.PersonalInformation.doctor_email2}
        />
        <div className="loginErrCreateProject">{this.state.doctoremail2Err}</div>
        {
            (!this.state.DoctorEmail2AddSource) ? (
                <div></div>
            ) : (
                <div style={{ marginTop: '8%' }}>
                    {this.state.itemsDoctorEmail2.map((itemsDoctorEmail2) => (
                        <div className="tag-item" key={itemsDoctorEmail2}>
                            {/* {itemsDoctorEmail} */}
                            <a href={itemsDoctorEmail2} target="_blank">
                                <text>{itemsDoctorEmail2}</text>
                            </a>
                        </div>
                    ))}
                </div>
            )
        }
    </div>

    <div class="col-md-5">
        <label for="exampleFormControlTextarea1">
            Comment</label>
        <input type="text" class="form-control-addsource-Personal"
            id="input3" placeholder="Enter Comment"
            name="doctor_email2_comment"
            value={this.state.PersonalInformation.doctor_email2_comment}
            onChange={(e) => {
                let { PersonalInformation } = this.state;
                PersonalInformation.doctor_email2_comment = e.target.value;
                this.setState({ PersonalInformation });
            }}
        />
    </div>

    <div class="col-md-1">
        <label for="exampleFormControlTextarea1">
            Check</label>
        <input type='checkbox'
            class="form-control-addsource-Personal-ckeckbox"
            id="input3"
            name="doctor_email2_status"
            checked={this.state.PersonalInformation.doctor_email2_status}
            onChange={(e) => {
                let { PersonalInformation } = this.state;
                PersonalInformation.doctor_email2_status = e.target.checked;
                this.setState({ PersonalInformation });
            }}
        />
    </div>
</div>


                                                                <hr />


                                                               
                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Fax </label>
                                                                            <text class="form-control disabled" >
                                                                                {this.state.PersonalInformation.fax}
                                                                            </text>
                                                                        {/* <input type="text" class="form-control disabled"
                                                                            id="input7" placeholder="Enter Fax"
                                                                            value={this.state.PersonalInformation.fax}
                                                                        /> */}
                                                                        {
                                                                            (!this.state.FaxAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div style={{ marginTop: '8%' }}>
                                                                              
                                                                                    {this.state.itemsFax.map((itemsFax) => (
                                                                                        <div className="tag-item" key={itemsFax}>
                                                                                            {/* {itemsFax} */}
                                                                                            <a href={itemsFax} target="_blank">
                                                                                                <text>{itemsFax}</text>
                                                                                            </a>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>
{/* 
                                                                    <div clas="col-md-5">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Comment</label>
                                                                        <input type="text" class="form-control-addsource-Personal"
                                                                            id="input3" placeholder="Enter Comment"
                                                                            name="fax_comment"
                                                                            value={this.state.PersonalInformation.fax_comment}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.fax_comment = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div> */}

                                                                    <div class="col-md-5">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Comment</label>
                                                                        <input type="text" class="form-control-addsource-Personal"
                                                                            id="input3" placeholder="Enter Comment"
                                                                            name="fax_comment"
                                                                            value={this.state.PersonalInformation.fax_comment}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.fax_comment = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>


                                                                    <div class="col-md-1">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Check</label>
                                                                        <input type='checkbox'
                                                                            class="form-control-addsource-Personal-ckeckbox"
                                                                            // id="input3"
                                                                            name="fax_status"
                                                                            checked={this.state.PersonalInformation.fax_status}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.fax_status = e.target.checked;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <hr />

                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            LinkedIn Profile </label>
                                                                        <input type="text" class="form-control disabled"
                                                                            id="input7" placeholder="Enter LinkedIn Profile"
                                                                            name="linked_in_profile"
                                                                            value={this.state.PersonalInformation.linked_in_profile}
                                                                        />
                                                                        {
                                                                            (!this.state.LinkedInProfileAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div style={{ marginTop: '8%' }}>
                                                                                    {this.state.itemsLinkedIn.map((itemsLinkedIn) => (
                                                                                        <div className="tag-item" key={itemsLinkedIn}>
                                                                                            {/* {itemsLinkedIn} */}
                                                                                            <a href={itemsLinkedIn} target="_blank">
                                                                                                <text>{itemsLinkedIn}</text>
                                                                                            </a>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>

                                                                    <div class="col-md-5">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Comment</label>
                                                                        <input type="text" class="form-control-addsource-Personal"
                                                                            id="input3" placeholder="Enter Comment"
                                                                            name="linked_in_profile_comment"
                                                                            value={this.state.PersonalInformation.linked_in_profile_comment}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.linked_in_profile_comment = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div class="col-md-1">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Check</label>
                                                                        <input type='checkbox'
                                                                            class="form-control-addsource-Personal-ckeckbox"
                                                                            // id="input3"
                                                                            name="linked_in_profile_status"
                                                                            checked={this.state.PersonalInformation.linked_in_profile_status}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.linked_in_profile_status = e.target.checked;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <hr />

                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Twitter Handle </label>
                                                                        <input type="text" class="form-control disabled"
                                                                            id="input7" placeholder="Enter Twitter Profile "
                                                                            name="twitter_handle"
                                                                            value={this.state.PersonalInformation.twitter_handle}
                                                                        />
                                                                        {
                                                                            (!this.state.TwitterHandleAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div style={{ marginTop: '8%' }}>
                                                                                    {this.state.itemsTwitter.map((itemsTwitter) => (
                                                                                        <div className="tag-item" key={itemsTwitter}>
                                                                                            {/* {itemsTwitter} */}
                                                                                            <a href={itemsTwitter} target="_blank">
                                                                                                <text>{itemsTwitter}</text>
                                                                                            </a>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>

                                                                    <div class="col-md-5">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Comment</label>
                                                                        <input type="text" class="form-control-addsource-Personal"
                                                                            id="input3" placeholder="Enter Comment"
                                                                            name="twitter_handle_comment"
                                                                            value={this.state.PersonalInformation.twitter_handle_comment}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.twitter_handle_comment = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div class="col-md-1">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Check</label>
                                                                        <input type='checkbox'
                                                                            class="form-control-addsource-Personal-ckeckbox"
                                                                            // id="input3"
                                                                            name="twitter_handle_status"
                                                                            checked={this.state.PersonalInformation.twitter_handle_status}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.twitter_handle_status = e.target.checked;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <hr />

                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Assistant/Secretary Contact No. </label>
                                                                        <text class="form-control disabled" >
                                                                            {this.state.PersonalInformation.assistance_contact_no}
                                                                        </text>
                                                                        {/* <PhoneInput
                                                        inputStyle={{
                                                            width: "100%",
                                                            height: "50px",
                                                            fontSize: "15px",

                                                        }}
                                                        className='disabled'
                                                        width= "50%"
                                                        country={'in'}
                                                        value={this.state.PersonalInformation.assistance_contact_no}
                                                        // onChange={(AssistanceContactNo) => {
                                                        //     let { PersonalInformation } = this.state;
                                                        //     PersonalInformation.assistance_contact_no = AssistanceContactNo;
                                                        //     this.setState({ PersonalInformation });
                                                        // }}
                                                    />  */}
                                                                        {
                                                                            (!this.state.AssistanceContactNoAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div style={{ marginTop: '8%' }}>
                                                                                    {this.state.itemsAssistanceContact.map((itemsAssistanceContact) => (
                                                                                        <div className="tag-item" key={itemsAssistanceContact}>
                                                                                            {/* {itemsAssistanceContact} */}
                                                                                            <a href={itemsAssistanceContact} target="_blank">
                                                                                                <text>{itemsAssistanceContact}</text>
                                                                                            </a>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>

                                                                    <div class="col-md-5">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Comment</label>
                                                                        <input type="text" class="form-control-addsource-Personal"
                                                                            id="input3" placeholder="Enter Comment"
                                                                            name="assistance_contact_no_comment"
                                                                            value={this.state.PersonalInformation.assistance_contact_no_comment}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.assistance_contact_no_comment = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div class="col-md-1">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Check</label>
                                                                        <input type='checkbox'
                                                                            class="form-control-addsource-Personal-ckeckbox"
                                                                            // id="input3"
                                                                            name="assistance_contact_no_status"
                                                                            checked={this.state.PersonalInformation.assistance_contact_no_status}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.assistance_contact_no_status = e.target.checked;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <hr />

                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Assistant Email ID </label>
                                                                        <input type="text" class="form-control disabled"
                                                                            id="input7" placeholder="Enter Email "
                                                                            name="assistance_email"
                                                                            value={this.state.PersonalInformation.assistance_email}
                                                                        />
                                                                        {
                                                                            (!this.state.AssistanceEmailAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div style={{ marginTop: '8%' }}>
                                                                                    {this.state.itemsAssistanceEmail.map((itemsAssistanceEmail) => (
                                                                                        <div className="tag-item" key={itemsAssistanceEmail}>
                                                                                            {/* {itemsAssistanceEmail} */}
                                                                                            <a href={itemsAssistanceEmail} target="_blank">
                                                                                                <text>{itemsAssistanceEmail}</text>
                                                                                            </a>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>

                                                                    <div class="col-md-5">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Comment</label>
                                                                        <input type="text" class="form-control-addsource-Personal"
                                                                            id="input3" placeholder="Enter Comment"
                                                                            name="assistance_email_comment"
                                                                            value={this.state.PersonalInformation.assistance_email_comment}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.assistance_email_comment = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div class="col-md-1">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Check</label>
                                                                        <input type='checkbox'
                                                                            class="form-control-addsource-Personal-ckeckbox"
                                                                            // id="input3"
                                                                            name="assistance_email_status"
                                                                            checked={this.state.PersonalInformation.assistance_email_status}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.assistance_email_status = e.target.checked;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <hr />

                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Website </label>
                                                                        <input type="text" class="form-control disabled"
                                                                            id="input7" placeholder="Enter Website "
                                                                            name="website"
                                                                            value={this.state.PersonalInformation.website}
                                                                        />
                                                                        {
                                                                            (!this.state.WebsiteAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div style={{ marginTop: '8%' }}>
                                                                                    {this.state.itemsWebsite.map((itemsWebsite) => (
                                                                                        <div className="tag-item" key={itemsWebsite}>
                                                                                            {/* {itemsWebsite} */}
                                                                                            <a href={itemsWebsite} target="_blank">
                                                                                                <text>{itemsWebsite}</text>
                                                                                            </a>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>

                                                                    <div class="col-md-5">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Comment</label>
                                                                        <input type="text" class="form-control-addsource-Personal"
                                                                            id="input3" placeholder="Enter Comment"
                                                                            name="website_comment"
                                                                            value={this.state.PersonalInformation.website_comment}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.website_comment = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div class="col-md-1">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Check</label>
                                                                        <input type='checkbox'
                                                                            class="form-control-addsource-Personal-ckeckbox"
                                                                            // id="input3"
                                                                            name="website_status"
                                                                            checked={this.state.PersonalInformation.website_status}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.website_status = e.target.checked;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <hr />

                                                                {/* <div class="row">
                                                <div class="col-md-6">
                                                    <label for="exampleFormControlTextarea1">
                                                    Contract </label>
                                                    <input type="text" class="form-control disabled" 
                                                        id="input7" placeholder="Enter Contract " 
                                                        name="contract"
                                                        value={this.state.PersonalInformation.contract} 
                                                    />                                           
                                                    {
                                                        ( this.state.PersonalInformation.contract_file == '' ) ? (
                                                            <div></div>
                                                        ) : (
                                                            <div style={{marginTop: '8%'}}>
                                                               <a href={this.state.PersonalInformation.contract_file} target = "_blank">
                                                                        <text>{this.state.PersonalInformation.contract_file}</text>
                                                                    </a>
                                                            </div>
                                                        )
                                                    }
                                                </div>

                                                <div class="col-md-5">
                                                    <label for="exampleFormControlTextarea1">
                                                        Comment</label>
                                                    <input type="text" class="form-control-addsource-Personal"                                                          
                                                        id="input3" placeholder="Enter Comment" 
                                                        name="contract_comment" 
                                                        value={this.state.PersonalInformation.contract_comment} 
                                                        onChange={(e) => {
                                                            let { PersonalInformation } = this.state;
                                                            PersonalInformation.contract_comment = e.target.value;
                                                            this.setState({ PersonalInformation });
                                                        }}
                                                    />
                                                </div>

                                                <div class="col-md-1">
                                                    <label for="exampleFormControlTextarea1">
                                                        Check</label>
                                                    <input type='checkbox'
                                                        class="form-control-addsource-Personal-ckeckbox" 
                                                        name="contract_status" 
                                                        checked={this.state.PersonalInformation.contract_status}
                                                        onChange={(e) => {
                                                            let { PersonalInformation } = this.state;
                                                            PersonalInformation.contract_status = e.target.checked;
                                                            this.setState({ PersonalInformation });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <hr/> */}

                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Country <span class="mandatory">*</span></label>
                                                                        <Input type="select" id="roleSelect" className="form-control disabled"
                                                                            style={{ height: '50px' }}
                                                                            name="country_id"
                                                                            value={this.state.PersonalInformation.country_id}
                                                                        // onChange={ async(e) => {
                                                                        //     let { PersonalInformation } = this.state;
                                                                        //     PersonalInformation.country_id = e.target.value;
                                                                        //     PersonalInformation.NPI_no = '';
                                                                        //     await this.setState({ PersonalInformation });
                                                                        //     {this.getStateList()}
                                                                        //     {this.getCityList()}
                                                                        // }}
                                                                        >
                                                                            <option value="" >--Select Country--</option>
                                                                            {OptionsCountry}
                                                                        </Input>
                                                                        <div style={{
                                                                            color: "red", fontSize: "12px", marginTop: "1%",
                                                                            marginBottom: "5px",
                                                                        }}>{this.state.countryErr}</div>
                                                                        {
                                                                            (!this.state.CountryAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div style={{ marginTop: '8%' }}>
                                                                                    {this.state.itemsCountry.map((itemsCountry) => (
                                                                                        <div className="tag-item" key={itemsCountry}>
                                                                                            {/* {itemsCountry} */}
                                                                                            <a href={itemsCountry} target="_blank">
                                                                                                <text>{itemsCountry}</text>
                                                                                            </a>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>

                                                                    <div class="col-md-5">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Comment</label>
                                                                        <input type="text" class="form-control-addsource-Personal"
                                                                            id="input3" placeholder="Enter Comment"
                                                                            name="country_comment"
                                                                            value={this.state.PersonalInformation.country_comment}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.country_comment = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div class="col-md-1">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Check</label>
                                                                        <input type='checkbox'
                                                                            class="form-control-addsource-Personal-ckeckbox"
                                                                            // id="input3"
                                                                            name="country_status"
                                                                            checked={this.state.PersonalInformation.country_status}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.country_status = e.target.checked;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <hr />

                                                                {/* State Dropdown starts here */}
                                                                {
                                                                    (!this.state.PersonalInformation.country_id) ? (
                                                                        <div></div>
                                                                    ) : (
                                                                        <div>

                                                                            <div class="row">
                                                                                <div class="col-md-6">
                                                                                    <label for="exampleFormControlTextarea1" >
                                                                                        State <span class="mandatory">*</span></label>
                                                                                    <Input type="select" id="roleSelect" className="form-control disabled"
                                                                                        style={{ height: '50px' }}
                                                                                        value={this.state.PersonalInformation.state_id}
                                                                                    // onChange={async(e) => {
                                                                                    //     let { PersonalInformation } = this.state;
                                                                                    //     PersonalInformation.state_id = e.target.value;
                                                                                    //     await this.setState({ PersonalInformation });
                                                                                    // }}
                                                                                    >
                                                                                        <option value="" >--Select State--</option>
                                                                                        {OptionsState}
                                                                                    </Input>
                                                                                    <div style={{
                                                                                        color: "red", fontSize: "12px", marginTop: "1%",
                                                                                        marginBottom: "5px",
                                                                                    }}>{this.state.stateErr}</div>
                                                                                    {
                                                                                        (!this.state.StateAddSource) ? (
                                                                                            <div></div>
                                                                                        ) : (
                                                                                            <div style={{ marginTop: '8%' }}>
                                                                                                {this.state.itemsState.map((itemsState) => (
                                                                                                    <div className="tag-item" key={itemsState}>
                                                                                                        {/* {itemsState} */}
                                                                                                        <a href={itemsState} target="_blank">
                                                                                                            <text>{itemsState}</text>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                ))}
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                </div>

                                                                                <div class="col-md-5">
                                                                                    <label for="exampleFormControlTextarea1">
                                                                                        Comment</label>
                                                                                    <input type="text" class="form-control-addsource-Personal"
                                                                                        id="input3" placeholder="Enter Comment"
                                                                                        name="state_comment"
                                                                                        value={this.state.PersonalInformation.state_comment}
                                                                                        onChange={(e) => {
                                                                                            let { PersonalInformation } = this.state;
                                                                                            PersonalInformation.state_comment = e.target.value;
                                                                                            this.setState({ PersonalInformation });
                                                                                        }}
                                                                                    />
                                                                                </div>

                                                                                <div class="col-md-1">
                                                                                    <label for="exampleFormControlTextarea1">
                                                                                        Check</label>
                                                                                    <input type='checkbox'
                                                                                        class="form-control-addsource-Personal-ckeckbox"
                                                                                        // id="input3"
                                                                                        name="state_status"
                                                                                        checked={this.state.PersonalInformation.state_status}
                                                                                        onChange={(e) => {
                                                                                            let { PersonalInformation } = this.state;
                                                                                            PersonalInformation.state_status = e.target.checked;
                                                                                            this.setState({ PersonalInformation });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <hr />

                                                                        </div>
                                                                    )
                                                                }

<div class="row">
                                                                    <div class="col-md-6">
                                                                        <label for="exampleFormControlTextarea1">
                                                                           City </label>
                                                                        <input type="text" class="form-control disabled"
                                                                            id="input7" placeholder="Enter City"
                                                                            name="city"
                                                                            value={this.state.PersonalInformation.city}
                                                                        />
                                                                        
                                                                        {
                                                                            (!this.state.CityPEAddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div style={{ marginTop: '8%' }}>
                                                                                    {this.state.itemsCity.map((itemsCity) => (
                                                                                        <div className="tag-item" key={itemsCity}>
                                                                                            {/* {itemsAddress1} */}
                                                                                            <a href={itemsCity} target="_blank">
                                                                                                <text>{itemsCity}</text>
                                                                                            </a>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>

                                                                    <div class="col-md-5">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Comment</label>
                                                                        <input type="text" class="form-control-addsource-Personal"
                                                                            id="input3" placeholder="Enter Comment"
                                                                            name="city_comments"
                                                                            value={this.state.PersonalInformation.city_comments}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.city_comments = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div class="col-md-1">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Check</label>
                                                                        <input type='checkbox'
                                                                            class="form-control-addsource-Personal-ckeckbox"
                                                                            // id="input3"
                                                                            name="city_status"
                                                                            checked={this.state.PersonalInformation.city_status}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.city_status = e.target.checked;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <hr />


                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Primary Work Address <span class="mandatory">*</span></label>
                                                                        <input type="text" class="form-control disabled"
                                                                            id="input7" placeholder="Enter First Address "
                                                                            name="address_1"
                                                                            value={this.state.PersonalInformation.address_1}
                                                                        />
                                                                        <div className="loginErrCreateProject">{this.state.address1Err}</div>
                                                                        {
                                                                            (!this.state.Address1AddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div style={{ marginTop: '8%' }}>
                                                                                    {this.state.itemsAddress1.map((itemsAddress1) => (
                                                                                        <div className="tag-item" key={itemsAddress1}>
                                                                                            {/* {itemsAddress1} */}
                                                                                            <a href={itemsAddress1} target="_blank">
                                                                                                <text>{itemsAddress1}</text>
                                                                                            </a>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </div>

                                                                    <div class="col-md-5">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Comment</label>
                                                                        <input type="text" class="form-control-addsource-Personal"
                                                                            id="input3" placeholder="Enter Comment"
                                                                            name="address_comment"
                                                                            value={this.state.PersonalInformation.address_comment}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.address_comment = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div class="col-md-1">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Check</label>
                                                                        <input type='checkbox'
                                                                            class="form-control-addsource-Personal-ckeckbox"
                                                                            // id="input3"
                                                                            name="address_status"
                                                                            checked={this.state.PersonalInformation.address_status}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.address_status = e.target.checked;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                <div class="row">
                                                               
                                                                <div class="col-md-6">
                                                                    
                                                                <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                    Primary Work Address 2 </label>
                                                                <input type="text" class="form-control disabled"
                                                                    id="input7" placeholder="Enter Second Address "
                                                                    name="address_2"
                                                                    value={this.state.PersonalInformation.address_2}
                                                                />

                                                                    <div className="loginErrCreateProject">{this.state.address2Err}</div>
                                                                        {
                                                                            (!this.state.Address2AddSource) ? (
                                                                                <div></div>
                                                                            ) : (
                                                                                <div style={{ marginTop: '8%' }}>
                                                                                    {this.state.itemsAddress2.map((itemsAddress2) => (
                                                                                        <div className="tag-item" key={itemsAddress2}>
                                                                                            {/* {itemsAddress1} */}
                                                                                            <a href={itemsAddress2} target="_blank">
                                                                                                <text>{itemsAddress2}</text>
                                                                                            </a>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            )
                                                                        }
                                                                   </div>         


                                                                   <div class="col-md-5">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Comment</label>
                                                                        <input type="text" class="form-control-addsource-Personal"
                                                                            id="input3" placeholder="Enter Comment"
                                                                            name="address_2comment"
                                                                            value={this.state.PersonalInformation.address_2comment}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.address_2comment = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div class="col-md-1">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Check</label>
                                                                        <input type='checkbox'
                                                                            class="form-control-addsource-Personal-ckeckbox"
                                                                            // id="input3"
                                                                            name="address_2status"
                                                                            checked={this.state.PersonalInformation.address_2status}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.address_2status = e.target.checked;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>
</div>

                                                                <hr />
                                                                {/* <a class="float-right" style={{marginTop:'-3%', color:'#007bff'}}
                                                onClick={this.Address2AddSourceFun}>+ Add source</a>                                            
                                            {
                                                ( !this.state.Address2AddSource ) ? (
                                                    <div></div>
                                                ) : (
                                                    <div style={{marginTop: '8%'}}>
                                                    <input type="text" class="form-control" 
                                                        id="input4" placeholder="Paste Source URL" 
                                                        value={this.state.Address2SourceUrl} 
                                                        onChange={(e) => {
                                                            let { Address2SourceUrl } = this.state;
                                                            Address2SourceUrl = e.target.value;
                                                            this.setState({ Address2SourceUrl });
                                                        }}/>
                                                    </div>
                                                )
                                            } */}

                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Zip Code <span class="mandatory">*</span></label>
                                                                        <input type="text" class="form-control disabled"
                                                                            id="input7" placeholder="Enter Zip Code "
                                                                            name="zip_code"
                                                                            value={this.state.PersonalInformation.zip_code}
                                                                        />
                                                                        <div className="loginErrCreateProject">{this.state.zipcodeErr}</div>
                                                                    </div>

                                                                    <div class="col-md-5">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Comment</label>
                                                                        <input type="text" class="form-control-addsource-Personal"
                                                                            id="input3" placeholder="Enter Comment"
                                                                            name="zip_code_comment"
                                                                            value={this.state.PersonalInformation.zip_code_comment}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.zip_code_comment = e.target.value;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div class="col-md-1">
                                                                        <label for="exampleFormControlTextarea1">
                                                                            Check</label>
                                                                        <input type='checkbox'
                                                                            class="form-control-addsource-Personal-ckeckbox"
                                                                            // id="input3"
                                                                            name="zip_code_status"
                                                                            checked={this.state.PersonalInformation.zip_code_status}
                                                                            onChange={(e) => {
                                                                                let { PersonalInformation } = this.state;
                                                                                PersonalInformation.zip_code_status = e.target.checked;
                                                                                this.setState({ PersonalInformation });
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <hr />
                                                                {/* Work details */}
                                                                {
                                                                    this.state.WorkDetails.map((el, i) => {

                                                                        var arrayCurruntPlaceOfWork = [];
                                                                        var arrayDesignation = [];
                                                                        var arrayDepartment = [];
                                                                        var arrayFromYear = [];
                                                                        var arrayToYear = [];

                                                                        if (el.current_place_of_work_links !== '') {
                                                                            arrayCurruntPlaceOfWork = el.current_place_of_work_links.split(',')
                                                                            arrayCurruntPlaceOfWork = arrayCurruntPlaceOfWork.splice(1)
                                                                        }
                                                                        if (el.designation_links !== '') {
                                                                            arrayDesignation = el.designation_links.split(',')
                                                                            arrayDesignation = arrayDesignation.splice(1)
                                                                        }
                                                                        if (el.department_links !== '') {
                                                                            arrayDepartment = el.department_links.split(',')
                                                                            arrayDepartment = arrayDepartment.splice(1)
                                                                        }
                                                                        if (el.from_year_links !== '') {
                                                                            arrayFromYear = el.from_year_links.split(',')
                                                                            arrayFromYear = arrayFromYear.splice(1)
                                                                        }
                                                                        if (el.to_year_links !== '') {
                                                                            arrayToYear = el.to_year_links.split(',')
                                                                            arrayToYear = arrayToYear.splice(1)
                                                                        }

                                                                        return (
                                                                            <div key={i}>
                                                                                {
                                                                                    (i == 0) ? (
                                                                                        <div>
                                                                                            <h3 class="mt-30">Work Details</h3>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div>
                                                                                            <h3 class="mt-30">Work Details {i + 1}</h3>
                                                                                            {/* {this.state.WorkDetails.length !== 1 && 
                                                                        <a class="float-right" style={{marginTop:'-8%', color:'#007bff'}}
                                                                        onClick={this.removeClickWorkDetails.bind(this, i)}>x</a>
                                                                    } */}
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                <div class="row">
                                                                                    <div class="col-md-6">
                                                                                        <label for="exampleFormControlTextarea1">
                                                                                            Current Place of Work <span class="mandatory">*</span></label>
                                                                                        <input type="text" class="form-control disabled"
                                                                                            id="input7" placeholder="Enter Current Place of Work"
                                                                                            name="current_place_of_work" value={el.current_place_of_work || ''}
                                                                                        // onChange={this.handleChangeWorkDetails.bind(this, i)}   
                                                                                        />
                                                                                        <div className="loginErrCreateProject">
                                                                                            {
                                                                                                (el.current_place_of_work != '') ? (<div></div>) : (this.state.placeofworkErr)
                                                                                            }
                                                                                        </div>
                                                                                        {
                                                                                            (!this.state.PlaceOfWorkAddSource) ? (
                                                                                                <div></div>
                                                                                            ) : (
                                                                                                <div>
                                                                                                    {
                                                                                                        arrayCurruntPlaceOfWork.flat().map((item) => (
                                                                                                            <div class="col-md-6" key={item}>
                                                                                                                {item}
                                                                                                                <a href={item} target="_blank">
                                                                                                                    <text>{item}</text>
                                                                                                                </a>
                                                                                                            </div>
                                                                                                        ))
                                                                                                    }
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </div>

                                                                                    <div class="col-md-5">
                                                                                        <label for="exampleFormControlTextarea1">
                                                                                            Comment</label>
                                                                                        <input type="text" class="form-control-addsource-Personal"
                                                                                            id="input3" placeholder="Enter Comment"
                                                                                            name="current_place_of_work_comment"
                                                                                            value={el.current_place_of_work_comment}
                                                                                            onChange={this.handleChangeWorkDetails.bind(this, i)}
                                                                                        />
                                                                                    </div>

                                                                                    <div class="col-md-1">
                                                                                        <label for="exampleFormControlTextarea1">
                                                                                            Check</label>
                                                                                        <input type='checkbox'
                                                                                            class="form-control-addsource-Personal-ckeckbox"
                                                                                            // id="input3"
                                                                                            name="current_place_of_work_status"
                                                                                            checked={el.current_place_of_work_status}
                                                                                            onChange={this.handleChangeCheckBoxWorkDetails.bind(this, i)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <hr />

                                                                                <div class="row">
                                                                                    <div class="col-md-6">
                                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '4%' }}>
                                                                                            Designation <span class="mandatory">*</span></label>
                                                                                        <input type="text" class="form-control disabled"
                                                                                            id="input7" placeholder="Enter Designation"
                                                                                            name="designation" value={el.designation || ''}
                                                                                        // onChange={this.handleChangeWorkDetails.bind(this, i)}   
                                                                                        />
                                                                                        <div className="loginErrCreateProject">
                                                                                            {
                                                                                                (el.designation != '') ? (<div></div>) : (this.state.designationErr)
                                                                                            }
                                                                                        </div>
                                                                                        {
                                                                                            (!this.state.DesignationAddSource) ? (
                                                                                                <div></div>
                                                                                            ) : (
                                                                                                <div style={{ marginTop: '8%' }}>
                                                                                                    {
                                                                                                        arrayDesignation.flat().map((item) => (
                                                                                                            <div class="col-md-6" key={item}>
                                                                                                                {/* {item} */}
                                                                                                                <a href={item} target="_blank">
                                                                                                                    <text>{item}</text>
                                                                                                                </a>
                                                                                                            </div>
                                                                                                        ))
                                                                                                    }
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </div>

                                                                                    <div class="col-md-5">
                                                                                        <label for="exampleFormControlTextarea1">
                                                                                            Comment</label>
                                                                                        <input type="text" class="form-control-addsource-Personal"
                                                                                            id="input3" placeholder="Enter Comment"
                                                                                            name="designation_comment"
                                                                                            value={el.designation_comment}
                                                                                            onChange={this.handleChangeWorkDetails.bind(this, i)}
                                                                                        />
                                                                                    </div>

                                                                                    <div class="col-md-1">
                                                                                        <label for="exampleFormControlTextarea1">
                                                                                            Check</label>
                                                                                        <input type='checkbox'
                                                                                            class="form-control-addsource-Personal-ckeckbox"
                                                                                            // id="input3"
                                                                                            name="designation_status"
                                                                                            checked={el.designation_status}
                                                                                            onChange={this.handleChangeCheckBoxWorkDetails.bind(this, i)}
                                                                                        />
                                                                                    </div>


                                                                                </div>
                                                                                <hr />

                                                                                <div class="row">
                                                                                    <div class="col-md-6">
                                                                                        <label for="exampleFormControlTextarea1">
                                                                                            Department 
                                                                                            {/* <span class="mandatory">*</span> */}
                                                                                        </label>
                                                                                        <input type="text" class="form-control disabled"
                                                                                            id="input7" placeholder="Enter Department"
                                                                                            name="department" value={el.department || ''}
                                                                                        // onChange={this.handleChangeWorkDetails.bind(this, i)}   
                                                                                        />
                                                                                        <div className="loginErrCreateProject">
                                                                                            {
                                                                                                (el.department != '') ? (<div></div>) : (this.state.departmentErr)
                                                                                            }
                                                                                        </div>
                                                                                        {
                                                                                            (!this.state.DepartmentAddSource) ? (
                                                                                                <div></div>
                                                                                            ) : (
                                                                                                <div style={{ marginTop: '8%' }}>
                                                                                                    {
                                                                                                        arrayDepartment.flat().map((item) => (
                                                                                                            <div class="col-md-6" key={item}>
                                                                                                                {/* {item} */}
                                                                                                                <a href={item} target="_blank">
                                                                                                                    <text>{item}</text>
                                                                                                                </a>
                                                                                                            </div>
                                                                                                        ))
                                                                                                    }
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </div>

                                                                                    <div class="col-md-5">
                                                                                        <label for="exampleFormControlTextarea1">
                                                                                            Comment</label>
                                                                                        <input type="text" class="form-control-addsource-Personal"
                                                                                            id="input3" placeholder="Enter Comment"
                                                                                            name="department_comment"
                                                                                            value={el.department_comment}
                                                                                            onChange={this.handleChangeWorkDetails.bind(this, i)}
                                                                                        />
                                                                                    </div>

                                                                                    <div class="col-md-1">
                                                                                        <label for="exampleFormControlTextarea1">
                                                                                            Check</label>
                                                                                        <input type='checkbox'
                                                                                            class="form-control-addsource-Personal-ckeckbox"
                                                                                            // id="input3"
                                                                                            name="department_status"
                                                                                            checked={el.department_status}
                                                                                            onChange={this.handleChangeCheckBoxWorkDetails.bind(this, i)}
                                                                                        />
                                                                                    </div>


                                                                                </div>
                                                                                <hr />

                                                                                <div class="row">
                                                                                    <div class="col-md-6">
                                                                                        <label for="exampleFormControlTextarea1">
                                                                                            From Year 
                                                                                            {/* <span class="mandatory">*</span> */}
                                                                                        </label>
                                                                                        <input type="text" class="form-control disabled"
                                                                                            id="input7" placeholder="Enter From Year"
                                                                                            name="from_year" value={el.from_year || ''}
                                                                                        // onChange={this.handleChangeWorkDetails.bind(this, i)}   
                                                                                        />
                                                                                        {/* <div className="loginErrCreateProject">
                                                                                            {
                                                                                                (el.from_year != '') ? (<div></div>) : (this.state.departmentErr)
                                                                                            }
                                                                                        </div> */}
                                                                                        {
                                                                                            (!this.state.FromYearAddSource) ? (
                                                                                                <div></div>
                                                                                            ) : (
                                                                                                <div style={{ marginTop: '8%' }}>
                                                                                                    {
                                                                                                        arrayFromYear.flat().map((item) => (
                                                                                                            <div class="col-md-6" key={item}>
                                                                                                                {/* {item} */}
                                                                                                                <a href={item} target="_blank">
                                                                                                                    <text>{item}</text>
                                                                                                                </a>
                                                                                                            </div>
                                                                                                        ))
                                                                                                    }
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </div>

                                                                                    <div class="col-md-5">
                                                                                        <label for="exampleFormControlTextarea1">
                                                                                            Comment</label>
                                                                                        <input type="text" class="form-control-addsource-Personal"
                                                                                            id="input3" placeholder="Enter Comment"
                                                                                            name="from_year_comments"
                                                                                            value={el.from_year_comments}
                                                                                            onChange={this.handleChangeWorkDetails.bind(this, i)}
                                                                                        />
                                                                                    </div>

                                                                                    <div class="col-md-1">
                                                                                        <label for="exampleFormControlTextarea1">
                                                                                            Check</label>
                                                                                        <input type='checkbox'
                                                                                            class="form-control-addsource-Personal-ckeckbox"
                                                                                            // id="input3"
                                                                                            name="from_year_status"
                                                                                            checked={el.from_year_status}
                                                                                            onChange={this.handleChangeCheckBoxWorkDetails.bind(this, i)}
                                                                                        />
                                                                                    </div>


                                                                                </div>
                                                                                <hr />

                                                                                <div class="row">
                                                                                    <div class="col-md-6">
                                                                                        <label for="exampleFormControlTextarea1">
                                                                                            To Year 
                                                                                            {/* <span class="mandatory">*</span> */}
                                                                                        </label>
                                                                                        <input type="text" class="form-control disabled"
                                                                                            id="input7" placeholder="Enter To Year"
                                                                                            name="to_year" value={el.to_year || ''}
                                                                                        // onChange={this.handleChangeWorkDetails.bind(this, i)}   
                                                                                        />
                                                                                        {/* <div className="loginErrCreateProject">
                                                                                            {
                                                                                                (el.from_year != '') ? (<div></div>) : (this.state.departmentErr)
                                                                                            }
                                                                                        </div> */}
                                                                                        {
                                                                                            (!this.state.ToYearAddSource) ? (
                                                                                                <div></div>
                                                                                            ) : (
                                                                                                <div style={{ marginTop: '8%' }}>
                                                                                                    {
                                                                                                        arrayToYear.flat().map((item) => (
                                                                                                            <div class="col-md-6" key={item}>
                                                                                                                {/* {item} */}
                                                                                                                <a href={item} target="_blank">
                                                                                                                    <text>{item}</text>
                                                                                                                </a>
                                                                                                            </div>
                                                                                                        ))
                                                                                                    }
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </div>

                                                                                    <div class="col-md-5">
                                                                                        <label for="exampleFormControlTextarea1">
                                                                                            Comment</label>
                                                                                        <input type="text" class="form-control-addsource-Personal"
                                                                                            id="input3" placeholder="Enter Comment"
                                                                                            name="to_year_comments"
                                                                                            value={el.to_year_comments}
                                                                                            onChange={this.handleChangeWorkDetails.bind(this, i)}
                                                                                        />
                                                                                    </div>

                                                                                    <div class="col-md-1">
                                                                                        <label for="exampleFormControlTextarea1">
                                                                                            Check</label>
                                                                                        <input type='checkbox'
                                                                                            class="form-control-addsource-Personal-ckeckbox"
                                                                                            // id="input3"
                                                                                            name="to_year_status"
                                                                                            checked={el.to_year_status}
                                                                                            onChange={this.handleChangeCheckBoxWorkDetails.bind(this, i)}
                                                                                        />
                                                                                    </div>


                                                                                </div>
                                                                                <hr />

                                                                                {/* Add More and remove */}
                                                                                {/* {this.state.WorkDetails.length - 1 === i && 
                                                        <a style={{marginTop:'5%', color:'#007bff'}}
                                                        onClick={this.addClickWorkDetails.bind(this)}>+ Add More Work Details</a>
                                                        } */}
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                                {/* <hr/>
                                            <hr/> */}
                                                                {/* Identifier  form */}
                                                                {
                                                                    (this.state.PersonalInformation.kol_type == 'KOL Community') ? (
                                                                        <div>
                                                                            <div class="row">
                                                                                <div class="col-md-6">
                                                                                    {/* <label style={{fontSize:'23px',fontWeight:'1%'}}>Event Identifier</label> */}
                                                                                    {/* <h3 class="mt-30">Event participation</h3> */}
                                                                                    {/* <br/> */}
                                                                                    <div className="row">
                                                                                        <h3 class="mt-30" style={{ marginLeft: '3%' }}>Event participation</h3>
                                                                                        <input type='checkbox'
                                                                                            style={{ marginLeft: '35%', marginTop: '9%' }}
                                                                                            id="event_identifier"
                                                                                            name="event_identifier"
                                                                                            checked={this.state.KOL_Identifiers.event_identifier}
                                                                                            onChange={(e) => {
                                                                                                let { KOL_Identifiers } = this.state;
                                                                                                KOL_Identifiers.event_identifier = e.target.checked;
                                                                                                this.setState({ KOL_Identifiers });
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <label for="exampleFormControlTextarea1">
                                                                                        Event/Conference Type </label>
                                                                                    {/* <div class="row"> */}
                                                                                    <div class="form-control">
                                                                                        <input type='checkbox'
                                                                                            disabled="disabled"
                                                                                            style={{ marginLeft: '5%' }}
                                                                                            id="international_event"
                                                                                            name="international_event"
                                                                                            checked={this.state.KOL_Identifiers.international_event}
                                                                                            onChange={(e) => {
                                                                                                let { KOL_Identifiers } = this.state;
                                                                                                KOL_Identifiers.international_event = e.target.checked;
                                                                                                this.setState({ KOL_Identifiers });
                                                                                            }}
                                                                                        />
                                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                            International
                                                                                        </label>

                                                                                        <input type='checkbox'
                                                                                            style={{ marginLeft: '13%' }}
                                                                                            disabled="disabled"
                                                                                            id="national_event"
                                                                                            name="national_event"
                                                                                            checked={this.state.KOL_Identifiers.national_event}
                                                                                        // onChange={(e) => {
                                                                                        //     let { KOL_Identifiers } = this.state;
                                                                                        //     KOL_Identifiers.national_event = e.target.checked;
                                                                                        //     this.setState({ KOL_Identifiers });
                                                                                        // }}
                                                                                        />
                                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                            National
                                                                                        </label>
                                                                                    </div>

                                                                                    {/* <br/> */}
                                                                                    <label for="exampleFormControlTextarea1" style={{ marginTop: '2%' }}>
                                                                                        Role
                                                                                    </label>
                                                                                    {/* <div class="row"> */}
                                                                                    <div class="form-control">
                                                                                        <input type='checkbox'
                                                                                            style={{ marginLeft: '5%' }}
                                                                                            disabled="disabled"
                                                                                            id="keynote_speaker_event"
                                                                                            name="keynote_speaker_event"
                                                                                            checked={this.state.KOL_Identifiers.keynote_speaker_event}
                                                                                        />
                                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                            Keynote Speaker
                                                                                        </label>

                                                                                        <input type='checkbox'
                                                                                            style={{ marginLeft: '6%' }}
                                                                                            disabled="disabled"
                                                                                            id="regular_event"
                                                                                            name="regular_event"
                                                                                            checked={this.state.KOL_Identifiers.regular_event}
                                                                                        />
                                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                            Regular
                                                                                        </label>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-5" style={{ marginTop: '17%' }}>
                                                                                    <label for="exampleFormControlTextarea1">
                                                                                        Comment</label>
                                                                                    <input type="text" class="form-control-addsource-Personal"
                                                                                        id="input3" placeholder="Enter Comment"
                                                                                        name="event_comment"
                                                                                        value={this.state.KOL_Identifiers.event_comment}
                                                                                        onChange={(e) => {
                                                                                            let { KOL_Identifiers } = this.state;
                                                                                            KOL_Identifiers.event_comment = e.target.value;
                                                                                            this.setState({ KOL_Identifiers });
                                                                                        }}
                                                                                    />
                                                                                </div>

                                                                                <div class="col-md-1" style={{ marginTop: '17%' }}>
                                                                                    <label for="exampleFormControlTextarea1">
                                                                                        Check</label>
                                                                                    <input type='checkbox'
                                                                                        class="form-control-addsource-Personal-ckeckbox"
                                                                                        // id="input3"
                                                                                        name="event_status"
                                                                                        checked={this.state.KOL_Identifiers.event_status}
                                                                                        onChange={(e) => {
                                                                                            let { KOL_Identifiers } = this.state;
                                                                                            KOL_Identifiers.event_status = e.target.checked;
                                                                                            this.setState({ KOL_Identifiers });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <hr style={{ marginTop: '1%' }} />

                                                                            <div class="row">
                                                                                <div class="col-md-6">
                                                                                    {/* <label style={{fontSize:'23px',fontWeight:'1%'}}>Advisory Board Identifier</label> */}
                                                                                    {/* <h3 class="mt-30">Advisory Board membership</h3> */}
                                                                                    <div className="row">
                                                                                        <h3 class="mt-30" style={{ marginLeft: '3%' }}>Advisory Board membership</h3>
                                                                                        <input type='checkbox'
                                                                                            style={{ marginLeft: '5%', marginTop: '9%' }}
                                                                                            id="advisory_identifier"
                                                                                            name="advisory_identifier"
                                                                                            checked={this.state.KOL_Identifiers.advisory_identifier}
                                                                                            onChange={(e) => {
                                                                                                let { KOL_Identifiers } = this.state;
                                                                                                KOL_Identifiers.advisory_identifier = e.target.checked;
                                                                                                this.setState({ KOL_Identifiers });
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    {/* <br/> */}
                                                                                    <label for="exampleFormControlTextarea1">
                                                                                        Org/Company Type </label>
                                                                                    {/* <div class="row"> */}
                                                                                    <div class="form-control">
                                                                                        <input type='checkbox'
                                                                                            style={{ marginLeft: '5%' }}
                                                                                            disabled="disabled"
                                                                                            id="international_advisory_board"
                                                                                            name="international_advisory_board"
                                                                                            checked={this.state.KOL_Identifiers.international_advisory_board}
                                                                                        />
                                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                            International (MNC)
                                                                                        </label>

                                                                                        <input type='checkbox'
                                                                                            style={{ marginLeft: '1%' }}
                                                                                            disabled="disabled"
                                                                                            id="national_advisory_board"
                                                                                            name="national_advisory_board"
                                                                                            checked={this.state.KOL_Identifiers.national_advisory_board}
                                                                                        />
                                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                            National
                                                                                        </label>
                                                                                    </div>

                                                                                    {/* <br/> */}
                                                                                    <label for="exampleFormControlTextarea1" >
                                                                                        Role </label>
                                                                                    {/* <div class="row"> */}
                                                                                    <div class="form-control">
                                                                                        <input type='checkbox'
                                                                                            style={{ marginLeft: '5%' }}
                                                                                            disabled="disabled"
                                                                                            id="board_member_advisory_board"
                                                                                            name="board_member_advisory_board"
                                                                                            checked={this.state.KOL_Identifiers.board_member_advisory_board}
                                                                                        />
                                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                            Board Member
                                                                                        </label>

                                                                                        <input type='checkbox'
                                                                                            style={{ marginLeft: '9%' }}
                                                                                            disabled="disabled"
                                                                                            id="advisory_committee_advisory_board"
                                                                                            name="advisory_committee_advisory_board"
                                                                                            checked={this.state.KOL_Identifiers.advisory_committee_advisory_board}
                                                                                        />
                                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                            Advisory Committee
                                                                                        </label>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-5" style={{ marginTop: '16%' }}>
                                                                                    <label for="exampleFormControlTextarea1">
                                                                                        Comment</label>
                                                                                    <input type="text" class="form-control-addsource-Personal"
                                                                                        id="input3" placeholder="Enter Comment"
                                                                                        name="advisory_board_comment"
                                                                                        value={this.state.KOL_Identifiers.advisory_board_comment}
                                                                                        onChange={(e) => {
                                                                                            let { KOL_Identifiers } = this.state;
                                                                                            KOL_Identifiers.advisory_board_comment = e.target.value;
                                                                                            this.setState({ KOL_Identifiers });
                                                                                        }}
                                                                                    />
                                                                                </div>

                                                                                <div class="col-md-1" style={{ marginTop: '16%' }}>
                                                                                    <label for="exampleFormControlTextarea1">
                                                                                        Check</label>
                                                                                    <input type='checkbox'
                                                                                        class="form-control-addsource-Personal-ckeckbox"
                                                                                        // id="input3"
                                                                                        name="advisory_board_status"
                                                                                        checked={this.state.KOL_Identifiers.advisory_board_status}
                                                                                        onChange={(e) => {
                                                                                            let { KOL_Identifiers } = this.state;
                                                                                            KOL_Identifiers.advisory_board_status = e.target.checked;
                                                                                            this.setState({ KOL_Identifiers });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <hr style={{ marginTop: '1%' }} />

                                                                            <div class="row">
                                                                                <div class="col-md-6">
                                                                                    {/* <label style={{fontSize:'23px',fontWeight:'1%'}}>Publication Identifier</label> */}
                                                                                    <h3 class="mt-30">Publication</h3>
                                                                                    <input type='checkbox'
                                                                                        style={{ marginLeft: '57%', marginTop: '9%' }}
                                                                                        id="publication_identifier"
                                                                                        name="publication_identifier"
                                                                                        checked={this.state.KOL_Identifiers.publication_identifier}
                                                                                        onChange={(e) => {
                                                                                            let { KOL_Identifiers } = this.state;
                                                                                            KOL_Identifiers.publication_identifier = e.target.checked;
                                                                                            this.setState({ KOL_Identifiers });
                                                                                        }}
                                                                                    />
                                                                                    {/* <br/> */}
                                                                                    <label for="exampleFormControlTextarea1" >
                                                                                        Org/Company Type </label>
                                                                                    {/* <div class="row"> */}
                                                                                    <div class="form-control">
                                                                                        <input type='checkbox'
                                                                                            style={{ marginLeft: '5%' }}
                                                                                            disabled="disabled"
                                                                                            id="international_publication"
                                                                                            name="international_publication"
                                                                                            checked={this.state.KOL_Identifiers.international_publication}
                                                                                        />
                                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                            International (MNC)
                                                                                        </label>

                                                                                        <input type='checkbox'
                                                                                            style={{ marginLeft: '1%' }}
                                                                                            disabled="disabled"
                                                                                            id="national_publication"
                                                                                            name="national_publication"
                                                                                            checked={this.state.KOL_Identifiers.national_publication}
                                                                                        />
                                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                            National
                                                                                        </label>
                                                                                    </div>

                                                                                    {/* <br/> */}
                                                                                    <label for="exampleFormControlTextarea1">
                                                                                        Role </label>
                                                                                    {/* <div class="row"> */}
                                                                                    <div class="form-control">
                                                                                        <input type='checkbox'
                                                                                            style={{ marginLeft: '5%' }}
                                                                                            disabled="disabled"
                                                                                            // id="second_author_publication"
                                                                                            id="first_author_publication"
                                                                                            name="first_author_publication"
                                                                                            checked={this.state.KOL_Identifiers.first_author_publication}
                                                                                        />
                                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                            First Author
                                                                                        </label>

                                                                                        <input type='checkbox'
                                                                                            style={{ marginLeft: '10%' }}
                                                                                            disabled="disabled"
                                                                                            // id="last_author_publication"
                                                                                            id="second_author_publication"
                                                                                            name="second_author_publication"
                                                                                            checked={this.state.KOL_Identifiers.second_author_publication}
                                                                                        />
                                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                            Second Author
                                                                                        </label>
                                                                                    </div>
                                                                                    <div class="form-control" style={{ marginTop: '2%' }}>
                                                                                        <input type='checkbox'
                                                                                            style={{ marginLeft: '5%' }}
                                                                                            disabled="disabled"
                                                                                            // id="first_author_publication"
                                                                                            id="last_author_publication"
                                                                                            name="last_author_publication"
                                                                                            checked={this.state.KOL_Identifiers.last_author_publication}
                                                                                        />
                                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                            Last Author
                                                                                        </label>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-5" style={{ marginTop: '16%' }}>
                                                                                    <label for="exampleFormControlTextarea1">
                                                                                        Comment</label>
                                                                                    <input type="text" class="form-control-addsource-Personal"
                                                                                        id="input3" placeholder="Enter Comment"
                                                                                        name="publication_comment"
                                                                                        value={this.state.KOL_Identifiers.publication_comment}
                                                                                        onChange={(e) => {
                                                                                            let { KOL_Identifiers } = this.state;
                                                                                            KOL_Identifiers.publication_comment = e.target.value;
                                                                                            this.setState({ KOL_Identifiers });
                                                                                        }}
                                                                                    />
                                                                                </div>

                                                                                <div class="col-md-1" style={{ marginTop: '16%' }}>
                                                                                    <label for="exampleFormControlTextarea1">
                                                                                        Check</label>
                                                                                    <input type='checkbox'
                                                                                        class="form-control-addsource-Personal-ckeckbox"
                                                                                        // id="input3"
                                                                                        name="publication_status"
                                                                                        checked={this.state.KOL_Identifiers.publication_status}
                                                                                        onChange={(e) => {
                                                                                            let { KOL_Identifiers } = this.state;
                                                                                            KOL_Identifiers.publication_status = e.target.checked;
                                                                                            this.setState({ KOL_Identifiers });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <hr style={{ marginTop: '1%' }} />

                                                                            <div class="row">
                                                                                <div class="col-md-6">
                                                                                    {/* <label style={{fontSize:'23px',fontWeight:'1%'}}>Clinical Trial Identifier</label> */}
                                                                                    {/* <h3 class="mt-30">Clinical Trial Participation</h3> */}
                                                                                    <div className="row">
                                                                                        <h3 class="mt-30" style={{ marginLeft: '3%' }}>Clinical Trial Participation</h3>
                                                                                        <input type='checkbox'
                                                                                            style={{ marginLeft: '14%', marginTop: '9%' }}
                                                                                            id="publication_identifier"
                                                                                            name="publication_identifier"
                                                                                            checked={this.state.KOL_Identifiers.publication_identifier}
                                                                                            onChange={(e) => {
                                                                                                let { KOL_Identifiers } = this.state;
                                                                                                KOL_Identifiers.publication_identifier = e.target.checked;
                                                                                                this.setState({ KOL_Identifiers });
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    {/* <br/> */}
                                                                                    <label for="exampleFormControlTextarea1" >
                                                                                        No. of Trial Location </label>
                                                                                    {/* <div class="row"> */}
                                                                                    <div class="form-control">
                                                                                        <input type='checkbox'
                                                                                            style={{ marginLeft: '5%' }}
                                                                                            disabled="disabled"
                                                                                            id="one_trial_location"
                                                                                            name="one_trial_location"
                                                                                            checked={this.state.KOL_Identifiers.one_trial_location}
                                                                                        />
                                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                            1
                                                                                        </label>

                                                                                        <input type='checkbox'
                                                                                            style={{ marginLeft: '35%' }}
                                                                                            disabled="disabled"
                                                                                            id="ten_trial_location"
                                                                                            name="ten_trial_location"
                                                                                            checked={this.state.KOL_Identifiers.ten_trial_location}
                                                                                        />
                                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                            2-10
                                                                                        </label>
                                                                                    </div>
                                                                                    {/* <div class="row"> */}
                                                                                    <div class="form-control" style={{ marginTop: '2%' }}>
                                                                                        <input type='checkbox'
                                                                                            style={{ marginLeft: '5%' }}
                                                                                            disabled="disabled"
                                                                                            id="fifty_trial_location"
                                                                                            name="fifty_trial_location"
                                                                                            checked={this.state.KOL_Identifiers.fifty_trial_location}
                                                                                        />
                                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                            11-50
                                                                                        </label>

                                                                                        <input type='checkbox'
                                                                                            style={{ marginLeft: '33%' }}
                                                                                            disabled="disabled"
                                                                                            id="tenthousand_trial_location"
                                                                                            name="tenthousand_trial_location"
                                                                                            checked={this.state.KOL_Identifiers.tenthousand_trial_location}
                                                                                        />
                                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                            51+
                                                                                        </label>
                                                                                    </div>

                                                                                    {/* <br/> */}
                                                                                    <label for="exampleFormControlTextarea1" >
                                                                                        Trial Type </label>
                                                                                    {/* <div class="row"> */}
                                                                                    <div class="form-control">
                                                                                        <input type='checkbox'
                                                                                            style={{ marginLeft: '5%' }}
                                                                                            disabled="disabled"
                                                                                            id="interventional_trial_location"
                                                                                            name="interventional_trial_location"
                                                                                            checked={this.state.KOL_Identifiers.interventional_trial_location}
                                                                                        />
                                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                            Interventional
                                                                                        </label>

                                                                                        <input type='checkbox'
                                                                                            style={{ marginLeft: '13%' }}
                                                                                            disabled="disabled"
                                                                                            id="observational_trial_location"
                                                                                            name="observational_trial_location"
                                                                                            checked={this.state.KOL_Identifiers.observational_trial_location}
                                                                                        />
                                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                            Observational
                                                                                        </label>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-md-5" style={{ marginTop: '22%' }}>
                                                                                    <label for="exampleFormControlTextarea1">
                                                                                        Comment</label>
                                                                                    <input type="text" class="form-control-addsource-Personal"
                                                                                        id="input3" placeholder="Enter Comment"
                                                                                        name="trial_location_comment"
                                                                                        value={this.state.KOL_Identifiers.trial_location_comment}
                                                                                        onChange={(e) => {
                                                                                            let { KOL_Identifiers } = this.state;
                                                                                            KOL_Identifiers.trial_location_comment = e.target.value;
                                                                                            this.setState({ KOL_Identifiers });
                                                                                        }}
                                                                                    />
                                                                                </div>

                                                                                <div class="col-md-1" style={{ marginTop: '22%' }}>
                                                                                    <label for="exampleFormControlTextarea1">
                                                                                        Check</label>
                                                                                    <input type='checkbox'
                                                                                        class="form-control-addsource-Personal-ckeckbox"
                                                                                        // id="input3"
                                                                                        name="trial_location_status"
                                                                                        checked={this.state.KOL_Identifiers.trial_location_status}
                                                                                        onChange={(e) => {
                                                                                            let { KOL_Identifiers } = this.state;
                                                                                            KOL_Identifiers.trial_location_status = e.target.checked;
                                                                                            this.setState({ KOL_Identifiers });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <hr style={{ marginTop: '1%' }} />

                                                                            <div class="row">
                                                                                <div class="col-md-6">
                                                                                    {/* <label style={{fontSize:'23px',fontWeight:'1%'}}>Guideline Identifier</label> */}
                                                                                    {/* <h3 class="mt-30">Guidelines</h3> */}
                                                                                    <div className="row">
                                                                                        <h3 class="mt-30" style={{ marginLeft: '3%' }}>Guidelines</h3>
                                                                                        <input type='checkbox'
                                                                                            style={{ marginLeft: '14%', marginTop: '9%' }}
                                                                                            id="guidelines_identifier"
                                                                                            name="guidelines_identifier"
                                                                                            checked={this.state.KOL_Identifiers.guidelines_identifier}
                                                                                            onChange={(e) => {
                                                                                                let { KOL_Identifiers } = this.state;
                                                                                                KOL_Identifiers.guidelines_identifier = e.target.checked;
                                                                                                this.setState({ KOL_Identifiers });
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    {/* <br/> */}
                                                                                    <label for="exampleFormControlTextarea1" >
                                                                                        Organization Type </label>
                                                                                    {/* <div class="row"> */}
                                                                                    <div class="form-control">
                                                                                        <input type='checkbox'
                                                                                            style={{ marginLeft: '5%' }}
                                                                                            disabled="disabled"
                                                                                            id="international_guideline"
                                                                                            name="international_guideline"
                                                                                            checked={this.state.KOL_Identifiers.international_guideline}
                                                                                        />
                                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                            International
                                                                                        </label>

                                                                                        <input type='checkbox'
                                                                                            style={{ marginLeft: '16%' }}
                                                                                            disabled="disabled"
                                                                                            id="national_guideline"
                                                                                            name="national_guideline"
                                                                                            checked={this.state.KOL_Identifiers.national_guideline}
                                                                                        />
                                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                            National
                                                                                        </label>
                                                                                    </div>
                                                                                    {/* <div class="row"> */}
                                                                                    <div class="form-control" style={{ marginTop: '2%' }}>
                                                                                        <input type='checkbox'
                                                                                            style={{ marginLeft: '5%' }}
                                                                                            disabled="disabled"
                                                                                            id="local_guideline"
                                                                                            name="local_guideline"
                                                                                            checked={this.state.KOL_Identifiers.local_guideline}
                                                                                        />
                                                                                        <label for="exampleFormControlTextarea1" style={{ marginTop: '-1.5%' }}>
                                                                                            Local
                                                                                        </label>
                                                                                    </div>
                                                                                </div>


                                                                                <div class="col-md-5" style={{ marginTop: '12%' }}>
                                                                                    <label for="exampleFormControlTextarea1">
                                                                                        Comment</label>
                                                                                    <input type="text" class="form-control-addsource-Personal"
                                                                                        id="input3" placeholder="Enter Comment"
                                                                                        name="guideline_comment"
                                                                                        value={this.state.KOL_Identifiers.guideline_comment}
                                                                                        onChange={(e) => {
                                                                                            let { KOL_Identifiers } = this.state;
                                                                                            KOL_Identifiers.guideline_comment = e.target.value;
                                                                                            this.setState({ KOL_Identifiers });
                                                                                        }}
                                                                                    />
                                                                                </div>

                                                                                <div class="col-md-1" style={{ marginTop: '12%' }}>
                                                                                    <label for="exampleFormControlTextarea1">
                                                                                        Check</label>
                                                                                    <input type='checkbox'
                                                                                        class="form-control-addsource-Personal-ckeckbox"
                                                                                        // id="input3"
                                                                                        name="guideline_status"
                                                                                        checked={this.state.KOL_Identifiers.guideline_status}
                                                                                        onChange={(e) => {
                                                                                            let { KOL_Identifiers } = this.state;
                                                                                            KOL_Identifiers.guideline_status = e.target.checked;
                                                                                            this.setState({ KOL_Identifiers });
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>

                                                                            <hr style={{ marginTop: '1%' }} />
                                                                        </div>
                                                                    ) : (
                                                                        <div></div>
                                                                    )
                                                                }




                                                            </div>

                                                            
                                                            <label for="exampleFormControlTextarea1">
                                                            Source Link</label>
                                                            <input type="text" class="form-control-addsource-Personal"
                                                                id="input3" placeholder="Enter Source Link"
                                                                name="source_link"
                                                                value={this.state.PersonalInformation.source_link}
                                                                onChange={(e) => {
                                                                    // let { kol_comment } = this.state;
                                                                    // kol_comment = e.target.value;
                                                                    // this.setState({ kol_comment });

                                                                    let { PersonalInformation } = this.state;
                                                                    PersonalInformation.source_link = e.target.value;
                                                                    this.setState({ PersonalInformation });
                                                                }}
                                                            />

                                                            <label for="exampleFormControlTextarea1">
                                                                Overall Comment</label>
                                                            <input type="text" class="form-control-addsource-Personal"
                                                                id="input3" placeholder="Enter Overall Comment"
                                                                name="kol_comment"
                                                                value={this.state.PersonalInformation.kol_comment}
                                                                onChange={(e) => {
                                                                    // let { kol_comment } = this.state;
                                                                    // kol_comment = e.target.value;
                                                                    // this.setState({ kol_comment });

                                                                    let { PersonalInformation } = this.state;
                                                                    PersonalInformation.kol_comment = e.target.value;
                                                                    this.setState({ PersonalInformation });
                                                                }}
                                                            />
                                                        </form>
                                                    </div>

                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="action-btn-container">
                                <div class="container">
                                    <div class="col-md-7 offset-md-3">
                                        <button class="cetas-button" onClick={this.RejectFunc}>Reject</button>
                                        <button class="cetas-button" data-toggle="modal" data-target="#edit-item"
                                            onClick={this.ApproveFunc}>Approve</button>

                                    </div>
                                </div>
                            </div>
                            <footer class="footer-fixed">
                                <div class="cetas-footer">
                                    <div class="container">
                                        <p>All rights reserved. www.cetashealthcare.com</p>
                                    </div>

                                </div>
                            </footer>
                        </div>
                    </div>
                </div>
            </body>
        );
    }
}

export default ManagerQCIdentifire;