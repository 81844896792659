import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'

//datatable npm
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';

// showing progress bar npm 
import { PropagateLoader } from 'react-spinners';
import LoadingBar from "react-top-loading-bar";

import axios from 'axios';

import Swal from 'sweetalert2';
import { Multiselect } from 'react-widgets';

import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import Header from './Header';

const { ExportCSVButton } = CSVExport;

const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total ml-2">
    Showing {from} to {to} of {size} Results
  </span>
);

const selectRow = {
  mode: 'checkbox',
  // clickToSelect: true
};

class AdministratorSearchInCetasDb extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // SearchInCetas: false,
      redirect: false,
      administrator_id: '',
      administrator_name: '',
      administrator_email: '',

      RedirectLogin: false,
      RedirectAdministratorProject: false,
      redirectAdministratorViewSearchKolList: false,

      // state for progress bar
      ShowBar: false,
      progressBar: false,
      Profile1: [],
      kolInfo: [],

      // Column state for Bootstrap Table 
      columns: [
        {
          dataField: 'rank_local',
          text: 'Rank Local',
          sort: true,
          formatter: this.RankLocalFormater,
        },
        {
          dataField: 'rank',
          text: 'Rank Global',
          sort: true,
          formatter: this.RankGlobalFormater,
        },
        {
          dataField: 'aggregate_score',
          text: 'Aggregate Score',
          sort: true,
        },
        {
          dataField: 'kol_unique_id',
          text: 'KOL ID',
          sort: true,
          formatter: this.linkToDetails,
        },
        {
          dataField: 'speciality_type',
          text: 'Speciality Type',
        },
        {
          dataField: 'procedure_type',
          text: 'Procedure Type',
        },
        {
          dataField: 'procedure_potential',
          text: 'Procedure Potential',
        },
        {
          dataField: 'qualification',
          text: 'Degree',
        },
        {
          dataField: 'medical_schools',
          text: 'Institution',
        },
        {
          dataField: 'country_name',
          text: 'Country',
        },
        {
          dataField: 'state_name',
          text: 'State',
        },
      ],

      columns2: [
        {
          dataField: 'kol_unique_id',
          text: 'ID',
          // sort: true,
          csvExport: false
        },
        {
          dataField: 'doctor_full_name',
          text: 'KOL Name',
          formatter: this.linkToDetails2,
          // sort: true,
          // filter: textFilter(),
          // filterRenderer: (onFilter, column) => {
          //   this.onProjectNameFilter = onFilter;
          //   return null;
          // },
        },
        // {
        //   dataField: "rank",
        //   text: "Ranking",
        //   hidden: true,
        // },
        // {
        //   dataField: "speciality_type",
        //   text: "Speciality Type",
        //   hidden: true,
        // },
        // {
        //   dataField: "sub_speciality_type",
        //   text: "Sub Speciality",
        //   hidden: true,
        // },
        // {
        //   dataField: "procedure_type",
        //   text: "Procedure Type",
        //   hidden: true,
        // },
        // {
        //   dataField: "doctor_email",
        //   text: "Email",
        //   hidden: true,
        // },
        // {
        //   dataField: "doctor_contact_no",
        //   text: "Mobile No.",
        //   hidden: true,
        // },
        {
          dataField: 'qualification',
          text: 'Degree',
          // hidden: true                              to show data in export file
          csvExport: false
        },
        {
          dataField: "speciality_type",
          text: "Speciality",
          csvExport: false
          // align: 'center',
          // headerAlign: 'center'
        },
        {
          dataField: "state_name",
          text: "State",
          csvExport: false
        },
        {
          dataField: "country_name",
          text: "Country",
        },
        {
          dataField: "procedure_potential",
          text: "Procedure Potential",
          csvExport: false
        },
        {
          dataField: "",
          text: "Download",
          formatter: this.linkFollow,
          csvExport: false,
        },
      ],

    } // End of states
    console.log("in constructor");
  }// End of constructor

  componentDidMount = async () => {
    console.log("in componentDidMount");
    let getSession_Administrator_Email = '';
    let getSession_Administrator_ID = '';
    let getSession_Administrator_Name = '';
    let getSession_kolInfo = [];
    // let getSession_SearchInCetas;

    getSession_Administrator_Email = await sessionStorage.getItem('administrator_email')
    console.log("getSession_Administrator_Email ====", getSession_Administrator_Email);
    if (getSession_Administrator_Email !== null) {
      await this.setState({
        administrator_email: getSession_Administrator_Email,
      })
    }

    getSession_Administrator_ID = await sessionStorage.getItem('administrator_id')
    console.log("getSession_Administrator_ID ====", getSession_Administrator_ID);
    if (getSession_Administrator_ID !== null) {
      await this.setState({
        administrator_id: getSession_Administrator_ID,
      })
    }
    

    getSession_Administrator_Name = await sessionStorage.getItem('administrator_name')
    console.log("getSession_Administrator_Name ====", getSession_Administrator_Name);
    if (getSession_Administrator_Name !== null) {
      await this.setState({
        administrator_name: getSession_Administrator_Name,
      })
    }
    

    getSession_kolInfo = await sessionStorage.getItem('kolInfo')
    console.log("getSession_kolInfo ====", getSession_kolInfo);
    if (getSession_kolInfo !== null) {
      console.log("getSession_kolInfo+++++ ====", getSession_kolInfo);
      let list_here = JSON.parse(getSession_kolInfo)
      list_here.map(async (data, index) => {
        console.log("item getSession_kolInfo",data)
        data.rank_local = index+1
      })
      console.log("list_here",list_here)

      await this.setState({
        kolInfo: list_here,
      });
      // await this.setState({
      //   kolInfo: JSON.parse(getSession_kolInfo),
      // });
    } else {
      await this.setState({
        kolInfo: [],
      });
    }

    let getSession_SearchInCetas = await sessionStorage.getItem('SearchInCetas')
    console.log("getSession_SearchInCetas ====", getSession_SearchInCetas);
    if (getSession_SearchInCetas !== null) {
      await this.setState({
        SearchInCetas: getSession_SearchInCetas,
      })
      console.log("Search in cetas", this.state.SearchInCetas);
    }
  }

  linkToDetails = (cell, row) => {
    var kol_id = row.kol_unique_id;
    console.log("Linktodetails");
    return (
      <div>
        <a style={{
          fontFamily: 'arial, sans-serif', color: '#069',
          textDecoration: 'underline', cursor: 'pointer'
        }}
          onClick={this.profileInfoNav.bind(this, kol_id)}> {row.kol_unique_id} </a>
      </div>
    )
  }

  linkToDetails2 = (cell, row) => {
    var kol_id = row.kol_unique_id;
    console.log("Linktodetails2")
    return (
      <div>
        <a style={{
          fontFamily: 'arial, sans-serif', color: '#069',
          textDecoration: 'underline', cursor: 'pointer'
        }}
          onClick={this.profileInfoNav.bind(this, kol_id)}> {row.doctor_full_name} </a>
      </div>
    )
  }

  RankLocalFormater = (cell, row) => {
    var kol_id = row.kol_unique_id;
    return (
      <div>
        <a style={{
          fontFamily: 'arial, sans-serif', color: '#8332a8',
        }}
        > 
          {row.rank_local} 
        </a>
      </div>
    )
  }

  RankGlobalFormater = (cell, row) => {
    var kol_id = row.kol_unique_id;
    return (
      <div>
        <a style={{
          fontFamily: 'arial, sans-serif', color: '#c33fcc',
        }}
        > 
          {row.rank} 
        </a>
      </div>
    )
  }

  profileInfoNav(kol_id) {
    sessionStorage.setItem('kol_id', kol_id);
    sessionStorage.setItem('administrator_name', this.state.administrator_name);
    sessionStorage.setItem('SearchInCetas', this.state.SearchInCetas);
    console.log('Navigation of SearchKolList', kol_id);
    this.setState({
      redirectAdministratorViewSearchKolList: true,
    })
  }

  // Funtion for displaying 'CV', 'Profile' & 'Bio' buttons inside Bootstrap Grid
  linkFollow = (cell, row, rowIndex, formatExtraData) => {
    console.log("in dowload resume linkFollow");
    return (
      <div>
        {console.log("in dowload bio linkFollow row.bio_pdf", row.bio_pdf)}
        {
          (row.bio_pdf !== null) ? (
            <a
              href={row.bio_pdf} target="_blank">
              <button className="cetas-button cetas-button-small mr-2">
                Bio
              </button>
            </a>
          ) : (
            <div></div>
          )
        }
        {console.log("in dowload profile linkFollow row.profile_pdf", row.profile_pdf)}
        {
          (row.profile_pdf !== null) ? (
            <a
              href={row.profile_pdf} target="_blank">
              <button className="cetas-button cetas-button-small mr-2">
                Profile
              </button>
            </a>
          ) : (
            <div></div>
          )
        }
        {console.log("in dowload resume linkFollow row.resume", row.resume)}
        {
          (row.resume !== null) ? (
            <a
              href={row.resume} target="_blank">
              <button className="cetas-button cetas-button-small mr-2">
                CV
              </button>
            </a>
          ) : (
            <div></div>
          )
        }
      </div>
    );
  };

  BackToDashboard() {
    this.setState({ RedirectFlag: true })
  }

  BackToClientProject() {
    this.setState({ RedirectAdministratorProject: true })
  }

  render() {
    if (this.state.redirect) {
      return (<Redirect to={'/'} />)
    }
    if (this.state.RedirectAdministratorProject) {
      return (<Redirect to={{
        pathname: '/AdministratorViewProject',
      }} />)
    }
    if (this.state.redirectAdministratorViewSearchKolList) {
      return (<Redirect to={{
        pathname: '/AdministratorViewSearchKolList',
      }} />)
    }

    // For rendering pageination buttons
    const pageButtonRenderer = ({
      page,
      active,
      disable,
      title,
      onPageChange
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        onPageChange(page);
      };
      return (
        <li class="page-item" onClick={handleClick} ><a class="page-link" href="#">{page}</a></li>
      );
    };

    // options for passing values to bootstrap table 2
    const options = {
      pageButtonRenderer,
      paginationSize: 5,
      pageStartIndex: 1,
      // alwaysShowAllBtns: true, // Always show next and previous button
      // withFirstAndLast: false, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      // firstPageText: 'First',
      prePageText: 'Previous',
      nextPageText: 'Next',
      // lastPageText: 'Last',
      // nextPageTitle: 'First page',
      // prePageTitle: 'Pre page',
      // firstPageTitle: 'Next page',
      // lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '50', value: 50
      }, {
        text: '100', value: 100
      }, {
        text: '200', value: 200
      }, {
        text: 'All', value: this.state.kolInfo.length
      }]// A numeric array is also available. the purpose of above example is custom the text
    };

    const MyExportCSV = (props) => {
      const handleClick = () => {
        props.onExport();
      };
      return (
        <div>
          <button class="cetas-button" onClick={handleClick}>Export</button>
        </div>
      );
    };

    return (
      <div>
        {this.state.ShowBar ? <Modal isOpen={true} centered>
          <PropagateLoader color={'#A90007'} size='30px' loading='true' />
        </Modal> : null}
        {this.state.progressBar ? <div style={{ position: 'fixed', top: '0' }}>
          <LoadingBar
            progress='100'
            height={3} color="#A90007" transitionTime={4000}
            loaderSpeed={3000} />
        </div> : null}

        <div>
          <Header ClientName={this.state.administrator_name} />
        </div>

        <div className="container">
          <div className="content-wrapper">
            <div class="screen-headingbox">
              <div className="back-section">
                <a onClick={this.BackToClientProject.bind(this)}>
                  <img src={require('../Assets/Images/back-arrow.svg')} />
                  Back
                </a>
              </div>
              {/* <h2>Profile Details </h2> */}
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12 emplist-box">
                <div className="row">
                  <div className="col-md-12">
                    <h3><b>KOL List</b></h3>
                    <div className="employee-actions">
                      <div className="table-infobox">
                        <p className="list-count">{this.state.kolInfo.length} KOL Listed</p>
                      </div>
                    </div>

                    {(this.state.SearchInCetas === 'false') ?
                      (
                        <div>
                          <ToolkitProvider
                            keyField="kol_unique_id"
                            data={this.state.kolInfo}
                            columns={this.state.columns2}
                            // search={this.customMatchFunc}
                            //exportCSV
                            exportCSV={{
                              fileName: 'cetas.csv',
                              noAutoBOM: false,
                              onlyExportSelection: true,
                              exportAll: true
                            }}
                          >
                            {
                              props => (
                                <div>
                                  <BootstrapTable
                                    keyField='kol_unique_id'
                                    data={this.state.kolInfo}
                                    columns={this.state.columns2}
                                    // filter={filterFactory()}
                                    // selectRow={ selectRow } 
                                    pagination={paginationFactory(options)}
                                    striped
                                    hover
                                    condensed
                                    tabIndexCell
                                  />
                                  <hr></hr>
                                  {/* <ExportCSVButton  class="cetas-button" { ...props.csvProps }>Export</ExportCSVButton> */}
                                  {/* <MyExportCSV {...props.csvProps} /> */}
                                </div>
                              )
                            }
                          </ToolkitProvider>
                        </div>
                      )
                      :
                      (
                        <div>
                          <ToolkitProvider
                            keyField="kol_unique_id"
                            data={this.state.kolInfo}
                            columns={this.state.columns}
                          // search={this.customMatchFunc}
                          >
                            {
                              props => (
                                <div>
                                  <BootstrapTable
                                    keyField='kol_unique_id'
                                    data={this.state.kolInfo}
                                    columns={this.state.columns}
                                    // filter={filterFactory()}
                                    // selectRow={ selectRow } 
                                    pagination={paginationFactory(options)}
                                    striped
                                    hover
                                    condensed
                                    tabIndexCell
                                  />
                                  <hr></hr>
                                </div>
                              )
                            }
                          </ToolkitProvider>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default AdministratorSearchInCetasDb;