import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button } from 'reactstrap';
import axios from 'axios';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {
  textFilter, customFilter, selectFilter, Comparator,
  FILTER_TYPES
} from 'react-bootstrap-table2-filter';
import Swal from 'sweetalert2';
import LoadingBar from "react-top-loading-bar";
import { RingLoader, FadeLoader, DotLoader, PropagateLoader } from 'react-spinners';

import AdminHeader from './AdminHeader';

class FormManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ShowProcedure: false,
      ShowBar: false,
      procedureFlag: false,
      EngagementTypeFlag: false,
      PublicationPositionFlag: false,
      EventRolesFlag: false,
      CTRolesFlag: false,
      ParticipantsFlag: false,
      SpecialityFlag: true,
      SubSpecialityFlag: false,

      // Set Procedure List Title on Table of procedure and sub speciality
      ProcedureTypeName: '',
      SubSpecialityTypeName: '',

      // this unique id is for add update and delete operations of procedure and sub speciality
      speciality_type_id: '',
      sub_speciality_type_id: '',

      // // this name and type is to get tables for dropdown
      // speciality_type_name: '',
      // sub_speciality_type_name: '',

      // To store response from api
      SpecialityList: [],
      ProcedureList: [],
      SubSpecialityList: [],

      // EngagementTypeList: [],
      // PublicationList: [],
      // EventRolesList: [],
      // CTList: [],
      // PartList: [],

      // State for displaying data
      statusEngagement: '',
      statusPublication: '',
      statusEvent: '',
      statusCT: '',
      statusPart: '',

      // To toggle Add modals
      toggleProcedureAddModal: false,
      addSpecialityModal: false,
      addSubSpecialityModal: false,

      isAddEngagement: false,
      isAddPublication: false,
      isAddEvent: false,
      isAddCT: false,
      isAddPart: false,


      // to pass data from add modals
      newDataProcedure: {
        procedure_type: '',
      },

      newDataSpeciality: {
        speciality_type: '',
      },

      newDataSubSpeciality: {
        sub_speciality_type: '',
      },

      addDataEngagement: {
        id: '',
        engagement_type: '',
      },

      addDataPublication: {
        id: '',
        publication_position_type: '',
      },

      addDataEvent: {
        id: '',
        event_role_type: '',
      },

      addDataCT: {
        id: '',
        ct_role_type: '',
      },

      addDataPart: {
        id: '',
        no_of_participants: '',
      },

      // For validations of add modal
      ProcedureAddErr: '',
      SpecialityAddErr: '',
      SubSpecialityAddErr: '',

      typeErrorEngagement: '',
      typeErrorPublication: '',
      typeErrorEvent: '',
      typeErrorCT: '',
      typeErrorPart: '',

      //  To toggle Edit modals
      editProcedureModal: false,
      editSpecialityModal: false,
      editSubSpecialityModal: false,

      isEditEngagement: false,
      isEditPublication: false,
      isEditEvent: false,
      isEditCT: false,
      isEditPart: false,

      // to pass data from edit modals
      editProcedureData: {
        id: '',
        procedure_type: '',
      },

      editSpecialityData: {
        id: '',
        speciality_type: '',
      },

      editSubSpecialityData: {
        id: '',
        sub_speciality_type: '',
      },

      editDataEngagement: {
        id: '',
        engagement_type: '',
      },

      editDataPublication: {
        id: '',
        publication_position_type: '',
      },

      editDataEvent: {
        id: '',
        event_role_type: '',
      },

      editDataCT: {
        id: '',
        ct_role_type: '',
      },

      editDataPart: {
        id: '',
        no_of_participants: '',
      },

      // For validations of edit modal
      ProcedureEditErr: false,
      SpecialityEditErr: false,
      SubSpecialityEditErr: false,

      onItemFilter: null,

      columns: [
        // {
        //     dataField: 'id' ,
        //     text: 'Sr. No.',
        //     align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //     return { width: '20%', textAlign: 'center' };
        //     },
        // },
        {
          dataField: 'procedure_type',
          text: 'Name',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '60%', textAlign: 'center' };
          },
          filter: textFilter(),

          filterRenderer: (onFilter, column) => {
            this.onItemFilter = onFilter;
            return null;
          },
        },
        {
          dataField: "Actions",
          text: "Actions",
          formatter: this.linkFollow,
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '30%', textAlign: 'center' };
          },
        },
      ],

      columnsEngagement: [
        {
          dataField: 'id',
          text: 'ID',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '20%', textAlign: 'center' };
          },
        },
        {
          dataField: 'engagement_type',
          text: 'Engagement Type',
          //sort: true,
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '60%', textAlign: 'center' };
          },
          filter: textFilter(),
          filterRenderer: (onFilter, column) => {
            this.onselectFilterEngagement = onFilter;
            return null;
          },
          filterRenderer: (onFilter, column) => {
            this.onsearchFilterEngagement = onFilter;
            return null;
          },
        },
        {
          dataField: "Actions",
          text: "Actions",
          formatter: this.actionEngagement,
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '30%', textAlign: 'center' };
          },
        }
      ],
      columnsPublication: [
        {
          dataField: 'id',
          text: 'ID',
          // sort: true,
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '20%', textAlign: 'center' };
          },
        },
        {
          dataField: 'publication_position_type',
          text: 'Position Authorship',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '60%', textAlign: 'center' };
          },
          filter: textFilter(),

          filterRenderer1: (onFilter, column) => {
            this.onselectFilter = onFilter;
            return null;
          },

          filterRenderer: (onFilter, column) => {
            this.onsearchFilterPublication = onFilter;
            return null;
          },
        },
        {
          dataField: "Actions",
          text: "Actions",
          formatter: this.actionPublication,
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '30%', textAlign: 'center' };
          },
        }
      ],
      columnsEvent: [
        {
          dataField: 'id',
          text: 'ID',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '20%', textAlign: 'center' };
          },
        },
        {
          dataField: 'event_role_type',
          text: 'Event Roles',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '60%', textAlign: 'center' };
          },
          filter: textFilter(),

          filterRenderer: (onFilter, column) => {
            this.onsearchFilterEvent = onFilter;
            return null;
          },
        },
        {
          dataField: "Actions",
          text: "Actions",
          formatter: this.actionEvent,
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '30%', textAlign: 'center' };
          },
        }
      ],
      columnsCT: [
        {
          dataField: 'id',
          text: 'ID',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '20%', textAlign: 'center' };
          },
        },
        {
          dataField: 'ct_role_type',
          text: 'CT Role',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '60%', textAlign: 'center' };
          },
          filter: textFilter(),

          filterRenderer: (onFilter, column) => {
            this.onsearchFilterCT = onFilter;
            return null;
          },

        },
        {
          dataField: "Actions",
          text: "Actions",
          formatter: this.actionCT,
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '30%', textAlign: 'center' };
          },

        }
      ],
      columnsPart: [
        {
          dataField: 'id',
          text: 'ID',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '20%', textAlign: 'center' };
          },
        },
        {
          dataField: 'no_of_participants',
          text: 'No of Participants',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '60%', textAlign: 'center' };
          },
          filter: textFilter(),

          filterRenderer1: (onFilter, column) => {
            this.onselectFilter = onFilter;
            return null;
          },

          filterRenderer: (onFilter, column) => {
            this.onsearchFilterPart = onFilter;
            return null;
          },
        },
        {
          dataField: "Actions",
          text: "Actions",
          formatter: this.actionPart,
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '30%', textAlign: 'center' };
          },
        }
      ],

      columnsSpeciality: [
        // {
        //     dataField: 'id',
        //     text: 'ID',
        //     align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //     return { width: '20%', textAlign: 'center' };
        //     },
        // },
        {
          dataField: 'speciality_type',
          text: 'Speciality Type',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '60%', textAlign: 'center' };
          },
          filter: textFilter(),

          filterRenderer: (onFilter, column) => {
            this.onItemFilter = onFilter;
            return null;
          },

        },
        {
          dataField: "Actions",
          text: "Actions",
          formatter: this.actionSpeciality,
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '30%', textAlign: 'center' };
          },
        }
      ],
      columnsSubSpeciality: [
        // {
        //     dataField: 'id',
        //     text: 'ID',
        //     align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //     return { width: '20%', textAlign: 'center' };
        //     },
        // },
        {
          dataField: 'sub_speciality_type',
          text: 'Sub Speciality Type',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '60%', textAlign: 'center' };
          },
          filter: textFilter(),

          filterRenderer: (onFilter, column) => {
            this.onItemFilter = onFilter;
            return null;
          },

        },
        {
          dataField: "Actions",
          text: "Actions",
          formatter: this.actionSubSpeciality,
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '30%', textAlign: 'center' };
          },
        }
      ],

    };
    this.toggleaddEngagementModal = this.toggleaddEngagementModal.bind(this);
    this.EditEngagementType = this.EditEngagementType.bind(this);
    this.toggleaddPublicationModal = this.toggleaddPublicationModal.bind(this);
    this.EditPublicationType = this.EditPublicationType.bind(this);
    this.toggleaddEventModal = this.toggleaddEventModal.bind(this);
    this.EditEventType = this.EditEventType.bind(this);
    this.toggleaddCTModal = this.toggleaddCTModal.bind(this);
    this.EditTypeCT = this.EditTypeCT.bind(this);
    this.toggleaddPartModal = this.toggleaddPartModal.bind(this);
    this.EditTypePart = this.EditTypePart.bind(this);
  }

  // for dropdown (toggle) of Procedure Types
  procedureType = async () => {
    await this.setState({
      procedureFlag: !this.state.procedureFlag,
      CTRolesFlag: false,
      EngagementTypeFlag: false,
      PublicationPositionFlag: false,
      EventRolesFlag: false,
      CTRolesFlag: false,
      SpecialityFlag: false,
      SubSpecialityFlag: false,
      ParticipantsFlag: false,
    });
    console.log("this.state.procedureFlag", this.state.procedureFlag)
  }

  EngagementType = async () => {
    await this.setState({
      procedureFlag: false,
      PublicationPositionFlag: false,
      EventRolesFlag: false,
      CTRolesFlag: false,
      ParticipantsFlag: false,
      SpecialityFlag: false,
      SubSpecialityFlag: false,
      EngagementTypeFlag: true,
    });
  }

  PublicationPosition = async () => {
    await this.setState({
      procedureFlag: false,
      EngagementTypeFlag: false,
      EventRolesFlag: false,
      CTRolesFlag: false,
      ParticipantsFlag: false,
      SpecialityFlag: false,
      SubSpecialityFlag: false,
      PublicationPositionFlag: true,
    });
  }

  EventRoles = async () => {
    await this.setState({
      procedureFlag: false,
      EngagementTypeFlag: false,
      PublicationPositionFlag: false,
      CTRolesFlag: false,
      ParticipantsFlag: false,
      SpecialityFlag: false,
      SubSpecialityFlag: false,
      EventRolesFlag: true,
    });
  }

  CTRoles = async () => {
    await this.setState({
      procedureFlag: false,
      EngagementTypeFlag: false,
      PublicationPositionFlag: false,
      EventRolesFlag: false,
      ParticipantsFlag: false,
      SpecialityFlag: false,
      SubSpecialityFlag: false,
      CTRolesFlag: true,
    });
  }

  Participants = async () => {
    await this.setState({
      procedureFlag: false,
      EngagementTypeFlag: false,
      PublicationPositionFlag: false,
      EventRolesFlag: false,
      CTRolesFlag: false,
      SpecialityFlag: false,
      SubSpecialityFlag: false,
      ParticipantsFlag: true,
    });
  }

  Speciality = async () => {
    await this.setState({
      procedureFlag: false,
      EngagementTypeFlag: false,
      PublicationPositionFlag: false,
      EventRolesFlag: false,
      CTRolesFlag: false,
      ParticipantsFlag: false,
      SubSpecialityFlag: false,
      SpecialityFlag: true,
    });
  }

  SubSpeciality = async () => {
    await this.setState({
      procedureFlag: false,
      EngagementTypeFlag: false,
      PublicationPositionFlag: false,
      EventRolesFlag: false,
      CTRolesFlag: false,
      ParticipantsFlag: false,
      SpecialityFlag: false,
      SubSpecialityFlag: !this.state.SubSpecialityFlag,
    });
  }

  ProcedureItemSelectedFunc = async (event) => {
    console.log("list item selected", event.target);
    console.log("list item selected id", event.target.value);
    console.log("list item selected name", event.target.type);

    await this.setState({
      ProcedureTypeName: event.target.type,
      speciality_type_id: event.target.value,
    });
    this.fetchProcedureData();
  }

  SubSpecialityItemSelectedFunc = async (event) => {
    console.log("list item selected", event.target);
    console.log("list item selected id", event.target.value);
    console.log("list item selected name", event.target.type);

    await this.setState({
      SubSpecialityTypeName: event.target.type,
      sub_speciality_type_id: event.target.value,
    });
    this.fetchSubSpecialityData();

  }

  // to fetch table data for Procedure
  fetchProcedureData() {
    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
    let formData = new FormData();
    formData.append('speciality_type_id', this.state.speciality_type_id);
    formData.append('search_type', "Procedure");

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var lgdata = response.data;
        console.log("lgdata", JSON.stringify(lgdata));
        var ProcedureStatus = response.data.status;
        console.log("ProcedureStatus", JSON.stringify(ProcedureStatus));
        if (ProcedureStatus == false) {
          Swal.fire({
            icon: 'error',
            title: 'No data to show for this Category',
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }
        else {
          this.setState({
            ProcedureList: response.data.data,
          })
          console.log("ProcedureList", this.state.ProcedureList)
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  // To fetch Data from sub speciality type tables
  fetchSubSpecialityData() {
    console.log(" in sub speciality Func");
    console.log("sub speciality id", this.state.sub_speciality_type_id);

    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
    let formData = new FormData();
    formData.append('speciality_type_id', this.state.sub_speciality_type_id);
    formData.append('search_type', "Sub Speciality");

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var subSpecialitydata = response.data;
        console.log("subSpecialitydata", JSON.stringify(subSpecialitydata));
        var SpecialityStatus = response.data.status;
        console.log("SpecialityStatus", JSON.stringify(SpecialityStatus));
        if (SpecialityStatus == false) {
          Swal.fire({
            icon: 'error',
            title: 'No data to show for this Category',
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }
        else {
          this.setState({
            SubSpecialityList: response.data.data,
          })
          console.log("SubSpecialityList", this.state.SubSpecialityList)
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  // priya has define Cardio thoracic Surgeon at id CA005 i dont get any tble of this name

  // 
  // Procedure type all functions
  // Funtion for displaying 'Edit' & 'delete' buttons inside Bootstrap Grid
  linkFollow = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button class="cetas-button cetas-button-small"
          onClick={this.editProcedure.bind(this, row.id, row.procedure_type,)}>
          Edit
        </button>
        &nbsp;&nbsp;
        <button class="cetas-secondry-button cetas-button-small"
          onClick={this.deleteProcedure.bind(this, row.id)}>
          Delete
        </button>
      </div>
    );
  };

  toggleProcedureAdd() {
    this.setState({
      toggleProcedureAddModal: !this.state.toggleProcedureAddModal,
      ProcedureAddErr: '',
    });
  }

  toggleeditProcedureModal() {
    this.setState({
      editProcedureModal: !this.state.editProcedureModal,
      ProcedureEditErr: '',
    });
  }

  editProcedure(id, procedure_type) {
    this.setState({
      editProcedureData: { id, procedure_type },
      editProcedureModal: !this.state.editProcedureModal
    });
  }

  validateIntOncAdd = () => {
    let ProcedureAddErr = '';
    if (!this.state.newDataProcedure.procedure_type) {
      ProcedureAddErr = 'Please Enter Item name';
    }
    if (ProcedureAddErr) {
      this.setState({ ProcedureAddErr });
      return false;
    }
    return true;
  }

  procedureAdd() {
    const isValid = this.validateIntOncAdd();
    console.log("in add modal")
    console.log("speciality_id", this.state.speciality_type_id);
    console.log("procedure-type", this.state.newDataProcedure.procedure_type);

    if (isValid) {
      this.setState({
        ShowProcedure: true,
      })
      const url = global.websiteUrl+'/service/Procedure_Speciality/procedure_speciality_curd';

      let formData = new FormData();
      formData.append('oper', "add");
      formData.append('procedure_type', this.state.newDataProcedure.procedure_type);
      formData.append('speciality_id', this.state.speciality_type_id);

      const config = {
        // headers: { 'content-type': 'multipart/form-data' }
      }

      axios.post(url, formData, config)
        .then(response => {
          console.log(response.data);
          this._refreshDropdownTable();
          this.setState({
            ShowProcedure: false,
          })
          if (response.data.status == true) {
            Swal.fire({
              icon: 'success',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
          else {
            Swal.fire({
              icon: 'error',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
        })
        .catch(error => {
          console.log(error);
        });

      this.setState({
        toggleProcedureAddModal: false, newDataProcedure: {
          procedure_type: '',
        }
      });
    }
  }

  _refreshDropdownTable() {
    console.log("in refresh");
    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
    let formData = new FormData();
    // formData.append('speciality_type_name',this.state.speciality_type_name);
    // formData.append('search_type', this.state.search_type);

    formData.append('speciality_type_id', this.state.speciality_type_id);
    formData.append('search_type', "Procedure");

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var lgdata = response.data.data;
        console.log("lgdata", JSON.stringify(lgdata));
        this.setState({
          ProcedureList: lgdata,
        })
        console.log("ProcedureList", this.state.ProcedureList)
      })
      .catch(error => {
        console.log(error);
      });
  }

  validateIntOncEdit = () => {
    let ProcedureEditErr = '';
    if (!this.state.editProcedureData.procedure_type) {
      ProcedureEditErr = 'Please Enter Item name';
    }
    if (ProcedureEditErr) {
      this.setState({ ProcedureEditErr });
      return false;
    }
    return true;
  }

  updateProcedure() {
    const isValid = this.validateIntOncEdit();
    if (isValid) {
      this.setState({
        ShowProcedure: true,
      })
      console.log("in edit modal")
      console.log("speciality_unique_id", this.state.speciality_type_id);
      console.log("procedure-type id", this.state.editProcedureData.id);
      console.log("procedure-type data to edit", this.state.editProcedureData.procedure_type);
      if (isValid) {
        const url = global.websiteUrl+'/service/Procedure_Speciality/procedure_speciality_curd';

        let formData = new FormData();
        formData.append('oper', "edit");
        formData.append('procedure_type', this.state.editProcedureData.procedure_type);
        formData.append('id', this.state.editProcedureData.id);
        formData.append('speciality_id', this.state.speciality_type_id);

        const config = {
          // headers: { 'content-type': 'multipart/form-data' }
        }

        axios.post(url, formData, config)
          .then(response => {
            console.log(response.data);
            this._refreshDropdownTable();
            this.setState({
              ShowProcedure: false,
            })
            if (response.data.status == true) {
              Swal.fire({
                icon: 'success',
                text: response.data.message,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            }
            else {
              Swal.fire({
                icon: 'error',
                text: response.data.message,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            }
          })
          .catch(error => {
            console.log(error);
          });

        this.setState({
          editProcedureModal: false, editProcedureData: {
            id: '',
            procedure_type: '',
          }
        });
      }
    }
  }

  deleteProcedure(id) {
    console.log(id);
    let idtoDelete = JSON.parse(id);
    const url = global.websiteUrl+'/service/Procedure_Speciality/procedure_speciality_curd';

    let formData = new FormData();
    formData.append('oper', "del");
    formData.append('id', idtoDelete);
    formData.append('speciality_id', this.state.speciality_type_id);

    const config = {
      // headers: { 'content-type': 'multipart/form-data' }
    }

    axios.post(url, formData, config)
      .then(response => {
        console.log(response.data);
        this._refreshDropdownTable();
        if (response.data.status == true) {
          Swal.fire({
            icon: 'success',
            text: response.data.message,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }
        else {
          Swal.fire({
            icon: 'error',
            text: response.data.message,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  onItemChange = (event) => {
    const { value } = event.target;
    this.onItemFilter(value);
  };

  //
  // Speciality Type All Functions
  toggleaddSpecialityModal = () => {
    this.setState({
      addSpecialityModal: !this.state.addSpecialityModal,
      SpecialityAddErr: '',
    });
  }

  actionSpeciality = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button class="cetas-button cetas-button-small"
          onClick={this.editSpeciality.bind(this, row.id, row.speciality_type,)}>
          Edit
        </button>
        &nbsp;&nbsp;
        <button class="cetas-secondry-button cetas-button-small"
          onClick={this.deleteSpeciality.bind(this, row.id)}>
          Delete
        </button>
      </div>
    );
  };

  toggleeditSpecialityModal() {
    this.setState({
      editSpecialityModal: !this.state.editSpecialityModal,
      SpecialityEditErr: '',
    });
  }

  editSpeciality(id, speciality_type) {
    this.setState({
      editSpecialityData: { id, speciality_type },
      editSpecialityModal: !this.state.editSpecialityModal
    });
  }

  validateInAddSpeciality = () => {
    let SpecialityAddErr = '';
    if (!this.state.newDataSpeciality.speciality_type) {
      SpecialityAddErr = 'Please Enter Item name';
    }
    if (SpecialityAddErr) {
      this.setState({ SpecialityAddErr });
      return false;
    }
    return true;
  }

  specialityAdd = () => {
    const isValid = this.validateInAddSpeciality();
    if (isValid) {
      this.setState({
        ShowProcedure: true,
      })
      const url = global.websiteUrl+'/service/Speciality/speciality_curd';

      let formData = new FormData();
      formData.append('oper', "add");
      formData.append('speciality_type', this.state.newDataSpeciality.speciality_type);

      const config = {
        // headers: { 'content-type': 'multipart/form-data' }
      }

      axios.post(url, formData, config)
        .then(response => {
          console.log(response.data);
          this._refreshSpecialityTable();
          this.setState({
            ShowProcedure: false,
          })
          if (response.data.status == true) {
            Swal.fire({
              icon: 'success',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
          else {
            Swal.fire({
              icon: 'error',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
        })
        .catch(error => {
          console.log(error);
        });

      this.setState({
        addSpecialityModal: false, newDataSpeciality: {
          speciality_type: '',
        }
      });

    }
  }

  validateSpecialityEdit = () => {
    let SpecialityEditErr = '';
    if (!this.state.editSpecialityData.speciality_type) {
      SpecialityEditErr = 'Please Enter Item name';
    }
    if (SpecialityEditErr) {
      this.setState({ SpecialityEditErr });
      return false;
    }
    return true;
  }

  updateSpeciality() {
    const isValid = this.validateSpecialityEdit();
    if (isValid) {
      this.setState({
        ShowProcedure: true,
      })
      console.log("in edit modal")
      console.log("speciality_type", this.state.editSpecialityData.speciality_type);
      if (isValid) {
        const url = global.websiteUrl+'/service/Speciality/speciality_curd';

        let formData = new FormData();
        formData.append('oper', "edit");
        formData.append('id', this.state.editSpecialityData.id);
        formData.append('speciality_type', this.state.editSpecialityData.speciality_type);

        const config = {
          // headers: { 'content-type': 'multipart/form-data' }
        }

        axios.post(url, formData, config)
          .then(response => {
            console.log(response.data);
            this._refreshSpecialityTable();
            this.setState({
              ShowProcedure: false,
            })
            if (response.data.status == true) {
              Swal.fire({
                icon: 'success',
                text: response.data.message,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            }
            else {
              Swal.fire({
                icon: 'error',
                text: response.data.message,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            }
          })
          .catch(error => {
            console.log(error);
          });

        this.setState({
          editSpecialityModal: false, editSpecialityData: {
            id: '',
            speciality_type: '',
          }
        });
      }
    }
  }

  deleteSpeciality(id) {
    console.log(id);
    let idtoDelete = JSON.parse(id);

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success ',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then((result) => {

      if (result.value) {
        this.setState({
          ShowProcedure: true,
        })
        const url = global.websiteUrl+'/service/Speciality/speciality_curd';

        let formData = new FormData();
        formData.append('oper', "del");
        formData.append('id', idtoDelete);

        const config = {
          // headers: { 'content-type': 'multipart/form-data' }
        }

        axios.post(url, formData, config)
          .then(response => {
            console.log(response.data);

            this._refreshSpecialityTable();
            this.setState({
              ShowProcedure: false,
            })
            // if(response.data.status == true)
            // {
            //   Swal.fire({
            //     icon: 'success',
            //     text: response.data.message,
            //     confirmButtonColor: '#d33',
            //     confirmButtonText: 'OK'
            //   })
            // }
            // else{
            //   Swal.fire({
            //     icon: 'error',
            //     text: response.data.message,
            //     confirmButtonColor: '#d33',
            //     confirmButtonText: 'OK'
            //   })
            // }
          })
          .catch(error => {
            console.log(error);
          });

      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        // swalWithBootstrapButtons.fire(
        //   'Cancelled',
        //   'Your imaginary file is safe :)',
        //   'error'
        // )
      }
    })
  }

  _refreshSpecialityTable = () => {
    const headers = new Headers()

    const options = {
      method: 'POST',
    }

    fetch(global.websiteUrl+'/service/Speciality/get_SpecialityList', options)
      .then((Response) => Response.json())
      .then((findresponse) => {
        console.log(findresponse)
        var testDataSpecialityList = findresponse.data;
        console.log("testDataSpecialityList", JSON.stringify(testDataSpecialityList));
        this.setState({
          SpecialityList: findresponse.data,
        })
      })
  }

  // 
  // Sub Speciality All functions
  toggleaddSubSpecialityModal = () => {
    this.setState({
      addSubSpecialityModal: !this.state.addSubSpecialityModal,
      SubSpecialityAddErr: '',
    })
  }

  validateInAddSubSpeciality = () => {
    let SubSpecialityAddErr = '';
    if (!this.state.newDataSubSpeciality.sub_speciality_type) {
      SubSpecialityAddErr = 'Please Enter Item name';
    }
    if (SubSpecialityAddErr) {
      this.setState({ SubSpecialityAddErr });
      return false;
    }
    return true;
  }

  subSpecialityAdd() {
    const isValid = this.validateInAddSubSpeciality();
    if (isValid) {
      this.setState({
        ShowProcedure: true,
      })
      const url = global.websiteUrl+'/service/Sub_Speciality/sub_speciality_curd';

      let formData = new FormData();
      formData.append('oper', "add");
      formData.append('speciality_id', this.state.sub_speciality_type_id);
      formData.append('sub_speciality_type', this.state.newDataSubSpeciality.sub_speciality_type);

      const config = {
        // headers: { 'content-type': 'multipart/form-data' }
      }

      axios.post(url, formData, config)
        .then(response => {
          console.log(response.data);
          this._refreshSubSpeciality();
          this.setState({
            ShowProcedure: false,
          })
          if (response.data.status == true) {
            Swal.fire({
              icon: 'success',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
          else {
            Swal.fire({
              icon: 'error',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
        })
        .catch(error => {
          console.log(error);
        });

      this.setState({
        addSubSpecialityModal: false, newDataSubSpeciality: {
          sub_speciality_type: '',
        }
      });
    }
  }

  actionSubSpeciality = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button class="cetas-button cetas-button-small"
          onClick={this.editSubSpeciality.bind(this, row.id, row.sub_speciality_type,)}>
          Edit
        </button>
        &nbsp;&nbsp;
        <button class="cetas-secondry-button cetas-button-small"
          onClick={this.deleteSubSpeciality.bind(this, row.id)}>
          Delete
        </button>
      </div>
    );
  };

  editSubSpeciality(id, sub_speciality_type) {
    this.setState({
      editSubSpecialityData: { id, sub_speciality_type },
      editSubSpecialityModal: !this.state.editSubSpecialityModal
    });
  }

  toggleeditSubSpecialityModal() {
    this.setState({
      editSubSpecialityModal: !this.state.editSubSpecialityModal,
      SubSpecialityEditErr: '',
    });
  }

  validateSubSpecialityEdit = () => {
    let SubSpecialityEditErr = '';
    if (!this.state.editSubSpecialityData.sub_speciality_type) {
      SubSpecialityEditErr = 'Please Enter Item name';
    }
    if (SubSpecialityEditErr) {
      this.setState({ SubSpecialityEditErr });
      return false;
    }
    return true;
  }

  updateSubSpeciality() {
    const isValid = this.validateSubSpecialityEdit();
    if (isValid) {
      this.setState({
        ShowProcedure: true,
      })
      console.log("in edit modal")
      console.log("sub_speciality_type", this.state.editSubSpecialityData.sub_speciality_type);
      console.log("id", this.state.editSubSpecialityData.id);
      if (isValid) {
        const url = global.websiteUrl+'/service/Sub_Speciality/sub_speciality_curd';

        let formData = new FormData();
        formData.append('oper', "edit");
        formData.append('id', this.state.editSubSpecialityData.id);
        formData.append('sub_speciality_type', this.state.editSubSpecialityData.sub_speciality_type);
        formData.append('speciality_id', this.state.sub_speciality_type_id)

        const config = {
          // headers: { 'content-type': 'multipart/form-data' }
        }

        axios.post(url, formData, config)
          .then(response => {
            console.log(response.data);
            this._refreshSubSpeciality();
            this.setState({
              ShowProcedure: false,
            })
            if (response.data.status == true) {
              Swal.fire({
                icon: 'success',
                text: response.data.message,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            }
            else {
              Swal.fire({
                icon: 'error',
                text: response.data.message,
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
              })
            }
          })
          .catch(error => {
            console.log(error);
          });

        this.setState({
          editSubSpecialityModal: false, editSubSpecialityData: {
            id: '',
            sub_speciality_type: '',
          }
        });
      }
    }
  }

  deleteSubSpeciality(id) {
    console.log(id);
    let idtoDelete = JSON.parse(id);
    const url = global.websiteUrl+'/service/Sub_Speciality/sub_speciality_curd';

    let formData = new FormData();
    formData.append('oper', "del");
    formData.append('id', idtoDelete);

    const config = {
      // headers: { 'content-type': 'multipart/form-data' }
    }

    axios.post(url, formData, config)
      .then(response => {
        console.log(response.data);
        this._refreshSubSpeciality();
        if (response.data.status == true) {
          Swal.fire({
            icon: 'success',
            text: response.data.message,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }
        else {
          Swal.fire({
            icon: 'error',
            text: response.data.message,
            confirmButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  _refreshSubSpeciality() {
    console.log("in refresh of sub specialty");
    const url = global.websiteUrl+'/service/Procedure_Speciality/get_Pro_Sub_SpecialityList';
    let formData = new FormData();
    formData.append('speciality_type_id', this.state.sub_speciality_type_id);
    formData.append('search_type', "Sub Speciality");

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var subSpecialitydata = response.data;
        console.log("subSpecialitydata", JSON.stringify(subSpecialitydata));
        var SpecialityStatus = response.data.status;
        console.log("SpecialityStatus", JSON.stringify(SpecialityStatus));
        this.setState({
          SubSpecialityList: response.data.data,
        })
      })
      .catch(error => {
        console.log(error);
      });
  }

  // 
  // Engagement type all functions starts from here
  componentDidMount() {
    this.setState({
      ShowBar: true,
    })
    const headers = new Headers()

    const options = {
      method: 'POST',
    }

    fetch(global.websiteUrl+'/service/Speciality/get_SpecialityList', options)
      .then((Response) => Response.json())
      .then((findresponse) => {
        console.log(findresponse)
        var testDataSpecialityList = findresponse.data;
        console.log("testDataSpecialityList", JSON.stringify(testDataSpecialityList));
        this.setState({
          SpecialityList: findresponse.data,
        })
        this.setState({
          ShowBar: false,
        })
      })

    // fetch(global.websiteUrl+'/service/EngagementType/get_EngagementTypeList', options)
    //   .then((Response) => Response.json())
    //   .then((findresponse) => {
    //     console.log(findresponse)
    //     var testDataEngagementList = findresponse.data;
    //     console.log("testDataEngagementList", JSON.stringify(testDataEngagementList));
    //     this.setState({
    //       EngagementTypeList: findresponse.data,
    //     })
    //   })

    // fetch(global.websiteUrl+'/service/Publication_Position/get_PublicationPositionList', options)
    //   .then((Response) => Response.json())
    //   .then((findresponse) => {
    //     console.log(findresponse)
    //     var testDataPubliactionPosition = findresponse.data;
    //     console.log("testDataPubliactionPosition", JSON.stringify(testDataPubliactionPosition));
    //     this.setState({
    //       PublicationList: findresponse.data,
    //     })
    // })

    // fetch(global.websiteUrl+'/service/EventRoles/get_EventRoleList', options)
    //   .then((Response) => Response.json())
    //   .then((findresponse) => {
    //     console.log(findresponse)
    //     var testDataEventRoles = findresponse.data;
    //     console.log("testDataEventRoles", JSON.stringify(testDataEventRoles));
    //     this.setState({
    //       EventRolesList: findresponse.data,
    //     })
    // })

    // fetch(global.websiteUrl+'/service/CT_Roles/get_CT_RoleList', options)
    //   .then((Response) => Response.json())
    //   .then((findresponse) => {
    //     console.log(findresponse)
    //     var testDataCTRoles = findresponse.data;
    //     console.log("testDataCTRoles", JSON.stringify(testDataCTRoles));
    //     this.setState({
    //       CTList: findresponse.data,
    //     })
    // })

    // fetch(global.websiteUrl+'/service/No_Of_Participants/get_no_of_ParticipantsList', options)
    //   .then((Response) => Response.json())
    //   .then((findresponse) => {
    //     console.log(findresponse)
    //     var testDataParticipants = findresponse.data;
    //     console.log("testDataParticipants", JSON.stringify(testDataParticipants));
    //     this.setState({
    //       PartList: findresponse.data,
    //     })
    // })
  }

  // STARNGE  
  onSearchEngagement = (event) => {
    const { value } = event.target;
    this.onsearchFilterEngagement(value);
  };

  // STRANGE
  onSelectEngagement = (event) => {
    const { value } = event.target;
    this.onselectFilterEngagement(value);
  };

  actionEngagement = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button class="cetas-button cetas-button-small"
          onClick={this.editEngagement.bind(this, row.id, row.engagement_type)}>
          Edit
        </button>
        &nbsp;&nbsp;
        <button class="cetas-secondry-button cetas-button-small"
          onClick={this.deleteEngagement.bind(this, row.id)}>
          Delete
        </button>
      </div>
    );
  };

  editEngagement(id, engagement_type) {
    this.setState({
      editDataEngagement: { id, engagement_type },
      isEditEngagement: !this.state.isEditEngagement,
      typeErrorEngagement: null
    })
  }

  EditEngagementType() {
    try {
      console.log(this.state.editDataEngagement.id)
      console.log(this.state.editDataEngagement.engagement_type)

      const url = global.websiteUrl+'/service/EngagementType/engagement_type_curd';
      let formData = new FormData();
      formData.append('id', this.state.editDataEngagement.id);
      formData.append('engagement_type', this.state.editDataEngagement.engagement_type);
      formData.append('oper', 'edit');

      const config = {
        //   headers: { 'content-type': 'multipart/form-data' }
      }

      axios.post(url, formData, config)
        .then(response => {
          var lgstatus = response.status;
          console.log(response);
          console.log("edit message", response.message);
          console.log("edit status", JSON.stringify(lgstatus));
          // alert(response.data.message)
          if (response.data.status == true) {
            Swal.fire({
              icon: 'success',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
          else {
            Swal.fire({
              icon: 'error',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
          this.setState({ statusEngagement: response.data.message })
          this.refreshEngagement();
        });

    } catch (e) {
      console.log(e);
    }
  }

  AddEngagementType() {
    try {

      const url = global.websiteUrl+'/service/EngagementType/engagement_type_curd';
      let formData = new FormData();
      formData.append('oper', 'add');
      // formData.append('id', this.state.addDataEngagement.id);
      formData.append('engagement_type', this.state.addDataEngagement.engagement_type);

      const config = {
        // headers: { 'content-type': 'multipart/form-data' }
      }

      axios.post(url, formData, config)
        .then(response => {
          console.log(response);
          var lgdata = response.data;
          console.log("lgdata", JSON.stringify(lgdata));
          var lgstatus = response.status;
          console.log("addlgstatus", JSON.stringify(lgstatus));
          this.refreshEngagement();
          if (response.data.status == true) {
            Swal.fire({
              icon: 'success',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
          else {
            Swal.fire({
              icon: 'error',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
          this.setState({
            statusEngagement: response.data.message,
            addDataEngagement: {
              engagement_type: null
            }
          });
        });
      this.setState({
        typeErrorEngagement: null,
      })
    } catch (e) {
      console.log(e);
    }
  }

  deleteEngagement(id) {
    console.log(id)
    try {
      console.log('ID will be deleted', id);
      const url = global.websiteUrl+'/service/EngagementType/engagement_type_curd';
      let formData = new FormData();
      formData.append('id', id);
      formData.append('oper', 'del');

      const config = {
        //   headers: { 'content-type': 'multipart/form-data' }
      }

      axios.post(url, formData, config)
        .then(response => {
          console.log(response);
          var lgdata = response.data;
          console.log("delete data", JSON.stringify(lgdata));
          var lgstatus = response.data.status;
          console.log("delete status", JSON.stringify(lgstatus));
          if (response.data.status == true) {
            Swal.fire({
              icon: 'success',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
          else {
            Swal.fire({
              icon: 'error',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
          this.setState({ statusEngagement: response.data.message })
          this.refreshEngagement();
        });
    } catch (e) {
      console.log(e);
    }
  }

  refreshEngagement() {
    try {
      console.log('I am in referesh category');
      const headers = new Headers()
      const options = {
        method: 'POST',
      }
      fetch(global.websiteUrl+'/service/EngagementType/get_EngagementTypeList', options)
        .then((Response) => Response.json())
        .then((findresponse) => {
          console.log(findresponse.data)
          this.setState({
            EngagementTypeList: findresponse.data,
            isAddEngagement: false,
            isEditEngagement: false,
          })
        })
    } catch (error) {
      console.log(error)
    }
  }

  // Function for toggling Add Form
  toggleaddEngagementModal() {
    this.setState({
      isAddEngagement: !this.state.isAddEngagement,
      typeErrorEngagement: null
    })
  }

  //Validations
  handleValidationAddEngagement() {
    let typeErrorEngagement = "";
    let formIsValid = true;
    //Type Field Error
    if (!this.state.addDataEngagement.engagement_type) {
      formIsValid = false;
      typeErrorEngagement = "Type field needed";

      if (typeErrorEngagement) {
        console.log(typeErrorEngagement)
        this.setState({ typeErrorEngagement })
      }
    }
    else {
      this.setState({ typeErrorEngagement: null })
    }
    console.log(formIsValid)
    return formIsValid;
  }

  handlesubmitAddEngagement(e) {
    e.preventDefault();

    if (this.handleValidationAddEngagement()) {
      this.AddEngagementType();
    } else {
      console.log('Form Has Errors')
    }
  }

  handleValidationEditEngagement() {
    let typeErrorEngagement = "";
    let formIsValid = true;
    //Tpe Field Error
    if (!this.state.editDataEngagement.engagement_type) {
      formIsValid = false;
      typeErrorEngagement = "Type field needed";

      if (typeErrorEngagement) {
        this.setState({ typeErrorEngagement })
      }
    }
    else {
      this.setState({ typeErrorEngagement: null })
    }
    console.log(formIsValid)
    return formIsValid;
  }

  handlesubmitEditEngagement(e) {
    e.preventDefault();
    if (this.handleValidationEditEngagement()) {
      this.EditEngagementType();
    } else {
      console.log('Form Has Errors')
    }
  }


  // publication functions starts here
  // STARNGE  
  onSearchPublication = (event) => {
    const { value } = event.target;
    this.onsearchFilterPublication(value);
  };

  actionPublication = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button class="cetas-button cetas-button-small"
          onClick={this.editPublication.bind(this, row.id, row.publication_position_type)}>
          Edit
        </button>
        &nbsp;&nbsp;
        <button class="cetas-secondry-button cetas-button-small"
          onClick={this.deletePublication.bind(this, row.id)}>
          Delete
        </button>
      </div>
    );
  };

  editPublication(id, publication_position_type) {
    this.setState({
      editDataPublication: { id, publication_position_type },
      isEditPublication: !this.state.isEditPublication,
      typeErrorPublication: null
    })
  }

  EditPublicationType() {
    try {
      console.log(this.state.editDataPublication.id)
      console.log(this.state.editDataPublication.engagement_type)

      const url = global.websiteUrl+'/service/Publication_Position/publication_position_curd';
      let formData = new FormData();
      formData.append('id', this.state.editDataPublication.id);
      formData.append('publication_position_type', this.state.editDataPublication.publication_position_type);
      formData.append('oper', 'edit');

      const config = {
        //   headers: { 'content-type': 'multipart/form-data' }
      }

      axios.post(url, formData, config)
        .then(response => {
          var lgstatus = response.statusPublication;
          console.log(response);
          console.log("edit message", response.data.message);
          console.log("edit status", JSON.stringify(lgstatus));
          if (response.data.status == true) {
            Swal.fire({
              icon: 'success',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
          else {
            Swal.fire({
              icon: 'error',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
          this.setState({ statusPublication: response.data.message })
          this.refreshPublication();
        });
    } catch (e) {
      console.log(e);
    }
  }

  AddTypePublication() {
    try {
      const url = global.websiteUrl+'/service/Publication_Position/publication_position_curd';
      let formData = new FormData();
      formData.append('oper', 'add');
      // formData.append('id', this.state.addDataPublication.id);
      formData.append('publication_position_type', this.state.addDataPublication.publication_position_type);

      const config = {
        // headers: { 'content-type': 'multipart/form-data' }
      }

      axios.post(url, formData, config)
        .then(response => {
          console.log(response);
          var lgdata = response.data;
          console.log("lgdata", JSON.stringify(lgdata));
          var lgstatus = response.statusPublication;
          console.log("addlgstatus", JSON.stringify(lgstatus));
          if (response.data.status == true) {
            Swal.fire({
              icon: 'success',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
          else {
            Swal.fire({
              icon: 'error',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
          this.refreshPublication();
          this.setState({
            statusPublication: response.data.message,
            addDataPublication: {
              publication_position_type: null
            }
          });
        });

      this.setState({
        typeErrorPublication: null,
      })

    } catch (e) {
      console.log(e);
    }
  }

  deletePublication(id) {
    console.log(id)
    try {
      console.log('ID will be deleted', id);
      const url = global.websiteUrl+'/service/Publication_Position/publication_position_curd';
      let formData = new FormData();
      formData.append('id', id);
      formData.append('oper', 'del');

      const config = {
        //   headers: { 'content-type': 'multipart/form-data' }
      }

      axios.post(url, formData, config)
        .then(response => {
          console.log(response);
          var lgdata = response.data;
          console.log("delete data", JSON.stringify(lgdata));
          var lgstatus = response.data.statusPublication;
          console.log("delete status", JSON.stringify(lgstatus));
          if (response.data.status == true) {
            Swal.fire({
              icon: 'success',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
          else {
            Swal.fire({
              icon: 'error',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
          this.setState({ statusPublication: response.data.message })
          this.refreshPublication();
        });
    } catch (e) {
      console.log(e);
    }
  }

  refreshPublication() {
    try {
      console.log('I am in referesh category');
      const headers = new Headers()

      const options = {
        method: 'POST',
      }

      fetch(global.websiteUrl+'/service/Publication_Position/get_PublicationPositionList', options)
        .then((Response) => Response.json())
        .then((findresponse) => {
          console.log(findresponse.data)
          this.setState({
            PublicationList: findresponse.data,
            isAddPublication: false,
            isEditPublication: false,
          })
        })

    } catch (error) {
      console.log(error)
    }
  }

  // Function for toggling Add Form
  toggleaddPublicationModal() {
    this.setState({
      isAddPublication: !this.state.isAddPublication,
      typeErrorPublication: null
    })
  }

  handleValidationAddPublication() {
    let typeErrorPublication = "";
    let formIsValid = true;
    //Type Field Error
    if (!this.state.addDataPublication.publication_position_type) {
      formIsValid = false;
      typeErrorPublication = "Type field needed";
      if (typeErrorPublication) {
        console.log(typeErrorPublication)
        this.setState({ typeErrorPublication })
      }
    } else {
      this.setState({ typeErrorPublication: null })
    }
    console.log(formIsValid)
    return formIsValid;
  }

  handlesubmitAddPublication(e) {
    e.preventDefault();

    if (this.handleValidationAddPublication()) {
      this.AddTypePublication();
    } else {
      console.log('Form Has Errors')
    }
  }

  handleValidationEditPublication() {
    let typeErrorPublication = "";
    let formIsValid = true;
    //Tpe Field Error
    if (!this.state.editDataPublication.publication_position_type) {
      formIsValid = false;
      typeErrorPublication = "Type field needed";

      if (typeErrorPublication) {
        this.setState({ typeErrorPublication })
      }
    } else {
      this.setState({ typeErrorPublication: null })
    }
    console.log(formIsValid)
    return formIsValid;
  }

  handlesubmitEditPublication(e) {
    e.preventDefault();
    if (this.handleValidationEditPublication()) {
      this.EditPublicationType();
    } else {
      console.log('Form Has Errors')
    }
  }

  // 
  // Event Functions start here
  // STARNGE  
  onSearchEvent = (event) => {
    const { value } = event.target;
    this.onsearchFilterEvent(value);
  };

  actionEvent = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button class="cetas-button cetas-button-small"
          onClick={this.editEvent.bind(this, row.id, row.event_role_type)}>
          Edit
        </button>
        &nbsp;&nbsp;
        <button class="cetas-secondry-button cetas-button-small"
          onClick={this.deleteEvent.bind(this, row.id)}>
          Delete
        </button>
      </div>
    );
  };

  editEvent(id, event_role_type) {
    this.setState({
      editDataEvent: { id, event_role_type },
      isEditEvent: !this.state.isEditEvent,
      typeErrorEvent: null
    })
  }

  EditEventType() {
    try {
      console.log(this.state.editDataEvent.id)
      console.log(this.state.editDataEvent.event_role_type)

      const url = global.websiteUrl+'/service/EventRoles/event_role_curd';
      let formData = new FormData();
      formData.append('id', this.state.editDataEvent.id);
      formData.append('event_role_type', this.state.editDataEvent.event_role_type);
      formData.append('oper', 'edit');

      const config = {
        //   headers: { 'content-type': 'multipart/form-data' }
      }

      axios.post(url, formData, config)
        .then(response => {
          var lgstatus = response.status;
          console.log(response);
          console.log("edit message", response.data.message);
          console.log("edit status", JSON.stringify(lgstatus));
          if (response.data.status == true) {
            Swal.fire({
              icon: 'success',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
          else {
            Swal.fire({
              icon: 'error',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
          this.setState({ statusEvent: response.data.message })
          this.refreshEvent();
        });
    } catch (e) {
      console.log(e);
    }
  }

  AddTypeEvent() {
    try {
      console.log(this.state.addDataEvent.event_role_type)
      console.log(this.state.addDataEvent.id)
      const url = global.websiteUrl+'/service/EventRoles/event_role_curd';
      let formData = new FormData();
      formData.append('oper', 'add');
      // formData.append('id', this.state.addDataEvent.id);
      formData.append('event_role_type', this.state.addDataEvent.event_role_type);

      const config = {
        // headers: { 'content-type': 'multipart/form-data' }
      }

      axios.post(url, formData, config)
        .then(response => {
          console.log(response);
          var lgdata = response.data;
          console.log("lgdata", JSON.stringify(lgdata));
          var lgstatus = response.status;
          console.log("addlgstatus", JSON.stringify(lgstatus));
          if (response.data.status == true) {
            Swal.fire({
              icon: 'success',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
          else {
            Swal.fire({
              icon: 'error',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
          this.refreshEvent();
          this.setState({
            statusEvent: response.data.message,
            addDataEvent: {
              event_role_type: null
            }
          });
        });
      this.setState({
        typeErrorEvent: null,
      })
    } catch (e) {
      console.log(e);
    }
  }

  deleteEvent(id) {
    console.log(id)
    try {
      console.log('ID will be deleted', id);
      const url = global.websiteUrl+'/service/EventRoles/event_role_curd';
      let formData = new FormData();
      formData.append('id', id);
      formData.append('oper', 'del');

      const config = {
        //   headers: { 'content-type': 'multipart/form-data' }
      }

      axios.post(url, formData, config)
        .then(response => {
          console.log(response);
          var lgdata = response.data;
          console.log("delete data", JSON.stringify(lgdata));
          var lgstatus = response.data.status;
          console.log("delete status", JSON.stringify(lgstatus));
          if (response.data.status == true) {
            Swal.fire({
              icon: 'success',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
          else {
            Swal.fire({
              icon: 'error',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
          this.setState({ statusEvent: response.data.message })
          this.refreshEvent();
        });
    } catch (e) {
      console.log(e);
    }
  }

  refreshEvent() {
    try {
      console.log('I am in referesh category');
      const headers = new Headers()

      const options = {
        method: 'POST',
      }

      fetch(global.websiteUrl+'/service/EventRoles/get_EventRoleList', options)
        .then((Response) => Response.json())
        .then((findresponse) => {
          console.log(findresponse.data)
          this.setState({
            EventRolesList: findresponse.data,
            isAddEvent: false,
            isEditEvent: false,
          })
        })

    } catch (error) {
      console.log(error)
    }
  }

  toggleaddEventModal() {
    this.setState({
      isAddEvent: !this.state.isAddEvent,
      typeErrorEvent: null
    })
  }

  handleValidationAddEvent() {
    let typeErrorEvent = "";
    let formIsValid = true;

    //Type Field Error
    if (!this.state.addDataEvent.event_role_type) {
      formIsValid = false;
      typeErrorEvent = "Type field needed";

      if (typeErrorEvent) {
        console.log(typeErrorEvent)
        this.setState({ typeErrorEvent })
      }
    } else {
      this.setState({ typeErrorEvent: null })
    }
    console.log(formIsValid)
    return formIsValid;
  }

  handlesubmitAddEvent(e) {
    e.preventDefault();

    if (this.handleValidationAddEvent()) {
      this.AddTypeEvent();
    } else {
      console.log('Form Has Errors')
    }
  }

  handleValidationEditEvent() {
    let typeErrorEvent = "";
    let formIsValid = true;
    //Tpe Field Error
    if (!this.state.editDataEvent.event_role_type) {
      formIsValid = false;
      typeErrorEvent = "Type field needed";

      if (typeErrorEvent) {
        this.setState({ typeErrorEvent })
      }
    } else {
      this.setState({ typeErrorEvent: null })
    }
    console.log(formIsValid)
    return formIsValid;
  }

  handlesubmitEditEvent(e) {
    e.preventDefault();

    if (this.handleValidationEditEvent()) {
      this.EditEventType();
    } else {
      console.log('Form Has Errors')
    }
  }

  // 
  // CT Functions start here
  // STARNGE  
  onSearchCT = (event) => {
    const { value } = event.target;
    this.onsearchFilterCT(value);
  };

  actionCT = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button class="cetas-button cetas-button-small"
          onClick={this.editCT.bind(this, row.id, row.ct_role_type)}>
          Edit
        </button>
        &nbsp;&nbsp;
        <button class="cetas-secondry-button cetas-button-small"
          onClick={this.deleteCT.bind(this, row.id)}>
          Delete
        </button>
      </div>
    );
  };

  editCT(id, ct_role_type) {
    this.setState({
      editDataCT: { id, ct_role_type },
      isEditCT: !this.state.isEditCT,
      typeErrorCT: null
    })
  }

  EditTypeCT() {
    try {
      const url = global.websiteUrl+'/service/CT_Roles/ct_role_curd';
      let formData = new FormData();
      formData.append('id', this.state.editDataCT.id);
      formData.append('ct_role_type', this.state.editDataCT.ct_role_type);
      formData.append('oper', 'edit');

      const config = {
        //   headers: { 'content-type': 'multipart/form-data' }
      }

      axios.post(url, formData, config)
        .then(response => {
          var lgstatus = response.status;
          console.log(response);
          console.log("edit message", response.data.message);
          console.log("edit status", JSON.stringify(lgstatus));
          if (response.data.status == true) {
            Swal.fire({
              icon: 'success',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
          else {
            Swal.fire({
              icon: 'error',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
          this.setState({ statusCT: response.data.message })
          this.refreshCT();
        });
    } catch (e) {
      console.log(e);
    }
  }

  AddTypeCT() {
    try {
      const url = global.websiteUrl+'/service/CT_Roles/ct_role_curd';
      let formData = new FormData();
      formData.append('oper', 'add');
      // formData.append('id', this.state.addDataCT.id);
      formData.append('ct_role_type', this.state.addDataCT.ct_role_type);

      const config = {
        // headers: { 'content-type': 'multipart/form-data' }
      }

      axios.post(url, formData, config)
        .then(response => {
          console.log(response);
          var lgdata = response.data;
          console.log("lgdata", JSON.stringify(lgdata));
          var lgstatus = response.status;
          console.log("addlgstatus", JSON.stringify(lgstatus));
          if (response.data.status == true) {
            Swal.fire({
              icon: 'success',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
          else {
            Swal.fire({
              icon: 'error',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
          this.refreshCT();
          this.setState({
            statusCT: response.data.message,
            addDataCT: {
              ct_role_type: null
            }
          });
        });

      this.setState({
        typeErrorCT: null,
      })
    } catch (e) {
      console.log(e);
    }
  }

  deleteCT(id) {
    console.log(id)
    try {
      console.log('ID will be deleted', id);
      const url = global.websiteUrl+'/service/CT_Roles/ct_role_curd';
      let formData = new FormData();
      formData.append('id', id);
      formData.append('oper', 'del');

      const config = {
        //   headers: { 'content-type': 'multipart/form-data' }
      }

      axios.post(url, formData, config)
        .then(response => {
          console.log(response);
          var lgdata = response.data;
          console.log("delete data", JSON.stringify(lgdata));
          var lgstatus = response.data.status;
          console.log("delete status", JSON.stringify(lgstatus));
          if (response.data.status == true) {
            Swal.fire({
              icon: 'success',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
          else {
            Swal.fire({
              icon: 'error',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
          this.setState({ statusCT: response.data.message })
          this.refreshCT();
        });
    } catch (e) {
      console.log(e);
    }
  }

  refreshCT() {
    try {
      console.log('I am in referesh category');
      const headers = new Headers()

      const options = {
        method: 'POST',
      }

      fetch(global.websiteUrl+'/service/CT_Roles/get_CT_RoleList', options)
        .then((Response) => Response.json())
        .then((findresponse) => {
          console.log(findresponse.data)
          this.setState({
            CTList: findresponse.data,
            isAddCT: false,
            isEditCT: false,
          })
        })
    } catch (error) {
      console.log(error)
    }
  }

  toggleaddCTModal() {
    this.setState({
      isAddCT: !this.state.isAddCT,
      typeErrorCT: null
    })
  }

  handleValidationAddCT() {
    let typeErrorCT = "";
    let formIsValid = true;
    //Type Field Error
    if (!this.state.addDataCT.ct_role_type) {
      formIsValid = false;
      typeErrorCT = "Type field needed";

      if (typeErrorCT) {
        console.log(typeErrorCT)
        this.setState({ typeErrorCT })
      }
    } else {
      this.setState({ typeErrorCT: null })
    }
    console.log(formIsValid)
    return formIsValid;
  }

  handlesubmitAddCT(e) {
    e.preventDefault();
    if (this.handleValidationAddCT()) {
      this.AddTypeCT();
    } else {
      console.log('Form Has Errors')
    }
  }

  handleValidationEditCT() {
    let typeErrorCT = "";
    let formIsValid = true;
    //Tpe Field Error
    if (!this.state.editDataCT.ct_role_type) {
      formIsValid = false;
      typeErrorCT = "Type field needed";

      if (typeErrorCT) {
        this.setState({ typeErrorCT })
      }
    } else {
      this.setState({ typeErrorCT: null })
    }
    console.log(formIsValid)
    return formIsValid;
  }

  handlesubmitEditCT(e) {
    e.preventDefault();

    if (this.handleValidationEditCT()) {
      this.EditTypeCT();
    } else {
      console.log('Form Has Errors')
    }
  }

  // 
  // Participant functions start here
  // STARNGE  
  onSearchPart = (event) => {
    const { value } = event.target;
    this.onsearchFilterPart(value);
  };

  actionPart = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div>
        <button class="cetas-button cetas-button-small"
          onClick={this.editPart.bind(this, row.id, row.no_of_participants)}>
          Edit
        </button>
        &nbsp;&nbsp;
        <button class="cetas-secondry-button cetas-button-small"
          onClick={this.deletePart.bind(this, row.id)}>
          Delete
        </button>
      </div>
    );
  };

  editPart(id, no_of_participants) {
    this.setState({
      editDataPart: { id, no_of_participants },
      isEditPart: !this.state.isEditPart,
      typeErrorPart: null
    })
  }

  EditTypePart() {
    try {
      const url = global.websiteUrl+'/service/No_Of_Participants/no_of_participants_curd';
      let formData = new FormData();
      formData.append('id', this.state.editDataPart.id);
      formData.append('no_of_participants', this.state.editDataPart.no_of_participants);
      formData.append('oper', 'edit');

      const config = {
        //   headers: { 'content-type': 'multipart/form-data' }
      }

      axios.post(url, formData, config)
        .then(response => {
          var lgstatus = response.status;
          console.log(response);
          console.log("edit message", response.data.message);
          console.log("edit status", JSON.stringify(lgstatus));
          if (response.data.status == true) {
            Swal.fire({
              icon: 'success',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
          else {
            Swal.fire({
              icon: 'error',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
          this.setState({ statusPart: response.data.message })
          this.refreshPart();
        });
    } catch (e) {
      console.log(e);
    }
  }

  AddTypePart() {
    try {
      const url = global.websiteUrl+'/service/No_Of_Participants/no_of_participants_curd';
      let formData = new FormData();
      formData.append('oper', 'add');
      // formData.append('id', this.state.addDataPart.id);
      formData.append('no_of_participants', this.state.addDataPart.no_of_participants);

      const config = {
        // headers: { 'content-type': 'multipart/form-data' }
      }

      axios.post(url, formData, config)
        .then(response => {
          console.log(response);
          var lgdata = response.data;
          console.log("lgdata", JSON.stringify(lgdata));
          var lgstatus = response.status;
          console.log("addlgstatus", JSON.stringify(lgstatus));
          if (response.data.status == true) {
            Swal.fire({
              icon: 'success',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
          else {
            Swal.fire({
              icon: 'error',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
          this.refreshPart();
          this.setState({
            statusPart: response.data.message,
            addDataPart: {
              no_of_participants: null
            }
          });
        });

      this.setState({
        typeErrorPart: null,
      })
    } catch (e) {
      console.log(e);
    }
  }

  deletePart(id) {
    console.log(id)
    try {
      console.log('ID will be deleted', id);
      const url = global.websiteUrl+'/service/No_Of_Participants/no_of_participants_curd';
      let formData = new FormData();
      formData.append('id', id);
      formData.append('oper', 'del');

      const config = {
        //   headers: { 'content-type': 'multipart/form-data' }
      }

      axios.post(url, formData, config)
        .then(response => {
          console.log(response);
          var lgdata = response.data;
          console.log("delete data", JSON.stringify(lgdata));
          var lgstatus = response.data.status;
          console.log("delete status", JSON.stringify(lgstatus));
          if (response.data.status == true) {
            Swal.fire({
              icon: 'success',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
          else {
            Swal.fire({
              icon: 'error',
              text: response.data.message,
              confirmButtonColor: '#d33',
              confirmButtonText: 'OK'
            })
          }
          this.setState({ statusPart: response.data.message })
          this.refreshPart();
        });
    } catch (e) {
      console.log(e);
    }
  }

  refreshPart() {
    try {
      console.log('I am in referesh category');
      const headers = new Headers()

      const options = {
        method: 'POST',
      }

      fetch(global.websiteUrl+'/service/No_Of_Participants/get_no_of_ParticipantsList', options)
        .then((Response) => Response.json())
        .then((findresponse) => {
          console.log(findresponse.data)
          this.setState({
            PartList: findresponse.data,
            isAddPart: false,
            isEditPart: false,
          })
        })

    } catch (error) {
      console.log(error)
    }
  }

  toggleaddPartModal() {
    this.setState({
      isAddPart: !this.state.isAddPart,
      typeErrorPart: null
    })
  }

  handleValidationAddPart() {
    let typeErrorPart = "";
    let formIsValid = true;
    //Type Field Error
    if (!this.state.addDataPart.no_of_participants) {
      formIsValid = false;
      typeErrorPart = "Type field needed";

      if (typeErrorPart) {
        console.log(typeErrorPart)
        this.setState({ typeErrorPart })
      }
    } else {
      this.setState({ typeErrorPart: null })
    }
    console.log(formIsValid)
    return formIsValid;
  }

  handlesubmitAddPart(e) {
    e.preventDefault();
    if (this.handleValidationAddPart()) {
      this.AddTypePart();
    } else {
      console.log('Form Has Errors')
    }
  }

  handleValidationEditPart() {
    let typeErrorPart = "";
    let formIsValid = true;
    //Tpe Field Error
    if (!this.state.editDataPart.no_of_participants) {
      formIsValid = false;
      typeErrorPart = "Type field needed";

      if (typeErrorPart) {
        this.setState({ typeErrorPart })
      }
    } else {
      this.setState({ typeErrorPart: null })
    }
    console.log(formIsValid)
    return formIsValid;
  }

  handlesubmitEditPart(e) {
    e.preventDefault();
    if (this.handleValidationEditPart()) {
      this.EditTypePart();
    } else {
      console.log('Form Has Errors')
    }
  }

  render() {
    // Engagement Type data
    let ProcedureList = this.state.ProcedureList.map((data) => {
      return (
        <tr key={data.id}>
          <td className="App">{data.id}</td>
          <td className="App">{data.procedure_type}</td>
          <td className="App">
            <Button color="success" size="sm" className="mr-2"
              onClick={this.editProcedure.bind(this, data.id, data.procedure_type)}>Edit</Button>
            <Button color="danger" size="sm"
              onClick={this.deleteProcedure.bind(this, data.id)}>Delete</Button>
          </td>
        </tr>
      )
    });

    return (
      <body>
        <div style={{marginTop:'15vh'}}>

          {this.state.ShowBar ? <div style={{ position: 'fixed', top: '0' }}>
            <LoadingBar
              progress='100'
              // onLoaderFinished={() => setProgress(0)}
              //  progress={progress}
              height={3} color="#A90007" transitionTime={4000}
              loaderSpeed={4000} />
          </div>
            : null}

          {this.state.ShowProcedure ? <Modal isOpen={true} className='propagateLoader' centered>
            <PropagateLoader color={'#A90007'} size='40' loading='true' />
          </Modal> : null}
          <div >
            <div >
              <AdminHeader />
              <div class="container">
                <div class="content-wrapper">
                  <div class="screen-headingbox">
                    <div className="back-section">
                      <a>
                        <Link to="/AdminDashboard">
                          <img
                            src={require('../Assets/Images/back-arrow.svg')}
                          />
                          Back
                        </Link>
                      </a>
                    </div>
                    <h2>Form Management</h2>
                  </div>
                  <div class="row">
                    <div class="col-md-3 col-sm-12 col-xs-12 profile-box">
                      <div className="sidebar" >
                        <ul className="cetas-leftnav">
                          {/* <li>
                                <a onClick={this.EngagementType}>
                                    {
                                        (this.state.EngagementTypeFlag) ? (
                                            <text style={{color:'#A90007', fontWeight:'bold'}}>Engagement Type</text>
                                        )  : (
                                            <text>Engagement Type</text>
                                        )
                                    }
                                </a>
                            </li> */}
                          {/* <li>
                                <a onClick={this.PublicationPosition}>
                                    {
                                        (this.state.PublicationPositionFlag) ? (
                                            <text style={{color:'#A90007', fontWeight:'bold'}}>Publication Position</text>
                                        )  : (
                                            <text>Publication Position</text>
                                        )
                                    }
                                </a>
                            </li> */}
                          {/* <li>
                                <a onClick={this.EventRoles}>
                                    {
                                        (this.state.EventRolesFlag) ? (
                                            <text style={{color:'#A90007', fontWeight:'bold'}}>Event Roles</text>
                                        )  : (
                                            <text>Event Roles</text>
                                        )
                                    }
                                </a>
                            </li> */}
                          {/* <li>
                                <a onClick={this.CTRoles}>
                                    {
                                        (this.state.CTRolesFlag) ? (
                                            <text style={{color:'#A90007', fontWeight:'bold'}}>CT Roles</text>
                                        )  : (
                                            <text>CT Roles</text>
                                        )
                                    }
                                </a>
                            </li> */}
                          {/* <li>
                                <a onClick={this.Participants}>
                                    {
                                        (this.state.ParticipantsFlag) ? (
                                            <text style={{color:'#A90007', fontWeight:'bold'}}>Participants</text>
                                        )  : (
                                            <text>Participants</text>
                                        )
                                    }
                                </a>
                            </li> */}
                          <li>
                            <a onClick={this.Speciality}>
                              {
                                (this.state.SpecialityFlag) ? (
                                  <text style={{ color: '#A90007', fontWeight: 'bold' }}>Speciality</text>
                                ) : (
                                  <text>Speciality</text>
                                )
                              }
                            </a>
                          </li>
                          <li class="submenu-link">
                            <a onClick={this.procedureType}>
                              {
                                (this.state.procedureFlag) ? (
                                  <text style={{ color: '#A90007', fontWeight: 'bold' }}>Procedure Types</text>
                                ) : (
                                  <text>Procedure Types</text>
                                )
                              }
                              {/* Procedure Types */}
                              <img style={{ marginLeft: '10%' }}
                                src={require('../Assets/Images/back-arrow.svg')}
                              />
                            </a>
                            {
                              (this.state.procedureFlag) ? (
                                <ul>
                                  {this.state.SpecialityList.map(item => (
                                    <li value={item.id} type={item.speciality_type}
                                      style={{ cursor: "pointer" }}
                                      onClick={this.ProcedureItemSelectedFunc} key={item.id}
                                    //  className={
                                    //     this.state.speciality_type_id == item.id ? "selected" : ""
                                    // }
                                    //  className={`segmentsList${this.state.speciality_type_id === item.id ? 'selected' : ''}`}
                                    >
                                      {item.speciality_type}
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                <div>
                                </div>
                              )
                            }
                          </li>
                          <li class="submenu-link">
                            <a onClick={this.SubSpeciality}>
                              {
                                (this.state.SubSpecialityFlag) ? (
                                  <text style={{ color: '#A90007', fontWeight: 'bold' }}>
                                    Sub Speciality Types
                                  </text>
                                ) : (
                                  <text>Sub Speciality Types</text>
                                )
                              }
                              {/* Procedure Types */}
                              <img style={{ marginLeft: '10%' }}
                                src={require('../Assets/Images/back-arrow.svg')}
                              />
                            </a>
                            {
                              (this.state.SubSpecialityFlag) ? (
                                <ul>
                                  {this.state.SpecialityList.map(item => (
                                    <li value={item.id} type={item.speciality_type}
                                      style={{ cursor: "pointer" }}
                                      onClick={this.SubSpecialityItemSelectedFunc} key={item.id}
                                    >
                                      {item.speciality_type}
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                <div>
                                </div>
                              )
                            }
                          </li>
                        </ul>
                        <div style={{ marginTop: '10px' }}></div>
                      </div>
                    </div>
                    <div class="col-md-8 col-sm-12 col-xs-12 profile-box">
                      <div class="form-rightsec">
                        {
                          (this.state.procedureFlag == true) ? (
                            <div>
                              <div className="screen-headingbox">
                                <h3>{this.state.ProcedureTypeName}<button className="cetas-button float-right"
                                  data-toggle="modal" data-target="#new-empl-add"
                                  onClick={this.toggleProcedureAdd.bind(this)}>+ Add Item</button></h3>
                              </div>
                              <div className="emplist-box" style={{ marginTop: '5%', marginBottom: '5%' }}>
                                <div className="employee-actions">
                                  <div className="table-infobox">
                                    <p className="list-count">
                                      {this.state.ProcedureList.length} Items listed
                                    </p>
                                    <div className="filter-box float-right mb-30">
                                      <div style={{ width: '70%', }} className="input-group float-right">
                                        <div className="input-group-prepend">
                                          <div className="input-group-text">
                                            <img src={require("../Assets/Images/search.svg")} />
                                          </div>
                                        </div>
                                        <input type="text" className="form-control"
                                          id="inlineFormInputGroupUsername"
                                          placeholder="Search by Name"
                                          onChange={(e) => { this.onItemChange(e) }} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <BootstrapTable keyField='id'
                                  data={this.state.ProcedureList}
                                  columns={this.state.columns}
                                  noDataIndication="Table is Empty"
                                  filter={filterFactory()}
                                  striped
                                  hover
                                  condensed
                                />
                              </div>
                            </div>
                          ) : (
                            <div>
                            </div>
                          )
                        }
                        {/* Engagement Type */}
                        {/* {
                            (this.state.EngagementTypeFlag) ? (
                            <div>
                                <div className="screen-headingbox">
                                    <h3>Engagement Type<button className="cetas-button float-right" 
                                    data-toggle="modal" data-target="#new-empl-add" 
                                    onClick={this.toggleaddEngagementModal.bind()}>+ Add Item</button></h3>
                                </div>
                                <div className="emplist-box" style={{marginTop:'5%', marginBottom:'5%'}}>
                                    <div className="employee-actions">
                                        <div className="table-infobox">
                                            <p className="list-count">
                                                {this.state.EngagementTypeList.length} Items listed
                                            </p>
                                            <div className="filter-box float-right mb-30"> 
                                                <div style={{width:'70%',}} className="input-group float-right">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <img src={require("../Assets/Images/search.svg")} />
                                                        </div>
                                                    </div>
                                                    <input type="text" className="form-control" 
                                                            id="inlineFormInputGroupUsername" 
                                                            placeholder="Search by Name"
                                                            onChange={ (e) => { this.onSearchEngagement(e) }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <BootstrapTable keyField='id'  
                                        data={this.state.EngagementTypeList}
                                        columns={this.state.columnsEngagement}
                                        filter={ filterFactory() }
                                        striped
                                        hover
                                        condensed
                                    /> 
                                </div>
                            </div>
                            ) : (
                                <div>
                                </div>
                            )
                        } */}
                        {/* Publication Position */}
                        {/* {
                            (this.state.PublicationPositionFlag) ? (
                            <div>
                                <div className="screen-headingbox">
                                    <h3>Publication Position<button className="cetas-button float-right" 
                                    data-toggle="modal" data-target="#new-empl-add" 
                                    onClick={this.toggleaddPartModal.bind()}>+ Add Item</button></h3>
                                </div>
                                <div className="emplist-box" style={{marginTop:'5%', marginBottom:'5%'}}>
                                    <div className="employee-actions">
                                        <div className="table-infobox">
                                            <p className="list-count">
                                                {this.state.PublicationList.length} Items listed
                                            </p>
                                            <div className="filter-box float-right mb-30"> 
                                                <div style={{width:'70%',}} className="input-group float-right">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <img src={require("../Assets/Images/search.svg")} />
                                                        </div>
                                                    </div>
                                                    <input type="text" className="form-control" 
                                                            id="inlineFormInputGroupUsername" 
                                                            placeholder="Search by Name"
                                                            onChange={ (e) => { this.onSearchPublication(e) }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <BootstrapTable keyField='id'  
                                        data={this.state.PublicationList}
                                        columns={this.state.columnsPublication}
                                        filter={ filterFactory() }
                                        striped
                                        hover
                                        condensed
                                    /> 
                                </div>
                            </div>
                            ) : (
                                <div>
                                </div>
                            )
                        } */}
                        {/* Evant Roles */}
                        {/* {
                            (this.state.EventRolesFlag) ? (
                            <div>
                                <div className="screen-headingbox">
                                    <h3>Event Roles<button className="cetas-button float-right" 
                                    data-toggle="modal" data-target="#new-empl-add" 
                                    onClick={this.toggleaddEventModal.bind()}>+ Add Item</button></h3>
                                </div>
                                <div className="emplist-box" style={{marginTop:'5%', marginBottom:'5%'}}>
                                    <div className="employee-actions">
                                        <div className="table-infobox">
                                            <p className="list-count">
                                                {this.state.EventRolesList.length} Items listed
                                            </p>
                                            <div className="filter-box float-right mb-30"> 
                                                <div style={{width:'70%',}} className="input-group float-right">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <img src={require("../Assets/Images/search.svg")} />
                                                        </div>
                                                    </div>
                                                    <input type="text" className="form-control" 
                                                            id="inlineFormInputGroupUsername" 
                                                            placeholder="Search by Name"
                                                            onChange={ (e) => { this.onSearchEvent(e) }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <BootstrapTable keyField='id'  
                                        data={this.state.EventRolesList}
                                        columns={this.state.columnsEvent}
                                        filter={ filterFactory() }
                                        striped
                                        hover
                                        condensed
                                        
                                    /> 
                                </div>
                            </div>
                            ) : (
                                <div>
                                </div>
                            )
                        } */}
                        {/* CT Roles */}
                        {/* {
                            (this.state.CTRolesFlag) ? (
                            <div>
                                <div className="screen-headingbox">
                                    <h3>CT Roles<button className="cetas-button float-right" 
                                    data-toggle="modal" data-target="#new-empl-add" 
                                    onClick={this.toggleaddCTModal.bind()}>+ Add Item</button></h3>
                                </div>
                                <div className="emplist-box" style={{marginTop:'5%', marginBottom:'5%'}}>
                                    <div className="employee-actions">
                                        <div className="table-infobox">
                                            <p className="list-count">
                                                {this.state.CTList.length} Items listed
                                            </p>
                                            <div className="filter-box float-right mb-30"> 
                                                <div style={{width:'70%',}} className="input-group float-right">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <img src={require("../Assets/Images/search.svg")} />
                                                        </div>
                                                    </div>
                                                    <input type="text" className="form-control" 
                                                            id="inlineFormInputGroupUsername" 
                                                            placeholder="Search by Name"
                                                            onChange={ (e) => { this.onSearchCT(e) }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <BootstrapTable keyField='id'  
                                        data={this.state.CTList}
                                        columns={this.state.columnsCT}
                                        filter={ filterFactory() }
                                        striped
                                        hover
                                        condensed
                                    /> 
                                </div>
                            </div>
                            ) : (
                                <div>
                                </div>
                            )
                        } */}
                        {/* Participants */}
                        {/* {
                            (this.state.ParticipantsFlag) ? (
                            <div>
                                <div className="screen-headingbox">
                                    <h3>Participants<button className="cetas-button float-right" 
                                    data-toggle="modal" data-target="#new-empl-add" 
                                    onClick={this.toggleaddPartModal.bind()}>+ Add Item</button></h3>
                                </div>
                                <div className="emplist-box" style={{marginTop:'5%', marginBottom:'5%'}}>
                                    <div className="employee-actions">
                                        <div className="table-infobox">
                                            <p className="list-count">
                                                {this.state.PartList.length} Items listed
                                            </p>
                                            <div className="filter-box float-right mb-30"> 
                                                <div style={{width:'70%',}} className="input-group float-right">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <img src={require("../Assets/Images/search.svg")} />
                                                        </div>
                                                    </div>
                                                    <input type="text" className="form-control" 
                                                            id="inlineFormInputGroupUsername" 
                                                            placeholder="Search by Name"
                                                            onChange={ (e) => { this.onSearchPart(e) }} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <BootstrapTable keyField='id'  
                                        data={this.state.PartList}
                                        columns={this.state.columnsPart}
                                        filter={ filterFactory() }
                                        striped
                                        hover
                                        condensed
                                    /> 
                                </div>
                            </div>
                            ) : (
                                <div>
                                </div>
                            )
                        } */}
                        {/* Speciality */}
                        {
                          (this.state.SpecialityFlag) ? (
                            <div>
                              <div className="screen-headingbox">
                                <h3>Speciality<button className="cetas-button float-right"
                                  data-toggle="modal" data-target="#new-empl-add"
                                  onClick={this.toggleaddSpecialityModal.bind()}>+ Add Item</button></h3>
                              </div>
                              <div className="emplist-box" style={{ marginTop: '5%', marginBottom: '5%' }}>
                                <div className="employee-actions">
                                  <div className="table-infobox">
                                    <p className="list-count" style={{ color: 'white' }}>
                                      {this.state.SpecialityList.length} Items listed
                                    </p>
                                    <div className="filter-box float-right mb-30">
                                      <div style={{ width: '70%', }} className="input-group float-right">
                                        <div className="input-group-prepend">
                                          <div className="input-group-text">
                                            <img src={require("../Assets/Images/search.svg")} />
                                          </div>
                                        </div>
                                        <input type="text" className="form-control"
                                          id="inlineFormInputGroupUsername"
                                          placeholder="Search by Name"
                                          onChange={(e) => { this.onItemChange(e) }} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <BootstrapTable keyField='id'
                                  data={this.state.SpecialityList}
                                  noDataIndication="Table is Empty"
                                  columns={this.state.columnsSpeciality}
                                  filter={filterFactory()}
                                  striped
                                  hover
                                  condensed
                                />
                              </div>
                            </div>
                          ) : (
                            <div>
                            </div>
                          )
                        }

                        {/* Sub Speciality */}
                        {
                          (this.state.SubSpecialityFlag) ? (
                            <div>
                              <div className="screen-headingbox">
                                <h3>{this.state.SubSpecialityTypeName}
                                  <button className="cetas-button float-right"
                                    data-toggle="modal" data-target="#new-empl-add"
                                    onClick={this.toggleaddSubSpecialityModal.bind()}
                                  >+ Add Item</button></h3>
                              </div>
                              <div className="emplist-box" style={{ marginTop: '5%', marginBottom: '5%' }}>
                                <div className="employee-actions">
                                  <div className="table-infobox">
                                    <p className="list-count" style={{ color: 'white' }}>
                                      {this.state.SubSpecialityList.length} Items listed
                                    </p>
                                    <div className="filter-box float-right mb-30">
                                      <div style={{ width: '70%', }} className="input-group float-right">
                                        <div className="input-group-prepend">
                                          <div className="input-group-text">
                                            <img src={require("../Assets/Images/search.svg")} />
                                          </div>
                                        </div>
                                        <input type="text" className="form-control"
                                          id="inlineFormInputGroupUsername"
                                          placeholder="Search by Name"
                                          onChange={(e) => { this.onItemChange(e) }} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <BootstrapTable keyField='id'
                                  data={this.state.SubSpecialityList}
                                  noDataIndication="Table is Empty"
                                  columns={this.state.columnsSubSpeciality}
                                  filter={filterFactory()}
                                  striped
                                  hover
                                  condensed
                                />
                              </div>
                            </div>
                          ) : (
                            <div>
                            </div>
                          )
                        }

                      </div>
                    </div>
                  </div>
                </div>
                {/* Add Procedure Type Modal*/}
                <Modal isOpen={this.state.toggleProcedureAddModal} toggle={this.toggleProcedureAdd.bind(this)}>
                  <ModalHeader toggle={this.toggleProcedureAdd.bind(this)}
                    cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
                    style={{ borderBottom: '0px' }}>
                    <h3 style={{ justifyContent: 'center', marginTop: '10px' }} id="login-tit">Add New Item</h3>
                  </ModalHeader>
                  <ModalBody>
                    {/* <FormGroup>
                    <Input id="id" value={this.state.newDataProcedure.id} readOnly hidden />
                  </FormGroup> */}
                    <FormGroup>
                      <Label for="AddIntOnco">Add Item in {this.state.ProcedureTypeName} </Label>
                      <Input id="title" value={this.state.newDataProcedure.procedure_type} onChange={(e) => {
                        let { newDataProcedure } = this.state;
                        newDataProcedure.procedure_type = e.target.value;
                        this.setState({ newDataProcedure });
                      }} />
                      <div style={{ fontSize: 12, color: "red" }}>{this.state.ProcedureAddErr}</div>
                    </FormGroup>
                  </ModalBody>
                  <ModalFooter>
                    <button class="cetas-button cetas-button-small" style={{ height: '40px' }}
                      onClick={this.procedureAdd.bind(this)}>Add Item</button>
                    <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                      onClick={this.toggleProcedureAdd.bind(this)}>Cancel</button>
                  </ModalFooter>
                </Modal>
                {/*  Edit procedure Modal*/}
                <Modal isOpen={this.state.editProcedureModal} toggle={this.toggleeditProcedureModal.bind(this)} >
                  <ModalHeader toggle={this.toggleeditProcedureModal.bind(this)}
                    cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
                    style={{ borderBottom: '0px' }}>
                    <h3 style={{ justifyContent: 'center', marginTop: '10px' }} id="login-tit">Edit Item</h3>
                  </ModalHeader>
                  <ModalBody>
                    <FormGroup>
                      <Label for="category">Update {this.state.ProcedureTypeName}</Label>
                      <Input id="title" value={this.state.editProcedureData.procedure_type} onChange={(e) => {
                        let { editProcedureData } = this.state;
                        editProcedureData.procedure_type = e.target.value;
                        this.setState({ editProcedureData });
                      }} />
                      <div style={{ fontSize: 12, color: "red" }}>{this.state.ProcedureEditErr}</div>
                    </FormGroup>
                  </ModalBody>
                  <ModalFooter>
                    <button class="cetas-button cetas-button-small" style={{ height: '40px' }}
                      onClick={this.updateProcedure.bind(this)}>Update</button>
                    <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                      onClick={this.toggleeditProcedureModal.bind(this)}>Cancel</button>
                  </ModalFooter>
                </Modal>

                {/* Add Speciality Type Modal*/}
                <Modal isOpen={this.state.addSpecialityModal} toggle={this.toggleaddSpecialityModal.bind(this)}>
                  <ModalHeader toggle={this.toggleaddSpecialityModal.bind(this)}
                    cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
                    style={{ borderBottom: '0px' }}>
                    <h3 style={{ justifyContent: 'center', marginTop: '10px' }} id="login-tit">Add New Item</h3>
                  </ModalHeader>
                  <ModalBody>
                    <FormGroup>
                      <Label for="AddIntOnco">Add Item in Speciality</Label>
                      <Input id="title" value={this.state.newDataSpeciality.speciality_type} onChange={(e) => {
                        let { newDataSpeciality } = this.state;
                        newDataSpeciality.speciality_type = e.target.value;
                        this.setState({ newDataSpeciality });
                      }} />
                      <div style={{ fontSize: 12, color: "red" }}>{this.state.SpecialityAddErr}</div>
                    </FormGroup>
                  </ModalBody>
                  <ModalFooter>
                    <button class="cetas-button cetas-button-small" style={{ height: '40px' }}
                      onClick={this.specialityAdd.bind(this)}>Add Item</button>
                    <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                      onClick={this.toggleaddSpecialityModal.bind(this)}>Cancel</button>
                  </ModalFooter>
                </Modal>

                {/*  Edit Speciality Modal*/}
                <Modal isOpen={this.state.editSpecialityModal} toggle={this.toggleeditSpecialityModal.bind(this)} >
                  <ModalHeader toggle={this.toggleeditSpecialityModal.bind(this)}
                    cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
                    style={{ borderBottom: '0px' }}>
                    <h3 style={{ justifyContent: 'center', marginTop: '10px' }} id="login-tit">Edit Item</h3>
                  </ModalHeader>
                  <ModalBody>
                    <FormGroup>
                      <Label for="category">Update {this.state.Speciality}</Label>
                      <Input id="title" value={this.state.editSpecialityData.speciality_type} onChange={(e) => {
                        let { editSpecialityData } = this.state;
                        editSpecialityData.speciality_type = e.target.value;
                        this.setState({ editSpecialityData });
                      }} />
                      <div style={{ fontSize: 12, color: "red" }}>{this.state.SpecialityEditErr}</div>
                    </FormGroup>
                  </ModalBody>
                  <ModalFooter>
                    <button class="cetas-button cetas-button-small" style={{ height: '40px' }}
                      onClick={this.updateSpeciality.bind(this)}>Update</button>
                    <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                      onClick={this.toggleeditSpecialityModal.bind(this)}>Cancel</button>
                  </ModalFooter>
                </Modal>

                {/* Add Sub Speciality Type Modal*/}
                <Modal isOpen={this.state.addSubSpecialityModal} toggle={this.toggleaddSubSpecialityModal.bind(this)}>
                  <ModalHeader toggle={this.toggleaddSubSpecialityModal.bind(this)}
                    cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
                    style={{ borderBottom: '0px' }}>
                    <h3 style={{ justifyContent: 'center', marginTop: '10px' }} id="login-tit">Add New Item</h3>
                  </ModalHeader>
                  <ModalBody>
                    <FormGroup>
                      <Label for="AddIntOnco">Add Item in Speciality</Label>
                      <Input id="title" value={this.state.newDataSubSpeciality.sub_speciality_type} onChange={(e) => {
                        let { newDataSubSpeciality } = this.state;
                        newDataSubSpeciality.sub_speciality_type = e.target.value;
                        this.setState({ newDataSubSpeciality });
                      }} />
                      <div style={{ fontSize: 12, color: "red" }}>{this.state.SubSpecialityAddErr}</div>
                    </FormGroup>
                  </ModalBody>
                  <ModalFooter>
                    <button class="cetas-button cetas-button-small" style={{ height: '40px' }}
                      onClick={this.subSpecialityAdd.bind(this)}
                    >Add Item</button>
                    <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                      onClick={this.toggleaddSubSpecialityModal.bind(this)}>Cancel</button>
                  </ModalFooter>
                </Modal>

                {/*  Edit Sub Speciality Modal*/}
                <Modal isOpen={this.state.editSubSpecialityModal} toggle={this.toggleeditSubSpecialityModal.bind(this)} >
                  <ModalHeader toggle={this.toggleeditSubSpecialityModal.bind(this)}
                    cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
                    style={{ borderBottom: '0px' }}>
                    <h3 style={{ justifyContent: 'center', marginTop: '10px' }} id="login-tit">Edit Item</h3>
                  </ModalHeader>
                  <ModalBody>
                    <FormGroup>
                      <Label for="category">Update {this.state.SubSpecialityTypeName}</Label>
                      <Input id="title" value={this.state.editSubSpecialityData.sub_speciality_type} onChange={(e) => {
                        let { editSubSpecialityData } = this.state;
                        editSubSpecialityData.sub_speciality_type = e.target.value;
                        this.setState({ editSubSpecialityData });
                      }} />
                      <div style={{ fontSize: 12, color: "red" }}>{this.state.SubSpecialityEditErr}</div>
                    </FormGroup>
                  </ModalBody>
                  <ModalFooter>
                    <button class="cetas-button cetas-button-small" style={{ height: '40px' }}
                      onClick={this.updateSubSpeciality.bind(this)}>Update</button>
                    <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                      onClick={this.toggleeditSubSpecialityModal.bind(this)}>Cancel</button>
                  </ModalFooter>
                </Modal>


                {/* Add Engagement Type modal */}
                <Modal isOpen={this.state.isAddEngagement} toggle={this.toggleaddEngagementModal.bind()} style={{ padding: '15px' }}>
                  <ModalHeader toggle={this.toggleaddEngagementModal.bind(this)} style={{ border: 'none' }}>
                    <h3 style={{ padding: '20px 50px 0px 50px' }}>Add New Type</h3></ModalHeader>
                  <ModalBody style={{ padding: '20px 80px 10px 80px' }}>
                    <FormGroup>
                      <Label for="category">Engegement Type</Label>
                      <Input id="emp_name" name="emp_name" value={this.state.addDataEngagement.engagement_type} onChange={(e) => {
                        let { addDataEngagement } = this.state;
                        addDataEngagement.engagement_type = e.target.value;
                        this.setState({ addDataEngagement });
                      }}
                      />
                      <div style={{ fontSize: '12', color: 'red' }}>
                        {this.state.typeErrorEngagement}
                      </div>
                    </FormGroup>
                  </ModalBody>
                  <ModalFooter>
                    <button class="cetas-button cetas-button-small" style={{ height: '40px' }}
                      onClick={this.handlesubmitAddEngagement.bind(this)}> Submit </button>
                    <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                      onClick={this.toggleaddEngagementModal.bind()} >Cancel</button>
                  </ModalFooter>
                </Modal>

                {/* Edit Engagement Type modal */}
                <Modal isOpen={this.state.isEditEngagement} toggle={this.editEngagement.bind(this)} style={{ padding: '15px' }}>
                  <ModalHeader toggle={this.editEngagement.bind(this)} style={{ border: 'none' }}>
                    <h3 style={{ padding: '20px 50px 0px 50px' }}>Edit EngagementType</h3></ModalHeader>
                  <ModalBody style={{ padding: '20px 80px 10px 80px' }}>
                    <FormGroup>
                      <Input id="title" value={this.state.editDataEngagement.id} readOnly hidden />
                    </FormGroup>
                    <FormGroup>
                      <Label for="category">Edit Engagement Type</Label>
                      <Input id="title" value={this.state.editDataEngagement.engagement_type} onChange={(e) => {
                        let { editDataEngagement } = this.state;
                        editDataEngagement.engagement_type = e.target.value;
                        this.setState({ editDataEngagement });
                      }}
                      />
                      <div style={{ fontSize: '12', color: 'red' }}>
                        {this.state.typeErrorEngagement}
                      </div>
                    </FormGroup>
                  </ModalBody>
                  <ModalFooter>
                    <button class="cetas-button cetas-button-small" style={{ height: '40px' }}
                      onClick={this.handlesubmitEditEngagement.bind(this)}> Submit </button>
                    <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                      onClick={this.editEngagement.bind(this)} >Cancel</button>
                  </ModalFooter>
                </Modal>

                {/* Add Publication */}
                <Modal isOpen={this.state.isAddPublication} toggle={this.toggleaddPublicationModal.bind()} style={{ padding: '15px' }}>
                  <ModalHeader toggle={this.toggleaddPublicationModal.bind(this)} style={{ border: 'none' }}>
                    <h3 style={{ padding: '20px 50px 0px 50px' }}>Add New Position</h3></ModalHeader>
                  <ModalBody style={{ padding: '20px 80px 10px 80px' }}>
                    <FormGroup>
                      <Label for="category">Position Authorship</Label>
                      <Input id="emp_name" name="emp_name" value={this.state.addDataPublication.publication_position_type} onChange={(e) => {
                        let { addDataPublication } = this.state;
                        addDataPublication.publication_position_type = e.target.value;
                        this.setState({ addDataPublication });
                      }}
                      />
                      <div style={{ fontSize: '12', color: 'red' }}>
                        {this.state.typeErrorPublication}
                      </div>
                    </FormGroup>
                  </ModalBody>
                  <ModalFooter>
                    <button class="cetas-button cetas-button-small" style={{ height: '40px' }}
                      onClick={this.handlesubmitAddPublication.bind(this)}> Submit </button>
                    <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                      onClick={this.toggleaddPublicationModal.bind()} >Cancel</button>
                  </ModalFooter>
                </Modal>

                {/* Edit Publication Form */}
                <Modal isOpen={this.state.isEditPublication} toggle={this.editPublication.bind(this)} style={{ padding: '15px' }}>
                  <ModalHeader toggle={this.editPublication.bind(this)} style={{ border: 'none' }}>
                    <h3 style={{ padding: '20px 50px 0px 50px' }}>Edit EngagementType</h3></ModalHeader>
                  <ModalBody style={{ padding: '20px 80px 10px 80px' }}>
                    <FormGroup>
                      <Input id="title" value={this.state.editDataPublication.id} readOnly hidden />
                    </FormGroup>
                    <FormGroup>
                      <Label for="category">Edit Position Authorship</Label>
                      <Input id="title" value={this.state.editDataPublication.publication_position_type} onChange={(e) => {
                        let { editDataPublication } = this.state;
                        editDataPublication.publication_position_type = e.target.value;
                        this.setState({ editDataPublication });
                      }}
                      />
                      <div style={{ fontSize: '12', color: 'red' }}>
                        {this.state.typeErrorPublication}
                      </div>
                    </FormGroup>
                  </ModalBody>
                  <ModalFooter>
                    <button class="cetas-button cetas-button-small" style={{ height: '40px' }}
                      onClick={this.handlesubmitEditPublication.bind(this)}> Submit </button>
                    <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                      onClick={this.editPublication.bind(this)} >Cancel</button>
                  </ModalFooter>
                </Modal>

                {/* Add Event */}
                <Modal isOpen={this.state.isAddEvent} toggle={this.toggleaddEventModal.bind()} style={{ padding: '15px' }}>
                  <ModalHeader toggle={this.toggleaddEventModal.bind(this)} style={{ border: 'none' }}>
                    <h3 style={{ padding: '20px 50px 0px 50px' }}>Add New Event</h3></ModalHeader>
                  <ModalBody style={{ padding: '20px 80px 10px 80px' }}>
                    <FormGroup>
                      <Label for="category">Event Roles</Label>
                      <Input id="emp_name" name="emp_name" value={this.state.addDataEvent.event_role_type} onChange={(e) => {
                        let { addDataEvent } = this.state;
                        addDataEvent.event_role_type = e.target.value;
                        this.setState({ addDataEvent });
                      }}
                      />
                      <div style={{ fontSize: '12', color: 'red' }}>
                        {this.state.typeErrorEvent}
                      </div>
                    </FormGroup>
                  </ModalBody>
                  <ModalFooter>
                    {/* <Button color="danger" onClick={this.AddEmployee}> Submit </Button>  */}
                    <button class="cetas-button cetas-button-small" style={{ height: '40px' }}
                      onClick={this.handlesubmitAddEvent.bind(this)}> Submit </button>
                    <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                      onClick={this.toggleaddEventModal.bind()} >Cancel</button>
                  </ModalFooter>
                </Modal>

                {/* Edit Event*/}
                <Modal isOpen={this.state.isEditEvent} toggle={this.editEvent.bind(this)} style={{ padding: '15px' }}>
                  <ModalHeader toggle={this.editEvent.bind(this)} style={{ border: 'none' }}>
                    <h3 style={{ padding: '20px 50px 0px 50px' }}>Edit Event Role Type</h3></ModalHeader>
                  <ModalBody style={{ padding: '20px 80px 10px 80px' }}>
                    <FormGroup>
                      {/* <Label for="category">Edit Event ID</Label> */}
                      <Input id="title" value={this.state.editDataEvent.id} readOnly hidden />
                    </FormGroup>
                    <FormGroup>
                      <Label for="category">Edit Event Roles</Label>
                      <Input id="title" value={this.state.editDataEvent.event_role_type} onChange={(e) => {
                        let { editDataEvent } = this.state;
                        editDataEvent.event_role_type = e.target.value;
                        this.setState({ editDataEvent });
                      }}
                      />
                      <div style={{ fontSize: '12', color: 'red' }}>
                        {this.state.typeErrorEvent}
                      </div>
                    </FormGroup>
                  </ModalBody>
                  <ModalFooter>
                    {/* <Button color="danger" onClick={this.EditEmployee} >Update </Button>  */}
                    <button class="cetas-button cetas-button-small" style={{ height: '40px' }}
                      onClick={this.handlesubmitEditEvent.bind(this)}> Submit </button>
                    <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                      onClick={this.editEvent.bind(this)} >Cancel</button>
                  </ModalFooter>
                </Modal>

                {/* Add CT */}
                <Modal isOpen={this.state.isAddCT} toggle={this.toggleaddCTModal.bind()} style={{ padding: '15px' }}>
                  <ModalHeader toggle={this.toggleaddCTModal.bind(this)} style={{ border: 'none' }}>
                    <h3 style={{ padding: '20px 50px 0px 50px' }}>Add New CT Role</h3></ModalHeader>
                  <ModalBody style={{ padding: '20px 80px 10px 80px' }}>
                    <FormGroup>
                      <Label for="category">CT Roles</Label>
                      <Input id="emp_name" name="emp_name" value={this.state.addDataCT.ct_role_type} onChange={(e) => {
                        let { addDataCT } = this.state;
                        addDataCT.ct_role_type = e.target.value;
                        this.setState({ addDataCT });
                      }}
                      />
                      <div style={{ fontSize: '12', color: 'red' }}>
                        {this.state.typeErrorCT}
                      </div>
                    </FormGroup>
                  </ModalBody>
                  <ModalFooter>
                    <button class="cetas-button cetas-button-small" style={{ height: '40px' }}
                      onClick={this.handlesubmitAddCT.bind(this)}> Submit </button>
                    <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                      onClick={this.toggleaddCTModal.bind()} >Cancel</button>
                  </ModalFooter>
                </Modal>

                {/* Edit CT */}
                <Modal isOpen={this.state.isEditCT} toggle={this.editCT.bind(this)} style={{ padding: '15px' }}>
                  <ModalHeader toggle={this.editCT.bind(this)} style={{ border: 'none' }}>
                    <h3 style={{ padding: '20px 50px 0px 50px' }}>Edit EngagementType</h3></ModalHeader>
                  <ModalBody style={{ padding: '20px 80px 10px 80px' }}>
                    <FormGroup>
                      {/* <Label for="category">Edit CT Roles ID</Label> */}
                      <Input id="title" value={this.state.editDataCT.id} readOnly hidden />
                    </FormGroup>
                    <FormGroup>
                      <Label for="category">Edit CT Roles</Label>
                      <Input id="title" value={this.state.editDataCT.ct_role_type} onChange={(e) => {
                        let { editDataCT } = this.state;
                        editDataCT.ct_role_type = e.target.value;
                        this.setState({ editDataCT });
                      }}
                      />
                      <div style={{ fontSize: '12', color: 'red' }}>
                        {this.state.typeErrorCT}
                      </div>
                    </FormGroup>
                  </ModalBody>
                  <ModalFooter>
                    {/* <Button color="danger" onClick={this.EditEmployee} >Update </Button>  */}
                    <button class="cetas-button cetas-button-small" style={{ height: '40px' }}
                      onClick={this.handlesubmitEditCT.bind(this)}> Submit </button>
                    <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                      onClick={this.editCT.bind(this)} >Cancel</button>
                  </ModalFooter>
                </Modal>

                {/* Add Participants */}
                <Modal isOpen={this.state.isAddPart} toggle={this.toggleaddPartModal.bind()} style={{ padding: '15px' }}>
                  <ModalHeader toggle={this.toggleaddPartModal.bind(this)} style={{ border: 'none' }}>
                    <h3 style={{ padding: '20px 50px 0px 50px' }}>Add New Participants</h3></ModalHeader>
                  <ModalBody style={{ padding: '20px 80px 10px 80px' }}>
                    <FormGroup>
                      <Label for="category">No of Participants</Label>
                      <Input id="emp_name" name="emp_name" value={this.state.addDataPart.no_of_participants} onChange={(e) => {
                        let { addDataPart } = this.state;
                        addDataPart.no_of_participants = e.target.value;
                        this.setState({ addDataPart });
                      }}
                      />
                      <div style={{ fontSize: '12', color: 'red' }}>
                        {this.state.typeErrorPart}
                      </div>
                    </FormGroup>
                  </ModalBody>
                  <ModalFooter>
                    {/* <Button color="danger" onClick={this.AddEmployee}> Submit </Button>  */}
                    <button class="cetas-button cetas-button-small" style={{ height: '40px' }}
                      onClick={this.handlesubmitAddPart.bind(this)}> Submit </button>
                    <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                      onClick={this.toggleaddPartModal.bind()} >Cancel</button>
                  </ModalFooter>
                </Modal>

                {/* Edit Participants */}
                <Modal isOpen={this.state.isEditPart} toggle={this.editPart.bind(this)} style={{ padding: '15px' }}>
                  <ModalHeader toggle={this.editPart.bind(this)} style={{ border: 'none' }}>
                    <h3 style={{ padding: '20px 50px 0px 50px' }}>Edit Participants</h3></ModalHeader>
                  <ModalBody style={{ padding: '20px 80px 10px 80px' }}>
                    <FormGroup>
                      {/* <Label for="category">Edit Participants ID</Label> */}
                      <Input id="title" value={this.state.editDataPart.id} readOnly hidden />
                    </FormGroup>
                    <FormGroup>
                      <Label for="category">Edit No of Participants</Label>
                      <Input id="title" value={this.state.editDataPart.no_of_participants} onChange={(e) => {
                        let { editDataPart } = this.state;
                        editDataPart.no_of_participants = e.target.value;
                        this.setState({ editDataPart });
                      }}
                      />
                      <div style={{ fontSize: '12', color: 'red' }}>
                        {this.state.typeErrorPart}
                      </div>
                    </FormGroup>
                  </ModalBody>
                  <ModalFooter>
                    {/* <Button color="danger" onClick={this.EditEmployee} >Update </Button>  */}
                    <button class="cetas-button cetas-button-small" style={{ height: '40px' }}
                      onClick={this.handlesubmitEditPart.bind(this)}> Submit </button>
                    <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                      onClick={this.editPart.bind(this)} >Cancel</button>
                  </ModalFooter>
                </Modal>

              </div>
            </div>
          </div>
          <footer class="footer-fixed">
            <div class="cetas-footer">
              <div class="container">
                <p>All rights reserved. www.cetashealthcare.com</p>
              </div>
            </div>
          </footer>
        </div>
      </body>
    );
  }
}

export default FormManagement;