import React, { Component} from 'react';
import { Redirect, Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, customFilter, selectFilter, Comparator, FILTER_TYPES } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
//import { Button, Spinner , Modal} from 'reactstrap';
import axios from 'axios';
import LoadingBar from "react-top-loading-bar";
import Swal from 'sweetalert2';
import ManagerHeader from './ManagerHeader';
import { RingLoader, FadeLoader, DotLoader, PropagateLoader } from 'react-spinners';
 import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, Spinner } from 'reactstrap';
import { Multiselect } from 'react-widgets';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
//import { useState } from 'react';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import SpiningImage from '../Assets/Images/Spinner-1s-96px.svg';


const moment = require("moment");
const headerSortingStyle = { backgroundColor: 'grey' };
const { SearchBar } = Search;

const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total ml-2">
        Showing {from} to {to} of {size} Results
    </span>
);

// session storage item:
// manager_email : is the email of login manager
// manager_id: is the id of login manager
// manager_name: is the name of login manager
// project_id: slected project id from table
// p_name: slected project name from table
// idForCreateNewProject: Id for Create new project
// project_id_for_edit_project: Id of Edit project 
// status_for_editProject: status for selected project tom edit
// kol_unique_id: kol id for go to managerqc form

//8368053734

class ImportCompletefun extends Component {
    fileObj = [];
    fileArray = [];
    
    constructor(props) {

        super(props)


        var today = new Date(),
        date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
         
        this.state = {
            file: '',
            system_id: '',
            delete_id: '',
            PreliminaryDataErr:'',
            ExportToKOLDataErr:'',
            UploadPublicationDataErr:'',
            PhotoUploadStatus:'',
            drop_action_type:'',
            totalSelectedphoto:'',
            totalMappedkol:0,
            ShowProgressMessage:false,

            old_total_kol:0,
            new_total_kol:5,

            total_row_data:0,

            uploadkolident:'1. Upload kol Row Data',
            
            uploadkol_pub:'Upload Publication Data',
            
            ExportToKOLDatalabel:'4. Export Row Data to kol',
            KOLMappinglabel:'Kol Mapping',

            //To show Spinner
            ShowBar: false,
            LoadingBar:false,
            ShowProcedure: false,
            
            duplicatekollist:'',
          
            KOLList: [],
            ResercherData: [],
            // manager id and name
            // manager_id: this.props.location.state.manager_id,
            // manager_name: this.props.location.state.manager_name,
            manager_id: '',
            manager_name: '',
            manager_email: '',

            // For passing to edit project page
          //  project_id: 'CHCPI0078',
            project_id: 'CHCPI005',
            p_name: '',
            status_for_editProject: '',


            // for incentive modal
            kol_researcher_info: {
                kol_id: '',
                project_id: '',
                researcher_id: '',
            },

            // redirect 
            redirectCreateProject: false,
            redirectpreliminarykolNav:false,
            RedirectManagerDashoard:false,
            redirectEditProject: false,
            redirectManagerKolList: false,

            EditIncentiveModal: false,
           // // Publication Excel
        
            PublicationExcelFile: '',
            PublicationExcelFileName: '',
            FinalPublicationExcelFile: '',

            PreliminaryExcelFile: '',
            PreliminaryExcelFileName: '',
            FinalPreliminaryExcelFile: '',
            
          
            filephoto: [null],
            myarr:[],
            prilimary_photo:[],
            PrilimaryImageFile: '',
            PrilimaryImageFileName: '',
            FinalPrilimaryImageFile: '',

            // State for saving data
            projectList: [],
            idForCreateNewProject: '',

            onPriceFilter: null,
            onProductilters: null,

            // State for displaying data
            status: '',

          
            // Column state for Bootstrap Table 
            columns: [
                
                // {
                //     dataField: 'kol_type',
                //     text: 'Kol Type',
                // },

                {
                    dataField: 'VLOOKUP_Name',
                    text: 'VLOOKUP Name',
                    sort: true,
                //    // formatter: this.link,
                    filter: textFilter(),

                    filterRenderer: (onFilter, column) => {
                        this.onTextFilter = onFilter;
                        return null;
                    },
                  
                   
                },

                {
                    dataField: 'First_Name',
                    text: 'First Name',
                },
               
                {
                    dataField: 'Last_Name',
                    text: 'Last Name',
                },

                {
                    
                    dataField: "Affiliation",
                    text: "Affiliation",
                    //formatter: this.linkFollow,
                    
                },

                {
                    
                    dataField: "Email",
                    text: "Email",
                    //formatter: this.linkFollow,
                    
                },

                {
                    
                    dataField: "City",
                    text: "City",
                    //formatter: this.linkFollow,
                    
                },

                {
                    
                    dataField: "State",
                    text: "State",
                    //formatter: this.linkFollow,
                    
                },

                {
                    
                    dataField: "Country",
                    text: "Country",
                    //formatter: this.linkFollow,
                    
                },
               
            
            ]
            // End of state
        }
        // End of constructor
    }

    kolstatuscolour = (cell, row) => {
        var id = row.project_id;
        var project_name = row.p_name;
        var project_status = row.project_status;
        var col='';
        var txt='';
      
        if (row.kol_status == "Prelim") {
           
            col= '#41A2FF'
            txt=row.kol_status
        
        } else if (row.kol_status == "Approved") {
            
            col= '#1FCE39'
            txt=row.kol_status
         
        } else if (row.kol_status == "Submited") {
            
            col= '#41A2FF'
            txt=row.kol_status
        

        } else if (row.kol_status == "Rejected") {
        
                col= '#e51c1c'
                txt=row.kol_status
        
        } else if (row.kol_status == "Work In Progress") {
            
                col= '#FF7F41'
                txt=row.kol_status
            
        } else if (row.kol_status == "Prelim Approved") {
            
                col= '#1FCE39'
                txt=row.kol_status
            
        } else if (row.kol_status == "Prelim Rejected") {
            
                col= '#1FCE39'
                txt='Updated'
            
        }
        else if (row.kol_status == "Prelim Pending") {
               col= '#41A2FF'
               txt=row.kol_status
        
        
        } else if (row.kol_status == "Pending") {
            col= '#41A2FF'
            txt=row.kol_status
        
     
     }else if (row.kol_status == "Preliminarykol") {
           
        col= '#41A2FF'
        txt=row.kol_status
    
    }
        
      
        return (
          <div style={{color:col}}>
          {/* {row.kol_status} */}
            {txt}
          </div>
        )
      }

    projectstatuscolour = (cell, row) => {
        var id = row.project_id;
        var project_name = row.p_name;
        var project_status = row.project_status;
        var col='';
      
        if (row.project_status == "work in progress") {
            col= '#FF7F41'
            
                } else if (row.project_status == "Ongoing") {
                        col= '#1FCE39'
            
                } else if (row.project_status == "Upcoming") {
                    
                        col= '#41A2FF'
                    

                } else if (row.project_status == "Completed") {
                    
                        col= '#FF7F41'
                    
                }
      
      
      
        return (
          <div style={{color:col}}>
           {row.project_status}
          </div>
        )
      }

      linkFollow = (cell, row, rowIndex, formatExtraData) => {
        const isAnonymous=false;
        var kolIdForCredential = row.kol_unique_id;
        var status=row.kol_status;
        return (
            <div>
                <button className="cetas-button cetas-button-small mr-2"  disabled={row.kol_status=="Prelim Rejected" ? true :false}
                    onClick={this.toggleEditIncentiveModal.bind(this, kolIdForCredential)}>
                    {row.kol_status=="Prelim Rejected" ? 'Updated' :'Update'}
                   </button>
                {/* {alert(status) } */}
            </div>
        );

        
        
    }

    toggleEditIncentiveModal(kolIdForCredential) {
       // alert(kolIdForCredential);
        let { kol_researcher_info } = this.state;
         kol_researcher_info.kol_id = kolIdForCredential;

        this.setState({
            kol_researcher_info,
            kolIdForCredential: kolIdForCredential,
            EditIncentiveModal: !this.state.EditIncentiveModal,
            //PasswordErr: '',
        });
    }

    UpdateResearcherName = async () => {

        //alert( this.state.project_id);
        //alert(this.state.kolIdForCredential);
        console.log("kolIdForCredential", this.state.kolIdForCredential)
        console.log("project_id", this.state.project_id)
        console.log("kol_researcher_info:", this.state.kol_researcher_info)


       var $kol_id =this.state.kolIdForCredential;
      // let $project_id="CHCPI0078";
       var $selected_researcher= this.state.kol_researcher_info['researcher_id'];
       // alert($selected_researcher);


        this.setState({
            ShowProcedure: true,
        })
        const url = global.websiteUrl+'/service/KOL/update_researcher_id';
        let formData = new FormData();
       // formData.append('kol_researcher_info', JSON.stringify(this.state.kol_researcher_info));
        formData.append('project_id',this.state.project_id);
        formData.append('kol_id',$kol_id);
        formData.append('researcher_id',$selected_researcher);

        const config = {
            //   headers: { 'content-type': 'multipart/form-data' }
        }

        axios.post(url, formData, config)
            .then(response => {
                console.log(response);
                var testStateData = response.data;
                console.log("testStateData", JSON.stringify(testStateData));
                if (response.data.status == true) {
                    Swal.fire({
                        icon: 'success',
                        text: response.data.message,
                        confirmButtonColor: '#d33',
                        confirmButtonText: 'OK'
                    })
                    this.setState({
                        ShowProcedure: false,
                    })
                    //this.refreshProjectList();
                    window.location.reload();
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        text: response.data.message,
                        confirmButtonColor: '#d33',
                        confirmButtonText: 'OK'
                    })
                    this.setState({
                        ShowProcedure: false,
                    })
                }
            })
            .catch(error => {
                console.log(error);
            });
        this.setState({
            EditIncentiveModal: false,
        })
    }
    
    
    // Method to fetch data from API when component Mounted
    componentDidMount = async () => {

        let getSession_Manager_ID = await sessionStorage.getItem('id')
        if (getSession_Manager_ID !== null) {
            await this.setState({
                manager_id: getSession_Manager_ID,
            })
        }

        let getSession_Manager_Name = await sessionStorage.getItem('name')
        if (getSession_Manager_Name !== null) {
            await this.setState({
                manager_name: getSession_Manager_Name,
            })
        }

        // let getSession_Project_Id = await sessionStorage.getItem('project_id')
        // if (getSession_Project_Id !== null) {
        //     let { kol_researcher_info } = this.state;
        //     kol_researcher_info.project_id = getSession_Project_Id;

        //     await this.setState({
        //         kol_researcher_info,
        //         project_id: getSession_Project_Id,
        //     })
        // }

        let getSession_Project_Name = await sessionStorage.getItem('p_name')
        if (getSession_Project_Name !== null) {
            await this.setState({
                p_name: getSession_Project_Name,
            })
        }

        this.GetMappedKolList();

        const options = {
            method: 'POST',
          }
          //  Speciality type data
          fetch(global.websiteUrl+'/service/KOL/get_researcher_Name_list', options)
            .then((Response) => Response.json())
            .then((findresponse) => {
              console.log(findresponse)
              var testDataSpeciality = findresponse.researcher_list;
              console.log("testDataSpeciality", JSON.stringify(testDataSpeciality));
              this.setState({
                ResercherData: findresponse.researcher_list,
              })
            })
      

    console.log("getSession_Manager_ID ====", getSession_Manager_ID)
    console.log("getSession_Manager_Name ====", getSession_Manager_Name)
   // console.log("getSession_Project_Id ====", getSession_Project_Id)
    console.log("getSession_Project_Name ====", getSession_Project_Name)

    this.setState({
        ShowBar: true,
    })

    // KOL List Fetch
    const url = global.websiteUrl+'/service/KOL/get_row_data_kol_list';
   //const url = global.websiteUrl+'/service/KOL/get_Preliminary_KOL';
     
   let formData = new FormData();
     formData.append('project_id', this.state.project_id);
    //formData.append('project_id', 'CHCPI0078');
    formData.append('user_type', 'Manager');

    const config = {
        //   headers: { 'content-type': 'multipart/form-data' }
    }

    axios.post(url, formData, config)
        .then(response => {
            console.log(response);
            var lgdata = response.data.KOL_Personal_Info;
            console.log("Fetched data", JSON.stringify(lgdata));
            var lgstatus = response.data.status;
            console.log("Fetched status", JSON.stringify(lgstatus));
            console.log("Message from fetched api", response.data.message);
            var kolIds = response.data.kol_system_id;
            console.log("kolIds", JSON.stringify(kolIds));

          // alert(response.data.KOL_Personal_Info);

            if (lgdata) {
                this.setState({
                    KOLList: response.data.KOL_Personal_Info,
                    total_row_data:response.data.KOL_Personal_Info
                })
               
               
            } else {
                this.setState({
                    KOLList: [],
                    total_row_data:0
                })
            }

            this.setState({
                ShowBar: false,
                // showAddnewKol : true
            })


        });


        // alert(this.state.old_total_kol)
        // alert(this.state.new_total_kol)
        // if(this.state.old_total_kol == this.state.new_total_kol)
        // {
        //     this.setState({
        //         new_total_kol:6                   
            
        //         })
        //         this.ExportRowDataToKOL();
        // }  



    }


    
    // STARNGE  
    onTextChange = (event) => {
        console.log(event.target.value)
        const { value } = event.target;
        this.onTextFilter(value);
    };

    //STRANGE
    onFilter = (event) => {
        console.log(event.target.value)
        const { value } = event.target;
        this.onStatusFilters(value);
    };

    //Funtion to apply links to project names
    link = (cell, row, rowIndex, formatExtraData) => {
        console.log(cell)
        console.log(row)
        var status = row.project_status;
        var project_id = row.project_id;
        return (
            <div>
                <a onClick={this.GoToEditProject.bind(this, project_id, status)}> {row.p_name} </a>
            </div>
        )
    }

    GoToEditProject(project_id, status) {
        console.log("in edit project redirect")
        console.log("status", status)
        // if( status == 'work in progress' || status == 'new' ){
        if (status == 'Upcoming' || status == 'Ongoing') {
            this.setState({
                redirectEditProject: true,
                project_id: project_id,
                status_for_editProject: status,
            })
        }
    }

    linkProjectComment = (cell, row, rowIndex, formatExtraData) => {
        console.log(cell)
        console.log("linkProjectComment",row)
        var project_id = row.project_id;
        var p_name = row.p_name;
        var project_status = row.project_status;
        return (
            <div>
                <a style={{
           color: '#069',
          textDecoration: 'underline', cursor: 'pointer'
        }} onClick={this.GoToManagerKolList.bind(this, project_id, p_name,project_status)}> {row.p_name} </a>
            </div>
        )
    }

    GoToManagerKolList(project_id, p_name, project_status) {
        console.log("in manager kol list redirect")
        sessionStorage.setItem('project_id', project_id)
        sessionStorage.setItem('p_name', p_name)
        if(project_status == "Upcoming"){
            Swal.fire({
                icon: 'error',
                text: "Project is in upcoming state",
                // text: 'Wrong Username or Password!',
                confirmButtonColor: '#d33',
                confirmButtonText: 'OK'
            });     
        }
        else{
            this.setState({
                redirectManagerKolList: true,
                // project_id: project_id, 
                // p_name: p_name,
            })
        }
    }

    // rowEvents = {
    // onClick: (e, row, rowIndex) => {
    //     console.log('clicked on row with index:',rowIndex);
    //         console.log(row)
    //         var project_id = row.project_id;
    //         var p_name = row.p_name;

    //         this.setState({ 
    //             redirectManagerKolList: true,
    //             project_id: project_id, 
    //             p_name: p_name,
    //         })
    // },
    // };

    createProjectNav() {
        this.setState({ redirectCreateProject: true })
    }

    BackToManagerDashboard() {
        this.setState({ RedirectManagerDashoard: true })
    }

    preliminarykolNav()
    {
        this.setState({ redirectpreliminarykolNav: true })

    }



    actionsEditProjectForm = (cell, row, rowIndex, formatExtraData) => {
        // var status = row.project_status;
        // var project_id = row.project_id;
        if (row.project_status == 'Upcoming') {
            return (
                <div>
                    <button class="cetas-button cetas-button-small"
                        onClick={this.editProjectForm.bind(this, row.project_id, row.project_status)}>
                        Edit
                    </button>
                </div>
            )
        }
        else if (row.project_status == 'Ongoing') {
            return (
                <div>
                    <button class="cetas-button cetas-button-small"
                        onClick={this.editProjectForm.bind(this, row.project_id, row.project_status)}
                    >
                        Manage
                    </button>
                </div>
            )
        }
        return (
            <div>
                {/* <button class="cetas-button cetas-button-small"
                    onClick={this.delete.bind(this, row.project_id, row.project_status)}
                >
                    Delete
                </button> */}
            </div>
        )

    };

    editProjectForm(project_id, status) {
        console.log("In Action Edit Project form", project_id)
        this.setState({
            redirectEditProject: true,
            // project_id: project_id, 
            // status_for_editProject: status,
        })
        sessionStorage.setItem('project_id_for_edit_project', project_id)
        sessionStorage.setItem('status_for_editProject', status)
    }

    

//PrilimaryData Upload upload resume function
onUploadPublication = async (event) => {
  
this.setState({
    ShowBar:true,
    ShowProcedure: true,
    LoadingBar:true,
    })

   
//const filesFormats= [".xlsx","application/xls"];
const filesFormats= [".xlsx","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
console.log(event.target.file);
if(event.target.files.length !=0)
{
const isRightFormat=filesFormats.includes(event.target.files[0].type);
console.log(isRightFormat);
  if(isRightFormat)
    {
        await this.setState({
            
            PublicationExcelFile: event.target.files[0],
            PublicationExcelFileName: event.target.files[0].name,
            FinalPublicationExcelFile: event.target.files[0].name,
            UploadPublicationDataErr:'',

        });
     console.log("event of publication excel", this.state.PublicationExcelFileName)
}
else
{
  this.setState( {
    UploadPublicationDataErr:"only .xlsx file allowed",
  });
  
}
}

this.setState({
    ShowBar:false,
    ShowProcedure: false,
    LoadingBar:false,
  })

}



    //PrilimaryData Upload upload resume function
    onUploadPreliminary = async (event) => {
         this.setState({
            ShowBar:true,
            ShowProcedure: true,
            LoadingBar:true,
          })

        //const filesFormats= [".xlsx","application/xls"];
        const filesFormats= [".xlsx","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
        console.log(event.target.file);

       if(event.target.files.length !=0)
       {
        const isRightFormat=filesFormats.includes(event.target.files[0].type);
        console.log(isRightFormat);
        if(isRightFormat)
        {       
        await this.setState({            
            PreliminaryExcelFile: event.target.files[0],
            PreliminaryExcelFileName: event.target.files[0].name,
            FinalPreliminaryExcelFile: event.target.files[0].name,
            PreliminaryDataErr:'',

        });
         console.log("event of Preliminary excel", this.state.PreliminaryExcelFileName)
    }
    else
    {
      this.setState({  
        PreliminaryDataErr:"only .xlsx file allowed",
      });
      
    }

}
  
    this.setState({
        ShowBar:false,
        ShowProcedure: false,
        LoadingBar:false,
      })
   
    }



    UploadPreliminaryExcel = async (event) => {
             
            console.log("FinalPreliminaryExcelFile",this.state.manager_id,this.state.PreliminaryExcelFile)
              if(this.state.PreliminaryExcelFileName == 'Identification_Template.xlsx')
              {
               const urlFile1 = global.websiteUrl+'/service/Deep_Profiles/Upload_complete_row_data';
                let formDataFile1 = new FormData();
                formDataFile1.append('file', this.state.PreliminaryExcelFile );
                formDataFile1.append('manager_id', this.state.manager_id);
                this.setState({
                    // ShowBar:true,   
                    // ShowProcedure: true,
                    // LoadingBar:true,
                    uploadkolident:'Please Wait...',
                    ShowProgressMessage:true,
                })
                const configfile1 = {
                  headers: { 'content-type': 'multipart/form-data' }
                }
                
                axios.post(urlFile1, formDataFile1, configfile1)
                  .then(response => {
                       if (response.data.statusCode == 200 )
                          {  
                            
                           // start mapping kol
                           this.kolmapping();

                            //   this.setState({
                            //       ShowBar:false,
                            //       ShowProcedure: false,
                            //       LoadingBar:false,
                            //       PreliminaryDataErr:"Upload Success",
                            //    })

                            //   Swal.fire({
                            //   icon: 'success',
                            //   text: 'Upload Success',
                            //   //text: "Success",
                            //   confirmButtonColor: '#d33',
                            //   confirmButtonText: 'OK'
                            //   })     
                              
                            //   window.location.reload(true);
                            
                          }
                          else
                          {
                              Swal.fire({
                                  icon: 'error',
                                  text: 'Upload failed',
                                  //text: "Success",
                                  confirmButtonColor: '#d33',
                                  confirmButtonText: 'OK'
                                  })
                                
                                    this.setState({
                                        PreliminaryDataErr:"Upload failed",                          
                                        ShowBar:false,
                                        ShowProcedure: false,
                                        LoadingBar:false,
                                    })

                                    window.location.reload(true);
                         }

                })

                }
                else
                {

                    this.setState({
                        PreliminaryDataErr:"Select proper excel sheet ",
                        ShowBar:false,
                        ShowProcedure: false,
                        LoadingBar:false,
                        
                    })
                }
       
    }

    


    UploadPublicationData = async (event) => {
             
        console.log("FinalPublicationExcelFile",this.state.manager_id,this.state.PublicationExcelFile)
          if(this.state.PublicationExcelFileName == 'Publication_Template.xlsx')
          {
           const urlFile1 = global.websiteUrl+'/service/Deep_Profiles/Upload_publication_row_data';
            let formDataFile1 = new FormData();
            formDataFile1.append('file', this.state.PublicationExcelFile );
            formDataFile1.append('manager_id', this.state.manager_id);
            this.setState({
                // ShowBar:true,   
                // ShowProcedure: true,
                // LoadingBar:true,
                
                uploadkol_pub:'Please Wait...',
                ShowProgressMessage:true,
            })
            const configfile1 = {
              headers: { 'content-type': 'multipart/form-data' }
            }
            
            axios.post(urlFile1, formDataFile1, configfile1)
              .then(response => {
                   if (response.data.statusCode == 200 )
                      { 
                        this.kol_mapping();  
                        //   this.setState({
                        //     //   ShowBar:false,
                        //     //   ShowProcedure: false,
                        //     //   LoadingBar:false,
                        //       UploadPublicationDataErr:"Publication Upload Success",
                        //   })

                        //   Swal.fire({
                        //   icon: 'success',
                        //   text: 'Upload Success',
                        //   //text: "Success",
                        //   confirmButtonColor: '#d33',
                        //   confirmButtonText: 'OK'
                        //   })
                        //   window.location.reload(true);
                      }
                      else
                      {
                          Swal.fire({
                              icon: 'error',
                              text: 'Upload failed',
                              //text: "Success",
                              confirmButtonColor: '#d33',
                              confirmButtonText: 'OK'
                              })
                                this.setState({
                                    UploadPublicationDataErr:"Upload failed",                          
                                    ShowBar:false,
                                    ShowProcedure: false,
                                    LoadingBar:false,
                                })

                                window.location.reload(true);
                     }

            })

            }
            else
            {

                this.setState({
                    UploadPublicationDataErr:"Select proper excel sheet ",
                    ShowBar:false,
                    ShowProcedure: false,
                    LoadingBar:false,
                    
                })
            }
   
}

refresh_kol_list()
{
        // KOL List Fetch
        const url = global.websiteUrl+'/service/KOL/get_row_data_kol_list';
        //const url = global.websiteUrl+'/service/KOL/get_Preliminary_KOL';
          
        let formData = new FormData();
          formData.append('project_id', this.state.project_id);
         //formData.append('project_id', 'CHCPI0078');
         formData.append('user_type', 'Manager');       
         const config = {
             //   headers: { 'content-type': 'multipart/form-data' }
         }
     
         axios.post(url, formData, config)
             .then(response => {
                 console.log(response);
                 var lgdata = response.data.KOL_Personal_Info;
                 console.log("Fetched data", JSON.stringify(lgdata));
                 var lgstatus = response.data.status;
                 console.log("Fetched status", JSON.stringify(lgstatus));
                 console.log("Message from fetched api", response.data.message);
                 var kolIds = response.data.kol_system_id;
                 console.log("kolIds", JSON.stringify(kolIds));
               
                 if (lgdata) {
                     this.setState({                        
                         KOLList: response.data.KOL_Personal_Info,                         
                         total_row_data:response.data.KOL_Personal_Info                             
                     })                  
                  
                 }
                //   else {
                //      this.setState({
                //          KOLList: [],
                //          total_row_data:0,
                //          ShowProgressMessage:false,
                //          ShowBar:false,   
                //          ShowProcedure: false,
                //          LoadingBar:false,
                //          ExportToKOLDatalabel:'Export Row Data to kol',
                //          ExportToKOLDataErr:"Export Success", 
                //      })
                //  }
     
               
             });
     
    }

    


checkstatusfun= async (event) =>
{ 
    this.refresh_kol_list();

    
}

// KOLMapping=async(event)=>
// {
//     const urlFile = global.websiteUrl+'/service/Deep_Profiles/kol_mapping';
//     let formDataFile = new FormData();
//     formDataFile.append('id', "");            
//     formDataFile.append('manager_id', this.state.manager_id);
  
//     this.setState({
//         ShowProgressMessage:true,
//         KOLMappinglabel:'Please Wait...',
//     })
//     const configfile = {
//       headers: { 'content-type': 'multipart/form-data' }
//     }
//       axios.post(urlFile, formDataFile, configfile)
//       .then(response => {
       
//         console.log(response);
//         var data = response.data;
//         console.log("data publication excel", data);
//         var status = response.data.status;
          
//         console.log("status publication", status);
            
//           if (response.data.statusCode == 200 )
//           { 
//             alert(response.data.message);
//             if(response.data.message == "Record found")
//             {                          
//                 this.KOLMapping();
//             }
//             else if(response.data.message == "No Record Found")
//             {
//                 Swal.fire({
//                     icon: 'success',
//                     text: 'Export Success',
//                     //text: "Success",
//                     confirmButtonColor: '#d33',
//                     confirmButtonText: 'OK'
//                     })
       
//                     window.location.reload(true);
      
//                     this.setState({
//                       ExportToKOLDataErr:"Mapping Success",              
//                       ShowProgressMessage:true,     
//                     })
//             }
              
//           }
//           else
//           {
//               Swal.fire({
//                   icon: 'error',
//                   text: 'Mapping failed',
//                   //text: "Success",
//                   confirmButtonColor: '#d33',
//                   confirmButtonText: 'OK'
//                   })

//                   this.setState({
//                     ExportToKOLDataErr:"Mapping failed",
                                            
//                   })

//                   window.location.reload(true);

//           this.setState({
//             //   ShowBar:false,
//             //   ShowProcedure: false,
//             //   LoadingBar:false,
//               ShowProgressMessage:true,
//           })
//           }


//        })
// }


ExportRowDataToKOL = async (event) =>
    { 
        this.setState({
            // ShowProgressMessage:true,
            // ExportToKOLDatalabel:'Please Wait...',  
            ShowBar:true,   
            ShowProcedure: true,
            LoadingBar:true,
                       
           })
       // alert('start');     
        const urlFile = global.websiteUrl+'/service/Deep_Profiles/Upload_Row_Data_to_deep_profile';
        let formDataFile = new FormData();
        formDataFile.append('id', "");            
        formDataFile.append('manager_id', this.state.manager_id);
        const configfile = {
          headers: { 'content-type': 'multipart/form-data' }
        }       

          axios.post(urlFile, formDataFile, configfile)
          .then(response => {   
                    
            console.log(response);
            var data = response.data;
            console.log("data publication excel", data);
            var status = response.data.status;                  
            console.log("status publication", status);             
           
           
            if(response.data.statusCode ===200)
            {
                this.refresh_kol_list();
               
              //  alert(this.state.total_row_data);

                 if(this.state.total_row_data >0)
                {
                    this.ExportRowDataToKOL();
                }
                else
                {                  
                  this.exportsuspicious_kol();
                    // Swal.fire({
                    // icon: 'success',
                    // text: 'Export Success',
                    // //text: "Success",
                    // confirmButtonColor: '#d33',
                    // confirmButtonText: 'OK'
                    // })
                 
                    // this.setState({
                    //     // ExportToKOLDataErr:"Export Success",                           
                    //     // ShowProgressMessage:false,   
                    //     ShowBar:false,   
                    //     ShowProcedure: false,
                    //     LoadingBar:false,
                       
                    // }) 
                    // window.location.reload();
                    
                    
                            
                }
    
            }

           })

   
    }


exportexcel=(csvData,fileName)=>{
        //const csvData= this.state.projectList;
        //const fileName="test";

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';    
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
       
}

exportsuspicious_kol()
{
    this.setState({
        ShowBar:true,   
        ShowProcedure: true,
        LoadingBar:true,
      })

             const urlFile = global.websiteUrl+'/service/Deep_Profiles/Get_Suspecious_kol_list';            
           // const urlFile ='http://localhost/Cetas/service/KOL/Upload_Preliminary';
            let formDataFile = new FormData();
              formDataFile.append('file', this.state.PublicationExcelFile );
              formDataFile.append('manager_id', this.state.manager_id);
              const configfile = {
                headers: { 'content-type': 'multipart/form-data' }
              }
                           
              axios.post(urlFile, formDataFile, configfile)
                .then(response => {
                 
                  console.log(response);
                //   var data = response.data.duplicatekollist;
                //   console.log("data publication excel", data);
                  var duplicatekollist = response.data.duplicatekollist;             
                  console.log("status publication", duplicatekollist);                 
                  if (response.data.statusCode === 200 ) {

                    //alert(response.data.message);
                    if(response.data.message !="No Record Found")
                    {
                       // alert('process restart');
                        this.exportsuspicious_kol();
                    }
                    else
                    {
                    //  this.exportexcel(duplicatekollist,"Suspecious_kol_list");

                        Swal.fire({
                        icon: 'success',
                        text: "Analyse Successfull",
                        //text: "Success",
                        confirmButtonColor: '#d33',
                        confirmButtonText: 'OK'
                        })

                        window.location.reload(true);
                        
                        this.setState({
                            ShowBar:false,
                            ShowProcedure: false,
                            LoadingBar:false,
                        })

                    }

                  }
                  else {
                    
                    Swal.fire({
                      icon: 'error',
                      text: response.data.message,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK'
                    })
                   
                    this.setState({
                        ShowBar:false,
                        ShowProcedure: false,
                        LoadingBar:false,
                    })
                  }
                })
      
        
    
}

    refreshing_export_process()
    {
        window.location.reload(true);

        // // this.ExportRowDataToKOL();
        // this.setState({
        //     ExportToKOLDatalabel:'Click Here To Start Exporting Process',    
        //   })

    }

    //UpdatekolmappingStatus


    UpdatekolmappingStatus = async (event) =>
    {
             const urlFile = global.websiteUrl+'/service/Deep_Profiles/Update_Mapping_Status_Done';            
               // const urlFile ='http://localhost/Cetas/service/KOL/Upload_Preliminary';
                let formDataFile = new FormData();
                  formDataFile.append('file', this.state.PublicationExcelFile );
                  formDataFile.append('manager_id', this.state.manager_id);
                  const configfile = {
                    headers: { 'content-type': 'multipart/form-data' }
                  }
                  this.setState({
                    ShowBar:true,   
                    ShowProcedure: true,
                    LoadingBar:true,
                  })        
                  
                  axios.post(urlFile, formDataFile, configfile)
                    .then(response => {
                     
                      console.log(response);
                      var data = response.data;
                      console.log("data publication excel", data);
                      var status = response.data.status;
                 
                      console.log("status publication", status);
                      if (response.data.statusCode === 200 )
                       {
                            
                            this.GetMappedKolList();
                            Swal.fire({
                                icon: 'success',
                                text: 'Mapping Success',
                                //text: "Success",
                                confirmButtonColor: '#d33',
                                confirmButtonText: 'OK'
                                })
            
                                this.setState({
                                    ShowBar:false,
                                    ShowProcedure: false,
                                    LoadingBar:false,
                                })
                   
                      }
                      else {
                        
                        Swal.fire({
                          icon: 'error',
                          text: response.data.message,
                          confirmButtonColor: '#d33',
                          confirmButtonText: 'OK'
                        })
                       
                        this.setState({
                            ShowBar:false,
                            ShowProcedure: false,
                            LoadingBar:false,
                        })
                      }
                    })
    }


    GetMappedKolList()
    {
             const urlFile = global.websiteUrl+'/service/Deep_Profiles/Get_row_Mapped_kol_List';            
               // const urlFile ='http://localhost/Cetas/service/KOL/Upload_Preliminary';
                let formDataFile = new FormData();
                  formDataFile.append('file', this.state.PublicationExcelFile );
                  formDataFile.append('manager_id', this.state.manager_id);
                  const configfile = {
                    headers: { 'content-type': 'multipart/form-data' }
                  }
                  
                  axios.post(urlFile, formDataFile, configfile)
                    .then(response => {
                     
                      console.log(response);
                      var data = response.data;
                      console.log("data publication excel", data);
                      var status = response.data.status;                 
                      console.log("status publication", status);
                      if (response.data.statusCode === 200 )
                       {
                         
                         this.setState({
                            totalMappedkol:response.data.MappedList
                         })
                       }
                   
                    })
    }



   kolmapping = async (event) =>
    {
             const urlFile = global.websiteUrl+'/service/Deep_Profiles/kol_mapping';            
               // const urlFile ='http://localhost/Cetas/service/KOL/Upload_Preliminary';
                let formDataFile = new FormData();
                  formDataFile.append('file', this.state.PublicationExcelFile );
                  formDataFile.append('manager_id', this.state.manager_id);
                  const configfile = {
                    headers: { 'content-type': 'multipart/form-data' }
                  }
                  this.setState({
                    ShowBar:true,   
                    ShowProcedure: true,
                    LoadingBar:true,
                  })
        
                  
                  axios.post(urlFile, formDataFile, configfile)
                    .then(response => {
                     
                      console.log(response);
                      var data = response.data;
                      console.log("data publication excel", data);
                      var status = response.data.status;
                 
                      console.log("status publication", status);
                      if (response.data.statusCode === 200 )
                       {
                            this.Upload_Row_Data_to_deep_profile();
                        //    Swal.fire({
                        //     icon: 'success',
                        //     text: 'Mapping Success',
                        //     //text: "Success",
                        //     confirmButtonColor: '#d33',
                        //     confirmButtonText: 'OK'
                        //     })

                        //     window.location.reload(true);
                            
                        //     this.setState({
                        //         ShowBar:false,
                        //         ShowProcedure: false,
                        //         LoadingBar:false,
                        //     })
                      }
                      else {
                        
                        Swal.fire({
                          icon: 'error',
                          text: response.data.message,
                          confirmButtonColor: '#d33',
                          confirmButtonText: 'OK'
                        })
                       
                        this.setState({
                            ShowBar:false,
                            ShowProcedure: false,
                            LoadingBar:false,
                        })
                      }
                    })
    }


    kolmapping_fun = async (event) =>
    {
             const urlFile = global.websiteUrl+'/service/Deep_Profiles/kol_mapping';            
               // const urlFile ='http://localhost/Cetas/service/KOL/Upload_Preliminary';
                let formDataFile = new FormData();
                  formDataFile.append('file', this.state.PublicationExcelFile );
                  formDataFile.append('manager_id', this.state.manager_id);
                  const configfile = {
                    headers: { 'content-type': 'multipart/form-data' }
                  }
                  this.setState({
                    ShowBar:true,   
                    ShowProcedure: true,
                    LoadingBar:true,
                  })
        
                  
                  axios.post(urlFile, formDataFile, configfile)
                    .then(response => {
                     
                      console.log(response);
                      var data = response.data;
                      console.log("data publication excel", data);
                      var status = response.data.status;
                 
                      console.log("status publication", status);
                      if (response.data.statusCode === 200 )
                       {

                        Swal.fire({
                                        icon: 'success',
                                        text: 'Mapping Success',
                                        //text: "Success",
                                        confirmButtonColor: '#d33',
                                        confirmButtonText: 'OK'
                                        })
        
                                        window.location.reload(true);
                                        
                                        this.setState({
                                            ShowBar:false,
                                            ShowProcedure: false,
                                            LoadingBar:false,
                                        })

                    //    // alert(response.data.message);
                    //     if(response.data.message == "Record Found")
                    //         {                          
                    //             this.KOLMapping();
                    //         }
                    //         else if(response.data.message == "No Record Found")
                    //         {
                    //             Swal.fire({
                    //             icon: 'success',
                    //             text: 'Mapping Success',
                    //             //text: "Success",
                    //             confirmButtonColor: '#d33',
                    //             confirmButtonText: 'OK'
                    //             })

                    //             window.location.reload(true);
                                
                    //             this.setState({
                    //                 ShowBar:false,
                    //                 ShowProcedure: false,
                    //                 LoadingBar:false,
                    //             })
                    //         }
                      }
                      else {
                        
                        Swal.fire({
                          icon: 'error',
                          text: response.data.message,
                          confirmButtonColor: '#d33',
                          confirmButtonText: 'OK'
                        })
                       
                        this.setState({
                            ShowBar:false,
                            ShowProcedure: false,
                            LoadingBar:false,
                        })
                      }
                    })
    }



export_Mapped_kol()
{
    this.setState({
        ShowBar:true,   
        ShowProcedure: true,
        LoadingBar:true,
      })

             const urlFile = global.websiteUrl+'/service/Deep_Profiles/Get_mapped_kol_List';            
           // const urlFile ='http://localhost/Cetas/service/KOL/Upload_Preliminary';
            let formDataFile = new FormData();
              formDataFile.append('file', this.state.PublicationExcelFile );
              formDataFile.append('manager_id', this.state.manager_id);
              const configfile = {
                headers: { 'content-type': 'multipart/form-data' }
              }
             
              
              axios.post(urlFile, formDataFile, configfile)
                .then(response => {
                 
                  console.log(response);
                //   var data = response.data.duplicatekollist;
                //   console.log("data publication excel", data);
                  var MappedKOLData = response.data.MappedKOLData;             
                  console.log("status publication", MappedKOLData);                 
                  if (response.data.statusCode === 200 ) {

                    this.exportexcel(MappedKOLData,"Mapped_kol_List");

                    Swal.fire({
                      icon: 'success',
                      text: response.data.message,
                       //text: "Success",
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK'
                    })

                    window.location.reload(true);
                    
                    this.setState({
                        ShowBar:false,
                        ShowProcedure: false,
                        LoadingBar:false,
                    })
                  }
                  else {
                    
                    Swal.fire({
                      icon: 'error',
                      text: response.data.message,
                      confirmButtonColor: '#d33',
                      confirmButtonText: 'OK'
                    })
                   
                    this.setState({
                        ShowBar:false,
                        ShowProcedure: false,
                        LoadingBar:false,
                    })
                  }
                })
      
        
    
}

Ranking_Calculation()
{
    
}

    deletetabledata()
    {

        this.setState({
            ShowBar:true,   
            ShowProcedure: true,
            LoadingBar:true,
          })

                 const urlFile = global.websiteUrl+'/service/Deep_Profiles/Delete_Deep_Profiles';            
               // const urlFile ='http://localhost/Cetas/service/KOL/Upload_Preliminary';
                let formDataFile = new FormData();
                  formDataFile.append('file', this.state.PublicationExcelFile );
                  formDataFile.append('manager_id', this.state.manager_id);
                  const configfile = {
                    headers: { 'content-type': 'multipart/form-data' }
                  }
                 
                  
                  axios.post(urlFile, formDataFile, configfile)
                    .then(response => {
                     
                      console.log(response);
                      var data = response.data;
                      console.log("data publication excel", data);
                      var status = response.data.status;
                 
                      console.log("status publication", status);
                     
                      if (response.data.statusCode === 200 ) {
             
                        Swal.fire({
                          icon: 'success',
                          text: response.data.message,
                           //text: "Success",
                          confirmButtonColor: '#d33',
                          confirmButtonText: 'OK'
                        })

                        window.location.reload(true);
                        
                        this.setState({
                            ShowBar:false,
                            ShowProcedure: false,
                            LoadingBar:false,
                        })
                      }
                      else {
                        
                        Swal.fire({
                          icon: 'error',
                          text: response.data.message,
                          confirmButtonColor: '#d33',
                          confirmButtonText: 'OK'
                        })
                       
                        this.setState({
                            ShowBar:false,
                            ShowProcedure: false,
                            LoadingBar:false,
                        })
                      }
                    })
          
            
        
       

    }



    exportToWord() {
            
  var header = "<html xmlns:o='urn:schemas-microsoft-com:office:office' "+
  "xmlns:w='urn:schemas-microsoft-com:office:word' "+
  "xmlns='http://www.w3.org/TR/REC-html40'>"+
  "<head><meta charset='utf-8'><title>Export HTML to Word Document with JavaScript</title></head><body>";

    var html, link, blob, url, css;
    css = (
      '<style>' +
      '@page WordSection1{size: 841.95pt 595.35pt;mso-page-orientation: landscape;}' +
      'div.WordSection1 {page: WordSection1;}' +
      '</style>'
    );
    
    var element = document.getElementById('PrintDuplicateKol');  
    //mainDashboard1  Export_doc
    html = header+element.innerHTML;
    blob = new Blob(['\ufeff', css + html], {
      type: 'application/msword'
    });
    url = URL.createObjectURL(blob);
    link = document.createElement('A');
    link.href = url;
    link.download = 'Duplicate_kol_list';  // default name without extension 
    document.body.appendChild(link);
    if (navigator.msSaveOrOpenBlob ) navigator.msSaveOrOpenBlob( blob, 'Duplicate_kol_list.doc'); // IE10-11
        else link.click();  // other browsers
    document.body.removeChild(link);
  
  
        }
    

    
    
    // UploadDeepKOLProfiles()
    // {
      
    //     this.setState({
    //   //      LoadingBar:true,
    //         ShowProcedure: true,
    //       })
      
    //     //  console.log("FinalPublicationExcelFile",this.state.PublicationExcelFile)
    //     console.log("FinalPublicationExcelFile",this.state.manager_id,this.state.PublicationExcelFile)
    
      
    //     if(this.state.PublicationExcelFileName){
           
    //        const urlFile = global.websiteUrl+'/service/KOL/Upload_Preliminary';            
    //       //const urlFile ='http://localhost/Cetas/service/KOL/Upload_Preliminary';
    //       let formDataFile = new FormData();
    //         formDataFile.append('file', this.state.FinalPublicationExcelFile );
    //         formDataFile.append('kol_id', this.state.manager_id);
    //         const configfile = {
    //           headers: { 'content-type': 'multipart/form-data' }
    //         }
           
    //         axios.post(urlFile, formDataFile, configfile)
    //           .then(response => {
               
    //             console.log(response);
    //             var data = response.data;
    //             console.log("data publication excel", data);
    //             var status = response.data.status;
           
    //             console.log("status publication", status);
    //             this.setState({
    //               ShowProcedure: false,
    //             })
               
    //          alert(response.data.status);

    //            this.setState({ duplicatekollist:response.data.duplicatekollist});

    //            if (response.data.status == true) {
    //             //if (response.status === 200 ) {
       
    //               Swal.fire({
    //                 icon: 'success',
    //                 text: response.data.message,
    //                 // text: "Data Saved",
    //                 confirmButtonColor: '#d33',
    //                 confirmButtonText: 'OK'
    //               })
    //               this.setState({
    //                 ShowPublicationExcel: false,
    //                 PublicationExcelFile: '',
    //                 PublicationExcelFileName: '',
    //                 FinalPublicationExcelFile: '',
    //                 ShowPublicationExcel: true,
    //                // PublicationFileIsUploadedRefreshNow: true,
    //                LoadingBar:false,
    //               })
    //             }
    //             else {
    //               Swal.fire({
    //                 icon: 'error',
    //                 text: response.data.message,
    //                 confirmButtonColor: '#d33',
    //                 confirmButtonText: 'OK'
    //               })
    //             }
    //           })
    //       }
    
    // }




onUploadPreliminaryimage = async (event) => {

       this.setState({
            ShowBar:true,
            ShowProcedure: true,
            LoadingBar:true,
          })

                const filesFormats=["image/jpeg","image/png","image/jpg"];
                const isRightFormat = filesFormats.includes(event.target.files[0].type);
            
                console.log(isRightFormat);
                if(isRightFormat)
                {
  
                    this.fileObj.push(event.target.files)
                    for (let i = 0; i < this.fileObj[0].length; i++) {
                       //this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]))
                        this.fileArray.push(this.fileObj[0][i])
                    }
    
        
            }
            else
            {
                this.setState({
                   
                    PreliminaryImageErr:'only JPEG , PNG , JPG Allowed',
                });
                //alert('only JPEG , PNG , JPG Allowed');
            }
  
    this.setState({
        ShowBar:false,
        ShowProcedure: false,
        LoadingBar:false,
      })


    }


    onUploadPreliminaryImageAtServer = async (event) => {
         
          if(this.fileArray.length>0)
          {
                Array.from(this.fileArray).forEach(item=>{

                    this.setState({
                        ShowBar:true,   
                        ShowProcedure: true,
                        LoadingBar:true,
                      })
            
                    console.log("FinalPublicationExcelFile",this.state.manager_id,this.fileArray)
                    const urlFile = global.websiteUrl+'/service/KOL/uplod_prilimary_image';            
                        let formDataFile = new FormData();
                        formDataFile.append('file', item);
                        formDataFile.append('manager_id', this.state.manager_id);
                        const configfile = {
                            headers: { 'content-type': 'multipart/form-data' }
                        }
                        axios.post(urlFile, formDataFile, configfile)
                            .then(response => {
                            console.log(response);
                            var data = response.data;
                            console.log("data publication excel", data);
                            var status = response.data.status;
                            console.log("status publication", status);
                
                            // if (response.data.status == true) {
                            //   if (response.status === 200 ) {
                    
                            //     Swal.fire({
                            //       icon: 'success',
                            //       //text: response.data.message,
                            //        text: "Success",
                            //       confirmButtonColor: '#d33',
                            //       confirmButtonText: 'OK'
                            //     })

                            //     //window.location.reload();

                                 this.setState({
                                    PreliminaryImageErr:"Upload Success", 
                                    PhotoUploadStatus:"Success", 
                                    ShowBar:false,
                                    ShowProcedure: false,
                                    LoadingBar:false,
                                })
                            //   }
                            //   else {
                            //     Swal.fire({
                            //       icon: 'error',
                            //       text: response.data.message,
                            //       confirmButtonColor: '#d33',
                            //       confirmButtonText: 'OK'
                            //     })
                            //   }
                            })
                    
                        })

                        // // Swal.fire({
                        // //     icon: 'success',
                        // //     //text: response.data.message,
                            
                        // //     text: "Upload Success",
                        // //     confirmButtonColor: '#d33',
                        // //     confirmButtonText: 'OK'
                        // //   })
              
            }
            else
            {
                this.setState({
                      PreliminaryImageErr:"Select Image first",                                   
                      ShowBar:false,
                      ShowProcedure: false,
                      LoadingBar:false,
                  })
             
            }
        //
             
            
       
       
    }


    render() {

        let speciality_type = this.state.ResercherData;
        let OptionsSpeciality = speciality_type.map((speciality_type) =>
        <option key={speciality_type.emp_id} value={speciality_type.emp_id}>
            {speciality_type.emp_name}</option>
        );

        if (this.state.redirectCreateProject) {
            return (<Redirect to={{
                pathname: '/CreateProject',
                // state:{
                //     idForCreateNewProject: this.state.idForCreateNewProject,
                //     manager_name: this.state.manager_name,
                //     manager_id: this.state.manager_id,    
                // } 
            }} />)
        }

        

        if (this.state.redirectpreliminarykolNav) {
            return (<Redirect to={{
                pathname: '/UploadPreliminarykol',                
            }} />)
        }
        
        
        if (this.state.RedirectManagerDashoard) {
            return (<Redirect to={{
                pathname: '/ManagerDashboard',                
            }} />)
        }

        if (this.state.redirectEditProject) {
            return (<Redirect to={{
                pathname: '/EditProject',
                
            }} />)
        }


        if (this.state.redirectManagerKolList) {
            return (<Redirect to={{
                pathname: '/ManagerKolList',
                // state:{ 
                //     project_id: this.state.project_id, 
                //     p_name: this.state.p_name,
                //     manager_name: this.state.manager_name,
                //     manager_id: this.state.manager_id, 
                // } 
            }} />)
        }

        const pageButtonRenderer = ({
            page,
            active,
            disable,
            title,
            onPageChange
        }) => {
            const handleClick = (e) => {
                e.preventDefault();
                onPageChange(page);
            };
            return (
                <Button outline color="danger" className="mr-2" onClick={handleClick} > {page} </Button>
            );
        };

        const options = {
            pageButtonRenderer,
            paginationSize: 4,
            pageStartIndex: 1,
            // alwaysShowAllBtns: true, // Always show next and previous button
            // withFirstAndLast: false, // Hide the going to First and Last page button
            // hideSizePerPage: true, // Hide the sizePerPage dropdown always
            // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            paginationTotalRenderer: customTotal,
            disablePageTitle: true,
            sizePerPageList: [{
                text: '50', value: 50
            }, {
                text: '100', value: 100
            }, {
                text: '200', value: 200
            }, {
                text: 'All', value: this.state.projectList.length
            }]  // A numeric array is also available. the purpose of above example is custom the text

        };

        return (
            <div style={{marginTop:'12vh' }}>
                 <div>
                        {this.state.ShowBar ? <Modal isOpen={true} className='propagateLoader' centered>
                            <PropagateLoader color={'#A90007'} size='40px' loading='true' />
                        </Modal> : null}
                        </div> 
                        {this.state.ShowProcedure ? <Modal isOpen={true} className='propagateLoader' centered>
                        <PropagateLoader color={'#A90007'} size='40px' loading='true' />
                        </Modal> : null}

                {this.state.ShowBar ?
                    <div style={{ position: 'fixed', top: '0' }}>
                        <LoadingBar
                            progress='100'
                            // onLoaderFinished={() => setProgress(0)}
                            //  progress={progress}
                            height={3} color="#A90007" transitionTime={4000}
                            loaderSpeed={4000} />
                    </div>
                    : null}
                <div >
                    <div>
                        <div >
                            <ManagerHeader ManagerName={this.state.manager_name} />
                            <div class="container">
                                <div class="content-wrapper">
                                    <div class="screen-headingbox">
                                    <div class="back-section">
                                            <a onClick={this.BackToManagerDashboard.bind(this)}>
                                                <img
                                                    src={require('../Assets/Images/back-arrow.svg')}
                                                />
                                                Back
                                            </a>
                                        </div>
                                        <h2 className="mb-20"  >Upload Deep KOL Profiles
                                        <button class="cetas-button float-right" onClick={this.exportsuspicious_kol.bind(this)}>
                                       5. Analyse Suspicious kol </button>
                                            
                                        {/* <button class="cetas-button float-right"  onClick={this.ExportRowDataToKOL}> {this.state.ExportToKOLDatalabel} </button> */}

                                        {/* <button class="cetas-button float-right" onClick={this.refreshing_export_process.bind(this)}>
                                        Refresh Exporting Process</button> */}
                                            {/* <button class="cetas-button float-right" onClick={this.UpdatekolmappingStatus.bind(this)}>
                                                  3. Update Mapping Status</button>
                                       */}
                                        <button class="cetas-button float-right" onClick={this.kolmapping.bind(this)}>
                                                   2. Mapping ( {this.state.totalMappedkol} )</button>
                                                
                                                    <button class="cetas-button float-right" onClick={this.export_Mapped_kol.bind(this)}>
                                                   Export Mapped KOL </button>  
                                     
                                         <button class="cetas-button float-right" onClick={this.deletetabledata.bind(this)}>
                                                    Delete Table Data</button>
                                     
                                                    <button class="cetas-button float-right" onClick={this.Ranking_Calculation.bind(this)}>
                                                   6. Calculation</button>
                                     
                                        
                                        </h2>
                                    </div>
                                    <div >
                                        <div >
                                          

{/* // Upload Images */}
{/* 
<div className="col-md-8" style={{ marginBottom: '20px' , marginTop:'20px',  marginLeft:'-1.3%'}}>
                                                                                <div class="form-control" style={{ width:'70%' , height:'40px'}}  >
                                                                                    <input type='file'
                                                                                        // accept="*"
                                                                                        multiple
                                                                                        id="myInputImage"
                                                                                        accept="image/*"
                                                                                        onChange={this.onUploadPreliminaryimage} hidden 
                                                                                        
                                                                                        />
                                                                                    <label htmlFor="myInputImage"
                                                                                        style={{ color: 'blue', fontSize: "15px", marginTop: '0%' }}>
                                                                                        Select KOL Image
                                                                                    </label>
                                                                                    {
                                                                                        (this.fileArray.length !=0) ? (
                                                                                            <div class="float-right" style={{ marginTop: '0%' }}>
                                                                                                <text>{this.fileArray.length} file selected</text>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <div class="float-right" style={{ marginTop: '0' }}>
                                                                                                No file Choosen
                                                                                            </div>
                                                                                        )
                                                                                        
                                                                                    }
                                                                                </div>
                                                                                
                                                                                <button class="cetas-button float-right" style={{ marginTop:'-5.7%', marginRight:'7%'}} onClick={this.onUploadPreliminaryImageAtServer}> Upload Image</button>
                                                                    
                                                                                <div className="loginErrCreateProject" style={{ marginTop:'10px', fontSize:15}} >
                                                                                    {
                                                                                        (this.state.PreliminaryImageErr !== "")?(
                                                                                        <div>
                                                                                              {  (this.state.PreliminaryImageErr == "Upload Success")?(<div style={{ color:'green'}}>
                                                                                                 {this.state.PreliminaryImageErr}
                                                                                   
                                                                                                </div>):(<div> {this.state.PreliminaryImageErr}
                                                                                   </div>)
                                                                                                }
                                                                                        </div>):(<div></div>)

                                                                                    }
                                                                                    </div>

                                                                             
</div> */}

{/* <div>   
<Label for="engStatusIN" style={{height:'10px'}} > 
     </Label>
    <Input type="select" id="engStatusIN" 
    style={{ height: '40px', width: '30%' }}
    name="engagement_status"
    // value={this.state.kol_incentive_info.engagement_status}
    onChange={async (e) => {
        await this.setState({drop_action_type:e.target.value});
            // if(this.state.kol_upload_type=="UpdateKOL" ||this.state.kol_upload_type=="MergeKOL" )
            // {
            //     this.setState({PhotoUploadStatus:'Success'})
            // }
    }
    }
>
    <option value="" >--Select Action Type--</option>    
    <option value="KOL_Row_Data" >Upload KOL Row Data Kol</option>    
    <option value="KOL_Publication_Data" >Upload KOL Publication Data</option>    
    <option value="Export_Row_To_Deep_KOL" >Export Row To Deep KOL</option>
    
</Input>

</div> */}
{/* {
(this.state.drop_action_type=="KOL_Row_Data")?
 ( */}
<div className="row">
    <div className='col-md-12'>
        <div className='col-md-6 float-left'>
        <label for="resume" style={{fontSize:'14px' , fontWeight:'500' }}>
                                                Upload KOL Identification Data </label>
                                            <div class="form-control" style={{ width:'100%' , height:'40px'}}  >
                                                <input type='file'
                                                    accept=".xlsx"
                                                    id="myInputResume"
                                                    onChange={this.onUploadPreliminary} hidden />
                                                <label htmlFor="myInputResume" 
                                                    style={{ color: 'blue', fontSize: "15px", marginTop:'0%'  }}>
                                                    Select excel file
                                                </label>
                                                {
                                                    (this.state.PreliminaryExcelFileName) ? (
                                                        <div class="float-right" style={{ marginTop: '-5%' }}>
                                                            <text>{this.state.FinalPreliminaryExcelFile}</text>
                                                        </div>
                                                    ) : (
                                                        <div class="float-right" style={{ marginTop: '-5%' }}>
                                                            No file Choosen
                                                        </div>
                                                    )
                                                    
                                                }
                                            </div>
                                            
                                            
                                            <div className="loginErrCreateProject" style={{ marginTop:'10px', fontSize:'15px', fontWeight:'bold'}} >
                                                    {
                                                    (this.state.PreliminaryDataErr !== "")?(
                                                    <div>
                                                            {  (this.state.PreliminaryDataErr == "Upload Success")?(<div style={{ color:'green'}}>
                                                                {this.state.PreliminaryDataErr}
                                                
                                                            </div>):(<div> {this.state.PreliminaryDataErr}
                                                </div>)
                                                            }
                                                    </div>):(<div></div>)

                                                }
                                                
                                                
                                                </div>

           </div>
        <div className='col-md-3 float-left' style={{paddingTop:'3.2%'}}>
        
             <button class="cetas-button float-left" onClick={this.UploadPreliminaryExcel}>{this.state.uploadkolident} </button>
                                                                    
        </div>
    </div>
</div>
{/* </div>

):(<div></div>)
} */}

{/* {
(this.state.drop_action_type =="KOL_Publication_Data")?
( */}
<div className="row">
    <div className='col-md-12'>
        <div className='col-md-6 float-left'>
        <label for="resume" style={{ marginTop: '0%', fontSize:'14px' , fontWeight:'500' }}>
                                                                                    Upload KOL Publication Data </label>
                                                                                <div class="form-control" style={{ width:'100%' , height:'40px'}}  >
                                                                                    <input type='file'
                                                                                        accept=".xlsx"
                                                                                        id="myInputResume1"
                                                                                        // accept="image/*"
                                                                                        onChange={this.onUploadPublication} hidden />
                                                                                    <label htmlFor="myInputResume1"
                                                                                        style={{ color: 'blue', fontSize: "15px", marginTop: '0%' }}>
                                                                                        Select excel file
                                                                                    </label>
                                                                                    {
                                                                                        (this.state.PublicationExcelFileName) ? (
                                                                                            <div class="float-right" style={{ marginTop: '-5%' }}>
                                                                                                <text>{this.state.FinalPublicationExcelFile}</text>
                                                                                            </div>
                                                                                        ) : (
                                                                                            <div class="float-right" style={{ marginTop: '-5%' }}>
                                                                                                No file Choosen
                                                                                            </div>
                                                                                        )
                                                                                        
                                                                                    }
                                                                                </div>
                                                                                
                                                                              
                                                                                <div className="loginErrCreateProject" style={{ marginTop:'10px', fontSize:'15px' , fontWeight:'bold'}} >
                                                                                      {
                                                                                        (this.state.UploadPublicationDataErr !== "")?(
                                                                                        <div>
                                                                                              {  (this.state.UploadPublicationDataErr == "Publication Upload Success")?(<div style={{ color:'green'}}>
                                                                                                 {this.state.UploadPublicationDataErr}
                                                                                   
                                                                                                </div>):(<div> {this.state.UploadPublicationDataErr}
                                                                                   </div>)
                                                                                                }
                                                                                        </div>):(<div></div>)

                                                                                    }
                                                                                    
                                                                                    
                                                                                    </div>
     <p className="list-count" style={{ color: 'green' , width:'100%',height:'50px'}}><b>{this.state.KOLList}</b> KOL's Row Data </p>
        
     {/* <label style={{color:'blue'}}><a onClick={this.checkstatusfun}>Refresh </a></label> */}
       
        {
            (this.state.ShowProgressMessage == true)?
            
                (<div>
                    {/* <label style={{color:'blue'}}>wait exporting in process...</label> */}
                  {/* <image src={SpiningImage} style={{height:'50px',width:'50px'}} ></image> */}
                  {/* <img id="lglogos" src={require('../Assets/Images/cetas_healthcare_logo.svg')} alt="Cetas"></img> */}
                   <img id="lglogos" src={SpiningImage}style={{height:'100px',width:'100px' , float:'left'}} alt="Cetas"></img>
                   <p> <label style={{color:'blue',float:'left',width:'100%',}}><a onClick={this.checkstatusfun}>Check Uploading/Exporting Status</a></label></p>
                                    
                </div>):(<div></div>)
            
        }
                                                                                                
        </div>
        <div className='col-md-3 float-left' style={{paddingTop:'3.2%'}}>
        
        <button class="cetas-button float-left" onClick={this.UploadPublicationData}> {this.state.uploadkol_pub} </button>
                                                                                                                              
        </div>
    </div>
</div>
{/* </div>
):(<div></div>)
}   */}

{/* {
(this.state.drop_action_type =="Export_Row_To_Deep_KOL")?
( */}

                                            <div className="row">
                                                <div className="col-md-12 col-sm-12 col-xs-12 emplist-box">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            {/* <h3 className="mb-30">Select Preliminary KOL </h3> */}
                                                            
                                                            {/* <div className="col-md-8" style={{ marginBottom: '3%' ,  marginLeft:'-1.3%'}}>
                                                            
                                                                             
                                                            </div> */}


                                                             {/* ***** Upload Publication Row Data****              */}
{/* 
                                                             <div className="col-md-8" style={{ marginBottom: '3%' ,  marginLeft:'-1.3%'}}>
                                                                     
                                                                             
                                                            </div> */}

                                                            {/* Convert Row Data To KOL */}

                                                            <div className="col-md-8" style={{ marginBottom: '3%' , marginTop:'-3%'}}>

                                                                {
            (this.state.ShowProgressMessage == false)?
            
                (<div>
                     
                    {/* <button class="cetas-button float-left" style={{marginRight:'0%'}} onClick={this.KOLMapping}> {this.state.KOLMappinglabel} </button>
                    */}
                    <button class="cetas-button float-left" style={{marginRight:'0%'}} onClick={this.ExportRowDataToKOL}> {this.state.ExportToKOLDatalabel} </button>
                         
                </div>):(<div></div>)
            
        }
                                                                                    
                                                                    
                                                                              <div className="loginErrCreateProject" style={{ marginTop:'10px', fontSize:'15px', fontWeight:'bold'}} >
                                                                                        {
                                                                                        (this.state.ExportToKOLDataErr !== "")?(
                                                                                        <div>
                                                                                              {  (this.state.ExportToKOLDataErr == "Export Success")?(<div style={{ color:'green'}}>
                                                                                                 {this.state.ExportToKOLDataErr}
                                                                                   
                                                                                                </div>):(<div> {this.state.ExportToKOLDataErr}
                                                                                   </div>)
                                                                                                }
                                                                                        </div>):(<div></div>)

                                                                                    }
                                                                                    
                                                                                    
                                                                                    </div>
                                                                             
                                                            </div>

                                                            {/* <div className="employee-actions">
                                                                <div className="table-infobox">
                                                                    <div className="filter-box float-right mb-30">
                                                                    
                                                                        <div className="input-group" style={{ marginLeft: '50%' }}>
                                                                            <div className="input-group-prepend">
                                                                                <div className="input-group-text"><img src={require("../Assets/Images/search.svg")} /></div>
                                                                            </div>
                                                                            <input type="text" className="form-control" id="inlineFormInputGroupUsername" placeholder="Search by KOL Name"
                                                                                onChange={(e) => { this.onTextChange(e) }}
                                                                            />
                                                                        </div>
                                                                    
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <p className="list-count" style={{ color: 'black' }}>{this.state.KOLList.length} KOL's</p>
                                                                     */}
                                                           
                                                            {/* <div style={{ marginTop: '1%' }}>
                                                                <BootstrapTable
                                                                    noDataIndication="Table is Empty"
                                                                    keyField="emp_id"
                                                                    data={this.state.KOLList}
                                                                    columns={this.state.columns}
                                                                    striped
                                                                    hover
                                                                    condensed
                                                                    loading={true}
                                                                    pagination={paginationFactory(options)}
                                                                    filter={filterFactory()}
                                                                    rowStyle={ { backgroundColor: 'White' } }

                                                                />
                                                            </div> */}
                                                           
                                    <Modal isOpen={this.state.EditIncentiveModal} toggle={this.toggleEditIncentiveModal.bind(this)} centered>
                                        <ModalHeader toggle={this.toggleEditIncentiveModal.bind(this)}
                                            cssModule={{ 'modal-title': 'w-100 text-center', 'border-bottom': '0px', }}
                                            style={{ borderBottom: '0px' }}>
                                            <h3 style={{ justifyContent: 'center', marginTop: '30px' }} id="login-tit">Assign Researcher</h3>
                                        </ModalHeader>
                                        <ModalBody style={{ padding: '0px 80px 0px 80px' }}>

                                            <FormGroup>
                                                <Label for="engStatusIN" >
                                                    Select Researcher Name </Label>
                                                <Input type="select" id="engStatusIN"
                                                    style={{ height: '50px', width: '100%' }}
                                                    name="researcher_id"
                                                   // value={this.state.kol_researcher_info.incentive_status}
                                                    onChange={async (e) => {
                                                        let { kol_researcher_info } = this.state;
                                                        kol_researcher_info.researcher_id = e.target.value;
                                                        await this.setState({ kol_researcher_info });
                                                    }}
                                                >
                                                    {/* <option value="" >--Select Incentive Status--</option>
                                                    <option value="Incentivised" >Incentivised</option>
                                                    <option value="In Process" >In Process</option> */}
                                                    <option value="" >--Select Researcher Name--</option>
                                                    {OptionsSpeciality}
                                                </Input>
                                                {/* <div className="centerErrLogin">{this.state.kolProfileUsernameErr}</div>  */}
                                            </FormGroup>

                                          

                                           

                                        </ModalBody>
                                        <ModalFooter style={{ borderTop: '0px' }}>
                                            <button class="cetas-secondry-button cetas-button-small" style={{ height: '40px', width: '80px' }}
                                                onClick={this.toggleEditIncentiveModal.bind(this)}>Cancel</button>
                                            <button type="submit" class="cetas-button" style={{ marginRight: '20%', }}
                                                onClick={this.UpdateResearcherName.bind(this)}>
                                                Save
                                            </button>

                                        </ModalFooter>
                                    </Modal>
{/* 
                                    <p className="list-count" style={{ color: 'black' , width:'100%',height:'50px'}}>{this.state.KOLList.length} KOL's</p>
                                                                    
                                                           
                                                                    <div style={{ marginTop: '1%' }}>
                                                                        <BootstrapTable
                                                                            // {...props.baseProps}
                                                                            noDataIndication="Table is Empty"
                                                                            keyField="emp_id"
                                                                            data={this.state.KOLList}
                                                                            columns={this.state.columns}
                                                                            striped
                                                                            hover
                                                                            condensed
                                                                            loading={true}
                                                                            pagination={paginationFactory(options)}
                                                                            filter={filterFactory()}
                                                                            rowStyle={ { backgroundColor: 'White' } }
        
                                                                        />
                                                                    </div> */}

                                        {/* // Duplicate Kol List  */}
                                        <div className="row" style={{visibility:'hidden'}}>
                                                <div className="col-md-12 text-center">
                                            
                                                    <div className='' id='PrintDuplicateKol'>
                                                    <h3>Suspicious Kol id is : </h3>
                                                    <p>{this.state.duplicatekollist}</p>
                                        </div>
                                        </div>
                                        </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                            {/* </div>
                                            ):(<div></div>)
} */}
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ImportCompletefun;