import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Typeahead } from "react-bootstrap-typeahead";
import PhoneInput from "react-phone-input-2";

import { FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, Col, FormFeedback, Form, Popover, PopoverHeader, PopoverBody, Alert } from 'reactstrap';
import { Router } from 'react-router-dom/cjs/react-router-dom.min';

// import '../styles/ClientHeader.css';
import './Client.scss';
var jwt=require('jsonwebtoken');
var jwtkey= require('../config');
class ClientHeader extends Component {
  constructor(props) {
    console.log(props)
    super(props);
    this.state = {
      redirect: false,
      client_id: '',
      client_name: '',
      client_email: '',
      kol_id: '',
      kols: [
        { id: "clinical", label: "Clinical" },
        { id: "non-clinical", label: "Non-Clinical" },
      ],
      project_id: '',
      speciality_id: '',
      speciality_name: '',
      redirectPClientViewKolList: false,
      redirectBackToKolList: false,

      logoutflag: false,
      redirectLogin: false,
      redirectSignup: false,

      SpecialityData: [],
      clinicalKolForm: {
        speciality: "",
        subSpeciality: "",
        procedure: "",
        condition: "",
        procedureType: "",
        country: "",
      },
      nonClinicalKolForm: {
        kolSubType: "",
        country: "",
        fullName: "",
        workEmail: "",
        phone: "",
        companyName: "",
        jobTitle: "",
        message: "",
      },

      countryList: [
        { value: "USA", label: "USA" },
        { value: "Canada", label: "Canada" },
        { value: "UK", label: "UK" },
        { value: "Germany", label: "Germany" },
        { value: "France", label: "France" },
        { value: "Italy", label: "Italy" },
        { value: "Spain", label: "Spain" },
      ],
    };
  }

  componentDidMount = async () => {

    let getSession_wtoken = await sessionStorage.getItem('wtoken')  
    jwt.verify(getSession_wtoken,jwtkey.jwt.key, async (err, response) => {     
    if(!err && response.login_type =="pre_client")
        {
          let getSession_pretoken = sessionStorage.getItem("token");
          if (getSession_pretoken !== null) {
            this.setState({
              pretoken: getSession_pretoken
            });
          } 
          else 
          {               
            this.setState({ redirect: true })
          }
        
        }
        else 
        {               
          Swal.fire({
            icon: 'error',
            title: jwtkey.jwt.session_expired_msg_title,
            text: jwtkey.jwt.wrong_url_msg,
            // showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK'
          })
  
          sessionStorage.removeItem('wtoken');
          sessionStorage.removeItem('id');
          sessionStorage.removeItem('name');
          sessionStorage.removeItem('email');
          sessionStorage.removeItem('login_type');
          
          sessionStorage.removeItem("speciality_id");
          this.setState({ redirect: true })
  
        }
      
      })

 

  }


  toggleLoginModal() {
    //console.log("loginclicked")

    this.setState({
      redirectLogin: true,

    });
  }

  toggleSignUpModal() {
    this.setState({
      redirectSignup: true,

    });


  }

  logout = () => {
    console.log("in logout")
    this.setState({ redirect: true })
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('id');
    sessionStorage.removeItem('name');
    sessionStorage.removeItem('wtoken');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('login_type');
    sessionStorage.removeItem('project_id');
    sessionStorage.removeItem('kol_id');
    localStorage.removeItem('SearchedProfileData');

     sessionStorage.removeItem('email_id')
    sessionStorage.removeItem('kol_id')
    sessionStorage.removeItem('getSession_Project_Id')
    sessionStorage.removeItem('Buyer_order')
    sessionStorage.removeItem('Buyer_kol_id')
    sessionStorage.removeItem('Buyer_client_name')
    sessionStorage.removeItem('Buyer_client_id')


  }
  closeSearchModal() {
    this.setState({ searchModalOpen: false, kolFormSubmitted: false });
  }
  render() {
    if (this.state.redirect) {
      return (<Redirect to={'/'} />)
    }

    if (this.state.redirectBackToKolList) {
      return (<Redirect to={{
        pathname: '/PClientViewKolList',
      }} />)
    }

    if (this.state.redirectLogin) {
      return (<Redirect to={'/user login'} />)
    }

    if (this.state.redirectSignup) {
      return (<Redirect to={'/create new account'} />)
    }

    // // Speciality Type options 
    let speciality_type = this.state.SpecialityData;
    let OptionsSpeciality = speciality_type.map((speciality_type) =>
      <option key={speciality_type.id} value={speciality_type.id}>
        {speciality_type.speciality_type}</option>
    );
    return (
      <div class="Client-header1" style={{ marginTop: '100px' }} >
        <div className="header-nav" >
          <div className="container">
            <nav class="navbar navbar-light navbar-expand-lg fixed-top" >
              <div class="container-fluid">
                <a style={{ display: "table-cell" }} className="navbar-brand"
                  href="https://www.cetas.medtech-gurus.com" target="_blank" rel="noopener">
                  <img
                    src={require('../Assets/Images/Medtech_Gurus_logo.png')}
                    width="60"
                    height="60"
                  ></img>
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse"
                  data-target="#navbarNavAltMarkup"
                  aria-controls="navbarNavAltMarkup" aria-expanded="false"
                  aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                  <div className="navbar-nav"  >
                    <div id="search" className="text-center" >

                      {/* <a className="nav-item nav-link active" href="#">Projects</a>
                                <a className="nav-item nav-link" href="#">Team Members</a> 
                                 */}
                    </div>
                  </div>
                  <div class="collapse navbar-collapse justify-content-center" id="navbarNavAltMarkup">
                    <div class="navbar-nav1">
                      {/* <a class="nav-link1"  aria-current="page" href="#0"  ><span class="btn-label"><i class="fa fa-user"></i>   </span>Welcome,  {this.state.client_name}</a> */}
                      {sessionStorage.getItem('login_type') == "client_demo" && this.props.onSubmitHandler !== undefined ?
                        (<div class="preclient">
                          <div className="searchbox">
                            <Input
                              type="select"
                              id="SpecilitySelect"
                              style={{ backgroundColor: "white" }}
                              value={this.state.selectedKol}
                              onChange={(e) => {
                                console.log(e.target.value, "target");
                                this.setState({ selectedKol: e.target.value });
                              }}
                            >
                              <option value="">Search KOLs</option>
                              {this.state.kols.map((kol) => (
                                <option key={kol.id} value={kol.label}>
                                  {kol.label}
                                </option>
                              ))}
                            </Input>
                          </div>
                          {/* <a
                            href="#" data-bs-toggle="modal"
                            onClick={this.openSearchModal.bind(this)}
                            style={{
                              background: "#0C4272"
                              , color: "#fff"
                              , paddingTop: "8px"
                              , paddingBottom: "8px"
                              , paddingLeft: "10px"
                              , paddingRight: "10px"
                              , borderRadius: "5px"
                              , minWidth: "110px"
                              , fontWeight: "400"
                              , fontSize: "14px"
                              , marginLeft: "3px"
                              , height: "auto"
                            }}
                          >
                            <i class="fa fa-search" style={{ fontWeight: 'lighter' }}></i> &nbsp;&nbsp; SEARCH
                          </a> */}
                          {/* Search Button */}
                          {/* <div className="searchbutton">
                          <a
                            href="#"
                            data-bs-toggle="modal"
                            onClick={() => this.openSearchModal()}
                            className="btn btn-brand ms-lg-1"
                            style={{
                              paddingLeft: "20px",
                              marginTop: "-5px",
                              paddingRight: "30px",
                              paddingTop: "10px",
                              paddingBottom: "10px",
                              fontSize: "12px",
                            }}
                          >
                            {" "}
                            <i
                              class="fa fa-search"
                              style={{ fontWeight: "lighter" }}
                            ></i>{" "}
                            &nbsp;&nbsp; SEARCH
                          </a>
                        </div> */}
                          {/* <div className='searchbox' style={{ marginTop: '15px' }}>
                          <Input type="select" id="SpecilitySelect"
                            //value={this.state.speciality_name}
                            onChange={(e) => {
                              this.setState({ speciality_name: e.target.value })
                            }}
                          >
                            <option value="0" >abc Search Speciality...</option>
                            {OptionsSpeciality}

                          </Input>
                        </div> */}
                        </div>) : (<div />)}

                    </div>
                    {/* <div style={{ textAlign: "center",marginLeft:"-100px" }}> */}

                    {/* <a href="#" data-bs-toggle="modal" className="btn ms-lg-1" style={{ backgroundColor: '#0C4272', color: '#fff', paddingLeft: '20px', paddingRight: '30px', paddingTop: '10px', paddingBottom: '10px', fontSize: '12px' }}> <i class="fa fa-search" style={{ fontWeight: 'lighter' }}></i> &nbsp;&nbsp; SEARCH</a> */}
                    {/* </div> */}
                  </div>

                  <div class="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
                    <div class="navbar-nav1" >

                      <div className='logoutbox' style={{ paddingRight: '30px' }}>

                        {(this.state.logoutflag == true) ?
                          (<div>
                            <div className='user_signout'><span class="btn-label"><i class="fa fa-user" style={{ marginRight: '10px' }}></i>   </span> {this.state.client_name}</div>
                            <button class="button_signout" style={{ fontWeight: 'lighter' }} onClick={this.logout.bind(this)}><span class="btn-label" ><i class="fa fa-power-off"></i>  </span> Logout </button>

                          </div>) : (
                            <div className="nav-clhsignup">
                              <a
                                href="#"
                                style={{ color: "#A90007", fontWeight: "bolder", marginRight: '3px' }}
                                data-bs-toggle="modal"
                                onClick={this.toggleLoginModal.bind(this)}
                              >
                                Log In
                              </a>
                              <a
                                href="#"
                                style={{ color: "#A90007", fontWeight: "bolder" }}
                                data-bs-toggle="modal"
                                onClick={this.toggleSignUpModal.bind(this)}
                              >
                                / Sign Up
                              </a>
                            </div>
                            // <div>

                            //   <button class="button_signout" style={{ fontWeight: 'lighter' }} >
                            //     {/* This is client login signup button which needs to be changed */}
                            //     <a onClick={this.toggleLoginModal.bind(this)}>asdLogin</a> / <a onClick={this.toggleSignUpModal.bind(this)}>Sign Up</a>
                            //   </button>

                            // </div>
                          )

                        }
                        {/* <div className='user_signout'><span class="btn-label"><i class="fa fa-user" style={{ marginRight:'10px'}}></i>   </span> {this.state.client_name}</div>      
      <button class="button_signout" style={{ fontWeight:'lighter'}} onClick={this.logout.bind(this)}><span class="btn-label" ><i class="fa fa-power-off"></i>  </span> Logout </button>
    
      <button class="button_signout" style={{ fontWeight:'lighter'}} onClick={this.logout.bind(this)}> 
                <a >Login</a>/ <a>Sign Up</a>      
      </button> */}

                      </div>
                    </div>
                  </div>

                  {/* <div class="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
    <div class="navbar-nav">
        <div className='user_signout'><span class="btn-label"><i class="fa fa-user"></i>   </span> {this.state.client_name}kumar</div>      
      
        <button class="button_signout"  onClick={this.logout.bind(this)}><span class="btn-label" ><i class="fa fa-power-off"></i>  </span> Logout</button>
        
    </div>
    </div> */}

                </div>
              </div>




            </nav>
          </div>


        </div>

      </div>
    );
  }
}

export default ClientHeader;