import React, { Component } from 'react'

class FAQs extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() {
        return (
            <div><text>FAQS</text></div>
        );
    }
}

export default FAQs;