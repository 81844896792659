import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';


class EmployeeHeader extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            redirect: false,
        };

        
    }

    componentDidMount = async () => {

        alert('hi');
        let getSession_Employee_Email = await sessionStorage.getItem('employee_email')
        if (getSession_Employee_Email !== null) {
          await this.setState({
            employee_email: getSession_Employee_Email,
          })
        }
    
        let getSession_Employee_ID = await sessionStorage.getItem('employee_id')
        if (getSession_Employee_ID !== null) {
          await this.setState({
            employee_id: getSession_Employee_ID,
          })
        }
    
        let getSession_Employee_Name = await sessionStorage.getItem('employee_name')
        if (getSession_Employee_Name !== null) {
          await this.setState({
            employee_name: getSession_Employee_Name,
          })
        }
        
        alert(getSession_Employee_Email);
        alert(getSession_Employee_ID);
        alert(getSession_Employee_Name);
        
       
    }


    logout = () => {
        console.log("in logout")
        this.setState({redirect: true})
        sessionStorage.removeItem('employee_email')
        sessionStorage.removeItem('employee_id')
        sessionStorage.removeItem('employee_name')
    }

    
    render() {
        if (this.state.redirect) {
            return (<Redirect to={'/'}/>)
        }
        return (
            <div class="header-nav">
                <div class="container">
                    <nav class="navbar navbar-expand-lg">
                        <a className="navbar-brand" href="https://cetas-healthcare.com/">
                            <img
                                src={require('../Assets/Images/cetas-logo.png')} 
                            />  
                        </a>
                        <button class="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup"
                                aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <div class="navbar-nav">
                                {/* <a class="nav-item nav-link active" href="#">Home <span
                                    class="sr-only">(current)</span></a>
                                <a class="nav-item nav-link" href="#">Projects</a>
                                <li class="nav-item  dropdown multi-option">
                                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        KOLs
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <a class="dropdown-item" href="#">KOL Ranking</a>
                                        <a class="dropdown-item" href="#">KOL QC</a>
                                    </div>
                                </li>
                                <a class="nav-item nav-link" href="#">
                                    <img src={require('../Assets/Images/setting.svg')} /> Search
                                </a> */}
                            </div>
                        </div>
                        <a class="nav-item nav-link username-box">
                        <img src={require('../Assets/Images/user.svg')}  /> {this.props.EmployeeName} </a>

                        <a className="nav-item nav-link username-box  float-right" 
                            onClick={this.logout.bind(this)}>
                            <img 
                                src={require('../Assets/Images/logoutByVibha.svg')} 
                            />
                                Logout
                        </a>
                    </nav>
                </div>
            </div>
        );
    }
}

export default EmployeeHeader;