import React, { Component, useRef, useState } from "react";
import { Redirect, Link, NavLink } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Button,
  Col,
  Form,
  Popover,
  PopoverHeader,
  PopoverBody,
  Alert,
  FormFeedback,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import LoadingBar from "react-top-loading-bar";
import PhoneInput from "react-phone-input-2";
import { Multiselect } from "react-widgets";
//import Spinner from '../Assets/Spinner'
import {
  RingLoader,
  FadeLoader,
  DotLoader,
  PropagateLoader,
} from "react-spinners";

export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement("script");
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}

class KOLEngagement extends Component {
  // typeAheadref = useRef < Typeahead > (null);
  constructor(props) {
    super(props);
    this.state = {
      subClinicalKolEnabled: true,
      subSpecilityVisible: false,
      ShowBar: false,
      alert: null,
      pretoken:"",
      redirect: false,
      redirectLogin: false,
      redirectSignup: false,
      redirecthome: false,
      redirectPClientViewKolList: false,
      redirectAdministrator: false,
      profiletypeErr: false,
      redirectVisitor: false,
      redirectResercher: false,
      redirectManager: false,
      redirectEmployee: false,
      redirectClientDashboard: false,
      redirectPClientDashboard: false,

      redirectAdministratorDashboard: false,
      redirectVisitorDashboard: false,
      redirectKolProfile: false,
      redirectTermsandPrivacy: false,

      redirectServices: false,

      kdidentificationhidediv: false,
      kdprofilinghidediv: false,
      kdinteracthidediv: false,

      GetInTouchModal: true,

      ClientLoginModal: false,
      AdministratorLoginModal: false,
      VisitorLoginModal: false,
      KolProfileloginModal: false,
      WrongTeamAlertModal: false,
      ContactUsModal: false,
      NewGurusModal: false,
      NewClientsModal: false,
      TermsAndConditionModal: false,
      TermsAndConditionModalForTeam: false,

      //resercherd id for redirectig to researcher dashoard
      RID: "",
      resercher_name: "",
      kols: [
        { id: "clinical", label: "Clinical" },
        { id: "non-clinical", label: "Non-Clinical" },
      ],
      kolSubType: [
        { value: "Payers", label: "Payers" },
        { value: "HTA Experts", label: "HTA Experts" },
        {
          value: "Hospital Procurement Experts",
          label: "Hospital Procurement Experts",
        },
        { value: "Advocacy Group Experts", label: "Advocacy Group Experts" },
        {
          value: "MedTech Industry Experts",
          label: "MedTech Industry Experts",
        },
      ],
      GetInTouchData: {
        client_name: "",
        email_id: "",
        company_nm: "",
        mobile_no: "",
        designation: "",
        message: "",
        status: "New",
        // loginType: 'pre_client',
      },
      Procedure: "",
      procedureSelected: false,
      conditionSelected: false,
      // Manager info
      manager_id: "",
      manager_name: "",

      // Employee info
      employee_id: "",
      employee_name: "",

      speciality_id: "",
      speciality_name: "",

      // For validations
      PhoneErr: "",
      DesignationErr: "",
      MessageErr: "",
      UsernameErr: "",
      UseremailErr: "",
      CompanyNameErr: "",

      PasswordErr: "",
      SelectRoleErr: "",
      wrngUsPsErr: "",

      clientUsernameErr: "",
      clientPasswordErr: "",

      administratorUsernameErr: "",
      administratorPasswordErr: "",

      visitorUsernameErr: "",
      visitorPasswordErr: "",

      kolProfileUsernameErr: "",
      kolProfilePasswordErr: "",
      // /kolProfileSelectRoleErr: '',

      //For getting values of input
      loginData: {
        client_id: "",
        password: "",
        role: "",
        // loginType: 'team',
      },

      ClientLoginData: {
        login_id: "",
        password: "",
        login_type: "client",
      },
      teamLoginIdForTerms: "",
      //Administrator
      AdministratorLoginData: {
        login_id: "",
        password: "",
        login_type: "administrator",
      },
      //visitor
      VisitorLoginData: {
        login_id: "",
        password: "",
        login_type: "visitor",
      },

      KolProfileLoginData: {
        username: "",
        password: "",
        // kol_login_role: '',
      },
      kolLoginIdForTerms: "",

      contact_us: {
        first_name: "",
        last_name: "",
        organization: "",
        email_id: "",
        phone_no: "",
        country: "",
        learn_qpharma: "",
        representative_contact_me: "",
        qpharmas_products: "",
        technical_support_issue: "",
        need_of_assistance: "",
        receive_qpharma_news: "",
        describe_issue_question: "",
      },
      cuFirstNameErr: "",
      cuLastNameErr: "",
      cuOrganizaErr: "",
      cuEmailErr: "",
      cuPhoneErr: "",
      cuCountryErr: "",

      new_guru: {
        first_name: "",
        last_name: "",
        mobile_no: "",
        email_id: "",
        speciality: "",
        sub_speciality: "",
        procedure_type: "",
        country: "",
        state: "",
        current_place_of_work: "",
        department: "",
        linkedin_link: "",
        additional_info: "",
      },
      ngFirstNameErr: "",
      ngLastNameErr: "",
      ngEmailErr: "",
      cuPhoneErr: "",
      ngCountryErr: "",
      ngStateErr: "",

      new_client: {
        first_name: "",
        last_name: "",
        email_id: "",
        mobile_no: "",
        msg: "",
      },
      ncFirstNameErr: "",
      ncLastNameErr: "",
      ncEmailErr: "",
      // ncPhoneErr: '',

      service1: false,
      service2: false,
      service3: false,
      service4: false,
      service5: false,
      service6: false,

      // to get data from dropdown
      SpecialityData: [],
      ProcedureData: [],
      SubSpecialityData: [],
      CountryListData: [],
      StateData: [],

      // to set form values
      Speciality: "",
      SubSpecialityType: [],
      ProcedureType: [],

      Market: [],
      Market2: [],

      PersonalInformation: {},

      toggleForgotPasswordModal: false,
      ForgotPasswordData: {
        email_id: "",
        role: "",
      },
      selectedKol: "",
      searchModalOpen: false,
      kolFormSubmitted: false,
      countryList: [
        { value: "USA", label: "USA" },
        { value: "Canada", label: "Canada" },
        { value: "UK", label: "UK" },
        { value: "Germany", label: "Germany" },
        { value: "France", label: "France" },
        { value: "Italy", label: "Italy" },
        { value: "Spain", label: "Spain" },
      ],
      clinicalKolForm: {
        speciality: "",
        subSpeciality: "",
        procedure: "",
        country: "",
      },
      nonClinicalKolForm: {
        kolSubType: "",
        country: "",
        fullName: "",
        workEmail: "",
        phone: "",
        companyName: "",
        jobTitle: "",
        message: "",
      },
      nonClinicalContactFormSubmitted: false,
      showNonClinicalContactForm: false,
    };
  }

  componentDidMount = async () => {
    console.log("in componentDidMount");

    let getSession_pretoken = sessionStorage.getItem("token");
    if (getSession_pretoken !== null) {
      this.setState({
        pretoken: getSession_pretoken
      });
    }
    const options = {
      method: "POST",
    };
    // Speciality type data

    fetch(
      global.websiteUrl +
      "/service/KOL_landing/Get_Speciality_List",
      options
    )
      .then((Response) => Response.json())
      .then((findresponse) => {
        console.log(findresponse);
        var testDataSpeciality = findresponse.data;
        // console.log("testDataSpeciality", JSON.stringify(testDataSpeciality));
        if (findresponse.data != "undefined") {
          this.setState({
            SpecialityData: findresponse.data.map((e) => ({
              label: e.speciality_type,
              value: e.id,
            })),
          });
        }
      });
    // Country list(market)
    fetch(global.websiteUrl + "/service/Location/get_CountryList", options)
      .then((Response) => Response.json())
      .then((findresponse) => {
        console.log(findresponse);
        // var testDataCountryList = findresponse.Country_Details;
        // console.log("testDataCountryList", JSON.stringify(testDataCountryList));
        this.setState({
          countryList: findresponse.Country_Details.map(c => ({ "value": c.id, "label": c.country_name })),
          Client_Unique_id: findresponse.client_system_id,
        });
      });
  };

  setSubSpecility = (id) => {
    const formdata = new FormData();
    formdata.append("speciality_type_id", id);
    const options = {
      method: "POST",
      body: formdata,
    };
    // Speciality type data
    const url = "/service/KOL_landing/Get_Sub_Speciality_List";
    fetch(global.websiteUrl + url, options)
      .then((Response) => Response.json())
      .then((findresponse) => {
        let subSpecilityList = findresponse.data.map(e => ({
          label: e.sub_speciality_type,
          value: e.id,
        }))
        if (findresponse.data != "undefined") {
          this.setState({
            SubSpecialityType: subSpecilityList,
            subSpecilityVisible: subSpecilityList[0].label !== "NA" ? true : false
          });
        }
      });
  };

  toggleGetInTouchModal() {
    //console.log("loginclicked")

    this.setState({
      GetInTouchModal: !this.state.GetInTouchModal,
    });
  }

  //login = toggle validate and login
  toggleLoginModal() {
    this.setState({
      redirectLogin: true,
    });
  }

  toggleSignUpModal() {
    sessionStorage.removeItem("quotation_id", "");
    this.setState({
      redirectSignup: true,
    });
  }

  toggleWithoutidModal() {
    setTimeout(function () {
      window.location.reload();
    }, 3000);
  }

  closeSearchModal() {
    this.setState({ searchModalOpen: false, kolFormSubmitted: false });
  }

  closeNonClinicalContactModal() {
    this.setState({ showNonClinicalContactForm: false });
  }

  openSearchModal() {
    if (this.state.selectedKol != "") {
      console.log(this.state.selectedKol);
      this.setState({ searchModalOpen: true });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops",
        text: "Please select KOL",
        confirmButtonColor: "#d33",
        confirmButtonText: "OK",
      });
    }
  }

  makespaceondivkdidentification() {
    this.setState({
      hidedivkdprofiling: false,
      hidedivkdinteract: false,
      hidedivkdidentification: true,
    });
  }

  makespaceondivkdprofiling() {
    this.setState({
      hidedivkdidentification: false,
      hidedivkdinteract: false,
      hidedivkdprofiling: true,
    });
  }

  makespaceondivkdinteract() {
    this.setState({
      hidedivkdidentification: false,
      hidedivkdprofiling: false,
      hidedivkdinteract: true,
    });
  }

  makespaceondivhome() {
    this.setState({
      hidedivkdidentification: false,
      hidedivkdprofiling: false,
      hidedivkdinteract: false,
    });
  }

  validate = () => {
    let UsernameErr = "";
    let UseremailErr = "";
    let CompanyNameErr = "";
    let PhoneErr = "";
    let DesignationErr = "";
    let MessageErr = "";

    if (!this.state.GetInTouchData.client_name) {
      UsernameErr = "Please Enter Full Name";
    }
    if (!this.state.GetInTouchData.email_id) {
      UseremailErr = "Please Enter Email id";
    }

    if (!this.state.GetInTouchData.company_nm) {
      CompanyNameErr = "Please Enter Company Name";
    }

    if (!this.state.GetInTouchData.mobile_no) {
      PhoneErr = "Please Enter phone";
    }

    if (!this.state.GetInTouchData.designation) {
      DesignationErr = "Please Enter designation";
    }

    if (!this.state.GetInTouchData.message) {
      MessageErr = "Please Enter message";
    }

    if (
      UsernameErr ||
      UseremailErr ||
      CompanyNameErr ||
      PhoneErr ||
      DesignationErr ||
      MessageErr
    ) {
      this.setState({
        UsernameErr,
        PhoneErr,
        UseremailErr,
        CompanyNameErr,
        DesignationErr,
        MessageErr,
      });
      return false;
    }
    return true;
  };

  GetInTouchfun() {
    const isValid = this.validate();

    if (isValid) {
      this.setState({ ShowBar: true });

      const url =
        global.websiteUrl + "/service/KOL_landing/check_duplicate_get_in_touch";
      let formData = new FormData();
      formData.append("email_id", this.state.GetInTouchData.email_id);
      formData.append('token_id',this.state.pretoken);
   
      const config = {
        //   headers: { 'content-type': 'multipart/form-data' }
      };
      axios
        .post(url, formData, config)
        .then((response) => {
          console.log(response);
          var lgstatus = response.data.status;
          console.log("lgstatus", JSON.stringify(lgstatus));

          this.setState({ ShowBar: false });

          //  alert(lgstatus);

          if (lgstatus == false) {
            this.SaveGetInTouchDatafun();
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops",
              text: "Emails Already exits pls change new email",
              confirmButtonColor: "#d33",
              confirmButtonText: "OK",
            });

            this.setState({
              UseremailErr: "Change New Email",
              confirmpasswordErr: "",
              UsernameErr: "",
              CompanyNameErr: "",
              PasswordErr: "",
              termconditionErr: "",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  SaveGetInTouchDatafun() {
    this.setState({
      ShowBar: true,
    });

    const url =
      global.websiteUrl + "/service/KOL_landing/save_enqury";
    let formData = new FormData();

    formData.append(
      "enqury_info",
      JSON.stringify(this.state.GetInTouchData)
    );
    formData.append('token_id',this.state.pretoken);
   
    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    };

    axios
      .post(url, formData, config)
      .then((response) => {
        console.log(response);
        var lgdata = response.data;
        console.log("lgdata", JSON.stringify(lgdata));
        var lgstatus = response.data.status;
        console.log("lgstatus", JSON.stringify(lgstatus));
        //      console.log("response message2", response.data.message)

        this.setState({
          ShowBar: false,
        });

        if (response.data.status == true) {
          Swal.fire({
            icon: "success",
            //text: response.data.message,
            text: "Success! Your message has been successfully delivered to us. Our team will get in touch with one working day.",
            confirmButtonColor: "#d33",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            icon: "error",
            text: "Failed",
            confirmButtonColor: "#d33",
            confirmButtonText: "OK",
          });
        }

        setTimeout(function () {
          window.location.reload();
        }, 4000);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  submitKOLform() {
    this.setState({ kolFormSubmitted: true });
    if (this.state.selectedKol === this.state.kols[0].label) {
      if (this.state.clinicalKolForm.speciality) {
        sessionStorage.setItem("client_email", "");
        sessionStorage.setItem("client_id", "Guest");
        sessionStorage.setItem("client_name", "");
        sessionStorage.setItem(
          "subspeciality",
          this.state.clinicalKolForm.subSpeciality?.[0]?.label
        );
        sessionStorage.setItem(
          "procedure",
          this.state.clinicalKolForm.procedure?.[0]?.label
        );
        sessionStorage.setItem(
          "speciality_id",
          this.state.clinicalKolForm?.speciality?.[0]?.value
        );
        sessionStorage.setItem(
          "speciality",
          this.state.clinicalKolForm?.speciality?.[0]?.label
        );
        this.setState({ redirectPClientViewKolList: true });
      } else {
        Swal.fire({
          icon: "error",
          text: "Please Select Speciality",
          confirmButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    } else {
      if (this.state.nonClinicalKolForm.kolSubType) {
        console.log(this.state.nonClinicalKolForm.kolSubType)
        this.setState({
          showNonClinicalContactForm: true,
          searchModalOpen: false,
        });
      } else {
        Swal.fire({
          icon: "error",
          text: "Please Select KOL Subtype",
          confirmButtonColor: "#d33",
          confirmButtonText: "OK",
        });
      }
    }
  }

  handleClinicalFormField(id, value) {
    console.log({
      id,
      value,
    });
    let { clinicalKolForm } = this.state;
    // this.setState({
    //   ProcedureType: []
    // })
    if (id != '')
      clinicalKolForm[id] = value;

    clinicalKolForm['procedureType'] = this.state.Procedure
    clinicalKolForm['procedure'] = ""

    // const spId = this.state.SpecialityData.filter(sp => sp.speciality_type === value)[0]
    if (id == "speciality" && value?.[0]?.value !== undefined) {
      this.state.subClinicalKolEnabled = false
      this.setSubSpecility(value?.[0]?.value);
      this.state.profiletypeErr = false;

      // this.refs.typeahead.getInstance().clear()
      this.setState({
        ProcedureType: [],
        Procedure: false,
        procedureSelected: false,
        conditionSelected: false
      })
    } else if (id == "procedure" && value?.[0]?.value !== undefined) {
      this.state.subClinicalKolEnabled = false
    } else {
      this.state.subClinicalKolEnabled = true
    }
    this.setState({ clinicalKolForm: clinicalKolForm });
    console.log(this.state.clinicalKolForm);
  }

  handleNonClinicalFormField(id, value) {
    this.state.subClinicalKolEnabled = false
    let { nonClinicalKolForm } = this.state;
    nonClinicalKolForm[id] = value;
    this.setState({ nonClinicalKolForm: nonClinicalKolForm });
  }

  validateField(type, id) {
    console.log('Validate field called with - ', { type, id })
    const form = this.state[type];
    if (this.state.kolFormSubmitted) {
      if (form[id]) {
        console.log('returning false')
        return false;
      } else {
        console.log('returning true')
        return true;
      }
    } else {
      console.log('returning false')
      return false;
    }
  }

  validateContactFormField(id) {
    const form = this.state.nonClinicalKolForm;
    // console.log(this.state.nonClinicalContactFormSubmitted, "check");
    if (this.state.nonClinicalContactFormSubmitted) {
      if (form[id]) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  onProcedureChanged = (e) => {
    console.log(e.target.value);
    let { clinicalKolForm } = this.state
    console.log(clinicalKolForm)
    let speciality_id = clinicalKolForm.speciality
    if (!speciality_id) {
      this.state.profiletypeErr = 'Please select Speciality from dropdown!'
    }
    const value = e.target.value;
    this.setState({
      ProcedureType: [],
    });
    // console.log(this.refs.typeahead.clear())
    if (this.refs.typeahead) { this.refs.typeahead.clear() }
    value === "Procedure" ? this.setState({ procedureSelected: true, conditionSelected: false }) : this.setState({ conditionSelected: true, procedureSelected: false })
    const formdata = new FormData();
    if (speciality_id?.[0]) {
      formdata.append("speciality_type_id", speciality_id[0].value);
      this.setState({ Procedure: value });
      this.state.profiletypeErr = false

    }
    const options = {
      method: "POST",
      body: formdata,
    };
    let url =
      value === "Procedure"
        ? "/service/KOL_landing/Get_Procedure_List"
        : "/service/KOL_landing/Get_Condition_List";
    fetch(global.websiteUrl + url, options)
      .then((Response) => Response.json())
      .then((findresponse) => {
        console.log(findresponse);
        var procedureList = findresponse.data;
        if (procedureList != "undefined") {
          this.setState({
            ProcedureType: procedureList.map((item) => ({
              label:
                value === "Procedure"
                  ? item.procedure_type
                  : item.condition_type,
              value: item.id,
            })),
          });
        }
      });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/home"} />;
    }

    if (this.state.redirecthome) {
      return <Redirect to={"/home"} />;
    }

    if (this.state.redirectLogin) {
      return <Redirect to={"/user login"} />;
    }

    if (this.state.redirectSignup) {
      return <Redirect to={"/create new account"} />;
    }

    
    if (this.state.redirectPClientViewKolList) {
      return <Redirect to={"/PClientViewKolList"} />;
    }

    if (this.state.redirectAdmin) {
      return <Redirect to={"/AdminDashboard"} />;
    }

    if (this.state.redirectResercher) {
      return (
        <Redirect
          to={{
            pathname: "/ResercherDashboard",
            state: {
              RID: this.state.RID,
              resercher_name: this.state.resercher_name,
            },
          }}
        />
      );
    }

    if (this.state.redirectManager) {
      return (
        <Redirect
          to={{
            pathname: "/ManagerDashboard",
            // state:{manager_id: this.state.manager_id , manager_name: this.state.manager_name}
          }}
        />
      );
    }

    if (this.state.redirectEmployee) {
      return (
        <Redirect
          to={{
            pathname: "/EmployeeDashboard",
            //  state:{employee_id: this.state.employee_id , employee_name: this.state.employee_name}
          }}
        />
      );
    }

    if (this.state.redirectClientDashboard) {
      return (
        <Redirect
          to={{
            pathname: "/ClientViewProject",
          }}
        />
      );
    }

    if (this.state.redirectAdministratorDashboard) {
      return (
        <Redirect
          to={{
            pathname: "/AdministratorViewProject",
          }}
        />
      );
    }

    if (this.state.redirectVisitorDashboard) {
      return (
        <Redirect
          to={{
            pathname: "/VisitorDashboard",
          }}
        />
      );
    }

    if (this.state.redirectKolProfile) {
      return (
        <Redirect
          to={{
            pathname: "/KolProfileDashboard",
          }}
        />
      );
    }

    if (this.state.redirectTermsandPrivacy) {
      return (
        <Redirect
          to={{
            pathname: "/TermsPrivacyPolicy",
          }}
        />
      );
    }
    if (this.state.redirectKOLidentification) {
      return (
        <Redirect
          to={{
            pathname: "/kolidentification",
          }}
        />
      );
    }
    if (this.state.redirectKOLEngagement) {
      return (
        <Redirect
          to={{
            pathname: "/kolEngagement",
          }}
        />
      );
    }
    if (this.state.redirectabout) {
      return (
        <Redirect
          to={{
            pathname: "/about",
          }}
        />
      );
    }
    if (this.state.redirectpricing) {
      return (
        <Redirect
          to={{
            pathname: "/pricing",
          }}
        />
      );
    }
    if (this.state.redirectContact) {
      return (
        <Redirect
          to={{
            pathname: "/contact",
          }}
        />
      );
    }
    if (this.state.redirectBlogs) {
      return (
        <Redirect
          to={{
            pathname: "/blogs",
          }}
        />
      );
    }
    if (this.state.redirectCaseStudy) {
      return (
        <Redirect
          to={{
            pathname: "/casestudy",
          }}
        />
      );
    }
    if (this.state.redirectKOLCompliant) {
      return (
        <Redirect
          to={{
            pathname: "/compliant",
          }}
        />
      );
    }
    let speciality_type = this.state.SpecialityData;
    let OptionsSpeciality = speciality_type.map((speciality_type) => (
      <option key={speciality_type.id} value={speciality_type.id}>
        {speciality_type.speciality_type}
      </option>
    ));

    return (
      <body id="landing-body">
        <div>
          {this.state.ShowBar ? (
            <Modal isOpen={true} className="propagateLoader" centered>
              <PropagateLoader color={"#A90007"} size="40px" loading="true" />
            </Modal>
          ) : null}
        </div>
        {this.state.ShowProcedure ? (
          <Modal isOpen={true} className="propagateLoader" centered>
            <PropagateLoader color={"#A90007"} size="40px" loading="true" />
          </Modal>
        ) : null}

        {AddLibrary("./js/app.js")}

        <nav
          className="navbar navbar-expand-lg navbar-landing sticky-top" id="navbar"
        >
          <div className="content container-fluid">
            <a
              className="navbar-brand"
              href="https://www.cetas.medtech-gurus.com"
            >
              <img
                src={require('../src/Assets/Images/Medtech_Gurus_logo.png')}
                width="60"
                height="60"
              ></img>
            </a>
            <div className="menu-bar">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="bx bx-menu"></i>
              </button>
              <div
                className="navbar-collapse collapse"
                id="navbarNav">
                <ul className="navbar-nav me-md-auto ms-auto mt-2 mt-lg-0">
                <li className="nav-item">
                    <a
                      href='/home' onClick={this.redirecthome}
                      className="nav-link"
                    >
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href='/about us' onClick={this.redirectabout}
                    >
                      About Us
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href='/kol identification' onClick={this.redirectKOLidentification}
                    >
                      KOL Identification
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href='/kol engagement' onClick={this.redirectKOLEngagement}
                    >
                      KOL Engagement
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href='/pricing' onClick={this.redirectpricing}
                    >
                      Pricing
                    </a>
                  </li>                  
                  <li className="nav-item">
                  <a
                      className="nav-link"
                      href='/blogs' onClick={this.redirectBlogs}
                    >
                      Blogs
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href='/contact us' onClick={this.redirectContact}
                    >
                      Contact Us
                    </a>
                  </li>
                  <li className="nav-signup align-items-center d-flex d-lg-none">
                    <a
                      href="javascript:void(0);"
                      style={{ color: "#A90007", fontWeight: "bolder" }}
                      data-bs-toggle="modal"
                      onClick={this.toggleLoginModal.bind(this)}
                    >
                      Log In
                    </a>
                    <a
                      href="javascript:void(0);"
                      style={{ color: "#A90007", fontWeight: "bolder" }}
                      data-bs-toggle="modal"
                      onClick={this.toggleSignUpModal.bind(this)}
                    >
                      / Sign Up
                    </a>
                  </li>
                </ul>

              </div>
            </div>
            <div className="Navheaderlink justify-content-between d-none d-lg-flex">
              <ul className="navbar-nav  align-items-center gap-20">
                <li className="nav-social">
                  <a
                    href="https://www.linkedin.com/company/cetas-healthcare/"
                    target="_blank"
                  >
                    <i className="bx bxl-linkedin"></i>
                  </a>
                </li>
                <li className="nav-item-email">
                  <a
                    className="link-h email-link"
                    onClick={this.makespaceondivhome.bind(this)}
                    href="mailto:kolmanagement@cetas-healthcare.com"
                    style={{ fontWeight: "normal" }}
                  >
                    <img src="./static/media/email.f2b82b84.svg" />
                    kolmanagement@cetas-healthcare.com
                  </a>
                </li>
              </ul>

              <div className="nav-signup align-items-center">
                <a
                  href="javascript:void(0);"
                  style={{ color: "#A90007", fontWeight: "bolder" }}
                  data-bs-toggle="modal"
                  onClick={this.toggleLoginModal.bind(this)}
                >
                  Log In
                </a>
                <a
                  href="javascript:void(0);"
                  style={{ color: "#A90007", fontWeight: "bolder" }}
                  data-bs-toggle="modal"
                  onClick={this.toggleSignUpModal.bind(this)}
                >
                  / Sign Up
                </a>
              </div>
            </div>
          </div>
        </nav>
        <div id="page-banner" className="bg-service">
          <div className="bg-overlay"></div>
          <div className="container-fluid banner-section">
            <h1 className="pb-1 text-white">KOL Engagement</h1>
            <ol class="breadcrumb m-0 p-0 bg-transparent">
              <li class="breadcrumb-item"><a href="/home">Home</a></li>
              <li class="breadcrumb-item active">KOL Engagement</li>
            </ol>
          </div>
        </div>
        <section class="section">
          <div class="container">
            <div class="justify-content-center row">
              <div class="col-lg-12">
                <div class="mb-4">
                  <h2 class="mb-3 fs-4 fw-bold lh-base text-start">Key Engagement Platform for the Medical Device Industry</h2>
                  <p class="text-justify">At Cetas Healthcare, we believe that the right knowledge can transform patient care. Our Key Opinion Leader (KOL) Engagement Services are meticulously crafted to empower your healthcare initiatives, from groundbreaking product launches to comprehensive clinical trials. Through a spectrum of tailored engagements — be it telephonic consultations, in-depth advisory board meetings, or dynamic webinars — we bridge the gap between your needs and the experts who can meet them.</p>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-12">
                <div class="text-center mb-4">
                  <h3 class="mb-0 fw-bold lh-base fs-4">Cetas HC offers varied engagement/consultation options for various requirements </h3>
                </div>
              </div>
            </div>
            <div class="row justify-content-center align-items-start">
              <div class="col-12 col-lg-4 h-100 mb-2">
                <div id="table-card" class="point1 h-100" >
                  <div class="title">Telephonic/Web-based<br></br>1-on-1 interviews</div>
                  <span class="leftshadow"></span>
                  <span class="rightshadow"></span>
                  <div class="content h-100">
                    <div class="icon"><img src={require('../src/Assets/Images/old-typical-phone_13936.svg')} class="m-auto" /></div>
                    <ul class="p-0 fw-normal lh-base fs-14">
                      <li class="d-flex align-items-start"><img src={require('../src/Assets/Images/tick_6460581.png')} class="me-2 mt-1" /> <span>Recruitment from the network for direct phone/web-based consultations by the client.</span></li>
                      <li class="d-flex align-items-start"><img src={require('../src/Assets/Images/tick_6460581.png')} class="me-2 mt-1" /> <span>Custom recruiting if not available in the network, will custom recruit within 2-10 working days.</span></li>
                      <li class="d-flex align-items-start"><img src={require('../src/Assets/Images/tick_6460581.png')} class="me-2 mt-1" /> <span>Direct phone/web-based consultations recruited, moderated, and completely managed by Cetas Healthcare with outputs.</span></li>
                      <li class="d-flex align-items-start"><img src={require('../src/Assets/Images/tick_6460581.png')} class="me-2 mt-1" /> <span>Long-term engagements handled and managed by Cetas HC.</span></li>
                    </ul>
                  </div>                  
                </div>
              </div>
              <div class="col-12 col-lg-4 h-100 mb-2">
                <div id="table-card" class="point2 h-100" >
                  <div class="title">Online surveys</div>
                  <span class="leftshadow"></span>
                  <span class="rightshadow"></span>
                  <div class="content h-100">
                  <div class="icon"><img src={require('../src/Assets/Images/data-management_3631359.svg')} class="m-auto" /></div>
                    <ul class="fw-normal lh-base fs-14">
                    <li class="d-flex align-items-start"><img src={require('../src/Assets/Images/tick_6460581.png')} class="me-2 mt-1" /> <span>Recruitment for online surveys of 30 - 100 respondents a length of interview of ~15-20 minutes.</span></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-4 h-100 mb-2">
                <div id="table-card" class="point3 h-100" >
                  <div class="title">In-person<br></br>Engagements</div>
                  <span class="leftshadow"></span>
                  <span class="rightshadow"></span>
                  <div class="content h-100">
                  <div class="icon"><img src={require('../src/Assets/Images/discussion_9520385.svg')} class="m-auto" /></div>
                    <ul class="fw-normal lh-base fs-14">
                    <li class="d-flex align-items-start"><img src={require('../src/Assets/Images/tick_6460581.png')} class="me-2 mt-1" /> <span>Recruitment for in-person engagement by the client.</span></li>
                    <li class="d-flex align-items-start"><img src={require('../src/Assets/Images/tick_6460581.png')} class="me-2 mt-1" /> <span>Advisory Board Meetings (Facilitated discussions with deliverables, featuring 4 KOLs and Cetas HC moderator).</span></li>
                    </ul></div>
                </div>
              </div>
              <div class="col-12 mt-4">
                <div class="alert alert-primary material-shadow text-center" role="alert">
                  <span class="fw-bold text-center">Custom Projects (In-depth Engagements/Consulting Solutions) - gathering primary insight via interviews and/or survey coupled with secondary research and providing executive summary and presentation with recommendations</span>
                </div>
              </div>
            </div>           
          </div>
        </section>
        <section class="section bg-grey-light">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-12">
                <div class="text-center mb-4">
                  <h3 class="mb-0 fw-bold lh-base fs-4">Use cases of these engagements / consultations</h3>
                </div>
              </div>
            </div>
            <div class="row justify-content-center gy-3">
              <div class="col-lg-3 col-md-6">
                <div class="card shadow-none py-2 h-100">
                  <div class="d-flex align-items-center gap-2 card-body py-2">
                    <div class="avatar-sm position-relative text-success">
                      <i class="bx bx-check fs-4"></i>
                    </div>
                    <a href="#!" class="stretched-link">
                      <h5 class="fs-14 m-0 p-0">Product Launch</h5>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="card shadow-none py-2 h-100">
                  <div class="d-flex align-items-center gap-2 card-body py-2">
                    <div class="avatar-sm position-relative text-success">
                      <i class="bx bx-check fs-4"></i>
                    </div>
                    <a href="#!" class="stretched-link">
                      <h5 class="fs-14 m-0 p-0">Product Training</h5>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="card shadow-none py-2 h-100">
                  <div class="d-flex align-items-center gap-2 card-body py-2">
                    <div class="avatar-sm position-relative text-success">
                      <i class="bx bx-check fs-4"></i>
                    </div>
                    <a href="#!" class="stretched-link">
                      <h5 class="fs-14 p-0 m-0">Podcasts/Webinars</h5>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="card shadow-none py-2 h-100">
                  <div class="d-flex align-items-center gap-2 card-body py-2">
                    <div class="avatar-sm position-relative text-success">
                      <i class="bx bx-check fs-4"></i>
                    </div>
                    <a href="#!" class="stretched-link">
                      <h5 class="fs-14 p-0 m-0">Conferences/Events</h5>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="card shadow-none py-2 h-100">
                  <div class="d-flex align-items-center gap-2 card-body py-2">
                    <div class="avatar-sm position-relative text-success">
                      <i class="bx bx-check fs-4"></i>
                    </div>
                    <a href="#!" class="stretched-link">
                      <h5 class="fs-14 p-0 m-0">Clinical Trials</h5>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="card shadow-none py-2 h-100">
                  <div class="d-flex align-items-center gap-2 card-body py-2">
                    <div class="avatar-sm position-relative text-success">
                      <i class="bx bx-check fs-4"></i>
                    </div>
                    <a href="#!" class="stretched-link">
                      <h5 class="fs-14 p-0 m-0">Advisory Board</h5>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="card shadow-none py-2 h-100">
                  <div class="d-flex align-items-center gap-2 card-body py-2">
                    <div class="avatar-sm position-relative text-success">
                      <i class="bx bx-check fs-4"></i>
                    </div>
                    <a href="#!" class="stretched-link">
                      <h5 class="fs-14 p-0 m-0">Regulatory and Reimbursement Support</h5>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="card shadow-none py-2 h-100">
                  <div class="d-flex align-items-center gap-2 card-body py-2">
                    <div class="avatar-sm position-relative text-success">
                      <i class="bx bx-check fs-4"></i>
                    </div>
                    <a href="#!" class="stretched-link">
                      <h5 class="fs-14 p-0 m-0">And many more...</h5>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="section">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-8">
                        <div class="text-center mb-4">
                            <h3 class="mb-0 fw-bold lh-base fs-4">Choose the plan that's right for you</h3>
                            <p class="text-muted mb-4">Cetas Healthcare’s Flexible Model Enables You To Use Units On The Services Best Suited To Your Needs</p>
                        </div>
                    </div>
                </div>
                <div class="justify-content-center row g-5 mb-5">
                    <div class="col-md-6 col-12">
                        <div class="box2">
                            <div class="price-title">
                                <sup><i class="bx bx-dollar"></i></sup><h3 class="price-cost mb-0">800</h3> <sub>/units</sub>
                            </div>
                            <h4>100 Units minimum order</h4>
                            <h4>No Expiry Date</h4>
                            {/* <div class="d-block text-center">
                            <a href="/kolidentification" class="btn btn-sm btn-orange1 rounded-pill px-4 my-3 mx-auto">Know More</a>
                            </div>                                     */}
                        </div>
                    </div>
                    <div class="col-md-6 col-12">
                        <div class="box2">
                            <div class="price-title">
                                <sup><i class="bx bx-dollar"></i></sup><h3 class="price-cost mb-0">850</h3> <sub>/units</sub>
                            </div>
                            <h4>50 Units minimum order</h4>
                            <h4>No Expiry Date</h4>
                            {/* <div class="d-block text-center">
                                <a href="/kolEngagement" class="btn btn-sm btn-orange1 rounded-pill px-4 my-3 mx-auto">Know More</a>
                            </div> */}
                        </div>
                    </div>
                </div>
                {/* <div class="text-center">
                    <a href="javascript:void(0);" class="btn btn-lg btn-orange1 rounded-pill px-4">Know More</a>
                </div> */}
                <div class="text-box-solid mb-4">Through pre-purchased units, you may apply them to multiple Cetas Healthcare services delineated above without the need for additional paperwork or contracting.
                </div>
              
                  <div id="pricetable" class="table-responsive">
                      <table class="table align-middle">
                          <thead>
                              <tr class="table-primaery">
                                  <th class="py-1 px-2 text-white align-middle">Service</th>
                                  <th class="py-1 px-2 text-white w-30 align-middle">Example Service Description</th>
                                  <th class="py-1 px-2 text-white text-center align-middle">Units</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr>
                                  <td class="py-1 px-2 align-middle">Direct Phone Consultations</td>
                                  <td class="py-1 px-2 align-middle">1 phone call up to 60 minutes</td>
                                  <td class="py-1 px-2 text-center align-middle">1</td>
                              </tr>
                              <tr>
                                  <td class="py-1 px-2 align-middle">Direct Phone Consultations <em>(Premium KOL / Expert)* - Qualify 3 of 5 Criteria - Top-tier journal publications/Pivotal trial leadership/Clinical Guidelines/Keynote Speaker/Society Office Bearer</em></td>
                                  <td class="py-1 px-2 align-middle">1 phone call up to 60 minutes</td>
                                  <td class="py-1 px-2 align-middle text-center">2-3*</td>
                              </tr>
                              <tr>
                                  <td class="py-1 px-2 align-middle">Moderated Consultations (Cetas HC Moderator conducts interviews and provides either interview notes or PPT summary insights report)</td>
                                  <td class="py-1 px-2 align-middle">5 moderated calls up to 60 minutes each</td>
                                  <td class="py-1 px-2 align-middle text-center">8-13* (subject to moderator fees)</td>
                              </tr>
                              <tr>
                                  <td class="py-1 px-2 align-middle">Transcripts of interviews conducted by Cetas HC Moderator</td>
                                  <td class="py-1 px-2 align-middle">1 phone call up to 60 minutes</td>
                                  <td class="py-1 px-2 align-middle text-center">Free (included)</td>
                              </tr>
                              <tr>
                                  <td class="py-1 px-2 align-middle">Online Survey of 30-100 Respondents</td>
                                  <td class="py-1 px-2 align-middle">1 survey with a length of interview of 15 mins</td>
                                  <td class="py-1 px-2 align-middle text-center">5-25*</td>
                              </tr>
                              <tr>
                                  <td class="py-1 px-2 align-middle">In-Person Engagements</td>
                                  <td class="py-1 px-2 align-middle">Preparation + 2 hours on site</td>
                                  <td class="py-1 px-2 align-middle text-center">5-10*</td>
                              </tr>
                              <tr>
                                  <td class="py-1 px-2 align-middle">Advisory Board (4 KOLs + Cetas HC Moderator + Deliverable)</td>
                                  <td class="py-1 px-2 align-middle ">Preparation + Ad Board (full day) + deliverable</td>
                                  <td class="py-1 px-2 align-middle text-center">30-50*</td>
                              </tr>
                              <tr>
                                  <td class="py-1 px-2 align-middle">Custom Recruiting (if not already available in network, will custom recruit within 2-10 working days)</td>
                                  <td class="py-1 px-2 align-middle">Free</td>
                                  <td class="py-1 px-2 align-middle text-center">Free (included)</td>
                              </tr>
                              <tr>
                                  <td class="py-1 px-2 align-middle">Custom Projects (In-depth Engagements/Consulting Solutions) - Gathering primary insight via interviews and/or survey coupled with secondary research and providing executive summary and presentation with recommendations</td>
                                  <td class="py-1 px-2 align-middle">Custom Scope</td>
                                  <td class="py-1 px-2 align-middle text-center">$ equivalent amount will be converted to units and deducted from outstanding balance</td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
              </div>
          </section>
        <section id="cta" class="py-4 bg-primary position-relative">
          <div class="bg-overlay bg-overlay-pattern opacity-50"></div>
          <div class="container">
            <div class="row align-items-center gy-4">
              <div class="col-sm">
                <div>
                  <h2 class="text-white mb-0 fw-bold">For pricing details, contact us</h2>
                </div>
              </div>
              <div class="col-sm-auto">
                <div>
                  <a href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#contactform" class="btn btn-brand btn-lg rounded-0">CONTACT MEDTECH EXPERTS</a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section class="section">
          <div class="container">
            <div class="justify-content-center row">
              <div class="col-lg-12">
                <div class="text-justify">
                  <p>We ensure your investment is converted into a high volume of actionable insights for better decision making in the industry by identifying the right <strong>key opinion leader healthcare </strong>across different therapeutic areas, specialities and geographies. We help you to know the REAL KOLs better by providing complete and accurate information, but we don’t just stop here! The <strong>KOL Healthcare </strong>Profiles are frequently updated from time to time to provide recent developments or changes in their activities.</p>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section class="section bg-light">
          <div class="container">
            <div class="justify-content-center row">
              <div class="col-lg-12">
                <div class="text-start mb-4">
                  <h3 class="mb-0 fw-bold lh-base fs-4">Global Reach Including Key Markets…</h3>
                </div>
              </div>
            </div>
            <div class="justify-content-center row">
              <div class="col-10">
              <img src={require('../src/Assets/Images/Global-Reach.svg')} />
              </div>
            </div>
          </div>
        </section>
        <div id="search" className="text-center">
          <p className="service_insights m-0">
            Your One-Stop-Solution for Key Opinion Leaders identification and engagement
          </p>
        </div>

        <footer class="footer bg-grey">
          <div class="container">
            <div class="row">
              <div class="col-sm-6 col-md-4 mt-4 col-lg-2 text-align">
                <a className="text-center" href="https://www.cetas.medtech-gurus.com">
                  <img src={require('../src/Assets/Images/Medtech_Gurus_logo.png')} width="100" ></img>
                </a>
                <div class="social">
                  <ul class="list-inline my-4">
                    <li class="list-inline-item"><a href="#" class="text-secbrand"><i class='fs-36 bx bxl-linkedin-square' ></i></a></li>
                  </ul>
                </div>
              </div>
              <div class="col-sm-6 col-md-4 col-lg-3 text-align">
                <div class="information">
                  <ul class="list-unstyled footer-link mt-4">
                    <li class="mb-1"><a href="/about us" class="text-decoration-none">About</a></li>
                    <li class="mb-1"><a href="/services" class="text-decoration-none">Services</a></li>
                    <li class="mb-1"><a href="/pricing" class="text-decoration-none">Pricing</a></li>
                    <li class=""><a href="/contact us" class="text-decoration-none">Contact</a></li>
                  </ul>
                </div>
              </div>
              <div class="col-sm-6 col-md-4 col-lg-3 text-align">
                <div class="information">
                  <ul class="list-unstyled footer-link mt-4">
                    <li class="mb-1"><a href="/blogs" class="text-decoration-none">Blogs</a></li>
                    <li class="mb-1"><a href="/casestudy" class="text-decoration-none">Case Study</a></li>
                  </ul>
                </div>
              </div>
              <div class="col-sm-6 col-md-4 col-lg-3 text-align">
                <div class="resources">
                  <ul class="list-unstyled footer-link mt-4">
                    <li class="mb-1"><a href="https://www.cetas.medtech-gurus.comprivacy_policy/" class="text-decoration-none">Privacy Policy</a></li>
                    <li class="mb-1"><a href="https://www.cetas.medtech-gurus.comterms-of-use/" class="text-decoration-none">Terms & Conditions</a></li>
                    <li class="mb-1"><a href="https://www.cetas.medtech-gurus.comcookie-notice/" class="text-decoration-none">Security</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center bg-black p-2">
            <p class="mb-0fs-42 text-white">{global.CopyRightText}</p>
          </div>
        </footer>
        <div class="modal fade" id="point1" tabindex="-1" aria-labelledby="point1Label" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header bg-fourth">
                <h1 class="modal-title fs-5 text-white" id="point1Label">Identification</h1>
                <button type="button" class="btn-close bg-light" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <h4 class="mb-2 lh-base">Identifying the top KOLs according to the respective criteria by:</h4>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item p-1 text-secbrand"><i class='bx bx-check-circle text-secbrand'></i> Capturing the specific information from cherry-picked data sources</li>
                  <li class="list-group-item p-1 text-secbrand"><i class='bx bx-check-circle text-secbrand'></i> Organizing and analyzing the information in a systematic manner</li>
                  <li class="list-group-item p-1 text-secbrand"><i class='bx bx-check-circle text-secbrand'></i> Applying the coherent scoring methodology to filter out the KOLs from regular HCPs</li>
                </ul>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="point2" tabindex="-1" aria-labelledby="point2Label" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header bg-fourth">
                <h1 class="modal-title fs-5 text-white" id="point2Label">Standard Deep Profiling</h1>
                <button type="button" class="btn-close bg-light" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <h4 class="mb-2 lh-base">Deep profile the KOLs with in-depth information of their activities. For the clinical KOLs, some of the aspects of deep profile includes:</h4>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item p-1 text-secbrand"><i class='bx bx-check-circle text-secbrand'></i> Literature Publications</li>
                  <li class="list-group-item p-1 text-secbrand"><i class='bx bx-check-circle text-secbrand'></i> Guidelines development</li>
                  <li class="list-group-item p-1 text-secbrand"><i class='bx bx-check-circle text-secbrand'></i> Clinical Trials</li>
                  <li class="list-group-item p-1 text-secbrand"><i class='bx bx-check-circle text-secbrand'></i> Events/Conferences</li>
                  <li class="list-group-item p-1 text-secbrand"><i class='bx bx-check-circle text-secbrand'></i> Advisory Board/Committee</li>
                </ul>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="point3" tabindex="-1" aria-labelledby="point3Label" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header bg-fourth">
                <h1 class="modal-title fs-5 text-white" id="point3Label">Standard Ranking/Rating</h1>
                <button type="button" class="btn-close bg-light" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <p class="text-secbrand text-justify">Robust 5-point rating methodology to rank the KOL based on the quantity and quality/level of engagement on various aspects. For e.g., for the clinical KOLs, the ratings and rankings are based on the performances on each of the above 5 aspects. Our ranking strategy doesn’t only apply to Global aspects but also based on the filters applied on Geography, Specialty, Procedure types, etc.</p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="point4" tabindex="-1" aria-labelledby="point4Label" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header bg-fourth">
                <h1 class="modal-title fs-5 text-white" id="point4Label">Custom Deep Profiling and Ranking/Rating</h1>
                <button type="button" class="btn-close bg-light" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <p class="text-secbrand text-justify">We can also provide custom deep profile and ranking/rating based on the specific KOL profiles that you select.</p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="point5" tabindex="-1" aria-labelledby="point5Label" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header bg-fourth">
                <h1 class="modal-title fs-5 text-white" id="point5Label">Classification of clinical KOLs</h1>
                <button type="button" class="btn-close bg-light" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <h4 class="mb-2 lh-base">We can help you identify the rock stars, the rising stars, and look out for the declining stars:</h4>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item p-1 text-secbrand"><i class='bx bx-check-circle text-secbrand'></i> <strong>Rock stars:</strong> These are the top KOLs who are on top of their field consistently over the past 3-5 years.</li>
                  <li class="list-group-item p-1 text-secbrand"><i class='bx bx-check-circle text-secbrand'></i> <strong>Rising stars:</strong> These are the KOLs who have potential to become Rock stars and you can catch them early and start engaging them.</li>
                  <li class="list-group-item p-1 text-secbrand"><i class='bx bx-check-circle text-secbrand'></i> <strong>Declining stars:</strong> These are the top KOLs who are starting to show signs of decline in the past 3-5 years and may not have the same influence in the near future.</li>
                </ul>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="point6" tabindex="-1" aria-labelledby="point6Label" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header bg-fourth">
                <h1 class="modal-title fs-5 text-white" id="point6Label">Long-term KOL Engagements</h1>
                <button type="button" class="btn-close bg-light" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <p class="text-secbrand text-justify">We provide tracking and managing the interactions and contracts with the KOLs throughout the duration of contract.</p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="point7" tabindex="-1" aria-labelledby="point7Label" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header bg-fourth">
                <h1 class="modal-title fs-5 text-white" id="point7Label">Interviews/Podcasts with KOLs</h1>
                <button type="button" class="btn-close bg-light" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <p class="text-secbrand text-justify">We also support interview moderations/podcasts with KOLS for various engagements using our in-house expert Healthcare native moderators who have 10+ years of moderation experience.</p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="point8" tabindex="-1" aria-labelledby="point8Label" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header bg-fourth">
                <h1 class="modal-title fs-5 text-white" id="point8Label">Recruitment of KOLs for Engagements</h1>
                <button type="button" class="btn-close bg-light" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <h4 class="mb-2 lh-base">Set-up KOL interactions and engagement with MedTech companies by:</h4>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item p-1 text-secbrand"><i class='bx bx-check-circle text-secbrand'></i> Connecting with the KOLs and recruiting them.</li>
                  <li class="list-group-item p-1 text-secbrand"><i class='bx bx-check-circle text-secbrand'></i> Building up contracts between the finalized KOLs and the MedTech companies for further interactions.</li>
                  <li class="list-group-item p-1 text-secbrand"><i class='bx bx-check-circle text-secbrand'></i> Setting up interactions and engagement between the KOLs and the MedTech companies based on schedule.</li>
                </ul>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="point9" tabindex="-1" aria-labelledby="point9Label" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header bg-fourth">
                <h1 class="modal-title fs-5 text-white" id="point9Label">KOL Influence Mapping</h1>
                <button type="button" class="btn-close bg-light" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <p class="text-secbrand text-justify">Do you want to know does a KOL have influence among HCPs or other healthcare personnel in one city or one region or one country or across the globe? We can provide a complete contextual understanding and influence mapping of a KOL geographically.</p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="point10" tabindex="-1" aria-labelledby="point10Label" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header bg-fourth">
                <h1 class="modal-title fs-5 text-white" id="point10Label">KOL Network</h1>
                <button type="button" class="btn-close bg-light" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <p class="text-secbrand text-justify">We provide complete picture of the KOL landscape vis-à-vis other KOLs. We provide significant insights on KOLs’ connection among their peers with regards to shared activities vis-à-vis publications, guidelines, clinical trials, conferences, and Advisory Board by Geography.</p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary btn-sm" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="contactform" tabindex="-1" aria-labelledby="contactformLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
              <div class="modal-header bg-fourth">
                <h1 class="modal-title fs-5 text-white" id="contactformLabel">Get in touch with us</h1>
                <button type="button" class="btn-close bg-danger" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <h5 className="mb-2">Please fill in the form below and we will get in touch within 1 working day</h5>
                <form className="row align-items-center justify-content-between g-2">
                  <div className="col-md-12 col-lg-6">
                    <div class="d-flex align-items-center justify-content-between"><label for="" className="form-label m-0">Full Name:<span class="mandatory">*</span></label> <div className="error"> {this.state.UsernameErr}</div></div>
                    <input type="text" className="form-control mb-0" placeholder="" id="" aria-describedby="" value={this.state.GetInTouchData.client_name} onChange={(e) => {
                      let { GetInTouchData } = this.state;
                      GetInTouchData.client_name = e.target.value;
                      this.setState({ GetInTouchData });
                    }}
                    ></input>
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <div class="d-flex align-items-center justify-content-between"><label for="" className="form-label m-0">Your work email:<span class="mandatory">*</span></label> <div className="error"> {" "} {this.state.UseremailErr}</div></div>
                    <input type="text" className="form-control mb-0" placeholder="" id="" aria-describedby="" value={this.state.GetInTouchData.email_id}
                      onChange={(e) => {
                        let { GetInTouchData } = this.state;
                        GetInTouchData.email_id = e.target.value;
                        this.setState({ GetInTouchData });
                      }}
                    ></input>
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <div class="d-flex align-items-center justify-content-between"><label for="" className="form-label m-0">Your work phone:<span class="mandatory">*</span></label> <div className="error"> {this.state.PhoneErr}</div></div>
                    <PhoneInput
                      inputStyle={{
                        width: "100%",
                        height: "50px",
                        fontSize: "15px",
                      }}
                      width="50%"
                      country={"in"}
                      value={this.state.GetInTouchData.mobile_no}
                      onChange={(mobile_no) => {
                        let { GetInTouchData } = this.state;
                        GetInTouchData.mobile_no = mobile_no;
                        this.setState({ GetInTouchData });
                      }}
                    />
                  </div>
                  <div className="col-md-12 col-lg-6">
                    <div class="d-flex align-items-center justify-content-between"><label for="" className="form-label m-0">Your company name:<span class="mandatory">*</span></label><div className="error">{" "}{this.state.CompanyNameErr}</div></div>
                    <input
                      type="text"
                      className="form-control mb-0"
                      placeholder=""
                      id=""
                      aria-describedby=""
                      value={this.state.GetInTouchData.company_nm}
                      onChange={(e) => {
                        let { GetInTouchData } = this.state;
                        GetInTouchData.company_nm = e.target.value;
                        this.setState({ GetInTouchData });
                      }}
                    ></input>
                  </div>
                  <div className="col-12">
                    <div class="d-flex align-items-center justify-content-between"><label for="" className="form-label m-0">Your job location:<span class="mandatory">*</span></label> <div className="error"> {" "} {this.state.DesignationErr} </div></div>
                    <input
                      type="text"
                      className="form-control mb-0"
                      placeholder=""
                      id=""
                      aria-describedby=""
                      value={this.state.GetInTouchData.designation}
                      onChange={(e) => {
                        let { GetInTouchData } = this.state;
                        GetInTouchData.designation = e.target.value;
                        this.setState({ GetInTouchData });
                      }}
                    ></input>
                  </div>
                  <div className="col-12">
                    <div class="d-flex align-items-center justify-content-between"><label for="" className="form-label m-0">Message:<span class="mandatory">*</span></label><div className="error"> {this.state.MessageErr}</div></div>
                    <textarea
                      name=""
                      placeholder=""
                      className="form-control mb-0"
                      id=""
                      rows="3"
                      value={this.state.GetInTouchData.message}
                      onChange={(e) => {
                        let { GetInTouchData } = this.state;
                        GetInTouchData.message = e.target.value;
                        this.setState({ GetInTouchData });
                      }}
                    ></textarea>
                  </div>
                </form>
              </div>
              <div class="modal-footer">
                {/* <button onClick={this.GetInTouchfun.bind(this)} className="btn btn-brand">Send Message</button> */}
                <a
                  onClick={this.GetInTouchfun.bind(this)}
                  className="btn btn-brand"
                >
                  Submit
                </a>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.searchModalOpen}
          toggle={() => this.closeSearchModal()}
          centered
          fullscreen
        >
          <ModalHeader toggle={() => this.closeSearchModal()}>
            <h3>{this.state.selectedKol} KOL</h3>
          </ModalHeader>
          <ModalBody style={{ padding: "0px 30px" }}>
            <div style={{ margin: "20px 0px" }}>
              {this.state.selectedKol === this.state.kols[0].label ? (
                <Form>
                  <FormGroup>
                    <Label>Select Country</Label>
                    <Typeahead
                      id="clinical_country"
                      multiple
                      onChange={(e) =>
                        this.handleClinicalFormField("country", e)
                      }
                      options={this.state.countryList}
                      placeholder="Choose a Country..."
                      selected={this.state.clinicalKolForm.country}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      Select Speciality <span class="mandatory">*</span>
                    </Label>
                    <Typeahead
                      id="speciality"
                      invalid={this.validateField(
                        "clinicalKolForm",
                        "speciality"
                      )}
                      onChange={(e) =>
                        this.handleClinicalFormField("speciality", e)
                      }
                      options={this.state.SpecialityData}
                      placeholder="Choose a Speciality..."
                      selected={this.state.clinicalKolForm.speciality}
                    />
                    {this.validateField("clinicalKolForm", "speciality") && (
                      <FormFeedback>Please Select Speciality</FormFeedback>
                    )}
                  </FormGroup>
                  <FormGroup style={this.state.subSpecilityVisible ? {} : { "display": "none" }}>
                    <Label>Select Sub-Speciality</Label>
                    <Typeahead
                      id="subSpeciality"
                      multiple
                      onChange={(e) =>
                        this.handleClinicalFormField("subSpeciality", e)
                      }
                      options={this.state.SubSpecialityType}
                      placeholder="Choose a Sub-speciality..."
                      selected={this.state.clinicalKolForm.subSpeciality}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label>Please specify the procedure/condition (if any)</Label>
                    <div
                      onChange={this.onProcedureChanged}
                      style={{
                        height: "30px",
                        width: "100%",
                        // color: "#949393",
                        backgroundColor: "#fff",
                        borderColor: "#ccc",
                      }}
                    >
                      <input
                        type="radio"
                        value="Procedure"
                        name="Procedure"
                        checked={this.state.procedureSelected}
                      />
                      <span className="Radio_button">Procedure </span>
                      <input
                        type="radio"
                        value="Condition"
                        name="Procedure"
                        checked={this.state.conditionSelected}
                        style={{ marginLeft: "5%" }}
                      />{" "}
                      Condition
                    </div>

                    <div className="Quotblanck">
                      {this.state.profiletypeErr ? this.state.profiletypeErr : null}
                    </div>
                  </FormGroup>
                  {this.state.Procedure && (
                    <FormGroup>
                      <Label>Select {this.state.Procedure}</Label>
                      <Typeahead
                        ref='typeahead'
                        id="procedure"
                        onChange={(e) =>
                          this.handleClinicalFormField("procedure", e)
                        }
                        options={this.state.ProcedureType}
                        placeholder={`Choose a ${this.state.Procedure}...`}
                        selected={this.state.clinicalKolForm.procedure}
                      />
                    </FormGroup>
                  )}


                </Form>
              ) : (
                <Form>
                  <FormGroup>
                    <Label>
                      Select KOL Subtype <span class="mandatory">*</span>
                    </Label>
                    <Typeahead
                      id="kolSubType"
                      onChange={(e) =>
                        this.handleNonClinicalFormField("kolSubType", e)
                      }
                      options={this.state.kolSubType}
                      placeholder="Choose a KOL Subtype..."
                      selected={this.state.nonClinicalKolForm.kolSubType}
                    />

                    {this.validateField("nonClinicalKolForm", "kolSubType") && (
                      <FormFeedback>Please Select Sub-KOL Type</FormFeedback>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label>Select Country</Label>
                    <Typeahead
                      id="nonclinical_country"
                      multiple
                      onChange={(e) =>
                        this.handleNonClinicalFormField("country", e)
                      }
                      options={this.state.countryList}
                      placeholder="Choose a Country..."
                      selected={this.state.nonClinicalKolForm.country}
                    />
                  </FormGroup>
                </Form>
              )}
            </div>
            <br />
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => this.submitKOLform()}
              className="btn btn-brand ms-lg-3"
              disabled={this.state.subClinicalKolEnabled}
            >
              Submit
            </Button>{" "}
            <Button color="secondary" onClick={() => this.closeSearchModal()}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.showNonClinicalContactForm}
          toggle={() => this.closeNonClinicalContactModal()}
          size="lg"
          style={{ maxWidth: "800px", width: "100%" }}
          centered
          fullscreen
        >
          <ModalHeader toggle={() => this.closeNonClinicalContactModal()}>
            <h1>Get in touch with us</h1>
            <p>
              Please fill in the form below and we will get in touch within 1
              working day
            </p>
          </ModalHeader>
          <ModalBody style={{ padding: "0px 30px" }}>
            <form className="p-lg-5 col-12 row g-3">
              <div className="col-lg-6">
                <label for="" className="form-label">
                  KOL Subtype:<span class="mandatory">*</span>
                </label>
                <Input
                  type="select"
                  id="SpecilitySelect"
                  value={this.state.nonClinicalKolForm.kolSubType[0]?.label}
                  onChange={(e) =>
                    this.handleNonClinicalFormField(
                      "kolSubType",
                      e.target.value
                    )
                  }
                  style={{ backgroundColor: "white" }}
                >
                  <option>Select KOL Subtype</option>
                  {this.state.kolSubType.map((kol) => (
                    <option key={kol.id} value={kol.label}>
                      {kol.label}
                    </option>
                  ))}
                </Input>
                {this.validateContactFormField("kolSubType") && (
                  <FormFeedback>Please Select Sub-KOL Type</FormFeedback>
                )}
                <br />
              </div>
              <div className="col-lg-6">
                <label for="" className="form-label">
                  Country:
                </label>
                <Input
                  type="select"
                  id="SpecilitySelect"
                  multi
                  style={{ backgroundColor: "white" }}
                  value={this.state.nonClinicalKolForm.country[0]?.label}
                  onChange={(e) =>
                    this.handleNonClinicalFormField("country", e.target.value)
                  }
                >
                  <option value="">Select Country</option>
                  {this.state.countryList.map((country) => {
                    // console.log(country)
                    return (<option key={country.value} value={country.label}>
                      {country.label}
                    </option>)
                  })}
                </Input>
              </div>
              <div className="col-lg-6">
                <label for="" className="form-label">
                  Full Name:<span class="mandatory">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  id=""
                  aria-describedby=""
                  value={this.state.nonClinicalKolForm.fullName}
                  onChange={(e) => {
                    this.handleNonClinicalFormField("fullName", e.target.value);
                  }}
                ></input>
                {this.validateContactFormField("fullName") && (
                  <div className="error">Please Enter Full Name</div>
                )}
              </div>

              <div className="col-lg-6">
                <label for="" className="form-label">
                  Your work email:<span class="mandatory">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  id=""
                  aria-describedby=""
                  value={this.state.nonClinicalKolForm.workEmail}
                  onChange={(e) => {
                    this.handleNonClinicalFormField(
                      "workEmail",
                      e.target.value
                    );
                  }}
                ></input>
                {this.validateContactFormField("workEmail") && (
                  <div className="error">Please Enter Work Email</div>
                )}
              </div>
              <div className="col-lg-6">
                <label for="" className="form-label">
                  Your work phone:<span class="mandatory">*</span>
                </label>

                <PhoneInput
                  inputStyle={{
                    width: "100%",
                    height: "50px",
                    fontSize: "15px",
                  }}
                  width="50%"
                  country={"in"}
                  value={this.state.nonClinicalKolForm.phone}
                  onChange={(mobile_no) => {
                    this.handleNonClinicalFormField("phone", mobile_no);
                  }}
                />

                {this.validateContactFormField("phone") && (
                  <div
                    style={{ marginTop: "10px !important" }}
                    className="error"
                  >
                    Please Enter Mobile Number
                  </div>
                )}
              </div>
              <div className="col-lg-6">
                <label for="" className="form-label">
                  Your company name:<span class="mandatory">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  id=""
                  aria-describedby=""
                  value={this.state.nonClinicalKolForm.companyName}
                  onChange={(e) => {
                    this.handleNonClinicalFormField(
                      "companyName",
                      e.target.value
                    );
                  }}
                ></input>
                {this.validateContactFormField("companyName") && (
                  <div className="error">Please Enter Work Email</div>
                )}
              </div>
              <div className="col-lg-12">
                <label for="" className="form-label">
                  Your job title:<span class="mandatory">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  id=""
                  aria-describedby=""
                  value={this.state.nonClinicalKolForm.jobTitle}
                  onChange={(e) => {
                    this.handleNonClinicalFormField("jobTitle", e.target.value);
                  }}
                ></input>
                {this.validateContactFormField("jobTitle") && (
                  <div className="error">Please Enter Job Title</div>
                )}
              </div>
              <div className="col-lg-12">
                <label for="exampleInputEmail1" className="form-label">
                  Message:<span class="mandatory">*</span>
                </label>
                <textarea
                  name=""
                  placeholder=""
                  className="form-control"
                  id=""
                  rows="2"
                  value={this.state.nonClinicalKolForm.message}
                  onChange={(e) => {
                    this.handleNonClinicalFormField("message", e.target.value);
                  }}
                ></textarea>

                {this.validateContactFormField("message") && (
                  <div className="error">Please Enter Message</div>
                )}
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() =>
                this.setState({
                  nonClinicalContactFormSubmitted: true,
                  showNonClinicalContactForm: false,
                })
              }
              className="btn btn-brand ms-lg-3"
            >
              Submit
            </Button>{" "}
            <Button
              color="secondary"
              onClick={() => this.closeNonClinicalContactModal()}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </body>
    );
  }
}

export default KOLEngagement;
