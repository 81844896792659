import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, customFilter, selectFilter, Comparator, FILTER_TYPES } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Axios from 'axios';
import { Redirect, Link } from 'react-router-dom';
import LoadingBar from "react-top-loading-bar";
import Swal from 'sweetalert2';

import ResercherHeader from './ResercherHeader';


import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
//import { color } from 'html2canvas/dist/types/css/types/color';
var jwt = require('jsonwebtoken');
const jwtkey=require('../config');

const { ExportCSVButton } = CSVExport;

const headerSortingStyle = { backgroundColor: 'grey' };

const { SearchBar } = Search;

const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total ml-2">
    Showing {from} to {to} of {size} Results
  </span>
);

// session storage item:
// researcher_email : is the email of login researcher
// researcher_id: is the id of login researcher
// researcher_name: is the name of login researcher
// project_id : is the id of selected project from table
// Project_Name : is the name of selected project from table
// emp_unique_id: is the employee id to create new emp
// SrNO: is the employee id to create new emp
// kol_unique_id: Kol Unique id to get record of kol on edit page
// kol_status: Kol  status to check edit or give qc comment on edit page

class ResercherDashboard extends Component {
  constructor(props) {

    super(props)

    this.state = {

      system_id: '',
      delete_id: '',

      redirect: false,

      //state for redirecting to login page
      dropdownOpen: false,

      //Name of researcher
      // researcher_name: '',

      token:'',

      //Project Id For redirecting
      BackRID: '',
      project_id: '',
      Project_Name: '',
      RedirectFlag: false,

      // RID: this.props.location.state.RID,
      // resercher_name: this.props.location.state.resercher_name,
      researcher_email: '',
      RID: '',
      resercher_name: '',

      //To show Spinner
      ShowBar: true,

      // State for saving data
      projectList: [],

      Rejected_Count: [],
      Approved_Count: [],

      Project_Count: '0',
      Approved_Count: 0,
      Rejected_Count: '0',
      Work_In_Count: '0',

      onPriceFilter: null,
      onProductilters: null,

      // State for displaying data
      status: '',

      // Column state for Bootstrap Table 
      columns: [
        {
          dataField: 'p_name',
          text: 'Project',
          sort: true,

          formatter: this.link,
          filter: textFilter(),
          filterRenderer: (onFilter, column) => {
            this.onTextFilter = onFilter;
            return null;
          },
        },
        {
          dataField: 'emp_name',
          text: 'Project Manager',
          sort: true,
        },
        // {
        //     dataField: 'last_updated',
        //     text: 'Last Updated Date',
        // },
        {
          dataField: 'end_date',
          text: 'Due Date',
        },
        {
          dataField: "project_status",
          text: "Status",
          sort: true,
          filter: textFilter(),
          formatter:this.statuscolour,
          // style:(cell,row,rowIndex,colIndex)=>
          // {
          //   return( <div style={{backgroundColor:'orange'}}>  </div>)

          // },

          // style: (cell, row, rowIndex, colIndex) => {
          //   if (row.project_status === "Ongoing") {
          //     return {
          //       color: '#1FCE39' 
          //     };
          //   } else if (row.project_status === "Approved") {
          //     return {
          //       color: '#1FCE39'
          //     };
          //   } else if (row.project_status === "Upcoming") {
          //     return {
          //       color: '#41A2FF'
          //     };
          //   } else if (row.project_status === "Completed") {
          //     return {
          //       color: '#41A2FF'
          //     };

          //   } else if (row.project_status === "Rejected") {
          //     return {
          //       color: '#e51c1c'
          //     };

          //   }
          //   else if (row.project_status === "Submited" || row.project_status === "Submit") {
          //     return {
          //       color: '#41A2FF'
          //     };

          //   }
          // },

          filterRenderer: (onFilter, column) => {
            this.onStatusFilters = onFilter;
            return null;
          },
        },

      ]
      // End of state
    }
    // End of constructor

    this.toggleLogout = this.toggleLogout.bind(this);
  }

  // STARNGE  
  onTextChange = (event) => {
    console.log(event.target.value)
    const { value } = event.target;
    this.onTextFilter(value);
  };

  //STRANGE
  onFilter = (event) => {
    console.log(event.target.value)
    const { value } = event.target;
    this.onStatusFilters(value);
  };
  

 //Funtion to apply links to project names
 statuscolour = (cell, row) => {
  var id = row.project_id;
  var project_name = row.p_name;
  var project_status = row.project_status;
  var col='';

          if (row.project_status === "Ongoing") {
              //return {
                col= '#1FCE39' 
              //};
             } else if (row.project_status === "Approved") {
            //   return {
                 col= '#1FCE39'
               //};
             } else if (row.project_status === "Upcoming") {
            //   return {
                 col= '#41A2FF'
              // };
            } else if (row.project_status === "Completed") {
            //   return {
                 col= '#41A2FF'
            //   };

            } else if (row.project_status === "Rejected") {
            //   return {
                 col= '#e51c1c'
            //   };

             }
             else if (row.project_status === "Submited" || row.project_status === "Submit") {
            //   return {
                 col= '#41A2FF'
            //   };

            }

  return (
    <div style={{color:col}}>
     {row.project_status}
    </div>
  )
}

  //Funtion to apply links to project names
  link = (cell, row) => {
    var id = row.project_id;
    var project_name = row.p_name;
    var project_status = row.project_status;
    return (
      <div>
        <a style={{  color: '#069', textDecoration: 'underline', cursor: 'pointer' }}
          onClick={this.GoToKols.bind(this, id, project_name, project_status)}> {row.p_name} </a>
      </div>
    )
  }

  GoToKols(id, project_name, project_status) {
    console.log('Yep Here');
    console.log('Id Here', id);
    console.log('project_name Here', project_name);
    if(project_status == "Upcoming"){
      Swal.fire({
          icon: 'error',
          text: "Project is in upcoming state",
          // text: 'Wrong Username or Password!',
          confirmButtonColor: '#d33',
          confirmButtonText: 'OK'
      });     
  }
  else{
    this.setState({
      RedirectFlag: true,
      // project_id: id, 
      // Project_Name: project_name 
    })
    sessionStorage.setItem('project_id', id)
    sessionStorage.setItem('Project_Name', project_name)
  }
  }

  // Method to fetch data from API when component Mounted
  componentDidMount = async () => {

    let getSession_token = await sessionStorage.getItem('token')
    if (getSession_token !== null) {
      await this.setState({
        token: getSession_token,
      })
    }  


    let getSession_Researcher_Email = await sessionStorage.getItem('email')
    if (getSession_Researcher_Email !== null) {
      await this.setState({
        researcher_email: getSession_Researcher_Email,
      })
    }  
   
    let getSession_Researcher_Id = await sessionStorage.getItem('id')
    if (getSession_Researcher_Id !== null) {
      await this.setState({
        RID: getSession_Researcher_Id,
      })
    }   
   

    let getSession_Researcher_Name = await sessionStorage.getItem('name')
    if (getSession_Researcher_Name !== null) {
      await this.setState({
        resercher_name: getSession_Researcher_Name,
      })
    }
    
   

    console.log("getSession_Researcher_Email ===== ", getSession_Researcher_Email)
    console.log("getSession_Researcher_Id ===== ", getSession_Researcher_Id)
    console.log("getSession_Researcher_Name ===== ", getSession_Researcher_Name)

    // Project List Fetched
    console.log('Fetchig data with id for project list');
    const url = global.websiteUrl+'/service/KOL/get_Researcher_ProjectList';
    let formData = new FormData();
    formData.append('researcher_id', this.state.RID);
    // formData.append('oper', 'del');

    const config = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }

    Axios.post(url, formData, config)
      .then(response => {
        console.log(response);
        var lgdata = response.data.Project_List;
        console.log("Fetched data", JSON.stringify(lgdata));
        var lgstatus = response.data.status;
        console.log("Fetched status", JSON.stringify(lgstatus));
        console.log("Message from fetched api", response.data.message);

        if (lgdata) {
          this.setState({
            status: response.data.message,
            projectList: response.data.Project_List,
            // researcher_name: response.data.Project_List[0]['emp_name'],
          })
        } else {
          this.setState({
            status: response.data.message,
            projectList: []
          })
        }
      });

    // Dashoard Count Fetched
    console.log('Fetchig Dashboard Count');
    console.log('Researcher ID', this.state.RID);
    const url1 = global.websiteUrl+'/service/KOL/get_Researcher_DashCount';
    let formData1 = new FormData();
    formData1.append('researcher_id', this.state.RID);

    const config1 = {
      //   headers: { 'content-type': 'multipart/form-data' }
    }

    Axios.post(url1, formData1, config1)
      .then(response => {
        console.log(response);
        var lgdata = response.data;
        console.log("Fetched data", JSON.stringify(lgdata));
        var lgstatus = response.data.status;
        console.log("Fetched status", JSON.stringify(lgstatus));
        console.log("New Project Count", response.data.New_Project_Count);
        // console.log("Approved Count", lgdata.Status_Count[0]['kol_status_count']);
        this.setState({
          Project_Count: response.data.New_Project_Count,
        })

        var all_count = response.data.Status_Count;
        if (all_count) {
          all_count.map(async (data) => {
            if (data.kol_status == 'Approved') {
              this.setState({
                Approved_Count: data.kol_status_count
              })
            }
            if (data.kol_status == 'Rejected') {
              this.setState({
                Rejected_Count: data.kol_status_count
              })
            }
            if (data.kol_status == 'Work In Progress') {
              this.setState({
                Work_In_Count: data.kol_status_count
              })
            }
          })
        }

       
      });
  }

  toggleLogout() {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }

  logout = () => {
    console.log("in logout")
    this.setState({ redirect: true })
  }

  render() {

    if (this.state.redirect) {
      return (<Redirect to={'/'} />)
    }

    const pageButtonRenderer = ({
      page,
      active,
      disable,
      title,
      onPageChange
    }) => {
      const handleClick = (e) => {
        e.preventDefault();
        onPageChange(page);
      };
      return (
        <li class="page-item" onClick={handleClick} ><a class="page-link" href="#">{page}</a></li>
      );
    };

    const options = {
      pageButtonRenderer,
      paginationSize: 4,
      pageStartIndex: 1,
      // alwaysShowAllBtns: true, // Always show next and previous button
      // withFirstAndLast: false, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '50', value: 50
      }, {
        text: '100', value: 100
      }, {
        text: '200', value: 200
      }, {
        text: 'All', value: this.state.projectList.length
      }]  // A numeric array is also available. the purpose of above example is custom the text

    };

    //Redirecting to Login Page
    if (this.state.RedirectFlag) {
      return (<Redirect to={{
        pathname: '/ResercheViewKolList',
       
      }} />)
    }
    return (
      <div style={{marginTop:'15vh' }}>
        {this.state.ShowBar ?
          <div style={{ position: 'fixed', top: '0' }}>
            <LoadingBar
              progress='100'
              // onLoaderFinished={() => setProgress(0)}
              //  progress={progress}
              height={3} color="#A90007" transitionTime={4000}
              loaderSpeed={4000} />
          </div>
          : null}
        <div >
          <div >
            <div >
              <ResercherHeader ResercherName={this.state.resercher_name} />
              <div class="container">
                <div class="content-wrapper">
                  <div class="screen-headingbox">
                    <h2>Researcher Dashboard</h2>
                  </div>
                  <div class="row">
                    <div class="col-md-12 col-sm-12 col-xs-12">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="tile">
                            <div class="tile-heading" style={{ marginTop: '14%' }}>
                              <h3>Total Projects</h3>
                              <div class="red">
                                <h2 class="bold" style={{color:'#C70039' , textDecoration:'none'}}>{this.state.Project_Count}</h2>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="tile">
                            <div class="tile-heading" style={{ marginTop: '14%' }}>
                              <h3>Approved Profiles</h3>
                              <div class="red">
                                <h2 class="bold" style={{color:'#C70039' , textDecoration:'none'}}>{this.state.Approved_Count}</h2>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="tile">
                            <div class="tile-heading" style={{ marginTop: '14%' }}>
                              <h3>Edit Profiles</h3>
                              <div class="red">
                                <h2 class="bold" style={{color:'#C70039' , textDecoration:'none'}}>{this.state.Rejected_Count}</h2>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="tile">
                            <div class="tile-heading">
                              {/* <h2>Work In Progress Profiles</h2> */}
                              <h3>Work In Progress Profiles</h3>
                              {/* <text style={{fontSize:'17px'}}>Work In Progress Profiles</text> */}
                              <div class="red">
                                <h2 class="bold" style={{color:'#C70039' , textDecoration:'none'}}>{this.state.Work_In_Count}</h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <hr  /> */}

                      <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12 emplist-box">
                          <div className="row">
                            <div className="col-md-12">
                              <h3>Project Overview</h3>
                              <div className="employee-actions">
                                <div className="table-infobox">
                                  <p className="list-count" style={{ color: 'white' }}>
                                    {this.state.projectList.length} Projects
                                  </p>
                                  <div className="filter-box float-right mb-30">
                                    <div className="input-group">
                                      {/* <div className="input-group-prepend">
                                                                                <div className="input-group-text"><img src={require("../Assets/Images/search.svg")} /></div>
                                                                            </div>
                                                                            <input type="text" className="form-control" id="inlineFormInputGroupUsername" placeholder="Search by Name, project"
                                                                                onChange={(e) => { this.onTextChange(e) }}
                                                                            /> */}
                                    </div>
                                    <div className="input-group">
                                      {/* <div className="input-group-prepend">
                                                                                <div className="input-group-text"><img src={require("../Assets/Images/search.svg")} /></div>
                                                                            </div>
                                                                            <select className="form-control no-border-top no-border-left no-border-right" id="exampleFormControlSelect1"
                                                                                onChange={(e) => { this.onTextChange(e) }}
                                                                            >
                                                                                <option value="">Sort by alphabates</option>
                                                                                <option>A</option>
                                                                                <option>B</option>
                                                                                <option>C</option>
                                                                                <option>D</option>
                                                                            </select> */}
                                      <div className="input-group-prepend">
                                        <div className="input-group-text"><img src={require("../Assets/Images/search.svg")} /></div>
                                      </div>
                                      <input type="text" className="form-control" id="inlineFormInputGroupUsername" placeholder="Search by Project Name"
                                        onChange={(e) => { this.onTextChange(e) }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="sort-roll">
                                  <select className="form-control" id="exampleFormControlSelect1"
                                    onChange={(e) => { this.onFilter(e) }}
                                  >
                                    <option value="">Select Status</option>
                                    <option>Completed</option>
                                    <option>Ongoing</option>
                                    <option>Upcoming</option>
                                  </select>
                                </div>
                              </div>
                              <ToolkitProvider
                                keyField="emp_id"
                                data={this.state.projectList}
                                columns={this.state.columns}
                                search={this.customMatchFunc}
                                //exportCSV
                                exportCSV={{
                                  fileName: 'cetas.csv',
                                  noAutoBOM: false,
                                }}
                              >
                                {
                                  props => (
                                    <div>
                                      {/* <ExportCSVButton { ...props.csvProps }>Export CSV!!</ExportCSVButton> */}
                                      {/* <hr /> */}
                                      <BootstrapTable
                                        {...props.baseProps}
                                        // selectRow={ selectRow }
                                        noDataIndication="Table is Empty"
                                        striped
                                        hover
                                        condensed
                                        loading={true}
                                        pagination={paginationFactory(options)}
                                        filter={filterFactory()}
                                        rowStyle={ { backgroundColor: 'White' } }

                                      />
                                    </div>
                                  )
                                }
                              </ToolkitProvider>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResercherDashboard;