import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import Swal from 'sweetalert2';
var jwt = require('jsonwebtoken');
const jwtkey=require('../config');

class AdminHeader extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            redirect: false,
        };
    }

    logout = () => {
        console.log("in logout")

        sessionStorage.removeItem('email')
        sessionStorage.removeItem('id')
        

        this.setState({redirect: true})
    }


componentDidMount = async () => {
  
    let getSession_wtoken = await sessionStorage.getItem('wtoken')  
    // console.log('tok',getSession_wtoken);
 
     jwt.verify(getSession_wtoken,jwtkey.jwt.key, async (err, response) => {     
     if(!err)
         {
             let getSession_token = await sessionStorage.getItem('token')  
             //console.log(getSession_token);
              
             let getSession_admin_email = await sessionStorage.getItem('email')
   
             let getSession_admin_id = await sessionStorage.getItem('id')
             if (getSession_admin_id == null) {
                 await this.setState({
                     redirect:true,
                 })
             }
         
         }
         else
         {                        
             console.error('error',err.message);
             Swal.fire({
             icon: 'error',
             title: jwtkey.jwt.session_expired_msg_title,
             text:jwtkey.jwt.session_expired_msg,
             // showCancelButton: true,
             confirmButtonColor: '#d33',
             cancelButtonColor: '#d33',
             confirmButtonText: 'OK'
             })
 
             sessionStorage.removeItem('wtoken');
             sessionStorage.removeItem('email')
             sessionStorage.removeItem('id')             
             this.setState({redirect: true})
         }
         
        
     })
   
   
    
   
}


    render() {
        if (this.state.redirect) {
            return (<Redirect to={'/'}/>)
        }
        return (
            <div className="header-nav fixed-top">
                <div className="container">
                    <nav className="navbar navbar-expand-lg">
                        {/* <a className="navbar-brand" href="https://cetas-healthcare.com/">
                            <img
                                src={require('../Assets/Images/cetas-logo.png')} 
                            />  
                        </a> */}
                        <a style={{display: "table-cell" ,marginLeft:'-5vh'}} className="navbar-brand" 
                            href="https://cetas-healthcare.com/" target = "_blank">
                            <img
                                src={require('../Assets/Images/Medtech_Gurus_logo.png')} 
                            />  
                        </a>

                        <button className="navbar-toggler" type="button" data-toggle="collapse" 
                                    data-target="#navbarNavAltMarkup" 
                                    aria-controls="navbarNavAltMarkup" aria-expanded="false" 
                                    aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <div className="navbar-nav">
                                {/* <a className="nav-item nav-link active" href="#">Home 
                                    <span className="sr-only">(current)</span>
                                </a>
                                <a className="nav-item nav-link" href="#">Projects</a>
                                <a className="nav-item nav-link" href="#">Team Members</a>
                                <a className="nav-item nav-link" href="#">
                                    <img
                                        src={require('../Assets/Images/setting.png')} 
                                    /> 
                                    Search
                                </a> */}
                            </div>
                        </div>

                        <a className="nav-item nav-link username-box" >
                            <img 
                                src={require('../Assets/Images/user.svg')} 
                            />
                                Welcome, Admin
                        </a>
                        

                        <a className="nav-item nav-link username-box float-right" 
                            onClick={this.logout.bind(this)}>
                            <img 
                                src={require('../Assets/Images/logoutByVibha.svg')} 
                            />
                                Logout
                        </a>

                    </nav>
                </div>
            </div> 
        );
    }
}

export default AdminHeader;