import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';


class KolProfileHeader extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            redirect: false,
        };
    }

    componentDidMount = async () => {
        let getSession_Resercher_Total_Survey = await sessionStorage.getItem('research_total_survey')
        if (getSession_Resercher_Total_Survey !== null) {
            await this.setState({
                researcher_total_survey: getSession_Resercher_Total_Survey,
            })
        }
        let getSession_Kol_Unique_Id = await sessionStorage.getItem('kol_uniqu_id')
        if (getSession_Kol_Unique_Id !== null) {
            await this.setState({
                kolIdForCredential: getSession_Kol_Unique_Id,
            })
        }
        else
        {
            this.setState({redirect: true})
        
        }
        let getSession_Kol_Name = await sessionStorage.getItem('kol_name')
        if (getSession_Kol_Name !== null) {
            await this.setState({
                kolNameLogedIn: getSession_Kol_Name,
            })
        }
        else
        {
            this.setState({redirect: true})
        
        }
        let getSession_Kol_Login_Time_Pass = await sessionStorage.getItem('kol_login_time_password')
        if (getSession_Kol_Login_Time_Pass !== null) {
            await this.setState({
                kolLoginTimePassword: getSession_Kol_Login_Time_Pass,
            })
        }
    }
    
    logout = () => {
        console.log("in logout")
        this.setState({redirect: true})
        sessionStorage.removeItem('kol_uniqu_id')
        sessionStorage.removeItem('research_total_survey')
        sessionStorage.removeItem('kol_name')
    }
    render() {
    if (this.state.redirect) {
            return (<Redirect to={'/'}/>)
        }
        return (
            <div class="header-nav">
                <div class="container">
                    <nav class="navbar navbar-expand-lg">
                        <a className="navbar-brand" href="https://cetas-healthcare.com/">
                            <img
                                src={require('../Assets/Images/cetas-logo.png')} 
                            />  
                        </a>
                        <button class="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup"
                                aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <div class="navbar-nav">
                                {/* <a class="nav-item nav-link active" href="#">Home <span
                                    class="sr-only">(current)</span></a>
                                <a class="nav-item nav-link" href="#">Projects</a>
                                <li class="nav-item  dropdown multi-option">
                                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        KOLs
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <a class="dropdown-item" href="#">KOL Ranking</a>
                                        <a class="dropdown-item" href="#">KOL QC</a>
                                    </div>
                                </li>
                                <a class="nav-item nav-link" href="#">
                                    <img src={require('../Assets/Images/setting.svg')} /> Search
                                </a> */}
                            </div>
                        </div>
                        <a class="nav-item nav-link username-box">
                        <img src={require('../Assets/Images/user.svg')}  /> {this.props.KolName} </a>

                        <a className="nav-item nav-link username-box  float-right" 
                            onClick={this.logout.bind(this)}>
                            <img 
                                src={require('../Assets/Images/logoutByVibha.svg')} 
                            />
                                Logout
                        </a>
                    </nav>
                </div>
            </div>
        );
    }

}

export default KolProfileHeader;